import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { KEYCLOAK_ID, KEYCLOAK_REALM, KEYCLOAK_BASE_URL } from "../configs/api";
import { GRANT_TYPE_REFRESH_TOKEN } from "../configs/configs";
import queryString from "query-string";
import { decodeToken } from "react-jwt";
import axios from "axios";

export const useAuthStore = create(
  persist(
    (set, get) => ({
      keycloak: undefined,
      authenticated: false,
      tokensParsed: undefined,
      tokens: undefined,
      saveKeycloak: (data) => set(data),
      getNewToken: async () => {
        try {
          const requestBody = queryString.stringify({
            client_id: KEYCLOAK_ID,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            refresh_token: get().tokens.refreshToken,
          });

          const response = await axios.post(
            `${KEYCLOAK_BASE_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
            requestBody,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accept: "application/json",
              },
            }
          );

          const data = response.data;

          const tokenParsed = decodeToken(data.access_token);
          const refreshTokenParsed = decodeToken(data.refresh_token);
          const tokensParsed = { tokenParsed, refreshTokenParsed };
          const tokens = {
            token: data.access_token,
            refreshToken: data.refresh_token,
          };

          set((state) => ({ authenticated: true, tokensParsed, tokens }));
          return { ...data, tokensParsed, tokens };
        } catch (err) {
          return;
        }
      },
    }),
    {
      name: "auth-storage",
    }
  )
);
