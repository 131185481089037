import React from "react";

const Card = ({ data }) => {
  return (
    <div className="flex flex-col space-y-8 rounded-8 border-gray-10 bg-white p-8">
      <p className="text-14 font-bold md:text-16">{data.title}</p>
      <div className="grid h-full grid-cols-2 gap-8">
        {data.data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-start justify-center border-l-2 border-l-yellow-500 px-16"
          >
            <h4 className="text-14 font-bold md:text-18">
              {item.value}{" "}
              <span className="text-12 md:text-14">{item.suffix}</span>
            </h4>
            <p className="font-regular text-12 md:text-14">{item.timeFrame}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
