import axiosInstance from "../api";
import { DIMENSIONAL_TAG } from "../../configs/api";

export const getDimensionalTagsApi = (params) =>
  axiosInstance.get(DIMENSIONAL_TAG, {
    params,
  });

export const createDimensionalTagApi = (body) =>
  axiosInstance.post(DIMENSIONAL_TAG, body);

export const updateDimensionalTagApi = (id, body) =>
  axiosInstance.put(`${DIMENSIONAL_TAG}/${id}`, body);

export const deleteDimensionalTagApi = (id) =>
  axiosInstance.delete(`${DIMENSIONAL_TAG}/${id}`);
