import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import {
  getDimensionalTagsApi,
  createDimensionalTagApi,
  updateDimensionalTagApi,
  deleteDimensionalTagApi,
} from "./dimensionalTagApi";

export const useDimensionalTagStore = create((set, get) => ({
  tags: [],
  tag: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getDimensionalTags: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getDimensionalTagsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Tags",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ tags: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan Tags",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateDimensionalTag: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateDimensionalTagApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Tag",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Tag`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Tag",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createDimensionalTag: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createDimensionalTagApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Create Tag",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Menambahkan Tag`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Create Tag",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  deleteDimensionalTag: async ({ id, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await deleteDimensionalTagApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Delete Tag",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Delete Tag`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Delete Create Tag",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
