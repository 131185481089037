import React from "react";
import PropTypes from "prop-types";
import InputHook from "../../../../../../../../components/atoms/InputHook";
import InputOption from "../../../../../../../../components/atoms/SelectHook/partials/InpuOptions";
import CreatableSelects from "../../../../../../../../components/atoms/Inputs/CreatableSelect/CreateableSelect";

const Content = ({ control, register, errors }) => {
  return (
    <div className="grid grid-cols-1 gap-12">
      <div className="grid grid-cols-2 gap-12">
        <InputHook
          required
          register={register}
          label="Nama Tag Grup"
          name="name"
          errors={errors?.name}
          inputClassName="input-large-border"
          className="col-span-2"
          placeholder="Masukkan nama group untuk tags"
        />
        <InputHook
          required
          register={register}
          label="Deskripsi"
          name="description"
          errors={errors?.description}
          inputClassName="input-large-border"
          placeholder="Masukkan deskripsi"
        />
        <InputHook
          required
          register={register}
          label="Tag Prefix"
          name="itemPrefix"
          errors={errors?.itemPrefix}
          inputClassName="input-large-border"
          placeholder="Masukkan prefix dari tag"
        />
        <CreatableSelects
          required
          control={control}
          label="Tags"
          name="newTags"
          errors={errors?.newTags}
          placeholder="Masukkan tag"
          className="col-span-2"
          components={{ Option: InputOption }}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </div>
    </div>
  );
};

export default Content;

Content.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};
