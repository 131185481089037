import React, { useEffect } from "react";
import { TbFilter, TbRefresh, TbX } from "react-icons/tb";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { CgNametag } from "react-icons/cg";
import InputHook from "../../../../../components/atoms/InputHook";
import { useForm } from "react-hook-form";
import { dataMaker } from "../../../dataMaker";
import { useEstateStore } from "../../../../../api/estates/useEstateStore";
import { useAfdelingStore } from "../../../../../api/afdelings/useAfdelingStore";
import { useBlockStore } from "../../../../../api/blocks/useBlockStore";
import CreateMassData from "../CreateMassData/CreateMassData";
import { APP_NAME } from "../../../../../configs/api";

const Filter = ({
  onShowModal,
  handleRefreshData,
  modalName,
  onFilter,
  showModal,
  // modalUploadName,
}) => {
  const { register, watch, handleSubmit, setValue } = useForm();
  const [inputNames, setInputNames] = React.useState({
    fullname: undefined,
  });

  const createEstate = useEstateStore((state) => state.createEstate);
  const createAfdeling = useAfdelingStore((state) => state.createAfdeling);
  const createBlock = useBlockStore((state) => state.createBlock);

  const onSubmit = (e) => {
    onFilter({
      value: e.name,
      type: "name",
    });
  };

  useEffect(() => {
    const subscription = watch((v) =>
      setInputNames({
        fullname: v.name,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const createMassData = () => {
    let estate = [];
    let afdeling = [];
    let blocks = [];

    dataMaker.map((initialEstate) => {
      // estate.push({
      //   ...initialEstate,
      //   companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
      //   companyName: "PT PERKEBUNAN NUSANTARA XIII",
      // });

      createEstate({
        silent: true,
        body: {
          ...initialEstate,
          companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
          companyName: "PT PERKEBUNAN NUSANTARA XIII",
        },

        onSuccess: (estateData) => {
          initialEstate?.afdelings?.map((intialAfdeling) => {
            // afdeling.push({
            //   ...afdelingData,
            //   estateId: initialData?.id,
            //   estateName: initialData?.name,
            //   companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
            //   companyName: "PT PERKEBUNAN NUSANTARA XIII",
            // });

            createAfdeling({
              silent: true,
              body: {
                ...intialAfdeling,
                estateId: estateData?.record?.id,
                estateName: estateData?.record?.name,
                companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
                companyName: "PT PERKEBUNAN NUSANTARA XIII",
              },

              onSuccess: (afdelingData) => {
                intialAfdeling?.blocks?.map((initialBlock) => {
                  // blocks.push({
                  //   ...initialBlock,
                  //   estateId: initialData?.id,
                  //   estateName: initialData?.name,
                  //   afdelingId: afdelingData?.id,
                  //   afdelingName: afdelingData?.name,
                  //   companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
                  //   companyName: "PT PERKEBUNAN NUSANTARA XIII",
                  // });
                  console.log({
                    ...initialBlock,
                    estateId: estateData?.record?.id,
                    estateName: estateData?.record?.name,
                    afdelingId: afdelingData?.record?.id,
                    afdelingName: afdelingData?.record?.name,
                    companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
                    companyName: "PT PERKEBUNAN NUSANTARA XIII",
                  });
                  createBlock({
                    silent: true,
                    body: {
                      ...initialBlock,
                      estateId: estateData?.record?.id,
                      estateName: estateData?.record?.name,
                      afdelingId: afdelingData?.record?.id,
                      afdelingName: afdelingData?.record?.name,
                      companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
                      companyName: "PT PERKEBUNAN NUSANTARA XIII",
                    },
                  });
                });
              },
            });
          });
        },
      });

      // createEstate({
      //   body: initialData,
      //   silent: true,
      //   onSuccess: (resEstate) => {
      //     const recordEstate = resEstate.record;
      //     initialData?.afdelings?.map((afdeling) => {
      //       createAfdeling({
      //         body: {
      //           ...afdeling,
      //           estateId: recordEstate?.id,
      //           estateName: recordEstate?.name,
      //           companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
      //           companyName: "PT PERKEBUNAN NUSANTARA XIII",
      //         },
      //         silent: true,
      //
      //         onSuccess: (resAfdeling) => {
      //           const recordAfdeling = resAfdeling.record;
      //           afdeling?.blocks?.map((block) => {
      //             createBlock({
      //               body: {
      //                 ...block,
      //                 estateId: recordEstate?.id,
      //                 estateName: recordEstate?.name,
      //                 afdelingId: recordAfdeling?.id,
      //                 afdelingName: recordAfdeling?.name,
      //                 companyId: "1ee838aa-fa7b-602b-9bd7-ffbb286aa6cc",
      //                 companyName: "PT PERKEBUNAN NUSANTARA XIII",
      //               },
      //               silent: true,
      //             });
      //           });
      //         },
      //       });
      //     });
      //   },
      // });
    });
  };

  return (
    <div className="mx-16 mt-56 grid grid-cols-1 gap-8 md:mt-0 md:grid-cols-2">
      <div className="order-2 grid w-full grid-cols-2 gap-8 md:order-1 md:flex md:w-fit md:flex-row md:items-center md:space-x-8">
        {/*<CreateMassData />*/}
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:-ml-8 md:hidden md:w-fit"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={handleRefreshData}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        {/* <ButtonComponent
					tooltipPlacement="bottom"
					tooltipTitle="Import Excel"
					size="none"
					type="button"
					className="bordered-button-transition bg-white p-10"
					onClick={() =>
						onShowModal(modalUploadName, "showModalInput")
					}
					icon={
						<MdOutlineUploadFile className="text-18 text-gray-500" />
					}
				/>
				<ButtonComponent
					tooltipPlacement="bottom"
					tooltipTitle="Upload Excel"
					size="none"
					type="button"
					className="bordered-button-transition bg-white p-10"
					onClick={() => alert.info("Feature akan segera tersedia")}
					icon={
						<MdOutlineSimCardDownload className="text-18 text-gray-500" />
					}
				/> */}
        <InputHook
          register={register}
          name="name"
          placeholder="name estate"
          className="hidden max-w-[210px] md:inline"
          inputClassName="input-mini-border pr-24 pl-8"
          rightIcon={
            <TbX
              className="cursor-pointer text-16 text-gray-500 hover:text-red-600"
              onClick={() => {
                setValue("name", undefined);
                onFilter({
                  value: undefined,
                  type: "name",
                });
              }}
            />
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit(onSubmit)(event);
            }
          }}
          onSubmit={() => {
            onFilter({
              value: inputNames.name,
              type: "name",
            });
          }}
        />
      </div>
      <div className="order-1 flex w-full justify-end md:order-2">
        <ButtonComponent
          value="Tambah Estate"
          className={`button-transition w-full md:w-fit ${
            APP_NAME === "semai-kebun" &&
            "bg-primary-red-500 hover:border-primary-red-500 hover:text-primary-red-500 active:bg-primary-red-500"
          }`}
          onClick={() => {
            onShowModal(modalName, "showModalCreate");
            // createMassData();
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
