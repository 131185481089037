import axiosInstance from "../api";
import { CHEMISTS_URL } from "../../configs/api";

export const getChemistsApi = (params) =>
  axiosInstance.get(CHEMISTS_URL, {
    params,
  });

export const createChemistApi = (body) =>
  axiosInstance.post(CHEMISTS_URL, body);

export const updateChemistApi = (id, body) =>
  axiosInstance.put(`${CHEMISTS_URL}/${id}`, body);

export const deleteChemistApi = (id) =>
  axiosInstance.delete(`${CHEMISTS_URL}/${id}`, body);
