export const defaultOptions = {
	maintainAspectRatio: false,
	scales: {
		y: {
			grid: {
				display: false,
			},
			ticks: {
				font: {
					size: "14",
					weight: "500",
					family: "Inter-Regular",
				},
			},
		},
		x: {
			grid: {
				display: false,
			},
			ticks: {
				font: {
					size: "14",
					weight: "500",
					family: "Inter-Regular",
				},
			},
		},
	},
	layout: {
		padding: {
			top: 32,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
};
