import { FERTILIZATIONS_URL } from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {};

export const getFertilizations = createAsyncThunk(
  "fertilization/getFertilizations",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(FERTILIZATIONS_URL, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: {
          fltDeleted: 0,
          pageIndex: data?.page || 0,
          sidx: "syncInfo.updatedTime",
          sord: data?.sord || "desc",
          ...data,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFertilizationById = createAsyncThunk(
  "fertilization/getFertilizationById",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(`${FERTILIZATIONS_URL}/${data.id}`, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: data,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getFertilizationSpotChecks = createAsyncThunk(
  "fertilization/getFertilizationSpotChecks",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${FERTILIZATIONS_URL}/${data.id}/spot-checks`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFertilization = createAsyncThunk(
  "harvesting/updateFertilization",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.put(
        `${FERTILIZATIONS_URL}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fertilizationSlice = createSlice({
  name: "fertilization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFertilizations.fulfilled, () => {})
      .addCase(getFertilizationById.fulfilled, () => {})
      .addCase(getFertilizationSpotChecks.fulfilled, () => {})
      .addCase(updateFertilization.fulfilled, () => {});
  },
});

export default fertilizationSlice.reducer;
