export const divisi11 = {
  type: "FeatureCollection",
  name: "BlokDivisi11_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        No_Kav: "B1",
        Luas: 24.3,
        POKOK: "2840",
        SPH: "116",
        POKOK0: "760",
        POKOK1: "3600",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.863629, -1.596905],
              [103.862956, -1.596989],
              [103.862583, -1.597028],
              [103.861879, -1.597113],
              [103.861319, -1.597169],
              [103.860902, -1.597198],
              [103.860504, -1.597253],
              [103.860174, -1.597278],
              [103.859833, -1.597316],
              [103.859503, -1.597352],
              [103.859209, -1.597385],
              [103.858879, -1.597422],
              [103.858241, -1.597478],
              [103.857801, -1.597527],
              [103.857501, -1.597552],
              [103.857132, -1.59758],
              [103.857005, -1.597584],
              [103.857009, -1.597469],
              [103.857006, -1.597384],
              [103.857016, -1.597301],
              [103.857086, -1.597049],
              [103.857123, -1.596894],
              [103.857128, -1.596862],
              [103.857133, -1.596834],
              [103.857142, -1.596763],
              [103.857151, -1.596722],
              [103.857215, -1.596421],
              [103.857253, -1.596238],
              [103.857312, -1.596008],
              [103.857325, -1.595968],
              [103.857337, -1.595919],
              [103.857344, -1.595842],
              [103.857351, -1.595775],
              [103.857358, -1.595712],
              [103.857372, -1.595659],
              [103.857403, -1.595523],
              [103.857499, -1.59508],
              [103.85761, -1.594596],
              [103.857721, -1.594142],
              [103.858208, -1.594135],
              [103.859002, -1.594128],
              [103.859683, -1.594125],
              [103.86023, -1.594133],
              [103.860727, -1.594133],
              [103.861226, -1.59413],
              [103.861637, -1.594119],
              [103.862337, -1.594123],
              [103.862678, -1.594115],
              [103.863168, -1.594123],
              [103.863694, -1.594115],
              [103.86369, -1.594419],
              [103.863687, -1.594646],
              [103.863684, -1.594876],
              [103.863676, -1.595182],
              [103.863674, -1.59548],
              [103.863666, -1.595709],
              [103.863663, -1.595934],
              [103.863652, -1.59617],
              [103.863652, -1.596466],
              [103.863642, -1.596566],
              [103.863642, -1.596566],
              [103.863629, -1.596905],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "C1",
        Luas: 26.7,
        POKOK: "3933",
        SPH: "147",
        POKOK0: "46",
        POKOK1: "3979",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.864365, -1.594116],
              [103.864754, -1.594113],
              [103.86501, -1.594119],
              [103.865356, -1.59412],
              [103.865695, -1.594118],
              [103.866042, -1.594118],
              [103.866296, -1.594125],
              [103.866474, -1.594124],
              [103.866806, -1.594123],
              [103.867143, -1.594123],
              [103.867476, -1.594123],
              [103.867817, -1.594121],
              [103.868345, -1.594116],
              [103.868677, -1.594115],
              [103.869008, -1.594115],
              [103.869341, -1.594115],
              [103.869512, -1.594113],
              [103.869677, -1.594113],
              [103.870008, -1.594113],
              [103.870339, -1.594113],
              [103.87067, -1.594113],
              [103.871011, -1.594109],
              [103.87135, -1.594103],
              [103.871689, -1.594103],
              [103.871859, -1.594107],
              [103.872032, -1.594105],
              [103.872538, -1.594102],
              [103.872936, -1.594112],
              [103.872933, -1.594504],
              [103.872932, -1.594877],
              [103.872928, -1.595251],
              [103.872928, -1.595548],
              [103.872928, -1.595844],
              [103.872932, -1.596049],
              [103.872406, -1.59611],
              [103.87187, -1.596161],
              [103.871346, -1.596211],
              [103.870962, -1.596239],
              [103.870623, -1.596271],
              [103.870094, -1.596319],
              [103.869591, -1.596364],
              [103.869256, -1.5964],
              [103.868895, -1.596433],
              [103.86824, -1.596494],
              [103.867754, -1.596538],
              [103.867244, -1.596575],
              [103.866752, -1.596626],
              [103.866255, -1.596668],
              [103.865746, -1.596705],
              [103.865076, -1.59678],
              [103.864463, -1.596829],
              [103.863948, -1.59688],
              [103.863722, -1.596891],
              [103.863731, -1.59645],
              [103.863737, -1.596196],
              [103.863742, -1.596021],
              [103.863753, -1.595441],
              [103.863757, -1.595141],
              [103.863761, -1.594844],
              [103.863768, -1.594544],
              [103.863778, -1.59432],
              [103.863781, -1.594121],
              [103.863781, -1.594121],
              [103.864365, -1.594116],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D1",
        Luas: 17.3,
        POKOK: "2478",
        SPH: "142",
        POKOK0: "82",
        POKOK1: "2560",
        SPH_2: "147",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.873475, -1.594102],
              [103.874105, -1.594099],
              [103.874774, -1.594092],
              [103.875262, -1.594094],
              [103.875607, -1.594082],
              [103.875938, -1.594079],
              [103.87625, -1.594079],
              [103.87647, -1.594084],
              [103.876723, -1.594079],
              [103.877061, -1.594079],
              [103.877569, -1.594078],
              [103.878067, -1.594079],
              [103.878569, -1.594081],
              [103.878906, -1.594078],
              [103.879216, -1.594076],
              [103.879548, -1.594076],
              [103.879879, -1.594077],
              [103.880216, -1.594076],
              [103.880638, -1.594073],
              [103.881136, -1.594073],
              [103.881598, -1.594077],
              [103.881601, -1.594424],
              [103.881601, -1.594725],
              [103.8816, -1.595023],
              [103.8816, -1.595248],
              [103.881601, -1.595332],
              [103.880812, -1.59542],
              [103.880021, -1.595482],
              [103.879378, -1.595549],
              [103.87862, -1.595614],
              [103.877771, -1.595691],
              [103.876626, -1.595791],
              [103.876129, -1.595838],
              [103.875618, -1.595877],
              [103.875144, -1.595917],
              [103.874762, -1.59596],
              [103.874118, -1.596021],
              [103.873479, -1.596065],
              [103.87303, -1.596123],
              [103.873036, -1.595875],
              [103.873032, -1.595675],
              [103.873035, -1.595397],
              [103.873031, -1.59513],
              [103.873027, -1.594822],
              [103.873025, -1.594582],
              [103.873027, -1.594345],
              [103.873027, -1.594103],
              [103.873027, -1.594103],
              [103.873475, -1.594102],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D2",
        Luas: 28.3,
        POKOK: "4158",
        SPH: "146",
        POKOK0: "26",
        POKOK1: "4184",
        SPH_2: "147",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.87303, -1.593508],
              [103.873025, -1.593187],
              [103.873027, -1.592887],
              [103.873026, -1.592591],
              [103.873026, -1.592369],
              [103.873026, -1.591999],
              [103.873023, -1.591628],
              [103.873026, -1.591323],
              [103.873357, -1.591317],
              [103.873853, -1.591323],
              [103.874184, -1.591324],
              [103.874705, -1.591326],
              [103.875202, -1.591326],
              [103.875536, -1.591326],
              [103.876033, -1.591326],
              [103.87637, -1.591327],
              [103.876733, -1.591323],
              [103.877074, -1.591327],
              [103.877441, -1.591328],
              [103.877946, -1.591326],
              [103.878286, -1.591327],
              [103.87881, -1.591331],
              [103.879326, -1.591327],
              [103.879679, -1.591329],
              [103.880098, -1.591331],
              [103.880604, -1.591331],
              [103.881106, -1.591331],
              [103.881437, -1.591331],
              [103.881629, -1.591336],
              [103.881633, -1.591631],
              [103.881632, -1.591863],
              [103.881634, -1.592094],
              [103.881629, -1.592326],
              [103.88163, -1.592561],
              [103.881629, -1.592874],
              [103.881629, -1.5931],
              [103.881624, -1.593335],
              [103.881624, -1.593558],
              [103.881624, -1.593781],
              [103.881624, -1.593929],
              [103.881622, -1.593993],
              [103.880365, -1.593987],
              [103.879834, -1.593992],
              [103.879138, -1.593997],
              [103.878477, -1.593997],
              [103.87796, -1.593999],
              [103.877082, -1.593993],
              [103.876402, -1.593998],
              [103.875908, -1.593993],
              [103.875412, -1.593993],
              [103.875061, -1.594007],
              [103.874955, -1.594018],
              [103.874656, -1.594016],
              [103.873977, -1.594016],
              [103.873479, -1.594015],
              [103.87303, -1.59401],
              [103.87303, -1.59401],
              [103.87303, -1.593508],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F2",
        Luas: 30.6,
        POKOK: "2801",
        SPH: "91",
        POKOK0: "1648",
        POKOK1: "4449",
        SPH_2: "144",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.890718, -1.594466],
              [103.890713, -1.594214],
              [103.89071, -1.593995],
              [103.89071, -1.593773],
              [103.89071, -1.593403],
              [103.89071, -1.593101],
              [103.89071, -1.592805],
              [103.890716, -1.592231],
              [103.890728, -1.591557],
              [103.890728, -1.591371],
              [103.890812, -1.59136],
              [103.89113, -1.59135],
              [103.891638, -1.591354],
              [103.892151, -1.591354],
              [103.892658, -1.591355],
              [103.893085, -1.591358],
              [103.893458, -1.591369],
              [103.894293, -1.591364],
              [103.894624, -1.591364],
              [103.894956, -1.591364],
              [103.895285, -1.591363],
              [103.895782, -1.591363],
              [103.896278, -1.591363],
              [103.896775, -1.591369],
              [103.897435, -1.591376],
              [103.898096, -1.591376],
              [103.898757, -1.591385],
              [103.899414, -1.591389],
              [103.900072, -1.591386],
              [103.90012, -1.591532],
              [103.900143, -1.591768],
              [103.900211, -1.592189],
              [103.900247, -1.592503],
              [103.900284, -1.592912],
              [103.900364, -1.593484],
              [103.900268, -1.593522],
              [103.899926, -1.593556],
              [103.899637, -1.593581],
              [103.899437, -1.593603],
              [103.899131, -1.593631],
              [103.898794, -1.593662],
              [103.898563, -1.593679],
              [103.898223, -1.593709],
              [103.897913, -1.593737],
              [103.897476, -1.593779],
              [103.897107, -1.593807],
              [103.896755, -1.593842],
              [103.896414, -1.593877],
              [103.896126, -1.593898],
              [103.895665, -1.593952],
              [103.895309, -1.593987],
              [103.895145, -1.594003],
              [103.894819, -1.594028],
              [103.894543, -1.594074],
              [103.894085, -1.594128],
              [103.893717, -1.59417],
              [103.893311, -1.594207],
              [103.892505, -1.594299],
              [103.892168, -1.594328],
              [103.89193, -1.594353],
              [103.891677, -1.594376],
              [103.891392, -1.594407],
              [103.89134, -1.594416],
              [103.891241, -1.594413],
              [103.891028, -1.594428],
              [103.890835, -1.59445],
              [103.890718, -1.594466],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E2",
        Luas: 28.8,
        POKOK: "4131",
        SPH: "143",
        POKOK0: "146",
        POKOK1: "4277",
        SPH_2: "148",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881718, -1.591612],
              [103.881714, -1.591326],
              [103.881714, -1.591326],
              [103.881737, -1.59132],
              [103.881851, -1.591323],
              [103.881868, -1.591331],
              [103.881885, -1.59135],
              [103.881916, -1.591367],
              [103.881951, -1.591379],
              [103.88198, -1.591374],
              [103.882019, -1.591358],
              [103.882042, -1.591344],
              [103.882083, -1.591335],
              [103.882116, -1.591333],
              [103.882345, -1.591338],
              [103.882413, -1.591338],
              [103.882923, -1.591338],
              [103.883457, -1.591333],
              [103.883954, -1.591336],
              [103.8845, -1.591334],
              [103.885008, -1.591336],
              [103.885525, -1.591341],
              [103.886028, -1.591343],
              [103.886524, -1.591345],
              [103.88704, -1.591347],
              [103.887549, -1.59135],
              [103.888049, -1.591348],
              [103.888627, -1.591353],
              [103.889179, -1.591358],
              [103.889773, -1.591361],
              [103.890494, -1.591359],
              [103.890616, -1.591361],
              [103.890616, -1.591678],
              [103.890616, -1.592053],
              [103.890614, -1.592369],
              [103.890619, -1.59267],
              [103.890624, -1.593116],
              [103.890619, -1.593564],
              [103.890621, -1.593958],
              [103.890003, -1.59396],
              [103.889495, -1.59396],
              [103.888954, -1.593962],
              [103.888436, -1.593967],
              [103.887927, -1.59397],
              [103.887422, -1.593971],
              [103.886908, -1.593976],
              [103.885915, -1.593976],
              [103.885241, -1.593976],
              [103.884719, -1.593978],
              [103.884001, -1.593986],
              [103.883397, -1.593982],
              [103.882292, -1.593983],
              [103.881707, -1.593982],
              [103.881708, -1.593876],
              [103.881709, -1.59364],
              [103.881712, -1.593342],
              [103.881712, -1.592972],
              [103.881712, -1.592675],
              [103.881714, -1.592298],
              [103.881716, -1.591995],
              [103.881718, -1.591612],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E3",
        Luas: 28.8,
        POKOK: "4237",
        SPH: "147",
        POKOK0: "49",
        POKOK1: "4286",
        SPH_2: "148",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881738, -1.588729],
              [103.881746, -1.588565],
              [103.881746, -1.588565],
              [103.881761, -1.588562],
              [103.8819, -1.588566],
              [103.882396, -1.588571],
              [103.882892, -1.588571],
              [103.883554, -1.588573],
              [103.884068, -1.588579],
              [103.884401, -1.588581],
              [103.8849, -1.588584],
              [103.8854, -1.588586],
              [103.885908, -1.58859],
              [103.886414, -1.588594],
              [103.886919, -1.588602],
              [103.887416, -1.588603],
              [103.887912, -1.588603],
              [103.888413, -1.588607],
              [103.888933, -1.588614],
              [103.889467, -1.588619],
              [103.889903, -1.588621],
              [103.890236, -1.588625],
              [103.890628, -1.588632],
              [103.890634, -1.588823],
              [103.890634, -1.589046],
              [103.890626, -1.589278],
              [103.890625, -1.589431],
              [103.890625, -1.589727],
              [103.890621, -1.590086],
              [103.890621, -1.590406],
              [103.890618, -1.590659],
              [103.890628, -1.590937],
              [103.890628, -1.591196],
              [103.890615, -1.591269],
              [103.889885, -1.591263],
              [103.88955, -1.591268],
              [103.889053, -1.591258],
              [103.888555, -1.591258],
              [103.88805, -1.591253],
              [103.887372, -1.591258],
              [103.88686, -1.591252],
              [103.886198, -1.591253],
              [103.885696, -1.591253],
              [103.885198, -1.591254],
              [103.88468, -1.591252],
              [103.884164, -1.591249],
              [103.883632, -1.591245],
              [103.883238, -1.591243],
              [103.882639, -1.591244],
              [103.882282, -1.591239],
              [103.882118, -1.590964],
              [103.881984, -1.590724],
              [103.881823, -1.590434],
              [103.881731, -1.590251],
              [103.881734, -1.590166],
              [103.881734, -1.590014],
              [103.881735, -1.589752],
              [103.881735, -1.589493],
              [103.881736, -1.589305],
              [103.881737, -1.589187],
              [103.881737, -1.589071],
              [103.881735, -1.588957],
              [103.881735, -1.588843],
              [103.881738, -1.588729],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F3",
        Luas: 29.5,
        POKOK: "3033",
        SPH: "102",
        POKOK0: "1372",
        POKOK1: "4405",
        SPH_2: "149",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.890786, -1.588644],
              [103.890808, -1.588629],
              [103.891394, -1.588621],
              [103.891754, -1.588623],
              [103.892091, -1.588631],
              [103.892442, -1.588629],
              [103.892782, -1.58863],
              [103.893121, -1.588635],
              [103.893466, -1.588632],
              [103.893833, -1.588635],
              [103.89422, -1.588637],
              [103.894567, -1.588635],
              [103.894929, -1.588637],
              [103.895271, -1.588644],
              [103.89561, -1.588646],
              [103.895952, -1.588647],
              [103.896356, -1.588647],
              [103.896741, -1.588655],
              [103.897239, -1.588658],
              [103.897602, -1.588662],
              [103.897801, -1.588672],
              [103.898052, -1.588666],
              [103.898395, -1.588671],
              [103.898728, -1.588678],
              [103.899209, -1.588678],
              [103.899312, -1.588678],
              [103.899528, -1.588687],
              [103.89973, -1.588695],
              [103.899796, -1.58869],
              [103.899807, -1.588868],
              [103.899815, -1.589028],
              [103.899836, -1.589263],
              [103.899854, -1.589494],
              [103.899874, -1.589751],
              [103.899901, -1.58998],
              [103.89993, -1.590219],
              [103.899954, -1.590435],
              [103.899969, -1.590606],
              [103.899991, -1.590828],
              [103.900022, -1.59127],
              [103.899564, -1.591266],
              [103.899302, -1.591256],
              [103.899046, -1.591259],
              [103.89887, -1.59126],
              [103.898623, -1.591255],
              [103.898334, -1.591255],
              [103.898085, -1.591255],
              [103.897684, -1.591248],
              [103.89736, -1.591248],
              [103.89719, -1.591248],
              [103.896789, -1.591253],
              [103.896578, -1.591247],
              [103.896233, -1.591249],
              [103.896005, -1.591247],
              [103.895674, -1.591245],
              [103.895179, -1.591243],
              [103.894683, -1.591248],
              [103.894351, -1.591248],
              [103.894021, -1.591242],
              [103.893691, -1.591243],
              [103.893383, -1.59125],
              [103.893217, -1.59125],
              [103.892885, -1.59125],
              [103.892055, -1.59126],
              [103.891552, -1.59125],
              [103.891222, -1.591249],
              [103.890704, -1.591249],
              [103.890703, -1.590938],
              [103.890703, -1.590608],
              [103.890703, -1.590306],
              [103.890703, -1.590082],
              [103.890705, -1.58993],
              [103.890705, -1.589708],
              [103.890708, -1.589408],
              [103.890709, -1.589109],
              [103.890707, -1.588872],
              [103.890704, -1.588639],
              [103.890704, -1.588639],
              [103.890786, -1.588644],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F4",
        Luas: 28.9,
        POKOK: "3014",
        SPH: "104",
        POKOK0: "1181",
        POKOK1: "4195",
        SPH_2: "144",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.890709, -1.588373],
              [103.890707, -1.588034],
              [103.890703, -1.587721],
              [103.890703, -1.587422],
              [103.890704, -1.587109],
              [103.890705, -1.586856],
              [103.890703, -1.586557],
              [103.890707, -1.586329],
              [103.890708, -1.586101],
              [103.89071, -1.585919],
              [103.89081, -1.585917],
              [103.891057, -1.585912],
              [103.891418, -1.585913],
              [103.891767, -1.585921],
              [103.892109, -1.585931],
              [103.89245, -1.585934],
              [103.892781, -1.585934],
              [103.892954, -1.585939],
              [103.893129, -1.585942],
              [103.893349, -1.585943],
              [103.893585, -1.585943],
              [103.89393, -1.585945],
              [103.894332, -1.585945],
              [103.89468, -1.585949],
              [103.895021, -1.58595],
              [103.895376, -1.585954],
              [103.895728, -1.58596],
              [103.896063, -1.585958],
              [103.89654, -1.585969],
              [103.89693, -1.585975],
              [103.897267, -1.58598],
              [103.897609, -1.585986],
              [103.897945, -1.58599],
              [103.898281, -1.585994],
              [103.898617, -1.586],
              [103.899002, -1.58601],
              [103.899327, -1.58601],
              [103.899574, -1.586005],
              [103.899585, -1.586092],
              [103.899595, -1.58626],
              [103.899617, -1.5865],
              [103.899638, -1.586655],
              [103.899646, -1.586814],
              [103.899665, -1.586975],
              [103.899669, -1.587062],
              [103.899674, -1.587136],
              [103.899696, -1.587296],
              [103.899714, -1.587458],
              [103.899722, -1.587652],
              [103.899737, -1.587896],
              [103.899754, -1.588148],
              [103.899767, -1.588403],
              [103.899753, -1.588553],
              [103.899709, -1.58859],
              [103.899389, -1.5886],
              [103.89886, -1.588598],
              [103.898282, -1.5886],
              [103.897784, -1.588597],
              [103.897282, -1.588595],
              [103.896775, -1.588594],
              [103.896258, -1.588583],
              [103.895914, -1.588582],
              [103.895413, -1.588581],
              [103.894915, -1.588581],
              [103.894414, -1.588577],
              [103.893901, -1.588573],
              [103.893453, -1.588561],
              [103.893103, -1.588561],
              [103.892607, -1.588555],
              [103.892275, -1.588558],
              [103.891766, -1.588557],
              [103.891433, -1.588551],
              [103.8911, -1.588551],
              [103.890709, -1.588551],
              [103.890709, -1.588551],
              [103.890709, -1.588373],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E5",
        Luas: 32.0,
        POKOK: "4502",
        SPH: "140",
        POKOK0: "302",
        POKOK1: "4804",
        SPH_2: "150",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881974, -1.582821],
              [103.882338, -1.582822],
              [103.882689, -1.582816],
              [103.883092, -1.582818],
              [103.883447, -1.582818],
              [103.883811, -1.582816],
              [103.884182, -1.582811],
              [103.88453, -1.582814],
              [103.884872, -1.582816],
              [103.885377, -1.582815],
              [103.885718, -1.582816],
              [103.886066, -1.582813],
              [103.886409, -1.58281],
              [103.886745, -1.58281],
              [103.887086, -1.582811],
              [103.887419, -1.582813],
              [103.887749, -1.582813],
              [103.888085, -1.582814],
              [103.888428, -1.582815],
              [103.888768, -1.58281],
              [103.889105, -1.582808],
              [103.889443, -1.58281],
              [103.889792, -1.582816],
              [103.890125, -1.582816],
              [103.890465, -1.582815],
              [103.890597, -1.582818],
              [103.890595, -1.583057],
              [103.890592, -1.583304],
              [103.890588, -1.58354],
              [103.890588, -1.583762],
              [103.890588, -1.583984],
              [103.890588, -1.584207],
              [103.890583, -1.58445],
              [103.890584, -1.584676],
              [103.890586, -1.584824],
              [103.890586, -1.584972],
              [103.890586, -1.585121],
              [103.890587, -1.585269],
              [103.890587, -1.585417],
              [103.890587, -1.585716],
              [103.890587, -1.585818],
              [103.890209, -1.585813],
              [103.889595, -1.585808],
              [103.889078, -1.585797],
              [103.888728, -1.58579],
              [103.888565, -1.585782],
              [103.888399, -1.585782],
              [103.88788, -1.585782],
              [103.887535, -1.585774],
              [103.887338, -1.585765],
              [103.88699, -1.585769],
              [103.886491, -1.585769],
              [103.885961, -1.585764],
              [103.885465, -1.585747],
              [103.885246, -1.585748],
              [103.884732, -1.585748],
              [103.884382, -1.585743],
              [103.883721, -1.585734],
              [103.883681, -1.585731],
              [103.883304, -1.585726],
              [103.882955, -1.585716],
              [103.882273, -1.585713],
              [103.88176, -1.585711],
              [103.88176, -1.58494],
              [103.881759, -1.58442],
              [103.881759, -1.583902],
              [103.881761, -1.583602],
              [103.881765, -1.583303],
              [103.881769, -1.583079],
              [103.881761, -1.582982],
              [103.881765, -1.582869],
              [103.881772, -1.582829],
              [103.881772, -1.582829],
              [103.881974, -1.582821],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E6",
        Luas: 29.8,
        POKOK: "2715",
        SPH: "91",
        POKOK0: "1763",
        POKOK1: "4478",
        SPH_2: "149",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881772, -1.582264],
              [103.881775, -1.581891],
              [103.881775, -1.581595],
              [103.881775, -1.581373],
              [103.881779, -1.581144],
              [103.881787, -1.580782],
              [103.881787, -1.580486],
              [103.881791, -1.580261],
              [103.881811, -1.580028],
              [103.881864, -1.580012],
              [103.882127, -1.579992],
              [103.882318, -1.579992],
              [103.882691, -1.57999],
              [103.883039, -1.57999],
              [103.883568, -1.579988],
              [103.884104, -1.579985],
              [103.884641, -1.579985],
              [103.885021, -1.57998],
              [103.885363, -1.579984],
              [103.885989, -1.579982],
              [103.886545, -1.579984],
              [103.887116, -1.579972],
              [103.887638, -1.579974],
              [103.888152, -1.579972],
              [103.888669, -1.579971],
              [103.889177, -1.579971],
              [103.889694, -1.579971],
              [103.8902, -1.579971],
              [103.890532, -1.57997],
              [103.890604, -1.579969],
              [103.890598, -1.580205],
              [103.890598, -1.580501],
              [103.890599, -1.580736],
              [103.890599, -1.581032],
              [103.890599, -1.581328],
              [103.890599, -1.581625],
              [103.890599, -1.581921],
              [103.890599, -1.582217],
              [103.890599, -1.582588],
              [103.890598, -1.582692],
              [103.890578, -1.58272],
              [103.890513, -1.582726],
              [103.890176, -1.582736],
              [103.889846, -1.582736],
              [103.889325, -1.582734],
              [103.888935, -1.582735],
              [103.888431, -1.582737],
              [103.888099, -1.582737],
              [103.887601, -1.582736],
              [103.887239, -1.582735],
              [103.886732, -1.582731],
              [103.886387, -1.582731],
              [103.885889, -1.582731],
              [103.885393, -1.582731],
              [103.884899, -1.582732],
              [103.884572, -1.582726],
              [103.884177, -1.582727],
              [103.883846, -1.582727],
              [103.883515, -1.582727],
              [103.883184, -1.582727],
              [103.882853, -1.582727],
              [103.882687, -1.582727],
              [103.882522, -1.582727],
              [103.882191, -1.582727],
              [103.881772, -1.582731],
              [103.881771, -1.582719],
              [103.881771, -1.582719],
              [103.881772, -1.582264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E2",
        Luas: 9.2,
        POKOK: "1189",
        SPH: "128",
        POKOK0: "153",
        POKOK1: "1342",
        SPH_2: "144",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.890615, -1.59405],
              [103.890604, -1.594466],
              [103.890302, -1.59451],
              [103.889922, -1.594554],
              [103.889403, -1.594603],
              [103.888849, -1.594652],
              [103.888507, -1.594688],
              [103.887963, -1.594731],
              [103.887576, -1.594777],
              [103.886988, -1.594827],
              [103.886893, -1.594844],
              [103.88655, -1.594867],
              [103.886168, -1.594907],
              [103.885849, -1.594934],
              [103.885292, -1.594998],
              [103.88489, -1.595029],
              [103.884169, -1.595076],
              [103.8838, -1.594502],
              [103.883577, -1.594157],
              [103.883536, -1.594072],
              [103.884083, -1.594067],
              [103.884494, -1.594065],
              [103.884884, -1.594062],
              [103.885232, -1.594061],
              [103.885646, -1.59406],
              [103.886189, -1.594057],
              [103.886549, -1.594057],
              [103.887075, -1.594057],
              [103.887582, -1.594053],
              [103.887921, -1.594051],
              [103.888439, -1.594052],
              [103.888779, -1.594052],
              [103.888959, -1.59405],
              [103.889477, -1.594042],
              [103.889813, -1.594045],
              [103.89016, -1.594041],
              [103.890493, -1.594042],
              [103.890615, -1.59405],
            ],
          ],
          [
            [
              [103.884099, -1.595059],
              [103.884091, -1.595073],
              [103.884074, -1.595086],
              [103.883922, -1.595113],
              [103.883546, -1.595149],
              [103.88316, -1.595193],
              [103.88282, -1.595224],
              [103.882687, -1.59523],
              [103.882352, -1.595266],
              [103.882218, -1.59527],
              [103.881971, -1.595301],
              [103.881718, -1.595319],
              [103.881702, -1.595286],
              [103.881698, -1.59512],
              [103.881701, -1.594845],
              [103.881702, -1.594682],
              [103.881703, -1.594445],
              [103.881704, -1.594142],
              [103.881713, -1.594086],
              [103.881863, -1.594081],
              [103.882139, -1.594077],
              [103.882481, -1.594074],
              [103.882815, -1.594076],
              [103.882987, -1.594074],
              [103.883337, -1.594073],
              [103.883453, -1.594071],
              [103.883528, -1.594147],
              [103.883653, -1.594329],
              [103.883752, -1.594486],
              [103.883873, -1.594699],
              [103.88404, -1.594954],
              [103.884095, -1.595036],
              [103.884099, -1.595059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D5",
        Luas: 10.5,
        POKOK: "1196",
        SPH: "113",
        POKOK0: "360",
        POKOK1: "1556",
        SPH_2: "147",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881691, -1.582842],
              [103.881689, -1.583219],
              [103.881679, -1.583902],
              [103.881681, -1.584362],
              [103.881677, -1.585128],
              [103.881677, -1.585701],
              [103.881263, -1.585705],
              [103.880571, -1.585698],
              [103.879418, -1.585672],
              [103.879081, -1.585682],
              [103.878701, -1.584921],
              [103.878651, -1.584738],
              [103.878649, -1.584629],
              [103.87861, -1.584178],
              [103.878612, -1.58353],
              [103.878623, -1.582996],
              [103.878634, -1.582964],
              [103.878701, -1.582836],
              [103.878699, -1.58281],
              [103.87894, -1.582816],
              [103.879445, -1.582816],
              [103.879954, -1.582816],
              [103.880469, -1.582822],
              [103.880785, -1.582824],
              [103.881061, -1.58282],
              [103.88134, -1.582819],
              [103.881655, -1.582815],
              [103.881689, -1.582814],
              [103.881691, -1.582842],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F5",
        Luas: 9.6,
        POKOK: "820",
        SPH: "84",
        POKOK0: "559",
        POKOK1: "1379",
        SPH_2: "141",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.890698811641386, -1.584370735282311],
              [103.895936903646103, -1.584399374115718],
              [103.896003, -1.5859],
              [103.895495, -1.585894],
              [103.895381, -1.58589],
              [103.895334, -1.585877],
              [103.895016, -1.585876],
              [103.894586, -1.585887],
              [103.894086, -1.585881],
              [103.893594, -1.585875],
              [103.893098, -1.58587],
              [103.892433, -1.585866],
              [103.891941, -1.585859],
              [103.891449, -1.585854],
              [103.890955, -1.585852],
              [103.89071, -1.585845],
              [103.890712, -1.585377],
              [103.890713, -1.585134],
              [103.890715, -1.584811],
              [103.890698811641386, -1.584370735282311],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D4",
        Luas: 15.9,
        POKOK: "2965",
        SPH: "124",
        POKOK0: "586",
        POKOK1: "3551",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.875849640401583, -1.587229763628039],
              [103.875820028957094, -1.585746930992415],
              [103.876023622963473, -1.585745926391111],
              [103.876289016929235, -1.585739021347931],
              [103.876646016929186, -1.585743021347936],
              [103.876978016929201, -1.585755021347963],
              [103.877301016929152, -1.585758021347944],
              [103.877634016929179, -1.585759021347929],
              [103.877965016929181, -1.58576502134797],
              [103.878294016929146, -1.585769021347971],
              [103.87858701692916, -1.585776021347939],
              [103.878618016929153, -1.58580002134792],
              [103.878786016929169, -1.586066021347944],
              [103.879008016929163, -1.586443021347941],
              [103.879071016929203, -1.586534021347936],
              [103.879133016929174, -1.586680021347977],
              [103.879250016929177, -1.586865021347924],
              [103.879364016929173, -1.587055021347958],
              [103.879461016929199, -1.587246021347948],
              [103.879513016929138, -1.58735902134794],
              [103.879624016929171, -1.587498021347948],
              [103.87969001692916, -1.587610021347926],
              [103.879718016929203, -1.587679021347952],
              [103.879806016929223, -1.58785302134797],
              [103.879911016929185, -1.588052021347948],
              [103.879944016929187, -1.588145021347963],
              [103.879996016929155, -1.588243021347941],
              [103.88009701692917, -1.588426021347966],
              [103.880122016929178, -1.588471021347921],
              [103.8797870169292, -1.588477021347973],
              [103.879248016929154, -1.588476021347974],
              [103.878582016929172, -1.588467021347894],
              [103.8780870169292, -1.588464021347923],
              [103.877425016929166, -1.588464021347914],
              [103.876925016929164, -1.588460021347923],
              [103.876594016929189, -1.588460021347971],
              [103.876264016929142, -1.588457021347937],
              [103.875933016929167, -1.588457021347966],
              [103.87559201692919, -1.588458021347951],
              [103.875092016929159, -1.588448021347905],
              [103.874928016929175, -1.58844702134797],
              [103.874423016929157, -1.588441021347957],
              [103.87392801692917, -1.58843702134794],
              [103.873431016929175, -1.588434021347962],
              [103.873062016929197, -1.588434021347966],
              [103.873062016929154, -1.588306021347956],
              [103.873065016929175, -1.588084021347952],
              [103.873065016929203, -1.587861021347948],
              [103.873070016929191, -1.587635021347915],
              [103.873051074561744, -1.587244241916344],
              [103.875849640401583, -1.587229763628039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E4",
        Luas: 28.9,
        POKOK: "4159",
        SPH: "147",
        POKOK0: "47",
        POKOK1: "4206",
        SPH_2: "149",
        TANAM: "2016",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881747, -1.58801],
              [103.881759, -1.586824],
              [103.881756, -1.586705],
              [103.881753, -1.586299],
              [103.881753, -1.585988],
              [103.88175, -1.585872],
              [103.881755, -1.585798],
              [103.881899, -1.585797],
              [103.882231, -1.585797],
              [103.882397, -1.5858],
              [103.882615, -1.585799],
              [103.88279, -1.585797],
              [103.883048, -1.58581],
              [103.883299, -1.585813],
              [103.883547, -1.585813],
              [103.883874, -1.585815],
              [103.884389, -1.585828],
              [103.884643, -1.585829],
              [103.884743, -1.585834],
              [103.885074, -1.585834],
              [103.885572, -1.58584],
              [103.886072, -1.585844],
              [103.886403, -1.585844],
              [103.886725, -1.585849],
              [103.887055, -1.585857],
              [103.887159, -1.58586],
              [103.887196, -1.585857],
              [103.88728, -1.585853],
              [103.887418, -1.585857],
              [103.888066, -1.58587],
              [103.888854, -1.585882],
              [103.889469, -1.585895],
              [103.889876, -1.585906],
              [103.890316, -1.585906],
              [103.890584, -1.585908],
              [103.890579, -1.586257],
              [103.890579, -1.586565],
              [103.890587, -1.586943],
              [103.890583, -1.587326],
              [103.890586, -1.587731],
              [103.890589, -1.587995],
              [103.890598, -1.58832],
              [103.890598, -1.588519],
              [103.890571, -1.588544],
              [103.8902, -1.588532],
              [103.889369, -1.588529],
              [103.888542, -1.588529],
              [103.887715, -1.58853],
              [103.887207, -1.588515],
              [103.88669, -1.588514],
              [103.886192, -1.588514],
              [103.885528, -1.588509],
              [103.885025, -1.588508],
              [103.884341, -1.588493],
              [103.883804, -1.588489],
              [103.882764, -1.588487],
              [103.882474, -1.588487],
              [103.882184, -1.588482],
              [103.881956, -1.588483],
              [103.881754, -1.588479],
              [103.881745, -1.588421],
              [103.881743, -1.588348],
              [103.881748, -1.588194],
              [103.881747, -1.58801],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "C2",
        Luas: 29.4,
        POKOK: "4307",
        SPH: "146",
        POKOK0: "70",
        POKOK1: "4377",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.863778, -1.594045],
              [103.86379, -1.593782],
              [103.8638, -1.593461],
              [103.863804, -1.59315],
              [103.863809, -1.592843],
              [103.863809, -1.592546],
              [103.863816, -1.592405],
              [103.863821, -1.592033],
              [103.863827, -1.591734],
              [103.863835, -1.591513],
              [103.863842, -1.591294],
              [103.864092, -1.591294],
              [103.864446, -1.591292],
              [103.864778, -1.591292],
              [103.86511, -1.591292],
              [103.865446, -1.591294],
              [103.865787, -1.591296],
              [103.865954, -1.591296],
              [103.866126, -1.5913],
              [103.866296, -1.5913],
              [103.866612, -1.5913],
              [103.866943, -1.5913],
              [103.867108, -1.5913],
              [103.867439, -1.591301],
              [103.867736, -1.591306],
              [103.868067, -1.591306],
              [103.868398, -1.591306],
              [103.868729, -1.59131],
              [103.86906, -1.59131],
              [103.869307, -1.591318],
              [103.869304, -1.591815],
              [103.871155, -1.591819],
              [103.871167, -1.591327],
              [103.871167, -1.59132],
              [103.871476, -1.591308],
              [103.871826, -1.591308],
              [103.872322, -1.591316],
              [103.872653, -1.591316],
              [103.872935, -1.591322],
              [103.872927, -1.591565],
              [103.872925, -1.591869],
              [103.872925, -1.592168],
              [103.872927, -1.592488],
              [103.872926, -1.592717],
              [103.872923, -1.592946],
              [103.872922, -1.59318],
              [103.872922, -1.593406],
              [103.872922, -1.593629],
              [103.872922, -1.593977],
              [103.872922, -1.594019],
              [103.870843, -1.594023],
              [103.86985, -1.594023],
              [103.869024, -1.59403],
              [103.86849, -1.594032],
              [103.867828, -1.594032],
              [103.867332, -1.594032],
              [103.86667, -1.594036],
              [103.866174, -1.594036],
              [103.865512, -1.594036],
              [103.86485, -1.594037],
              [103.864186, -1.59404],
              [103.863778, -1.594045],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D3",
        Luas: 28.7,
        POKOK: "4142",
        SPH: "144",
        POKOK0: "135",
        POKOK1: "4277",
        SPH_2: "149",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.882121, -1.591231],
              [103.882117, -1.591245],
              [103.88165, -1.591244],
              [103.881318, -1.591243],
              [103.88123, -1.591243],
              [103.881143, -1.591252],
              [103.880607, -1.591252],
              [103.880245, -1.591257],
              [103.879907, -1.591255],
              [103.879808, -1.591252],
              [103.879679, -1.59126],
              [103.879503, -1.591249],
              [103.879232, -1.591243],
              [103.878563, -1.591245],
              [103.878057, -1.591247],
              [103.877557, -1.591249],
              [103.877211, -1.591247],
              [103.876684, -1.591243],
              [103.876339, -1.591242],
              [103.875836, -1.59124],
              [103.875503, -1.591242],
              [103.87517, -1.591242],
              [103.874829, -1.591239],
              [103.87432, -1.59124],
              [103.873901, -1.591238],
              [103.873033, -1.591217],
              [103.873028, -1.591052],
              [103.873026, -1.590901],
              [103.873021, -1.590753],
              [103.873022, -1.590602],
              [103.873023, -1.590367],
              [103.873021, -1.59013],
              [103.873027, -1.58997],
              [103.873031, -1.589811],
              [103.873031, -1.589661],
              [103.873028, -1.589438],
              [103.873031, -1.589213],
              [103.87303, -1.588915],
              [103.873033, -1.588624],
              [103.873039, -1.588521],
              [103.873068, -1.58852],
              [103.873162, -1.58852],
              [103.873662, -1.588521],
              [103.87416, -1.588524],
              [103.874656, -1.588524],
              [103.875155, -1.588524],
              [103.875714, -1.588528],
              [103.87612, -1.588546],
              [103.876477, -1.58854],
              [103.877006, -1.588539],
              [103.877531, -1.58855],
              [103.877897, -1.588557],
              [103.878228, -1.588552],
              [103.878745, -1.588556],
              [103.879532, -1.588568],
              [103.880103, -1.588556],
              [103.880157, -1.588563],
              [103.880377, -1.588578],
              [103.880618, -1.588569],
              [103.880692, -1.588697],
              [103.880803, -1.588914],
              [103.880983, -1.589257],
              [103.881197, -1.589625],
              [103.881501, -1.590186],
              [103.881717, -1.590579],
              [103.881944, -1.590982],
              [103.882066, -1.59118],
              [103.882102, -1.591197],
              [103.882121, -1.591231],
            ],
          ],
          [
            [
              [103.88166, -1.589477],
              [103.881654, -1.589921],
              [103.881643, -1.590089],
              [103.88143, -1.589719],
              [103.881285, -1.589462],
              [103.881167, -1.589249],
              [103.881033, -1.589012],
              [103.880907, -1.588776],
              [103.880791, -1.588566],
              [103.88165, -1.588567],
              [103.881653, -1.589033],
              [103.88166, -1.589477],
            ],
          ],
          [
            [
              [103.882198, -1.591232],
              [103.882165, -1.591249],
              [103.881822, -1.590678],
              [103.880987, -1.589159],
              [103.880865, -1.588956],
              [103.880693, -1.588656],
              [103.880768, -1.588625],
              [103.880766, -1.588632],
              [103.881082, -1.589229],
              [103.881661, -1.590251],
              [103.882014, -1.590889],
              [103.882198, -1.591232],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D5",
        Luas: 5.5,
        POKOK: "602",
        SPH: "108",
        POKOK0: "192",
        POKOK1: "794",
        SPH_2: "142",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.88169, -1.582692],
              [103.881688, -1.582732],
              [103.881419, -1.582736],
              [103.881024, -1.582747],
              [103.880324, -1.58274],
              [103.87948, -1.582739],
              [103.878965, -1.58273],
              [103.878905, -1.58273],
              [103.878979, -1.582423],
              [103.879097, -1.582252],
              [103.879256, -1.582104],
              [103.880513, -1.581088],
              [103.880898, -1.58075],
              [103.881129, -1.580552],
              [103.881161, -1.580525],
              [103.881197, -1.58049],
              [103.881286, -1.580415],
              [103.881345, -1.580367],
              [103.881439, -1.580283],
              [103.881578, -1.580165],
              [103.881666, -1.580105],
              [103.881672, -1.580435],
              [103.8817, -1.580604],
              [103.881676, -1.580696],
              [103.881698, -1.581348],
              [103.881688, -1.582378],
              [103.88169, -1.582692],
            ],
          ],
          [
            [
              [103.881593, -1.580104],
              [103.881035, -1.580582],
              [103.880848, -1.580735],
              [103.880617, -1.580935],
              [103.879911, -1.581526],
              [103.879078, -1.582232],
              [103.878969, -1.582366],
              [103.878899, -1.582662],
              [103.878888, -1.582728],
              [103.878854, -1.582723],
              [103.878809, -1.582715],
              [103.87881, -1.582682],
              [103.878845, -1.582486],
              [103.878882, -1.582335],
              [103.87896, -1.582192],
              [103.879245, -1.581964],
              [103.879596, -1.581668],
              [103.879961, -1.581346],
              [103.880502, -1.580916],
              [103.881045, -1.580448],
              [103.881531, -1.58004],
              [103.881579, -1.580047],
              [103.881593, -1.580104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "C4",
        Luas: 5.8,
        POKOK: "456",
        SPH: "79",
        POKOK0: "406",
        POKOK1: "862",
        SPH_2: "149",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.869881, -1.588374],
              [103.869915, -1.587059],
              [103.871785, -1.587132],
              [103.871803, -1.586391],
              [103.872923, -1.586408],
              [103.872923, -1.586483],
              [103.872923, -1.586632],
              [103.872921, -1.586789],
              [103.872923, -1.58694],
              [103.872915, -1.587093],
              [103.872914, -1.587212],
              [103.872918, -1.587609],
              [103.872925, -1.587836],
              [103.872925, -1.588067],
              [103.872925, -1.588289],
              [103.872922, -1.588406],
              [103.872494, -1.588402],
              [103.871985, -1.588392],
              [103.871447, -1.588393],
              [103.870862, -1.588388],
              [103.87001, -1.588377],
              [103.869881, -1.588374],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "C3",
        Luas: 14.0,
        POKOK: "2228",
        SPH: "122",
        POKOK0: "473",
        POKOK1: "2701",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.867417, -1.590283],
              [103.868428, -1.590237],
              [103.868512468587099, -1.590236409314749],
              [103.867417, -1.590283],
            ],
          ],
          [
            [
              [103.868512468587099, -1.590236409314749],
              [103.868739487515285, -1.590226754039861],
              [103.868714, -1.590235],
              [103.868512468587099, -1.590236409314749],
            ],
          ],
          [
            [
              [103.869298, -1.590203],
              [103.868739487515285, -1.590226754039861],
              [103.868816, -1.590202],
              [103.869298, -1.590203],
            ],
          ],
          [
            [
              [103.867417, -1.590283],
              [103.867409, -1.590677],
              [103.867417, -1.591232],
              [103.866787, -1.591219],
              [103.866046, -1.591222],
              [103.865777, -1.591224],
              [103.865508, -1.591219],
              [103.86396, -1.591205],
              [103.863866, -1.591197],
              [103.863876, -1.590592],
              [103.863881, -1.58998],
              [103.863884, -1.589824],
              [103.867237316262347, -1.589846055099473],
              [103.867381, -1.589847],
              [103.867417, -1.590283],
            ],
          ],
          [
            [
              [103.869298, -1.590203],
              [103.869287, -1.589995],
              [103.869283, -1.589798],
              [103.869281, -1.589637],
              [103.869298, -1.590203],
            ],
          ],
          [
            [
              [103.869281, -1.589637],
              [103.869302307320453, -1.588516882790064],
              [103.869530709278308, -1.588474705468645],
              [103.869915, -1.588477],
              [103.870159, -1.588472],
              [103.870848, -1.588477],
              [103.871356, -1.588478],
              [103.871982, -1.588489],
              [103.872426, -1.588487],
              [103.872947, -1.588502],
              [103.872939, -1.588774],
              [103.872937, -1.589146],
              [103.872938, -1.589522],
              [103.872928, -1.589902],
              [103.872931, -1.590296],
              [103.872931, -1.5907],
              [103.872937, -1.591194],
              [103.872927, -1.59124],
              [103.872148, -1.591233],
              [103.871377, -1.591223],
              [103.871381, -1.590264],
              [103.871374, -1.589985],
              [103.87137, -1.589579],
              [103.871357, -1.589537],
              [103.871296, -1.589529],
              [103.870866, -1.589543],
              [103.870547, -1.589547],
              [103.87012, -1.589555],
              [103.869811, -1.589567],
              [103.869557, -1.589582],
              [103.869281, -1.589637],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D5",
        Luas: 0.9,
        POKOK: "137",
        SPH: "55",
        POKOK0: "233",
        POKOK1: "370",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.87501035027735, -1.585622759618482],
              [103.875006577656436, -1.585144073641084],
              [103.875066, -1.585153],
              [103.875559, -1.585234],
              [103.875956, -1.585284],
              [103.876112, -1.585299],
              [103.876408, -1.585344],
              [103.877122, -1.585455],
              [103.877333, -1.58548],
              [103.877446, -1.58556],
              [103.877547, -1.585656],
              [103.87727, -1.585653],
              [103.876338, -1.585638],
              [103.875345, -1.585626],
              [103.87501035027735, -1.585622759618482],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "D4",
        Luas: 2.9,
        POKOK: "2965",
        SPH: "124",
        POKOK0: "586",
        POKOK1: "3551",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881451, -1.588484],
              [103.881026, -1.588485],
              [103.880813, -1.588469],
              [103.881656, -1.588018],
              [103.881658, -1.588018],
              [103.881649, -1.588343],
              [103.881643, -1.588482],
              [103.881539, -1.588483],
              [103.881451, -1.588484],
            ],
          ],
          [
            [
              [103.879644, -1.58577],
              [103.880287, -1.585774],
              [103.880455, -1.585774],
              [103.880791, -1.58578],
              [103.881128, -1.585781],
              [103.881609, -1.585794],
              [103.881668, -1.585794],
              [103.881668, -1.586211],
              [103.881219, -1.585929],
              [103.881175, -1.585952],
              [103.880986, -1.586041],
              [103.880602, -1.586181],
              [103.880277, -1.58631],
              [103.879665, -1.586575],
              [103.879523, -1.586393],
              [103.879429, -1.586246],
              [103.879213, -1.585907],
              [103.879134, -1.58577],
              [103.879644, -1.58577],
            ],
          ],
          [
            [
              [103.880486, -1.588347],
              [103.880536, -1.588459],
              [103.880164, -1.58845],
              [103.880027, -1.588235],
              [103.879916, -1.587995],
              [103.879724, -1.587638],
              [103.87967, -1.587518],
              [103.879609, -1.587414],
              [103.879536, -1.587303],
              [103.879464, -1.587181],
              [103.879379, -1.587016],
              [103.879249, -1.586799],
              [103.879152, -1.586647],
              [103.879087, -1.586511],
              [103.878965, -1.586318],
              [103.878812, -1.586064],
              [103.8786, -1.585725],
              [103.878987, -1.585715],
              [103.879087, -1.585874],
              [103.8792, -1.586097],
              [103.879331, -1.586288],
              [103.879503, -1.586598],
              [103.879694, -1.586898],
              [103.879847, -1.587167],
              [103.879864, -1.587225],
              [103.879886, -1.587308],
              [103.879918, -1.587363],
              [103.879958, -1.587393],
              [103.879974, -1.587402],
              [103.880002, -1.587454],
              [103.880023, -1.587517],
              [103.88006, -1.587554],
              [103.880086, -1.587587],
              [103.880263, -1.58792],
              [103.880413, -1.5882],
              [103.880486, -1.588347],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "B2",
        Luas: 9.6,
        POKOK: "1251",
        SPH: "65",
        POKOK0: "1608",
        POKOK1: "2859",
        SPH_2: "148",
        TANAM: "2015",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.859871793130438, -1.594033642299805],
              [103.85988549368254, -1.592677783080366],
              [103.86152571855142, -1.592517666697458],
              [103.862059111155219, -1.59249112495219],
              [103.862046736358181, -1.591297950087038],
              [103.862159, -1.591299],
              [103.863082, -1.591303],
              [103.86375, -1.591301],
              [103.863745, -1.59172],
              [103.863739, -1.592073],
              [103.863731, -1.59231],
              [103.863726, -1.59268],
              [103.863722, -1.59298],
              [103.863713, -1.593283],
              [103.863702, -1.594049],
              [103.862593, -1.594039],
              [103.861145, -1.594044],
              [103.860144, -1.594032],
              [103.859871793130438, -1.594033642299805],
            ],
          ],
        ],
      },
    },
  ],
};
