import React from "react";
import { Controller } from "react-hook-form";
import { cn } from "../../../utils/utils";
import { PatternFormat } from "react-number-format";

const FormLabel = ({ children, required, optional, secondLabel }) => {
  return (
    <p className="mb-8 flex flex-row items-center text-14 font-medium text-gray-500">
      {children}
      {required && <span className="ml-8 text-red-500"> *</span>}
      {optional && (
        <span className="ml-8 font-regular text-gray-300">(optional)</span>
      )}
      {secondLabel}
    </p>
  );
};

const FormMessage = ({ children }) => {
  return <p className="mt-4 text-14 font-medium text-red-600">{children}</p>;
};

const FormIcons = ({ leftIcon, rightIcon }) => {
  return (
    <>
      {leftIcon && <div className="absolute left-8 z-10">{leftIcon}</div>}
      {rightIcon && <div className="absolute right-16 z-10">{rightIcon}</div>}
    </>
  );
};

const InputPatternFormat = ({
  label,
  className,
  inputClassName,
  secondaryLabel,
  secondaryLabelClassName,
  name,
  control,
  required,
  errors,
  optional,
  afterOnChange,
  leftIcon,
  rightIcon,
  ...attrs
}) => {
  return (
    <div className={cn("relative", className)}>
      {label && (
        <FormLabel
          required={required}
          optional={optional}
          secondLabel={secondaryLabel}
        >
          {label}
        </FormLabel>
      )}

      <div className="relative flex w-full flex-col items-start">
        <div className="relative flex w-full flex-row items-center">
          <FormIcons leftIcon={leftIcon} rightIcon={rightIcon} />
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <PatternFormat
                  {...field}
                  onChange={(event) => {
                    const value = event.target.value;
                    field.onChange(value);
                    afterOnChange && afterOnChange(value, event, onChange);
                  }}
                  className={cn(
                    "input-border relative w-full rounded-8 font-regular text-16 text-gray-500 placeholder-gray-200",
                    errors && "border-red-400",
                    attrs.disabled &&
                      "cursor-not-allowed bg-gray-20 text-gray-300",
                    inputClassName
                  )}
                  style={{
                    WebkitBoxShadow: "0 0 0px 0px #FFFFFF",
                  }}
                  {...attrs}
                />
              );
            }}
          />
        </div>
        {errors && <FormMessage>{errors?.message}</FormMessage>}
      </div>
    </div>
  );
};

export default InputPatternFormat;
