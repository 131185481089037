import ButtonComponent from "../../atoms/ButtonComponent";
import Modal from "../../atoms/Modal";
import PropTypes from "prop-types";
import React from "react";
import { TbFileDescription } from "react-icons/tb";
import moment from "moment";
import { useAlert } from "react-alert";
import useFileUpload from "react-use-file-upload";

export const modalName = "fileUploadModal";

const FileUploadModal = ({
  isOpen,
  toggleModal,
  onSubmit: handleSubmit,
  actionButtonTitle,
}) => {
  const [file, setFile] = React.useState(null);
  const { handleDragDropEvent } = useFileUpload();
  const alert = useAlert();

  const inputRef = React.useRef();

  const submitFile = () => {
    if (file) {
      handleSubmit(file);
      toggleModal();
      setFile(null);
    } else {
      alert.error("Silahkan input document terlebih dahulu sebelum dikirimkan");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={toggleModal} title={actionButtonTitle}>
      <div className="relative flex flex-col px-24 py-16 ">
        <div
          className="bg-gray-white flex flex-col items-center justify-center rounded-8 bg-gray-30 p-42"
          onDragEnter={handleDragDropEvent}
          onDragOver={handleDragDropEvent}
          onDrop={(e) => {
            handleDragDropEvent(e);
            setFile(e.dataTransfer.files[0]);
          }}
        >
          <TbFileDescription className="mb-16 text-42 text-gray-500" />
          <p>Jatuhkan file Anda atau klik untuk mengunggah</p>

          <p className="mb-16 font-regular text-14 text-gray-500">
            Jenis file yang didukung: XLSX
          </p>

          <ButtonComponent
            value="Pilih file"
            size="sm"
            type="button"
            className="bordered-button-transition border-gray-400 hover:bg-gray-500 hover:text-white"
            onClick={() => inputRef.current.click()}
          />

          {/* Hide the crappy looking default HTML input */}
          <input
            className="font-regular text-14 text-gray-500"
            ref={inputRef}
            type="file"
            multiple={false}
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.currentTarget.files[0]);
              inputRef.current.value = null;
            }}
          />
        </div>

        {file && (
          <div className="mt-24 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <TbFileDescription className="mr-16 text-24 text-green-500" />
              <p className="font-regular text-14 text-gray-500">{file?.name}</p>
            </div>
            <p className="font-regular text-14 text-gray-400">
              {moment(file?.lastModified).format("LL")}
              {/* {moment("03-01-2023").format("LL")} */}
            </p>
          </div>
        )}
      </div>
      <div className="modal-footer-container">
        <div className="left flex flex-row items-center space-x-8">
          <ButtonComponent
            value="Batalkan"
            size="sm"
            type="button"
            border
            className="text-gray-300 hover:text-red-500"
            onClick={() => toggleModal()}
          />
          <ButtonComponent
            value={actionButtonTitle ? actionButtonTitle : "Unduh File"}
            size="sm"
            type="button"
            onClick={submitFile}
            className="button-transition"
          />
        </div>
      </div>
    </Modal>
  );
};

FileUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FileUploadModal;
