import * as yup from "yup";

export const formSchema = yup.object().shape({
	blockName: yup.string().required("nama blok diperlukan"),
	areaHa: yup.string().required("luas area (Ha) diperlukan"),
	plantedYear: yup
		.object()
		.shape({
			label: yup.string().required("Tahun tanam diperlukan"),
			value: yup.string().required("Tahun tanam diperlukan"),
		})
		.nullable()
		.required("Tahun tanam diperlukan"),
	tphCount: yup.string().required("tph diperlukan"),
	treeCount: yup.string().required("Jumlah pohon diperlukan"),
	treePerHa: yup.string().required("Jumlah Pohon Per Ha diperlukan"),
});
