import PurchaseTable from "./PurchaseTable";
export default PurchaseTable;

export const data = [
  {
    name: "Jaka",
    totalNota: 4,
    totalNetto: 35900,
    totalRp: 74133500,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 8910,
        priceAgreement: 2065,
        totalRp: 18399150,
      },
      {
        name: "Indoraya",
        totalNetto: 8660,
        priceAgreement: 2065,
        totalRp: 17882900,
      },
      {
        name: "Indoraya",
        totalNetto: 9710,
        priceAgreement: 2065,
        totalRp: 20051150,
      },
      {
        name: "Indoraya",
        totalNetto: 8620,
        priceAgreement: 2065,
        totalRp: 17800300,
      },
    ],
  },
  {
    name: "Sandi",
    totalNota: 1,
    totalNetto: 8995,
    totalRp: 18709600,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 8995,
        priceAgreement: 2080,
        totalRp: 18709600,
      },
    ],
  },
  {
    name: "Ramp Pulo",
    totalNota: 3,
    totalNetto: 16780,
    totalRp: 35427000,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 5580,
        priceAgreement: 2065,
        totalRp: 11469400,
      },
      {
        name: "Indoraya",
        totalNetto: 5580,
        priceAgreement: 2065,
        totalRp: 11469400,
      },
      {
        name: "Indoraya",
        totalNetto: 5620,
        priceAgreement: 2065,
        totalRp: 11547900,
      },
    ],
  },
  {
    name: "Ramp Mogot",
    totalNota: 1,
    totalNetto: 8680,
    totalRp: 17924000,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 8680,
        priceAgreement: 2065,
        totalRp: 17924000,
      },
    ],
  },
];
