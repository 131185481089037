import React, { createContext, useContext, useState } from "react";

const imageViewerContext = createContext(undefined);

export const ImageViewerProvider = ({ children }) => {
  const Provider = imageViewerContext.Provider;

  const [isOpenImagePreviewer, setIsOpenImagePreviewer] = useState(false);
  const [currentImageViewer, setCurrentImageViewer] = useState({});

  return (
    <Provider
      value={{
        isOpenImagePreviewer,
        setIsOpenImagePreviewer,
        currentImageViewer,
        setCurrentImageViewer,
      }}
    >
      {children}
    </Provider>
  );
};

export const useImageViewer = () => useContext(imageViewerContext);
