import {
  selectStatuses,
  selectUser,
  submitCodeVerif,
  verifEmailAddress,
} from "../../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../atoms/ButtonComponent";
import FixedAlert from "../../../atoms/Alert/AlertFixed";
import { HiMail } from "react-icons/hi";
import InputHook from "../../../atoms/InputHook";
import React from "react";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const EmailStatus = ({ refreshData }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const statusRequest = useSelector(selectStatuses);
  const user = useSelector(selectUser);

  const [isSended, setIsSended] = React.useState(false);
  const { register, handleSubmit, reset } = useForm();
  const isEmailVerified = user?.isEmailVerified;

  const sendCodeVerification = () => {
    dispatch(verifEmailAddress({ id: user.id }))
      .unwrap()
      .then((resp) => {
        if (!resp.success) {
          return alert.error(resp.message);
        }
        setIsSended(true);
        alert.success(
          "Email verifikasi telah kami kirimkan ke alamat email yang Anda daftarkan. Silahkan cek inbox atau spam folder Anda"
        );
      })
      .catch((e) => {
        setIsSended(false);
        alert.error(`Gagal mengirimkan email verifikasi ${e?.message}`);
      });
  };

  const onSubmit = (data) => {
    const code = data.code;
    if (isSended) {
      return dispatch(submitCodeVerif({ id: user.id, code: code }))
        .unwrap()
        .then((resp) => {
          if (!resp.success) {
            return alert.error(resp.message);
          }
          setIsSended(false);
          alert.success("Email adddress berhasil di verifikasi");
          refreshData();
          reset({ email: user.email });
        })
        .catch((err) => {
          alert.error(`Gagal memverifikasi email address ${err?.message}`);
        });
    }

    sendCodeVerification();
  };

  useEffect(() => {
    reset({ email: user.email });
  }, [user?.email]);

  return (
    <div
      className={`rounded-16 border-1 border-gray-10 bg-white pt-24 md:mx-32`}
    >
      <div className="flex flex-row items-center space-x-16 px-24">
        <HiMail className="text-24 text-gray-500" />
        <h1 className="text-left font-medium text-16 text-gray-500 md:text-[20px]">
          Email
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8 flex flex-col p-24">
          <div className="mb-16 flex flex-col">
            <p className="mb-16 font-regular text-14 text-gray-500">
              Perbarui alamat email Anda jika Anda perlu memulihkan akun Anda.
            </p>
            {isSended ? (
              <InputHook
                register={register}
                label="Kode Verifikasi"
                name="code"
                className="mb-0 mt-0"
                inputClassName="input-large-border"
                placeholder="Masukkan kode verifikasi"
                type="number"
              />
            ) : (
              <InputHook
                disabled
                register={register}
                name="email"
                className="mb-0 mt-0"
                inputClassName="input-large-border"
              />
            )}
          </div>

          <FixedAlert
            message={
              isEmailVerified
                ? "Email anda telah diverifikasi."
                : "Email anda belum diverifikasi."
            }
            options={{
              type: isEmailVerified ? "success" : "warning",
            }}
          />
        </div>

        {!isEmailVerified && (
          <div className="card-footer-container space-x-16">
            {isSended && (
              <ButtonComponent
                disabled={statusRequest.isRequest}
                value="Kirim ulang email verifikasi"
                size="sm"
                type="button"
                className="bordered-button-transition"
                onClick={sendCodeVerification}
              />
            )}
            <ButtonComponent
              disabled={statusRequest.isRequest}
              value={`${isSended ? "Verifikasi" : "Kirim email verifikasi"}`}
              size="sm"
              type="submit"
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default EmailStatus;
