import React, { useState } from "react";
import PaginationContainer from "./partials/PaginationContainer";
import Table from "./partials/Table";

const SalesTable = () => {
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const paginationLength = Math.ceil(page?.total / 7);

  const pageClickHandler = () => {};

  return (
    <div className="mx-8 grid h-[250px] max-h-full grid-cols-1 grid-rows-[max-content,1fr,max-content] gap-8 overflow-hidden rounded-8 border-gray-10 bg-white p-8 md:h-full">
      <h4 className="text-16 font-bold">Rekap Penjualan Hari Ini</h4>
      <Table />
      <PaginationContainer
        length={paginationLength}
        current={page.current}
        total={page.total}
        onPageClick={pageClickHandler}
      />
    </div>
  );
};

export default SalesTable;
