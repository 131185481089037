import React from "react";
import { parseToFloat } from "../../../../../../../../../helpers/Common";

const TableRow = ({ data }) => {
  return data.map((item, index) => {
    return (
      <tr className="table-header-regular cursor-pointer" key={index}>
        <td className="table-row-regular text-16">{item?.afdelingName}</td>
        <td className="table-row-regular text-16">
          {parseToFloat(item?.ytdYieldKgPerHa / 1000, 3)}
        </td>
      </tr>
    );
  });
};

export default TableRow;
