import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./partials/Filter/Filter";
import ListHistorySpb from "./partials/ListHistorySpb";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { getDeliveries } from "../../../features/delivery/deliverySlice";
import { handleModalStarter } from "../../../features/modalSlice";
import { selectUser } from "../../../features/userSlice";
import { ConfigProvider, Modal, notification } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import useFilter from "../../../hooks/useFilter";
import TRUCK_SPB from "../../../assets/icons/truck-spb.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../App";
import moment from "moment/moment";

export const pagePath = "riwayat_spb";

const ListView = () => {
  const dispatch = useDispatch();
  const initialFilter = {
    pageIndex: 0,
    fltCompany: undefined,
    fltUser: undefined,
    fltAfdeling: undefined,
    fltBlock: undefined,
    fltStartDate: undefined,
    fltEndDate: undefined,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };
  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({ initialFilter });

  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );
  const paginationLenght = Math.ceil(page?.total / 20);
  const modalName = "Vehicle";
  const user = useSelector(selectUser);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    try {
      if (beforeFetch) {
        beforeFetch();
      }
      if (!isSilent) setRequest({ isLoading: true });
      const response = await dispatch(
        getDeliveries({
          ...isFilterBy,
          pageIndex,
        })
      ).unwrap();

      const success = response?.success;
      const message = response?.message;

      if (!success) {
        setRequest({ isLoading: false });
        return notification.error({
          message: "Gagal Mendapatkan Data SPB",
          description: message,
          placement: "bottomRight",
        });
      }
      setData(response?.records);
      const totalRecords = response?.totalRecords;
      setPage({
        current: pageIndex,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoading: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoading: false });
      return notification.error({
        message: "Gagal Mendapatkan Data SPB",
        description: error.data,
        placement: "bottomRight",
      });
    }
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
    logEvent(
      analytics,
      `pagination_button_${pagePath}_${window.location.host}`,
      {
        page_title: `${pagePath}_${window.location.host}`,
        page_location: window.location.href,
      }
    );
  };

  const handleRefreshData = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    document.title = "Riwayat SPB - SawitWeb";

    logEvent(analytics, "page_view", {
      firebase_screen: `${pagePath}_${window.location.host}`,
      page_title: `${pagePath}_${window.location.host}`,
      user_id: user?.id,
      what_time_access_screen: `${
        user?.username
      }_access_${pagePath}_at_${moment().format("ll")}`,
    });

    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, [isFilterBy]); // it will be call when the page is first loaded and isFilterBy is changed

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={TRUCK_SPB}
                alt=""
                className="hidden w-32 grayscale md:inline"
              />
              <p className="line-clamp-1">Riwayat SPB</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListHistorySpb
        isLoading={request.isLoading}
        data={data}
        handleRefreshData={handleRefreshData}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
