import axiosInstance from "../api";
import { AFDELINGS_URL } from "../../configs/api";

export const getAfdelingsApi = (params) =>
  axiosInstance.get(AFDELINGS_URL, {
    params,
  });
export const updateAfdelingApi = (id, body) =>
  axiosInstance.put(`${AFDELINGS_URL}/${id}`, body);
export const createAfdelingApi = (body) =>
  axiosInstance.post(AFDELINGS_URL, body);
