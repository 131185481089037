export const ujungbatu2012 = {
  type: "FeatureCollection",
  name: "ujungbatu_2012_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "M. Adem Imanula",
        KETERANGAN: "Sporadik",
        No_Kav: "123",
        block: "Blok 9",
        KODE: "TT 2012",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713627097585501, -3.70496569355866],
              [114.713991378727926, -3.704240847405986],
              [114.716240090665707, -3.704253524814877],
              [114.716178243960996, -3.704500232608321],
              [114.715710496178176, -3.704985970291647],
              [114.713627097585501, -3.70496569355866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "M. Adem Imanula",
        KETERANGAN: "Sporadik",
        No_Kav: "124",
        block: "Blok 9",
        KODE: "TT 2012",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713991378727926, -3.704240847405986],
              [114.714298249133165, -3.703630235318249],
              [114.714908247612243, -3.703300240803416],
              [114.715018249586919, -3.703580241562727],
              [114.715041806934479, -3.703562171763091],
              [114.716633413608577, -3.703557016371101],
              [114.716558247156499, -3.703660234418283],
              [114.716248241181418, -3.704220232380647],
              [114.716240090589451, -3.704253523837595],
              [114.713991378727926, -3.704240847405986],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.532,
        Nm_Pemilik: "Sumarno",
        KETERANGAN: "Sporadik",
        No_Kav: "125",
        block: "Blok 9",
        KODE: "TT 2012",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715041808537791, -3.703562170471248],
              [114.716438238747372, -3.702490261348292],
              [114.717228217410621, -3.702740234317066],
              [114.716633413608577, -3.703557016371101],
              [114.715041808537791, -3.703562170471248],
            ],
          ],
        ],
      },
    },
  ],
};
