import React, { useEffect, useState } from "react";
import ModalContainer from "../../../../../../components/atoms/Modal";

import { useForm } from "react-hook-form";
import Content from "./partials/Content/Content.jsx";
import { isEmptyObject } from "../../../../../../helpers/Common";
import { create } from "zustand";
import { useDimensionalTagStore } from "../../../../../../api/dimensionalTag/useDimensionalTagStore";
import { useUserStore } from "../../../../../MasterWorkerPage/hooks/userStore";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { formSchema } from "./schema/formSchema";
import { usePartiesStore } from "../../../../../../api/parties/usePartiesStore";
import { omitBy } from "lodash";
import { usePriceListStore } from "../../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import moment from "moment";

export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  modalTag: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data, isUpdate, key = "modal" }) =>
    set((state) => ({
      [key]: {
        ...state[key],
        isOpen: state[key] ? !state[key].isOpen : true,
        isUpdate: isUpdate || false,
        data: data || {},
      },
    })),
}));

const Modal = ({ onRefreshData }) => {
  const [tags, setTags] = useState([]);
  const [modal, isOpen, isUpdate, isLoading, toggleModal] = useModalStore(
    (state) => [
      state.modal,
      state.modal.isOpen,
      state.modal.isUpdate,
      state.modal.isLoading,
      state.toggleModal,
    ]
  );
  const [getDimensionalTags] = useDimensionalTagStore((state) => [
    state.getDimensionalTags,
  ]);
  const [createParty, updateParty] = usePartiesStore((state) => [
    state.createParty,
    state.updateParty,
  ]);
  const [createPriceList] = usePriceListStore((state) => [
    state.createPriceList,
  ]);

  const [user] = useUserStore((state) => [state.user]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = (inputData) => {
    if (isUpdate) {
      const body = {
        ...modal?.data,
        ...omitBy(inputData, (_, key) => key === "tags"),
      };

      updateParty({
        id: body.id,
        body,
        onSuccess: () => {
          onRefreshData();
          toggleModal({ data: {}, isUpdate: false });
          clearGroupTagForm();
        },
      });
    } else {
      const body = {
        companyId: user?.companyId,
        companyName: user?.adhocEstateName,
        name: inputData?.name,
        description: inputData?.description,
        tags: inputData?.tags?.map((item) => item?.value),
      };
      createParty({
        body,
        onSuccess: () => {
          onRefreshData();
          toggleModal({ data: {} });
          clearGroupTagForm();
        },
      });
    }
  };

  const fetchData = () => {
    getDimensionalTags({
      params: { pageSize: 1000, pageIndex: 0 },
      onSuccess: (data) => {
        const newData = data?.records?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        }));
        setTags(newData);
      },
    });
  };

  const clearGroupTagForm = () => {
    clearErrors();
    setValue("name", null);
    setValue("description", null);
    setValue("dimension", null);
    setValue("tags", null);
  };

  useEffect(() => {
    fetchData();
    clearGroupTagForm();

    if (!isEmptyObject(modal?.data)) {
      setValue("name", modal?.data?.name);
      setValue("description", modal?.data?.description);
    }
  }, [isOpen, modal?.data, isUpdate]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ data: {}, isUpdate: false })}
      title={`${isUpdate ? "Ubah" : "Tambah"} PKS`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            tags={tags}
            isUpdate={isUpdate}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ data: {}, isUpdate: false })}
          type={isUpdate ? "Update PKS" : "Simpan PKS"}
        />
      </form>
    </ModalContainer>
  );
};

export default Modal;
