export const tanjung2009 = {
  type: "FeatureCollection",
  name: "tanjungtt09_30ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.003,
        No_Kav: "2",
        Nm_Pemilik: "Noor Nahdiana",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.895783288239372, -3.716330830105008],
              [114.895963853556111, -3.71678351471001],
              [114.896100456579973, -3.717391906957317],
              [114.89546393900018, -3.717488407857702],
              [114.894898627056662, -3.717645897558612],
              [114.894822185734611, -3.717237024662496],
              [114.894786245248568, -3.717112842502804],
              [114.89450725341888, -3.716688593190637],
              [114.89421295168566, -3.716469479445358],
              [114.893947798018658, -3.716326436803849],
              [114.895783288239372, -3.716330830105008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "16",
        Nm_Pemilik: "Maryono",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891371384011677, -3.71696795195093],
              [114.891101416117777, -3.717079511773459],
              [114.890936944748717, -3.717070510616529],
              [114.890633798017546, -3.717012979358413],
              [114.890516318314113, -3.716697692987462],
              [114.890539403276705, -3.716356923760243],
              [114.890647624993719, -3.71624185377906],
              [114.890910225270005, -3.716056579163939],
              [114.891246479463135, -3.715881809010902],
              [114.891374032887285, -3.71585487702118],
              [114.891371384011677, -3.71696795195093],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        No_Kav: "15",
        Nm_Pemilik: "Lilik Dwi Haryanto",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891247713386278, -3.718826876889931],
              [114.891085349964087, -3.718324432352931],
              [114.891048748469686, -3.718083720942412],
              [114.890640860542419, -3.718008440542721],
              [114.890200719390108, -3.71777461352389],
              [114.889978968829823, -3.71740895070588],
              [114.889729307751239, -3.717317068883943],
              [114.889834637928715, -3.716965883472016],
              [114.890474531499891, -3.716400428822844],
              [114.890456271018707, -3.71670853698128],
              [114.890589808779552, -3.717063965781311],
              [114.890928531401357, -3.71712934347169],
              [114.891118411926641, -3.71713273367366],
              [114.891341511146877, -3.717049015583529],
              [114.891340435534374, -3.717502495217083],
              [114.89134033162469, -3.717546219602628],
              [114.891336879467588, -3.718937667406642],
              [114.891247713386278, -3.718826876889931],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.002,
        No_Kav: "13",
        Nm_Pemilik: "Hasnan Fauzi",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.89341021914305, -3.716977189049177],
              [114.893548650646693, -3.716590180641599],
              [114.893358262144702, -3.716500218460932],
              [114.893188267756912, -3.716430200751527],
              [114.89296441542308, -3.716431157113434],
              [114.892487998371962, -3.716561013835753],
              [114.892516041129994, -3.717549038555164],
              [114.891340331882162, -3.717546219437086],
              [114.891340622537584, -3.717423804394556],
              [114.891341513155155, -3.717049010146281],
              [114.891400641578528, -3.717026449038366],
              [114.891530336254121, -3.716947567418074],
              [114.892454920549227, -3.716467085742533],
              [114.89282688821369, -3.716324676007633],
              [114.893053178389621, -3.716288065172444],
              [114.893562716198261, -3.716292498925121],
              [114.893834764848165, -3.716336517895952],
              [114.894198428654633, -3.716529713953733],
              [114.894460730639011, -3.716726438350937],
              [114.894628593945086, -3.716980106995599],
              [114.89341021914305, -3.716977189049177],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.007,
        No_Kav: "14",
        Nm_Pemilik: "Henriyadi W Putra",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.89251604117122, -3.717549038595427],
              [114.892513641542223, -3.71875554888287],
              [114.892206976646591, -3.718917692506406],
              [114.891740855397046, -3.71898987375406],
              [114.891423757055151, -3.719005311258457],
              [114.8913368796632, -3.718937667540247],
              [114.891337368878283, -3.718792702653833],
              [114.89134033162469, -3.717546219602628],
              [114.89251604117122, -3.717549038595427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.001,
        No_Kav: "11",
        Nm_Pemilik: "Hadi Sapuan",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.894968701621437, -3.717730135538874],
              [114.894898460291969, -3.71861133976435],
              [114.894461354304667, -3.719608259970681],
              [114.894213638408743, -3.719853048581374],
              [114.893968591365308, -3.719951383512885],
              [114.893943914193315, -3.719953851001193],
              [114.893948547164229, -3.718001000852805],
              [114.894088121062893, -3.717926094816558],
              [114.894543424801242, -3.717743547413293],
              [114.894968701621437, -3.717730135538874],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.002,
        No_Kav: "10",
        Nm_Pemilik: "Woro Kusneini",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8939439101907, -3.719953854519416],
              [114.893730796730694, -3.719975184566167],
              [114.893585280793317, -3.719960213765367],
              [114.893022925074447, -3.71977365401542],
              [114.892889781006701, -3.719751398888415],
              [114.892892465999893, -3.718620640739105],
              [114.893388958202067, -3.718347817356858],
              [114.893444325955471, -3.718320695522178],
              [114.893487564772315, -3.71841828001488],
              [114.893616256738042, -3.718315358491744],
              [114.893698830361373, -3.718160711465873],
              [114.893948547164229, -3.718001000852805],
              [114.8939439101907, -3.719953854519416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.577,
        No_Kav: "9",
        Nm_Pemilik: "Maryono",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892889780864039, -3.719751398922745],
              [114.892727088080377, -3.71972420475133],
              [114.892518708893334, -3.719621351357687],
              [114.892263304362714, -3.718951515053],
              [114.892521348582918, -3.718813303916824],
              [114.892602372601658, -3.718770985349146],
              [114.892892464613865, -3.718620639066802],
              [114.892889780864039, -3.719751398922745],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.026,
        No_Kav: "3",
        Nm_Pemilik: "Risman Ginting",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.896087869873469, -3.717486131776651],
              [114.896090309403206, -3.717842497696356],
              [114.896115537101096, -3.718284708545456],
              [114.896492647714027, -3.718542982273944],
              [114.896620243677944, -3.718754464031632],
              [114.896632300706912, -3.718893461372309],
              [114.894883518978745, -3.718889275772848],
              [114.894993970947567, -3.718632619892357],
              [114.895075683712975, -3.717689617939],
              [114.89547472871584, -3.717570936517405],
              [114.896087869873469, -3.717486131776651],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.022,
        No_Kav: "4",
        Nm_Pemilik: "Isnah Paulina",
        KETERANGAN: "Masuk Kawasan",
        AJUAN_SHM: null,
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.896632304516658, -3.718893465892178],
              [114.896581891385352, -3.71969146754581],
              [114.896443486930281, -3.719882514824528],
              [114.896002504757845, -3.719636547122851],
              [114.895917458822538, -3.719751858421027],
              [114.89445888163975, -3.719748363579908],
              [114.894548336815447, -3.719668122310677],
              [114.894883516499405, -3.718889278952104],
              [114.896632304516658, -3.718893465892178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "1",
        Nm_Pemilik: "Mahrita",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.893716243402423, -3.714938798158302],
              [114.894298139147082, -3.715438069046464],
              [114.894480347253605, -3.715547735873498],
              [114.894693241877533, -3.715595044130775],
              [114.894906904795434, -3.715572726572816],
              [114.89515001195177, -3.715591571419649],
              [114.895442706826316, -3.715781751581871],
              [114.895775488402862, -3.716312168672891],
              [114.895783288239372, -3.716330830105008],
              [114.893947796461688, -3.716326433903061],
              [114.893849417778796, -3.716273363633527],
              [114.8937205996355, -3.716256138868014],
              [114.893683724818445, -3.716251208014707],
              [114.893686615752912, -3.715032440457736],
              [114.893686616496154, -3.715032437608426],
              [114.893716243402423, -3.714938798158302],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        No_Kav: "17",
        Nm_Pemilik: "Irwan",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.893045761101348, -3.716231721813541],
              [114.892810147181692, -3.71626756591933],
              [114.89243263410772, -3.716410612639037],
              [114.891642729202957, -3.716819063011735],
              [114.891495977892163, -3.716888204831104],
              [114.891377206524737, -3.716965686763542],
              [114.891371391429345, -3.716967952123273],
              [114.891374035787081, -3.715854875494022],
              [114.89167156959202, -3.715771763060399],
              [114.892724627541028, -3.715884216269552],
              [114.892950894307035, -3.715853415832353],
              [114.893360919799861, -3.715627164887254],
              [114.893548634067585, -3.715408216963453],
              [114.893686615752912, -3.715032440457736],
              [114.893683721927175, -3.716251208031915],
              [114.893559648881066, -3.716234613754435],
              [114.893045761101348, -3.716231721813541],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.009,
        No_Kav: "12",
        Nm_Pemilik: "Gt. Indriyani",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892576279671118, -3.718728941925392],
              [114.893036369707033, -3.718022344317512],
              [114.893410219930161, -3.71697719368108],
              [114.894628593945086, -3.716980106995599],
              [114.894728166045098, -3.717130582995744],
              [114.894763577770433, -3.717238145371496],
              [114.89482456298748, -3.717639911911699],
              [114.894535509696766, -3.717642990822637],
              [114.894045139193935, -3.717851111451238],
              [114.893620321689838, -3.71811784051585],
              [114.893381806254851, -3.718179491808788],
              [114.893425317634041, -3.718277734531925],
              [114.892576279671118, -3.718728941925392],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.011,
        No_Kav: "5",
        Nm_Pemilik: "Endang Suprobowati",
        KETERANGAN: "Masuk Kawasan",
        AJUAN_SHM: null,
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.895917458822538, -3.719751858421027],
              [114.894735468183555, -3.721287364958501],
              [114.894560964487496, -3.721465126527891],
              [114.89435829338791, -3.721440229435369],
              [114.894290897598808, -3.721423228607015],
              [114.894294523540125, -3.719895797628524],
              [114.894294524534871, -3.719895793926643],
              [114.89445888163975, -3.719748363579908],
              [114.895917458822538, -3.719751858421027],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.022,
        No_Kav: "6",
        Nm_Pemilik: "Asit",
        KETERANGAN: "Masuk Kawasan",
        AJUAN_SHM: null,
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892997039706628, -3.721094172237946],
              [114.893000010930919, -3.71984303466806],
              [114.893563438770741, -3.72005195472236],
              [114.893743117869136, -3.720063131336535],
              [114.893987064005913, -3.720037926024947],
              [114.894233312829058, -3.719943952611228],
              [114.894294523540125, -3.719895797628524],
              [114.89429089368268, -3.721423227619162],
              [114.893056253745627, -3.721111784394424],
              [114.892997039706628, -3.721094172237946],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.067,
        No_Kav: "7",
        Nm_Pemilik: "Subandi",
        KETERANGAN: "Masuk Kawasan",
        AJUAN_SHM: null,
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892130559527871, -3.721273263299397],
              [114.891686949462724, -3.721098309406845],
              [114.891550614840256, -3.72091613516703],
              [114.891950871393419, -3.720202887192018],
              [114.892501584452077, -3.719703621802573],
              [114.892503442198532, -3.719705170117689],
              [114.892691836877304, -3.719785462082891],
              [114.892997283334751, -3.719841763533426],
              [114.89300000816111, -3.719843032264465],
              [114.892997039706628, -3.721094172237946],
              [114.892898373644954, -3.721199501554764],
              [114.892612032695624, -3.721350022669144],
              [114.892130559527871, -3.721273263299397],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.905,
        No_Kav: "8",
        Nm_Pemilik: "Wella Widia Ratna",
        KETERANGAN: "Bebas Kawasan",
        AJUAN_SHM: "Proses SHM",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891507519849682, -3.720858550391436],
              [114.891431880758219, -3.72075747923229],
              [114.891161850502556, -3.720378811195249],
              [114.890945058529681, -3.719765898728153],
              [114.89145088748036, -3.71909423724279],
              [114.892181124193797, -3.718997703720842],
              [114.892435456532098, -3.719648508325019],
              [114.8924586796591, -3.719667863347025],
              [114.891911187212031, -3.72015280956145],
              [114.891507519849682, -3.720858550391436],
            ],
          ],
        ],
      },
    },
  ],
};
