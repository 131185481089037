import { useForm } from "react-hook-form";
import React, { useCallback } from "react";
import ButtonComponent from "../../../../../../components/atoms/ButtonComponent";
import { TbFilter, TbRefresh } from "react-icons/tb";
import useFilterDataDefaults from "../../../../../../hooks/useFilterDataDefaults";
import InputHook from "../../../../../../components/atoms/InputHook";
import { debounce } from "lodash";
import { useModalStore } from "../Modal/Modal";

const Filter = ({ handleRefreshData, onFilter, showModal }) => {
  const { control } = useForm();
  const { users } = useFilterDataDefaults();
  const toggleModal = useModalStore((state) => state.toggleModal);

  const request = debounce(async (searchTerm) => {
    onFilter({
      type: "fltFullname",
      value: searchTerm,
    });
  }, 500);

  const debounceTextRequest = useCallback((searchTerm) => {
    request(searchTerm);
  }, []);

  return (
    <div className="mx-16 grid grid-cols-1 gap-8 md:mt-0 md:grid-cols-2">
      <div className="order-2 grid w-full grid-cols-2 gap-8 md:order-1 md:flex md:w-fit md:flex-row md:items-center md:space-x-8">
        {/* <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:-ml-8 md:hidden md:w-fit"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        /> */}
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => handleRefreshData()}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        {/*<InputHook*/}
        {/*  control={control}*/}
        {/*  name="fullname"*/}
        {/*  placeholder="nama pengguna"*/}
        {/*  className="hidden max-w-[210px] md:inline"*/}
        {/*  inputClassName="input-mini-border pr-24 pl-8"*/}
        {/*  afterOnChange={debounceTextRequest}*/}
        {/*/>*/}
      </div>
      <div className="order-1 flex w-full justify-end md:order-2">
        <ButtonComponent value="Tambah Tag" onClick={() => toggleModal({})} />
      </div>
    </div>
  );
};

export default Filter;
