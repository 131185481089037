import ChemistComponent from "../../components";
import React from "react";
import moment from "moment";
import { useData } from "../../hooks/DataContext";
import { useChemistRecapsStore } from "../../../../../api/chemist-recaps/useChemistRecapsStore";
import MaterialSummaries from "./partials/MaterialSummaries";
import { formatNumericValue, Number } from "../../../../../helpers/Common";
import DistincBlocks from "./partials/DistincBlocks";

const Cards = () => {
  const { isFilterBy } = useData();
  const [distinctBlocks, materialSummary, overallSummary] =
    useChemistRecapsStore((state) => [
      state.distinctBlocks,
      state.materialSummary,
      state.overallSummary,
    ]);

  return (
    <div className="mx-auto grid w-full grid-cols-1 gap-8 px-8 md:grid-cols-4 md:grid-rows-8 md:gap-13 md:px-[2%]">
      <div className="relative md:row-span-3">
        <DistincBlocks
          data={distinctBlocks}
          title="Blok Chemist"
          subTitle={`${moment(isFilterBy?.fltChemistDate).format(
            "ll"
          )} - Hari Ini`}
        />
      </div>
      <div className="h-full max-h-[200px] md:col-span-3 md:row-span-3 md:max-h-full">
        <MaterialSummaries data={materialSummary} />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Luas Terawat"
          subTitle={moment(isFilterBy?.fltChemistDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.realizationAreaHa, {
                decimals: 2,
              }),
              unit: "Ha",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdRealizationAreaHa, {
                decimals: 2,
              }),
              unit: "Ha",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Jumlah Material Digunakan"
          subTitle={moment(isFilterBy?.fltChemistDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.chemicalLtr, {
                decimals: 2,
              }),
              unit: "Ltr",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdChemicalLtr, {
                decimals: 2,
              }),
              unit: "Ltr",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Tenaga Kerja"
          subTitle={moment(isFilterBy?.fltChemistDate).format("ll")}
          data={[
            {
              value: Number(overallSummary?.workerPersonCount, {
                decimals: 2,
              }),
              unit: "TK",
              dateType: "HI",
            },
            {
              value: Number(overallSummary?.mtdWorkerPersonCount, {
                decimals: 2,
              }),
              unit: "TK",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>

      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Output"
          subTitle={moment(isFilterBy?.fltChemistDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.chemicalLtrPerPerson, {
                decimals: 2,
              }),
              unit: "Ltr/TK",
              dateType: "HI",
            },
            {
              value: formatNumericValue(
                overallSummary?.mtdChemicalLtrPerPerson,
                { decimals: 2 }
              ),
              unit: "Ltr/TK",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Cards;
