import ButtonComponent from "../../ButtonComponent";
import PropTypes from "prop-types";
import React from "react";

const BasePageHeader = ({
	title,
	icon,
	onCreate,
	buttonValue,
	buttonShow = true,
	additionalButton,
	customIcon,
}) => {
	return (
		<section className="mb-24 flex flex-row flex-wrap items-center justify-between space-y-16 border-1 border-gray-10 bg-white p-24 lg:space-y-0">
			<div className="flex flex-row items-center space-x-16">
				{icon && (
					<div className="rounded-8 bg-gray-20 p-8 text-24 text-gray-400">
						{icon}
					</div>
				)}
				{customIcon}
				<h1 className="text-24 font-bold text-gray-700">{title}</h1>
			</div>
			<div className="flex flex-row items-center space-x-16">
				{additionalButton}
				{buttonShow && (
					<ButtonComponent
						value={buttonValue}
						size="sm"
						type="button"
						className="button-transition"
						onClick={onCreate}
					/>
				)}
			</div>
		</section>
	);
};

BasePageHeader.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
		PropTypes.symbol,
	]),
	icon: PropTypes.element,
	onCreate: PropTypes.func,
	buttonValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
		PropTypes.symbol,
	]),
	buttonShow: PropTypes.bool,
	additionalButton: PropTypes.element,
};

export default BasePageHeader;
