import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./templates/Layout";
import { getUser } from "../features/userSlice";
import {
  handleKeycloackAuth,
  selectAuthenticated,
} from "../features/authSlice";
import { useAuthStore } from "../hooks/authStore";
import moment from "moment";
import { APP_NAME, LOGOUT_CALLBACK_URL } from "../configs/api";

function AuthMiddleware({ needAuth, children }) {
  const [keycloak, getNewToken, tokensParsed] = useAuthStore((state) => [
    state.keycloak,
    state.getNewToken,
    state.tokensParsed,
  ]);
  const authenticated = useSelector(selectAuthenticated);
  // console.log(authenticated);
  let location = useLocation();
  const [user, setUser] = useState();
  const dispatch = useDispatch();

  const handleLogout = () => {
    keycloak.logout({
      redirectUri: LOGOUT_CALLBACK_URL,
    });
  };

  const checkForInactivity = () => {
    if (!authenticated) return;
    // Get the last activity
    const lastActivity = tokensParsed;

    // Get the expiration time
    const expiresIn = lastActivity?.tokenParsed?.exp;
    const expirationTime = moment.unix(expiresIn);
    const currentTime = moment();

    // Check if the expiration time is in the past
    const isExpired = expirationTime.isBefore(currentTime);

    // If the token is expired, logout
    if (isExpired) {
      updateExpireTime();
      // console.log("Token has expired");
    } else {
      // console.log("Token is still valid");
    }
  };

  const updateExpireTime = async () => {
    try {
      console.log("I do update expire time!");
      // Get the refresh token
      const response = await getNewToken();
      handleGetUser(
        response.tokensParsed.tokenParsed.sub,
        response.tokens.token
      );

      const sub = keycloak.tokenParsed.sub;
      const provide = keycloak.token;
      const provide_fresh = keycloak.refreshToken;
      const keycloakProvides = {
        provide,
        provide_fresh,
        sub,
      };

      dispatch(
        handleKeycloackAuth({
          keycloak: keycloak,
          auth: authenticated,
          tokens: keycloakProvides,
        })
      );

      const remainingToken = {
        idToken: keycloak.idToken,
        refreshToken: keycloak.refreshToken,
        accessToken: keycloak.token,
      };

      localStorage.setItem("token", JSON.stringify(remainingToken));
      localStorage.setItem("user", JSON.stringify(keycloak.tokenParsed));

      console.log("I DONE update expire time!");
    } catch (error) {
      handleLogout();
      localStorage.clear();
      return;
    }
  };

  const handleGetUser = async (subParams, tokenParams) => {
    try {
      const remainingUser = JSON.parse(localStorage.getItem("user"));
      const remainingToken = JSON.parse(localStorage.getItem("token"));
      const sub = remainingUser.sub;
      const token = remainingToken.accessToken;

      const response = await dispatch(
        getUser({ id: subParams || sub, token: tokenParams || token })
      ).unwrap();
      if (!response.success) {
        return;
      }
      const record = response.record;
      localStorage.setItem("user:api", JSON.stringify(record));
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkForInactivity();
  }, []);

  useEffect(() => {
    handleGetUser();
  }, []);

  if (needAuth) {
    if (!authenticated) {
      return (
        <Navigate to="/login-callback" state={{ from: location }} replace />
      );
    } else {
      return <Layout />;
    }
  }

  if (!needAuth) {
    if (authenticated) {
      return (
        <Navigate to={"/produksi-kebun"} state={{ from: location }} replace />
      );
    } else {
      return children;
    }
  }
}

export default AuthMiddleware;
