import React, { useEffect } from "react";
import {
  getAfdelings,
  selectAfdelings,
} from "../../../../../../../features/afdelingSlice";
import {
  getEstates,
  selectEstates,
} from "../../../../../../../features/estateSlice";
import { useDispatch, useSelector } from "react-redux";

import InputHook from "../../../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../../../components/atoms/SelectHook";
import { getYears } from "../../../../../../../helpers/Common";
import { selectUserCompanyId } from "../../../../../../../features/userSlice";

const Content = ({ register, control, errors, watch }) => {
  const dispatch = useDispatch();
  const companyId = useSelector(selectUserCompanyId);
  const estates = useSelector(selectEstates);
  const afdelings = useSelector(selectAfdelings);

  const getAllEstates = () => {
    return dispatch(
      getEstates({
        companyId: companyId,
        type: "selects",
      })
    ).unwrap();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAllEstates();
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      if (value?.estate) {
        dispatch(
          getAfdelings({
            id: value?.afdeling?.id,
            pageSize: 999,
            type: "selects",
          })
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="grid grid-cols-1 gap-13">
      <InputHook
        required
        register={register}
        label="Tipe/Model Truk"
        name="type"
        placeholder="Masukkan tipe atau model truk"
        errors={errors?.type}
        inputClassName="input-large-border"
      />
      <InputHook
        required
        register={register}
        label="Nama Truk"
        name="name"
        placeholder="Masukkan nama truk"
        errors={errors?.name}
        inputClassName="input-large-border"
      />
      <InputHook
        required
        register={register}
        label="Nomor Polisi"
        name="code"
        placeholder="Masukkan nomor polisi"
        errors={errors?.code}
        inputClassName="input-large-border"
      />
      <SelectHook
        required
        label="Tahun Akuisisi"
        name="acquisitionYear"
        errors={errors?.acquisitionYear}
        data={getYears()}
        control={control}
        placeholder="Pilih tahun akuisisi"
      />
      <InputHook
        required
        register={register}
        label="Kepemilikan"
        name="ownership"
        placeholder="Masukkan nama kepemilikan"
        errors={errors?.ownership}
        inputClassName="input-large-border"
      />
      <InputHook
        required
        register={register}
        label="Berat Bersih (kg)"
        name="tareWeight"
        placeholder="Masukkan berat bersih"
        errors={errors?.tareWeight}
        inputClassName="input-large-border"
      />
      <InputHook
        required
        register={register}
        label="Batas Beban Maksimal (kg)"
        name="maxLoadCapacity"
        placeholder="Masukkan batas beban maksimal"
        errors={errors?.maxLoadCapacity}
        inputClassName="input-large-border"
      />
      <InputHook
        register={register}
        optional={true}
        label="Nomer Rangka"
        name="frameNo"
        placeholder="Masukkan nomer rangka"
        errors={errors?.frameNo}
        inputClassName="input-large-border"
      />
      <InputHook
        register={register}
        optional={true}
        label="Nomor Mesin"
        name="engineNo"
        placeholder="Masukkan nomor mesin"
        errors={errors?.engineNo}
        inputClassName="input-large-border"
      />
      <SelectHook
        label="Kebun"
        name="estate"
        errors={errors?.estate}
        data={estates}
        control={control}
        placeholder="Pilih Wilayah"
      />
      <SelectHook
        label="Wilayah/Afdeling/Divisi"
        name="afdeling"
        errors={errors?.afdeling}
        data={afdelings}
        control={control}
        placeholder="Pilih wilayah"
      />
    </div>
  );
};

export default Content;
