import { HARVESTINGS_URL } from "../configs/api";
import axiosInstance from "./api";

export const getHarvestingsApi = (params) =>
  axiosInstance.get(HARVESTINGS_URL, {
    params,
  });

export const getHarvestingByIdApi = (id) =>
  axiosInstance.get(`${HARVESTINGS_URL}/${id}`);
export const updateHarvestingApi = (id, body) =>
  axiosInstance.put(`${HARVESTINGS_URL}/${id}`, body);
