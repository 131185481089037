import { APP_NAME } from "../../../../../configs/api";
import { cn } from "../../../../../helpers/Common";
import ButtonComponent from "../../../ButtonComponent";
import React from "react";

const Footer = ({
  toggleModal,
  type,
  leftContent,
  isLoading,
  submitComponent,
}) => {
  return (
    <div
      className={`modal-footer-container ${leftContent && "justify-between"}`}
    >
      {leftContent}
      <div className="left flex flex-row items-center space-x-16">
        <ButtonComponent
          value="Batal"
          type="button"
          className="border border-gray-200 bg-white text-gray-500 hover:border-gray-500"
          onClick={toggleModal}
        />
        {submitComponent ? (
          submitComponent
        ) : (
          <ButtonComponent
            value={type}
            type="submit"
            className={cn(
              " text-white",
              APP_NAME === "sawit-web"
                ? "bg-yellow-500 hover:bg-yellow-700"
                : "bg-primary-red-500 hover:bg-primary-red-500"
            )}
            disabled={isLoading}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
