export const headersData = [
	{
		value: "NO.",
		accessorKey: "index",
		type: "alphabet",
	},
	{
		value: "Foto",
		accessorKey: "photo",
		type: "alphabet",
	},
	{
		value: "Tanggal Angkut",
		accessorKey: "date",
		type: "alphabet",
		sorted: true,
	},
	{
		value: "Dibuat oleh",
		accessorKey: "createdBy",
		type: "alphabet",
	},
	{
		value: "Komentar",
		accessorKey: "remark",
		type: "alphabet",
	},
	{
		value: "Tanggal Tindakan",
		accessorKey: "caDueDate",
		type: "alphabet",
	},
	{
		value: "Jumlah TBS (jjg)",
		accessorKey: "tbsCount",
		type: "alphabet",
	},
	{
		value: "Estimasi Tonase (kg)",
		accessorKey: "beratTotal",
		type: "alphabet",
	},
	{
		value: "Nomer SPB",
		accessorKey: "fruitDeliveryLetterNumber",
		type: "alphabet",
	},
	{
		value: "Nopol",
		accessorKey: "nopol",
		type: "alphabet",
	},
	{
		value: "Tujuan Pengantaran SPB",
		accessorKey: "destination",
		type: "alphabet",
	},
];
