import { ConfigProvider, DatePicker, Image, notification, Tooltip } from "antd";
import { MdDragIndicator, MdOutlineWarning } from "react-icons/md";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { formatNumericValue } from "../../../../../helpers/Common";
import { capitalize, debounce, omit } from "lodash";

import ColumnSettings from "../../../../../components/atoms/Table/partials/ColumnSettings";
import { Controller, useForm } from "react-hook-form";
import { IMAGE_NOT_FOUND } from "../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../components/atoms/InputHook";
import { MaterialReactTable } from "material-react-table";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbCalendar } from "react-icons/tb";
import { checkValues } from "../../../../../helpers/validationHelper";
import { create } from "zustand";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useFfbStockMovementStore } from "../../../../../api/ffbStockMovement/useFfbStockMovementStore";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import { useImageSource } from "../../../../../hooks/useImageSource";
import { useMeasure } from "@uidotdev/usehooks";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import {
  processDateChange,
  processTextChange,
  showSuccessToast,
} from "../../../../../hooks/commentUtils";
import UnitPriceRow from "./partials/UnitPriceRow";
import SellUnitPriceRow from "./partials/SellUnitPriceRow";

const ImagesRow = ({ row }) => {
  const rowData = row.original;

  const { source, setSource, setDownloadedImage } = useImageSource();
  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(rowData?.photoFileId, "main");
    setDownloadedImage(rowData?.photoFileId2, "second");
  }, [rowData?.photoFileId, rowData?.photoFileId2]);

  return (
    <div className="flex flex-row items-center space-x-8">
      <Tooltip placement="bottom" title="Dokumen 1" className="font-regular">
        <img
          src={source.main || IMAGE_NOT_FOUND}
          alt={rowData?.photoFileId}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("main")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.main}
        preview={{
          visible: source.mainVisible,
          src: source.main || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("main"),
        }}
      />
      <Tooltip placement="bottom" title="Dokumen 2" className="font-regular">
        <img
          src={source.second || IMAGE_NOT_FOUND}
          alt={rowData?.photoFileId2}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("second")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.second}
        preview={{
          visible: source.secondVisible,
          src: source.second || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("second"),
        }}
      />
    </div>
  );
};

const RemarkRow = ({ row, refreshHandler }) => {
  const [getFfbStockMovementById, updateFfbStockMovement] =
    useFfbStockMovementStore((state) => [
      state.getFfbStockMovementById,
      state.updateFfbStockMovement,
    ]);

  const jeprinData = row.original;
  const { control, setValue, watch } = useForm();

  const watchRemark = watch(`remark-${jeprinData?.id}`);

  const handleBlurOnRemarkChange = async () => {
    const textParsed = processTextChange({
      value: watchRemark,
    });

    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["remark"]),
          remark: textParsed.value,
        },
        onSuccess: () => {
          textParsed.onSuccess();
          refreshHandler(true);
        },
      });
    });
  };

  const handleOnKeyDownOnRemarkChange = async (event) => {
    if (event.key === "Enter") {
      await handleBlurOnRemarkChange();
    }
  };

  const request = debounce(async (searchTerm) => {
    const textParsed = processTextChange({
      value: searchTerm,
    });

    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["remark"]),
          remark: textParsed.value,
          version: stockMovementData.record.version + 1,
        },
        onSuccess: () => refreshHandler(true),
      });
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [jeprinData]
  );

  useEffect(() => {
    setValue(`remark-${jeprinData?.id}`, jeprinData?.remark);
  }, [jeprinData?.id]);

  return (
    <InputHook
      control={control}
      name={`remark-${jeprinData.id}`}
      placeholder="komentar"
      className="min-w-[160px] rounded-8 shadow"
      inputClassName="input-mini-border pl-8 pr-24 w-full"
      afterOnChange={debounceTextRequest}
      onBlur={handleBlurOnRemarkChange}
      onKeyDown={handleOnKeyDownOnRemarkChange}
    />
  );
};

const DueDateRow = ({ row, refreshHandler }) => {
  const [getFfbStockMovementById, updateFfbStockMovement] =
    useFfbStockMovementStore((state) => [
      state.getFfbStockMovementById,
      state.updateFfbStockMovement,
    ]);

  const jeprinData = row.original;
  const { control, setValue } = useForm();

  const handleOnChangeOnDateChange = async (date, onChange) => {
    onChange(date);

    const dateParsed = processDateChange({
      value: date,
    });

    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["caDueDate"]),
          caDueDate: dateParsed.value,
          version: stockMovementData.record.version + 1,
        },
        onSuccess: () => {
          dateParsed.onSuccess();
          refreshHandler(true);
        },
      });
    });
  };

  useEffect(() => {
    setValue(
      `caDueDate-${jeprinData?.id}`,
      jeprinData?.caDueDate
        ? dayjs(moment(jeprinData?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [jeprinData?.id]);

  return (
    <Controller
      name={`caDueDate-${jeprinData?.id}`}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#d7a137",
                colorTextQuaternary: "#e1e4e8",
                fontFamily: "Inter-Regular",
                fontSize: 16,
                colorTextPlaceholder: "#6B6F77",
              },
            }}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={value}
              onChange={(date) => handleOnChangeOnDateChange(date, onChange)}
              placeholder="Tanggal tindakan"
              suffixIcon={<TbCalendar className="text-16" />}
              style={{
                width: 160,
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                fontFamily: "Inter-Regular",
                maxWidth: 250,
                borderWidth: 1,
                borderColor: "#e1e4e8",
              }}
            />
          </ConfigProvider>
        );
      }}
    />
  );
};

const TraderRow = ({ row, refreshHandler }) => {
  const [getFfbStockMovementById, updateFfbStockMovement] =
    useFfbStockMovementStore((state) => [
      state.getFfbStockMovementById,
      state.updateFfbStockMovement,
    ]);

  const jeprinData = row.original;
  const { control, setValue } = useForm();

  const [user] = useUserStore((state) => [state.user]);
  const { users, fetchUsers } = useFilterDataDefaults();

  const afterOnChangeHandler = async (value) => {
    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["caDueDate"]),
          updatedBy: value?.fullname,
          userId: value?.id,
          version: stockMovementData.record.version + 1,
        },
        onSuccess: () => {
          showSuccessToast({
            message: "Berhasil Update Nama Trader",
            value: value?.fullname,
          });
          refreshHandler(true);
        },
        onError: () => {
          notification.error({
            message: "Gagal Merubah Trader pada Jeprin File",
            placement: "bottomRight",
          });
        },
      });
    });
  };

  const onSearchUser = async (searchTerm) => {
    await fetchUsers({ name: searchTerm });
  };

  useEffect(() => {
    setValue(`fullname-${row?.original?.id}`, {
      label: row?.original?.fullname,
      value: row?.original?.userId,
    });
    fetchUsers({ params: { pageSize: 99999 } });
  }, [row.original?.id]);

  return (
    <div>
      {user?.username === "kjp02" ||
      user?.username === "k.emj" ||
      user?.username === "pranotodahlan" ||
      user?.username === "tradingkjpcps" ? (
        <SelectHook
          control={control}
          data={users}
          name={`fullname-${row?.original?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          menuStyles={{
            input: {
              padding: 4,
            },
            option: {
              padding: "4px 12px",
            },
            menuList: {
              padding: "2px",
            },
          }}
          onInputChange={(searchValue) => {
            onSearchUser(searchValue);
          }}
          afterOnChange={afterOnChangeHandler}
        />
      ) : (
        row?.original?.fullname
      )}
    </div>
  );
};

export const useTableStore = create((set, get) => ({
  isDrawer: false,
  setIsDrawer: (value) => set({ isDrawer: !get().isDrawer }),
  sortedColumns: [],
  setSortedColumns: (value) => set({ sortedColumns: value }),
}));

const ListData = ({ data, refreshHandler }) => {
  const columns = useMemo(
    () => [
      {
        header: "NO.",
        accessorKey: "index",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ cell, row }) => {
          const message = generateMessage(row.original);

          return (
            <div className="flex flex-row items-center space-x-8">
              <Tooltip
                placement="bottom"
                title={message.message}
                className="font-regular"
              >
                <div>
                  {message.isError ? (
                    <MdOutlineWarning className="text-24 text-yellow-600" />
                  ) : null}
                </div>
              </Tooltip>
              <p>{row.index + 1}</p>
            </div>
          );
        },
      },
      {
        header: "Tanggal Jeprin",
        accessorKey: "operationDate",
        Cell: ({ cell, row }) => moment(cell.getValue()).format("ll"),
      },
      {
        header: "Foto",
        accessorKey: "photoFileId",
        Cell: ({ cell, row }) => {
          return <ImagesRow row={row} />;
        },
      },
      {
        header: "Tanggal Nota",
        accessorKey: "receiptDate",
        Cell: ({ cell, row }) => moment(cell.getValue()).format("ll"),
      },
      {
        header: "Nama Trader",
        accessorKey: "createdBy",
        Cell: ({ cell, row }) => (
          <TraderRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Komentar",
        accessorKey: "remark",
        Cell: ({ cell, row }) => (
          <RemarkRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Tanggal Tindakan",
        accessorKey: "caDueDate",
        Cell: ({ cell, row }) => (
          <DueDateRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Netto(Kg)", // timbangan isi - timbangan kosong
        accessorKey: "measuredNettWeightKg",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => <p>{formatNumericValue(cell.getValue())}</p>,
      },
      {
        header: "Rp/Kg Klaim",
        accessorKey: "unitPrice",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <UnitPriceRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Harga Klaim (Rp)",
        accessorKey: "totalPrice",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Rp/Kg Jual PKS",
        accessorKey: "sellUnitPrice",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <SellUnitPriceRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Harga Jual (Rp)",
        accessorKey: "totalSellPrice",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue() || 0, {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Profit",
        accessorKey: "profit",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          const profit =
            row?.original?.totalSellPrice - row?.original?.totalPrice;

          return (
            <p>
              {formatNumericValue(profit || 0, {
                prefix: "Rp",
                decimals: 0,
              })}
            </p>
          );
        },
      },
      {
        header: "Bruto(Kg)",
        accessorKey: "measuredGrossWeightKg",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => <p>{formatNumericValue(cell.getValue())}</p>,
      },
      {
        header: "Tarra(Kg)",
        accessorKey: "measuredTaraWeightKg",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => <p>{formatNumericValue(cell.getValue())}</p>,
      },
      {
        header: "Arus TBS",
        accessorKey: "logisticFlow",
        Cell: ({ cell, row }) => (
          <div
            className={`${
              cell.getValue() === "MASUK"
                ? "bg-blue-50 text-blue-500"
                : "bg-green-50 text-green-500"
            } rounded-8 px-8 py-4`}
          >
            <p>{capitalize(cell.getValue())}</p>
          </div>
        ),
      },
      {
        header: "Diterima Dari",
        accessorKey: "ffbSourceParty",
      },
      {
        header: "PKS",
        accessorKey: "ffbDestinationParty",
      },
      {
        header: "Nopol",
        accessorKey: "truckPlateNo",
      },
      {
        header: "TBS Diterima (Jjg)",
        accessorKey: "ffbCount",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Penalti (Jjg)",
        accessorKey: "pinaltyCount",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "BJR Ramp (Kg)",
        accessorKey: "bjrCount",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => {
          const rowData = row.original;

          const calculateNettWeight = () => {
            const grossWeight = rowData?.measuredGrossWeightKg || 0;
            const taraWeight = rowData?.measuredTaraWeightKg || 0;

            const nettWeight =
              rowData?.measuredNettWeightKg || grossWeight - taraWeight || 0;
            return nettWeight;
          };

          const calculateBjrRamp = () => {
            const nettWeight = calculateNettWeight();
            const ffbCount = rowData?.ffbCount || 0;
            const pinaltyCount = rowData?.pinaltyCount || 0;

            if (ffbCount !== 0 && nettWeight !== 0) {
              return formatNumericValue(nettWeight / (ffbCount - pinaltyCount));
            } else {
              return 0;
            }
          };
          return <div>{calculateBjrRamp()}</div>;
        },
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  const generateMessage = (data) => {
    let message;

    let fieldNames = {
      truckPlateNo: "Nopol",
      logisticFlow: "Arus TBS",
      measuredGrossWeightKg: "Bruto(Kg)",
      measuredTaraWeightKg: "Tarra(Kg)",
      measuredNettWeightKg: "Netto(Kg)",
      ffbCount: "TBS Diterima(Jjg)",
      pinaltyCount: "Penalti(Jjg)",
      totalPrice: "Harga Klaim (Rp)",
      unitPrice: "Rp/Kg Klaim",
    };

    let items = [
      { truckPlateNo: data?.truckPlateNo },
      { logisticFlow: data?.logisticFlow },
      { measuredGrossWeightKg: data?.measuredGrossWeightKg },
      { measuredNettWeightKg: data?.measuredNettWeightKg },
      { measuredTaraWeightKg: data?.measuredTaraWeightKg },
      { ffbCount: data?.ffbCount },
      { pinaltyCount: data?.pinaltyCount },
      { totalPrice: data?.totalPrice },
      { unitPrice: data?.unitPrice },
    ];

    if (data?.logisticFlow === "MASUK") {
      fieldNames = { ...fieldNames, ffbSourceParty: "Diterima Dari" };
      items = [...items, { ffbSourceParty: data?.ffbSourceParty }];
    } else if (data?.logisticFlow === "KELUAR") {
      fieldNames = { ...fieldNames, ffbDestinationParty: "PKS" };
      items = [...items, { ffbDestinationParty: data?.ffbDestinationParty }];
    }

    message = checkValues(items, fieldNames);

    return message;
  };

  return (
    <div
      ref={ref}
      className="vird relative mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <ColumnSettings
        ref={tableInstanceRef}
        columns={columns}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        isDrawer={isDrawer}
        setIsDrawer={setIsDrawer}
        sortedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
      />
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        enableColumnOrdering
        enablePinning
        enableColumnResizing
        enableDensityToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableRowVirtualization
        initialState={{ density: "compact" }}
        enableTableFooter={false}
        enableBottomToolbar={false}
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{
          sx: {
            borderRadius: "24px",
            boxShadow: "0",
            fontFamily: "Inter-Regular",
          },
        }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          const message = generateMessage(row.original);

          return {
            sx: {
              backgroundColor:
                message.message.toLowerCase() == "sudah lengkap"
                  ? "#FFFFFF"
                  : "#F9F3E4",
              borderBottom: "1px solid #ECEDEF",
              fontFamily: "Inter-Regular",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: "#fafbfd",
            padding: "8px",
            fontFamily: "Inter-Regular",
            color: "#646769",
          },
        }}
        muiTableHeadCellProps={{
          sx: { borderBottom: 0 },
        }}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        columnResizeMode="onEnd"
        onColumnSizingChange={(updater) => {
          setColumnSizing((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnPinningChange={(updater) => {
          setColumnPinning((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnOrderChange={(updater) => {
          setColumnOrder((prev) => {
            const newOrder =
              updater instanceof Function ? updater(prev) : updater;
            // setSortedColumns(
            //   newOrder?.map((item) =>
            //     tableInstanceRef.current
            //       ?.getAllColumns()
            //       .find((column) => column.id === item)
            //   )
            // );
            return updater instanceof Function ? updater(prev) : updater;
          });
        }}
        onColumnVisibilityChange={(updater) => {
          setColumnVisibility((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
      />
    </div>
  );
};
export default ListData;
