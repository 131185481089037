import axiosInstance from "../api";
import { PARTIES_URL } from "../../configs/api";

export const getPartiesApi = (params) =>
  axiosInstance.get(PARTIES_URL, {
    params,
  });

export const createPartyApi = (body) => axiosInstance.post(PARTIES_URL, body);

export const updatePartyApi = (id, body) =>
  axiosInstance.put(`${PARTIES_URL}/${id}`, body);

export const deletePartyApi = (id) =>
  axiosInstance.delete(`${PARTIES_URL}/${id}`);
