import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import { getChemistsApi, updateChemistApi } from "./chemistApi";

export const useChemistStore = create((set, get) => ({
  chemists: [],
  chemist: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getChemists: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getChemistsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan List Kegiatan Chemist",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ chemists: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan List Kegiatan Chemist",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateChemist: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateChemistApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Kegiatan Chemist",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Kegiatan Chemist`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Kegiatan Chemist",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
