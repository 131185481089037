import { ConfigProvider, DatePicker } from "antd";
import { logEvent } from "firebase/analytics";
import moment from "moment/moment";
import React from "react";
import { Controller } from "react-hook-form";
import { FiDownload } from "react-icons/fi";
import { TbFilter, TbGridDots, TbRefresh, TbTableFilled } from "react-icons/tb";
import { analytics } from "../../../../../App";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { fruitCriteria, useFilter } from "../../contexts/filterContext";
import { pagePath } from "../../ListView";
import { APP_NAME } from "../../../../../configs/api";

const { RangePicker } = DatePicker;

export const fruitCountCriteria = [
  {
    label: "Terdapat abnormal",
    value: 10,
    type: "fltAbnormalCountGe",
  },
  {
    label: "Terdapat buah mentah",
    value: -2,
    type: "fltUnripeCountGe",
  },
  {
    label: "Kurang 15 dari Janjang",
    value: 14,
    type: "fltFruitCountLe",
  },
  {
    label: "Lebih dari 15 Janjang",
    value: 15,
    type: "fltFruitCountGe",
  },
  {
    label: "Data belum lengkap",
    value: 1,
    type: "fltIncomplete",
  },
  {
    label: "Tidak ada buah",
    value: -1,
    type: "fltFruitCountLe",
  },
];

const Filter = ({
  handleRefreshData,
  onFilter,
  showModal,
  isView,
  handleSetIsView,
  isFilterBy,
  setIsFilterBy,
  initialFilter,
  page,
}) => {
  const {
    control,
    setValue,
    user,
    users,
    estates,
    afdelings,
    blocks,
    searchUsers,
    searchEstates,
    searchAfdelings,
    searchBlocks,
    fetchBlockData,
    downloadExcelFile,
    downloading,
    fetchHarvestData,
    selectedEstate,
    selectedAfdeling,
  } = useFilter();

  return (
    <div className="mx-16 flex flex-row items-center justify-between">
      <div className="relative flex w-full flex-row flex-wrap items-center space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition -ml-8 w-fit bg-white p-10 md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />

        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-fit bg-white p-10"
          onClick={() => {
            logEvent(
              analytics,
              `refresh_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_button: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleRefreshData();
          }}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Reset Filter"
          value="Reset Filter"
          type="button"
          size="none"
          className="bordered-button-transition hidden w-fit bg-white p-10 md:block"
          onClick={() => {
            setIsFilterBy({
              ...initialFilter,
              pageIndex: page.current,
              fltCompany: user?.companyId,
            });
            setValue("username", null);
            setValue("estate", null);
            setValue("afdeling", null);
            setValue("block", null);
            setValue("anotherFilter", null);
            setValue("dateRange", null);
          }}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle={isView === "list" ? "Galeri" : "List"}
          value={isView === "list" ? "Galeri" : "List"}
          size="none"
          type="button"
          className="bordered-button-transition w-fit bg-white p-10"
          onClick={() => {
            logEvent(
              analytics,
              `grid_view_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_button: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
                selected_view: isView === "list" ? "grid" : "list",
                selected_view_on_page:
                  isView === "list"
                    ? `grid_${pagePath.toLowerCase().replace(/[-\s]/g, "_")}`
                    : `list_${pagePath.toLowerCase().replace(/[-\s]/g, "_")}`,
              }
            );
            handleSetIsView();
          }}
          icon={
            isView === "list" ? (
              <TbTableFilled className="text-18 text-gray-500" />
            ) : (
              <TbGridDots className="text-18 text-gray-500" />
            )
          }
        />
        <SelectHook
          name="username"
          data={users}
          control={control}
          placeholder="Pilih pengguna"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            searchUsers(searchValue);
          }}
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_user_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            onFilter({ type: "fltUser", value });
          }}
        />
        <SelectHook
          control={control}
          data={estates}
          name="estate"
          placeholder="Pilih kebun"
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            searchEstates(searchValue);
          }}
          afterOnChange={(data) => {
            logEvent(
              analytics,
              `filter_estate_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            onFilter({
              value: data?.value,
              type: "fltEstate",
            });
            searchAfdelings({
              estateId: data?.value,
              params: {
                pageSize: 99999,
              },
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        {selectedEstate?.value && (
          <SelectHook
            control={control}
            data={afdelings}
            name="afdeling"
            placeholder="Pilih wilayah"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              searchAfdelings(searchValue);
            }}
            afterOnChange={(data) => {
              logEvent(
                analytics,
                `filter_afdeling_button_${pagePath}_${window.location.host}`,
                {
                  page_title: `${pagePath}_${window.location.host}`,
                  page_location: window.location.href,
                  who_access_this_filter: `${
                    user?.username
                  }_at_${moment().format("D_MMM_YY")}`,
                }
              );
              onFilter({
                type: "fltAfdeling",
                value: data?.value,
              });
              searchBlocks({
                afdelingId: data?.value,
                params: {
                  pageSize: 99999,
                },
              });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}

        {selectedAfdeling?.value && (
          <SelectHook
            control={control}
            data={blocks}
            name="block"
            placeholder="Pilih blok"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              searchBlocks(searchValue);
            }}
            afterOnChange={(data) => {
              logEvent(
                analytics,
                `filter_block_button_${pagePath}_${window.location.host}`,
                {
                  page_title: `${pagePath}_${window.location.host}`,
                  page_location: window.location.href,
                  who_access_this_filter: `${
                    user?.username
                  }_at_${moment().format("D_MMM_YY")}`,
                }
              );
              onFilter({
                type: "fltBlock",
                value: data?.value,
              });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}
        <SelectHook
          control={control}
          data={fruitCriteria}
          name="anotherFilter"
          placeholder="Filter lainnya "
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(data) => {
            logEvent(
              analytics,
              `filter_additional_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            onFilter({
              type: data?.type || "clearOtherFilter",
              value: data?.value,
              filterHandler: async ({ type, updateFilter }) => {
                const clearOtherFilter = () =>
                  updateFilter({
                    fltFruitCountLe: undefined,
                    fltFruitCountGe: undefined,
                    fltUnripeCountLe: undefined,
                    fltUnripeCountGe: undefined,
                    fltIncomplete: undefined,
                    fltAbnormalCountGe: undefined,
                  });

                if (type === "clearOtherFilter") {
                  clearOtherFilter();
                }

                await clearOtherFilter();

                if (data?.type === "fltUnripeCountGe") {
                  await updateFilter({ [data?.type]: 1 });
                } else if (data?.type === "fltAbnormalCountGe") {
                  await updateFilter({ [data?.type]: 1 });
                } else if (data?.type === "fltFruitCountGe") {
                  await updateFilter({
                    [data?.type]: data?.value,
                    fltFruitCountLe: undefined,
                  });
                } else if (data?.type === "fltFruitCountLe") {
                  await updateFilter({
                    [data?.type]: data?.value,
                    fltFruitCountGe: undefined,
                  });
                } else {
                  await updateFilter({ [data?.type]: data?.value });
                }
              },
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary:
                      APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <div className="hidden md:inline">
                  <RangePicker
                    placeholder={["Dari tanggal", "sampai"]}
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(date) => {
                      logEvent(
                        analytics,
                        `filter_date_range_picker_button_${pagePath}_${window.location.host}`,
                        {
                          page_title: `${pagePath}_${window.location.host}`,
                          page_location: window.location.href,
                          who_access_this_filter: `${
                            user?.username
                          }_at_${moment().format("D_MMM_YY")}`,
                        }
                      );
                      onChange(date);
                      onFilter({
                        value: date,
                        type: "dateRange",
                        filterKeys: ["fltStartDate", "fltEndDate"],
                      });
                    }}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 8,
                      maxWidth: 250,
                      borderWidth: 1,
                      borderColor: "#e1e4e8",
                    }}
                  />
                </div>
              </ConfigProvider>
            );
          }}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Unduh Excel"
          value="Unduh Excel"
          size="none"
          type="button"
          isLoading={downloading}
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => downloadExcelFile(isFilterBy)}
          icon={<FiDownload className="text-18 text-gray-500" />}
        />
      </div>
    </div>
  );
};

export default Filter;
