import * as yup from "yup";

export const formSchema = yup.object().shape({
  fullname: yup
    .string("Nama lengkap diperlukan")
    .nullable()
    .required("Nama lengkap diperlukan"),
  email: yup
    .string("Email diperlukan")
    .nullable()
    .email("Harus mengandung kata @")
    .required("Email diperlukan"),
  username: yup
    .string()
    .nullable()
    .lowercase("username harus huruf kecil")
    .required("Username diperlukan")
    .min(4, "Panjang username harus minimal 4 karakter"),
  position: yup
    .object()
    .shape({
      label: yup.string().required("Jabatan diperlukan (from label)"),
      value: yup.string().required("Jabatan diperlukan"),
    })
    .nullable()
    .required("Jabatan diperlukan"),
  phone: yup.string().min(10, "Nomor handphone minimal 10 karakter").nullable(),
});
