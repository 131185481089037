import { create } from "zustand";
import { notification } from "antd";
import getCroppedImg from "../partials/EasyCropImage/helper/cropImage";
import { blobToBase64, blobToFile } from "../../../../../hooks/imageUtils";

export const useUploadImageStore = create((set, get) => ({
  photo: undefined,
  crop: { x: 0, y: 0 },
  rotation: 0,
  zoom: 1,
  croppedAreaPixels: null,
  croppedImage: null,
  setCroppedAreaPixels: (croppedAreaPixels) =>
    set({ croppedAreaPixels: croppedAreaPixels }),
  setCroppedImage: (croppedImage) => set({ croppedImage: croppedImage }),
  setZoom: (zoom) => set({ zoom: zoom }),
  setCrop: (coordinate) => set({ crop: coordinate }),
  setRotation: (degree) => set({ rotation: degree }),
  setRotatePlus: () => set((state) => ({ rotation: state.rotation + 90 })),
  setRotateMinus: () => set((state) => ({ rotation: state.rotation - 90 })),
  setPhoto: (photo) => set({ photo: photo }),
  getCroppedImage: async () => {
    try {
      const croppedImageBlob = await getCroppedImg(
        get().photo,
        get().croppedAreaPixels,
        get().rotation
      );

      const url = await blobToBase64(croppedImageBlob);
      const file = await blobToFile(croppedImageBlob, "croppedImage.png");

      get().setZoom(1);
      get().setCrop({ x: 0, y: 0 });
      get().setRotation(0);
      get().setCroppedImage(url);
      return { file, url };
    } catch (error) {
      return notification.error({
        message: "Gagal Memotong Gambar",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  readFile: (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.addEventListener("abort", () => resolve(null), false);
      reader.addEventListener("error", () => resolve(null), false);
      reader.readAsDataURL(file);
    });
  },
}));
