import axiosInstance from "./api";
import { COMPANIES_URL, MEMBER_URL, USER_URL } from "../configs/api";

export const getUsersApi = (params) =>
  axiosInstance.get(USER_URL, {
    params,
  });
export const getUserByIdApi = (id) => axiosInstance.get(USER_URL + id);
export const updateUserApi = (id, companyId, body) =>
  axiosInstance.put(`${COMPANIES_URL}${companyId}${MEMBER_URL}/${id}`, body);

export const deleteUserApi = (id, companyId) =>
  axiosInstance.delete(`${COMPANIES_URL}${companyId}${MEMBER_URL}/${id}`);

export const userLoginUrlApi = (id, companyId) =>
  axiosInstance.delete(
    `${COMPANIES_URL}${companyId}${MEMBER_URL}/${id}/login-helper-url`
  );

export const createUserApi = (companyId, body) =>
  axiosInstance.post(`${COMPANIES_URL}${companyId}${MEMBER_URL}`, body);
