import { ConfigProvider, Modal } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logEvent } from "firebase/analytics";
import moment from "moment";
import { useAlert } from "react-alert";
import { useHarvestingStore } from "../../../api/useHarvestingStore";
import { analytics } from "../../../App";
import FFB from "../../../assets/icons/ffb.svg";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { APP_NAME } from "../../../configs/api";
import { getHarvestigs } from "../../../features/harvestingSlice";
import { handleModalStarter } from "../../../features/modalSlice";
import { selectUser } from "../../../features/userSlice";
import useFilter from "../../../hooks/useFilter";
import FilterProvider from "./contexts/filterContext";
import { ImageViewerProvider } from "./contexts/imageViewerContext";
import Filter from "./partials/Filter/Filter";
import GalleryView from "./partials/GalleryView";
import ListHistoryHarvest from "./partials/ListHistoryHarvest/ListHistoryHarvest";
import ModalFilter from "./partials/ModalFilter/ModalFilter";
import ModalFilterFooter from "./partials/ModalFilter/ModalFilterFooter";
import ModalImagePreview from "./partials/ModalImagePreview";

export const pagePath = "riwayat_panen";

const Container = ({ children }) => (
  <FilterProvider>
    <ImageViewerProvider>{children}</ImageViewerProvider>
  </FilterProvider>
);

const ListView = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isView, setIsView] = useState("list");
  const initialFilter = {
    pageSize: 20,
    pageIndex: 0,
    fltCompany: undefined,
    fltUser: undefined,
    fltEstate: undefined,
    fltAfdeling: undefined,
    fltBlock: undefined,
    fltStartDate: undefined,
    fltEndDate: undefined,
    fltFruitCountLe: undefined,
    fltFruitCountGe: undefined,
    fltUnripeCountLe: undefined,
    fltUnripeCountGe: undefined,
    fltIncomplete: undefined,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };
  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({
    initialFilter,
  });
  const getHarvestings = useHarvestingStore((state) => state.getHarvestings);

  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );
  let paginationLength = Math.ceil(page?.total / isFilterBy.pageSize);
  const modalName = "Vehicle";
  const user = useSelector(selectUser);

  const [isOpenImagePreviewer, setIsOpenImagePreviewer] = useState(false);
  const [currentImageViewer, setCurrentImageViewer] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen((prev) => !prev);
  const handleOk = () => setIsModalOpen(false);

  const handleSetIsView = async () => {
    await setIsView((prevView) => {
      setIsFilterBy({ pageSize: prevView === "list" ? 128 : 20 });
      fetchData(0);
      return prevView === "list" ? "grid" : "list";
    });
  };

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    try {
      if (beforeFetch) {
        beforeFetch();
      }
      if (!isSilent) setRequest({ isLoading: true });
      const response = await dispatch(
        getHarvestigs({
          ...isFilterBy,
          pageIndex,
        })
      ).unwrap();
      const success = response?.success;
      const message = response?.message;
      if (!success) {
        setRequest({ isLoading: false });
        return alert.error(message);
      }
      setData(response?.records || []);
      const totalRecords = response?.totalRecords;
      setPage({
        current: pageIndex,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoading: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoading: false });
      return alert.error(error?.data);
    }
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const handleRefreshData = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    document.title = "Riwayat Panen - SawitWeb";

    logEvent(analytics, "page_view", {
      firebase_screen: `riwayat_panen_${window.location.host}`,
      page_title: `riwayat_panen_${window.location.host}`,
      user_id: user?.id,
      what_time_access_screen: `${
        user?.username
      }_access_riwayat_panen_at_${moment().format("ll")}`,
    });
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, [isFilterBy]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <Container>
      <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
            },
          }}
        >
          <Modal
            title="Filter"
            zIndex={10}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={showModal}
            wrapClassName="backdrop-blur-sm"
            footer={
              <ModalFilterFooter
                setIsFilterBy={setIsFilterBy}
                handleOk={handleOk}
                initialFilter={initialFilter}
                page={page}
              />
            }
          >
            <ModalFilter onFilter={onFilter} />
          </Modal>
          <ModalImagePreview
            images={data}
            isOpen={isOpenImagePreviewer}
            onOk={() => setIsOpenImagePreviewer(false)}
            onCancel={() => setIsOpenImagePreviewer(false)}
          />
        </ConfigProvider>
        <div>
          <Navbar
            leftContentTitle={
              <div className="flex flex-row items-center space-x-8">
                <img
                  src={FFB}
                  alt=""
                  className="hidden w-32 grayscale md:inline"
                />
                <p className="line-clamp-1">Riwayat Panen</p>
              </div>
            }
          />
        </div>
        <div>
          <Filter
            isView={isView}
            handleSetIsView={handleSetIsView}
            onFilter={onFilter}
            handleRefreshData={handleRefreshData}
            showModal={showModal}
            isFilterBy={isFilterBy}
            setIsFilterBy={setIsFilterBy}
            handleOk={handleOk}
            initialFilter={initialFilter}
            page={page}
          />
        </div>
        {isView === "list" ? (
          // <ListData
          //   data={data}
          //   refreshHandler={handleRefreshData}
          //   onFilter={onFilter}
          //   showModal={showModal}
          // />

          <ListHistoryHarvest
            isLoading={request.isLoading}
            data={data}
            handleRefreshData={handleRefreshData}
            page={page}
          />
        ) : (
          <GalleryView
            isLoading={request.isLoading}
            data={data}
            handleRefreshData={handleRefreshData}
          />
        )}
        <div className="-mt-8">
          <Pagination.Container>
            <Pagination.Records
              current={page.current}
              length={paginationLength}
              totalRecords={page?.total}
            />
            {paginationLength !== 1 && (
              <Pagination
                pageCount={paginationLength}
                onPageClick={handlePageClick}
              />
            )}
          </Pagination.Container>
        </div>
      </div>
    </Container>
  );
};

export default ListView;
