import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Drawer } from "antd";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TbGripVertical } from "react-icons/tb";
import { FiEye, FiEyeOff } from "react-icons/fi";

const SortableColumns = ({ column, toggleColumnVisibility }) => {
  const { id } = column;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const clickHandler = async (event) => {
    event.stopPropagation();
    await column.toggleVisibility();
    await toggleColumnVisibility(id);
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className="flex flex-row items-center justify-between space-x-8 rounded-8 bg-white py-8 px-8 hover:bg-gray-50 hover:shadow"
    >
      <div className="flex flex-row items-center space-x-8">
        <TbGripVertical className="text-24 text-gray-200" />
        <p className="font-regular text-14 text-gray-500">
          {column?.columnDef?.header}
        </p>
      </div>
      <div>
        {column.getIsVisible() ? (
          <FiEye className="text-24 text-gray-400" onClick={clickHandler} />
        ) : (
          <FiEyeOff className="text-24 text-gray-400" onClick={clickHandler} />
        )}
      </div>
    </div>
  );
};

const ColumnSettings = forwardRef(
  (
    { columns, state, isDrawer, setIsDrawer, sortedColumns, setSortedColumns },
    ref
  ) => {
    const { columnOrder, columnVisibility } = state;

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 3,
        },
      })
    );

    const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
      columnOrder.splice(
        columnOrder.indexOf(targetColumnId),
        0,
        columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
      );
      return [...columnOrder];
    };

    const toggleColumnVisibility = useCallback(
      (columnId) => {
        setSortedColumns(
          ref.current
            ?.getState()
            ?.columnOrder.map((item) =>
              ref.current?.getAllColumns().find((column) => column.id === item)
            )
        );
      },
      [columnVisibility, sortedColumns]
    );

    const toggleColumnAllVisibility = useCallback(
      (visibility) => {
        ref?.current?.toggleAllColumnsVisible(visibility);

        setTimeout(() => {
          setSortedColumns(
            ref.current
              ?.getState()
              ?.columnOrder.map((item) =>
                ref.current
                  ?.getAllColumns()
                  .find((column) => column.id === item)
              )
          );
        }, [800]);
      },
      [columnVisibility, sortedColumns]
    );

    const onDragEndHandler = useCallback(
      (event) => {
        const { active, over } = event;

        const newColumnOrder = reorderColumn(active.id, over.id, columnOrder);
        setColumnOrder(newColumnOrder);

        const newOrder = newColumnOrder.map((columnId) =>
          sortedColumns.find((column) => column.id === columnId)
        );
        setSortedColumns(newOrder);
      },
      [columnOrder, sortedColumns, columns]
    );

    useEffect(() => {
      setSortedColumns(ref?.current?.getAllColumns());
    }, []);

    return (
      <Drawer
        title="Pengaturan Table"
        placement="right"
        onClose={(prev) => setIsDrawer(!prev)}
        open={isDrawer}
        mask={false}
        style={{
          borderRadius: 24,
          boxShadow: "0 0 #0000",
          filter: "drop-shadow(0 0 #0000)",
        }}
        contentWrapperStyle={{
          margin: "16px 16px 16px 0",
          borderRadius: 24,
          boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.20)",
          filter: "drop-shadow(0 0 #0000)",
        }}
      >
        <div className="grid grid-cols-1 gap-16">
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-regular text-16 text-gray-200">Tampilkan</h2>
            <div
              className="cursor-pointer"
              onClick={() => toggleColumnAllVisibility(false)}
            >
              <p className="font-regular text-16 text-yellow-500">
                Sembunyi Semua
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={onDragEndHandler}
            >
              <SortableContext
                items={sortedColumns}
                strategy={verticalListSortingStrategy}
              >
                {sortedColumns
                  ?.filter((column) => column.getIsVisible() === true)
                  .map((column) => (
                    <SortableColumns
                      key={column.id}
                      column={column}
                      toggleColumnVisibility={toggleColumnVisibility}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
          <div className="h-1 w-full bg-gray-40"></div>
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-regular text-16 text-gray-200">Sembunyi</h2>
            <div
              className="cursor-pointer"
              onClick={() => toggleColumnAllVisibility(true)}
            >
              <p className="font-regular text-16 text-yellow-500">
                Tampilkan Semua
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={onDragEndHandler}
            >
              <SortableContext
                items={sortedColumns}
                strategy={verticalListSortingStrategy}
              >
                {sortedColumns
                  ?.filter((column) => column.getIsVisible() === false)
                  .map((column) => (
                    <SortableColumns
                      key={column.id}
                      column={column}
                      toggleColumnVisibility={toggleColumnVisibility}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </Drawer>
    );
  }
);

export default ColumnSettings;
