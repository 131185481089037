import "react-lazy-load-image-component/src/effects/blur.css";

import { Tooltip } from "antd";
import React, { useEffect } from "react";
import { TbPencil, TbTrash } from "react-icons/tb";

import { useImageSource } from "../../../../../../../../../../hooks/useImageSource";
import Swal from "sweetalert2";
import { useDimensionalTagStore } from "../../../../../../../../../../api/dimensionalTag/useDimensionalTagStore";
import { useModalStore } from "../../../../../Modal/Modal";
import ShowMore from "../../../../../../../../../../components/atoms/ShowMore";

const Item = ({ item, index, handleRefreshData }) => {
  const { source, setSource, setDownloadedImage } = useImageSource();
  const deleteDimensionalTag = useDimensionalTagStore(
    (state) => state.deleteDimensionalTag
  );
  const toggleModal = useModalStore((state) => state.toggleModal);

  const deleteHandler = () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda tidak akan dapat mengembalikan ini! ${item?.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus ini!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDimensionalTag({
          id: item?.id,
          onSuccess: () => {
            handleRefreshData();
            // Swal.fire("Terhapus!", "Data telah dihapus.", "success");
          },
        });
      }
    });
  };

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(item?.photoFileId, "main");
  }, [item?.photoFileId]);

  return (
    <tr
      className={`table-header-regular relative hover:bg-[rgba(215,161,55,0.2)] hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out`}
      key={index}
    >
      <td className="table-row-regular">{index + 1}</td>
      <td className="table-row-regular ">{item?.name}</td>
      <td className="table-row-regular ">{item?.itemPrefix}</td>
      <td className="table-row-regular ">{item?.description}</td>
      <td className="table-row-regular w-[300px] ">
        <div className="w-[300px]">
          <ShowMore
            className="flex flex-row flex-wrap"
            data={item?.items}
            maxItems={2}
            itemComponent={(item) => (
              <div className="mr-4 mb-4 flex flex-row items-center space-x-8 rounded-[4px] bg-gray-20 px-12 py-4">
                <span className="text-14 font-medium text-gray-500">
                  {item}
                </span>
              </div>
            )}
          />
        </div>
      </td>
      <td className="table-row-regular ">
        <div className="flex flex-row items-center space-x-16">
          <Tooltip placement="bottom" title="Edit" className="font-regular">
            <button
              className="rounded-[4px] p-8 outline-none"
              onClick={(event) => {
                event.stopPropagation();
                toggleModal({ data: item, isUpdate: true });
              }}
            >
              <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
          </Tooltip>

          <Tooltip placement="bottom" title="Hapus" className="font-regular">
            <button
              onClick={(event) => {
                event.stopPropagation();
                deleteHandler();
              }}
              className="rounded-[4px] p-8 outline-none"
            >
              <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default Item;
