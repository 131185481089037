import * as yup from "yup";

export const formSchema = yup.object().shape({
  startDate: yup.string().required("Tanggal harga berlaku wajib di isi"),
  buyerPartyPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Harga PKS wajib di isi"),
  maxPurchasePrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Batas atas wajib di isi"),
  minPurchasePrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Batas bawah wajib di isi"),
});
