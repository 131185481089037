import { ConfigProvider, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";
import { TbFilter, TbRefresh } from "react-icons/tb";
import { capitalize, debounce } from "lodash";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { FiDownload } from "react-icons/fi";
import InputHook from "../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { downloadExcel } from "react-export-table-to-excel";
import { formatNumericValue } from "../../../../../helpers/Common";
import moment from "moment";
import { selectUser } from "../../../../../features/userSlice";
import { toast } from "react-hot-toast";
import { useFfbStockMovementStore } from "../../../../../api/ffbStockMovement/useFfbStockMovementStore";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import { useSelector } from "react-redux";
import useExport from "../../../../../hooks/useExport";

const { RangePicker } = DatePicker;

const useGetAllDataRamp = () => {
  const user = useSelector(selectUser);
  const getFfbStockMovements = useFfbStockMovementStore(
    (state) => state.getFfbStockMovements
  );
  const [data, setData] = useState([]);

  const fetchData = ({ onSuccessFromParams }) => {
    getFfbStockMovements({
      silent: true,
      params: {
        pageSize: 999999,
        fltCompany: user?.companyId,
        sord: "desc",
        sidx: "syncInfo.createdTime",
      },
      onSuccess: (res) => {
        setData(res?.records);
        onSuccessFromParams(res?.records);
      },
    });
  };

  useEffect(() => {
    fetchData({});
  }, []);

  return { data, fetchData };
};

const Filter = ({ handleRefreshData, onFilter, showModal, data }) => {
  const { control } = useForm();
  const { users, fetchUsers } = useFilterDataDefaults();
  const { exportToCSV } = useExport();
  const onSearchUser = async (searchTerm) => {
    await fetchUsers({ name: searchTerm, options: { withoutAllItem: true } });
  };
  const { fetchData } = useGetAllDataRamp();
  const [isLoading, setIsLoading] = useState(false);

  // const request = debounce(async (searchTerm) => {
  //   onFilter({
  //     type: "fltFullname",
  //     value: searchTerm,
  //   });
  // }, 500);
  //
  // const debounceTextRequest = useCallback((searchTerm) => {
  //   request(searchTerm);
  // }, []);

  const excelDownloader = () => {
    const header = [
      "Tanggal Jeprin",
      "Tanggal Nota",
      "Nama Trader",

      "Netto(Kg)",
      "Rp/Kg Klaim",
      "Harga Klaim (Rp)",
      "Rp/Kg Jual PKS",
      "Harga Jual (Rp)",
      "Profit (Rp)",

      "Bruto(Kg)",
      "Tarra(Kg)",
      "Arus TBS",
      "Diterima Dari",
      "PKS",
      "Nopol",
      "TBS Diterima (Jjg)",
      "Penalti (Jjg)",
      "BJR Ramp (Kg)",
    ];

    setIsLoading(true);
    fetchData({
      onSuccessFromParams: async (payloads) => {
        try {
          const customPayloads = payloads?.map((item) => {
            const rowData = item;

            const calculateNettWeight = () => {
              const grossWeight = rowData?.measuredGrossWeightKg || 0;
              const taraWeight = rowData?.measuredTaraWeightKg || 0;

              const nettWeight =
                rowData?.measuredNettWeightKg || grossWeight - taraWeight || 0;
              return nettWeight;
            };

            const calculateBjrRamp = () => {
              const nettWeight = calculateNettWeight();
              const ffbCount = rowData?.ffbCount || 0;
              const pinaltyCount = rowData?.pinaltyCount || 0;

              if (ffbCount !== 0 && nettWeight !== 0) {
                return formatNumericValue(
                  nettWeight / (ffbCount - pinaltyCount),
                  {
                    thousandsSeparator: ",",
                    decimalSeparator: ".",
                  }
                );
              } else {
                return 0;
              }
            };

            const profit = rowData?.totalSellPrice - rowData?.totalPrice;

            return {
              "Tanggal Jeprin": moment(item?.operationDate).format("ll"),
              "Tanggal Nota": moment(item?.receiptDate).format("ll"),
              "Nama Trader": item?.fullname,

              "Netto(Kg)": formatNumericValue(item?.measuredNettWeightKg, {
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Rp/Kg Klaim": formatNumericValue(item?.unitPrice, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Harga Klaim (Rp)": formatNumericValue(item?.totalPrice, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Rp/Kg Jual PKS": formatNumericValue(item?.sellUnitPrice || 0, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Harga Jual (Rp)": formatNumericValue(item?.totalSellPrice || 0, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Profit (Rp)": formatNumericValue(profit || 0, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Bruto(Kg)": formatNumericValue(item?.measuredGrossWeightKg, {
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Tarra(Kg)": formatNumericValue(item?.measuredTaraWeightKg, {
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Arus TBS": capitalize(item?.logisticFlow),
              "Diterima Dari": item?.ffbSourceParty,
              PKS: item?.ffbDestinationParty,
              Nopol: item?.truckPlateNo,
              "TBS Diterima (Jjg)": formatNumericValue(item?.ffbCount || 0, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "Penalti (Jjg)": formatNumericValue(item?.pinaltyCount || 0, {
                decimals: 0,
                thousandsSeparator: ",",
                decimalSeparator: ".",
              }),
              "BJR Ramp (Kg)": calculateBjrRamp(),
            };
          });

          await exportToCSV(
            customPayloads,
            `riwayat_ramp_${moment().format("DD-MM-YYYY")}`,
            {},
            { isXLSX: true }
          );

          // downloadExcel({
          //   fileName: `riwayat_ramp_${moment().format("DD-MM-YYYY")}`,
          //   sheet: `data original`,
          //   tablePayload: {
          //     header,
          //     // accept two different data structures
          //     body: payloads?.map((item) => {
          //       const rowData = item;
          //
          //       const calculateNettWeight = () => {
          //         const grossWeight = rowData?.measuredGrossWeightKg || 0;
          //         const taraWeight = rowData?.measuredTaraWeightKg || 0;
          //
          //         const nettWeight =
          //           rowData?.measuredNettWeightKg ||
          //           grossWeight - taraWeight ||
          //           0;
          //         return nettWeight;
          //       };
          //
          //       const calculateBjrRamp = () => {
          //         const nettWeight = calculateNettWeight();
          //         const ffbCount = rowData?.ffbCount || 0;
          //         const pinaltyCount = rowData?.pinaltyCount || 0;
          //
          //         if (ffbCount !== 0 && nettWeight !== 0) {
          //           return formatNumericValue(
          //             nettWeight / (ffbCount - pinaltyCount)
          //           );
          //         } else {
          //           return 0;
          //         }
          //       };
          //
          //       const calculateProfit = () => {
          //         const totalPrice = rowData?.totalPrice || 0;
          //         const totalSellPrice = rowData?.totalSellPrice || 0;
          //
          //         const profit = totalSellPrice - totalPrice;
          //         return profit;
          //       };
          //
          //       return {
          //         operationDate: moment(item?.operationDate).format("ll"),
          //         receiptDate: moment(item?.receiptDate).format("ll"),
          //         createdBy: item?.fullname,
          //
          //         measuredNettWeightKg: item?.measuredNettWeightKg,
          //         unitPrice: item?.unitPrice,
          //         totalPrice: item?.totalPrice,
          //         sellUnitPrice: item?.sellUnitPrice,
          //         totalSellPrice: item?.totalSellPrice,
          //         profit: calculateProfit(),
          //
          //         measuredGrossWeightKg: item?.measuredGrossWeightKg,
          //         measuredTaraWeightKg: item?.measuredTaraWeightKg,
          //         logisticFlow: capitalize(item?.logisticFlow),
          //         ffbSourceParty: item?.ffbSourceParty,
          //         ffbDestinationParty: item?.ffbDestinationParty,
          //         truckPlateNo: item?.truckPlateNo,
          //         ffbCount: item?.ffbCount,
          //         pinaltyCount: item?.pinaltyCount,
          //         bjrCount: calculateBjrRamp(),
          //       };
          //     }),
          //   },
          // });

          setIsLoading(false);
          setTimeout(() => {
            toast.success("Berhasil mengunduh excel");
          }, 500);
        } catch (err) {
          setIsLoading(false);
          console.error(err);
        }
      },
    });
  };

  return (
    <div className="mx-16 mt-56 flex flex-row items-center justify-between md:mt-8">
      <div className="flex flex-row flex-wrap items-center space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition -ml-8 w-fit bg-white p-10 md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-fit bg-white p-10"
          onClick={() => handleRefreshData()}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <SelectHook
          name="username"
          data={users}
          control={control}
          placeholder="Pilih pengguna"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            onSearchUser(searchValue);
          }}
          afterOnChange={(value) => {
            onFilter({ type: "fltUser", value });
          }}
        />
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <div className="hidden md:inline">
                  <RangePicker
                    placeholder={["Dari tanggal", "sampai"]}
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(date) => {
                      onChange(date);
                      onFilter({
                        value: date,
                        type: "dateRange",
                        filterKeys: ["fltStartDate", "fltEndDate"],
                      });
                    }}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 8,
                      maxWidth: 250,
                      borderWidth: 1,
                      borderColor: "#e1e4e8",
                    }}
                  />
                </div>
              </ConfigProvider>
            );
          }}
        />
        <ButtonComponent
          disabled={isLoading}
          tooltipPlacement="bottom"
          tooltipTitle="Unduh Excel"
          value={isLoading ? "Loading..." : "Unduh Excel"}
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={excelDownloader}
          icon={<FiDownload className="text-18 text-gray-500" />}
        />

        {/*<InputHook*/}
        {/*  control={control}*/}
        {/*  name="logisticFlow"*/}
        {/*  placeholder="Masukkan alur logistic"*/}
        {/*  className="hidden max-w-[210px] md:inline"*/}
        {/*  inputClassName="input-mini-border pr-24 pl-8"*/}
        {/*  afterOnChange={debounceTextRequest}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default Filter;
