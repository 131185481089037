export const headersData = [
	{
		value: "TT",
		accessorKey: "plantedYear",
		type: "alphabet",
		// sorted: true,
	},
	{
		value: "BJR Acuan",
		accessorKey: "bjr",
		type: "alphabet",
		// sorted: true,
	},
];
