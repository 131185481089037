import { create } from "zustand";
import domToImage from "dom-to-image";
import moment from "moment/moment";

export const useSnapshotStore = create((set, get) => ({
  company: {},
  isSnapshot: false,
  setIsSnapshot: (isActive) => set({ isSnapshot: isActive }),
  captureComponent: (ref, name) => {
    const component = ref.current;
    if (!component) return;

    set({ isSnapshot: true }); // Set isSnapshot to true before capturing the screenshot

    // Tambahkan penundaan sebelum mengambil tangkapan layar
    setTimeout(() => {
      domToImage.toBlob(component).then((blob) => {
        const link = document.createElement("a");
        link.download = `${moment().format("D-MM-Y")} - ${name}.jpg`;
        link.href = URL.createObjectURL(blob);
        link.click();

        // Set isSnapshot to false after the screenshot is taken
        setTimeout(() => {
          set({ isSnapshot: false });
        }, 1500);
      });
    }, 1000); // Sesuaikan nilai penundaan jika diperlukan
  },
}));
