import { ConfigProvider, Segmented } from "antd";
import React, { useEffect, useState } from "react";

import Chart from "../../../../../components/atoms/Chart";
import moment from "moment";
import { options } from "./config/options";
import { useData } from "../../hooks/DataContext";
import { APP_NAME } from "../../../../../configs/api";

const ComparisonChart = ({ title }) => {
  const { comparisonChartData } = useData();
  const [datasets, setDatasets] = useState();
  const [labels, setLabels] = useState([]);
  const [segmentedSelected, setSegmentedSelected] = useState("Janjang");

  let customLabels = [];

  for (let i = 1; i <= 31; i++) {
    let value = (i < 10 ? "0" : "") + i;
    customLabels.push(value);
  }

  const getTonase = (type) => {
    let datasets = [
      {
        label: "Produksi Kebun",
        data: [],
        backgroundColor: "#1A4980",
      },
      {
        label: "Penerimaan PKS",
        data: [],
        backgroundColor: "#07C103",
      },
      {
        label: "Penalti",
        data: [],
        backgroundColor: "#DDBE65",
      },
    ];

    // if (type.toLowerCase() === "janjang") {
    // 	datasets = [
    // 		...datasets,
    // 		{
    // 			label: "Penalti",
    // 			data: [],
    // 			backgroundColor: "#DDBE65",
    // 		},
    // 	];
    // }

    if (comparisonChartData?.length < 1) {
      setDatasets(datasets);
      return;
    }

    const labels =
      comparisonChartData?.map(
        (data) =>
          moment(data?.harvestingDate).format("DD").padStart(2, "0") || 0
      ) || [];
    setLabels(labels);

    const kebunTonase =
      comparisonChartData?.map((data) => data?.harvestingEstWeightKg || 0) ||
      [];
    const kebunJanjang =
      comparisonChartData?.map((data) => data?.harvestingBunchCount || 0) || [];
    const penaltiJanjang =
      comparisonChartData?.map((data) => data?.millRejectedBunchCount || 0) ||
      [];
    const pksTonase =
      comparisonChartData?.map((data) => data?.millNetWeightKg || 0) || [];
    const pksJanjang =
      comparisonChartData?.map((data) => data?.millAcceptedBunchCount || 0) ||
      [];

    const objectMapping = {
      tonase: {
        kebun: kebunTonase,
        pks: pksTonase,
        penalti: [],
      },
      janjang: {
        kebun: kebunJanjang,
        pks: pksJanjang,
        penalti: penaltiJanjang,
      },
    };

    console.log("Datasets: ", datasets);
    console.log("Type: ", type);

    datasets = [
      {
        ...datasets[0],
        data: objectMapping[type.toLowerCase()].kebun,
      },
      {
        ...datasets[1],
        data: objectMapping[type.toLowerCase()].pks,
      },
      {
        ...datasets[2],
        data: objectMapping[type.toLowerCase()].penalti,
      },
    ];

    setDatasets(datasets);
  };

  useEffect(() => {
    getTonase("Janjang");
  }, [comparisonChartData?.length]);

  return (
    <div className="relative mx-auto grid h-full w-full grid-cols-1 grid-rows-[minmax(0px,auto)_minmax(0px,1fr)] gap-0 rounded-8 bg-white px-8 pt-8 shadow md:grid-rows-[minmax(0px,0.1fr)_minmax(0px,1fr)] md:gap-13 md:px-16 md:pt-16">
      <div className="grid h-full grid-cols-1 gap-4 md:grid-cols-3 ">
        <div className="flex items-center justify-center md:justify-start">
          <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
            {title}
          </h3>
        </div>
        <div className="flex items-center justify-center">
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Inter-Regular",
                colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
                colorBgElevated:
                  APP_NAME === "sawit-web" ? "#F8E7AC" : "#F8E7E7",
                colorText: "785F37",
                borderRadius: 999,
              },
            }}
          >
            <Segmented
              defaultValue="Janjang"
              value={segmentedSelected}
              options={["Tonase", "Janjang"]}
              className="w-fit"
              onChange={(data) => {
                setSegmentedSelected(data);
                getTonase(data);
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      <div className="h-[250px] md:h-auto">
        <Chart.Bar
          labels={labels}
          options={options}
          withoutMainDatasets={true}
          moreDatasets={datasets}
        />
      </div>
    </div>
  );
};

export default ComparisonChart;
