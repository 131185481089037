import React from "react";
import { formatNumericValue } from "../../../../../helpers/Common";
import Card from "./partials/Card";

const Summaries = () => {
  const cards = [
    {
      title: "Tonase Pembelian",
      description: "",
      data: [
        {
          timeFrame: "Hari Ini",
          value: formatNumericValue(70355, {
            decimals: 0,
          }),
          suffix: "Kg",
        },
        {
          timeFrame: "s.d Hari Ini",
          value: formatNumericValue(213274, {
            decimals: 0,
          }),
          suffix: "Kg",
        },
      ],
    },
    {
      title: "Nilai Pembelian",
      description: "",
      data: [
        {
          timeFrame: "Hari ini",
          value: formatNumericValue(146193000, {
            prefix: "Rp ",
            decimals: 0,
          }),
        },
        {
          timeFrame: "s.d Hari ini",
          value: formatNumericValue(390984520, {
            prefix: "Rp ",
            decimals: 0,
          }),
        },
      ],
    },
    {
      title: "Tonase Penjualan",
      description: "",
      data: [
        {
          timeFrame: "Hari ini",
          value: formatNumericValue(100134, {
            decimals: 0,
          }),
          suffix: "Kg",
        },
        {
          timeFrame: "s.d Hari ini",
          value: formatNumericValue(314793, {
            decimals: 0,
          }),
          suffix: "Kg",
        },
      ],
    },
    {
      title: "Nilai Penjualan",
      description: "",
      data: [
        {
          timeFrame: "Hari ini",
          value: formatNumericValue(208421240, {
            prefix: "Rp ",
            decimals: 0,
          }),
        },
        {
          timeFrame: "s.d Hari ini",
          value: formatNumericValue(397716645, {
            prefix: "Rp ",
            decimals: 0,
          }),
        },
      ],
    },
  ];

  return (
    <div className="mx-8 grid grid-cols-1 gap-8 md:grid-cols-[0.8fr,1.2fr]">
      {cards.map((card, index) => (
        <Card data={card} key={index} />
      ))}
    </div>
  );
};

export default Summaries;
