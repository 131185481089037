import SalesTable from "./SalesTable";
export default SalesTable;

export const data = [
  {
    name: "Indoraya",
    totalNota: 5,
    totalNetto: 44895,
    totalRp: 94279000,
    detail_of_all_notas: [
      {
        name: "Jaka",
        totalNetto: 8910,
        priceAgreement: 2065,
        totalRp: 18399150,
      },
      {
        name: "Jaka",
        totalNetto: 8660,
        priceAgreement: 2065,
        totalRp: 17882900,
      },
      {
        name: "Jaka",
        totalNetto: 9710,
        priceAgreement: 2065,
        totalRp: 20051150,
      },
      {
        name: "Jaka",
        totalNetto: 8620,
        priceAgreement: 2065,
        totalRp: 17800300,
      },
      {
        name: "Sandi",
        totalNetto: 8995,
        priceAgreement: 2100,
        totalRp: 18889500,
      },
    ],
  },
  {
    name: "ABS",
    totalNota: 1,
    totalNetto: 10640,
    totalRp: 22113000,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 8910,
        priceAgreement: 2065,
        totalRp: 18399150,
      },
      {
        name: "Indoraya",
        totalNetto: 8660,
        priceAgreement: 2065,
        totalRp: 17882900,
      },
      {
        name: "Indoraya",
        totalNetto: 9710,
        priceAgreement: 2065,
        totalRp: 20051150,
      },
      {
        name: "Indoraya",
        totalNetto: 8620,
        priceAgreement: 2065,
        totalRp: 17800300,
      },
    ],
  },
  {
    name: "GMK",
    totalNota: 1,
    totalNetto: 8995,
    totalRp: 18235000,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 5580,
        priceAgreement: 2065,
        totalRp: 11469400,
      },
      {
        name: "Indoraya",
        totalNetto: 5580,
        priceAgreement: 2065,
        totalRp: 11469400,
      },
      {
        name: "Indoraya",
        totalNetto: 5620,
        priceAgreement: 2065,
        totalRp: 11547900,
      },
    ],
  },
  {
    name: "SST",
    totalNota: 1,
    totalNetto: 35604,
    totalRp: 73344240,
    detail_of_all_notas: [
      {
        name: "Indoraya",
        totalNetto: 8680,
        priceAgreement: 2065,
        totalRp: 17924000,
      },
    ],
  },
];
