import * as yup from "yup";

export const formSchema = yup.object().shape({
	name: yup
		.string("nama estate diperlukan")
		.required("nama estate diperlukan")
		.typeError("nama estate diperlukan"),
	areaHa: yup
		.string("luas area (Ha) diperlukan")
		.required("luas area (Ha) diperlukan")
		.typeError("luas area (Ha) diperlukan"),
	province: yup
		.object()
		.shape({
			label: yup.string().required("Province diperlukan"),
			value: yup.string().required("Province diperlukan"),
		})
		.nullable()
		.required("Province diperlukan"),
	city: yup
		.object()
		.shape({
			label: yup.string().required("Kota diperlukan"),
			value: yup.string().required("Kota diperlukan"),
		})
		.nullable()
		.required("Kota diperlukan"),
	subdistrict: yup
		.object()
		.shape({
			label: yup.string().required("Kecamatan diperlukan"),
			value: yup.string().required("Kecamatan diperlukan"),
		})
		.nullable()
		.required("Kecamatan diperlukan"),
	village: yup
		.object()
		.shape({
			label: yup.string().required("Kelurahan diperlukan"),
			value: yup.string().required("Kelurahan diperlukan"),
		})
		.nullable()
		.required("Kelurahan diperlukan"),
});
