import React, { useEffect, useReducer, useState } from "react";

import Filter from "./partials/Filter/Filter";
import ListData from "./partials/ListData/ListData";
import Navbar from "../../../../components/atoms/Navbar";
import Pagination from "../../../../components/atoms/Pagination";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter/ModalFilter";
import useFilter from "../../../../hooks/useFilter";
import { useUserStore } from "../../../MasterWorkerPage/hooks/userStore";
import { TbBuildingFactory2 } from "react-icons/tb";
import MultiModal from "./partials/Modal";
import { usePartiesStore } from "../../../../api/parties/usePartiesStore";
import ModalTag from "./partials/ModalTag";

export const modalEstateName = "Member";

const ListView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [user] = useUserStore((state) => [state.user, state.getUsers]);

  const initialFilter = {
    pageSize: 20,
    pageIndex: undefined,
    fltCompany: undefined,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };
  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({
    initialFilter,
  });
  const [getParties] = usePartiesStore((state) => [state.getParties]);

  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );

  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  let paginationLength = Math.ceil(page?.total / isFilterBy.pageSize);

  const showModal = () => setIsModalOpen((prev) => !prev);
  const handleOk = () => setIsModalOpen(false);

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    if (beforeFetch) {
      beforeFetch();
    }
    if (!isSilent) setRequest({ isLoading: true });
    await getParties({
      params: {
        ...isFilterBy,
        pageIndex,
      },
      onSuccess: (response) => {
        setData(response?.records);
        const totalRecords = response?.totalRecords;
        setPage({
          current: pageIndex,
          total: totalRecords,
        });
        setTimeout(() => {
          setRequest({ isLoading: false });
        }, 800);
      },
    });
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const handleRefreshData = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, [isFilterBy]);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
      {/* <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider> */}
      <MultiModal onRefreshData={handleRefreshData} />
      <ModalTag onRefreshData={handleRefreshData} />
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <TbBuildingFactory2 className="mr-16 hidden text-24 text-gray-500 md:inline" />
              <p className="line-clamp-1">Data PKS</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListData
        isLoading={request.isLoading}
        data={data}
        handleRefreshData={handleRefreshData}
        page={page}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLength}
            totalRecords={page?.total}
          />
          {paginationLength !== 1 && (
            <Pagination
              pageCount={paginationLength}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
