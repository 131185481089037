import axiosInstance from "../api";
import { BLOCKS_URL, HARVESTINGS_URL } from "../../configs/api";

export const getBlocksApi = (params) =>
  axiosInstance.get(BLOCKS_URL, {
    params,
  });
export const getBlockApi = (id) => axiosInstance.get(`${BLOCKS_URL}/${id}`);
export const updateBlockApi = (id, body) =>
  axiosInstance.put(`${BLOCKS_URL}/${id}`, body);
export const createBlockApi = (body) => axiosInstance.post(BLOCKS_URL, body);
