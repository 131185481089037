import { faker } from "@faker-js/faker";
import { reduceCount } from "../../../helpers/Common";

export const getBlocks = (count) => {
	let data = [];
	Array.from(Array(count).keys()).map((item, index) =>
		data.push({
			id: index + 1,
			block: `${faker.random
				.alpha(1)
				?.toUpperCase()}${faker.datatype.number({
				min: 1,
				max: 20,
			})}`,
			tph: faker.datatype.number({ min: 1, max: 20 }),
			total_pokok: faker.datatype.number({ min: 1, max: 20 }),
			total_bjr: faker.datatype.number({ min: 1.5, max: 15.5 }),
			jenis_bibit: faker.helpers.arrayElement([
				"Dura",
				"Pisifera",
				"Tenera ",
				"Nigrescens ",
				"Virecens ",
				"Albenscens ",
			]),
			jenis_tanah: faker.helpers.arrayElement([
				"Aluvial",
				"Latosol",
				"Organosol",
			]),
			topografi_lahan: faker.helpers.arrayElement([
				"Datar",
				"Miring",
				"Sangat miring",
			]),
			created_at: faker.date.between(
				"2022-01-01T00:00:00.000Z",
				"2022-12-30T00:00:00.000Z"
			),
		})
	);

	return data;
};

export const masterEstateRows = () => {
	let data = [];

	Array.from(Array(faker.datatype.number({ min: 20, max: 20 })).keys()).map(
		(item, index) => {
			const totalBlock = faker.datatype.number({ min: 1, max: 10 });
			const blocks = getBlocks(totalBlock);

			data.push({
				id: index + 1,
				estate: `KJP`,
				divisi: `Divisi 0${index + 1}`,
				wilayah: faker.address.city(),
				desa: faker.address.cityName(),
				luas: faker.datatype.number({ min: 1, max: 300 }),
				total_blok: totalBlock,
				total_tph: reduceCount(blocks, "tph"),
				total_pokok: reduceCount(blocks, "total_pokok"),
				created_at: faker.date.between(
					"2022-01-01T00:00:00.000Z",
					"2022-12-30T00:00:00.000Z"
				),
				blocks: blocks,
			});
		}
	);

	return data;
};
