import React, { useRef } from "react";
import Filter from "./partials/Filter";
import Summaries from "./partials/Summaries";
import PurchaseTable from "./partials/PurchaseTable";
import SalesTable from "./partials/SalesTable";
import Heading from "./partials/Heading";

const DashboardView = () => {
  const componentRef = useRef();

  return (
    <div
      ref={componentRef}
      className="relative grid max-h-screen min-h-screen w-full grid-cols-1 gap-8 bg-gray-30 md:grid-rows-[max-content,max-content,0.8fr,1fr,1fr]"
    >
      <Heading />
      <Filter ref={componentRef} />
      <Summaries />
      <PurchaseTable />
      <SalesTable />
    </div>
  );
};

export default DashboardView;
