import * as yup from "yup";

export const formSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Kata sandi lama tidak boleh kosong")
    .min(4, "Panjang kata sandi lama harus minimal 4 karakter")
    .max(12, "Kata sandi lama tidak boleh lebih dari 12 karakter"),
  newPassword: yup
    .string()
    .required("Kata sandi baru tidak boleh kosong")
    .min(4, "Panjang kata sandi baru harus minimal 4 karakter")
    .max(12, "Kata sandi baru tidak boleh lebih dari 12 karakter"),
  confirmNewPassword: yup
    .string()
    .required("Konfirmasi kata sandi baru tidak boleh kosong")
    .min(4, "Panjang kata sandi baru harus minimal 4 karakter")
    .max(12, "Kata sandi baru tidak boleh lebih dari 12 karakter")
    .oneOf([yup.ref("newPassword")], "Sandi baru tidak cocok"),
});
