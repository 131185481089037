import React, { useEffect } from "react";
import { TbX } from "react-icons/tb";
import InputHook from "../../../../../components/atoms/InputHook";
import { useForm } from "react-hook-form";

const ModalFilter = ({ onFilter }) => {
  const { register, watch, handleSubmit, setValue } = useForm();
  const [inputNames, setInputNames] = React.useState({
    fullname: undefined,
  });

  const onSubmit = (e) => {
    onFilter({
      value: e.name,
      type: "name",
    });
  };

  useEffect(() => {
    const subscription = watch((v) =>
      setInputNames({
        fullname: v.name,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="grid grid-cols-1 gap-13">
      <InputHook
        register={register}
        name="name"
        placeholder="name estate"
        className="w-full"
        inputClassName="input-mini-border pr-24 pl-8"
        rightIcon={
          <TbX
            className="cursor-pointer text-16 text-gray-500 hover:text-red-600"
            onClick={() => {
              setValue("name", undefined);
              onFilter({
                value: undefined,
                type: "name",
              });
            }}
          />
        }
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit(onSubmit)(event);
          }
        }}
        onSubmit={() => {
          onFilter({
            value: inputNames.name,
            type: "name",
          });
        }}
      />
    </div>
  );
};

export default ModalFilter;
