import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import LOGO_KEBUN from "../../../../../assets/icons/vertical.png";
import LOGO_KJP from "../../../../../assets/icons/logo-kjp.jpeg";
import Navbar from "../../../../../components/atoms/Navbar";

const Heading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSnapshot = useSnapshotStore((state) => state.isSnapshot);
  const user = useUserStore((state) => state.user);

  return (
    <div
      className={`mx-auto flex w-full flex-col-reverse items-center justify-between space-y-reverse md:grid md:space-y-0 md:px-[2%] ${
        isSnapshot
          ? "md:grid-cols-[1fr,1fr,max-content]"
          : "md:grid-cols-[1fr,max-content]"
      }`}
    >
      <div className="hidden flex-row items-center space-x-16 md:flex">
        <ButtonComponent
          value="Chemist"
          className={`rounded-8 text-14 ${
            location.pathname === "/dashboard-chemist"
              ? "bg-yellow-500 text-white"
              : "bg-white text-yellow-600"
          } shadow`}
          onClick={() => navigate("/dashboard-chemist")}
        />
      </div>
      {isSnapshot && (
        <div className="my-16 flex flex-row items-center justify-between space-x-8 md:my-0">
          <h1 className="text-24 font-bold text-gray-500">SNAPSHOT</h1>
          {user?.companyName ===
            "Koperasi Jasa Profesi Cipta Prima Sejahtera" && (
            <div className="flex flex-row items-center space-x-8 md:hidden">
              <img src={LOGO_KEBUN} className="h-[55px] w-auto" />
              <img src={LOGO_KJP} className="h-[52px] w-auto" />
            </div>
          )}
        </div>
      )}
      <div className="w-full md:hidden">
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <p className="line-clamp-1">Chemist</p>
            </div>
          }
        />
      </div>
      <div className="flex flex-row items-center space-x-24">
        {user?.companyName ===
          "Koperasi Jasa Profesi Cipta Prima Sejahtera" && (
          <div className="hidden flex-row items-center space-x-8 md:flex">
            <img src={LOGO_KEBUN} className="h-[55px] w-auto" />
            <img src={LOGO_KJP} className="h-[52px] w-auto" />
          </div>
        )}

        <div className="hidden md:inline">
          <Navbar onlyUserProfile={true} />
        </div>
      </div>
    </div>
  );
};

export default Heading;
