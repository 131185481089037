import React from "react";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../ButtonComponent";
import EasyCropImage from "../../../../templates/Profile/ProfilePicture/partials/EasyCropImage";
import { useUploadImageStore } from "../../../../templates/Profile/ProfilePicture/hooks/useUplodaImageStore";

const CroppingTool = ({
  isModalOpen,
  showModal,
  handleOk,
  onCancel,
  onSave,
}) => {
  const getCroppedImage = useUploadImageStore((state) => state.getCroppedImage);

  const onSaveImage = async () => {
    await handleOk();
    const { file, url } = await getCroppedImage();
    onSave({ file, url });
  };

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#D7A137" },
      }}
    >
      <Modal
        title="Filter"
        zIndex={100}
        width={600}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={showModal}
        wrapClassName="backdrop-blur-sm"
        footer={
          <div className="flex flex-row items-center justify-end space-x-16 pt-16">
            <ButtonComponent
              value="Batalkan"
              type="submit"
              size="none"
              className="bordered-button-transition border-gray-100 px-16 py-8 text-16 text-gray-500"
              onClick={() => {
                onCancel && onCancel();
                handleOk();
              }}
            />
            <ButtonComponent
              value="Simpan"
              type="submit"
              size="none"
              className="button-transition px-16 py-8 text-16"
              onClick={onSaveImage}
            />
          </div>
        }
      >
        <EasyCropImage />
      </Modal>
    </ConfigProvider>
  );
};

export default CroppingTool;
