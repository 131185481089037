import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import {
  getPartiesApi,
  createPartyApi,
  updatePartyApi,
  deletePartyApi,
} from "./partiesApi";

export const usePartiesStore = create((set, get) => ({
  parties: [],
  party: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getParties: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getPartiesApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Data PKS",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ tags: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan Data PKS",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateParty: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updatePartyApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update PKS",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update PKS`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update PKS",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createParty: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createPartyApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Menambahkan PKS",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Menambahkan PKS`;
        const toastMessageNextStep = `Selanjutnya buka halaman 'Pengaturan Harga'`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
        toast.success(toastMessageNextStep, {
          duration: calculateToastDuration(toastMessageNextStep),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Menambahkan PKS",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  deleteParty: async ({ id, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await deletePartyApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Hapus PKS",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Hapus PKS`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Hapus Create PKS",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
