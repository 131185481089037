import { useReducer } from "react";
import { useFileAttachmentStore } from "./fileAttachmentStore";

export const useImageSource = (initialState) => {
  const [source, setSource] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    initialState || {
      main: null,
      mainVisible: false,
      second: null,
      secondVisible: false,
      third: null,
      thirdVisible: false,
    }
  );
  const getImage = useFileAttachmentStore((state) => state.getImage);

  const setDownloadedImage = async (photoFileId, type, silent = true) => {
    try {
      if (type && photoFileId) {
        const response = await getImage(photoFileId, silent);
        setSource({ [type]: response });
      } else {
        setSource({ [type]: null });
      }
    } catch (error) {
      return console.warn(error?.data);
    }
  };

  return { source, setSource, setDownloadedImage };
};
