import { ConfigProvider, DatePicker } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

import SelectHook from "../../../../../components/atoms/SelectHook";
import { APP_NAME } from "../../../../../configs/api";
import { fruitCriteria, useFilter } from "../../contexts/filterContext";

const { RangePicker } = DatePicker;

const ModalFilter = ({ onFilter }) => {
  const {
    control,
    users,
    estates,
    afdelings,
    blocks,
    searchAfdelings,
    searchBlocks,
    selectedEstate,
    selectedAfdeling,
  } = useFilter();

  return (
    <div className="grid grid-cols-1 gap-13">
      <SelectHook
        name="username"
        data={users}
        control={control}
        placeholder="Pilih pengguna"
        menuStyles={{ padding: 5 }}
        className="w-full"
        afterOnChange={(value) => {
          onFilter({ type: "fltUser", value });
        }}
      />
      <SelectHook
        control={control}
        data={estates}
        name="estate"
        placeholder="Pilih kebun"
        className="w-full"
        afterOnChange={(data) => {
          onFilter({
            value: data?.value,
            type: "fltEstate",
          });
          searchAfdelings({ estate: data.value });
        }}
        menuStyles={{ padding: 5 }}
      />
      {selectedEstate?.value && (
        <SelectHook
          control={control}
          data={afdelings}
          name="afdeling"
          placeholder="Pilih wilayah"
          className="w-full"
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "fltAfdeling",
            });
            searchBlocks({ afdelingId: data?.value });
          }}
          menuStyles={{ padding: 5 }}
        />
      )}

      {selectedAfdeling?.value && (
        <SelectHook
          control={control}
          data={blocks}
          name="block"
          placeholder="Pilih blok"
          className="w-full"
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "fltBlock",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
      )}
      <SelectHook
        control={control}
        data={fruitCriteria}
        name="anotherFilter"
        placeholder="Filter lainnya "
        className="w-full"
        afterOnChange={(data) => {
          onFilter({
            type: data?.type || "clearOtherFilter",
            value: data?.value,
            filterHandler: async ({ type, updateFilter }) => {
              const clearOtherFilter = () =>
                updateFilter({
                  fltFruitCountLe: undefined,
                  fltFruitCountGe: undefined,
                  fltUnripeCountLe: undefined,
                  fltUnripeCountGe: undefined,
                  fltIncomplete: undefined,
                  fltAbnormalCountGe: undefined,
                });

              if (type === "clearOtherFilter") {
                clearOtherFilter();
              }

              await clearOtherFilter();

              if (data?.type === "fltUnripeCountGe") {
                await updateFilter({ [data?.type]: 1 });
              } else if (data?.type === "fltAbnormalCountGe") {
                await updateFilter({ [data?.type]: 1 });
              } else if (data?.type === "fltFruitCountGe") {
                await updateFilter({
                  [data?.type]: data?.value,
                  fltFruitCountLe: undefined,
                });
              } else if (data?.type === "fltFruitCountLe") {
                await updateFilter({
                  [data?.type]: data?.value,
                  fltFruitCountGe: undefined,
                });
              } else {
                await updateFilter({ [data?.type]: data?.value });
              }
            },
          });
        }}
        menuStyles={{ padding: 5 }}
      />
      <Controller
        name="dateRange"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary:
                    APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
                  colorTextQuaternary: "#e1e4e8",
                  fontFamily: "Inter-Regular",
                  fontSize: 16,
                  colorTextPlaceholder: "#6B6F77",
                },
              }}
            >
              <RangePicker
                placeholder={["Dari tanggal", "sampai"]}
                format="DD/MM/YYYY"
                value={value}
                onChange={(date) => {
                  onChange(date);
                  onFilter({
                    value: date,
                    type: "dateRange",
                    filterKeys: ["fltStartDate", "fltEndDate"],
                  });
                }}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#e1e4e8",
                }}
              />
            </ConfigProvider>
          );
        }}
      />
    </div>
  );
};

export default ModalFilter;
