export const seijelai2012 = {
  type: "FeatureCollection",
  name: "seijelai_tt2012kav_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: "1",
        Luas: 0.752,
        DESA: "Sei Jelai",
        Nm_Pemilik: "H. Jaya Fitiyani B",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 96",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73920211489262, -3.716775409361415],
              [114.739641291870129, -3.717336335042878],
              [114.739838127797626, -3.717646031120334],
              [114.739409446670777, -3.718051757798623],
              [114.738822055119201, -3.71717149696894],
              [114.73920211489262, -3.716775409361415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "2",
        Luas: 0.923,
        DESA: "Sei Jelai",
        Nm_Pemilik: "Faridan Alfiannorr",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 99",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740524331603666, -3.715739093548549],
              [114.741381386352742, -3.71719766883591],
              [114.741018380643155, -3.717424294641444],
              [114.740116139383872, -3.715920091482077],
              [114.740524331603666, -3.715739093548549],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "3",
        Luas: 0.914,
        DESA: "Sei Jelai",
        Nm_Pemilik: "Imam Sutiono",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 97",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.743116677303178, -3.716452842176309],
              [114.742699388199668, -3.716557851695485],
              [114.742222403720064, -3.714921485958812],
              [114.742647235387423, -3.714805191943969],
              [114.743116677303178, -3.716452842176309],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "4",
        Luas: 1.015,
        DESA: "Sei Jelai",
        Nm_Pemilik: "Hj. Yuni Muryati",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 37",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.743608448177412, -3.71632908922079],
              [114.743116677303178, -3.716452842176309],
              [114.742647235387423, -3.714805191943969],
              [114.743085227136916, -3.714685295184305],
              [114.743608448177412, -3.71632908922079],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "5",
        Luas: 0.993,
        DESA: "Sei Jelai",
        Nm_Pemilik: "Agus Rianto",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 98",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.743534638498048, -3.714562272095417],
              [114.74406358415392, -3.716214555032938],
              [114.743608448177412, -3.71632908922079],
              [114.743085227136916, -3.714685295184305],
              [114.743534638498048, -3.714562272095417],
            ],
          ],
        ],
      },
    },
  ],
};
