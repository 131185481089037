import {
	createAverageFruit,
	updateAverageFruit,
} from "../../../../../features/averageFruitSlice";
import { useDispatch, useSelector } from "react-redux";

import Content from "./partials/Content";
import Modal from "../../../../../components/atoms/Modal";
import React from "react";
import { formSchema } from "./schema/formSchema";
import moment from "moment/moment";
import { omitBy } from "lodash";
import { selectModal } from "../../../../../features/modalSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";

const MultiModal = ({ isOpen, toggleModal, type, onRefreshData }) => {
	const UPDATE_TYPE = type.toLowerCase().includes("ubah");
	const dispatch = useDispatch();
	const alert = useAlert();
	const modalName = "AverageFruitMonitoring";
	const modal = useSelector(selectModal);
	const user = useSelector(selectUser);

	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
		clearErrors,
		watch,
		setValue,
	} = useForm();

	const watchFields = watch();

	const handleCreateAverageFruit = async (data) => {
		try {
			const response = await dispatch(createAverageFruit(data)).unwrap();
			if (!response.success) {
				alert.error(response?.message);
			}
			alert.success("Data bjr berhasil dibuat");
			toggleModal();
			onRefreshData();
		} catch (error) {
			alert.error("Gagal membuat data bjr, coba lagi.");
		}
	};

	const handleUpdateAverageFruit = async (data) => {
		try {
			const response = await dispatch(updateAverageFruit(data)).unwrap();
			if (!response.success) {
				alert.error(response?.message);
			}
			alert.success("Data bjr berhasil dibuat");
			toggleModal();
			onRefreshData();
		} catch (error) {
			alert.error("Gagal membuat data bjr, coba lagi.");
		}
	};

	const onSubmit = (data) => {
		let requestData = {
			...data,
			companyId: user?.companyId,
			companyName: user?.companyName,
			afdelingId: data.afdeling.value,
			afdelingName: data.afdeling.label,
			estateId: data.estate.value,
			blockId: data.block.value,
			estateName: data.estate.label,
			blockName: data.block.label,
			plantingYear: parseInt(data.plantingYear),
			month: data.month.value,
			year: data.year.value,
		};

		if (UPDATE_TYPE) {
			requestData = omitBy(requestData, (v) => v == null);
			return handleUpdateAverageFruit(requestData);
		}

		requestData = omitBy(requestData, (v) => v == null);
		requestData = { ...requestData, id: uuidv4() };
		handleCreateAverageFruit(requestData);
	};

	const handleDefaultValue = () => {
		let state = modal[modalName]?.params?.item;
		console.log(state);
		clearErrors();
		if (UPDATE_TYPE) {
			reset();
			const currentData = {
				...state,
				estate: { label: state.estateName, value: state.estateId },
				afdeling: {
					label: state.afdelingName,
					value: state.afdelingId,
				},
				block: { label: state.blockName, value: state.blockId },
				month: {
					label: moment()
						.month(state.month - 1)
						.format("MMMM"),
					value: state.month,
				},
				year: { label: state.year, value: state.year },
			};
			reset(currentData);
		} else {
			clearErrors();
			reset();
			reset({
				plantingYear: null,
				month: null,
				year: null,
				estate: null,
				bjr: null,
			});
		}
	};

	React.useEffect(() => handleDefaultValue(), [type, isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={toggleModal} title={type}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Content>
					<Content
						register={register}
						control={control}
						errors={errors}
						bjr={watchFields.bjr}
						watch={watch}
						isOpen={isOpen}
						setValue={setValue}
					/>
				</Modal.Content>
				<Modal.Footer toggleModal={toggleModal} type={type} />
			</form>
		</Modal>
	);
};

export default MultiModal;
