import { ConfigProvider, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectHook from "../../../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../../../features/blockSlice";
import { getEstates } from "../../../../../../../features/estateSlice";
import { getYears } from "../../../../../../../helpers/Common";
import { selectUser } from "../../../../../../../features/userSlice";
import { useForm } from "react-hook-form";

const ModalContent = () => {
  const { control } = useForm();
  // const { handleOnFilter } = useData();
  const handleOnFilter = () => {};
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetEstates({});
    return () => (isMounted = false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <div className="grid grid-cols-1 gap-13">
        <SelectHook
          name="estate"
          data={[]}
          control={control}
          placeholder="Pilih PKS"
          menuStyles={{ padding: 5 }}
          className="w-full"
          afterOnChange={(value) => {
            // handleOnFilter({ type: "estate", value });
            // if (value !== null) {
            //   handleGetAfdelings({ estate: value.value });
            // } else {
            //   setAfdelings([]);
            // }
          }}
        />{" "}
        <SelectHook
          name="estate"
          data={[]}
          control={control}
          placeholder="Pilih Trader"
          menuStyles={{ padding: 5 }}
          className="w-full"
          afterOnChange={(value) => {
            // handleOnFilter({ type: "estate", value });
            // if (value !== null) {
            //   handleGetAfdelings({ estate: value.value });
            // } else {
            //   setAfdelings([]);
            // }
          }}
        />
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Pilih tanggal"
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#e1e4e8",
          }}
          onChange={(value) => {
            handleOnFilter({ type: "date", value });
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default ModalContent;
