import moment from "moment";

export function formatNumericValue(value, options = {}) {
  const {
    prefix = "",
    suffix = "",
    decimals = 2,
    thousandsSeparator = ".",
    decimalSeparator = ",",
    negativeInRed = false,
  } = options;

  // Check if the value is numeric
  if (typeof value !== "number" || isNaN(value)) {
    return "NaN";
  }

  // Apply rounding and convert to a string with the specified number of decimals
  const formattedValue = value.toFixed(decimals);

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = formattedValue.split(".");

  // Add thousands separator to the integer part
  const integerWithSeparator = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandsSeparator
  );

  // Combine integer and decimal parts with the decimal separator
  const result =
    integerWithSeparator + (decimalPart ? decimalSeparator + decimalPart : "");

  // Add prefix and suffix
  const formattedResult = `${prefix}${result}${suffix}`;

  // Apply red color to negative values if required
  if (negativeInRed && value < 0) {
    return `<span style="color: red;">${formattedResult}</span>`;
  }

  return formattedResult;
}

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const calculateToastDuration = (message) => {
  // Calculate the length of the message
  const messageLength = message.length;

  // Adjust the duration based on the message length
  // You can tweak the values according to your preference
  const duration = Math.max(messageLength * 40, 2000);

  return duration;
};

export function cn(...cns) {
  return cns.join(" ");
}

export function variant(base, variants) {
  return function builder(props) {
    const classes = [];

    Object.entries(props).forEach(([key, value]) => {
      if (!(key in variants)) {
        return console.warn(`Unknown prop '${key}'`);
      }

      return cn(base, ...classes);
    });
  };
}

export const MONTHS = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const getYears = () => {
  let years = [];
  const now = moment().format("Y");
  for (let index = 1990; index < parseInt(now) + 1; index++) {
    years.push({
      label: index,
      value: index,
    });
  }
  return years.reverse();
};

export const areArrayHaveNull = (arr, numberOfArray) => {};

export const valuesInObjectToNull = (obj) => {
  const newObj = Object.keys(obj).reduce((accumulator, key) => {
    return { ...accumulator, [key]: null };
  }, {});

  return newObj;
};

export const currencyMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, "");
  // value = value.replace(/(\d)(\d{2})$/, "$1. $2");
  value = value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
  e.target.value = value;
  return e;
};

export const omit = (obj, arr) =>
  Object?.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

export const parseToFloat = (number, fraction) => {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: fraction ? fraction : 2,
    maximumFractionDigits: fraction ? fraction : 2,
  }).format(number);
};

export const parseFloatHelper = (e) => parseFloat(e?.toFixed(2));

export const getFilterDataLocal = (label, data, key) => {
  let newData = [{ label: `Semua ${label}`, value: "all" }];
  data?.reduce((a, b) => {
    const found = newData?.find((itemSearch) => itemSearch?.value == b[key]);

    if (!found) {
      return newData?.push({
        label: b[key],
        value: b[key],
      });
    }
  }, []);

  return newData;
};

export const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const Number = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return x;
  }
};

export const GlobalDebug = (function () {
  var savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function (debugOn, suppressAll) {
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

export function objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

export const reduceCount = (data, key) => {
  return data?.reduce((total, item) => total + item[key], 0);
};

export const getRandomColor = () => {
  // light color ---
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

export function extractNumericValue(currencyString) {
  const numericValue = currencyString.replace(/\D/g, "");
  return parseInt(numericValue, 10);
}
