export const batalang125 = {
  type: "FeatureCollection",
  name: "Batalang_Kepemilikan_125_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Woko Wahtoto",
        No_SHM: 287.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "5",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Woko Wahtoto",
        Nm_Pemil_2: "Woko Wahtoto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936106382071941, -3.904622484432493],
              [114.937006544883303, -3.904625028794703],
              [114.93700147823688, -3.906433224943779],
              [114.93610131350222, -3.906430679550602],
              [114.936103848045178, -3.905526582002084],
              [114.936104319924482, -3.905358237458092],
              [114.936106382071941, -3.904622484432493],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Narjito",
        No_SHM: 285.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "6",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Narjito",
        Nm_Pemil_2: "M. Bahrun N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937006544883303, -3.904625028794703],
              [114.937906708210591, -3.904627572191286],
              [114.937901643487422, -3.906435769370895],
              [114.93700147823688, -3.906433224943779],
              [114.937006544883303, -3.904625028794703],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Istiana",
        No_SHM: 278.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "7",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Istiana",
        Nm_Pemil_2: "Istiana",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937906708210591, -3.904627572191286],
              [114.938806872053476, -3.904630114622236],
              [114.938801809253633, -3.906438312831914],
              [114.937901643487422, -3.906435769370895],
              [114.937906708210591, -3.904627572191286],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yudi W",
        No_SHM: 260.0,
        Nama_SHM: "Sujiono",
        No_Kav: "8",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yudi W",
        Nm_Pemil_2: "Yudi Waningsih",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938806872053476, -3.904630114622236],
              [114.939707036411846, -3.904632656087496],
              [114.9397019755353, -3.906440855326846],
              [114.938801809253633, -3.906438312831914],
              [114.938806872053476, -3.904630114622236],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sagenah",
        No_SHM: 294.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "9",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sagenah",
        Nm_Pemil_2: "Yani Septiani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.939707036411846, -3.904632656087496],
              [114.940523059459224, -3.904634959158028],
              [114.940607201285431, -3.904635196587185],
              [114.940602142332182, -3.90644339685569],
              [114.9397019755353, -3.906440855326846],
              [114.939707036411846, -3.904632656087496],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ganis Dianto",
        No_SHM: 305.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "10",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ganis Dianto",
        Nm_Pemil_2: "Ganis Dianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940607201285431, -3.904635196587185],
              [114.941507366674017, -3.904637736121217],
              [114.941502309644051, -3.906445937418445],
              [114.940602142332182, -3.90644339685569],
              [114.940607201285431, -3.904635196587185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fatmawati",
        No_SHM: 280.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "11",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fatmawati",
        Nm_Pemil_2: "Fatmawati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.941507366674017, -3.904637736121217],
              [114.94240753257732, -3.904640274689602],
              [114.942402477470722, -3.906448477015104],
              [114.941502309644051, -3.906445937418445],
              [114.941507366674017, -3.904637736121217],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yani S",
        No_SHM: 297.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "12",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yani S",
        Nm_Pemil_2: "Sugenah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94240753257732, -3.904640274689602],
              [114.943307698995227, -3.90464281229234],
              [114.94330264581194, -3.906451015645668],
              [114.942402477470722, -3.906448477015104],
              [114.94240753257732, -3.904640274689602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hamdan F",
        No_SHM: 283.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "13",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hamdan F",
        Nm_Pemil_2: "Hamdan Fuadi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.943307698995227, -3.90464281229234],
              [114.944207865927424, -3.904645348929427],
              [114.944202814667477, -3.906453553310131],
              [114.94330264581194, -3.906451015645668],
              [114.943307698995227, -3.90464281229234],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Imam S",
        No_SHM: 293.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "14",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Imam S",
        Nm_Pemil_2: "Wiwik Widya H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.944207865927453, -3.904645348929427],
              [114.945108033373756, -3.904647884600859],
              [114.945102984037149, -3.906456090008509],
              [114.944202814667506, -3.906453553310131],
              [114.944207865927453, -3.904645348929427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ismugiono",
        No_SHM: 265.0,
        Nama_SHM: "Adrianto",
        No_Kav: "1",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ismugiono",
        Nm_Pemil_2: "Aris Munandar",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.943312750121251, -3.902834608854711],
              [114.944212915131018, -3.902837144464426],
              [114.944207865927453, -3.904645348929427],
              [114.943307698995227, -3.90464281229234],
              [114.943312750121251, -3.902834608854711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Haris W.W",
        No_SHM: 255.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "2",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Haris W.W",
        Nm_Pemil_2: "Haris Wahyu W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.944212915131018, -3.902837144464426],
              [114.945113080654863, -3.902839679108934],
              [114.945108033373756, -3.904647884600859],
              [114.944207865927453, -3.904645348929427],
              [114.944212915131018, -3.902837144464426],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "I Gede A.S",
        No_SHM: 251.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "3",
        Luas: 2.0023,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "I Gede A.S",
        Nm_Pemil_2: "I Gede A. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.945113080654892, -3.902839679108934],
              [114.945113086258417, -3.902837671216974],
              [114.94601325229398, -3.902840204895135],
              [114.94600820119733, -3.90465046824308],
              [114.945108033237148, -3.904647933537278],
              [114.945108033373756, -3.904647884600859],
              [114.945113080654892, -3.902839679108934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Bahrun N",
        No_SHM: 292.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "4",
        Luas: 2.1898,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "M. Bahrun N",
        Nm_Pemil_2: "M. Bahrun N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94601325229398, -3.902840204895135],
              [114.946321911747944, -3.902841073449746],
              [114.946377343164443, -3.902945860908312],
              [114.947420731878324, -3.903448017775206],
              [114.946763271804855, -3.904648872641166],
              [114.946761237714014, -3.904652587911138],
              [114.94600820119733, -3.90465046824308],
              [114.94601325229398, -3.902840204895135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Dili A. Timor",
        No_SHM: 300.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "15",
        Luas: 1.511,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.236,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.946008201197344, -3.90465046824308],
              [114.946005727113246, -3.905536886442188],
              [114.946002371627628, -3.905540442606513],
              [114.945141203716872, -3.906453111690857],
              [114.945102878911754, -3.906493728376477],
              [114.945102984037121, -3.906456090008509],
              [114.945108033237148, -3.904647933537278],
              [114.946008201197344, -3.90465046824308],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sri Endah A",
        No_SHM: 276.0,
        Nama_SHM: "Andrianto",
        No_Kav: "16",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sri Endah A",
        Nm_Pemil_2: "Wiwik Widya H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934300985581515, -3.906425585866054],
              [114.935201149283714, -3.906428133191348],
              [114.935196076725319, -3.908236327193725],
              [114.934295911098985, -3.908233778836512],
              [114.934300985581515, -3.906425585866054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Inas T.F",
        No_SHM: 272.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "17",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935201149283714, -3.906428133191348],
              [114.936101313502235, -3.906430679550602],
              [114.936096242868004, -3.908238874584399],
              [114.93519607672529, -3.908236327193725],
              [114.935201149283714, -3.906428133191348],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Salmah",
        No_SHM: 257.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "18",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Salmah",
        Nm_Pemil_2: "Salmah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936101313502235, -3.906430679550602],
              [114.93700147823688, -3.906433224943779],
              [114.936996409526827, -3.908241421008548],
              [114.936096242868004, -3.908238874584399],
              [114.936101313502235, -3.906430679550602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 253.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "19",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93700147823688, -3.906433224943779],
              [114.937901643487422, -3.906435769370895],
              [114.93789657670159, -3.90824396646617],
              [114.936996409526827, -3.908241421008548],
              [114.937001093963943, -3.906570335087737],
              [114.93700147823688, -3.906433224943779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sari Sg",
        No_SHM: 274.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "20",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sari Sg",
        Nm_Pemil_2: "Sari Sg",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937901643487422, -3.906435769370895],
              [114.938801809253661, -3.906438312831914],
              [114.938796744392008, -3.908246510957278],
              [114.93789657670159, -3.90824396646617],
              [114.937901643487422, -3.906435769370895],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang S",
        No_SHM: 298.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "21",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Bambang S",
        Nm_Pemil_2: "Umi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938801809253661, -3.906438312831914],
              [114.9397019755353, -3.906440855326846],
              [114.939696912597853, -3.908249054481837],
              [114.938796744392008, -3.908246510957278],
              [114.938801809253661, -3.906438312831914],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Salmah",
        No_SHM: 281.0,
        Nama_SHM: "Adrianto",
        No_Kav: "22",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Salmah",
        Nm_Pemil_2: "Salmah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.9397019755353, -3.906440855326846],
              [114.940602142332182, -3.90644339685569],
              [114.940597081318913, -3.908251597039857],
              [114.939696912597853, -3.908249054481837],
              [114.9397019755353, -3.906440855326846],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nadan",
        No_SHM: 307.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "23",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Nadan",
        Nm_Pemil_2: "Nadan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940602142332182, -3.90644339685569],
              [114.941502309644108, -3.906445937418445],
              [114.941497250555059, -3.908254138631338],
              [114.940597081318913, -3.908251597039857],
              [114.940602142332182, -3.90644339685569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Umi R. I.S",
        No_SHM: 250.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "24",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Umi R. I.S",
        Nm_Pemil_2: "Umi Rahmah I. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.941502309644079, -3.906445937418445],
              [114.942402477470722, -3.906448477015104],
              [114.942397420305909, -3.908256679256278],
              [114.941497250555059, -3.908254138631338],
              [114.941502309644079, -3.906445937418445],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sa'adah D",
        No_SHM: 271.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "25",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sa'adah D",
        Nm_Pemil_2: "Sa'adah Doroni",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.942402477470722, -3.906448477015104],
              [114.943302645811968, -3.906451015645668],
              [114.943297590571362, -3.908259218914672],
              [114.942397420305909, -3.908256679256278],
              [114.942402477470722, -3.906448477015104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Didik Y",
        No_SHM: 299.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "26",
        Luas: 2.1929,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.205,
        Grup: null,
        Nm_Pemil_1: "Didik Y",
        Nm_Pemil_2: "Didik Yulianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94343895797526, -3.908257146774326],
              [114.943436632676139, -3.908259611109097],
              [114.943297590571362, -3.908259218914672],
              [114.943302645811968, -3.906451015645668],
              [114.944202814667477, -3.906453553310147],
              [114.945102984037121, -3.906456090008509],
              [114.945102878911754, -3.906493728376477],
              [114.944200028604044, -3.90745056823147],
              [114.94343895797526, -3.908257146774326],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Farhat",
        No_SHM: 258.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "27",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Farhat",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934295911098985, -3.908233778836512],
              [114.93519607672529, -3.908236327193725],
              [114.935191002101519, -3.910044521111665],
              [114.934290834550112, -3.910041971722595],
              [114.934295911098985, -3.908233778836512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Melly M",
        No_SHM: 301.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "28",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93519607672529, -3.908236327193725],
              [114.936096242868004, -3.908238874584399],
              [114.936091170169277, -3.910047069533764],
              [114.935191002101519, -3.910044521111665],
              [114.93519607672529, -3.908236327193725],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sumardino",
        No_SHM: 270.0,
        Nama_SHM: "Andrianto",
        No_Kav: "29",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sumardino",
        Nm_Pemil_2: "Sumardino",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936096242868032, -3.9082388745844],
              [114.936996409526856, -3.908241421008548],
              [114.936991338753202, -3.91004961698889],
              [114.936091170169306, -3.910047069533764],
              [114.936096242868032, -3.9082388745844],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ina A",
        No_SHM: 284.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "30",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ina A",
        Nm_Pemil_2: "Ina Agustina",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936996409526856, -3.908241421008548],
              [114.93789657670159, -3.90824396646617],
              [114.937891507853038, -3.910052163477038],
              [114.936991338753202, -3.91004961698889],
              [114.936996409526856, -3.908241421008548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "I Gede A. S",
        No_SHM: 290.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "31",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "I Gede A. S",
        Nm_Pemil_2: "I Gede A. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93789657670159, -3.90824396646617],
              [114.938796744392008, -3.908246510957261],
              [114.938791677468558, -3.910054708998208],
              [114.937891507853038, -3.910052163477038],
              [114.93789657670159, -3.90824396646617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Alfian",
        No_SHM: 302.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "32",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Alfian",
        Nm_Pemil_2: "Alfian",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938796744392008, -3.908246510957261],
              [114.939696912597867, -3.90824905448182],
              [114.939691847599534, -3.910057253552396],
              [114.938791677468558, -3.910054708998208],
              [114.938796744392008, -3.908246510957261],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sari Sg",
        No_SHM: 275.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "33",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sari Sg",
        Nm_Pemil_2: "Sari Sg",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.939696912597853, -3.90824905448182],
              [114.940597081318941, -3.908251597039841],
              [114.940592018245667, -3.910059797139616],
              [114.939691847599477, -3.910057253552412],
              [114.939696912597853, -3.90824905448182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Austin M",
        No_SHM: 259.0,
        Nama_SHM: "Sujiono",
        No_Kav: "34",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Austin M",
        Nm_Pemil_2: "Austin Murphiati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940597081318941, -3.908251597039841],
              [114.941497250555031, -3.908254138631322],
              [114.941492189406887, -3.91006233975983],
              [114.940592018245667, -3.910059797139616],
              [114.940597081318941, -3.908251597039841],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hari S",
        No_SHM: 279.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "37",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hari S",
        Nm_Pemil_2: "Bela Aris. ST",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930690169512502, -3.910031764496658],
              [114.93159033499623, -3.910034317753579],
              [114.931585250603163, -3.911842507456871],
              [114.930685083193495, -3.911839953166278],
              [114.930690169512502, -3.910031764496658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hadi M",
        No_SHM: 295.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "38",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hadi M",
        Nm_Pemil_2: "Hadi Mulyanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93159033499623, -3.910034317753579],
              [114.932490500997233, -3.91003687004354],
              [114.932485418530106, -3.911845060780058],
              [114.931585250603163, -3.911842507456871],
              [114.93159033499623, -3.910034317753579],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Haris W. W",
        No_SHM: 288.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "39",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Haris W. W",
        Nm_Pemil_2: "Haris Wahyu W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932490500997233, -3.91003687004354],
              [114.933390667515269, -3.910039421366559],
              [114.933385586974111, -3.91184761313585],
              [114.932485418530106, -3.911845060780058],
              [114.932490500997233, -3.91003687004354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Lou Sari",
        No_SHM: 269.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "40",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Lou Sari",
        Nm_Pemil_2: "Louh Sari",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933390667515269, -3.910039421366559],
              [114.934290834550112, -3.910041971722595],
              [114.934285755934923, -3.911850164524214],
              [114.933385586974111, -3.91184761313585],
              [114.933390667515269, -3.910039421366559],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Amrozi",
        No_SHM: 306.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "41",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Amrozi",
        Nm_Pemil_2: "Amrozi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934290834550112, -3.910041971722595],
              [114.935191002101519, -3.910044521111665],
              [114.935185925412299, -3.911852714945161],
              [114.934285755934923, -3.911850164524214],
              [114.934290834550112, -3.910041971722595],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 267.0,
        Nama_SHM: "Sujiono",
        No_Kav: "42",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935191002101519, -3.910044521111665],
              [114.936091170169306, -3.910047069533764],
              [114.936086095406054, -3.911855264398689],
              [114.935185925412299, -3.911852714945161],
              [114.935191002101519, -3.910044521111665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fatmawati",
        No_SHM: 264.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "43",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fatmawati",
        Nm_Pemil_2: "Fatmawati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936091170169306, -3.910047069533764],
              [114.936991338753202, -3.91004961698889],
              [114.936986265915934, -3.911857812884797],
              [114.936086095406054, -3.911855264398689],
              [114.936091170169306, -3.910047069533764],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Lili F",
        No_SHM: 303.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "44",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Lili F",
        Nm_Pemil_2: "Lily Febriyani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936991338753202, -3.910049616988906],
              [114.937891507853038, -3.910052163477055],
              [114.937886436941739, -3.911860360403478],
              [114.93698626591592, -3.911857812884797],
              [114.936991338753202, -3.910049616988906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sagenah",
        No_SHM: 310.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "45",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sagenah",
        Nm_Pemil_2: "Sugenah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937891507853038, -3.910052163477055],
              [114.938791677468529, -3.910054708998226],
              [114.938786608483227, -3.911862906954732],
              [114.937886436941739, -3.911860360403478],
              [114.937891507853038, -3.910052163477055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Fauzan",
        No_SHM: 304.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "46",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "M. Fauzan",
        Nm_Pemil_2: "M. Fauzan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938791677468529, -3.910054708998226],
              [114.939691847599505, -3.910057253552412],
              [114.939686780540185, -3.911865452538554],
              [114.938786608483227, -3.911862906954732],
              [114.938791677468529, -3.910054708998226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wasito P",
        No_SHM: 273.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "47",
        Luas: 1.8774,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.872,
        Grup: null,
        Nm_Pemil_1: "Wasito P",
        Nm_Pemil_2: "Wasito Pramono",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.929635346292613, -3.911839500691979],
              [114.9296372081144, -3.911836978524049],
              [114.929784916301301, -3.911837397908264],
              [114.930685083193495, -3.911839953166278],
              [114.930680815580601, -3.913356523965099],
              [114.930680292353301, -3.913356456959917],
              [114.930500313937713, -3.913333408671427],
              [114.929781701773635, -3.912979351549426],
              [114.929781044552271, -3.91297902773896],
              [114.929303558176443, -3.912288966126189],
              [114.929635346292613, -3.911839500691979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 266.0,
        Nama_SHM: "Sujiono",
        No_Kav: "48",
        Luas: 1.7398,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.737,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930685083193495, -3.911839953166278],
              [114.931585250603192, -3.911842507456871],
              [114.931580667586587, -3.913471759714271],
              [114.931580184880929, -3.913471697898852],
              [114.930680815580601, -3.913356523965099],
              [114.930685083193495, -3.911839953166278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Harmadi B.S",
        No_SHM: 309.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "49",
        Luas: 1.9087,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.906,
        Grup: null,
        Nm_Pemil_1: "Harmadi B.S",
        Nm_Pemil_2: "Nagang Kita S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931585250603163, -3.911842507456871],
              [114.932485418530106, -3.911845060780058],
              [114.93248033399486, -3.913653251432044],
              [114.93221503646636, -3.913652498714355],
              [114.93194014210188, -3.91351779403939],
              [114.931580667586587, -3.913471759714271],
              [114.931580184880929, -3.913471697898852],
              [114.931585250603163, -3.911842507456871],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Riwo C",
        No_SHM: 261.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "50",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Riwo C",
        Nm_Pemil_2: "Riwo C",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932485418530106, -3.911845060780058],
              [114.933385586974111, -3.911847613135816],
              [114.933380504365701, -3.913655804820601],
              [114.93248033399486, -3.913653251432044],
              [114.932485418530106, -3.911845060780058],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yulianor H",
        No_SHM: 262.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "51",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yulianor H",
        Nm_Pemil_2: "Yulianor H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933385586974111, -3.911847613135816],
              [114.934285755934894, -3.911850164524197],
              [114.934280675253362, -3.913658357241296],
              [114.933380504365701, -3.913655804820601],
              [114.933385586974111, -3.911847613135816],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Umi R. I. S",
        No_SHM: 252.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "52",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Umi R. I. S",
        Nm_Pemil_2: "Umi rahmah I. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934285755934894, -3.911850164524197],
              [114.935185925412299, -3.911852714945144],
              [114.935180846657602, -3.913660908694127],
              [114.934280675253362, -3.913658357241296],
              [114.934285755934894, -3.911850164524197],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Inas T.F",
        No_SHM: 308.0,
        Nama_SHM: "Andrianto",
        No_Kav: "53",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935185925412299, -3.911852714945144],
              [114.936086095406026, -3.911855264398673],
              [114.936081018578207, -3.91366345917909],
              [114.935180846657602, -3.913660908694127],
              [114.935185925412299, -3.911852714945144],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Melly M",
        No_SHM: 289.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "54",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Melly M",
        Nm_Pemil_2: "Melly M",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936086095406026, -3.911855264398689],
              [114.93698626591592, -3.911857812884797],
              [114.936981191015008, -3.913666008696199],
              [114.936081018578207, -3.913663459179106],
              [114.936086095406026, -3.911855264398689],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hani H",
        No_SHM: 249.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "55",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hani H",
        Nm_Pemil_2: "Hani Hadiyati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93698626591592, -3.911857812884797],
              [114.937886436941739, -3.911860360403478],
              [114.937881363967691, -3.913668557245417],
              [114.936981191015008, -3.913666008696199],
              [114.93698626591592, -3.911857812884797],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 312.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "56",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937886436941739, -3.911860360403478],
              [114.938786608483227, -3.911862906954732],
              [114.938781537436043, -3.913671104826759],
              [114.937881363967691, -3.913668557245417],
              [114.937886436941739, -3.911860360403478],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fery W. J",
        No_SHM: 254.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "57",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fery W. J",
        Nm_Pemil_2: "Fery Wahyu J",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938786608483227, -3.911862906954732],
              [114.939686780540214, -3.911865452538554],
              [114.939681711419922, -3.913673651440222],
              [114.938781537436043, -3.913671104826759],
              [114.938786608483227, -3.911862906954732],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 268.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "58",
        Luas: 1.4985,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.494,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "PT. ASA",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93221829968158, -3.913654097765598],
              [114.932215036466417, -3.913652498714355],
              [114.93248033399486, -3.913653251432044],
              [114.933380504365672, -3.913655804820617],
              [114.934280675253362, -3.913658357241296],
              [114.934277550892801, -3.914766307260421],
              [114.933989517540766, -3.914752443772104],
              [114.933378604083444, -3.914331661485827],
              [114.932830246673618, -3.913953965739734],
              [114.9324799709742, -3.913782322726935],
              [114.93221829968158, -3.913654097765598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hastawan H",
        No_SHM: 282.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "59",
        Luas: 1.2698,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.269,
        Grup: null,
        Nm_Pemil_1: "Hastawan H",
        Nm_Pemil_2: "Hastawan H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934280675253362, -3.913658357241296],
              [114.935180846657602, -3.913660908694127],
              [114.935177455625052, -3.914869347711313],
              [114.934583565345207, -3.914781036100193],
              [114.934277550892801, -3.914766307260421],
              [114.934280675253362, -3.913658357241296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 291.0,
        Nama_SHM: "Sujiono",
        No_Kav: "60",
        Luas: 1.4092,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.408,
        Grup: null,
        Nm_Pemil_1: "Kawan Melly M",
        Nm_Pemil_2: "Miely",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935180846657602, -3.913660908694127],
              [114.936081018578236, -3.913663459179106],
              [114.936077255834064, -3.915003147417441],
              [114.935177456322819, -3.91486934781509],
              [114.935180846657602, -3.913660908694127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 311.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "61",
        Luas: 1.5433,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.543,
        Grup: null,
        Nm_Pemil_1: "Melly M",
        Nm_Pemil_2: "Eliya Nor",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936081018578236, -3.913663459179106],
              [114.936981191014979, -3.913666008696199],
              [114.936977167243924, -3.915099162741188],
              [114.936500249330251, -3.915066045952042],
              [114.936077318754926, -3.915003156773705],
              [114.936077255834064, -3.915003147417441],
              [114.936081018578236, -3.913663459179106],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 256.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "62",
        Luas: 1.6123,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.621,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "Lily F",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936981191014979, -3.913666008696199],
              [114.937881363967691, -3.913668557245417],
              [114.937877203904605, -3.915150810169413],
              [114.936977263803783, -3.915099169446188],
              [114.936977167243924, -3.915099162741188],
              [114.936981191014979, -3.913666008696199],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 296.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "63",
        Luas: 1.6206,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1621.0,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "PT. ASA",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937881363967691, -3.913668557245417],
              [114.938781537436043, -3.913671104826759],
              [114.938777707989487, -3.915036094553834],
              [114.938516442999912, -3.91515239462141],
              [114.937877318160105, -3.915150816725645],
              [114.937877203904605, -3.915150810169413],
              [114.937881363967691, -3.913668557245417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ariani N.A",
        No_SHM: 263.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "64",
        Luas: 1.2297,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.18,
        Grup: null,
        Nm_Pemil_1: "Ariani N.A",
        Nm_Pemil_2: "Ariani noor A",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938781537436043, -3.913671104826759],
              [114.939681711419922, -3.913673651440222],
              [114.939679559946683, -3.914440878317622],
              [114.939678328403645, -3.914442109168996],
              [114.939526011378121, -3.914594340595801],
              [114.939102198910376, -3.914891649766241],
              [114.938777780275657, -3.915036062376218],
              [114.938777707989487, -3.915036094553834],
              [114.938781537436043, -3.913671104826759],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fery W. J",
        No_SHM: 277.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "36",
        Luas: 1.2927,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.294,
        Grup: null,
        Nm_Pemil_1: "Fery W. J",
        Nm_Pemil_2: "Ferry Wahyu J",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930690169512502, -3.910031764496658],
              [114.930685083193495, -3.911839953166278],
              [114.929784916301273, -3.911837397908264],
              [114.9296372081144, -3.911836978524049],
              [114.929785482817067, -3.911636114039431],
              [114.930308180504625, -3.910928025552781],
              [114.930173944569319, -3.910032633841954],
              [114.930173594507167, -3.910030298830204],
              [114.930690169512502, -3.910031764496658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Bahrun N",
        No_SHM: 286.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "35",
        Luas: 1.7211,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nm_Pemil_1: "M. Bahrun N",
        Nm_Pemil_2: "M. Bahrun N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.942393676246226, -3.909364925710253],
              [114.94173670474315, -3.910061174461483],
              [114.941490476741095, -3.910060568356825],
              [114.941494888370812, -3.908252365514328],
              [114.942395058972352, -3.908254579962326],
              [114.942393676246226, -3.909364925710253],
            ],
          ],
        ],
      },
    },
  ],
};
