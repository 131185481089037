import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import InputHook from "../../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../../components/atoms/SelectHook";
import StepActions from "../StepActions/StepActions";
import TextAreaHook from "../../../../../../components/atoms/TextAreaHook";
import { formSchema } from "./schema/formSchema";
import { getCities } from "../../../../../../features/areaSlice";
import { onRegister } from "../../../../../../features/authSlice";
import { parseToFloat } from "../../../../../../helpers/Common";
import { selectArea } from "../../../../../../features/areaSlice";
import { useAlert } from "react-alert";
import { useData } from "../../hooks/DataContext";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { APP_NAME } from "../../../../../../configs/api";

const SecondContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const area = useSelector(selectArea);
  const { data, setValues, current, onNext, onPrev, stepLength } = useData();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: { ...data, country: "Indonesia" },
  });
  const watchAllFields = watch();

  const watchProvince = watch("province");
  const watchAreaHa = watch("areaHa");
  const provinceIsNull = watchProvince == undefined;

  const onSubmit = (data) => {
    setValues(data);
    let requestData = {
      ...data,
      city: data.city.value,
      province: data.province.value,
      contactName: data.createdBy,
      updatedBy: data.createdBy,
      contactPhone: data?.contactPhone?.replace(/[^0-9+]/g, ""),
      phone: data?.phone?.replace(/[^0-9+]/g, ""),
    };
    dispatch(onRegister(requestData))
      .unwrap()
      .then((data) => {
        const success = data.success;
        const message = data.message;
        if (!success) {
          return alert.error(message);
        }
        setValues({});
        alert.success("Berhasil register");
        APP_NAME === "sawit-web"
          ? navigate("/produksi-kebun", { replace: true })
          : navigate("/riwayat-panen", { replace: true });
      });
  };

  const handleOnPrev = () => {
    setValues(watchAllFields);
    onPrev();
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.province !== undefined) {
        dispatch(getCities({ id: value.province.id }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto mb-24 mt-24 grid grid-cols-1	gap-16 md:mt-32 md:max-w-[60%]"
    >
      <InputHook
        register={register}
        label="Nama Perusahaan/Koperasi/Grup"
        required
        name="companyName"
        errors={errors?.companyName}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan nama koperasi"
      />
      <InputHook
        register={register}
        label="Nomer Telpon Perusahaan/Koperasi/Grup"
        required
        name="phone"
        errors={errors?.phone}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan nomer telepon koperasi +62/+021/08"
      />
      <InputHook
        register={register}
        label="Luas Lahan (Ha)"
        required
        name="areaHa"
        errors={errors?.areaHa}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan Luas Lahan (Ha)"
        secondaryLabel={
          watchAreaHa?.length >= 1 && `(${parseToFloat(watchAreaHa)} Ha)`
        }
      />
      <InputHook
        register={register}
        label="Negara"
        disabled={true}
        required
        name="country"
        errors={errors?.country}
        autocomplete="on"
        inputClassName="input-large-border"
      />
      <SelectHook
        label="Provinsi"
        required
        name="province"
        errors={errors?.province}
        data={area.provinces}
        control={control}
        placeholder="Pilih provinsi"
      />
      {!provinceIsNull && (
        <SelectHook
          label="Kota/Kabupaten"
          required
          name="city"
          errors={errors?.city}
          data={area.cities}
          control={control}
          placeholder="Pilih kota/kabupaten"
        />
      )}
      <InputHook
        register={register}
        label="Kode Pos"
        name="postalCode"
        errors={errors?.postalCode}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan kode pos"
      />
      <TextAreaHook
        rows={2}
        register={register}
        label="Alamat Perusahaan/Koperasi/Grup"
        placeholder="Masukkan alamat koperasi"
        name="address"
        errors={errors?.address}
        className="w-full"
        inputClassName="input-large-border"
      />
      <InputHook
        register={register}
        label="Website Perusahaan/Koperasi/Grup"
        name="url"
        errors={errors?.url}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan link website"
      />
      <StepActions
        current={current}
        stepLength={stepLength}
        onNext={onNext}
        onPrev={handleOnPrev}
      />
    </form>
  );
};

export default SecondContent;
