import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./partials/Filter/Filter";
import ListHistorySpb from "./partials/ListHistorySpb";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { handleModalStarter } from "../../../features/modalSlice";
import { selectUser } from "../../../features/userSlice";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import useFilter from "../../../hooks/useFilter";
import TRAKSI from "../../../assets/icons/traksi.svg";
import { useVehicleCheckpointStore } from "../../../api/vehicleCheckpoint/useVehicleCheckpointStore";
import MultiModal from "./partials/Modal";
const ListView = () => {
  const dispatch = useDispatch();
  const initialFilter = {
    pageIndex: 0,
    fltCompany: undefined,
    fltUser: undefined,
    fltAfdeling: undefined,
    fltBlock: undefined,
    fltStartDate: undefined,
    fltEndDate: undefined,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };
  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({ initialFilter });
  const [getVehicleCheckpoints] = useVehicleCheckpointStore((state) => [
    state.getVehicleCheckpoints,
  ]);

  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );
  const paginationLenght = Math.ceil(page?.total / 20);
  const modalName = "Vehicle";
  const user = useSelector(selectUser);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    if (beforeFetch) {
      beforeFetch();
    }
    if (!isSilent) setRequest({ isLoading: true });
    await getVehicleCheckpoints({
      params: {
        ...isFilterBy,
        pageIndex,
      },
      onSuccess: (response) => {
        setData(response?.records);
        const totalRecords = response?.totalRecords;
        setPage({
          current: pageIndex,
          total: totalRecords,
        });
        setTimeout(() => {
          setRequest({ isLoading: false });
        }, 800);
      },
    });
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const handleRefreshData = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, [isFilterBy]); // it will be call when the page is first loaded and isFilterBy is changed

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <MultiModal onRefreshData={handleRefreshData} />
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={TRAKSI}
                alt=""
                className="hidden w-24 grayscale md:inline"
              />
              <p className="line-clamp-1">Riwayat Traksi</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListHistorySpb
        isLoading={request.isLoading}
        data={data}
        handleRefreshData={handleRefreshData}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
