import "moment/locale/id";

import { TbPencil, TbTrash } from "react-icons/tb";

import { Number } from "../../../../../helpers/Common";
import React from "react";

const TableRowMasterPremi = ({ data, handleUpdate, handleDelete }) => {
	return data.map((item, index) => (
		<tr className="table-header-regular" key={index}>
			<td className="table-row-regular  font-semi-bold text-gray-600">
				{index + 1}
			</td>
			<td className="table-row-regular ">{item?.kebun}</td>
			<td className="table-row-regular ">{item?.block}</td>
			<td className="table-row-regular ">{Number(item?.total_pokok)}</td>
			<td className="table-row-regular ">
				Rp{Number(item?.total_premi)}
			</td>
			<td className="table-row-regular ">
				<div className="flex flex-row items-center space-x-16">
					<button
						className="rounded-[4px] p-8 outline-none"
						onClick={() => handleUpdate({ item })}
					>
						<TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
					</button>
					<button
						className="rounded-[4px] bg-red-500 p-8 outline-none"
						onClick={() =>
							handleDelete({
								id: item.id,
							})
						}
					>
						<TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
					</button>
				</div>
			</td>
		</tr>
	));
};

export default TableRowMasterPremi;
