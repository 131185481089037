export const options = {
	maintainAspectRatio: false,
	layout: {},
	scales: {},
	plugins: {
		datalabels: {
			display: false,
		},
		legend: {
			display: true,
			position: "top",
		},
		title: {
			display: false,
			text: "Fraction per Detection Accuracy",
		},
	},
};
