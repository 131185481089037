import React from "react";
import Header from "./partials/Header";
import Content from "./partials/Content";

const DataSummaryCard = ({ title, subTitle, data }) => {
  return (
    <div className="grid h-full w-full grid-cols-1 rounded-8 bg-white p-8 shadow md:gap-14 md:p-16 md:px-16">
      <Header title={title} subTitle={subTitle} />
      <Content data={data} />
    </div>
  );
};

export default DataSummaryCard;
