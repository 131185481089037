import "react-lazy-load-image-component/src/effects/blur.css";

import { Controller } from "react-hook-form";
import { ConfigProvider, DatePicker, Image, notification, Tooltip } from "antd";
import {
  cn,
  Number,
  parseToFloat,
} from "../../../../../../../../../helpers/Common";
import React, { useCallback, useEffect, useState } from "react";
import { TbCalendar } from "react-icons/tb";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import { MdOutlineWarning } from "react-icons/md";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { updateLoad } from "../../../../../../../../../features/loadSlice/loadSlice";
import { useDispatch } from "react-redux";
import { checkValues } from "../../../../../../../../../helpers/validationHelper";
import { debounce } from "lodash";
import { useImageSource } from "../../../../../../../../../hooks/useImageSource";
import { useComment } from "../../../../../../../../../hooks/useComment";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../../../../../App";
import { pagePath } from "../../../../../../ListView";
import { DEFAULT_TABLE_THEME } from "../../../../../../../../../utils/constant";

dayjs.extend(customParseFormat);
const Item = ({ item, index, handleRefreshData }) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    onChangeDate,
    onChangeText,
    setData,
  } = useComment({ input: item });
  const dispatch = useDispatch();
  const watchRemark = watch(`remark-${item?.id}`);
  const [message, setMessage] = useState("");
  const [_, setVersion] = useState(item?.version);
  const { source, setSource, setDownloadedImage } = useImageSource();

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  const updateFunc = async (request) => {
    try {
      const response = await dispatch(updateLoad(request)).unwrap();
      if (!response?.success) {
        return notification.error({
          message: "Gagal Memberikan Komentar",
          description: response.message,
          placement: "bottomRight",
        });
      }
      return response?.record;
    } catch (error) {}
  };

  const request = debounce(async (searchTerm) => {
    onChangeText({
      key: "remark",
      data: searchTerm,
      refreshFunc: handleRefreshData,
      updateFunc,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [item]
  );

  useEffect(() => {
    setValue(`remark-${item?.id}`, item?.remark);
    setValue(
      `caDueDate-${item?.id}`,
      item?.caDueDate
        ? dayjs(moment(item?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [item?.id]);

  useEffect(() => {
    setData(item);
  }, [item?.caDueDate, item?.remark, item]);

  useEffect(() => {
    const fieldNames = {
      nopol: "Nomer Polisi",
      factoryBruto: "Bruto PKS (kg)",
      factoryTarra: "Tarra PKS (kg)",
      factoryTbsCount: "TBS Diterima (jjg)",
      nettoPks: "Netto PKS (kg)",
    };

    const items = [
      { nopol: item?.nopol },
      { factoryBruto: item?.factoryBruto },
      { factoryTarra: item?.factoryTarra },
      { factoryTbsCount: item?.factoryTbsCount },
      { nettoPks: item?.factoryBruto - item?.factoryTarra },
    ];
    setMessage(checkValues(items, fieldNames));

    setDownloadedImage(item?.photoFileId, "main");
    setDownloadedImage(item?.photoFileId2, "second");
  }, [item?.photoFileId, item?.photoFileId2]);

  const nettoPks =
    parseFloat(item?.factoryBruto) - parseFloat(item?.factoryTarra);
  const bjrPks = nettoPks / (item?.factoryTbsCount - item?.factoryPinalty);

  return (
    <tr
      className={cn(
        `table-header-regular relative hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out`,
        DEFAULT_TABLE_THEME
      )}
      key={index}
    >
      <td className="table-row-regular">
        {index + 1}

        <Tooltip
          placement="bottom"
          title={message.message}
          className="font-regular"
        >
          <div>
            {message.isError ? (
              <MdOutlineWarning className="text-24 text-yellow-600" />
            ) : null}
          </div>
        </Tooltip>
      </td>
      <td className="flex w-[170px] flex-row items-center space-x-4">
        <Tooltip
          placement="bottom"
          title="Dokumen PKS"
          className="font-regular"
        >
          <img
            src={source.main || IMAGE_NOT_FOUND}
            alt={item?.photoFileId}
            className="aspect-square w-[80px] cursor-pointer rounded-8"
            onClick={() => {
              logEvent(
                analytics,
                `image_dokumen_pks_${pagePath}_${window.location.host}`,
                {
                  page_title: `${pagePath}_${window.location.host}`,
                  page_location: window.location.href,
                }
              );
              handleShowVisible("main");
            }}
          />
        </Tooltip>
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.main}
          preview={{
            visible: source.mainVisible,
            src: source.main || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("main"),
          }}
        />
        <Tooltip
          placement="bottom"
          title="Buah Penalti"
          className="font-regular"
        >
          <img
            src={source.second || IMAGE_NOT_FOUND}
            alt={item?.photoFileId2}
            className="aspect-square w-[80px] cursor-pointer rounded-8"
            onClick={() => {
              logEvent(
                analytics,
                `image_buah_penalti_${pagePath}_${window.location.host}`,
                {
                  page_title: `${pagePath}_${window.location.host}`,
                  page_location: window.location.href,
                }
              );
              handleShowVisible("second");
            }}
          />
        </Tooltip>
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.second}
          preview={{
            visible: source.secondVisible,
            src: source.second || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("second"),
          }}
        />
      </td>
      <td className="table-row-regular">
        <p>{moment(item?.date).format("LL")}</p>
        <p>{moment(item?.createdTime).format("H:mm")}</p>
      </td>
      <td className="table-row-regular">{item?.fullname}</td>{" "}
      <td className="table-row-regular w">
        <InputHook
          control={control}
          name={`remark-${item?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          afterOnChange={debounceTextRequest}
          onBlur={() => {
            onChangeText({
              key: "remark",
              data: watchRemark,
              refreshFunc: handleRefreshData,
              updateFunc,
            });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit((fieldValues) =>
                onChangeText({
                  key: "remark",
                  data: fieldValues[`remark-${item?.id}`],
                  refreshFunc: handleRefreshData,
                  updateFunc,
                })
              )(event);
            }
          }}
        />
      </td>
      <td className="table-row-regular">
        <Controller
          name={`caDueDate-${item?.id}`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    onChangeDate({
                      key: "caDueDate",
                      date: date || null,
                      refreshFunc: handleRefreshData,
                      updateFunc,
                    });
                  }}
                  placeholder="Tanggal tindakan"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: 160,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    fontFamily: "Inter-Regular",
                    maxWidth: 250,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </td>
      <td className="table-row-regular">
        {nettoPks || nettoPks === 0 ? Number(Math.ceil(nettoPks)) : null}
      </td>
      <td className="table-row-regular">
        {item?.factoryTbsCount || item?.factoryTbsCount === 0
          ? Number(item?.factoryTbsCount)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.factoryPinalty || item?.factoryPinalty === 0
          ? Number(item?.factoryPinalty)
          : null}
      </td>
      <td className="table-row-regular">
        {bjrPks || bjrPks === 0 ? parseToFloat(bjrPks) : null}
      </td>
      <td className="table-row-regular">
        {item?.factoryBruto || item?.factoryBruto === 0
          ? Number(Math.ceil(item?.factoryBruto))
          : null}
      </td>
      <td className="table-row-regular">
        {item?.factoryTarra || item?.factoryTarra === 0
          ? Number(Math.ceil(item?.factoryTarra))
          : null}
      </td>
      <td className="table-row-regular">{item?.nopol}</td>
    </tr>
  );
};

export default Item;
