import InputHook from "../../../../../../components/atoms/InputHook";
import React from "react";
import StepActions from "../StepActions/StepActions";
import { TbInfoCircle } from "react-icons/tb";
import { Tooltip } from "antd";
import { formSchema } from "./schema/formSchema";
import { useData } from "../../hooks/DataContext";
import { useForm } from "react-hook-form";
import usePassword from "../../../../../../hooks/usePassword";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

const FirstContent = () => {
  const { data, setValues, current, onNext, onPrev, stepLength } = useData();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...data,
      position: "Admin",
    },
  });
  const { isPasswordShow, handlePasswordShow } = usePassword();
  const [tooltipUsername, setTooltipUsername] = useState(false);
  const [tooltipPassword, setTooltipPassword] = useState(false);

  const onSubmit = (data) => {
    setValues(data);
    onNext();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto mt-24 mb-24 grid grid-cols-1 gap-16 md:mt-32 md:max-w-[60%]"
    >
      <h1 className="text-16 font-semibold text-gray-400">Kontak Info</h1>
      <InputHook
        register={register}
        label="Nama Lengkap"
        required
        name="fullname"
        errors={errors?.fullname}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan nama lengkap"
      />
      <InputHook
        register={register}
        label="Email"
        required
        name="contactEmail"
        errors={errors?.contactEmail}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan email"
      />
      <InputHook
        register={register}
        label="Nomor Handphone"
        required
        name="contactPhone"
        errors={errors?.contactPhone}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan nomer telepon +62"
      />
      <h1 className="text-16 font-semibold text-gray-400">Pembuatan Akun</h1>
      <InputHook
        register={register}
        label="Username"
        secondaryLabel={
          <Tooltip
            open={tooltipUsername}
            title="Perhatian! Username tidak dapat diubah setelah didaftarkan. pastikan username mudah diingat dan sesuai."
          >
            <div
              onMouseEnter={() => setTooltipUsername(true)}
              onMouseLeave={() => setTooltipUsername(false)}
            >
              <TbInfoCircle className="ml-8 text-16 text-red-600" />
            </div>
          </Tooltip>
        }
        onFocus={() => {
          setTooltipUsername(true);
        }}
        onBlur={() => {
          setTooltipUsername(false);
        }}
        required
        name="createdBy"
        errors={errors?.createdBy}
        autocomplete="on"
        inputClassName="input-large-border"
        placeholder="Masukkan username"
      />
      <p className="-mt-8 font-regular text-14 text-gray-300">
        Contoh: ETM atau CV.MAKMUR{" "}
      </p>
      <InputHook
        register={register}
        label="Kata Sandi"
        secondaryLabel={
          <Tooltip
            open={tooltipPassword}
            title="Perhatian! Pastikan Anda memilih dan mengingat password anda. Min 8 karakter"
          >
            <div
              onMouseEnter={() => setTooltipPassword(true)}
              onMouseLeave={() => setTooltipPassword(false)}
            >
              <TbInfoCircle className="ml-8 text-16 text-red-600" />
            </div>
          </Tooltip>
        }
        onFocus={() => {
          setTooltipPassword(true);
        }}
        onBlur={() => {
          setTooltipPassword(false);
        }}
        required
        name="password"
        type={isPasswordShow ? "text" : "password"}
        errors={errors?.password}
        inputClassName="input-large-border"
        autocomplete="current-password"
        placeholder="Masukkan password"
      />
      <InputHook
        register={register}
        label="Konfirmasi Kata Sandi"
        required
        name="cpassword"
        type={isPasswordShow ? "text" : "password"}
        errors={errors?.cpassword}
        autocomplete="current-password"
        inputClassName="input-large-border"
        placeholder="Masukkan konfirmasi password"
      />

      <div className="flex flex-row items-center space-x-16">
        <input
          id="decision-checkbox"
          type="checkbox"
          className="h-[20px] w-[20px] border-gray-300 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          onChange={handlePasswordShow}
        />
        <label
          for="decision-checkbox"
          className="font-regular text-14 text-gray-500"
        >
          Tampilkan password
        </label>
      </div>

      <StepActions current={current} stepLength={stepLength} onPrev={onPrev} />
    </form>
  );
};

export default FirstContent;
