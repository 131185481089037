import React, { useCallback, useState } from "react";

const usePassword = () => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const handlePasswordShow = useCallback(() => {
    setIsPasswordShow(!isPasswordShow);
  }, [isPasswordShow]);

  return { isPasswordShow, setIsPasswordShow, handlePasswordShow };
};

export default usePassword;
