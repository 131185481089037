import React, { forwardRef } from "react";
import { ConfigProvider, Image, Spin } from "antd";
import { IMAGE_NOT_FOUND } from "../../../api/fallbackImageNotFound";
import ButtonComponent from "../ButtonComponent";

const LoadingComponent = ({ status }) => {
  return status ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          colorTextTertiary: "#e1e4e8",
        },
      }}
    >
      <div className="absolute z-10 flex flex-col items-center">
        <Spin size="large" />
        <p className="text-14 font-bold text-gray-500">Memuat...</p>
      </div>
    </ConfigProvider>
  ) : null;
};

const theme = {
  token: {
    colorPrimary: "#d7a137",
    colorTextQuaternary: "#e1e4e8",
    colorTextTertiary: "#e1e4e8",
  },
};

const Picture = forwardRef(
  (
    {
      className,
      isLoading,
      imageSource,
      imageVisible,
      onVisibleChange,
      onInputChange,
      title,
      buttonUploadTitle,
      photoFileId,
    },
    ref
  ) => {
    const triggerFileInput = (ref) => ref.current.click();

    return (
      <div
        className={`relative flex flex-col items-center justify-center ${className?.container}`}
      >
        <Image
          wrapperStyle={{ display: "none" }}
          src={imageSource}
          preview={{
            visible: imageVisible,
            src: imageSource || IMAGE_NOT_FOUND,
            onVisibleChange: onVisibleChange,
          }}
        />
        <p className={`mb-14 text-18 font-medium ${className?.title}`}>
          {title}
        </p>
        <input
          type="file"
          accept="image/*"
          onChange={(event) => {
            event.stopPropagation();
            onInputChange(event);
          }}
          ref={ref}
          style={{ display: "none" }}
        />
        <LoadingComponent status={isLoading} />
        <img
          src={imageSource || IMAGE_NOT_FOUND}
          alt={photoFileId}
          onClick={onVisibleChange}
          className={`relative mb-16 aspect-square w-[170px] cursor-pointer rounded-8 border border-dashed border-gray-200 object-contain ${className?.image}`}
        />
        <ConfigProvider theme={theme}>
          <ButtonComponent
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => triggerFileInput(ref)}
            value={buttonUploadTitle}
            className={`input-mini-border hover:bg-yellow-600 hover:text-white ${className?.buttonUpload}`}
          />
        </ConfigProvider>
      </div>
    );
  }
);

export default Picture;
