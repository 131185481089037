/**
 * Converts a Blob URL to a base64-encoded string.
 * @param {string} blobUrl - The Blob URL to convert.
 * @returns {Promise<string>} A Promise that resolves to the base64-encoded string.
 */
export async function blobToBase64(blobUrl) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

/**
 * Converts a Blob URL to a File and then to a base64-encoded string.
 * @param {string} blobUrl - The Blob URL to convert.
 * @param {string} fileName - The desired name for the resulting File.
 * @returns {Promise<File>} A Promise that resolves to the created File.
 * @throws {Error} Throws an error if the conversion process fails.
 */
export async function blobToFile(blobUrl, fileName) {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: blob.type });

    return file;
  } catch (error) {
    console.error("Error converting Blob to File:", error);
    throw error;
  }
}

/**
 * Converts a File to a base64-encoded string.
 * @param {File} file - The File to convert.
 * @returns {Promise<string>} A Promise that resolves to the base64-encoded string.
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
