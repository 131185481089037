import React from "react";
import { ConfigProvider, Modal as AntdModal } from "antd";
import ButtonComponent from "../../../../../../../../../components/atoms/ButtonComponent";
import Content from "./partials/Content";

const Modal = ({ isModalOpen, showModal }) => {
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#D7A137" },
      }}
    >
      <AntdModal
        title="Filter"
        zIndex={10}
        open={isModalOpen}
        onOk={showModal}
        onCancel={showModal}
        wrapClassName="backdrop-blur-sm"
        footer={
          <div className="flex flex-row items-center justify-end space-x-16 pt-16">
            <ButtonComponent
              value="Terapkan Filter"
              type="submit"
              size="none"
              className="button-transition px-16 py-8 text-16"
              onClick={showModal}
            />
          </div>
        }
      >
        <Content />
      </AntdModal>
    </ConfigProvider>
  );
};

export default Modal;
