import React, { useEffect, useState } from "react";
import ModalContainer from "../../../../../components/atoms/Modal";

import { useForm } from "react-hook-form";
import Content from "./partials/Content/Content.jsx";
import { isEmptyObject } from "../../../../../helpers/Common";
import { create } from "zustand";
import { useDimensionalTagStore } from "../../../../../api/dimensionalTag/useDimensionalTagStore";
import { useVehicleCheckpointStore } from "../../../../../api/vehicleCheckpoint/useVehicleCheckpointStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";

export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data }) =>
    set((state) => ({
      modal: {
        ...state.modal,
        isOpen: !state.modal.isOpen,
        data: data || {},
      },
    })),
}));

const Modal = ({ onRefreshData }) => {
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [isCreateDimensionalTag, setIsCreateDimensionalTag] = useState(false);
  const [modal, isOpen, isLoading, toggleModal] = useModalStore((state) => [
    state.modal,
    state.modal.isOpen,
    state.modal.isLoading,
    state.toggleModal,
  ]);
  const [updateVehicleCheckpoint] = useVehicleCheckpointStore((state) => [
    state.updateVehicleCheckpoint,
  ]);
  const [getDimensionalTags, createDimensionalTag] = useDimensionalTagStore(
    (state) => [state.getDimensionalTags, state.createDimensionalTag]
  );
  const [user] = useUserStore((state) => [state.user]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit = (inputData) => {
    if (isCreateDimensionalTag) {
      const body = {
        companyId: user?.companyId,
        companyName: user?.adhocEstateName,
        name: inputData?.name,
        description: inputData?.description,
        itemPrefix: inputData?.itemPrefix,
        items: inputData?.newTags?.map((item) => item?.value),
      };
      createDimensionalTag({
        body,
        onSuccess: () => {
          setIsCreateDimensionalTag(false);
          fetchData();
        },
      });
    } else {
      const body = {
        ...modal?.data,
        tags: tagsSelected?.map((item) => item?.value),
      };
      updateVehicleCheckpoint({
        id: modal?.data?.id,
        body,
        onSuccess: () => {
          onRefreshData(true);
          toggleModal({ data: {} });
        },
      });
    }
  };

  const fetchData = () => {
    getDimensionalTags({
      params: { pageSize: 1000, pageIndex: 0 },
      onSuccess: (data) => {
        const newData = data?.records?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        }));
        setTags(newData);
      },
    });
  };

  const clearGroupTagForm = () => {
    setValue("name", null);
    setValue("description", null);
    setValue("itemPrefix", null);
    setValue("newTags", null);
  };

  useEffect(() => {
    setTagsSelected([]);
    setTags([]);

    setValue("tags", null);
    setValue("checkpointDate", null);
    setValue("checkpointLocation", null);
    setValue("deliveryLetterNumber", null);
    setValue("tripCode", null);
    setIsCreateDimensionalTag(false);
  }, [isOpen, modal?.data]);

  useEffect(() => {
    fetchData();

    if (!isEmptyObject(modal?.data)) {
      if (modal?.data?.tags?.length > 0) {
        setValue(
          "tags",
          modal?.data?.tags?.map((item) => ({
            label: item,
            value: item,
          }))
        );
      }
      setValue("checkpointDate", modal?.data?.checkpointDate);
      setValue("checkpointLocation", modal?.data?.checkpointLocation);
      setValue("deliveryLetterNumber", modal?.data?.deliveryLetterNumber);
      setValue("tripCode", modal?.data?.tripCode);
    }
  }, [isOpen, modal?.data, isCreateDimensionalTag]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ data: {} })}
      title={
        <p className="flex flex-row items-center space-x-8">
          {isCreateDimensionalTag ? (
            "Tambah Tag Grup Baru"
          ) : (
            <div className="flex flex-row items-center space-x-8 rounded-[4px] bg-gray-20 px-12 py-4">
              <span className="text-14 font-medium text-gray-500">
                {modal?.data?.checkpointDate}
              </span>
            </div>
          )}
        </p>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            tags={tags}
            watch={watch}
            tagsSelected={tagsSelected}
            setTagsSelected={setTagsSelected}
            isCreateDimensionalTag={isCreateDimensionalTag}
            setIsCreateDimensionalTag={setIsCreateDimensionalTag}
            clearGroupTagForm={clearGroupTagForm}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ data: {} })}
          type={isCreateDimensionalTag ? "Buat Grup Tag" : "Simpan Tag"}
        />
      </form>
    </ModalContainer>
  );
};

export default Modal;
