import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import {
  createAfdelingApi,
  getAfdelingsApi,
  updateAfdelingApi,
} from "./afdelingApi";
import { calculateToastDuration } from "../../helpers/Common";
import { createEstateApi } from "../estates/estateApi";

export const useAfdelingStore = create((set, get) => ({
  afdelings: [],
  afdeling: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getAfdelings: async (params = {}, silent, onSuccess) => {
    try {
      set({ isLoading: true });
      const response = await getAfdelingsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Afdelings",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ afdelings: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Afdelings",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createAfdeling: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createAfdelingApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Afdeling",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Afdeling`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Afdeling",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateAfdeling: async (id, body, silent) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateAfdelingApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Update Afdeling",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil update afdeling`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      if (get().afdeling?.id === id) {
        set({ afdeling: data.record });
      }

      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Update Afdeling",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
