import BasePageHeader from "../../components/atoms/Headings/BasePageHeader";
import { DataPremiRows } from "../../api/tables/premi/DataPremiRows";
import React from "react";
import TableRowMasterPremi from "../../components/organisms/Tables/columns/TableRowMasterPremi";
import Tables from "../../components/organisms/Tables";
import { TbReportMoney } from "react-icons/tb";
import headers from "../../api/tables/premi/premi_headers.json";
import { useDispatch } from "react-redux";

export const base = DataPremiRows();
export const modalName = "masterPremi";

const MasterPremiPage = () => {
	const dispatch = useDispatch();
	const [data, setData] = React.useState(base);

	return (
		<div className="relative w-full overflow-hidden bg-white">
			{/* <PremiModal
				isOpen={modal[modalName]?.showModalCreate}
				toggleModal={isUpdate ? handleUpdate : handleCreate}
				params={isUpdate && modal[modalName]?.params}
				type={isUpdate ? "Ubah" : "Tambah"}
			/> */}

			<BasePageHeader
				icon={<TbReportMoney />}
				title="Data Premi"
				buttonValue="Tambah premi"
			/>

			<section className="mx-16 rounded-8 border-1 border-gray-50 bg-white">
				<Tables
					headers={headers}
					rows={data}
					rowContent={(data) => <TableRowMasterPremi data={data} />}
				/>
			</section>
		</div>
	);
};

export default MasterPremiPage;
