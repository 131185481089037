import React from "react";
import Navbar from "../../../components/atoms/Navbar";
import SETTING_PRICE from "../../../assets/icons/setting-price.svg";
import SettingPrice from "./partials/SettingPrice";
import HistoryPrice from "./partials/HistoryPrice";

const PriceSetting = () => {
  return (
    <div className="relative grid min-h-screen w-full grid-cols-1 grid-rows-[max-content,1.2fr,0.8fr] gap-8 bg-gray-30 pb-16 md:max-h-screen md:overflow-hidden">
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={SETTING_PRICE}
                alt='Money by Zona desain from <a href="https://thenounproject.com/browse/icons/term/money/" target="_blank" title="Money Icons">Noun Project</a> (CC BY 3.0)'
                className="hidden w-24 grayscale md:inline"
              />
              <p className="line-clamp-1">Pengaturan Harga</p>
            </div>
          }
        />
      </div>
      <SettingPrice />
      <HistoryPrice />
    </div>
  );
};

export default PriceSetting;
