import ButtonComponent from "../../components/atoms/ButtonComponent";
import NOTFOUND from "../../assets/images/notfound.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../../configs/api";

const NoMatchPage = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center">
      <div className="flex w-[400px] flex-col items-center justify-center">
        <img src={NOTFOUND} alt="sorry page not found" className="mb-24" />
        <h1 className="mb-16 text-center font-bold text-24 text-gray-500">
          Hmm! Sepertinya kamu tersesat.
        </h1>
        <p className="mb-24 text-center font-regular text-13 text-gray-400">
          Halaman yang Anda cari tidak ditemukan. Kami sarankan Anda pergi ke
          beranda saat kami memperbaiki masalah.
        </p>

        <ButtonComponent
          value="Kembali ke Beranda"
          size="sm"
          onClick={() =>
            APP_NAME === "sawit-web"
              ? navigate("/produksi-kebun", { replace: true })
              : navigate("/riwayat-panen", { replace: true })
          }
        />
      </div>
    </div>
  );
};

export default NoMatchPage;
