import "react-lazy-load-image-component/src/effects/blur.css";

import React, { useEffect } from "react";
import { positions, transitions, useAlert } from "react-alert";

import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { downloadImage } from "../../../../../../../features/fileAttachment/fileAttachmentSlice";
import { Image } from "antd";
import { IMAGE_NOT_FOUND } from "../../../../../../../api/fallbackImageNotFound";

const DatasetImage = ({
  item,
  onClick,
  checkImage,
  isMultiSelect,
  selected,
}) => {
  const [source, setSource] = React.useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [visible, setVisible] = React.useState(false);

  const handleDownloadImage = async () => {
    try {
      if (!item?.photoFileId) {
        return;
      }
      const response = await dispatch(
        downloadImage({ id: item?.photoFileId })
      ).unwrap();
      response.onloadend = function () {
        setSource(response.result);
      };
    } catch (error) {
      alert.error(`Failed to get image ${item?.photoFileId}`, {
        position: positions.BOTTOM_RIGHT,
        transition: transitions.SCALE,
      });
    }
  };

  useEffect(() => {
    handleDownloadImage();
  }, [item?.photoFileId]);

  return (
    <Image
      className="aspect-square w-full cursor-pointer rounded-8"
      src={source || IMAGE_NOT_FOUND}
    />
  );
};

DatasetImage.propTypes = {
  checkImage: PropTypes.func,
  isMultiSelect: PropTypes.any,
  item: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.any,
};

export default DatasetImage;
