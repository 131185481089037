export const seiaris2012 = {
  type: "FeatureCollection",
  name: "seiaris_tt2012kav_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: "9",
        Luas: 1.702,
        DESA: "Sei Aris",
        Nm_Pemilik: "Jasran",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 09",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78932669139752, -3.680166927330648],
              [114.790431309196535, -3.680181000661379],
              [114.790791088881122, -3.680555595208753],
              [114.790805523406519, -3.68115731930202],
              [114.789300049426032, -3.68115357186241],
              [114.78932669139752, -3.680166927330648],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "11",
        Luas: 1.465,
        DESA: "Sei Aris",
        Nm_Pemilik: "Siti Khadijah",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 015",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.790827065008727, -3.682055329447543],
              [114.7908319744366, -3.682259992885653],
              [114.790497636852379, -3.682995235114664],
              [114.790231991973229, -3.683300327592145],
              [114.78989340070882, -3.683475009662888],
              [114.78977505935265, -3.6834690527642],
              [114.789855807338455, -3.68272185553388],
              [114.789651491613427, -3.682491706184678],
              [114.78948424431205, -3.682311694186958],
              [114.78946334279145, -3.682051934176221],
              [114.790827065008727, -3.682055329447543],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "10",
        Luas: 1.642,
        DESA: "Sei Aris",
        Nm_Pemilik: "Jasran",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 08",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.790805523406519, -3.68115731930202],
              [114.790827065008727, -3.682055329447543],
              [114.78946334279145, -3.682051934176221],
              [114.789452016099844, -3.681911168439817],
              [114.789283272727829, -3.68177485762139],
              [114.789300049426032, -3.68115357186241],
              [114.790805523406519, -3.68115731930202],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "1",
        Luas: 1.982,
        DESA: "Sei Aris",
        Nm_Pemilik: "Nur Risqa Damayanti",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 06",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786494324373024, -3.67944731817181],
              [114.785892906894077, -3.678457555079409],
              [114.787574642292796, -3.677547520864765],
              [114.787616883648496, -3.677803814553323],
              [114.787675243380392, -3.678157904504529],
              [114.787425140680526, -3.678508640057834],
              [114.787230707139742, -3.678781306707404],
              [114.786953830188665, -3.679031724591945],
              [114.786494324373024, -3.67944731817181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "2",
        Luas: 1.856,
        DESA: "Sei Aris",
        Nm_Pemilik: "Siti Rukayah",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 05",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785892906894077, -3.678457555079409],
              [114.785511766118731, -3.677830304562114],
              [114.785309580555719, -3.677202110199699],
              [114.786245415919936, -3.677750259857817],
              [114.786198753298621, -3.677395361825226],
              [114.78646382824806, -3.677307326630687],
              [114.786646802940197, -3.677464705100851],
              [114.787453456392925, -3.676812242566697],
              [114.787574642292796, -3.677547520864765],
              [114.785892906894077, -3.678457555079409],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "3",
        Luas: 1.911,
        DESA: "Sei Aris",
        Nm_Pemilik: "Husain H",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 12",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787616883648496, -3.677803814553323],
              [114.787574642292796, -3.677547520864765],
              [114.787453456392925, -3.676812242566697],
              [114.787681132769578, -3.676628084341252],
              [114.788245406457548, -3.676383870044273],
              [114.788888466973148, -3.677238312155571],
              [114.788932403349733, -3.677812505401677],
              [114.787616883648496, -3.677803814553323],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "4",
        Luas: 1.983,
        DESA: "Sei Aris",
        Nm_Pemilik: "Husain H",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 13",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.788992663913831, -3.678075964772063],
              [114.789012322152175, -3.678064613279408],
              [114.789077107470973, -3.678017995831937],
              [114.789129095917517, -3.677966674558707],
              [114.789738720438933, -3.677189242147239],
              [114.789704866883625, -3.676754069323532],
              [114.790431577018524, -3.67633180452275],
              [114.790650781362089, -3.677684235748181],
              [114.79059266857881, -3.678079944676146],
              [114.788992663913831, -3.678075964772063],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "5",
        Luas: 2.063,
        DESA: "Sei Aris",
        Nm_Pemilik: "M. Juhran",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 10",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787425140680526, -3.678508640057834],
              [114.787675243380392, -3.678157904504529],
              [114.787616883648496, -3.677803814553323],
              [114.788932403349733, -3.677812505401677],
              [114.788954259680224, -3.678098140987225],
              [114.788992663913831, -3.678075964772063],
              [114.79059266857881, -3.678079944676146],
              [114.790561021393316, -3.678295440245366],
              [114.790720326888618, -3.678521141024798],
              [114.787425140680526, -3.678508640057834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "6",
        Luas: 2.179,
        DESA: "Sei Aris",
        Nm_Pemilik: "M. Juhran",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 11",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786953830188665, -3.679031724591945],
              [114.787230707139742, -3.678781306707404],
              [114.787425140680526, -3.678508640057834],
              [114.790720328727048, -3.678521146611746],
              [114.790748414907668, -3.678560934075565],
              [114.790448233480291, -3.679040423235328],
              [114.786953830188665, -3.679031724591945],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "7",
        Luas: 2.185,
        DESA: "Sei Aris",
        Nm_Pemilik: "Abdul Hamid",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 04",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786508252151336, -3.679470239342782],
              [114.786494324373024, -3.67944731817181],
              [114.786953830188665, -3.679031724591945],
              [114.790448233480291, -3.679040423235328],
              [114.790309962541855, -3.679261287395639],
              [114.789942698003216, -3.679293908845091],
              [114.789712908463528, -3.679559956521455],
              [114.786396176546361, -3.679551696580288],
              [114.786508252151336, -3.679470239342782],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "8",
        Luas: 2.166,
        DESA: "Sei Aris",
        Nm_Pemilik: "Abdul Hamid",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "No skt 07",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.789712908463528, -3.679559956521455],
              [114.789501931491571, -3.679804222946544],
              [114.788872547808353, -3.680096088579163],
              [114.788284103085047, -3.680142969710968],
              [114.787907771688111, -3.680242637678294],
              [114.787747508319356, -3.680257888158629],
              [114.787573986719082, -3.680235099133781],
              [114.78661128569334, -3.679879464044388],
              [114.786375371972383, -3.679890054417963],
              [114.786119303220943, -3.67995201491256],
              [114.78587644293026, -3.680072135674742],
              [114.785649021901591, -3.680248186582165],
              [114.78577095607379, -3.680039056403734],
              [114.786169975040153, -3.679716101105925],
              [114.786396176546361, -3.679551696580288],
              [114.789712908463528, -3.679559956521455],
            ],
          ],
        ],
      },
    },
  ],
};
