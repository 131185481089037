import { SPOT_CHECK_URL } from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {};

export const getSpotChecks = createAsyncThunk(
  "spotcheck/getSpotChecks",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(SPOT_CHECK_URL, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: {
          fltDeleted: 0,
          pageIndex: data?.page || 0,
          sidx: "syncInfo.updatedTime",
          sord: data?.sord || "desc",
          ...data,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSpotCheckById = createAsyncThunk(
  "spotcheck/getSpotCheckById",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(`${SPOT_CHECK_URL}/${data.id}`, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: data,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSpotCheckById = createAsyncThunk(
  "harvesting/updateSpotCheckById",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.put(`${SPOT_CHECK_URL}/${data?.id}`, data, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const spotCheckSlice = createSlice({
  name: "spotcheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpotChecks.fulfilled, () => {})
      .addCase(getSpotCheckById.fulfilled, () => {})
      .addCase(updateSpotCheckById.fulfilled, () => {});
  },
});

export default spotCheckSlice.reducer;
