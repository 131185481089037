import { FFB_STOCK_MOVEMENT } from "../../configs/api";
import axiosInstance from "../api";

export const getFfbStockMovementsApi = (params) =>
  axiosInstance.get(FFB_STOCK_MOVEMENT, {
    params,
  });

export const createFfbStockMovementApi = (body) =>
  axiosInstance.post(FFB_STOCK_MOVEMENT, body);

export const getFfbStockMovementApi = (id) =>
  axiosInstance.get(`${FFB_STOCK_MOVEMENT}/${id}`);

export const updateFfbStockMovementApi = (id, body) =>
  axiosInstance.put(`${FFB_STOCK_MOVEMENT}/${id}`, body);

export const deleteFfbStockMovementApi = (id) =>
  axiosInstance.delete(`${FFB_STOCK_MOVEMENT}/${id}`, body);
