import { selectUser, selectUserPosition } from "../features/userSlice";

import { useSelector } from "react-redux";

const usePrivilage = () => {
	const currentUserPosition = useSelector(selectUserPosition);
	const user = useSelector(selectUser);

	const excludeM1Privilage =
		currentUserPosition === "Admin" ||
		currentUserPosition === "Kepala Divisi";
	const m1Privilage =
		currentUserPosition === "Mandor 1" ||
		currentUserPosition === "Asisten Divisi";

	return { user, currentUserPosition, excludeM1Privilage, m1Privilage };
};

export default usePrivilage;
