import MainSidebar from "../../atoms/MainSidebar";
import { Outlet } from "react-router-dom";
import React from "react";
import { Toaster } from "react-hot-toast";

const Layout = () => {
  return (
    <div className="grid min-h-screen w-full grid-cols-1 md:grid-cols-[max-content,1fr]">
      <Toaster />
      <MainSidebar />
      <Outlet />
    </div>
  );
};

export default Layout;
