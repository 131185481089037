export const sabuhur2012 = {
  type: "FeatureCollection",
  name: "sabuhur_tt2012_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: "19",
        Luas: 2.019,
        PERIMETRO: 724.487,
        AREA: 20191.015,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.762097937759606, -4.005887137840686],
              [114.762251292609022, -4.004575359344213],
              [114.764632859306346, -4.004745078168281],
              [114.764654521821541, -4.004751864108508],
              [114.763213518052197, -4.005428539095051],
              [114.762097937759606, -4.005887137840686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "20",
        Luas: 2.019,
        PERIMETRO: 750.766,
        AREA: 20191.453,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.762251292648287, -4.004575359306129],
              [114.762383868599329, -4.003441308168978],
              [114.762417941983941, -4.003703241423945],
              [114.762454259587017, -4.003742358167925],
              [114.764178454753107, -4.003880528940488],
              [114.764255553069972, -4.004059291299168],
              [114.764179907063635, -4.004536303053944],
              [114.764257243345327, -4.004627413648307],
              [114.764632859306346, -4.004745078168281],
              [114.762251292648287, -4.004575359306129],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "18",
        Luas: 2.0,
        PERIMETRO: 728.315,
        AREA: 20000.0,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.764742857264068, -4.004801124876368],
              [114.764094941831516, -4.006250963442996],
              [114.762087892628344, -4.005969727646837],
              [114.762093775537437, -4.005923323047092],
              [114.763227032856904, -4.005455952617268],
              [114.764691406738095, -4.004770436805425],
              [114.764742857264068, -4.004801124876368],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "16",
        Luas: 0.457,
        PERIMETRO: 459.418,
        AREA: 4567.663,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.763836814824586, -4.007082765007311],
              [114.763799836147314, -4.00728078867744],
              [114.761958525148728, -4.006990168947214],
              [114.761983196405879, -4.006795450994859],
              [114.763836814824586, -4.007082765007311],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "17",
        Luas: 2.0,
        PERIMETRO: 622.778,
        AREA: 19999.998,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.764094941831516, -4.006250963442996],
              [114.764028229646954, -4.006400244573983],
              [114.763926572715221, -4.00660210355796],
              [114.763836814824586, -4.007082765007311],
              [114.761983196405353, -4.006795450987702],
              [114.762087892628344, -4.005969727646837],
              [114.764094941831516, -4.006250963442996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "7",
        Luas: 2.0,
        PERIMETRO: 649.056,
        AREA: 20000.004,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760233373830715, -4.001541078937423],
              [114.760319766988161, -4.000794811019735],
              [114.762444430542402, -4.001018524782939],
              [114.762434534168051, -4.001767155243802],
              [114.760233373830715, -4.001541078937423],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "8",
        Luas: 2.0,
        PERIMETRO: 658.25,
        AREA: 20000.001,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760149020612374, -4.002270388777044],
              [114.760233373830715, -4.001541078937423],
              [114.762434534168051, -4.001767155243802],
              [114.762427981363629, -4.00226283879736],
              [114.76240715638508, -4.002482744330861],
              [114.760149020612374, -4.002270388777044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "9",
        Luas: 2.0,
        PERIMETRO: 663.848,
        AREA: 20000.004,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760065937175355, -4.00298794953865],
              [114.760149020612374, -4.002270388777044],
              [114.76240715638508, -4.002482744330861],
              [114.762340385775971, -4.003187818818267],
              [114.760065937175355, -4.00298794953865],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "10",
        Luas: 2.0,
        PERIMETRO: 664.888,
        AREA: 20000.0,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759984853309561, -4.0036882364766],
              [114.760065937175355, -4.00298794953865],
              [114.762340385775971, -4.003187818818267],
              [114.762329794300413, -4.003299660686626],
              [114.762257130154282, -4.003903561377791],
              [114.759984853309561, -4.0036882364766],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "11",
        Luas: 2.0,
        PERIMETRO: 664.619,
        AREA: 19999.995,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759903845123546, -4.004387865683979],
              [114.759984853309561, -4.0036882364766],
              [114.762257130154282, -4.003903561377791],
              [114.762170743541645, -4.004621553646894],
              [114.759903845123546, -4.004387865683979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "12",
        Luas: 2.0,
        PERIMETRO: 664.059,
        AREA: 19999.998,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759823539545764, -4.005081385529643],
              [114.759903845123546, -4.004387865683979],
              [114.762170743541645, -4.004621553646894],
              [114.762083382930513, -4.0053476370689],
              [114.759823539545764, -4.005081385529643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "13",
        Luas: 2.0,
        PERIMETRO: 663.545,
        AREA: 20000.005,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75974225490279, -4.005783410599787],
              [114.759823539545764, -4.005081385529643],
              [114.762083382930513, -4.0053476370689],
              [114.761996804568312, -4.006067214699384],
              [114.75974225490279, -4.005783410599787],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "14",
        Luas: 2.0,
        PERIMETRO: 663.056,
        AREA: 19998.806,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759659998772989, -4.006493821814344],
              [114.75974225490279, -4.005783410599787],
              [114.761996804568312, -4.006067214699384],
              [114.761911003216142, -4.006780330195016],
              [114.759659998772989, -4.006493821814344],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "15",
        Luas: 0.523,
        PERIMETRO: 545.616,
        AREA: 5234.817,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.761887112164217, -4.006978893682936],
              [114.759639807932317, -4.006668200870544],
              [114.759659998772989, -4.006493821814344],
              [114.761911003216142, -4.006780330195016],
              [114.761887112164217, -4.006978893682936],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "6",
        Luas: 2.0,
        PERIMETRO: 589.55,
        AREA: 20000.003,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758357979704911, -4.001723591422616],
              [114.758363812094714, -4.001672027508691],
              [114.759117565368101, -4.000689131882019],
              [114.760251822103214, -4.000786749142251],
              [114.760121187651052, -4.001893883771263],
              [114.758357979704911, -4.001723591422616],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "5",
        Luas: 2.0,
        PERIMETRO: 596.644,
        AREA: 20000.002,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758254223129953, -4.002640910499148],
              [114.758357979704911, -4.001723591422616],
              [114.760121187651052, -4.001893883771263],
              [114.760013634315939, -4.00280457847937],
              [114.758254223129953, -4.002640910499148],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "4",
        Luas: 2.0,
        PERIMETRO: 596.183,
        AREA: 20000.006,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758152356916128, -4.003540425999026],
              [114.758254223129953, -4.002640910499148],
              [114.760013634315939, -4.00280457847937],
              [114.75990452791487, -4.003737261716825],
              [114.758152356916128, -4.003540425999026],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "3",
        Luas: 2.0,
        PERIMETRO: 586.28,
        AREA: 19999.998,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758204431935624, -4.004495372934963],
              [114.758110333095644, -4.003913003040343],
              [114.758152356916128, -4.003540425999026],
              [114.75990452791487, -4.003737261716825],
              [114.759792303259246, -4.00468871079529],
              [114.758204431935624, -4.004495372934963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "2",
        Luas: 2.0,
        PERIMETRO: 575.455,
        AREA: 20000.002,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758387577232227, -4.0056513002564],
              [114.758347964759039, -4.005381141170257],
              [114.758204431935624, -4.004495372934963],
              [114.759792303259246, -4.00468871079529],
              [114.759658542157354, -4.005818742188024],
              [114.758387577232227, -4.0056513002564],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "1",
        Luas: 1.207,
        PERIMETRO: 449.463,
        AREA: 12073.371,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 1",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759558792106674, -4.006660102785617],
              [114.758513550770402, -4.00651022011581],
              [114.758387577232227, -4.00565130025645],
              [114.759658542130765, -4.005818742256836],
              [114.759558792106674, -4.006660102785617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "21",
        Luas: 2.0,
        PERIMETRO: 810.917,
        AREA: 20000.006,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75496840088671, -4.012670694393936],
              [114.75370708498464, -4.012079860232783],
              [114.753712935189242, -4.009777389076871],
              [114.753765208179246, -4.009760872655987],
              [114.753800259069848, -4.009930375676],
              [114.753935166926041, -4.009946796319876],
              [114.754090962108705, -4.01045540589192],
              [114.754086452043239, -4.010838122673341],
              [114.754280329168793, -4.01089084711221],
              [114.754262171552654, -4.011352506525538],
              [114.754427854529865, -4.011423595133294],
              [114.754683344585857, -4.011751358563091],
              [114.754862489094677, -4.012124143369047],
              [114.754865497531185, -4.012291160847674],
              [114.755064551507047, -4.012413520429529],
              [114.75496840088671, -4.012670694393936],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "22",
        Luas: 2.0,
        PERIMETRO: 860.457,
        AREA: 20000.001,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75370708498464, -4.012079860232783],
              [114.75305579280888, -4.011774777527479],
              [114.753071163875916, -4.009146729936695],
              [114.753270759863383, -4.009218938740696],
              [114.753405758567169, -4.00933060508366],
              [114.753430781060104, -4.009146047545293],
              [114.753606137368251, -4.009489384153111],
              [114.753614143904429, -4.009808603625184],
              [114.75353712279086, -4.009819281314162],
              [114.753541082223663, -4.010126020005871],
              [114.753702763362995, -4.01006383514518],
              [114.753711950685158, -4.009777700145388],
              [114.753712935189242, -4.009777389076871],
              [114.75370708498464, -4.012079860232783],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "23",
        Luas: 2.0,
        PERIMETRO: 753.74,
        AREA: 20000.02,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75305579280888, -4.011774777527479],
              [114.752452400204831, -4.011492131781058],
              [114.75246389313368, -4.008721598723906],
              [114.752878561448711, -4.009047672697601],
              [114.753046536359349, -4.009137820317617],
              [114.753071163875916, -4.009146729936695],
              [114.75305579280888, -4.011774777527479],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "24",
        Luas: 2.0,
        PERIMETRO: 846.419,
        AREA: 20000.006,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752452400204831, -4.011492131781058],
              [114.751921206592442, -4.011243305326007],
              [114.751925658148096, -4.007987744808496],
              [114.752129431821785, -4.008011819640044],
              [114.752187952506034, -4.008442374480911],
              [114.752315952898513, -4.008605255265363],
              [114.75246389313368, -4.008721598723906],
              [114.752452400204831, -4.011492131781058],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "25",
        Luas: 2.0,
        PERIMETRO: 820.574,
        AREA: 20000.002,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751921206592442, -4.011243305326007],
              [114.751402468279977, -4.011000313296471],
              [114.751416575999841, -4.007927601601994],
              [114.751925658148096, -4.007987744808496],
              [114.751921206592442, -4.011243305326007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "26",
        Luas: 2.0,
        PERIMETRO: 764.089,
        AREA: 20000.002,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751402468279977, -4.011000313296471],
              [114.7508329082616, -4.0107335141941],
              [114.750835807026547, -4.00816264965411],
              [114.751387869550342, -4.00792420756361],
              [114.751416575999841, -4.007927601601994],
              [114.751402468279977, -4.011000313296471],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "27",
        Luas: 2.0,
        PERIMETRO: 817.887,
        AREA: 20000.046,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750832908002806, -4.010733514193387],
              [114.750249399439639, -4.010460180646127],
              [114.75024939862935, -4.010460178569935],
              [114.750261073900418, -4.007452796107134],
              [114.750417138563321, -4.007397769334758],
              [114.750605017635166, -4.00803664074594],
              [114.750679299679973, -4.008024769933201],
              [114.750770603582794, -4.008190806057576],
              [114.750835803426995, -4.008162650848029],
              [114.750832908002806, -4.010733514193387],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "28",
        Luas: 2.0,
        PERIMETRO: 770.164,
        AREA: 20000.061,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 2",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75024939862935, -4.010460178569935],
              [114.750053100397309, -4.010263858009685],
              [114.749900833612088, -4.010181342481468],
              [114.749760996591618, -4.010152538962938],
              [114.749660047549966, -4.010085145300097],
              [114.749667576557158, -4.007457014524829],
              [114.749891222460406, -4.007508718511055],
              [114.750138273096212, -4.007496093666043],
              [114.750261073900418, -4.007452796107134],
              [114.75024939862935, -4.010460178569935],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "29",
        Luas: 2.0,
        PERIMETRO: 620.141,
        AREA: 20000.004,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 3",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.858923307841735, -3.971083450756824],
              [114.860268234915125, -3.969800252172069],
              [114.861095373493612, -3.970161607493165],
              [114.859801313406422, -3.97149712911719],
              [114.858923307841735, -3.971083450756824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "30",
        Luas: 2.0,
        PERIMETRO: 619.382,
        AREA: 20000.024,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 3",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861095373493612, -3.970161607493165],
              [114.861935203500892, -3.970528507369742],
              [114.860641685065374, -3.971893074627363],
              [114.859801313406422, -3.97149712911719],
              [114.861095373493612, -3.970161607493165],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "31",
        Luas: 2.0,
        PERIMETRO: 620.986,
        AREA: 20000.059,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 3",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861935203500892, -3.970528507369742],
              [114.862763416886409, -3.970890330734174],
              [114.861469263477744, -3.972282992626643],
              [114.860641690470146, -3.971893075509968],
              [114.861935203500892, -3.970528507369742],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "33",
        Luas: 1.535,
        PERIMETRO: 569.234,
        AREA: 15350.831,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 3",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.863547169730793, -3.971232726564809],
              [114.864077963929276, -3.971464612618054],
              [114.86302238719027, -3.973014751069885],
              [114.862317203284192, -3.972682500569868],
              [114.863547169730793, -3.971232726564809],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "32",
        Luas: 2.0,
        PERIMETRO: 620.377,
        AREA: 20000.001,
        LUAS__HA_: null,
        JLH_POKOK: null,
        Nm_Pemilik: null,
        block: "Blok 3",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862763416886409, -3.970890330734174],
              [114.863547169730793, -3.971232726564809],
              [114.862317201592006, -3.97268249971466],
              [114.861469263477744, -3.972282992626643],
              [114.862763416886409, -3.970890330734174],
            ],
          ],
        ],
      },
    },
  ],
};
