import React, { useEffect, useState } from "react";
import {
  getAggregatesMonthlyInYear,
  getAggregatesMonthlyInYearTemplate,
  importHarvestingTargets,
} from "../../../features/harvestingSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import { ExpandableProvider } from "./hooks/ExpandableContext";
import FileUploadModal from "../../../components/templates/FileUploadModal";
import Filter from "./partials/Filter/Filter";
import ListHistoryHarvest from "./partials/ListHistoryHarvest/ListHistoryHarvest";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { TableProvider } from "./hooks/TableContext";
import moment from "moment";
import { selectUser } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import { useCallback } from "react";
import useModal from "../../../hooks/useModal";
import { TbTargetArrow } from "react-icons/tb";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../App";

const modalUploadName = "modalUpload";
export const pagePath = "target_produksi";

const ListView = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { onShowModal } = useModal();

  const [isFilterBy, setIsFilterBy] = useState({
    year: moment().year() || undefined,
    pageSize: 99999,
  });
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const paginationLenght = Math.ceil(page?.total / 20);
  const modalName = "Vehicle";
  const modal = useSelector(selectModal);
  const user = useSelector(selectUser);

  const onFileCapture = (data) => {
    const fileContent = data;
    const fileName = data.name;
    const userId = user?.id;

    dispatch(importHarvestingTargets({ fileContent, fileName, userId }))
      .unwrap()
      .then((resp) => {
        if (!resp.success) {
          return alert.error(resp.message);
        }
        alert.success("Berhasil import data target produksi");
        handleRefreshData();
        logEvent(
          analytics,
          `upload_button_${pagePath}_${window.location.host}`,
          {
            page_title: `${pagePath}_${window.location.host}`,
            page_location: window.location.href,
          }
        );
      })
      .catch((err) => {
        alert.error(err.message);
      });
  };

  let params = {
    pageIndex: page.current,
    fltCompany: user?.companyId,
    fltYear: isFilterBy.year,
    // sidx: "syncInfo.createdTime",
    type: "by-estate",
    sord: "desc",
    sidx: "estateName",
  };

  const onFilter = useCallback(
    async ({ type, value }) => {
      if (type === "year") {
        if (value?.value !== undefined) {
          setIsFilterBy({
            ...isFilterBy,
            year: value.value,
          });
          params = {
            ...params,
            fltYear: value.value,
          };
        } else {
          setIsFilterBy({
            ...isFilterBy,
            year: undefined,
          });
          params = {
            ...params,
            fltYear: undefined,
          };
        }
      }

      const response = await dispatch(
        getAggregatesMonthlyInYear({ ...params, page: 0 })
      ).unwrap();
      const success = response.success;
      const message = response.message;
      if (!success) {
        return alert.error(message);
      }
      setData(response?.records);
      const totalRecords = response?.totalRecords;
      setPage({
        current: 0,
        total: totalRecords,
      });
    },
    [isFilterBy, page, data]
  );

  const handlePageClick = async (page) => {
    logEvent(
      analytics,
      `pagination_button_${pagePath}_${window.location.host}`,
      {
        page_title: `${pagePath}_${window.location.host}`,
        page_location: window.location.href,
      }
    );

    try {
      const response = await dispatch(
        getAggregatesMonthlyInYear({
          ...params,
          pageIndex: page.selected,
        })
      ).unwrap();
      const success = response?.success;
      const message = response?.message;
      if (!success) {
        alert.error(message);
      }
      setData(response?.records);
      const totalRecords = response?.totalRecords;
      setPage({
        current: page.selected,
        total: totalRecords,
      });
    } catch (error) {
      alert.error(error?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(
        getAggregatesMonthlyInYear({
          ...params,
          pageIndex: 0,
        })
      ).unwrap();
      const success = response?.success;
      const message = response?.message;
      if (!success) {
        alert.error(message);
      }
      setData(response?.records);
      const totalRecords = response?.totalRecords;
      setPage({
        current: 0,
        total: totalRecords,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert.error(error?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await dispatch(
        getAggregatesMonthlyInYearTemplate({
          budgetYear: isFilterBy.year,
        })
      ).unwrap();
      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "Target Produksi - SawitWeb";

    logEvent(analytics, "page_view", {
      firebase_screen: `${pagePath}_${window.location.host}`,
      page_title: `${pagePath}_${window.location.host}`,
      user_id: user?.id,
      what_time_access_screen: `${
        user?.username
      }_access_${pagePath}_at_${moment().format("ll")}`,
    });

    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
      dispatch(
        handleModalStarter({
          [modalUploadName]: {
            showModalInput: false,
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  const tableContextValue = { isFilterBy };

  return (
    <ExpandableProvider>
      <TableProvider value={tableContextValue}>
        <div className="relative min-h-full w-full overflow-hidden bg-gray-30">
          <FileUploadModal
            isOpen={modal[modalUploadName]?.showModalInput}
            onSubmit={onFileCapture}
            toggleModal={() => onShowModal(modalUploadName, "showModalInput")}
            actionButtonTitle="Unggah file"
          />
          <Navbar
            leftContentTitle={
              <div className="flex flex-row items-center">
                <TbTargetArrow className="mr-16 hidden text-24 text-gray-500 md:inline" />
                <p className="line-clamp-1">Target Produksi Bulanan</p>
              </div>
            }
          />
          <Filter
            onFilter={onFilter}
            handleRefreshData={handleRefreshData}
            handleDownloadTemplate={handleDownloadTemplate}
            showModalImport={() =>
              onShowModal(modalUploadName, "showModalInput")
            }
          />
          <ListHistoryHarvest
            isLoading={isLoading}
            isFilterBy={isFilterBy}
            data={data}
            handleRefreshData={handleRefreshData}
          />
          <Pagination.Container>
            <Pagination.Records
              current={page.current}
              length={paginationLenght}
              totalRecords={page?.total}
            />
            {paginationLenght !== 1 && (
              <Pagination
                pageCount={paginationLenght}
                onPageClick={handlePageClick}
              />
            )}
          </Pagination.Container>
        </div>
      </TableProvider>
    </ExpandableProvider>
  );
};

export default ListView;
