import { RiSortAsc, RiSortDesc } from "react-icons/ri";

import React from "react";

const TableHeadItem = ({ item, requestSort, sortConfig, size }) => {
  const layouts = {
    sm: "px-16 py-4 text-12",
    md: "py-8 px-[12px] text-16",
    lg: "",
  };

  return (
    <th
      title={item.value}
      className={`whitespace-nowrap text-left font-regular ${layouts[size]}`}
      onClick={() =>
        item?.sorted ? requestSort(item?.accessorKey) : undefined
      }
    >
      <div className="flex flex-row items-center">
        <span className="font-regular text-gray-400">{item.value}</span>
        {item?.sorted &&
        sortConfig?.direction === "ascending" &&
        item?.accessorKey === sortConfig?.key ? (
          <RiSortAsc
            className={`ml-8 h-24 w-24 ${
              sortConfig?.key === item?.accessorKey
                ? "text-blue-600"
                : "text-gray-200"
            } hover:text-gray-450`}
          />
        ) : item?.sorted &&
          sortConfig?.direction === "descending" &&
          item?.accessorKey === sortConfig?.key ? (
          <RiSortDesc
            className={`ml-8 h-24 w-24 ${
              sortConfig?.key === item?.accessorKey
                ? "text-blue-600"
                : "text-gray-200"
            } hover:text-gray-450`}
          />
        ) : (
          item?.sorted && (
            <RiSortDesc
              className={`ml-8 h-24 w-24 ${
                sortConfig?.key === item?.accessorKey
                  ? "text-blue-600"
                  : "text-gray-200"
              } hover:text-gray-450`}
            />
          )
        )}
        {}
      </div>
    </th>
  );
};

export default TableHeadItem;
