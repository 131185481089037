import { TbArrowLeft, TbSection } from "react-icons/tb";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { deleteAfdeling, getAfdelings } from "../../../features/afdelingSlice";
import { getEstates, selectStatuses } from "../../../features/estateSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import AfdelingModal from "../../../components/organisms/MasterAfdeling/AfdelingModal";
import Filter from "./partials/Filter/Filter";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import React, { useState } from "react";
import TableRowMasterAfdeling from "../../../components/organisms/Tables/columns/TableRowMasterAfdeling";
import Tables from "../../../components/organisms/Tables";
import headers from "../../../api/tables/afdeling/afdeling_headers.json";
import { selectUserCompanyId } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";
import { TiChartArea } from "react-icons/ti";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import { APP_NAME } from "../../../configs/api";

export const modalName = "masterAfdeling";

const MoContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onShowModal, onModalUpdate, isUpdate } = useModal();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const estateIdFromParams = searchParams.get("estateId");
  const estateNameFromParams = searchParams.get("estateName");

  const modal = useSelector(selectModal);
  const status = useSelector(selectStatuses);
  const currentCompanyId = useSelector(selectUserCompanyId);

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState({
    total: 1,
    current: 1,
  });
  const paginationLenght = Math.ceil(page?.total / 20);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (props) => {
    dispatch(deleteAfdeling({ id: props.id }))
      .unwrap()
      .then(() => {
        alert.success(
          `Berhasil menghapus afdeling ${props.name} - ${props.estate}`
        );
        handleRefreshData();
      })
      .catch((err) => {
        alert.error(`Gagal menghapus afdeling id: ${props.id}`);
        console.error(err);
      });
  };

  const handlePageClick = (page) => {
    dispatch(
      getAfdelings({
        companyId: currentCompanyId,
        page: page.selected,
        estate: estateIdFromParams || undefined,
      })
    ).then((e) => {
      setData(e.payload.records);
      setPage({ ...page, total: e.payload.totalRecords });
    });
  };

  const handleRefreshData = () => {
    dispatch(
      getAfdelings({
        companyId: currentCompanyId,
        page: page.selected,
        estate: estateIdFromParams || undefined,
      })
    )
      .unwrap()
      .then((e) => {
        console.log(e.data.records);
        setData(e.data.records);
        setPage({ current: 0, total: e.data?.totalRecords });
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      getEstates({
        companyId: currentCompanyId,
        page: 0,
        pageSize: 1000,
        type: "selects",
      })
    );
  };

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleRefreshData();
    }

    return () => (isMounted = false);
  }, []);

  // modal initial
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            showModalDetail: false,
            showModalBlock: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  const onFilter = React.useCallback(
    (props) => {
      dispatch(
        getAfdelings({
          companyId: currentCompanyId,
          page: page.selected,
          estate: estateIdFromParams || undefined,
          name: props.value,
        })
      )
        .unwrap()
        .then((e) => {
          setData(e.data.records);
          setPage({ current: 0, total: e.data?.totalRecords });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [data]
  );

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-16">
              <button onClick={() => navigate(-1)}>
                <TbArrowLeft className="text-24 text-gray-500" />
              </button>
              <TbSection className="mr-16 hidden text-24 text-gray-500 md:inline" />
              <p className="line-clamp-1">
                Kebun {estateNameFromParams} / Afdeling
              </p>
            </div>
          }
        />
      </div>

      <AfdelingModal
        isOpen={modal[modalName]?.showModalCreate}
        toggleModal={() => onShowModal(modalName, "showModalCreate")}
        onRefreshData={handleRefreshData}
        params={isUpdate && modal[modalName]?.params}
        type={isUpdate ? "Ubah" : "Tambah"}
        estate={{ id: estateIdFromParams, name: estateNameFromParams }}
      />

      <div>
        <Filter
          modalName={modalName}
          onShowModal={onShowModal}
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>

      <div className="mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
        <Tables
          isLoading={status.isLoading}
          isError={status.isError}
          headers={headers}
          rows={data || []}
          rowContent={(props) => (
            <TableRowMasterAfdeling
              data={props}
              handleDelete={handleDelete}
              handleUpdate={(params) =>
                onModalUpdate(modalName, "showModalCreate", params)
              }
              handleDetail={(afdelingId, afdelingName) => {
                navigate({
                  pathname: "/data-block",
                  search: createSearchParams({
                    afdelingId: afdelingId,
                    afdelingName: afdelingName,
                    estateId: estateIdFromParams,
                    estateName: estateNameFromParams,
                  }).toString(),
                });
              }}
            />
          )}
        />
      </div>
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              current={page.current}
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default MoContent;
