import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  isSidebarSize: true,
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    toggleButton: (state, action) => {
      state.isActive = !state.isActive;
    },
    toggleChangeSidebarSize: (state, action) => {
      state.isSidebarSize = !state.isSidebarSize;
    },
    toggleChangeSidebarSizeManual: (state, action) => {
      state.isSidebarSize = action.payload.data;
    },
  },
});

export const {
  toggleButton,
  toggleChangeSidebarSize,
  toggleChangeSidebarSizeManual,
} = navbarSlice.actions;
export const getIsActive = (state) => state.navbar.isActive;
export const getIsSidebarSize = (state) => state.navbar.isSidebarSize;

export default navbarSlice.reducer;
