export const pemalongan2012 = {
  type: "FeatureCollection",
  name: "pemalongan_tt2012kav_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: "2",
        Luas: 0.495,
        DESA: "Pemalongan",
        Nm_Pemilik: "R Suwito",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.429 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.855246793534661, -3.823544001790478],
              [114.855437024886768, -3.823421630716035],
              [114.855648857509962, -3.823530236176017],
              [114.855807400983309, -3.823697903166585],
              [114.855870435935671, -3.823891137513538],
              [114.855951289858126, -3.824396757464303],
              [114.855266851170541, -3.823911627379489],
              [114.855086962664203, -3.823714564129709],
              [114.855246793534661, -3.823544001790478],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "4",
        Luas: 0.451,
        DESA: "Pemalongan",
        Nm_Pemilik: "Suratno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.565 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851981720692393, -3.82339176420667],
              [114.851944958007536, -3.823058916685383],
              [114.852224965640744, -3.82288970249814],
              [114.852354733774064, -3.822713188815636],
              [114.852528720270428, -3.822619069227182],
              [114.852622715900921, -3.822612225361087],
              [114.852713610495456, -3.822650323943559],
              [114.853002722031803, -3.822856256129852],
              [114.851981720692393, -3.82339176420667],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "1",
        Luas: 1.351,
        DESA: "Pemalongan",
        Nm_Pemilik: "Ardiansyah",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.455 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.856190903735225, -3.822166557416175],
              [114.856317348575104, -3.822136579531863],
              [114.856584310263628, -3.822411924117947],
              [114.856288243968066, -3.822480247067769],
              [114.856278243957092, -3.822640247070396],
              [114.856423760013854, -3.822662585793826],
              [114.856448128534566, -3.822859625815037],
              [114.856448775743345, -3.823439183425702],
              [114.856448243906613, -3.823440247098584],
              [114.856248243884252, -3.823710247090567],
              [114.856269984493565, -3.823968919589762],
              [114.856497519849981, -3.824657529253518],
              [114.856344298102201, -3.824629401472067],
              [114.856244959405174, -3.824591698587626],
              [114.856002572948711, -3.824321978638043],
              [114.855925038932909, -3.823845983098731],
              [114.855848158336642, -3.823662683171984],
              [114.855725250596109, -3.82351308394149],
              [114.85581570205656, -3.823316863464644],
              [114.855821184422936, -3.82290851983013],
              [114.856190903735225, -3.822166557416175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "3",
        Luas: 0.544,
        DESA: "Pemalongan",
        Nm_Pemilik: "R Suwito",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.086 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.855397362689516, -3.823343889476671],
              [114.855389914679932, -3.823006787353991],
              [114.855550734033741, -3.822756112046114],
              [114.855628366165959, -3.822637009682766],
              [114.855766824028095, -3.822493090661347],
              [114.855738367810716, -3.822273845663978],
              [114.856190903735225, -3.822166557416175],
              [114.855821184422936, -3.82290851983013],
              [114.85581570205656, -3.823316863464644],
              [114.855725250596109, -3.82351308394149],
              [114.855700836671161, -3.823483368115977],
              [114.855511878327292, -3.823387179124779],
              [114.855397362689516, -3.823343889476671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "9",
        Luas: 1.583,
        DESA: "Pemalongan",
        Nm_Pemilik: "Zulkarnain",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.500 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848984843867598, -3.8243889634344],
              [114.848883385311922, -3.824416851319056],
              [114.848624835068605, -3.824440001614451],
              [114.848431209850006, -3.824342146510475],
              [114.848014509559661, -3.823862913871052],
              [114.847889836980599, -3.82375657741321],
              [114.847440134865678, -3.823554218703074],
              [114.847318091654614, -3.823257480955572],
              [114.847124905882993, -3.823059289326302],
              [114.847192250530171, -3.822824756603848],
              [114.847731647812523, -3.822564209043039],
              [114.848180895563445, -3.823218350523991],
              [114.848232187292808, -3.823293035437759],
              [114.848984843867598, -3.8243889634344],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "13",
        Luas: 1.628,
        DESA: "Pemalongan",
        Nm_Pemilik: "Madsaeri",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.500 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847883468147714, -3.821917540895047],
              [114.847843344280648, -3.82182693558191],
              [114.847720839301033, -3.821550302134225],
              [114.847702895793873, -3.821477900154656],
              [114.847691403244625, -3.821397782962922],
              [114.847594369468709, -3.820673363484168],
              [114.847595511491221, -3.820215303790576],
              [114.847744792459665, -3.820154824152735],
              [114.848032773793165, -3.820313351725563],
              [114.848409876053353, -3.820786439472464],
              [114.84841411031654, -3.820957267001611],
              [114.848442505181339, -3.821114076089156],
              [114.848910450241334, -3.821232836554057],
              [114.849077678189133, -3.821233257032689],
              [114.849219842887464, -3.82122521562735],
              [114.849331566408139, -3.82126060652202],
              [114.849333373950756, -3.821262722278233],
              [114.848233527529359, -3.821759444527601],
              [114.847883468147714, -3.821917540895047],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "7",
        Luas: 1.552,
        DESA: "Pemalongan",
        Nm_Pemilik: "Ngadiyo",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.487 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851680911755267, -3.823559305389912],
              [114.851361468899327, -3.823785060171943],
              [114.850944991748577, -3.823936353704881],
              [114.850908838039373, -3.823943095454106],
              [114.850856915810581, -3.823868675197662],
              [114.851257661207384, -3.823631797683875],
              [114.851192636653394, -3.823508590358928],
              [114.85082907945781, -3.823569198479485],
              [114.850560294094009, -3.823285521927121],
              [114.850135560850802, -3.823317265762053],
              [114.849692364235395, -3.822565584228351],
              [114.849353909958182, -3.82238426896619],
              [114.849338630770873, -3.822392519543475],
              [114.848953223436553, -3.822600632611198],
              [114.848729875472202, -3.822164950515662],
              [114.848917776693412, -3.822120516448734],
              [114.848944038378079, -3.822122792533683],
              [114.849482120931853, -3.822169617141833],
              [114.849997945231564, -3.822436744822111],
              [114.850119619819338, -3.822499755857126],
              [114.850393528927242, -3.822709649703349],
              [114.850709337558328, -3.822951650577771],
              [114.85139520607315, -3.823382300293854],
              [114.851389598940173, -3.823396132858731],
              [114.851680911755267, -3.823559305389912],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "11",
        Luas: 0.254,
        DESA: "Pemalongan",
        Nm_Pemilik: "Parto",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.288 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848090583371544, -3.822385235981235],
              [114.847883468147714, -3.821917540895047],
              [114.848233527529359, -3.821759444527601],
              [114.848487514224558, -3.82222226593853],
              [114.848363535852243, -3.82225158717538],
              [114.848090583371544, -3.822385235981235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "12",
        Luas: 1.427,
        DESA: "Pemalongan",
        Nm_Pemilik: "S Waloyo",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.500 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848233527529359, -3.821759444527601],
              [114.849333373950756, -3.821262722278233],
              [114.849508794023734, -3.821468054110006],
              [114.849608808986019, -3.821471521658537],
              [114.849857537351838, -3.82170674757488],
              [114.849820587113655, -3.821870575804306],
              [114.850056605881491, -3.822045855768234],
              [114.85016332668016, -3.822072173794617],
              [114.850212303627771, -3.82207443609919],
              [114.849997945231564, -3.822436744822111],
              [114.849482120931853, -3.822169617141833],
              [114.848944038378079, -3.822122792533683],
              [114.84891777752155, -3.822120507273144],
              [114.848676422653142, -3.822177588515802],
              [114.848487514224558, -3.82222226593853],
              [114.848233527529359, -3.821759444527601],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "5",
        Luas: 0.953,
        DESA: "Pemalongan",
        Nm_Pemilik: "Suratno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.050 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.852324505022963, -3.822349177671069],
              [114.852166673435633, -3.822549168181621],
              [114.851741878908115, -3.822830051919776],
              [114.851477091318728, -3.823180292688216],
              [114.85139520607315, -3.823382300293854],
              [114.850709337558328, -3.822951650577771],
              [114.850393528927242, -3.822709649703349],
              [114.852324505022963, -3.822349177671069],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "6",
        Luas: 1.589,
        DESA: "Pemalongan",
        Nm_Pemilik: "Pujiman",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.500 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850212303627771, -3.82207443609919],
              [114.850268575423783, -3.822077035358274],
              [114.850470148885691, -3.821994795638029],
              [114.851126469446015, -3.821855469520722],
              [114.851255920380268, -3.821943138141018],
              [114.851396297910114, -3.821931232063847],
              [114.851803517860802, -3.82196903110873],
              [114.852095096532423, -3.821887016955079],
              [114.852250244905306, -3.82206975518766],
              [114.852400816800213, -3.82225248193647],
              [114.852324505022963, -3.822349177671069],
              [114.850393528927242, -3.822709649703349],
              [114.850119619819338, -3.822499755857126],
              [114.849997945231564, -3.822436744822111],
              [114.850212303627771, -3.82207443609919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "17",
        Luas: 0.906,
        DESA: "Pemalongan",
        Nm_Pemilik: "Warno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.880 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.854448244033719, -3.820940246910484],
              [114.85431824402427, -3.821040246903711],
              [114.854128244016238, -3.821100246892011],
              [114.853858244016706, -3.821010246871767],
              [114.853832429117958, -3.82096586329446],
              [114.853649873424956, -3.820892503942799],
              [114.853529348051708, -3.820890899757938],
              [114.853478244012194, -3.820960246844848],
              [114.853415027553424, -3.820999964985775],
              [114.85329052998965, -3.82103219785312],
              [114.853178244001882, -3.821020246825669],
              [114.853099451381183, -3.820964061493934],
              [114.853430870299533, -3.820316728396185],
              [114.853450823918394, -3.820316289628311],
              [114.853737233964424, -3.820337561221846],
              [114.854207644488667, -3.820420953657337],
              [114.854384756328912, -3.820510461507797],
              [114.854478865050211, -3.820832447835619],
              [114.854428244040719, -3.820830246906842],
              [114.854448244033719, -3.820940246910484],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "14",
        Luas: 1.79,
        DESA: "Pemalongan",
        Nm_Pemilik: "Suwarno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.562 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.853099451381183, -3.820964061493934],
              [114.852906287787519, -3.820826320625261],
              [114.852558244035563, -3.820330246769124],
              [114.852318244033924, -3.820280246751746],
              [114.852338244053243, -3.820000246747297],
              [114.85228824405894, -3.819900246741819],
              [114.852148244070193, -3.819690246727915],
              [114.851819424534412, -3.819782145350065],
              [114.851578244052249, -3.819780246690975],
              [114.851448244057678, -3.819660246679629],
              [114.851403047866825, -3.819469886496238],
              [114.851391768299152, -3.819119997156771],
              [114.851438244105353, -3.818950246664212],
              [114.85143508276478, -3.818802790062003],
              [114.851478088499078, -3.818609660797609],
              [114.851588244142221, -3.818450246664041],
              [114.851636070459278, -3.818432311800231],
              [114.851674900662431, -3.818612774104693],
              [114.852655048534061, -3.819793402296355],
              [114.852660923721231, -3.819800479191909],
              [114.852762856333413, -3.819951458000549],
              [114.852932857663035, -3.820157415994452],
              [114.853048567643341, -3.8202536209977],
              [114.853171166297827, -3.820322439085377],
              [114.853430870299533, -3.820316728396185],
              [114.853099451381183, -3.820964061493934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "15",
        Luas: 1.562,
        DESA: "Pemalongan",
        Nm_Pemilik: "Alinsyah",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.480 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851636070459278, -3.818432311800231],
              [114.851668244145927, -3.818420246668874],
              [114.851498244163977, -3.818100246650656],
              [114.851858244174878, -3.818050246674132],
              [114.852233032229918, -3.818192646614222],
              [114.852438244164674, -3.818380246720471],
              [114.85251824415549, -3.818540246729236],
              [114.852647081377711, -3.818690286141496],
              [114.85274824413537, -3.818910246752584],
              [114.852838244121699, -3.819140246763488],
              [114.853048244126725, -3.819130246777576],
              [114.853258905166996, -3.819180476171565],
              [114.853432038886297, -3.819262991450065],
              [114.852722648914792, -3.819663613120038],
              [114.852655048534061, -3.819793402296355],
              [114.851674900662431, -3.818612774104693],
              [114.851636070459278, -3.818432311800231],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "16",
        Luas: 1.461,
        DESA: "Pemalongan",
        Nm_Pemilik: "Jupri",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.525 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.853432038886297, -3.819262991450065],
              [114.853468244125366, -3.819280246809299],
              [114.853588244129213, -3.819260246817045],
              [114.853748244112964, -3.81955024683396],
              [114.853818244094839, -3.819840246844749],
              [114.854038244083924, -3.820070246864511],
              [114.854238244069833, -3.820340246883736],
              [114.854418444204683, -3.820494411535437],
              [114.855074454647237, -3.820669237076554],
              [114.854889106072079, -3.820851056718282],
              [114.854888244048965, -3.820850246938579],
              [114.854478865050211, -3.820832447835619],
              [114.854384756328912, -3.820510461507797],
              [114.854207644488667, -3.820420953657337],
              [114.853737233964424, -3.820337561221846],
              [114.853450823918394, -3.820316289628311],
              [114.853430870299533, -3.820316728396185],
              [114.853171166297827, -3.820322439085377],
              [114.853048567643341, -3.8202536209977],
              [114.852932857663035, -3.820157415994452],
              [114.852762856333413, -3.819951458000549],
              [114.852660923721231, -3.819800479191909],
              [114.852655048534061, -3.819793402296355],
              [114.852722648914792, -3.819663613120038],
              [114.853432038886297, -3.819262991450065],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "18",
        Luas: 0.739,
        DESA: "Pemalongan",
        Nm_Pemilik: "Warno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.667 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.855074454647237, -3.820669237076554],
              [114.855078244065069, -3.820670246947782],
              [114.855378244078736, -3.820560246965932],
              [114.855618244085747, -3.820530246981654],
              [114.855918244079859, -3.820710247005825],
              [114.855898244073373, -3.820800247006322],
              [114.855758244059004, -3.820970247000317],
              [114.855688244038589, -3.821250247001369],
              [114.855668244022695, -3.821480247004784],
              [114.85556824400912, -3.821650247001493],
              [114.855458244008176, -3.821630246993592],
              [114.855298244026443, -3.821310246976049],
              [114.85521824403493, -3.821160246967489],
              [114.854889106072079, -3.820851056718282],
              [114.855074454647237, -3.820669237076554],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "22",
        Luas: 2.244,
        DESA: "Pemalongan",
        Nm_Pemilik: "Arhani",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.560 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.856778348173535, -3.82021692055739],
              [114.856747629955734, -3.820101241089719],
              [114.85667920655797, -3.819925290889867],
              [114.856343873494325, -3.819723286857942],
              [114.856043542175172, -3.819694681043231],
              [114.855834035983932, -3.819692608756831],
              [114.855526359605847, -3.819520074744021],
              [114.855246731611942, -3.819219175521592],
              [114.855029596104714, -3.819187683103714],
              [114.854753848123735, -3.819185444617982],
              [114.85452441167719, -3.819144636770262],
              [114.854364563601251, -3.818997231734434],
              [114.854274980401769, -3.818889938313932],
              [114.854378173023505, -3.818832690601893],
              [114.854596702802013, -3.818462308805238],
              [114.854767293625684, -3.818348689130318],
              [114.854794857595209, -3.818330330519312],
              [114.85513760762494, -3.818390802982117],
              [114.855420862000869, -3.818510740001062],
              [114.855835201753976, -3.8189239139196],
              [114.855960254373699, -3.819048614602529],
              [114.857113007659805, -3.819548282695405],
              [114.857115357438417, -3.819549672239644],
              [114.857112714154965, -3.819554905712721],
              [114.856778348173535, -3.82021692055739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "19",
        Luas: 1.068,
        DESA: "Pemalongan",
        Nm_Pemilik: "Slamet Riadi",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.655 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.854767293625684, -3.818348689130318],
              [114.854596702802013, -3.818462308805238],
              [114.854378173023505, -3.818832690601893],
              [114.854274980401769, -3.818889938313932],
              [114.854117275496691, -3.818701055762537],
              [114.85395300211411, -3.818474721536423],
              [114.853713016961294, -3.818339494926346],
              [114.85333893065534, -3.8182348803278],
              [114.853345638724917, -3.818015164470859],
              [114.853460382314623, -3.817715253962032],
              [114.853567920133671, -3.817508600428301],
              [114.854034194548476, -3.817835197777238],
              [114.854767293625684, -3.818348689130318],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "21",
        Luas: 2.119,
        DESA: "Pemalongan",
        Nm_Pemilik: "Nuryani",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.699 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.855835201753976, -3.8189239139196],
              [114.855420862000869, -3.818510740001062],
              [114.85513760762494, -3.818390802982117],
              [114.854794857595209, -3.818330330519312],
              [114.854767293625684, -3.818348689130318],
              [114.854034194548476, -3.817835197777238],
              [114.854213356421866, -3.817362112136445],
              [114.854345102892182, -3.817399868225503],
              [114.85458027517619, -3.817334194674875],
              [114.855168067094681, -3.817225919317793],
              [114.855254041511415, -3.817243065720751],
              [114.855371793060996, -3.817371379436413],
              [114.855365176889094, -3.817545302742391],
              [114.855340063977451, -3.817696401877481],
              [114.855294562490258, -3.817756338578225],
              [114.85508406086295, -3.817848993353802],
              [114.855013420633938, -3.818070382609246],
              [114.855056541914308, -3.818138824147803],
              [114.855171933052802, -3.818159820353778],
              [114.855444150493568, -3.818119088004222],
              [114.855572172671302, -3.818034509432565],
              [114.855665478710421, -3.817817318238643],
              [114.855824602805598, -3.817660342510151],
              [114.856040913705442, -3.817718864127095],
              [114.856289564708106, -3.818046913265429],
              [114.855835201753976, -3.8189239139196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "20",
        Luas: 0.808,
        DESA: "Pemalongan",
        Nm_Pemilik: "Slamet Riadi",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.845 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.854034194548476, -3.817835197777238],
              [114.853567920133671, -3.817508600428301],
              [114.853575937647889, -3.81749319330513],
              [114.853621819832625, -3.817280025135565],
              [114.853604022726245, -3.816975586093797],
              [114.853569600100712, -3.816724943862985],
              [114.853697879158872, -3.816536830671096],
              [114.853914312787239, -3.816545655834396],
              [114.854060425120167, -3.816647486762185],
              [114.854125934503074, -3.816831944185804],
              [114.854329752650145, -3.816940131828045],
              [114.854376868248579, -3.817060351134725],
              [114.854281804071263, -3.817155365679638],
              [114.854133054209711, -3.817285447768056],
              [114.854155581252016, -3.817345554840534],
              [114.854213356421866, -3.817362112136445],
              [114.854034194548476, -3.817835197777238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "25",
        Luas: 1.81,
        DESA: "Pemalongan",
        Nm_Pemilik: "A Rosadi",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.436 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.859032928842367, -3.820361765874013],
              [114.858887604221451, -3.820469543485316],
              [114.858724860941692, -3.820422946901762],
              [114.858502311944434, -3.820541712365577],
              [114.858186190528954, -3.820535147445087],
              [114.857956159064571, -3.820580760907929],
              [114.857749038103634, -3.820659149079412],
              [114.857630751216689, -3.820747804820948],
              [114.857487340898615, -3.820804700152519],
              [114.857253147535431, -3.820818040410021],
              [114.857011328439157, -3.820800413161972],
              [114.856892843013043, -3.820745956793427],
              [114.856836612160436, -3.820436332609692],
              [114.856778348173535, -3.82021692055739],
              [114.857112714154965, -3.819554905712721],
              [114.85711535774584, -3.819549673870622],
              [114.85711590890179, -3.81954999834812],
              [114.857382975924637, -3.819707928682107],
              [114.85855283714838, -3.820171517274652],
              [114.859032928842367, -3.820361765874013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "23",
        Luas: 1.229,
        DESA: "Pemalongan",
        Nm_Pemilik: "Supono",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.923 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.85711590890179, -3.81954999834812],
              [114.857115357438417, -3.819549672239644],
              [114.857113007659805, -3.819548282695405],
              [114.855960254373699, -3.819048614602529],
              [114.855835201753976, -3.8189239139196],
              [114.856289564708106, -3.818046913265429],
              [114.856349127809438, -3.818125495765714],
              [114.856404268670062, -3.818334775956011],
              [114.856451477084363, -3.818417722726636],
              [114.85661980393715, -3.818705973483351],
              [114.856708334428262, -3.818751750864973],
              [114.856823730943589, -3.818770676214987],
              [114.856990715805594, -3.8187690235535],
              [114.857081276994975, -3.818827230285283],
              [114.857155238480686, -3.818928880478387],
              [114.85732193572025, -3.81904318711047],
              [114.857435255439853, -3.819068319355104],
              [114.857474459114002, -3.819078608359793],
              [114.857364294420805, -3.819264090439407],
              [114.857238807198598, -3.819343261328012],
              [114.85711590890179, -3.81954999834812],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "26",
        Luas: 0.147,
        DESA: "Pemalongan",
        Nm_Pemilik: "A Rosadi",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.154 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.858846398401027, -3.819912637650983],
              [114.859133302381025, -3.82028732545289],
              [114.859032928842367, -3.820361765874013],
              [114.85855283714838, -3.820171517274652],
              [114.858846398401027, -3.819912637650983],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "24",
        Luas: 1.095,
        DESA: "Pemalongan",
        Nm_Pemilik: "Supono",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "0.645 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.858626157437854, -3.819625009545357],
              [114.858846398401027, -3.819912637650983],
              [114.85855283714838, -3.820171517274652],
              [114.857382975924637, -3.819707928682107],
              [114.85711590890179, -3.81954999834812],
              [114.857238807198598, -3.819343261328012],
              [114.857364294420805, -3.819264090439407],
              [114.857474459114002, -3.819078608359793],
              [114.857554744202545, -3.819099679176588],
              [114.857717457287336, -3.819158066477205],
              [114.857923695966647, -3.819271909013239],
              [114.858141422949259, -3.819420435686355],
              [114.858626155394191, -3.819625007580689],
              [114.858626157437854, -3.819625009545357],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "10",
        Luas: 1.279,
        DESA: "Pemalongan",
        Nm_Pemilik: "Parto",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.242 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84872987120562, -3.822164950504929],
              [114.848953216436172, -3.822600638467892],
              [114.849046595467897, -3.822777057800537],
              [114.849467676176502, -3.822610138029702],
              [114.849600265096925, -3.822822565192167],
              [114.848232187304831, -3.823293035319492],
              [114.848180895563445, -3.823218350523991],
              [114.847731647812523, -3.822564209043039],
              [114.848092624255727, -3.822389844581767],
              [114.848090583371544, -3.822385235981235],
              [114.848363535852243, -3.82225158717538],
              [114.848487514224558, -3.82222226593853],
              [114.848676422653142, -3.822177588515802],
              [114.84872987120562, -3.822164950504929],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: "8",
        Luas: 2.146,
        DESA: "Pemalongan",
        Nm_Pemilik: "Kasno",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET: null,
        LUAS_SHM: "1.500 Ha",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.849600265938776, -3.822822568611039],
              [114.849939445229197, -3.823365990138227],
              [114.848913839603057, -3.823654613891618],
              [114.849068386995071, -3.823901090178326],
              [114.850482008357147, -3.823568325947408],
              [114.85082256479437, -3.823959183217507],
              [114.849952890200427, -3.824121354811435],
              [114.849060873209609, -3.82436806526014],
              [114.848984843867598, -3.8243889634344],
              [114.848232187292808, -3.823293035437759],
              [114.849600265938776, -3.822822568611039],
            ],
          ],
        ],
      },
    },
  ],
};
