export const headersData = [
	{
		value: "NO.",
		accessorKey: "index",
		type: "alphabet",
	},
	{
		value: "Foto",
		accessorKey: "photo",
		type: "alphabet",
	},
	{
		value: "Tanggal Diterima PKS",
		accessorKey: "date",
		type: "alphabet",
		sorted: true,
	},
	{
		value: "Dibuat Oleh",
		accessorKey: "createdBy",
		type: "alphabet",
	},
	{
		value: "Komentar",
		accessorKey: "remark",
		type: "alphabet",
	},
	{
		value: "Tanggal Tindakan",
		accessorKey: "caDueDate",
		type: "alphabet",
	},
	{
		value: "Netto PKS (kg)",
		accessorKey: "nettoPKs",
		type: "alphabet",
	},
	{
		value: "TBS Diterima (jjg)",
		accessorKey: "tbsCount",
		type: "alphabet",
	},
	{
		value: "Pinalti (jjg)",
		accessorKey: "factoryPinalty",
		type: "alphabet",
	},
	{
		value: "BJR PKS (kg)",
		accessorKey: "bjrPks",
		type: "alphabet",
	},
	{
		value: "Bruto PKS (kg)",
		accessorKey: "factoryBruto",
		type: "alphabet",
	},
	{
		value: "Tarra PKS (kg)",
		accessorKey: "factoryTarra",
		type: "alphabet",
	},
	{
		value: "Nopol",
		accessorKey: "nopol",
		type: "alphabet",
	},
	// {
	// 	value: "Tahun Tanam",
	// 	accessorKey: "plantedYear",
	// 	type: "alphabet",
	// },
];
