import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { COLLECTION_POINTS_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	collectionPoints: [],
	isLoading: false,
	isError: false,
	errors: null,
};

export const getCollectionPoints = createAsyncThunk(
	"collection-point/getCollectionPoints",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);
			const response = await axios.get(COLLECTION_POINTS_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					...data,
					sidx: "syncInfo.updatedTime",
					sord: "desc",
				},
			});
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createCollectionPoint = createAsyncThunk(
	"collection-point/createCollectionPoint",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(COLLECTION_POINTS_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateCollectionPoint = createAsyncThunk(
	"collection-point/updateCollectionPoint",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(
				`${COLLECTION_POINTS_URL}/${data?.id}`,
				data,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteCollectionPoint = createAsyncThunk(
	"user/deleteCollectionPoint",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(
				`${COLLECTION_POINTS_URL}/${data?.id}`,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const collectionPointSlice = createSlice({
	name: "collectionPoint",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCollectionPoints.pending, (state) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getCollectionPoints.fulfilled, (state, action) => {
				state.isLoading = false;
				const records = action.payload?.records;
				state.collectionPoints = records;
			})
			.addCase(getCollectionPoints.rejected, (state) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(createCollectionPoint.fulfilled, (state, action) => {})
			.addCase(updateCollectionPoint.fulfilled, (state, action) => {})
			.addCase(deleteCollectionPoint.fulfilled, (state, action) => {});
	},
});

export const {} = collectionPointSlice.actions;
export const selectCollectionPoints = (state) =>
	state.collectionPoint.collectionPoints;
export const selectCollectionPointStatus = (state) => ({
	isLoading: state.collectionPoint.isLoading,
	isError: state.collectionPoint.isError,
	errors: state.collectionPoint.errors,
});

export default collectionPointSlice.reducer;
