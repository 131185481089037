import Chemist from "./Chemist";
import Trading from "./Trading";
import Fertilizations from "./Fertilizations";

const Dashboard = {
  Chemist,
  Fertilizations,
  Trading,
};
export default Dashboard;
