import "react-lazy-load-image-component/src/effects/blur.css";

import { ConfigProvider, DatePicker, Image, Tooltip, notification } from "antd";
import {
  Number,
  formatNumericValue,
} from "../../../../../../../../../helpers/Common";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Controller } from "react-hook-form";
import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import { MdOutlineWarning } from "react-icons/md";
import { TbCalendar } from "react-icons/tb";
import { analytics } from "../../../../../../../../../App";
import { checkValues } from "../../../../../../../../../helpers/validationHelper";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { pagePath } from "../../../../../../ListView";
import { updateHarvesting } from "../../../../../../../../../features/harvestingSlice";
import { useComment } from "../../../../../../../../../hooks/useComment";
import { useDispatch } from "react-redux";
import { useImageSource } from "../../../../../../../../../hooks/useImageSource";
import { useImageViewer } from "../../../../../../contexts/imageViewerContext";
import { cn } from "../../../../../../../../../utils/utils";
import { APP_NAME } from "../../../../../../../../../configs/api";
import { DEFAULT_TABLE_THEME } from "../../../../../../../../../utils/constant";

dayjs.extend(customParseFormat);

const Item = ({ item, index, handleRefreshData }) => {
  const rowRef = useRef(null);
  const { setIsOpenImagePreviewer, currentImageViewer, setCurrentImageViewer } =
    useImageViewer();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    onChangeDate,
    onChangeText,
    setData,
  } = useComment({ input: item });
  const watchRemark = watch(`remark-${item?.id}`);
  const dispatch = useDispatch();
  const { source, setSource, setDownloadedImage } = useImageSource();
  const [message, setMessage] = useState({
    message: "",
    isError: false,
  });

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  const updateFunc = async (request) => {
    try {
      const response = await dispatch(updateHarvesting(request)).unwrap();
      if (!response?.success) {
        return notification.error({
          message: "Gagal Memberikan Komentar",
          description: response.message,
          placement: "bottomRight",
        });
      }
      return response.record;
    } catch (error) {}
  };

  const request = debounce(async (searchTerm) => {
    onChangeText({
      key: "remark",
      data: searchTerm,
      refreshFunc: handleRefreshData,
      updateFunc,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [item]
  );

  useEffect(() => {
    setValue(`remark-${item?.id}`, item?.remark);
    setValue(
      `caDueDate-${item?.id}`,
      item?.caDueDate
        ? dayjs(moment(item?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [item?.id]);

  useEffect(() => {
    setData(item);
  }, [item?.caDueDate, item?.remark, item]);

  useEffect(() => {
    const fieldNames = {
      estateName: "Estate",
      afdelingName: "Afdeling",
      adhocBlockName: "Blok",
      collectionPointName: "TPH",
      totalWeight: "Total panen",
      fruitCount: "Total janjang",
      unripeCount: "Buah mentah",
    };

    const items = [
      { estateName: item?.estateName },
      { afdelingName: item?.afdelingName },
      { adhocBlockName: item?.adhocBlockName },
      { collectionPointName: item?.collectionPointName },
      { totalWeight: item?.totalWeight },
      { fruitCount: item?.fruitCount },
      { unripeCount: item?.unripeCount },
    ];

    setMessage(checkValues(items, fieldNames));

    setDownloadedImage(item?.photoFileId, "main");
  }, [item?.photoFileId]);

  useEffect(() => {
    if (currentImageViewer?.id === item?.id) {
      rowRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      });
    }
  }, [currentImageViewer?.id]);

  return (
    <tr
      ref={rowRef}
      className={cn(
        DEFAULT_TABLE_THEME,
        currentImageViewer?.id === item?.id
          ? APP_NAME === "semai-kebun"
            ? "bg-[rgba(124,7,17,0.2)] hover:bg-[rgba(124,7,17,0.2)]"
            : "bg-[rgba(215,161,55,0.3)]"
          : ""
      )}
      onMouseEnter={() =>
        currentImageViewer?.id === item?.id && setCurrentImageViewer({})
      }
      key={index}
    >
      <td className="table-row-regular">
        {index + 1}
        <Tooltip
          placement="bottom"
          title={message.message}
          className="font-regular"
        >
          <div>
            {message.isError ? (
              <MdOutlineWarning className="text-24 text-yellow-600" />
            ) : null}
          </div>
        </Tooltip>
      </td>
      <td className="table-row-regular">
        <div className="hfi w-fit">
          <img
            src={source.main}
            alt={item?.photoFileId}
            className="aspect-square w-[80px] cursor-pointer rounded-8"
            onClick={() => {
              logEvent(
                analytics,
                `image_panen_${pagePath}_${window.location.host}`,
                {
                  page_title: `${pagePath}_${window.location.host}`,
                  page_location: window.location.href,
                }
              );
              // handleShowVisible("main");
              setIsOpenImagePreviewer(true);
              setCurrentImageViewer({
                ...item,
                index,
              });
            }}
          />
          <Image
            wrapperStyle={{ display: "none" }}
            src={source.main}
            preview={{
              visible: source.mainVisible,
              src: source.main || IMAGE_NOT_FOUND,
              onVisibleChange: () => handleShowVisible("main"),
              toolbarRender: () => (
                <div className="w-full bg-green-800 p-24">Hellow</div>
              ),
            }}
          />
        </div>
      </td>
      <td className="table-row-regular">
        <p>{moment(item?.date).format("LL")}</p>
        <p>{moment(item?.createdTime).format("H:mm")}</p>
      </td>
      <td className="table-row-regular">{item?.fullname}</td>
      <td className="table-row-regular w">
        <InputHook
          control={control}
          name={`remark-${item?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          afterOnChange={debounceTextRequest}
          onBlur={() => {
            onChangeText({
              key: "remark",
              data: watchRemark,
              refreshFunc: handleRefreshData,
              updateFunc,
              silent: true,
            });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit((fieldValues) =>
                onChangeText({
                  key: "remark",
                  data: fieldValues[`remark-${item?.id}`],
                  refreshFunc: handleRefreshData,
                  updateFunc,
                })
              )(event);
            }
          }}
        />
      </td>
      <td className="table-row-regular">
        <Controller
          name={`caDueDate-${item?.id}`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    onChangeDate({
                      key: "caDueDate",
                      date: date,
                      refreshFunc: handleRefreshData,
                      updateFunc,
                    });
                  }}
                  placeholder="Tanggal tindakan"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: 160,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    fontFamily: "Inter-Regular",
                    maxWidth: 250,
                    // fontSize: 16,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </td>
      <td className="table-row-regular">{item?.adhocBlockName}</td>
      <td className="table-row-regular">{item?.collectionPointName}</td>
      <td className="table-row-regular">
        {item?.fruitCount || item?.fruitCount === 0
          ? Number(item?.fruitCount)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.unripeCount || item?.unripeCount === 0
          ? Number(item?.unripeCount)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.abnormalCount || item?.abnormalCount === 0
          ? Number(item?.abnormalCount)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.totalWeight || item?.totalWeight === 0
          ? formatNumericValue(item?.totalWeight, {
              decimals: 2,
            })
          : null}
      </td>
      {/* <td className="table-row-regular">{item?.estateName}</td>
      <td className="table-row-regular">{item?.afdelingName}</td> */}
    </tr>
  );
};

export default Item;
