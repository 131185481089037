import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	fields: {},
};

export const AccountingSlice = createSlice({
	name: "accountingReport",
	initialState,
	reducers: {
		handleFieldsOnLocal: (state, action) => {
			state.fields = action.payload;
		},
	},
});

export const { handleFieldsOnLocal } = AccountingSlice.actions;
export const getAllAccountingFields = (state) => state.accountingReport.fields;
export default AccountingSlice.reducer;
