import React from "react";
import Pagination from "../../../../../../../components/atoms/Pagination";

const PaginationContainer = ({
  length = 0,
  current = 0,
  total = 0,
  onPageClick: pageClickHandler,
}) => {
  return (
    <div className="-mt-8">
      <Pagination.Container className="mx-0 mb-0">
        <Pagination.Records
          current={current}
          length={length}
          totalRecords={total}
        />
        {length !== 1 && (
          <Pagination pageCount={length} onPageClick={pageClickHandler} />
        )}
      </Pagination.Container>
    </div>
  );
};

export default PaginationContainer;
