import * as yup from "yup";

export const formSchema = yup.object().shape({
	code: yup
		.string("kode diperlukan")
		.required("kode diperlukan")
		.typeError("kode diperlukan"),
	name: yup
		.string("nama afdeling diperlukan")
		.required("nama afdeling diperlukan")
		.typeError("nama afdeling diperlukan"),
	areaHa: yup
		.number("luas area (Ha) diperlukan")
		.required("luas area (Ha) diperlukan")
		.typeError("luas area (Ha) diperlukan"),
});
