import { ConfigProvider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  getUser,
  selectStatuses,
  submitCodeVerif,
  verifEmailAddress,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../components/atoms/ButtonComponent";
import Content from "./partials/Content/Content";
import { LOGOUT_CALLBACK_URL } from "../../configs/api";
import LOGO_LONG from "../../assets/icons/horizontal.png";
import { selectKeycloak } from "../../features/authSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const statusRequest = useSelector(selectStatuses);
  const [user, setUser] = useState();
  const keycloak = useSelector(selectKeycloak);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSended, setIsSended] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleLogoutAccount = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user:api");

    keycloak.logout({
      redirectUri: LOGOUT_CALLBACK_URL,
    });

    return navigate("/");
  };

  const handleEmailClick = () =>
    window.open(`https://mail.google.com/mail/u/0`, "_blank");

  const sendCodeVerification = async () => {
    try {
      const response = await dispatch(
        verifEmailAddress({ id: user?.id })
      ).unwrap();
      setIsSended(true);
      if (!response.success) {
        return alert.error(response?.message);
      }
      alert.success(
        "Email verifikasi telah kami kirimkan ke alamat email yang Anda daftarkan. Silahkan cek inbox atau spam folder Anda"
      );
    } catch (error) {
      setIsSended(false);
      return alert.error(error?.data);
    }
  };

  const onSubmit = async (data) => {
    if (isSended) {
      return dispatch(submitCodeVerif({ id: user?.id, code: data?.code }))
        .unwrap()
        .then((resp) => {
          if (!resp.success) {
            return alert.error(resp.message);
          }
          setIsSended(false);
          alert.success("Email address Anda telah berhasil diverifikasi");
          setValue("email", undefined);
          setValue("code", undefined);
          setValue("password", undefined);
          setValue("confirmPassword", undefined);
          navigate("/produksi-kebun");
        })
        .catch((error) => {
          return alert.error(error?.data);
        });
    }

    sendCodeVerification();
  };

  const handleGetUser = async () => {
    try {
      const remainingUser = JSON.parse(localStorage.getItem("user"));
      const remainingToken = JSON.parse(localStorage.getItem("token"));
      const sub = remainingUser.sub;
      const token = remainingToken.accessToken;

      const response = await dispatch(
        getUser({ id: sub, token: token })
      ).unwrap();
      if (!response.success) {
        return alert.error(response.message);
      }
      const record = response.record;
      setUser(record);
      if (record?.isEmailVerified === 0) {
        setIsModalOpen(true);
      } else if (record?.isEmailVerified === 1) {
        setIsModalOpen(false);
      }
      localStorage.setItem("user:api", JSON.stringify(record));
    } catch (error) {
      return alert.error(error?.data);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    reset({ email: user?.email });
  }, [user?.email]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Modal
        width={500}
        open={isModalOpen}
        closable={false}
        keyboard={false}
        wrapClassName="backdrop-blur-sm cursor-not-allowed"
        className="cursor-default"
        maskClosable={false}
        footer={null}
        zIndex={20}
      >
        <div className="relative flex w-full flex-col items-center justify-center rounded-16 bg-gray-30 py-24">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative flex w-full max-w-[380px] flex-col items-center justify-center rounded-16 border border-gray-10 bg-white p-16"
          >
            <img src={LOGO_LONG} className="mb-16 h-72 w-auto" alt="SawitWeb" />
            <div className="flex flex-col space-y-8">
              <h2 className="text-20 font-semi-bold text-gray-500">
                Verifikasi Email
              </h2>
              <p className="font-regular text-14 text-gray-400">
                Terima kasih telah mendaftar! Kami telah mengirimkan email
                verifikasi ke alamat email yang Anda daftarkan.
              </p>
              <p className="font-regular text-14 text-gray-400">
                Silakan buka email Anda dan ikuti instruksi selanjutnya untuk
                mengonfirmasi akun Anda. Jika tidak menerima email verifikasi,
                periksa folder spam atau kirim ulang email.
              </p>
            </div>
            <Content register={register} errors={errors} isSended={isSended} />
            <div className="flex w-full flex-col items-center justify-center space-y-16">
              <ButtonComponent
                value={`${isSended ? "Verifikasi Email" : "Kirim"}`}
                size="sm"
                type="submit"
                className="button-transition w-full"
              />
              {isSended && (
                <div className="flex flex-row items-center space-x-16">
                  <ButtonComponent
                    disabled={statusRequest.isRequest}
                    value="Buka Email di Gmail"
                    size="sm"
                    type="button"
                    className="bordered-button-transition w-full"
                    onClick={handleEmailClick}
                  />
                  <ButtonComponent
                    disabled={statusRequest.isRequest}
                    value="Kirim Ulang Email Verifikasi"
                    size="sm"
                    type="button"
                    className="bordered-button-transition w-full"
                    onClick={sendCodeVerification}
                  />
                </div>
              )}
              <ButtonComponent
                value="Akhiri sesi"
                size="sm"
                type="button"
                className="bordered-button-transition w-full"
                onClick={handleLogoutAccount}
              />
            </div>
          </form>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default EmailVerification;
