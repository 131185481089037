import { MaterialReactTable } from "material-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMeasure } from "@uidotdev/usehooks";
import { formatNumericValue } from "../../../../../helpers/Common";
import moment from "moment/moment";
import { MdDragIndicator, MdOutlineWarning } from "react-icons/md";
import { ConfigProvider, DatePicker, Image, notification, Tooltip } from "antd";
import { checkValues } from "../../../../../helpers/validationHelper";
import { IMAGE_NOT_FOUND } from "../../../../../api/fallbackImageNotFound";
import { useImageSource } from "../../../../../hooks/useImageSource";
import { capitalize, debounce, isUndefined } from "lodash";
import InputHook from "../../../../../components/atoms/InputHook";
import { useComment } from "../../../../../hooks/useComment";
import { TbCalendar } from "react-icons/tb";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { create } from "zustand";
import ColumnSettings from "../../../../../components/atoms/Table/partials/ColumnSettings";
import { useChemistStore } from "../../../../../api/chemist/useChemistStore";

const ImagesRow = ({ row }) => {
  const rowData = row.original;

  const { source, setSource, setDownloadedImage } = useImageSource();
  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(rowData?.photoFileId, "main");
    setDownloadedImage(rowData?.photoFileId2, "second");
  }, []);

  return (
    <div className="flex flex-row items-center space-x-8">
      <Tooltip
        placement="bottom"
        title="Foto Penerimaan"
        className="font-regular"
      >
        <img
          src={source.main || IMAGE_NOT_FOUND}
          alt={rowData?.photoFileId}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("main")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.main}
        preview={{
          visible: source.mainVisible,
          src: source.main || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("main"),
        }}
      />
      <Tooltip placement="bottom" title="Foto Sisa" className="font-regular">
        <img
          src={source.second || IMAGE_NOT_FOUND}
          alt={rowData?.photoFileId2}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("second")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.second}
        preview={{
          visible: source.secondVisible,
          src: source.second || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("second"),
        }}
      />
    </div>
  );
};

const RemarkRow = ({ row, refreshHandler }) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    onChangeText,
    setData,
    setVersion,
  } = useComment({ input: row.original });
  const watchRemark = watch(`remark-${row.original?.id}`);

  const updateChemist = useChemistStore((state) => state.updateChemist);

  const updateFunc = async (body) => {
    const response = await updateChemist({
      id: body?.id,
      body,
      silent: true,
      onError: () => {
        notification.error({
          message: "Gagal Memberikan Komentar",
          placement: "bottomRight",
        });
      },
    });
    return response?.record;
  };

  const request = debounce(async (searchTerm) => {
    onChangeText({
      key: "remark",
      data: searchTerm,
      refreshFunc: refreshHandler,
      updateFunc,
      silent: true,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      setData(row.original);
      setVersion(row.original.version);

      request(searchTerm);
    },
    [row.original]
  );

  useEffect(() => {
    setData(row.original);
    setVersion(row.original.version);
  }, [row.original.remark]);

  useEffect(() => {
    setValue(`remark-${row.original?.id}`, row.original?.remark);
  }, []);

  return (
    <InputHook
      control={control}
      name={`remark-${row?.original?.id}`}
      placeholder="komentar"
      className="min-w-[160px] rounded-8 shadow"
      inputClassName="input-mini-border pl-8 pr-24 w-full"
      afterOnChange={debounceTextRequest}
      onBlur={() => {
        onChangeText({
          key: "remark",
          data: watchRemark,
          refreshFunc: refreshHandler,
          updateFunc,
        });
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmit((fieldValues) =>
            onChangeText({
              key: "remark",
              data: fieldValues[`remark-${row?.original?.id}`],
              refreshFunc: refreshHandler,
              updateFunc,
            })
          )(event);
        }
      }}
    />
  );
};

const DueDateRow = ({ row, refreshHandler }) => {
  const { control, setValue, watch, onChangeDate, setData, setVersion } =
    useComment({ input: row.original });

  const updateChemist = useChemistStore((state) => state.updateChemist);

  const updateFunc = async (body) => {
    const response = await updateChemist({
      id: body?.id,
      body,
      silent: true,
      onError: () => {
        notification.error({
          message: "Gagal Memberikan Tanggal Tindakan",
          placement: "bottomRight",
        });
      },
    });
    return response?.record;
  };

  useEffect(() => {
    setData(row.original);
    setVersion(row.original.version);
  }, [row.original.caDueDate]);

  useEffect(() => {
    setValue(
      `caDueDate-${row.original?.id}`,
      row.original?.caDueDate
        ? dayjs(moment(row.original?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, []);

  return (
    <Controller
      name={`caDueDate-${row.original?.id}`}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#d7a137",
                colorTextQuaternary: "#e1e4e8",
                fontFamily: "Inter-Regular",
                fontSize: 16,
                colorTextPlaceholder: "#6B6F77",
              },
            }}
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={value}
              onChange={(date) => {
                onChange(date);
                onChangeDate({
                  key: "caDueDate",
                  date: date || null,
                  refreshFunc: refreshHandler,
                  updateFunc,
                });
              }}
              placeholder="Tanggal tindakan"
              suffixIcon={<TbCalendar className="text-16" />}
              style={{
                width: 160,
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                fontFamily: "Inter-Regular",
                maxWidth: 250,
                borderWidth: 1,
                borderColor: "#e1e4e8",
              }}
            />
          </ConfigProvider>
        );
      }}
    />
  );
};

export const useTableStore = create((set, get) => ({
  isDrawer: false,
  setIsDrawer: (value) => set({ isDrawer: !get().isDrawer }),
  sortedColumns: [],
  setSortedColumns: (value) => set({ sortedColumns: value }),
}));

const ListData = ({ data, refreshHandler }) => {
  const columns = useMemo(
    () => [
      {
        header: "NO.",
        accessorKey: "index",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ cell, row }) => {
          const message = generateMessage(row.original);

          return (
            <div className="flex flex-row items-center space-x-8">
              <Tooltip
                placement="bottom"
                title={message.message}
                className="font-regular"
              >
                <div>
                  {message.isError ? (
                    <MdOutlineWarning className="text-24 text-yellow-600" />
                  ) : null}
                </div>
              </Tooltip>
              <p>{row.index + 1}</p>
            </div>
          );
        },
      },
      {
        header: "Foto",
        accessorKey: "photoFileId",
        Cell: ({ cell, row }) => {
          return <ImagesRow row={row} />;
        },
      },
      {
        header: "Tanggal Jeprin",
        accessorKey: "date",
        Cell: ({ cell, row }) => (
          <div>
            <p>{moment(cell.getValue()).format("LL")}</p>
            <p>{moment(row.original?.createdTime).format("H:mm")}</p>
          </div>
        ),
      },
      {
        header: "Dibuat Oleh",
        accessorKey: "createdBy",
        Cell: ({ cell, row }) => <p>{capitalize(cell.getValue())}</p>,
      },
      {
        header: "Komentar",
        accessorKey: "remark",
        Cell: ({ cell, row }) => (
          <RemarkRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Tanggal Tindakan",
        accessorKey: "caDueDate",
        Cell: ({ cell, row }) => (
          <DueDateRow row={row} refreshHandler={refreshHandler} />
        ),
      },
      {
        header: "Blok",
        accessorKey: "blockName",
      },
      {
        header: "Jenis Material",
        accessorKey: "materialNames",
        Cell: ({ cell, row }) => <p>{JSON.parse(cell.getValue())?.[0]}</p>,
      },
      {
        header: "Ha Terawat",
        accessorKey: "realizationArea",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Dosis(Ltr/Ha)",
        accessorKey: "unitValues",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {JSON.parse(cell.getValue())?.[0] === undefined
              ? null
              : formatNumericValue(
                  parseFloat(JSON.parse(cell.getValue())?.[0]),
                  {
                    decimals: 2,
                  }
                )}
          </p>
        ),
      },
      {
        header: "Chemist Digunakan(Ltr)",
        accessorKey: "receivedChemicalSum",
        muiTableBodyCellProps: {
          align: "right",
        },
        size: 250,
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 2,
            })}
          </p>
        ),
      },
      // {
      //   header: "Sisa Chemist(Ltr)",
      //   accessorKey: "restChemical",
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell, row }) => (
      //     <p>
      //       {isUndefined(cell.getValue())
      //         ? ""
      //         : formatNumericValue(cell.getValue(), {
      //             decimals: 0,
      //           })}
      //     </p>
      //   ),
      // },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  const generateMessage = (data) => {
    let message;

    let fieldNames = {
      blockName: "Blok",
      materialNames: "Chemist",
      realizationArea: "Ha Terawat",
      unitValues: "Dosis(Ltr/Ha)",
      receivedChemicalSum: "Chemist Diterima(Ltr)",
      restChemical: "Sisa Chemist(Ltr)",
      units: "Satuan Dosis",
    };

    let items = [
      { blockName: data?.blockName },
      { materialNames: data?.materialNames },
      { realizationArea: data?.realizationArea },
      { unitValues: data?.unitValues },
      { receivedChemicalSum: data?.receivedChemicalSum },
      { restChemical: data?.restChemical },
      { units: data?.units },
    ];

    if (data?.logisticFlow === "MASUK") {
      fieldNames = { ...fieldNames, ffbSourceParty: "Diterima Dari" };
      items = [...items, { ffbSourceParty: data?.ffbSourceParty }];
    } else if (data?.logisticFlow === "KELUAR") {
      fieldNames = { ...fieldNames, ffbDestinationParty: "PKS" };
      items = [...items, { ffbDestinationParty: data?.ffbDestinationParty }];
    }

    message = checkValues(items, fieldNames);

    return message;
  };

  return (
    <div
      ref={ref}
      className="vird relative mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <ColumnSettings
        ref={tableInstanceRef}
        columns={columns}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        isDrawer={isDrawer}
        setIsDrawer={setIsDrawer}
        sortedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
      />
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        enableColumnOrdering
        enablePinning
        enableColumnResizing
        enableDensityToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableRowVirtualization
        initialState={{ density: "compact" }}
        enableTableFooter={false}
        enableBottomToolbar={false}
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{
          sx: {
            borderRadius: "24px",
            boxShadow: "0",
            fontFamily: "Inter-Regular",
          },
        }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          const message = generateMessage(row.original);

          return {
            sx: {
              backgroundColor:
                message.message.toLowerCase() == "sudah lengkap"
                  ? "#FFFFFF"
                  : "#F9F3E4",
              borderBottom: "1px solid #ECEDEF",
              fontFamily: "Inter-Regular",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: "#fafbfd",
            padding: "8px",
            fontFamily: "Inter-Regular",
            color: "#646769",
          },
        }}
        muiTableHeadCellProps={{
          sx: { borderBottom: 0 },
        }}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        columnResizeMode="onEnd"
        onColumnSizingChange={(updater) => {
          setColumnSizing((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnPinningChange={(updater) => {
          setColumnPinning((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnOrderChange={(updater) => {
          setColumnOrder((prev) => {
            const newOrder =
              updater instanceof Function ? updater(prev) : updater;
            // setSortedColumns(
            //   newOrder?.map((item) =>
            //     tableInstanceRef.current
            //       ?.getAllColumns()
            //       .find((column) => column.id === item)
            //   )
            // );
            return updater instanceof Function ? updater(prev) : updater;
          });
        }}
        onColumnVisibilityChange={(updater) => {
          setColumnVisibility((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
      />
    </div>
  );
};
export default ListData;
