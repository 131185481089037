import axiosInstance from "../api";
import { GENERIC_RECORDS_URL } from "../../configs/api";

export const getGenericRecordsApi = (params) =>
  axiosInstance.get(GENERIC_RECORDS_URL, {
    params,
  });

export const getGenericRecordApi = (id, params) =>
  axiosInstance.get(`${GENERIC_RECORDS_URL}/${id}`, {
    params,
  });

export const getGenericRecordAttachmentsApi = (id, params) =>
  axiosInstance.get(`${GENERIC_RECORDS_URL}/${id}/attachments`, {
    params,
  });

export const createGenericRecordApi = (body) =>
  axiosInstance.post(GENERIC_RECORDS_URL, body);

export const updateGenericRecordApi = (id, body) =>
  axiosInstance.put(`${GENERIC_RECORDS_URL}/${id}`, body);

export const deleteGenericRecordApi = (id) =>
  axiosInstance.delete(`${GENERIC_RECORDS_URL}/${id}`, body);
