export const damarlima70 = {
  type: "FeatureCollection",
  name: "Damarlima70Kepemilikan_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sairi",
        No_persil: 42.0,
        Luas_SKT: 1.0,
        No_SKT: "590/30/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Mundari Fatmawati",
        Luas_SHM: 0.82,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Samsul Muarif",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879791608619811, -3.872895299872255],
              [114.878159741437145, -3.87207063710164],
              [114.878893555420902, -3.872072480533865],
              [114.879793651508209, -3.872074741221834],
              [114.879791608619811, -3.872895299872255],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sukirman",
        No_persil: 4.0,
        Luas_SKT: 1.0,
        No_SKT: "590/45/TK/DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Sukirman",
        Luas_SHM: 1.43,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Sukirman",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883411023670433, -3.864851221149289],
              [114.880711725344085, -3.864844465033224],
              [114.879811636348833, -3.864842209536253],
              [114.879812808718498, -3.86437028498924],
              [114.879813025127959, -3.864283165389101],
              [114.880800448958453, -3.864350332624894],
              [114.883411023670433, -3.864851221149289],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "H. Kursani",
        No_persil: 1.0,
        Luas_SKT: 1.0,
        No_SKT: "590/46/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "H. Kursani",
        Luas_SHM: 0.78,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Hasnah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878011459946606, -3.864837695674338],
              [114.877111372540085, -3.864835437309399],
              [114.877113203012684, -3.864099512139938],
              [114.878013143064791, -3.864160731328285],
              [114.878011459946606, -3.864837695674338],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP",
        No_persil: 2.0,
        Luas_SKT: 1.0,
        No_SKT: "590/47/TK-DLS/III-08",
        Nama_Asal: "Budi Cahyono",
        Nama_SHM: "Budi Cahyono",
        Luas_SHM: 0.72,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Budi Cahyono",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878911547882936, -3.86483995308329],
              [114.878011459946606, -3.864837695674338],
              [114.878013143064791, -3.864160731328285],
              [114.878913083772716, -3.864221947974425],
              [114.878911547882936, -3.86483995308329],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sri Kiswati",
        No_persil: 3.0,
        Luas_SKT: 1.0,
        No_SKT: "590/48/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Aditiya Arief",
        Luas_SHM: 0.65,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Samsul Muarif",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879811636348833, -3.864842209536253],
              [114.878911547882936, -3.86483995308329],
              [114.878913083772716, -3.864221947974425],
              [114.879813025127959, -3.864283165389101],
              [114.879812808718498, -3.86437028498924],
              [114.879811636348833, -3.864842209536253],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wawan Halwani",
        No_persil: 8.0,
        Luas_SKT: 2.0,
        No_SKT: "590/124/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Suhendra I",
        Luas_SHM: 2.0,
        No_SHM: "339",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Wawan Halwani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880707234092029, -3.866652599301164],
              [114.879807143192778, -3.866650342745895],
              [114.879811636348833, -3.864842209536253],
              [114.880711725344085, -3.864844465033224],
              [114.880707234092029, -3.866652599301164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Noer Komari",
        No_persil: 9.0,
        Luas_SKT: 2.0,
        No_SKT: "590/125/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noer Komari",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881607325520463, -3.866654854899955],
              [114.880707234092029, -3.86665259930113],
              [114.880711725344085, -3.864844465033224],
              [114.881611814873978, -3.864846717367005],
              [114.881607325520463, -3.866654854899955],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Noer Komari",
        No_persil: 10.0,
        Luas_SKT: 2.0,
        No_SKT: "590/126/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noer Komari",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Cornelius Masianus",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882507417917324, -3.866657109543435],
              [114.881607325520463, -3.866654854899955],
              [114.881611814873978, -3.864846717367005],
              [114.88251190541213, -3.864848971726711],
              [114.882507417917324, -3.866657109543435],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP",
        No_persil: 20.0,
        Luas_SKT: 1.0,
        No_SKT: "590/44/TK-DLS/III-08",
        Nama_Asal: "Mansyur (Amin)",
        Nama_SHM: "Mansyur",
        Luas_SHM: 1.38,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Amin Nur Rohman",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883407510511972, -3.866659363229639],
              [114.883893922162926, -3.866660580727058],
              [114.883396019668396, -3.871287544977124],
              [114.883407510511972, -3.866659363229639],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sri Rubianik",
        No_persil: 12.0,
        Luas_SKT: 1.0,
        No_SKT: "590/49/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Sri Rubianti",
        Luas_SHM: 1.44,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Sri Rubianik",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883893922162926, -3.866660580727058],
              [114.883407510511972, -3.866659363229639],
              [114.883411995539575, -3.864851407620708],
              [114.884096123206433, -3.864982670143328],
              [114.884300054457796, -3.865021782201019],
              [114.883921836742729, -3.866401162854454],
              [114.883893922162926, -3.866660580727058],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Radiansono",
        No_persil: 11.0,
        Luas_SKT: 2.0,
        No_SKT: "590/106/TE/DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Zulkifli",
        Luas_SHM: 2.0,
        No_SHM: "322",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883407510511972, -3.866659363229639],
              [114.882507417917324, -3.866657109543435],
              [114.88251190541213, -3.864848971726711],
              [114.883411023670433, -3.864851221149289],
              [114.883411995539575, -3.864851407620708],
              [114.883407510511972, -3.866659363229639],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurul Ulfah",
        No_persil: 5.0,
        Luas_SKT: 2.0,
        No_SKT: "590/121/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Nurul Ulfah",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Hilmi Hasan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878006962982525, -3.866645826766008],
              [114.87710687367202, -3.866643567341429],
              [114.877111372540085, -3.864835437309399],
              [114.878011459946606, -3.864837695674338],
              [114.878006962982525, -3.866645826766008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurul Ulfah",
        No_persil: 6.0,
        Luas_SKT: 2.0,
        No_SKT: "590/122/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "M. Albach R",
        Luas_SHM: 2.0,
        No_SHM: "440",
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Ahmad Yasin, H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878907052822868, -3.866648085234154],
              [114.878006962982525, -3.866645826766008],
              [114.878011459946606, -3.864837695674338],
              [114.878911547882936, -3.86483995308329],
              [114.878907052822868, -3.866648085234154],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wawan Halwani",
        No_persil: 7.0,
        Luas_SKT: 2.0,
        No_SKT: "590/123/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noor Aidil",
        Luas_SHM: 2.0,
        No_SHM: "337",
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Wawan Helwani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879807143192778, -3.866650342745862],
              [114.878907052822868, -3.866648085234154],
              [114.878911547882936, -3.86483995308329],
              [114.879811636348833, -3.864842209536253],
              [114.879807143192778, -3.866650342745862],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Jamhari",
        No_persil: 47.0,
        Luas_SKT: 1.0,
        No_SKT: "590/25/TE-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Jamhari",
        Luas_SHM: 0.66,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Suradi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880691498041855, -3.87298106859047],
              [114.881591596141959, -3.872983327664699],
              [114.881589554749524, -3.873803886671075],
              [114.880690580887759, -3.873349593411803],
              [114.880691498041855, -3.87298106859047],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP",
        No_persil: 48.0,
        Luas_SKT: 1.0,
        No_SKT: "590/24/TE-DLS/III-08",
        Nama_Asal: "Jumayah Susanti",
        Nama_SHM: "Jumayah S",
        Luas_SHM: 1.13,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Jumayah Susanti",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881591596141959, -3.872983327664699],
              [114.882491695045246, -3.872985585781617],
              [114.882488754829481, -3.874167870311328],
              [114.88190482526926, -3.873963206971525],
              [114.881589554749524, -3.873803886671075],
              [114.881591596141959, -3.872983327664699],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "H. Suyadi",
        No_persil: 49.0,
        Luas_SKT: 1.0,
        No_SKT: "590/23/TE-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "H. Suyadi",
        Luas_SHM: 1.03,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Sishadi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882491695045246, -3.872985585781617],
              [114.883213096040222, -3.872987394898824],
              [114.883064965037903, -3.874363910429298],
              [114.882488754829481, -3.874167870311328],
              [114.882491695045246, -3.872985585781617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rochmanudin",
        No_persil: 44.0,
        Luas_SKT: 1.0,
        No_SKT: "590/28/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Rochmanudin",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Nor Komari",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880693747637636, -3.872077001139396],
              [114.881593844783964, -3.872079259723189],
              [114.881591596141959, -3.872983327664699],
              [114.880691498041855, -3.87298106859047],
              [114.880693747637636, -3.872077001139396],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nawawi",
        No_persil: 45.0,
        Luas_SKT: 1.0,
        No_SKT: "590/27/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Nawawi",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Hilmi Hasan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881593844783964, -3.872079259723189],
              [114.882493942756625, -3.87208151753854],
              [114.882491695045246, -3.872985585781617],
              [114.881591596141959, -3.872983327664699],
              [114.881593844783964, -3.872079259723189],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hilmi Hasan",
        No_persil: 46.0,
        Luas_SKT: 1.0,
        No_SKT: "590/26/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "M Albach R",
        Luas_SHM: 0.85,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Hilmi Hasan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883310358904438, -3.87208356462792],
              [114.883213096040222, -3.872987394898824],
              [114.882491695045246, -3.872985585781617],
              [114.882493942756625, -3.87208151753854],
              [114.883310358904438, -3.87208356462792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Runie",
        No_persil: 35.0,
        Luas_SKT: 1.0,
        No_SKT: "590/37/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Runie",
        Luas_SHM: 0.66,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Runie",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877095617470431, -3.871163891731888],
              [114.877995711302262, -3.871166153474923],
              [114.877993667407367, -3.871986711030205],
              [114.877094698948994, -3.871532416036151],
              [114.877095617470431, -3.871163891731888],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Mamat",
        No_persil: 36.0,
        Luas_SKT: 1.0,
        No_SKT: "590/36/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Mamat",
        Luas_SHM: 0.99,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Mamat",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877995711302262, -3.871166153474923],
              [114.87889580590651, -3.871168414591071],
              [114.878893554932475, -3.872072481192786],
              [114.878159741331288, -3.872070637431432],
              [114.877993667407367, -3.871986711030205],
              [114.877995711302262, -3.871166153474923],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rusmadi",
        No_persil: 37.0,
        Luas_SKT: 1.0,
        No_SKT: "590/35/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Rusmadi",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Nurul Ulpah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87889580590651, -3.871168414591071],
              [114.879795901040367, -3.871170674749662],
              [114.879793651019767, -3.872074741880755],
              [114.878893554932475, -3.872072481192786],
              [114.87889580590651, -3.871168414591071],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Evansyah H",
        No_persil: 39.0,
        Luas_SKT: 1.0,
        No_SKT: "590/33/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Evansyah",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Anisa K. K",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880695996703153, -3.871172934124372],
              [114.881596092895961, -3.871175192194101],
              [114.881593844783964, -3.872079259723189],
              [114.880693747637636, -3.872077001139396],
              [114.880695996703153, -3.871172934124372],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suradi",
        No_persil: 40.0,
        Luas_SKT: 1.0,
        No_SKT: "590/32/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Suradi",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Suradi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881596092895961, -3.871175192194101],
              [114.882496189938777, -3.871177449480804],
              [114.882493942756625, -3.87208151753854],
              [114.881593844783964, -3.872079259723189],
              [114.881596092895961, -3.871175192194101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Muslih",
        No_persil: 41.0,
        Luas_SKT: 1.0,
        No_SKT: "590/31/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Muslih",
        Luas_SHM: 0.96,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Muslih",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882496189938777, -3.871177449480804],
              [114.883396287461551, -3.871179705809817],
              [114.883396019668396, -3.871287544977124],
              [114.883310358904438, -3.87208356462792],
              [114.882493942756625, -3.87208151753854],
              [114.882496189938777, -3.871177449480804],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Noor Aida",
        No_persil: 31.0,
        Luas_SKT: 1.0,
        No_SKT: "590/41/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Noor Aida",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Garin Indrarinaldy",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.88069824523933, -3.870268867149991],
              [114.880695996703025, -3.871172934170715],
              [114.879795901040367, -3.871170674749662],
              [114.879798150529894, -3.870266608258074],
              [114.88069824523933, -3.870268867149991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yuliansyah",
        No_persil: 28.0,
        Luas_SKT: 1.0,
        No_SKT: "590/43/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Yuliansyah",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Tjindar Bumi. K. N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877097869819679, -3.870259826829107],
              [114.877997962698274, -3.870262088042318],
              [114.877995711302262, -3.871166153474923],
              [114.877095617470431, -3.871163891731888],
              [114.877097869819679, -3.870259826829107],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suriansyah",
        No_persil: 29.0,
        Luas_SKT: 1.0,
        No_SKT: "590/50/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Suriansyah",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Sapiah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877997962698274, -3.870262088042318],
              [114.8788980563493, -3.870264348628863],
              [114.87889580590651, -3.871168414591071],
              [114.877995711302262, -3.871166153474923],
              [114.877997962698274, -3.870262088042318],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ahmad Wahyudi",
        No_persil: 30.0,
        Luas_SKT: 1.0,
        No_SKT: "590/42/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Rudi Ismanto",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Rudi Ismanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8788980563493, -3.870264348628863],
              [114.879798150529894, -3.870266608258074],
              [114.879795901040367, -3.871170674749662],
              [114.87889580590651, -3.871168414591071],
              [114.8788980563493, -3.870264348628863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rudi Ismanto",
        No_persil: 32.0,
        Luas_SKT: 1.0,
        No_SKT: "590/40/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Rudi Ismanto",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Rudi Ismanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880698245239458, -3.870268867103631],
              [114.88159834047903, -3.870271124644432],
              [114.881596092895961, -3.871175192194101],
              [114.880695996703153, -3.871172934124372],
              [114.880698245239458, -3.870268867103631],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Abbas",
        No_persil: 33.0,
        Luas_SKT: 1.0,
        No_SKT: "590/39/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Abbas",
        Luas_SHM: 1.0,
        No_SHM: "338",
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Anisa. K. K",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.88159834047903, -3.870271124644432],
              [114.882498436592229, -3.870273381402488],
              [114.882496189938777, -3.871177449480804],
              [114.881596092895961, -3.871175192194101],
              [114.88159834047903, -3.870271124644432],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wandi Purnomo",
        No_persil: 34.0,
        Luas_SKT: 1.0,
        No_SKT: "590/38/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Wandi Purnomo",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Wandi Purnomo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882498436592229, -3.870273381402488],
              [114.883398533079827, -3.870275637202811],
              [114.883396287461551, -3.871179705809817],
              [114.882496189938777, -3.871177449480804],
              [114.882498436592229, -3.870273381402488],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suprapnoto",
        No_persil: 16.0,
        Luas_SKT: 2.0,
        No_SKT: "590/117/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Suprapnoto",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Dedi Martadinata",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880702740724345, -3.868460733109548],
              [114.879802647919959, -3.868458475590296],
              [114.879807143192778, -3.866650342745862],
              [114.880707234092029, -3.86665259930113],
              [114.880702740724345, -3.868460733109548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Saut Selamat T.",
        No_persil: 24.0,
        Luas_SKT: 2.0,
        No_SKT: "590/107/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Saut Selamat T",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Dedi Martadinata",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879802647919959, -3.868458475590296],
              [114.880702740724345, -3.868460733109548],
              [114.88069824523933, -3.870268867149991],
              [114.879798150529894, -3.870266608258074],
              [114.879802647919959, -3.868458475590296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Agus Fatoni",
        No_persil: 21.0,
        Luas_SKT: 2.0,
        No_SKT: "590/113/TE-DLS-V/08",
        Nama_Asal: null,
        Nama_SHM: "Agus Fatoni",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Agus Fatoni",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877102372683837, -3.868451697291174],
              [114.878002463899463, -3.868453957681099],
              [114.877997962698274, -3.870262088042318],
              [114.877097869819679, -3.870259826829107],
              [114.877102372683837, -3.868451697291174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Indarto",
        No_persil: 13.0,
        Luas_SKT: 2.0,
        No_SKT: "590/114/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noor Aidil",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Indarto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878002463899463, -3.868453957681099],
              [114.877102372683837, -3.868451697291174],
              [114.87710687367202, -3.866643567341429],
              [114.878006962982525, -3.866645826766008],
              [114.878002463899463, -3.868453957681099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rosida Hutagaol",
        No_persil: 22.0,
        Luas_SKT: 2.0,
        No_SKT: "590/109/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Zulkifli",
        Luas_SHM: 2.0,
        No_SHM: "328",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878002463899463, -3.868453957681099],
              [114.878902555644899, -3.868456217114141],
              [114.8788980563493, -3.870264348628863],
              [114.877997962698274, -3.870262088042318],
              [114.878002463899463, -3.868453957681099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Indarto",
        No_persil: 14.0,
        Luas_SKT: 2.0,
        No_SKT: "590/115/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Suhendra I",
        Luas_SHM: 2.0,
        No_SHM: "331",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878902555644899, -3.868456217114141],
              [114.878002463899463, -3.868453957681099],
              [114.878006962982525, -3.866645826766008],
              [114.878907052822868, -3.866648085234154],
              [114.878902555644899, -3.868456217114141],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rosida Hutagaol",
        No_persil: 23.0,
        Luas_SKT: 2.0,
        No_SKT: "590/110/TE/DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noor Aidil",
        Luas_SHM: 2.0,
        No_SHM: "330",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878902555644899, -3.868456217114124],
              [114.879802647919959, -3.868458475590296],
              [114.879798150529894, -3.870266608258074],
              [114.8788980563493, -3.870264348628863],
              [114.878902555644899, -3.868456217114124],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suprapnoto",
        No_persil: 15.0,
        Luas_SKT: 2.0,
        No_SKT: "590/116/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Suprapnoto",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Supratnoto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879802647919959, -3.868458475590296],
              [114.878902555644899, -3.868456217114124],
              [114.878907052822868, -3.866648085234154],
              [114.879807143192778, -3.866650342745862],
              [114.879802647919959, -3.868458475590296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Tatang Yulianto",
        No_persil: 17.0,
        Luas_SKT: 2.0,
        No_SKT: "590/118/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Tatang Yulianto",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Tatang Julianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881602834057944, -3.868462989671939],
              [114.880702740724345, -3.868460733109548],
              [114.880707234092029, -3.86665259930113],
              [114.881607325520463, -3.866654854899955],
              [114.881602834057944, -3.868462989671939],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Saut Selamat T.",
        No_persil: 25.0,
        Luas_SKT: 2.0,
        No_SKT: "590/108/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Suhendra I",
        Luas_SHM: 2.0,
        No_SHM: "435",
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Dedi Martadinata",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880702740724345, -3.868460733109548],
              [114.881602834057944, -3.868462989671939],
              [114.88159834047903, -3.870271124644432],
              [114.880698245239458, -3.870268867103631],
              [114.880702740724345, -3.868460733109548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Tatang Yulianto",
        No_persil: 18.0,
        Luas_SKT: 2.0,
        No_SKT: "590/119/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Tatang Yulianto",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Tatang Julianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882502928312775, -3.868465245278389],
              [114.881602834057944, -3.86846298967189],
              [114.881607325520463, -3.866654854899955],
              [114.882507417917324, -3.866657109543435],
              [114.882502928312775, -3.868465245278389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Elsa Marcelia T",
        No_persil: 26.0,
        Luas_SKT: 2.0,
        No_SKT: "590/111/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Zulkifli",
        Luas_SHM: 2.0,
        No_SHM: "323",
        Lokasi: "Damarlima 70 Pak  Lujahid",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881602834057944, -3.86846298967189],
              [114.882502928312775, -3.868465245278389],
              [114.882498436592229, -3.870273381402488],
              [114.88159834047903, -3.870271124644432],
              [114.881602834057944, -3.86846298967189],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Agus Setiawan",
        No_persil: 19.0,
        Luas_SKT: 2.0,
        No_SKT: "590/120/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "M. Albach R",
        Luas_SHM: 2.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Agus Setiawan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883403022826073, -3.868467499927295],
              [114.882502928312775, -3.868465245278389],
              [114.882507417917324, -3.866657109543435],
              [114.883407510511972, -3.866659363229639],
              [114.883403022826073, -3.868467499927295],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Elsa Marcelia T",
        No_persil: 27.0,
        Luas_SKT: 2.0,
        No_SKT: "590/112/TE-DLS/V-08",
        Nama_Asal: null,
        Nama_SHM: "Noor Aidil",
        Luas_SHM: 2.0,
        No_SHM: "325",
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882502928312775, -3.868465245278389],
              [114.883403022826073, -3.868467499927295],
              [114.883398533079827, -3.870275637202811],
              [114.882498436592229, -3.870273381402488],
              [114.882502928312775, -3.868465245278389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sahri",
        No_persil: 38.0,
        Luas_SKT: 1.0,
        No_SKT: "590/34/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Sahri",
        Luas_SHM: 1.0,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Aly Fadholi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880693747637054, -3.872077001375168],
              [114.879793651264009, -3.872074741551259],
              [114.879795901040367, -3.871170674749662],
              [114.880695996703025, -3.871172934170715],
              [114.880693747637054, -3.872077001375168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Januri",
        No_persil: 43.0,
        Luas_SKT: 1.0,
        No_SKT: "590/29/TK-DLS/III-08",
        Nama_Asal: null,
        Nama_SHM: "Januri",
        Luas_SHM: 1.16,
        No_SHM: null,
        Lokasi: "Damar Lima 70",
        Nama_B_01: "Januri",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880693747637054, -3.872077001375168],
              [114.880691498041855, -3.87298106859047],
              [114.880690580887759, -3.873349593411803],
              [114.879791608376792, -3.872895299718388],
              [114.879793651264009, -3.872074741551259],
              [114.880693747637054, -3.872077001375168],
            ],
          ],
        ],
      },
    },
  ],
};
