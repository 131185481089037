import { useForm } from "react-hook-form";
import { processTextChange } from "../../../hooks/commentUtils";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ColorPicker, Tooltip } from "antd";
import InputHook from "../../../components/atoms/InputHook";
import { TbTrash } from "react-icons/tb";
import { hexToRgba, rgbaToHex } from "../helpers/colorHelpers";

const messageConfig = {
  remove: "Berhasil menghapus nama lokasi",
  success: "Berhasil mengubah nama lokasi",
};

const NameRow = ({ row, updateData, deleteData }) => {
  const { control, setValue, watch } = useForm();

  const watchRemark = watch(`name-${row?.id}`);
  const [fillColor, setFillColor] = useState(
    hexToRgba(row?.fillColor, row?.opacity)
  );
  const [outlineColor, setOutlineColor] = useState(
    hexToRgba(row?.outlineColor, 1)
  );

  const handleBlurOnRemarkChange = async () => {
    const textParsed = processTextChange({
      value: watchRemark,
      message: messageConfig,
    });

    updateData({
      id: row?.id,
      name: textParsed.value,
    });
  };

  const handleOnKeyDownOnRemarkChange = async (event) => {
    if (event.key === "Enter") {
      await handleBlurOnRemarkChange();
    }
  };

  const request = debounce(async (searchTerm) => {
    const textParsed = processTextChange({
      value: searchTerm,
      message: messageConfig,
    });

    updateData({
      id: row?.id,
      name: textParsed.value,
    });
  }, 250);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [row?.id]
  );

  const setColorHandler = useCallback(
    (colorRgb, setColor, updateColorData) => {
      const newColor = (colorRgb) => {
        if (typeof colorRgb === "string") {
          return colorRgb;
        } else {
          const { r, g, b, a } = colorRgb?.toRgb();
          return `rgba(${r}, ${g}, ${b}, ${a})`;
        }
      };
      setColor(newColor);

      const converted = rgbaToHex(newColor(colorRgb));

      updateColorData(converted.hex, converted.alpha);
    },
    [fillColor, outlineColor]
  );

  const renderColorPicker = ({
    color,
    defaultColor,
    setColor,
    updateColorData,
    label,
  }) => {
    return (
      <Tooltip title={label}>
        <ColorPicker
          value={color}
          defaultValue={defaultColor}
          format={"rgb"}
          onChange={(colorRgb) =>
            setColorHandler(colorRgb, setColor, updateColorData)
          }
        />
      </Tooltip>
    );
  };

  const updateRowData = ({ id, key, value, alpha }) => {
    const data = {
      id: id,
      [key]: value,
    };

    if (alpha !== undefined) {
      data.opacity = alpha;
    }

    updateData(data);
  };

  useEffect(() => {
    setValue(`name-${row?.id}`, row?.name);
    const initialFillColor = hexToRgba(row?.fillColor, row?.opacity);
    const initialOutlineColor = hexToRgba(row?.outlineColor, 1);

    setFillColor(initialFillColor);
    setOutlineColor(initialOutlineColor);
  }, [row?.id]);

  return (
    <div className={"grid grid-cols-[1fr,max-content] items-center gap-24"}>
      <InputHook
        control={control}
        name={`name-${row?.id}`}
        placeholder="Isikan dengan nama lokasi. default: nama file"
        inputClassName="input-mini-border pl-8 pr-24 w-full"
        afterOnChange={debounceTextRequest}
        onBlur={handleBlurOnRemarkChange}
        onKeyDown={handleOnKeyDownOnRemarkChange}
      />
      <div className={"flex h-full flex-row items-center gap-16"}>
        {row.geometryType !== "LineString" &&
          renderColorPicker({
            label: "Warna isi",
            color: fillColor,
            defaultColor: "rgba(59, 147, 48, 1)",
            setColor: setFillColor,
            updateColorData: (hex, alpha) => {
              updateRowData({
                id: row?.id,
                key: "fillColor",
                value: hex,
                alpha,
              });
            },
          })}

        {renderColorPicker({
          label: "Warna garis",
          color: outlineColor,
          defaultColor: "rgba(59, 147, 48, 1)",
          setColor: setOutlineColor,
          updateColorData: (hex) => {
            updateRowData({ id: row?.id, key: "outlineColor", value: hex });
          },
        })}
        <Tooltip title={`Hapus ${watchRemark}`}>
          <div
            className={
              "cursor-pointer rounded-8 p-8 hover:border hover:border-1 hover:border-gray-100"
            }
            onClick={() => deleteData(row?.id)}
          >
            <TbTrash fontSize={"24px"} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default NameRow;
