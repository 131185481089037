import React, { useEffect, useReducer, useState } from "react";
import { getUser, selectUser } from "../../features/userSlice";
import { positions, transitions, useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { Alert } from "antd";
import CommonProfile from "../../components/templates/Profile/CommonProfile";
import EmailStatus from "../../components/templates/Profile/EmailStatus";
import GroupProfile from "../../components/templates/Profile/GroupProfile";
import Navbar from "../../components/atoms/Navbar";
import ProfilePicture from "../../components/templates/Profile/ProfilePicture/ProfilePicture";
import ResetPassword from "../../components/templates/Profile/ResetPassword";
import { TbSettings } from "react-icons/tb";
import { downloadImage } from "../../features/fileAttachment/fileAttachmentSlice";
import { getCompany } from "../../features/companySlice";
import { useUserStore } from "../MasterWorkerPage/hooks/userStore";
import { useAuthStore } from "../../hooks/authStore";
import { useCompanyStore } from "../../hooks/companyStore";
import { useFileAttachmentStore } from "../../hooks/fileAttachmentStore";
import { shallow } from "zustand/shallow";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [source, setSource] = useState(null);
  const [companySource, setCompanySource] = useState(null);
  const user = useSelector(selectUser);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoadingProfile: false, isLoadingLogo: false }
  );

  const handleDownloadImage = async (photoFileId, type) => {
    try {
      if (!photoFileId) {
        return;
      }
      if (type === "company") setRequest({ isLoadingLogo: true });
      if (type === "user") setRequest({ isLoadingProfile: true });
      const response = await dispatch(
        downloadImage({ id: photoFileId })
      ).unwrap();
      response.onloadend = function () {
        if (type === "company") {
          setRequest({ isLoadingLogo: false });
          setCompanySource(response.result);
        }
        if (type === "user") {
          setRequest({ isLoadingProfile: false });
          setSource(response.result);
        }
      };
    } catch (error) {
      return alert.error(`Failed to get image ${photoFileId}`, {
        position: positions.BOTTOM_RIGHT,
        transition: transitions.SCALE,
      });
    }
  };
  const handleGetCompany = async (companyId) => {
    try {
      const response = await dispatch(getCompany({ id: companyId })).unwrap();
      if (!response?.success) {
        return;
        // alert.error(response?.message);
      }
      handleDownloadImage(response?.record?.photoFileId, "company");
    } catch (error) {
      return;
      // alert.error(`${error?.data}`);
    }
  };
  const handleGetUser = async () => {
    try {
      const remainingUser = JSON.parse(localStorage.getItem("user"));
      const remainingToken = JSON.parse(localStorage.getItem("token"));
      const sub = remainingUser.sub;
      const token = remainingToken.accessToken;

      const response = await dispatch(
        getUser({ id: sub, token: token })
      ).unwrap();
      if (!response.success) {
        return alert.error(response.message);
      }
      const record = response.record;
      const companyId = record.companyId;
      localStorage.setItem("user:api", JSON.stringify(record));

      handleDownloadImage(record?.photoFileId, "user");
      handleGetCompany(companyId);
    } catch (error) {
      return alert.error(error?.data);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetUser();
    }
    return () => (isMounted = false);
  }, []);

  // Zustand state management
  const getUserById = useUserStore((state) => state.getUserById);
  const tokensParsed = useAuthStore((state) => state.tokensParsed);
  const getCompanyById = useCompanyStore((state) => state.getCompanyById);
  const [getImage, setPhoto] = useFileAttachmentStore(
    (state) => [state.getImage, state.setPhoto],
    shallow
  );

  const setDownloadedImage = async (id, type, setStateFunc) => {
    const response = await getImage(id);
    response.onloadend = () => {
      setStateFunc({ [type]: response.result });
    };
  };

  const initialGet = async () => {
    if (user?.record?.photoFileId) {
      const user = await getUserById(tokensParsed?.tokenParsed?.sub);
      await setDownloadedImage(
        user?.record?.photoFileId,
        "photoProfile",
        setPhoto
      );

      if (user?.record?.companyId) {
        const company = await getCompanyById(user?.record?.companyId);
        await setDownloadedImage(
          company?.record?.photoFileId,
          "logoCompany",
          setPhoto
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = initialGet();
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-gray-30">
      <Navbar
        arrowBack
        leftContentTitle={
          <div className="flex flex-row items-center">
            <TbSettings className="mr-16 hidden text-24 text-gray-500 md:inline" />
            <p className="line-clamp-1">Pengaturan</p>
          </div>
        }
      />
      <div className="relative grid grid-cols-1 gap-24 px-16 pt-64 pb-24 md:py-24 md:px-0 ">
        <ProfilePicture
          refreshData={handleGetUser}
          source={source}
          imageLoading={request}
          setSource={setSource}
          companySource={companySource}
          setCompanySource={setCompanySource}
        />
        <CommonProfile refreshData={handleGetUser} />
        <GroupProfile refreshData={handleGetUser} />
        <EmailStatus refreshData={handleGetUser} />
        {!user?.isEmailVerified && (
          <Alert
            message="Untuk memastikan keamanan akun Anda, kami memerlukan alamat email Anda yang sudah terverifikasi sebelum mereset password."
            type="warning"
            showIcon
            className="font-regular text-16 md:mx-32"
          />
        )}
        <ResetPassword refreshData={handleGetUser} />
      </div>
    </div>
  );
};

export default ProfilePage;
