import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getDailyInMonth,
  getHarvestingTargetAggregates,
  getProductionRecapsDaily,
} from "../../../../features/harvestingSlice";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useUserStore } from "../../../MasterWorkerPage/hooks/userStore";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  // const user = useSelector(selectUser);
  const user = useUserStore((state) => state.user);
  const [isFilterBy, setIsFilterBy] = useState({
    company: user?.companyId,
    estate: undefined,
    estateName: undefined,
    afdeling: undefined,
    block: undefined,
    afdelingName: undefined,
    month: undefined,
    plantedYear: undefined,
    year: undefined,
    date: moment().format("Y-MM-DD"),
    startDate: moment().format("Y-MM-DD"),
    endDate: moment().format("Y-MM-DD"),
  });
  const [data, setData] = useState([]);
  const [dataPreviousDate, setDataPreviousDate] = useState([]);
  const [comparisonChartData, setComparisonChartData] = useState([]);
  const [averageFruits, setAverageFruits] = useState([]);
  const dispatch = useDispatch();
  const alert = useAlert();

  const [target, setTarget] = useState({});

  let params = {
    fltCompany: isFilterBy.company,
    fltEstate: isFilterBy.estate,
    fltAfdeling: isFilterBy.afdeling,
    fltBlock: isFilterBy.block,
    fltMonth: isFilterBy.month,
    fltStartDate: isFilterBy.startDate,
    fltEndDate: isFilterBy.endDate,
    fltPlantedYear: isFilterBy.plantedYear,
    fltYear: isFilterBy.year,
    sord: isFilterBy.sord,
  };

  const baseRedashUrl = (QUERIES, API_KEY) =>
    `https://semaipro-dev.semaigroup.com/api/queries/${QUERIES}/results?api_key=${API_KEY}`;

  const calculateDate = (harvestingDate) => {
    const today = harvestingDate || moment();
    const harvestingDateObject = new Date(today);
    const harvestingDateString = moment(harvestingDateObject).format("Y-MM-DD");
    let yesterdayDate = moment(harvestingDateString).subtract(1, "days");

    // Check if yesterday is a weekend day (Saturday or Sunday)
    while (yesterdayDate.weekday() === 0 || yesterdayDate.weekday() === 6) {
      yesterdayDate = yesterdayDate.subtract(1, "days");
    }

    const yesterdayDateString = yesterdayDate.format("Y-MM-DD");

    return { today: harvestingDateString, yesterday: yesterdayDateString };
  };

  const handleGetBjr = async () => {
    try {
      const companyId = user?.companyId;

      const API_KEY = "1tjNibegi435DBQ9haWffgYFi36js0b9QgKgQJHa";
      const QUERIES = "59";

      const response = await axios.post(
        baseRedashUrl(QUERIES, API_KEY),
        {
          max_age: -1,
          apply_auto_limit: true,
          parameters: {
            companyId: companyId
              ? companyId
              : "00000000-0000-0000-0000-000000000000",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const records = response?.data?.query_result?.data?.rows;
      setAverageFruits(records);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetProductionRecapsDaily = useCallback(
    async (type, params) => {
      setData({});
      setDataPreviousDate({});
      try {
        let newParams = params;

        const { yesterday, today } = calculateDate(params.fltStartDate);

        if (type === "scoreboardYesterday") {
          newParams = {
            ...newParams,
            fltStartDate: yesterday,
            fltEndDate: yesterday,
          };
        }

        const response = await dispatch(
          getProductionRecapsDaily(newParams)
        ).unwrap();
        if (!response.success) {
          return;
          // alert.error(response?.message);
        }
        if (type === "scoreboardToday") {
          setData(response.records[0]);
        }
        if (type === "scoreboardYesterday") {
          setDataPreviousDate(response.records[0]);
        }
      } catch (error) {
        // alert.error(error?.data);
      }
    },
    [data, dataPreviousDate]
  );

  const handleGetDailyInMonth = useCallback(
    async (date, type, id) => {
      setComparisonChartData([]);
      try {
        const { today } = calculateDate(date);
        const response = await dispatch(
          getDailyInMonth({
            month: parseInt(moment(today).format("M")),
            year: parseInt(moment(today).year()),
            type,
            id,
          })
        ).unwrap();
        if (!response.success) {
          return;
          // disabled sementara
          // alert.error(response?.message);
        }
        setComparisonChartData(response.records);
      } catch (error) {
        // alert.error(error?.data);
      }
    },
    [comparisonChartData, user]
  );

  const handleGetTargetAggregates = useCallback(
    async (type, params, isBlock) => {
      setTarget({});
      try {
        const response = await dispatch(
          getHarvestingTargetAggregates({ type, ...params, isBlock })
        ).unwrap();
        if (!response.success) {
          return;
          // alert.error(response?.message);
        }
        setTarget(response.records[0] || {});
      } catch (error) {
        return;
        // alert.error(error?.data);
      }
    },
    [target]
  );

  // help me write refactor this function below "handleOnFilter" become more readable
  const handleOnFilter = ({ type, value }) => {
    if (type === "estate") {
      const { today } = calculateDate(isFilterBy.startDate);
      const month = parseInt(moment(today).format("M"));
      const year = parseInt(moment(today).year());

      if (value !== null) {
        const { today } = calculateDate(isFilterBy.startDate);
        const month = parseInt(moment(today).format("M"));
        const year = parseInt(moment(today).year());

        setIsFilterBy({
          ...isFilterBy,
          estate: value.value,
        });
        params = {
          ...params,
          fltEstate: value.value,
        };

        handleGetTargetAggregates("by-estate", {
          ...params,
          fltMonth: month,
          fltYear: year,
        });
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-estate",
          value.value
        );
      } else {
        setIsFilterBy({
          ...isFilterBy,
          estate: undefined,
        });
        params = {
          ...params,
          fltEstate: undefined,
        };
        setTarget({});
        handleGetTargetAggregates("by-company", {
          ...params,
          fltMonth: month,
          fltYear: year,
        });
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-company",
          isFilterBy.company
        );
      }
    }
    if (type === "afdeling") {
      const { today } = calculateDate(isFilterBy.startDate);
      const month = parseInt(moment(today).format("M"));
      const year = parseInt(moment(today).year());

      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: value.value,
        });
        params = {
          ...params,
          fltAfdeling: value.value,
        };

        handleGetTargetAggregates("by-afdeling", {
          ...params,
          fltMonth: month,
          fltYear: year,
        });
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-afdeling",
          value.value
        );
      } else {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: undefined,
        });
        params = {
          ...params,
          fltAfdeling: undefined,
        };
        setTarget({});
        handleGetTargetAggregates("by-estate", {
          ...params,
          fltMonth: month,
          fltYear: year,
        });
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-estate",
          isFilterBy.estate
        );
      }
    }
    if (type === "block") {
      const { today } = calculateDate(isFilterBy.startDate);
      const month = parseInt(moment(today).format("M"));
      const year = parseInt(moment(today).year());

      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          block: value.value,
        });
        params = {
          ...params,
          fltBlock: value.value,
        };

        handleGetTargetAggregates(
          "line-items",
          {
            ...params,
            fltMonth: month,
            fltYear: year,
          },
          true
        );
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-block",
          value.value
        );
        // handleGetDailyAfdelingHarvestingInMonth(
        // 	value?.value,
        // 	moment(isFilterBy.date).format("Y-MM-DD")
        // );
      } else {
        setIsFilterBy({
          ...isFilterBy,
          block: undefined,
        });
        params = {
          ...params,
          fltBlock: undefined,
        };
        setTarget({});
        handleGetTargetAggregates("by-afdeling", {
          ...params,
          fltMonth: month,
          fltYear: year,
        });
        handleGetDailyInMonth(
          moment(isFilterBy.date).format("Y-MM-DD"),
          "by-afdeling",
          isFilterBy.afdeling
        );
      }
    }
    if (type === "plantedYear") {
      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          plantedYear: value.value,
        });
        params = {
          ...params,
          fltPlantedYear: value.value,
        };

        if (isFilterBy.block !== undefined) {
          handleGetDailyInMonth(
            moment(isFilterBy.date).format("Y-MM-DD"),
            "by-block",
            isFilterBy.block
          );
        } else if (isFilterBy.afdeling !== undefined) {
          handleGetDailyInMonth(
            moment(isFilterBy.date).format("Y-MM-DD"),
            "by-afdeling",
            isFilterBy.afdeling
          );
        } else if (
          isFilterBy.afdeling === undefined &&
          isFilterBy.estate !== undefined
        ) {
          handleGetDailyInMonth(
            moment(isFilterBy.date).format("Y-MM-DD"),
            "by-estate",
            isFilterBy.estate
          );
        } else {
          handleGetDailyInMonth(
            moment(isFilterBy.date).format("Y-MM-DD"),
            "by-company",
            isFilterBy.company
          );
        }
      } else {
        setIsFilterBy({
          ...isFilterBy,
          plantedYear: undefined,
        });
        params = {
          ...params,
          fltPlantedYear: undefined,
        };
      }
    }
    if (type === "date") {
      if (value !== null) {
        const { today } = calculateDate(value);
        const month = parseInt(moment(today).format("M"));
        const year = parseInt(moment(today).year());

        setIsFilterBy({
          ...isFilterBy,
          startDate: today,
          endDate: today,
        });
        params = {
          ...params,
          fltStartDate: today,
          fltEndDate: today,
        };

        if (isFilterBy.block !== undefined) {
          handleGetTargetAggregates(
            "line-items",
            {
              ...params,
              fltMonth: month,
              fltYear: year,
            },
            true
          );
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-block",
            isFilterBy.block
          );
        } else if (isFilterBy.afdeling !== undefined) {
          handleGetTargetAggregates("by-afdeling", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-afdeling",
            isFilterBy.afdeling
          );
        } else if (
          isFilterBy.afdeling === undefined &&
          isFilterBy.estate !== undefined
        ) {
          handleGetTargetAggregates("by-estate", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-estate",
            isFilterBy.estate
          );
        } else {
          handleGetTargetAggregates("by-company", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-company",
            isFilterBy.company
          );
        }
      } else {
        const { today } = calculateDate(moment().format("Y-MM-DD"));
        const month = parseInt(moment(today).format("M"));
        const year = parseInt(moment(today).year());

        setIsFilterBy({
          ...isFilterBy,
          startDate: today,
          endDate: today,
          month: month,
          year: year,
        });
        params = {
          ...params,
          fltStartDate: today,
          fltEndDate: today,
          fltMonth: month,
          fltYear: year,
        };
        setTarget({});

        if (isFilterBy.block !== undefined) {
          handleGetTargetAggregates(
            "line-items",
            {
              ...params,
              fltMonth: month,
              fltYear: year,
            },
            true
          );
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-block",
            isFilterBy.block
          );
        } else if (isFilterBy.afdeling !== undefined) {
          handleGetTargetAggregates("by-afdeling", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-afdeling",
            isFilterBy.afdeling
          );
        } else if (
          isFilterBy.afdeling === undefined &&
          isFilterBy.estate !== undefined
        ) {
          handleGetTargetAggregates("by-estate", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-estate",
            isFilterBy.estate
          );
        } else {
          handleGetTargetAggregates("by-company", {
            ...params,
            fltMonth: month,
            fltYear: year,
          });
          handleGetDailyInMonth(
            moment(today).format("Y-MM-DD"),
            "by-company",
            isFilterBy.company
          );
        }
      }
    }
    // console.log("Filter params: ", params);
    handleGetProductionRecapsDaily("scoreboardToday", params);
    handleGetProductionRecapsDaily("scoreboardYesterday", params);
  };

  // write refactoring here

  const handleRefreshData = () => {
    setIsFilterBy({ ...isFilterBy, company: user?.companyId });
    const { today } = calculateDate(isFilterBy.startDate);
    const month = parseInt(moment(today).format("M"));
    const year = parseInt(moment(today).year());
    handleGetBjr();
    handleGetTargetAggregates("by-company", {
      ...params,
      fltMonth: month,
      fltYear: year,
    });
    handleGetDailyInMonth(
      moment(isFilterBy.date).format("Y-MM-DD"),
      "by-company",
      user?.companyId
    );
    handleGetProductionRecapsDaily("scoreboardToday", params);
    handleGetProductionRecapsDaily("scoreboardYesterday", params);
  };

  useEffect(() => {
    handleRefreshData();
  }, [user?.companyId]);

  return (
    <DataContext.Provider
      value={{
        isFilterBy,
        data,
        dataPreviousDate,
        setData,
        calculateDate,
        comparisonChartData,
        averageFruits,
        handleRefreshData,
        handleOnFilter,
        target,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
