import { TbCube3dSphere, TbArrowLeft } from "react-icons/tb";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deleteBlock,
  getBlocks,
  importBlocks,
} from "../../../features/blockSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import BlockModal from "../../../components/organisms/MasterTph/BlockModal";
import FileUploadModal from "../../../components/templates/FileUploadModal";
import Filter from "./partials/Filter/Filter";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import React, { useEffect, useState } from "react";
import TableRowBlock from "../../../components/organisms/Tables/columns/TableRowBlock";
import Tables from "../../../components/organisms/Tables";
import headers from "../../../api/tables/dataTph/blocks_headers.json";
import { selectStatuses } from "../../../features/estateSlice";
import { selectUserCompanyId } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";
import usePrivilage from "../../../hooks/usePrivilages";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import { APP_NAME } from "../../../configs/api";

const modalName = "masterBlock";
const modalUploadName = "modalUpload";

const MoContent = () => {
  const dispatch = useDispatch();
  const { onShowModal, onModalUpdate, isUpdate } = useModal();
  const { user } = usePrivilage();
  const alert = useAlert();
  const navigate = useNavigate();

  const modal = useSelector(selectModal);
  const status = useSelector(selectStatuses);

  const [searchParams] = useSearchParams();

  const estateIdFromParams = searchParams.get("estateId");
  const estateNameFromParams = searchParams.get("estateName");
  const afdelingIdFromParams = searchParams.get("afdelingId");
  const afdelingNameFromParams = searchParams.get("afdelingName");

  const currentCompanyId = useSelector(selectUserCompanyId);

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState({
    total: 1,
    current: 1,
  });
  const [isFilterBy, setIsFilterBy] = useState({
    name: undefined,
  });
  const paginationLenght = Math.ceil(page?.total / 20);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  let params = {
    companyId: currentCompanyId,
    afdelingId: afdelingIdFromParams || undefined,
    page: page.current,
    fltName: isFilterBy.name,
  };

  const handleUpdate = (params) =>
    onModalUpdate(modalName, "showModalCreate", params);

  const handleDetail = (params) => {
    navigate({
      pathname: "/data-tph",
      search: createSearchParams(params).toString(),
    });
  };

  const handleDelete = (props) => {
    dispatch(deleteBlock({ id: props.id }))
      .unwrap()
      .then(() => {
        alert.success(`Berhasil menghapus block ${props.name}`);
        handleRefreshData();
      })
      .catch((err) => {
        return alert.error(`Gagal menghapus block id: ${props.id}`);
      });
  };

  const onFileCapture = (data) => {
    const fileContent = data;
    const fileName = data.name;
    const userId = user?.id;

    dispatch(importBlocks({ fileContent, fileName, userId }))
      .unwrap()
      .then((e) => {
        if (!e.success) {
          return alert.error(e.message);
        }
        alert.success("Berhasil import data block");
        handleRefreshData();
      })
      .catch((e) => {
        alert.error(e.message);
      });
  };

  const onFilter = React.useCallback(
    ({ type, value }) => {
      if (type === "name") {
        setIsFilterBy({
          ...isFilterBy,
          name: value,
        });
        params = {
          ...params,
          fltName: value,
        };
      }
      dispatch(getBlocks(params))
        .unwrap()
        .then((response) => {
          setData(response.data?.records);
          setPage({ current: 0, total: response.data?.totalRecords });
        });
    },
    [data]
  );

  const handlePageClick = (page) => {
    dispatch(
      getBlocks({
        ...params,
        page: page.selected,
      })
    )
      .unwrap()
      .then((response) => {
        setData(response.data?.records);
        setPage({ ...page, total: response.data?.totalRecords });
      });
  };

  const handleRefreshData = () => {
    dispatch(
      getBlocks({
        ...params,
        page: 0,
      })
    )
      .unwrap()
      .then((response) => {
        setData(response.data?.records);
        setPage({ current: 0, total: response.data?.totalRecords });
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleRefreshData();
    }
    return () => (isMounted = false);
  }, []);

  // modal initial
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            showModalDetail: false,
            showModalBlock: false,
            params: {},
          },
        })
      );
      dispatch(
        handleModalStarter({
          [modalUploadName]: {
            showModalInput: false,
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-16">
              <button onClick={() => navigate(-1)}>
                <TbArrowLeft className="text-24 text-gray-500" />
              </button>
              <TbCube3dSphere className="mr-16 hidden text-24 text-gray-500 md:inline" />
              <p className="line-clamp-1">
                {estateNameFromParams} / {afdelingNameFromParams} / Blok
              </p>
            </div>
          }
        />
      </div>
      <FileUploadModal
        isOpen={modal[modalUploadName]?.showModalInput}
        onSubmit={onFileCapture}
        toggleModal={() => onShowModal(modalUploadName, "showModalInput")}
      />

      <BlockModal
        isOpen={modal[modalName]?.showModalCreate}
        toggleModal={() => onShowModal(modalName, "showModalCreate")}
        onRefreshData={handleRefreshData}
        params={isUpdate && modal[modalName]?.params}
        type={isUpdate ? "Ubah" : "Tambah"}
        estate={{ id: estateIdFromParams, name: estateNameFromParams }}
        afdeling={{
          id: afdelingIdFromParams,
          name: afdelingNameFromParams,
        }}
      />
      <div>
        <Filter
          modalName={modalName}
          onShowModal={onShowModal}
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>

      <div className="mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
        <Tables
          className="overscroll-y max-h-full"
          isLoading={status.isLoading}
          isError={status.isError}
          headers={headers}
          rows={data || []}
          rowContent={(props) => (
            <TableRowBlock
              data={props}
              handleUpdate={handleUpdate}
              handleDetail={handleDetail}
              handleDelete={handleDelete}
            />
          )}
        />
      </div>
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              current={page.current}
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default MoContent;
