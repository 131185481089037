import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAggregatesMonthlyInYear } from "../../../../features/harvestingSlice";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";

const TableContext = createContext();

export const TableProvider = ({ children, value }) => {
  const alert = useAlert();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [segmentedSelected, setSegmentedSelected] = useState("Tonase");

  const handleGetHarvestingTargets = async ({
    estateId,
    afdelingId,
    blockId,
    type,
  }) => {
    try {
      let params = {
        pageIndex: 0,
        pageSize: 99999,
        fltCompany: user?.companyId,
        fltEstate: estateId || undefined,
        fltAfdeling: afdelingId || undefined,
        fltBlock: blockId || undefined,
        fltYear: value.isFilterBy.year || undefined,
        type: type,
        sord: type === "by-estate" ? "desc" : "asc",
        sidx: type === "by-estate" ? "estateName" : "",
      };
      const response = await dispatch(
        getAggregatesMonthlyInYear({
          ...params,
          pageIndex: 0,
        })
      ).unwrap();
      const success = response?.success;
      const message = response?.message;
      if (!success) {
        return alert.error(message);
      }
      if (type === "by-afdeling") {
        setAfdelings([]);
        setAfdelings(response?.records);
      } else if (type === "by-block") {
        setBlocks([]);
        setBlocks(response?.records);
      }
    } catch (error) {
      alert.error(error?.data);
    }
  };

  return (
    <TableContext.Provider
      value={{
        handleGetHarvestingTargets,
        afdelings,
        blocks,
        segmentedSelected,
        setSegmentedSelected,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTable = () => useContext(TableContext);
