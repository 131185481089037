import { TbLogout, TbPhone, TbSettings } from "react-icons/tb";
import { MdOutlinePrivacyTip } from "react-icons/md";

export const profileMenu = [
  {
    title: "Pengaturan pengguna",
    link: "/pengaturan/profil",
    haveChild: false,
    icon: <TbSettings />,
  },
  // {
  //   title: "Info Tagihan",
  //   link: "/pengaturan/tagihan",
  //   haveChild: false,
  //   icon: <TbCreditCard />,
  // },
  {
    title: "Contact Support",
    type: "external",
    link: "https://www.semaigroup.com/",
    haveChild: false,
    icon: <TbPhone />,
  },
  {
    title: "Kebijakan Privasi",
    type: "external",
    link: "https://semai.id/semai-privacy-policy",
    haveChild: false,
    icon: <MdOutlinePrivacyTip />,
  },
  {
    title: "Akhiri Sesi",
    link: "/logout",
    haveChild: false,
    icon: <TbLogout />,
  },
];
