import { ConfigProvider, Image, Modal, notification, Spin } from "antd";
import React, { useReducer, useRef, useState } from "react";

import { IMAGE_NOT_FOUND } from "../../../../api/fallbackImageNotFound";
import ButtonComponent from "../../../atoms/ButtonComponent";
import { useFileAttachmentStore } from "../../../../hooks/fileAttachmentStore";
import { useUserStore } from "../../../../pages/MasterWorkerPage/hooks/userStore";
import { useCompanyStore } from "../../../../hooks/companyStore";
import { toast } from "react-hot-toast";
import { calculateToastDuration, cn } from "../../../../helpers/Common";
import EasyCropImage from "./partials/EasyCropImage";
import { useUploadImageStore } from "./hooks/useUplodaImageStore";
import { fileToBase64 } from "../../../../hooks/imageUtils";
import { APP_NAME } from "../../../../configs/api";

const LoadingComponent = ({ status }) => {
  return status ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          colorTextQuaternary: "#e1e4e8",
          colorTextTertiary: "#e1e4e8",
        },
      }}
    >
      <div className="absolute z-10 flex flex-col items-center">
        <Spin size="large" />
        <p className="font-bold text-14 text-gray-500">Memuat...</p>
      </div>
    </ConfigProvider>
  ) : null;
};

const ProfilePicture = ({ imageLoading, setSource, setCompanySource }) => {
  const fileInputRef = useRef(null);
  const fileInputCompanyRef = useRef(null);
  const [currentState, setCurrentState] = useState(undefined);
  const [imageSource, uploadFile, setPhoto] = useFileAttachmentStore(
    (state) => [state.imageSource, state.uploadFile, state.setPhoto]
  );
  const getCroppedImage = useUploadImageStore((state) => state.getCroppedImage);
  const [user, updateUser] = useUserStore((state) => [
    state.user,
    state.updateUser,
  ]);
  const [company, updateCompany] = useCompanyStore((state) => [
    state.company,
    state.updateCompany,
  ]);
  const [visible, setVisible] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      photoProfile: false,
      logoCompany: false,
    }
  );
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isLoadingPhotoProfile: false,
      isLoadingLogoCompany: false,
    }
  );
  const setPhotoPreview = useUploadImageStore((state) => state.setPhoto);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen((prev) => !prev);
  const handleOk = () => setIsModalOpen(false);

  const handleShowVisible = (key) => {
    setVisible({ [key]: !visible[key] });
  };

  const updateUserPhotoProfile = async (
    currentData,
    photoFileId,
    parsedUrl,
    type,
    loadingState,
    setStateLoading
  ) => {
    // Capitalize first letter
    const firstChar = loadingState.charAt(0).toUpperCase();
    const restOfString = loadingState.slice(1);
    const capitalizedStr = firstChar + restOfString;

    try {
      setStateLoading({ [`isLoading${capitalizedStr}`]: true });
      const requestBody = {
        ...currentData,
        photoFileId,
        version: currentData?.version + 1,
      };

      let response;

      if (type === "company") {
        response = await updateCompany(company?.id, requestBody);
      }
      if (type === "user") {
        response = await updateUser(user?.id, company?.id, requestBody);
      }

      if (!response?.success) {
        setStateLoading({ [`isLoading${capitalizedStr}`]: false });
        return notification.error({
          message: `Gagal Update Foto ${type}`,
          description: response?.message,
          placement: "bottomRight",
        });
      }

      const toastMessage = `Berhasil Update Foto ${
        type === "company" ? "Logo" : "Profil"
      }`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      setPhoto({ [loadingState]: parsedUrl });
      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
    } catch (error) {
      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
      return notification.error({
        message: `Gagal Update Foto ${type}`,
        description: error?.data,
        placement: "bottomRight",
      });
    }
  };

  const setUploadFile = async (data, loadingState, setStateLoading) => {
    // Capitalize first letter
    const firstChar = loadingState.charAt(0).toUpperCase();
    const restOfString = loadingState.slice(1);
    const capitalizedStr = firstChar + restOfString;

    try {
      setStateLoading({ [`isLoading${capitalizedStr}`]: true });

      const response = await uploadFile(data);
      if (!response?.success) {
        setStateLoading({ [`isLoading${capitalizedStr}`]: false });
        return notification.error({
          message: "Gagal Upload File",
          description: response?.message,
          placement: "bottomRight",
        });
      }

      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
      return response?.record;
    } catch (error) {
      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
      return notification.error({
        message: "Gagal Upload File",
        description: error?.data,
        placement: "bottomRight",
      });
    }
  };

  const onChange = async (file, url, type, loadingState, currentData) => {
    const data = {
      fileContent: file,
      fileName: file?.name,
      userId: user?.id,
    };

    const uploadedFile = await setUploadFile(data, loadingState, setRequest);
    if (!uploadedFile) return;

    await updateUserPhotoProfile(
      currentData,
      uploadedFile?.id,
      url,
      type,
      loadingState,
      setRequest
    );
  };

  const resetInput = (inputElement) => {
    inputElement.value = ""; // Clear the value of the input element
  };

  const onCropping = async (event) => {
    const file = event?.target?.files[0];
    const imageDataUrl = await fileToBase64(file);

    setPhotoPreview(imageDataUrl);
    showModal();
    resetInput(event.target); // Reset the input element to allow uploading the same image
  };

  const triggerFileInput = (ref) => ref.current.click();

  return (
    <div className="relative flex w-full flex-col items-center space-y-16 overflow-hidden rounded-16 border-1 border-gray-10 bg-white p-16 md:mx-32 md:w-fit md:flex-row md:items-start md:space-x-16 md:space-y-0">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Modal
          title="Filter"
          zIndex={100}
          width={600}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Batalkan"
                type="submit"
                size="none"
                className="bordered-button-transition border-gray-100 px-16 py-8 text-16 text-gray-500"
                onClick={() => handleOk()}
              />
              <ButtonComponent
                value="Simpan"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={async () => {
                  await handleOk();
                  const { file, url } = await getCroppedImage();
                  if (currentState === "user")
                    await onChange(file, url, "user", "photoProfile", user);
                  if (currentState === "company")
                    await onChange(
                      file,
                      url,
                      "company",
                      "logoCompany",
                      company
                    );
                }}
              />
            </div>
          }
        >
          <EasyCropImage />
        </Modal>
      </ConfigProvider>
      <div className="relative flex flex-col items-center justify-center">
        <Image
          wrapperStyle={{ display: "none" }}
          src={imageSource.logoCompany || IMAGE_NOT_FOUND}
          preview={{
            visible: visible.logoCompany,
            src: imageSource.logoCompany || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("logoCompany"),
          }}
        />

        <input
          type="file"
          accept="image/*"
          onChange={(event) => {
            onCropping(event);
            setCurrentState("company");
          }}
          ref={fileInputCompanyRef}
          style={{ display: "none" }}
        />

        <LoadingComponent status={request.isLoadingLogoCompany} />
        <p className="mb-14 font-medium text-18">Logo Perusahaan</p>
        <img
          src={imageSource.logoCompany || IMAGE_NOT_FOUND}
          alt={company?.photoFileId}
          onClick={() => handleShowVisible("logoCompany")}
          className="relative mb-16 aspect-square w-[170px] cursor-pointer resize rounded-8 border border-dashed border-gray-200 object-contain"
        />

        <ButtonComponent
          disabled={request.isLoadingLogoCompany}
          isLoading={request.isLoadingLogoCompany}
          onClick={() => triggerFileInput(fileInputCompanyRef)}
          value="Unggah logo perusahaan"
          className={cn(
            "input-mini-border hover:text-white",
            APP_NAME === "sawit-web"
              ? "hover:bg-yellow-600"
              : "hover:bg-primary-red-500"
          )}
        />
      </div>
      <div className="relative flex flex-col items-center justify-center">
        <Image
          wrapperStyle={{ display: "none" }}
          src={imageSource.photoProfile}
          preview={{
            visible: visible.photoProfile,
            src: imageSource.photoProfile || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("photoProfile"),
          }}
        />
        <p className="mb-14 font-medium text-18">Foto profil Admin</p>
        <input
          type="file"
          accept="image/*"
          onChange={(event) => {
            onCropping(event);
            setCurrentState("user");
          }}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <LoadingComponent status={imageLoading.isLoadingPhotoProfile} />
        <img
          src={imageSource.photoProfile || IMAGE_NOT_FOUND}
          alt={user?.photoFileId}
          onClick={() => handleShowVisible("photoProfile")}
          className="relative mb-16 aspect-square w-[170px] cursor-pointer rounded-8 border border-dashed border-gray-200 object-contain"
        />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
              colorTextQuaternary: "#e1e4e8",
              colorTextTertiary: "#e1e4e8",
            },
          }}
        >
          <ButtonComponent
            disabled={request.isLoadingPhotoProfile}
            isLoading={request.isLoadingPhotoProfile}
            onClick={() => triggerFileInput(fileInputRef)}
            value="Unggah foto profil"
            className={cn(
              "input-mini-border hover:text-white",
              APP_NAME === "sawit-web"
                ? "hover:bg-yellow-600"
                : "hover:bg-primary-red-500"
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ProfilePicture;
