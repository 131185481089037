import axiosInstance from "../api";
import { COMPANIES_NEW_URL } from "../../configs/api";

export const getCompaniesApi = (params) =>
  axiosInstance.get(COMPANIES_NEW_URL, {
    params,
  });

export const getCompanyApi = (id, params) =>
  axiosInstance.get(`${COMPANIES_NEW_URL}/${id}`, {
    params,
  });

export const createCompanyApi = (body) =>
  axiosInstance.post(COMPANIES_NEW_URL, body);

export const updateCompanyApi = (id, body) =>
  axiosInstance.put(`${COMPANIES_NEW_URL}/${id}`, body);

export const deleteCompanyApi = (id) =>
  axiosInstance.delete(`${COMPANIES_NEW_URL}/${id}`);
