export const defaultOptions = {
	maintainAspectRatio: false,
	tension: 0.4,
	scales: {
		y: {
			display: false,
			grid: {
				display: false,
			},
		},
		x: {
			grid: {
				display: false,
			},
			ticks: {
				font: {
					size: "14",
					weight: "500",
					family: "Inter-Regular",
				},
			},
		},
	},
	layout: {
		padding: {
			top: 32,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		bar: {
			borderRadius: 999,
		},
		datalabels: {
			color: "black",
			align: "end",
			anchor: "end",
			font: { size: "14", weight: "500", family: "Inter-Regular" },
			formatter: function (value) {
				return `${value != 0 ? value + "%" : ""}`;
			},
		},
	},
};
