import Item from "./partials/Item/Item";
import React from "react";

const TableRow = ({ data, handleRefreshData, page }) => {
  return data.map((item, index) => {
    return (
      <Item
        item={item}
        index={index}
        key={index}
        handleRefreshData={handleRefreshData}
        page={page}
      />
    );
  });
};

export default TableRow;
