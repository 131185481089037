import { TbBuildingSkyscraper, TbUser } from "react-icons/tb";

import React from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
	const [data, setData] = useState({});
	const [current, setCurrent] = useState(0);

	const steps = [
		{
			title: "Data Pribadi",
			content: "first",
			icon: <TbUser className="text-24" />,
		},
		{
			title: "Data Perusahaan/Koperasi/Grup",
			content: "second",
			icon: <TbBuildingSkyscraper className="text-24" />,
		},
	];
	const stepLength = steps.length;

	const setValues = (values) => {
		setData((prevData) => ({
			...prevData,
			...values,
		}));
	};

	const onNext = async () => {
		setCurrent((v) => v + 1);
	};
	const onPrev = () => {
		setCurrent((v) => v - 1);
	};

	return (
		<DataContext.Provider
			value={{
				data,
				setValues,
				steps,
				current,
				onNext,
				onPrev,
				stepLength,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export const useData = () => useContext(DataContext);
