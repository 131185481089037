import { RxTriangleDown, RxTriangleRight } from "react-icons/rx";

import { MdArrowRight } from "react-icons/md";
import { Number } from "../../../../../../../helpers/Common";
import React from "react";
import { generateMonthlyData } from "./helpers/helpers";
import { useExpandable } from "../../../../hooks/ExpandableContext";
import { useTable } from "../../../../hooks/TableContext";
import { BiSolidSquareRounded } from "react-icons/bi";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../../../App";
import { pagePath } from "../../../../ListView";

const TableRow = ({ data }) => {
  const { handleGetHarvestingTargets, afdelings, blocks, segmentedSelected } =
    useTable();
  const { handleRowClick, expandedEstate, expandedAfdeling } = useExpandable();

  const renderMonths = (months, active) => (
    <>
      {months?.map((month, index) => (
        <React.Fragment key={index}>
          <td
            className={`table-row-regular border-collapse border border-gray-10 ${
              active
                ? "text-14 font-bold text-gray-500"
                : "font-regular text-14 text-gray-300"
            }`}
          >
            {Number(Math.ceil(month.weightKg || 0, 1))}
          </td>
          <td
            className={`table-row-regular border-collapse border border-gray-10 ${
              active
                ? "text-14 font-bold text-gray-500"
                : "font-regular text-14 text-gray-300"
            }`}
          >
            {Number(Math.ceil(month.bunchCount || 0, 1))}
          </td>
        </React.Fragment>
      ))}
    </>
  );

  const renderBlocks = (blocks) => (
    <>
      {blocks
        .sort((a, b) => {
          const contentA = a.blockName.toLowerCase();
          const contentB = b.blockName.toLowerCase();

          // Use localeCompare for alphanumeric sorting
          return contentA.localeCompare(contentB);
        })
        .map((block, index) => {
          const months = generateMonthlyData(
            block,
            segmentedSelected?.toLowerCase() === "tonase"
              ? "WeightKg"
              : "BunchCount"
          );

          return (
            <tr key={index} className="table-header-regular relative">
              <td
                className={`indent table-row-regular flex border-collapse flex-row items-center space-x-4 border border-gray-10 px-32`}
              >
                <span className="mr-4">
                  <BiSolidSquareRounded className="text-[8px]" />
                </span>
                {block?.blockName}
              </td>
              {renderMonths(months, false)}
            </tr>
          );
        })}
    </>
  );

  const renderAfdelings = (afdelings) => (
    <>
      {afdelings
        ?.sort((a, b) => {
          const contentA = a.afdelingName.toLowerCase();
          const contentB = b.afdelingName.toLowerCase();

          // Use localeCompare for alphanumeric sorting
          return contentA.localeCompare(contentB);
        })
        .map((afdeling, index) => {
          const months = generateMonthlyData(
            afdeling,
            segmentedSelected?.toLowerCase() === "tonase"
              ? "WeightKg"
              : "BunchCount"
          );

          return (
            <React.Fragment key={index}>
              <tr className="table-header-regular relative">
                <td
                  className={`indent table-row-regular flex border-collapse cursor-pointer flex-row items-center space-x-4 border border-gray-10 px-16 ${
                    expandedAfdeling === afdeling?.afdelingName
                      ? "font-bold text-gray-500"
                      : "text-gray-300"
                  }`}
                  onClick={() => {
                    logEvent(
                      analytics,
                      `detail_afdeling_button_${pagePath}_${window.location.host}`,
                      {
                        page_title: `${pagePath}_${window.location.host}`,
                        page_location: window.location.href,
                      }
                    );
                    handleRowClick(afdeling?.afdelingName, true);
                    handleGetHarvestingTargets({
                      type: "by-block",
                      estateId: afdeling?.estateId,
                      afdelingId: afdeling?.afdelingId,
                    });
                  }}
                >
                  <span>
                    {expandedAfdeling === afdeling?.afdelingName ? (
                      <RxTriangleDown />
                    ) : (
                      <MdArrowRight />
                    )}
                  </span>
                  {afdeling?.afdelingName}
                </td>
                {renderMonths(
                  months,
                  expandedAfdeling === afdeling?.afdelingName
                )}
              </tr>
              {expandedAfdeling === afdeling?.afdelingName &&
                renderBlocks(blocks)}
            </React.Fragment>
          );
        })}
    </>
  );

  const renderEstates = (estates) => (
    <>
      {estates
        .sort((a, b) => {
          const contentA = a.estateName.toLowerCase();
          const contentB = b.estateName.toLowerCase();

          // Use localeCompare for alphanumeric sorting
          return contentA.localeCompare(contentB);
        })
        .map((estate, index) => {
          const months = generateMonthlyData(
            estate,
            segmentedSelected?.toLowerCase() === "tonase"
              ? "WeightKg"
              : "BunchCount"
          );

          return (
            <React.Fragment key={index}>
              <tr className="table-header-regular relative">
                <td
                  className={`indent table-row-regular flex border-collapse cursor-pointer flex-row items-center space-x-4 border border-gray-10 px-8 ${
                    expandedEstate === estate?.estateName
                      ? "font-bold text-gray-500"
                      : "text-gray-400"
                  }`}
                  onClick={() => {
                    logEvent(
                      analytics,
                      `detail_estate_button_${pagePath}_${window.location.host}`,
                      {
                        page_title: `${pagePath}_${window.location.host}`,
                        page_location: window.location.href,
                      }
                    );
                    handleRowClick(estate?.estateName);
                    handleGetHarvestingTargets({
                      type: "by-afdeling",
                      estateId: estate?.estateId,
                    });
                  }}
                >
                  <span>
                    {expandedEstate === estate?.estateName ? (
                      <RxTriangleDown />
                    ) : (
                      <RxTriangleRight />
                    )}
                  </span>
                  <p>{estate?.estateName}</p>
                </td>
                {renderMonths(months, expandedEstate === estate?.estateName)}
              </tr>
              {expandedEstate === estate?.estateName &&
                renderAfdelings(afdelings)}
            </React.Fragment>
          );
        })}
    </>
  );

  return renderEstates(data);
};

export default TableRow;
