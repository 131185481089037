import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Route, Routes } from "react-router-dom";

import AuthMiddleware from "./components/AuthMiddleware";
import AverageFruitMonitoring from "./pages/AverageFruitMonitoring";
import BaseLayout from "./components/templates/BaseLayout";
import BillingPage from "./pages/BillingPage";
import CollectionPoint from "./pages/CollectionPoint";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import GardenProduction from "./pages/Dashboard/GardenProduction/GardenProduction";
import HistoryHarvest from "./pages/HistoryHarvest";
import HistoryPks from "./pages/HistoryPks";
import HistorySpb from "./pages/HistorySpb";
import LoginPage from "./pages/LoginPage";
import MasterAfdelingPage from "./pages/MasterAfdelingPage";
import MasterBlockPage from "./pages/MasterBlockPage";
import MasterPremiPage from "./pages/MasterPremiPage";
import MasterTphPage from "./pages/MasterTphPage";
import NoMatchPage from "./pages/NoMatchPage";
import PksProduction from "./pages/Dashboard/PksProduction/PksProduction";
import ProductionTargets from "./pages/ProductionTargets";
import ProfilePage from "./pages/ProfilePage";
import React, { useEffect } from "react";
import RedirectPage from "./pages/RedirectPage";
import RegisterPage from "./pages/RegisterPage";
import SubscriptionPlansPage from "./pages/SubscriptionPlansPage";
import Vehicle from "./pages/Vehicle";
import Yield from "./pages/Dashboard/Yield/Yield";
import moment from "moment";
import "react-loading-skeleton/dist/skeleton.css";
import HistoryFertilization from "./pages/HistoryFertilization";
import { useUserStore } from "./pages/MasterWorkerPage/hooks/userStore";
import { useAuthStore } from "./hooks/authStore";
import { useCompanyStore } from "./hooks/companyStore";
import { useFileAttachmentStore } from "./hooks/fileAttachmentStore";
import { shallow } from "zustand/shallow";
import Member from "./pages/Member";
import HistoryRamp from "./pages/HistoryRamp";
import HistoryTraksi from "./pages/HistoryTraksi";
import DimensionalTags from "./pages/master-data/DimensionalTags";
import DataPks from "./pages/master-data/DataPKS";
import HistoryChemist from "./pages/HistoryChemist";
import Dashboard from "./pages/Dashboard";
import PriceSetting from "./pages/PriceSetting/ListView";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ReactGA from "react-ga4";
import Employee from "./pages/master-data/Employee";
import HistoryMultifunction from "./pages/HistoryMultifunction";
import MapboxPage from "./pages/MapViewer/MapboxPage";
import AdvancedMapViewer from "./pages/AdvancedMapViewer/AdvancedMapViewer";

moment.locale("id");
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Filler,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const firebaseConfig = {
  apiKey: "AIzaSyCJZA3tWSl7l5am94QKre_4wcPN7gXo7lE",
  authDomain: "bouquet-store.firebaseapp.com",
  projectId: "bouquet-store",
  storageBucket: "bouquet-store.appspot.com",
  messagingSenderId: "1072294362549",
  appId: "1:1072294362549:web:8cd56c7cb24977dfc0adfc",
  measurementId: "G-L2592JZBMH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  ReactGA.initialize("G-L2592JZBMH");

  const getUserById = useUserStore((state) => state.getUserById);
  const [tokensParsed, authenticated] = useAuthStore((state) => [
    state.tokensParsed,
    state.authenticated,
  ]);
  const getCompanyById = useCompanyStore((state) => state.getCompanyById);
  const [getImage, setPhoto] = useFileAttachmentStore(
    (state) => [state.getImage, state.setPhoto],
    shallow
  );

  const setDownloadedImage = async (id, type, setStateFunc) => {
    const response = await getImage(id);
    setStateFunc({ [type]: response });
  };

  const initialGet = async () => {
    if (!tokensParsed.tokenParsed.sub) {
      initialGet();
      return;
    }
    const user = await getUserById(tokensParsed.tokenParsed.sub, true);
    if (user?.record?.photoFileId) {
      await setDownloadedImage(
        user?.record?.photoFileId,
        "photoProfile",
        setPhoto
      );
    }
    const company = await getCompanyById(user?.record?.companyId, true);
    if (company?.record?.photoFileId) {
      await setDownloadedImage(
        company?.record?.photoFileId,
        "logoCompany",
        setPhoto
      );
      return;
    }
  };

  useEffect(() => {
    if (authenticated) initialGet();
  }, [authenticated]);

  return (
    <Routes>
      <Route path="/redirect-to" element={<RedirectPage />} />
      <Route
        index
        element={
          <AuthMiddleware>
            <LoginPage />
          </AuthMiddleware>
        }
      />
      <Route
        path="login-callback"
        element={
          <AuthMiddleware>
            <LoginPage />
          </AuthMiddleware>
        }
      />
      <Route
        path="logout-callback"
        element={
          <AuthMiddleware>
            <LoginPage />
          </AuthMiddleware>
        }
      />
      <Route
        path="verifikasi-email"
        element={
          <AuthMiddleware>
            <EmailVerification />
          </AuthMiddleware>
        }
      />
      <Route
        path="account/register"
        element={
          <AuthMiddleware>
            <RegisterPage />
          </AuthMiddleware>
        }
      />
      <Route
        path="forgot-password"
        element={
          <AuthMiddleware>
            <ForgotPassword />
          </AuthMiddleware>
        }
      />

      <Route element={<AuthMiddleware needAuth />}>
        {/*<Route index.js element={<GardenProduction />} />*/}
        <Route index path="produksi-kebun" element={<GardenProduction />} />
        <Route path="penerimaan-pks" element={<PksProduction />} />
        <Route
          path="dashboard-pemupukan"
          element={<Dashboard.Fertilizations />}
        />
        <Route path="peta-kebun" element={<MapboxPage />} />
        <Route path="peta-kebun-advanced" element={<AdvancedMapViewer />} />
        <Route path="dashboard-chemist" element={<Dashboard.Chemist />} />

        <Route path="dashboard-trading" element={<Dashboard.Trading />} />
        <Route path="pengaturan-harga" element={<PriceSetting />} />

        <Route path="data-yield" element={<Yield />} />
        {/* master */}
        <Route path="data-kebun" element={<MasterTphPage />} />
        <Route path="data-afdeling" element={<MasterAfdelingPage />} />
        <Route path="data-block" element={<MasterBlockPage />} />
        <Route path="data-tph" element={<CollectionPoint.ListView />} />
        <Route path="bjr-acuan" element={<AverageFruitMonitoring.ListView />} />
        <Route path="data-truck" element={<Vehicle.ListView />} />
        <Route path="data-pengguna" element={<Member.ListView />} />
        <Route path="data-premi" element={<MasterPremiPage />} />
        <Route path="data-anggota" element={<Employee.ListView />} />
        <Route path="data-tag" element={<DimensionalTags.ListView />} />
        <Route path="data-pks" element={<DataPks.ListView />} />

        <Route
          path="riwayat-multiguna"
          element={<HistoryMultifunction.ListView />}
        />
        <Route
          path="riwayat-multiguna/:id"
          element={<HistoryMultifunction.DetailView />}
        />
        {/* end of master */}
        {/* <Route
					path="laporan-pembukuan"
					element={<AccountingReportPage />}
				/>
				<Route
					path="stock-material"
					element={<ManageMaterialsPage />}
				/> */}
        <Route path="pengaturan" element={<BaseLayout />}>
          <Route path="profil" element={<ProfilePage />} />
          <Route path="tagihan" element={<BillingPage />} />
          <Route
            path="paket-berlangganan"
            element={<SubscriptionPlansPage />}
          />
        </Route>
        {/* <Route path="supplier" element={<BaseLayout />}>
					<Route index.js element={<Supplier.IndexPage />} />
					<Route path="create" element={<Supplier.CreatePage />} />
					<Route path=":id" element={<Supplier.CreatePage />} />
				</Route> */}
        {/* <Route path="riwayat-panen" element={<DailyHarvestPage />} /> */}
        <Route path="riwayat-panen" element={<HistoryHarvest.ListView />} />
        <Route path="riwayat-spb" element={<HistorySpb.ListView />} />
        <Route path="riwayat-pks" element={<HistoryPks.ListView />} />
        <Route path="riwayat-ramp" element={<HistoryRamp.ListView />} />
        <Route path="riwayat-traksi" element={<HistoryTraksi.ListView />} />
        <Route
          path="riwayat-pupuk"
          element={<HistoryFertilization.ListView />}
        />
        <Route path="riwayat-chemist" element={<HistoryChemist.ListView />} />
        <Route
          path="riwayat-pupuk/:id"
          element={<HistoryFertilization.DetailView />}
        />
        <Route
          path="target-produksi"
          element={<ProductionTargets.ListView />}
        />
        {/* <Route path="panen" element={<BaseLayout />}> */}
        {/* <Route path="kebun" element={<GardenHarvestPage />} /> */}
        {/* <Route path="tph" element={<TphHarvestPage />} /> */}
        {/* <Route path="tph/:id" element={<TphCreatePage />} /> */}
        {/* </Route> */}
      </Route>
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
}

export default App;
