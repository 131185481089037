export const ujungbatu2009 = {
  type: "FeatureCollection",
  name: "ujungbatutt09_1775ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Luas: 1.059,
        Nm_Pemilik: "Satria",
        KETERANGAN: "Sporadik",
        No_Kav: "91",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725942609077109, -3.712512907855033],
              [114.725746401725971, -3.712416015049282],
              [114.725354198662671, -3.71229278450865],
              [114.725264603709903, -3.712321377839837],
              [114.725084248381947, -3.712385479409111],
              [114.724992056869468, -3.712531671246431],
              [114.72474452029121, -3.712835421524669],
              [114.724683668177377, -3.712844488172961],
              [114.724691879310896, -3.712775329850619],
              [114.724848477789692, -3.712146372537235],
              [114.724893936387261, -3.711855763992655],
              [114.724881328775751, -3.711535786704413],
              [114.72482100032795, -3.711205287713337],
              [114.724750397499335, -3.710988342707656],
              [114.724668152347604, -3.710835935217998],
              [114.72496576498024, -3.710697191925798],
              [114.725109729541245, -3.710950069209758],
              [114.725282428110432, -3.711186878839329],
              [114.725255310806119, -3.711468139069258],
              [114.725194042476332, -3.711599457790923],
              [114.725163715263491, -3.711829942205291],
              [114.725277827667981, -3.712044206726688],
              [114.725570371969454, -3.712135382947607],
              [114.725641556374455, -3.712125189900215],
              [114.725941848456159, -3.712285078680457],
              [114.725942609077109, -3.712512907855033],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.649,
        Nm_Pemilik: "Said Ekaroni",
        KETERANGAN: "Sertifikat",
        No_Kav: "117",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719032065888271, -3.712512997186657],
              [114.717551614605682, -3.710542292669845],
              [114.717695266620993, -3.710583927869145],
              [114.717847004077825, -3.710634784965512],
              [114.718114330022004, -3.710784068440057],
              [114.718539687809852, -3.711194714480992],
              [114.718825123910008, -3.711350798251317],
              [114.719429075136532, -3.711554781031913],
              [114.71955061657448, -3.711668073564878],
              [114.719710007013546, -3.711640241909177],
              [114.719854566946381, -3.711913645896753],
              [114.719032065888271, -3.712512997186657],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.727,
        Nm_Pemilik: "Muksin",
        KETERANGAN: "Sertifikat",
        No_Kav: "126",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.702068966554151, -3.73127058922044],
              [114.702868159753592, -3.730841180937742],
              [114.703184390770488, -3.731408838718499],
              [114.702377565331133, -3.73184078032107],
              [114.702068966554151, -3.73127058922044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.721,
        Nm_Pemilik: "Sucipto Baitoni",
        KETERANGAN: "Sporadik",
        No_Kav: "141",
        block: "Blok 11",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706455991480084, -3.732139660494479],
              [114.706405550377099, -3.731616273639878],
              [114.706916958514569, -3.731375574322397],
              [114.707076301468703, -3.731250131476755],
              [114.707076615375172, -3.731129113078336],
              [114.707461908974921, -3.731207573871707],
              [114.707615931055074, -3.731275747760392],
              [114.70770727262591, -3.731358279912851],
              [114.70792388745987, -3.73144598067182],
              [114.707945461882446, -3.732408424698365],
              [114.707662901095063, -3.732357438825234],
              [114.706455991480084, -3.732139660494479],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.712,
        Nm_Pemilik: "Satria",
        KETERANGAN: "Sporadik",
        No_Kav: "44",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726561795649388, -3.717492143592119],
              [114.726961126290902, -3.717364264166518],
              [114.727050712667094, -3.717614262747051],
              [114.727212655527921, -3.717795963926186],
              [114.72739058708342, -3.717997848953654],
              [114.727374010193742, -3.718207288115501],
              [114.727290047209777, -3.718400902727053],
              [114.727287439469535, -3.718506546642406],
              [114.726633650469267, -3.718277120067058],
              [114.726682626163452, -3.71806853132311],
              [114.726645086637035, -3.717715036753132],
              [114.726561795649388, -3.717492143592119],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.748,
        Nm_Pemilik: "Rahmatulah",
        KETERANGAN: "Sertifikat",
        No_Kav: "128",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.699603094078512, -3.732685597290728],
              [114.699945677314801, -3.733253654393045],
              [114.699228612216515, -3.733799654316413],
              [114.69886363874275, -3.733221822349405],
              [114.699603094078512, -3.732685597290728],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.736,
        Nm_Pemilik: "Sumari",
        KETERANGAN: "Sertifikat",
        No_Kav: "127",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.702323291960809, -3.731776527398779],
              [114.702656992375466, -3.732390450015526],
              [114.701885606472459, -3.732844612782669],
              [114.701588839171876, -3.732260183039987],
              [114.702323291960809, -3.731776527398779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.741,
        Nm_Pemilik: "Sinem",
        KETERANGAN: "Sporadik",
        No_Kav: "84",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727425433065534, -3.710275658450029],
              [114.727105120630085, -3.709881087385804],
              [114.727076200357743, -3.709769783130501],
              [114.727020392639162, -3.709788032859092],
              [114.726087536072754, -3.710124463151105],
              [114.726056191515084, -3.710103236826005],
              [114.725848274650062, -3.710105684170682],
              [114.725996118398655, -3.709869971135876],
              [114.726029834604148, -3.709692692304629],
              [114.726219806472812, -3.709448980281723],
              [114.726283641636911, -3.709429487203435],
              [114.726476195217785, -3.709626915536688],
              [114.726966407892888, -3.709571487060264],
              [114.727028288524437, -3.709585386853508],
              [114.72762785208532, -3.709905047754655],
              [114.727425433065534, -3.710275658450029],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.725,
        Nm_Pemilik: "Ahmad Gazali",
        KETERANGAN: "Sertifikat",
        No_Kav: "136",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.700767771993796, -3.728768214298202],
              [114.700475583666304, -3.728157530650276],
              [114.701229632534577, -3.727770005174358],
              [114.70155440222409, -3.728344895869133],
              [114.70156251999444, -3.728361312756649],
              [114.700767771993796, -3.728768214298202],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.753,
        Nm_Pemilik: "Asari",
        KETERANGAN: "Sertifikat",
        No_Kav: "135",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.701860205674194, -3.728963334111702],
              [114.701066955642702, -3.729393518116595],
              [114.700767771993796, -3.728768214298202],
              [114.70156251999444, -3.728361312756649],
              [114.701860205674194, -3.728963334111702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.733,
        Nm_Pemilik: "Prayetno / Sutoyo",
        KETERANGAN: "Sertifikat",
        No_Kav: "137",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.701229632534577, -3.727770005174358],
              [114.701990810817378, -3.727378814436535],
              [114.702365114802504, -3.727950392336698],
              [114.70156251999444, -3.728361312756649],
              [114.70155440222409, -3.728344895869133],
              [114.701229632534577, -3.727770005174358],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.959,
        Nm_Pemilik: "Armawi",
        KETERANGAN: "Sertifikat",
        No_Kav: "138",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.701619093988171, -3.726811186827736],
              [114.70162631584445, -3.726807483860962],
              [114.70162426917851, -3.726802497443358],
              [114.702677462350806, -3.726269351689782],
              [114.702983646732974, -3.726868566467975],
              [114.701990810817378, -3.727378814436535],
              [114.701619093988171, -3.726811186827736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Nezar",
        KETERANGAN: "Sertifikat",
        No_Kav: "142",
        block: "Blok 11",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707173446071906, -3.73352145198137],
              [114.706217593344277, -3.733369618451593],
              [114.706499505420425, -3.732344793666585],
              [114.706477872197965, -3.732172504985358],
              [114.70756370822059, -3.732369832539527],
              [114.707173446071906, -3.73352145198137],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.751,
        Nm_Pemilik: "A. Hamid",
        KETERANGAN: "Sporadik",
        No_Kav: "39",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724691792199081, -3.714366738142339],
              [114.725175977238933, -3.714340855289155],
              [114.725224425081493, -3.71565793945982],
              [114.724791670653289, -3.715701696498026],
              [114.724691792199081, -3.714366738142339],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suyanto",
        KETERANGAN: "Sertifikat",
        No_Kav: "38",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724176830696848, -3.715763864327981],
              [114.723513808680821, -3.714429702173569],
              [114.724691786323021, -3.714366738127145],
              [114.724791670653289, -3.715701696498026],
              [114.724176830696848, -3.715763864327981],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.91,
        Nm_Pemilik: "Satria",
        KETERANGAN: "Sporadik",
        No_Kav: "55",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730474688842506, -3.718639045557418],
              [114.730727311383873, -3.71857697322974],
              [114.730776277714511, -3.718538915933074],
              [114.730825327768628, -3.718468129940385],
              [114.730987503458422, -3.717688509583101],
              [114.730977159925786, -3.717486654602723],
              [114.731424220119621, -3.716778681680589],
              [114.731570783228605, -3.716606393628787],
              [114.731731803817908, -3.716458401347132],
              [114.731728846121356, -3.716079454539796],
              [114.732186411520431, -3.716083690889295],
              [114.732150274959878, -3.716322349399875],
              [114.73231823023427, -3.716685512205203],
              [114.732351407020815, -3.71682147191711],
              [114.732234550162346, -3.716860655344087],
              [114.73193478418716, -3.717001562887953],
              [114.731656521993344, -3.717322529883532],
              [114.731531915527839, -3.717679893992311],
              [114.731381800238097, -3.718114380482293],
              [114.731260627505833, -3.718452032688404],
              [114.731159883884203, -3.718642017792825],
              [114.731072576513171, -3.718805179409657],
              [114.731111669535778, -3.718956966369908],
              [114.730474688842506, -3.718639045557418],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Subandi",
        KETERANGAN: "Sertifikat",
        No_Kav: "53",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730890334362144, -3.714643408194482],
              [114.731506105888968, -3.715133671920578],
              [114.730622692528868, -3.716151946180891],
              [114.730598248484554, -3.716140236846784],
              [114.730557608145801, -3.716175797046028],
              [114.72956434723568, -3.71556994863669],
              [114.729598053999709, -3.71540287369479],
              [114.729996832545353, -3.715651248234287],
              [114.730890334362144, -3.714643408194482],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sunarto",
        KETERANGAN: "Sertifikat",
        No_Kav: "54",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731506106644019, -3.715133672682982],
              [114.732282965120874, -3.715751486106892],
              [114.732187782124541, -3.716081788970606],
              [114.731727216671615, -3.7160773249508],
              [114.731729889267754, -3.716457219170943],
              [114.731569366620135, -3.716605427159785],
              [114.730622692528868, -3.716151946180891],
              [114.731506106644019, -3.715133672682982],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Titik Purwaraharti",
        KETERANGAN: "Sertifikat",
        No_Kav: "60",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730386052264947, -3.711766884478579],
              [114.731806849993902, -3.711739108591906],
              [114.731733327405863, -3.712606870874018],
              [114.730573274967313, -3.71279657121372],
              [114.730539998552459, -3.712503240398755],
              [114.730386052264947, -3.711766884478579],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Nikur Usman",
        KETERANGAN: "Sertifikat",
        No_Kav: "14",
        block: "Blok 2",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721850249365588, -3.714499428262857],
              [114.721903911165967, -3.71449732643076],
              [114.722171343651624, -3.715395742726726],
              [114.722394833778807, -3.716146538562526],
              [114.722414266068327, -3.716211819669635],
              [114.721493474568035, -3.716503846980746],
              [114.720594659091745, -3.716788901264677],
              [114.720610299116643, -3.716726151356994],
              [114.721817291769554, -3.715475668996417],
              [114.721850249365588, -3.714499428262857],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Isroniyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "16",
        block: "Blok 2",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723721880459365, -3.715070406818841],
              [114.724054199736585, -3.71570796537709],
              [114.723274694443845, -3.715913990436072],
              [114.722394833778807, -3.716146538562526],
              [114.722171343651624, -3.715395742726726],
              [114.723721880459365, -3.715070406818841],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "M. Langgeng",
        KETERANGAN: "Sertifikat",
        No_Kav: "15",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721903911165967, -3.71449732643076],
              [114.723059730141571, -3.714452054351921],
              [114.723391746056876, -3.714436098027821],
              [114.723721880454249, -3.715070406792071],
              [114.722171343651624, -3.715395742726726],
              [114.721903911165967, -3.71449732643076],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suhadak",
        KETERANGAN: "Sertifikat",
        No_Kav: "18",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723653302155853, -3.717185160164956],
              [114.72278192612886, -3.717446942171728],
              [114.722414266068327, -3.716211819669635],
              [114.722394833778807, -3.716146538562526],
              [114.723274694443845, -3.715913990436072],
              [114.723653302155853, -3.717185160164956],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mursiah",
        KETERANGAN: "Sertifikat",
        No_Kav: "17",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724054199736585, -3.71570796537709],
              [114.724664738568976, -3.71688060425445],
              [114.723653302378693, -3.717185159982032],
              [114.723274694443845, -3.715913990436072],
              [114.724054199736585, -3.71570796537709],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Amir",
        KETERANGAN: "Sertifikat",
        No_Kav: "20",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721882499637758, -3.71771727172271],
              [114.720981402510233, -3.717987991376666],
              [114.720574621361678, -3.716866879337439],
              [114.7205946588617, -3.716788903393001],
              [114.721493474568035, -3.716503846980746],
              [114.721882499637758, -3.71771727172271],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.501,
        Nm_Pemilik: "Rudi Hartono",
        KETERANGAN: "Sertifikat",
        No_Kav: "19",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722414266068327, -3.716211819669635],
              [114.72278192612886, -3.717446942171728],
              [114.721882500158173, -3.717717271521144],
              [114.721493474568035, -3.716503846980746],
              [114.722414266068327, -3.716211819669635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Slamet Riyadi",
        KETERANGAN: "Sertifikat",
        No_Kav: "30",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723718216321359, -3.718144977350578],
              [114.723344047816127, -3.717348382946613],
              [114.724708340399502, -3.716945047981766],
              [114.725071796086795, -3.717719192434681],
              [114.723718216321359, -3.718144977350578],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "A. Rahman",
        KETERANGAN: "Sertifikat",
        No_Kav: "32",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725751426367736, -3.719166791280713],
              [114.724824361329865, -3.719584136328583],
              [114.724631092711107, -3.720088463989805],
              [114.724128447449644, -3.719018347545887],
              [114.724097746322201, -3.7189529857539],
              [114.725429932714277, -3.718482145682549],
              [114.725751426367736, -3.719166791280713],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Niwari",
        KETERANGAN: "Sertifikat",
        No_Kav: "31",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724097746322201, -3.7189529857539],
              [114.723725613464538, -3.718160725659151],
              [114.723718216321359, -3.718144977350578],
              [114.725071796086795, -3.717719192434681],
              [114.725429932714462, -3.718482145614388],
              [114.724097746322201, -3.7189529857539],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.15,
        Nm_Pemilik: "Mursiah",
        KETERANGAN: "Sertifikat",
        No_Kav: "25",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722506003603641, -3.718848554553637],
              [114.722127555310621, -3.718824678121063],
              [114.721882754826893, -3.718775208823677],
              [114.721149469323294, -3.718311939653817],
              [114.72111737038918, -3.718286468377884],
              [114.720993933218864, -3.718043038509099],
              [114.722061611174311, -3.717727451380005],
              [114.722394684447977, -3.718567720887751],
              [114.722395355243492, -3.71856941315695],
              [114.722506003603641, -3.718848554553637],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "M. Mukrim",
        KETERANGAN: "Sertifikat",
        No_Kav: "26",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722394684447977, -3.718567720887751],
              [114.722061611174311, -3.717727451380005],
              [114.723344047816127, -3.717348382946613],
              [114.723718216321359, -3.718144977350578],
              [114.723725613464538, -3.718160725659151],
              [114.722394684447977, -3.718567720887751],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.249,
        Nm_Pemilik: "Mangku Alam",
        KETERANGAN: "Sertifikat",
        No_Kav: "27",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723337500153164, -3.719294762813614],
              [114.723323954163448, -3.71899601119057],
              [114.723222841113923, -3.718958994646819],
              [114.723155639242535, -3.718917498781306],
              [114.722940733851473, -3.718875620225186],
              [114.722506003603641, -3.718848554553637],
              [114.722394684447977, -3.718567720887751],
              [114.723725613464538, -3.718160725659151],
              [114.724097746322201, -3.7189529857539],
              [114.724128447449644, -3.719018347545887],
              [114.723337500153164, -3.719294762813614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sandi",
        KETERANGAN: "Sertifikat",
        No_Kav: "29",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724631092711107, -3.720088463989805],
              [114.724839422578967, -3.720315892600364],
              [114.724589967413735, -3.720712302326036],
              [114.724397839517735, -3.720605901980486],
              [114.724077712773806, -3.720227595323084],
              [114.723634340465154, -3.719955349339053],
              [114.723409392722274, -3.719759164694998],
              [114.72335170996142, -3.719608024387824],
              [114.723337500007347, -3.719294763431559],
              [114.724128447449644, -3.719018347545887],
              [114.724631092711107, -3.720088463989805],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.5,
        Nm_Pemilik: "Desita HK",
        KETERANGAN: "Sertifikat",
        No_Kav: "33",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726545768737211, -3.718440524005605],
              [114.72642719667968, -3.718737797671789],
              [114.726086611571674, -3.719370214621937],
              [114.725914731882852, -3.719337380838828],
              [114.725641749786107, -3.718755023204362],
              [114.726545768737211, -3.718440524005605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.513,
        Nm_Pemilik: "Desita HK",
        KETERANGAN: "Sertifikat",
        No_Kav: "36",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725827032018856, -3.716587894887104],
              [114.726656494593243, -3.716315999293332],
              [114.726505485192561, -3.7170547644204],
              [114.726488453243491, -3.717373975652381],
              [114.726511206536955, -3.717458268090498],
              [114.726487796834149, -3.717428487553518],
              [114.725827032018856, -3.716587894887104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Desita HK",
        KETERANGAN: "Sertifikat",
        No_Kav: "34",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726487796834149, -3.717428487553518],
              [114.726511206536955, -3.717458268090498],
              [114.726613424886111, -3.717719765133412],
              [114.726647551780189, -3.718064719633567],
              [114.726569511850343, -3.718411109132002],
              [114.726545768737211, -3.718440524005605],
              [114.725641749786107, -3.718755023204362],
              [114.725213780366943, -3.717842032532153],
              [114.726487796834149, -3.717428487553518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Desita HK",
        KETERANGAN: "Sertifikat",
        No_Kav: "35",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724785999850852, -3.71692943480893],
              [114.725827029322375, -3.716587894880129],
              [114.726487796834149, -3.717428487553518],
              [114.725213780178649, -3.717842032474468],
              [114.724785999850852, -3.71692943480893],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.0,
        Nm_Pemilik: "Sugiyono",
        KETERANGAN: "Sertifikat",
        No_Kav: "50",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729471959369832, -3.718097192973212],
              [114.729514653404323, -3.718319459726034],
              [114.729558076954106, -3.718638074568452],
              [114.729017354041702, -3.71913352785499],
              [114.728797162760188, -3.719434289042806],
              [114.728656101533218, -3.719640343408686],
              [114.72865494512267, -3.718236915892592],
              [114.729471959369832, -3.718097192973212],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Isroniyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "48",
        block: "Blok 3",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727945709521421, -3.720248959997544],
              [114.727725800713031, -3.72036697139614],
              [114.727273303496276, -3.720191731171002],
              [114.727310931752285, -3.718728391452121],
              [114.72730446980178, -3.718584591221374],
              [114.727309353105042, -3.718467032402123],
              [114.727598406919668, -3.718417600047285],
              [114.727942603704889, -3.718358737308946],
              [114.727945709521421, -3.720248959997544],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Nuryati",
        KETERANGAN: "Sertifikat",
        No_Kav: "49",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728656101509173, -3.719640343436037],
              [114.728546784753391, -3.719811823197284],
              [114.728299208708492, -3.720059258709636],
              [114.727945709521421, -3.720248959997544],
              [114.727942603706211, -3.718358737308949],
              [114.727961031278966, -3.718355585915506],
              [114.728528985407365, -3.718258457003023],
              [114.728535650822906, -3.718257317112408],
              [114.72865494512267, -3.718236915892592],
              [114.728656101509173, -3.719640343436037],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Ardi Jaelani",
        KETERANGAN: "Sertifikat",
        No_Kav: "52",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730557608145801, -3.716175797046028],
              [114.730358248413339, -3.716350236240804],
              [114.73022824841135, -3.716550235927711],
              [114.729858247427856, -3.717190236581179],
              [114.729995763241803, -3.717267414476222],
              [114.729449188116035, -3.717510552777746],
              [114.729395391955237, -3.717254826542316],
              [114.729386704689276, -3.717213531029678],
              [114.729388177044896, -3.716638113568548],
              [114.729509387183867, -3.715842369792181],
              [114.72956434723568, -3.71556994863669],
              [114.730557608145801, -3.716175797046028],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Kasmuji",
        KETERANGAN: "Sertifikat",
        No_Kav: "47",
        block: "Blok 3",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729299974730381, -3.716832130340792],
              [114.729303750753374, -3.717214179606431],
              [114.72939293313955, -3.717658530885452],
              [114.729471959369832, -3.718097192973212],
              [114.72865494512267, -3.718236915892592],
              [114.728528985407365, -3.718258457003023],
              [114.72832445655385, -3.716981035075814],
              [114.729299974730381, -3.716832130340792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.459,
        Nm_Pemilik: "Koiri",
        KETERANGAN: "Sertifikat",
        No_Kav: "45",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727607835466472, -3.718415984986416],
              [114.727309353105042, -3.718467032402123],
              [114.727311535937829, -3.718414483616012],
              [114.727406868612732, -3.718209743295508],
              [114.727419333781441, -3.717982939861962],
              [114.727073316264253, -3.717592843087098],
              [114.727030808926983, -3.717485284743194],
              [114.726997802718614, -3.717380138710352],
              [114.727006736777767, -3.717182172074402],
              [114.727395478575332, -3.717122834686818],
              [114.727607835466472, -3.718415984986416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Miskari",
        KETERANGAN: "Sertifikat",
        No_Kav: "46",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728528985407365, -3.718258457003023],
              [114.727961031278966, -3.718355585915506],
              [114.727607838449757, -3.71841598499413],
              [114.727395478575332, -3.717122834686818],
              [114.72832445655385, -3.716981035075814],
              [114.728528985407365, -3.718258457003023],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Darsono",
        KETERANGAN: "Sertifikat",
        No_Kav: "118",
        block: "Blok 9",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71609831838289, -3.709937285454609],
              [114.71627808987607, -3.710445183199537],
              [114.71450941373719, -3.711264127004514],
              [114.714154018006212, -3.710683009756317],
              [114.71609831838289, -3.709937285454609],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.288,
        Nm_Pemilik: "Mangku Alam",
        KETERANGAN: "Sertifikat",
        No_Kav: "28",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722488578090392, -3.718860565791239],
              [114.722876557198532, -3.718906169632115],
              [114.722907641849986, -3.719070257832622],
              [114.723023422349357, -3.719155438996348],
              [114.7230129212886, -3.719340999411803],
              [114.723001036037004, -3.719507853922319],
              [114.722629031583821, -3.719375971157096],
              [114.722577805542286, -3.71924779735651],
              [114.722503665891253, -3.71911956450806],
              [114.722488578090392, -3.718860565791239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.311,
        Nm_Pemilik: "Mursiah",
        KETERANGAN: "Sertifikat",
        No_Kav: "24",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722031749269178, -3.718824497721097],
              [114.722122389352648, -3.718838381357018],
              [114.722437394882988, -3.718857089671087],
              [114.722435528001725, -3.718903349637333],
              [114.722401439999658, -3.718940256022968],
              [114.722389199212103, -3.719294520152354],
              [114.722377108170178, -3.719590446790894],
              [114.722027978262147, -3.719432994803364],
              [114.722006108800443, -3.718932016731516],
              [114.722031749269178, -3.718824497721097],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.905,
        Nm_Pemilik: "Sugianto",
        KETERANGAN: "Sertifikat",
        No_Kav: "37",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726555851359834, -3.715686540317593],
              [114.726657266520277, -3.716288744421283],
              [114.725809605406653, -3.716559264002162],
              [114.725455920919558, -3.715721754096731],
              [114.726555851359834, -3.715686540317593],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.025,
        Nm_Pemilik: "Koiri",
        KETERANGAN: "Sertifikat",
        No_Kav: "43",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72784844390921, -3.716983876758907],
              [114.72706078306166, -3.717118264895201],
              [114.727019733517267, -3.716910764204475],
              [114.727195456109143, -3.716659120403276],
              [114.727311507023785, -3.716350160528213],
              [114.727443650041749, -3.716219259833001],
              [114.727696532705295, -3.716090432041088],
              [114.727731658505931, -3.715976789290081],
              [114.727564927072549, -3.715790885437436],
              [114.727148084325947, -3.716012026058318],
              [114.72685395133017, -3.715925528386448],
              [114.726774299063138, -3.715653033952294],
              [114.727734447138033, -3.715600685569429],
              [114.72784844390921, -3.716983876758907],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.539,
        Nm_Pemilik: "Supeno",
        KETERANGAN: "Sertifikat",
        No_Kav: "108",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723580726135395, -3.708145963665148],
              [114.723583118340173, -3.708148904114528],
              [114.72358720922999, -3.708146287886451],
              [114.7241497621322, -3.708833877554679],
              [114.723592291894178, -3.708847780085336],
              [114.723269824811609, -3.708599640717015],
              [114.722923428571164, -3.708683757238091],
              [114.722833969435527, -3.708563824485121],
              [114.723580726135395, -3.708145963665148],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.224,
        Nm_Pemilik: "Supeno",
        KETERANGAN: "Sertifikat",
        No_Kav: "106",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725244918535381, -3.708260545400617],
              [114.725495044376885, -3.708634442232519],
              [114.725473701973172, -3.708655565886495],
              [114.724985273957898, -3.708627255935894],
              [114.724550429262891, -3.708703416516372],
              [114.724369439284033, -3.708784096589095],
              [114.724364481019606, -3.708785239426668],
              [114.725244918535381, -3.708260545400617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Prima Hepi",
        KETERANGAN: "Sertifikat",
        No_Kav: "107",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72358720922999, -3.708146287886451],
              [114.724689680004346, -3.7074412281494],
              [114.725244918266554, -3.708260545054221],
              [114.724364481019606, -3.708785239426668],
              [114.724153910504668, -3.708833774098706],
              [114.7241497621322, -3.708833877554679],
              [114.72358720922999, -3.708146287886451],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sarjono",
        KETERANGAN: "Sertifikat",
        No_Kav: "110",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722047880859137, -3.707544048975455],
              [114.721925916469473, -3.707045695195299],
              [114.724083499563037, -3.706401384017408],
              [114.724272722272403, -3.706737307356482],
              [114.724263751384115, -3.706742203800165],
              [114.723064212632494, -3.707503647547921],
              [114.72277921016007, -3.707177939457747],
              [114.722047880859137, -3.707544048975455],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sarjono",
        KETERANGAN: "Sertifikat",
        No_Kav: "109",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722779209931048, -3.70717794680931],
              [114.723064198962419, -3.707503658904205],
              [114.723580726135395, -3.708145963665148],
              [114.722833969435527, -3.708563824485121],
              [114.72271620657493, -3.708390621047165],
              [114.722239714455213, -3.708311031130385],
              [114.722047881758087, -3.707544050407065],
              [114.722779209931048, -3.70717794680931],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.737,
        Nm_Pemilik: "Supeno",
        KETERANGAN: "Sertifikat",
        No_Kav: "96",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724965014646912, -3.709640757537688],
              [114.724839474767307, -3.709590599183811],
              [114.724655359887521, -3.709593548787053],
              [114.724131000440067, -3.709325037873695],
              [114.724032514435009, -3.709174079801258],
              [114.724029534601897, -3.709006243013368],
              [114.724135514285905, -3.708893488982367],
              [114.724381129056383, -3.708839321957896],
              [114.724558609035512, -3.708764428403794],
              [114.724888215754433, -3.708704681221869],
              [114.724965014646912, -3.709640757537688],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sugianto bin Warno",
        KETERANGAN: "Sertifikat",
        No_Kav: "115",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720686704459354, -3.709486628081625],
              [114.72115277050122, -3.710915921010094],
              [114.719202059874291, -3.709926850855978],
              [114.719199187387289, -3.709851505336546],
              [114.720686704459354, -3.709486628081625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sumijan",
        KETERANGAN: "Sertifikat",
        No_Kav: "114",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720411538297412, -3.708642771124728],
              [114.720686704459354, -3.709486628081625],
              [114.719199187397777, -3.709851501244913],
              [114.719167706595385, -3.708988226337318],
              [114.719987632542058, -3.708760504722503],
              [114.720411538297412, -3.708642771124728],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Abdul Majid",
        KETERANGAN: "Sertifikat",
        No_Kav: "111",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721513341533651, -3.707519640340101],
              [114.72160104360799, -3.707815972138119],
              [114.720247327358564, -3.70813918250475],
              [114.720411538297412, -3.708642771124728],
              [114.719987632542058, -3.708760504722503],
              [114.719708500585668, -3.70775884459791],
              [114.720261960113788, -3.707505401802664],
              [114.720971322035709, -3.707350414890154],
              [114.721513341533651, -3.707519640340101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.63,
        Nm_Pemilik: "Abdul Majid",
        KETERANGAN: "Sporadik",
        No_Kav: "113",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718598894205641, -3.708751108744558],
              [114.717788104414979, -3.708986993095039],
              [114.717775321648801, -3.708289605679148],
              [114.718405557299661, -3.70808813768803],
              [114.718598894205641, -3.708751108744558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Saifullah",
        KETERANGAN: "Sertifikat",
        No_Kav: "112",
        block: "Blok 8",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719987632542058, -3.708760504722503],
              [114.719167706595385, -3.708988226337318],
              [114.719142376622329, -3.708597936724577],
              [114.718598897065803, -3.708751108751962],
              [114.718405557299661, -3.70808813768803],
              [114.719015677116289, -3.707893099533068],
              [114.719511456904755, -3.707850120026133],
              [114.719708500585668, -3.70775884459791],
              [114.719987632542058, -3.708760504722503],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.4,
        Nm_Pemilik: "Titik Purwaraharti",
        KETERANGAN: "Sertifikat",
        No_Kav: "59",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731131749037331, -3.714750414139043],
              [114.730144365294265, -3.713972509624911],
              [114.730503722341012, -3.713245836132903],
              [114.731668750860933, -3.71399784781407],
              [114.731131749037331, -3.714750414139043],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Iman Khoiri",
        KETERANGAN: "Sertifikat",
        No_Kav: "58",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731962270298936, -3.713586511940389],
              [114.732045046185348, -3.713861720107276],
              [114.732089179534654, -3.71406367838037],
              [114.732281164129034, -3.714980728958251],
              [114.732345890564346, -3.715218962984129],
              [114.732355079859701, -3.715735627365167],
              [114.731131752272205, -3.714750413969306],
              [114.731962270298936, -3.713586511940389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "M. Langgeng",
        KETERANGAN: "Sertifikat",
        No_Kav: "63",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731974736775115, -3.709034954551357],
              [114.731989844212961, -3.709259461169935],
              [114.732091158090469, -3.709665394745895],
              [114.730093902180698, -3.70966703927594],
              [114.730091690651008, -3.709228745153004],
              [114.730089828367994, -3.709031471822465],
              [114.731974736775115, -3.709034954551357],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Ansari",
        KETERANGAN: "Sertifikat",
        No_Kav: "64",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732189028473087, -3.708423789264106],
              [114.732147038413828, -3.708684232084855],
              [114.731966761059354, -3.708916428406267],
              [114.731974736775115, -3.709034954551357],
              [114.730089828367994, -3.709031471822465],
              [114.730087314923253, -3.708765224692656],
              [114.730088146254914, -3.708422406925205],
              [114.732189028473087, -3.708423789264106],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Rahmatullah",
        KETERANGAN: "Sertifikat",
        No_Kav: "65",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732182623246246, -3.708216688623229],
              [114.732205867355006, -3.708319347455928],
              [114.732189028473087, -3.708423789264106],
              [114.730088146254914, -3.708422406925205],
              [114.730088251864544, -3.708378854090975],
              [114.730428623844176, -3.708144045624059],
              [114.730955495846686, -3.707739048480057],
              [114.731324277962145, -3.707292058443987],
              [114.732182623246246, -3.708216688623229],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Supriyatin",
        KETERANGAN: "Sertifikat",
        No_Kav: "66",
        block: "Blok 5",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732866685295903, -3.707068549207816],
              [114.732781829543683, -3.707155521535869],
              [114.732501922733903, -3.707311245695398],
              [114.732365936718878, -3.707399146388032],
              [114.732235940144378, -3.707487062451267],
              [114.732089491022435, -3.707763471227124],
              [114.732148613520565, -3.708066482891753],
              [114.732182623246246, -3.708216688623229],
              [114.731324277962145, -3.707292058443987],
              [114.732140026352113, -3.706303309092432],
              [114.732866685295903, -3.707068549207816],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suhardi",
        KETERANGAN: "Sertifikat",
        No_Kav: "72",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731782394225391, -3.706592324141543],
              [114.731139588822586, -3.70736656286047],
              [114.730390607688278, -3.706466426461202],
              [114.73073982307794, -3.705977220155953],
              [114.73097585509511, -3.70558224233409],
              [114.731782394225391, -3.706592324141543],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mahmudin",
        KETERANGAN: "Sertifikat",
        No_Kav: "71",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73236683247012, -3.70588838300876],
              [114.731782394225391, -3.706592324141543],
              [114.73097585509511, -3.70558224233409],
              [114.731449646550885, -3.704789385656063],
              [114.731470529919065, -3.704776971412653],
              [114.73236683247012, -3.70588838300876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sugianto",
        KETERANGAN: "Sertifikat",
        No_Kav: "70",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733104027274095, -3.705000449584741],
              [114.73236683247012, -3.70588838300876],
              [114.731470529919065, -3.704776971412653],
              [114.731751404970879, -3.704610003205457],
              [114.732371514957066, -3.704604826131169],
              [114.732621586099711, -3.704411571772416],
              [114.733104027274095, -3.705000449584741],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.865,
        Nm_Pemilik: "Sairani",
        KETERANGAN: "Sertifikat",
        No_Kav: "69",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733351103760612, -3.704702852051818],
              [114.733104027440547, -3.705000449418406],
              [114.732621586105978, -3.70441157176824],
              [114.732624770277653, -3.704409111038966],
              [114.732639787072031, -3.704243509265831],
              [114.733004588325528, -3.703479041006368],
              [114.733196639082067, -3.703496462463455],
              [114.733280063533314, -3.703821696541413],
              [114.733351103760612, -3.704702852051818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suriahadi",
        KETERANGAN: "Sertifikat",
        No_Kav: "67",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733245544193025, -3.705498382980744],
              [114.73323737534308, -3.705528741685428],
              [114.73330168781132, -3.705752240297403],
              [114.733282038764614, -3.705994058863079],
              [114.733263148145696, -3.706210490623367],
              [114.733267332024056, -3.706586801656738],
              [114.733096100683127, -3.706833413007469],
              [114.732866685295903, -3.707068549207816],
              [114.732140026352113, -3.706303309092432],
              [114.733027981993075, -3.705194698613319],
              [114.733245544193025, -3.705498382980744],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.127,
        Nm_Pemilik: "Sairani",
        KETERANGAN: "Sertifikat",
        No_Kav: "68",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733350688055808, -3.704791799528482],
              [114.733328606053774, -3.705025588615131],
              [114.733313722857616, -3.705245005680581],
              [114.733245544411801, -3.705498382111078],
              [114.733027981993075, -3.705194698613319],
              [114.733350688055808, -3.704791799528482],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Rini Dwi Andayanti",
        KETERANGAN: "Sertifikat",
        No_Kav: "88",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726959992787442, -3.7122663831512],
              [114.726298369256583, -3.712673111852865],
              [114.725985147535368, -3.712537142065468],
              [114.725985822492959, -3.712273432468443],
              [114.72564340646683, -3.712087064115921],
              [114.725580035744713, -3.712113398101418],
              [114.725290101002045, -3.71203315600523],
              [114.725221423496734, -3.711897762502528],
              [114.726473555450227, -3.711270742882024],
              [114.726959992787442, -3.7122663831512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Saifullah",
        KETERANGAN: "Sertifikat",
        No_Kav: "89",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725221423496734, -3.711897762502528],
              [114.72518512532119, -3.711826202862399],
              [114.725205174138026, -3.711607179092118],
              [114.725265674119854, -3.711472371450857],
              [114.725296488655047, -3.711184393690532],
              [114.725122649024058, -3.710946246989232],
              [114.725012640360418, -3.710752129067395],
              [114.725709773958613, -3.710413398308216],
              [114.726079732322418, -3.710620218621303],
              [114.726169755081244, -3.710548249432926],
              [114.726178140779794, -3.710666086891421],
              [114.726473555450227, -3.711270742882024],
              [114.725221423496734, -3.711897762502528],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.297,
        Nm_Pemilik: "Abdul Majid",
        KETERANGAN: "Sporadik",
        No_Kav: "90",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725012640360418, -3.710752129067395],
              [114.724996891780918, -3.710724339602716],
              [114.725011651729886, -3.710181763237537],
              [114.725510241644983, -3.710233247771242],
              [114.725670602240896, -3.710390800271143],
              [114.725709771447654, -3.710413397670696],
              [114.725012640360418, -3.710752129067395],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Tugiman",
        KETERANGAN: "Sertifikat",
        No_Kav: "6",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715171707131148, -3.716966850249364],
              [114.715395219284915, -3.716731890392682],
              [114.715433416115147, -3.71649730321446],
              [114.715380430987267, -3.716217969660936],
              [114.715152853005108, -3.715959762932333],
              [114.715161382180256, -3.715929884352569],
              [114.716851898826235, -3.716905769561431],
              [114.716075954125628, -3.717561274413805],
              [114.715171707131148, -3.716966850249364],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sunyoto",
        KETERANGAN: "Sertifikat",
        No_Kav: "87",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72672226648703, -3.711690488000104],
              [114.726221250214238, -3.710677345208481],
              [114.726197375419616, -3.710533460442397],
              [114.726405358578347, -3.710349042317546],
              [114.726525732502495, -3.710256875498979],
              [114.726716336624037, -3.710157052814727],
              [114.726845791319263, -3.710174628461226],
              [114.727059145605836, -3.710330353200106],
              [114.727264085693818, -3.710725870917381],
              [114.727341936783759, -3.710788768460684],
              [114.727432169523837, -3.710890883365006],
              [114.727516201443876, -3.710977308106578],
              [114.727522177861005, -3.711080773021958],
              [114.727524933621567, -3.711223415041544],
              [114.727524825685379, -3.711265609699243],
              [114.72672226648703, -3.711690488000104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mat Kailani",
        KETERANGAN: "Sertifikat",
        No_Kav: "86",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727543069211706, -3.713167133536679],
              [114.727470114838695, -3.712959652777668],
              [114.727408988536567, -3.712849656569276],
              [114.727348141895135, -3.712615597563294],
              [114.727018845829306, -3.712250672480535],
              [114.726753779248114, -3.711754212302966],
              [114.72672226648703, -3.711690488000104],
              [114.727524825685379, -3.711265609699243],
              [114.727524657083762, -3.711331566071566],
              [114.727525864615515, -3.711469501845997],
              [114.727470988041688, -3.711574377132084],
              [114.727535976574345, -3.711786146089915],
              [114.72763693057685, -3.711966659551944],
              [114.727861162884906, -3.712139654102383],
              [114.727865458789708, -3.712290137212924],
              [114.727916746008049, -3.712368640424468],
              [114.727977350531603, -3.71246440927223],
              [114.728012887137979, -3.712600866270678],
              [114.728054849058594, -3.712665238670537],
              [114.728058912448049, -3.712906631392346],
              [114.728111783977042, -3.712975734210252],
              [114.728115826396561, -3.713064886156317],
              [114.727543069211706, -3.713167133536679],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sunyoto",
        KETERANGAN: "Sertifikat",
        No_Kav: "77",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729160937944357, -3.714038919014544],
              [114.727883834863022, -3.714136264023431],
              [114.727543069211706, -3.713167133536679],
              [114.728115826396561, -3.713064886156317],
              [114.728118380195312, -3.713121190868839],
              [114.728700875453868, -3.713491519857754],
              [114.728712872600781, -3.713327552361066],
              [114.728724822141615, -3.713182194492493],
              [114.728693812243762, -3.713117102173231],
              [114.728958638159384, -3.713093496279024],
              [114.729160937944357, -3.714038919014544],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "T Wiharsa",
        KETERANGAN: "Sertifikat",
        No_Kav: "76",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73050764215445, -3.712955420179142],
              [114.730514897092348, -3.713079698595569],
              [114.730063613735055, -3.713970112908286],
              [114.729160937951889, -3.714038919014564],
              [114.728958638159384, -3.713093496279024],
              [114.729173354887507, -3.713074356924947],
              [114.72958230112269, -3.713037904184807],
              [114.73050764215445, -3.712955420179142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.868,
        Nm_Pemilik: "Armawi",
        KETERANGAN: "Sertifikat",
        No_Kav: "139",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70162426917851, -3.726802497443358],
              [114.701376071533886, -3.726197798225698],
              [114.702417755639246, -3.725761096507598],
              [114.702677462350806, -3.726269351689782],
              [114.70162426917851, -3.726802497443358],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.786,
        Nm_Pemilik: "Bustanil Arifin",
        KETERANGAN: "Sertifikat",
        No_Kav: "134",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.701867440812862, -3.728959410448162],
              [114.701860205674194, -3.728963334111702],
              [114.70156251999444, -3.728361312756649],
              [114.702365114802504, -3.727950392336698],
              [114.70241507211567, -3.727924814651197],
              [114.702710173986233, -3.728511279870282],
              [114.702703953688442, -3.728514622748484],
              [114.701869425505464, -3.728963109488645],
              [114.701867440812862, -3.728959410448162],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.786,
        Nm_Pemilik: "Prayetno",
        KETERANGAN: "Sertifikat",
        No_Kav: "133",
        block: "Blok 10",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70241507211567, -3.727924814651197],
              [114.703293542796573, -3.727475044836438],
              [114.703583275149896, -3.728042061771032],
              [114.702710173986233, -3.728511279870282],
              [114.70241507211567, -3.727924814651197],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.781,
        Nm_Pemilik: "Sugianto",
        KETERANGAN: "Sertifikat",
        No_Kav: "132",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703010699504105, -3.729117350283814],
              [114.702851824061199, -3.729220627130809],
              [114.702181714432697, -3.72954514877083],
              [114.701869425505464, -3.728963109488645],
              [114.702699287924318, -3.728517130515104],
              [114.703010699504105, -3.729117350283814],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.781,
        Nm_Pemilik: "Armawi",
        KETERANGAN: "Sertifikat",
        No_Kav: "131",
        block: "Blok 10",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703583275149896, -3.728042061771032],
              [114.703852047736234, -3.728568059418174],
              [114.703859818594069, -3.72856537996023],
              [114.703613189908992, -3.728725701274979],
              [114.703015909926194, -3.729113963252172],
              [114.703010699751374, -3.729117350123073],
              [114.702699288101101, -3.728517130338451],
              [114.702710173986233, -3.728511279870282],
              [114.703583275149896, -3.728042061771032],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.737,
        Nm_Pemilik: "Mujiono",
        KETERANGAN: "Sertifikat",
        No_Kav: "130",
        block: "Blok 10",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703971805514115, -3.729367138812802],
              [114.703217397273306, -3.729746140028115],
              [114.70285362552066, -3.729219754716188],
              [114.702851824061199, -3.729220627130809],
              [114.703015909926194, -3.729113963252172],
              [114.703613189908992, -3.728725701274979],
              [114.703971805514115, -3.729367138812802],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.737,
        Nm_Pemilik: "Armawi",
        KETERANGAN: "Sertifikat",
        No_Kav: "129",
        block: "Blok 10",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703859818594069, -3.72856537996023],
              [114.704365675380885, -3.728390956013759],
              [114.704689742615145, -3.729006461449373],
              [114.703971807608326, -3.729367138292682],
              [114.703613189908992, -3.728725701274979],
              [114.703859818594069, -3.72856537996023],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.115,
        Nm_Pemilik: "Sunarto",
        KETERANGAN: "Sertifikat",
        No_Kav: "100",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72997474909937, -3.708131471963527],
              [114.729977720505175, -3.708145068690405],
              [114.72997656605385, -3.708597405979299],
              [114.72987576020499, -3.708551226907413],
              [114.729598420097034, -3.708324615986399],
              [114.72997474909937, -3.708131471963527],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sugiyono",
        KETERANGAN: "Sertifikat",
        No_Kav: "101",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728244002329888, -3.706969087609239],
              [114.728506391033633, -3.707065040142238],
              [114.729265246235983, -3.707180953771987],
              [114.729474464595853, -3.70726038717267],
              [114.729619619077752, -3.707368875757526],
              [114.729740273230561, -3.707524583494566],
              [114.729911784245743, -3.707843338699887],
              [114.72997474909937, -3.708131471963527],
              [114.729598420097034, -3.708324615986399],
              [114.72903906277179, -3.707867572140144],
              [114.72873464601355, -3.707683012781628],
              [114.728214208207532, -3.707626621248768],
              [114.728244002329888, -3.706969087609239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Rini Dwi Andayanti",
        KETERANGAN: "Sertifikat",
        No_Kav: "102",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726683166470892, -3.706965898010039],
              [114.72675300469453, -3.707028124745162],
              [114.726995621558316, -3.707091691523881],
              [114.727471390822728, -3.706881207865631],
              [114.727983327256354, -3.706873761212952],
              [114.728244002329888, -3.706969087609239],
              [114.728214208207532, -3.707626621248768],
              [114.728010737813932, -3.707604574331954],
              [114.72661453565027, -3.707852734879708],
              [114.726628597416322, -3.707295519284436],
              [114.726628758235961, -3.707289146545808],
              [114.726634917309227, -3.707045082396208],
              [114.726637932630254, -3.70692559433671],
              [114.726683166470892, -3.706965898010039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mijayadi",
        KETERANGAN: "Sertifikat",
        No_Kav: "40",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727734450034148, -3.715600684786869],
              [114.727525353041997, -3.715610341665518],
              [114.727486289713923, -3.714233397621387],
              [114.72838824755155, -3.71420023655912],
              [114.728408248747797, -3.715550235881832],
              [114.727734450034148, -3.715600684786869],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Vickha Dian Hapsari",
        KETERANGAN: "Sertifikat",
        No_Kav: "61",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732122697939431, -3.710274862467273],
              [114.732118921562574, -3.710314787545519],
              [114.73206622983993, -3.710621522782406],
              [114.731994035137149, -3.710898134714231],
              [114.730216793959116, -3.710924828194137],
              [114.730215237905696, -3.710915566850839],
              [114.730177010544253, -3.710647414815778],
              [114.730127622710555, -3.710395987375968],
              [114.730117894026307, -3.71026452360896],
              [114.732122697939431, -3.710274862467273],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Ragil Rizki Gemilang",
        KETERANGAN: "Sertifikat",
        No_Kav: "62",
        block: "Blok 5",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732091158090469, -3.709665394745895],
              [114.732098541093563, -3.709694976174358],
              [114.732141491849077, -3.710076168274568],
              [114.732122697950743, -3.710274862444689],
              [114.730117894026307, -3.71026452360896],
              [114.730095381882947, -3.70996031735175],
              [114.730093902180698, -3.70966703927594],
              [114.732091158090469, -3.709665394745895],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "Husin Naparin",
        KETERANGAN: "Sporadik",
        No_Kav: "119",
        block: "Blok 9",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714331659026413, -3.707344770144089],
              [114.713908248482511, -3.708160222731232],
              [114.713278245605338, -3.708040224839278],
              [114.713158247611375, -3.708050229379806],
              [114.712848248903754, -3.7080902325612],
              [114.712678246482909, -3.708060227787262],
              [114.712608244427201, -3.707990223196204],
              [114.712468245483123, -3.707930226377831],
              [114.712368249172485, -3.707940223756206],
              [114.712318247482088, -3.707890232053671],
              [114.712238244807708, -3.707910225397611],
              [114.712198242048075, -3.70796023279828],
              [114.712218224523752, -3.708020228796527],
              [114.712158247425606, -3.708120231692043],
              [114.712048272305239, -3.708030230643102],
              [114.71198825184878, -3.707920228990299],
              [114.711878269318163, -3.707830204949021],
              [114.711638250963063, -3.70769023051685],
              [114.71189542771441, -3.707348611053848],
              [114.714331659026413, -3.707344770144089],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "Siti Rahmah",
        KETERANGAN: "Sporadik",
        No_Kav: "120",
        block: "Blok 9",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712358248447501, -3.706870236429263],
              [114.712541744818267, -3.706672446870361],
              [114.712570670989564, -3.706641267519611],
              [114.714694079269876, -3.706646776761546],
              [114.714677874779895, -3.706677986185444],
              [114.714504675192458, -3.707011555073408],
              [114.714331659117661, -3.70734477005288],
              [114.711895429396051, -3.707348609373203],
              [114.712308248737543, -3.706800235722261],
              [114.712358248447501, -3.706870236429263],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "Husin Naparin",
        KETERANGAN: "Sporadik",
        No_Kav: "121",
        block: "Blok 9",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712570670989564, -3.706641267519611],
              [114.713128267576934, -3.706040236539727],
              [114.713317793713145, -3.705811208069289],
              [114.715125639157392, -3.705815539599402],
              [114.714694081257505, -3.706646773750081],
              [114.712570670989564, -3.706641267519611],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.002,
        Nm_Pemilik: "Siti Rahmah",
        KETERANGAN: "Sporadik",
        No_Kav: "122",
        block: "Blok 9",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713317793736664, -3.705811208022324],
              [114.713368249683924, -3.705750235565147],
              [114.713318247914373, -3.70558024047705],
              [114.713627097585501, -3.70496569355866],
              [114.715710494817714, -3.704985971197722],
              [114.71539824353701, -3.705310233403537],
              [114.715258245308235, -3.705560234378307],
              [114.715125698891683, -3.70581550969346],
              [114.713317793736664, -3.705811208022324],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.976,
        Nm_Pemilik: "Maria Ulfah",
        KETERANGAN: "Sporadik",
        No_Kav: "1",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719356096904491, -3.714559624877504],
              [114.720427126983338, -3.714533379449228],
              [114.720394066444968, -3.71547528345101],
              [114.720196723770997, -3.715470190010783],
              [114.720177127283051, -3.715826831903875],
              [114.720159548124769, -3.715971258549201],
              [114.720010177150968, -3.716221536776201],
              [114.719951070115556, -3.716263366910019],
              [114.719738450306181, -3.716281023508778],
              [114.719721994604598, -3.716166984483729],
              [114.719674781256444, -3.71617620959201],
              [114.719601726475602, -3.716255205590492],
              [114.719319924157915, -3.716162103200191],
              [114.719356096904491, -3.714559624877504],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.1,
        Nm_Pemilik: "Sahril",
        KETERANGAN: "Sporadik",
        No_Kav: "2",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718205384916899, -3.715407312138564],
              [114.718229547790145, -3.714611211418442],
              [114.71935609667004, -3.714559624876895],
              [114.719319924148138, -3.716162103195249],
              [114.719195125198397, -3.716120871863462],
              [114.718937362771655, -3.716016516908093],
              [114.718741910547564, -3.715977781486967],
              [114.718505971838866, -3.716059834986289],
              [114.718347106722717, -3.716200140118687],
              [114.718180659854326, -3.716421893396563],
              [114.718205384916899, -3.715407312138564],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sanom",
        KETERANGAN: "Sertifikat",
        No_Kav: "78",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72958230112269, -3.713037904184807],
              [114.729173354887507, -3.713074356924947],
              [114.728693812243762, -3.713117102173231],
              [114.72864881646845, -3.713022652716434],
              [114.728584688565221, -3.712745667033391],
              [114.728679759520361, -3.71232800605845],
              [114.728291036480172, -3.712116022847842],
              [114.728175332639907, -3.711969047065906],
              [114.728102692403823, -3.711876784527991],
              [114.729350779109097, -3.711774757088241],
              [114.72958230112269, -3.713037904184807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Muhlison",
        KETERANGAN: "Sertifikat",
        No_Kav: "75",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730274759016268, -3.711699157675636],
              [114.73041013368811, -3.712217200966156],
              [114.73045604734186, -3.71239606647236],
              [114.730482598302572, -3.712526415536606],
              [114.73050764215445, -3.712955420179142],
              [114.72958230112269, -3.713037904184807],
              [114.7293507804537, -3.711774757091708],
              [114.730274759016268, -3.711699157675636],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.385,
        Nm_Pemilik: "Sunarto",
        KETERANGAN: "Sertifikat",
        No_Kav: "73",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729992928945293, -3.708720305978038],
              [114.729986685916046, -3.708988343384355],
              [114.729986022321043, -3.709248301822461],
              [114.72895976014081, -3.709245657226074],
              [114.728955424093485, -3.709245146917923],
              [114.728824428484756, -3.709194695186932],
              [114.728828740350551, -3.707829534261416],
              [114.728996950609414, -3.70793487712343],
              [114.729826607968008, -3.708623826291755],
              [114.730006695697824, -3.708709827218603],
              [114.729992928945293, -3.708720305978038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Samiyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "82",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728824428388577, -3.709194695160568],
              [114.728563701510808, -3.709094279130082],
              [114.728035233804746, -3.70908435334907],
              [114.727946430016061, -3.709140311219952],
              [114.727949553508594, -3.707689332606326],
              [114.728030966914034, -3.707675776960478],
              [114.728694418495394, -3.707745413138799],
              [114.728828738661718, -3.707829533861946],
              [114.728824428388577, -3.709194695160568],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mualam",
        KETERANGAN: "Sertifikat",
        No_Kav: "83",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727946429897727, -3.709140311259251],
              [114.727574447915927, -3.70937470984411],
              [114.727376490788004, -3.709106103871721],
              [114.727231363791446, -3.708816679670875],
              [114.727127181926789, -3.708592079983227],
              [114.727117190440225, -3.70807690392322],
              [114.726811677372325, -3.708069264138624],
              [114.726587312163076, -3.707919625872388],
              [114.726877320552006, -3.707867862438379],
              [114.727949553465976, -3.707689332606215],
              [114.727946429897727, -3.709140311259251],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.193,
        Nm_Pemilik: "Juriyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "99",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726811676759667, -3.708069264444389],
              [114.72690946205087, -3.708642301498847],
              [114.726908605321967, -3.708977491816181],
              [114.726807188336124, -3.709137823488534],
              [114.725586391614087, -3.708637932622367],
              [114.725927373928442, -3.708299341181825],
              [114.726347281148819, -3.707923390763635],
              [114.726587311826037, -3.707919625871519],
              [114.726811676759667, -3.708069264444389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Prayetno",
        KETERANGAN: "Sertifikat",
        No_Kav: "13",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720476098073007, -3.714526225122709],
              [114.721746299396756, -3.714504495597382],
              [114.721703496594515, -3.715479184839519],
              [114.720440316544554, -3.715478828998196],
              [114.720476098073007, -3.714526225122709],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.45,
        Nm_Pemilik: "A. Rahman",
        KETERANGAN: "Sporadik",
        No_Kav: "4",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718006420992381, -3.7171100143296],
              [114.717217652217172, -3.718938320334934],
              [114.717191251646994, -3.718933829524839],
              [114.717187373230288, -3.718730396767376],
              [114.717135220854772, -3.7184693496082],
              [114.7171268152115, -3.718314549652751],
              [114.717210919584033, -3.718133456561656],
              [114.717240645981306, -3.717978229275498],
              [114.717182926080113, -3.717587232715993],
              [114.717288113396748, -3.717218255674916],
              [114.717403333759293, -3.716917842167109],
              [114.717347132019597, -3.716519695709466],
              [114.717483174537804, -3.716441283524053],
              [114.717831430507701, -3.716608112410164],
              [114.718084704553263, -3.716601396857752],
              [114.718209954723207, -3.716508669079873],
              [114.718323376780845, -3.716372651449914],
              [114.718006420992381, -3.7171100143296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.0,
        Nm_Pemilik: "Agung Saputra",
        KETERANGAN: "Sertifikat",
        No_Kav: "143",
        block: "Blok 11",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706803206035772, -3.734333160906563],
              [114.70610032134995, -3.734227248924594],
              [114.706157085915464, -3.733718693884619],
              [114.706228095299636, -3.733418113455892],
              [114.706638184450426, -3.733493451230492],
              [114.706990773404186, -3.733548890135706],
              [114.707022438696313, -3.733553868532609],
              [114.70722909532978, -3.73357646274577],
              [114.70706505408684, -3.734372616385147],
              [114.706803206035772, -3.734333160906563],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.077,
        Nm_Pemilik: "Agung Saputra",
        KETERANGAN: "Sertifikat",
        No_Kav: "145",
        block: "Blok 11",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708106608174148, -3.734529558643159],
              [114.70755390008523, -3.734446414160839],
              [114.707065054361166, -3.734372616385865],
              [114.707229095921917, -3.733576463045148],
              [114.707722966030502, -3.733630456977362],
              [114.708275116376427, -3.733690823049835],
              [114.708106608174148, -3.734529558643159],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.4,
        Nm_Pemilik: "Didik Hartono",
        KETERANGAN: "Sertifikat",
        No_Kav: "97",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726243843488561, -3.709419549302235],
              [114.72582114667695, -3.709682186009304],
              [114.725415562491932, -3.709629762651787],
              [114.725098324220468, -3.709694020184083],
              [114.724965014646912, -3.709640757537688],
              [114.724888215754433, -3.708704681221869],
              [114.724971335484554, -3.708690142150711],
              [114.72551679092517, -3.708708675592078],
              [114.725557013900229, -3.708668881228509],
              [114.726225977362219, -3.708944498819098],
              [114.726243843488561, -3.709419549302235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.171,
        Nm_Pemilik: "Juriyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "98",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726789703844247, -3.709176756132031],
              [114.726773571105639, -3.709211981639886],
              [114.72625482934555, -3.709412723388589],
              [114.726243843488561, -3.709419549302235],
              [114.726225978324933, -3.708944499423539],
              [114.726789703844247, -3.709176756132031],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.859,
        Nm_Pemilik: "Sinem",
        KETERANGAN: "Sporadik",
        No_Kav: "103",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72410241866848, -3.706386410037495],
              [114.72441444873111, -3.706362606268898],
              [114.724892325260811, -3.706425178228591],
              [114.72534777603876, -3.706522101726426],
              [114.725769028935417, -3.706597991858252],
              [114.726151467183641, -3.706711183502014],
              [114.726259900689556, -3.706738714027435],
              [114.726437210360487, -3.706783732524813],
              [114.726616950866912, -3.706907549904981],
              [114.726637932630254, -3.70692559433671],
              [114.726634917309227, -3.707045082396208],
              [114.726461853603183, -3.706989383777763],
              [114.725888387941708, -3.706804820027031],
              [114.7243606878653, -3.706820299837879],
              [114.72410241866848, -3.706386410037495],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Prima Hepi",
        KETERANGAN: "Sertifikat",
        No_Kav: "105",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726634917309227, -3.707045082396208],
              [114.72661453565027, -3.707852734879708],
              [114.726560431756042, -3.707862351226741],
              [114.726325559549878, -3.707868232936395],
              [114.725870896775916, -3.708262440576641],
              [114.725523102622503, -3.708606671466451],
              [114.725037802154574, -3.707887056933982],
              [114.726461853603183, -3.706989383777763],
              [114.726634917309227, -3.707045082396208],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Jaelani",
        KETERANGAN: "Sertifikat",
        No_Kav: "104",
        block: "Blok 7",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725037801984755, -3.707887056690909],
              [114.724779514134411, -3.707504061918836],
              [114.724678784502274, -3.70735469903653],
              [114.7243606878653, -3.706820299837879],
              [114.725888387941708, -3.706804820027031],
              [114.726461853603183, -3.706989383777763],
              [114.725037801984755, -3.707887056690909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.395,
        Nm_Pemilik: "Sair",
        KETERANGAN: "Sporadik",
        No_Kav: "23",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722377162833055, -3.719589050257595],
              [114.722401443295979, -3.718940259824271],
              [114.722435528001725, -3.718903349637333],
              [114.722437394882988, -3.718857089671087],
              [114.722488578090392, -3.718860565791239],
              [114.722503665891253, -3.71911956450806],
              [114.722577800188546, -3.719247798534828],
              [114.722629030731341, -3.719375972129585],
              [114.723001036037004, -3.719507853922319],
              [114.723008247384357, -3.719590235701242],
              [114.722991327866296, -3.720043170418413],
              [114.722377108632685, -3.719590446895126],
              [114.722377162833055, -3.719589050257595],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.934,
        Nm_Pemilik: "Nikur Usman",
        KETERANGAN: "Sporadik",
        No_Kav: "22",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721697720207729, -3.719313478323166],
              [114.721817123539765, -3.719342355762533],
              [114.722027988898077, -3.719433073844709],
              [114.722261559366416, -3.719538361662087],
              [114.722377107727624, -3.719590447660067],
              [114.722991327549124, -3.720043171537954],
              [114.722978247715417, -3.720440235663212],
              [114.722487899346646, -3.720215838980229],
              [114.721925748368406, -3.719955331028424],
              [114.721601029731858, -3.719952932566778],
              [114.721563999048058, -3.719478938250262],
              [114.721697720207729, -3.719313478323166],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Shintawati / Abdul karim",
        KETERANGAN: "Sertifikat",
        No_Kav: "95",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722961783975961, -3.712531720120507],
              [114.72452472343636, -3.712074404549906],
              [114.724280956904934, -3.713116948174475],
              [114.723165971426553, -3.713321493724729],
              [114.722961783975961, -3.712531720120507],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Mat Kailani",
        KETERANGAN: "Sertifikat",
        No_Kav: "94",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725309680828559, -3.714248806175897],
              [114.725358222545708, -3.712690230912067],
              [114.726221865995882, -3.71293379681393],
              [114.726160024790303, -3.714209590565753],
              [114.725309680828559, -3.714248806175897],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.346,
        Nm_Pemilik: "Sair",
        KETERANGAN: "Sporadik",
        No_Kav: "93",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724548956583277, -3.713067781162715],
              [114.724280958589247, -3.713116947198388],
              [114.724524723717451, -3.7120744047175],
              [114.724820076149371, -3.711987986065668],
              [114.724548956583277, -3.713067781162715],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.31,
        Nm_Pemilik: "Sair",
        KETERANGAN: "Sporadik",
        No_Kav: "92",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725358222904987, -3.712690229394361],
              [114.725367674780927, -3.712386749965367],
              [114.725719240347217, -3.712476629061173],
              [114.726233367294171, -3.712696505970533],
              [114.726221865769972, -3.7129337952608],
              [114.725358222904987, -3.712690229394361],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.0,
        Nm_Pemilik: "Siti Fatimah",
        KETERANGAN: "Sporadik",
        No_Kav: "56",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731546877254502, -3.720022541178932],
              [114.731284857352691, -3.719958135803101],
              [114.731201989208586, -3.719853525043038],
              [114.731105318632558, -3.719727999292221],
              [114.731001404917762, -3.71962335522164],
              [114.730491787531491, -3.719469899839067],
              [114.730033474003221, -3.71928525402725],
              [114.72986656164943, -3.719237838688028],
              [114.729813620930486, -3.719038578613887],
              [114.7297385581046, -3.718781090552796],
              [114.73047466290393, -3.71863905126041],
              [114.730474688842506, -3.718639045557418],
              [114.731111673298031, -3.718956968247686],
              [114.731111860951444, -3.718957075938772],
              [114.731266165905936, -3.719071477079063],
              [114.731886895717494, -3.719437530296208],
              [114.732071021678053, -3.719475410601591],
              [114.731979097187647, -3.720179696013933],
              [114.731546877254502, -3.720022541178932],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.0,
        Nm_Pemilik: "Siti Fatimah",
        KETERANGAN: "Sporadik",
        No_Kav: "57",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732071026047095, -3.719475410612867],
              [114.732096047463472, -3.719480551872562],
              [114.732395408123224, -3.719360147303566],
              [114.732491432651969, -3.719192462831542],
              [114.732572751263689, -3.719059820134877],
              [114.732672830033451, -3.718952104311126],
              [114.73277030020266, -3.718877089449593],
              [114.73309939325172, -3.718951448384807],
              [114.73344557278655, -3.719020950631247],
              [114.733879842522384, -3.718980414882276],
              [114.734231476047697, -3.718882856028822],
              [114.734285058416603, -3.718944693689976],
              [114.734326740877492, -3.719156893254411],
              [114.734084644852473, -3.719260386963571],
              [114.733854113788908, -3.719344629302437],
              [114.733514088235367, -3.719508427426946],
              [114.73328743988283, -3.719814450830989],
              [114.733165258313136, -3.719902347608089],
              [114.732962086710288, -3.719920760852414],
              [114.732547128546685, -3.720146188562276],
              [114.732112254260002, -3.720228111779446],
              [114.731979097187647, -3.720179696013933],
              [114.732071026047095, -3.719475410612867],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.2,
        Nm_Pemilik: "Sair",
        KETERANGAN: "Sporadik",
        No_Kav: "21a",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720583989744341, -3.717087560666272],
              [114.720689467862485, -3.717366889541586],
              [114.720802511526699, -3.717691369870539],
              [114.720886874646055, -3.717914601505417],
              [114.719593747503893, -3.718408646022291],
              [114.719559961084258, -3.717680481087192],
              [114.719860059668861, -3.717503221512011],
              [114.720080806969875, -3.717474349959724],
              [114.720271846947711, -3.717343140028283],
              [114.720498245620547, -3.717022836896819],
              [114.720583989744341, -3.717087560666272],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sucipto",
        KETERANGAN: "Sporadik",
        No_Kav: "21b",
        block: "Blok 2",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720886874646055, -3.717914601505417],
              [114.720939909149763, -3.71805493508124],
              [114.721102561430513, -3.718310158027855],
              [114.721870744774307, -3.718792245572916],
              [114.722031749269178, -3.718824497721097],
              [114.722006108800443, -3.718932016731516],
              [114.722027978262147, -3.719432994803364],
              [114.721817124599937, -3.719342340954692],
              [114.721697722128866, -3.719313460801165],
              [114.721688665234893, -3.719088889640565],
              [114.721578395484897, -3.719055120647729],
              [114.721264255389301, -3.71887687882339],
              [114.72092779559425, -3.71868531461068],
              [114.720486963881015, -3.718517236310637],
              [114.720332715202275, -3.718747546242902],
              [114.720244162779423, -3.718749838813576],
              [114.720081050151165, -3.718708917894592],
              [114.719944165617662, -3.718688966693668],
              [114.719720629764609, -3.718622773620982],
              [114.719603965234398, -3.718628862807062],
              [114.719593747503353, -3.718408646232799],
              [114.720886874646055, -3.717914601505417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.745,
        Nm_Pemilik: "Rahmatulah",
        KETERANGAN: "Sertifikat",
        No_Kav: "140",
        block: "Blok 10",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.700475730701896, -3.728150607562039],
              [114.700146549061344, -3.727510448385055],
              [114.700917690149907, -3.727134704949591],
              [114.701228821988082, -3.727763919680773],
              [114.700475730701896, -3.728150607562039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Karyono",
        KETERANGAN: "Sertifikat",
        No_Kav: "41",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72838824977697, -3.714200237668158],
              [114.729294718522382, -3.71412141057443],
              [114.729308248428822, -3.715470236076817],
              [114.728408248747797, -3.715550235881832],
              [114.72838824977697, -3.714200237668158],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 2.0,
        Nm_Pemilik: "Minarko",
        KETERANGAN: "Sporadik",
        No_Kav: "85",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727886887705893, -3.712286985605668],
              [114.72788186476555, -3.712181985941065],
              [114.727879351593856, -3.712130150577344],
              [114.7276496476088, -3.711944834337289],
              [114.727554830453926, -3.711777142515513],
              [114.727490537464618, -3.711570990070397],
              [114.727548994408977, -3.711472798980931],
              [114.727544702016829, -3.711082077985549],
              [114.727535727474091, -3.710970423422712],
              [114.72735500660788, -3.710770615317316],
              [114.727285034853679, -3.710715948012703],
              [114.727115195905171, -3.710399220848315],
              [114.727078363386227, -3.710318060166468],
              [114.726856542659746, -3.710154027558982],
              [114.726712399700901, -3.710135050359419],
              [114.726521668185171, -3.710228913174953],
              [114.726390447849766, -3.710329574255769],
              [114.726087536096045, -3.710124463174604],
              [114.727020392659185, -3.709788032895176],
              [114.727053566233806, -3.709911628431129],
              [114.727323393934199, -3.71023434922115],
              [114.727718843115738, -3.710607303273131],
              [114.72779324500371, -3.710916400631823],
              [114.72785323607097, -3.711169891665593],
              [114.727800899252799, -3.711532793954582],
              [114.728064309784841, -3.711857274662351],
              [114.728277542592295, -3.712129434062033],
              [114.728543309188183, -3.712279428937358],
              [114.728651758736021, -3.712335791786341],
              [114.728558366036211, -3.712739303613194],
              [114.728624133808339, -3.713037194462393],
              [114.728696349716287, -3.713178646465277],
              [114.728676017575538, -3.713442897471479],
              [114.728141612067162, -3.713108860457322],
              [114.72813139696126, -3.712964530523768],
              [114.728081667266537, -3.712899085819926],
              [114.728073212783542, -3.712657545405122],
              [114.728034063365683, -3.712593647001966],
              [114.727996616310051, -3.712455322739182],
              [114.727886887705893, -3.712286985605668],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.5,
        Nm_Pemilik: "Sugianto",
        KETERANGAN: "Sporadik",
        No_Kav: "42",
        block: "Blok 3",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.72929471969033, -3.714121410751328],
              [114.729988248053914, -3.714070236031124],
              [114.729732736101511, -3.714819165304115],
              [114.729301869417981, -3.71483395209003],
              [114.72929471969033, -3.714121410751328],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.8,
        Nm_Pemilik: "Suhardi",
        KETERANGAN: "Sertifikat",
        No_Kav: "116",
        block: "Blok 11",
        KODE: null,
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707563712235043, -3.7323698331713],
              [114.707950786864146, -3.732440360275261],
              [114.707886476328397, -3.733595270960787],
              [114.707173451059091, -3.733521451485393],
              [114.707563712235043, -3.7323698331713],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suliyanto",
        KETERANGAN: "Sertifikat",
        No_Kav: "74",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729984011415368, -3.709455366969009],
              [114.729975147269798, -3.7096081873401],
              [114.729979807850469, -3.709960019543842],
              [114.730000959470928, -3.710384492064326],
              [114.730050375787201, -3.710624750666867],
              [114.730094062116976, -3.710932007888263],
              [114.730143478401729, -3.711172266517112],
              [114.730184109522554, -3.711342547800711],
              [114.730253511016258, -3.7116080028954],
              [114.73026215088295, -3.711656321923261],
              [114.729719265686128, -3.711701085492881],
              [114.729397415947091, -3.709246788639804],
              [114.729408193133267, -3.70924682062118],
              [114.729986022228573, -3.709248303860989],
              [114.729984011415368, -3.709455366969009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Samsul Aripin",
        KETERANGAN: "Sertifikat",
        No_Kav: "81",
        block: "Blok 6",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728908310257182, -3.711064742800144],
              [114.728743310566443, -3.709349734993463],
              [114.728959763402599, -3.709245660143129],
              [114.729397412020376, -3.709246789943506],
              [114.72962378233062, -3.710972973858893],
              [114.728908310257182, -3.711064742800144],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Nur Salim",
        KETERANGAN: "Sertifikat",
        No_Kav: "80",
        block: "Blok 6",
        KODE: "Hijau",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729719265686128, -3.711701085492881],
              [114.728106478566062, -3.711832398349632],
              [114.727841986910832, -3.71152350096799],
              [114.727892112636056, -3.711195089338519],
              [114.729623782604506, -3.710972974971598],
              [114.729719265686128, -3.711701085492881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Sudarka",
        KETERANGAN: "Sertifikat",
        No_Kav: "146",
        block: "Blok 11",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711768327282215, -3.732162669337258],
              [114.712721615955346, -3.73165631974408],
              [114.713464942889019, -3.73137707479743],
              [114.713455550550307, -3.731695536306108],
              [114.712846937658966, -3.731791501404998],
              [114.71293800501951, -3.733022644216123],
              [114.71194834391089, -3.73277207988481],
              [114.711768327282215, -3.732162669337258],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.955,
        Nm_Pemilik: "Suhardi",
        KETERANGAN: "Sertifikat",
        No_Kav: "147",
        block: "Blok 11",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711474731468769, -3.727335252524215],
              [114.711489585135098, -3.727351527537017],
              [114.711558463494598, -3.727441673070886],
              [114.711708142790087, -3.727623856977158],
              [114.712031530684385, -3.727794786408983],
              [114.712308316395251, -3.728014473130465],
              [114.712614064114248, -3.728272950880577],
              [114.712838982502547, -3.728595051738615],
              [114.711942687540216, -3.728828784508962],
              [114.711474731468769, -3.727335252524215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "H. Samsi",
        KETERANGAN: "Sertifikat",
        No_Kav: "5",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715336844486288, -3.71566118991111],
              [114.716004028435322, -3.715630020505679],
              [114.716027599788859, -3.715685669894399],
              [114.716156668436014, -3.715864367186871],
              [114.716825870834171, -3.716054548106027],
              [114.717034031558313, -3.716244164898833],
              [114.717170314139608, -3.71636193794631],
              [114.717219826913436, -3.716577789020466],
              [114.716851901414771, -3.716905768734122],
              [114.71516138395647, -3.715929879045143],
              [114.715336844486288, -3.71566118991111],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "A. Rahman",
        KETERANGAN: "Sertifikat",
        No_Kav: "11a",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715593939514136, -3.7180501023622],
              [114.716660250167024, -3.719169609453023],
              [114.716193966050298, -3.719461180984317],
              [114.71515137478282, -3.718820663758781],
              [114.71511309696028, -3.718797153518872],
              [114.714694421442573, -3.719038083891253],
              [114.714588302135198, -3.718973744077903],
              [114.714602880170489, -3.71884072131851],
              [114.715593939514136, -3.7180501023622],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.708,
        Nm_Pemilik: "Pariani",
        KETERANGAN: "Sporadik",
        No_Kav: "9",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712031201879455, -3.716869685495388],
              [114.711984604462074, -3.716794839379763],
              [114.712341029953123, -3.716588074475733],
              [114.712615761495698, -3.716565052881752],
              [114.712862511808325, -3.716512288083192],
              [114.713095830184983, -3.716514378394849],
              [114.713277442096256, -3.716570812093816],
              [114.713321180276623, -3.717271666065],
              [114.712982943280352, -3.717177561503167],
              [114.712990391324112, -3.717166949794713],
              [114.712872757968313, -3.717132219192895],
              [114.712758451951913, -3.717023115861681],
              [114.712616398302828, -3.716901523006994],
              [114.712157363939724, -3.716926177272292],
              [114.712031201879455, -3.716869685495388],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.097,
        Nm_Pemilik: "Pariani",
        KETERANGAN: "Sporadik",
        No_Kav: "8",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713321189899688, -3.717271669960958],
              [114.713277447847844, -3.716570807073874],
              [114.713686022306391, -3.71671506164805],
              [114.714111701783224, -3.716556298202458],
              [114.714304975362552, -3.716456258469606],
              [114.714309757920006, -3.716511485133436],
              [114.714426387413226, -3.717653987506302],
              [114.714298242069759, -3.717610231560679],
              [114.713478854672417, -3.717315531481301],
              [114.713321189899688, -3.717271669960958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Santer",
        KETERANGAN: "Sertifikat",
        No_Kav: "10",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715119094431657, -3.718373403716932],
              [114.714670482479804, -3.718739491736163],
              [114.714608157663903, -3.718576844704281],
              [114.714629961056147, -3.718462310750187],
              [114.713649227035802, -3.717937115304358],
              [114.713359388863282, -3.71789746105908],
              [114.712748643560474, -3.717528918660449],
              [114.712982941199712, -3.717177585570894],
              [114.713478853361778, -3.7173155351904],
              [114.714179157474604, -3.717567404913567],
              [114.715119094431657, -3.718373403716932],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Rajiyah",
        KETERANGAN: "Sertifikat",
        No_Kav: "7",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714179162905538, -3.717567407004687],
              [114.714426390437524, -3.717653994049438],
              [114.714518247925298, -3.717560235728399],
              [114.715171702282888, -3.716966858476166],
              [114.716075954125628, -3.717561274413805],
              [114.71582108961519, -3.717777953458504],
              [114.715119098978434, -3.718373402333247],
              [114.714179162905538, -3.717567407004687],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.5,
        Nm_Pemilik: "Prayetno",
        KETERANGAN: "Sporadik",
        No_Kav: "11b",
        block: "Blok 1",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716660250529785, -3.719169610555818],
              [114.717087880922122, -3.719620880442873],
              [114.716980808204113, -3.719926685543975],
              [114.716654886907818, -3.719918720352114],
              [114.716195255183493, -3.719554496859656],
              [114.716193968143472, -3.719461191641423],
              [114.716660250529785, -3.719169610555818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.1,
        Nm_Pemilik: "Sugiyono",
        KETERANGAN: "Sertifikat",
        No_Kav: "50",
        block: "Blok 4",
        KODE: "Hijau",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729510986448048, -3.717778868044992],
              [114.729489845645531, -3.71770381873683],
              [114.729449191686257, -3.717510558196028],
              [114.729995763241803, -3.717267414476222],
              [114.729510986448048, -3.717778868044992],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 0.562,
        Nm_Pemilik: "Sair / Syahril",
        KETERANGAN: "Sporadik",
        No_Kav: "51",
        block: "Blok 4",
        KODE: "Merah",
        KET: null,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73086339589149, -3.718195221394112],
              [114.730807822493773, -3.718460255230974],
              [114.730765463085206, -3.718524576548591],
              [114.730713050712907, -3.718560047653217],
              [114.730456338492914, -3.718625511010441],
              [114.729736987236706, -3.718762687677885],
              [114.729638278743408, -3.718318702460606],
              [114.73086339589149, -3.718195221394112],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Luas: 1.5,
        Nm_Pemilik: "Suyatno H",
        KETERANGAN: "Sertifikat",
        No_Kav: "51",
        block: "Blok 4",
        KODE: "Merah",
        KET: "dijual",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729578129505342, -3.718056447140897],
              [114.729510993016106, -3.717778874677225],
              [114.729995763241803, -3.717267414476222],
              [114.730679706796735, -3.717650563033639],
              [114.73140287849084, -3.716525768533348],
              [114.73156928132569, -3.716605489120912],
              [114.731415548860539, -3.716769652621579],
              [114.730963904355079, -3.717485720109566],
              [114.730968434344845, -3.717694283773913],
              [114.73086339589149, -3.718195221394112],
              [114.729638278457855, -3.718318702027923],
              [114.729578129505342, -3.718056447140897],
            ],
          ],
        ],
      },
    },
  ],
};
