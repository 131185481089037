import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpenReportActive: false,
	isUpdateMaterialActive: false,
	isCreateMaterialActive: false,
};

export const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		handleModalStarter: (state, action) => {
			return { ...state, ...action.payload };
		},
		handleModal: (state, action) => {
			let payload = action.payload;
			let modal = state[payload.modalName];

			return {
				...state,
				[payload.modalName]: {
					...state[payload.modalName],
					[payload.state]: !modal[payload.state],
				},
			};
		},
		handleSetParams: (state, action) => {
			let payload = action.payload;
			let modal = state[payload.modalName];

			return {
				...state,
				[payload.modalName]: {
					...state[payload.modalName],
					[payload.state]: {
						...modal.params,
						...payload.params,
					},
				},
			};
		},
		toggleOpenReport: (state, action) => {
			state.isOpenReportActive = !state.isOpenReportActive;
		},
		toggleModalUpdateMaterial: (state, action) => {
			state.isUpdateMaterialActive = !state.isUpdateMaterialActive;
		},
		toggleModalCreateMaterial: (state, action) => {
			state.isCreateMaterialActive = !state.isCreateMaterialActive;
		},
	},
});

export const {
	toggleOpenReport,
	toggleModalUpdateMaterial,
	toggleModalCreateMaterial,
	handleModalStarter,
	handleModal,
	handleSetParams,
} = modalSlice.actions;
export const getStatusOfModal = (state) => state.modal;
export const selectModal = (state) => state.modal;
export default modalSlice.reducer;
