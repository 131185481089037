import React from "react";
import ButtonComponent from "../../../../../../components/atoms/ButtonComponent";
import { TbRefresh } from "react-icons/tb";
import { FiDownload, FiUploadCloud } from "react-icons/fi";
import { useModalStore } from "../Modal/Modal";
import { useModalUploadStore } from "../ModalUploadFile/ModalUploadFile";

const Filter = ({ handleRefreshData }) => {
  const toggleModal = useModalStore((state) => state.toggleModal);
  const toggleModalUpload = useModalUploadStore((state) => state.toggleModal);

  return (
    <div className="mx-16 grid grid-cols-1 gap-8 md:mt-0 md:grid-cols-2">
      <div className="order-2 grid w-full grid-cols-2 gap-8 md:order-1 md:flex md:w-fit md:flex-row md:items-center md:gap-8">
        {/*<ButtonComponent*/}
        {/*  tooltipPlacement="bottom"*/}
        {/*  tooltipTitle="Filter"*/}
        {/*  value="Filter"*/}
        {/*  size="none"*/}
        {/*  type="button"*/}
        {/*  className="bordered-button-transition w-full bg-white p-10 md:-ml-8 md:hidden md:w-fit"*/}
        {/*  onClick={showModal}*/}
        {/*  icon={<TbFilter className="text-18 text-gray-500" />}*/}
        {/*/>*/}
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Mass Data"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => handleRefreshData()}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => handleRefreshData()}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Unduh Template"
          value="Unduh Template"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => {}}
          icon={<FiDownload className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Unggah"
          value="Unggah"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => toggleModalUpload({})}
          icon={<FiUploadCloud className="text-18 text-gray-500" />}
        />
      </div>
      <div className="order-1 flex w-full justify-end md:order-2">
        <ButtonComponent
          value="Tambah Anggota"
          className="button-transition w-full md:w-fit"
          onClick={() => toggleModal({})}
        />
      </div>
    </div>
  );
};

export default Filter;
