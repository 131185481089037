import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

//  help me write JSDoc for below function
// ** @param {string} str
export function cn(...inputs) {
  return twMerge(inputs);
}

/**
 * @param number
 * @param pattern
 * @param patternChar
 * @returns {string}
 * @example
 * formatValueByPattern(23456, "%% (%%%)", "%") // Output: '12 (312)'
 * formatValueByPattern("89620044936", "+62 #### #### ####") // Output: '+62 8962 0044 936'
 */
export function formatValueByPattern(number, { pattern, patternChar = "#" }) {
  let numStr = number.toString();
  let formattedNumber = "";

  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] === patternChar) {
      if (numStr.length > 0) {
        formattedNumber += numStr[0];
        numStr = numStr.slice(1);
      } else {
        formattedNumber += " ";
      }
    } else {
      formattedNumber += pattern[i];
    }
  }

  return formattedNumber;
}

/**
 * @param phoneNumber
 * @returns {string}
 * @example
 * formatPhoneNumber("089620044936") // Output: '89620044936'
 * formatPhoneNumber("+6289620044936") // Output: '89620044936'
 */
export function formatPhoneNumber(phoneNumber) {
  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
  cleanedPhoneNumber = cleanedPhoneNumber.replace(/^0/, "");
  cleanedPhoneNumber = cleanedPhoneNumber.replace(/-/g, "");
  cleanedPhoneNumber = cleanedPhoneNumber.replace(/^62/, "");

  return cleanedPhoneNumber;
}
