import * as turf from "@turf/turf";
import { toast } from "react-hot-toast";

export const MAP_STYLE =
  "mapbox://styles/mapbox/satellite-streets-v12?optimize=true";

export const DEFAULT_CSS_MAP_STYLE = {
  width: "100vw",
  height: "100vh",
  position: "relative",
};

/**
 * Utility function to generate a random RGB color.
 *
 * @returns {string} A randomly generated RGB color string (e.g., 'rgb(255, 0, 127)').
 */

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  const color = `rgb(${red}, ${green}, ${blue})`;
  return color;
};

/**
 * Utility function to map random colors to properties in the provided data.
 *
 * @param {Object} data - The data object to be color-mapped.
 * @param {Array} data.features - An array of features with properties to be color-mapped.
 * @returns {Object} The modified data object with random colors mapped to properties.
 */

export const dataColorMapping = (data) => {
  return {
    ...data,
    features: data.features.map((item) => {
      return {
        ...item,
        properties: { ...item.properties, color: getRandomColor() },
      };
    }),
  };
};

/**
 * Utility function to create a map layer.
 *
 * @param {Object} params - Parameters for creating the layer.
 * @param {string} params.id - The unique identifier for the layer.
 * @param {string} params.type - The type of the layer (e.g., 'fill', 'line', etc.).
 * @param {string} params.source - The data source for the layer.
 * @param {string} params.fillColor - The fill color of the layer.
 * @param {number} params.opacity - The opacity of the layer (0 to 1).
 * @param {...any} params.rest - Additional properties to be merged into the layer.
 * @returns {Object} The created layer object.
 */

export const createLayer = ({
  id,
  type,
  source,
  fillColor,
  opacity,
  ...rest
}) => ({
  id,
  type,
  source,
  layout: {},
  paint: {
    "fill-color": fillColor,
    "fill-opacity": opacity,
    "circle-radius": 10,
    "circle-color": "#007cbf",
  },
  ...rest,
});

/**
 * Utility function to create an outline layer style.
 *
 * @param {Object} params - Parameters for creating the outline layer style.
 * @param {string} params.id - The unique identifier for the outline layer style.
 * @param {string} params.type - The type of the outline layer style (e.g., 'line').
 * @param {string} params.source - The data source for the outline layer style.
 * @returns {Object} The created outline layer style object.
 */

export const createOutlineLayerStyle = ({
  id,
  type,
  source,
  geometryType,
  outlineColor,
}) => ({
  id,
  type,
  source,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": outlineColor,
    "line-width": geometryType === "Polygon" ? 2 : 5,
  },
});

/**
 * Utility function to create a label layer.
 *
 * @param {Object} params - Parameters for creating the label layer.
 * @param {string} params.id - The unique identifier for the label layer.
 * @param {string} params.type - The type of the label layer (e.g., 'symbol').
 * @param {string} params.source - The data source for the label layer.
 * @param {string} params.iconField - The field used for the icon image.
 * @returns {Object} The created label layer object.
 */

export const createLabelLayer = ({ id, type, source, iconField }) => ({
  id,
  type,
  source,
  layout: {
    "text-field": ["get", "No_Kav"],
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-radial-offset": 0.5,
    "text-justify": "center",
    "text-anchor": "center",
    "icon-image": ["get", iconField],
  },
  paint: {
    "text-color": "black",
  },
});

/**
 * Utility function to create multiple map layers from the provided layer data.
 *
 * @param {Array} layerData - An array of objects representing layer data.
 * @returns {Array} An array of objects representing created layers.
 */

export const createMultipleLayers = (layerData) => {
  return layerData.map((data) => {
    const { id, fillColor, outlineColor, geometryType, opacity } = data;
    const layer = createLayer({
      id,
      source: id,
      fillColor,
      type:
        geometryType === "Polygon"
          ? "fill"
          : geometryType === "Point"
          ? "circle"
          : "line",
      beforeId: `poi-labels-${id}`,
      opacity: opacity !== null ? opacity : 0.2,
    });

    const outlineStyle = createOutlineLayerStyle({
      id: `outline-${id}`,
      source: id,
      type: "line",
      geometryType,
      outlineColor,
    });

    const label = createLabelLayer({
      id: `poi-labels-${id}`,
      source: id,
      iconField: "icon",
      type: "symbol",
    });

    return { layer, outlineStyle, label, ...data };
  });
};

/**
 * Calculates a dynamic zoom level based on the latitude of the coordinates.
 * This is a simple implementation that adjusts the zoom level based on latitude.
 * Higher latitudes result in lower zoom levels.
 *
 * @param {Array<number>} coordinates - The coordinates array where the first element is longitude and the second is latitude.
 * @returns {number} The calculated zoom level.
 */
export const calculateDynamicZoom = (coordinates) => {
  const lat = coordinates[1];
  let zoom = 10; // Default zoom level

  if (lat > 45) {
    zoom = 5;
  } else if (lat > 30) {
    zoom = 6;
  } else if (lat > 15) {
    zoom = 7;
  }

  return zoom;
};

/**
 * Adjusts the map view to fit the bounds of the provided GeoJSON object.
 * If the GeoJSON contains exactly one Point feature, the map will use `flyTo` to center on that point
 * with a dynamic zoom level. Otherwise, it calculates the bounding box of the GeoJSON and uses `fitBounds`
 * to show the entire area.
 *
 * @param {Object} map - The Mapbox GL map instance to manipulate.
 * @param {Object} geojson - A! GeoJSON object containing the features to fit the map around.
 */
export const fitToBounds = async (map, geojson) => {
  try {
    if (
      geojson.features.filter((feature) => feature.geometry).length === 1 &&
      geojson.features[0].geometry.type === "Point"
    ) {
      map.flyTo({
        center: geojson.features[0].geometry.coordinates,
        zoom: calculateDynamicZoom(geojson.features[0].geometry.coordinates),
        duration: 1000,
      });
    } else {
      const bounds = turf.bbox(geojson);
      map.fitBounds(bounds, {
        padding: 50,
        duration: 5000,
        animate: true,
      });
    }
  } catch (error) {
    toast.error(
      `Terjadi kesalahan: ${error.message}. Silakan periksa kembali isi file GeoJSON Anda, pastikan formatnya benar, terutama untuk data shape jalan.`
    );
  }
};

function hexToRgb(hex) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function adjustColorToSpecificTarget(originalHex) {
  // Mengubah warna asal menjadi komponen RGB
  const [r, g, b] = hexToRgb(originalHex);

  // Logika untuk menyesuaikan warna spesifik (ini hanya contoh)
  // Untuk kasus #3b9330 ke #38d529, kita perlu menyesuaikan setiap komponen
  let newR = r - (r - 56) * 0.9; // Mengurangi intensitas merah
  let newG = g + (217 - g) * 0.9; // Meningkatkan intensitas hijau
  let newB = b - b * 0.9; // Mengurangi intensitas biru secara signifikan

  // Membulatkan nilai dan memastikan mereka berada dalam rentang yang valid
  newR = Math.round(Math.min(255, Math.max(0, newR)));
  newG = Math.round(Math.min(255, Math.max(0, newG)));
  newB = Math.round(Math.min(255, Math.max(0, newB)));

  // Mengembalikan warna baru dalam format Hex
  return rgbToHex(newR, newG, newB);
}
