import { ConfigProvider, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbFilter, TbRefresh } from "react-icons/tb";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../App";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../features/userSlice";
import { pagePath } from "../../ListView";

const { RangePicker } = DatePicker;

const Filter = ({ handleRefreshData, onFilter, showModal }) => {
  const user = useSelector(selectUser);
  const { control } = useForm();
  const { users, fetchUsers } = useFilterDataDefaults();

  const onSearchUser = async (searchTerm) => {
    await fetchUsers({ name: searchTerm, options: { withoutAllItem: true } });
  };

  return (
    <div className="mx-16 mt-56 flex flex-row items-center justify-between md:mt-8">
      <div className="relative flex w-full flex-row items-center space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition -ml-8 w-fit bg-white p-10 md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-fit bg-white p-10"
          onClick={() => {
            logEvent(
              analytics,
              `refresh_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_button: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            handleRefreshData();
          }}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <SelectHook
          name="username"
          data={users}
          control={control}
          placeholder="Pilih pengguna"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            onSearchUser(searchValue);
          }}
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_user_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            onFilter({ type: "fltUser", value });
          }}
        />
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <div className="hidden md:inline">
                  <RangePicker
                    placeholder={["Dari tanggal", "sampai"]}
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(date) => {
                      logEvent(
                        analytics,
                        `filter_date_range_picker_button_${pagePath}_${window.location.host}`,
                        {
                          page_title: `${pagePath}_${window.location.host}`,
                          page_location: window.location.href,
                          who_access_this_filter: `${
                            user?.username
                          }_at_${moment().format("D_MMM_YY")}`,
                        }
                      );
                      onChange(date);
                      onFilter({
                        value: date,
                        type: "dateRange",
                        filterKeys: ["fltStartDate", "fltEndDate"],
                      });
                    }}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 8,
                      maxWidth: 250,
                      borderWidth: 1,
                      borderColor: "#e1e4e8",
                    }}
                  />
                </div>
              </ConfigProvider>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
