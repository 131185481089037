import { MaterialReactTable } from "material-react-table";
import React, { useMemo, useRef, useState } from "react";
import { useMeasure } from "@uidotdev/usehooks";
import moment from "moment/moment";
import { MdDragIndicator, MdOutlineWarning } from "react-icons/md";
import { Tooltip } from "antd";
import { checkValues } from "../../../../../helpers/validationHelper";
import { create } from "zustand";
import ColumnSettings from "../../../../../components/atoms/Table/partials/ColumnSettings";
import { formatNumericValue } from "../../../../../helpers/Common";

export const useTableStore = create((set, get) => ({
  isDrawer: false,
  setIsDrawer: (value) => set({ isDrawer: !get().isDrawer }),
  sortedColumns: [],
  setSortedColumns: (value) => set({ sortedColumns: value }),
}));

const HistoryData = ({ data, request = { request } }) => {
  const columns = useMemo(
    () => [
      {
        header: "NO.",
        accessorKey: "index",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ cell, row }) => {
          const message = generateMessage(row.original);

          return (
            <div className="flex flex-row items-center space-x-8">
              <Tooltip
                placement="bottom"
                title={message.message}
                className="font-regular"
              >
                <div>
                  {message.isError ? (
                    <MdOutlineWarning className="text-24 text-yellow-600" />
                  ) : null}
                </div>
              </Tooltip>
              <p>{row.index + 1}</p>
            </div>
          );
        },
      },
      {
        header: "PKS",
        accessorKey: "buyerPartyName",
      },
      {
        header: "Harga Mulai Berlaku",
        accessorKey: "startDate",
        Cell: ({ cell, row }) =>
          cell.getValue() ? moment(cell.getValue()).format("ll") : null,
      },
      {
        header: "Harga Selesai Berlaku",
        accessorKey: "endDate",
        Cell: ({ cell, row }) =>
          cell.getValue() ? moment(cell.getValue()).format("ll") : null,
      },
      {
        header: "Harga PKS",
        accessorKey: "buyerPartyPrice",
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        header: "Batas Atas",
        accessorKey: "maxPurchasePrice",
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        header: "Batas Bawah",
        accessorKey: "minPurchasePrice",
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  const generateMessage = (data) => {
    let message;

    const fieldNames = {
      startDate: "Tanggal Mulai Berlaku",
      buyerPartyPrice: "Harga PKS(Rp)",
      maxPurchasePrice: "Batas Atas(Rp)",
      minPurchasePrice: "Batas Bawah(Rp)",
      buyerPartyName: "Nama PKS",
    };

    const items = [
      { startDate: data?.startDate },
      {
        buyerPartyPrice: data?.buyerPartyPrice,
        customValidation: () => {
          return data?.buyerPartyPrice !== 0;
        },
        customValidationMessage: "tidak boleh kosong",
      },
      {
        maxPurchasePrice: data?.maxPurchasePrice,
        customValidation: () => {
          return data?.buyerPartyPrice !== 0;
        },
        customValidationMessage: "tidak boleh kosong",
      },
      {
        minPurchasePrice: data?.minPurchasePrice,
        customValidation: () => {
          return data?.buyerPartyPrice !== 0;
        },
        customValidationMessage: "tidak boleh kosong",
      },
      { buyerPartyName: data?.buyerPartyName },
    ];
    message = checkValues(items, fieldNames);

    return message;
  };

  return (
    <div
      ref={ref}
      className="relative h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <ColumnSettings
        ref={tableInstanceRef}
        columns={columns}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        isDrawer={isDrawer}
        setIsDrawer={setIsDrawer}
        sortedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
      />
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data ?? []}
        enableColumnOrdering
        enablePinning
        enableColumnResizing
        enableDensityToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableRowVirtualization
        initialState={{ density: "compact" }}
        enableTableFooter={false}
        enableBottomToolbar={false}
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{
          sx: {
            borderRadius: "24px",
            boxShadow: "0",
            fontFamily: "Inter-Regular",
          },
        }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          const message = generateMessage(row.original);

          return {
            sx: {
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor:
                message.message.toLowerCase() === "sudah lengkap"
                  ? "#FFFFFF"
                  : "#F9F3E4",
              borderBottom: "1px solid #ECEDEF",
              fontFamily: "Inter-Regular",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: "#fafbfd",
            padding: "8px",
            fontFamily: "Inter-Regular",
            color: "#646769",
          },
        }}
        muiTableHeadCellProps={{
          sx: { borderBottom: 0 },
        }}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
          isLoading: request?.isLoading,
        }}
        columnResizeMode="onEnd"
        onColumnSizingChange={(updater) => {
          setColumnSizing((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnPinningChange={(updater) => {
          setColumnPinning((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnOrderChange={(updater) => {
          setColumnOrder((prev) => {
            const newOrder =
              updater instanceof Function ? updater(prev) : updater;
            return updater instanceof Function ? updater(prev) : updater;
          });
        }}
        onColumnVisibilityChange={(updater) => {
          setColumnVisibility((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
      />
    </div>
  );
};
export default HistoryData;
