export const headersData = [
	{
		value: "Estate",
		accessorKey: "estateName",
		type: "alphabet",
	},
	{
		value: "Afdeling/Wilayah",
		accessorKey: "afdelingName",
		type: "alphabet",
	},
	{
		value: "Blok",
		accessorKey: "blockName",
		type: "alphabet",
	},
	{
		value: "Tahun Tanam",
		accessorKey: "plantingYear",
		type: "alphabet",
	},
	{
		value: "Tonase (Kg)",
		accessorKey: "tonnase",
		type: "alphabet",
	},
	{
		value: "BJR Acuan (Kg)",
		accessorKey: "bjr",
		type: "alphabet",
	},
	{
		value: "Bulan Panen",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Tahun Panen",
		accessorKey: "year",
		type: "alphabet",
	},
	{
		value: "Aksi",
		accessorKey: "action",
		type: "alphabet",
	},
];
