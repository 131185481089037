import { notification } from "antd";
import { create } from "zustand";
import {
  getFertilizationRecapsDistinctBlocksApi,
  getFertilizationRecapsMaterialSummaryApi,
  getFertilizationRecapsOverallSummaryApi,
} from "./fertilizationRecapitulationApi";

export const useFertilizationRecapsStore = create((set, get) => ({
  distinctBlocks: [],
  materialSummary: [],
  overallSummary: [],
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getRecapsByDistinctBlocks: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getFertilizationRecapsDistinctBlocksApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Pemupukan Distinct Block",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ distinctBlocks: data.records, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      onError?.(error?.data);
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Rekap Pemupukan Distinct Block",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getFertilizationRecapsMaterialSummary: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getFertilizationRecapsMaterialSummaryApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Pemupukan Material Summary",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ materialSummary: data.records, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      onError?.(error?.data);
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Rekap Pemupukan Material Summary",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getFertilizationRecapsOverallSummary: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getFertilizationRecapsOverallSummaryApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Pemupukan Overall Summary",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ overallSummary: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(error?.data);
      return notification.error({
        message: "Gagal Mendapatkan Rekap Pemupukan Overall Summary",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
