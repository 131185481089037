export const sabuhur2009 = {
  type: "FeatureCollection",
  name: "sabuhurtt09_200ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.307,
        No_Kav: "113c",
        Nm_Pemilik: "Sapuani",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253684.84089,
        Y_COORD: 9556346.3600999992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781210950054387, -4.011008548958147],
              [114.781014167269007, -4.010568071117814],
              [114.782148234018123, -4.010570958904888],
              [114.782066709335638, -4.0106090204307],
              [114.781210950054387, -4.011008548958147],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.016,
        No_Kav: "115",
        Nm_Pemilik: "Juanda",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254064.3281,
        Y_COORD: 9556580.6173199993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784252104189918, -4.00850786555928],
              [114.785755623590489, -4.007772637360183],
              [114.78575277930868, -4.008799959307464],
              [114.785643007050027, -4.008856653380851],
              [114.785648526843445, -4.008936848100288],
              [114.784249190536158, -4.009589506489924],
              [114.784252104189918, -4.00850786555928],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.046,
        No_Kav: "116",
        Nm_Pemilik: "Mundir Harianto",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254236.77217000001,
        Y_COORD: 9556668.1750799995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785755623590489, -4.007772637360183],
              [114.787360846227216, -4.006987670096706],
              [114.787358029047155, -4.00803754763563],
              [114.787027431953888, -4.008217943698567],
              [114.786263034143474, -4.008537042540393],
              [114.785752952591324, -4.008799843973711],
              [114.785755623590489, -4.007772637360183],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.005,
        No_Kav: "117",
        Nm_Pemilik: "Abdulrahman Sidik",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254412.3737,
        Y_COORD: 9556753.01,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787360851870048, -4.006987666523198],
              [114.787644727944851, -4.006848848105191],
              [114.788921289557024, -4.006224710578246],
              [114.788918483925471, -4.007269083097968],
              [114.788551480926031, -4.007455263757438],
              [114.788019393483737, -4.007707700776266],
              [114.787358029047155, -4.00803754763563],
              [114.787360851870048, -4.006987666523198],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.407,
        No_Kav: "118",
        Nm_Pemilik: "Dwi Sumrahadi",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254611.74306000001,
        Y_COORD: 9556848.1560699996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.788921289557024, -4.006224710578246],
              [114.789264692697003, -4.006056812969982],
              [114.790079303048358, -4.005544034224925],
              [114.790398540976554, -4.005281134905716],
              [114.790952608571558, -4.006117443168522],
              [114.789846873737417, -4.006824782607291],
              [114.788918483925471, -4.007269083097968],
              [114.788921289557024, -4.006224710578246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.065,
        No_Kav: "3",
        Nm_Pemilik: "Asliani",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252585.32759,
        Y_COORD: 9556041.3920399994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.770937894752095, -4.01292819757553],
              [114.771462030187735, -4.012597206773272],
              [114.772413462023721, -4.013975538471623],
              [114.771591116551946, -4.014293435347606],
              [114.77093379436603, -4.014438976620438],
              [114.770937894752095, -4.01292819757553],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.035,
        No_Kav: "4",
        Nm_Pemilik: "H. Asnau / Asnawiyah",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252452.27749000001,
        Y_COORD: 9556035.8938500006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.770022092955145, -4.01248700354713],
              [114.770291776868589, -4.012614854962567],
              [114.770866736657553, -4.012973133754257],
              [114.770937892074983, -4.012928200504857],
              [114.77093379436603, -4.014438976620438],
              [114.770016243670057, -4.014642136871892],
              [114.770022092955145, -4.01248700354713],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.005,
        No_Kav: "5",
        Nm_Pemilik: "Sanadi",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252362.87298,
        Y_COORD: 9556037.6249299999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.769329883284541, -4.012335623313342],
              [114.769643102889333, -4.012307332236076],
              [114.770022092955145, -4.01248700354713],
              [114.770016243670057, -4.014642136871892],
              [114.769460911989228, -4.014765095481868],
              [114.769323229273553, -4.014787575170499],
              [114.769329883284541, -4.012335623313342],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.041,
        No_Kav: "7",
        Nm_Pemilik: "Citra Dewi Wahyuningsih",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252214.03627000001,
        Y_COORD: 9556019.6529099997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76800696791878, -4.012455168421655],
              [114.76866420759842, -4.012395929662198],
              [114.768658524301486, -4.014895318380726],
              [114.768171944960642, -4.014975547675731],
              [114.768000006564534, -4.015017608139204],
              [114.76800696791878, -4.012455168421655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.021,
        No_Kav: "10",
        Nm_Pemilik: "Ponijan",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252000.04761000001,
        Y_COORD: 9555979.2761599999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.766160558896274, -4.012667008239732],
              [114.766303346497722, -4.012631958899532],
              [114.76668950023334, -4.01283980522255],
              [114.766712681930073, -4.012829431851872],
              [114.76670585479205, -4.015340267768227],
              [114.766097551574106, -4.015499908090279],
              [114.766105148330311, -4.012704593685053],
              [114.766160558896274, -4.012667008239732],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.021,
        No_Kav: "12",
        Nm_Pemilik: "Sri Astuti",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251828.8842,
        Y_COORD: 9555887.9234999996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76443398026862, -4.01386423050282],
              [114.765296078440585, -4.01394691479267],
              [114.765291283753584, -4.015707415010429],
              [114.764653020448364, -4.015865798812826],
              [114.764428414202001, -4.015909586549505],
              [114.76443398026862, -4.01386423050282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.012,
        No_Kav: "13",
        Nm_Pemilik: "Khairus Saleh",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251737.00124000001,
        Y_COORD: 9555884.3059500009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.763828073534157, -4.016003667389342],
              [114.763636254986707, -4.015999041638761],
              [114.763641537472267, -4.014059172362893],
              [114.764108507512049, -4.013833013078563],
              [114.76443398026862, -4.01386423050282],
              [114.764428411985151, -4.015909584780464],
              [114.763982858902281, -4.01599644882279],
              [114.763828073534157, -4.016003667389342],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.002,
        No_Kav: "14",
        Nm_Pemilik: "Khairus Saleh",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251641.59868,
        Y_COORD: 9555871.95266,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.763515618908301, -4.015975861738306],
              [114.762711285739798, -4.015736078080125],
              [114.762715555849084, -4.014166797047292],
              [114.762918262045133, -4.014143432313168],
              [114.76343707048693, -4.014083820579545],
              [114.763641532983826, -4.014059177648225],
              [114.763636254986707, -4.015999041638761],
              [114.763515618908301, -4.015975861738306],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.013,
        No_Kav: "15",
        Nm_Pemilik: "Agib Eko Prasetyo",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251534.5899,
        Y_COORD: 9555912.7701399997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.762711279932475, -4.015736074802103],
              [114.761711226538452, -4.015437927572695],
              [114.761716302774147, -4.013576336532204],
              [114.762111264724751, -4.013925484214964],
              [114.76246042048551, -4.014134036643726],
              [114.762606233146627, -4.014154782598752],
              [114.762715552909981, -4.014166797983497],
              [114.762711279932475, -4.015736074802103],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.002,
        No_Kav: "17",
        Nm_Pemilik: "Ahmad Zaki",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251353.95397,
        Y_COORD: 9556016.9053799994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760929389431439, -4.015199017314967],
              [114.760590087987552, -4.015112224545304],
              [114.760438288737333, -4.014790266460429],
              [114.760245561568951, -4.014301074309003],
              [114.760251229534944, -4.012221708686163],
              [114.760375054893714, -4.012361131943795],
              [114.760935690556025, -4.012886274272884],
              [114.760929389431439, -4.015199017314967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.003,
        No_Kav: "18",
        Nm_Pemilik: "Radu Suranta K",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251264.38916,
        Y_COORD: 9556142.6541900001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760245561568951, -4.014301074309003],
              [114.760155510770531, -4.014072501686798],
              [114.759324751979648, -4.012316803526324],
              [114.759328773831726, -4.010841703256119],
              [114.760251229534944, -4.012221708686163],
              [114.760245561568951, -4.014301074309003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.051,
        No_Kav: "19",
        Nm_Pemilik: "Radu Suranta K",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251153.44066,
        Y_COORD: 9556329.1359499991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759324751979648, -4.012316803526324],
              [114.758687700795363, -4.010970476157272],
              [114.758257103984988, -4.010993918608134],
              [114.758260887853169, -4.009605474539421],
              [114.758545417507804, -4.009521514796429],
              [114.759024679969173, -4.009451025501663],
              [114.759046643950938, -4.010419632793075],
              [114.759328769139131, -4.010841703314737],
              [114.759324751979648, -4.012316803526324],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.012,
        No_Kav: "20",
        Nm_Pemilik: "Herman / Sardju",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251006.97516,
        Y_COORD: 9556388.5303000007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758257102650688, -4.010993918803525],
              [114.756691223222688, -4.011079165768177],
              [114.756693741170977, -4.010155693649303],
              [114.756811607362664, -4.010228613249205],
              [114.756892071267032, -4.010166630148703],
              [114.757057667737683, -4.010249156613082],
              [114.758260887853169, -4.009605474539421],
              [114.758257102650688, -4.010993918803525],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.06,
        No_Kav: "21",
        Nm_Pemilik: "Hasnan F Norman",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 250823.92173,
        Y_COORD: 9556352.5712599996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756691223222688, -4.011079165768177],
              [114.755018230802435, -4.011170242068309],
              [114.754979176415659, -4.011034656026102],
              [114.754961906366489, -4.010840201208085],
              [114.75496280327404, -4.010511722447919],
              [114.754964869063457, -4.010150861112259],
              [114.756693741170977, -4.010155693649303],
              [114.756691223222688, -4.011079165768177],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.555,
        No_Kav: "23",
        Nm_Pemilik: "Suradi",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 250660.59801,
        Y_COORD: 9556629.9282300007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754229260389408, -4.008881681406963],
              [114.753894039866964, -4.008185107968975],
              [114.75333055081731, -4.007418163825677],
              [114.754051195594371, -4.007465170897594],
              [114.755397509064139, -4.008884900014937],
              [114.754229260389408, -4.008881681406963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.051,
        No_Kav: "22",
        Nm_Pemilik: "Y. Aryani",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 250782.93033,
        Y_COORD: 9556479.0273800008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754964869063457, -4.010150861112259],
              [114.754766527698223, -4.010096539876743],
              [114.754704150971179, -4.009758967823065],
              [114.754229264225671, -4.008881685606717],
              [114.75539751045045, -4.008884901389922],
              [114.755502710817908, -4.0089966000897],
              [114.755477649164533, -4.009164543961016],
              [114.75586016465175, -4.009639985879469],
              [114.756693742962341, -4.010155690613634],
              [114.754964869063457, -4.010150861112259],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.007,
        No_Kav: "16",
        Nm_Pemilik: "Jayadi",
        block: "Blok 2",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251435.53525,
        Y_COORD: 9555967.1571699996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.761711226538452, -4.015437927572695],
              [114.7615970245987, -4.015403880320673],
              [114.76092939056791, -4.015199017594123],
              [114.760935693981068, -4.012886274487992],
              [114.761716299156419, -4.013576333172691],
              [114.761711226538452, -4.015437927572695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.057,
        No_Kav: "8",
        Nm_Pemilik: "Sugeng W",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252141.51589000001,
        Y_COORD: 9556007.4126900006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.767970618751747, -4.012458444652191],
              [114.76800696791878, -4.012455168421655],
              [114.768000006564534, -4.015017608139204],
              [114.767352119183087, -4.015176097380058],
              [114.767359205262593, -4.012569094276028],
              [114.767723820634728, -4.01250421603876],
              [114.767970618751747, -4.012458444652191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.06,
        No_Kav: "9",
        Nm_Pemilik: "Wiyono",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252069.68242,
        Y_COORD: 9555991.8351300005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.766712681930073, -4.012829431851872],
              [114.76701871613578, -4.01262967941147],
              [114.767359203192356, -4.012569094644387],
              [114.767352119183087, -4.015176097380058],
              [114.767047221427177, -4.015250682556009],
              [114.76670585479205, -4.015340267768227],
              [114.766712681930073, -4.012829431851872],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.83,
        No_Kav: "30",
        Nm_Pemilik: "?",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 250835.82147,
        Y_COORD: 9556185.0872200001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755956501432109, -4.012489612153059],
              [114.75592816034397, -4.01240518909363],
              [114.755828079678679, -4.012358190669664],
              [114.75565304896611, -4.012235510507055],
              [114.755685984970171, -4.011969640528705],
              [114.755546742972598, -4.011847058947795],
              [114.755401505979805, -4.011618524977974],
              [114.75546886182471, -4.011484164439869],
              [114.755408844410553, -4.011189058425168],
              [114.75564544806501, -4.011174103354132],
              [114.756462457733178, -4.01112775572384],
              [114.756457865876158, -4.013229529672083],
              [114.755751413547401, -4.012911465216517],
              [114.756053079915816, -4.012777301584537],
              [114.755956501432109, -4.012489612153059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.018,
        No_Kav: "46",
        Nm_Pemilik: "Sunardi R Taruna",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252751.40167,
        Y_COORD: 9555982.7094299998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.770611423074541, -4.014595287129543],
              [114.771617583805124, -4.014369747640472],
              [114.772988132294586, -4.013838642417436],
              [114.773901930399745, -4.013507671355435],
              [114.773898958898386, -4.014604278940693],
              [114.773877839371508, -4.01460422121854],
              [114.773857157083114, -4.014604164690878],
              [114.773662018063547, -4.014603631322127],
              [114.77262022272339, -4.014600783092004],
              [114.771584336761336, -4.014597949697377],
              [114.771452376217766, -4.014597588659318],
              [114.771381320744084, -4.014597394245924],
              [114.7713760141161, -4.014597379724814],
              [114.771369244765523, -4.014597361201054],
              [114.770611423074541, -4.014595287129543],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.027,
        No_Kav: "44",
        Nm_Pemilik: "Prayitno G Taruna",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252906.72553,
        Y_COORD: 9555992.1133500002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.773901930399745, -4.013507671355435],
              [114.774367163631496, -4.013339166658158],
              [114.775235963462706, -4.013341291207007],
              [114.775232533372318, -4.014607922648239],
              [114.775174203736597, -4.014607763320603],
              [114.775095134513862, -4.014607547336035],
              [114.773898958898386, -4.014604278940693],
              [114.773901930399745, -4.013507671355435],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.001,
        No_Kav: "40",
        Nm_Pemilik: "Susilawati",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253197.00815000001,
        Y_COORD: 9555992.1542300005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.776539868790209, -4.013344478017697],
              [114.777824655585448, -4.013347616061387],
              [114.777821227477517, -4.014614989472318],
              [114.777586861045805, -4.01461435050143],
              [114.777571380885874, -4.014614308294333],
              [114.776536439697793, -4.014611483183825],
              [114.776539868790209, -4.013344478017697],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.027,
        No_Kav: "35",
        Nm_Pemilik: "Masrita Inat",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253490.92062,
        Y_COORD: 9555992.1932699997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.779115659062555, -4.013350767249078],
              [114.780292419326813, -4.013353637801016],
              [114.780536970651994, -4.014622394286333],
              [114.780050983234901, -4.014621069845637],
              [114.780047264566718, -4.014621059710192],
              [114.779112231945064, -4.014618510686313],
              [114.779115659062555, -4.013350767249078],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.011,
        No_Kav: "38",
        Nm_Pemilik: "Rita Susana",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253340.07775,
        Y_COORD: 9555992.1746699996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.777824655585448, -4.013347616061387],
              [114.779115659062555, -4.013350767249078],
              [114.779112231945064, -4.014618510686313],
              [114.778810703040662, -4.01461768844945],
              [114.777821227477517, -4.014614989472318],
              [114.777824655585448, -4.013347616061387],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.019,
        No_Kav: "51",
        Nm_Pemilik: "Fitria Utami",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252036.50284,
        Y_COORD: 9555825.74388,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.768501463380957, -4.015910324892321],
              [114.764955633313591, -4.015900920833998],
              [114.768201542319957, -4.015059609837036],
              [114.768503909346123, -4.015009713438363],
              [114.768501463380957, -4.015910324892321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.03,
        No_Kav: "50",
        Nm_Pemilik: "Fitria Utami",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252321.61932,
        Y_COORD: 9555842.4847400002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.770088777792765, -4.01591452865658],
              [114.768501466568381, -4.015910323792148],
              [114.768503911282167, -4.015009713150324],
              [114.769484563480759, -4.014847880477786],
              [114.770092043706924, -4.01471170983487],
              [114.770088777792765, -4.01591452865658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.03,
        No_Kav: "48",
        Nm_Pemilik: "Siti Dugel",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252621.11649,
        Y_COORD: 9555849.0170399994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.77261664157065, -4.015921218246218],
              [114.771365662092094, -4.015917908434411],
              [114.771369245957615, -4.014597362530524],
              [114.77262022272339, -4.014600783092004],
              [114.77261664157065, -4.015921218246218],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.007,
        No_Kav: "47",
        Nm_Pemilik: "Sunardi R Taruna",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252759.30534,
        Y_COORD: 9555849.0233100001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.773853578218464, -4.015924489038134],
              [114.77261664157065, -4.015921218246218],
              [114.77262022272339, -4.014600783092004],
              [114.773662018063547, -4.014603631322127],
              [114.773857157083114, -4.014604164690878],
              [114.773853578218464, -4.015924489038134],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.009,
        No_Kav: "45",
        Nm_Pemilik: "Prayitno G Taruna",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252896.77201,
        Y_COORD: 9555849.0295000002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.775091557939106, -4.015927760707602],
              [114.773853578218464, -4.015924489038134],
              [114.773857157083114, -4.014604164690878],
              [114.773877839371508, -4.01460422121854],
              [114.773898958898386, -4.014604278940693],
              [114.775095134513862, -4.014607547336035],
              [114.775091557939106, -4.015927760707602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.01,
        No_Kav: "43",
        Nm_Pemilik: "Hadi Sapuan",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253034.36911,
        Y_COORD: 9555849.0357000008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.776330845018137, -4.015931033947687],
              [114.775091557939106, -4.015927760707602],
              [114.775095134513862, -4.014607547336035],
              [114.775174203736597, -4.014607763320603],
              [114.775232533372318, -4.014607922648239],
              [114.776334419300895, -4.014610931669726],
              [114.776330845018137, -4.015931033947687],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.007,
        No_Kav: "41",
        Nm_Pemilik: "Eto Catur Yulianto",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253171.90968000001,
        Y_COORD: 9555849.0419100001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.777567808305889, -4.015934299170018],
              [114.776330845018137, -4.015931033947687],
              [114.776334419300895, -4.014610931669726],
              [114.776508496006002, -4.014611406900748],
              [114.776536439697793, -4.014611483183825],
              [114.777571380885874, -4.014614308294333],
              [114.777567808305889, -4.015934299170018],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.01,
        No_Kav: "39",
        Nm_Pemilik: "Susilawati",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253309.4522,
        Y_COORD: 9555849.0481899995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.778807133339129, -4.015937567830465],
              [114.777567812919457, -4.015934297699799],
              [114.777571380885874, -4.014614308294333],
              [114.777821227477517, -4.014614989472318],
              [114.778810703040662, -4.01461768844945],
              [114.778807133339129, -4.015937567830465],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.003,
        No_Kav: "36",
        Nm_Pemilik: "Riza Mutaqin",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253557.19028000001,
        Y_COORD: 9555849.0605100002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.780791476917898, -4.015942799888815],
              [114.780043697930083, -4.015940829095843],
              [114.780047264994394, -4.014621060276761],
              [114.780536970651994, -4.014622394286333],
              [114.780791476917898, -4.015942799888815],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        No_Kav: "73",
        Nm_Pemilik: "Budi Susanto ST",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 254227.70057,
        Y_COORD: 9555700.9214299992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78790797214026, -4.016097983311846],
              [114.784995782667608, -4.017177174761051],
              [114.784998251464074, -4.016090073851918],
              [114.78790797214026, -4.016097983311846],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        No_Kav: "32",
        Nm_Pemilik: "Poniran",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253438.68631,
        Y_COORD: 9556129.3235599995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.778478867584127, -4.013227872346517],
              [114.778481523845386, -4.012245197576759],
              [114.780021533122962, -4.012249394004134],
              [114.780237392612634, -4.01324574022756],
              [114.778478867584127, -4.013227872346517],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        No_Kav: "37",
        Nm_Pemilik: "Masykur",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253446.97239000001,
        Y_COORD: 9555849.0543600004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.780043697149623, -4.015940828808221],
              [114.778807133339129, -4.015937567830465],
              [114.778810703040662, -4.01461768844945],
              [114.779112231945064, -4.014618510686313],
              [114.780047264566718, -4.014621059710192],
              [114.780043697149623, -4.015940828808221],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.024,
        No_Kav: "33",
        Nm_Pemilik: "Poniran",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253281.49867,
        Y_COORD: 9556174.7279700004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.778483714558803, -4.011432205540328],
              [114.778478864476057, -4.01322787321201],
              [114.77740744943776, -4.013230103448335],
              [114.777410909678792, -4.01195064395649],
              [114.778483714558803, -4.011432205540328],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.035,
        No_Kav: "49",
        Nm_Pemilik: "Siti Dugel",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252480.70884000001,
        Y_COORD: 9555849.0106300004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.771365661297537, -4.015917908719281],
              [114.770088781744619, -4.015914528418582],
              [114.770092048453222, -4.014711709970362],
              [114.770611423074541, -4.014595287129543],
              [114.771369244765523, -4.014597361201054],
              [114.771365661297537, -4.015917908719281],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.994,
        No_Kav: "108",
        Nm_Pemilik: "Krisman ET Hutagaol",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253980.50962,
        Y_COORD: 9556445.5461400002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78489822079753, -4.010519158260649],
              [114.782270500926913, -4.010512687659479],
              [114.782301722011226, -4.01049879513971],
              [114.782569194425946, -4.01037391042841],
              [114.782634622078703, -4.01034336178364],
              [114.782711091597136, -4.010307657571585],
              [114.782751129145197, -4.01028896376802],
              [114.782799337539714, -4.010266454950487],
              [114.783478090576565, -4.009949540056512],
              [114.784115710621435, -4.009651829564431],
              [114.784249190536158, -4.009589506489924],
              [114.784904458046242, -4.009283887675164],
              [114.78489822079753, -4.010519158260649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.6,
        No_Kav: "109",
        Nm_Pemilik: "M. Ruzaidin Noor",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254102.9533,
        Y_COORD: 9556342.5979699995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784891627240256, -4.010557164292027],
              [114.785759502579324, -4.010559544761045],
              [114.785796998200098, -4.01111029109534],
              [114.785480964317003, -4.011109432341354],
              [114.784890143669145, -4.011107826585062],
              [114.784891627240256, -4.010557164292027],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.002,
        No_Kav: "112",
        Nm_Pemilik: "Iman Buhari",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254226.84181000001,
        Y_COORD: 9556558.0012899991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786262997491775, -4.008537110959646],
              [114.787027433934057, -4.008217950331249],
              [114.787223084041415, -4.009003567773711],
              [114.787284821710102, -4.009564790510803],
              [114.785691263544749, -4.009560465206948],
              [114.785643020368184, -4.00885666273126],
              [114.785831262689783, -4.008759638007882],
              [114.7858371702619, -4.008756806504976],
              [114.785843479684857, -4.008753341065244],
              [114.786262997491775, -4.008537110959646],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.003,
        No_Kav: "111",
        Nm_Pemilik: "Sudarka",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254235.95307,
        Y_COORD: 9556427.7045699991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787284821710102, -4.009564790510803],
              [114.787396004628263, -4.01057475283615],
              [114.785760635221024, -4.010570313117085],
              [114.785691263544749, -4.009560465206948],
              [114.787284821710102, -4.009564790510803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.001,
        No_Kav: "110",
        Nm_Pemilik: "Bambang Pramusinto",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254249.09247,
        Y_COORD: 9556317.6823100001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787396005700685, -4.010574755396709],
              [114.787477162054358, -4.011311972259263],
              [114.78755783678146, -4.011554763416963],
              [114.785827939039933, -4.011550064847857],
              [114.785760635700328, -4.010570317878195],
              [114.787396005700685, -4.010574755396709],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "99",
        Nm_Pemilik: "Iman Mustofa",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254269.39594,
        Y_COORD: 9556214.74,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78755783678146, -4.011554763416963],
              [114.78785106349045, -4.01243721764377],
              [114.785888514487908, -4.012431887939279],
              [114.785827938080757, -4.011550070199713],
              [114.78755783678146, -4.011554763416963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.119,
        No_Kav: "98",
        Nm_Pemilik: "Samsul Hadi",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254288.38865000001,
        Y_COORD: 9556119.9534000009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78785106211744, -4.012437219557354],
              [114.788127809081644, -4.01327007516689],
              [114.788127803686223, -4.01327007840172],
              [114.787794065572257, -4.013269172024162],
              [114.78778764773071, -4.013269154613675],
              [114.787256731474571, -4.013267712426462],
              [114.78672518084997, -4.013266268190314],
              [114.786722791562383, -4.013266261697778],
              [114.786187695297372, -4.013264807426463],
              [114.785945813673834, -4.013264149856769],
              [114.785944076570686, -4.013240716599836],
              [114.785888519587331, -4.012431895235847],
              [114.78785106211744, -4.012437219557354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.4,
        No_Kav: "109",
        Nm_Pemilik: "M. Ruzaidin Noor",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254101.01886000001,
        Y_COORD: 9556464.8892299999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785648517114481, -4.008936852641622],
              [114.785757265768197, -4.010521264827153],
              [114.784898220866452, -4.010519158294447],
              [114.784904458797925, -4.009283887112185],
              [114.785648517114481, -4.008936852641622],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "135",
        Nm_Pemilik: "Arbayah",
        block: "Blok 14",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256833.73363,
        Y_COORD: 9558424.0261199996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.810515111623204, -3.992977681731805],
              [114.810372292475037, -3.993011085980254],
              [114.809954709161673, -3.993103656573393],
              [114.809731164919839, -3.993150905164975],
              [114.809438446605327, -3.991135275491957],
              [114.809838996981483, -3.991074351630668],
              [114.810248322925787, -3.991016301067327],
              [114.810515111623204, -3.992977681731805],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "136",
        Nm_Pemilik: "Hj. Fitriah",
        block: "Blok 14",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256918.57173,
        Y_COORD: 9558441.2645399999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811179160135779, -3.990882427754554],
              [114.811233403883492, -3.992809743709502],
              [114.810515112119518, -3.992977681733131],
              [114.810248322971688, -3.99101630104443],
              [114.810872401953759, -3.990926310599385],
              [114.811179160135779, -3.990882427754554],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.102,
        No_Kav: "120",
        Nm_Pemilik: "Said Abubakar",
        block: "Blok 14",
        KET: "Sertifikat",
        KODE: "Layak",
        X_COORD: 255368.68025,
        Y_COORD: 9558705.7512500007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.795711099606109, -3.989178781782706],
              [114.795901527461979, -3.989054729168305],
              [114.797663714508843, -3.988706873887473],
              [114.797882090583755, -3.988917232748232],
              [114.796950528657504, -3.990298607060798],
              [114.795711099606109, -3.989178781782706],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.103,
        No_Kav: "119",
        Nm_Pemilik: "Said Abubakar",
        block: "Blok 14",
        KET: "Sertifikat",
        KODE: "Layak",
        X_COORD: 255274.00773000001,
        Y_COORD: 9558635.51877,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.796416934829182, -3.991089847280417],
              [114.794933184771551, -3.989685544989651],
              [114.795711098128734, -3.989178782402877],
              [114.796950528575195, -3.990298607191531],
              [114.796416934829182, -3.991089847280417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "130",
        Nm_Pemilik: "Aning Hastuti",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 257287.40664,
        Y_COORD: 9557515.8537499998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.813584245419491, -3.99911277014242],
              [114.814008159016595, -3.999033369926829],
              [114.814451323569969, -3.999548987260434],
              [114.814497196258898, -4.000088708760426],
              [114.813788230993836, -4.001560247865173],
              [114.813577716366311, -4.001574471883508],
              [114.813584245419491, -3.99911277014242],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "129",
        Nm_Pemilik: "Agung Kwartanto",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 257199.13442,
        Y_COORD: 9557509.3425500002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.812910195317727, -3.999239021125924],
              [114.813584245419491, -3.99911277014242],
              [114.813577716366311, -4.001574471883508],
              [114.813048230975269, -4.001610247815766],
              [114.812904258170605, -4.001573948489788],
              [114.812910195317727, -3.999239021125924],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "128",
        Nm_Pemilik: "Riza Mutaqin",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 257120.08781,
        Y_COORD: 9557504.2464899998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.812160251155788, -3.999375958311918],
              [114.812416305459493, -3.999331527261717],
              [114.812439467581541, -3.999327188976379],
              [114.812466320765012, -3.999322159351109],
              [114.812484367652516, -3.999318779152363],
              [114.812908200539241, -3.999239394725141],
              [114.812910195224859, -3.99923902111744],
              [114.812904257539699, -4.00157394863756],
              [114.812154803867202, -4.001385073167018],
              [114.812160251155788, -3.999375958311918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "127",
        Nm_Pemilik: "Angga Jatmika",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 257028.10397,
        Y_COORD: 9557506.8971500006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811253567951084, -3.99950048748281],
              [114.811528113443941, -3.999485647625979],
              [114.811874165460324, -3.99942560031213],
              [114.812159360115913, -3.999376112904641],
              [114.812160251128986, -3.999375958311846],
              [114.812154803676606, -4.001385073166509],
              [114.81215402883646, -4.001384879912677],
              [114.811818230970957, -4.001300247725569],
              [114.811249383452804, -4.001076543719501],
              [114.811253567951084, -3.99950048748281],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "126",
        Nm_Pemilik: "Herman",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256894.73677,
        Y_COORD: 9557518.5840799995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.80975637428206, -4.000406534687758],
              [114.81019823099038, -4.000520247599177],
              [114.810514400295204, -3.999466350820166],
              [114.810692155576874, -3.999530832900253],
              [114.811253567951084, -3.99950048748281],
              [114.811249383452804, -4.001076543719501],
              [114.810931245773901, -4.000948296033965],
              [114.809754791817255, -4.001002087227953],
              [114.80975637428206, -4.000406534687758],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "132a",
        Nm_Pemilik: "Warman",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 256939.52523,
        Y_COORD: 9557675.1280199997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.810588231086243, -3.999220247599127],
              [114.810171648359173, -3.999106073278933],
              [114.809947947359305, -3.998297568649888],
              [114.810428129818945, -3.998623751698625],
              [114.810908231131407, -3.99865024760925],
              [114.810848231147702, -3.99839024759984],
              [114.811573670122812, -3.998182999564718],
              [114.811874165460324, -3.99942560031213],
              [114.811528113443941, -3.999485647625979],
              [114.811253567951084, -3.99950048748281],
              [114.810692155576874, -3.999530832900253],
              [114.810514400295204, -3.999466350820166],
              [114.810588231086243, -3.999220247599127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "122",
        Nm_Pemilik: "Siti Majelis",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 256282.86044,
        Y_COORD: 9557694.6438900009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.806314445516776, -3.999664871186429],
              [114.80527731438697, -3.999551622017929],
              [114.804757806978614, -3.999483173618844],
              [114.804887602182731, -3.997666451552792],
              [114.805312994037166, -3.997818237192488],
              [114.805179310036806, -3.998697056320966],
              [114.80569931300235, -3.998815495526427],
              [114.805698940384673, -3.998955532652866],
              [114.806183599059437, -3.999059249111469],
              [114.806273892164924, -3.998252497667796],
              [114.806389752576777, -3.998298554116374],
              [114.806314445516776, -3.999664871186429],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.229,
        No_Kav: "125",
        Nm_Pemilik: "?",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256698.76794,
        Y_COORD: 9557521.3149800003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.80906501714891, -3.999421854404282],
              [114.808838230986154, -4.000170247499321],
              [114.80975637428206, -4.000406534687758],
              [114.809754791817255, -4.001002087227953],
              [114.808438230917773, -4.001060247490288],
              [114.808418230988337, -4.000010247467411],
              [114.807798230978989, -3.999960247424139],
              [114.807768230983086, -3.999890247420654],
              [114.807760647221997, -3.999822251077132],
              [114.807736995974508, -3.999819698019876],
              [114.807725572623525, -3.999418263888474],
              [114.80906501714891, -3.999421854404282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "132b",
        Nm_Pemilik: "Supiani",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256720.61272,
        Y_COORD: 9557679.2508899998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.809764176936994, -3.998994395923328],
              [114.809238231083555, -3.998850247499543],
              [114.80906501714891, -3.999421854404282],
              [114.807725572623525, -3.999418263888474],
              [114.807708628516508, -3.998822828094635],
              [114.807878230985693, -3.998890247585424],
              [114.809326174749771, -3.998385372513784],
              [114.80978610947767, -3.99820829011535],
              [114.809947946994185, -3.99829756875858],
              [114.810171648312988, -3.999106073245183],
              [114.809764176936994, -3.998994395923328],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "123",
        Nm_Pemilik: "Siti Rahmah",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256466.20505,
        Y_COORD: 9557656.0153899994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.806976938324453, -3.999736999562475],
              [114.806314446760439, -3.999664872442102],
              [114.806389752576777, -3.998298554116374],
              [114.806984771080536, -3.99853508363282],
              [114.806976938324453, -3.999736999562475],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "124",
        Nm_Pemilik: "Budiono",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256544.98368,
        Y_COORD: 9557638.5605100002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807736995974508, -3.999819698019876],
              [114.806976938608827, -3.999736999692508],
              [114.806984771080536, -3.99853508363282],
              [114.807708628516508, -3.998822828094635],
              [114.807725572623525, -3.999418263888474],
              [114.807736995974508, -3.999819698019876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133f",
        Nm_Pemilik: "Ibrahim Nazar",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256188.9333,
        Y_COORD: 9557864.0311399996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.803332951176657, -3.99693422525303],
              [114.803648496890787, -3.996881903804085],
              [114.803723968610242, -3.996882106420928],
              [114.803861280314919, -3.996925462483393],
              [114.80388149909227, -3.996947010482133],
              [114.80399883291841, -3.996878319288277],
              [114.80392949495328, -3.996813912210082],
              [114.804672076537898, -3.996474250321202],
              [114.80554964080703, -3.996072844933358],
              [114.805553903614452, -3.996082859802313],
              [114.805655984901534, -3.996322691526104],
              [114.805768710484514, -3.996587531111395],
              [114.804110611175517, -3.997338410486177],
              [114.80406073472038, -3.997320301346929],
              [114.803703415407583, -3.997208837844598],
              [114.803558230909147, -3.998190247018017],
              [114.802548230995399, -3.997970246994041],
              [114.802607346092088, -3.997801776073739],
              [114.802668591861689, -3.997814248588495],
              [114.803097152322479, -3.997961647550788],
              [114.803340049927868, -3.997982571931113],
              [114.80335842780174, -3.9978494055138],
              [114.80335428730352, -3.997382256799035],
              [114.803275162214121, -3.997061901110883],
              [114.803332951176657, -3.99693422525303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133c",
        Nm_Pemilik: "Sridawati Ahmad",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256257.26589000001,
        Y_COORD: 9558037.8434900008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.804006983735334, -3.994939817088865],
              [114.804393645077766, -3.994649031888622],
              [114.804614300233837, -3.994646708078819],
              [114.804981713692769, -3.99473854500464],
              [114.80554964080703, -3.996072844933358],
              [114.804672076537898, -3.996474250321202],
              [114.804006983735334, -3.994939817088865],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133e",
        Nm_Pemilik: "Suprianto bin Lasidi",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256515.92308000001,
        Y_COORD: 9558067.6378000006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.806277949001, -3.994832639477644],
              [114.806373317727534, -3.99478554388576],
              [114.806896268565325, -3.99464990959178],
              [114.807375662751298, -3.994517073851757],
              [114.80751777539939, -3.994572851904881],
              [114.807903885582562, -3.99567600305257],
              [114.807936576601676, -3.995772647596202],
              [114.806843828527647, -3.996078413336104],
              [114.80678905556033, -3.995957831672067],
              [114.806277949001, -3.994832639477644],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133k",
        Nm_Pemilik: "Djumadi A Salim",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256657.69053,
        Y_COORD: 9557772.5681599993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808502982057902, -3.99715069691109],
              [114.808625172421443, -3.997383592981597],
              [114.808761129350842, -3.997592893040408],
              [114.809048745775712, -3.99803370824429],
              [114.809216899077072, -3.998301671290775],
              [114.809265611226763, -3.998370268267503],
              [114.809159642326776, -3.998407246715543],
              [114.809155317517366, -3.998408755881865],
              [114.808878312395862, -3.998505418291694],
              [114.808731768855949, -3.99855655538366],
              [114.808589800463835, -3.998606095918674],
              [114.808523252778244, -3.998629318032725],
              [114.808507820507756, -3.998634703191752],
              [114.808495806367262, -3.998638895578906],
              [114.80807378030056, -3.998786126004251],
              [114.80749466148275, -3.997511209099465],
              [114.807487302330017, -3.997495008082302],
              [114.808502982057902, -3.99715069691109],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133h",
        Nm_Pemilik: "Djumadi A Salim",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256579.20153,
        Y_COORD: 9557920.0237700008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807936576601676, -3.995772647596202],
              [114.808220759066515, -3.996612776788457],
              [114.808502982057902, -3.99715069691109],
              [114.807487302317142, -3.997495008053979],
              [114.807351202194141, -3.9971953865034],
              [114.807347764830425, -3.997187819263214],
              [114.806858168306817, -3.996109982094472],
              [114.806843828527647, -3.996078413336104],
              [114.807936576601676, -3.995772647596202],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133j",
        Nm_Pemilik: "Jumadi bin Abdul Salim",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256519.69686,
        Y_COORD: 9557765.7121799998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807347764830425, -3.997187819263214],
              [114.80749466148275, -3.997511209099465],
              [114.80807378030056, -3.998786126004251],
              [114.807835724789584, -3.998869229753572],
              [114.807716459288869, -3.998820799198436],
              [114.807713822090435, -3.998782198020052],
              [114.807365239701426, -3.998617233327985],
              [114.806480916006265, -3.998260798043666],
              [114.806193364079206, -3.997585219137017],
              [114.807347764830425, -3.997187819263214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133g",
        Nm_Pemilik: "Arip Adi Saputra",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256449.34759,
        Y_COORD: 9557904.4302399997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.80678905556033, -3.995957831672067],
              [114.806843828527647, -3.996078413336104],
              [114.806858168306817, -3.996109982094472],
              [114.807347764830425, -3.997187819263214],
              [114.806193364079206, -3.997585219137017],
              [114.805772527284375, -3.996596498039043],
              [114.805760439712415, -3.996568099280679],
              [114.805758111164124, -3.996562628868774],
              [114.805660397363667, -3.996333056892258],
              [114.80678905556033, -3.995957831672067],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133d",
        Nm_Pemilik: "Farijal Ahmad",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256380.20112000001,
        Y_COORD: 9558040.7744600009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.804981713692769, -3.99473854500464],
              [114.805758165425715, -3.994932622665692],
              [114.806079701111202, -3.994930539448233],
              [114.806277949001, -3.994832639477644],
              [114.80678905556033, -3.995957831672067],
              [114.805660397269165, -3.996333056508317],
              [114.805553903614452, -3.996082859802313],
              [114.80554964080703, -3.996072844933358],
              [114.804981713692769, -3.99473854500464],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133i",
        Nm_Pemilik: "Hj. Mas'amah",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256315.42591,
        Y_COORD: 9557832.1290799994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.805772527284375, -3.996596498039043],
              [114.806193364079206, -3.997585219137017],
              [114.806480916006265, -3.998260798043666],
              [114.80594145444762, -3.99804362634514],
              [114.804969374204916, -3.997649073075831],
              [114.804110612046586, -3.997338411072752],
              [114.805768710532732, -3.996587531307257],
              [114.805772527284375, -3.996596498039043],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.83,
        No_Kav: "28",
        Nm_Pemilik: "Geriyati",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 250999.67052000001,
        Y_COORD: 9556158.6460900009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757690228667315, -4.013784371106304],
              [114.757112299971965, -4.013524171876236],
              [114.75712063141593, -4.011090423720396],
              [114.757696377513071, -4.011057755509571],
              [114.757690228667315, -4.013784371106304],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.83,
        No_Kav: "26",
        Nm_Pemilik: "Yohanes DH",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 251116.30455,
        Y_COORD: 9556137.0424099993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758689947016663, -4.014234467737524],
              [114.758209842351206, -4.014018313487925],
              [114.758218209486031, -4.011028151560068],
              [114.758650473546922, -4.011003626941887],
              [114.758696019809889, -4.011098726471521],
              [114.758689947016663, -4.014234467737524],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.83,
        No_Kav: "25",
        Nm_Pemilik: "Dhani Prambodo",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 251173.6581,
        Y_COORD: 9556118.0943700001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759243254602225, -4.014483591490792],
              [114.758689947292325, -4.014234467876913],
              [114.758696021024349, -4.01109873016402],
              [114.759249703264345, -4.012254745205678],
              [114.759243254602225, -4.014483591490792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.83,
        No_Kav: "24",
        Nm_Pemilik: "Rahmat H",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 251277.19291000001,
        Y_COORD: 9556021.8949699998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760549563003053, -4.015071971520625],
              [114.759243254609586, -4.014483591494503],
              [114.759249703349411, -4.01225474529155],
              [114.760123111616977, -4.014077924270075],
              [114.76039689272875, -4.014781776676203],
              [114.760549563003053, -4.015071971520625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "52",
        Nm_Pemilik: "Armah",
        block: "Blok 10",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251912.9563,
        Y_COORD: 9555712.1331999991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.764432976984622, -4.016307580317653],
              [114.76418930803716, -4.016070821532947],
              [114.764630276207711, -4.016004062100802],
              [114.767042745692848, -4.016010937440837],
              [114.76704008734184, -4.016955752287958],
              [114.765772635938632, -4.016581703267721],
              [114.764432976984622, -4.016307580317653],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "53",
        Nm_Pemilik: "Reni Damayanti",
        block: "Blok 10",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252148.13151000001,
        Y_COORD: 9555689.8054300006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76704008734184, -4.016955752287958],
              [114.767042745692848, -4.016010937440837],
              [114.76842303796461, -4.016014869144113],
              [114.76841993095637, -4.017362968027286],
              [114.76704008734184, -4.016955752287958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "54",
        Nm_Pemilik: "Reni Damayanti",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 252283.41934,
        Y_COORD: 9555673.9080299996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76841993095637, -4.017362968027286],
              [114.76842303796461, -4.016014869144113],
              [114.769478892906463, -4.016017876482989],
              [114.769472797723679, -4.017653132598654],
              [114.769329103970378, -4.017631279631782],
              [114.76841993095637, -4.017362968027286],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "55",
        Nm_Pemilik: "Suliani",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 252393.48913,
        Y_COORD: 9555665.5825599991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.77040150350949, -4.017806088323249],
              [114.770286491811731, -4.017776878892053],
              [114.769472797723679, -4.017653132598654],
              [114.769478892906463, -4.016017876482989],
              [114.770406041410936, -4.016020514623563],
              [114.77040150350949, -4.017806088323249],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "56",
        Nm_Pemilik: "Suliani",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 252492.5052,
        Y_COORD: 9555658.1668900009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.77125323087013, -4.017942398574623],
              [114.770458811978116, -4.017820642161765],
              [114.77040150350949, -4.017806088323249],
              [114.770406041410936, -4.016020514623563],
              [114.77126020847605, -4.0160229430583],
              [114.77125323087013, -4.017942398574623],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "57",
        Nm_Pemilik: "Adi Sugito",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 252584.25595,
        Y_COORD: 9555651.5078699999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.772052458187744, -4.018064892734643],
              [114.77125323087013, -4.017942398574623],
              [114.77126020847605, -4.0160229430583],
              [114.772059662266585, -4.016025215048512],
              [114.772052458187744, -4.018064892734643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "58",
        Nm_Pemilik: "Adi Sugito",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 252670.6494,
        Y_COORD: 9555645.8134899996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.772052458187744, -4.018064892734643],
              [114.772059662266585, -4.016025215048512],
              [114.772814690572403, -4.016027359898056],
              [114.772809701592678, -4.018169836556756],
              [114.772291240210663, -4.01810148927494],
              [114.772052458187744, -4.018064892734643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "59",
        Nm_Pemilik: "Semiaty",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 252752.86148,
        Y_COORD: 9555640.6457400005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.772809701592678, -4.018169836556756],
              [114.772814690572403, -4.016027359898056],
              [114.773537360016761, -4.016029412930092],
              [114.773532878398825, -4.018265169773205],
              [114.772809701592678, -4.018169836556756],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "60",
        Nm_Pemilik: "Semiaty",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 252831.52409,
        Y_COORD: 9555635.6913600005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.773532878398825, -4.018265169773205],
              [114.773537360016761, -4.016029412930092],
              [114.774231970699176, -4.016031384868255],
              [114.774226189826933, -4.018356565711809],
              [114.773532878398825, -4.018265169773205],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "61",
        Nm_Pemilik: "Darma Syaputra",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 252908.04155,
        Y_COORD: 9555634.9498200007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.774329459019157, -4.018370182389431],
              [114.774226189826933, -4.018356565711809],
              [114.774231970699176, -4.016031384868255],
              [114.774913967694701, -4.016033320141815],
              [114.774909264008684, -4.018328452834968],
              [114.774329459019157, -4.018370182389431],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "62",
        Nm_Pemilik: "Darma Syaputra",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 252984.69308,
        Y_COORD: 9555637.3416200001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.774909264008684, -4.018328452834968],
              [114.774913967694701, -4.016033320141815],
              [114.775613504019773, -4.016035305546251],
              [114.775604951780124, -4.018278391116556],
              [114.774909264008684, -4.018328452834968],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "63",
        Nm_Pemilik: "Tusti Lestari",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253063.01948,
        Y_COORD: 9555640.2116,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.775604951780124, -4.018278391116556],
              [114.775613504019773, -4.016035305546251],
              [114.776326244885524, -4.016037326956955],
              [114.776320681058593, -4.018226884625077],
              [114.775604951780124, -4.018278391116556],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "64",
        Nm_Pemilik: "Tusti Lestari",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253143.39534,
        Y_COORD: 9555643.1644599997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.776320681058593, -4.018226884625077],
              [114.776326244885524, -4.016037326956955],
              [114.777058466607556, -4.016039403699949],
              [114.777052670113136, -4.018174209358795],
              [114.776320681058593, -4.018226884625077],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "65",
        Nm_Pemilik: "Gialen Dani",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253225.79671,
        Y_COORD: 9555646.1842199992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.777052670113136, -4.018174209358795],
              [114.777058466607556, -4.016039403699949],
              [114.77780979718662, -4.016041533375956],
              [114.777804116621169, -4.018120131525067],
              [114.777052670113136, -4.018174209358795],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "66",
        Nm_Pemilik: "Gialen Dani",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253310.43438,
        Y_COORD: 9555649.2843800001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.777804120139038, -4.018120131534676],
              [114.77780979718662, -4.016041533375956],
              [114.778581647427529, -4.016043721720629],
              [114.778576832476574, -4.018064525293246],
              [114.777804120139038, -4.018120131534676],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "67",
        Nm_Pemilik: "Nana Lia",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253397.44701,
        Y_COORD: 9555652.4720600005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.778576832476574, -4.018064525293246],
              [114.778581647427529, -4.016043721720629],
              [114.779376958969962, -4.01604597463831],
              [114.779371558811903, -4.01800733173135],
              [114.778576832476574, -4.018064525293246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "68",
        Nm_Pemilik: "Nana Lia",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253486.90865,
        Y_COORD: 9555655.7507399991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.780185424675935, -4.01797105312071],
              [114.779979735668633, -4.017983451520885],
              [114.779422171204772, -4.018003689410725],
              [114.779371558811903, -4.01800733173135],
              [114.779376958969962, -4.01604597463831],
              [114.78019239291298, -4.016048282937703],
              [114.780185424675935, -4.01797105312071],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "69",
        Nm_Pemilik: "Sugito",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253578.43159,
        Y_COORD: 9555657.8753900006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781021350611297, -4.017917420273459],
              [114.780839316302774, -4.01793131556744],
              [114.780185424675935, -4.01797105312071],
              [114.78019239291298, -4.016048282937703],
              [114.781026330553232, -4.01605057144955],
              [114.781021350611297, -4.017917420273459],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "70",
        Nm_Pemilik: "Sri W",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253672.81664,
        Y_COORD: 9555660.9773200005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78188507482065, -4.017851488915031],
              [114.781021350611297, -4.017917420273459],
              [114.781026330553232, -4.01605057144955],
              [114.781889984163513, -4.016052738432975],
              [114.78188507482065, -4.017851488915031],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "71a",
        Nm_Pemilik: "Muji Astuti SE",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253770.64489,
        Y_COORD: 9555664.7647799999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782782999249747, -4.017782945716541],
              [114.78188507482065, -4.017851488915031],
              [114.781889984163513, -4.016052738432975],
              [114.78278746709816, -4.016054990654346],
              [114.782782999249747, -4.017782945716541],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        No_Kav: "71b",
        Nm_Pemilik: "Maryono",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253876.06295,
        Y_COORD: 9555668.6932399999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.783783155010809, -4.017626545867384],
              [114.783511095174433, -4.017727365658813],
              [114.782782999288372, -4.017782945703733],
              [114.782787467106914, -4.016054990663193],
              [114.782962251418439, -4.016055429142646],
              [114.783319177637523, -4.016235864363295],
              [114.783787825756875, -4.016147127001957],
              [114.783783155010809, -4.017626545867384],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        No_Kav: "72",
        Nm_Pemilik: "Muji Astuti SE",
        block: "Blok 10",
        KET: "Masuk SK-435",
        KODE: "Layak",
        X_COORD: 253998.85039000001,
        Y_COORD: 9555675.8846300002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78499578224195, -4.017177174759895],
              [114.783783155417964, -4.017626545664341],
              [114.783787825785041, -4.016147126987906],
              [114.784102023542786, -4.016087634070234],
              [114.784998251212016, -4.016090073851232],
              [114.78499578224195, -4.017177174759895],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "133b",
        Nm_Pemilik: "Faisal Ahmad",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256160.38185000001,
        Y_COORD: 9558002.6457499992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.80392949495328, -3.996813912210082],
              [114.803802190049112, -3.996695660415381],
              [114.803770935680134, -3.996588107912864],
              [114.803629021906033, -3.996580939461063],
              [114.803530943063947, -3.996610088613404],
              [114.803454227490064, -3.996654001333101],
              [114.803417380106268, -3.996531727600655],
              [114.803439011384015, -3.996445810812081],
              [114.803432121841183, -3.996443813966563],
              [114.80313713666304, -3.995714576868083],
              [114.803270599197106, -3.995544037290383],
              [114.803887462670644, -3.995029623592403],
              [114.804006983735334, -3.994939817088865],
              [114.804672076537898, -3.996474250321202],
              [114.80392949495328, -3.996813912210082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.872,
        No_Kav: "133a",
        Nm_Pemilik: "Supiani ahmad",
        block: "Blok 13",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256033.37341,
        Y_COORD: 9557959.1417399999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.803432121841183, -3.996443813966563],
              [114.803320825239695, -3.996411556067752],
              [114.803156280293933, -3.996442789223706],
              [114.803075302926416, -3.996395059380268],
              [114.803007568798506, -3.99645030865083],
              [114.802932892270618, -3.996574545384864],
              [114.802828934228572, -3.996696440966014],
              [114.802758230717231, -3.996820246792663],
              [114.80208823110398, -3.996580247040333],
              [114.802339810652526, -3.996197557873896],
              [114.802831221824718, -3.995913144507987],
              [114.803072502588037, -3.995797166561821],
              [114.803137136553474, -3.995714576977544],
              [114.803432121841183, -3.996443813966563],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "87",
        Nm_Pemilik: "Masdiana",
        block: "Blok 11",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 255213.83655000001,
        Y_COORD: 9556293.2705300003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.794571758837336, -4.010251772872064],
              [114.796116077351613, -4.01182676402295],
              [114.794569748488954, -4.012357564126138],
              [114.794571758837336, -4.010251772872064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "86",
        Nm_Pemilik: "M. Nasrullah",
        block: "Blok 11",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 255088.61189,
        Y_COORD: 9556290.6388300005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.793861938798557, -4.010316952855756],
              [114.794008386582021, -4.010279390496468],
              [114.794376377312673, -4.010052510964329],
              [114.794571758837336, -4.010251772872064],
              [114.794569748488954, -4.012357564126138],
              [114.793857795904444, -4.012601952703864],
              [114.793861938798557, -4.010316952855756],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "85",
        Nm_Pemilik: "M. Nasrullah",
        block: "Blok 11",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 255010.10854,
        Y_COORD: 9556262.4629699998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.793159356774993, -4.010504099220622],
              [114.793192020360294, -4.010488779502678],
              [114.793861938798557, -4.010316952855756],
              [114.793857795904444, -4.012601952703864],
              [114.793153697946039, -4.01284364255776],
              [114.793159356774993, -4.010504099220622],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "84",
        Nm_Pemilik: "Yahya",
        block: "Blok 11",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 254931.79995,
        Y_COORD: 9556231.4080500007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.792452045373778, -4.010835821675369],
              [114.793159356774993, -4.010504099220622],
              [114.793153697946039, -4.01284364255776],
              [114.792545450399459, -4.013052430760413],
              [114.792446465988263, -4.013214163080968],
              [114.792452045373778, -4.010835821675369],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "83",
        Nm_Pemilik: "Siti Aminah",
        block: "Blok 11",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 254859.60152,
        Y_COORD: 9556158.2680300009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.791857724687659, -4.011094630038372],
              [114.791950335231618, -4.011043376032837],
              [114.792298855447854, -4.010907665907407],
              [114.792452045373778, -4.010835821675369],
              [114.792446465980404, -4.013214166432274],
              [114.791852138747657, -4.014201312224458],
              [114.791857724687659, -4.011094630038372],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "82",
        Nm_Pemilik: "Farida",
        block: "Blok 11",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 254799.00985,
        Y_COORD: 9556117.6508499999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.791357821612465, -4.011349001463723],
              [114.791621785250967, -4.011225207312001],
              [114.791857724687659, -4.011094630038372],
              [114.791852138747657, -4.014201312224458],
              [114.791636387858375, -4.014558832783585],
              [114.791360880824328, -4.014673935078771],
              [114.791357821612465, -4.011349001463723],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "81",
        Nm_Pemilik: "Teguh P",
        block: "Blok 11",
        KET: "Masuk SK-435",
        KODE: "Tidak Layak",
        X_COORD: 254744.74858000001,
        Y_COORD: 9556091.9674800001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.790870272966671, -4.01157765553368],
              [114.791357821612465, -4.011349001463723],
              [114.791360880824328, -4.014673935078771],
              [114.790866190884728, -4.014881231657513],
              [114.790870272966671, -4.01157765553368],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "80",
        Nm_Pemilik: "M Haris S",
        block: "Blok 11",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254689.98355,
        Y_COORD: 9556067.6635200009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.790377793316623, -4.011808622655476],
              [114.790870272966671, -4.01157765553368],
              [114.790866190884728, -4.014881231657513],
              [114.79036904702599, -4.015089331586913],
              [114.790377793316623, -4.011808622655476],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "79",
        Nm_Pemilik: "Riza Mutaqin",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254632.60386,
        Y_COORD: 9556042.4008699991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.789838990300154, -4.012671928964158],
              [114.790232120558471, -4.011876944197409],
              [114.790377793316623, -4.011808622655476],
              [114.79036904702599, -4.015089331586913],
              [114.789836285109274, -4.015312340786712],
              [114.789838990300154, -4.012671928964158],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "107",
        Nm_Pemilik: "Suhasri Said",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253567.05537,
        Y_COORD: 9556189.5571999997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781019942794842, -4.013249760459535],
              [114.780348254137039, -4.013247530309751],
              [114.780006608914348, -4.011550188874382],
              [114.780622433861652, -4.011335350566352],
              [114.781030954017695, -4.011161217440165],
              [114.781019942794842, -4.013249760459535],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "106",
        Nm_Pemilik: "Odieta Jesse",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253662.73685,
        Y_COORD: 9556211.6453000009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781729180536587, -4.013251984982094],
              [114.781019942794842, -4.013249760459535],
              [114.781030954017695, -4.011161217440165],
              [114.781239452628498, -4.011072344058193],
              [114.781210950054387, -4.011008548958147],
              [114.781736741503252, -4.010763487225981],
              [114.781729180536587, -4.013251984982094],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "105",
        Nm_Pemilik: "Nirwaty Ginting",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253736.65016,
        Y_COORD: 9556223.5403899997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782348183961233, -4.013253772156346],
              [114.781729180536587, -4.013251984982094],
              [114.781736741503252, -4.010763487225981],
              [114.782195300422259, -4.010549760856326],
              [114.782358801073883, -4.010550011443973],
              [114.782348183961233, -4.013253772156346],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "104",
        Nm_Pemilik: "Ries Aryanto",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253804.46356,
        Y_COORD: 9556223.5316400006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782950129090693, -4.013255568482355],
              [114.782348183961233, -4.013253772156346],
              [114.782358801073883, -4.010550011443973],
              [114.782960693858342, -4.010551722190397],
              [114.782950129090693, -4.013255568482355],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "103",
        Nm_Pemilik: "M. Widiawan F.",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253871.27195,
        Y_COORD: 9556223.5143199991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.783553106626968, -4.013257448300831],
              [114.782950129090693, -4.013255568482355],
              [114.782960693858342, -4.010551722190397],
              [114.783561551249207, -4.010553429550994],
              [114.783553106626968, -4.013257448300831],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "102",
        Nm_Pemilik: "M. Rahdianto",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253938.17663,
        Y_COORD: 9556223.5052199997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784155820780896, -4.013259178557909],
              [114.783553106626968, -4.013257448300831],
              [114.783561551249207, -4.010553429550994],
              [114.784162615559239, -4.010555126531139],
              [114.784155820780896, -4.013259178557909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "101",
        Nm_Pemilik: "M. Faried",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254005.14549,
        Y_COORD: 9556223.4975799993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784442806844098, -4.010555932873921],
              [114.784766583077598, -4.010557031963386],
              [114.784755633181859, -4.013260884574359],
              [114.784155820780896, -4.013259178557909],
              [114.784162615559239, -4.010555126531139],
              [114.784442806844098, -4.010555932873921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "97",
        Nm_Pemilik: "Siti Mahmudah",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254144.60745000001,
        Y_COORD: 9556193.03596,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785487794552893, -4.011109451002704],
              [114.785796998200098, -4.01111029109534],
              [114.785802993523575, -4.01119587600797],
              [114.785810699348914, -4.011311539924454],
              [114.785871747957174, -4.012208247658711],
              [114.785945813673834, -4.013264149856769],
              [114.785479657690971, -4.013262871156126],
              [114.785487794552893, -4.011109451002704],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "100",
        Nm_Pemilik: "M. Jiwaroh M.",
        block: "Blok 6",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254078.63006,
        Y_COORD: 9556223.4934199993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784766583077598, -4.010557031963386],
              [114.784891627240256, -4.010557164292027],
              [114.784890143669145, -4.011107826585062],
              [114.785487794324581, -4.011109451002084],
              [114.785479657690971, -4.013262871156126],
              [114.784755633181859, -4.013260884574359],
              [114.784766583077598, -4.010557031963386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "93",
        Nm_Pemilik: "Fajar Kurnia",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253601.28766,
        Y_COORD: 9555919.2915700004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781263007039598, -4.015944344578307],
              [114.780909591779675, -4.015941341108702],
              [114.780847232249883, -4.015686535659812],
              [114.780373083616311, -4.013353211747936],
              [114.781267742937359, -4.013354400546952],
              [114.781263007039598, -4.015944344578307],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "94",
        Nm_Pemilik: "Noor Riwandi",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253685.817,
        Y_COORD: 9555919.1428399999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781889138893092, -4.015949665331451],
              [114.781263007039598, -4.015944344578307],
              [114.781267742937359, -4.013354400546952],
              [114.781897197258672, -4.013355188655313],
              [114.781889138893092, -4.015949665331451],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "95",
        Nm_Pemilik: "Noor Riwandi",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253755.509,
        Y_COORD: 9555918.967,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782516299300809, -4.015955048769914],
              [114.781889138893092, -4.015949665331451],
              [114.781897197258672, -4.013355188655313],
              [114.782523519487185, -4.013356397675607],
              [114.782516299300809, -4.015955048769914],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "96",
        Nm_Pemilik: "Etty Nanda Ningsih",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253841.09784,
        Y_COORD: 9555861.33378,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.783427898583597, -4.016113038115172],
              [114.783332523626584, -4.01613168019356],
              [114.78297686252327, -4.015958907465776],
              [114.782516299300809, -4.015955048769914],
              [114.782521105487703, -4.01422545867016],
              [114.783434935086774, -4.014226491327235],
              [114.783427898583597, -4.016113038115172],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.976,
        No_Kav: "97",
        Nm_Pemilik: "Siti Mahmudah",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253841.15519,
        Y_COORD: 9556014.7105700001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782521105546934, -4.014225458551995],
              [114.782523525702629, -4.013356398512999],
              [114.78343817574752, -4.013357424039509],
              [114.783434935086774, -4.014226491327235],
              [114.782521105546934, -4.014225458551995],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "92",
        Nm_Pemilik: "Djoko W",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253921.91808,
        Y_COORD: 9555910.5316499993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.783967430306788, -4.016008683266007],
              [114.783427898583597, -4.016113038115172],
              [114.783434935086774, -4.014226491327235],
              [114.78343817574752, -4.013357424039509],
              [114.783980339928348, -4.013357367276359],
              [114.783967430306788, -4.016008683266007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "91",
        Nm_Pemilik: "Donny Hadi Nugroho",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253982.69236,
        Y_COORD: 9555916.4233999997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784523772138229, -4.015988740896884],
              [114.784098048245866, -4.015983431811174],
              [114.783967430306788, -4.016008683266007],
              [114.783980339928348, -4.013357367276359],
              [114.78453526661194, -4.013358175518175],
              [114.784523772138229, -4.015988740896884],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "90",
        Nm_Pemilik: "Kunti Rahayu",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254044.39609,
        Y_COORD: 9555917.4142799992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.784774710279081, -4.015992456282604],
              [114.784523772138229, -4.015988740896884],
              [114.78453526661194, -4.013358175518175],
              [114.785086186041127, -4.013359043560729],
              [114.785082783216154, -4.015994432690687],
              [114.784774710279081, -4.015992456282604],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "89",
        Nm_Pemilik: "Paimin Kusprayetno",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254106.06157,
        Y_COORD: 9555917.3419899996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785086186041127, -4.013359043560729],
              [114.785639879487761, -4.013359807048142],
              [114.785637677412581, -4.015997990516537],
              [114.785082783216154, -4.015994432690687],
              [114.785086186041127, -4.013359043560729],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "88b",
        Nm_Pemilik: "Teguh P",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254166.95621,
        Y_COORD: 9555922.56776,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.785639879487761, -4.013359807048142],
              [114.785952155889419, -4.013360237479803],
              [114.785945810045192, -4.013264157744178],
              [114.786187695297372, -4.013264807426463],
              [114.786180212052102, -4.016001470812459],
              [114.785637677412581, -4.015997990516537],
              [114.785639879487761, -4.013359807048142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "88a",
        Nm_Pemilik: "Djoko W",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254227.08473,
        Y_COORD: 9555922.4588900004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786187695297372, -4.013264807426463],
              [114.786722791562383, -4.013266261697778],
              [114.786712852294855, -4.016004887286179],
              [114.786180212052102, -4.016001470812459],
              [114.786187695297372, -4.013264807426463],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "74",
        Nm_Pemilik: "Budi Susanto ST",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254286.32671,
        Y_COORD: 9555922.3493399993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.786722791562383, -4.013266261697778],
              [114.787256731474571, -4.013267712426462],
              [114.78724587910726, -4.016008316120582],
              [114.786712852294855, -4.016004887286179],
              [114.786722791562383, -4.013266261697778],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "75",
        Nm_Pemilik: "Ari Wibowo",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254345.47221000001,
        Y_COORD: 9555922.2402400002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.787256731474571, -4.013267712426462],
              [114.78778764773071, -4.013269154613675],
              [114.787781162051033, -4.01601174280937],
              [114.78724587910726, -4.016008316120582],
              [114.787256731474571, -4.013267712426462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "76",
        Nm_Pemilik: "Ari Wibowo",
        block: "Blok 9",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254406.45928000001,
        Y_COORD: 9555922.1644400004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78778764773071, -4.013269154613675],
              [114.788127803686223, -4.01327007840172],
              [114.788347196102833, -4.013930232374477],
              [114.788344994273245, -4.015934079108333],
              [114.78815300163609, -4.01601412298596],
              [114.787781162051033, -4.01601174280937],
              [114.78778764773071, -4.013269154613675],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "78",
        Nm_Pemilik: "H. Maksum",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254567.64071000001,
        Y_COORD: 9555979.7561700009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.789199183583008, -4.013552812498377],
              [114.789555523062802, -4.013110922406957],
              [114.789654701704166, -4.013044594876165],
              [114.789838990300154, -4.012671928964158],
              [114.789836285109274, -4.015312340786712],
              [114.789197937002143, -4.015578476860093],
              [114.789199183583008, -4.013552812498377],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.796,
        No_Kav: "77",
        Nm_Pemilik: "H. Maksum",
        block: "Blok 7",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 254484.95907000001,
        Y_COORD: 9555911.1435400005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.788347196102833, -4.013930232374477],
              [114.788370400335324, -4.014000141129568],
              [114.788437507865865, -4.014496731335982],
              [114.78919918349564, -4.013552812676072],
              [114.789197937002143, -4.015578476860093],
              [114.788344994273245, -4.015934079108333],
              [114.788347196102833, -4.013930232374477],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.027,
        No_Kav: "134",
        Nm_Pemilik: "Supiani Ahmad",
        block: "Blok 14",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 256766.88501,
        Y_COORD: 9558412.7920999993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.809731164885079, -3.993150905200967],
              [114.809347916646004, -3.993231858063531],
              [114.809018232281119, -3.991200245738428],
              [114.809438446265915, -3.991135275491049],
              [114.809731164885079, -3.993150905200967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "1b",
        Nm_Pemilik: "Sibli",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252624.23877,
        Y_COORD: 9556256.6940400004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.771425846383536, -4.011336628105433],
              [114.77141852073666, -4.011023250640129],
              [114.771602690167526, -4.010720195701238],
              [114.772643430021404, -4.012174657008337],
              [114.772198770761193, -4.012427151175983],
              [114.771425846383536, -4.011336628105433],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.008,
        No_Kav: "1a",
        Nm_Pemilik: "Halimah",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252720.95987,
        Y_COORD: 9556105.9806299992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.772643430021404, -4.012174657008337],
              [114.773597103313506, -4.013507437426323],
              [114.773103009329674, -4.013702920807857],
              [114.772198776675395, -4.012427152238743],
              [114.772643430021404, -4.012174657008337],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.008,
        No_Kav: "2b",
        Nm_Pemilik: "Sariati",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252624.69989,
        Y_COORD: 9556111.1651499998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.771462030187735, -4.012597206773272],
              [114.771582927246229, -4.012526562853897],
              [114.771272469053628, -4.011888739016329],
              [114.771395670808175, -4.011803006653653],
              [114.772790960969459, -4.013826288507074],
              [114.772413462023721, -4.013975538471623],
              [114.771462030187735, -4.012597206773272],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "2a",
        Nm_Pemilik: "Sri Setiti",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252648.84365,
        Y_COORD: 9556145.2761700004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.771395670808175, -4.011803006653653],
              [114.771436304942483, -4.011774730582175],
              [114.771425846004263, -4.011336640626509],
              [114.773103005600817, -4.013702923115374],
              [114.77279096226097, -4.013826288457398],
              [114.771395670808175, -4.011803006653653],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.013,
        No_Kav: "6b",
        Nm_Pemilik: "Masliana",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252269.02259000001,
        Y_COORD: 9556028.2407699991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76866420759842, -4.012395929662198],
              [114.76899827499895, -4.012365669432408],
              [114.76898745139826, -4.014842001882828],
              [114.768658524301486, -4.014895318380726],
              [114.76866420759842, -4.012395929662198],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.01,
        No_Kav: "6a",
        Nm_Pemilik: "H. Mahudin",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 252305.76009,
        Y_COORD: 9556032.9515700005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.768998279228043, -4.012365667782933],
              [114.769329883284541, -4.012335623313342],
              [114.769323225327369, -4.014787575410195],
              [114.76898745139826, -4.014842001882828],
              [114.768998279228043, -4.012365667782933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.011,
        No_Kav: "11b",
        Nm_Pemilik: "Udin",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251902.89708,
        Y_COORD: 9555918.5107300002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.765296078440585, -4.01394691479267],
              [114.765577082611884, -4.013978658115031],
              [114.765767426029768, -4.013519414039527],
              [114.765751439258622, -4.015590740396062],
              [114.765577165019494, -4.015636477444523],
              [114.765291289418073, -4.015707411919204],
              [114.765296078440585, -4.01394691479267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.016,
        No_Kav: "11a",
        Nm_Pemilik: "Edy",
        block: "Blok 3",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 251947.07337,
        Y_COORD: 9555970.15233,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.765767426029768, -4.013519414039527],
              [114.766105144905495, -4.012704592872677],
              [114.766097543718871, -4.01549991120125],
              [114.76575144158862, -4.015590740338132],
              [114.765767426029768, -4.013519414039527],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.915,
        No_Kav: "27b",
        Nm_Pemilik: "Indah PS",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 251046.94898,
        Y_COORD: 9556152.7297300007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757963728288246, -4.013907507156042],
              [114.757690228667315, -4.013784371106304],
              [114.757696377513071, -4.011057755509571],
              [114.757955573678274, -4.011043052488247],
              [114.757963728288246, -4.013907507156042],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.915,
        No_Kav: "27a",
        Nm_Pemilik: "Parsinah",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 251075.04243,
        Y_COORD: 9556147.5026200004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758209842351206, -4.014018313487925],
              [114.757963728288246, -4.013907507156042],
              [114.757955574103477, -4.011043052890537],
              [114.758218209486031, -4.011028151560068],
              [114.758209842351206, -4.014018313487925],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.915,
        No_Kav: "29b",
        Nm_Pemilik: "?",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 250914.01303,
        Y_COORD: 9556177.5762600005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756805554366792, -4.013386067589712],
              [114.756457865876158, -4.013229529672083],
              [114.756462457733178, -4.01112775572384],
              [114.756795115436773, -4.011108888041669],
              [114.756805554366792, -4.013386067589712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.915,
        No_Kav: "29a",
        Nm_Pemilik: "Indah PS",
        block: "Blok 1",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 250949.64013000001,
        Y_COORD: 9556171.0553600006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757112299971965, -4.013524171876236],
              [114.756805554366792, -4.013386067589712],
              [114.756795117229785, -4.011108887081416],
              [114.75712062677745, -4.011090421375353],
              [114.757112299971965, -4.013524171876236],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.001,
        No_Kav: "31b",
        Nm_Pemilik: "Yusuf",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253386.0671,
        Y_COORD: 9556251.0050600003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.778481523841791, -4.012245194568],
              [114.77848371955659, -4.011432199508597],
              [114.779286315885017, -4.011043126534418],
              [114.779292741106602, -4.012247405709076],
              [114.778481523841791, -4.012245194568],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.001,
        No_Kav: "31a",
        Nm_Pemilik: "Siti Rahmah",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253471.0676,
        Y_COORD: 9556265.1319200005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.779286315989268, -4.01104312632173],
              [114.779810248404672, -4.010789137205619],
              [114.779883023477765, -4.011610073122601],
              [114.780021527403051, -4.012249390994651],
              [114.779292742873267, -4.012247405713888],
              [114.779286315989268, -4.01104312632173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.016,
        No_Kav: "34b",
        Nm_Pemilik: "Samlani",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253036.80707000001,
        Y_COORD: 9556125.5890800003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.776646029129765, -4.013225908705256],
              [114.774825054780706, -4.01321587493886],
              [114.776658366612381, -4.012317785226849],
              [114.776646029129765, -4.013225908705256],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.016,
        No_Kav: "34a",
        Nm_Pemilik: "Indah PS",
        block: "Blok 5",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253179.69593,
        Y_COORD: 9556145.8911000006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.77740744663258, -4.013230103114963],
              [114.776646029313937, -4.013225908576573],
              [114.776658370944531, -4.012317783996565],
              [114.776821415399993, -4.012237911710869],
              [114.777321914224217, -4.011993482735609],
              [114.777410904217007, -4.011950656929292],
              [114.77740744663258, -4.013230103114963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.015,
        No_Kav: "42b",
        Nm_Pemilik: "Arbain",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253017.10078000001,
        Y_COORD: 9555992.1236300003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.775235963462706, -4.013341291207007],
              [114.775889555162777, -4.013342889716724],
              [114.775883047935864, -4.014609696960864],
              [114.775232533372318, -4.014607922648239],
              [114.775235963462706, -4.013341291207007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.015,
        No_Kav: "42a",
        Nm_Pemilik: "Dwi SR",
        block: "Blok 8",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253089.354,
        Y_COORD: 9555992.1340800002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.77588955520369, -4.013342889735107],
              [114.776539868790209, -4.013344478017697],
              [114.776536436490318, -4.014611478589703],
              [114.775883047935864, -4.014609696960864],
              [114.77588955520369, -4.013342889735107],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.718,
        No_Kav: "113b",
        Nm_Pemilik: "Sapuani",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253660.18056000001,
        Y_COORD: 9556424.9124599993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.78098296382727, -4.010495293243371],
              [114.780831325738504, -4.010180624949013],
              [114.781890590010789, -4.009662649379426],
              [114.781888219296206, -4.010497694538392],
              [114.78098296382727, -4.010495293243371],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "113a",
        Nm_Pemilik: "Rajudin",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253764.60238,
        Y_COORD: 9556447.3073699996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.781890592237303, -4.009662647711214],
              [114.782713916320901, -4.009260042812888],
              [114.782711091597136, -4.010307657571585],
              [114.782634622078703, -4.01034336178364],
              [114.782569194425946, -4.01037391042841],
              [114.782301722011226, -4.01049879513971],
              [114.78188822436357, -4.010497696798757],
              [114.781890592237303, -4.009662647711214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.005,
        No_Kav: "114b",
        Nm_Pemilik: "Abdul Kadir",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253853.28969000001,
        Y_COORD: 9556479.0262499992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.782802167600948, -4.009216887189799],
              [114.783489170918116, -4.008880942532412],
              [114.783486427658815, -4.009945645967202],
              [114.782799337539714, -4.010266454950487],
              [114.782751129145197, -4.01028896376802],
              [114.782711091597136, -4.010307657571585],
              [114.782713916320901, -4.009260042812888],
              [114.782802167600948, -4.009216887189799],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.005,
        No_Kav: "114a",
        Nm_Pemilik: "Salafudin",
        block: "Blok 4",
        KET: "Sporadik Bebas SK-435",
        KODE: "Layak",
        X_COORD: 253938.60678,
        Y_COORD: 9556519.9145100005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.783489171441332, -4.008880942082663],
              [114.784252104189918, -4.00850786555928],
              [114.784249190536158, -4.009589506489924],
              [114.784115710621435, -4.009651829564431],
              [114.783486429091639, -4.009945645611595],
              [114.783489171441332, -4.008880942082663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "121",
        Nm_Pemilik: "Rifina F Barasa",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 256238.12401,
        Y_COORD: 9557712.4876700006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.804757806374752, -3.999483173821831],
              [114.804308230943818, -3.999420247175218],
              [114.804478231075677, -3.997520247147893],
              [114.804887601849003, -3.997666451263162],
              [114.804757806374752, -3.999483173821831],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "131b",
        Nm_Pemilik: "M Salim",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 257064.55898,
        Y_COORD: 9557690.3277000003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811573670122812, -3.998182999564718],
              [114.812038231195132, -3.998050247673976],
              [114.812158626162358, -3.998018142496933],
              [114.812500139997553, -3.999315816655229],
              [114.812439467581541, -3.999327188976379],
              [114.812416305459493, -3.999331527261717],
              [114.812159360115913, -3.999376112904641],
              [114.811874165460324, -3.99942560031213],
              [114.811573670122812, -3.998182999564718],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "131a",
        Nm_Pemilik: "Hamdanah",
        block: "Blok 12",
        KET: "Sporadik Bebas SK-435",
        KODE: "Tidak Layak",
        X_COORD: 257180.74377,
        Y_COORD: 9557699.1680200007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81215862637093, -3.99801814231974],
              [114.812338231206667, -3.997970247692779],
              [114.812448231178536, -3.998420247709512],
              [114.812888231194322, -3.998320247737437],
              [114.812938231176446, -3.998600247746585],
              [114.813118231182173, -3.998570247758236],
              [114.813258231172199, -3.998760247771679],
              [114.813308231149549, -3.999110247782264],
              [114.813968231171259, -3.998990247824779],
              [114.814008159016595, -3.999033369926829],
              [114.813584245419491, -3.99911277014242],
              [114.812908200539241, -3.999239394725141],
              [114.812500151060419, -3.999315812245545],
              [114.81215862637093, -3.99801814231974],
            ],
          ],
        ],
      },
    },
  ],
};
