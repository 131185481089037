import React, { useMemo, useRef, useState } from "react";
import { create } from "zustand";
import { MdDragIndicator } from "react-icons/md";
import { useMeasure } from "@uidotdev/usehooks";
import ColumnSettings from "../../../../../../../components/atoms/Table/partials/ColumnSettings";
import { MaterialReactTable } from "material-react-table";
import { formatNumericValue } from "../../../../../../../helpers/Common";
import {
  defaultTableConfig,
  MIU_TABLE_BODY_CELL_PROPS_SX,
  MIU_TABLE_HEAD_CELL_PROPS_SX,
  MIU_TABLE_HEAD_ROW_PROPS_SX,
  MIU_TABLE_PROPS_SX,
} from "../../../../../../../utils/tableUtils";

export const useTableStore = create((set, get) => ({
  isDrawer: false,
  setIsDrawer: () => set({ isDrawer: !get().isDrawer }),
  sortedColumns: [],
  setSortedColumns: (value) => set({ sortedColumns: value }),
}));

const MaterialSummaries = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        header: "Jenis Chemist",
        accessorKey: "chemicalName",
      },
      {
        header: "Jumlah Chemist HI(Ltr)",
        accessorKey: "chemicalLtr",
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 2,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        header: "Jumlah Chemist s/d HI (Ltr)",
        accessorKey: "mtdChemicalLtr",
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 2,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        header: "Luas Terawat HI",
        accessorKey: "realizationAreaHa",
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 2,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        header: "Luas Terawat s/d HI",
        accessorKey: "mtdRealizationAreaHa",
        Cell: ({ cell }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 2,
            })}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  // default table state handler
  const columnSizingChangeHandler = (updater) => {
    setColumnSizing((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnPinningChangeHandler = (updater) => {
    setColumnPinning((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnOrderChangeHandler = (updater) => {
    setColumnOrder((prev) => {
      return updater instanceof Function ? updater(prev) : updater;
    });
  };
  const columnVisibilityHandler = (updater) => {
    setColumnVisibility((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };

  return (
    <div
      ref={ref}
      className="relative h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <ColumnSettings
        ref={tableInstanceRef}
        columns={columns}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        isDrawer={isDrawer}
        setIsDrawer={setIsDrawer}
        sortedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
      />
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        {...defaultTableConfig}
        // State
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        initialState={{ density: "compact" }}
        onColumnSizingChange={columnSizingChangeHandler}
        onColumnPinningChange={columnPinningChangeHandler}
        onColumnOrderChange={columnOrderChangeHandler}
        onColumnVisibilityChange={columnVisibilityHandler}
        // Styles
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{ sx: MIU_TABLE_PROPS_SX }}
        muiTableBodyCellProps={{
          sx: MIU_TABLE_BODY_CELL_PROPS_SX,
        }}
        muiTableHeadRowProps={{
          sx: { ...MIU_TABLE_HEAD_ROW_PROPS_SX, backgroundColor: "#fafbfd" },
        }}
        muiTableHeadCellProps={{
          sx: MIU_TABLE_HEAD_CELL_PROPS_SX,
        }}
      />
    </div>
  );
};
export default MaterialSummaries;
