import { BLOCKS_IMPORT_EXCEL_URL, BLOCKS_URL } from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  blocks: [],
  isLoading: false,
  isRequest: false,
  isError: false,
  errors: null,
};

export const getBlocks = createAsyncThunk(
  "block/getBlocks",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);
      const response = await axios.get(BLOCKS_URL, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: {
          ...data,
          fltCompany: data.companyId,
          fltEstate: data?.estate || null,
          fltAfdeling: data?.afdelingId || null,
          fltDeleted: 0,
          pageSize: data?.pageSize || 20,
          pageIndex: data?.page || 0,
          // fltName: data?.name || null,

          sidx: "syncInfo.updatedTime",
          sord: "desc",
        },
      });
      return { data: response.data, type: data.type };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBlock = createAsyncThunk(
  "block/createBlock",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);
      const response = await axios.post(BLOCKS_URL, data, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBlock = createAsyncThunk(
  "block/updateBlock",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.put(`${BLOCKS_URL}/${data?.id}`, data, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importBlocks = createAsyncThunk(
  "block/importBlocks",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const formData = new FormData();
      formData.append("content", data.fileContent);
      formData.append("fileName", data.fileName);
      formData.append("userId", data.userId);
      formData.append("mimeType", null);
      formData.append("id", null);

      const response = await axios.post(BLOCKS_IMPORT_EXCEL_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token?.accessToken,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBlock = createAsyncThunk(
  "user/deleteBlock",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.delete(`${BLOCKS_URL}/${data?.id}`, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const blockSlice = createSlice({
  name: "block",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlocks.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getBlocks.fulfilled, (state, action) => {
        state.isLoading = false;

        const data = action.payload?.data;
        const type = action.payload?.type;

        if (type == "selects") {
          let newData = [{ label: "Semua Blok", value: "all" }];
          data?.records.forEach((item) => {
            return newData.push({
              value: `${item?.id}`,
              label: `${item?.blockName} - ${item.estateName}`,
            });
          });

          state.blocks = newData;
        } else {
          state.blocks = data?.records;
        }
      })
      .addCase(getBlocks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.info(action.payload);
      })
      .addCase(importBlocks.pending, (state, action) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(importBlocks.fulfilled, (state, action) => {
        state.isRequest = false;
      })
      .addCase(importBlocks.rejected, (state, action) => {
        state.isRequest = false;
        state.isError = true;
        console.info(action.payload);
      })
      .addCase(deleteBlock.pending, (state, action) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(deleteBlock.fulfilled, (state, action) => {
        state.isRequest = false;
      })
      .addCase(deleteBlock.rejected, (state, action) => {
        state.isRequest = false;
        state.isError = true;
        console.info(action.payload);
      })
      .addCase(createBlock.pending, (state, action) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(createBlock.fulfilled, (state, action) => {
        state.isRequest = false;
      })
      .addCase(createBlock.rejected, (state, action) => {
        state.isRequest = false;
        state.isError = true;
        console.info(action.payload);
      })
      .addCase(updateBlock.pending, (state, action) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        state.isRequest = false;
      })
      .addCase(updateBlock.rejected, (state, action) => {
        state.isRequest = false;
        state.isError = true;
        console.info(action.payload);
      });
  },
});

export const {} = blockSlice.actions;
export const selectBlocks = (state) => state.block.blocks;
export const selectStatuses = (state) => ({
  isLoading: state.block.isLoading,
  isRequest: state.block.isRequest,
  isError: state.block.isError,
  errors: state.block.errors,
});

export default blockSlice.reducer;
