import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { VEHICLE_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	vehicles: [],
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getVehicles = createAsyncThunk(
	"vehicle/getVehicles",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(VEHICLE_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					sidx: "syncInfo.updatedTime",
					sord: "desc",
					...data,
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createVehicle = createAsyncThunk(
	"vehicle/createVehicle",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(VEHICLE_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateVehicle = createAsyncThunk(
	"vehicle/updateVehicle",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(
				`${VEHICLE_URL}/${data?.id}`,
				data,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteVehicle = createAsyncThunk(
	"vehicle/deleteVehicle",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(`${VEHICLE_URL}/${data?.id}`, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const vehicleSlice = createSlice({
	name: "vehicle",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getVehicles.fulfilled, (state, action) => {
				const records = action.payload?.records;
				state.vehicles = records;
			})
			.addCase(deleteVehicle.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(createVehicle.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(updateVehicle.fulfilled, (state, action) => {
				state.isRequest = false;
			});
	},
});

export const {} = vehicleSlice.actions;
export const selectVehicles = (state) => state.vehicle.vehicles;
export const selectStatuses = (state) => ({
	isLoading: state.vehicle.isLoading,
	isRequest: state.vehicle.isRequest,
	isError: state.vehicle.isError,
	errors: state.vehicle.errors,
});

export default vehicleSlice.reducer;
