import axiosInstance from "../api";
import { EMPLOYEES_URL } from "../../configs/api";

export const getEmployeesApi = (params) =>
  axiosInstance.get(EMPLOYEES_URL, {
    params,
  });

export const createEmployeeApi = (body) =>
  axiosInstance.post(EMPLOYEES_URL, body);

export const updateEmployeeApi = (id, body) =>
  axiosInstance.put(`${EMPLOYEES_URL}/${id}`, body);

export const deleteEmployeeApi = (id) =>
  axiosInstance.delete(`${EMPLOYEES_URL}/${id}`);
