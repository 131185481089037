import { BASE_URL, COMPANIES_URL } from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
	companies: [],
	company: {},
	data: [],
	isLoading: false,
	isError: false,
	errors: null,
};

export const getCompanies = createAsyncThunk(
	"company/getCompanies",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(COMPANIES_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					fltDeleted: 0,
					pageIndex: data?.page || 0,
					pageSize: data?.pageSize || 20,
					fltCompany: data.companyId,
				},
			});

			return { data: response.data, type: data.type };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const getCompany = createAsyncThunk(
	"company/getCompany",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(COMPANIES_URL + data.id, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateCompany = createAsyncThunk(
	"company/updateCompany",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(COMPANIES_URL + data.id, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCompanies.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getCompanies.fulfilled, (state, action) => {
				const data = action.payload?.data;
				const type = action.payload?.type;

				if (type == "selects") {
					let newData = [];
					data?.records.forEach((item) => {
						return newData.push({
							value: `${item?.id}`,
							label: `${item?.name}`,
						});
					});

					state.companies = newData;
				} else {
					state.companies = data;
				}
				state.isLoading = false;
			})
			.addCase(getCompanies.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(getCompany.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getCompany.fulfilled, (state, action) => {
				const payload = action.payload.record;

				state.company = payload;
				state.isLoading = false;
			})
			.addCase(getCompany.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(updateCompany.fulfilled, (state) => {});
	},
});

export const {} = companySlice.actions;
export const selectCompanies = (state) => state.company.companies;
export const selectCompany = (state) => state.company.company;
export const selectStatuses = (state) => ({
	isLoading: state.company.isLoading,
	isError: state.company.isError,
	errors: state.company.errors,
});

export default companySlice.reducer;
