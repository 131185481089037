import { HiHome, HiOutlineHome, HiOutlineUsers, HiUsers } from "react-icons/hi";
import {
  TbBuildingFactory2,
  TbHeartRateMonitor,
  TbTargetArrow,
  TbUsers,
} from "react-icons/tb";
import { TiChartArea, TiChartAreaOutline, TiTags } from "react-icons/ti";

import CHEMIST from "../assets/icons/chemist.svg";
import DASHBOARD_CHEMIST from "../assets/icons/dashboard-chemist.svg";
import DASHBOARD_FERTILIZER from "../assets/icons/dashboard-fertilization.svg";
import DASHBOARD_SETTING_PRICE from "../assets/icons/dashboard-setting-price.svg";
import FERTILIZER from "../assets/icons/fertilizer.svg";
import FFB from "../assets/icons/ffb.svg";
import MULTIUSE_JEPRIN from "../assets/icons/multiuse-jeprin-logo.png";
import RAMP from "../assets/icons/ramp.svg";
import SETTING_PRICE from "../assets/icons/setting-price.svg";
import TRAKSI from "../assets/icons/traksi.svg";
import TRUCK from "../assets/icons/truck.svg";
import TRUCK_SPB from "../assets/icons/truck-spb.svg";
import usePrivilage from "../hooks/usePrivilages";
import { LuMap } from "react-icons/lu";
import { APP_NAME } from "../configs/api";

export const routes = () => {
  const { user } = usePrivilage();
  let pageConfigurations = [
    {
      title: "Trading",
      link: "/dashboard-trading",
      icon: (
        <img
          src={DASHBOARD_SETTING_PRICE}
          alt='Money by Zona desain from <a href="https://thenounproject.com/browse/icons/term/money/" target="_blank" title="Money Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      activeIcon: (
        <img
          src={DASHBOARD_SETTING_PRICE}
          alt='Money by Zona desain from <a href="https://thenounproject.com/browse/icons/term/money/" target="_blank" title="Money Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      requiredPrivilege: ["tradingkjpcps", "k.emj", "pranotodahlan", "teaku"],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Pengaturan Harga",
      link: "/pengaturan-harga",
      icon: (
        <img
          src={SETTING_PRICE}
          alt='Money by Zona desain from <a href="https://thenounproject.com/browse/icons/term/money/" target="_blank" title="Money Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      activeIcon: (
        <img
          src={SETTING_PRICE}
          alt='Money by Zona desain from <a href="https://thenounproject.com/browse/icons/term/money/" target="_blank" title="Money Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      requiredPrivilege: ["tradingkjpcps", "k.emj", "pranotodahlan", "teaku"],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat Ramp",
      link: "/riwayat-ramp",
      icon: (
        <img src={RAMP} alt="riwayat-ramp" className="h-auto w-24  grayscale" />
      ),
      activeIcon: (
        <img src={RAMP} alt="riwayat-ramp" className="h-auto w-24  grayscale" />
      ),
      requiredPrivilege: ["tradingkjpcps", "k.emj", "pranotodahlan", "teaku"],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Dashboard",
      link: "/produksi-kebun",
      icon: <HiOutlineHome />,
      activeIcon: <HiHome />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Target Produksi",
      link: "/target-produksi",
      icon: <TbTargetArrow />,
      activeIcon: <TbTargetArrow />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat Panen",
      link: "/riwayat-panen",
      icon: (
        <img src={FFB} alt="riwayat-panen" className="h-auto w-24  grayscale" />
      ),
      activeIcon: (
        <img src={FFB} alt="riwayat-panen" className="h-auto w-24  grayscale" />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Riwayat SPB",
      link: "/riwayat-spb",
      icon: (
        <img
          src={TRUCK_SPB}
          alt="riwayat-spb"
          className="h-auto w-24  grayscale"
        />
      ),
      activeIcon: (
        <img
          src={TRUCK_SPB}
          alt="riwayat-spb"
          className="h-auto w-24  grayscale"
        />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat PKS",
      link: "/riwayat-pks",
      icon: <TbBuildingFactory2 />,
      activeIcon: <TbBuildingFactory2 />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Pemupukan",
      link: "/dashboard-pemupukan",
      icon: (
        <img
          src={DASHBOARD_FERTILIZER}
          alt=""
          className="h-auto w-24 grayscale"
        />
      ),
      activeIcon: (
        <img
          src={DASHBOARD_FERTILIZER}
          alt=""
          className="h-auto w-24 grayscale"
        />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat Pupuk",
      link: "/riwayat-pupuk",
      icon: <img src={FERTILIZER} alt="" className="h-auto w-24 grayscale" />,
      activeIcon: (
        <img src={FERTILIZER} alt="" className="h-auto w-24 grayscale" />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Data Yield",
      link: "/data-yield",
      icon: <TbHeartRateMonitor />,
      activeIcon: <TbHeartRateMonitor />,
      requiredPrivilege: [
        // "smp",
        // "kjpjne",
        // "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },

    {
      title: "Chemist",
      link: "/dashboard-chemist",
      icon: (
        <img
          src={DASHBOARD_CHEMIST}
          alt='Pesticide Applicator by Luis Prado from <a href="https://thenounproject.com/browse/icons/term/pesticide-applicator/" target="_blank" title="Pesticide Applicator Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      activeIcon: (
        <img
          src={DASHBOARD_CHEMIST}
          alt='Pesticide Applicator by Luis Prado from <a href="https://thenounproject.com/browse/icons/term/pesticide-applicator/" target="_blank" title="Pesticide Applicator Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat Chemist",
      link: "/riwayat-chemist",
      icon: (
        <img
          src={CHEMIST}
          alt='Pesticide Applicator by Luis Prado from <a href="https://thenounproject.com/browse/icons/term/pesticide-applicator/" target="_blank" title="Pesticide Applicator Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      activeIcon: (
        <img
          src={CHEMIST}
          alt='Pesticide Applicator by Luis Prado from <a href="https://thenounproject.com/browse/icons/term/pesticide-applicator/" target="_blank" title="Pesticide Applicator Icons">Noun Project</a> (CC BY 3.0)'
          className="h-auto w-24 grayscale"
        />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Riwayat Traksi",
      link: "/riwayat-traksi",
      icon: (
        <img
          src={TRAKSI}
          alt="riwayat-spb"
          className="h-auto w-24  grayscale"
        />
      ),
      activeIcon: (
        <img
          src={TRAKSI}
          alt="riwayat-spb"
          className="h-auto w-24  grayscale"
        />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },

    {
      title: "Data Estate",
      link: "/data-kebun",
      icon: <TiChartAreaOutline />,
      activeIcon: <TiChartArea />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Data Pengguna",
      link: "/data-pengguna",
      icon: <HiOutlineUsers />,
      activeIcon: <HiUsers />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "tradingkjpcps",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Data Anggota",
      link: "/data-anggota",
      icon: <TbUsers />,
      activeIcon: <TbUsers />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "tradingkjpcps",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Data Kendaraan",
      link: "/data-truck",
      icon: (
        <img src={TRUCK} alt="riwayat-spb" className="h-auto w-24  grayscale" />
      ),
      activeIcon: (
        <img src={TRUCK} alt="riwayat-spb" className="h-auto w-24  grayscale" />
      ),
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "tradingkjpcps",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web"],
    },

    {
      title: "Data Tag",
      link: "/data-tag",
      icon: <TiTags />,
      activeIcon: <TiTags />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "tradingkjpcps",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Data PKS",
      link: "/data-pks",
      icon: <TbBuildingFactory2 />,
      activeIcon: <TbBuildingFactory2 />,
      requiredPrivilege: [
        "smp",
        "kjpjne",
        "default",
        "tradingkjpcps",
        "k.emj",
        "pranotodahlan",
        "teaku",
      ],
      requiredApp: ["sawit-web", "semai-kebun"],
    },
    {
      title: "Riwayat Multiguna",
      link: "/riwayat-multiguna",
      icon: (
        <img
          src={MULTIUSE_JEPRIN}
          alt="multiuse-jeprin"
          className="h-auto w-24  grayscale"
        />
      ),
      activeIcon: (
        <img
          src={MULTIUSE_JEPRIN}
          alt="multiuse-jeprin"
          className="h-auto w-24  grayscale"
        />
      ),
      requiredPrivilege: [
        "default",
        "k.emj",
        "pranotodahlan",
        "teaku",
        "admincoba",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Peta Kebun",
      link: "/peta-kebun",
      icon: <LuMap />,
      activeIcon: <LuMap />,
      requiredPrivilege: [
        "k.emj",
        "pranotodahlan",
        "teaku",
        "admincoba",
        "smp",
        "kjpjne",
        "plasmapelaihari",
      ],
      requiredApp: ["sawit-web"],
    },
    {
      title: "Advanced Peta Kebun",
      link: "/peta-kebun-advanced",
      icon: <LuMap />,
      activeIcon: <LuMap />,
      requiredPrivilege: ["k.emj", "pranotodahlan", "teaku"],
      requiredApp: ["sawit-web"],
    },
  ];

  // write default sorting here based on array link above
  let defaultSorting = [
    "/produksi-kebun",
    "/target-produksi",
    "/riwayat-panen",
    "/riwayat-spb",
    "/riwayat-pks",
    "/dashboard-pemupukan",
    "/riwayat-pupuk",
    "/dashboard-chemist",
    "/riwayat-chemist",
    "/riwayat-traksi",
    "/data-kebun",
    "/data-pengguna",
    "/data-anggota",
    "/data-truck",
    "/data-tag",
    "/data-pks",
    "/riwayat-multiguna",
    "/dashboard-trading",
    "/pengaturan-harga",
    "/riwayat-ramp",
    "/peta-kebun",
    "/peta-kebun-advanced",
  ];

  const tradingSorting = [
    "/dashboard-trading",
    "/pengaturan-harga",
    "/riwayat-ramp",
    "/data-pengguna",
    "/data-anggota",
    "/data-truck",
    "/data-tag",
    "/data-pks",
  ];

  let sortingArray = defaultSorting;

  if (user?.username === "tradingkjpcps") {
    sortingArray = tradingSorting;
  }

  let routeConfigured = [];

  sortingArray.forEach((route) => {
    const page = pageConfigurations.find((config) => config.link === route);

    if (page) {
      if (
        (page.requiredPrivilege.includes(user?.username) ||
          page.requiredPrivilege.includes("default")) &&
        page.requiredApp.includes(APP_NAME)
      ) {
        routeConfigured.push(page);
      }
    }
  });

  return routeConfigured;
};
