import Item from "./Item";
import React from "react";
import { profileMenu } from "../../../api/profileMenu";
import { useNavigate } from "react-router-dom";
import { useFileAttachmentStore } from "../../../hooks/fileAttachmentStore";
import { useCompanyStore } from "../../../hooks/companyStore";

const Menu = () => {
  const navigate = useNavigate();
  const imageSource = useFileAttachmentStore((state) => state.imageSource);
  const company = useCompanyStore((state) => state.company);

  return (
    <div className={`right-16 z-40 flex w-[286px] flex-col rounded-16 shadow`}>
      <Item
        item={{
          additional: "Di perusahaan",
          title: company?.name,
          icon: (
            <div className="relative">
              <img
                src={imageSource.logoCompany}
                alt={company?.name}
                className="aspect-square w-64 rounded-8 object-contain"
              />
            </div>
          ),
        }}
        className="rounded-t-16 border"
        navigate={navigate}
      />
      {profileMenu?.map((item, index) => {
        const LOGOUT_TYPE = item.title.toLowerCase() === "akhiri sesi";

        return <Item key={index} item={item} logoutType={LOGOUT_TYPE} />;
      })}
    </div>
  );
};

export default Menu;
