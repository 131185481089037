import React from "react";

const Content = ({ children, className }) => {
	return (
		<div className={`relative flex flex-col px-24 py-16 ${className}`}>
			{children}
		</div>
	);
};

export default Content;
