export const santol2009 = {
  type: "FeatureCollection",
  name: "santoltt09_875ha_new_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.5,
        No_Kav: "60",
        Nm_Pemilik: "Norhadi",
        block: "Blok 1",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750323838732157, -3.759145622787557],
              [114.750251740790006, -3.757893535904405],
              [114.750606847745189, -3.757840651579924],
              [114.750794348220182, -3.758010956332592],
              [114.751237468358553, -3.757986847684675],
              [114.751425516292159, -3.758042935970863],
              [114.751467476766095, -3.758537936531663],
              [114.751178626825819, -3.758747971559891],
              [114.751195428593775, -3.759086670702654],
              [114.750525124238521, -3.759116251895561],
              [114.750323838732157, -3.759145622787557],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.947,
        No_Kav: "78",
        Nm_Pemilik: "Ahmad T",
        block: "Blok 4",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755888244514139, -3.771970239192292],
              [114.756128244443403, -3.772640239922522],
              [114.755138245876438, -3.772980238304907],
              [114.755078245266176, -3.772870239069995],
              [114.754908245926643, -3.772830238611747],
              [114.75479824495217, -3.772450239423125],
              [114.755888244514139, -3.771970239192292],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.769,
        No_Kav: "80a",
        Nm_Pemilik: "Hj Fatimah Zahra",
        block: "Blok 4",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755835441976302, -3.775954718333293],
              [114.755684107691621, -3.775551310328507],
              [114.755342370009942, -3.774197995672316],
              [114.755418788597396, -3.774014402136504],
              [114.756126864025788, -3.773863655161834],
              [114.756441406763969, -3.775550496803465],
              [114.755835441976302, -3.775954718333293],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.398,
        No_Kav: "82b",
        Nm_Pemilik: "Johar baini",
        block: "Blok 4",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748333636864174, -3.773516015289792],
              [114.748738244735804, -3.773220240875025],
              [114.749108232022351, -3.773460249493085],
              [114.749028239006506, -3.773740236948305],
              [114.74872000203041, -3.773970477611047],
              [114.748333636864174, -3.773516015289792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.185,
        No_Kav: "80b",
        Nm_Pemilik: "Hj Fatimah Zahra",
        block: "Blok 4",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756318333092608, -3.774782145512356],
              [114.75757284470194, -3.774598770390986],
              [114.757731785292833, -3.775313380947527],
              [114.756455716176404, -3.775543356013755],
              [114.756318333092608, -3.774782145512356],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.4,
        No_Kav: "81",
        Nm_Pemilik: "H Rustam Efendi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747723362658263, -3.770890898432923],
              [114.748525522032921, -3.770002304748829],
              [114.749005620139812, -3.770441843136944],
              [114.748224052518552, -3.771282615031449],
              [114.748172967736082, -3.771337570598684],
              [114.747554917928824, -3.772068970858417],
              [114.747508348301608, -3.771867636210521],
              [114.747682226525981, -3.770936470054726],
              [114.747723362658263, -3.770890898432923],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.067,
        No_Kav: "77",
        Nm_Pemilik: "H Samsuri",
        block: "Blok 4",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754187101709988, -3.771314574784077],
              [114.753839332498728, -3.77147111086695],
              [114.753487922289622, -3.771585263362747],
              [114.753288595210179, -3.771642945428428],
              [114.753087505107985, -3.771056633206528],
              [114.753052504038479, -3.770826901452639],
              [114.753938245405251, -3.770440239345731],
              [114.754187101709988, -3.771314574784077],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.932,
        No_Kav: "82a",
        Nm_Pemilik: "Johar baini",
        block: "Blok 4",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747468245012456, -3.772640239931872],
              [114.747708234985851, -3.772720253478625],
              [114.747888244836943, -3.773660238302367],
              [114.748018245453565, -3.773660239192144],
              [114.748278404166484, -3.7735424181584],
              [114.748667190729705, -3.774004221119625],
              [114.748258236572752, -3.774190223884706],
              [114.747718242829905, -3.774350236599628],
              [114.747468245012456, -3.772640239931872],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "84",
        Nm_Pemilik: "Sukimin",
        block: "Blok 4",
        KET: "Sertifikat",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749668239913518, -3.777160236075952],
              [114.749627475422614, -3.777400895847344],
              [114.749408245195028, -3.777810238486557],
              [114.748538248225046, -3.77771023220338],
              [114.748328244887873, -3.77766023874537],
              [114.748518361750016, -3.776971687142456],
              [114.749668239913518, -3.777160236075952],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.257,
        No_Kav: "76",
        Nm_Pemilik: "Sukiman",
        block: "Blok 2",
        KET: "Sporadik",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745948244092702, -3.765590239161974],
              [114.745878244683652, -3.767310237342037],
              [114.745368245459474, -3.767280235644147],
              [114.745268247105926, -3.765560241132774],
              [114.745948244092702, -3.765590239161974],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.928,
        No_Kav: "79",
        Nm_Pemilik: "Samhadi",
        block: "Blok 4",
        KET: "Sporadik",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75538929345764, -3.774541216614813],
              [114.754268211995566, -3.774701646581113],
              [114.753967311581917, -3.774550340297797],
              [114.753835955186432, -3.774178488994593],
              [114.75525831885227, -3.774020441506672],
              [114.75538929345764, -3.774541216614813],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.03,
        No_Kav: "71",
        Nm_Pemilik: "Sulastri",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.738706590890374, -3.762088778614024],
              [114.73847978928174, -3.761651629800153],
              [114.738550718474258, -3.761251890192245],
              [114.738852653248884, -3.760837487302119],
              [114.739054487081461, -3.760748197700818],
              [114.739474394157867, -3.761647657616803],
              [114.739594469692776, -3.76190486471979],
              [114.73925428655275, -3.761830711038107],
              [114.738706590890374, -3.762088778614024],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.03,
        No_Kav: "70",
        Nm_Pemilik: "Heriyanto",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740336678989763, -3.761269382631199],
              [114.740628356299297, -3.761669430750636],
              [114.740809919848857, -3.761972136118423],
              [114.739909325489904, -3.762372771300818],
              [114.739594469732694, -3.761904864728852],
              [114.739594469692776, -3.76190486471979],
              [114.739463996903538, -3.761625385664973],
              [114.740336678989763, -3.761269382631199],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.03,
        No_Kav: "69",
        Nm_Pemilik: "Saiqodin",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.739054487081461, -3.760748197700818],
              [114.739587270255385, -3.760512497590763],
              [114.739777557692875, -3.760436915762763],
              [114.740095366028768, -3.760938411159103],
              [114.74033667897433, -3.761269382584298],
              [114.739463996707926, -3.761625385368565],
              [114.739054487081461, -3.760748197700818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "68",
        Nm_Pemilik: "Aswan",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74028447640039, -3.761115356402663],
              [114.740126563918096, -3.760896493177989],
              [114.739840239671253, -3.76042480414628],
              [114.740694600331963, -3.760191298044264],
              [114.74097336456029, -3.760314333933785],
              [114.741528863787607, -3.760575376339797],
              [114.74028447640039, -3.761115356402663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "67",
        Nm_Pemilik: "Holwani",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740609988744126, -3.761566509486318],
              [114.74028447640039, -3.761115356402663],
              [114.741528863787607, -3.760575376339797],
              [114.742139352462573, -3.760862259155414],
              [114.742162207197367, -3.760872999218759],
              [114.740609988744126, -3.761566509486318],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "66",
        Nm_Pemilik: "Ridwan",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740844752740372, -3.761936939447287],
              [114.740687140163317, -3.761673439644849],
              [114.740609988744126, -3.761566509486318],
              [114.742162207197367, -3.760872999218759],
              [114.742165742194317, -3.760874660298961],
              [114.742213261932704, -3.760898290291027],
              [114.742449394706327, -3.761015711454454],
              [114.742508234257585, -3.761237763460328],
              [114.740844752740372, -3.761936939447287],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "65",
        Nm_Pemilik: "Dianto",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.741073471314337, -3.762345784983956],
              [114.741056671288376, -3.762312334778038],
              [114.740858301889574, -3.761959591177277],
              [114.740844752740372, -3.761936939447287],
              [114.742508234257585, -3.761237763460328],
              [114.742630906987188, -3.761700714460162],
              [114.741073471314337, -3.762345784983956],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "64",
        Nm_Pemilik: "Sunardi",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.741284059651917, -3.762765082965436],
              [114.741073471314337, -3.762345784983956],
              [114.742630905249754, -3.761700719651346],
              [114.742646319364368, -3.761758876603171],
              [114.742731292296455, -3.761963593279793],
              [114.742796034591663, -3.762119178387013],
              [114.742830863596637, -3.76217972346944],
              [114.741284059651917, -3.762765082965436],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "63",
        Nm_Pemilik: "Suwanto",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.741497414319312, -3.763189889156919],
              [114.741284059651917, -3.762765082965436],
              [114.742830865169253, -3.762179728249888],
              [114.742897366878097, -3.762295308161386],
              [114.743026967363264, -3.762561489612752],
              [114.743056157076182, -3.762645757395014],
              [114.741497414319312, -3.763189889156919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "61",
        Nm_Pemilik: "Norhadi",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74330222466476, -3.763867064114819],
              [114.742649037644995, -3.763883896683714],
              [114.742087286056901, -3.764000219418734],
              [114.741936050261373, -3.764063247864428],
              [114.741711641727804, -3.763616432884429],
              [114.743215872339974, -3.763158324035817],
              [114.743232639878713, -3.76332683893926],
              [114.743280706922505, -3.763633707822001],
              [114.74330222466476, -3.763867064114819],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "62",
        Nm_Pemilik: "Eko Anton Sujarwo",
        block: "Blok 3",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.741711641727804, -3.763616432884429],
              [114.741497414319312, -3.763189889156919],
              [114.743056157444002, -3.762645758513131],
              [114.743107795252598, -3.76279482501532],
              [114.743200890029541, -3.763007742709554],
              [114.743215872341622, -3.763158323395417],
              [114.741711641727804, -3.763616432884429],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "17",
        Nm_Pemilik: "Sujilah",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745179414979745, -3.7654890657071],
              [114.74474306239604, -3.765477355654024],
              [114.744678708225976, -3.764984257329004],
              [114.744527338634526, -3.763944502313833],
              [114.745180921127101, -3.763948002613531],
              [114.745179414979745, -3.7654890657071],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "16",
        Nm_Pemilik: "Milani",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745717248867692, -3.76550349796321],
              [114.745179414979745, -3.7654890657071],
              [114.745180921127101, -3.763948002613531],
              [114.745719934877471, -3.763950888918513],
              [114.745717248867692, -3.76550349796321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "15",
        Nm_Pemilik: "Sukimin",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746250569950277, -3.76551781048035],
              [114.74571725081735, -3.765503496990277],
              [114.745719934877471, -3.763950888918513],
              [114.745897764862406, -3.763951841148117],
              [114.746253362625552, -3.763944279322375],
              [114.746250569950277, -3.76551781048035],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "14",
        Nm_Pemilik: "Kasri",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74677441449974, -3.765531867575576],
              [114.746250569950277, -3.76551781048035],
              [114.746253362625552, -3.763944279322375],
              [114.746779684067008, -3.763933086952576],
              [114.74677441449974, -3.765531867575576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "13",
        Nm_Pemilik: "Yuliansyah",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747292991075611, -3.765545782965641],
              [114.74677441449974, -3.765531867575576],
              [114.746779684067008, -3.763933086952576],
              [114.747294940420076, -3.763922129292351],
              [114.747292991075611, -3.765545782965641],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "12",
        Nm_Pemilik: "Sukimin",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747793428218642, -3.76555921122271],
              [114.747292991075611, -3.765545782965641],
              [114.747294940420076, -3.763922129292351],
              [114.747727936127902, -3.76391292077716],
              [114.747812729783163, -3.763912502663417],
              [114.747793428218642, -3.76555921122271],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.024,
        No_Kav: "11",
        Nm_Pemilik: "Suharyono",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748351440155218, -3.763909846112409],
              [114.748261231963767, -3.765571763848007],
              [114.747793428374067, -3.765559211223113],
              [114.747812729783163, -3.763912502663417],
              [114.748351440155218, -3.763909846112409],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.018,
        No_Kav: "10",
        Nm_Pemilik: "Suharyono",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750279256605907, -3.765628335186217],
              [114.74982655912163, -3.765617136937549],
              [114.74980651846937, -3.763833689846106],
              [114.750272195849973, -3.763805227789904],
              [114.750279256605907, -3.765628335186217],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.018,
        No_Kav: "9",
        Nm_Pemilik: "Basrawi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750721525760454, -3.765639275187245],
              [114.750279256605907, -3.765628335186217],
              [114.750272195849973, -3.763805227789904],
              [114.750506521419851, -3.76379090566655],
              [114.750729929282329, -3.763786515840788],
              [114.750721525760454, -3.765639275187245],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.018,
        No_Kav: "7",
        Nm_Pemilik: "Saimun",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751246760163511, -3.764422188061068],
              [114.751246889779182, -3.764424763180817],
              [114.752596311594786, -3.764387719807116],
              [114.752571399756434, -3.764540421538205],
              [114.752499342455664, -3.765026588941481],
              [114.751131710181326, -3.765006999143163],
              [114.751246760163511, -3.764422188061068],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.018,
        No_Kav: "8",
        Nm_Pemilik: "Srihadi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751193159446188, -3.763777413608168],
              [114.751246760163511, -3.764422188061068],
              [114.751131710195708, -3.765006999063639],
              [114.751131710181326, -3.765006999143163],
              [114.751075069673163, -3.765647969342258],
              [114.750721525760454, -3.765639275187245],
              [114.750729929282329, -3.763786515840788],
              [114.751193159446188, -3.763777413608168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "2",
        Nm_Pemilik: "Suharyono",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756233910422793, -3.764310891494489],
              [114.756950290356386, -3.764424663355217],
              [114.756943419984438, -3.765562226017138],
              [114.756193267545513, -3.765382603217563],
              [114.756233910422793, -3.764310891494489],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "1",
        Nm_Pemilik: "Hj Ernawati / Hj. Erna Hayati",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756950290356386, -3.764424663355217],
              [114.757620996928523, -3.764531181090773],
              [114.757905031661025, -3.765179105258163],
              [114.757157618993034, -3.765613515524921],
              [114.756943419991345, -3.765562226020625],
              [114.756950290356386, -3.764424663355217],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.998,
        No_Kav: "3",
        Nm_Pemilik: "Rusyidah",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755752705919662, -3.764208321120349],
              [114.756228245787554, -3.764310238922985],
              [114.756188245241532, -3.765380239149704],
              [114.755455854314704, -3.765277565040662],
              [114.755487653885538, -3.764164270390268],
              [114.755752705919662, -3.764208321120349],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.998,
        No_Kav: "4",
        Nm_Pemilik: "Meisyer Alam",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755455854314704, -3.765277565040662],
              [114.755125028872641, -3.765231186326765],
              [114.75472979978997, -3.765169044519428],
              [114.754772206989628, -3.7640453644513],
              [114.755487653885538, -3.764164270390268],
              [114.755455854314704, -3.765277565040662],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "6",
        Nm_Pemilik: "H. Muhyar",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753990679521934, -3.765052831975819],
              [114.753942547059808, -3.765045264382999],
              [114.753270560980624, -3.764928253282288],
              [114.753371286021846, -3.763812532726527],
              [114.754080061071264, -3.763930330731379],
              [114.753990679521934, -3.765052831975819],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "5",
        Nm_Pemilik: "Masri Matsih",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75472979978997, -3.765169044519428],
              [114.753990679839575, -3.765052831976642],
              [114.754080061071264, -3.763930330731379],
              [114.754772206989628, -3.7640453644513],
              [114.75472979978997, -3.765169044519428],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.962,
        No_Kav: "34",
        Nm_Pemilik: "Rustam",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755798245968663, -3.762590238794215],
              [114.75682824581537, -3.762720238974921],
              [114.757138537420985, -3.763425447402643],
              [114.756557354535531, -3.763340385604903],
              [114.755761912428255, -3.763223964273176],
              [114.755763630904141, -3.763193982327036],
              [114.755798245968663, -3.762590238794215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.962,
        No_Kav: "32",
        Nm_Pemilik: "Ida Murniati",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756538494290623, -3.764296417279906],
              [114.75570824584139, -3.764160239018621],
              [114.755761912428255, -3.763223964273176],
              [114.756576767581848, -3.763343226916816],
              [114.756538494290623, -3.764296417279906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.962,
        No_Kav: "33",
        Nm_Pemilik: "H. Rustam Efendi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757138537420985, -3.763425447402643],
              [114.757598245707271, -3.764470238682256],
              [114.756538494605991, -3.764296417280721],
              [114.756576767636929, -3.763343226944677],
              [114.757138537420985, -3.763425447402643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.068,
        No_Kav: "35",
        Nm_Pemilik: "Suharyono",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753921769661432, -3.762079109964555],
              [114.755814459864879, -3.762294078945077],
              [114.755786636677058, -3.762751081708947],
              [114.753940771786219, -3.762550614921246],
              [114.753921769661432, -3.762079109964555],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.033,
        No_Kav: "30",
        Nm_Pemilik: "Filik Lewi Harianja",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754846093992626, -3.764018363390632],
              [114.753988674691072, -3.763871301193183],
              [114.753959633802026, -3.763866232999199],
              [114.753945272807968, -3.762961391292764],
              [114.754668531267541, -3.763052052796679],
              [114.754877604941711, -3.763079149728367],
              [114.754846093992626, -3.764018363390632],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.033,
        No_Kav: "31",
        Nm_Pemilik: "H. Sutaji",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75570824584139, -3.764160239018621],
              [114.75539723120977, -3.76411289265429],
              [114.754846094129391, -3.76401836343682],
              [114.754877604941711, -3.763079149728367],
              [114.755763630904141, -3.763193982327036],
              [114.755761912428255, -3.763223964273176],
              [114.75570824584139, -3.764160239018621],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "37",
        Nm_Pemilik: "Sahidah",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753017049290165, -3.762751748670421],
              [114.752279579166142, -3.762777350202306],
              [114.752123847339135, -3.762782756404844],
              [114.752089792695116, -3.762783938601919],
              [114.752057585332651, -3.761903471143123],
              [114.752093294492127, -3.761902180243351],
              [114.752687797162466, -3.761880688523342],
              [114.752987999492689, -3.76186983582418],
              [114.753017049290165, -3.762751748670421],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.007,
        No_Kav: "36",
        Nm_Pemilik: "H. Samsuri",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753919977380392, -3.761836142951005],
              [114.753920690858394, -3.762078987434569],
              [114.753921769661432, -3.762079109964555],
              [114.753940771786219, -3.762550614921246],
              [114.75393961120308, -3.762550488877044],
              [114.753940660690972, -3.762719684274953],
              [114.753180933954027, -3.762746059348283],
              [114.753153318566405, -3.762747018029401],
              [114.753021932058346, -3.762751579161296],
              [114.75301704939433, -3.762751748666819],
              [114.752987999492689, -3.76186983582418],
              [114.753322096916278, -3.76185775764889],
              [114.753919977380392, -3.761836142951005],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "38",
        Nm_Pemilik: "Budiman",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751169029342478, -3.762801339350248],
              [114.751128305963022, -3.762803107447061],
              [114.750521699501078, -3.762829444393522],
              [114.750265327518633, -3.762840575128132],
              [114.750236052267809, -3.762841846086755],
              [114.750233192477822, -3.761953907142301],
              [114.750253217130208, -3.761953115711681],
              [114.750548685317625, -3.761941437947627],
              [114.751089155284618, -3.761920076731832],
              [114.751130960777743, -3.761918424421805],
              [114.751169029342478, -3.762801339350248],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "39",
        Nm_Pemilik: "Arbiansyah",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750236052267809, -3.762841846086755],
              [114.749661205631881, -3.76286680377636],
              [114.749652693375864, -3.762867173334123],
              [114.749323049269194, -3.762881484695745],
              [114.749322767698175, -3.761989889351661],
              [114.749721798084124, -3.761974118817591],
              [114.750233192477822, -3.761953907142301],
              [114.750236052267809, -3.762841846086755],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "41",
        Nm_Pemilik: "Nurzaini SG",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74841677172985, -3.762920830336357],
              [114.747909307948163, -3.762942861227474],
              [114.747509589659714, -3.762960214270631],
              [114.747510658615766, -3.762063627716753],
              [114.74751066114645, -3.762061504590577],
              [114.747754093975715, -3.762051884245185],
              [114.748412684296611, -3.762025856729001],
              [114.74841677172985, -3.762920830336357],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        No_Kav: "40",
        Nm_Pemilik: "Aisyah Indartina",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749323049269194, -3.762881484695745],
              [114.748785569482507, -3.7629048192618],
              [114.74841677172985, -3.762920830336357],
              [114.748412684336401, -3.762025856753469],
              [114.748420765670772, -3.762025537349579],
              [114.74879909147532, -3.762010585620683],
              [114.748800361741004, -3.762010535418754],
              [114.749320634227843, -3.761989973669611],
              [114.749322767698175, -3.761989889351661],
              [114.749323049269194, -3.762881484695745],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "42",
        Nm_Pemilik: "Siti Romlah",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746578464500416, -3.762095818809697],
              [114.746614959299393, -3.763001575033257],
              [114.746099182265993, -3.763012329134218],
              [114.746098571056081, -3.763012341870611],
              [114.745697446406567, -3.763020705215319],
              [114.745697275124442, -3.762122451598715],
              [114.745702614366778, -3.762122264047115],
              [114.746571323073255, -3.762091749792338],
              [114.74657829068839, -3.762091505044622],
              [114.746578464500416, -3.762095818809697],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "43",
        Nm_Pemilik: "Zursasi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745697446406567, -3.763020705215319],
              [114.745177241882914, -3.763031551064611],
              [114.744793066021884, -3.763039560621935],
              [114.744786793444135, -3.763039691169129],
              [114.744783327589516, -3.762154554108514],
              [114.744795343962736, -3.762154131874917],
              [114.745300523358424, -3.762136387117762],
              [114.745302586013111, -3.762136314669498],
              [114.745697275124442, -3.762122451598715],
              [114.745697446406567, -3.763020705215319],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "44",
        Nm_Pemilik: "Eko Anton Sujarwo",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.744786793444135, -3.763039691169129],
              [114.744211517250477, -3.763051684673957],
              [114.743862877801959, -3.763058953010026],
              [114.74386245931494, -3.763010186344772],
              [114.743861800442758, -3.762933407755166],
              [114.743855396230103, -3.762187146543357],
              [114.744783327589516, -3.762154554108514],
              [114.744786793444135, -3.763039691169129],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "29",
        Nm_Pemilik: "Filik Lewi Harianja",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753180933954027, -3.762746059348283],
              [114.753940660690972, -3.762719684274953],
              [114.75394215749985, -3.762961000783104],
              [114.753945272807968, -3.762961391292764],
              [114.753959633802026, -3.763866232999199],
              [114.753248208797132, -3.763742075355853],
              [114.753191557355265, -3.763733720336301],
              [114.753180933954027, -3.762746059348283],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "28",
        Nm_Pemilik: "Sumarno D",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752279579166142, -3.762777350202306],
              [114.753021932058346, -3.762751579161296],
              [114.753180933954027, -3.762746059348283],
              [114.753191557355265, -3.763733720336301],
              [114.752503633450729, -3.763632264249171],
              [114.75242198414702, -3.763634512239126],
              [114.752306341725713, -3.763651042366635],
              [114.752279579166142, -3.762777350202306],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "27",
        Nm_Pemilik: "Muhaimin",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751395527856417, -3.762797059111013],
              [114.751857278156706, -3.762788332907142],
              [114.752089792695116, -3.762783938601919],
              [114.752123847339135, -3.762782756404844],
              [114.752279579166142, -3.762777350202306],
              [114.752306341725713, -3.763651042366635],
              [114.752185326084685, -3.763668341053258],
              [114.751904079272336, -3.763708942630797],
              [114.751412000988239, -3.763732505397421],
              [114.751395527856417, -3.762797059111013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "26",
        Nm_Pemilik: "Syarkawi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750536342356526, -3.76282880994644],
              [114.751128305963022, -3.762803107447061],
              [114.751169029342478, -3.762801339350248],
              [114.751395527856417, -3.762797059111013],
              [114.751412000988239, -3.763732505397421],
              [114.751221710391775, -3.76374161722986],
              [114.750843522600817, -3.763747180806928],
              [114.750530721102734, -3.763759380687495],
              [114.750536342356526, -3.76282880994644],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "25",
        Nm_Pemilik: "Rustam",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749661205631881, -3.76286680377636],
              [114.750236052267809, -3.762841846086755],
              [114.750265327518633, -3.762840575128132],
              [114.750536340652104, -3.762828810512253],
              [114.750530721102734, -3.763759380687495],
              [114.750291419193047, -3.763768713859775],
              [114.749654647550159, -3.763796573440041],
              [114.749661205631881, -3.76286680377636],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "24",
        Nm_Pemilik: "Sunardi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748785569482507, -3.7629048192618],
              [114.749323049269194, -3.762881484695745],
              [114.749661205631881, -3.76286680377636],
              [114.749654647550159, -3.763796573440041],
              [114.748779439661675, -3.763834864230135],
              [114.748785569482507, -3.7629048192618],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "23",
        Nm_Pemilik: "Sumarno",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747909307948163, -3.762942861227474],
              [114.74841677172985, -3.762920830336357],
              [114.748785569482507, -3.7629048192618],
              [114.748779439661675, -3.763834864230135],
              [114.748481030676814, -3.763847919567028],
              [114.747898141946806, -3.763861759424153],
              [114.747909307948163, -3.762942861227474],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "22",
        Nm_Pemilik: "H. Rustam Efendi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747010249122312, -3.762983300017952],
              [114.747509589659714, -3.762960214270631],
              [114.747909307948163, -3.762942861227474],
              [114.747898141946806, -3.763861759424153],
              [114.747007936495052, -3.76388289535683],
              [114.747010249122312, -3.762983300017952],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "21",
        Nm_Pemilik: "Sahrian",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746614959299393, -3.763001575033257],
              [114.747010249122312, -3.762983300017952],
              [114.747007936495052, -3.76388289535683],
              [114.746096276501362, -3.763904539718227],
              [114.746099182265993, -3.763012329134218],
              [114.746614959299393, -3.763001575033257],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "20",
        Nm_Pemilik: "Ngadimin Efendi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746096276501362, -3.763904539718227],
              [114.74589273887041, -3.763909371915516],
              [114.745172441202882, -3.763896516109635],
              [114.745177241882914, -3.763031551064611],
              [114.745697446406567, -3.763020705215319],
              [114.746099182265993, -3.763012329134218],
              [114.746096276501362, -3.763904539718227],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "18",
        Nm_Pemilik: "Misno Efendi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.744210807435223, -3.763879352038507],
              [114.743360358014925, -3.763864171602405],
              [114.743333227077599, -3.763630229971596],
              [114.743282244243787, -3.763306050359005],
              [114.743258206202498, -3.763004668052513],
              [114.743226502833778, -3.762931757408577],
              [114.743861800419367, -3.762933407767703],
              [114.743862877801959, -3.763058953010026],
              [114.744215643761919, -3.763051598791409],
              [114.744210807435223, -3.763879352038507],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "19",
        Nm_Pemilik: "Ngadimin Efendi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74517244119879, -3.763896516109625],
              [114.744210807435223, -3.763879352038507],
              [114.744215643927916, -3.76305159879184],
              [114.744786793444135, -3.763039691169129],
              [114.744793066021884, -3.763039560621935],
              [114.745177241882914, -3.763031551064611],
              [114.74517244119879, -3.763896516109625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "45",
        Nm_Pemilik: "Siti Romlah",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.743226502804646, -3.762931757388583],
              [114.743149376896341, -3.762754385152411],
              [114.74308054801331, -3.76255845842013],
              [114.742962251865521, -3.762300086438631],
              [114.742852536163269, -3.762110651756509],
              [114.742786986175091, -3.761914607013745],
              [114.743521232063259, -3.761797905126208],
              [114.743519301168817, -3.762199975626674],
              [114.74358305048213, -3.762196712156264],
              [114.743855396230103, -3.762187146543357],
              [114.743861800442758, -3.762933407755166],
              [114.743226502804646, -3.762931757388583],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.01,
        No_Kav: "58",
        Nm_Pemilik: "Sukimin",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755853292757394, -3.761422469879195],
              [114.755858531813928, -3.761854246422158],
              [114.753923526363792, -3.761621517595749],
              [114.753924602152466, -3.761201853557255],
              [114.755147188480393, -3.761341700683364],
              [114.75515027317644, -3.761342053533601],
              [114.755853292757394, -3.761422469879195],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.01,
        No_Kav: "59",
        Nm_Pemilik: "Mujiono",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755150310935164, -3.760121799699958],
              [114.755211590870147, -3.760113794777963],
              [114.755638561924286, -3.760189917474011],
              [114.755841697739484, -3.760466882234772],
              [114.755853292757394, -3.761422469879195],
              [114.75515027317644, -3.761342053533601],
              [114.755150310935164, -3.760121799699958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.01,
        No_Kav: "57",
        Nm_Pemilik: "Chaeruddin",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754672756789233, -3.760184181841481],
              [114.755150310935164, -3.760121799699958],
              [114.75515027317644, -3.761342053533601],
              [114.75392460215788, -3.761201851435195],
              [114.753924691234403, -3.76116706133653],
              [114.753925397102265, -3.760891363148373],
              [114.754665729732437, -3.760890219723839],
              [114.754672756789233, -3.760184181841481],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.6,
        No_Kav: "56b",
        Nm_Pemilik: "Saibatul hamdi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753926954438313, -3.760283026077059],
              [114.754648738805415, -3.760187319264944],
              [114.754672756789233, -3.760184181841481],
              [114.754665729728075, -3.760890219719164],
              [114.753925397102265, -3.760891363148373],
              [114.753926954438313, -3.760283026077059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "46",
        Nm_Pemilik: "Sumarno",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745300523358424, -3.762136387117762],
              [114.744795343962736, -3.762154131874917],
              [114.744783327589516, -3.762154554108514],
              [114.743855396230103, -3.762187146543357],
              [114.74358305048213, -3.762196712156264],
              [114.743519301168817, -3.762199975626674],
              [114.743521232063259, -3.761797905126208],
              [114.745304070443154, -3.761514535814158],
              [114.745300523358424, -3.762136387117762],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "47",
        Nm_Pemilik: "H. Anang Ilmi",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746607111357534, -3.762090577669933],
              [114.74657829068839, -3.762091505044622],
              [114.746571323073255, -3.762091749792338],
              [114.745702614366778, -3.762122264047115],
              [114.745697275124442, -3.762122451598715],
              [114.745300523358424, -3.762136387117762],
              [114.745304070443154, -3.761514535814158],
              [114.746261322240585, -3.761362385763567],
              [114.746604299626114, -3.761332621303674],
              [114.746607111357534, -3.762090577669933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "48",
        Nm_Pemilik: "Suharyanto",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747754093975715, -3.762051884245185],
              [114.74751066114645, -3.762061504590577],
              [114.747510249165586, -3.762061517843168],
              [114.746607111357534, -3.762090577669933],
              [114.746604299626114, -3.761332621303674],
              [114.747406347036105, -3.761263017001442],
              [114.747760445806151, -3.761220976356485],
              [114.747754093975715, -3.762051884245185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "49",
        Nm_Pemilik: "Hamdi Hasan",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74879909147532, -3.762010585620683],
              [114.748420765670772, -3.762025537349579],
              [114.747754093975715, -3.762051884245185],
              [114.747760445806151, -3.761220976356485],
              [114.748593507626325, -3.761122069661555],
              [114.748802736443608, -3.761085592986892],
              [114.74879909147532, -3.762010585620683],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "50",
        Nm_Pemilik: "Slamet",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749721798084124, -3.761974118817591],
              [114.749322767698175, -3.761989889351661],
              [114.749320634227843, -3.761989973669611],
              [114.74879909147532, -3.762010585620683],
              [114.748802736443608, -3.761085592986892],
              [114.74972630614981, -3.760924578736413],
              [114.749721798084124, -3.761974118817591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "51",
        Nm_Pemilik: "Rusmawati",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750548685317625, -3.761941437947627],
              [114.750253217130208, -3.761953115711681],
              [114.750233192477822, -3.761953907142301],
              [114.749721798084124, -3.761974118817591],
              [114.74972630614981, -3.760924578736413],
              [114.750299243759216, -3.760824692168117],
              [114.750335703935079, -3.760819076224801],
              [114.750550259847671, -3.760788125922137],
              [114.750548685317625, -3.761941437947627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "52",
        Nm_Pemilik: "Normansyah",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751130960777743, -3.761918424421805],
              [114.751089155284618, -3.761920076731832],
              [114.750548685317625, -3.761941437947627],
              [114.750550259847671, -3.760788125922137],
              [114.751314227431124, -3.760677920587287],
              [114.751311115368082, -3.761915516941376],
              [114.751130960777743, -3.761918424421805],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "53",
        Nm_Pemilik: "Suriyanto",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751314227431124, -3.760677920587287],
              [114.752023155994067, -3.76057565416497],
              [114.752022451586342, -3.761904037882571],
              [114.751921228461129, -3.761905670696115],
              [114.751311115368082, -3.761915516941376],
              [114.751314227431124, -3.760677920587287],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "54",
        Nm_Pemilik: "Aburrahman",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752023155994067, -3.76057565416497],
              [114.752692511573159, -3.760479095817427],
              [114.752687797162466, -3.761880688523342],
              [114.752093294492127, -3.761902180243351],
              [114.752057585332651, -3.761903471143123],
              [114.752022451586342, -3.761904037882571],
              [114.752023155994067, -3.76057565416497],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "56a",
        Nm_Pemilik: "Saibatul Hamdi",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753324609977994, -3.760379448018301],
              [114.753902094727735, -3.760286322400934],
              [114.753926954438313, -3.760283026077059],
              [114.753925397102265, -3.760891363148373],
              [114.753924691234403, -3.76116706133653],
              [114.75392460215788, -3.761201851435195],
              [114.753923526363792, -3.761621517595749],
              [114.753919345299707, -3.761621014722337],
              [114.753919977380392, -3.761836142951005],
              [114.753322096916278, -3.76185775764889],
              [114.753324609977994, -3.760379448018301],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.12,
        No_Kav: "55",
        Nm_Pemilik: "Sumarno",
        block: "Blok 1",
        KET: "Sertifikat",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752692511573159, -3.760479095817427],
              [114.752826861784385, -3.760459714880513],
              [114.753324609962576, -3.760379448028569],
              [114.753322096916278, -3.76185775764889],
              [114.752987999492689, -3.76186983582418],
              [114.752687797162466, -3.761880688523342],
              [114.752692511573159, -3.760479095817427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.731,
        No_Kav: "75",
        Nm_Pemilik: "Sri Yuli Wiyati",
        block: "Blok 3",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.741924274267205, -3.764942730225267],
              [114.742337564791242, -3.76474698076288],
              [114.742372935379251, -3.764952774058159],
              [114.742525616073721, -3.766399378818193],
              [114.742305377316981, -3.766459051983861],
              [114.742068764866858, -3.765761280865592],
              [114.741924274267205, -3.764942730225267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        No_Kav: "73",
        Nm_Pemilik: "Masrun Wiyono/Yatini",
        block: "Blok 3",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740438246878895, -3.765620237657194],
              [114.740615615218331, -3.765587143953194],
              [114.740808142143166, -3.765471784148958],
              [114.74080819629809, -3.765471341615556],
              [114.740858861799069, -3.765057332764051],
              [114.740863451948542, -3.76501982606393],
              [114.74192427304493, -3.764942731085195],
              [114.742068764846067, -3.765761280948215],
              [114.742070432136089, -3.765766202176156],
              [114.741259533969469, -3.765800786068327],
              [114.741619754417954, -3.766637738582708],
              [114.741058245642193, -3.76678023654597],
              [114.740608245920413, -3.765880237252857],
              [114.740438246878895, -3.765620237657194],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.016,
        No_Kav: "83",
        Nm_Pemilik: "Kasri",
        block: "Blok 2",
        KET: "Sertifikat",
        KET_2: "Tidak Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747509583708464, -3.762960209345815],
              [114.746614961040208, -3.763001573468317],
              [114.746578292057791, -3.76209150726531],
              [114.747510658532434, -3.762061506968547],
              [114.747509583708464, -3.762960209345815],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.744,
        No_Kav: "74",
        Nm_Pemilik: "Agus Sri Waluyo",
        block: "Blok 3",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.742070432561192, -3.765766203468411],
              [114.742305377316981, -3.766459051983861],
              [114.742032142963367, -3.766533083592141],
              [114.741619754417954, -3.766637738582708],
              [114.741259534025517, -3.765800786107721],
              [114.742070432561192, -3.765766203468411],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.1,
        No_Kav: "72",
        Nm_Pemilik: "Masrun Wiyono/Yatini",
        block: "Blok 3",
        KET: "Sporadik",
        KET_2: "Layak",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.740898146271249, -3.764736305491465],
              [114.740912871452963, -3.764615973130789],
              [114.742142846589445, -3.764041960294943],
              [114.742213356538286, -3.764024312402283],
              [114.742337564791242, -3.76474698076288],
              [114.741924274077419, -3.764942728511628],
              [114.740863451949863, -3.765019826023169],
              [114.740898146271249, -3.764736305491465],
            ],
          ],
        ],
      },
    },
  ],
};
