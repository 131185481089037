import * as yup from "yup";

export const formSchema = yup.object().shape({
  name: yup.string().required("Nama anggota wajib diisi"),
  phone: yup.string().required("No. HP wajib diisi"),
  identificationNumber: yup.mixed().nullable(),
  employeeNo: yup.mixed().nullable(),
  province: yup.mixed().nullable(),
  city: yup.mixed().nullable(),
  subdistrict: yup.mixed().nullable(),
  village: yup.mixed().nullable(),
  bank: yup.mixed().nullable(),
  bankAccountNumber: yup.mixed().nullable(),
});
