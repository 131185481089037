import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/userSlice";
import useFilterDataDefaults from "../../../../hooks/useFilterDataDefaults";
import { downloadExcel } from "react-export-table-to-excel";
import { useHarvestingStore } from "../../../../api/useHarvestingStore";
import { useBlockStore } from "../../../../api/blocks/useBlockStore";
import { formatNumericValue } from "../../../../helpers/Common";
import moment from "moment";
import toast from "react-hot-toast";

export const FilterContext = createContext();

export const fruitCriteria = [
  {
    label: "Terdapat abnormal",
    value: 10,
    type: "fltAbnormalCountGe",
  },
  {
    label: "Terdapat buah mentah",
    value: -2,
    type: "fltUnripeCountGe",
  },
  {
    label: "Kurang dari 15 Janjang",
    value: 14,
    type: "fltFruitCountLe",
  },
  {
    label: "Lebih dari 15 Janjang",
    value: 15,
    type: "fltFruitCountGe",
  },
  {
    label: "Data belum lengkap",
    value: 1,
    type: "fltIncomplete",
  },
  {
    label: "Tidak ada buah",
    value: -1,
    type: "fltFruitCountLe",
  },
];

const useFetchHarvestData = () => {
  const user = useSelector(selectUser);
  const getHarvestings = useHarvestingStore((state) => state.getHarvestings);
  const [harvestData, setHarvestData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchHarvestData = ({ onSuccessCallback, queryParams }) => {
    setLoading(true);
    getHarvestings({
      params: {
        fltCompany: user?.companyId,
        sord: "desc",
        sidx: "syncInfo.createdTime",
        ...queryParams,
        pageSize: 9999999,
      },
      onSuccess: (response) => {
        setHarvestData(response?.records);
        setLoading(false);
        onSuccessCallback(response?.records);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return { harvestData, fetchHarvestData, loading };
};

const FilterProvider = ({ children }) => {
  const forms = useForm();
  const user = useSelector(selectUser);

  const selectedAfdeling = forms.watch("afdeling");
  const selectedEstate = forms.watch("estate");

  const {
    users,
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
    fetchUsers,
  } = useFilterDataDefaults();

  const fetchBlock = useBlockStore((state) => state.getBlock);

  const { fetchHarvestData, loading: downloading } = useFetchHarvestData();

  const searchUsers = async (searchTerm) => {
    await fetchUsers({ name: searchTerm, options: { withoutAllItem: true } });
  };

  const searchEstates = async (searchTerm) => {
    await fetchEstates({
      name: searchTerm,
      options: { withoutAllItem: true },
      params: { pageSize: 99999 },
    });
  };

  const searchAfdelings = async (searchTerm) => {
    await fetchAfdelings({
      estateId: selectedEstate?.value,
      name: searchTerm,
      params: { pageSize: 99999 },
    });
  };

  const searchBlocks = async (searchTerm) => {
    await fetchBlocks({
      afdelingId: selectedAfdeling?.value,
      name: searchTerm,
      params: { pageSize: 99999 },
    });
  };

  const fetchBlockData = async (id) => {
    let record = {};
    await fetchBlock({
      id,
      onSuccess: (response) => {
        record = response?.record;
      },
    });
    return record;
  };

  const downloadExcelFile = (isFilterBy) => {
    const header = [
      "Tanggal Panen",
      "Waktu Jeprin",
      "Nama Pemanen",
      "Estate",
      "Afdeling",
      "Blok",
      "TPH",
      "Total JJG",
      "Buah Mentah",
      "Abnormal",
      "Total Panen (Kg)",
    ];

    fetchHarvestData({
      queryParams: isFilterBy,
      onSuccessCallback: async (records) => {
        const body = await prepareExcelData(records);

        downloadExcel({
          fileName: `Kendali Jeprin Harian (${moment(
            isFilterBy?.fltStartDate
          ).format("DDMMYYYY")} - ${moment(isFilterBy?.fltEndDate).format(
            "DDMMYYYY"
          )})`,
          sheet: `data original`,
          tablePayload: { header, body },
        });

        setTimeout(() => {
          toast.success("Berhasil mengunduh excel");
        }, 500);
      },
    });
  };

  return (
    <FilterContext.Provider
      value={{
        ...forms,
        user,
        users,
        estates,
        afdelings,
        blocks,
        searchUsers,
        searchEstates,
        searchAfdelings,
        searchBlocks,
        fetchBlockData,
        downloadExcelFile,
        downloading,
        fetchHarvestData,
        selectedEstate,
        selectedAfdeling,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
export const useFilter = () => useContext(FilterContext);
export const useFilters = () => useContext(FilterContext);
