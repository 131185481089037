import React from "react";
import TableHeadItem from "./TableHeadItem";
import useSortableData from "../../../hooks/useSortableData";

const Tables = ({
  headers,
  rowContent,
  rows,
  isLoading,
  isError,
  className,
  tableClassName,
  size = "md",
  width = "w-full",
  customHeader,
}) => {
  const { items, requestSort, sortConfig } = useSortableData(rows);

  const fontSizes = {
    sm: "text-10",
    md: "text-14",
    lg: "",
  };

  return (
    <div className={`relative overflow-auto rounded-lg md:block ${className}`}>
      <table className={`relative ${width} ${tableClassName}`}>
        {customHeader ? (
          customHeader
        ) : (
          <thead className="sticky top-0 z-10 bg-[#fafbfd]">
            <tr>
              {headers.map((item, index) => (
                <TableHeadItem
                  requestSort={(key) => requestSort(key)}
                  item={item}
                  key={index}
                  size={size}
                  sortConfig={sortConfig}
                />
              ))}
            </tr>
          </thead>
        )}
        {isLoading ? (
          <tbody>
            <tr>
              <td
                colSpan={headers?.length + 1}
                className="py-16 text-center font-regular text-gray-400"
              >
                <div className="col-span-10 flex w-full flex-col items-center justify-center">
                  <div className="loader-spinner-gradient h-32 w-32"></div>
                  <p className="font-regular text-14 text-gray-500">
                    Sedang dalam proses memuat data...
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        ) : items?.length < 1 ? (
          <tbody>
            <tr>
              <td
                colSpan={headers?.length + 1}
                className="py-16 text-center font-regular text-gray-400"
              >
                <div className="col-span-10 flex w-full flex-col items-center justify-center">
                  <p className="font-regular text-14 text-gray-300">
                    Tidak ada data yang tersedia.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        ) : items?.length >= 1 ? (
          <tbody className="divide-y divide-slate-100">
            {rowContent(items)}
          </tbody>
        ) : null}
      </table>
    </div>
  );
};

export default Tables;
