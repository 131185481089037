import * as yup from "yup";

export const formSchema = yup.object().shape({
	code: yup
		.string("Harus berupa string")
		.required("Nomer polisi wajib diisi."),
	type: yup
		.string("Harus berupa string")
		.required("Tipe atau model truck wajib diisi."),
	name: yup.string("Harus berupa string").required("Nama truck wajib diisi."),
	ownership: yup
		.string("Harus berupa string")
		.required("Kepemilikan wajib diisi."),
	tareWeight: yup
		.string("Harus berupa string")
		.required("Berat bersih wajib diisi."),
	maxLoadCapacity: yup
		.string("Harus berupa string")
		.required("Berat beban maksimal wajib diisi."),
	frameNo: yup.string("Harus berupa string").nullable(),
	// .optional("Kepemilikan tidak wajib untuk di isi."),
	engineNo: yup.string("Harus berupa string").nullable(),
	// .optional("Kepemilikan tidak wajib untuk di isi."),
	acquisitionYear: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Tahun akuisisi wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Tahun akuisisi wajib diisi."),
		})
		.nullable()
		.required("Tahun akuisisi wajib diisi."),
});
