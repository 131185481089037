import { createContext, useContext, useEffect, useState } from "react";
import {
  getProductionRecapsDaily,
  getProductionRecapsYield,
} from "../../../../features/harvestingSlice";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { omit } from "../../../../helpers/Common";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const user = useSelector(selectUser);
  const [isFilterBy, setIsFilterBy] = useState({
    company: user?.companyId,
    estate: undefined,
    estateName: undefined,
    afdeling: undefined,
    block: undefined,
    afdelingName: undefined,
    plantedYear: undefined,
    year: moment().format("YYYY"),
    month: moment().format("M"),
    startDate: moment().format("Y-MM-DD"),
    endDate: moment().format("Y-MM-DD"),
    date: undefined,
  });
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [sideTableData, setSideTableData] = useState([]);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const paginationLenght = Math.ceil(page?.total / 20);

  let params = {
    pageIndex: page.current,
    fltCompany: isFilterBy.company,
    fltEstate: isFilterBy.estate,
    fltAfdeling: isFilterBy.afdeling,
    fltBlock: isFilterBy.block,
    fltMonth: isFilterBy.month,
    fltStartDate: isFilterBy.startDate,
    fltEndDate: isFilterBy.endDate,
    fltPlantedYear: isFilterBy.plantedYear,
    fltYear: isFilterBy.year,
    sord: isFilterBy.sord,
  };

  const calculateDate = (harvestingDate) => {
    const today = harvestingDate || moment();
    const harvestingDateObject = new Date(today);
    const harvestingDateString = moment(harvestingDateObject).format("Y-MM-DD");
    let yesterdayDate = moment(harvestingDateString).subtract(1, "days");

    // Check if yesterday is a weekend day (Saturday or Sunday)
    while (yesterdayDate.weekday() === 0 || yesterdayDate.weekday() === 6) {
      yesterdayDate = yesterdayDate.subtract(1, "days");
    }
    const yesterdayDateString = yesterdayDate.format("Y-MM-DD");
    return { today: harvestingDateString, yesterday: yesterdayDateString };
  };

  const handleGetProductionRecapsYield = async (params) => {
    try {
      const response = await dispatch(
        getProductionRecapsYield(params)
      ).unwrap();
      if (!response.success) {
        return alert.error(response?.message);
      }

      setDataChart(response?.records);
      return response;
    } catch (error) {
      return alert.error(error?.data);
    }
  };

  const handleGetProductionRecapsDailyByBlock = async (params) => {
    try {
      const response = await dispatch(
        getProductionRecapsDaily({
          ...params,
          type: "/by-block",
          fltPlantedYear: -1,
        })
      ).unwrap();
      if (!response.success) {
        return alert.error(response?.message);
      }
      setData(response?.records);
      return response;
    } catch (error) {
      return;
      // alert.error(error?.data);
    }
  };

  const handleGetProductionRecapsDailyByAfdeling = async (params) => {
    try {
      const response = await dispatch(
        getProductionRecapsDaily({
          ...params,
          type: "/by-afdeling",
          pageIndex: 0,
          pageSize: 99999,
          fltPlantedYear: -1,
        })
      ).unwrap();
      if (!response.success) {
        return alert.error(response?.message);
      }
      setSideTableData(response?.records);
    } catch (error) {
      return;
      // alert.error(error?.data);
    }
  };

  const handleOnFilter = ({ type, value }) => {
    const dateValidation =
      params?.fltMonth === moment().format("M")
        ? moment().format("DD")
        : moment().endOf("month").format("DD");

    if (type === "estate") {
      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          estate: value.value,
        });
        params = {
          ...params,
          fltEstate: value.value,
        };
        handleGetProductionRecapsYield({
          type: "by-estate",
          id: params?.fltEstate,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      } else {
        setIsFilterBy({
          ...isFilterBy,
          estate: undefined,
        });
        params = {
          ...params,
          fltEstate: undefined,
        };
        handleGetProductionRecapsYield({
          type: "by-company",
          id: params?.fltCompany,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      }
    }
    if (type === "afdeling") {
      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: value.value,
        });
        params = {
          ...params,
          fltAfdeling: value.value,
        };
        handleGetProductionRecapsYield({
          type: "by-afdeling",
          id: params?.fltAfdeling,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      } else {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: undefined,
        });
        params = {
          ...params,
          fltAfdeling: undefined,
        };
        handleGetProductionRecapsYield({
          type: "by-estate",
          id: params?.fltEstate,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      }
    }
    if (type === "block") {
      if (value !== null) {
        setIsFilterBy({
          ...isFilterBy,
          block: value.value,
        });
        params = {
          ...params,
          fltBlock: value.value,
        };
        handleGetProductionRecapsYield({
          type: "by-block",
          id: params?.fltBlock,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      } else {
        setIsFilterBy({
          ...isFilterBy,
          block: undefined,
        });
        params = {
          ...params,
          fltBlock: undefined,
        };
        handleGetProductionRecapsYield({
          type: "by-afdeling",
          id: params?.fltAfdeling,
          year: params?.fltYear || moment().format("Y"),
          month: params?.fltMonth || moment().format("M"),
          dayOfMonth: dateValidation,
        });
      }
    }
    if (type === "date") {
      if (value !== null) {
        const dateParsed = new Date(value);
        const month = moment(dateParsed).format("M");
        const year = moment(dateParsed).format("YYYY");
        // const date = moment(dateParsed).format("DD");

        console.log("Month selected: ", month);
        console.log("Year selected: ", year);
        console.log("-------------------------");

        const dateValidation =
          month === moment().format("M")
            ? moment().format("DD")
            : moment(dateParsed).endOf("month").format("DD");

        const fullDateValidation =
          month === moment().format("M")
            ? moment().format("Y-MM-DD")
            : moment(dateParsed).endOf("month").format("Y-MM-DD");

        console.log("Default Date: ", value);
        console.log("Date Validation: ", dateValidation);
        console.log("Full Date Validation: ", fullDateValidation);
        console.log("-------------------------");

        setIsFilterBy({
          ...isFilterBy,
          month,
          year,
          startDate: fullDateValidation,
          endDate: fullDateValidation,
        });
        params = {
          ...params,
          fltMonth: month,
          fltYear: year,
          fltStartDate: fullDateValidation,
          fltEndDate: fullDateValidation,
        };

        if (isFilterBy.block !== undefined) {
          handleGetProductionRecapsYield({
            type: "by-block",
            id: params?.fltBlock,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else if (isFilterBy.afdeling !== undefined) {
          handleGetProductionRecapsYield({
            type: "by-afdeling",
            id: params?.fltAfdeling,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else if (
          isFilterBy.afdeling === undefined &&
          isFilterBy.estate !== undefined
        ) {
          handleGetProductionRecapsYield({
            type: "by-estate",
            id: params?.fltEstate,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else {
          handleGetProductionRecapsYield({
            type: "by-company",
            id: params?.fltCompany,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        }
      } else {
        const month = moment().format("M");
        const year = moment().format("YYYY");

        setIsFilterBy({
          ...isFilterBy,
          startDate: moment().format("Y-MM-DD"),
          endDate: moment().format("Y-MM-DD"),
          month,
          year,
        });
        params = {
          ...params,
          fltStartDate: moment().format("Y-MM-DD"),
          fltEndDate: moment().format("Y-MM-DD"),
          fltMonth: month,
          fltYear: year,
        };
        if (isFilterBy.block !== undefined) {
          handleGetProductionRecapsYield({
            type: "by-block",
            id: params?.fltBlock,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else if (isFilterBy.afdeling !== undefined) {
          handleGetProductionRecapsYield({
            type: "by-afdeling",
            id: params?.fltAfdeling,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else if (
          isFilterBy.afdeling === undefined &&
          isFilterBy.estate !== undefined
        ) {
          handleGetProductionRecapsYield({
            type: "by-estate",
            id: params?.fltEstate,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        } else {
          handleGetProductionRecapsYield({
            type: "by-company",
            id: params?.fltCompany,
            year: params?.fltYear || moment().format("Y"),
            month: params?.fltMonth || moment().format("M"),
            dayOfMonth: dateValidation,
          });
        }
      }
    }
    // params = omit(params, ["fltStartDate"]);
    handleGetProductionRecapsDailyByBlock(params);
    handleGetProductionRecapsDailyByAfdeling(params);
  };

  const handlePageClick = async (page) => {
    setIsFilterBy({ ...isFilterBy, company: user?.companyId });
    params = omit(params, ["fltStartDate"]);
    const response = await handleGetProductionRecapsDailyByBlock({
      ...params,
      pageIndex: page.selected,
      fltCompany: user?.companyId,
      fltStartDate: moment().format("Y-MM-DD"),
      fltEndDate: moment().format("Y-MM-DD"),
    });

    if (!response?.success) {
      return alert.error(response?.message);
    }
    setData(response?.records);
    const totalRecords = response?.totalRecords;
    setPage({
      current: page?.selected,
      total: totalRecords,
    });
  };

  const handleRefreshData = async () => {
    setIsFilterBy({ ...isFilterBy, company: user?.companyId });
    // params = omit(params, ["fltStartDate"]);
    // console.log(params);
    const response = await handleGetProductionRecapsDailyByBlock({
      ...params,
      fltCompany: user?.companyId,
      pageIndex: 0,
      fltStartDate: moment().format("Y-MM-DD"),
      fltEndDate: moment().format("Y-MM-DD"),
    });
    const totalRecords = response?.totalRecords;
    setPage({
      current: 0,
      total: totalRecords,
    });
    handleGetProductionRecapsDailyByAfdeling({
      ...params,
      fltCompany: user?.companyId,
      pageIndex: 0,
      startDate: moment().format("Y-MM-DD"),
      endDate: moment().format("Y-MM-DD"),
    });
    handleGetProductionRecapsYield({
      type: "by-company",
      id: user?.companyId,
      year: moment().format("Y"),
      month: moment().format("M"),
      dayOfMonth: moment().format("DD"),
    });
  };

  useEffect(() => {
    handleRefreshData();
  }, []);

  return (
    <DataContext.Provider
      value={{
        isFilterBy,
        sideTableData,
        dataChart,
        data,
        setData,
        calculateDate,
        handleRefreshData,
        handleOnFilter,
        handlePageClick,
        page,
        paginationLenght,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
