import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import { createEstateApi, getEstatesApi, updateEstateApi } from "./estateApi";

export const useEstateStore = create((set, get) => ({
  estates: [],
  estate: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getEstates: async (params = {}, silent) => {
    try {
      set({ isLoading: true });
      const response = await getEstatesApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Estates",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ estates: data.record, isLoading: false });
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Estates",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createEstate: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createEstateApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Estate",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Estate`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Estate",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateEstate: async (id, body, silent) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateEstateApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Update Estate",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil update estate`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      if (get().estate?.id === id) {
        set({ estate: data.record });
      }

      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Update Estate",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
