import { useLocation, useNavigate } from "react-router-dom";

import React from "react";
import { Tooltip } from "antd";
import {
  getIsSidebarSize,
  toggleButton,
} from "../../../../features/navbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../App";
import moment from "moment/moment";
import { selectUser } from "../../../../features/userSlice";
import { APP_NAME } from "../../../../configs/api";

const SidebarItem = ({ icon, title, linkTo, activeIcon, item }) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let checkThePath = location.pathname == linkTo;
  const isSidebarSize = useSelector(getIsSidebarSize);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    if (isSidebarSize) return;
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => setTooltipVisible(false);

  return (
    <Tooltip title={title} placement="right" open={tooltipVisible}>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`flex h-40 w-full flex-row items-center rounded-8 hover:bg-gray-20 ${
          checkThePath && "text-white hover:text-gray-500"
        } ${
          checkThePath
            ? APP_NAME === "semai-kebun"
              ? "bg-primary-red-500"
              : "bg-yellow-600"
            : ""
        } checkThePath  ${
          isSidebarSize ? "justify-between" : "w-40 justify-center"
        }`}
        onClick={() => {
          localStorage.setItem(
            "current_page",
            JSON.stringify({ title: item.title, link: item.link })
          );
          navigate(linkTo);
          dispatch(toggleButton());

          logEvent(analytics, "page_view", {
            firebase_screen: `${title.toLowerCase().replace(/[-\s]/g, "_")}_${
              window.location.host
            }`,
            page_title: `${title.toLowerCase().replace(/[-\s]/g, "_")}_${
              window.location.host
            }`,
            user_id: user?.id,
            what_time_access_screen: `${user?.username}_access_${title
              .toLowerCase()
              .replace(/[-\s]/g, "_")}_at_${moment().format("ll")}_${
              window.location.host
            }`,
          });
        }}
      >
        <div className="flex flex-row items-center space-x-16 px-8">
          {icon && !checkThePath && <div className="text-24">{icon}</div>}
          {activeIcon && checkThePath && (
            <div className="text-24">{activeIcon}</div>
          )}
          {isSidebarSize && (
            <p className={`text-left font-semi-bold text-16 duration-300`}>
              {title}
            </p>
          )}
        </div>
      </button>
    </Tooltip>
  );
};

export default SidebarItem;
