import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbRefresh } from "react-icons/tb";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useForm } from "react-hook-form";
import { useState } from "react";

const Filter = ({ onShowModal, handleRefreshData, modalName, onFilter }) => {
	const dispatch = useDispatch();
	const { watch, control } = useForm();
	const watchAfdeling = watch("afdeling");
	const watchEstate = watch("estate");

	const user = useSelector(selectUser);

	const [estates, setEstates] = useState([]);
	const [afdelings, setAfdelings] = useState([]);
	const [blocks, setBlocks] = useState([]);

	const handleGetBlocks = ({ afdelingId, name, page }) => {
		dispatch(
			getBlocks({
				companyId: user?.companyId,
				afdelingId,
				name: name || undefined,
				page: page || 0,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua blok", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.blockName}`,
					});
				});
				setBlocks(newData);
			});
	};

	const handleGetAfdelings = ({ estate, name, page }) => {
		dispatch(
			getAfdelings({
				companyId: user?.companyId,
				estate,
				name: name || undefined,
				page: page || 0,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua afdeling", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.name}`,
					});
				});
				setAfdelings(newData);
			});
	};

	const handleGetEstates = ({ name, page }) => {
		dispatch(
			getEstates({
				companyId: user?.companyId,
				fltName: name || undefined,
				page: page || 0,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua estate", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.name}`,
					});
				});
				setEstates(newData);
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) handleGetEstates({});
		return () => (isMounted = false);
	}, []);

	useEffect(() => {
		const subscription = watch((value) => {
			console.log(value);
			if (value?.estate) {
				handleGetAfdelings({ estate: value.estate.value });
			}
			if (value?.afdeling) {
				handleGetBlocks({ afdelingId: value.afdeling.value });
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<div className="mx-16 mt-16 mb-8 flex flex-row items-center justify-between">
			<div className="flex flex-row items-center space-x-8">
				<ButtonComponent
					tooltipPlacement="bottom"
					tooltipTitle="Muat ulang"
					size="none"
					type="button"
					className="bordered-button-transition border-yellow-500 bg-white p-10"
					onClick={() => handleRefreshData()}
					icon={<TbRefresh className="text-18 text-gray-500" />}
				/>
				<SelectHook
					control={control}
					data={estates}
					name="estate"
					placeholder="Pilih kebun"
					afterOnChange={(data) => {
						onFilter({
							value: data?.value,
							type: "estate",
						});
					}}
					menuStyles={{ padding: 5 }}
				/>
				{watchEstate?.value && (
					<SelectHook
						control={control}
						data={afdelings}
						name="afdeling"
						placeholder="Pilih wilayah"
						afterOnChange={(data) => {
							onFilter({
								value: data?.value,
								type: "afdeling",
							});
						}}
						menuStyles={{ padding: 5 }}
					/>
				)}

				{watchAfdeling?.value && (
					<SelectHook
						control={control}
						data={blocks}
						name="block"
						placeholder="Pilih blok"
						afterOnChange={(data) => {
							onFilter({
								value: data?.value,
								type: "block",
							});
						}}
						menuStyles={{ padding: 5 }}
					/>
				)}
			</div>
			<ButtonComponent
				value="Tambah Data BJR"
				onClick={() => onShowModal(modalName, "showModalCreate")}
			/>
		</div>
	);
};

export default Filter;
