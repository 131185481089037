import { ConfigProvider, DatePicker } from "antd";
import React, { forwardRef, useEffect } from "react";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbDownload } from "react-icons/tb";
import domToImage from "dom-to-image";
import { getYears } from "../../../../../helpers/Common";
import moment from "moment";
import { useData } from "../../hooks/DataContext";
import { Controller, useForm } from "react-hook-form";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import MobileFilter from "./partials/MobileFilter";
dayjs.extend(customParseFormat);

const Filter = forwardRef((props, ref) => {
  const { watch, control, setValue } = useForm();
  const { isFilterBy, onFilter } = useData();
  const watchAfdeling = watch("afdeling");
  const watchEstate = watch("estate");
  const {
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
  } = useFilterDataDefaults();
  const setIsSnapshot = useSnapshotStore((state) => state.setIsSnapshot);

  const onSearchEstate = async (searchTerm) => {
    await fetchEstates({ name: searchTerm });
  };

  const onSearchAfdeling = async (searchTerm) => {
    await fetchAfdelings({
      estateId: watchEstate.value,
      name: searchTerm,
    });
  };

  const onSearchBlock = async (searchTerm) => {
    await fetchBlocks({
      afdelingId: watchAfdeling.value,
      name: searchTerm,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchEstates({});
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    const date = dayjs(
      moment(isFilterBy.fltChemistDate).format("DD/MM/Y"),
      "DD/MM/YYYY"
    );
    const defaultDateIfEmpty = dayjs(moment().format("DD/MM/Y"), "DD/MM/YYYY");

    if (isFilterBy.fltChemistDate === null) {
      setValue("date", defaultDateIfEmpty);
      return;
    }
    setValue("date", date);
  }, [isFilterBy]);

  const captureComponent = () => {
    const component = ref.current;
    if (!component) return;

    const estateName =
      isFilterBy.estateName !== undefined ? `_${isFilterBy.estateName}` : "";

    const afdelingName =
      isFilterBy.afdelingName !== undefined
        ? `_${isFilterBy.afdelingName}`
        : "";

    setIsSnapshot(true); // Set isSnapshot to true before capturing the screenshot

    // Tambahkan penundaan sebelum mengambil tangkapan layar
    setTimeout(() => {
      domToImage.toBlob(component).then((blob) => {
        const link = document.createElement("a");
        link.download = `${moment(isFilterBy.date).format(
          "D-MM-Y"
        )}${estateName}${afdelingName}_produksi kebun.jpg`;
        link.href = URL.createObjectURL(blob);
        link.click();

        // Set isSnapshot to false after the screenshot is taken
        setTimeout(() => {
          setIsSnapshot(false);
        }, 1500);
      });
    }, 1000); // Sesuaikan nilai penundaan jika diperlukan
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <div className="flex h-fit w-full flex-row items-center px-8 md:h-full md:space-x-8 md:px-[2%]">
        <SelectHook
          control={control}
          data={estates}
          name="estate"
          placeholder="Pilih kebun"
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            onSearchEstate(searchValue);
          }}
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "fltEstate",
            });
            fetchAfdelings({ estateId: data.value });
          }}
          menuStyles={{ padding: 5 }}
        />
        {watchEstate?.value && (
          <SelectHook
            control={control}
            data={afdelings}
            name="afdeling"
            placeholder="Pilih wilayah"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              onSearchAfdeling(searchValue);
            }}
            afterOnChange={(data) => {
              onFilter({
                type: "fltAfdeling",
                value: data?.value,
              });
              console.log("Fetch blocks");
              fetchBlocks({ afdelingId: data?.value });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}

        {watchAfdeling?.value && (
          <SelectHook
            control={control}
            data={blocks}
            name="block"
            placeholder="Pilih blok"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              onSearchBlock(searchValue);
            }}
            afterOnChange={(data) => {
              onFilter({
                type: "fltBlock",
                value: data?.value,
              });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}
        <SelectHook
          name="year"
          data={getYears()}
          control={control}
          placeholder="Pilih tahun tanam"
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            onFilter({ type: "fltPlantedYear", value });
          }}
          menuStyles={{ padding: 5 }}
        />

        <Controller
          name="date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 14,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Pilih tanggal"
                  className="hidden md:inline"
                  value={value}
                  style={{
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    maxWidth: 250,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                  onChange={(date) => {
                    onChange(date);
                    if (date !== null) {
                      onFilter({
                        type: "fltChemistDate",
                        value: date,
                        filterHandler: ({ type, value, updateFilter }) => {
                          const dateObject = new Date(value);
                          const date = moment(dateObject).format("YYYY-MM-DD");
                          updateFilter({
                            [type]: date,
                          });
                        },
                      });
                    } else {
                      onFilter({
                        type: "fltChemistDate",
                        value: moment().format("YYYY-MM-DD"),
                      });
                    }
                  }}
                />
              </ConfigProvider>
            );
          }}
        />

        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Snapshot Halaman"
          value="Snapshot"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => {
            captureComponent(ref, isFilterBy);
          }}
          icon={<TbDownload className="text-18 text-gray-500" />}
        />

        <MobileFilter />
      </div>
    </ConfigProvider>
  );
});

export default Filter;
