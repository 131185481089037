import axiosInstance from "../../api";
import { FFB_TRADING_PRICE_LISTS_URL } from "../../../configs/api";

export const getPriceListsApi = (params) =>
  axiosInstance.get(FFB_TRADING_PRICE_LISTS_URL, {
    params,
  });

export const createPriceListApi = (body) =>
  axiosInstance.post(FFB_TRADING_PRICE_LISTS_URL, body);

export const updatePriceListApi = (id, body) =>
  axiosInstance.put(`${FFB_TRADING_PRICE_LISTS_URL}/${id}`, body);

export const deletePriceListApi = (id) =>
  axiosInstance.delete(`${FFB_TRADING_PRICE_LISTS_URL}/${id}`);
