import "react-lazy-load-image-component/src/effects/blur.css";

import { Image, Tooltip } from "antd";
import {
  Number,
  parseToFloat,
} from "../../../../../../../../../helpers/Common";
import React, { useEffect, useReducer, useState } from "react";
import { useAlert } from "react-alert";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { downloadImage } from "../../../../../../../../../features/fileAttachment/fileAttachmentSlice";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFertilizationSpotChecks } from "../../../../../../../../../features/fertilizationSlice";
import { MdOutlineWarning } from "react-icons/md";

dayjs.extend(customParseFormat);

const Item = ({ item, index }) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState({ message: null, isError: false });
  const [spotChecks, setSpotChecks] = useState([]);

  const [source, setSource] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      main: null,
      mainVisible: false,
      second: null,
      secondVisible: false,
      third: null,
      thirdVisible: false,
    }
  );

  const handleShowVisible = (type) => {
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });
  };

  function checkNullValues(items) {
    const errorMessages = {
      blockId: "Blok",
      photoFileId: "Foto pupuk diterima",
      photoFileId2: "Foto sisa pupuk",
      receivedFertilizerSum: "Jumlah pupuk yang diterima",
      restFertilizer: "Sisa pupuk",
      tphId: "TPH",
      treeCount: "Jumlah pohon",
      unitValues: "Nilai unit",
      materialNames: "Nama bahan",
      units: "Satuan",
      spotChecks: "Jumlah pemeriksaan spot",
    };

    const errorList = [];

    for (let i = 0; i < items.length; i++) {
      const key = Object.keys(items[i])[0];
      const value = items[i][key];

      if (Array.isArray(value) && value.length < items[i].min) {
        errorList.push(`${errorMessages[key]} kurang dari ${items[i].min}`);
      } else if (typeof items[i].min !== "undefined" && value < items[i].min) {
        errorList.push(
          `${errorMessages[key]} harus lebih besar atau sama dengan ${items[i].min}`
        );
      } else if (!value && value !== 0) {
        errorList.push(errorMessages[key]);
      }

      if (!(key in errorMessages)) {
        console.warn(`Error message for ${key} is missing`);
      }
    }

    if (errorList.length > 0) {
      return {
        message: `Masih belum lengkap: ${errorList.join(", ")}`,
        isError: true,
      };
    } else {
      return { message: "Semua sudah lengkap", isError: false };
    }
  }

  const getSpotCheckData = async (parentId) => {
    try {
      const response = await dispatch(
        getFertilizationSpotChecks({ id: item.id })
      ).unwrap();
      if (!response.success) {
        return alert.error(response.message);
      }
      const records = response.records;
      setSpotChecks(records);
      const items = [
        { blockId: item?.blockId },
        { photoFileId: item?.photoFileId },
        { photoFileId2: item?.photoFileId2 },
        { receivedFertilizerSum: item?.receivedFertilizerSum },
        { restFertilizer: item?.restFertilizer, min: 0 },
        { tphId: item?.tphId },
        { treeCount: item?.treeCount },
        { unitValues: JSON.parse(item?.unitValues)[0] },
        { materialNames: JSON.parse(item?.materialNames)[0] },
        { units: JSON.parse(item?.units)[0] },
        { spotChecks: records, min: 10 },
      ];
      setMessage(checkNullValues(items));
    } catch (error) {
      return alert.error(error.data);
    }
  };

  const handleDownloadImage = async (photoFileId, type) => {
    try {
      if (type === "main") setSource({ main: null });
      if (type === "second") setSource({ second: null });
      if (type === "third") setSource({ third: null });

      if (!photoFileId) {
        return;
      }
      const response = await dispatch(
        downloadImage({ id: photoFileId })
      ).unwrap();
      response.onloadend = function () {
        if (type === "main") setSource({ main: response.result });
        if (type === "second") setSource({ second: response.result });
        if (type === "third") setSource({ third: response.result });
      };
    } catch (error) {}
  };

  useEffect(() => {
    getSpotCheckData(item?.id);
  }, [item?.id]);

  useEffect(() => {
    handleDownloadImage(item?.photoFileId, "main");
    handleDownloadImage(item?.photoFileId2, "second");
  }, [item?.photoFileId, item?.photoFileId2]);

  // const checkHaveNull =
  //   !item?.blockId ||
  //   item?.receivedFertilizerSum === null ||
  //   !item?.restFertilizer ||
  //   !item?.tphId ||
  //   item?.treeCount === 0 ||
  //   parseInt(JSON.parse(item?.unitValues)[0]) === 0 ||
  //   item?.unitValues === null ||
  //   !JSON.parse(item?.materialNames)[0] ||
  //   item?.materialNames === null ||
  //   !JSON.parse(item?.units)[0] ||
  //   item?.units === null ||
  //   !JSON.parse(item?.unitValues)[0] ||
  //   item?.unitValues === null ||
  //   spotChecks?.length < 10;

  const handleNavigate = () => navigate(`/riwayat-pupuk/${item?.id}`);

  return (
    <tr className="table-header-regular relative" key={index}>
      <td className="table-row-regular">
        {index + 1}
        <Tooltip
          placement="bottom"
          title={message.message}
          className="font-regular"
        >
          <div>
            {message.isError ? (
              <MdOutlineWarning className="text-24 text-yellow-600" />
            ) : null}
          </div>
        </Tooltip>
      </td>
      <td className="relative flex w-[170px] flex-row items-center space-x-4">
        <Tooltip
          placement="bottom"
          title="Jumlah Bukti Spot Check"
          className="font-regular"
        >
          <div className="absolute top-8 left-8 flex aspect-auto h-24 w-fit flex-col items-center justify-center rounded bg-yellow-100 px-4 text-16 font-bold text-yellow-500 shadow">
            {spotChecks?.length}
          </div>
        </Tooltip>
        <img
          src={source.main || IMAGE_NOT_FOUND}
          alt={item?.photoFileId}
          className="aspect-square w-[80px] cursor-pointer rounded-8"
          onClick={() => handleShowVisible("main")}
        />
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.main}
          preview={{
            visible: source.mainVisible,
            src: source.main || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("main"),
          }}
        />
        <img
          src={source.second || IMAGE_NOT_FOUND}
          alt={item?.photoFileId2}
          className="aspect-square w-[80px] cursor-pointer rounded-8"
          onClick={() => handleShowVisible("second")}
        />
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.second}
          preview={{
            visible: source.secondVisible,
            src: source.second || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("second"),
          }}
        />
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        <p>{moment(item?.date).format("LL")}</p>
        <p>{moment(item?.createdTime).format("H:mm")}</p>
      </td>
      <td className="table-row-regular w-[20px]" onClick={handleNavigate}>
        {item?.userFullname || item?.createdBy}
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        {item?.blockName}
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        {Number(item?.receivedFertilizerSum || 0)}
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        {parseToFloat(parseInt(JSON.parse(item?.unitValues)[0]) || 0, 2)}{" "}
        {/*{JSON.parse(item?.units)[0]}*/}
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        {Number(item?.treeCount)}
      </td>
      <td className="table-row-regular" onClick={handleNavigate}>
        {item?.restFertilizer}
      </td>
    </tr>
  );
};

export default Item;
