import MoContent from "./MoContent";
import React from "react";
import { masterEstateRows } from "../../api/tables/dataTph/masterEstateRows";

export const base = masterEstateRows();
export const modalName = "masterTph";

const MasterBlockPage = () => {
	return (
		<div className="relative w-full overflow-hidden bg-white">
			<MoContent />
		</div>
	);
};

export default MasterBlockPage;
