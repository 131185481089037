import { TbAlertTriangle, TbCheck, TbInfoCircle, TbX } from "react-icons/tb";

import PropTypes from "prop-types";
import React from "react";

const FixedAlert = ({ options, message }) => {
	return (
		<div className="flex flex-row items-center space-x-16">
			{options.type === "info" && (
				<div className="border-gray-65 w-fit rounded-8 border-1 bg-gray-50 p-8">
					<TbInfoCircle className="text-18 text-gray-500" />
				</div>
			)}
			{options.type === "success" && (
				<div className="w-fit rounded-8 border-1 border-green-65 bg-green-50 p-8">
					<TbCheck className="text-18 text-green-500" />
				</div>
			)}
			{options.type === "warning" && (
				<div className="w-fit rounded-8 border-1 border-yellow-65 bg-yellow-50 p-8">
					<TbAlertTriangle className="text-18 text-yellow-500" />
				</div>
			)}
			{options.type === "error" && (
				<div className="w-fit rounded-8 border-1 border-red-65 bg-red-50 p-8">
					<TbX className="text-18 text-red-500" />
				</div>
			)}

			<p className="font-regular text-14 text-gray-500">{message}</p>
		</div>
	);
};
FixedAlert.propTypes = {
	message: PropTypes.string.isRequired,
	options: PropTypes.shape({
		type: PropTypes.oneOf(["info", "success", "warning", "error"]),
	}),
};
export default FixedAlert;
