import React, { createContext, useContext, useState } from "react";

const ExpandableContext = createContext();

export const ExpandableProvider = ({ children }) => {
	const [expandedEstate, setExpandedEstate] = useState("");
	const [expandedAfdeling, setExpandedAfdeling] = useState("");

	const handleRowClick = (rowName, isAfdeling) => {
		if (isAfdeling) {
			if (expandedAfdeling === rowName) {
				setExpandedAfdeling("");
			} else {
				setExpandedAfdeling(rowName);
			}
		} else {
			if (expandedEstate === rowName) {
				setExpandedEstate("");
			} else {
				setExpandedEstate(rowName);
				setExpandedAfdeling("");
			}
		}
	};

	return (
		<ExpandableContext.Provider
			value={{ handleRowClick, expandedEstate, expandedAfdeling }}
		>
			{children}
		</ExpandableContext.Provider>
	);
};

export const useExpandable = () => useContext(ExpandableContext);
