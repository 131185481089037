import PropTypes from "prop-types";
import React from "react";

const TextAreaHook = ({
	label,
	secondaryLabel,
	required = false,
	name,
	type,
	register,
	className: handleClass,
	autocomplete = "off",
	placeholder,
	inputClassName,
	disabled,
	errors,
	rows = 5,
}) => {
	return (
		<div className={`${handleClass} relative`}>
			<p className="mb-13 font-semi-bold text-14 text-gray-900">
				{label} <span className="text-blue-500">{secondaryLabel}</span>
				{required ? <span className="text-red-500">* </span> : null}
			</p>

			<div className="relative flex w-full flex-col">
				<textarea
					{...register(name)}
					rows={rows}
					disabled={disabled}
					placeholder={placeholder}
					type={type ? type : type === "search" ? "text" : "text"}
					autoComplete={autocomplete}
					className={`relative w-full rounded-8  placeholder-gray-200 ${inputClassName} ${
						errors && "border-red-400"
					}  font-regular text-14 text-gray-900`}
				></textarea>
				{errors && (
					<p className="mt-[4px] text-14 font-medium text-red-500">
						{errors?.message}
					</p>
				)}
			</div>
		</div>
	);
};

TextAreaHook.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(["search", "text"]),
	autocomplete: PropTypes.oneOf(["on", "off"]),
};

export default TextAreaHook;
