export const MAP_STYLE = "mapbox://styles/mapbox/satellite-v9";

/**
 * Utility function to generate a random RGB color.
 *
 * @returns {string} A randomly generated RGB color string (e.g., 'rgb(255, 0, 127)').
 */

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  const color = `rgb(${red}, ${green}, ${blue})`;
  return color;
};

/**
 * Utility function to map random colors to properties in the provided data.
 *
 * @param {Object} data - The data object to be color-mapped.
 * @param {Array} data.features - An array of features with properties to be color-mapped.
 * @returns {Object} The modified data object with random colors mapped to properties.
 */

export const dataColorMapping = (data) => {
  return {
    ...data,
    features: data.features.map((item) => {
      return {
        ...item,
        properties: { ...item.properties, color: getRandomColor() },
      };
    }),
  };
};

/**
 * Utility function to create a map layer.
 *
 * @param {Object} params - Parameters for creating the layer.
 * @param {string} params.id - The unique identifier for the layer.
 * @param {string} params.type - The type of the layer (e.g., 'fill', 'line', etc.).
 * @param {string} params.source - The data source for the layer.
 * @param {string} params.fillColor - The fill color of the layer.
 * @param {number} params.opacity - The opacity of the layer (0 to 1).
 * @param {...any} params.rest - Additional properties to be merged into the layer.
 * @returns {Object} The created layer object.
 */

export const createLayer = ({
  id,
  type,
  source,
  fillColor,
  opacity,
  ...rest
}) => ({
  id,
  type,
  source,
  layout: {},
  paint: {
    "fill-color": fillColor,
    "fill-opacity": opacity,
  },
  ...rest,
});

/**
 * Utility function to create an outline layer style.
 *
 * @param {Object} params - Parameters for creating the outline layer style.
 * @param {string} params.id - The unique identifier for the outline layer style.
 * @param {string} params.type - The type of the outline layer style (e.g., 'line').
 * @param {string} params.source - The data source for the outline layer style.
 * @returns {Object} The created outline layer style object.
 */

export const createOutlineLayerStyle = ({ id, type, source, lineColor }) => ({
  id,
  type,
  source,
  layout: {},
  paint: {
    "line-color": lineColor,
    "line-width": 2,
  },
});

/**
 * Utility function to create a label layer.
 *
 * @param {Object} params - Parameters for creating the label layer.
 * @param {string} params.id - The unique identifier for the label layer.
 * @param {string} params.type - The type of the label layer (e.g., 'symbol').
 * @param {string} params.source - The data source for the label layer.
 * @param {string} params.iconField - The field used for the icon image.
 * @returns {Object} The created label layer object.
 */

export const createLabelLayer = ({ id, type, source, iconField }) => ({
  id,
  type,
  source,
  layout: {
    "text-field": ["get", "No_Kav"],
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-radial-offset": 0.5,
    "text-justify": "center",
    "text-anchor": "center",
    "icon-image": ["get", iconField],
  },
  paint: {
    "text-color": "black",
  },
});

/**
 * Utility function to create multiple map layers from the provided layer data.
 *
 * @param {Array} layerData - An array of objects representing layer data.
 * @returns {Array} An array of objects representing created layers.
 */

export const createMultipleLayers = (layerData) => {
  return layerData.map((data) => {
    const { id, fillColor } = data;
    const layer = createLayer({
      id,
      source: id,
      fillColor,
      type: "fill",
      beforeId: `poi-labels-${id}`,
      opacity: 0.2,
    });

    const outlineStyle = createOutlineLayerStyle({
      id: `outline-${id}`,
      source: id,
      type: "line",
      lineColor: fillColor,
    });

    const label = createLabelLayer({
      id: `poi-labels-${id}`,
      source: id,
      iconField: "icon",
      type: "symbol",
    });

    return { layer, outlineStyle, label, ...data };
  });
};
