import { TbArrowNarrowLeft, TbArrowNarrowRight } from "react-icons/tb";

import PropTypes from "prop-types";
import React from "react";
import ReactPaginate from "react-paginate";
import Records from "./partials/Records";
import { APP_NAME } from "../../../configs/api";

const Pagination = ({
  pageCount = 1,
  onPageClick: handlePageClick,
  current,
}) => {
  return (
    <ReactPaginate
      previousLabel={<TbArrowNarrowLeft className="text-24" />}
      nextLabel={<TbArrowNarrowRight className="text-24" />}
      breakLabel={"..."}
      pageCount={pageCount}
      forcePage={current}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={handlePageClick}
      initialPage={0}
      containerClassName={"pagination__container"}
      pageLinkClassName={"pagination__a"}
      previousClassName={"pagination__prev"}
      nextClassName={"pagination__next"}
      activeClassName={
        APP_NAME === "sawit-web"
          ? "pagination__active"
          : "pagination__active__red"
      }
      breakClassName={"break-me"}
    />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
  onPageClick: PropTypes.func,
};

const Container = ({ children, className }) => (
  <div
    className={`mx-16 mb-24 flex flex-col items-center justify-between rounded-b-16 border border-t-0 border-gray-10 bg-white p-8 md:h-[57px] md:flex-row md:p-16 ${className}`}
  >
    {children}
  </div>
);
Pagination.Container = Container;
Pagination.Records = Records;

export default Pagination;
