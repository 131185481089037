import {
  getFfbStockMovementApi,
  getFfbStockMovementsApi,
  updateFfbStockMovementApi,
} from "./ffbStockMovementApi";

import { calculateToastDuration } from "../../helpers/Common";
import { create } from "zustand";
import { notification } from "antd";
import { toast } from "react-hot-toast";

export const useFfbStockMovementStore = create((set, get) => ({
  ffbStockMovements: [],
  ffbStockMovement: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getFfbStockMovements: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getFfbStockMovementsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Data Riwayat Ramp",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ ffbStockMovements: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan Data Riwayat Ramp",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getFfbStockMovementById: async (id, onSuccess) => {
    try {
      set({ isLoading: true });
      const response = await getFfbStockMovementApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        return notification.error({
          message: "Gagal Mendapatkan Data Ramp",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ ffbStockMovement: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      return notification.error({
        message: "Gagal Mendapatkan Data Ramp",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateFfbStockMovement: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateFfbStockMovementApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Ramp",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Ramp`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Ramp",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
