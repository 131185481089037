import { ConfigProvider, DatePicker } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbDownload, TbFilter } from "react-icons/tb";
import domToImage from "dom-to-image";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import locale from "antd/es/date-picker/locale/id_ID";
import moment from "moment";
import { selectUser } from "../../../../../features/userSlice";
import { useData } from "../../hooks/DataContext";
import { useForm } from "react-hook-form";

const ModalFilter = forwardRef(({ showModal }, ref) => {
  const { control } = useForm();
  const { isFilterBy, handleOnFilter } = useData();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const captureComponent = () => {
    const component = ref.current;
    if (!component) return;

    const estateName =
      isFilterBy.estateName !== undefined ? `_${isFilterBy.estateName}` : "";

    const afdelingName =
      isFilterBy.afdelingName !== undefined
        ? `_${isFilterBy.afdelingName}`
        : "";

    domToImage.toBlob(component).then((blob) => {
      const link = document.createElement("a");
      link.download = `${moment(isFilterBy.date).format(
        "D-MM-Y"
      )}${estateName}${afdelingName}_Data Yield.jpg`;
      link.href = URL.createObjectURL(blob);
      link.click();
    });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetEstates({});
    return () => (isMounted = false);
  }, []);

  return (
    <div className="grid grid-cols-1 gap-13">
      <SelectHook
        name="estate"
        data={estates}
        control={control}
        placeholder="Pilih estate"
        menuStyles={{ padding: 5 }}
        className="w-full"
        afterOnChange={(value) => {
          handleOnFilter({ type: "estate", value });
          if (value !== null) {
            handleGetAfdelings({ estate: value.value });
          } else {
            setAfdelings([]);
          }
        }}
      />
      <SelectHook
        name="afdeling"
        data={afdelings}
        control={control}
        placeholder="Pilih afdeling"
        menuStyles={{ padding: 5 }}
        className="w-full"
        afterOnChange={(value) => {
          handleOnFilter({ type: "afdeling", value });
          if (value !== null) {
            handleGetBlocks({ afdelingId: value.value });
          } else {
            setBlocks([]);
          }
        }}
      />
      <SelectHook
        control={control}
        data={blocks}
        name="block"
        className="w-full"
        placeholder="Pilih blok"
        afterOnChange={(value) => {
          handleOnFilter({
            value: value,
            type: "block",
          });
        }}
        menuStyles={{ padding: 5 }}
      />
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#d7a137",
            colorTextQuaternary: "#e1e4e8",
            fontFamily: "Inter-Regular",
            fontSize: 14,
            colorTextPlaceholder: "#6B6F77",
          },
        }}
      >
        <DatePicker
          picker="month"
          locale={locale}
          format="MMMM YYYY"
          placeholder="Pilih bulan tahun"
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#e1e4e8",
          }}
          onChange={(value) => {
            handleOnFilter({ type: "date", value });
          }}
        />
      </ConfigProvider>
    </div>
  );
});

export default ModalFilter;
