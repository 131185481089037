import ProgressBar from "../../../atoms/ProgressBar";
import React from "react";
import { TbMoonStars } from "react-icons/tb";
import { faker } from "@faker-js/faker";

const BillingHistory = () => {
	const progressCardRef = React.useRef(null);
	const [cardWidth, setCardWidth] = React.useState(0);

	React.useEffect(() => {
		let isMounted = true;
		setCardWidth(progressCardRef.current.offsetWidth);

		return () => (isMounted = false);
	}, [progressCardRef]);

	return (
		<div className="mx-16 flex flex-col rounded-16 border-1 border-gray-10 bg-white md:mx-32">
			<div className="flex flex-col items-start justify-between space-y-8 border-b-1 border-b-gray-10 px-24 py-13 md:flex-row md:items-center md:space-y-0">
				<div className="flex flex-row items-center space-x-13">
					<TbMoonStars className="text-24 text-gray-500" />
					<h3 className="font-semi-bold text-[20px] text-gray-500">
						Penggunaan Bulanan
					</h3>
				</div>

				<p className="font-regular text-14 text-gray-400">
					Berakhir 30 Agustus 2022
				</p>
			</div>

			<div className="mt-24 flex-col space-y-24 px-24 pb-24">
				{Array.from(
					Array(faker.datatype.number({ min: 6, max: 10 })).keys()
				).map(() => (
					<div
						ref={progressCardRef}
						className="flex flex-col items-start md:flex-row md:items-center md:space-x-42"
					>
						<h4 className="w-[130px] text-16 font-medium text-gray-500">
							{faker.commerce.productMaterial()} usages
						</h4>
						<div className="flex flex-col space-y-8">
							<p className="font-regular text-13 text-gray-400">
								10 pengguna dari 25 pengguna
							</p>
							<ProgressBar
								completed={faker.datatype.number({
									min: 30,
									max: 100,
								})}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default BillingHistory;
