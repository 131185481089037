import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import React from "react";
import { TbX } from "react-icons/tb";

const IndicatorSeparator = ({ innerProps }) => {
  return <span {...innerProps} />;
};

IndicatorSeparator.propTypes = {
  innerProps: PropTypes.any,
};

const CustomClearText = () => (
  <TbX className="text-16 text-gray-200 hover:text-red-500" />
);

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{ ...getStyles("clearIndicator", props), marginRight: -16 }}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

ClearIndicator.propTypes = {
  children: PropTypes.any,
  getStyles: PropTypes.func,
  innerProps: PropTypes.any,
};

const CreatableSelects = ({
  label,
  data,
  name,
  required,
  onBlur,
  control,
  background,
  errors,
  className,
  components,
  placeholder = "Please choose",
  afterOnChange,
  disabled,
  onInputChange,
  menuStyles,
  onCreateOption,
  isMulti,
  defaultValue,
}) => {
  const styles = {
    menuList: (base) => ({
      ...base,
      margin: 8,
      "::-webkit-scrollbar": {
        width: "0",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "0 8px 0 0 ",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: 99,
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: 99,
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        fontFamily: state.isSelected ? "Inter-Semibold" : "Inter-Regular",
        fontSize: 15,
        padding: "8px 16px",
        color: state.isSelected ? "#FFFFFF" : "#1d1b1d",
        borderRadius: 8,
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Inter-Regular",
      fontSize: 15,
      color: "#080808",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor:
        background && !disabled ? "#f2f2f2" : disabled ? "#FAFAFA" : "#FFFFFF",
      border: background && "none",
      fontFamily: "Inter-Regular",
      fontSize: 15,
      width: "100%",
      borderRadius: 8,
    }),
    input: (provided) => ({
      ...provided,
      padding: "8px",
      ...menuStyles,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 999,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      borderRadius: "8px 8px 8px 8px",
    }),
  };

  return (
    <div className={`min-w-[160px] ${className}`}>
      {label && (
        <p className="mb-8 text-14 font-medium text-gray-900">
          {label}
          {required && <span className="text-red-500"> *</span>}
        </p>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <CreatableSelect
              isMulti={isMulti}
              components={{ IndicatorSeparator, ClearIndicator, ...components }}
              isDisabled={disabled}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              options={data}
              styles={styles}
              onCreateOption={onCreateOption}
              value={value}
              onBlur={onBlur}
              ref={ref}
              onChange={(val) => {
                onChange(val);
                afterOnChange && afterOnChange(val);
              }}
              onInputChange={onInputChange}
              placeholder={placeholder}
              isClearable
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#f9f9fb",
                  primary50: "#f9f9fb",
                  primary: "#D7A137",
                  neutral20: "#e1e4e8",
                },
              })}
            />
          );
        }}
      />
      {errors && (
        <p className="error-message mt-4">
          {errors?.message || errors?.label?.message}
        </p>
      )}
    </div>
  );
};

CreatableSelects.propTypes = {
  afterOnChange: PropTypes.func,
  background: PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.array,
  disabled: PropTypes.any,
  errors: PropTypes.shape({
    label: PropTypes.shape({
      message: PropTypes.any,
    }),
    message: PropTypes.any,
  }),
  defaultValue: PropTypes.any,
  isMulti: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuStyles: PropTypes.any,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.any,
  onCreateOption: PropTypes.any,
  onInputChange: PropTypes.any,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
};

CreatableSelects.defaultProps = {
  required: false,
};

export default CreatableSelects;
