import { Dropdown } from "antd";
import React from "react";
import { TbArrowLeft, TbMenu2 } from "react-icons/tb";

import { IMAGE_NOT_FOUND } from "../../../api/fallbackImageNotFound";
import Menu from "../Menu";
import { toggleButton } from "../../../features/navbarSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFileAttachmentStore } from "../../../hooks/fileAttachmentStore";
import { useUserStore } from "../../../pages/MasterWorkerPage/hooks/userStore";

const Navbar = ({
  leftContent,
  leftContentTitle,
  arrowBack,
  onlyUserProfile,
}) => {
  const imageSource = useFileAttachmentStore((state) => state.imageSource);
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = React.useState(false);

  return (
    <div
      className={`bg-yellow fixed top-0 z-[15] grid w-full grid-cols-[1fr,max-content] gap-8 border-1 border-gray-10 bg-white py-10 px-16 md:relative md:px-24 ${
        onlyUserProfile && "rounded-8"
      }`}
    >
      {!onlyUserProfile && (
        <div className="flex flex-row items-center space-x-8 md:space-x-16">
          <button
            className="block md:hidden"
            onClick={() => dispatch(toggleButton())}
          >
            <TbMenu2 className="h-auto w-32 text-gray-500" />
          </button>
          {arrowBack && (
            <button type="button" onClick={() => navigate(-1)}>
              <TbArrowLeft className="text-24 text-gray-500" />
            </button>
          )}
          {leftContent || (
            <div>
              <h1 className="font-semi-bold text-16 text-gray-500 line-clamp-1 md:text-18">
                {leftContentTitle || "Selamat datang 👋"}
              </h1>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-row items-center justify-end space-x-16">
        <Dropdown dropdownRender={() => <Menu />}>
          <div className="flex flex-row items-center space-x-16">
            <p className="font-semi-bold text-16 text-gray-500">
              {user?.fullname}
            </p>
            <button
              className="cursor-pointer rounded-full border border-gray-100"
              onClick={() => setIsActive(!isActive)}
            >
              <img
                alt={user?.photoFileId}
                className="h-[30px] w-[30px] rounded-full shadow-smooth-1"
                src={imageSource.photoProfile || IMAGE_NOT_FOUND}
              />
            </button>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
