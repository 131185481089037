import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut as ChartDoughnut } from "react-chartjs-2";
import React from "react";
import { defaultOptions } from "./config/options";

const Doughnut = ({
	width,
	height,
	labels,
	dataset,
	moreDatasets,
	options,
	config,
}) => {
	let datasets = [
		{
			label: "Akurasi (%)",
			data: dataset,
			backgroundColor: "rgba(84, 70, 205, 0.2)",
			borderColor: "#5446CD",
			...config,
		},
	];

	if (moreDatasets) {
		datasets = moreDatasets;
	}

	const data = {
		labels: labels,
		datasets: datasets,
	};

	const centerText = {
		id: "centerText",
		afterDatasetsDraw(chart, args, pluginOptions) {
			const { ctx, data } = chart;
			const text = `${parseInt(
				(data.datasets[0].data[0] / data.datasets[0].data[1]) * 100
			)}%`;
			ctx.save();
			const x = chart.getDatasetMeta(0).data[0].x;
			const y = chart.getDatasetMeta(0).data[0].y;

			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.font = "bold 16px sans-serif";
			ctx.fillText(text, x, y);
		},
	};

	return (
		<ChartDoughnut
			plugins={[ChartDataLabels, centerText]}
			options={options || defaultOptions}
			data={data}
			width={width}
			height={height}
		/>
	);
};

export default Doughnut;
