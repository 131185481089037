import {
  createUserApi,
  deleteUserApi,
  getUserByIdApi,
  getUsersApi,
  updateUserApi,
  userLoginUrlApi,
} from "../../../api/userApi";
import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../../helpers/Common";

export const useUserStore = create((set, get) => ({
  users: [],
  user: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getUsers: async (params = {}, silent) => {
    try {
      set({ isLoading: true });
      const response = await getUsersApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Users",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ users: data.record, isLoading: false });
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Users",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getUserById: async (id, silent) => {
    try {
      set({ isLoading: true });
      const response = await getUserByIdApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Data Pengguna",
          description: data.message,
          placement: "bottomRight",
        });
      }
      //
      set({ user: data.record, isLoading: false });
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Data User",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getUserLoginUrl: async ({ id, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await userLoginUrlApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        return notification.error({
          message: "Berhasil Mendapatkan Link Login Pengguna",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ user: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      onError?.(error?.data);
      return notification.error({
        message: "Gagal Mendapatkan Link Login Pengguna",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateUser: async (id, companyId, body, onSuccess, onError) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateUserApi(id, companyId, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        return notification.error({
          message: "Gagal Update Data Pengguna",
          description: data.message,
          placement: "bottomRight",
        });
      }
      const toastMessage = `Berhasil update data pengguna ${data?.record?.fullname}`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      if (get().user?.id === id) {
        set({ user: data.record });
      }

      onSuccess?.(data);
      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      return notification.error({
        message: "Gagal Update Data Pengguna",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  deleteUser: async ({ id, companyId, fullname, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await deleteUserApi(id, companyId);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        onError?.(data.message);
        return notification.error({
          message: "Gagal Hapus Data Pengguna",
          description: data.message,
          placement: "bottomRight",
        });
      }
      const toastMessage = `Berhasil hapus data pengguna ${fullname}`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      onError?.(data.message);
      return notification.error({
        message: "Gagal Hapus Data Pengguna",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createUser: async (companyId, body, onSuccess, onError) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createUserApi(companyId, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        return notification.error({
          message: "Gagal Menambahkan Data Pengguna",
          description: data?.message,
          placement: "bottomRight",
        });
      }

      const toastMessage = `Berhasil menambahkan data pengguna ${data?.record?.fullname}`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      if (get().user?.id === body.id) {
        set({ user: data?.record });
      }

      onSuccess?.(data);
      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      console.warn(error);
      set({ isLoadingRequest: false });
      return notification.error({
        message: "Gagal Menambahkan Data Pengguna",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
