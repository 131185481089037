import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../helpers/Common";
import moment from "moment";

const DEFAULT_REMOVE_MESSAGE = "Berhasil menghapus";
const DEFAULT_SUCCESS_MESSAGE = "Berhasil mengubah";

export const showSuccessToast = ({ message, value }) => {
  toast.success(`${message} ${value || ""}`, {
    duration: calculateToastDuration(message),
    position: "top-right",
    className: "font-regular text-16",
  });
};

export const processTextChange = ({ value, message }) => {
  let text = value;
  const isEmptyComment = text?.length < 1 || text === undefined || text === " ";
  text = isEmptyComment ? null : text;

  const defaultMessage = isEmptyComment
    ? message?.remove || `${DEFAULT_REMOVE_MESSAGE} komentar`
    : message?.success || `${DEFAULT_SUCCESS_MESSAGE} komentar`;

  return {
    value: text,
    onSuccess: () => showSuccessToast({ message: defaultMessage, value: text }),
  };
};

export const processDateChange = ({ value, message }) => {
  const dateParsed = new Date(value);
  const formattedDate = value ? moment(dateParsed).format("Y-MM-DD") : null;
  const previewDate = value ? moment(dateParsed).format("LL") : " ";

  const isEmptyDate = previewDate === " ";

  const defaultMessage = isEmptyDate
    ? message?.remove || `${DEFAULT_REMOVE_MESSAGE} tanggal tindakan`
    : message?.success || `${DEFAULT_SUCCESS_MESSAGE} tanggal tindakan`;

  return {
    value: formattedDate,
    onSuccess: () =>
      showSuccessToast({ message: defaultMessage, value: formattedDate }),
  };
};
