export const galam2009 = {
  type: "FeatureCollection",
  name: "galamtt09__1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.634,
        Nm_Pemilik: "Iban",
        PENGGANTI: null,
        No_Kav: "15a",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 3",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.829418244461351, -3.815760245202696],
              [114.829818244034286, -3.814950246604806],
              [114.830768243968336, -3.815220246921718],
              [114.832308243863352, -3.815750244975865],
              [114.832298243232927, -3.816360245365637],
              [114.832088869222616, -3.816142130918939],
              [114.831322580768997, -3.815803070450606],
              [114.830750663109797, -3.815696322025679],
              [114.830540987396489, -3.815671713746246],
              [114.830244594815966, -3.815577663479527],
              [114.829911998278959, -3.815582840408583],
              [114.829418244461351, -3.815760245202696],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.483,
        Nm_Pemilik: "Iban",
        PENGGANTI: null,
        No_Kav: "15b",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 3",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.830742357113465, -3.815746622629627],
              [114.831084519756743, -3.815805699122727],
              [114.831302041558132, -3.815848201407768],
              [114.832068231773746, -3.816187223735933],
              [114.83208824174838, -3.816330240505911],
              [114.831508241421361, -3.816250243813286],
              [114.830748242739418, -3.816090245716419],
              [114.830742357113465, -3.815746622629627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.237,
        Nm_Pemilik: "Suratno",
        PENGGANTI: null,
        No_Kav: "11",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok2",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818019162978587, -3.814056586608784],
              [114.817205288832355, -3.814138959632211],
              [114.816983645608104, -3.812735997810798],
              [114.817560112901603, -3.812737465428341],
              [114.817813950337055, -3.813280975317173],
              [114.817924081777136, -3.813661260326182],
              [114.818019162978587, -3.814056586608784],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.026,
        Nm_Pemilik: "Ngatijo",
        PENGGANTI: null,
        No_Kav: "6",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808191592584009, -3.810088206998065],
              [114.807763554454723, -3.80984005415772],
              [114.80732504725998, -3.809563639160128],
              [114.807175471618251, -3.808966785612098],
              [114.807355390684762, -3.808681362577561],
              [114.807703427756479, -3.808608133892528],
              [114.80797058879331, -3.808559404408988],
              [114.808886718083244, -3.808928803366462],
              [114.80861477475483, -3.809478698448425],
              [114.808191592584009, -3.810088206998065],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.8,
        Nm_Pemilik: "Suwanto",
        PENGGANTI: null,
        No_Kav: "8",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.810756449974292, -3.810913638401998],
              [114.810423570443888, -3.810870364692181],
              [114.810853278020346, -3.809559440275391],
              [114.811191237036908, -3.809753554695939],
              [114.811221103735264, -3.809763522271228],
              [114.81131859870797, -3.809826850465402],
              [114.810921251559108, -3.810930787037948],
              [114.810889230065285, -3.810933028529472],
              [114.810756449974292, -3.810913638401998],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        Nm_Pemilik: "Ngatijo",
        PENGGANTI: null,
        No_Kav: "1",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.805643753801618, -3.809235585541062],
              [114.805597670196505, -3.809110400846046],
              [114.805266963219225, -3.809027348213606],
              [114.80527069357197, -3.808959447688499],
              [114.805000485674114, -3.808872975504172],
              [114.805392724082665, -3.808545150902464],
              [114.805552536369277, -3.808667082818521],
              [114.805626969332835, -3.808781648003549],
              [114.80563021055535, -3.808906753809716],
              [114.805832848495186, -3.808978756220396],
              [114.805921532548652, -3.809086209422162],
              [114.806185448340216, -3.808847411653059],
              [114.80645604588409, -3.808780193200985],
              [114.806618701447007, -3.80918449545263],
              [114.806625138836623, -3.809452578193578],
              [114.806370486788481, -3.8096923346178],
              [114.805641589807479, -3.809395216314197],
              [114.805678766798721, -3.809320773586424],
              [114.805643753801618, -3.809235585541062],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.147,
        Nm_Pemilik: "Suratno",
        PENGGANTI: null,
        No_Kav: "3b",
        KET: "Sertifikat",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.805924614762972, -3.812081409811356],
              [114.805842920204555, -3.812024013334522],
              [114.805679617270911, -3.811719981105234],
              [114.805928992850511, -3.811809906052045],
              [114.805978762859354, -3.811815223540038],
              [114.806131390468892, -3.811818525159899],
              [114.8061903314738, -3.811813824039897],
              [114.806428035711889, -3.811737383951468],
              [114.806238552772299, -3.812017651565639],
              [114.806103959857836, -3.811968864040333],
              [114.806060446039126, -3.811953083858642],
              [114.805924614762972, -3.812081409811356],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.777,
        Nm_Pemilik: "Wagiman",
        PENGGANTI: null,
        No_Kav: "20a",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836620435617448, -3.822544637144366],
              [114.836078273604869, -3.823056907930599],
              [114.835755449336361, -3.822963100655127],
              [114.837186022359475, -3.821726108239095],
              [114.837580696005716, -3.82197647704822],
              [114.837367309730027, -3.822201587723029],
              [114.837150268116091, -3.822325361859757],
              [114.836620435617448, -3.822544637144366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.224,
        Nm_Pemilik: "Kuwat Riyanti",
        PENGGANTI: "Kuwat Riyanti",
        No_Kav: "35",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.841322664712237, -3.819700894822909],
              [114.841379050898638, -3.819939312288672],
              [114.841377930514298, -3.819945258375912],
              [114.84107311784102, -3.820196655804449],
              [114.841150115713134, -3.820276895279546],
              [114.841157398844388, -3.820351956042503],
              [114.841167028366087, -3.82048455522367],
              [114.841245683852691, -3.820647114417447],
              [114.841240113274338, -3.82067667838139],
              [114.840872210734162, -3.820951164159827],
              [114.840777889073195, -3.821023283838155],
              [114.840574461378097, -3.821246066514478],
              [114.840185384684474, -3.82145512105266],
              [114.839793563374982, -3.821513692550568],
              [114.839412889268047, -3.821484519081759],
              [114.839750507516868, -3.821259661663048],
              [114.840247647020007, -3.820894137602359],
              [114.840332862950589, -3.820819243713374],
              [114.840509267663322, -3.820629104779743],
              [114.84055275296376, -3.820536631192527],
              [114.840935120091245, -3.820032138310427],
              [114.841142954532216, -3.819803892433539],
              [114.841322664712237, -3.819700894822909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.69,
        Nm_Pemilik: "Suwanto",
        PENGGANTI: null,
        No_Kav: "13a",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok2",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819686205678337, -3.812225115201557],
              [114.819678442696727, -3.812311091495763],
              [114.819506947829197, -3.813202224815817],
              [114.818495674642833, -3.813137765609027],
              [114.818505214556453, -3.813426592878464],
              [114.817985728973923, -3.813550954456159],
              [114.817585001110089, -3.812651469147938],
              [114.818063670583271, -3.812597489971608],
              [114.818494303739342, -3.81259375276416],
              [114.818835974683608, -3.812570457011579],
              [114.819083792733409, -3.812559004900212],
              [114.819360735107097, -3.812446133673753],
              [114.819686205678337, -3.812225115201557],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.064,
        Nm_Pemilik: "Suwanto",
        PENGGANTI: null,
        No_Kav: "13b",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok2",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819460577537768, -3.81196687617945],
              [114.819705035410195, -3.812016572534837],
              [114.819694568086831, -3.812132500075616],
              [114.819459772495421, -3.812286302135331],
              [114.819460577537768, -3.81196687617945],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.119,
        Nm_Pemilik: "Hj. Lindawati",
        PENGGANTI: null,
        No_Kav: "12",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok2",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817337467019271, -3.812171721133645],
              [114.818477547407753, -3.812050515710575],
              [114.819030646314673, -3.81197125326725],
              [114.819456916735135, -3.811966131966666],
              [114.819460577537768, -3.81196687617945],
              [114.819459772495421, -3.812286302135331],
              [114.819293503281415, -3.812395216129725],
              [114.819043066326969, -3.812491239244934],
              [114.818453589582376, -3.812521154135129],
              [114.818063853427731, -3.812524995419068],
              [114.817551506482033, -3.812576286111962],
              [114.817482014446583, -3.812420302213053],
              [114.817337467019271, -3.812171721133645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        Nm_Pemilik: "Sucipto",
        PENGGANTI: null,
        No_Kav: "2",
        KET: "Sertifikat",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.805541712405883, -3.809598893127142],
              [114.805641589857885, -3.809395222428212],
              [114.806370420261459, -3.809692356704101],
              [114.80630056980992, -3.809755556885879],
              [114.806406827514039, -3.810349407872324],
              [114.806408241575085, -3.810591480611317],
              [114.805238066483781, -3.810123242037263],
              [114.805458067757712, -3.809770763717039],
              [114.805541712405883, -3.809598893127142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.979,
        Nm_Pemilik: "Saiful Anwar",
        PENGGANTI: null,
        No_Kav: "5",
        KET: "Sertifikat",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808534651291666, -3.811163997374404],
              [114.8079518824795, -3.811255808358956],
              [114.807898900770297, -3.811247321444262],
              [114.807644680172189, -3.810960210987423],
              [114.808011492899951, -3.8106227902495],
              [114.8080039062297, -3.810564503643179],
              [114.808278793292999, -3.810155680633083],
              [114.808924695518684, -3.810766025629954],
              [114.809041060728092, -3.810875984792863],
              [114.808966310249872, -3.810875793078038],
              [114.808911030427254, -3.8109546906795],
              [114.808702488096515, -3.811106230735754],
              [114.808676419502959, -3.811117499091666],
              [114.808586342827979, -3.811156432952017],
              [114.808534651291666, -3.811163997374404],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.177,
        Nm_Pemilik: "Rohansah",
        PENGGANTI: null,
        No_Kav: "4",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807898900770297, -3.811247321444262],
              [114.807754738169848, -3.81122422863446],
              [114.807607699130259, -3.811258813114742],
              [114.807691712882928, -3.811678551755624],
              [114.807567591046123, -3.811825844691219],
              [114.807602021107911, -3.811973542962347],
              [114.807350077466793, -3.812202083083201],
              [114.807090645611098, -3.812333491929406],
              [114.806901412705059, -3.812232011508105],
              [114.806798482058454, -3.812144840013319],
              [114.80634491604151, -3.811928329851446],
              [114.80631733253837, -3.812045722896422],
              [114.80623855593349, -3.812017654278048],
              [114.806428050795319, -3.811737372663369],
              [114.806714161155668, -3.811578841149888],
              [114.806845000965282, -3.811602581749367],
              [114.807256276955783, -3.811234185918522],
              [114.807329286370816, -3.81125033243752],
              [114.807644680172189, -3.810960210987423],
              [114.807898900770297, -3.811247321444262],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.958,
        Nm_Pemilik: "Suratno",
        PENGGANTI: null,
        No_Kav: "3a",
        KET: "Sertifikat",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.806042180014572, -3.811713373600992],
              [114.805925629857384, -3.811681100293394],
              [114.805719469406895, -3.811594791666576],
              [114.804828400306278, -3.810784739227256],
              [114.805238063051448, -3.810123247180397],
              [114.806408242143164, -3.810591484089695],
              [114.806407237484052, -3.810650114280453],
              [114.806378104642633, -3.81086934852227],
              [114.806327884051555, -3.810929845748927],
              [114.806477555199479, -3.811499971984491],
              [114.806328141683494, -3.811639901480852],
              [114.806258006547921, -3.811619972810615],
              [114.806174759443834, -3.811716563362756],
              [114.806042180014572, -3.811713373600992],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.567,
        Nm_Pemilik: "Tri Astuti",
        PENGGANTI: null,
        No_Kav: "10",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811622658180738, -3.80986229739716],
              [114.811234012002117, -3.80963201301353],
              [114.811019940525881, -3.809497264701906],
              [114.811208387267015, -3.808965123297035],
              [114.811680921595141, -3.808870783937758],
              [114.811701703736702, -3.809661440778625],
              [114.811622658180738, -3.80986229739716],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.765,
        Nm_Pemilik: "Mahlina",
        PENGGANTI: "Mulyani",
        No_Kav: "34",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840231319012233, -3.819550285782102],
              [114.840400897386289, -3.819301719954153],
              [114.841076031691259, -3.818975919694388],
              [114.841682773166781, -3.819331004834977],
              [114.841537848977239, -3.81950183442082],
              [114.841099975842312, -3.819772409025981],
              [114.840839129151377, -3.820057544342879],
              [114.840497801306597, -3.820500072267214],
              [114.840442090542837, -3.820608115639228],
              [114.840257339414194, -3.820785300384402],
              [114.839848287509, -3.820601448480609],
              [114.840231319012233, -3.819550285782102],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.488,
        Nm_Pemilik: "Kuwat Riyanti",
        PENGGANTI: null,
        No_Kav: "14",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 3",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.824365236990886, -3.813372773617508],
              [114.824581704677385, -3.813025611794169],
              [114.825280552453208, -3.813560119597279],
              [114.825447827121209, -3.813392942012641],
              [114.826142161317009, -3.813352802589403],
              [114.826656017878577, -3.813732597113813],
              [114.826658931304578, -3.813750092967507],
              [114.826000518518015, -3.814043407507075],
              [114.82594912717434, -3.814128953058171],
              [114.825652861278314, -3.814256775850699],
              [114.825464677267419, -3.81416895276167],
              [114.824741348016758, -3.813650322467704],
              [114.824365236990886, -3.813372773617508],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.534,
        Nm_Pemilik: "Supiati",
        PENGGANTI: "Supiati",
        No_Kav: "31",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837190873236665, -3.815972771190429],
              [114.837270952510181, -3.815977175922712],
              [114.837337587609341, -3.816089369915951],
              [114.837484611967511, -3.816192161798142],
              [114.837524065133451, -3.816271921951759],
              [114.83766259258762, -3.81637469238163],
              [114.837857937339209, -3.816409325745232],
              [114.838061736394479, -3.816461050536581],
              [114.838035967922735, -3.816571941301752],
              [114.837974846050386, -3.8166260764048],
              [114.837901793023477, -3.816641835577852],
              [114.837851493011712, -3.816673576146223],
              [114.837837630981653, -3.816732093493235],
              [114.837823812036575, -3.816773389671066],
              [114.837806590059586, -3.816804344490806],
              [114.837808598359601, -3.816830983051532],
              [114.837794270087983, -3.817017907436874],
              [114.837772319728643, -3.817144819924366],
              [114.837450682333127, -3.81764144894361],
              [114.837283992075285, -3.817303202205828],
              [114.836868020708579, -3.817192577525573],
              [114.83644752269953, -3.817136302900041],
              [114.837190873236665, -3.815972771190429],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.104,
        Nm_Pemilik: "Eko Septian",
        PENGGANTI: null,
        No_Kav: "30",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835791877810166, -3.817048565848567],
              [114.835874351805757, -3.816700631811814],
              [114.835906259687306, -3.816605695080673],
              [114.835973582415022, -3.816435153123733],
              [114.836205690388567, -3.81616151052344],
              [114.836349514262082, -3.816098403436866],
              [114.836696873669993, -3.816022917596501],
              [114.836879193119032, -3.815997108592214],
              [114.837000474686903, -3.815962298355787],
              [114.837190873236665, -3.815972771190429],
              [114.836447512073832, -3.817136302873195],
              [114.835791877810166, -3.817048565848567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.746,
        Nm_Pemilik: "Tri Astuti",
        PENGGANTI: null,
        No_Kav: "9",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811318606474856, -3.809826849454232],
              [114.811499405078351, -3.809944251245508],
              [114.811832563190592, -3.810125160982094],
              [114.812175730673033, -3.810286089524182],
              [114.81251878458319, -3.810492032730707],
              [114.812677326144112, -3.810807542149826],
              [114.812747312956972, -3.810879664828132],
              [114.812687411518539, -3.811125633810131],
              [114.812493514576715, -3.811224381938835],
              [114.812116072225649, -3.811235328317601],
              [114.811788270351101, -3.811151128303371],
              [114.811757283910367, -3.810872115481656],
              [114.810921265839099, -3.810930790662698],
              [114.811318606474856, -3.809826849454232],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Eka S.H.",
        PENGGANTI: null,
        No_Kav: "26",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835950878167154, -3.819722040594027],
              [114.835683875463516, -3.819423324433274],
              [114.835026913360863, -3.818805662093208],
              [114.835112820149419, -3.818508333604836],
              [114.835428244572, -3.818170245625885],
              [114.835377123159546, -3.818112157089012],
              [114.836920716485125, -3.818200642772632],
              [114.835950878167154, -3.819722040594027],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Wahyu Sri Lestari",
        PENGGANTI: null,
        No_Kav: "25",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837480559056729, -3.817861061357645],
              [114.83767717327774, -3.818267935916961],
              [114.837728112876263, -3.818320941945326],
              [114.836413054021008, -3.820319666973135],
              [114.836374143182695, -3.82019571987449],
              [114.83604337869771, -3.819825601334972],
              [114.835950878167154, -3.819722040594027],
              [114.836920716485125, -3.818200642772632],
              [114.837076657397574, -3.818209581729874],
              [114.837088243707811, -3.818210245895919],
              [114.837404385990581, -3.817715278496958],
              [114.837480559056729, -3.817861061357645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Wahyu Sri Lestari",
        PENGGANTI: null,
        No_Kav: "24",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837728112876263, -3.818320941945326],
              [114.838190856170186, -3.818803197739518],
              [114.836666460623206, -3.821124156301862],
              [114.836413054021008, -3.820319666973135],
              [114.837728112876263, -3.818320941945326],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Putri G.A.",
        PENGGANTI: null,
        No_Kav: "23",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838190856170186, -3.818803197739518],
              [114.83861264879917, -3.819241598806615],
              [114.837090068047786, -3.82158818829136],
              [114.836962630230317, -3.821509139898921],
              [114.836666460623206, -3.821124156301862],
              [114.838190856170186, -3.818803197739518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Fauzi",
        PENGGANTI: "Fauzi",
        No_Kav: "21",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838902386278818, -3.819987498704896],
              [114.838918489524829, -3.820134150470848],
              [114.839021615761681, -3.820454111256274],
              [114.839214608057986, -3.820634839227463],
              [114.839362881809635, -3.820708525105889],
              [114.839564389899806, -3.820725161193899],
              [114.83962906641905, -3.82083624099729],
              [114.839284511808529, -3.821472922158531],
              [114.838978597450847, -3.821624914933732],
              [114.838376158540044, -3.82183318851205],
              [114.837837255091685, -3.821933528032044],
              [114.837634256495164, -3.821905352200822],
              [114.838902386278818, -3.819987498704896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.073,
        Nm_Pemilik: "Imam Taufik",
        PENGGANTI: null,
        No_Kav: "22",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.83861264879917, -3.819241598806615],
              [114.838814107182657, -3.819450989591338],
              [114.838857099585212, -3.819575063582386],
              [114.838902386341402, -3.819987496383817],
              [114.837634254553421, -3.821905353050368],
              [114.837592347153759, -3.821899992764235],
              [114.837090068047786, -3.82158818829136],
              [114.83861264879917, -3.819241598806615],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        Nm_Pemilik: "Syahrisada",
        PENGGANTI: null,
        No_Kav: "19",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836925339704393, -3.821553470737263],
              [114.837186018504994, -3.821726106165535],
              [114.835755449336361, -3.822963100655127],
              [114.835601128643987, -3.822253547742028],
              [114.835121901524715, -3.822753664011949],
              [114.834845656226165, -3.822555589470491],
              [114.834913213917872, -3.822473203983344],
              [114.83662113362152, -3.821154777224966],
              [114.83662359127139, -3.821162416684509],
              [114.836788452514696, -3.821380706864399],
              [114.836925339704393, -3.821553470737263],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        Nm_Pemilik: "Tamba Siagian",
        PENGGANTI: null,
        No_Kav: "18",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.834990623081168, -3.822378528202567],
              [114.835039049746626, -3.822319380259315],
              [114.8348653760834, -3.8222854680381],
              [114.834727016102434, -3.822407053486289],
              [114.83461045626423, -3.822378067604939],
              [114.834375406690739, -3.821566482679526],
              [114.834434372007252, -3.821289220359819],
              [114.834515181183036, -3.820907823576335],
              [114.834654409444042, -3.820996222244809],
              [114.834786980572815, -3.821091274230825],
              [114.834872397666572, -3.821268791066291],
              [114.835040317901161, -3.821436901656621],
              [114.835189551225795, -3.821549509516994],
              [114.835311282026154, -3.821624170222891],
              [114.835424479985775, -3.821628830367369],
              [114.835472584485913, -3.821545852075322],
              [114.835430658486018, -3.82149577267735],
              [114.836483612324372, -3.820724076351492],
              [114.836555462004043, -3.820950640871375],
              [114.83662113362152, -3.821154777224966],
              [114.834913219110803, -3.822473195455827],
              [114.834990623081168, -3.822378528202567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        Nm_Pemilik: "Saribudin",
        PENGGANTI: null,
        No_Kav: "16",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.834773381580561, -3.820616860966229],
              [114.834752864714233, -3.820593513007687],
              [114.834517313569492, -3.820385218933097],
              [114.834484923691633, -3.819980605159492],
              [114.834658248609912, -3.818600255048978],
              [114.83473090130839, -3.818616821580302],
              [114.835637003279771, -3.81944671739135],
              [114.835935138951427, -3.819780467652258],
              [114.834773381580561, -3.820616860966229],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        Nm_Pemilik: "Yasrin Nasution",
        PENGGANTI: null,
        No_Kav: "17",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835430658486018, -3.82149577267735],
              [114.835235412196823, -3.82126255655439],
              [114.834910125015014, -3.820868374383098],
              [114.834773394592972, -3.820616869727628],
              [114.835935138951427, -3.819780467652258],
              [114.83632323765211, -3.820214927389658],
              [114.83647770813937, -3.820704307687171],
              [114.836483612324372, -3.820724076351492],
              [114.835430658486018, -3.82149577267735],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.911,
        Nm_Pemilik: "Heny W.",
        PENGGANTI: null,
        No_Kav: "33",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839355878468567, -3.819451856929611],
              [114.839395406892947, -3.819510406919173],
              [114.83947733474406, -3.819537182016747],
              [114.839639808161806, -3.819614640157109],
              [114.83970551388083, -3.819782186167681],
              [114.83974620197715, -3.81990583137333],
              [114.839863777300039, -3.819956607900548],
              [114.83991678657695, -3.819912904042486],
              [114.840048261402842, -3.819694047551008],
              [114.840231319012233, -3.819550285782102],
              [114.839848287509, -3.820601448480609],
              [114.840257339414194, -3.820785300384402],
              [114.840176999594618, -3.820867679905372],
              [114.839403757172121, -3.821413147421373],
              [114.839702686521804, -3.820834252059038],
              [114.839631601662248, -3.820688358744164],
              [114.839575395194615, -3.820653419491612],
              [114.839391411574354, -3.820641586334081],
              [114.839271644679499, -3.820578184788604],
              [114.83909098174351, -3.820421663955406],
              [114.838992109533834, -3.820132161560583],
              [114.838973042464929, -3.819964651325472],
              [114.838958278436863, -3.81980802612084],
              [114.838945538015494, -3.819707951666252],
              [114.838930746756887, -3.819562200557951],
              [114.83887914823876, -3.819416356512616],
              [114.838695064560056, -3.819223685003886],
              [114.839662384443741, -3.819064233538172],
              [114.839678093426713, -3.819090057903245],
              [114.839844042565332, -3.819303311617856],
              [114.839853714047635, -3.819470563442867],
              [114.83936458736936, -3.819378113851625],
              [114.839355878468567, -3.819451856929611],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.911,
        Nm_Pemilik: "Tugiman",
        PENGGANTI: null,
        No_Kav: "32",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838695064560056, -3.819223685003886],
              [114.838616839778169, -3.819141810873689],
              [114.838134860129927, -3.818654206444605],
              [114.837725487819071, -3.818216200086074],
              [114.837547251191225, -3.817829890360508],
              [114.837450685116266, -3.817641452111812],
              [114.837772319528909, -3.817144826197633],
              [114.837809831288411, -3.817321675029349],
              [114.837771128886274, -3.817413359579961],
              [114.83777065740523, -3.81760175357858],
              [114.837875744131509, -3.817887027056667],
              [114.838283441214827, -3.818317984104366],
              [114.838605372703242, -3.818429901841419],
              [114.838931920056353, -3.818619121547913],
              [114.839128440554532, -3.818880472736997],
              [114.839225106010659, -3.818687490739168],
              [114.839134240657202, -3.818484374071284],
              [114.838768061671814, -3.817851292075958],
              [114.83899552510016, -3.817674503880369],
              [114.83920195975017, -3.817837184702091],
              [114.839292412799438, -3.817979302847746],
              [114.839372611324464, -3.818187272569498],
              [114.839432921159656, -3.818328958927781],
              [114.839619291213211, -3.818395662438177],
              [114.839740140445628, -3.818487182342766],
              [114.839563617119694, -3.818745466560048],
              [114.839562580604309, -3.818881998995776],
              [114.839662383551911, -3.819064232854236],
              [114.838695064560056, -3.819223685003886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.407,
        Nm_Pemilik: "Wagiman",
        PENGGANTI: null,
        No_Kav: "20b",
        KET: "Masuk SK-435",
        KODE: "Merah",
        block: "Blok 4",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837652339202961, -3.82199359117307],
              [114.836399711605182, -3.823918306135705],
              [114.836000621347551, -3.823971796439812],
              [114.835592607885246, -3.823765585355852],
              [114.836262945249388, -3.822971844006175],
              [114.836673439760929, -3.822592620832759],
              [114.837179871921691, -3.822383029282603],
              [114.837427999321264, -3.822248836592866],
              [114.837652339202961, -3.82199359117307],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.75,
        Nm_Pemilik: "Durajid",
        PENGGANTI: null,
        No_Kav: "28",
        KET: "Bebas SK-435",
        KODE: "Merah",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835628251733183, -3.816980242357333],
              [114.835671326934857, -3.816563331487371],
              [114.8356317936789, -3.816277659255352],
              [114.8355283643116, -3.815619913197635],
              [114.835607405705943, -3.815420172981642],
              [114.835947486465088, -3.815409689541395],
              [114.836110200610662, -3.815269283693897],
              [114.836378673571062, -3.815219136323125],
              [114.836641564492894, -3.81537987814685],
              [114.837120220591785, -3.815300262101915],
              [114.837276856374572, -3.815217502296988],
              [114.83739498629815, -3.815893817487093],
              [114.837296561176714, -3.815911520846761],
              [114.836986630866093, -3.815894348767233],
              [114.836867679669069, -3.815931500623629],
              [114.836709736792287, -3.815946580140619],
              [114.836664731257855, -3.815956560199143],
              [114.83633448185978, -3.81602963866019],
              [114.836177197190509, -3.816097968177529],
              [114.83606270543406, -3.816222896245367],
              [114.835912888637253, -3.816402752878989],
              [114.835801883694771, -3.816683546433918],
              [114.835713650789941, -3.816996397254242],
              [114.835628251733183, -3.816980242357333],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 3.6,
        Nm_Pemilik: "Kamto",
        PENGGANTI: null,
        No_Kav: "27",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 5",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.833861532895284, -3.817545075441259],
              [114.834054795061277, -3.817463912600583],
              [114.834159364496472, -3.817387296383206],
              [114.834230012675576, -3.81733529763746],
              [114.834312575824654, -3.817274292879119],
              [114.834423246367081, -3.817214014647325],
              [114.834653678911309, -3.817082295954445],
              [114.834936422924415, -3.817057197489288],
              [114.835468842013938, -3.817122416663351],
              [114.835669158004876, -3.81712855286971],
              [114.836879596287474, -3.817278001347542],
              [114.837227431166198, -3.817382133345429],
              [114.837404385990581, -3.817715278496958],
              [114.837088236979554, -3.8182102378347],
              [114.835377123159546, -3.818112157089012],
              [114.835079909315766, -3.817798463601707],
              [114.834955733458187, -3.817667403487484],
              [114.834434330131813, -3.818352986199394],
              [114.834283049529091, -3.818157360247008],
              [114.834182865464456, -3.817974032369365],
              [114.833861532895284, -3.817545075441259],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.75,
        Nm_Pemilik: "Rina Suryani",
        PENGGANTI: null,
        No_Kav: "29",
        KET: "Masuk SK-435",
        KODE: "Hijau",
        block: "Blok 6",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.83739499518056, -3.815893815833916],
              [114.837276859683755, -3.815217501134225],
              [114.83811685041168, -3.814768610450523],
              [114.838258575607796, -3.814720683067631],
              [114.838359855805976, -3.814727785267997],
              [114.837821144108403, -3.815754202970342],
              [114.837726633331783, -3.815773110533458],
              [114.837557892297298, -3.81580684848689],
              [114.837424839605134, -3.815888447634491],
              [114.83739499518056, -3.815893815833916],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.985,
        Nm_Pemilik: "Mujiati",
        PENGGANTI: null,
        No_Kav: "7",
        KET: "Bebas SK-435",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.810851903164448, -3.809556660602649],
              [114.810383808731359, -3.810986666002535],
              [114.809429663277513, -3.811045937288107],
              [114.809205527581852, -3.810983213085382],
              [114.809140426903255, -3.810933194823017],
              [114.809041060728092, -3.810875984792863],
              [114.809041060715273, -3.810875984775082],
              [114.808886308747802, -3.810729751692059],
              [114.80973198122436, -3.809129271400423],
              [114.810566925551342, -3.809390164622221],
              [114.810851903164448, -3.809556660602649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.5,
        Nm_Pemilik: "Mujiati",
        PENGGANTI: null,
        No_Kav: "7",
        KET: "Sertifikat",
        KODE: "Hijau",
        block: "Blok 1",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808886308308658, -3.810729751470094],
              [114.808278793292999, -3.810155680633083],
              [114.808582260134102, -3.809715279539221],
              [114.808923580392047, -3.809105231423632],
              [114.809061748438211, -3.808919844644751],
              [114.80973198122436, -3.809129271400423],
              [114.808886308308658, -3.810729751470094],
            ],
          ],
        ],
      },
    },
  ],
};
