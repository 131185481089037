export const KEYCLOAK_ID = process.env.REACT_APP_KEYCLOAK_ID;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_SECRET = process.env.REACT_APP_KEYCLOAK_SECRET;
export const KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_BASE_URL;

export const LOGIN_CALLBACK_URL = process.env.REACT_APP_LOGIN_CALLBACK_URL;
export const LOGOUT_CALLBACK_URL = process.env.REACT_APP_LOGOUT_CALLBACK_URL;
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const APP_NAME = process.env.REACT_APP_NAME;

export const BASE_URL = BACKEND_BASE_URL;
export const UUID_GENERATOR_URL = "https://www.uuidgenerator.net/api/version1";
// const BASE_AREA_URL = "https://dev.farizdotid.com/api/daerahindonesia";
const BASE_AREA_URL = "https://kanglerian.github.io/api-wilayah-indonesia/api";

export const REGISTER_URL = BASE_URL + "/companies/signup";

// Harvesting
export const BLOCKS_URL = BASE_URL + "/blocks";
export const BLOCKS_IMPORT_EXCEL_URL = BLOCKS_URL + "/import";
export const HARVESTINGS_URL = BASE_URL + "/harvestings";
export const FERTILIZATIONS_URL = BASE_URL + "/fertilizations";
export const SPOT_CHECK_URL = BASE_URL + "/spot-checks";
export const HARVESTING_TARGETS_URL = BASE_URL + "/harvesting-targets";
export const PRODUCTION_RECAPS_DAILY = BASE_URL + "/production-recaps";
export const PRODUCTION_RECAPS_RECALCULATE =
  PRODUCTION_RECAPS_DAILY + "/cmd/recalculate";
export const AFDELINGS_URL = BASE_URL + "/afdelings";
export const WORKER_URL = BASE_URL + "/users";
export const ESTATES_URL = BASE_URL + "/estates/";
export const FFB_STOCK_MOVEMENT = BASE_URL + "/ffb-stock-movements";
export const CHEMISTS_URL = BASE_URL + "/chemists";
export const GENERIC_RECORDS_URL = BASE_URL + "/generic-records";
export const DIMENSIONAL_TAG = BASE_URL + "/dimensional-tags";
export const EMPLOYEES_URL = BASE_URL + "/employees";
export const FFB_TRADING_PRICE_LISTS_URL =
  BASE_URL + "/ffb-trading-price-lists";
export const PARTIES_URL = BASE_URL + "/parties";
export const VEHICLE_CHECKPOINT = BASE_URL + "/vehicle-checkpoints";
export const ESTATES_IMPORT_EXCEL_URL = ESTATES_URL + "import";
export const COMPANIES_URL = BASE_URL + "/companies/";
export const COMPANIES_NEW_URL = BASE_URL + "/companies";
export const USER_URL = BASE_URL + "/users/";
export const COLLECTION_POINTS_URL = BASE_URL + "/collection-points";
export const MEMBER_URL = "/members";
export const USER_IMPORT_EXCEL_URL = USER_URL + "import";
export const LOADS_URL = BASE_URL + "/loads";
export const VEHICLE_URL = BASE_URL + "/vehicles";
export const DELIVERY_URL = BASE_URL + "/deliveries";
export const AVERAGEFRUIT_URL = BASE_URL + "/harvesting-smes";
export const FILE_ATTACHMENTS_URL = BASE_URL + "/file-contents";

export const FERTILIZATION_RECAPITULATION_URL =
  BASE_URL + "/fertilization-recaps";
export const CHEMIST_RECAPITULATION_URL = BASE_URL + "/chemist-recaps";

// export const PROVINCE_URL = BASE_AREA_URL + "/provinsi";
export const PROVINCE_URL = BASE_AREA_URL + "/provinces.json";
export const CITIES_URL = BASE_AREA_URL + "/regencies";
export const SUBDISTRICTS_URL = BASE_AREA_URL + "/districts";
export const VILLAGES_URL = BASE_AREA_URL + "/villages";
