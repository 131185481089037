import React from "react";
import TableRow from "./partials/TableRow/TableRow";
import Tables from "../../../../../../../components/organisms/Tables";
import { useData } from "../../../../hooks/DataContext";

const DataSection = () => {
	const { sideTableData } = useData();
	const headers = [
		{
			value: "Wilayah",
			accessorKey: "afdelingName",
			type: "alphabet",
		},
		{
			value: "Yield",
			accessorKey: "bjr",
			type: "tonHa",
		},
	];

	// backup for container
	// grid-rows-[minmax(0px,0.5fr)_minmax(0px,1fr)]
	return (
		<div className="relative grid grid-cols-1 grid-rows-1 gap-8">
			{/* <div className="relative h-full max-h-full overflow-y-scroll rounded-8 bg-white shadow-sm scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200">
				<div className="w-full divide-y rounded-8 border border-gray-10">
					{data1.map((item) => (
						<div className="grid grid-cols-2 gap-8 p-8">
							<div className="flex flex-col">
								<p className="font-regular lg:text-13">Yield</p>
								<h3 className="font-bold lg:text-14">
									{item?.afdelingName}
								</h3>
							</div>
							<div className="flex flex-row items-center justify-end space-x-4">
								<div className="flex flex-row items-center space-x-4">
									<h2 className="font-semibold lg:text-16">
										{parseToFloat(4)}
									</h2>
									<sub className="font-regular lg:text-[12px]">
										ton/ha
									</sub>
								</div>
								<div className="flex flex-row items-center rounded-full border border-yellow-600 bg-red-100 px-4">
									<TbArrowDown className="text-red-600" />
									<p className="font-regular text-red-600 lg:text-13">
										{parseToFloat(0)}%
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div> */}
			<div className="h-full w-full rounded-8 bg-white shadow-sm">
				<Tables
					headers={headers}
					rows={sideTableData}
					className="max-h-full scrollbar-thin scrollbar-thumb-gray-100"
					rowContent={(props) => <TableRow data={props} />}
					size="sm"
				/>
			</div>
		</div>
	);
};

export default DataSection;
