import React from "react";
import { TbFileDownload, TbFileImport, TbRefresh } from "react-icons/tb";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { getYears } from "../../../../../helpers/Common";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../features/userSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../App";
import moment from "moment/moment";
import { pagePath } from "../../ListView";

const Filter = ({
  handleRefreshData,
  handleDownloadTemplate,
  onFilter,
  showModalImport,
}) => {
  const user = useSelector(selectUser);
  const { control } = useForm();

  return (
    <div className="mx-16 my-8 mt-56 grid grid-cols-1 gap-8 md:my-8 md:grid-cols-2">
      <div className="grid w-full grid-cols-2 gap-8 md:flex md:w-fit md:flex-row md:items-center md:space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition order-3 border-yellow-500 bg-white p-10"
          onClick={() => {
            logEvent(
              analytics,
              `refresh_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_button: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleRefreshData();
          }}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Unggah target bulanan"
          size="none"
          type="button"
          className="bordered-button-transition border-yellow-500 bg-white p-10"
          onClick={() => {
            showModalImport();
          }}
          icon={<TbFileImport className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Unduh template"
          size="none"
          type="button"
          className="bordered-button-transition border-yellow-500 bg-white p-10"
          onClick={() => {
            logEvent(
              analytics,
              `download_template_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_button: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            handleDownloadTemplate();
          }}
          icon={<TbFileDownload className="text-18 text-gray-500" />}
        />
        <SelectHook
          name="year"
          data={getYears()}
          control={control}
          placeholder="Pilih tahun"
          menuStyles={{ padding: 5 }}
          className="max-w-[200px]"
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_year_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );
            onFilter({ type: "year", value });
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
