import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import {
  getCompaniesApi,
  createCompanyApi,
  updateCompanyApi,
  deleteCompanyApi,
  getCompanyApi,
} from "./companyApi.js";
import { calculateToastDuration } from "../../helpers/Common";

export const useCompanyStore = create((set, get) => ({
  companies: [],
  company: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getCompanies: async ({
    params = {},
    silent,
    onSuccess,
    onError,
    currentFilePath,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getCompaniesApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Failed to Get Data Companies",
          description: `${data.message}. ${
            currentFilePath && `Current File Path: ${currentFilePath}`
          }`,
          placement: "bottomRight",
        });
      }
      set({ companies: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Failed to Get Data Companies",
        description: `${error.data}. ${
          currentFilePath && `Current File Path: ${currentFilePath}`
        }`,
        placement: "bottomRight",
      });
    }
  },
  getCompany: async ({
    id,
    params = {},
    silent,
    onSuccess,
    onError,
    currentFilePath,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getCompanyApi(id, params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Failed to Get Data Company",
          description: `${data.message}. ${
            currentFilePath && `Current File Path: ${currentFilePath}`
          }`,
          placement: "bottomRight",
        });
      }
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Failed to Get Data Company",
        description: `${error.data}. ${
          currentFilePath && `Current File Path: ${currentFilePath}`
        }`,
        placement: "bottomRight",
      });
    }
  },
  createCompany: async ({
    body,
    silent,
    onSuccess,
    onError,
    currentFilePath,
  }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createCompanyApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Failed to Create Company",
          description: `${data.message}. ${
            currentFilePath && `Current File Path: ${currentFilePath}`
          }`,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Successfully Created Company`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Failed to Create Company",
        description: `${error.data}. ${
          currentFilePath && `Current File Path: ${currentFilePath}`
        }`,
        placement: "bottomRight",
      });
    }
  },
  updateCompany: async ({
    id,
    body,
    silent,
    onSuccess,
    onError,
    currentFilePath,
  }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateCompanyApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Failed to Update Company",
          description: `${data.message}. ${
            currentFilePath && `Current File Path: ${currentFilePath}`
          }`,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Successfully Updated Company`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Failed to Update Company",
        description: `${error.data}. ${
          currentFilePath && `Current File Path: ${currentFilePath}`
        }`,
        placement: "bottomRight",
      });
    }
  },
  deleteCompany: async ({
    id,
    silent,
    onSuccess,
    onError,
    currentFilePath,
  }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await deleteCompanyApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Failed to Delete Company",
          description: `${data.message}. ${
            currentFilePath && `Current File Path: ${currentFilePath}`
          }`,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Successfully Deleted Company`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Failed to Delete Company",
        description: `${error.data}. ${
          currentFilePath && `Current File Path: ${currentFilePath}`
        }`,
        placement: "bottomRight",
      });
    }
  },
}));
