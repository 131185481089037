export const jilatan25 = {
  type: "FeatureCollection",
  name: "Jilatan25Kepemilikan_3",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2002",
        Nm_Pemilik: "Sumanto",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "1",
        Luas_SHM: 10081.0,
        Nm_SHM_Ud: "Sumanto",
        Luas_Inkes: 1.0,
        Nm_Baru_20: "Totok Dewanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844402333432669, -3.91280881679969],
              [114.845805278473378, -3.912780324341492],
              [114.845404308699855, -3.913381054831542],
              [114.843984135285183, -3.913377391147344],
              [114.844402333432669, -3.91280881679969],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2001",
        Nm_Pemilik: "Totok Dewanto (KJP)",
        Status_Kep: "Dibeli KJP (April 2017)",
        Lokasi: "Jilatan 25 Pak Mujahid",
        No_Kvl: "2",
        Luas_SHM: 19888.0,
        Nm_SHM_Ud: "Mujahidin",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84398413528524, -3.913377391147344],
              [114.845404308699884, -3.913381054831542],
              [114.844714647823267, -3.914414296916164],
              [114.842781909803477, -3.914409307448083],
              [114.843216142217614, -3.914192845452329],
              [114.84378119479365, -3.913653304713804],
              [114.84398413528524, -3.913377391147344],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2003",
        Nm_Pemilik: "Ririen Kartika Rini",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "3",
        Luas_SHM: 17684.0,
        Nm_SHM_Ud: "Ririen Kartika Rini",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Ririen Kartika sari",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842781909803477, -3.914409307448083],
              [114.844714647823267, -3.914414296916164],
              [114.844256578553953, -3.915101119186445],
              [114.842235460656624, -3.915095815910316],
              [114.84227108054651, -3.914792883515982],
              [114.842651761095041, -3.91447418564048],
              [114.842781909803477, -3.914409307448083],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2004",
        Nm_Pemilik: "Denisa Tetraparistha",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "4",
        Luas_SHM: 19894.0,
        Nm_SHM_Ud: "Denisa Tetraparistha",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Pruwitasari Endah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842235460656624, -3.915095815910316],
              [114.844256634164097, -3.915101035805591],
              [114.844254891758254, -3.915241996886193],
              [114.844477027995595, -3.915258509362543],
              [114.844359550208779, -3.915851707644873],
              [114.842284573110817, -3.915846347964273],
              [114.842195924724649, -3.915432051755489],
              [114.842235460656624, -3.915095815910316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2005",
        Nm_Pemilik: "Dewi Retno Wulandari",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "5",
        Luas_SHM: 18727.0,
        Nm_SHM_Ud: "Dewi Retno Wulandari",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Dewi Retno W.",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844359550208779, -3.915851707644873],
              [114.844201510609452, -3.916649717536851],
              [114.842492536422114, -3.916645302401074],
              [114.842438102126081, -3.916563862611813],
              [114.842284573110817, -3.915846347964273],
              [114.844353982714168, -3.915851693270891],
              [114.844359550208779, -3.915851707644873],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2006",
        Nm_Pemilik: "Lusita Wardani (KJP)",
        Status_Kep: "Dibeli KJP (April 2017)",
        Lokasi: "Jilatan 25 Pak Mujahid",
        No_Kvl: "6",
        Luas_SHM: 15422.0,
        Nm_SHM_Ud: "Mujahidin",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842492536422114, -3.916645302401074],
              [114.844201510609452, -3.916649717536851],
              [114.844008296631003, -3.917625332912318],
              [114.843146099569068, -3.917623105111769],
              [114.842492536422114, -3.916645302401074],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2007",
        Nm_Pemilik: "Sumanto",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "7",
        Luas_SHM: 11324.0,
        Nm_SHM_Ud: "Sumanto",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Totok Dewanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843146099569068, -3.917623105111769],
              [114.844008296631003, -3.917625332912318],
              [114.843819532903382, -3.918579196233499],
              [114.843747925095101, -3.918773381946594],
              [114.843486162567999, -3.918704901659505],
              [114.843250992378358, -3.919128719962769],
              [114.843142253800764, -3.91912388782169],
              [114.843146099569068, -3.917623105111769],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2008",
        Nm_Pemilik: "Tjindar Bumi Kusuma Ningsih (KJP)",
        Status_Kep: "Dibeli KJP (April 2017)",
        Lokasi: "Jilatan 25 Pak Mujahid",
        No_Kvl: "8",
        Luas_SHM: 18595.0,
        Nm_SHM_Ud: "Mujahidin",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Mujahid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842074954959926, -3.917483304565736],
              [114.843146099569068, -3.917623105111769],
              [114.843142253800764, -3.91912388782169],
              [114.84303503455881, -3.919119598350577],
              [114.842648259122456, -3.919010112325685],
              [114.842071762132605, -3.918728748285168],
              [114.842074954959926, -3.917483304565736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        No_SHM: "M 2009",
        Nm_Pemilik: "Denisa Tetraparistha",
        Status_Kep: "INKESA",
        Lokasi: "Jilatan 25",
        No_Kvl: "9",
        Luas_SHM: 17740.0,
        Nm_SHM_Ud: "Denisa Tetraparistha",
        Luas_Inkes: 2.0,
        Nm_Baru_20: "Pruwitasari Endah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842074954959926, -3.917483304565736],
              [114.842071762132605, -3.918728748285168],
              [114.840652466268878, -3.918037617430583],
              [114.840552402057781, -3.917245426615969],
              [114.841149365691848, -3.917384326749897],
              [114.842074954959926, -3.917483304565736],
            ],
          ],
        ],
      },
    },
  ],
};
