import React, { useEffect, useState } from "react";
import { useBlockStore } from "../api/blocks/useBlockStore";
import { useUserStore } from "../pages/MasterWorkerPage/hooks/userStore";
import { useEstateStore } from "../api/estates/useEstateStore";
import { useAfdelingStore } from "../api/afdelings/useAfdelingStore";

const useFilterDataDefaults = () => {
  const [users, setUsers] = useState([]);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const getBlocks = useBlockStore((state) => state.getBlocks);
  const getEstates = useEstateStore((state) => state.getEstates);
  const getAfdelings = useAfdelingStore((state) => state.getAfdelings);
  const getUsers = useUserStore((state) => state.getUsers);
  const user = useUserStore((state) => state.user);

  const fetchEstates = async ({ name, page, defaultData = [], params }) => {
    try {
      const response = await getEstates({
        fltCompany: user?.companyId,
        fltName: name || undefined,
        pageIndex: page || 0,
        ...params,
      });

      const records = response.records;
      let estates = [...defaultData];

      records.forEach((item) => {
        return estates.push({
          value: `${item?.id}`,
          label: `${item?.name}`,
          ...item,
        });
      });
      setEstates(estates);
    } catch (error) {}
  };

  const fetchAfdelings = async ({
    estateId,
    name,
    page,
    defaultData = [],
    params,
  }) => {
    try {
      const response = await getAfdelings({
        fltCompany: user?.companyId,
        fltEstate: estateId,
        fltName: name || undefined,
        pageIndex: page || 0,
        ...params,
      });

      const records = response.records;
      let afdelings = [...defaultData];

      records.forEach((item) => {
        return afdelings.push({
          value: `${item?.id}`,
          label: `${item?.name}`,
          ...item,
        });
      });
      setAfdelings(afdelings);
    } catch (error) {}
  };

  const fetchBlocks = async ({
    afdelingId,
    name,
    page,
    defaultData = [],
    params,
  }) => {
    try {
      const response = await getBlocks({
        fltCompany: user?.companyId,
        fltAfdeling: afdelingId,
        fltName: name || undefined,
        pageIndex: page || 0,
        ...params,
      });

      const records = response.records;
      let blocks = [...defaultData];
      records.forEach((item) => {
        return blocks.push({
          value: `${item?.id}`,
          label: `${item?.blockName}`,
          ...item,
        });
      });
      setBlocks(blocks);
    } catch (error) {}
  };

  const fetchUsers = async ({ name, page, defaultData = [], params }) => {
    try {
      const response = await getUsers({
        fltCompany: user?.companyId,
        fltFullname: name || undefined,
        pageIndex: page || 0,
        ...params,
      });

      const records = response.records;
      let users = [...defaultData];
      records.forEach((item) => {
        return users.push({
          value: `${item?.id}`,
          label: `${item?.fullname}`,
          ...item,
        });
      });
      setUsers(users);
    } catch (error) {}
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchUsers({});
      fetchEstates({});
    }
    return () => (isMounted = false);
  }, []);

  return {
    users,
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
    fetchUsers,
  };
};

export default useFilterDataDefaults;
