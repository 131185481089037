import Navbar from "../../components/atoms/Navbar";
import { LuMap } from "react-icons/lu";
import React from "react";
import MapContainer from "./MapContainer";
import ReactMapGlViewer from "./ReactMapGlViewer";

const MapboxPage = () => {
  return (
    <MapContainer>
      <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <LuMap className="text-24 text-gray-500" />
              <p className="line-clamp-1">Peta Kebun</p>
            </div>
          }
        />
        {/*<MapViewer />*/}
        <ReactMapGlViewer />
      </div>
    </MapContainer>
  );
};

export default MapboxPage;
