export const headersData = [
  {
    value: "NO.",
    accessorKey: "index",
    type: "alphabet",
  },
  {
    value: "Foto",
    accessorKey: "photoFileId",
    type: "alphabet",
  },
  {
    value: "Tanggal",
    accessorKey: "createdTime",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Dibuat Oleh",
    accessorKey: "createdBy",
    type: "alphabet",
  },
  {
    value: "Blok",
    accessorKey: "blockName",
    type: "number",
  },
  {
    value: "Material Diterima",
    accessorKey: "receivedFertilizerSum",
    type: "number",
  },
  {
    value: "Dosis Material (Kg/Pokok)",
    accessorKey: "unitValues",
    type: "number",
  },
  {
    value: "Pokok Terpupuk",
    accessorKey: "treeCount",
    type: "number",
  },
  {
    value: "Sisa Pupuk (Kg)",
    accessorKey: "restFertilizer",
    type: "number",
  },
];
