import Pagination from "../../../../../components/atoms/Pagination";
import React from "react";
import TableRow from "./partials/TableRow/TableRow";
import Tables from "../../../../../components/organisms/Tables";
import { headersData } from "./api/api/headerData";
import { useData } from "../../hooks/DataContext";

const TableSection = () => {
  const { data, handlePageClick, page, paginationLenght } = useData();

  return (
    // grid-rows-[minmax(0px,0.15fr)_minmax(0px,1fr)]
    <div className="relative mx-8 grid grid-cols-1 grid-rows-[1fr,max-content] md:mx-0 md:grid-rows-[minmax(0px,1fr)_minmax(0px,0.15fr)]">
      {/*<div className="border border-red-500">Table</div>*/}
      {/*<div className="border border-red-500">Pagination</div>*/}

      <div className="h-full w-full rounded-t-8 border-1 border-gray-10 bg-white shadow-sm">
        <Tables
          headers={headersData}
          rows={data || []}
          className="max-h-full"
          rowContent={(data) => <TableRow data={data} />}
        />
      </div>

      <Pagination.Container className="mx-0 mb-0">
        <Pagination.Records
          current={page.current}
          length={paginationLenght}
          totalRecords={page?.total}
        />
        {paginationLenght !== 1 && (
          <Pagination
            pageCount={paginationLenght}
            onPageClick={handlePageClick}
          />
        )}
      </Pagination.Container>
    </div>
  );
};

export default TableSection;
