import React, { useEffect, useState } from "react";
import ModalContainer from "../../../../../../components/atoms/Modal";

import { useForm } from "react-hook-form";
import Content from "./partials/Content/Content.jsx";
import { isEmptyObject } from "../../../../../../helpers/Common";
import { useDimensionalTagStore } from "../../../../../../api/dimensionalTag/useDimensionalTagStore";
import { useUserStore } from "../../../../../MasterWorkerPage/hooks/userStore";
import { usePartiesStore } from "../../../../../../api/parties/usePartiesStore";
import { useModalStore } from "../Modal/Modal";

const ModalTag = ({ onRefreshData }) => {
  const [tags, setTags] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [isCreateDimensionalTag, setIsCreateDimensionalTag] = useState(false);
  const [modal, isOpen, isLoading, toggleModal] = useModalStore((state) => [
    state.modalTag,
    state.modalTag.isOpen,
    state.modalTag.isLoading,
    state.toggleModal,
  ]);
  const [updateParty] = usePartiesStore((state) => [state.updateParty]);
  const [getDimensionalTags, createDimensionalTag] = useDimensionalTagStore(
    (state) => [state.getDimensionalTags, state.createDimensionalTag]
  );
  const [user] = useUserStore((state) => [state.user]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit = (inputData) => {
    if (isCreateDimensionalTag) {
      const body = {
        companyId: user?.companyId,
        companyName: user?.adhocEstateName,
        name: inputData?.name,
        description: inputData?.description,
        itemPrefix: inputData?.itemPrefix,
        items: inputData?.newTags?.map((item) => item?.value),
      };
      createDimensionalTag({
        body,
        onSuccess: () => {
          setIsCreateDimensionalTag(false);
          fetchData();
        },
      });
    } else {
      const body = {
        ...modal?.data,
        tags: tagsSelected?.map((item) => item?.value),
      };
      updateParty({
        id: modal?.data?.id,
        body,
        onSuccess: () => {
          onRefreshData(true);
          toggleModal({ key: "modalTag", data: {} });
        },
      });
    }
  };

  const fetchData = () => {
    getDimensionalTags({
      params: { pageSize: 1000, pageIndex: 0 },
      onSuccess: (data) => {
        const newData = data?.records?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        }));
        setTags(newData);
      },
    });
  };

  const clearGroupTagForm = () => {
    setValue("name", null);
    setValue("description", null);
    setValue("itemPrefix", null);
    setValue("newTags", null);
  };

  useEffect(() => {
    setTagsSelected([]);
    setTags([]);

    setValue("tags", null);
    setValue("dimension", null);
    setValue("namePks", null);
    setValue("descriptionPks", null);
    setIsCreateDimensionalTag(false);
  }, [isOpen, modal?.data]);

  useEffect(() => {
    fetchData();

    if (!isEmptyObject(modal?.data)) {
      setValue(
        "tags",
        modal?.data?.tags?.map((item) => ({
          label: item,
          value: item,
        }))
      );
      setValue("namePks", modal?.data?.name);
      setValue("descriptionPks", modal?.data?.description);
    }
  }, [isOpen, modal?.data, isCreateDimensionalTag]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ key: "modalTag", data: {} })}
      title={
        <p className="flex flex-row items-center space-x-8">
          {isCreateDimensionalTag ? "Tambah Tag Grup Baru" : modal?.data?.name}
        </p>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            tags={tags}
            watch={watch}
            tagsSelected={tagsSelected}
            setTagsSelected={setTagsSelected}
            isCreateDimensionalTag={isCreateDimensionalTag}
            setIsCreateDimensionalTag={setIsCreateDimensionalTag}
            clearGroupTagForm={clearGroupTagForm}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ key: "modalTag", data: {} })}
          type={isCreateDimensionalTag ? "Buat Grup Tag" : "Simpan Tag"}
        />
      </form>
    </ModalContainer>
  );
};

export default ModalTag;
