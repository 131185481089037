import M1Content from "./M1Content";
import MoContent from "./MoContent";
import React from "react";
import { masterEstateRows } from "../../api/tables/dataTph/masterEstateRows";
import usePrivilage from "../../hooks/usePrivilages";

export const base = masterEstateRows();
export const modalName = "masterTph";

const MasterTphPage = () => {
	const { excludeM1Privilage } = usePrivilage();

	return (
		<div className="relative w-full overflow-hidden bg-white">
			<MoContent />
			{/* {!excludeM1Privilage && <M1Content />} */}
		</div>
	);
};

export default MasterTphPage;
