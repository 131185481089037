import React, { useEffect } from "react";
import DatasetImage from "../Panel/partials/DatasetImage";
import { useSpotCheckStore } from "../../hooks/useSpotCheckStore";
import { getFertilizationSpotChecks } from "../../../../../features/fertilizationSlice";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { Image } from "antd";

const SpotChecks = () => {
  const { id } = useParams();
  const [spotChecks, request, setRequest, setSpotChecks, setPage] =
    useSpotCheckStore((state) => [
      state.spotChecks,
      state.request,
      state.setRequest,
      state.setSpotChecks,
      state.setPage,
    ]);

  const dispatch = useDispatch();
  const alert = useAlert();

  const getSpotCheckData = async (parentId) => {
    try {
      const response = await dispatch(
        getFertilizationSpotChecks({ id, pageSize: 24 })
      ).unwrap();
      if (!response.success) {
        setRequest({ isLoadingSpotChecks: false });
        return alert.error(response.message);
      }
      const records = response.records;
      const totalRecords = response?.totalRecords;

      setSpotChecks(records);
      setPage({
        current: 0,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoadingSpotChecks: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoadingSpotChecks: false });
      return alert.error(error.data);
    }
  };

  useEffect(() => {
    getSpotCheckData();
  }, [id]);

  return (
    <div className="w-full max-w-[1247px] overflow-y-scroll rounded-16 border border-gray-10 bg-white p-16 md:mx-auto">
      <div className="grid grid-cols-3 gap-8 md:grid-cols-10">
        <Image.PreviewGroup>
          {request?.isLoadingSpotChecks ? (
            <div className="col-span-10 flex w-full flex-col items-center justify-center">
              <div className="loader-spinner-gradient h-32 w-32"></div>
              <p className="font-regular text-14 text-gray-500">
                Sedang dalam proses memuat data spot check...
              </p>
            </div>
          ) : spotChecks?.length < 1 ? (
            <div className="col-span-10 flex w-full flex-col items-center justify-center">
              <p className="font-regular text-14 text-gray-300">
                Tidak ada data gambar bukti spot check tersedia.
              </p>
            </div>
          ) : spotChecks?.length >= 1 ? (
            spotChecks.map((item) => {
              return <DatasetImage item={item} />;
            })
          ) : null}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default SpotChecks;
