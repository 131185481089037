import React from "react";
import PropTypes from "prop-types";
import InputHook from "../../../../../../../../components/atoms/InputHook";
import InputPatternFormat from "../../../../../../../../components/atoms/InputPatternFormat";
import SelectHook from "../../../../../../../../components/atoms/SelectHook";
import { useSelector } from "react-redux";
import { selectArea } from "../../../../../../../../features/areaSlice";

const FormDivider = ({ children }) => {
  return (
    <div className="grid grid-cols-[1fr,max-content,1fr] items-center justify-center gap-8">
      <div className="h-[2px] rounded-full bg-gray-100" />
      <p className="text-16 font-bold text-gray-500">{children}</p>
      <div className="h-[2px] rounded-full bg-gray-100" />
    </div>
  );
};
const Content = ({ control, register, errors, isUpdate }) => {
  const area = useSelector(selectArea);

  return (
    <div className="relative grid grid-cols-1 gap-12">
      <FormDivider>Biodata Anggota</FormDivider>
      <div className="relative grid grid-cols-2 gap-12">
        <InputHook
          required
          register={register}
          label="Nama Anggota"
          name="name"
          errors={errors?.name}
          inputClassName="input-large-border"
          placeholder="Masukkan nama anggota"
        />
        <InputPatternFormat
          required
          control={control}
          label="No. HP"
          name="phone"
          errors={errors?.phone}
          inputClassName="input-large-border"
          placeholder="Masukkan nomer telepon"
          format="+62 ###-####-####"
        />
        <InputPatternFormat
          control={control}
          label="No. Induk Kependudukan"
          name="identificationNumber"
          errors={errors?.identificationNumber}
          inputClassName="input-large-border"
          placeholder="Masukkan no. induk kependudukan"
          format="#### #### #### ####"
        />
        <InputHook
          control={control}
          label="No. Anggota"
          name="employeeNo"
          type="number"
          errors={errors?.employeeNo}
          inputClassName="input-large-border"
          placeholder="Masukkan no. anggota"
        />
      </div>
      <FormDivider>Alamat Tinggal (Opsional)</FormDivider>
      <div className="grid grid-cols-2 items-center gap-12">
        <SelectHook
          label="Provinsi"
          name="province"
          errors={errors?.province}
          data={area.provinces}
          control={control}
          placeholder="Pilih provinsi"
        />
        <SelectHook
          label="Kota/Kabupaten"
          name="city"
          errors={errors?.city}
          data={area.cities}
          control={control}
          placeholder="Pilih kota/kabupaten"
        />
        <SelectHook
          label="Kecamatan"
          name="subdistrict"
          errors={errors?.subdistrict}
          data={area.subdistricts}
          control={control}
          placeholder="Pilih kecamatan"
        />
        <SelectHook
          label="Kelurahan/Desa"
          name="village"
          errors={errors?.village}
          data={area.villages}
          control={control}
          placeholder="Pilih kelurahan/desa"
        />
      </div>
    </div>
  );
};

export default Content;

Content.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};
