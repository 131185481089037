export const pantailinuh2009 = {
  type: "FeatureCollection",
  name: "kuringkittt09_200ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.574,
        block: "BLOK 2",
        Nm_Pemilik: "Sutawi",
        No_Kav: "13",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247740.50408000001,
        Y_COORD: 9555683.3562800009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727527250272843, -4.016233043550754],
              [114.728420898512397, -4.016241973634018],
              [114.728584290606804, -4.016403264192634],
              [114.727860227657459, -4.01703591759672],
              [114.727527250272843, -4.016233043550754],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.055,
        block: "BLOK 1",
        Nm_Pemilik: "I Made Supana",
        No_Kav: "2",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247296.80493000001,
        Y_COORD: 9554967.25612,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723227554148323, -4.024199497904642],
              [114.723243517453255, -4.0240966034193],
              [114.723251937270192, -4.024044904044647],
              [114.723577163246631, -4.021992215181575],
              [114.724858314785109, -4.021995801474876],
              [114.723461214844775, -4.024199816156404],
              [114.723227554148323, -4.024199497904642],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.071,
        block: "BLOK 1",
        Nm_Pemilik: "Gt. sugiannor",
        No_Kav: "3",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247358.14551,
        Y_COORD: 9555149.2455800008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723577163246631, -4.021992215181575],
              [114.723748518992124, -4.0209106786121],
              [114.725622172063709, -4.020915921055489],
              [114.725208116344078, -4.021443963688694],
              [114.724858314785109, -4.021995801474876],
              [114.723577163246631, -4.021992215181575],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.064,
        block: "BLOK 1",
        Nm_Pemilik: "Purwanto",
        No_Kav: "4",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247403.05097000001,
        Y_COORD: 9555253.0651699994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.723748518992124, -4.0209106786121],
              [114.723895299698825, -4.020117162052655],
              [114.723895500307762, -4.020116099890752],
              [114.725346426250823, -4.020120158708525],
              [114.725387367265583, -4.02012027317309],
              [114.726264468547456, -4.020122725502054],
              [114.726139500742093, -4.020256173317592],
              [114.725622172063709, -4.020915921055489],
              [114.723748518992124, -4.0209106786121],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.095,
        block: "BLOK 1",
        Nm_Pemilik: "Sutarto",
        No_Kav: "5",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247554.29038,
        Y_COORD: 9555363.6897999998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725390708170352, -4.018915233023774],
              [114.727353890777067, -4.018947465157415],
              [114.727070345227645, -4.019262164264169],
              [114.726264468547456, -4.020122725502054],
              [114.725387367265583, -4.02012027317309],
              [114.725390708170352, -4.018915233023774],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.552,
        block: "BLOK 1",
        Nm_Pemilik: "M. Asegaf",
        No_Kav: "1",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247154.57579,
        Y_COORD: 9554712.5914299991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.722509378872545, -4.024246657222704],
              [114.723513499567929, -4.02426528935695],
              [114.723497121710011, -4.02449065380003],
              [114.723326484243557, -4.024960769110738],
              [114.722958555400353, -4.02557269560874],
              [114.722464139898662, -4.026354313180319],
              [114.721998938705227, -4.026542827433763],
              [114.722755576091444, -4.024927532463504],
              [114.722709068696105, -4.024658491677777],
              [114.722509378872545, -4.024246657222704],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        block: "BLOK 12",
        Nm_Pemilik: "Nur Mulyadi",
        No_Kav: "104",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248109.58357,
        Y_COORD: 9557290.6140599996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730289484788145, -4.001474522089996],
              [114.730336994173499, -4.001485880627427],
              [114.730692714031576, -4.001620703835486],
              [114.731856987740898, -4.002226651846662],
              [114.732325041310816, -4.002526169773164],
              [114.732545071831993, -4.002756626386515],
              [114.730286034741681, -4.002727223223403],
              [114.730289484788145, -4.001474522089996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        block: "BLOK 12",
        Nm_Pemilik: "Abdul Salam",
        No_Kav: "103",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247918.21642000001,
        Y_COORD: 9557304.6240800004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729104053143644, -4.001241063923892],
              [114.729681597584829, -4.001329187052959],
              [114.730289484788145, -4.001474522089996],
              [114.730286033560418, -4.002727222158439],
              [114.729100001918709, -4.002711783984467],
              [114.729104053143644, -4.001241063923892],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        block: "BLOK 12",
        Nm_Pemilik: "Fitria Anggraini",
        No_Kav: "101",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247608.48111,
        Y_COORD: 9557293.0539900009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726761867490055, -4.0014691078966],
              [114.727780595087111, -4.00157710320887],
              [114.72777751405674, -4.002694874141399],
              [114.726032541763601, -4.002672703346217],
              [114.726761867490055, -4.0014691078966],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.949,
        block: "BLOK 3",
        Nm_Pemilik: "Damai",
        No_Kav: "21",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247917.5416,
        Y_COORD: 9555875.3263700008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729110687290643, -4.013634020179515],
              [114.730203188691092, -4.013637916389856],
              [114.729261011311365, -4.016172530934782],
              [114.729103430689477, -4.01617176720139],
              [114.729110687290643, -4.013634020179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.94,
        block: "BLOK 3",
        Nm_Pemilik: "Abdul Azis",
        No_Kav: "20",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247822.30814000001,
        Y_COORD: 9555875.3713300005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728488450196508, -4.013631800686011],
              [114.729110687290643, -4.013634020179515],
              [114.729103426948271, -4.016171763423423],
              [114.728481433914382, -4.016168743640043],
              [114.728488450196508, -4.013631800686011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.929,
        block: "BLOK 3",
        Nm_Pemilik: "Ahmad Mudhofar",
        No_Kav: "19",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247753.36758,
        Y_COORD: 9555875.4690499995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727869543627776, -4.01362959260136],
              [114.728488450196508, -4.013631800686011],
              [114.728481433914382, -4.016168743640043],
              [114.727862527641847, -4.016165738372051],
              [114.727869543627776, -4.01362959260136],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.942,
        block: "BLOK 3",
        Nm_Pemilik: "M. Nur Ali",
        No_Kav: "18",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247684.3716,
        Y_COORD: 9555875.5666099992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727246309312932, -4.013627368601863],
              [114.727869543627776, -4.01362959260136],
              [114.727862527641847, -4.016165738372051],
              [114.727239293626511, -4.016162711614888],
              [114.727246309312932, -4.013627368601863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.942,
        block: "BLOK 3",
        Nm_Pemilik: "Sakip",
        No_Kav: "17",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247615.13522,
        Y_COORD: 9555875.6646699999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726623075271007, -4.013625144127018],
              [114.727246309312932, -4.013627368601863],
              [114.727239293626511, -4.016162711614888],
              [114.726616059885302, -4.01615968438277],
              [114.726623075271007, -4.013625144127018],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.927,
        block: "BLOK 3",
        Nm_Pemilik: "Sakip",
        No_Kav: "16",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247546.13924,
        Y_COORD: 9555875.7625300009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726004169513402, -4.013622934629489],
              [114.726623075271007, -4.013625144127018],
              [114.726616059885302, -4.01615968438277],
              [114.725997154427517, -4.016156677703077],
              [114.726004169513402, -4.013622934629489],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.94,
        block: "BLOK 3",
        Nm_Pemilik: "M. Kasim",
        No_Kav: "15",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247477.14326000001,
        Y_COORD: 9555875.8600900006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725380936015611, -4.013620709207245],
              [114.726004169513402, -4.013622934629489],
              [114.725997154427517, -4.016156677703077],
              [114.725373921232887, -4.01615364952435],
              [114.725380936015611, -4.013620709207245],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.948,
        block: "BLOK 3",
        Nm_Pemilik: "M. Kasim",
        No_Kav: "14",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247396.85685000001,
        Y_COORD: 9555875.9498100001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724951327133411, -4.013619174896249],
              [114.725380936015611, -4.013620709207245],
              [114.725373921232887, -4.01615364952435],
              [114.724551754168033, -4.016149654032904],
              [114.724951327133411, -4.013619174896249],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.833,
        block: "BLOK 4",
        Nm_Pemilik: "Ahmad Wahyudi",
        No_Kav: "22",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247947.67111,
        Y_COORD: 9555875.6364200003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730438962426348, -4.016159982351665],
              [114.730231173019433, -4.016167004236275],
              [114.729403153812001, -4.016159206664172],
              [114.730301027363012, -4.013639963843048],
              [114.730445919933601, -4.01364082109037],
              [114.730438962426348, -4.016159982351665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.811,
        block: "BLOK 4",
        Nm_Pemilik: "Wijayanti",
        No_Kav: "23",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248037.84374000001,
        Y_COORD: 9555876.0318899993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731026616378088, -4.016140123053442],
              [114.730438962426348, -4.016159982351665],
              [114.730445923527427, -4.01364081862689],
              [114.731033511081378, -4.013644285592668],
              [114.731026616378088, -4.016140123053442],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.856,
        block: "BLOK 4",
        Nm_Pemilik: "Sanun",
        No_Kav: "26",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248352.15635,
        Y_COORD: 9555933.9619299993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732533694767397, -4.013653135520242],
              [114.734604319815631, -4.013665344652981],
              [114.732529669963398, -4.015111951506968],
              [114.732533694767397, -4.013653135520242],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.829,
        block: "BLOK 4",
        Nm_Pemilik: "Laila Kurniati",
        No_Kav: "25",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248190.09697000001,
        Y_COORD: 9555901.2498000003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73168675838285, -4.013648139514654],
              [114.732533694280917, -4.013653135578779],
              [114.732529667424345, -4.015111953906295],
              [114.731681082312647, -4.015703649018316],
              [114.73168675838285, -4.013648139514654],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.835,
        block: "BLOK 4",
        Nm_Pemilik: "Wijayanti",
        No_Kav: "24",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248106.76735000001,
        Y_COORD: 9555877.1196899991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731033511081378, -4.013644285592668],
              [114.731686756472527, -4.013648139313963],
              [114.73168107998967, -4.015703651357049],
              [114.731057479570055, -4.016138469859039],
              [114.731026616378088, -4.016140123053442],
              [114.731033511081378, -4.013644285592668],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.952,
        block: "BLOK 5",
        Nm_Pemilik: "Endang Pujianti",
        No_Kav: "34",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248056.99725,
        Y_COORD: 9556167.9321800005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731446341820302, -4.013582924396736],
              [114.730378251990061, -4.013581803461716],
              [114.731317188788424, -4.010943816302813],
              [114.731452948090194, -4.010944241822769],
              [114.731446341820302, -4.013582924396736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        block: "BLOK 5",
        Nm_Pemilik: "Nunik Mariana",
        No_Kav: "33",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248149.56562000001,
        Y_COORD: 9556168.0325300004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732045458630878, -4.013583552098051],
              [114.731446342394023, -4.013582924541256],
              [114.731452948710924, -4.010944241802271],
              [114.731460360038909, -4.010944254297791],
              [114.732052738663683, -4.010946064726653],
              [114.732045458630878, -4.013583552098051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.964,
        block: "BLOK 5",
        Nm_Pemilik: "Faisal Fauzan",
        No_Kav: "32",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248216.55042000001,
        Y_COORD: 9556168.0811000001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73265161254217, -4.013584187524126],
              [114.732045458630878, -4.013583552098051],
              [114.732052738663683, -4.010946064726653],
              [114.732658885336136, -4.010947917944861],
              [114.73265161254217, -4.013584187524126],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.956,
        block: "BLOK 5",
        Nm_Pemilik: "Saibatul Hamdi",
        No_Kav: "31",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248283.76968,
        Y_COORD: 9556168.1300399993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733255625439753, -4.013584820256982],
              [114.73265161254217, -4.013584187524126],
              [114.732658885336136, -4.010947917944861],
              [114.733262892949639, -4.010949764175164],
              [114.733255625439753, -4.013584820256982],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.941,
        block: "BLOK 5",
        Nm_Pemilik: "Saibatul Hamdi",
        No_Kav: "30",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248350.62511,
        Y_COORD: 9556168.1787899993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733855229734715, -4.013585447928494],
              [114.733255625439753, -4.013584820256982],
              [114.733262892949639, -4.010949764175164],
              [114.733862492000881, -4.010951596487761],
              [114.733855229734715, -4.013585447928494],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.955,
        block: "BLOK 5",
        Nm_Pemilik: "Surahmad",
        No_Kav: "29",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248417.48054,
        Y_COORD: 9556168.2271999996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.734459243137181, -4.013586079769112],
              [114.733855229734715, -4.013585447928494],
              [114.733862492000881, -4.010951596487761],
              [114.734466500122878, -4.010953441828446],
              [114.734459243137181, -4.013586079769112],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.96,
        block: "BLOK 5",
        Nm_Pemilik: "Sanun",
        No_Kav: "27",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248554.18085,
        Y_COORD: 9556183.563,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.735604716596157, -4.0122866869434],
              [114.7355367266358, -4.012771199933784],
              [114.735081652089633, -4.013311254194472],
              [114.735088146119978, -4.010955340959161],
              [114.736426544166136, -4.010959426993755],
              [114.735604716596157, -4.0122866869434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.963,
        block: "BLOK 5",
        Nm_Pemilik: "Surahmad",
        No_Kav: "28",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248485.56043000001,
        Y_COORD: 9556168.2759499997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.735081651606478, -4.013311255008929],
              [114.734799920224901, -4.01358643594376],
              [114.734459243137181, -4.013586079769112],
              [114.734466500122878, -4.010953441828446],
              [114.735088143784836, -4.010955340581516],
              [114.735081651606478, -4.013311255008929],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.008,
        block: "BLOK 2",
        Nm_Pemilik: "M. Achyar",
        No_Kav: "10",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247562.5205,
        Y_COORD: 9555581.7735900003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726752856779797, -4.018885676630354],
              [114.726140125742134, -4.01887723996657],
              [114.726147506812353, -4.01621290879959],
              [114.726760311027235, -4.016216641053361],
              [114.726752856779797, -4.018885676630354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.992,
        block: "BLOK 2",
        Nm_Pemilik: "M. Achyar",
        No_Kav: "9",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247494.66235,
        Y_COORD: 9555582.2576599997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726140125742134, -4.01887723996657],
              [114.725531290538555, -4.018868885380671],
              [114.725538660755348, -4.016209180831515],
              [114.726147506812353, -4.01621290879959],
              [114.726140125742134, -4.01887723996657],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.988,
        block: "BLOK 2",
        Nm_Pemilik: "Sumarno",
        No_Kav: "8",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247427.02404,
        Y_COORD: 9555582.7377700005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725531290538555, -4.018868885380671],
              [114.724922455604755, -4.018860530345767],
              [114.724929814961328, -4.016205452410824],
              [114.725538660755348, -4.016209180831515],
              [114.725531290538555, -4.018868885380671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        block: "BLOK 6",
        Nm_Pemilik: "H Chaeran Wakiran",
        No_Kav: "35",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248028.20648,
        Y_COORD: 9556168.7637399994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730118247686335, -4.010936951877417],
              [114.731202626528116, -4.010937633970075],
              [114.730228015173097, -4.013571987211003],
              [114.730110969100451, -4.013571888044217],
              [114.730118245311445, -4.01093695464246],
              [114.730118247686335, -4.010936951877417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.954,
        block: "BLOK 6",
        Nm_Pemilik: "Wismaya PrimaSetya",
        No_Kav: "36",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247934.45519000001,
        Y_COORD: 9556168.5695600007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729514801370698, -4.010936574091712],
              [114.730118245311445, -4.01093695464246],
              [114.730110969100451, -4.013571888044217],
              [114.729507522552353, -4.013571377938423],
              [114.729514801370698, -4.010936574091712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.962,
        block: "BLOK 6",
        Nm_Pemilik: "Dwi kurniati",
        No_Kav: "37",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247867.27618,
        Y_COORD: 9556168.4325900003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728908809361897, -4.010936191484246],
              [114.729514801370698, -4.010936574091712],
              [114.729507522552353, -4.013571377938423],
              [114.728901528959142, -4.013570865229489],
              [114.728908809361897, -4.010936191484246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.931,
        block: "BLOK 6",
        Nm_Pemilik: "Lutfi Wijaya",
        No_Kav: "38",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247800.48577,
        Y_COORD: 9556168.2963500004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728312360783548, -4.010935814462077],
              [114.728908809361897, -4.010936191484246],
              [114.728901528959142, -4.013570865229489],
              [114.728305078821521, -4.013570360154512],
              [114.728312360783548, -4.010935814462077],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.962,
        block: "BLOK 6",
        Nm_Pemilik: "Darno",
        No_Kav: "39",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247733.69535,
        Y_COORD: 9556168.1602100004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727706369281208, -4.010935430960492],
              [114.728312360783548, -4.010935814462077],
              [114.728305078821521, -4.013570360154512],
              [114.727699085735154, -4.013569846551084],
              [114.727706369281208, -4.010935430960492],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.931,
        block: "BLOK 6",
        Nm_Pemilik: "Sri Pamuji Rahayu",
        No_Kav: "40",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247666.90494000001,
        Y_COORD: 9556168.0239700004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727109921201517, -4.010935053058284],
              [114.727706369281208, -4.010935430960492],
              [114.727699085735154, -4.013569846551084],
              [114.727102636096518, -4.013569340595703],
              [114.727109921201517, -4.010935053058284],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.007,
        block: "BLOK 6",
        Nm_Pemilik: "Wismaya PrimaSetya",
        No_Kav: "41",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247599.33707000001,
        Y_COORD: 9556167.8863299992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726489934502155, -4.010934660512487],
              [114.727109921201517, -4.010935053058284],
              [114.727102636096518, -4.013569340595703],
              [114.726482646840751, -4.013568814277669],
              [114.726489934502155, -4.010934660512487],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.969,
        block: "BLOK 7",
        Nm_Pemilik: "Ali Mustakim",
        No_Kav: "53",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248121.59059000001,
        Y_COORD: 9556465.6889800001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731003249196775, -4.008252705609314],
              [114.732013639283664, -4.008256556632741],
              [114.731602092076173, -4.009590425565048],
              [114.731200851556949, -4.010890869566784],
              [114.730995974784065, -4.010890438128371],
              [114.731003249196775, -4.008252705609314],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.959,
        block: "BLOK 7",
        Nm_Pemilik: "Mahdiyah",
        No_Kav: "52",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248031.90437,
        Y_COORD: 9556465.7010200005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730399018035541, -4.008250401418339],
              [114.731003249196775, -4.008252705609314],
              [114.730995971744036, -4.010890438536665],
              [114.730391736019925, -4.010889161811271],
              [114.730399018035541, -4.008250401418339],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.944,
        block: "BLOK 7",
        Nm_Pemilik: "Insanul Roipatin",
        No_Kav: "51",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247965.05315,
        Y_COORD: 9556465.7129900008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729799727086743, -4.008248115624896],
              [114.730399018035541, -4.008250401418339],
              [114.730391736019925, -4.010889161811271],
              [114.729792440336553, -4.010887895081682],
              [114.729799727086743, -4.008248115624896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        block: "BLOK 7",
        Nm_Pemilik: "Mas udi",
        No_Kav: "50",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247898.47632,
        Y_COORD: 9556465.7249500006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729200436390229, -4.008245829392649],
              [114.729799727086743, -4.008248115624896],
              [114.729792440336553, -4.010887895081682],
              [114.729193144903959, -4.010886627912131],
              [114.729200436390229, -4.008245829392649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.945,
        block: "BLOK 7",
        Nm_Pemilik: "Anita Citra Sari",
        No_Kav: "49",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247831.89949000001,
        Y_COORD: 9556465.7369100004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728601145946044, -4.008243542721567],
              [114.729200436390229, -4.008245829392649],
              [114.729193144903959, -4.010886627912131],
              [114.72859384972223, -4.010885360302618],
              [114.728601145946044, -4.008243542721567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.946,
        block: "BLOK 7",
        Nm_Pemilik: "Mahmudah",
        No_Kav: "48",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247765.32266000001,
        Y_COORD: 9556465.7488800008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728001855754243, -4.008241255611684],
              [114.728601145946044, -4.008243542721567],
              [114.72859384972223, -4.010885360302618],
              [114.727994554791422, -4.010884092253147],
              [114.728001855754243, -4.008241255611684],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.962,
        block: "BLOK 7",
        Nm_Pemilik: "Nuredy M",
        No_Kav: "47",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247698.48779000001,
        Y_COORD: 9556465.7609299999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727397920157472, -4.008238950328321],
              [114.728001855754243, -4.008241255611684],
              [114.727994554791422, -4.010884092253147],
              [114.727390614417388, -4.010882813928735],
              [114.727397920157472, -4.008238950328321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.948,
        block: "BLOK 7",
        Nm_Pemilik: "Aryadi",
        No_Kav: "46",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247631.65291,
        Y_COORD: 9556465.7729000002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726798630472672, -4.008236662337406],
              [114.727397920157472, -4.008238950328321],
              [114.727390614417388, -4.010882813928735],
              [114.72679131999061, -4.010881544995936],
              [114.726798630472672, -4.008236662337406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.963,
        block: "BLOK 7",
        Nm_Pemilik: "Siti masitah",
        No_Kav: "45",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247564.81803,
        Y_COORD: 9556465.7849499993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726194695386937, -4.008234356166184],
              [114.726798630472672, -4.008236662337406],
              [114.72679131999061, -4.010881544995936],
              [114.726187380124628, -4.010880265781348],
              [114.726194695386937, -4.008234356166184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.946,
        block: "BLOK 7",
        Nm_Pemilik: "Miswadi",
        No_Kav: "44",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247487.73762,
        Y_COORD: 9556465.74395,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725781865904153, -4.008233155526576],
              [114.726194695386937, -4.008234356166184],
              [114.726187380124628, -4.010880265781348],
              [114.725403634474048, -4.010878870562572],
              [114.725781865904153, -4.008233155526576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.04,
        block: "BLOK 8",
        Nm_Pemilik: "Abdul Gofar",
        No_Kav: "54",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248158.83656,
        Y_COORD: 9556465.3102199994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732345509109649, -4.010894760404535],
              [114.731327372368426, -4.010891571957488],
              [114.731715538829832, -4.009586761866293],
              [114.732109619955253, -4.008262062781786],
              [114.732352766073973, -4.008262815661444],
              [114.732345509109649, -4.010894760404535],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.008,
        block: "BLOK 8",
        Nm_Pemilik: "Saberun Rais",
        No_Kav: "55",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248249.87489000001,
        Y_COORD: 9556465.29397,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732966345146778, -4.010896704021554],
              [114.732345509109649, -4.010894760404535],
              [114.732352766073973, -4.008262815661444],
              [114.732973600185232, -4.008264737688807],
              [114.732966345146778, -4.010896704021554],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.013,
        block: "BLOK 8",
        Nm_Pemilik: "Santoso",
        No_Kav: "56",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248318.92059,
        Y_COORD: 9556465.2710699998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733588546782642, -4.010898651439754],
              [114.732966345146778, -4.010896704021554],
              [114.732973600185232, -4.008264737688807],
              [114.733595799890978, -4.008266663470173],
              [114.733588546782642, -4.010898651439754],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.013,
        block: "BLOK 8",
        Nm_Pemilik: "H Tamzis",
        No_Kav: "57",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248388.04212,
        Y_COORD: 9556465.2481600009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.734210748689364, -4.010900598384261],
              [114.733588546782642, -4.010898651439754],
              [114.733595799890978, -4.008266663470173],
              [114.734217999867539, -4.00826858877814],
              [114.734210748689364, -4.010900598384261],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.029,
        block: "BLOK 8",
        Nm_Pemilik: "Prawoto Heru Bono",
        No_Kav: "58",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248457.44014,
        Y_COORD: 9556465.2251500003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.734837928485334, -4.010902560424915],
              [114.734210748689364, -4.010900598384261],
              [114.734217999867539, -4.00826858877814],
              [114.734845177717887, -4.00827052900948],
              [114.734837928485334, -4.010902560424915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        block: "BLOK 8",
        Nm_Pemilik: "Masriadi",
        No_Kav: "60",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248595.52804,
        Y_COORD: 9556465.1793799996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.736077356033761, -4.010906436375595],
              [114.735457337445624, -4.010904497561349],
              [114.735464584834233, -4.008272444685316],
              [114.736084601421268, -4.008274361861637],
              [114.736077356033761, -4.010906436375595],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.003,
        block: "BLOK 8",
        Nm_Pemilik: "Drs. Suriansyah",
        No_Kav: "62",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248783.26583,
        Y_COORD: 9556488.4369200002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.736727244378855, -4.008276349016041],
              [114.738201805563307, -4.008280904850455],
              [114.736721162416373, -4.010487335141223],
              [114.736727244378855, -4.008276349016041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        block: "BLOK 8",
        Nm_Pemilik: "Drs. Suriansyah",
        No_Kav: "61",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248665.6637,
        Y_COORD: 9556465.1615299992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.736439162338243, -4.010907567464946],
              [114.736077356033761, -4.010906436375595],
              [114.736084601421268, -4.008274361861637],
              [114.736727243978535, -4.008276348881508],
              [114.736721159816994, -4.010487341030424],
              [114.736439162338243, -4.010907567464946],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.946,
        block: "BLOK 9",
        Nm_Pemilik: "Linda",
        No_Kav: "73",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 248244.39816000001,
        Y_COORD: 9556765.9437199999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733116499570201, -4.008201129412961],
              [114.732111621014823, -4.008198335971969],
              [114.732945335412808, -4.005524820605229],
              [114.73312386842656, -4.005525373398862],
              [114.733116499570201, -4.008201129412961],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.985,
        block: "BLOK 9",
        Nm_Pemilik: "Abdul Salam",
        No_Kav: "72",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248333.74881,
        Y_COORD: 9556765.94056,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.733720208797706, -4.008202806974841],
              [114.733116499570201, -4.008201129412961],
              [114.73312386842656, -4.005525373398862],
              [114.733727576548858, -4.005527242711939],
              [114.733720208797706, -4.008202806974841],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        block: "BLOK 9",
        Nm_Pemilik: "Sutawi",
        No_Kav: "71",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248400.10999,
        Y_COORD: 9556765.9298700001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.73431120416646, -4.008204448775457],
              [114.733720208797706, -4.008202806974841],
              [114.733727576548858, -4.005527242711939],
              [114.73431856948072, -4.005529072221711],
              [114.73431120416646, -4.008204448775457],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.974,
        block: "BLOK 9",
        Nm_Pemilik: "Sutriono",
        No_Kav: "70",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248466.29436,
        Y_COORD: 9556765.9193999991,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.734911731968467, -4.008206116619089],
              [114.73431120416646, -4.008204448775457],
              [114.73431856948072, -4.005529072221711],
              [114.734919094806813, -4.005530930802516],
              [114.734911731968467, -4.008206116619089],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.974,
        block: "BLOK 9",
        Nm_Pemilik: "Hj Siti Rabiatun",
        No_Kav: "69",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248533.0082,
        Y_COORD: 9556765.9087700006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.735512260022361, -4.008207784021572],
              [114.734911731968467, -4.008206116619089],
              [114.734919094806813, -4.005530930802516],
              [114.735519620385006, -4.005532788942642],
              [114.735512260022361, -4.008207784021572],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.943,
        block: "BLOK 9",
        Nm_Pemilik: "Saerani",
        No_Kav: "68",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248599.19256,
        Y_COORD: 9556765.8981400002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.736103256130733, -4.008209424526643],
              [114.735512260022361, -4.008207784021572],
              [114.735519620385006, -4.005532788942642],
              [114.736110614057282, -4.005534617158228],
              [114.736103256130733, -4.008209424526643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.958,
        block: "BLOK 9",
        Nm_Pemilik: "Isnaniah",
        No_Kav: "67",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248665.11219000001,
        Y_COORD: 9556765.8876799997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.736699018583465, -4.00821107782916],
              [114.736103256130733, -4.008209424526643],
              [114.736110614057282, -4.005534617158228],
              [114.736706374054549, -4.005536459685479],
              [114.736699018583465, -4.00821107782916],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.958,
        block: "BLOK 9",
        Nm_Pemilik: "Hj Siti Rabiatun",
        No_Kav: "66",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248731.29655,
        Y_COORD: 9556765.8771299999,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.737294781283865, -4.008212730697503],
              [114.736699018583465, -4.00821107782916],
              [114.736706374054549, -4.005536459685479],
              [114.737302134299767, -4.005538301779019],
              [114.737294781283865, -4.008212730697503],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.958,
        block: "BLOK 9",
        Nm_Pemilik: "Isnaniah",
        No_Kav: "65",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248797.48091000001,
        Y_COORD: 9556765.8665800001,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.737890544231874, -4.008214383131667],
              [114.737294781283865, -4.008212730697503],
              [114.737302134299767, -4.005538301779019],
              [114.737897894792852, -4.005540143438813],
              [114.737890544231874, -4.008214383131667],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.951,
        block: "BLOK 9",
        Nm_Pemilik: "M Yusuf",
        No_Kav: "63",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248977.40417,
        Y_COORD: 9556788.3129399996,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.739919756558081, -4.005546289503593],
              [114.738513212426056, -4.007809895466666],
              [114.738519445243824, -4.005542065919846],
              [114.739919756558081, -4.005546289503593],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.975,
        block: "BLOK 9",
        Nm_Pemilik: "Saifudin",
        No_Kav: "64",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248865.09769,
        Y_COORD: 9556765.8560400009,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.738513211886243, -4.00780989663533],
              [114.738242220343295, -4.008215358351799],
              [114.737890544231874, -4.008214383131667],
              [114.737897894792852, -4.005540143438813],
              [114.738519442966648, -4.005542064572758],
              [114.738513211886243, -4.00780989663533],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.923,
        block: "BLOK 10",
        Nm_Pemilik: "Saerani",
        No_Kav: "74",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248215.43433,
        Y_COORD: 9556766.0997400004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731860436408297, -4.005518128380859],
              [114.732861011438047, -4.005521234436518],
              [114.732020057220737, -4.00819893960637],
              [114.731853049534351, -4.008198430965702],
              [114.731860436408297, -4.005518128380859],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.896,
        block: "BLOK 10",
        Nm_Pemilik: "Suryati",
        No_Kav: "75",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248127.88304,
        Y_COORD: 9556766.1125799995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731284826532558, -4.005516340976723],
              [114.731860436408297, -4.005518128380859],
              [114.731853049534351, -4.008198430965702],
              [114.731277437691418, -4.008196677613354],
              [114.731284826532558, -4.005516340976723],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.882,
        block: "BLOK 10",
        Nm_Pemilik: "Saberun Rais",
        No_Kav: "76",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248064.18191000001,
        Y_COORD: 9556766.1313400008,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730713626271637, -4.005514566864533],
              [114.731284826532558, -4.005516340976723],
              [114.731277437691418, -4.008196677613354],
              [114.730706235478323, -4.008194937291809],
              [114.730713626271637, -4.005514566864533],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.912,
        block: "BLOK 10",
        Nm_Pemilik: "Budi  Widoyo",
        No_Kav: "77",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248000.21396,
        Y_COORD: 9556766.1502,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730133213337368, -4.005512763729232],
              [114.730713626271637, -4.005514566864533],
              [114.730706235478323, -4.008194937291809],
              [114.730125820560332, -4.008193168491856],
              [114.730133213337368, -4.005512763729232],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Slamet Hartanto",
        No_Kav: "78",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247935.99013,
        Y_COORD: 9556766.1691200007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729557407088166, -4.00551097449776],
              [114.730133213337368, -4.005512763729232],
              [114.730125820560332, -4.008193168491856],
              [114.729550012343168, -4.008191413322924],
              [114.729557407088166, -4.00551097449776],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Dani Suyanto",
        No_Kav: "79",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247872.02218,
        Y_COORD: 9556766.1879600007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728981601071311, -4.005509184861136],
              [114.729557407088166, -4.00551097449776],
              [114.729550012343168, -4.008191413322924],
              [114.728974204358224, -4.008189657748538],
              [114.728981601071311, -4.005509184861136],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Saifudin",
        No_Kav: "80",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247808.05423000001,
        Y_COORD: 9556766.2068099994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728405795286847, -4.005507394819364],
              [114.728981601071311, -4.005509184861136],
              [114.728974204358224, -4.008189657748538],
              [114.728398396605698, -4.008187901768723],
              [114.728405795286847, -4.005507394819364],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Winda Ardila",
        No_Kav: "81",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247744.08628,
        Y_COORD: 9556766.2256499995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727829989734857, -4.005505604372444],
              [114.728405795286847, -4.005507394819364],
              [114.728398396605698, -4.008187901768723],
              [114.727822589085548, -4.008186145383441],
              [114.727829989734857, -4.005505604372444],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Isnin Najla",
        No_Kav: "82",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247680.11833,
        Y_COORD: 9556766.2445,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727254184415372, -4.00550381352038],
              [114.727829989734857, -4.005505604372444],
              [114.727822589085548, -4.008186145383441],
              [114.727246781797916, -4.008184388592729],
              [114.727254184415372, -4.00550381352038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.882,
        block: "BLOK 10",
        Nm_Pemilik: "Misrina Najifah",
        No_Kav: "83",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247616.40625,
        Y_COORD: 9556766.2632599995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726682985768235, -4.005502036594835],
              [114.727254184415372, -4.00550381352038],
              [114.727246781797916, -4.008184388592729],
              [114.726675581198279, -4.008182645455746],
              [114.726682985768235, -4.005502036594835],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.897,
        block: "BLOK 10",
        Nm_Pemilik: "Ardiansyah",
        No_Kav: "84",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247552.69418,
        Y_COORD: 9556766.28204,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726107180912152, -4.005500244935718],
              [114.726682985768235, -4.005502036594835],
              [114.726675581198279, -4.008182645455746],
              [114.726099774373878, -4.008180887857366],
              [114.726107180912152, -4.005500244935718],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.944,
        block: "BLOK 10",
        Nm_Pemilik: "Rusmilawati",
        No_Kav: "85",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247485.91164000001,
        Y_COORD: 9556766.3017999995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725480705492984, -4.005498295150399],
              [114.726107180912152, -4.005500244935718],
              [114.726099774373878, -4.008180887857366],
              [114.725766888240543, -4.008179871567203],
              [114.725807863369425, -4.00791219243904],
              [114.725474260978658, -4.007830213804064],
              [114.725480705492984, -4.005498295150399],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.885,
        block: "BLOK 10",
        Nm_Pemilik: "Ardiansyah",
        No_Kav: "86",
        KETERANGAN: "Bebas SK-435",
        KODE: "Merah",
        X_COORD: 247413.24032000001,
        Y_COORD: 9556785.6105899997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724798890824943, -4.005496231408141],
              [114.725480705492984, -4.005498295150399],
              [114.725474260978658, -4.007830213804064],
              [114.724792896742542, -4.007662813676031],
              [114.724798890824943, -4.005496231408141],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.955,
        block: "BLOK 10",
        Nm_Pemilik: "Rusmilawati",
        No_Kav: "87",
        KETERANGAN: "Bebas SK-435",
        KODE: "Merah",
        X_COORD: 247317.79669,
        Y_COORD: 9556794.7646500003,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724136702282152, -4.006056234585196],
              [114.724450009217577, -4.00549527902076],
              [114.724798885652177, -4.005496230756123],
              [114.724792896392373, -4.007662813653856],
              [114.723915046883235, -4.007449466815385],
              [114.723757852973861, -4.007153103185805],
              [114.724136702282152, -4.006056234585196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.975,
        block: "BLOK 11",
        Nm_Pemilik: "Suyatmin",
        No_Kav: "88",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248285.40708,
        Y_COORD: 9557065.5278500002,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.732591826107026, -4.002865480421489],
              [114.733403943558528, -4.002873596589547],
              [114.733329039338187, -4.003943611051991],
              [114.732877093518809, -4.005442455088475],
              [114.732584732772395, -4.005441315731701],
              [114.732591826107026, -4.002865480421489],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.951,
        block: "BLOK 11",
        Nm_Pemilik: "Bayu Asdono",
        No_Kav: "89",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248206.08197,
        Y_COORD: 9557065.8147999998,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731975889408105, -4.002858907733346],
              [114.732591821943529, -4.002865478719957],
              [114.732584730067956, -4.005441317801133],
              [114.731968784115153, -4.005438921442737],
              [114.731975889408105, -4.002858907733346],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.975,
        block: "BLOK 11",
        Nm_Pemilik: "Titik Sumarni",
        No_Kav: "90",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248137.2939,
        Y_COORD: 9557066.1244099997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.731353460916537, -4.002852266976016],
              [114.731975889408105, -4.002858907733346],
              [114.731968784115153, -4.005438921442737],
              [114.731346342058416, -4.005436499339451],
              [114.731353460916537, -4.002852266976016],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.993,
        block: "BLOK 11",
        Nm_Pemilik: "Eko Nugroho",
        No_Kav: "91",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248067.89444,
        Y_COORD: 9557066.4363899995,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730726522354971, -4.002845577623102],
              [114.731353460916537, -4.002852266976016],
              [114.731346342058416, -4.005436499339451],
              [114.730719389826987, -4.005434059206521],
              [114.730726522354971, -4.002845577623102],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.965,
        block: "BLOK 11",
        Nm_Pemilik: "Istiana",
        No_Kav: "92",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247998.77683,
        Y_COORD: 9557066.7444599997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.730109168561881, -4.002838990070456],
              [114.730726522354971, -4.002845577623102],
              [114.730719389826987, -4.005434059206521],
              [114.730102022566683, -4.005431655908844],
              [114.730109168561881, -4.002838990070456],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.96,
        block: "BLOK 11",
        Nm_Pemilik: "Arif Zainal M",
        No_Kav: "93",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247930.34821,
        Y_COORD: 9557067.0507200006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.729494634007949, -4.0028324321397],
              [114.730109168561881, -4.002838990070456],
              [114.730102022566683, -4.005431655908844],
              [114.729487474600887, -4.005429263122992],
              [114.729494634007949, -4.0028324321397],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.963,
        block: "BLOK 11",
        Nm_Pemilik: "Sugino",
        No_Kav: "94",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247862.07617,
        Y_COORD: 9557067.3567900006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728880099725643, -4.002825873751728],
              [114.729494634007949, -4.0028324321397],
              [114.729487474600887, -4.005429263122992],
              [114.728872926900479, -4.005426869876056],
              [114.728880099725643, -4.002825873751728],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.979,
        block: "BLOK 11",
        Nm_Pemilik: "Hestia Nur Sina",
        No_Kav: "95",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247793.58587000001,
        Y_COORD: 9557067.6645899992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.728261636251773, -4.002819272966387],
              [114.728880099725643, -4.002825873751728],
              [114.728872926900479, -4.005426869876056],
              [114.728254449916491, -4.005424460860671],
              [114.728261636251773, -4.002819272966387],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.964,
        block: "BLOK 11",
        Nm_Pemilik: "Hestia Nur Sina",
        No_Kav: "96",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247725.18097,
        Y_COORD: 9557067.9702400006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727648640129885, -4.002812730073579],
              [114.728261636251773, -4.002819272966387],
              [114.728254449916491, -4.005424460860671],
              [114.727641440397747, -4.005422072679618],
              [114.727648640129885, -4.002812730073579],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.982,
        block: "BLOK 11",
        Nm_Pemilik: "Suyanti",
        No_Kav: "97",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247656.83301,
        Y_COORD: 9557068.2774899993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727031202280514, -4.002806139308621],
              [114.727648640129885, -4.002812730073579],
              [114.727641440397747, -4.005422072679618],
              [114.727023989048206, -4.005419666729146],
              [114.727031202280514, -4.002806139308621],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.97,
        block: "BLOK 11",
        Nm_Pemilik: "Istiana",
        No_Kav: "98",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247588.48506000001,
        Y_COORD: 9557068.5830700006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.726418206701496, -4.002799595502674],
              [114.727031202280514, -4.002806139308621],
              [114.727023989048206, -4.005419666729146],
              [114.726410980059981, -4.005417277627224],
              [114.726418206701496, -4.002799595502674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.97,
        block: "BLOK 11",
        Nm_Pemilik: "Sirajudin Noor",
        No_Kav: "100",
        KETERANGAN: "Bebas SK-435",
        KODE: "Merah",
        X_COORD: 247410.0907,
        Y_COORD: 9557053.9114500005,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724429831984779, -4.005408729873153],
              [114.725800019898543, -4.00306449973864],
              [114.725793529244953, -4.005414870749211],
              [114.724429831984779, -4.005408729873153],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.961,
        block: "BLOK 11",
        Nm_Pemilik: "Sirajudin Noor",
        No_Kav: "99",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247520.1371,
        Y_COORD: 9557068.8191800006,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725800019898543, -4.00306449973864],
              [114.725963741432409, -4.002794743732474],
              [114.726418206701496, -4.002799595502674],
              [114.726410980059981, -4.005417277627224],
              [114.725793529244953, -4.005414870749211],
              [114.725800019898543, -4.00306449973864],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        block: "BLOK 12",
        Nm_Pemilik: "Pamuji",
        No_Kav: "102",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 247778.87271,
        Y_COORD: 9557307.4636000004,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727780599550201, -4.001577105724722],
              [114.728169832284451, -4.001618365921159],
              [114.728459762964562, -4.001716317508406],
              [114.728459840276827, -4.00151227979508],
              [114.728842410056131, -4.00120114203576],
              [114.729104051450207, -4.001241063924592],
              [114.729099998202372, -4.002711783293245],
              [114.728755941269512, -4.002707304313385],
              [114.72777751405674, -4.002694874141399],
              [114.727780599550201, -4.001577105724722],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.055,
        block: "BLOK 1",
        Nm_Pemilik: "Suhadak",
        No_Kav: "6",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247362.37352,
        Y_COORD: 9555363.6897899993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724105694337823, -4.018912656564295],
              [114.725390708170352, -4.018915233023774],
              [114.725387367265583, -4.02012027317309],
              [114.723895500307762, -4.020116099890752],
              [114.724105694337823, -4.018912656564295],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        block: "BLOK 2",
        Nm_Pemilik: "Sumarno",
        No_Kav: "7",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247347.31705000001,
        Y_COORD: 9555583.1795799993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.724922455604755, -4.018860530345767],
              [114.724096352621586, -4.018849193020021],
              [114.724528890494739, -4.016202997000741],
              [114.724929814961328, -4.016205452410824],
              [114.724922455604755, -4.018860530345767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        block: "BLOK 8",
        Nm_Pemilik: "Edi Gutomo",
        No_Kav: "59",
        KETERANGAN: "Masuk SK 435",
        KODE: "Merah",
        X_COORD: 248526.68298000001,
        Y_COORD: 9556465.2021999992,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.735457337337508, -4.01090449768208],
              [114.734837928485334, -4.010902560424915],
              [114.734845177717887, -4.00827052900948],
              [114.735464584648483, -4.008272444727838],
              [114.735457337337508, -4.01090449768208],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.036,
        block: "BLOK 6",
        Nm_Pemilik: "Solehatin",
        No_Kav: "43",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247448.3358,
        Y_COORD: 9556167.5715999994,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725436744303053, -4.010933990623176],
              [114.725858675307322, -4.010934258865257],
              [114.725851387949021, -4.013568283988488],
              [114.725015388059674, -4.013567713232873],
              [114.725436744303053, -4.010933990623176],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.043,
        block: "BLOK 6",
        Nm_Pemilik: "Faqih Usman",
        No_Kav: "42",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247529.83567,
        Y_COORD: 9556167.7443400007,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.725858677834495, -4.010934260320078],
              [114.726489933488423, -4.010934659810314],
              [114.726482646762918, -4.013568814343876],
              [114.725851387949021, -4.013568283988488],
              [114.725858677834495, -4.010934260320078],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.498,
        block: "BLOK 2",
        Nm_Pemilik: "Sarmin",
        No_Kav: "12",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247710.27994000001,
        Y_COORD: 9555581.1645299997,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727385006647722, -4.018894401654961],
              [114.727357089651107, -4.018893963252524],
              [114.727364493406441, -4.016220359257687],
              [114.727456214648029, -4.016220920504963],
              [114.728198262058299, -4.018096505753775],
              [114.727385006647722, -4.018894401654961],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.984,
        block: "BLOK 2",
        Nm_Pemilik: "Sarmin",
        No_Kav: "11",
        KETERANGAN: "Masuk SK 435",
        KODE: "Hijau",
        X_COORD: 247630.1162,
        Y_COORD: 9555581.2968099993,
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.727357089651107, -4.018893963252524],
              [114.726752858024767, -4.01888567616387],
              [114.726760311266233, -4.016216641307607],
              [114.727364493406441, -4.016220359257687],
              [114.727357089651107, -4.018893963252524],
            ],
          ],
        ],
      },
    },
  ],
};
