import React from "react";

const TableRow = ({ data }) => {
	return data.map((item, index) => {
		return (
			<tr className="table-header-regular cursor-pointer" key={index}>
				<td className="table-row-regular text-16">
					{item?.planted_year}
				</td>
				<td className="table-row-regular text-16">
					{item?.avg_bunch_weight_kg}
				</td>
			</tr>
		);
	});
};

export default TableRow;
