import React, { useRef } from "react";

import { DataProvider } from "./hooks/DataContext";
import Filter from "./partials/Filter";
import Cards from "./partials/Cards";
import Heading from "./partials/Heading";

const DashboardView = () => {
  const componentRef = useRef();

  return (
    <DataProvider>
      <div
        ref={componentRef}
        className="relative flex min-h-screen w-full flex-col gap-13 bg-gray-30 pt-42 pb-64 md:grid md:max-h-screen md:grid-rows-[minmax(0px,50px)_minmax(0px,50px)_minmax(0px,1fr)] md:py-16 md:pb-16"
      >
        <Heading />
        <Filter ref={componentRef} />
        <Cards />
      </div>
    </DataProvider>
  );
};

export default DashboardView;
