export const pantailinuh2010 = {
  type: "FeatureCollection",
  name: "pantailinuhtt10_84ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.936,
        Nm_Pemilik: "Suwarno",
        No_Kav: "47",
        KET: "Sporadik",
        NAMA_SKT_S: "Suwarno",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875949906035643, -3.837534877891729],
              [114.875361738955149, -3.838257869062073],
              [114.875299565045125, -3.838502144870815],
              [114.875276022597177, -3.838537425544819],
              [114.875179321440925, -3.838516569632973],
              [114.875150213805753, -3.838431093449347],
              [114.875179714684322, -3.838357543289468],
              [114.875180056938376, -3.838219131471468],
              [114.875171348675835, -3.838183770341138],
              [114.875121594074429, -3.838148306811181],
              [114.875092537411291, -3.838042216110035],
              [114.875101471407206, -3.837986284341581],
              [114.875089853109799, -3.837942081102852],
              [114.875098845353122, -3.837862589876444],
              [114.875069672180814, -3.83780361809476],
              [114.875058104852315, -3.837738800332993],
              [114.875072960844946, -3.837659323734228],
              [114.875041219846182, -3.837453098038452],
              [114.874930303569855, -3.837252564757668],
              [114.87496585013605, -3.837105405918425],
              [114.875083387317801, -3.836999680867274],
              [114.875294620801881, -3.836944253606819],
              [114.875405857462283, -3.837015209822868],
              [114.875613103728796, -3.837386790477622],
              [114.875724267689776, -3.837487196031952],
              [114.875949906035643, -3.837534877891729],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.726,
        Nm_Pemilik: "Suwarno",
        No_Kav: "47",
        KET: "Sporadik",
        NAMA_SKT_S: "Suwarno",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875312009622618, -3.83539488470874],
              [114.875524343021311, -3.835487443706397],
              [114.875619374262499, -3.83559075387446],
              [114.875747425484732, -3.83567942158692],
              [114.875882506335302, -3.835889586082645],
              [114.876056972944681, -3.836475330168495],
              [114.876073796489919, -3.837082768505031],
              [114.876018223392549, -3.837325588512172],
              [114.875872329851219, -3.837041773145761],
              [114.875646164504332, -3.836614191289362],
              [114.875599403843793, -3.836256999352488],
              [114.875526725471829, -3.836006497307179],
              [114.875446726516159, -3.835752295840028],
              [114.875297159261905, -3.83547215265023],
              [114.875312009622618, -3.83539488470874],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.538,
        Nm_Pemilik: "Siam",
        No_Kav: "45",
        KET: "Sporadik",
        NAMA_SKT_S: "Siam",
        block: "Blok 4",
        NO_TANDA_T: "15",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876865565412501, -3.836726695941174],
              [114.876860713831249, -3.836516271748158],
              [114.876941535413522, -3.836413964658164],
              [114.876979453759276, -3.836284574748835],
              [114.876936990183239, -3.836079452021404],
              [114.876722567466118, -3.835906271988788],
              [114.876572666138998, -3.835706276800615],
              [114.876529896200935, -3.835625242522512],
              [114.876696457393706, -3.835604076763221],
              [114.876621739865215, -3.835409664086632],
              [114.876648982286667, -3.835253271688351],
              [114.876745597657958, -3.835280488270819],
              [114.876890407519355, -3.835367171898661],
              [114.876965178403054, -3.835540003985867],
              [114.877306379384052, -3.836576729102058],
              [114.876865565412501, -3.836726695941174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.657,
        Nm_Pemilik: "M. Soleh",
        No_Kav: "48",
        KET: "SHM",
        NAMA_SKT_S: "M. Soleh - Ngatiwi",
        block: "Blok 4",
        NO_TANDA_T: "1",
        KTP: "5",
        KK: "0",
        SKT: "5",
        SJB: null,
        KET_2: "shm",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874725949556577, -3.835169983100181],
              [114.874388330059546, -3.834877459191426],
              [114.873883534273375, -3.834316920237117],
              [114.874158511292194, -3.834084795498175],
              [114.874485996477119, -3.834165891414683],
              [114.874579087713329, -3.83422767107812],
              [114.874829082913152, -3.834402233137866],
              [114.874725949556577, -3.835169983100181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.646,
        Nm_Pemilik: "Marliansyah",
        No_Kav: "29",
        KET: "SHM",
        NAMA_SKT_S: "Pujiman - Adi Prawiro",
        block: "Blok 3",
        NO_TANDA_T: "10",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "shm",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880161863007487, -3.844419112995367],
              [114.87986324459402, -3.844694785275762],
              [114.879641453983098, -3.84484326818438],
              [114.879131779553475, -3.844261973561029],
              [114.87973677088506, -3.843852759111062],
              [114.880161863007487, -3.844419112995367],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.142,
        Nm_Pemilik: "Marliansyah",
        No_Kav: "29",
        KET: "SHM",
        NAMA_SKT_S: "Pujiman - Adi Prawiro",
        block: "Blok 3",
        NO_TANDA_T: "10",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "shm",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879687687813842, -3.844892364075804],
              [114.879902851496922, -3.84474905794804],
              [114.880206519488752, -3.844471025699841],
              [114.880297264602945, -3.844601927969855],
              [114.87982996732643, -3.845055881308532],
              [114.879829435649413, -3.845056397802646],
              [114.879687687813842, -3.844892364075804],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.179,
        Nm_Pemilik: "Supiati",
        No_Kav: "43",
        KET: "Sporadik",
        NAMA_SKT_S: "Supiati",
        block: "Blok 4",
        NO_TANDA_T: "12",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876860724185818, -3.83705646506675],
              [114.87718374273301, -3.837525348800775],
              [114.877216304517944, -3.838013860029392],
              [114.877112871367743, -3.838875138580277],
              [114.876880749017431, -3.83886596155887],
              [114.876167042656164, -3.837806073274022],
              [114.876352693070203, -3.837665736168272],
              [114.876622754845812, -3.837700328133876],
              [114.876852931644663, -3.837477038128381],
              [114.876860724185818, -3.83705646506675],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.314,
        Nm_Pemilik: "Jahriah",
        No_Kav: "14",
        KET: "Sporadik",
        NAMA_SKT_S: "Jahriah",
        block: "Blok 2",
        NO_TANDA_T: "16",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872417681179954, -3.844808673604148],
              [114.872806712511093, -3.845007689986805],
              [114.87197547933323, -3.846657577576177],
              [114.871832158603183, -3.846275623862178],
              [114.871659408969137, -3.846125451570867],
              [114.871510907814596, -3.845893224670389],
              [114.871405303366885, -3.845815675219568],
              [114.872417681179954, -3.844808673604148],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.623,
        Nm_Pemilik: "Nor Aina",
        No_Kav: "7",
        KET: "Sporadik",
        NAMA_SKT_S: "Nor Aina",
        block: "Blok 1",
        NO_TANDA_T: "20",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868043513668596, -3.847711788984905],
              [114.868392621383194, -3.848068075734294],
              [114.868517696220309, -3.848277330156598],
              [114.86908330275503, -3.848105288935124],
              [114.869436358822185, -3.848246896539364],
              [114.869738473794015, -3.848267220510681],
              [114.869863770735648, -3.848273528459677],
              [114.869902602424744, -3.848255644553875],
              [114.870008472362628, -3.848275389726263],
              [114.870109814755779, -3.848316101693928],
              [114.870258976943859, -3.848323967865529],
              [114.870294735657993, -3.84834203879411],
              [114.870391766490926, -3.848316808566885],
              [114.870421476065417, -3.848367830009281],
              [114.870576642759715, -3.848360726781637],
              [114.870642846355111, -3.848446229721631],
              [114.870765073520531, -3.848508584133385],
              [114.870782329962026, -3.848555158775711],
              [114.870508950153138, -3.848386092792672],
              [114.869682607940561, -3.848278061367555],
              [114.86957078011234, -3.848293595783277],
              [114.869439780913069, -3.848306213892633],
              [114.869344082238996, -3.848317061920778],
              [114.869244676344309, -3.848338988685043],
              [114.869134360127148, -3.848309143851809],
              [114.869060923200507, -3.848246127382069],
              [114.868925183296184, -3.848236979461392],
              [114.868826884087966, -3.848287863197153],
              [114.868761354305846, -3.848320568296439],
              [114.868648647310536, -3.848316633360245],
              [114.868481445709193, -3.848294300846979],
              [114.868376119741953, -3.848246558410276],
              [114.868274384375226, -3.848217085803619],
              [114.868154514438544, -3.848169306855619],
              [114.86808179442923, -3.848169124365637],
              [114.868042440194088, -3.84817933080886],
              [114.86799811688725, -3.848190825260824],
              [114.867797968063797, -3.848256063789113],
              [114.867634330255143, -3.848262957560839],
              [114.867623374051391, -3.848264501590032],
              [114.867625340211291, -3.847554072806312],
              [114.867898966079068, -3.847715974588722],
              [114.868036364331829, -3.847704492534811],
              [114.868043513668596, -3.847711788984905],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Jailani",
        No_Kav: "10",
        KET: "Sporadik",
        NAMA_SKT_S: "Jailani",
        block: "Blok 1",
        NO_TANDA_T: "23",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869803355412856, -3.850463743872709],
              [114.869710123057729, -3.850249461753231],
              [114.869411887655431, -3.850205903806086],
              [114.868977757403457, -3.849965080454847],
              [114.868603104077749, -3.849801463601972],
              [114.868297026733899, -3.849483904232784],
              [114.868046574076118, -3.849256355998918],
              [114.868041880790742, -3.849252091923141],
              [114.868727473329272, -3.8492535413923],
              [114.869313780248746, -3.849254780514427],
              [114.869755895329476, -3.849255714959742],
              [114.870146249104451, -3.849256540398151],
              [114.870143045756762, -3.850697778075259],
              [114.869826766092558, -3.850696735350201],
              [114.869811098086728, -3.850540801538101],
              [114.869803355412856, -3.850463743872709],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.954,
        Nm_Pemilik: "Ir. H. Sugian Noorbah",
        No_Kav: "19",
        KET: "Sporadik",
        NAMA_SKT_S: "Ir. H. Sugian Noorbah",
        block: "Blok 2",
        NO_TANDA_T: "6",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875450036720423, -3.84132944104085],
              [114.875287097353905, -3.841937109960134],
              [114.874934475076785, -3.842720092381034],
              [114.875022082004051, -3.843136384625998],
              [114.874929871950854, -3.843311083419401],
              [114.874868165094767, -3.843427989327747],
              [114.87384927021462, -3.842865145185797],
              [114.873886211151969, -3.842736022105634],
              [114.873893351127691, -3.842724977915433],
              [114.874082246401557, -3.84243279188996],
              [114.874386318053396, -3.842125078663933],
              [114.874557483088225, -3.841957116137117],
              [114.874838000621409, -3.841508776404912],
              [114.875422520496016, -3.841157863800374],
              [114.875450036720423, -3.84132944104085],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.909,
        Nm_Pemilik: "khasan Ma'ruf",
        No_Kav: "33",
        KET: "Sporadik",
        NAMA_SKT_S: "khasan Ma'ruf",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877062532689138, -3.842996796066476],
              [114.877091372058942, -3.84295805693904],
              [114.877186385619325, -3.842766060023151],
              [114.877255960785661, -3.842604108545808],
              [114.877349390040479, -3.842120282439451],
              [114.877361199677665, -3.842006824331231],
              [114.877560085338601, -3.842064657220174],
              [114.877717327744008, -3.842125496644123],
              [114.877902749614933, -3.842170667158898],
              [114.87805691108305, -3.842176582410826],
              [114.878299247848844, -3.842152298259112],
              [114.878373438779008, -3.842210556391033],
              [114.878409003903457, -3.842301902854617],
              [114.878507692794614, -3.842473602959733],
              [114.878540297915308, -3.842541380074676],
              [114.878651030435151, -3.842690449406079],
              [114.878706385215224, -3.842769633833775],
              [114.878747840973176, -3.842853433394175],
              [114.878766081883029, -3.842965073799714],
              [114.878739822442185, -3.843237428805444],
              [114.878691046844423, -3.843463720052766],
              [114.877062532689138, -3.842996796066476],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.909,
        Nm_Pemilik: "Sri Wulandari",
        No_Kav: "34",
        KET: "Sporadik",
        NAMA_SKT_S: "Sri Wulandari",
        block: "Blok 3",
        NO_TANDA_T: "4 dan 5",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87843465117875, -3.844080196245248],
              [114.878313947424871, -3.844219388725057],
              [114.878280532696209, -3.844244245371077],
              [114.876607544963647, -3.843758508617198],
              [114.876671218157782, -3.843564280122722],
              [114.876862266349306, -3.843293315611014],
              [114.87695024801431, -3.843147622664545],
              [114.877062532689138, -3.842996796066476],
              [114.878691046844423, -3.843463720052766],
              [114.878628418790626, -3.843754278463497],
              [114.878541340875927, -3.843992116432319],
              [114.87843465117875, -3.844080196245248],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.909,
        Nm_Pemilik: "Sudrajat",
        No_Kav: "35",
        KET: "Sporadik",
        NAMA_SKT_S: "Sudrajat",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878280532625126, -3.844244245442113],
              [114.878199516649772, -3.844304511987855],
              [114.877996861564569, -3.844462023041083],
              [114.87792264414459, -3.844613404886986],
              [114.877886953226522, -3.844764882866083],
              [114.877590641609032, -3.845144673254866],
              [114.877523092475755, -3.845196101905698],
              [114.877331426673351, -3.845243936847193],
              [114.877014265224147, -3.845239062694801],
              [114.876635911812954, -3.844626806673934],
              [114.876550734695812, -3.84438453117731],
              [114.876551597790836, -3.844035798800905],
              [114.876605367435758, -3.84376515093771],
              [114.876607544963647, -3.843758508617198],
              [114.878280532625126, -3.844244245442113],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.646,
        Nm_Pemilik: "Bayu Asdono",
        No_Kav: "30",
        KET: "Sporadik",
        NAMA_SKT_S: "Bayu Asdono",
        block: "Blok 3",
        NO_TANDA_T: "9",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "skt asli128",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879792065936812, -3.84324804159667],
              [114.880247966864673, -3.843865047749579],
              [114.880410180771449, -3.843731543161874],
              [114.880367097663765, -3.844122938248313],
              [114.880235957289784, -3.844350712131513],
              [114.880161863007487, -3.844419112995367],
              [114.87973677088506, -3.843852759111062],
              [114.879131779553475, -3.844261973561029],
              [114.879089604607984, -3.844290500537104],
              [114.878664580784005, -3.84430018704641],
              [114.878330746039239, -3.844306267675328],
              [114.878627400361296, -3.844027012273952],
              [114.878707134927936, -3.843792153069979],
              [114.878731600859226, -3.843640119630609],
              [114.878746274009984, -3.843584331079284],
              [114.879636665862748, -3.843037733167034],
              [114.879792065936812, -3.84324804159667],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.632,
        Nm_Pemilik: "Titik Sumarni",
        No_Kav: "26",
        KET: "Sporadik",
        NAMA_SKT_S: "Titik Sumarni",
        block: "Blok 3",
        NO_TANDA_T: "8",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "skt asli",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879571215223876, -3.844887055900474],
              [114.878910429260756, -3.84545084332837],
              [114.878685978208495, -3.845872141133988],
              [114.878611782004597, -3.846046179769661],
              [114.878464576861973, -3.845870232895886],
              [114.878084658322521, -3.84586695827863],
              [114.87798688157379, -3.84594628049531],
              [114.878562292017506, -3.844361979743615],
              [114.878670306903075, -3.844357728936654],
              [114.879098089292654, -3.844347261544229],
              [114.879179517517755, -3.844441907375993],
              [114.8793568719625, -3.844650787776705],
              [114.879571215223876, -3.844887055900474],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.782,
        Nm_Pemilik: "Ardiansyah",
        No_Kav: "18",
        KET: "Sporadik",
        NAMA_SKT_S: "Ardiansyah",
        block: "Blok 2",
        NO_TANDA_T: "19",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871475363028111, -3.842416113832181],
              [114.87166682628839, -3.842011902954948],
              [114.871992071902511, -3.842079574252975],
              [114.872214863924256, -3.841708699058045],
              [114.872430112727784, -3.841397234024337],
              [114.873007051825923, -3.841368961587665],
              [114.873558951905764, -3.842492067506328],
              [114.873691467674959, -3.842737544298418],
              [114.873578416753091, -3.843270284103623],
              [114.871475363028111, -3.842416113832181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Badarudin",
        No_Kav: "17",
        KET: "Sporadik",
        NAMA_SKT_S: "Badarudin",
        block: "Blok 2",
        NO_TANDA_T: "18",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "1",
        KET_2: "sjb asli",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871168660632037, -3.843080381209153],
              [114.871473524709359, -3.842419994826273],
              [114.871475363028111, -3.842416113832181],
              [114.873578416739662, -3.843270284136326],
              [114.873541804548466, -3.843442892297943],
              [114.873304901780571, -3.843538872477894],
              [114.873089541722507, -3.843894909227028],
              [114.871168660632037, -3.843080381209153],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.9,
        Nm_Pemilik: "Sujono",
        No_Kav: "50",
        KET: "Sporadik",
        NAMA_SKT_S: "Sujono",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871803043337124, -3.83468927792437],
              [114.871945889168671, -3.834417388645514],
              [114.872848342105243, -3.835219318478119],
              [114.872969304128603, -3.835110930440137],
              [114.873049134821883, -3.835142064601349],
              [114.87331537191659, -3.835395999425739],
              [114.873375972620664, -3.835704558108657],
              [114.873160536239382, -3.835921075475991],
              [114.871758134500354, -3.834722598396628],
              [114.871803043337124, -3.83468927792437],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.5,
        Nm_Pemilik: "Paimin",
        No_Kav: "51",
        KET: "Sporadik",
        NAMA_SKT_S: "Paimin",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871374523314643, -3.835030447768673],
              [114.871598220885545, -3.834850405026375],
              [114.871758134485717, -3.834722598412033],
              [114.873160535998764, -3.835921075713181],
              [114.872932718723675, -3.83614038445572],
              [114.872710975492012, -3.836227943833734],
              [114.872449777046882, -3.83633996306395],
              [114.872293628105496, -3.836373864453385],
              [114.872098708927552, -3.836380697455576],
              [114.872045107181435, -3.83622920430217],
              [114.872031627912861, -3.835824550049894],
              [114.872007843013776, -3.835582002465179],
              [114.871956720190255, -3.835426284287749],
              [114.871744124133869, -3.835336577661019],
              [114.871592354078828, -3.835406896092521],
              [114.871374523314643, -3.835030447768673],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.988,
        Nm_Pemilik: "M. Soleh",
        No_Kav: "48",
        KET: "SHM",
        NAMA_SKT_S: "M. Soleh - Ngatiwi",
        block: "Blok 4",
        NO_TANDA_T: "1",
        KTP: "5",
        KK: "0",
        SKT: "5",
        SJB: null,
        KET_2: "shm",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873254345744172, -3.833969726165996],
              [114.873259710175745, -3.833973420607623],
              [114.873358982426325, -3.834023587819086],
              [114.873540129286425, -3.834202598226196],
              [114.873815719228148, -3.834355069048901],
              [114.874407887182599, -3.834997328958317],
              [114.874659481614145, -3.835352993785305],
              [114.874595448515777, -3.835454273396585],
              [114.874345660549636, -3.835442972661417],
              [114.874213169914796, -3.835285144438262],
              [114.873862703517105, -3.835148128182225],
              [114.873373350995593, -3.834553106235324],
              [114.873240473624534, -3.834494064678776],
              [114.873158153783379, -3.834366316727924],
              [114.873037992552369, -3.834166303879961],
              [114.873254345744172, -3.833969726165996],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.805,
        Nm_Pemilik: "Lasimin",
        No_Kav: "49",
        KET: "Sporadik",
        NAMA_SKT_S: "Susiati",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "skt di bank",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871945889168671, -3.834417388645514],
              [114.871979598250263, -3.834347588196565],
              [114.872342107561096, -3.833687321451841],
              [114.872521055102013, -3.833535741559182],
              [114.872761665582317, -3.833630421033107],
              [114.873254345744172, -3.833969726165996],
              [114.873037992552369, -3.834166303879961],
              [114.873015639512218, -3.834129096405639],
              [114.872596902953788, -3.833931676675903],
              [114.872295984012496, -3.834177912933048],
              [114.872384265025204, -3.834340092000164],
              [114.872329571346327, -3.834451302156221],
              [114.872455718733235, -3.834607399883533],
              [114.872673518375464, -3.834709030735882],
              [114.872848342105243, -3.835219318478119],
              [114.871945889168671, -3.834417388645514],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.365,
        Nm_Pemilik: "Komarudin",
        No_Kav: "44",
        KET: "Sporadik",
        NAMA_SKT_S: "Komarudin",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876849139646723, -3.83605219180165],
              [114.876911809686248, -3.836101978872372],
              [114.87694439335381, -3.836264032515704],
              [114.87690745398092, -3.836381738638682],
              [114.876830255800783, -3.836477257289784],
              [114.876793134556536, -3.836668586781133],
              [114.876770909070061, -3.8367642423899],
              [114.876792643589553, -3.836867369927243],
              [114.876777747786733, -3.836963043798713],
              [114.87679230717147, -3.837003573193445],
              [114.876781121672963, -3.83708085035004],
              [114.876787532963121, -3.837452666717622],
              [114.876589694619639, -3.837628677055571],
              [114.876348811037616, -3.837600828712403],
              [114.876131371873271, -3.837771787816005],
              [114.876018675206268, -3.837529481777637],
              [114.876121375862894, -3.837108655449821],
              [114.876104652329801, -3.836460724250333],
              [114.876023750272907, -3.836180976228706],
              [114.876849139646723, -3.83605219180165],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.6,
        Nm_Pemilik: "Sujono",
        No_Kav: "46",
        KET: "Sporadik",
        NAMA_SKT_S: "Sujono",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875575723552572, -3.835458122260611],
              [114.875693016664144, -3.835451052225623],
              [114.87584350249314, -3.835359397528761],
              [114.875891054507605, -3.835396327932337],
              [114.876055926935265, -3.835415144743807],
              [114.87630118047089, -3.835533553966413],
              [114.876531629278375, -3.835710825333569],
              [114.876688690374905, -3.835924725769304],
              [114.876849139646723, -3.83605219180165],
              [114.876023749913912, -3.836180975502998],
              [114.875937497180686, -3.835882360760468],
              [114.87582084964744, -3.835628067931333],
              [114.87567080027128, -3.83554302659071],
              [114.875575723552572, -3.835458122260611],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.65,
        Nm_Pemilik: "Hilmiansyah",
        No_Kav: "27",
        KET: "Sporadik",
        NAMA_SKT_S: "Hilmiansyah",
        block: "Blok 3",
        NO_TANDA_T: "17",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "5",
        KET_2: "asli dan cp",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880130123465932, -3.846492845191652],
              [114.880106342449395, -3.846584402640834],
              [114.879792158522889, -3.846904342851015],
              [114.879318469275461, -3.847164491447932],
              [114.879107274841871, -3.846486880760156],
              [114.878699554932822, -3.846373015978166],
              [114.878533275838493, -3.846442139395748],
              [114.878790156145769, -3.845875380482122],
              [114.878972759217746, -3.845499470210094],
              [114.879141048388675, -3.845353968731514],
              [114.879402319194284, -3.845654810820543],
              [114.880130123465932, -3.846492845191652],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.8,
        Nm_Pemilik: "Hartanto",
        No_Kav: "16",
        KET: "Sporadik",
        NAMA_SKT_S: "Hartanto",
        block: "Blok 2",
        NO_TANDA_T: "7",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874868163921633, -3.843427991085802],
              [114.874652760807464, -3.843836090279574],
              [114.874400129083213, -3.844635423226451],
              [114.874395026588758, -3.84464329108004],
              [114.873269466340005, -3.84395578284951],
              [114.873278469425742, -3.843943053370996],
              [114.87331665821371, -3.843784961596181],
              [114.87341141659158, -3.843677631185265],
              [114.87363838504676, -3.843602268748469],
              [114.873849270153244, -3.842865145228787],
              [114.874868163921633, -3.843427991085802],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.8,
        Nm_Pemilik: "Andreas Sujatmiko",
        No_Kav: "32",
        KET: "Sporadik",
        NAMA_SKT_S: "Andreas Sujatmiko",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878388827818355, -3.842135526698446],
              [114.87832847056282, -3.842089822488136],
              [114.878277549648786, -3.842089695513434],
              [114.878147921291415, -3.842094022049327],
              [114.878013640783124, -3.842107636535071],
              [114.877860889568723, -3.842102605786498],
              [114.877703624151465, -3.842051065898952],
              [114.877569516119564, -3.841994933971248],
              [114.8773659716324, -3.841941986195276],
              [114.877368523674946, -3.841842401428097],
              [114.877398888517305, -3.841684984214143],
              [114.87746932798386, -3.841173307770732],
              [114.877557984649229, -3.840754319623223],
              [114.877592773546183, -3.840598947676904],
              [114.877683282111178, -3.840437329997378],
              [114.877760020101874, -3.840301220588954],
              [114.877879122387782, -3.84044331775821],
              [114.878246803638348, -3.840915589527398],
              [114.878340260998201, -3.840974024173181],
              [114.878518460465315, -3.841010284789188],
              [114.878617161364659, -3.841017465241729],
              [114.878388827818355, -3.842135526698446],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.8,
        Nm_Pemilik: "Khairuddin Efendi",
        No_Kav: "31",
        KET: "Sporadik",
        NAMA_SKT_S: "Khairuddin Efendi",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "4",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87906986320003, -3.841969322580256],
              [114.879578861584804, -3.84295949534443],
              [114.879636663686611, -3.843037723497813],
              [114.878746274733189, -3.84358432963542],
              [114.878761129768094, -3.843527847665773],
              [114.878783448766242, -3.8434089916489],
              [114.878811356036877, -3.843256964908069],
              [114.878825531797389, -3.843091076979595],
              [114.878831366126136, -3.842958352904141],
              [114.878801498584636, -3.842789589763594],
              [114.878727430596484, -3.842681554951586],
              [114.87866981989329, -3.84259844966832],
              [114.878603936140777, -3.842520854533723],
              [114.878551415703072, -3.842424766307532],
              [114.878485783994961, -3.842294870928459],
              [114.878420882757723, -3.842159798953245],
              [114.878388827818355, -3.842135526698446],
              [114.878617161364659, -3.841017465241729],
              [114.878759612801758, -3.841027827836173],
              [114.879155729520292, -3.841056643559904],
              [114.879177904950794, -3.841101469360521],
              [114.87911049400752, -3.841325154076236],
              [114.878958374013337, -3.8415575817446],
              [114.878815312125113, -3.84173183022861],
              [114.87906986320003, -3.841969322580256],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.76,
        Nm_Pemilik: "Dedy Firdaus",
        No_Kav: "28",
        KET: "SHM",
        NAMA_SKT_S: "Sage Waluyo - Mangun S",
        block: "Blok 3",
        NO_TANDA_T: "11",
        KTP: "1",
        KK: "1",
        SKT: "1",
        SJB: null,
        KET_2: "shm",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880348309457744, -3.84565282222424],
              [114.880130123465932, -3.846492845191652],
              [114.879402319194284, -3.845654810820543],
              [114.879942740096482, -3.845185756008343],
              [114.880348309457744, -3.84565282222424],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.349,
        Nm_Pemilik: "Titik Sumarni",
        No_Kav: "26",
        KET: "Sporadik",
        NAMA_SKT_S: "Titik Sumarni",
        block: "Blok 3",
        NO_TANDA_T: "8",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "no 127",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879402319194284, -3.845654810820543],
              [114.879141048388675, -3.845353968731514],
              [114.879629892208726, -3.844930857837513],
              [114.879687687813842, -3.844892364075804],
              [114.879829435649413, -3.845056397802646],
              [114.87982996732643, -3.845055881308532],
              [114.879942738771504, -3.845185755116324],
              [114.879402319194284, -3.845654810820543],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.721,
        Nm_Pemilik: "Sudrajat",
        No_Kav: "36",
        KET: "Sporadik",
        NAMA_SKT_S: "Sudrajat",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874980079477382, -3.843529447079105],
              [114.875096252123669, -3.843315375566355],
              [114.875548709763336, -3.843418395230321],
              [114.875931826271767, -3.843453311892],
              [114.876588067419846, -3.843550892139555],
              [114.876512927102198, -3.843752555310479],
              [114.876511606209732, -3.843760324361763],
              [114.876483201867003, -3.843927270934651],
              [114.87489831627579, -3.843680112243705],
              [114.874980079477382, -3.843529447079105],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Hj. Nortinah",
        No_Kav: "1",
        KET: "Sporadik",
        NAMA_SKT_S: "Hj. Nortinah",
        block: "Blok 1",
        NO_TANDA_T: "25",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868250747030956, -3.852494587218081],
              [114.868327965704367, -3.85271423135024],
              [114.868327568492674, -3.852873747218271],
              [114.868394497681024, -3.853099887227183],
              [114.867499118912789, -3.853962592073669],
              [114.867146236272362, -3.853764802809149],
              [114.866744465555939, -3.852490512676909],
              [114.868250747030956, -3.852494587218081],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "M. Salmani",
        No_Kav: "2",
        KET: "Sporadik",
        NAMA_SKT_S: "M. Salmani",
        block: "Blok 1",
        NO_TANDA_T: "2",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868067212745288, -3.851361856847475],
              [114.868091381161548, -3.852057845507947],
              [114.868139178741174, -3.852350413131302],
              [114.868235976526748, -3.852452569846576],
              [114.868240820655288, -3.852466351356656],
              [114.868250747030956, -3.852494587218081],
              [114.866744465555939, -3.852490512676909],
              [114.866540852998185, -3.851844721186132],
              [114.866683637467474, -3.851540109548228],
              [114.866874042489798, -3.851450102862984],
              [114.866897946418476, -3.851397379888627],
              [114.866885931815759, -3.851362207282113],
              [114.868067212745288, -3.851361856847475],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Hj. Siti Fatimah",
        No_Kav: "3",
        KET: "Sporadik",
        NAMA_SKT_S: "Hj. Siti Fatimah",
        block: "Blok 1",
        NO_TANDA_T: "28",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868030191874212, -3.850198921601042],
              [114.868058723354522, -3.850971722178335],
              [114.868057690670099, -3.851011992432061],
              [114.868058497210583, -3.851062569661033],
              [114.868058450463707, -3.851109523778947],
              [114.868067212745288, -3.851361856847475],
              [114.866885931815759, -3.851362207282113],
              [114.866740964565963, -3.850937845476812],
              [114.866815850633955, -3.850811880982359],
              [114.866702433001336, -3.850649962885547],
              [114.866160063049222, -3.850193665867241],
              [114.868030191874212, -3.850198921601042],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Saibatul Aslamiah",
        No_Kav: "4",
        KET: "Sporadik",
        NAMA_SKT_S: "Saibatul Aslamiah",
        block: "Blok 1",
        NO_TANDA_T: "29",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86804703601959, -3.849432892272826],
              [114.868029093431304, -3.850169185106968],
              [114.868030191874212, -3.850198921601042],
              [114.866160063049222, -3.850193665867241],
              [114.86608096729077, -3.850127123166643],
              [114.865903436770012, -3.849977769134616],
              [114.865907940994262, -3.849745186596642],
              [114.865765313958235, -3.849427166043989],
              [114.86804703601959, -3.849432892272826],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Asiah",
        No_Kav: "5",
        KET: "Sporadik",
        NAMA_SKT_S: "Asiah",
        block: "Blok 1",
        NO_TANDA_T: "21",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.866515235777229, -3.848450554491178],
              [114.867794982061511, -3.849131605248474],
              [114.86798732431491, -3.849358143917852],
              [114.867998962390431, -3.849371851099403],
              [114.868047386410893, -3.849418512054173],
              [114.86804703601959, -3.849432892272826],
              [114.865765313958235, -3.849427166043989],
              [114.865755717274411, -3.849405768939019],
              [114.865735756750283, -3.84935237686967],
              [114.865646548827428, -3.849113766743848],
              [114.8657610069436, -3.848857806919771],
              [114.865745906557265, -3.84861729060068],
              [114.865798352375791, -3.848448358687143],
              [114.866515235777229, -3.848450554491178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.261,
        Nm_Pemilik: "Milawati",
        No_Kav: "6a",
        KET: "Sporadik",
        NAMA_SKT_S: "Milawati",
        block: "Blok 1",
        NO_TANDA_T: "27",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86605880532727, -3.84795022874217],
              [114.866178883563109, -3.848079805668738],
              [114.866356318454152, -3.848365982274416],
              [114.866515235742582, -3.848450554473663],
              [114.865798352384672, -3.848448358669417],
              [114.865813168480884, -3.848400634822805],
              [114.865955011747431, -3.848180224105773],
              [114.865912290244893, -3.847998772415315],
              [114.865802915533067, -3.847789557421919],
              [114.865792064612947, -3.847641263544597],
              [114.86605880532727, -3.84795022874217],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.739,
        Nm_Pemilik: "Milawati",
        No_Kav: "6b",
        KET: "Sporadik",
        NAMA_SKT_S: "Milawati",
        block: "Blok 1",
        NO_TANDA_T: "27",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.865914243324326, -3.847214265794291],
              [114.866106215874396, -3.847352727337396],
              [114.866353538244567, -3.847329694788475],
              [114.866914031938691, -3.847634656925167],
              [114.867043717778131, -3.847567963877603],
              [114.867185119216856, -3.847524953840348],
              [114.867526833630166, -3.847423312431844],
              [114.867624630553053, -3.847553652888266],
              [114.867625340200576, -3.847554072783754],
              [114.867623374021548, -3.84826450162975],
              [114.867456102617311, -3.848288075334566],
              [114.867314162328384, -3.848342501449439],
              [114.867190292964054, -3.848440798854915],
              [114.867113700659033, -3.848535562773558],
              [114.867011719965973, -3.848604697787288],
              [114.866663357265693, -3.848412124209206],
              [114.866440271299481, -3.848277283768483],
              [114.866271930885219, -3.848023293924383],
              [114.866219294343352, -3.847967040014342],
              [114.865788420022824, -3.847489874003496],
              [114.865791617437011, -3.847482167214916],
              [114.865914243324326, -3.847214265794291],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.377,
        Nm_Pemilik: "Nor Aina",
        No_Kav: "7",
        KET: "Sporadik",
        NAMA_SKT_S: "Nor Aina",
        block: "Blok 1",
        NO_TANDA_T: "20",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868721528205356, -3.849253528456716],
              [114.868041880790742, -3.849252091923141],
              [114.867931430500889, -3.849106262033895],
              [114.86776126941902, -3.848977406090654],
              [114.867100794642539, -3.848637347731418],
              [114.867173952130841, -3.848580135524884],
              [114.867244248240311, -3.848485104736102],
              [114.867339219593404, -3.848410833119707],
              [114.867475371308501, -3.848349083205794],
              [114.867623783407183, -3.848328758518802],
              [114.867821597562724, -3.848329255043734],
              [114.868016128390821, -3.84825661152557],
              [114.868107064746013, -3.848242231080746],
              [114.868161568421513, -3.848256976518875],
              [114.868266930698411, -3.848290110406173],
              [114.868365020986531, -3.848323226037327],
              [114.868466756372996, -3.848352698641807],
              [114.868641229988071, -3.848375049409611],
              [114.868722015506819, -3.848379413843598],
              [114.868721528205356, -3.849253528456716],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "H. Pahrani",
        No_Kav: "8",
        KET: "Sporadik",
        NAMA_SKT_S: "H. Pahrani",
        block: "Blok 1",
        NO_TANDA_T: "26",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869313780248746, -3.849254780514427],
              [114.868721528858956, -3.849253528458355],
              [114.868722015763197, -3.848379413973165],
              [114.868783015931712, -3.848382709472523],
              [114.868852172641496, -3.848353665639486],
              [114.868921374754649, -3.848306361077798],
              [114.869025060192882, -3.84829501974246],
              [114.869090864504656, -3.848348997690858],
              [114.869218506396862, -3.848394851845412],
              [114.869315949139107, -3.848382471176378],
              [114.869346304749513, -3.848378616675668],
              [114.869437011017538, -3.848362284307601],
              [114.869690342790236, -3.848333432768448],
              [114.870496225175799, -3.848436676670823],
              [114.870563874805455, -3.848479277824838],
              [114.87056134861399, -3.849257416914996],
              [114.870557486747373, -3.849257408759],
              [114.87050395545846, -3.849257295702863],
              [114.870450424171452, -3.849257182643357],
              [114.870302182304684, -3.849256869534989],
              [114.87000827511028, -3.849256248683794],
              [114.869758452386591, -3.849255720876928],
              [114.869313780248746, -3.849254780514427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Jailani",
        No_Kav: "9",
        KET: "Sporadik",
        NAMA_SKT_S: "Jailani",
        block: "Blok 1",
        NO_TANDA_T: "22",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.870915900038767, -3.849258165627907],
              [114.870930922447926, -3.849406851786771],
              [114.870994017011711, -3.849637228417562],
              [114.870990999959446, -3.849826511583502],
              [114.871242980707166, -3.850545298539369],
              [114.871215435654364, -3.850682353106907],
              [114.871208944706765, -3.850701344719751],
              [114.870143046083626, -3.850697778141788],
              [114.870146249391055, -3.849256540494923],
              [114.870450424171452, -3.849257182643357],
              [114.87050395545846, -3.849257295702863],
              [114.870557486747373, -3.849257408759],
              [114.870561348615851, -3.849257416915001],
              [114.870561353862598, -3.849255803288189],
              [114.870563874858377, -3.848479277849219],
              [114.870731424942889, -3.84858481339787],
              [114.870803023141875, -3.84861100174173],
              [114.871143916100522, -3.848755100105556],
              [114.871143681049475, -3.848849744798183],
              [114.870898608541225, -3.849087022494959],
              [114.870915900038767, -3.849258165627907],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.762,
        Nm_Pemilik: "Basuki Rianto",
        No_Kav: "13",
        KET: "Sporadik",
        NAMA_SKT_S: "Luqman",
        block: "Blok 1",
        NO_TANDA_T: "30",
        KTP: "5",
        KK: "0",
        SKT: "5",
        SJB: null,
        KET_2: "balik nama",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871208945866726, -3.850701346628764],
              [114.871105563074934, -3.851003902782866],
              [114.871172967699593, -3.851312202028062],
              [114.871383618577681, -3.851458446189364],
              [114.870114474975921, -3.852117288188313],
              [114.87003846325949, -3.852047345362482],
              [114.870022411963902, -3.851731627018157],
              [114.8699798479648, -3.851474494411237],
              [114.869946475745252, -3.851183305058498],
              [114.869895885019162, -3.850960567946744],
              [114.869828310049485, -3.850712102442764],
              [114.869826766067746, -3.850696735399714],
              [114.871208945866726, -3.850701346628764],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Hj. Norhasanah",
        No_Kav: "11",
        KET: "Sporadik",
        NAMA_SKT_S: "Hj. Norhasanah",
        block: "Blok 1",
        NO_TANDA_T: "3",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868191982396098, -3.851003900480645],
              [114.868192642687688, -3.850966531239352],
              [114.868174753487665, -3.850446214208478],
              [114.868166035575626, -3.850192434977523],
              [114.868173541926936, -3.849536929255629],
              [114.868560207047651, -3.849912661585965],
              [114.869360445677103, -3.850325642748212],
              [114.869616017542725, -3.850386216716966],
              [114.869675409341269, -3.850497670577032],
              [114.869700385274783, -3.850737467239563],
              [114.86980205270612, -3.851137302319016],
              [114.869152159606628, -3.851136100597068],
              [114.868195713804468, -3.851134331121482],
              [114.868195182650496, -3.851118729213594],
              [114.868192793607307, -3.851052604569782],
              [114.868191982396098, -3.851003900480645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.92,
        Nm_Pemilik: "Norhasanah",
        No_Kav: "11",
        KET: "Sporadik",
        NAMA_SKT_S: "Hj. Norhasanah",
        block: "Blok 1",
        NO_TANDA_T: "skt tdk ada",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869823844483207, -3.851571462609599],
              [114.869857430413447, -3.851773598313211],
              [114.869872585855148, -3.851961956115668],
              [114.869892094634338, -3.852113231560144],
              [114.869954341320366, -3.852199168161027],
              [114.86944520744801, -3.852464717430304],
              [114.869370239235735, -3.852377839455016],
              [114.869288905777353, -3.852092891875363],
              [114.869225946370094, -3.851722229546116],
              [114.869229003784596, -3.851719171439703],
              [114.869152965816681, -3.851136102582903],
              [114.86980205270612, -3.851137302319016],
              [114.869810381730744, -3.851273612535172],
              [114.869821638362339, -3.851450786256295],
              [114.869823844483207, -3.851571462609599],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.0,
        Nm_Pemilik: "Hj. Nortinah",
        No_Kav: "12",
        KET: "Sporadik",
        NAMA_SKT_S: "Hj. Nortinah",
        block: "Blok 1",
        NO_TANDA_T: "24",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869229003784596, -3.851719171439703],
              [114.869225945105512, -3.851722228365303],
              [114.86928890533099, -3.852092892872257],
              [114.869370238486297, -3.852377841251057],
              [114.869445207080062, -3.852464719283633],
              [114.868596401466945, -3.852905350753177],
              [114.868494387302505, -3.853003642523534],
              [114.868446655228382, -3.852882908439132],
              [114.868455853226294, -3.852732276715601],
              [114.868346383751231, -3.852404105930647],
              [114.86825845294571, -3.852284247401948],
              [114.86822806936911, -3.852084775006191],
              [114.868208163075934, -3.851500026673637],
              [114.868195713804468, -3.851134331121482],
              [114.869152965312864, -3.85113610258164],
              [114.869229003784596, -3.851719171439703],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.625,
        Nm_Pemilik: "M Haris Suparno",
        No_Kav: "15",
        KET: "Sporadik",
        NAMA_SKT_S: "M Haris Suparno",
        block: "Blok 2",
        NO_TANDA_T: "skt tdk ada",
        KTP: "0",
        KK: "0",
        SKT: "1",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874395026588758, -3.84464329108004],
              [114.87397463403677, -3.845291520586831],
              [114.873964203796561, -3.845344199413983],
              [114.872917042696059, -3.844986605318071],
              [114.87291681208454, -3.844986184617235],
              [114.872652674156072, -3.844808354097751],
              [114.872615097091156, -3.844719675249801],
              [114.872943421913689, -3.844416777157494],
              [114.873269466340005, -3.84395578284951],
              [114.874395026588758, -3.84464329108004],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Andreas Sujatmiko",
        No_Kav: "41",
        KET: "Sporadik",
        NAMA_SKT_S: "Andreas Sujatmiko",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875466639370487, -3.840215078070966],
              [114.875484917216312, -3.840067904001156],
              [114.875479210042016, -3.839623424275378],
              [114.877477012416605, -3.839907449427386],
              [114.877485806898264, -3.839940584440187],
              [114.877521751398106, -3.840042476366558],
              [114.877561407498803, -3.840108022861467],
              [114.877606299913793, -3.840155308887748],
              [114.877644447782998, -3.840195491257941],
              [114.877709424078546, -3.840268371317998],
              [114.877568653922907, -3.840515627694546],
              [114.875466639370487, -3.840215078070966],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Yuli Rusliani",
        No_Kav: "40",
        KET: "Sporadik",
        NAMA_SKT_S: "Hedi Siswoyo",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875452889998542, -3.840612104368693],
              [114.875435273909872, -3.840467633430146],
              [114.875466639370487, -3.840215078070966],
              [114.877568653922907, -3.840515627694546],
              [114.877523784177598, -3.840594428337977],
              [114.87747333295745, -3.840811033688702],
              [114.877451776358996, -3.84090356975741],
              [114.877401427815457, -3.84111969852186],
              [114.875483044224495, -3.840836050283985],
              [114.875474291444931, -3.840787613307447],
              [114.875452889998542, -3.840612104368693],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Yuli Rusliani",
        No_Kav: "39",
        KET: "Sporadik",
        NAMA_SKT_S: "Hedi Siswoyo",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875568435209118, -3.841308595852087],
              [114.875516046449548, -3.841018683803561],
              [114.875483044224495, -3.840836050283985],
              [114.877401427815457, -3.84111969852186],
              [114.87738629002682, -3.841184680669697],
              [114.877369194929642, -3.841288233690698],
              [114.877348836489219, -3.841411562767261],
              [114.877311273679894, -3.841682449593096],
              [114.877294874231367, -3.841755178543078],
              [114.877285950444929, -3.841794753273708],
              [114.875516947918229, -3.841538302633619],
              [114.87552416402086, -3.84150637976387],
              [114.875568435209118, -3.841308595852087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Lulus Puji Haryani",
        No_Kav: "38",
        KET: "Sporadik",
        NAMA_SKT_S: "Puguh SW",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877240233811264, -3.842241681995143],
              [114.877186181934448, -3.842485374941066],
              [114.875322110321946, -3.842189702371351],
              [114.875415524886137, -3.841980999647189],
              [114.875451299588278, -3.841828708528074],
              [114.875516947918229, -3.841538302633619],
              [114.877285950444929, -3.841794753273708],
              [114.877276302823731, -3.841837539104914],
              [114.87727336460506, -3.842092304249798],
              [114.877240233811264, -3.842241681995143],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.826,
        Nm_Pemilik: "Sudrajat",
        No_Kav: "36",
        KET: "Sporadik",
        NAMA_SKT_S: "Sudrajat",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876864050814376, -3.843115289348106],
              [114.876797864170939, -3.843228304843548],
              [114.876619698083942, -3.843477995851214],
              [114.87593577609131, -3.84337596193426],
              [114.875571430595329, -3.843344862959601],
              [114.87513211990769, -3.843243764396181],
              [114.87518217157789, -3.843145131745667],
              [114.875104985670845, -3.84282040114369],
              [114.876864050814376, -3.843115289348106],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Lulus Puji Haryani",
        No_Kav: "37",
        KET: "Sporadik",
        NAMA_SKT_S: "Puguh SW",
        block: "Blok 4",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877046226877397, -3.842848419220099],
              [114.877013066275381, -3.842920804362593],
              [114.876869647883098, -3.843105732198402],
              [114.876864050814376, -3.843115289348106],
              [114.875104985548774, -3.842820400979352],
              [114.875082379727445, -3.842725295840308],
              [114.875153373624102, -3.842566684920607],
              [114.875322110321946, -3.842189702371351],
              [114.877186181934448, -3.842485374941066],
              [114.877161439857986, -3.842596924436456],
              [114.877046226877397, -3.842848419220099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Hartanto",
        No_Kav: "22",
        KET: "Sporadik",
        NAMA_SKT_S: "Hartanto",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874643165050685, -3.844354797622645],
              [114.874776969431196, -3.843903717804752],
              [114.87489831627579, -3.843680112243705],
              [114.876483201867003, -3.843927270934651],
              [114.876142243344844, -3.844665660443004],
              [114.874615756609998, -3.84444719787559],
              [114.874643165050685, -3.844354797622645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Roni Heru L",
        No_Kav: "20",
        KET: "Sporadik",
        NAMA_SKT_S: "Roni Heru L",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "skt cuma 1",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875798079348769, -3.845410989440614],
              [114.875514646017876, -3.846024797002334],
              [114.875416182266079, -3.846041162857994],
              [114.87512037704694, -3.846053776537457],
              [114.874954052601694, -3.846120122934167],
              [114.874734125295603, -3.846359908320172],
              [114.874392464148926, -3.846105366916847],
              [114.874029947713495, -3.845909647228103],
              [114.874132459006461, -3.845320017625259],
              [114.874184532545883, -3.845232251971126],
              [114.875798079348769, -3.845410989440614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Roni Heru L",
        No_Kav: "21",
        KET: "Sporadik",
        NAMA_SKT_S: "Roni Heru L",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876142243344844, -3.844665660443004],
              [114.875799863501342, -3.845407125647305],
              [114.875798079382733, -3.845410989367089],
              [114.874184532545883, -3.845232251971126],
              [114.874248178187486, -3.845124982449195],
              [114.874551820290947, -3.844662753554281],
              [114.874615756609998, -3.84444719787559],
              [114.876142243344844, -3.844665660443004],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Budi Utomo / Toni Hartanto",
        No_Kav: "23",
        KET: "Sporadik",
        NAMA_SKT_S: "Toni Hartanto",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "0",
        KK: "0",
        SKT: "5",
        SJB: "asli1",
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876360571245954, -3.846318366930901],
              [114.876227379162088, -3.845906336020688],
              [114.875921179043431, -3.845957229847998],
              [114.875514646017876, -3.846024797002334],
              [114.875799863501342, -3.845407125647305],
              [114.876142243344844, -3.844665660443004],
              [114.876483201867003, -3.843927270934651],
              [114.876463398214696, -3.84404367674228],
              [114.876468345292125, -3.844366651856106],
              [114.876478269477204, -3.844418297567205],
              [114.876493854561417, -3.844499360526754],
              [114.876565204146502, -3.844669671246659],
              [114.876622349486425, -3.844779390859137],
              [114.876705289471957, -3.844906476509478],
              [114.876839464962899, -3.845117443157369],
              [114.876360571245954, -3.846318366930901],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Ngesti Nuririani",
        No_Kav: "24",
        KET: "Sporadik",
        NAMA_SKT_S: "Ngesti Nuririani",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: "0",
        KK: "0",
        SKT: "5",
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876638127684501, -3.84717700548175],
              [114.876614868437812, -3.846397111726456],
              [114.876401208939797, -3.846444086494881],
              [114.876360573385298, -3.846318372704793],
              [114.876839464982794, -3.845117443240051],
              [114.876956944684565, -3.845302158588785],
              [114.877063108336401, -3.845325492525292],
              [114.877301408754505, -3.845317436743071],
              [114.877404940411594, -3.845297182005976],
              [114.877519699078249, -3.845274727722965],
              [114.877631775710086, -3.845228869800704],
              [114.876900850222199, -3.847126529899949],
              [114.876847415387743, -3.847090399357558],
              [114.876798565533903, -3.847131129427444],
              [114.876661591232548, -3.847249591034921],
              [114.876638127684501, -3.84717700548175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.547,
        Nm_Pemilik: "Ngesti Nuririani",
        No_Kav: "25",
        KET: "Sporadik",
        NAMA_SKT_S: "Ngesti Nuririani",
        block: "Blok 3",
        NO_TANDA_T: "skt tdk ada",
        KTP: null,
        KK: null,
        SKT: null,
        SJB: null,
        KET_2: null,
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87798688157379, -3.84594628049531],
              [114.877516359492276, -3.846327995127112],
              [114.877493520965913, -3.846652555519948],
              [114.877121341791479, -3.84684896469794],
              [114.87711140970201, -3.847268902554041],
              [114.8769654854827, -3.847170234008338],
              [114.876900850453538, -3.847126529993582],
              [114.877631775710086, -3.845228869800704],
              [114.877818953865329, -3.844993440285227],
              [114.8779658336463, -3.844808697302353],
              [114.878015030079695, -3.844650221784402],
              [114.878078544971245, -3.844506200010798],
              [114.878256864510163, -3.844373999099051],
              [114.878562292017506, -3.844361979743615],
              [114.87798688157379, -3.84594628049531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.884,
        Nm_Pemilik: "Ngatijo",
        No_Kav: "42A",
        KET: "Sporadik",
        NAMA_SKT_S: "Ngatijo",
        block: "Blok 4",
        NO_TANDA_T: "13",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "42b",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875344099841726, -3.839012760137973],
              [114.875297624548708, -3.838894533593833],
              [114.875296710445028, -3.838842112049034],
              [114.877200527148673, -3.83910088088256],
              [114.87749057198215, -3.83933394708709],
              [114.877490505297089, -3.839334093103435],
              [114.877450893488131, -3.839420561458318],
              [114.877425240246964, -3.839547754142521],
              [114.877435846467066, -3.839645887874922],
              [114.877442863213545, -3.839740502490941],
              [114.877460701279219, -3.839845987494547],
              [114.877477012416605, -3.839907449427386],
              [114.875479210042016, -3.839623424275378],
              [114.875477504403108, -3.839490312287262],
              [114.875451466482389, -3.839285884934349],
              [114.875344099841726, -3.839012760137973],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.556,
        Nm_Pemilik: "Ngatijo",
        No_Kav: "42B",
        KET: "Sporadik",
        NAMA_SKT_S: "Ngatijo",
        block: "Blok 4",
        NO_TANDA_T: "14",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET_2: "42a",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875296710445028, -3.838842112049034],
              [114.875293750777359, -3.838672383209459],
              [114.875400882882801, -3.838277239976576],
              [114.875954881457403, -3.837592987541784],
              [114.876872166261109, -3.838942393866199],
              [114.877003532889589, -3.838942585596545],
              [114.877200527148673, -3.83910088088256],
              [114.875296710445028, -3.838842112049034],
            ],
          ],
        ],
      },
    },
  ],
};
