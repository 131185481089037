import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADS_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	loads: [],
	load: {},
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getLoads = createAsyncThunk(
	"load/getLoads",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(LOADS_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					fltDeleted: 0,
					pageIndex: data?.page || 0,
					pageSize: data?.pageSize || 20,
					sidx: "id",
					...data,
				},
			});
			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const getLoad = createAsyncThunk(
	"load/getLoad",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(`${LOADS_URL}/${data.id}`, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});
			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const createLoad = createAsyncThunk(
	"load/createLoad",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(LOADS_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});
			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const updateLoad = createAsyncThunk(
	"load/updateLoad",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(`${LOADS_URL}/${data.id}`, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});
			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const loadSlice = createSlice({
	name: "load",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLoads.fulfilled, (state, action) => {
			state.transports = action.payload.records;
		});
		// .addCase(getTransport.fulfilled, (state, action) => {
		// 	state.transport = action.payload.record;
		// })
		// .addCase(updateTransport.fulfilled, (state) => {})
		// .addCase(setTrainingWorthy.fulfilled, (state) => {})
		// .addCase(unsetTrainingWorthy.fulfilled, (state) => {})
		// .addCase(createTransport.fulfilled, (state) => {});
	},
});

export const {} = loadSlice.actions;
export const selectLoads = (state) => state.load.transports;
export const selectLoad = (state) => state.load.load;
export const selectStatuses = (state) => ({
	isLoading: state.load.isLoading,
	isRequest: state.load.isRequest,
	isError: state.load.isError,
	errors: state.load.errors,
});

export default loadSlice.reducer;
