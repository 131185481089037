import PropTypes from "prop-types";
import React from "react";
import { TbX } from "react-icons/tb";

const Modal = (props) => {
  const { children, isOpen, title, type, size, align, zIndex, onClose } = props;
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const alignItems = {
    start: "items-start",
    center: "items-center",
    end: "items-end",
  };
  const zIndexs = {
    5: "z-[5]",
    20: "z-[20]",
    25: "z-[25]",
  };
  // tablet:w-[45%] laptop:w-[65%] large-desktop:w-[45%] 3xl:w-[35%]
  const sizes = {
    sm: "w-[100%] md:w-[680px]",
    lg: "md:w-[45%] 3xl:w-[35%]",
  };

  return (
    <div
      className={`fixed top-0 left-0 bottom-0 right-0 m-auto min-h-full w-screen min-w-full flex-col justify-start ${
        isOpen ? "flex" : "hidden"
      } ${alignItems[align]} ${zIndexs[zIndex]}`}
    >
      <div className=" absolute h-full w-full bg-gray-white-transparent backdrop-blur-sm"></div>
      {type === "form" && (
        <div
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          className={`relative my-16 max-h-screen bg-white shadow-lg md:max-h-[calc(100vh-2rem)] md:rounded-16 ${
            isMouseOver && "overflow-y-auto"
          } scrollbar-thumb-rounded-full w-full scrollbar-thin scrollbar-thumb-gray-10 ${
            sizes[size]
          }`}
        >
          <div className="sticky top-0 z-[5] flex w-full flex-row items-center justify-between rounded-t-8 border-b-1 border-b-gray-50 bg-white px-16 py-8">
            <h3 className="font-semi-bold text-18 text-gray-800">{title}</h3>
            <div
              onClick={onClose}
              className="cursor-pointer rounded-8 border-1 border-white p-8 hover:border-gray-100 hover:bg-gray-30"
            >
              <TbX className="text-24 text-gray-500" />
            </div>
          </div>
          {children}
        </div>
      )}
      {type === "section" && (
        <div
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          className={`relative h-full bg-white shadow-lg md:rounded-16 ${
            isMouseOver && "overflow-y-auto"
          } w-full scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 ${
            sizes[size]
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["form", "section"]),
  size: PropTypes.oneOf(["sm", "lg"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  zIndex: PropTypes.oneOf([5, 20, 35]),
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  type: "form",
  size: "sm",
  align: "center",
  zIndex: 20,
};

export default Modal;
