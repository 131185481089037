import Navbar from "../../../../../components/atoms/Navbar";
import React from "react";
import { TbHeartRateMonitor } from "react-icons/tb";

const Heading = () => {
  return (
    <div className="relative mx-auto flex w-full flex-row items-start justify-between md:space-x-16">
      <div className="hidden flex-row items-center space-x-16 md:flex">
        <TbHeartRateMonitor className="text-42 text-gray-500" />
        <div className="flex flex-row items-center divide-x divide-gray-500">
          <h1 className="pr-8 text-18 font-bold text-gray-500">
            Data Yield (Year to Date)
          </h1>
        </div>
      </div>
      <div className="w-full md:hidden">
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <p className="line-clamp-1">Data Yield (Year to Date)</p>
            </div>
          }
        />
      </div>
      <div className="hidden md:inline">
        <Navbar onlyUserProfile={true} />
      </div>
    </div>
  );
};

export default Heading;
