import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ConfigProvider, DatePicker } from "antd";
import { TbCalendar } from "react-icons/tb";
import { Controller } from "react-hook-form";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import moment from "moment";
import { debounce } from "lodash";
import dayjs from "dayjs";
import SelectHook from "../../../../../../../../../components/atoms/SelectHook";

const Content = ({
  control,
  register,
  errors,
  watch,
  setValue,
  isCreate,
  parties,
}) => {
  const watchStartEndDate = watch("startDate");
  const watchBuyerPartyPrice = watch("buyerPartyPrice");
  const watchMaxPurchasePrice = watch("maxPurchasePrice");
  const watchMinPurchasePrice = watch("minPurchasePrice");

  const request = debounce(async (searchTerm, inputName) => {
    let valueToSet = searchTerm;
    if (inputName === "maxPurchasePrice") {
      if (searchTerm >= watchBuyerPartyPrice) {
        valueToSet = watchBuyerPartyPrice;
      } else if (searchTerm <= watchMinPurchasePrice) {
        valueToSet = watchMinPurchasePrice;
      }
    } else if (searchTerm === "minPurchasePrice") {
      if (searchTerm >= watchMaxPurchasePrice) {
        valueToSet = watchMaxPurchasePrice;
      }
    } else if (searchTerm <= 1) {
      valueToSet = 100;
    }
    setValue(inputName, valueToSet);
  }, 800);

  const debounceTextRequest = useCallback(
    (searchTerm, inputName) => {
      request(searchTerm, inputName);
    },
    [watchBuyerPartyPrice, watchMaxPurchasePrice, watchStartEndDate]
  );

  const dateParsed = new Date(watchStartEndDate);
  const invalidDate = dateParsed
    ? moment(dateParsed).format("LL")
    : "mulai berlaku";

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <div className="grid grid-cols-1 gap-12">
      <div className="grid grid-cols-1 gap-8">
        <h3 className="text-18 font-bold text-red-500">Aturan Update Harga</h3>
        <ul className="list-inside list-disc font-regular text-16 text-red-500">
          <li>
            Jika terdapat kesalahan dalam memasukkan harga di bawah ini, harap
            perbaiki sebelum pukul 09:00 pada tanggal{" "}
            <span className="font-bold">{invalidDate}</span>.
          </li>
          <li>
            Pengubahan tidak dapat dilakukan pukul 09:00 pada tanggal{" "}
            <span className="font-bold">{invalidDate}</span>, karena pengaturan
            tersebut sudah berlaku.
          </li>
        </ul>
      </div>
      {isCreate ? (
        <SelectHook
          label="Nama PKS"
          control={control}
          data={parties?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))}
          name="buyerParty"
          placeholder="Pilih PKS"
          className="hidden md:inline"
          menuStyles={{ padding: 5 }}
        />
      ) : null}

      <div className="grid grid-cols-1 gap-8">
        <p className="text-14 font-medium text-gray-500">
          Tanggal harga mulai berlaku
        </p>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD MMM YYYY"
                  disabledDate={disabledDate}
                  value={value}
                  onChange={(date) => onChange(date)}
                  placeholder="Tanggal harga berlaku"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: "100%",
                    paddingTop: 13,
                    paddingBottom: 13,
                    borderRadius: 12,
                    fontFamily: "Inter-Regular",
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </div>
      <InputHook
        control={control}
        label="Harga PKS (Rp)"
        name="buyerPartyPrice"
        type="number"
        errors={errors?.buyerPartyPrice}
        inputClassName="input-large-border"
      />
      <InputHook
        control={control}
        label="Batas Harga Atas (Rp)"
        name="maxPurchasePrice"
        type="number"
        errors={errors?.maxPurchasePrice}
        inputClassName="input-large-border"
        onBlur={(searchTerm) =>
          debounceTextRequest(watchMaxPurchasePrice, "maxPurchasePrice")
        }
      />
      <InputHook
        control={control}
        label="Batas Harga Bawah (Rp)"
        name="minPurchasePrice"
        type="number"
        errors={errors?.minPurchasePrice}
        inputClassName="input-large-border"
        onBlur={(searchTerm) =>
          debounceTextRequest(watchMinPurchasePrice, "minPurchasePrice")
        }
      />
    </div>
  );
};

export default Content;

Content.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};
