import { createSearchParams, useNavigate } from "react-router-dom";

import { LOGOUT_CALLBACK_URL } from "../../../../configs/api";
import React from "react";
import { useAuthStore } from "../../../../hooks/authStore";

const Item = ({ logoutType, item }) => {
  const navigate = useNavigate();
  const keycloak = useAuthStore((state) => state.keycloak);

  return (
    <div
      className={`flex cursor-pointer flex-row items-center space-x-16 border border-gray-20 bg-white p-16 ${
        logoutType && "rounded-b-16"
      }`}
      onClick={() => {
        if (logoutType) {
          keycloak.logout({
            redirectUri: LOGOUT_CALLBACK_URL,
          });
          localStorage.clear();
          navigate("/logout-callback", { replace: true });
        } else if (item?.type === "external") {
          return navigate({
            pathname: "/redirect-to",
            search: createSearchParams({
              url: item.link,
            }).toString(),
          });
        } else {
          return navigate(item.link);
        }
      }}
    >
      <div
        className={`${logoutType ? "text-red-500" : "text-gray-400"} text-24`}
      >
        {item.icon}
      </div>
      <div className="flex flex-col">
        <p
          className={`text-16 font-medium ${
            logoutType ? "text-red-500" : "text-gray-500"
          }`}
        >
          {item.additional}
        </p>
        <p
          className={`text-16 ${
            logoutType
              ? "font-medium text-red-500"
              : item.additional
              ? "font-regular text-gray-300"
              : "font-medium text-gray-500"
          }`}
        >
          {item.title}
        </p>
      </div>
    </div>
  );
};

export default Item;
