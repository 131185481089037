import axiosInstance from "../api";
import { VEHICLE_CHECKPOINT } from "../../configs/api";

export const getVehicleCheckpointsApi = (params) =>
  axiosInstance.get(VEHICLE_CHECKPOINT, {
    params,
  });

export const createVehicleCheckpointApi = (body) =>
  axiosInstance.post(VEHICLE_CHECKPOINT, body);

export const updateVehicleCheckpointApi = (id, body) =>
  axiosInstance.put(`${VEHICLE_CHECKPOINT}/${id}`, body);

export const deleteVehicleCheckpointApi = (id) =>
  axiosInstance.delete(`${VEHICLE_CHECKPOINT}/${id}`, body);
