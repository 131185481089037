import React, { useRef, useState } from "react";

import { DataProvider } from "./hooks/DataContext";
import Filter from "./partials/Filter/Filter";
import Heading from "./partials/Heading/Heading";
import RecapSection from "./partials/RecapSection";
import TableSection from "./partials/TableSection/TableSection";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import { TbFilter } from "react-icons/tb";

const Yield = () => {
  // md:bg-yellow-500 lg:bg-red-500 xl:bg-green-600 2xl:bg-blue-600
  const componentRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <DataProvider>
      <div
        ref={componentRef}
        className="relative grid w-full grid-rows-[max-content,max-content,max-content,1fr] gap-8 overflow-hidden bg-gray-30 pb-24 md:max-h-screen md:grid-rows-[minmax(0px,50px)_minmax(0px,50px)_minmax(0px,0.5fr)_minmax(0px,0.5fr)] md:gap-13 md:px-0 md:py-16 md:px-[2%] md:pb-16"
      >
        <ConfigProvider
          theme={{
            token: { colorPrimary: "#D7A137" },
          }}
        >
          <Modal
            title="Filter"
            zIndex={10}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={showModal}
            wrapClassName="backdrop-blur-sm"
            footer={
              <div className="flex flex-row items-center justify-end space-x-16 pt-16">
                <ButtonComponent
                  value="Tutup"
                  type="submit"
                  size="none"
                  className="button-transition px-16 py-8 text-16"
                  onClick={handleOk}
                />
              </div>
            }
          >
            <ModalFilter showModal={showModal} ref={componentRef} />
          </Modal>
        </ConfigProvider>
        <Heading />
        <Filter ref={componentRef} showModal={showModal} />
        <RecapSection />
        <TableSection />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition fixed right-16 bottom-24 z-10 -ml-8 w-fit rounded-16 bg-red-mobile p-10 text-16 text-white md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-white" />}
        />
        {/*<div className="border border-red-500">Filter</div>*/}
        {/*<div className="border border-red-500">Filter</div>*/}
        {/*<div className="border border-red-500">Recap</div>*/}
        {/*<div className="border border-red-500">Table</div>*/}
      </div>
    </DataProvider>
  );
};

export default Yield;
