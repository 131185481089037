import * as yup from "yup";

export const formSchema = yup.object().shape({
  fullname: yup.string().required("Nama lengkap diperlukan"),
  createdBy: yup
    .string()
    .required("Username diperlukan")
    .min(2, "Username harus minimal 2 karakter"),
  contactEmail: yup
    .string()
    .email("email harus email yang valid")
    .required("Email diperlukan"),
  contactPhone: yup
    .string()
    .required("Nomer telepon diperlukan")
    .min(7, "Nomor Handphone minimal harus 7 karakter"),
  password: yup
    .string()
    .required("Kata Sandi diperlukan")
    .min(4, "Panjang kata sandi harus minimal 4 karakter")
    .max(12, "Kata sandi tidak boleh lebih dari 12 karakter"),
  cpassword: yup
    .string()
    .required("Konfirmasi kata Sandi diperlukan")
    .min(4, "Panjang kata sandi harus minimal 4 karakter")
    .max(12, "Kata sandi tidak boleh lebih dari 12 karakter")
    .oneOf([yup.ref("password")], "Sandi tidak cocok"),
});
