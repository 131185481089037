import Bar from "./partials/Bar";
import Doughnut from "./partials/Doughnut";
import Line from "./partials/Line";
import Radar from "./partials/Radar";

const Chart = () => <p>Chart</p>;

Chart.Bar = Bar;
Chart.Line = Line;
Chart.Radar = Radar;
Chart.Doughnut = Doughnut;
export default Chart;
