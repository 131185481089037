import React, { useState } from "react";
import * as XLSX from "xlsx";

const handleNullValues = (_key, value) => (value === null ? "" : value);

const jsonToCsv = (json) => {
  const header = Object.keys(json[0]);
  const csv = [
    header.join(";"),
    ...json.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], handleNullValues))
        .join(";")
    ),
  ].join("\r\n");

  return csv;
};

const createAndDownloadCsv = (data, fileName) => {
  const csv = jsonToCsv(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.csv`;
  link.click();
};

const createAndDownloadXlsx = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

const useExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const exportToCSV = async (data, fileName, params, opts) => {
    setIsLoading(true);
    try {
      opts?.isXLSX
        ? createAndDownloadXlsx(data, fileName)
        : createAndDownloadCsv(data, fileName);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
      setIsLoading(false);
    }
  };

  return { isLoading, isError, exportToCSV };
};

export default useExport;
