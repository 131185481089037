import ButtonComponent from "../../../../../../components/atoms/ButtonComponent";
import React from "react";

const StepActions = ({ current, stepLength, onPrev }) => {
	return (
		<div className="flex flex-row items-center justify-end space-x-16">
			{current > 0 && (
				<ButtonComponent
					type="submit"
					style={{ margin: "0 8px" }}
					onClick={onPrev}
					value="Sebelumnya"
					className="bordered-button-transition"
				/>
			)}
			{current === stepLength - 1 && (
				<ButtonComponent type="submit" value="Selesai" />
			)}
			{current < stepLength - 1 && (
				<ButtonComponent type="submit" value="Selanjutnya" />
			)}
		</div>
	);
};

export default StepActions;
