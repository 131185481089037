import React, { createContext, useContext, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";

const mapContext = createContext(undefined);

export const MapProvider = ({ children }) => {
  const [popupContent, setPopupContent] = useState([]);
  const [count, setCount] = useState(0);

  const [map, setMap] = useLocalStorage("map", null);
  const [lngLat, setLngLat] = useLocalStorage("lngLat", {
    lng: 115.02146465715937,
    lat: -1.163918159016864,
  });
  const [zoom, setZoom] = useLocalStorage("zoom", 15);

  function click() {
    setCount(count + 1);
  }

  const Provider = mapContext.Provider;

  return (
    <Provider
      value={{
        popupContent,
        setPopupContent,
        map,
        setMap,
        lngLat,
        setLngLat,
        count,
        click,
        zoom,
        setZoom,
      }}
    >
      {children}
    </Provider>
  );
};

export const useMap = () => useContext(mapContext);
