import React, { useEffect } from "react";
import { TbInfoCircle } from "react-icons/tb";
import { useForm } from "react-hook-form";
import InputHook from "../../../../../components/atoms/InputHook";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useSpotCheckStore } from "../../hooks/useSpotCheckStore";
import { useGenericRecordsStore } from "../../../../../api/genericRecords/useChemistStore";
import { toast } from "react-hot-toast";

const GeneralInfo = () => {
  const { id } = useParams();
  const { register, setValue, watch, handleSubmit } = useForm();
  const [data, setData, setRequest, request] = useSpotCheckStore((state) => [
    state.data,
    state.setData,
    state.setRequest,
    state.request,
  ]);
  const watchRemark = watch("comment");
  const [getGenericRecord, updateGenericRecord] = useGenericRecordsStore(
    (state) => [state.getGenericRecord, state.updateGenericRecord]
  );

  const updateDataHandler = async (data) => {
    setRequest({ isLoadingRequest: true });
    await updateGenericRecord({
      id,
      body: data,
      onSuccess: (response) => {
        setData(response.record);
        handleSetDefaultData(response.record);
        setTimeout(() => {
          setRequest({ isLoadingRequest: false });
        }, 800);
        toast.success("Berhasil memberikan komentar");
      },
      onError: () => {
        setRequest({ isLoadingRequest: false });
      },
    });
  };

  const onSubmit = (inputData) => {
    let requestData = {
      ...data,
      remark: inputData,
    };
    console.log("requestData: ", requestData);
    updateDataHandler(requestData);
  };

  const handleSetDefaultData = (data) => {
    setValue("title", data?.title);
    setValue("userFullname", data?.userFullname);
    setValue("notes", data?.notes);
    setValue("comment", data?.remark);
    setValue("createdTime", data?.createdTime);
  };

  const getInitialData = async () => {
    setRequest({ isLoadingData: true });
    await getGenericRecord({
      id,
      onSuccess: (response) => {
        setData(response?.record);
        handleSetDefaultData(response?.record);
        setTimeout(() => {
          setRequest({ isLoadingData: false });
        }, 800);
      },
    });
  };

  useEffect(() => {
    getInitialData();
  }, [id]);

  return request.isLoadingData ? (
    <div className="col-span-10 flex w-full flex-col items-center justify-center border border-gray-10 bg-white">
      <div className="loader-spinner-gradient h-32 w-32"></div>
      <p className="font-regular text-14 text-gray-500">
        Sedang dalam proses memuat informasi...
      </p>
    </div>
  ) : (
    <div className="grid w-full max-w-[1247px] grid-cols-1 gap-8 overflow-y-scroll rounded-16 border border-gray-10 bg-white py-14 md:mx-auto md:mt-0">
      <div className="flex flex-row items-center justify-between px-14">
        <div className="flex flex-row items-center space-x-16">
          <TbInfoCircle className="text-24 text-gray-500" />
          <h1 className="text-left text-14 font-medium text-gray-500 md:text-16">
            Informasi
          </h1>
        </div>
        <p className="font-regular text-14 text-gray-500 md:text-16">
          {moment(data?.createdTime).format("dddd LL H:mm")},
        </p>
      </div>
      <form>
        <div className="grid grid-cols-2 gap-8 px-16 md:grid-cols-4">
          <InputHook
            register={register}
            label="Dibuat Oleh"
            name="userFullname"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Judul"
            name="title"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Deskripsi"
            name="notes"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          {/*<InputHook*/}
          {/*  optional*/}
          {/*  register={register}*/}
          {/*  label="Komentar"*/}
          {/*  name="comment"*/}
          {/*  inputClassName="input-large-border bg-gray-30"*/}
          {/*  onBlur={() => onSubmit(watchRemark)}*/}
          {/*/>*/}
        </div>
      </form>
    </div>
  );
};

export default GeneralInfo;
