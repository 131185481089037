import axiosInstance from "../api";
import { FERTILIZATION_RECAPITULATION_URL } from "../../configs/api";

export const getFertilizationRecapsDistinctBlocksApi = (params) =>
  axiosInstance.get(
    `${FERTILIZATION_RECAPITULATION_URL}/daily/distinct-blocks`,
    {
      params,
    }
  );

export const getFertilizationRecapsMaterialSummaryApi = (params) =>
  axiosInstance.get(
    `${FERTILIZATION_RECAPITULATION_URL}/daily/material-summary`,
    {
      params,
    }
  );

export const getFertilizationRecapsOverallSummaryApi = (params) =>
  axiosInstance.get(
    `${FERTILIZATION_RECAPITULATION_URL}/daily/overall-summary`,
    {
      params,
    }
  );
