import { BASE_URL } from "../configs/api";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const tokens = JSON.parse(localStorage.getItem("auth-storage")).state.tokens;
  if (tokens) {
    req.headers["Authorization"] = "Bearer " + tokens.token;
  }
  return req;
});

export default axiosInstance;
