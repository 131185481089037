export const headersData = [
  {
    value: "ID",
    accessorKey: "index",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Grup Tag",
    accessorKey: "name",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Tag Prefix",
    accessorKey: "itemPrefix",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Deskripsi",
    accessorKey: "description",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Tags",
    accessorKey: "items",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Aksi",
    accessorKey: "action",
    type: "alphabet",
  },
];
