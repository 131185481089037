import "react-circular-progressbar/dist/styles.css";

import React from "react";
import { Number, parseToFloat } from "../../../../../helpers/Common";
// import ProgressBar from "@ramonak/react-progress-bar";
import ProgressBar from "../../../../../components/atoms/ProgressBar";

const CardWithDoughnutChartVertical = ({
  title,
  subTitle,
  data,
  progressStyle,
}) => {
  const valuePercentage = (data[0]?.value / data[1]?.value) * 100 || 0;

  return (
    <div className="grid h-full grid-cols-1 gap-0 rounded-8 bg-white p-8 shadow md:grid-rows-[minmax(0px,0.9fr)_minmax(0px,2fr)] md:gap-32 md:p-8 md:px-16 md:pb-16">
      <div className="flex flex-col">
        <h3 className="truncate text-14 font-semibold text-gray-500 md:text-16">
          {title}
        </h3>
        <p className="font-regular text-13 text-gray-400 md:text-14">
          {subTitle}
        </p>
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4">
        <div className="relative hidden flex-col items-center justify-center md:flex">
          <p className="absolute -top-24 text-18 font-bold text-gray-500 md:-top-32 md:text-24">
            {parseToFloat(valuePercentage)}%
          </p>
          <ProgressBar
            baseBgColor={progressStyle.backgroundColor[1]}
            bgColor={progressStyle.backgroundColor[0]}
            completed={valuePercentage}
            className="h-full -rotate-180"
            containerStyles={{ width: 15 }}
          />
        </div>
        <div className="flex flex-row space-x-8 space-y-0 md:flex-col md:justify-between md:space-x-0 md:space-y-8">
          {data.map((item, index) => (
            <div className="grid w-full grid-cols-1">
              <div className="flex flex-row items-center justify-start space-x-4">
                <div
                  className="aspect-square w-10 rounded-full"
                  style={item?.style}
                ></div>
                <p className="break-words text-13 font-medium text-gray-500 md:text-13">
                  {item?.title}
                </p>
              </div>
              <div
                key={index}
                className="flex flex-row items-end space-x-4 md:items-center"
              >
                <h1 className="text-14 font-bold text-gray-500 md:text-16">
                  {Number(Math.ceil(item?.value))}
                </h1>
                <p className="text-12 font-regular text-gray-500 md:text-14">
                  {item?.unit}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardWithDoughnutChartVertical;
