export const headersData = [
	{
		value: "Estate",
		accessorKey: "estateName",
		type: "alphabet",
	},
	{
		value: "Afdeling",
		accessorKey: "afdelingName",
		type: "alphabet",
	},
	{
		value: "Tipe Truk",
		accessorKey: "type",
		type: "alphabet",
	},
	{
		value: "Nama Truk",
		accessorKey: "name",
		type: "alphabet",
	},
	{
		value: "Nomor Polisi",
		accessorKey: "code",
		type: "alphabet",
	},
	{
		value: "Tahun Akuisisi",
		accessorKey: "acquisitionYear",
		type: "alphabet",
	},
	{
		value: "Berat Bersih (Kg)",
		accessorKey: "tareWeight",
		type: "alphabet",
	},
	// {
	// 	value: "Waktu Diperbarui",
	// 	accessorKey: "updatedTime",
	// 	type: "alphabet",
	// },
	{
		value: "Tanggal dibuat",
		accessorKey: "updatedTime",
		type: "alphabet",
	},
	{
		value: "Aksi",
		accessorKey: "action",
		type: "alphabet",
	},
];
