import axiosInstance from "./api";
import { FILE_ATTACHMENTS_URL } from "../configs/api";

export const getImageApi = (id) =>
  axiosInstance.get(`${FILE_ATTACHMENTS_URL}/${id}/download`, {
    responseType: "blob",
  });

export const uploadFileApi = (data) =>
  axiosInstance.post(`${FILE_ATTACHMENTS_URL}/upload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
