import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showModalCreate: false,
	showModalUpdate: false,
	showModalHistory: false,
	params: {},
};

export const materialModalSlice = createSlice({
	name: "materialModal",
	initialState,
	reducers: {
		handleModal: (state, action) => {
			state[action.payload.state] = !state[action.payload.state];
		},
		handleSetParams: (state, action) => {
			state.params = action.payload;
		},
	},
});

export const { handleModal, handleSetParams } = materialModalSlice.actions;
export const selectMaterialModalStatus = (state) => state.materialModal;
export default materialModalSlice.reducer;
