import { ConfigProvider, Steps } from "antd";
import React, { useEffect } from "react";

import Dot from "./partials/Dot";
import FirstContent from "./partials/FirstContent";
import SecondContent from "./partials/SecondContent";
import { getProvinces } from "../../../../features/areaSlice";
import { useData } from "./hooks/DataContext";
import { useDispatch } from "react-redux";
import { APP_NAME } from "../../../../configs/api";

const { Step } = Steps;

const Forms = () => {
  const dispatch = useDispatch();
  const { steps, current } = useData();

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  return (
    <div className="h-full overflow-y-scroll p-16 md:p-24">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Steps current={current} className="mx-auto md:max-w-[60%]">
          {steps.map((item, index) => (
            <Step
              key={index}
              icon={<Dot icon={item.icon} isActive={index <= current} />}
              title={item.title}
              className="font-medium text-16 text-gray-500"
            />
          ))}
        </Steps>
      </ConfigProvider>
      {steps[current]?.content === "first" && <FirstContent />}
      {steps[current]?.content === "second" && <SecondContent />}
    </div>
  );
};

export default Forms;
