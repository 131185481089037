import { changePassword, selectUser } from "../../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../atoms/ButtonComponent";
import { HiKey } from "react-icons/hi";
import InputHook from "../../../atoms/InputHook";
import React from "react";
import queryString from "query-string";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import usePassword from "../../../../hooks/usePassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./schema/formSchema";
import { notification } from "antd";
import { RiErrorWarningFill } from "react-icons/ri";
import { MdClose } from "react-icons/md";

const ResetPassword = ({ refreshData }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const alert = useAlert();
  const user = useSelector(selectUser);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { isPasswordShow, handlePasswordShow } = usePassword();

  const showErrorNotification = ({ message, description, placement }) => {
    return api.error({
      message,
      description,
      placement: placement || "topRight",
      className: "font-regular text-14 text-gray-500",
      icon: <RiErrorWarningFill className="text-24 text-red-500" />,
      closeIcon: <MdClose />,
      duration: 15,
    });
  };

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(
        changePassword(queryString.stringify(data))
      ).unwrap();
      if (!response.success) {
        return showErrorNotification({
          message: "Gagal Mengubah Kata Sandi",
          description:
            "Mohon pastikan bahwa Anda telah memasukkan password yang benar sebelum mencoba lagi. Pastikan juga password baru yang dibuat sudah benar.",
        });
      }

      alert.success("Berhasil mengubah kata sandi");
      refreshData();
      setValue("oldPassword", undefined);
      setValue("newPassword", undefined);
      setValue("confirmNewPassword", undefined);
    } catch (error) {
      return showErrorNotification({
        message: "Gagal Mengubah Kata Sandi",
        description:
          "Mohon pastikan bahwa Anda telah memasukkan password yang benar sebelum mencoba lagi. Pastikan juga password baru yang dibuat sudah benar.",
      });
    }
  };

  return (
    <div
      className={`rounded-16 border-1 border-gray-10 bg-white pt-24 md:mx-32`}
    >
      {contextHolder}

      <div className="flex flex-row items-center space-x-16 px-24">
        <HiKey className="text-24 text-gray-500" />
        <h1 className="text-left font-medium text-16 text-gray-500 md:text-[20px]">
          Ubah Kata Sandi
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8 flex flex-col p-24">
          <div className="mb-16 flex flex-col">
            <p className="mb-16 font-regular text-14 text-gray-500">
              Silahkan masukkan kata sandi lama Anda untuk memulai proses
              penggantian kata sandi. Selanjutnya, silakan masukkan kata sandi
              baru yang ingin Anda gunakan dan konfirmasikan kata sandi baru
              tersebut.
            </p>
            <div className="grid grid-cols-1 gap-13">
              <InputHook
                password
                register={register}
                label="Kata Sandi Lama"
                name="oldPassword"
                className="mb-0 mt-0"
                inputClassName="input-large-border"
                placeholder="Masukkan password baru"
                errors={errors?.oldPassword}
              />
              <InputHook
                type={isPasswordShow ? "text" : "password"}
                register={register}
                label="Kata Sandi Baru"
                name="newPassword"
                className="mb-0 mt-0"
                inputClassName="input-large-border"
                placeholder="Masukkan password baru"
                errors={errors?.newPassword}
              />
              <InputHook
                type={isPasswordShow ? "text" : "password"}
                register={register}
                label="Konfirmasi Kata Sandi Baru"
                name="confirmNewPassword"
                className="mb-0 mt-0"
                inputClassName="input-large-border"
                placeholder="Masukkan konfirmasi password"
                errors={errors?.confirmNewPassword}
              />
              <div className="flex flex-row items-center space-x-16">
                <input
                  id="decision-checkbox"
                  type="checkbox"
                  className="z-10 h-[20px] w-[20px] border-gray-300 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  onChange={handlePasswordShow}
                />
                <label
                  htmlFor="decision-checkbox"
                  className="font-regular text-14 text-gray-500"
                >
                  Tampilkan password
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer-container space-x-16">
          <ButtonComponent
            disabled={!user?.isEmailVerified}
            value="Ubah kata sandi"
            size="sm"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
