import React from "react";
import TableRow from "./partials/TableRow";
import Tables from "../../../../../components/organisms/Tables";
import { headersData } from "./api/headerData";
import { selectVehicles } from "../../../../../features/vehicleSlice";
import { useSelector } from "react-redux";

const ListVehicle = ({ isLoading, onDelete, onUpdate }) => {
	const data = useSelector(selectVehicles);
	return (
		<div className="mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
			<Tables
				className="overscroll-y max-h-full"
				isLoading={isLoading}
				headers={headersData}
				rows={data || []}
				rowContent={(e) => (
					<TableRow
						data={e}
						onDelete={onDelete}
						onUpdate={onUpdate}
					/>
				)}
			/>
		</div>
	);
};

export default ListVehicle;
