import { useDispatch } from "react-redux";

import React, { useEffect } from "react";
import { TbInfoCircle } from "react-icons/tb";
import { parseToFloat } from "../../../../../helpers/Common";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import InputHook from "../../../../../components/atoms/InputHook";
import { useParams } from "react-router-dom";
import {
  getFertilizationById,
  updateFertilization,
} from "../../../../../features/fertilizationSlice";
import moment from "moment/moment";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { useSpotCheckStore } from "../../hooks/useSpotCheckStore";

const GeneralInfo = () => {
  const { id } = useParams();
  const alert = useAlert();
  const { register, setValue, watch, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [data, setData, setRequest, request] = useSpotCheckStore((state) => [
    state.data,
    state.setData,
    state.setRequest,
    state.request,
  ]);
  const watchRemark = watch("comment");

  const handleUpdateFertilization = async (data) => {
    try {
      setRequest({ isLoadingRequest: true });
      const response = await dispatch(updateFertilization(data)).unwrap();
      if (!response.success) {
        setRequest({ isLoadingRequest: false });
        return alert.error(response.message);
      }
      setData(response.record);
      handleSetDefaultData(response.record);
      setTimeout(() => {
        setRequest({ isLoadingRequest: false });
      }, 800);
      alert.success("Berhasil memberikan komentar");
    } catch (error) {
      setRequest({ isLoadingRequest: false });
      return alert.error(error.data);
    }
  };

  const onSubmit = (inputData) => {
    let requestData = {
      ...data,
      remark: inputData,
    };
    handleUpdateFertilization(requestData);
    console.log("requestData: ", requestData);
  };

  const handleSetDefaultData = (data) => {
    setValue(
      "usedFertilizer",
      parseInt(data?.receivedFertilizerSum) - parseInt(data?.restFertilizer)
    );
    setValue("restFertilizer", data?.restFertilizer);
    setValue("treeCount", data?.treeCount);
    setValue("notAccordingSOP", data?.notAccordingSOP);
    setValue(
      "treeCountNotFertilized",
      parseInt(data?.blockTreeCount) - parseInt(data?.treeCount)
    );
    setValue("comment", data?.remark);
    setValue("fullname", data?.userFullname);
    setValue("blockName", data?.blockName);
    setValue("tphName", data?.tphName);
    setValue("materialNames", JSON.parse(data?.materialNames)[0]);
    setValue("unitValue", JSON.parse(data?.unitValues)[0]);
    setValue(
      "materialDoses",
      `${parseToFloat(JSON.parse(data?.unitValues)[0], 2) || 0} ${
        JSON.parse(data?.units)[0]
      }`
    );
  };

  const getInitialData = async () => {
    try {
      const response = await dispatch(getFertilizationById({ id })).unwrap();
      if (!response.success) {
        setRequest({ isLoadingData: false });
        return alert.error(response.message);
      }
      setData(response.record);
      handleSetDefaultData(response.record);
      setTimeout(() => {
        setRequest({ isLoadingData: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoadingData: false });
      return alert.error(error.data);
    }
  };

  useEffect(() => {
    getInitialData();
  }, [id]);

  return request.isLoadingData ? (
    <div className="col-span-10 flex w-full flex-col items-center justify-center border border-gray-10 bg-white">
      <div className="loader-spinner-gradient h-32 w-32"></div>
      <p className="font-regular text-14 text-gray-500">
        Sedang dalam proses memuat informasi pemupukan...
      </p>
    </div>
  ) : (
    <div className="grid w-full max-w-[1247px] grid-cols-1 gap-8 overflow-y-scroll rounded-16 border border-gray-10 bg-white py-14 md:mx-auto md:mt-0">
      <div className="flex flex-row items-center justify-between px-14">
        <div className="flex flex-row items-center space-x-16">
          <TbInfoCircle className="text-24 text-gray-500" />
          <h1 className="text-left text-14 font-medium text-gray-500 md:text-16">
            Informasi
          </h1>
        </div>
        <p className="font-regular text-14 text-gray-500 md:text-16">
          {moment(data?.date).format("dddd LL")},
          {moment(data?.createdTime).format("H:mm")}
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-8 px-16 md:grid-cols-5">
          <InputHook
            register={register}
            label="Nama Mandor"
            name="fullname"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Blok"
            name="blockName"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="TPH"
            name="tphName"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Nama Material"
            name="materialNames"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Dosis Material"
            name="materialDoses"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Pupuk Diterima"
            name="usedFertilizer"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Sisa Pupuk"
            name="restFertilizer"
            disabled
            inputClassName="input-large-border bg-gray-30"
          />
          <InputHook
            register={register}
            label="Pokok Terpupuk"
            name="treeCount"
            disabled
            className=""
            inputClassName="input-large-border bg-gray-30"
          />
          {/*<InputHook*/}
          {/*  */}
          {/*  register={register}*/}
          {/*  label="Tidak sesuai SOP(Pokok)"*/}
          {/*  name="notAccordingSOP"*/}
          {/*  disabled*/}
          {/*  inputClassName="input-large-border bg-gray-30"*/}
          {/*/>*/}
          {/*<InputHook*/}
          {/*  */}
          {/*  register={register}*/}
          {/*  label="Pokok Tidak Terpupuk"*/}
          {/*  name="treeCountNotFertilized"*/}
          {/*  disabled*/}
          {/*  inputClassName="input-large-border bg-gray-30"*/}
          {/*/>*/}
          <InputHook
            optional
            register={register}
            label="Komentar"
            name="comment"
            className="col-span-2"
            inputClassName="input-large-border bg-gray-30"
            onBlur={() => onSubmit(watchRemark)}
          />
        </div>
        {/*<div className="card-footer-container mt-16 px-16">*/}
        {/*  <ButtonComponent*/}
        {/*    isLoading={request.isLoadingRequest}*/}
        {/*    value="Berikan Komentar"*/}
        {/*    className="button-transition"*/}
        {/*  />*/}
        {/*</div>*/}
      </form>
    </div>
  );
};

export default GeneralInfo;
