export const MIU_TABLE_PROPS_SX = {
  borderRadius: "24px",
  boxShadow: "0",
  fontFamily: "Inter-Regular",
};

export const MIU_TABLE_BODY_CELL_PROPS_SX = {
  borderBottom: "1px solid #ECEDEF",
  fontFamily: "Inter-Regular",
};

export const MIU_TABLE_HEAD_ROW_PROPS_SX = {
  backgroundColor: "#D7A137",
  padding: "8px",
  fontFamily: "Inter-Regular",
  color: "#FFFFFF",
};

// muiTableHeadCellProps
export const MIU_TABLE_HEAD_CELL_PROPS_SX = { borderBottom: 0 };

export const defaultTableConfig = {
  enableColumnOrdering: true,
  enablePinning: true,
  enableColumnResizing: true,
  enableStickyHeader: true,
  enableDensityToggle: false,
  enableTopToolbar: false,
  enablePagination: false,
  enableTableFooter: false,
  enableBottomToolbar: false,
  columnResizeMode: "onEnd",
};
