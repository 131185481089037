import InputHook from "../../../../components/atoms/InputHook";
import React from "react";
import usePassword from "../../../../hooks/usePassword";

const Content = ({
  register,
  errors,
  isSended,
  registerVerif,
  errorsVerif,
}) => {
  const { isPasswordShow, handlePasswordShow } = usePassword();

  return (
    <div className="mt-16 mb-24 grid w-full grid-cols-1 gap-13">
      <div className="grid grid-cols-1 gap-13">
        {isSended ? (
          <div className="grid grid-cols-1 gap-13">
            <InputHook
              register={registerVerif}
              label="Kode Verifikasi"
              name="code"
              className="mb-0 mt-0"
              inputClassName="input-large-border"
              placeholder="Masukkan kode verifikasi"
              errors={errorsVerif?.code}
            />
            <InputHook
              type={isPasswordShow ? "text" : "password"}
              register={registerVerif}
              label="Kata Sandi Baru"
              name="password"
              className="mb-0 mt-0"
              inputClassName="input-large-border"
              placeholder="Masukkan password"
              errors={errorsVerif?.password}
            />
            <InputHook
              type={isPasswordShow ? "text" : "password"}
              register={registerVerif}
              label="Konfirmasi Kata Sandi Baru"
              name="confirmPassword"
              className="mb-0 mt-0"
              inputClassName="input-large-border"
              placeholder="Masukkan konfirmasi password"
              errors={errorsVerif?.confirmPassword}
            />
            <div className="flex flex-row items-center space-x-16">
              <input
                id="decision-checkbox"
                type="checkbox"
                className="z-10 h-[20px] w-[20px] border-gray-300 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                onChange={handlePasswordShow}
              />
              <label
                htmlFor="decision-checkbox"
                className="font-regular text-14 text-gray-500"
              >
                Tampilkan password
              </label>
            </div>
          </div>
        ) : (
          <InputHook
            label="Email Terdaftar"
            register={register}
            name="email"
            className="mb-0 mt-0"
            inputClassName="input-large-border"
            errors={errors?.email}
          />
        )}
      </div>
    </div>
  );
};

export default Content;
