import { TbPencil, TbTrash } from "react-icons/tb";

import React from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { parseToFloat } from "../../../../../../../helpers/Common";
import { useAlert } from "react-alert";

const TableRow = ({ data, onDelete, onUpdate }) => {
	const alert = useAlert();

	return data.map((item, index) => {
		return (
			<tr
				className="table-header-regular cursor-pointer"
				key={index}
				// onClick={(event) => {
				// 	event.stopPropagation();
				// 	onUpdate({ item });
				// }}
			>
				<td className="table-row-regular">{item?.estateName}</td>
				<td className="table-row-regular">{item?.afdelingName}</td>
				<td className="table-row-regular">{item?.type}</td>
				<td className="table-row-regular">{item?.name}</td>
				<td className="table-row-regular">{item?.code}</td>
				<td className="table-row-regular">{item?.acquisitionYear}</td>
				{/* <td className="table-row-regular">{item?.ownership}</td> */}
				<td className="table-row-regular">
					{parseToFloat(item?.tareWeight || 0)}
				</td>
				{/* <td className="table-row-regular">
					{parseToFloat(item?.maxLoadCapacity || 0)}
				</td>
				<td className="table-row-regular">{item?.frameNo}</td>
				<td className="table-row-regular">{item?.engineNo}</td> */}
				{/* <td className="table-row-regular">
					{moment(item?.updatedTime).format("LL")}
				</td> */}
				<td className="table-row-regular">
					{moment(item?.createdTime).format("LL")}
				</td>
				<td className="table-row-regular flex flex-row items-center space-x-16">
					<button
						className="rounded-[4px] p-8"
						onClick={(event) => {
							event.stopPropagation();
							onUpdate({ item });
						}}
					>
						<TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
					</button>
					<button
						onClick={(event) => {
							event.stopPropagation();
							Swal.fire({
								title: "Hapus Kendaraan",
								text: `Kamu akan menghapus ${item?.name} , apa kamu yakin?`,
								icon: "warning",
								showCancelButton: true,
								confirmButtonColor: "#dc2626",
								confirmButtonText: "Ya, hapus!",
								cancelButtonText: "Tidak",
								reverseButtons: true,
								heightAuto: false,
							}).then((result) => {
								if (result.isConfirmed) {
									onDelete(item.id);
									alert.success(
										`${item?.name} telah berhasil dihapus`
									);
								}
							});
						}}
						className="rounded-[4px]  p-8 outline-none"
					>
						<TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
					</button>
				</td>
			</tr>
		);
	});
};

export default TableRow;
