import PropTypes from "prop-types";
import React from "react";

const ProgressBar = ({
  baseBgColor,
  bgColor,
  completed,
  width = false,
  className,
  containerStyles: propsContainerStyles,
  fillerStyles: propsFillerStyles,
  vertical,
}) => {
  const containerStyles = {
    position: "relative",
    width: 8,
    backgroundColor: baseBgColor || "#e0e0de",
    borderRadius: 999,
    ...propsContainerStyles,
  };

  const fillerStyles = {
    width: "100%",
    height: `${completed}%`,
    maxHeight: "100%",
    transition: "width 1s ease-in-out",
    backgroundColor: bgColor || "#FE9900",
    borderRadius: "inherit",
    textAlign: "right",
    ...propsFillerStyles,
  };

  return (
    <div
      style={containerStyles}
      className={`${className ? className : "w-[308px]"} md:w-[400px]`}
    >
      <div style={fillerStyles}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  bgcolor: PropTypes.string,
  completed: PropTypes.number.isRequired,
};

export default ProgressBar;
