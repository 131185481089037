import { ConfigProvider, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { getUsers, selectUser } from "../../../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import SelectHook from "../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import { useState } from "react";

const { RangePicker } = DatePicker;

const ModalFilter = ({ onFilter }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [users, setUsers] = useState([]);
  const { watch, control } = useForm();
  const watchAfdeling = watch("afdeling");
  const watchEstate = watch("estate");

  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  const handleGetUsers = async ({ name, page }) => {
    const response = await dispatch(
      getUsers({
        companyId: user?.companyId,
        fltFullname: name || undefined,
        page: page || 0,
      })
    ).unwrap();
    const records = response.records;
    let newData = [{ label: "Semua pengguna", value: undefined }];
    records.forEach((item) => {
      return newData.push({
        value: `${item?.id}`,
        label: `${item?.fullname}`,
      });
    });
    setUsers(newData);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetUsers({});
      handleGetEstates({});
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="grid grid-cols-1 gap-13">
      <SelectHook
        name="username"
        data={users}
        control={control}
        placeholder="Pilih pengguna"
        menuStyles={{ padding: 5 }}
        className="w-full"
        afterOnChange={(value) => {
          onFilter({ type: "username", value });
        }}
      />
      <SelectHook
        control={control}
        data={estates}
        name="estate"
        placeholder="Pilih kebun"
        className="w-full"
        afterOnChange={(data) => {
          onFilter({
            value: data?.value,
            type: "estate",
          });
          handleGetAfdelings({ estate: data.value });
        }}
        menuStyles={{ padding: 5 }}
      />
      {watchEstate?.value && (
        <SelectHook
          control={control}
          data={afdelings}
          name="afdeling"
          placeholder="Pilih wilayah"
          className="w-full"
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "afdeling",
            });
            handleGetBlocks({ afdelingId: data?.value });
          }}
          menuStyles={{ padding: 5 }}
        />
      )}

      {watchAfdeling?.value && (
        <SelectHook
          control={control}
          data={blocks}
          name="block"
          placeholder="Pilih blok"
          className="w-full"
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "block",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
      )}
      <Controller
        name="dateRange"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#d7a137",
                  colorTextQuaternary: "#e1e4e8",
                  fontFamily: "Inter-Regular",
                  fontSize: 16,
                  colorTextPlaceholder: "#6B6F77",
                },
              }}
            >
              <RangePicker
                placeholder={["Dari tanggal", "sampai"]}
                format="DD/MM/YYYY"
                value={value}
                onChange={(data) => {
                  onChange(data);
                  onFilter({
                    value: data,
                    type: "dateRange",
                  });
                }}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#e1e4e8",
                }}
              />
            </ConfigProvider>
          );
        }}
      />
    </div>
  );
};

export default ModalFilter;
