import * as yup from "yup";

const KOPERASI_NAMING_CONVENTION = "Perusahaan/Koperasi/Grup";

export const formSchema = yup.object().shape({
  companyName: yup
    .string()
    .required(`Nama ${KOPERASI_NAMING_CONVENTION} diperlukan`),
  phone: yup
    .string()
    .required(`Nomer telepon ${KOPERASI_NAMING_CONVENTION} diperlukan`)
    .min(7, "Nomor Handphone minimal harus 7 karakter"),
  url: yup
    .string()
    .url(`${KOPERASI_NAMING_CONVENTION} harus berupa URL yang valid https://`),
  areaHa: yup
    .string()
    .required(`Luas lahan ${KOPERASI_NAMING_CONVENTION} diperlukan`),
  province: yup
    .object()
    .shape({
      label: yup.string().required("Provinsi diperlukan (from label)"),
      value: yup.string().required("Provinsi diperlukan"),
    })
    .nullable()
    .required("Provinsi diperlukan"),
  city: yup
    .object()
    .shape({
      label: yup.string().required("Kota/Kabupaten diperlukan (from label)"),
      value: yup.string().required("Kota/Kabupaten diperlukan"),
    })
    .nullable()
    .required("Kota/Kabupaten diperlukan"),
  postalCode: yup.string("Kode pos berupa angka, contoh: 13650"),
  address: yup
    .string()
    .max(100, "Alamat tidak boleh lebih dari 100 karakter")
    .required(`Alamat ${KOPERASI_NAMING_CONVENTION} diperlukan`),
});
