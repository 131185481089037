import React from "react";
import { APP_NAME } from "../../../../../../configs/api";

const Dot = ({ icon, isActive }) => {
  return (
    <div
      className={`flex aspect-square h-42 flex-col items-center justify-center rounded-full p-8 ${
        isActive
          ? APP_NAME === "sawit-web"
            ? "bg-yellow-600 text-white"
            : "bg-primary-red-500 text-white"
          : "bg-gray-20 text-gray-500"
      }`}
    >
      {icon}
    </div>
  );
};

export default Dot;
