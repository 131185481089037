import { useForm } from "react-hook-form";
import React from "react";

import SelectHook from "../../../../../../components/atoms/SelectHook";
import useFilterDataDefaults from "../../../../../../hooks/useFilterDataDefaults";

const ModalFilter = ({ onFilter }) => {
  const { control } = useForm();
  const { users } = useFilterDataDefaults();

  return (
    <div className="grid grid-cols-1 gap-13">
      {/*<SelectHook*/}
      {/*  name="username"*/}
      {/*  data={users}*/}
      {/*  control={control}*/}
      {/*  placeholder="Pilih pengguna"*/}
      {/*  menuStyles={{ padding: 5 }}*/}
      {/*  className="w-full"*/}
      {/*  afterOnChange={(value) => {*/}
      {/*    onFilter({ type: "fltUser", value });*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  );
};

export default ModalFilter;
