import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { createBlockApi, getBlockApi, updateBlockApi } from "./blockApi";
import { calculateToastDuration } from "../../helpers/Common";
import { getBlocksApi } from "./blockApi";

export const useBlockStore = create((set, get) => ({
  blocks: [],
  block: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getBlocks: async (params = {}, silent, onSuccess) => {
    try {
      set({ isLoading: true });
      const response = await getBlocksApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Blok",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ blocks: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Blok",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getBlock: async ({ id = {}, silent, onSuccess }) => {
    try {
      set({ isLoading: true });
      const response = await getBlockApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Blok",
          description: data.message,
          placement: "bottomRight",
        });
      }
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Blok",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createBlock: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createBlockApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Block",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Block`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Block",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateBlock: async ({ id, body, silent, onSuccess }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateBlockApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Update Blok",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil update blok`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Update Blok",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
