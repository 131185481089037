import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import { useCompanyStore } from "../../../../../api/company/useCompanyStore";
import { useImageSource } from "../../../../../hooks/useImageSource";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import LOGO_KEBUN from "../../../../../assets/icons/vertical.png";
import Navbar from "../../../../../components/atoms/Navbar";

const Heading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSnapshot = useSnapshotStore((state) => state.isSnapshot);
  const user = useUserStore((state) => state.user);
  const getCompany = useCompanyStore((state) => state.getCompany);
  const { source, setSource, setDownloadedImage } = useImageSource();
  const [isLogoReady, setIsLogoReady] = useState(false);

  useEffect(() => {
    if (user?.companyId) {
      getCompany({
        id: user?.companyId,
        silent: true,
        onSuccess: (res) => {
          if (res?.record?.photoFileId) {
            setIsLogoReady(true);
            setDownloadedImage(res?.record?.photoFileId, "main");
          }
        },
      });
    }
  }, [user?.companyId]);

  return (
    <div
      className={`flex w-full flex-col-reverse items-center justify-between space-y-16 space-y-reverse md:grid md:space-y-0 md:px-[2%] ${
        isSnapshot
          ? "md:grid-cols-[1fr,1fr,max-content]"
          : "md:grid-cols-[1fr,max-content]"
      }`}
    >
      <div className="flex flex-row items-center">
        <ButtonComponent
          value="Trading"
          className={`rounded-8 text-14 ${
            location.pathname === "/dashboard-trading"
              ? "bg-yellow-500 text-white"
              : "bg-white text-yellow-600"
          }  shadow`}
          onClick={() => navigate("/dashboard-trading")}
        />
      </div>

      {isSnapshot && (
        <div className="flex flex-row items-center justify-between space-x-8">
          <h1 className="text-24 font-bold text-gray-500">SNAPSHOT</h1>
          {isLogoReady && (
            <div className="flex flex-row items-center space-x-8 md:hidden">
              <img src={LOGO_KEBUN} className="h-[55px] w-auto" />
              <img src={source.main} className="h-[52px] w-auto" />
            </div>
          )}
        </div>
      )}

      <div className="w-full md:hidden">
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <p className="line-clamp-1">Dashboard</p>
            </div>
          }
        />
      </div>
      <div className="flex flex-row items-center space-x-24 py-8">
        {isLogoReady && (
          <div className="hidden flex-row items-center space-x-8 md:flex">
            <img src={LOGO_KEBUN} className="h-42 w-auto" />
            <img src={source.main} className="h-42 w-auto" />
          </div>
        )}

        <div className="hidden md:inline">
          <Navbar onlyUserProfile={true} />
        </div>
      </div>
    </div>
  );
};

export default Heading;
