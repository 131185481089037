import React from "react";
import Navbar from "../../components/atoms/Navbar";
import { LuMap } from "react-icons/lu";
import { MapProvider } from "./context/mapContext";
import Content from "./Content";

const Header = () => (
  <Navbar
    leftContentTitle={
      <div className="flex flex-row items-center space-x-8">
        <LuMap className="text-24 text-gray-500" />
        <p className="line-clamp-1">Peta Kebun</p>
      </div>
    }
  />
);

const AdvancedMapViewer = () => {
  return (
    <MapProvider>
      <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
        <Header />
        <Content />
      </div>
    </MapProvider>
  );
};

export default AdvancedMapViewer;
