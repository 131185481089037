import "./index.css";
import "./antd.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "mapbox-gl/dist/mapbox-gl.css";

import { Provider as AlertProvider, positions } from "react-alert";

import Alert from "./components/atoms/Alert";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { createRoot } from "react-dom/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const options = {
  timeout: 2500,
  position: positions.TOP_CENTER,
};

const theme = createTheme({
  // Your theme configurations here
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider>
        <AlertProvider template={Alert} {...options}>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </DndProvider>
        </AlertProvider>
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
