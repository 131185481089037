export const headersData = [
  {
    value: "ID",
    accessorKey: "index",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Nama",
    accessorKey: "name",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Deskripsi",
    accessorKey: "description",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Tags/Label",
    accessorKey: "items",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Tanggal Dibuat",
    accessorKey: "createdTime",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Aksi",
    accessorKey: "action",
    type: "alphabet",
  },
];
