import { createEstate, updateEstate } from "../../../../features/estateSlice";
import {
	getCities,
	getProvinces,
	getSubdistricts,
	getVillages,
	selectArea,
} from "../../../../features/areaSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../atoms/ButtonComponent";
import Modal from "../../../atoms/Modal";
import React from "react";
import TphCreateContent from "../../../molecules/MasterTph/Modal/CreateContent";
import { UUID_GENERATOR_URL } from "../../../../configs/api";
import axios from "axios";
import { formSchema as formSchemaEstate } from "../../../molecules/MasterTph/Modal/CreateContent/formValidation";
import { selectModal } from "../../../../features/modalSlice";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const modalName = "masterTph";

const EstateModal = ({
	isOpen,
	toggleModal,
	type,
	onRefreshData: handleRefreshData,
}) => {
	const UPDATE_TYPE = type.toLowerCase().includes("ubah");
	const {
		register,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm({
		resolver: yupResolver(formSchemaEstate),
	});
	const watchFields = watch();

	const alert = useAlert();
	const dispatch = useDispatch();
	const modal = useSelector(selectModal);
	const user = useSelector(selectUser);
	const [isLoading, setIsLoading] = React.useState(false);

	const onSubmit = (data) => {
		setIsLoading(true);
		axios
			.get(UUID_GENERATOR_URL)
			.then((e) => {
				let requestBody = {
					...data,
					province: data?.province.label,
					regency: data?.city.label,
					district: data?.subdistrict.label,
					village: data?.village.label,
					companyId: user?.companyId,
					companyName: user?.companyName,
					areaHa: parseFloat(data?.areaHa),
				};

				if (UPDATE_TYPE) {
					requestBody = { ...requestBody, id: data.id };
					dispatch(updateEstate(requestBody))
						.unwrap()
						.then((e) => {
							setIsLoading(false);
							if (!e.success) {
								setIsLoading(false);
								return alert.error(e?.message);
							}
							alert.success(
								`Berhasil memperharui data estate ${e.record.name}`
							);
							toggleModal();
							return handleRefreshData();
						})
						.catch((err) => alert.error(err?.message));
				} else {
					requestBody = { ...requestBody, id: e.data, uuid: e.data };
					dispatch(createEstate(requestBody))
						.unwrap()
						.then((resp) => {
							setIsLoading(false);
							if (!resp.success) {
								setIsLoading(false);
								return alert.error(resp?.message);
							}
							alert.success(
								`Berhasil menambahkan estate ${resp.record.name}`
							);
							toggleModal();
							return handleRefreshData();
						})
						.catch((err) => alert.error(err?.message));
				}
			})
			.catch((err) => console.log(err));
	};

	const area = useSelector(selectArea);

	const setLocationValues = async () => {
		const location = modal[modalName]?.params?.item;
		if (!location) return;

		const selectedProvince = area.provinces?.find(
			(item) => item.value === location.province
		);
		if (!selectedProvince) return;
		setValue("province", selectedProvince);

		const regencies = await dispatch(
			getCities({ id: selectedProvince.id })
		).unwrap();
		if (!regencies) return;
		const selectedRegency = regencies.find(
			(item) => item.name === location.regency
		);
		if (!selectedRegency) return;
		setValue("city", {
			label: selectedRegency.name,
			value: selectedRegency.name,
			id: selectedRegency.id,
		});

		const subdistricts = await dispatch(
			getSubdistricts({ id: selectedRegency.id })
		).unwrap();
		if (!subdistricts) return;
		const selectedSubdistrict = subdistricts.find(
			(item) => item.name === location.district
		);
		if (!selectedSubdistrict) return;
		setValue("subdistrict", {
			label: selectedSubdistrict.name,
			value: selectedSubdistrict.name,
			id: selectedSubdistrict.id,
		});

		const villages = await dispatch(
			getVillages({ id: selectedSubdistrict.id })
		).unwrap();
		if (!villages) return;
		const selectedVillage = villages.find(
			(item) => item.name === location.village
		);
		if (!selectedVillage) return;
		setValue("village", {
			label: selectedVillage.name,
			value: selectedVillage.name,
			id: selectedVillage.id,
		});
	};

	const handleDefaultValue = () => {
		const state = modal[modalName]?.params?.item;

		clearErrors();
		if (UPDATE_TYPE) {
			let currentData = {
				...state,
			};
			reset(currentData);
			setLocationValues();
		} else {
			const nullValues = Object.keys(state || {}).reduce(
				(accumulator, key) => {
					return { ...accumulator, [key]: null };
				},
				{}
			);
			reset(nullValues);
			reset();
			reset({
				areaHa: null,
				fullname: null,
				description: null,
				province: null,
				city: null,
				subdistrict: null,
				village: null,
				name: null,
			});
		}
	};

	React.useEffect(() => {
		handleDefaultValue();
		dispatch(getProvinces());
	}, [modal, type]);

	React.useEffect(() => {
		const subscription = watch((value) => {
			if (value?.province !== undefined) {
				dispatch(getCities({ id: value?.province?.id }));
			}
			if (value?.city !== undefined) {
				dispatch(getSubdistricts({ id: value?.city?.id }));
			}
			if (value?.subdistrict !== undefined) {
				dispatch(getVillages({ id: value?.subdistrict?.id }));
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<Modal isOpen={isOpen} onClose={toggleModal} title={type}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="relative flex flex-col px-24 py-16">
					<TphCreateContent
						register={register}
						control={control}
						errors={errors}
						watchFields={watchFields}
					/>
				</div>
				<div className="modal-footer-container">
					<div className="left flex flex-row items-center space-x-16">
						<ButtonComponent
							value="Batalkan"
							size="sm"
							type="button"
							border
							className="danger-button-transition"
							onClick={() => toggleModal()}
						/>
						<ButtonComponent
							disabled={isLoading}
							isLoading={isLoading}
							value={type}
							size="sm"
							type="submit"
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default EstateModal;
