export const jilatan29 = {
  type: "FeatureCollection",
  name: "Jilatan29_2",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1415" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838998574309059, -3.91453145570818],
              [114.838521040562853, -3.914586262478962],
              [114.837833843831191, -3.914665131845445],
              [114.837916920857324, -3.914554474948307],
              [114.837998209744157, -3.91444619976349],
              [114.838079429016744, -3.914365045782386],
              [114.837989950892279, -3.914156883430873],
              [114.838044375389984, -3.913994295876681],
              [114.838026651403823, -3.913885764393236],
              [114.838720853844166, -3.913453618100005],
              [114.839072293193425, -3.913300839125132],
              [114.838998574309059, -3.91453145570818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1416" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838998574309059, -3.91453145570818],
              [114.839072293193425, -3.913300839125132],
              [114.839872261245205, -3.913745891256704],
              [114.840421240049452, -3.913783472532447],
              [114.840433714041765, -3.914366742258215],
              [114.839415340815677, -3.914483622978286],
              [114.838998574309059, -3.91453145570818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1420" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837744811132609, -3.916490319774163],
              [114.836738721699007, -3.916605785656391],
              [114.836635990270921, -3.915038365792867],
              [114.836817711096927, -3.914967489944262],
              [114.836979499950218, -3.915058313610188],
              [114.837150290019039, -3.915149160576544],
              [114.837303565200855, -3.915050112442692],
              [114.837420673229573, -3.91501425385355],
              [114.837573878757112, -3.914942326852362],
              [114.837639756058593, -3.914887478209573],
              [114.837744811132609, -3.916490319774163],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1421" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838639113173315, -3.916387682286468],
              [114.837744811132609, -3.916490319774163],
              [114.837639756058593, -3.914887478209573],
              [114.837682124832597, -3.914852202473173],
              [114.837808535637237, -3.914698841830555],
              [114.837833843831191, -3.914665131845445],
              [114.838521040562853, -3.914586262478962],
              [114.838639113173315, -3.916387682286468],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1418" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839533415516797, -3.916285043718442],
              [114.838639113173315, -3.916387682286468],
              [114.838521040562853, -3.914586262478962],
              [114.838998574309059, -3.91453145570818],
              [114.839415340815677, -3.914483622978286],
              [114.839533415516797, -3.916285043718442],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1417" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839533415516797, -3.916285043718442],
              [114.839415340815677, -3.914483622978286],
              [114.840433714041765, -3.914366742258215],
              [114.840472433303063, -3.916177272059376],
              [114.839533415516797, -3.916285043718442],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1421" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836738721699007, -3.916605785656391],
              [114.835956207960379, -3.916695591508284],
              [114.836074272687043, -3.918497008707659],
              [114.836023013192559, -3.918504702893445],
              [114.835962773222661, -3.918400647867869],
              [114.835622378204718, -3.917757892849068],
              [114.835111728181616, -3.91681636155913],
              [114.834968708869411, -3.916427251323439],
              [114.834987292246211, -3.916201288289531],
              [114.835113238872907, -3.916228736238392],
              [114.835310939255663, -3.916355815186039],
              [114.835518291496527, -3.916229786481331],
              [114.835860452177016, -3.916185471229854],
              [114.835878849443816, -3.916031831192756],
              [114.835906596287487, -3.915742608564359],
              [114.835970858672894, -3.915254590542597],
              [114.836177746098727, -3.915309369389401],
              [114.83633990639791, -3.915255546822083],
              [114.836610475371415, -3.915048317262434],
              [114.836635990270921, -3.915038365792867],
              [114.836738721699007, -3.916605785656391],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1435" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838120733771021, -3.920033407750183],
              [114.838108813649143, -3.918263513896019],
              [114.839203903694525, -3.918137833259066],
              [114.838704239945287, -3.919926552456378],
              [114.838120733771021, -3.920033407750183],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1436" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838120733771021, -3.920033407750183],
              [114.837478727206403, -3.920150975447744],
              [114.837102721768559, -3.918378979062514],
              [114.838108813649143, -3.918263513896019],
              [114.838120733771021, -3.920033407750183],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1437" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837478727206403, -3.920150975447744],
              [114.836903135184997, -3.920256380464134],
              [114.836240578519124, -3.918880512487196],
              [114.836023013192559, -3.918504702893445],
              [114.836074272687043, -3.918497008707659],
              [114.837102721768559, -3.918378979062514],
              [114.837478727206403, -3.920150975447744],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1428" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837862880042948, -3.9182917388408],
              [114.836968576213465, -3.918394374314684],
              [114.836850509394907, -3.916592956181454],
              [114.837744811132609, -3.916490319774163],
              [114.837862880042948, -3.9182917388408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1427" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836968576213465, -3.918394374314684],
              [114.836074272687043, -3.918497008707659],
              [114.835956207960379, -3.916695591508284],
              [114.836850509394907, -3.916592956181454],
              [114.836968576213465, -3.918394374314684],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1429" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838757184175293, -3.918189102286073],
              [114.837862880042948, -3.9182917388408],
              [114.837744811132609, -3.916490319774163],
              [114.838639113173315, -3.916387682286468],
              [114.838757184175293, -3.918189102286073],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1430" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839651488610301, -3.918086464650583],
              [114.839203903694525, -3.918137833259066],
              [114.838757184175293, -3.918189102286073],
              [114.838639113173315, -3.916387682286468],
              [114.839533415516797, -3.916285043718442],
              [114.839651488610301, -3.918086464650583],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { Id: 0.0, Lokasi: "Jilatan 29", No_SHM: "1431" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839651488610301, -3.918086464650583],
              [114.839533415516797, -3.916285043718442],
              [114.840472433303063, -3.916177272059376],
              [114.840477504241591, -3.916414398231463],
              [114.840590508592527, -3.917978693970244],
              [114.839696203839964, -3.918081332740458],
              [114.839651488610301, -3.918086464650583],
            ],
          ],
        ],
      },
    },
  ],
};
