import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import {
  createCollectionPointApi,
  updateCollectionPointApi,
} from "./collectionPointApi";
import { calculateToastDuration } from "../../helpers/Common";
import { getCollectionPointApi } from "./collectionPointApi";
import { createAfdelingApi } from "../afdelings/afdelingApi";

export const useCollectionPointStore = create((set, get) => ({
  blocks: [],
  block: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getCollectionPoint: async (params = {}, silent) => {
    try {
      set({ isLoading: true });
      const response = await getCollectionPointApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Blok",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ blocks: data.record, isLoading: false });
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Blok",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  createCollectionPoint: async ({ body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await createCollectionPointApi(body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update TPH",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update TPH`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update TPH",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateCollectionPoint: async (id, body, silent) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateCollectionPointApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Update Blok",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil update blok`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      if (get().block?.id === id) {
        set({ block: data.record });
      }

      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      return notification.error({
        message: "Gagal Update Blok",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
