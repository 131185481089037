import React, { useEffect, useReducer, useState } from "react";
import { deleteVehicle, getVehicles } from "../../../features/vehicleSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./partials/Filter/Filter";
import ListVehicle from "./partials/ListVehicle/ListVehicle";
import MultiModal from "./partials/MultiModal/MultiModal";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { selectUserCompanyId } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";
import ModalFilter from "./partials/ModalFilter";
import TRUCK from "../../../assets/icons/truck.svg";

const ListView = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { onShowModal, onModalUpdate, isUpdate } = useModal();
  const [isFilterBy, setIsFilterBy] = useState({
    name: undefined,
    afdeling: undefined,
  });
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const paginationLenght = Math.ceil(page?.total / 20);
  const modalName = "Vehicle";
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true, isRequest: true }
  );

  const modal = useSelector(selectModal);
  const companyId = useSelector(selectUserCompanyId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  let params = {
    pageIndex: page.current,
    fltCompany: companyId,
    fltName: isFilterBy.name,
    fltAfdeling: isFilterBy.afdeling,
  };

  const handleGetVehicleByFilter = async (params) => {
    try {
      setRequest({ isLoading: true });
      const response = await dispatch(getVehicles(params)).unwrap();

      const success = response.success;
      const message = response.message;

      if (!success) {
        setRequest({ isLoading: false });
        return alert.error(message);
      }
      const totalRecords = response?.totalRecords;
      setPage({
        current: 0,
        total: totalRecords,
      });
      setRequest({ isLoading: false });
    } catch (error) {
      setRequest({ isLoading: false });
      return alert.error("Gagal mendapatkan data kendaraan");
    }
  };

  const onFilter = ({ type, value }) => {
    switch (type) {
      case "name":
        setIsFilterBy({
          ...isFilterBy,
          name: value,
        });
        params = {
          ...params,
          fltName: value,
        };
        break;
      case "afdeling":
        setIsFilterBy({
          ...isFilterBy,
          afdeling: value,
        });
        params = {
          ...params,
          fltAfdeling: value,
        };
        break;
      default:
        break;
    }

    handleGetVehicleByFilter(params);
  };

  const onDelete = (id) => {
    dispatch(deleteVehicle({ id: id }))
      .unwrap()
      .then(() => handleRefreshData());
  };

  const onUpdate = (params) => {
    onModalUpdate(modalName, "showModalCreate", params);
  };

  const handlePageClick = async (page) => {
    try {
      setRequest({ isLoading: true });
      const response = await dispatch(
        getVehicles({
          ...params,
          pageIndex: page.selected,
        })
      ).unwrap();
      const success = response.success;
      const message = response.message;
      if (!success) {
        setRequest({ isLoading: false });
        return alert.error(message);
      }
      const totalRecords = response?.totalRecords;
      setPage({
        current: page.selected,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoading: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoading: false });
      return alert.error(error?.data);
    }
  };

  const handleRefreshData = async () => {
    try {
      setRequest({ isLoading: true });
      const response = await dispatch(
        getVehicles({
          ...params,
          pageIndex: 0,
        })
      ).unwrap();

      const success = response.success;
      const message = response.message;

      if (!success) {
        setRequest({ isLoading: false });
        return alert.error(message);
      }
      const totalRecords = response?.totalRecords;
      setPage({
        current: 0,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoading: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoading: false });
      return alert.error(error?.data);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ModalFilter
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        showModal={showModal}
        onFilter={onFilter}
      />
      <MultiModal
        modalName={modalName}
        isOpen={modal[modalName]?.showModalCreate}
        toggleModal={() => onShowModal(modalName, "showModalCreate")}
        onRefreshData={handleRefreshData}
        type={isUpdate ? "Ubah Kendaraan" : "Tambah Kendaraan"}
      />
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={TRUCK}
                alt=""
                className="hidden w-32 grayscale md:inline"
              />
              <p className="line-clamp-1">Data Kendaraan</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          modalName={modalName}
          onShowModal={onShowModal}
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListVehicle
        isLoading={request.isLoading}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
