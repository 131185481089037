import { notification } from "antd";
import { create } from "zustand";
import { getImageApi, uploadFileApi } from "../api/fileAttachmentApi";
import { devtools } from "zustand/middleware";
import { fileToBase64 } from "./imageUtils";

export const useFileAttachmentStore = create(
  devtools((set, get) => ({
    imageSource: {
      photoProfile: undefined,
      logoCompany: undefined,
    },
    setPhoto: (photos) =>
      set((state) => ({ imageSource: { ...state.imageSource, ...photos } })),
    getImage: async (id, silent) => {
      try {
        const response = await getImageApi(id);
        const url = await fileToBase64(response.data);
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        return url;
      } catch (error) {
        if (!silent) {
          return notification.error({
            message: "Gagal Mendapatkan Gambar",
            description: error.data,
          });
        }
      }
    },
    uploadFile: async (data) => {
      try {
        const formData = new FormData();
        formData.append("content", data.fileContent);
        formData.append("fileName", data.fileName);
        formData.append("userId", data?.userId);
        formData.append("mimeType", "application/octet-stream");

        const response = await uploadFileApi(formData);
        return response?.data;
      } catch (error) {
        return notification.error({
          message: "Gagal Upload File",
          description: error.data,
        });
      }
    },
    setUploadFile: async ({ data, loadingState, setStateLoading }) => {
      // Capitalize first letter
      const firstChar = loadingState.charAt(0).toUpperCase();
      const restOfString = loadingState.slice(1);
      const capitalizedStr = firstChar + restOfString;

      try {
        setStateLoading({ [`isLoading${capitalizedStr}`]: true });

        const response = await get().uploadFile(data);
        if (!response?.success) {
          setStateLoading({ [`isLoading${capitalizedStr}`]: false });
          return notification.error({
            message: "Gagal Upload File",
            description: response?.message,
            placement: "bottomRight",
          });
        }

        setStateLoading({ [`isLoading${capitalizedStr}`]: false });
        return response?.record;
      } catch (error) {
        setStateLoading({ [`isLoading${capitalizedStr}`]: false });
        return notification.error({
          message: "Gagal Upload File",
          description: error?.data,
          placement: "bottomRight",
        });
      }
    },
  }))
);
