import React, { useEffect, useState } from "react";
import ModalContainer from "../../../../../../components/atoms/Modal";

import { useForm } from "react-hook-form";
import Content from "./partials/Content/Content.jsx";
import { isEmptyObject } from "../../../../../../helpers/Common";
import { create } from "zustand";
import { useDimensionalTagStore } from "../../../../../../api/dimensionalTag/useDimensionalTagStore";
import { useUserStore } from "../../../../../MasterWorkerPage/hooks/userStore";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { formSchema } from "./schema/formSchema";

export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data, isUpdate }) =>
    set((state) => ({
      modal: {
        ...state.modal,
        isOpen: !state.modal.isOpen,
        isUpdate: isUpdate || false,
        data: data || {},
      },
    })),
}));

const Modal = ({ onRefreshData }) => {
  const [isCreateDimensionalTag, setIsCreateDimensionalTag] = useState(false);
  const [modal, isOpen, isUpdate, isLoading, toggleModal] = useModalStore(
    (state) => [
      state.modal,
      state.modal.isOpen,
      state.modal.isUpdate,
      state.modal.isLoading,
      state.toggleModal,
    ]
  );
  const [createDimensionalTag, updateDimensionalTag] = useDimensionalTagStore(
    (state) => [state.createDimensionalTag, state.updateDimensionalTag]
  );
  const [user] = useUserStore((state) => [state.user]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = (inputData) => {
    if (isUpdate) {
      const body = {
        ...modal?.data,
        ...inputData,
        items: inputData?.newTags?.map((item) => item?.value),
      };
      updateDimensionalTag({
        id: body.id,
        body,
        onSuccess: () => {
          onRefreshData();
          toggleModal({ data: {}, isUpdate: false });
          clearGroupTagForm();
        },
      });
    } else {
      const body = {
        companyId: user?.companyId,
        companyName: user?.adhocEstateName,
        name: inputData?.name,
        description: inputData?.description,
        itemPrefix: inputData?.itemPrefix,
        items: inputData?.newTags?.map((item) => item?.value),
      };
      createDimensionalTag({
        body,
        onSuccess: () => {
          onRefreshData();
          toggleModal({ data: {} });
          clearGroupTagForm();
        },
      });
    }
  };

  const clearGroupTagForm = () => {
    setValue("name", null);
    setValue("description", null);
    setValue("itemPrefix", null);
    setValue("newTags", null);
  };

  useEffect(() => {
    clearGroupTagForm();

    if (!isEmptyObject(modal?.data)) {
      setValue(
        "newTags",
        modal?.data?.items?.map((item) => ({
          label: item,
          value: item,
        }))
      );
      setValue("name", modal?.data?.name);
      setValue("description", modal?.data?.description);
      setValue("itemPrefix", modal?.data?.itemPrefix);
    }
  }, [isOpen, modal?.data, isUpdate]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ data: {}, isUpdate: false })}
      title={`${isUpdate ? "Ubah" : "Tambah"} Tag`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            watch={watch}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ data: {}, isUpdate: false })}
          type={isCreateDimensionalTag ? "Buat Grup Tag" : "Simpan Tag"}
        />
      </form>
    </ModalContainer>
  );
};

export default Modal;
