import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { REGISTER_URL } from "../configs/api";
import axios from "axios";
import moment from "moment";

const initialState = {
	keycloak: null,
	authenticated: false,
	tokens: {},
	isLoading: false,
	isError: false,
	errors: null,
};

export const onRegister = createAsyncThunk(
	"authentication/register",
	async (data, { rejectWithValue }) => {
		try {
			const response = await axios.post(REGISTER_URL, data, {
				headers: {
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const authSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		handleKeycloackAuth: (state, action) => {
			const payload = action.payload;
			state.keycloak = payload.keycloak;
			state.authenticated = payload.auth;
			state.tokens = payload.tokens;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(onRegister.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(onRegister.fulfilled, (state, action) => {
				state.isLoading = false;
			})
			.addCase(onRegister.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			});
	},
});

export const { handleKeycloackAuth } = authSlice.actions;
export const selectAuthenticated = (state) =>
	state.authentication.authenticated;
export const selectKeycloak = (state) => state.authentication.keycloak;
export const selectStatuses = (state) => ({
	isLoading: state.authentication.isLoading,
	isError: state.authentication.isError,
	errors: state.authentication.errors,
});

export default authSlice.reducer;
