export const data = {
  type: "FeatureCollection",
  name: "Batalang_Kepemilikan_125_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Woko Wahtoto",
        No_SHM: 287.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "M12",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Woko Wahtoto",
        Nm_Pemil_2: "Woko Wahtoto",
        blockId: "1ee4d4d5-57a4-613d-b27e-33157b2c0425",
        afdelingId: "1ee4d4d3-4919-6bc4-b27e-33157b2c0425",
        afdelingName: "Wilayah 1",
        blockName: "M12",
        estateId: "1ee4d4ce-ca7f-64c9-b27e-33157b2c0425",
        estateName: "Katingan 1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936106382071941, -3.904622484432493],
              [114.937006544883303, -3.904625028794703],
              [114.93700147823688, -3.906433224943779],
              [114.93610131350222, -3.906430679550602],
              [114.936103848045178, -3.905526582002084],
              [114.936104319924482, -3.905358237458092],
              [114.936106382071941, -3.904622484432493],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Narjito",
        No_SHM: 285.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "M14",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Narjito",
        Nm_Pemil_2: "M. Bahrun N",
        afdelingId: "1ee4d4d3-4919-6bc4-b27e-33157b2c0425",
        afdelingName: "Wilayah 1",
        blockName: "M14",
        estateId: "1ee4d4ce-ca7f-64c9-b27e-33157b2c0425",
        estateName: "Katingan 1",
        blockId: "1ee4d4d6-f1b7-650a-b27e-33157b2c0425",
        plantedYear: 2015,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937006544883303, -3.904625028794703],
              [114.937906708210591, -3.904627572191286],
              [114.937901643487422, -3.906435769370895],
              [114.93700147823688, -3.906433224943779],
              [114.937006544883303, -3.904625028794703],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Istiana",
        No_SHM: 278.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "7",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Istiana",
        Nm_Pemil_2: "Istiana",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937906708210591, -3.904627572191286],
              [114.938806872053476, -3.904630114622236],
              [114.938801809253633, -3.906438312831914],
              [114.937901643487422, -3.906435769370895],
              [114.937906708210591, -3.904627572191286],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yudi W",
        No_SHM: 260.0,
        Nama_SHM: "Sujiono",
        No_Kav: "8",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yudi W",
        Nm_Pemil_2: "Yudi Waningsih",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938806872053476, -3.904630114622236],
              [114.939707036411846, -3.904632656087496],
              [114.9397019755353, -3.906440855326846],
              [114.938801809253633, -3.906438312831914],
              [114.938806872053476, -3.904630114622236],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sagenah",
        No_SHM: 294.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "9",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sagenah",
        Nm_Pemil_2: "Yani Septiani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.939707036411846, -3.904632656087496],
              [114.940523059459224, -3.904634959158028],
              [114.940607201285431, -3.904635196587185],
              [114.940602142332182, -3.90644339685569],
              [114.9397019755353, -3.906440855326846],
              [114.939707036411846, -3.904632656087496],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ganis Dianto",
        No_SHM: 305.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "10",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ganis Dianto",
        Nm_Pemil_2: "Ganis Dianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940607201285431, -3.904635196587185],
              [114.941507366674017, -3.904637736121217],
              [114.941502309644051, -3.906445937418445],
              [114.940602142332182, -3.90644339685569],
              [114.940607201285431, -3.904635196587185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fatmawati",
        No_SHM: 280.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "11",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fatmawati",
        Nm_Pemil_2: "Fatmawati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.941507366674017, -3.904637736121217],
              [114.94240753257732, -3.904640274689602],
              [114.942402477470722, -3.906448477015104],
              [114.941502309644051, -3.906445937418445],
              [114.941507366674017, -3.904637736121217],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yani S",
        No_SHM: 297.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "12",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yani S",
        Nm_Pemil_2: "Sugenah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94240753257732, -3.904640274689602],
              [114.943307698995227, -3.90464281229234],
              [114.94330264581194, -3.906451015645668],
              [114.942402477470722, -3.906448477015104],
              [114.94240753257732, -3.904640274689602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hamdan F",
        No_SHM: 283.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "13",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hamdan F",
        Nm_Pemil_2: "Hamdan Fuadi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.943307698995227, -3.90464281229234],
              [114.944207865927424, -3.904645348929427],
              [114.944202814667477, -3.906453553310131],
              [114.94330264581194, -3.906451015645668],
              [114.943307698995227, -3.90464281229234],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Imam S",
        No_SHM: 293.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "14",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Imam S",
        Nm_Pemil_2: "Wiwik Widya H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.944207865927453, -3.904645348929427],
              [114.945108033373756, -3.904647884600859],
              [114.945102984037149, -3.906456090008509],
              [114.944202814667506, -3.906453553310131],
              [114.944207865927453, -3.904645348929427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ismugiono",
        No_SHM: 265.0,
        Nama_SHM: "Adrianto",
        No_Kav: "1",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ismugiono",
        Nm_Pemil_2: "Aris Munandar",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.943312750121251, -3.902834608854711],
              [114.944212915131018, -3.902837144464426],
              [114.944207865927453, -3.904645348929427],
              [114.943307698995227, -3.90464281229234],
              [114.943312750121251, -3.902834608854711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Haris W.W",
        No_SHM: 255.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "2",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Haris W.W",
        Nm_Pemil_2: "Haris Wahyu W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.944212915131018, -3.902837144464426],
              [114.945113080654863, -3.902839679108934],
              [114.945108033373756, -3.904647884600859],
              [114.944207865927453, -3.904645348929427],
              [114.944212915131018, -3.902837144464426],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "I Gede A.S",
        No_SHM: 251.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "3",
        Luas: 2.0023,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "I Gede A.S",
        Nm_Pemil_2: "I Gede A. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.945113080654892, -3.902839679108934],
              [114.945113086258417, -3.902837671216974],
              [114.94601325229398, -3.902840204895135],
              [114.94600820119733, -3.90465046824308],
              [114.945108033237148, -3.904647933537278],
              [114.945108033373756, -3.904647884600859],
              [114.945113080654892, -3.902839679108934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Bahrun N",
        No_SHM: 292.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "4",
        Luas: 2.1898,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "M. Bahrun N",
        Nm_Pemil_2: "M. Bahrun N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94601325229398, -3.902840204895135],
              [114.946321911747944, -3.902841073449746],
              [114.946377343164443, -3.902945860908312],
              [114.947420731878324, -3.903448017775206],
              [114.946763271804855, -3.904648872641166],
              [114.946761237714014, -3.904652587911138],
              [114.94600820119733, -3.90465046824308],
              [114.94601325229398, -3.902840204895135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Dili A. Timor",
        No_SHM: 300.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "15",
        Luas: 1.511,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.236,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.946008201197344, -3.90465046824308],
              [114.946005727113246, -3.905536886442188],
              [114.946002371627628, -3.905540442606513],
              [114.945141203716872, -3.906453111690857],
              [114.945102878911754, -3.906493728376477],
              [114.945102984037121, -3.906456090008509],
              [114.945108033237148, -3.904647933537278],
              [114.946008201197344, -3.90465046824308],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sri Endah A",
        No_SHM: 276.0,
        Nama_SHM: "Andrianto",
        No_Kav: "16",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sri Endah A",
        Nm_Pemil_2: "Wiwik Widya H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934300985581515, -3.906425585866054],
              [114.935201149283714, -3.906428133191348],
              [114.935196076725319, -3.908236327193725],
              [114.934295911098985, -3.908233778836512],
              [114.934300985581515, -3.906425585866054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Inas T.F",
        No_SHM: 272.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "17",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935201149283714, -3.906428133191348],
              [114.936101313502235, -3.906430679550602],
              [114.936096242868004, -3.908238874584399],
              [114.93519607672529, -3.908236327193725],
              [114.935201149283714, -3.906428133191348],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Salmah",
        No_SHM: 257.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "18",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Salmah",
        Nm_Pemil_2: "Salmah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936101313502235, -3.906430679550602],
              [114.93700147823688, -3.906433224943779],
              [114.936996409526827, -3.908241421008548],
              [114.936096242868004, -3.908238874584399],
              [114.936101313502235, -3.906430679550602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 253.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "19",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93700147823688, -3.906433224943779],
              [114.937901643487422, -3.906435769370895],
              [114.93789657670159, -3.90824396646617],
              [114.936996409526827, -3.908241421008548],
              [114.937001093963943, -3.906570335087737],
              [114.93700147823688, -3.906433224943779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sari Sg",
        No_SHM: 274.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "20",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sari Sg",
        Nm_Pemil_2: "Sari Sg",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937901643487422, -3.906435769370895],
              [114.938801809253661, -3.906438312831914],
              [114.938796744392008, -3.908246510957278],
              [114.93789657670159, -3.90824396646617],
              [114.937901643487422, -3.906435769370895],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang S",
        No_SHM: 298.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "21",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Bambang S",
        Nm_Pemil_2: "Umi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938801809253661, -3.906438312831914],
              [114.9397019755353, -3.906440855326846],
              [114.939696912597853, -3.908249054481837],
              [114.938796744392008, -3.908246510957278],
              [114.938801809253661, -3.906438312831914],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Salmah",
        No_SHM: 281.0,
        Nama_SHM: "Adrianto",
        No_Kav: "22",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Salmah",
        Nm_Pemil_2: "Salmah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.9397019755353, -3.906440855326846],
              [114.940602142332182, -3.90644339685569],
              [114.940597081318913, -3.908251597039857],
              [114.939696912597853, -3.908249054481837],
              [114.9397019755353, -3.906440855326846],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nadan",
        No_SHM: 307.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "23",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Nadan",
        Nm_Pemil_2: "Nadan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940602142332182, -3.90644339685569],
              [114.941502309644108, -3.906445937418445],
              [114.941497250555059, -3.908254138631338],
              [114.940597081318913, -3.908251597039857],
              [114.940602142332182, -3.90644339685569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Umi R. I.S",
        No_SHM: 250.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "24",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Umi R. I.S",
        Nm_Pemil_2: "Umi Rahmah I. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.941502309644079, -3.906445937418445],
              [114.942402477470722, -3.906448477015104],
              [114.942397420305909, -3.908256679256278],
              [114.941497250555059, -3.908254138631338],
              [114.941502309644079, -3.906445937418445],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sa'adah D",
        No_SHM: 271.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "25",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sa'adah D",
        Nm_Pemil_2: "Sa'adah Doroni",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.942402477470722, -3.906448477015104],
              [114.943302645811968, -3.906451015645668],
              [114.943297590571362, -3.908259218914672],
              [114.942397420305909, -3.908256679256278],
              [114.942402477470722, -3.906448477015104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Didik Y",
        No_SHM: 299.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "26",
        Luas: 2.1929,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.205,
        Grup: null,
        Nm_Pemil_1: "Didik Y",
        Nm_Pemil_2: "Didik Yulianto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.94343895797526, -3.908257146774326],
              [114.943436632676139, -3.908259611109097],
              [114.943297590571362, -3.908259218914672],
              [114.943302645811968, -3.906451015645668],
              [114.944202814667477, -3.906453553310147],
              [114.945102984037121, -3.906456090008509],
              [114.945102878911754, -3.906493728376477],
              [114.944200028604044, -3.90745056823147],
              [114.94343895797526, -3.908257146774326],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Farhat",
        No_SHM: 258.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "27",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Farhat",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934295911098985, -3.908233778836512],
              [114.93519607672529, -3.908236327193725],
              [114.935191002101519, -3.910044521111665],
              [114.934290834550112, -3.910041971722595],
              [114.934295911098985, -3.908233778836512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Melly M",
        No_SHM: 301.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "28",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93519607672529, -3.908236327193725],
              [114.936096242868004, -3.908238874584399],
              [114.936091170169277, -3.910047069533764],
              [114.935191002101519, -3.910044521111665],
              [114.93519607672529, -3.908236327193725],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sumardino",
        No_SHM: 270.0,
        Nama_SHM: "Andrianto",
        No_Kav: "29",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sumardino",
        Nm_Pemil_2: "Sumardino",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936096242868032, -3.9082388745844],
              [114.936996409526856, -3.908241421008548],
              [114.936991338753202, -3.91004961698889],
              [114.936091170169306, -3.910047069533764],
              [114.936096242868032, -3.9082388745844],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ina A",
        No_SHM: 284.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "30",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Ina A",
        Nm_Pemil_2: "Ina Agustina",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936996409526856, -3.908241421008548],
              [114.93789657670159, -3.90824396646617],
              [114.937891507853038, -3.910052163477038],
              [114.936991338753202, -3.91004961698889],
              [114.936996409526856, -3.908241421008548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "I Gede A. S",
        No_SHM: 290.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "31",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "I Gede A. S",
        Nm_Pemil_2: "I Gede A. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93789657670159, -3.90824396646617],
              [114.938796744392008, -3.908246510957261],
              [114.938791677468558, -3.910054708998208],
              [114.937891507853038, -3.910052163477038],
              [114.93789657670159, -3.90824396646617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Alfian",
        No_SHM: 302.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "32",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Alfian",
        Nm_Pemil_2: "Alfian",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938796744392008, -3.908246510957261],
              [114.939696912597867, -3.90824905448182],
              [114.939691847599534, -3.910057253552396],
              [114.938791677468558, -3.910054708998208],
              [114.938796744392008, -3.908246510957261],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sari Sg",
        No_SHM: 275.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "33",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sari Sg",
        Nm_Pemil_2: "Sari Sg",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.939696912597853, -3.90824905448182],
              [114.940597081318941, -3.908251597039841],
              [114.940592018245667, -3.910059797139616],
              [114.939691847599477, -3.910057253552412],
              [114.939696912597853, -3.90824905448182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Austin M",
        No_SHM: 259.0,
        Nama_SHM: "Sujiono",
        No_Kav: "34",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Austin M",
        Nm_Pemil_2: "Austin Murphiati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.940597081318941, -3.908251597039841],
              [114.941497250555031, -3.908254138631322],
              [114.941492189406887, -3.91006233975983],
              [114.940592018245667, -3.910059797139616],
              [114.940597081318941, -3.908251597039841],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hari S",
        No_SHM: 279.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "37",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hari S",
        Nm_Pemil_2: "Bela Aris. ST",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930690169512502, -3.910031764496658],
              [114.93159033499623, -3.910034317753579],
              [114.931585250603163, -3.911842507456871],
              [114.930685083193495, -3.911839953166278],
              [114.930690169512502, -3.910031764496658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hadi M",
        No_SHM: 295.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "38",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hadi M",
        Nm_Pemil_2: "Hadi Mulyanto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93159033499623, -3.910034317753579],
              [114.932490500997233, -3.91003687004354],
              [114.932485418530106, -3.911845060780058],
              [114.931585250603163, -3.911842507456871],
              [114.93159033499623, -3.910034317753579],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Haris W. W",
        No_SHM: 288.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "39",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Haris W. W",
        Nm_Pemil_2: "Haris Wahyu W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932490500997233, -3.91003687004354],
              [114.933390667515269, -3.910039421366559],
              [114.933385586974111, -3.91184761313585],
              [114.932485418530106, -3.911845060780058],
              [114.932490500997233, -3.91003687004354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Lou Sari",
        No_SHM: 269.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "40",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Lou Sari",
        Nm_Pemil_2: "Louh Sari",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933390667515269, -3.910039421366559],
              [114.934290834550112, -3.910041971722595],
              [114.934285755934923, -3.911850164524214],
              [114.933385586974111, -3.91184761313585],
              [114.933390667515269, -3.910039421366559],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Amrozi",
        No_SHM: 306.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "41",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Amrozi",
        Nm_Pemil_2: "Amrozi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934290834550112, -3.910041971722595],
              [114.935191002101519, -3.910044521111665],
              [114.935185925412299, -3.911852714945161],
              [114.934285755934923, -3.911850164524214],
              [114.934290834550112, -3.910041971722595],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 267.0,
        Nama_SHM: "Sujiono",
        No_Kav: "42",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935191002101519, -3.910044521111665],
              [114.936091170169306, -3.910047069533764],
              [114.936086095406054, -3.911855264398689],
              [114.935185925412299, -3.911852714945161],
              [114.935191002101519, -3.910044521111665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fatmawati",
        No_SHM: 264.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "43",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fatmawati",
        Nm_Pemil_2: "Fatmawati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936091170169306, -3.910047069533764],
              [114.936991338753202, -3.91004961698889],
              [114.936986265915934, -3.911857812884797],
              [114.936086095406054, -3.911855264398689],
              [114.936091170169306, -3.910047069533764],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Lili F",
        No_SHM: 303.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "44",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Lili F",
        Nm_Pemil_2: "Lily Febriyani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936991338753202, -3.910049616988906],
              [114.937891507853038, -3.910052163477055],
              [114.937886436941739, -3.911860360403478],
              [114.93698626591592, -3.911857812884797],
              [114.936991338753202, -3.910049616988906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sagenah",
        No_SHM: 310.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "45",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Sagenah",
        Nm_Pemil_2: "Sugenah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937891507853038, -3.910052163477055],
              [114.938791677468529, -3.910054708998226],
              [114.938786608483227, -3.911862906954732],
              [114.937886436941739, -3.911860360403478],
              [114.937891507853038, -3.910052163477055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Fauzan",
        No_SHM: 304.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "46",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "M. Fauzan",
        Nm_Pemil_2: "M. Fauzan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938791677468529, -3.910054708998226],
              [114.939691847599505, -3.910057253552412],
              [114.939686780540185, -3.911865452538554],
              [114.938786608483227, -3.911862906954732],
              [114.938791677468529, -3.910054708998226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wasito P",
        No_SHM: 273.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "47",
        Luas: 1.8774,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.872,
        Grup: null,
        Nm_Pemil_1: "Wasito P",
        Nm_Pemil_2: "Wasito Pramono",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.929635346292613, -3.911839500691979],
              [114.9296372081144, -3.911836978524049],
              [114.929784916301301, -3.911837397908264],
              [114.930685083193495, -3.911839953166278],
              [114.930680815580601, -3.913356523965099],
              [114.930680292353301, -3.913356456959917],
              [114.930500313937713, -3.913333408671427],
              [114.929781701773635, -3.912979351549426],
              [114.929781044552271, -3.91297902773896],
              [114.929303558176443, -3.912288966126189],
              [114.929635346292613, -3.911839500691979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 266.0,
        Nama_SHM: "Sujiono",
        No_Kav: "48",
        Luas: 1.7398,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.737,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "Aryudi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930685083193495, -3.911839953166278],
              [114.931585250603192, -3.911842507456871],
              [114.931580667586587, -3.913471759714271],
              [114.931580184880929, -3.913471697898852],
              [114.930680815580601, -3.913356523965099],
              [114.930685083193495, -3.911839953166278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Harmadi B.S",
        No_SHM: 309.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "49",
        Luas: 1.9087,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.906,
        Grup: null,
        Nm_Pemil_1: "Harmadi B.S",
        Nm_Pemil_2: "Nagang Kita S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931585250603163, -3.911842507456871],
              [114.932485418530106, -3.911845060780058],
              [114.93248033399486, -3.913653251432044],
              [114.93221503646636, -3.913652498714355],
              [114.93194014210188, -3.91351779403939],
              [114.931580667586587, -3.913471759714271],
              [114.931580184880929, -3.913471697898852],
              [114.931585250603163, -3.911842507456871],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Riwo C",
        No_SHM: 261.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "50",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Riwo C",
        Nm_Pemil_2: "Riwo C",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932485418530106, -3.911845060780058],
              [114.933385586974111, -3.911847613135816],
              [114.933380504365701, -3.913655804820601],
              [114.93248033399486, -3.913653251432044],
              [114.932485418530106, -3.911845060780058],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yulianor H",
        No_SHM: 262.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "51",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Yulianor H",
        Nm_Pemil_2: "Yulianor H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933385586974111, -3.911847613135816],
              [114.934285755934894, -3.911850164524197],
              [114.934280675253362, -3.913658357241296],
              [114.933380504365701, -3.913655804820601],
              [114.933385586974111, -3.911847613135816],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Umi R. I. S",
        No_SHM: 252.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "52",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Umi R. I. S",
        Nm_Pemil_2: "Umi rahmah I. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934285755934894, -3.911850164524197],
              [114.935185925412299, -3.911852714945144],
              [114.935180846657602, -3.913660908694127],
              [114.934280675253362, -3.913658357241296],
              [114.934285755934894, -3.911850164524197],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Inas T.F",
        No_SHM: 308.0,
        Nama_SHM: "Andrianto",
        No_Kav: "53",
        Luas: 2.0,
        Lokasi: "Batalang 125 (Mujah*",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Mujahidin",
        Nm_Pemil_2: "Mujahidin",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935185925412299, -3.911852714945144],
              [114.936086095406026, -3.911855264398673],
              [114.936081018578207, -3.91366345917909],
              [114.935180846657602, -3.913660908694127],
              [114.935185925412299, -3.911852714945144],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Melly M",
        No_SHM: 289.0,
        Nama_SHM: "M. Albach R",
        No_Kav: "54",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Melly M",
        Nm_Pemil_2: "Melly M",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936086095406026, -3.911855264398689],
              [114.93698626591592, -3.911857812884797],
              [114.936981191015008, -3.913666008696199],
              [114.936081018578207, -3.913663459179106],
              [114.936086095406026, -3.911855264398689],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hani H",
        No_SHM: 249.0,
        Nama_SHM: "Nor Aidil",
        No_Kav: "55",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Hani H",
        Nm_Pemil_2: "Hani Hadiyati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93698626591592, -3.911857812884797],
              [114.937886436941739, -3.911860360403478],
              [114.937881363967691, -3.913668557245417],
              [114.936981191015008, -3.913666008696199],
              [114.93698626591592, -3.911857812884797],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aryudi",
        No_SHM: 312.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "56",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Aryudi",
        Nm_Pemil_2: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937886436941739, -3.911860360403478],
              [114.938786608483227, -3.911862906954732],
              [114.938781537436043, -3.913671104826759],
              [114.937881363967691, -3.913668557245417],
              [114.937886436941739, -3.911860360403478],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fery W. J",
        No_SHM: 254.0,
        Nama_SHM: "Saiful Anwar",
        No_Kav: "57",
        Luas: 2.0,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nm_Pemil_1: "Fery W. J",
        Nm_Pemil_2: "Fery Wahyu J",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938786608483227, -3.911862906954732],
              [114.939686780540214, -3.911865452538554],
              [114.939681711419922, -3.913673651440222],
              [114.938781537436043, -3.913671104826759],
              [114.938786608483227, -3.911862906954732],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 268.0,
        Nama_SHM: "Suharmaji",
        No_Kav: "58",
        Luas: 1.4985,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.494,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "PT. ASA",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93221829968158, -3.913654097765598],
              [114.932215036466417, -3.913652498714355],
              [114.93248033399486, -3.913653251432044],
              [114.933380504365672, -3.913655804820617],
              [114.934280675253362, -3.913658357241296],
              [114.934277550892801, -3.914766307260421],
              [114.933989517540766, -3.914752443772104],
              [114.933378604083444, -3.914331661485827],
              [114.932830246673618, -3.913953965739734],
              [114.9324799709742, -3.913782322726935],
              [114.93221829968158, -3.913654097765598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hastawan H",
        No_SHM: 282.0,
        Nama_SHM: "Suhendra Irawan",
        No_Kav: "59",
        Luas: 1.2698,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.269,
        Grup: null,
        Nm_Pemil_1: "Hastawan H",
        Nm_Pemil_2: "Hastawan H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934280675253362, -3.913658357241296],
              [114.935180846657602, -3.913660908694127],
              [114.935177455625052, -3.914869347711313],
              [114.934583565345207, -3.914781036100193],
              [114.934277550892801, -3.914766307260421],
              [114.934280675253362, -3.913658357241296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 291.0,
        Nama_SHM: "Sujiono",
        No_Kav: "60",
        Luas: 1.4092,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.408,
        Grup: null,
        Nm_Pemil_1: "Kawan Melly M",
        Nm_Pemil_2: "Miely",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935180846657602, -3.913660908694127],
              [114.936081018578236, -3.913663459179106],
              [114.936077255834064, -3.915003147417441],
              [114.935177456322819, -3.91486934781509],
              [114.935180846657602, -3.913660908694127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 311.0,
        Nama_SHM: "Purnawan S.K",
        No_Kav: "61",
        Luas: 1.5433,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.543,
        Grup: null,
        Nm_Pemil_1: "Melly M",
        Nm_Pemil_2: "Eliya Nor",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936081018578236, -3.913663459179106],
              [114.936981191014979, -3.913666008696199],
              [114.936977167243924, -3.915099162741188],
              [114.936500249330251, -3.915066045952042],
              [114.936077318754926, -3.915003156773705],
              [114.936077255834064, -3.915003147417441],
              [114.936081018578236, -3.913663459179106],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 256.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "62",
        Luas: 1.6123,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.621,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "Lily F",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936981191014979, -3.913666008696199],
              [114.937881363967691, -3.913668557245417],
              [114.937877203904605, -3.915150810169413],
              [114.936977263803783, -3.915099169446188],
              [114.936977167243924, -3.915099162741188],
              [114.936981191014979, -3.913666008696199],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 296.0,
        Nama_SHM: "Erma Sari",
        No_Kav: "63",
        Luas: 1.6206,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1621.0,
        Grup: null,
        Nm_Pemil_1: "KJP-CPS",
        Nm_Pemil_2: "PT. ASA",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937881363967691, -3.913668557245417],
              [114.938781537436043, -3.913671104826759],
              [114.938777707989487, -3.915036094553834],
              [114.938516442999912, -3.91515239462141],
              [114.937877318160105, -3.915150816725645],
              [114.937877203904605, -3.915150810169413],
              [114.937881363967691, -3.913668557245417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ariani N.A",
        No_SHM: 263.0,
        Nama_SHM: "Dili Aerami. T",
        No_Kav: "64",
        Luas: 1.2297,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.18,
        Grup: null,
        Nm_Pemil_1: "Ariani N.A",
        Nm_Pemil_2: "Ariani noor A",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938781537436043, -3.913671104826759],
              [114.939681711419922, -3.913673651440222],
              [114.939679559946683, -3.914440878317622],
              [114.939678328403645, -3.914442109168996],
              [114.939526011378121, -3.914594340595801],
              [114.939102198910376, -3.914891649766241],
              [114.938777780275657, -3.915036062376218],
              [114.938777707989487, -3.915036094553834],
              [114.938781537436043, -3.913671104826759],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fery W. J",
        No_SHM: 277.0,
        Nama_SHM: "Zulkifli",
        No_Kav: "36",
        Luas: 1.2927,
        Lokasi: "Batalang 125",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.294,
        Grup: null,
        Nm_Pemil_1: "Fery W. J",
        Nm_Pemil_2: "Ferry Wahyu J",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930690169512502, -3.910031764496658],
              [114.930685083193495, -3.911839953166278],
              [114.929784916301273, -3.911837397908264],
              [114.9296372081144, -3.911836978524049],
              [114.929785482817067, -3.911636114039431],
              [114.930308180504625, -3.910928025552781],
              [114.930173944569319, -3.910032633841954],
              [114.930173594507167, -3.910030298830204],
              [114.930690169512502, -3.910031764496658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Bahrun N",
        No_SHM: 286.0,
        Nama_SHM: "Siti Masitoh",
        No_Kav: "35",
        Luas: 1.7211,
        Lokasi: "Batalang 125",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nm_Pemil_1: "M. Bahrun N",
        Nm_Pemil_2: "M. Bahrun N",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.942393676246226, -3.909364925710253],
              [114.94173670474315, -3.910061174461483],
              [114.941490476741095, -3.910060568356825],
              [114.941494888370812, -3.908252365514328],
              [114.942395058972352, -3.908254579962326],
              [114.942393676246226, -3.909364925710253],
            ],
          ],
        ],
      },
    },
  ],
};

export const dataBatalang2 = {
  type: "FeatureCollection",
  name: "Batalang_Kepemilikan_150_2",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Pardamean",
        No_SHM: 123.0,
        Nama_SHM: "Pardamean",
        No_Kav: "1",
        Luas: 2.0408,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9908,
        Grup: null,
        Nama_Baru: "Perdamean",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93325679561697, -3.890448361023077],
              [114.933436828480396, -3.890447363584438],
              [114.933445580575608, -3.890448396694032],
              [114.933441359233314, -3.891957499034305],
              [114.932541211925439, -3.891954958038793],
              [114.931920116930883, -3.891953204208642],
              [114.932102292469068, -3.891394827988904],
              [114.932409445158385, -3.890943532881984],
              [114.932544319657097, -3.890844435631086],
              [114.93271604628481, -3.890718261682714],
              [114.93325679561697, -3.890448361023077],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Arifin Z",
        No_SHM: 124.0,
        Nama_SHM: "Arifin Zais",
        No_Kav: "2",
        Luas: 1.612,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.6195,
        Grup: null,
        Nama_Baru: "Arifin Z",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933445580575608, -3.890448396694032],
              [114.934309725303905, -3.890550401115959],
              [114.934336731545741, -3.890549720991034],
              [114.934345451236752, -3.890549501394219],
              [114.934341507057908, -3.891960039067321],
              [114.933441359233314, -3.891957499034305],
              [114.933444962960493, -3.890669222222013],
              [114.933445580575608, -3.890448396694032],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "A. Kipli",
        No_SHM: 125.0,
        Nama_SHM: "Abdul Kipli",
        No_Kav: "3",
        Luas: 1.5741,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.5798,
        Grup: "Abdul Kipli",
        Nama_Baru: "A. Kipli",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934345451236752, -3.890549501394219],
              [114.935236939844359, -3.890527049651111],
              [114.935245668537249, -3.89052682981809],
              [114.935241655399025, -3.891962578137892],
              [114.934341507057908, -3.891960039067321],
              [114.934345451236752, -3.890549501394219],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bonardo P",
        No_SHM: 126.0,
        Nama_SHM: "Bonardo Posma PL. Tobing",
        No_Kav: "4",
        Luas: 1.5782,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.5836,
        Grup: null,
        Nama_Baru: "Bonardo p",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935290952356979, -3.890525689339199],
              [114.935596914629002, -3.890562605146521],
              [114.936137144657138, -3.89050599917377],
              [114.936145883712683, -3.890505083481604],
              [114.936141804256465, -3.891965116245944],
              [114.935241655399025, -3.891962578137892],
              [114.935245668537249, -3.89052682981809],
              [114.935290952356979, -3.890525689339199],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Arifin Z",
        No_SHM: 127.0,
        Nama_SHM: "Arifin Zais",
        No_Kav: "5",
        Luas: 1.6919,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.6951,
        Grup: null,
        Nama_Baru: "Arifin Z",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936145883712683, -3.890505083481604],
              [114.936524309578161, -3.890465431322346],
              [114.937037702877461, -3.890339901813274],
              [114.937046505903709, -3.890337749386783],
              [114.937041953629944, -3.891967653391561],
              [114.936141804256465, -3.891965116245944],
              [114.936145883712683, -3.890505083481604],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Arifin Z",
        No_SHM: 128.0,
        Nama_SHM: "Jati Ayu Awaliyah",
        No_Kav: "6",
        Luas: 1.0595,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.0882,
        Grup: null,
        Nama_Baru: "Arifin Z",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937449891698265, -3.891968517598795],
              [114.937449858177231, -3.891968802786958],
              [114.937041953629944, -3.891967653391561],
              [114.937046505903709, -3.890337749386783],
              [114.937659178982031, -3.890187944510036],
              [114.937449891698265, -3.891968517598795],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suwondo",
        No_SHM: 129.0,
        Nama_SHM: "Davidson Pantung",
        No_Kav: "7",
        Luas: 1.9447,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9305,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931919556705594, -3.891954921322105],
              [114.931920116930883, -3.891953204208608],
              [114.932541211925439, -3.891954958038776],
              [114.932536150138489, -3.893763149618316],
              [114.931636001430647, -3.893760606627169],
              [114.931540553449707, -3.893760336922529],
              [114.931421576161924, -3.89368957000897],
              [114.931638314881823, -3.89293459142939],
              [114.931919556705594, -3.891954921322105],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang E",
        No_SHM: 130.0,
        Nama_SHM: "David",
        No_Kav: "8",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Bambang E",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932541211925439, -3.891954958038776],
              [114.933441359233314, -3.891957499034305],
              [114.933436299363322, -3.893765691646521],
              [114.932536150138489, -3.893763149618316],
              [114.932541211925439, -3.891954958038776],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 131.0,
        Nama_SHM: "Murni",
        No_Kav: "9",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Oryza Anjani",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933441359233314, -3.891957499034305],
              [114.934341507057908, -3.891960039067321],
              [114.934336449104933, -3.893768232711849],
              [114.933436299363322, -3.893765691646521],
              [114.933441359233314, -3.891957499034305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suwondo",
        No_SHM: 132.0,
        Nama_SHM: "Davidson Pantung",
        No_Kav: "10",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934341507057908, -3.891960039067321],
              [114.935241655399025, -3.891962578137892],
              [114.935236599363051, -3.893770772814249],
              [114.934336449104933, -3.893768232711849],
              [114.934341507057908, -3.891960039067321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wasito P",
        No_SHM: 133.0,
        Nama_SHM: "David",
        No_Kav: "11",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Warsito",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935241655399025, -3.891962578137892],
              [114.936141804256465, -3.891965116245944],
              [114.93613675013745, -3.893773311953683],
              [114.935236599363051, -3.893770772814249],
              [114.935241655399025, -3.891962578137892],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suharto",
        No_SHM: 134.0,
        Nama_SHM: "Murni",
        No_Kav: "12",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suharto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936141804256465, -3.891965116245944],
              [114.937041953629944, -3.891967653391544],
              [114.937036901427959, -3.893775850130181],
              [114.93613675013745, -3.893773311953683],
              [114.936141804256465, -3.891965116245944],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aprizal C",
        No_SHM: 135.0,
        Nama_SHM: "Wene Herlena",
        No_Kav: "13",
        Luas: 0.8912,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 0.9265,
        Grup: "Aprizal C",
        Nama_Baru: "Aprizal C",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937041953629958, -3.891967653391544],
              [114.937449858177231, -3.891968802786908],
              [114.937237389726789, -3.893776415320175],
              [114.937032016034166, -3.89552364377189],
              [114.937036901427959, -3.893775850130213],
              [114.937041953629958, -3.891967653391544],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suharto",
        No_SHM: 137.0,
        Nama_SHM: "Tarmaini",
        No_Kav: "15",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931636001430647, -3.893760606627169],
              [114.932536150138489, -3.893763149618316],
              [114.932531086283674, -3.895571341113724],
              [114.931630935657921, -3.895568797089397],
              [114.931636001430647, -3.893760606627169],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suwondo",
        No_SHM: 138.0,
        Nama_SHM: "Budi Kherman",
        No_Kav: "16",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932536150138489, -3.893763149618316],
              [114.93343629936335, -3.893765691646521],
              [114.933431237426376, -3.895573884174661],
              [114.932531086283674, -3.895571341113724],
              [114.932536150138489, -3.893763149618316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 139.0,
        Nama_SHM: "Willy",
        No_Kav: "17",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93343629936335, -3.893765691646521],
              [114.934336449104933, -3.893768232711849],
              [114.934331389085827, -3.895576426272273],
              [114.933431237426376, -3.895573884174661],
              [114.93343629936335, -3.893765691646521],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suharto",
        No_SHM: 140.0,
        Nama_SHM: "Tarmaini",
        No_Kav: "18",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suharto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934336449104933, -3.893768232711849],
              [114.935236599363051, -3.893770772814249],
              [114.935231541261857, -3.895578967406507],
              [114.934331389085827, -3.895576426272273],
              [114.934336449104933, -3.893768232711849],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suwondo",
        No_SHM: 141.0,
        Nama_SHM: "Agung Kristiyono",
        No_Kav: "19",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935236599363051, -3.893770772814249],
              [114.93613675013745, -3.893773311953683],
              [114.936131693954152, -3.895581507577341],
              [114.935231541261857, -3.895578967406507],
              [114.935236599363051, -3.893770772814249],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 142.0,
        Nama_SHM: "Willy",
        No_Kav: "20",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93613675013745, -3.893773311953683],
              [114.937036901427959, -3.893775850130213],
              [114.937032016034166, -3.89552364377189],
              [114.93703184716253, -3.895584046784795],
              [114.937031842478092, -3.895585722331804],
              [114.937025448375053, -3.89558402873807],
              [114.936131693954152, -3.895581507577341],
              [114.93613675013745, -3.893773311953683],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Ekwan",
        No_SHM: 136.0,
        Nama_SHM: "M. Ekwan",
        No_Kav: "14",
        Luas: 1.4658,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.44,
        Luas_SHM: 1.4434,
        Grup: null,
        Nama_Baru: "M. Ekwan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931636001430647, -3.893760606627169],
              [114.931630935657921, -3.895568797089397],
              [114.931625867816351, -3.897376987467402],
              [114.931202764450532, -3.897375790867337],
              [114.931216055228091, -3.896753967039088],
              [114.931200636769503, -3.896458708887556],
              [114.931163769600616, -3.896048639159864],
              [114.93125117050721, -3.895567723499873],
              [114.931410123678418, -3.894693094363908],
              [114.931435817094751, -3.894483852957412],
              [114.931447277957417, -3.8942230531724],
              [114.931543683991819, -3.893762198948237],
              [114.931540553449707, -3.893760336922529],
              [114.931636001430647, -3.893760606627169],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Selamat L.B",
        No_SHM: 143.0,
        Nama_SHM: "Selamat Lumban Batu",
        No_Kav: "21",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L. B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931630935657921, -3.895568797089347],
              [114.932531086283646, -3.895571341113708],
              [114.932526020360854, -3.897379532524929],
              [114.931625867816351, -3.897376987467402],
              [114.931630935657921, -3.895568797089347],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rusliana S",
        No_SHM: 144.0,
        Nama_SHM: "Rusliana Sinurat",
        No_Kav: "22",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Rusliana S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932531086283646, -3.895571341113708],
              [114.933431237426376, -3.895573884174661],
              [114.933426173422347, -3.897382076618619],
              [114.932526020360854, -3.897379532524929],
              [114.932531086283646, -3.895571341113708],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wiwit H",
        No_SHM: 145.0,
        Nama_SHM: "Wiwit Hermanto",
        No_Kav: "23",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Wiwit H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933431237426376, -3.895573884174661],
              [114.934331389085827, -3.895576426272272],
              [114.934326327000591, -3.897384619748486],
              [114.933426173422347, -3.897382076618619],
              [114.933431237426376, -3.895573884174661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Selamat L.B",
        No_SHM: 146.0,
        Nama_SHM: "Selamat Lumban Batu",
        No_Kav: "24",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L. B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934331389085827, -3.895576426272272],
              [114.935231541261828, -3.895578967406474],
              [114.935226481095384, -3.897387161914542],
              [114.934326327000591, -3.897384619748486],
              [114.934331389085827, -3.895576426272272],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Rusliana S",
        No_SHM: 147.0,
        Nama_SHM: "Rusliana Sinurat",
        No_Kav: "25",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Rusliana S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935231541261828, -3.895578967406474],
              [114.936131693954152, -3.895581507577325],
              [114.936126635706501, -3.897389703116753],
              [114.935226481095384, -3.897387161914542],
              [114.935231541261828, -3.895578967406474],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wiwit H",
        No_SHM: 148.0,
        Nama_SHM: "Wiwit Hermanto",
        No_Kav: "26",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Wiwit H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936131693954152, -3.895581507577309],
              [114.937025448375053, -3.89558402873807],
              [114.93703184247812, -3.895585722331821],
              [114.937026790833698, -3.897392243355165],
              [114.936126635706501, -3.897389703116753],
              [114.936131693954152, -3.895581507577309],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "PT. Busra",
        No_SHM: 149.0,
        Nama_SHM: "Eni Mufaridah",
        No_Kav: "27",
        Luas: 1.8678,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.8699,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937026790833698, -3.897392243355165],
              [114.93703184247812, -3.895585722331821],
              [114.937924513825436, -3.89582216199078],
              [114.937931337449925, -3.895823969343591],
              [114.937926946476765, -3.897394782629742],
              [114.937026790833698, -3.897392243355165],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Mulyadi",
        No_SHM: 150.0,
        Nama_SHM: "Mulyadi",
        No_Kav: "28",
        Luas: 1.6071,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.6099,
        Grup: null,
        Nama_Baru: "Mulyadi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937926946476765, -3.897394782629742],
              [114.937931337449925, -3.895823969343591],
              [114.938824094442381, -3.896060430718379],
              [114.938830833402577, -3.896062215639217],
              [114.938827102635472, -3.897397320940461],
              [114.937926946476765, -3.897394782629742],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Zuriadi",
        No_SHM: 151.0,
        Nama_SHM: "Zuriadi",
        No_Kav: "29",
        Luas: 1.3464,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.3499,
        Grup: null,
        Nama_Baru: "Zuriadi",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938827102635472, -3.897397320940461],
              [114.938830833402577, -3.896062215639217],
              [114.939723676040131, -3.896298698749393],
              [114.93973033033582, -3.896300461238455],
              [114.93972725930962, -3.89739985828734],
              [114.938827102635472, -3.897397320940461],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sulistiya B",
        No_SHM: 152.0,
        Nama_SHM: "Sulistiya Budini",
        No_Kav: "30",
        Luas: 0.8615,
        Lokasi: "Batalang 150",
        Luas_Inkes: 0.87,
        Luas_SHM: 0.8748,
        Grup: null,
        Nama_Baru: "M. Ekwan",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93972725930962, -3.89739985828734],
              [114.93973033033582, -3.896300461238455],
              [114.940470329510958, -3.896496460686046],
              [114.940414868098117, -3.897093034829048],
              [114.940358473877069, -3.897400292593031],
              [114.940358227257065, -3.897401636273992],
              [114.93972725930962, -3.89739985828734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aprizal C",
        No_SHM: 153.0,
        Nama_SHM: "Bambang Aryanto",
        No_Kav: "31",
        Luas: 0.9788,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 0.9656,
        Grup: "Aprizal C",
        Nama_Baru: "Aprizal C",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931202722275771, -3.897377764040975],
              [114.931202764450532, -3.897375790867337],
              [114.931625867816365, -3.897376987467385],
              [114.93162079790595, -3.899185177761227],
              [114.931120679768867, -3.899183762757659],
              [114.931165234015268, -3.899131627895171],
              [114.931202722275771, -3.897377764040975],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Eko D",
        No_SHM: 154.0,
        Nama_SHM: "Eko Daryanto",
        No_Kav: "32",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L. B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931625867816365, -3.897376987467385],
              [114.932526020360854, -3.897379532524929],
              [114.932520952370098, -3.899187723851963],
              [114.93162079790595, -3.899185177761227],
              [114.931625867816365, -3.897376987467385],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ai Sugiarto",
        No_SHM: 155.0,
        Nama_SHM: "Ai Sugiarto",
        No_Kav: "33",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L. B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932526020360854, -3.897379532524929],
              [114.933426173422347, -3.897382076618636],
              [114.933421107351265, -3.899190268978411],
              [114.932520952370098, -3.899187723851963],
              [114.932526020360854, -3.897379532524929],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Mawarhdah R",
        No_SHM: 156.0,
        Nama_SHM: "Mawarhdah Rahmah",
        No_Kav: "34",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Mawarhdah R",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933426173422347, -3.897382076618636],
              [114.934326327000591, -3.897384619748519],
              [114.934321262849195, -3.899192813140588],
              [114.933421107351265, -3.899190268978411],
              [114.933426173422347, -3.897382076618636],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Eko D",
        No_SHM: 157.0,
        Nama_SHM: "Eko Daryanto",
        No_Kav: "35",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L. B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934326327000591, -3.897384619748519],
              [114.935226481095384, -3.897387161914577],
              [114.935221418863662, -3.899195356338489],
              [114.934321262849195, -3.899192813140588],
              [114.934326327000591, -3.897384619748519],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ai Sugiarto",
        No_SHM: 158.0,
        Nama_SHM: "Ai Sugiarto",
        No_Kav: "36",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: "Selamat Lumban Batu",
        Nama_Baru: "Selamat L.B",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935226481095384, -3.897387161914577],
              [114.936126635706501, -3.897389703116805],
              [114.936121575394452, -3.899197898572111],
              [114.935221418863662, -3.899195356338489],
              [114.935226481095384, -3.897387161914577],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "H. Isra",
        No_SHM: 159.0,
        Nama_SHM: "H. Isra",
        No_Kav: "37",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "H. Isra",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936126635706501, -3.897389703116805],
              [114.937026790833727, -3.897392243355165],
              [114.937021732441366, -3.899200439841453],
              [114.936121575394452, -3.899197898572111],
              [114.936126635706501, -3.897389703116805],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "P. Endah",
        No_SHM: 160.0,
        Nama_SHM: "Pruwitasari Endah",
        No_Kav: "38",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "P. Endah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937026790833727, -3.897392243355165],
              [114.937926946476765, -3.897394782629742],
              [114.937921890004105, -3.89920298014651],
              [114.937021732441366, -3.899200439841453],
              [114.937026790833727, -3.897392243355165],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "H. Sulaeman Y",
        No_SHM: 161.0,
        Nama_SHM: "H. Sulaeman Yusran",
        No_Kav: "39",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937926946476765, -3.897394782629742],
              [114.938827102635472, -3.897397320940461],
              [114.938822048082528, -3.899205519487281],
              [114.937921890004105, -3.89920298014651],
              [114.937926946476765, -3.897394782629742],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurhadiyat",
        No_SHM: 162.0,
        Nama_SHM: "Diah Ika Handayani",
        No_Kav: "40",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Nurhadiyat",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938827102635472, -3.897397320940461],
              [114.93972725930962, -3.89739985828734],
              [114.939722206676379, -3.89920805786376],
              [114.938822048082528, -3.899205519487281],
              [114.938827102635472, -3.897397320940461],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Aprizal C",
        No_SHM: 163.0,
        Nama_SHM: "Faisal Wahyudi",
        No_Kav: "41",
        Luas: 1.1031,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.1217,
        Grup: "Aprizal C",
        Nama_Baru: "Aprizal C",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93972725930962, -3.89739985828734],
              [114.940358227257065, -3.897401636274025],
              [114.940250661133959, -3.897987697006463],
              [114.940149420482129, -3.898900595744163],
              [114.940102300082685, -3.899207876145736],
              [114.940102107982142, -3.899209128865786],
              [114.939722206676379, -3.89920805786376],
              [114.93972725930962, -3.89739985828734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Gt. Zakatiah",
        No_SHM: 164.0,
        Nama_SHM: "Gusti Zakatiah",
        No_Kav: "42",
        Luas: 1.3626,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.353,
        Grup: null,
        Nama_Baru: "Gt. Zakatiah",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931118973079805, -3.899185759828514],
              [114.931120679768867, -3.899183762757659],
              [114.931620797905978, -3.899185177761227],
              [114.93161572592669, -3.90099336797082],
              [114.930890707399442, -3.900991315722534],
              [114.931118973079805, -3.899185759828514],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Poppy D.W",
        No_SHM: 165.0,
        Nama_SHM: "Hilmi Hasan",
        No_Kav: "43",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9908,
        Grup: null,
        Nama_Baru: "Poppy D. W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931620797905978, -3.899185177761227],
              [114.932520952370126, -3.899187723851963],
              [114.932515882311392, -3.900995915094732],
              [114.93161572592669, -3.90099336797082],
              [114.931620797905978, -3.899185177761227],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Hadi M",
        No_SHM: 166.0,
        Nama_SHM: "Siti Aisyah",
        No_Kav: "44",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Hadi M",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932520952370126, -3.899187723851963],
              [114.933421107351293, -3.899190268978411],
              [114.933416039213128, -3.900998461253927],
              [114.932515882311392, -3.900995915094732],
              [114.932520952370126, -3.899187723851963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wasito P",
        No_SHM: 167.0,
        Nama_SHM: "Ahmad Noor",
        No_Kav: "45",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Warsito",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933421107351293, -3.899190268978411],
              [114.934321262849224, -3.899192813140588],
              [114.934316196631627, -3.901001006448403],
              [114.933416039213128, -3.900998461253927],
              [114.933421107351293, -3.899190268978411],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang E",
        No_SHM: 168.0,
        Nama_SHM: "Juniansyah",
        No_Kav: "46",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Bambang E",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934321262849224, -3.899192813140588],
              [114.935221418863691, -3.899195356338489],
              [114.935216354566677, -3.901003550678153],
              [114.934316196631627, -3.901001006448403],
              [114.934321262849224, -3.899192813140588],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bela Aris",
        No_SHM: 169.0,
        Nama_SHM: "Siti Aisyah",
        No_Kav: "47",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Bela Aris",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935221418863691, -3.899195356338489],
              [114.93612157539448, -3.899197898572111],
              [114.936116513018064, -3.901006093943177],
              [114.935216354566677, -3.901003550678153],
              [114.935221418863691, -3.899195356338489],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurhadiyat",
        No_SHM: 170.0,
        Nama_SHM: "Diah Ika Handayani",
        No_Kav: "48",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Nurhadiyat",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93612157539448, -3.899197898572111],
              [114.937021732441366, -3.899200439841453],
              [114.93701667198556, -3.901008636243469],
              [114.936116513018064, -3.901006093943177],
              [114.93612157539448, -3.899197898572111],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurhadiyat",
        No_SHM: 171.0,
        Nama_SHM: "Nurhadiyat",
        No_Kav: "49",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 0.0,
        Grup: null,
        Nama_Baru: "Nurhadiyat",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937021732441366, -3.899200439841453],
              [114.937921890004134, -3.899202980146494],
              [114.937916831468911, -3.90101117757903],
              [114.937016671985546, -3.901008636243469],
              [114.937021732441366, -3.899200439841453],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Fahrul R",
        No_SHM: 172.0,
        Nama_SHM: "Fahrul Razi",
        No_Kav: "50",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Fahrul R",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937921890004134, -3.899202980146494],
              [114.938822048082528, -3.899205519487264],
              [114.938816991467931, -3.901013717949856],
              [114.937916831468911, -3.90101117757903],
              [114.937921890004134, -3.899202980146494],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nafrin Y",
        No_SHM: 173.0,
        Nama_SHM: "Nafrin Yuvada",
        No_Kav: "51",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938822048082528, -3.899205519487264],
              [114.939722206676379, -3.89920805786376],
              [114.939717151982379, -3.901016257355939],
              [114.938816991467931, -3.901013717949856],
              [114.938822048082528, -3.899205519487264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "KJP-CPS",
        No_SHM: 174.0,
        Nama_SHM: "Rochmanudin",
        No_Kav: "52",
        Luas: 1.2515,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.2907,
        Grup: null,
        Nama_Baru: "Suharto",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.939722206676379, -3.89920805786376],
              [114.940102107982142, -3.899209128865786],
              [114.940012216719538, -3.899795323850103],
              [114.940162425112717, -3.900952948921103],
              [114.940157323154423, -3.901017498753121],
              [114.940153137151867, -3.901070459906846],
              [114.940089619174785, -3.901278248224998],
              [114.939826831546071, -3.901991846060382],
              [114.939763251029405, -3.902824601106587],
              [114.939710149253756, -3.903520103680195],
              [114.93971209522752, -3.902824456763843],
              [114.939717151982379, -3.901016257355939],
              [114.939722206676379, -3.89920805786376],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yunika H",
        No_SHM: 175.0,
        Nama_SHM: "Yunika Herawati",
        No_Kav: "53",
        Luas: 1.8979,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.8924,
        Grup: null,
        Nama_Baru: "Yunika H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930707313208956, -3.902798999964306],
              [114.930713291789218, -3.901802488614113],
              [114.930844738007522, -3.901354925514319],
              [114.930890444149554, -3.90099339798565],
              [114.930890707399442, -3.900991315722534],
              [114.93161572592669, -3.90099336797082],
              [114.931610651878472, -3.902801558096107],
              [114.930710494089539, -3.902799008973829],
              [114.930707313208956, -3.902798999964306],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bela Aris",
        No_SHM: 176.0,
        Nama_SHM: "Ahmad Noor",
        No_Kav: "54",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nama_Baru: "Bela Aris",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93161572592669, -3.90099336797082],
              [114.932515882311392, -3.900995915094732],
              [114.932510810184652, -3.90280410625322],
              [114.931610651878472, -3.902801558096107],
              [114.93161572592669, -3.90099336797082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Myristin T",
        No_SHM: 177.0,
        Nama_SHM: "Juniansyah",
        No_Kav: "55",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: "Aprizal C",
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932515882311392, -3.900995915094732],
              [114.933416039213128, -3.900998461253927],
              [114.933410969007838, -3.902806653445166],
              [114.932510810184652, -3.90280410625322],
              [114.932515882311392, -3.900995915094732],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nelombi A",
        No_SHM: 178.0,
        Nama_SHM: "Hilmi Hasan",
        No_Kav: "56",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Nelombi A",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933416039213128, -3.900998461253927],
              [114.934316196631627, -3.901001006448403],
              [114.934311128347829, -3.902809199671944],
              [114.933410969007838, -3.902806653445166],
              [114.933416039213128, -3.900998461253927],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yunika H",
        No_SHM: 179.0,
        Nama_SHM: "Yunika Herawati",
        No_Kav: "57",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Yunika H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934316196631599, -3.901001006448403],
              [114.935216354566677, -3.901003550678153],
              [114.935211288204357, -3.902811744933532],
              [114.934311128347829, -3.902809199671927],
              [114.934316196631599, -3.901001006448403],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Warsita",
        No_SHM: 180.0,
        Nama_SHM: "Warsita",
        No_Kav: "58",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Warsito P",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935216354566677, -3.901003550678153],
              [114.936116513018064, -3.901006093943177],
              [114.936111448577236, -3.902814289229961],
              [114.935211288204357, -3.902811744933532],
              [114.935216354566677, -3.901003550678153],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Nurhidayat",
        No_SHM: 181.0,
        Nama_SHM: "Nurhidayat",
        No_Kav: "59",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Nurhidayat",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936116513018064, -3.901006093943177],
              [114.937016671985546, -3.901008636243469],
              [114.937011609466197, -3.902816832561177],
              [114.936111448577236, -3.902814289229961],
              [114.936116513018064, -3.901006093943177],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Suwondo",
        No_SHM: 182.0,
        Nama_SHM: "Nafrin Yuvada",
        No_Kav: "60",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Suwondo",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937016671985546, -3.901008636243469],
              [114.937916831468911, -3.90101117757903],
              [114.937911770871054, -3.902819374927246],
              [114.937011609466197, -3.902816832561177],
              [114.937016671985546, -3.901008636243469],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yenni L",
        No_SHM: 183.0,
        Nama_SHM: "H. Isra",
        No_Kav: "61",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "H. Isra",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937916831468911, -3.90101117757903],
              [114.938816991467903, -3.901013717949839],
              [114.938811932791552, -3.902821916328129],
              [114.937911770871054, -3.902819374927246],
              [114.937916831468911, -3.90101117757903],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "P. Endah",
        No_SHM: 184.0,
        Nama_SHM: "Pruwitasari Endah",
        No_Kav: "62",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.8866,
        Grup: null,
        Nama_Baru: "P. Endah S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.938816991467903, -3.901013717949839],
              [114.939717151982379, -3.901016257355939],
              [114.93971209522752, -3.902824456763826],
              [114.938811932791552, -3.902821916328129],
              [114.938816991467903, -3.901013717949839],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang E",
        No_SHM: 185.0,
        Nama_SHM: "Bambang Eko",
        No_Kav: "63",
        Luas: 1.8897,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.8866,
        Grup: null,
        Nama_Baru: "Bambang E",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930710494089539, -3.902799008973829],
              [114.930705416050074, -3.904607197981137],
              [114.929805256856241, -3.904604646859597],
              [114.929806313605994, -3.90422856257828],
              [114.929982834987953, -3.90411030340144],
              [114.929808861600009, -3.903321631611649],
              [114.92975136322633, -3.90306097489189],
              [114.929809767713081, -3.902999065729937],
              [114.929998134404329, -3.902799395604708],
              [114.930002579004551, -3.902797003577258],
              [114.930710494089539, -3.902799008973829],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Zainal A",
        No_SHM: 186.0,
        Nama_SHM: "Zainal Abidin",
        No_Kav: "64",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Zainal A",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930710494089539, -3.902799008973829],
              [114.931610651878472, -3.902801558096107],
              [114.931605575761367, -3.904609748137067],
              [114.930705416050074, -3.904607197981137],
              [114.930710494089539, -3.902799008973829],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bambang E",
        No_SHM: 187.0,
        Nama_SHM: "Bambang Eko",
        No_Kav: "65",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nama_Baru: "Bambang E",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931610651878472, -3.902801558096107],
              [114.932510810184681, -3.90280410625322],
              [114.932505735989906, -3.904612297327386],
              [114.931605575761367, -3.904609748137067],
              [114.931610651878472, -3.902801558096107],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Soetrisno",
        No_SHM: 188.0,
        Nama_SHM: "Soetrisno",
        No_Kav: "66",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Soetrisno",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932510810184681, -3.90280410625322],
              [114.933410969007838, -3.902806653445166],
              [114.933405896735465, -3.90461484555209],
              [114.932505735989906, -3.904612297327386],
              [114.932510810184681, -3.90280410625322],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bowo H.S",
        No_SHM: 189.0,
        Nama_SHM: "Murtado",
        No_Kav: "67",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Bowo H. S",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.933410969007838, -3.902806653445166],
              [114.934311128347829, -3.902809199671944],
              [114.934306057997802, -3.904617392811174],
              [114.933405896735465, -3.90461484555209],
              [114.933410969007838, -3.902806653445166],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wiwik W.H",
        No_SHM: 190.0,
        Nama_SHM: "Wiwik Widya Hariyani",
        No_Kav: "68",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Wiwik W. H",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934311128347829, -3.902809199671944],
              [114.935211288204385, -3.902811744933549],
              [114.935206219776717, -3.904619939104637],
              [114.934306057997802, -3.904617392811174],
              [114.934311128347829, -3.902809199671944],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Soetrisno",
        No_SHM: 191.0,
        Nama_SHM: "Soetrisno",
        No_Kav: "69",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Soetrisno",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935211288204385, -3.902811744933549],
              [114.936111448577236, -3.902814289229961],
              [114.936106382071969, -3.904622484432477],
              [114.935206219776717, -3.904619939104637],
              [114.935211288204385, -3.902811744933549],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ni'man",
        No_SHM: 192.0,
        Nama_SHM: "H. Sulaeman Yusran",
        No_Kav: "70",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.7294,
        Grup: null,
        Nama_Baru: "Suharyati",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936111448577236, -3.902814289229977],
              [114.937011609466197, -3.902816832561177],
              [114.937006544883303, -3.904625028794688],
              [114.936106382071941, -3.904622484432477],
              [114.936111448577236, -3.902814289229977],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Turana",
        No_SHM: 193.0,
        Nama_SHM: "Turana",
        No_Kav: "71",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.5903,
        Grup: null,
        Nama_Baru: "Turana",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937011609466197, -3.902816832561177],
              [114.937911770871068, -3.902819374927295],
              [114.937906708210591, -3.904627572191236],
              [114.937006544883303, -3.904625028794688],
              [114.937011609466197, -3.902816832561177],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Mawarhdah R",
        No_SHM: 194.0,
        Nama_SHM: "Mawarhdah Rahmah",
        No_Kav: "72",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.5775,
        Grup: null,
        Nama_Baru: "Mawarhdah R",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.937911770871068, -3.902819374927295],
              [114.93881193279158, -3.90282191632818],
              [114.938806872053476, -3.904630114622183],
              [114.937906708210591, -3.904627572191236],
              [114.937911770871068, -3.902819374927295],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Yenni L",
        No_SHM: 195.0,
        Nama_SHM: "Duma Rumilis Tobing",
        No_Kav: "73",
        Luas: 1.9999,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.5646,
        Grup: null,
        Nama_Baru: "KJP-CPS",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.93881193279158, -3.902821916328129],
              [114.939712095227549, -3.902824456763843],
              [114.939710149253784, -3.903520103679925],
              [114.939705949850122, -3.90357510531388],
              [114.939709994787862, -3.903575317501498],
              [114.93970703641186, -3.904632656087496],
              [114.938806872053476, -3.904630114622183],
              [114.93881193279158, -3.902821916328129],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Bowo H.S",
        No_SHM: 196.0,
        Nama_SHM: "Murtado",
        No_Kav: "74",
        Luas: 1.9701,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9994,
        Grup: null,
        Nama_Baru: "M. Aulia",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.929801184961093, -3.906053486373954],
              [114.929805256856227, -3.904604646859597],
              [114.930705416050074, -3.904607197981137],
              [114.93070033594077, -3.906415386904076],
              [114.929935404461418, -3.90641321821607],
              [114.929801184961093, -3.906053486373954],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Johar A",
        No_SHM: 197.0,
        Nama_SHM: "Johar Awaludin",
        No_Kav: "75",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Bambang E",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.930705416050074, -3.904607197981137],
              [114.931605575761367, -3.904609748137067],
              [114.931600497575303, -3.906417938093664],
              [114.93070033594077, -3.906415386904076],
              [114.930705416050074, -3.904607197981137],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Darmadji W.K.",
        No_SHM: 198.0,
        Nama_SHM: "Darmadji W.K.",
        No_Kav: "76",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 1.9999,
        Grup: null,
        Nama_Baru: "Darmadji W. K",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.931605575761367, -3.904609748137067],
              [114.932505735989906, -3.904612297327386],
              [114.932500659727097, -3.906420488317194],
              [114.931600497575303, -3.906417938093664],
              [114.931605575761367, -3.904609748137067],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Zainal A",
        No_SHM: 199.0,
        Nama_SHM: "Zainal Abidin",
        No_Kav: "77",
        Luas: 2.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 2.0,
        Luas_SHM: 2.0,
        Grup: null,
        Nama_Baru: "Zainal A",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.932505735989906, -3.904612297327386],
              [114.933405896735465, -3.90461484555209],
              [114.93340082239591, -3.906423037574657],
              [114.932500659727097, -3.906420488317194],
              [114.932505735989906, -3.904612297327386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "M. Fahruzzaini",
        No_SHM: 200.0,
        Nama_SHM: "M. Fahruzzaini",
        No_Kav: "78",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.0,
        Grup: null,
        Nama_Baru: "M. Fahruzzaini",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934303522047941, -3.905521489349142],
              [114.933403359824084, -3.905518941573904],
              [114.933405896735465, -3.90461484555209],
              [114.934306057997802, -3.904617392811174],
              [114.934303522047941, -3.905521489349142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Sri W",
        No_SHM: 203.0,
        Nama_SHM: "A. Raihanor",
        No_Kav: "81",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.0,
        Grup: null,
        Nama_Baru: "Sri W",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.934303522047941, -3.905521489349142],
              [114.934300985581515, -3.906425585866054],
              [114.93340082239591, -3.906423037574657],
              [114.933403359824084, -3.905518941573904],
              [114.934303522047941, -3.905521489349142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Tri W",
        No_SHM: 201.0,
        Nama_SHM: "Tri Wijayanti",
        No_Kav: "79",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.0,
        Grup: null,
        Nama_Baru: "Tri Wijayanti",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935203684788377, -3.905524036158539],
              [114.934303522047941, -3.905521489349142],
              [114.934306057997802, -3.904617392811174],
              [114.935206219776717, -3.904619939104637],
              [114.935203684788377, -3.905524036158539],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Ainun J",
        No_SHM: 204.0,
        Nama_SHM: "Ainun Jariah",
        No_Kav: "82",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.0,
        Grup: "Ainun Jariah",
        Nama_Baru: "Ainun J",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.935203684788377, -3.905524036158539],
              [114.935201149283714, -3.906428133191348],
              [114.934300985581515, -3.906425585866054],
              [114.934303522047941, -3.905521489349142],
              [114.935203684788377, -3.905524036158539],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Warsita",
        No_SHM: 205.0,
        Nama_SHM: "Juliman D.J.",
        No_Kav: "83",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.3216,
        Grup: null,
        Nama_Baru: "Warsita",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936103848045178, -3.905526582002084],
              [114.93610131350222, -3.906430679550602],
              [114.935201149283714, -3.906428133191348],
              [114.935203684788377, -3.905524036158539],
              [114.936103848045178, -3.905526582002084],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Nm_Pemilik: "Wahidah M",
        No_SHM: 202.0,
        Nama_SHM: "Wahidah Marwiah",
        No_Kav: "80",
        Luas: 1.0,
        Lokasi: "Batalang 150",
        Luas_Inkes: 1.0,
        Luas_SHM: 1.3216,
        Grup: null,
        Nama_Baru: "Wahidah M",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.936103848045178, -3.905526582002084],
              [114.935203684788377, -3.905524036158539],
              [114.935206219776717, -3.904619939104637],
              [114.936106382071941, -3.904622484432477],
              [114.93610431992451, -3.905358237458092],
              [114.936103848045178, -3.905526582002084],
            ],
          ],
        ],
      },
    },
  ],
};
