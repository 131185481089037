import { ConfigProvider, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { getUsers, selectUser } from "../../../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbFilter, TbRefresh } from "react-icons/tb";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import { useState } from "react";

const { RangePicker } = DatePicker;

const Filter = ({ handleRefreshData, onFilter, showModal }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [users, setUsers] = useState([]);
  const { watch, control } = useForm();
  const watchAfdeling = watch("afdeling");
  const watchEstate = watch("estate");

  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  const handleGetUsers = async ({ name, page }) => {
    console.log("Name: ", name);
    const response = await dispatch(
      getUsers({
        companyId: user?.companyId,
        fltFullname: name || undefined,
        page: page || 0,
      })
    ).unwrap();
    const records = response.records;
    console.log("Records: ", response);
    let newData = [{ label: "Semua pengguna", value: undefined }];
    records.forEach((item) => {
      return newData.push({
        value: `${item?.id}`,
        label: `${item?.fullname}`,
      });
    });
    console.log("User: ", newData);
    setUsers(newData);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetUsers({});
      handleGetEstates({});
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="mx-16 flex flex-row items-center justify-between">
      <div className="relative flex w-full flex-row items-center space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition -ml-8 w-fit bg-white p-10 md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-fit bg-white p-10"
          onClick={() => handleRefreshData()}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <SelectHook
          name="username"
          data={users}
          control={control}
          placeholder="Pilih pengguna"
          menuStyles={{ padding: 5 }}
          onInputChange={(searchValue) => {
            handleGetUsers({ name: searchValue });
          }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            console.log("Value: ", value);
            onFilter({ type: "username", value });
          }}
        />
        <SelectHook
          control={control}
          data={estates}
          name="estate"
          placeholder="Pilih kebun"
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            handleGetEstates({ name: searchValue });
          }}
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "estate",
            });
            handleGetAfdelings({ estate: data.value });
          }}
          menuStyles={{ padding: 5 }}
        />
        {watchEstate?.value && (
          <SelectHook
            control={control}
            data={afdelings}
            name="afdeling"
            placeholder="Pilih wilayah"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              handleGetAfdelings({
                estate: watchEstate?.value,
                name: searchValue,
              });
            }}
            afterOnChange={(data) => {
              onFilter({
                value: data?.value,
                type: "afdeling",
              });
              handleGetBlocks({ afdelingId: data?.value });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}

        {watchAfdeling?.value && (
          <SelectHook
            control={control}
            data={blocks}
            name="block"
            placeholder="Pilih blok"
            className="hidden max-w-[200px] md:inline"
            onInputChange={(searchValue) => {
              handleGetBlocks({
                afdelingId: watchAfdeling?.value,
                name: searchValue,
              });
            }}
            afterOnChange={(data) => {
              onFilter({
                value: data?.value,
                type: "block",
              });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <div className="hidden md:inline">
                  <RangePicker
                    placeholder={["Dari tanggal", "sampai"]}
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(data) => {
                      onChange(data);
                      onFilter({
                        value: data,
                        type: "dateRange",
                      });
                    }}
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 8,
                      maxWidth: 250,
                      borderWidth: 1,
                      borderColor: "#e1e4e8",
                    }}
                  />
                </div>
              </ConfigProvider>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
