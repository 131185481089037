export const headersData = [
  {
    value: "NO.",
    accessorKey: "index",
    type: "alphabet",
  },
  {
    value: "Foto",
    accessorKey: "photo",
    type: "alphabet",
  },
  {
    value: "Tanggal Pengambilan",
    accessorKey: "departureTime",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Dibuat oleh",
    accessorKey: "createdBy",
    type: "alphabet",
  },
  {
    value: "Komentar",
    accessorKey: "remark",
    type: "alphabet",
  },
  {
    value: "Tanggal Tindakan",
    accessorKey: "caDueDate",
    type: "alphabet",
  },
  //
  {
    value: "Aktivitas",
    accessorKey: "cargoFlow",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Jenis Muatan",
    accessorKey: "cargoType",
    type: "alphabet",
  },
  {
    value: "Estimasi Berat Muatan (Kg)",
    accessorKey: "cargoWeightKg",
    type: "number",
  },
  {
    value: "Lokasi",
    accessorKey: "originLocation",
    type: "alphabet",
  },
  {
    value: "Nopol",
    accessorKey: "vehiclePlateNo",
    type: "alphabet",
  },
];
