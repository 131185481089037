import React from "react";
import { Number } from "../../../../../helpers/Common";

const SimpleCardHorizontalDivide = ({ title, subTitle, value, unit }) => {
  const checkNumber = (num) => {
    if (num === undefined || num === null || num < 0) return 0;
    return num || 0;
  };

  return (
    <div className="grid h-full w-full grid-cols-1 items-center justify-between gap-0 rounded-8 bg-white p-8 shadow md:grid-cols-2 md:gap-13 md:py-8 md:px-16">
      <div className="grid w-full grid-cols-1">
        <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
          {title}
        </h3>
        <p className="font-regular text-13 text-gray-400 md:text-14">
          {subTitle}
        </p>
        <div className="flex flex-row items-end justify-center space-x-4 border border-red-600 md:justify-end">
          <h1 className="text-24 font-bold text-gray-600 md:text-24">
            {checkNumber(Number(Math.ceil(value)))}
          </h1>
          <p className="justify-end text-16 font-medium text-gray-600 md:text-16">
            {unit}
          </p>
        </div>
      </div>{" "}
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col justify-center md:justify-start">
          <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
            {title}
          </h3>
          <p className="font-regular text-13 text-gray-400 md:text-14">
            {subTitle}
          </p>
        </div>
        <div className="flex flex-row items-end justify-center space-x-4 md:justify-end">
          <h1 className="text-24 font-bold text-gray-600 md:text-24">
            {checkNumber(Number(Math.ceil(value)))}
          </h1>
          <p className="justify-end text-16 font-medium text-gray-600 md:text-16">
            {unit}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SimpleCardHorizontalDivide;
