import React, { Fragment } from "react";
import { TbArrowLeft, TbCheck, TbCreditCard, TbX } from "react-icons/tb";

import BasePageHeader from "../../components/atoms/Headings/BasePageHeader";
import ButtonComponent from "../../components/atoms/ButtonComponent";
import Navbar from "../../components/atoms/Navbar";
import { useNavigate } from "react-router-dom";

const data = [
	{
		title: "Core features",
		data: [
			{
				title: "Repeating Work Orders",
				planOne: 10,
				planTwo: "Unlimited",
				planThree: "Unlimited",
			},
			{
				title: "Work Orders with Pictures",
				planOne: 45,
				planTwo: "Unlimited",
				planThree: "Unlimited",
			},
			{
				title: "Work Orders with Attached Procedures",
				planOne: 90,
				planTwo: "Unlimited",
				planThree: "Unlimited",
			},
		],
	},
	{
		title: "Productivity & collaboration",
		data: [
			{
				title: "Basic Analytics & Insights",
				planType: "icon",
				planOne: <TbX className="text-24 text-red-500" />,
				planTwo: <TbCheck className="text-24 text-yellow-500" />,
				planThree: <TbCheck className="text-24 text-yellow-500" />,
			},
			{
				title: "Advanced Analytics",
				planType: "icon",
				planOne: <TbX className="text-24 text-red-500" />,
				planTwo: <TbCheck className="text-24 text-yellow-500" />,
				planThree: <TbCheck className="text-24 text-yellow-500" />,
			},
			{
				title: "Email Support",
				planType: "icon",
				planOne: <TbX className="text-24 text-red-500" />,
				planTwo: <TbCheck className="text-24 text-yellow-500" />,
				planThree: <TbCheck className="text-24 text-yellow-500" />,
			},
			{
				title: "Work Order Exports",
				planType: "icon",
				planOne: <TbX className="text-24 text-red-500" />,
				planTwo: <TbCheck className="text-24 text-yellow-500" />,
				planThree: <TbCheck className="text-24 text-yellow-500" />,
			},
			{
				title: "CSV Exports",
				planType: "icon",
				planOne: <TbX className="text-24 text-red-500" />,
				planTwo: <TbCheck className="text-24 text-yellow-500" />,
				planThree: <TbCheck className="text-24 text-yellow-500" />,
			},
		],
	},
];

const SubscriptionPlansPage = () => {
	const [isChecked, setIsChecked] = React.useState(false);
	const navigate = useNavigate();

	return (
		<div className="relative w-full overflow-hidden bg-gray-30">
			<Navbar />
			<BasePageHeader
				icon={<TbCreditCard />}
				title="Paket Berlangganan"
				buttonShow={false}
			/>

			<div className="mx-16 mb-24 flex flex-row items-center justify-between mdx:mx-[250px]">
				<ButtonComponent
					value="Kembali"
					size="sm"
					type="button"
					className="bordered-button-transition bg-white"
					onClick={() => navigate(-1)}
					icon={<TbArrowLeft />}
				/>
			</div>

			<div className="grid grid-cols-1 gap-24 pb-24">
				<div className="mx-16 flex flex-row items-center justify-between rounded-16 border-1 border-gray-10 bg-white p-24 md:mx-16 3xl:mx-[250px]">
					<div className="relative w-full overflow-auto rounded-lg md:block">
						<table className="relative w-full">
							<thead>
								<tr className="whitespace-nowrap">
									<td className="pr-42 md:pr-0">
										<div className="grid max-w-[250px] grid-cols-2 rounded-full bg-gray-20 p-8">
											<div
												className={`flex cursor-pointer select-none flex-col items-center justify-center rounded-full py-8 px-16 ${
													!isChecked && "bg-white"
												}`}
												onClick={() =>
													setIsChecked(false)
												}
											>
												<p
													className={`font-bold ${
														!isChecked
															? "text-gray-500"
															: "text-gray-300"
													}`}
												>
													Monthly
												</p>
											</div>
											<div
												className={`flex cursor-pointer select-none flex-col items-center justify-center rounded-full py-8 px-16 ${
													isChecked && "bg-white"
												}`}
												onClick={() =>
													setIsChecked(true)
												}
											>
												<p
													className={`font-bold ${
														isChecked
															? "text-gray-500"
															: "text-gray-300"
													}`}
												>
													Yearly
												</p>
											</div>
										</div>
									</td>

									<td className="pr-42 md:pr-0">
										<div className="mb-16">
											<div className="flex flex-row items-center space-x-13">
												<p className="font-semi-bold text-16 text-gray-500">
													Standard
												</p>
												<div className="rounded-[4px] bg-green-100 p-4">
													<p className="text-13 font-medium text-green-800">
														20% off
													</p>
												</div>
											</div>

											<h3 className="font-semi-bold text-24 text-gray-500">
												Rp150.000
											</h3>
											<p className="font-regular text-13 text-gray-400">
												For small teams
											</p>
										</div>
										<ButtonComponent
											value="Current plan"
											disabled
											size="sm"
											type="button"
											className="button-transition rounded-8 bg-yellow-500 font-medium text-white hover:bg-yellow-500 hover:text-white"
										/>
									</td>

									<td className="pr-42 md:pr-0">
										<div className="mb-16">
											<p className="font-semi-bold text-16 text-gray-500">
												Premium
											</p>
											<h3 className="font-semi-bold text-24 text-gray-500">
												Rp350.000
											</h3>
											<p className="font-regular text-13 text-gray-400">
												For small bussiness
											</p>
										</div>
										<ButtonComponent
											value="Pilih paket"
											size="sm"
											type="button"
											className="button-transition"
										/>
									</td>

									<td className="pr-42 md:pr-0">
										<div className="mb-16">
											<p className="font-semi-bold text-16 text-gray-500">
												Enterprise
											</p>
											<h3 className="font-semi-bold text-24 text-gray-500">
												Rp950.000
											</h3>
											<p className="font-regular text-13 text-gray-400">
												For medium company
											</p>
										</div>

										<ButtonComponent
											value="Pilih paket"
											size="sm"
											type="button"
											className="button-transition"
										/>
									</td>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<Fragment key={index}>
										<tr>
											<td
												className="table-row-regular px-0 text-16 font-bold text-gray-500"
												colSpan={4}
											>
												{item.title}
											</td>
										</tr>
										{item.data.map((secItem, secIndex) => {
											return (
												<tr
													key={secIndex}
													className="border-y-1 border-gray-10"
												>
													<td className="table-row-regular px-0 font-medium text-gray-400">
														{secItem.title}
													</td>
													<td className="table-row-regular font-medium text-gray-400 ">
														{secItem.planOne}
													</td>
													<td className="table-row-regular font-medium text-gray-400 ">
														{secItem.planTwo}
													</td>
													<td className="table-row-regular font-medium text-gray-400 ">
														{secItem.planThree}
													</td>
												</tr>
											);
										})}
										{index + 1 !== data?.length && (
											<tr>
												<td
													className="table-row-regular px-0 font-bold text-gray-500"
													colSpan={4}
												></td>
											</tr>
										)}
									</Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionPlansPage;
