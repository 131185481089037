import React, { useEffect, useState } from "react";
import { TbArrowLeft, TbChartArea } from "react-icons/tb";
import {
  deleteCollectionPoint,
  getCollectionPoints,
} from "../../../features/collectionPointSlice/collectionPointSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Filter from "./partials/Filter/Filter";
import ListCollectionPoint from "./partials/ListCollectionPoint/ListCollectionPoint";
import MassCreateModal from "./partials/MassCreateModal/MassCreateModal";
import MultiModal from "./partials/MultiModal/MultiModal";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { selectUserCompanyId } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import { APP_NAME } from "../../../configs/api";

const ListView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { onShowModal, onModalUpdate, isUpdate } = useModal();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const paginationLenght = Math.ceil(page?.total / 50);
  const modalName = "CollectionPoint";

  const modal = useSelector(selectModal);
  const companyId = useSelector(selectUserCompanyId);

  const estateId = searchParams.get("estateId");
  const estateName = searchParams.get("estateName");
  const afdelingId = searchParams.get("afdelingId");
  const afdelingName = searchParams.get("afdelingName");
  const blockId = searchParams.get("blockId");
  const blockName = searchParams.get("blockName");

  let params = {
    pageIndex: page.current,
    fltCompany: companyId,
    fltEstate: estateId,
    fltAfdeling: afdelingId,
    fltBlock: blockId,
    pageSize: 50,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const onDelete = (id) => {
    dispatch(deleteCollectionPoint({ id: id }))
      .unwrap()
      .then(() => handleRefreshData());
  };

  const onUpdate = (params) => {
    onModalUpdate(modalName, "showModalCreate", params);
  };

  const handlePageClick = (page) => {
    dispatch(
      getCollectionPoints({
        ...params,
        pageIndex: page.selected,
      })
    )
      .unwrap()
      .then((data) => {
        const success = data.success;
        const message = data.message;
        if (!success) {
          alert.error(message);
        }
        setData(sortNumber(data?.records));
        const totalRecords = data?.totalRecords;
        setPage({
          current: page.selected,
          total: totalRecords,
        });
      });
  };

  const sortNumber = (items) => {
    let records = [...items];
    records.sort(function (a, b) {
      if (parseInt(a.name) > parseInt(b.name)) {
        return -1;
      }
      if (parseInt(a.name) < parseInt(b.name)) {
        return 1;
      }
      return 0;
    });
    return records;
  };

  const handleRefreshData = () => {
    setIsLoading(true);
    dispatch(
      getCollectionPoints({
        ...params,
        pageIndex: 0,
      })
    )
      .unwrap()
      .then((data) => {
        const success = data.success;
        const message = data.message;
        if (!success) {
          alert.error(message);
        }
        const totalRecords = data?.totalRecords;
        setData(sortNumber(data?.records));
        setPage({
          current: 0,
          total: totalRecords,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert.error("Our serve are down, try again later");
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            showModalMassCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "KeyC") {
        onShowModal(modalName, "showModalCreate");
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          {/*<ModalContent onFilter={onFilter} />*/}
        </Modal>
      </ConfigProvider>
      <MultiModal
        params={params}
        modalName={modalName}
        isOpen={modal[modalName]?.showModalCreate}
        toggleModal={() => onShowModal(modalName, "showModalCreate")}
        onRefreshData={handleRefreshData}
        type={isUpdate ? "Ubah" : "Tambah"}
        estate={{ id: estateId, name: estateName }}
        afdeling={{
          id: afdelingId,
          name: afdelingName,
        }}
        block={{ id: blockId, name: blockName }}
      />
      <MassCreateModal
        params={params}
        modalName={modalName}
        isOpen={modal[modalName]?.showModalMassCreate}
        toggleModal={() => onShowModal(modalName, "showModalMassCreate")}
        onRefreshData={handleRefreshData}
        type={isUpdate ? "Ubah" : "Buat"}
        estate={{ id: estateId, name: estateName }}
        afdeling={{
          id: afdelingId,
          name: afdelingName,
        }}
        block={{ id: blockId, name: blockName }}
      />
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-16">
              <button onClick={() => navigate(-1)}>
                <TbArrowLeft className="text-24 text-gray-500" />
              </button>
              <TbChartArea className="mr-16 hidden text-24 text-gray-500 md:inline" />
              <p className="line-clamp-1">
                {estateName} / {afdelingName} / {blockName} / TPH
              </p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          modalName={modalName}
          onShowModal={onShowModal}
          handleRefreshData={handleRefreshData}
        />
      </div>
      <ListCollectionPoint
        data={data}
        isLoading={isLoading}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
