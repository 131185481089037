import React, { useCallback, useRef, useState } from "react";
import Map, { Layer, Source } from "react-map-gl";
import { data, dataBatalang2 } from "./partials/Layers/data";
import { formatNumericValue } from "../../helpers/Common";
import {
  createMultipleLayers,
  dataColorMapping,
  MAP_STYLE,
} from "../../hooks/mapUtils";
import { useBlockStore } from "../../api/blocks/useBlockStore";
import { jilatan } from "../../api/qgis/kjp/jilatan/jilatan";
import { jilatan25 } from "../../api/qgis/kjp/jilatan/jilatan25";
import { jilatan29 } from "../../api/qgis/kjp/jilatan/jilatan29";
import { divisi11 } from "../../api/qgis/sawitmas/divisi11";
import { pudingr1 } from "../../api/qgis/sawitmas/pudingr1";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { useMap } from "./context/mapContext";
import { damarlima25 } from "../../api/qgis/kjp/damarlima/damarlima25";
import { damarlima70 } from "../../api/qgis/kjp/damarlima/damarlima70";
import { damarlima100 } from "../../api/qgis/kjp/damarlima/damarlima100";

import { batutungku2008 } from "../../api/qgis/ptpn/2008/batutungku2008";
import { sabuhur2008 } from "../../api/qgis/ptpn/2008/sabuhur2008";
import { bumiasih2008 } from "../../api/qgis/ptpn/2008/bumiasih2008";
import { kampungbaru2008 } from "../../api/qgis/ptpn/2008/kampungbaru2008";
import { kandanganlama2008 } from "../../api/qgis/ptpn/2008/kandanganlama2008";
import { pantailinuh2008 } from "../../api/qgis/ptpn/2008/pantailinuh2008";
import { tanjung2008 } from "../../api/qgis/ptpn/2008/tanjung2008";
import { tanjungtt08 } from "../../api/qgis/ptpn/2008/tanjungtt08";
import { galam2009 } from "../../api/qgis/ptpn/2009/galam2009";
import { kuringkit2009 } from "../../api/qgis/ptpn/2009/kuringkit2009";
import { pantailinuh2009 } from "../../api/qgis/ptpn/2009/pantailinuh2009";
import { pemalongan2009 } from "../../api/qgis/ptpn/2009/pemalongan2009";
import { sabuhur2009 } from "../../api/qgis/ptpn/2009/sabuhur2009";
import { santol2009 } from "../../api/qgis/ptpn/2009/santol2009";
import { tanjung2009 } from "../../api/qgis/ptpn/2009/tanjung2009";
import { ujungbatu2009 } from "../../api/qgis/ptpn/2009/ujungbatu2009";
import { pantailinuh2010 } from "../../api/qgis/ptpn/2010/pantailinuh2010";
import { sabuhur2010 } from "../../api/qgis/ptpn/2010/sabuhur2010";
import { pemalongan2012 } from "../../api/qgis/ptpn/2012/pemalongan2012";
import { sabuhur2012 } from "../../api/qgis/ptpn/2012/sabuhur2012";
import { seiaris2012 } from "../../api/qgis/ptpn/2012/seiaris2012";
import { seijelai2012 } from "../../api/qgis/ptpn/2012/seijelai2012";
import { tanjung2012 } from "../../api/qgis/ptpn/2012/tanjung2012";
import { ujungbatu2012 } from "../../api/qgis/ptpn/2012/ujungbatu2012";
import { batalang125 } from "../../api/qgis/kjp/batalang/batalang125";
import { batalang150 } from "../../api/qgis/kjp/batalang/batalang150";

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_SECRET_TOKEN;

const ReactMapGlViewer = () => {
  const user = useSelector(selectUser);
  const divRef = useRef(null);
  const [dataBlock, setDataBlock] = useState(null);
  const { lngLat, setLngLat, zoom, setZoom } = useMap();

  const initialViewStateKjp = {
    longitude: 114.87264014431548,
    latitude: -3.9088264022394696,
    zoom: 13.18,
  };

  const layerKjp = [
    {
      id: "jilatan",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: jilatan,
    },
    {
      id: "jilatan25",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: jilatan25,
    },
    {
      id: "jilatan29",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: jilatan29,
    },
    {
      id: "damarlima25",
      fillColor: "rgba(133,182,111,1.0)",
      boxes: damarlima25,
    },
    {
      id: "damarlima70",
      fillColor: "rgba(196,60,57,1.0)",
      boxes: damarlima70,
    },
    {
      id: "damarlima100",
      fillColor: "rgba(141,90,153,1.0)",
      boxes: damarlima100,
    },
    {
      id: "batalang125",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: batalang125,
    },
    {
      id: "batalang150",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: batalang150,
    },
  ];

  const initialViewStateSawitMas = {
    longitude: 103.88544893945772,
    latitude: -1.5906477428902974,
    zoom: 13.448568782563038,
  };

  const layerSawitMas = [
    {
      id: "divisi11",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: divisi11,
    },
    {
      id: "pudingr1",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: pudingr1,
    },
  ];

  const initialViewStatePtpn = {
    longitude: 114.75992191502507,
    latitude: -4.013671574112479,
    zoom: 12.86,
  };

  const layerPtpn = [
    {
      id: "batutungku2008",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: batutungku2008,
    },
    {
      id: "bumiasih2008",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: bumiasih2008,
    },
    {
      id: "kampungbaru2008",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: kampungbaru2008,
    },
    {
      id: "kandanganlama2008",
      fillColor: "rgba(133,182,111,1.0)",
      boxes: kandanganlama2008,
    },
    {
      id: "pantailinuh2008",
      fillColor: "rgba(196,60,57,1.0)",
      boxes: pantailinuh2008,
    },
    {
      id: "sabuhur2008",
      fillColor: "rgba(141,90,153,1.0)",
      boxes: sabuhur2008,
    },

    {
      id: "tanjung2008",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: tanjung2008,
    },
    {
      id: "tanjungtt08",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: tanjungtt08,
    },
    {
      id: "galam2009",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: galam2009,
    },
    {
      id: "kuringkit2009",
      fillColor: "rgba(133,182,111,1.0)",
      boxes: kuringkit2009,
    },
    {
      id: "pantailinuh2009",
      fillColor: "rgba(196,60,57,1.0)",
      boxes: pantailinuh2009,
    },
    {
      id: "pemalongan2009",
      fillColor: "rgba(141,90,153,1.0)",
      boxes: pemalongan2009,
    },

    {
      id: "sabuhur2009",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: sabuhur2009,
    },
    {
      id: "santol2009",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: santol2009,
    },
    {
      id: "tanjung2009",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: tanjung2009,
    },
    {
      id: "ujungbatu2009",
      fillColor: "rgba(133,182,111,1.0)",
      boxes: ujungbatu2009,
    },
    {
      id: "pantailinuh2010",
      fillColor: "rgba(196,60,57,1.0)",
      boxes: pantailinuh2010,
    },
    {
      id: "sabuhur2010",
      fillColor: "rgba(141,90,153,1.0)",
      boxes: sabuhur2010,
    },

    {
      id: "pemalongan2012",
      fillColor: "rgba(152,125,183,1.0)",
      boxes: pemalongan2012,
    },
    {
      id: "sabuhur2012",
      fillColor: "rgba(243,166,178,1.0)",
      boxes: sabuhur2012,
    },
    {
      id: "seiaris2012",
      fillColor: "rgba(255,158,23,1.0)",
      boxes: seiaris2012,
    },
    {
      id: "seijelai2012",
      fillColor: "rgba(133,182,111,1.0)",
      boxes: seijelai2012,
    },
    {
      id: "tanjung2012",
      fillColor: "rgba(196,60,57,1.0)",
      boxes: tanjung2012,
    },
    {
      id: "ujungbatu2012",
      fillColor: "rgba(141,90,153,1.0)",
      boxes: ujungbatu2012,
    },
  ];

  const layerData = [
    {
      id: "orange-data",
      fillColor: "orange",
      boxes: data,
    },
    {
      id: "yellow-data",
      fillColor: "yellow",
      boxes: dataBatalang2,
    },
  ];

  const initialViewState = {
    longitude: 114.936106382071941,
    latitude: -3.904622484432493,
    zoom: 15,
  };

  const [hoverInfo, setHoverInfo] = useState(null);

  const getBlock = useBlockStore((state) => state.getBlock);

  const [prevBlockId, setPrevBlockId] = useState(null);

  const onHover = useCallback(
    (event) => {
      const hoveredFeature = event?.features && event?.features[0];

      if (hoveredFeature) {
        const currentBlockId = hoveredFeature?.properties?.blockId;

        // if (currentBlockId && currentBlockId !== prevBlockId) {
        //   // Only call getBlock if the blockId has changed
        //   getBlock({
        //     id: currentBlockId,
        //     silent: true,
        //     onSuccess: (data) => {
        //       console.log("data block: ", data);
        //     },
        //   });
        //   setPrevBlockId(currentBlockId);
        // }

        setHoverInfo({
          feature: hoveredFeature,
          x: event?.point?.x,
          y: event?.point?.y,
        });
      } else {
        setHoverInfo(null);
      }
    },
    [prevBlockId, setPrevBlockId, setHoverInfo] // Include dependencies in the dependency array
  );

  // const onMouseEnter = useCallback(
  //   (event) => {
  //     const hoveredFeature = event?.features && event?.features[0];
  //
  //     if (hoveredFeature) {
  //       if (hoveredFeature?.properties?.blockId) {
  //         getBlock({
  //           id: hoveredFeature?.properties?.blockId,
  //           silent: true,
  //           onSuccess: (data) => {
  //             console.log("data block: ", data);
  //           },
  //         });
  //       }
  //     }
  //   },
  //   [hoverInfo]
  // );

  // console.log("layer data: ", createMultipleLayers(layerData));

  const chooseData = () => {
    if (user?.username === "kjpjne") {
      return layerKjp;
    } else if (user?.username === "smp") {
      return layerSawitMas;
    } else if (user?.username === "k.emj") {
      return layerSawitMas;
    } else if (user?.username === "plasmapelaihari") {
      return layerPtpn;
    } else {
      return layerData;
    }
  };

  const chooseInitialViewState = () => {
    if (user?.username === "kjpjne") {
      return initialViewStateKjp;
    } else if (user?.username === "smp") {
      return initialViewStateSawitMas;
    } else if (user?.username === "k.emj") {
      return initialViewStateSawitMas;
    } else if (user?.username === "plasmapelaihari") {
      return initialViewStatePtpn;
    } else {
      return initialViewState;
    }
  };

  return (
    <div
      ref={divRef}
      className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden"
    >
      {/*<div className="absolute left-16 top-16 z-10 rounded-8 bg-green-800 px-16 py-8 font-medium text-white shadow">*/}
      {/*  Longitude: {lngLat.lng} | Latitude: {lngLat.lat} | Zoom:{" "}*/}
      {/*  {formatNumericValue(zoom, {*/}
      {/*    decimals: 2,*/}
      {/*  })}*/}
      {/*</div>*/}
      <Map
        initialViewState={chooseInitialViewState()}
        mapStyle={MAP_STYLE}
        mapboxAccessToken={ACCESS_TOKEN}
        interactiveLayerIds={chooseData().map((item) => item?.id)}
        onMouseMove={onHover}
        onZoom={(e) => setZoom(e.viewState.zoom)}
        onDrag={(e) =>
          setLngLat({
            lng: e.viewState.longitude,
            lat: e.viewState.latitude,
          })
        }
        // onMouseEnter={onMouseEnter}
        // style={{ width: "100vw", height: "100vh " }}
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        {createMultipleLayers(chooseData()).map((item) => (
          <Source
            id={item?.layer?.id}
            type="geojson"
            data={dataColorMapping(item.boxes)}
          >
            <Layer {...item.label} />
            <Layer {...item.outlineStyle} />
            <Layer {...item.layer} />
          </Source>
        ))}

        {hoverInfo && (
          <div
            className="tooltip pointer-events-none absolute z-10 min-w-[200px] max-w-sm space-y-4 rounded-[4px] bg-white p-8"
            style={{ left: hoverInfo.x, top: hoverInfo.y }}
          >
            <div className="border-b border-gray-100 pb-8">
              <p className="font-bold text-14 text-black">
                {hoverInfo.feature.properties["Lokasi"]}{" "}
                {user?.username === "smp" &&
                  `- Blok ${hoverInfo.feature.properties["No_Kav"]} (${hoverInfo.feature.properties["TANAM"]})`}
                {user?.username === "plasmapelaihari" &&
                  `${hoverInfo.feature.layer.source} - Blok ${hoverInfo.feature.properties["No_Kav"]} (${hoverInfo.feature.properties["TANAM"]})`}
              </p>
            </div>
            <div>
              <ul className="list-inside list-disc">
                <li className="font-regular text-14 text-black">
                  Luas:{" "}
                  {formatNumericValue(
                    hoverInfo.feature.properties["Luas"] ||
                      hoverInfo.feature.properties["Luas_SHM"] ||
                      0,
                    {
                      decimals: 2,
                    }
                  )}{" "}
                  Ha
                </li>

                {user?.username === "kjpjne" && (
                  <>
                    <li className="font-regular text-14 text-black">
                      Nama Pemilik: {hoverInfo.feature.properties["Nm_Pemilik"]}
                    </li>
                    <li className="font-regular text-14 text-black">
                      {hoverInfo.feature.properties["NO_SKT"]
                        ? "NO SKT: "
                        : "NO SHM: "}{" "}
                      {hoverInfo.feature.properties["NO_SKT"]
                        ? hoverInfo.feature.properties["NO_SKT"]
                        : hoverInfo.feature.properties["No_SHM"]}
                    </li>
                    <li className="font-regular text-14 text-black">
                      NO Kav:{" "}
                      {hoverInfo.feature.properties["No_Kav"] ||
                        hoverInfo.feature.properties["NoKavl"] ||
                        hoverInfo.feature.properties["No_persil"]}
                    </li>
                  </>
                )}

                {user?.username === "plasmapelaihari" && (
                  <>
                    <li className="font-regular text-14 text-black">
                      Nama Pemilik: {hoverInfo.feature.properties["Nm_Pemilik"]}
                    </li>
                    <li className="font-regular text-14 text-black">
                      {hoverInfo.feature.properties["NO_SKT"]
                        ? "NO SKT: "
                        : "NO SHM: "}{" "}
                      {hoverInfo.feature.properties["NO_SKT"]}
                    </li>
                    <li className="font-regular text-14 text-black">
                      NO Kav: {hoverInfo.feature.properties["No_Kav"]}
                    </li>
                    <li className="font-regular text-14 text-black">
                      Blok: {hoverInfo.feature.properties["block"]}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </Map>
    </div>
  );
};

export default ReactMapGlViewer;
