import React from "react";

const Content = ({ data }) => {
  return (
    <div
      className={`m-auto grid w-full ${
        data?.length === 1 ? "grid-cols-1" : "grid-cols-2"
      } gap-8`}
    >
      {data?.map((item, index) => (
        <div key={index} className="flex flex-col items-center">
          <div className="mx-auto flex flex-row items-end space-x-4">
            <h1
              className={`text-gray-600 ${
                index === 0 ? "text-24 font-bold" : "text-24 font-medium"
              }`}
            >
              {item?.value}
            </h1>
            <p className="justify-end text-13 font-medium text-gray-600 md:text-16">
              {item?.unit}
            </p>
          </div>
          <p className="justify-end text-13 font-medium text-gray-600 md:text-16">
            {item?.dateType}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Content;
