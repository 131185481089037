export const tanjung2008 = {
  type: "FeatureCollection",
  name: "tanjungtt08_49ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 2634.734,
        Luas: 0.263,
        PERIMETRO: 276.806,
        Nm_Pemilik: "Dian Paskalis",
        No_Kav: "1",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848623570963696, -3.738398549134773],
              [114.848899864656417, -3.738992124955653],
              [114.848858333323321, -3.739413443922512],
              [114.848806108300138, -3.739474348800965],
              [114.848766199988205, -3.739230116955623],
              [114.848637411639714, -3.738961936877062],
              [114.848498330630079, -3.738741190515187],
              [114.848388944991569, -3.738514225853553],
              [114.848415138968562, -3.738450350570884],
              [114.848623570963696, -3.738398549134773],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13790.21,
        Luas: 1.379,
        PERIMETRO: 640.425,
        Nm_Pemilik: "Bustani",
        No_Kav: "11",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.846670014602452, -3.745485151371606],
              [114.84684075091306, -3.745932174453197],
              [114.846997675901264, -3.746450400618672],
              [114.847013879391611, -3.746739975223351],
              [114.847124686199663, -3.746932934474633],
              [114.846771440788714, -3.746697258678704],
              [114.846630718320426, -3.747055010205644],
              [114.846481198536054, -3.746854629290981],
              [114.846178486571972, -3.746840182518492],
              [114.845867961101646, -3.746675022591556],
              [114.845685558167929, -3.746537577867791],
              [114.84558505334752, -3.746553654911209],
              [114.845732098865938, -3.746466455746615],
              [114.845789609626124, -3.746370701727074],
              [114.845863371016748, -3.74632156589046],
              [114.846057300378035, -3.746206969427923],
              [114.846065737376634, -3.746102874552189],
              [114.846365554095527, -3.746185811257724],
              [114.846106950485108, -3.745979681374258],
              [114.846121036678682, -3.745796143712573],
              [114.84623282123232, -3.745812858952624],
              [114.846320434152162, -3.745676080826033],
              [114.846670014602452, -3.745485151371606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 7259.175,
        Luas: 0.726,
        PERIMETRO: 488.322,
        Nm_Pemilik: "Bustani",
        No_Kav: "11",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845324309203335, -3.747318037197811],
              [114.845241615495738, -3.746969843447546],
              [114.845307247614983, -3.746796010820979],
              [114.845439767924276, -3.746767680167841],
              [114.845501649530206, -3.746916936543325],
              [114.845555350448009, -3.746999276319628],
              [114.845612145419224, -3.747105860453647],
              [114.845693222419897, -3.747282102477738],
              [114.845829612832333, -3.747337708263448],
              [114.845886498018913, -3.747407446674043],
              [114.845853574240365, -3.74753837321494],
              [114.84596759512101, -3.747575500777505],
              [114.846031144461165, -3.747420085879598],
              [114.846029547571874, -3.747239946117457],
              [114.846198646368848, -3.747252645663095],
              [114.846530495564153, -3.747374237925693],
              [114.846215282545543, -3.747948666121373],
              [114.845407358681527, -3.747520895060847],
              [114.845324309203335, -3.747318037197811],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17807.682,
        Luas: 1.781,
        PERIMETRO: 567.597,
        Nm_Pemilik: "Dian Paskalis",
        No_Kav: "1",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847068220182052, -3.738043119732323],
              [114.847760698235263, -3.738234457439851],
              [114.848145514415307, -3.738152501498906],
              [114.848270027075358, -3.738427424125809],
              [114.848427082276345, -3.738765348963693],
              [114.848636384105617, -3.739095777513767],
              [114.847808582789384, -3.739229484295677],
              [114.847226628104565, -3.73915382271185],
              [114.846977854071994, -3.739200837391677],
              [114.846980736920415, -3.73802796286069],
              [114.847068220182052, -3.738043119732323],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20157.268,
        Luas: 2.016,
        PERIMETRO: 560.994,
        Nm_Pemilik: "Marina Dwi M",
        No_Kav: "8",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84744895296447, -3.739276368539036],
              [114.84785520842523, -3.73930882367199],
              [114.84867354431023, -3.739174894983715],
              [114.848729643660249, -3.739315248602334],
              [114.848785481319808, -3.739859879787851],
              [114.848663428536071, -3.740421088500658],
              [114.848611211277927, -3.740536246479052],
              [114.847445880278869, -3.740535340779876],
              [114.847445888361776, -3.740532029527737],
              [114.84744895296447, -3.739276368539036],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20041.967,
        Luas: 2.004,
        PERIMETRO: 563.66,
        Nm_Pemilik: "Noor Riwandi",
        No_Kav: "7",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84607067828712, -3.739484376775309],
              [114.846189814267873, -3.739473408870477],
              [114.846692400032154, -3.739343691806901],
              [114.847198735299131, -3.739248648266924],
              [114.84744895296447, -3.739276368539036],
              [114.847445888361776, -3.740532029527737],
              [114.847236148158046, -3.740531512831636],
              [114.846068127938324, -3.740528634487281],
              [114.84607067828712, -3.739484376775309],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20678.282,
        Luas: 2.068,
        PERIMETRO: 624.236,
        Nm_Pemilik: "Marlise Dorkas",
        No_Kav: "10",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847231263130894, -3.742531985155917],
              [114.846540263784732, -3.741934648076661],
              [114.846065720926305, -3.741239618152588],
              [114.846066496788083, -3.741196366159199],
              [114.846068127938324, -3.740528634487281],
              [114.847236148158046, -3.740531512831636],
              [114.847231263130894, -3.742531985155917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10310.775,
        Luas: 1.031,
        PERIMETRO: 901.758,
        Nm_Pemilik: "M Randi Ginting",
        No_Kav: "5",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844212422485668, -3.740045759791804],
              [114.844512713406061, -3.740061338221765],
              [114.844881192769492, -3.739793516250129],
              [114.844978162009468, -3.739693669202297],
              [114.844987960296109, -3.73968353952258],
              [114.844992356399928, -3.739679018202135],
              [114.844988633428699, -3.741202472880111],
              [114.844983610232788, -3.74120563596917],
              [114.844978248295362, -3.741210240654806],
              [114.844720926796853, -3.740692677369987],
              [114.844400944378265, -3.740517414371229],
              [114.844401567764407, -3.740278019574269],
              [114.844212479052601, -3.74004586145248],
              [114.843921198835005, -3.740015970323546],
              [114.843749388009883, -3.740049860202945],
              [114.843743281459723, -3.740051072454988],
              [114.843630148432482, -3.740080735341631],
              [114.843553990745917, -3.740120816983855],
              [114.843258882873926, -3.74017068205892],
              [114.843117002046256, -3.74017033081899],
              [114.842340804579294, -3.739409348594576],
              [114.842958709826959, -3.739696402286247],
              [114.843125697421897, -3.739780788713064],
              [114.843526608519426, -3.739924540827077],
              [114.843921195169358, -3.740015969393341],
              [114.844212422485668, -3.740045759791804],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20064.174,
        Luas: 2.006,
        PERIMETRO: 634.781,
        Nm_Pemilik: "Eti Nanda Ningsi",
        No_Kav: "6",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844992356399928, -3.739679018202135],
              [114.845157927448028, -3.739508731034018],
              [114.845863841267686, -3.739503418577878],
              [114.84607067828712, -3.739484376775309],
              [114.846068127938324, -3.740528634487281],
              [114.846066496788083, -3.741196366159199],
              [114.845730896356301, -3.740829089859827],
              [114.845363076473916, -3.741081066255199],
              [114.845105112256121, -3.74112912654753],
              [114.844988633428699, -3.741202472880111],
              [114.844992356399928, -3.739679018202135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 9615.027,
        Luas: 0.962,
        PERIMETRO: 457.448,
        Nm_Pemilik: "M Widiawan F",
        No_Kav: "16",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.849918034737982, -3.74400509263267],
              [114.850244315936152, -3.74267032437247],
              [114.851089489471221, -3.744007978168805],
              [114.849918034737982, -3.74400509263267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20133.357,
        Luas: 2.013,
        PERIMETRO: 676.57,
        Nm_Pemilik: "Suhasri Said",
        No_Kav: "18",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851845661531101, -3.745100802932718],
              [114.850933785695247, -3.743640602612363],
              [114.850889755271822, -3.743570405066162],
              [114.85158972115839, -3.74357212770045],
              [114.853161036645062, -3.743575992706004],
              [114.852852680110161, -3.743874611325573],
              [114.852505084981203, -3.744181840648166],
              [114.851845661531101, -3.745100802932718],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10888.433,
        Luas: 1.089,
        PERIMETRO: 435.982,
        Nm_Pemilik: "M Widiawan F",
        No_Kav: "16",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850889755271822, -3.743570405066162],
              [114.850878752984073, -3.743552864157273],
              [114.850283099914691, -3.742613442048584],
              [114.850292377762997, -3.742542579072265],
              [114.85109333459863, -3.742658882229232],
              [114.851591324335217, -3.742914578229583],
              [114.85158972115839, -3.74357212770045],
              [114.850889755271822, -3.743570405066162],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20591.751,
        Luas: 2.059,
        PERIMETRO: 639.956,
        Nm_Pemilik: "Solistiana B",
        No_Kav: "17",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851591324335217, -3.742914578229583],
              [114.851800364234265, -3.743021910998507],
              [114.852115577755413, -3.742574504569022],
              [114.853003133255513, -3.742649859528689],
              [114.853858409951911, -3.742894346038423],
              [114.853186144926795, -3.743552402362153],
              [114.853168586093091, -3.743569702825394],
              [114.853161036645062, -3.743575992706004],
              [114.85158972115839, -3.74357212770045],
              [114.851591324335217, -3.742914578229583],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20198.938,
        Luas: 2.02,
        PERIMETRO: 610.639,
        Nm_Pemilik: "Yulianto",
        No_Kav: "20",
        KET: "Bebas Kawasan",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848019562335054, -3.748032883679895],
              [114.848014129519456, -3.74802668382888],
              [114.847142261777691, -3.747034308729782],
              [114.848460468682816, -3.746703316684843],
              [114.849297739917162, -3.747566887474715],
              [114.849415792494469, -3.747660544346723],
              [114.849423570892554, -3.747666639863792],
              [114.848019562335054, -3.748032883679895],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20201.829,
        Luas: 2.02,
        PERIMETRO: 759.43,
        Nm_Pemilik: "M Ruzaidin N",
        No_Kav: "21",
        KET: "Bebas Kawasan",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.849906995550811, -3.748045473863718],
              [114.850618709967463, -3.748603210901798],
              [114.850729323899884, -3.748691138224062],
              [114.850944156483266, -3.748780989562931],
              [114.849861109875462, -3.749149151903803],
              [114.849860821962565, -3.749148882046451],
              [114.849604411135743, -3.748908963292745],
              [114.849203894849651, -3.748534188170038],
              [114.848631965775112, -3.748042329371638],
              [114.848624706056938, -3.748036085613812],
              [114.848108476467687, -3.748164379380539],
              [114.848044241653469, -3.748061047362103],
              [114.848019562335054, -3.748032883679895],
              [114.849423570892554, -3.747666639863792],
              [114.849906995550811, -3.748045473863718],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13043.621,
        Luas: 1.304,
        PERIMETRO: 480.03,
        Nm_Pemilik: "Ries Aryanto",
        No_Kav: "24",
        KET: "Masuk SK-435",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850944156483266, -3.748780989562931],
              [114.850946999557067, -3.748782178646564],
              [114.851626522701025, -3.749061844883475],
              [114.851444531981926, -3.749320392420526],
              [114.850930441709494, -3.749631817645641],
              [114.850593823574386, -3.749929035617709],
              [114.850043525116646, -3.749432140834059],
              [114.849861109875462, -3.749149151903803],
              [114.850944156483266, -3.748780989562931],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20132.535,
        Luas: 2.013,
        PERIMETRO: 918.708,
        Nm_Pemilik: "Odieta Jesse",
        No_Kav: "19",
        KET: "Bebas Kawasan",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848545648064686, -3.746682882262085],
              [114.849247959000451, -3.74657479296736],
              [114.850975423492784, -3.746022654929964],
              [114.851115159327961, -3.745930796565402],
              [114.851873344207604, -3.745158161925618],
              [114.851590094250739, -3.745621334353248],
              [114.850753780163942, -3.746791436834098],
              [114.850746199062783, -3.746802037766288],
              [114.850745663445622, -3.74680278746205],
              [114.849107353621719, -3.747264929150945],
              [114.849105777825585, -3.747263287778541],
              [114.849094115437993, -3.747251204200108],
              [114.848545648064686, -3.746682882262085],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20175.213,
        Luas: 2.018,
        PERIMETRO: 720.963,
        Nm_Pemilik: "Nirwati Ginting",
        No_Kav: "22",
        KET: "Bebas Kawasan",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850745663445622, -3.74680278746205],
              [114.850419819112204, -3.747258866747885],
              [114.850590023329474, -3.747443044777212],
              [114.850938609960082, -3.747396549512658],
              [114.850941393104208, -3.748349795627028],
              [114.85094240373904, -3.748695994649335],
              [114.850794701424832, -3.748634127800698],
              [114.849358732515242, -3.747526769034769],
              [114.849107353621719, -3.747264929150945],
              [114.850745663445622, -3.74680278746205],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 7177.644,
        Luas: 0.718,
        PERIMETRO: 383.356,
        Nm_Pemilik: "Ries Aryanto",
        No_Kav: "24",
        KET: "Masuk SK-435",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.852328735304937, -3.748349733804567],
              [114.852102964316217, -3.748642201097885],
              [114.851684703418698, -3.748917598463209],
              [114.851650726784541, -3.74899268370187],
              [114.85094240373904, -3.748695994649335],
              [114.850941393104208, -3.748349795627028],
              [114.852328735304937, -3.748349733804567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20284.291,
        Luas: 2.028,
        PERIMETRO: 556.272,
        Nm_Pemilik: "M Faried",
        No_Kav: "23",
        KET: "Masuk SK-435",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850938609960082, -3.747396549512658],
              [114.851658713820868, -3.747279003649282],
              [114.852209955514056, -3.747254962283668],
              [114.852492758686012, -3.747408051912911],
              [114.85266393042356, -3.747718339794172],
              [114.85253171254584, -3.748022800900908],
              [114.852328735304937, -3.748349733804567],
              [114.850941393104208, -3.748349795627028],
              [114.850938609960082, -3.747396549512658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20394.661,
        Luas: 2.039,
        PERIMETRO: 643.711,
        Nm_Pemilik: "Sotardodo N.",
        No_Kav: "12",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847107960727882, -3.746354259286139],
              [114.846815228257952, -3.745488709211258],
              [114.847234382550127, -3.745682299213986],
              [114.847270754591307, -3.74563815312692],
              [114.847596722688934, -3.745805492078981],
              [114.84770258130186, -3.745782887329445],
              [114.848163466613087, -3.745332639434399],
              [114.848451938691596, -3.745536552399958],
              [114.848449288455782, -3.746621145599753],
              [114.847238391643941, -3.746906713745924],
              [114.847152090623695, -3.7467095411904],
              [114.847136509293478, -3.746509314317078],
              [114.847107960727882, -3.746354259286139],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20022.498,
        Luas: 2.002,
        PERIMETRO: 738.279,
        Nm_Pemilik: "Rita Magdalena",
        No_Kav: "13",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.848451942389175, -3.745536553920997],
              [114.848603460496278, -3.745523461857988],
              [114.849605507442973, -3.7452835630991],
              [114.849840326339702, -3.744322969725717],
              [114.849835494713417, -3.746301966970599],
              [114.849698725896886, -3.746343995375148],
              [114.849238499273525, -3.746502937427991],
              [114.848449291176181, -3.746621142827262],
              [114.848451942389175, -3.745536553920997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20119.14,
        Luas: 2.012,
        PERIMETRO: 622.88,
        Nm_Pemilik: "M Joworahmadin",
        No_Kav: "14",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851748082735256, -3.745050325687913],
              [114.851799863165255, -3.745132278043571],
              [114.851081046894436, -3.745860125160215],
              [114.850923294833365, -3.745967690130069],
              [114.849835500482811, -3.746301960404666],
              [114.849838568230467, -3.745045623502347],
              [114.851748082735256, -3.745050325687913],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20046.749,
        Luas: 2.005,
        PERIMETRO: 595.051,
        Nm_Pemilik: "M Rahdianto M",
        No_Kav: "15",
        KET: "Bebas Kawasan",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.851089491718497, -3.744007979700759],
              [114.851748081661754, -3.745050324204886],
              [114.849838567954805, -3.74504561831615],
              [114.849840332356123, -3.744322967608775],
              [114.849918033975669, -3.744005095390967],
              [114.851089491718497, -3.744007979700759],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20632.627,
        Luas: 2.063,
        PERIMETRO: 574.948,
        Nm_Pemilik: "Amran Ginting",
        No_Kav: "2",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845736764866757, -3.737947392873729],
              [114.845836018204835, -3.737985478151102],
              [114.846577660813224, -3.737959472800791],
              [114.846980734818359, -3.738027960738655],
              [114.846977844553081, -3.739200836671488],
              [114.846656423269863, -3.739261560439757],
              [114.846151874247312, -3.739383136491787],
              [114.845733129390595, -3.739435741063311],
              [114.845736764866757, -3.737947392873729],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20546.742,
        Luas: 2.055,
        PERIMETRO: 616.645,
        Nm_Pemilik: "Dina Heruwati",
        No_Kav: "3",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844715807279528, -3.737949962044671],
              [114.845050664209808, -3.737824098226456],
              [114.845440999236786, -3.737833902252877],
              [114.845736753920278, -3.737947393215778],
              [114.845733129390595, -3.739435741063311],
              [114.845728550470639, -3.739436324708524],
              [114.845479678058012, -3.73943256577612],
              [114.845100829690537, -3.739426865610092],
              [114.844784084547612, -3.739744160776422],
              [114.844711302498538, -3.739793874899159],
              [114.844715807279528, -3.737949962044671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12464.611,
        Luas: 1.246,
        PERIMETRO: 514.256,
        Nm_Pemilik: "M Randi Ginting",
        No_Kav: "5",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842322422537606, -3.739292090304644],
              [114.842489506550223, -3.739241883402898],
              [114.84258452669674, -3.739192635902153],
              [114.842942275371854, -3.738997284964251],
              [114.843310932253033, -3.738736698726661],
              [114.843754639022762, -3.7384592685065],
              [114.843751163925361, -3.739882169156119],
              [114.843560867690698, -3.739839884389467],
              [114.843162632099407, -3.73969689740414],
              [114.842322422537606, -3.739292090304644],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20576.514,
        Luas: 2.058,
        PERIMETRO: 595.909,
        Nm_Pemilik: "M. Iqbal Ginting",
        No_Kav: "4",
        KET: "Bebas Kawasan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84375464360869, -3.738459263988424],
              [114.843989664403168, -3.738312314796855],
              [114.844144613199958, -3.738215937427366],
              [114.844346098925286, -3.738088925358695],
              [114.844715803086245, -3.737949963369594],
              [114.844711295433029, -3.739793879730852],
              [114.844459704353199, -3.739965730084763],
              [114.844143903484877, -3.739941903816111],
              [114.843957137679652, -3.739927941572443],
              [114.84375116553754, -3.739882171469891],
              [114.84375464360869, -3.738459263988424],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 1256.756,
        Luas: 0.126,
        PERIMETRO: 254.781,
        Nm_Pemilik: "M Ariep Rahman",
        No_Kav: "9",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847905381878434, -3.741763162455731],
              [114.847963794419528, -3.741866438169706],
              [114.847875114490094, -3.742146148860642],
              [114.847803616847031, -3.742396435622428],
              [114.84740193418645, -3.742726940672391],
              [114.84742160892344, -3.742677878801896],
              [114.847553915574807, -3.74255051794084],
              [114.847693789086478, -3.742327410453279],
              [114.847740813290301, -3.742089341005491],
              [114.847905381878434, -3.741763162455731],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19274.272,
        Luas: 1.927,
        PERIMETRO: 671.604,
        Nm_Pemilik: "M Ariep Rahman",
        No_Kav: "9",
        KET: "Bebas Kawasan",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847445888361776, -3.740532029527737],
              [114.847445880278869, -3.740535340779876],
              [114.848611211277927, -3.740536246479052],
              [114.848399467833531, -3.741003216933124],
              [114.848217354183674, -3.741132862447927],
              [114.847968651642134, -3.741499525758158],
              [114.847861593459896, -3.741677111407734],
              [114.847699469241391, -3.742003295972768],
              [114.84767238956259, -3.742081805845964],
              [114.847632746357007, -3.742300249319727],
              [114.847510019260639, -3.742508665939966],
              [114.84738097209565, -3.742652228324298],
              [114.847231263130894, -3.742531985155917],
              [114.847236148158046, -3.740531512831636],
              [114.847445888361776, -3.740532029527737],
            ],
          ],
        ],
      },
    },
  ],
};
