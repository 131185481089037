import React, { Fragment } from "react";
import TableRow from "./partials/TableRow";
import Tables from "../../../../../components/organisms/Tables";
import { headersData } from "./api/headerData";

const CustomHeader = () => {
  const className = "border-collapse border border-gray-10 font-medium text-14";
  return (
    <thead>
      <tr>
        <th rowSpan="2" className={className}>
          UNIT
        </th>
        {headersData?.map((item, index) => (
          <th key={index} colSpan="2" className={className}>
            {item?.value}
          </th>
        ))}
      </tr>
      <tr>
        {headersData?.map((_, index) => (
          <Fragment key={index}>
            <th className={className}>KG</th>
            <th className={className}>JANJANG</th>
          </Fragment>
        ))}
      </tr>
    </thead>
  );
};

const ListHistoryHarvest = ({ isLoading, data, handleRefreshData }) => {
  return (
    <div className="mx-16 rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
      <Tables
        customHeader={<CustomHeader />}
        isLoading={isLoading}
        headers={headersData}
        rows={data || []}
        rowContent={(data) => (
          <TableRow data={data} handleRefreshData={handleRefreshData} />
        )}
      />
    </div>
  );
};

export default ListHistoryHarvest;
