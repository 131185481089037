import React, { useRef, useState } from "react";

import Cards from "./partials/Cards";
import { DataProvider } from "./hooks/DataContext";
import Filter from "./partials/Filter/Filter";
import GardenProductionComponent from "../GardenProduction/components";
import PksProductionComponent from "./components";
import { TbFilter } from "react-icons/tb";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import { ConfigProvider, Modal } from "antd";
import ModalFilter from "./partials/ModalFilter";

export const pagePath = "penerimaan_pks";

const PksProduction = () => {
  const componentRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <DataProvider>
      <div
        ref={componentRef}
        className="relative grid w-full gap-13 bg-gray-30 pt-42 pb-16 md:grid-rows-[minmax(0px,50px)_minmax(0px,50px)_minmax(0px,0.5fr)_minmax(0px,0.5fr)] md:py-16 md:pb-0"
      >
        <ConfigProvider
          theme={{
            token: { colorPrimary: "#D7A137" },
          }}
        >
          <Modal
            title="Filter"
            zIndex={10}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={showModal}
            wrapClassName="backdrop-blur-sm"
            footer={
              <div className="flex flex-row items-center justify-end space-x-16 pt-16">
                <ButtonComponent
                  value="Tutup"
                  type="submit"
                  size="none"
                  className="button-transition px-16 py-8 text-16"
                  onClick={handleOk}
                />
              </div>
            }
          >
            <ModalFilter />
          </Modal>
        </ConfigProvider>
        <GardenProductionComponent.Heading />
        <Filter ref={componentRef} />
        <Cards />
        <div className="h-full w-full px-16 md:px-[2%]">
          <PksProductionComponent.ComparisonChart title="Produksi Kebun vs Penerimaan PKS vs Pinalti" />
        </div>
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition fixed right-16 bottom-16 z-10 -ml-8 w-fit rounded-16 bg-red-mobile p-10 text-16 text-white md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-white" />}
        />
      </div>
    </DataProvider>
  );
};

export default PksProduction;
