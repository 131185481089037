import { faker } from "@faker-js/faker";

export const DataPremiRows = () => {
	let data = [];

	Array.from(Array(faker.datatype.number({ min: 6, max: 10 })).keys()).map(
		(item, index) =>
			data.push({
				id: index + 1,
				kebun: `${faker.name.firstName()} ${faker.name.lastName()}`,
				block: `A${faker.datatype.number({ min: 1, max: 10 })}`,
				total_pokok: faker.datatype.number({ min: 10, max: 35 }),
				total_premi: faker.datatype.number({ min: 10000, max: 35000 }),
			})
	);

	return data;
};
