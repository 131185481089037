import ButtonComponent from "../../../atoms/ButtonComponent";
import React from "react";
import { TbLicense } from "react-icons/tb";

const BillingCurrentPlan = ({ navigateToPlans }) => {
	return (
		<div className="mx-16 flex flex-col items-center justify-between space-y-16 rounded-16 border-1 border-gray-10 bg-white p-24 md:mx-32 md:flex-row md:space-y-0">
			<div className="flex flex-col items-center space-x-13 space-y-8 md:flex-row">
				<TbLicense className="text-24 text-gray-500" />
				<h3 className="font-semi-bold text-[20px] text-gray-500">
					Paket Gratis
				</h3>
				<p className="font-regular text-14 text-gray-400">
					Mulai 1 Agustus 2022
				</p>
			</div>
			<ButtonComponent
				value="Tingkatkan"
				size="sm"
				type="button"
				className="button-transition"
				onClick={navigateToPlans}
			/>
		</div>
	);
};

export default BillingCurrentPlan;
