import React, { useEffect, useState } from "react";

import InputHook from "../../../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../../../components/atoms/SelectHook";
import InputOption from "../../../../../../../components/atoms/SelectHook/partials/InpuOptions";
import { Tooltip } from "antd";
import { TbInfoCircle } from "react-icons/tb";
import useFilterDataDefaults from "../../../../../../../hooks/useFilterDataDefaults";
import { modalMemberName } from "../../../../ListView";
import { useSelector } from "react-redux";
import { selectModal } from "../../../../../../../features/modalSlice";

const dataPositions = [
  {
    label: "Admin/Manager Estate/Manager Kebun",
    value: "Admin",
  },
  { label: "Sr. Asisten", value: "Sr. Asisten" },
  { label: "Mandor Wilayah", value: "Mandor Wilayah" },
  { label: "Mandor Transport", value: "Mandor Transport" },
  { label: "Kasie", value: "Kasie" },
  { label: "Admin Tanaman", value: "Admin Tanaman" },
];

const accessControlData = [
  { label: "Pilih semua", value: "all" },
  { label: "Panen", value: "Harvesting" },
  {
    label: "Rawat (Pupuk, Racun, Pruning)",
    value: "Treatment",
  },
  {
    label: "Transport",
    value: "Transport",
  },
];

const Content = ({
  register,
  control,
  errors,
  watch,
  isEditable,
  setValue,
}) => {
  const {
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
  } = useFilterDataDefaults();
  const modal = useSelector(selectModal);
  const state = modal[modalMemberName]?.params?.item;

  const watchEstate = watch("estate");
  const watchAfdeling = watch("afdeling");
  const watchPosition = watch("position");

  const regexPattern = /^[0-9+]+$/;

  const onSearchEstate = async (searchTerm) => {
    await fetchEstates({
      name: searchTerm,
      params: {
        pageSize: 99999,
      },
    });
  };

  const onSearchAfdeling = async (searchTerm) => {
    await fetchAfdelings({
      estateId: watchEstate?.value,
      name: searchTerm,
      params: {
        pageSize: 99999,
      },
    });
  };

  const onSearchBlock = async (searchTerm) => {
    await fetchBlocks({
      afdelingId: watchAfdeling.value,
      name: searchTerm,
      defaultData: [{ label: "Choose all", value: "all" }],
      params: {
        pageSize: 99999,
      },
    });
  };

  const [tooltipUsername, setTooltipUsername] = useState(false);
  const [tooltipPassword, setTooltipPassword] = useState(false);

  useEffect(() => {
    if (isEditable) {
      if (state?.defaultEstateId) {
        fetchAfdelings({ estateId: state?.defaultEstateId });
      }
      if (state?.defaultAfdelingId) {
        fetchBlocks({
          afdelingId: state?.defaultAfdelingId,
          defaultData: [{ label: "Choose all", value: "all" }],
        });
      }
    }
  }, [isEditable]);

  return (
    <div className="grid grid-cols-1 gap-13">
      <InputHook
        register={register}
        label="Nama Lengkap"
        required
        name="fullname"
        placeholder="Masukkan nama lengkap"
        errors={errors?.fullname}
        inputClassName="input-large-border"
      />
      <InputHook
        register={register}
        label="Email"
        required
        name="email"
        placeholder="Masukkan email"
        errors={errors?.email}
        inputClassName="input-large-border"
      />
      <InputHook
        register={register}
        label="Nomor Handphone"
        name="phone"
        placeholder="Contoh: diawali dengan +62 atau 08"
        errors={errors?.phone}
        inputClassName="input-large-border"
      />
      <SelectHook
        label="Jabatan"
        required
        name="position"
        errors={errors?.position}
        data={dataPositions}
        control={control}
        placeholder="Pilih jabatan"
      />
      <SelectHook
        label="Estate/Kebun"
        name="estate"
        errors={errors?.estate}
        data={estates?.filter((item) => {
          return item.label !== "Semua estate";
        })}
        control={control}
        placeholder="Pilih estate atau kebun"
        onInputChange={(searchValue) => {
          onSearchEstate(searchValue);
        }}
        afterOnChange={(data) => {
          fetchAfdelings({
            estateId: data?.value || state?.defaultEstateId,
            params: {
              pageSize: 99999,
            },
          });
        }}
      />
      {watchPosition?.value !== "Sr. Asisten" &&
      watchPosition?.value !== "Admin" ? (
        <>
          {watchEstate && (
            <SelectHook
              label="Afdeling/Wilayah"
              name="afdeling"
              errors={errors?.afdeling}
              data={afdelings?.filter((item) => {
                return item.label !== "Semua afdeling";
              })}
              control={control}
              placeholder="Pilih afdeling atau wilayah"
              onInputChange={(searchValue) => {
                onSearchAfdeling(searchValue);
              }}
              afterOnChange={(data) => {
                fetchBlocks({
                  afdelingId: data?.value || state?.defaultAfdelingId,
                  defaultData: [{ label: "Choose all", value: "all" }],
                  params: {
                    pageSize: 99999,
                  },
                });
              }}
            />
          )}
          {watchAfdeling && (
            <SelectHook
              label="Wilayah Kerja"
              name="workingArea"
              errors={errors?.workingArea}
              data={blocks?.filter((item) => {
                return item.label !== "Semua blok";
              })}
              control={control}
              placeholder="Pilih wilayah kerja"
              afterOnChange={(item) => {
                const all = item.find((opt) => opt.value === "all");
                if (all) {
                  setValue("workingArea", blocks.slice(1));
                }
              }}
              components={{ Option: InputOption }}
              isMulti
              onInputChange={(searchValue) => {
                onSearchBlock(searchValue);
              }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
            />
          )}
          <SelectHook
            label="Kontrol Akses"
            required
            name="accessControl"
            errors={errors?.accessControl}
            data={accessControlData}
            control={control}
            placeholder="Pilih kontrol akses"
            afterOnChange={(item) => {
              const all = item.find((opt) => opt.value === "all");
              if (all) {
                setValue("accessControl", accessControlData?.slice(1));
              }
            }}
            components={{ Option: InputOption }}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </>
      ) : null}

      <InputHook
        register={register}
        label="Username"
        secondaryLabel={
          <Tooltip
            open={tooltipUsername}
            title="Perhatian! Username tidak dapat diubah setelah didaftarkan. pastikan username mudah diingat dan sesuai."
          >
            <div
              onMouseEnter={() => setTooltipUsername(true)}
              onMouseLeave={() => setTooltipUsername(false)}
            >
              <TbInfoCircle className="ml-8 text-16 text-red-600" />
            </div>
          </Tooltip>
        }
        secondaryLabelClassName="text-gray-200 text-regular"
        required
        disabled={isEditable}
        name="username"
        placeholder="Contoh: herman"
        errors={errors?.username}
        inputClassName="input-large-border"
        onFocus={() => {
          setTooltipUsername(true);
        }}
        onBlur={() => {
          setTooltipUsername(false);
        }}
      />
      {!isEditable && (
        <InputHook
          register={register}
          label="Kata Sandi"
          secondaryLabel={
            <Tooltip
              open={tooltipPassword}
              title="Perhatian! Pastikan Anda memilih dan mengingat password anda. Min 8 karakter"
            >
              <div
                onMouseEnter={() => setTooltipPassword(true)}
                onMouseLeave={() => setTooltipPassword(false)}
              >
                <TbInfoCircle className="ml-8 text-16 text-red-600" />
              </div>
            </Tooltip>
          }
          onFocus={() => {
            setTooltipPassword(true);
          }}
          onBlur={() => {
            setTooltipPassword(false);
          }}
          required
          type="password"
          name="password"
          placeholder="Masukkan password"
          errors={errors?.password}
          inputClassName="input-large-border"
        />
      )}
    </div>
  );
};

export default Content;
