import React, { useEffect, useState } from "react";
import { create } from "zustand";
import ModalContainer from "../../../../../../../components/atoms/Modal";
import Content from "./partials/Content";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { usePriceListStore } from "../../../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import { useUserStore } from "../../../../../../MasterWorkerPage/hooks/userStore";
import moment from "moment/moment";
dayjs.extend(customParseFormat);
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "./schema/formSchema";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../../../../../../helpers/Common";
import { usePartiesStore } from "../../../../../../../api/parties/usePartiesStore";
import { omitBy } from "lodash";
export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
    type: "update",
  },
  toggleModal: ({ data, type }) =>
    set((state) => ({
      modal: {
        ...state.modal,
        isOpen: !state.modal.isOpen,
        data: data || {},
        type: type || "update",
      },
    })),
}));

const ModalUpdateHarga = ({ refreshHandler }) => {
  const [isOpen, toggleModal, data, type] = useModalStore((state) => [
    state.modal.isOpen,
    state.toggleModal,
    state.modal.data,
    state.modal.type,
  ]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm({ resolver: yupResolver(formSchema) });

  const [getPriceLists, createPriceList, updatePriceList] = usePriceListStore(
    (state) => [
      state.getPriceLists,
      state.createPriceList,
      state.updatePriceList,
    ]
  );
  const [priceLists, setPriceLists] = useState([]);
  const [user] = useUserStore((state) => [state.user]);
  const [getParties] = usePartiesStore((state) => [state.getParties]);
  const [parties, setParties] = useState([]);

  const onSubmit = async (inputData) => {
    let body = {
      companyId: data?.companyId,
      companyName: data?.companyName,
      buyerPartyId: data?.buyerPartyId,
      buyerPartyName: data?.buyerPartyName,
      buyerPartyPrice: inputData?.buyerPartyPrice,
      maxPurchasePrice: inputData?.maxPurchasePrice,
      minPurchasePrice: inputData?.minPurchasePrice,
      startDate: moment(inputData?.startDate).format("YYYY-MM-DD"),
    };

    if (type === "create") {
      body = {
        ...body,
        buyerPartyId: inputData?.buyerParty?.value,
        buyerPartyName: inputData?.buyerParty?.label,
        companyId: user?.companyId,
        companyName: user?.companyName,
      };

      const priceListFound = priceLists?.filter(
        (item) => item?.buyerPartyName === inputData?.buyerParty?.label
      );

      if (priceListFound?.length >= 1) {
        // console.log("priceListFound Create & Update");
        await createPriceList({
          body,
          silent: true,
          onSuccess: () => {
            updatePriceList({
              id: priceListFound[0]?.id,
              body: {
                ...priceListFound[0],
                endDate: moment().format("YYYY-MM-DD"),
              },
              silent: true,
              onSuccess: (data) => {
                const priceListData = data.record;

                const toastMessage = `Berhasil memberlakukan harga pada PKS ${
                  priceListData?.buyerPartyName
                }. Harga mulai berlaku tanggal ${moment(
                  inputData?.startDate
                ).format("LL")}`;
                toast.success(toastMessage, {
                  duration: calculateToastDuration(toastMessage),
                  position: "top-right",
                  className: "font-regular text-16",
                });

                toggleModal({ data: {}, type: "update" });
                refreshHandler(true);
              },
            });
          },
        });
      } else {
        // console.log("priceListFound Create");
        await createPriceList({
          body,
          silent: true,
          onSuccess: () => {
            const toastMessage = `Berhasil memberlakukan harga pada PKS ${
              inputData?.buyerParty?.label
            }. Harga mulai berlaku tanggal ${moment(
              inputData?.startDate
            ).format("LL")}`;
            toast.success(toastMessage, {
              duration: calculateToastDuration(toastMessage),
              position: "top-right",
              className: "font-regular text-16",
            });

            toggleModal({ data: {}, type: "update" });
            refreshHandler(true);
          },
        });
      }
    } else if (type === "update") {
      // console.log("priceListFound Update");
      body = omitBy(body, (_, key) => key === "buyerParty");
      await createPriceList({
        body,
        silent: true,
        onSuccess: () => {
          updatePriceList({
            id: data?.id,
            body: {
              ...data,
              endDate: moment().format("YYYY-MM-DD"),
            },
            silent: true,
            onSuccess: (data) => {
              const priceListData = data.record;

              const toastMessage = `Berhasil memberlakukan harga pada PKS ${
                priceListData?.buyerPartyName
              }. Harga mulai berlaku tanggal ${moment(
                inputData?.startDate
              ).format("LL")}`;
              toast.success(toastMessage, {
                duration: calculateToastDuration(toastMessage),
                position: "top-right",
                className: "font-regular text-16",
              });

              toggleModal({ data: {}, type: "update" });
              refreshHandler(true);
            },
          });
        },
      });
    }
  };

  useEffect(() => {
    getParties({
      params: {
        companyId: user?.companyId,
        pageSize: 9999,
        fltAllTags: "pt.pabrik",
      },
      silent: true,
      onSuccess: (dataParties) => {
        setParties(dataParties?.records);
        getPriceLists({
          params: {
            fltCompany: user?.companyId,
            pageSize: 9999,
            fltEndDateIsNull: 1,
          },
          silent: true,
          onSuccess: (dataPrice) => {
            setPriceLists(dataPrice?.records);
          },
        });
      },
    });
  }, [isOpen]);

  useEffect(() => {
    const tomorrow = dayjs().add(1, "day");
    setValue("startDate", tomorrow);
    setValue("buyerPartyPrice", null);
    setValue("maxPurchasePrice", null);
    setValue("minPurchasePrice", null);
    setValue("buyerParty", null);
    clearErrors();
  }, [isOpen]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ data: {} })}
      title={
        <h1 className="flex flex-row items-center space-x-8">
          {type === "create"
            ? "Tambah Daftar PKS"
            : `Update Harga PKS ${data?.buyerPartyName}`}
        </h1>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            isCreate={type === "create"}
            parties={parties}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ data: {} })}
          type="Berlakukan Harga"
        />
      </form>
    </ModalContainer>
  );
};

export default ModalUpdateHarga;
