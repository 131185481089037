import axiosInstance from "../api";
import { ESTATES_URL } from "../../configs/api";

export const getEstatesApi = (params) =>
  axiosInstance.get(ESTATES_URL, {
    params,
  });
export const updateEstateApi = (id, body) =>
  axiosInstance.put(`${ESTATES_URL}${id}`, body);
export const createEstateApi = (body) => axiosInstance.post(ESTATES_URL, body);
