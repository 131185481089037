import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { TbX } from "react-icons/tb";

const IndicatorSeparator = ({ innerProps }) => {
  return <span {...innerProps} />;
};
const CustomClearText = () => (
  <TbX className="text-16 text-gray-200 hover:text-red-500" />
);
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={{ ...getStyles("clearIndicator", props), marginRight: -16 }}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const SelectHook = ({
  label,
  data,
  name,
  required = false,
  onBlur,
  control,
  background,
  errors,
  className,
  placeholder,
  afterOnChange,
  disabled,
  onInputChange,
  menuStyles,
  components,
  ...attrs
}) => {
  const styles = {
    menuList: (base) => ({
      ...base,
      margin: 8,
      ...(menuStyles?.menuList || {}),

      "::-webkit-scrollbar": {
        width: "0",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "0 8px 0 0 ",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: 99,
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: 99,
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        fontFamily: "Inter-Regular",
        fontSize: 15,
        padding: "8px 16px",
        borderRadius: 8,
        ...(menuStyles?.option || {}),
        // ":active": {
        //   backgroundColor:
        //     state.label === isSelected ? provided.backgroundColor : "#CCCC",
        // },
        // backgroundColor:
        //   state.label === isSelected ? provided.backgroundColor : "transparent",
        // fontFamily:
        //   state.label === isSelected ? "Inter-Semibold" : "Inter-Regular",
        // color: state.label === isSelected ? "#FFFFFF" : "#1d1b1d",
      };
    },
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Inter-Regular",
      fontSize: 15,
      color: "#080808",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor:
        background && !disabled ? "#f2f2f2" : disabled ? "#FAFAFA" : "#FFFFFF",
      border: background && "none",
      fontFamily: "Inter-Regular",
      fontSize: 15,
      width: "100%",
      borderRadius: 8,
    }),
    input: (provided) => ({
      ...provided,
      padding: "8px",
      ...(menuStyles?.input || {}),
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 999,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      borderRadius: "8px 8px 8px 8px",
    }),
  };

  return (
    <div className={`${className} min-w-[160px]`}>
      {label && (
        <p className="mb-8 text-14 font-medium text-gray-900">
          {label}
          {required && <span className="text-red-500"> *</span>}
        </p>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              components={{
                IndicatorSeparator,
                ClearIndicator,
                ...components,
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuPlacement="bottom"
              options={data}
              styles={styles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#f9f9fb",
                  primary50: "#f9f9fb",
                  primary: "#D7A137",
                  neutral20: "#e1e4e8",
                },
              })}
              value={value}
              onBlur={onBlur}
              inputRef={ref}
              onChange={(val) => {
                onChange(val);
                afterOnChange && afterOnChange(val);
              }}
              onInputChange={onInputChange}
              placeholder={placeholder}
              isClearable
              {...attrs}
            />
          );
        }}
      />
      {errors && (
        <p className="error-message mt-4">
          {errors?.message || errors?.label?.message}
        </p>
      )}
    </div>
  );
};

SelectHook.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  errors: PropTypes.string,
  required: PropTypes.bool,
  background: PropTypes.string,
};

export default SelectHook;
