import React, { useEffect } from "react";
import { TbX } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import InputHook from "../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { ConfigProvider, Modal } from "antd";

const ModalFilter = ({ onFilter, isModalOpen, handleOk, showModal }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [afdelings, setAfdelings] = useState([]);
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const [inputNames, setInputNames] = useState({
    name: undefined,
  });
  const watchName = watch("name");
  const watchAfdeling = watch("afdeling");

  const onSubmit = (e) => {
    onFilter({
      value: e.name,
      type: "name",
    });
  };

  useEffect(() => {
    const subscription = watch((v) =>
      setInputNames({
        name: v.name,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleGetAfdelings = ({ name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetAfdelings({});
    return () => (isMounted = false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#D7A137" },
      }}
    >
      <Modal
        title="Filter"
        zIndex={10}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={showModal}
        wrapClassName="backdrop-blur-sm"
        footer={
          <div className="flex flex-row items-center justify-end space-x-16 pt-16">
            <ButtonComponent
              value="Terapkan Filter"
              type="submit"
              size="none"
              className="button-transition px-16 py-8 text-16"
              onClick={() => {
                handleOk();

                onFilter({
                  value: watchName || undefined,
                  type: "name",
                });
                onFilter({
                  value: watchAfdeling?.value || undefined,
                  type: "afdeling",
                });
              }}
            />
          </div>
        }
      >
        <div className="grid grid-cols-1 gap-13">
          <SelectHook
            control={control}
            data={afdelings}
            name="afdeling"
            placeholder="Pilih wilayah"
            className="w-full"
            afterOnChange={(data) => {
              onFilter({
                value: data?.value,
                type: "afdeling",
              });
            }}
            menuStyles={{ padding: 5 }}
          />
          <InputHook
            register={register}
            name="name"
            placeholder="nama truk"
            className="w-full"
            inputClassName="input-mini-border pr-24 pl-8"
            rightIcon={
              <TbX
                className="cursor-pointer text-16 text-gray-500 hover:text-red-600"
                onClick={() => {
                  setValue("name", undefined);
                }}
              />
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit(onSubmit)(event);
              }
            }}
            onSubmit={() => {
              onFilter({
                value: inputNames.name,
                type: "name",
              });
            }}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default ModalFilter;
