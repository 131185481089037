import { useForm } from "react-hook-form";
import { usePriceListStore } from "../../../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  calculateToastDuration,
  formatNumericValue,
} from "../../../../../../../helpers/Common";
import { notification, Tooltip } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import InputHook from "../../../../../../../components/atoms/InputHook";
import { TbPencil } from "react-icons/tb";

const RemarkRow = ({ cell, row, refreshHandler, rowName, id }) => {
  const { control, setValue, watch, handleSubmit } = useForm();
  const watchRemark = watch(`${rowName}-${row.original?.id}`);

  const [updatePriceList] = usePriceListStore((state) => [
    state.updatePriceList,
  ]);

  const [version, setVersion] = useState(row.original?.version);

  const updateFunc = useCallback(
    async ({ value, refreshFunc, silent = false }) => {
      // refreshFunc(true);
      setVersion(async (prev) => {
        let valueToSet = value;
        if (
          cell.column.columnDef.header === "Batas Atas" &&
          id === row?.original?.id
        ) {
          if (value !== 0 && value >= row?.original?.buyerPartyPrice) {
            valueToSet = row?.original?.buyerPartyPrice;
          } else if (value <= row?.original?.minPurchasePrice) {
            valueToSet = row?.original?.minPurchasePrice;
          }
        } else if (
          cell.column.columnDef.header === "Batas Bawah" &&
          id === row?.original?.id
        ) {
          if (value >= row?.original?.maxPurchasePrice) {
            valueToSet = row?.original?.maxPurchasePrice;
          }
        } else if (value <= 1 && id === row?.original?.id) {
          valueToSet = 100;
        }

        // setValue(`${rowName}-${row?.original?.id}`, valueToSet);

        await updatePriceList({
          id: row?.original?.id,
          body: {
            ...row?.original,
            [rowName]: valueToSet,
            version: prev + 1,
          },
          silent: true,
          onSuccess: (data) => {
            setValue(`${rowName}-${row?.original?.id}`, data.record?.[rowName]);
            if (!silent) {
              const toastMessage = `Berhasil Merubah ${cell.column.columnDef.header} milik ${row?.original?.buyerPartyName}`;
              toast.success(toastMessage, {
                duration: calculateToastDuration(toastMessage),
                position: "top-right",
                className: "font-regular text-16",
              });
              refreshFunc(true);
            }
          },
          onError: () => {
            notification.error({
              message: "Gagal Merubah Tanggal Mulai Berlaku",
              placement: "bottomRight",
            });
          },
        });

        return prev + 1;
      });
    },
    [row.original, version]
  );

  const request = debounce(async (searchTerm) => {
    let valueToSet = searchTerm;

    updateFunc({
      value: valueToSet,
      refreshFunc: refreshHandler,
      silent: false,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => request(searchTerm),
    [
      row.original?.buyerPartyPrice,
      row?.original?.minPurchasePrice,
      row?.original?.maxPurchasePrice,
    ]
  );

  useEffect(() => {
    setVersion(row.original?.version);
  }, [version, row?.original?.[rowName], row?.original?.version]);

  useEffect(() => {
    setValue(`${rowName}-${row.original?.id}`, row.original?.[rowName]);
  }, [row?.original, row?.original?.[rowName]]);

  const currentMoment = moment();
  const startMoment = moment(`${row?.original?.startDate} 09:00:00`);

  // Set waktu batasan hingga jam 7 pagi
  const limitTime = moment().startOf("day").hour(9);

  // Periksa apakah saat ini setelah waktu mulai dan sebelum batasan waktu
  const isAfterStart = currentMoment.isAfter(startMoment);

  return isAfterStart ? (
    <Tooltip
      placement="bottom"
      title={`${cell.column.columnDef.header} yang sedang berlaku tidak dapat diubah`}
      className="font-regular"
    >
      <p>
        {formatNumericValue(cell.getValue(), {
          prefix: "Rp",
          decimals: 0,
        })}
      </p>
    </Tooltip>
  ) : (
    <div className="relative flex flex-row items-center space-x-4">
      <InputHook
        control={control}
        name={`${rowName}-${row?.original?.id}`}
        // disabled={moment().isAfter(moment(row?.original?.startDate))}
        placeholder="komentar"
        type="number"
        className=" min-w-[160px] rounded-8 shadow"
        inputClassName="input-mini-border pl-8 pr-24 w-full "
        afterOnChange={debounceTextRequest}
      />
      <div
        onClick={() => {
          debounceTextRequest(watchRemark);
        }}
        className="absolute right-8 z-40"
      >
        <TbPencil className="text-24 text-yellow-500 hover:text-gray-500" />
      </div>
    </div>
  );
};
export default RemarkRow;
