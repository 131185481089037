import { useCallback, useState } from "react";

import { calculateToastDuration } from "../helpers/Common";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { notification } from "antd";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

dayjs.extend(customParseFormat);

export const useComment = ({ input }) => {
  const [data, setData] = useState(input);
  const { control, handleSubmit, setValue, watch } = useForm();
  const [version, setVersion] = useState(input?.version);

  const updateData = useCallback(
    async ({
      requestInput,
      requestDate,
      successFunc,
      refreshFunc,
      silent,
      updateFunc,
    }) => {
      try {
        let requestBody = structuredClone(data);

        setVersion((prevVersion) => {
          requestBody = { ...requestBody, version: prevVersion + 1 };
          return prevVersion + 1;
        });

        if (requestDate !== undefined) {
          requestBody = {
            ...requestBody,
            [requestDate.key]:
              requestDate.value.trim() === ""
                ? undefined
                : requestDate.value || undefined,
          };
        }

        if (requestInput !== undefined) {
          requestBody = {
            ...requestBody,
            [requestInput.key]:
              requestInput.value.trim() === ""
                ? undefined
                : requestInput.value || undefined,
          };
        }

        const record = await updateFunc(requestBody);
        if (successFunc) {
          if (silent) return;
          successFunc();
        }
        setData(record);
        refreshFunc(true);
      } catch (error) {
        if (silent) return;
        return notification.error({
          message: "Gagal Memberikan Komentar",
          description: error.data,
          placement: "bottomRight",
        });
      }
    },
    [data, version]
  );

  const onChangeDate = ({
    key,
    date,
    message,
    refreshFunc,
    updateFunc,
    silent,
  }) => {
    const dateParsed = new Date(date);
    const caDueDate = date ? moment(dateParsed).format("Y-MM-DD") : " ";
    const previewDate = date ? moment(dateParsed).format("LL") : " ";

    const defaultMessage =
      previewDate === " "
        ? "Berhasil menghapus tanggal"
        : "Berhasil mengubah tanggal tindakan";

    updateData({
      requestDate: { key, value: caDueDate },
      updateFunc,
      refreshFunc,
      successFunc: () =>
        toast.success(`${message?.success || defaultMessage} ${previewDate}`, {
          duration: calculateToastDuration(defaultMessage),
          position: "top-right",
          className: "font-regular text-16",
        }),
    });
  };

  const onChangeText = ({
    key,
    data,
    message,
    refreshFunc,
    updateFunc,
    silent,
  }) => {
    let remark = data;
    const remarkLogic =
      remark?.length < 1 || remark === undefined || remark === " ";
    remark = remarkLogic ? " " : remark;

    const defaultMessage = remarkLogic
      ? "Berhasil menghapus komentar"
      : "Berhasil mengubah komentar";

    updateData({
      requestInput: { key, value: remark },
      updateFunc,
      refreshFunc,
      silent,
      successFunc: () => {
        if (!silent) {
          toast.success(
            `${message?.success || defaultMessage} ${remark || ""}`,
            {
              duration: calculateToastDuration(defaultMessage),
              position: "top-right",
              className: "font-regular text-16",
            }
          );
        }
      },
    });
  };

  return {
    version,
    control,
    setValue,
    watch,
    handleSubmit,
    updateData,
    onChangeDate,
    onChangeText,
    data,
    setData,
    setVersion,
  };
};
