import moment from "moment";

const getMonthValue = (obj, month, property) => {
	const propName = `${month}${property
		.charAt(0)
		.toUpperCase()}${property.slice(1)}`;
	return obj[propName];
};
export const generateMonthlyData = (obj, propName) => {
	const months = [];

	for (let i = 0; i < 12; i++) {
		const month = moment({ month: i }).locale("en-US");
		const monthName = month.format("MMM");
		const value = getMonthValue(obj, monthName.toLowerCase(), propName);
		const weightKg = getMonthValue(
			obj,
			monthName.toLowerCase(),
			"WeightKg"
		);
		const bunchCount = getMonthValue(
			obj,
			monthName.toLowerCase(),
			"BunchCount"
		);
		months.push({ monthName, value, weightKg, bunchCount });
	}

	return months;
};
