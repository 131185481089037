import { createContext, useContext, useEffect } from "react";

import useFilter from "../../../../hooks/useFilter";
import moment from "moment";
import { useCompanyStore } from "../../../../hooks/companyStore";
import { useFertilizationRecapsStore } from "../../../../api/fertilization-recaps/useFertilizationRecapsStore";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [
    getRecapsByDistinctBlocks,
    getFertilizationRecapsMaterialSummary,
    getFertilizationRecapsOverallSummary,
  ] = useFertilizationRecapsStore((state) => [
    state.getRecapsByDistinctBlocks,
    state.getFertilizationRecapsMaterialSummary,
    state.getFertilizationRecapsOverallSummary,
  ]);
  const company = useCompanyStore((state) => state.company);

  const initialFilter = {
    fltCompany: company?.id,
    fltUser: undefined,
    fltEstate: undefined,
    fltAfdeling: undefined,
    fltBlock: undefined,
    fltFertilizationDate: moment().format("YYYY-MM-DD"),
    fltPlantedYear: undefined,
  };
  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({
    initialFilter,
  });

  const getRecapsByDistinctBlocksHandler = async () => {
    await getRecapsByDistinctBlocks({
      params: isFilterBy,
      silent: false,
    });
  };

  const getFertilizationRecapsMaterialSummaryHandler = async () => {
    await getFertilizationRecapsMaterialSummary({
      params: isFilterBy,
      silent: false,
    });
  };

  const getFertilizationRecapsOverallSummaryHandler = async () => {
    await getFertilizationRecapsOverallSummary({
      params: isFilterBy,
      silent: false,
    });
  };

  const parallel = async () => {
    await Promise.all([
      getRecapsByDistinctBlocksHandler(),
      getFertilizationRecapsMaterialSummaryHandler(),
      getFertilizationRecapsOverallSummaryHandler(),
    ]);
  };

  useEffect(() => {
    parallel();
  }, [isFilterBy]);

  return (
    <DataContext.Provider value={{ onFilter, setIsFilterBy, isFilterBy }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
