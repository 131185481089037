export function rgbaToHex(rgba) {
  // Pisahkan komponen RGBA
  const parts = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d*)\)$/);
  if (!parts) {
    console.error("Format RGBA tidak valid:", rgba);
    return null;
  }

  // Ekstrak nilai RGB dan Alpha (opacity)
  let r = parseInt(parts[1], 10).toString(16);
  let g = parseInt(parts[2], 10).toString(16);
  let b = parseInt(parts[3], 10).toString(16);
  let a = Math.round(parseFloat(parts[4]) * 255).toString(16);

  // Pastikan setiap bagian memiliki dua digit
  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  // Gabungkan bagian R, G, dan B menjadi format heksadesimal tanpa Alpha
  const hex = "#" + r + g + b;

  // Kembalikan nilai heksadesimal tanpa alpha dan nilai alpha sebagai objek
  return {
    hex, // Warna dalam format heksadesimal tanpa alpha
    alpha: parseFloat(parts[4]), // Nilai opacity asli sebagai angka desimal
  };
}

export function hexToRgba(hex, opacity) {
  // Ensure input is a string and its length is sufficient for RGB
  if (typeof hex !== "string" || hex.length !== 7) {
    console.error("Invalid hexadecimal format:", hex);
    return null;
  }
  if (typeof opacity !== "number" || opacity < 0 || opacity > 1) {
    console.error("Invalid opacity value:", opacity);
    return null;
  }

  // Extract R, G, B components from hexadecimal
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Format into RGBA string with given opacity
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;

  // Return RGBA value
  return rgba;
}
