import { v4 as uuidv4 } from "uuid";

/**
 * Membaca file sebagai teks dan menguraikan menjadi JSON.
 * @param {File} file - File yang akan dibaca.
 * @returns {Promise<any>} Promise yang mengembalikan konten file sebagai JSON.
 */
export const readJsonFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        let json = JSON.parse(reader.result);

        // Fungsi untuk menambahkan atau memodifikasi properti pada objek GeoJSON
        const modifyJson = (obj) => {
          obj["id"] = uuidv4();
          obj["name"] = obj?.fileName || file?.name?.split(".")[0];
          // obj["fillColor"] = "rgba(59, 147, 48, 1)";
          // obj["outlineColor"] = "rgba(59, 147, 48, 1)";

          obj["fillColor"] = "#62a959";
          obj["outlineColor"] = "#3b9330";
          obj["opacity"] = 0.2;
          obj["geometryType"] = obj?.features?.[0]?.geometry?.type || "Unknown";
          obj["features"] = obj?.features?.map((item) => {
            item["featureId"] = uuidv4();
            item["properties"]["propertyId"] = uuidv4();
            return item;
          });
          return obj;
        };

        // Cek apakah json adalah array
        if (Array.isArray(json)) {
          // Jika ya, modifikasi setiap objek di dalam array
          json = json.map((obj) => modifyJson(obj));
        } else {
          // Jika tidak, langsung modifikasi objek
          json = [modifyJson(json)];
        }

        resolve(json);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.readAsText(file);
  });
};

/**
 * Membaca beberapa file JSON yang dipilih dan mengembalikan promise yang
 * menyelesaikan array dari konten JSON dari setiap file.
 * @param {FileList} files - Daftar file yang dipilih untuk dibaca.
 * @returns {Promise<any[]>} Promise yang mengembalikan array dari konten JSON.
 */
export const readMultipleJsonFiles = (files) => {
  // Jika hanya ada satu file, langsung proses file tersebut
  if (files.length === 1) {
    return readJsonFile(files[0]).then((json) => json); // Mengembalikan dalam bentuk array
  }

  // Jika lebih dari satu file, proses setiap file menggunakan map
  const promises = Array.from(files).map((file) => readJsonFile(file));
  return Promise.all(promises);
};
