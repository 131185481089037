import React from "react";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { useFilter } from "../../contexts/filterContext";
import { APP_NAME } from "../../../../../configs/api";
import { selectUser } from "../../../../../features/userSlice";
import { useSelector } from "react-redux";

const ModalFilterFooter = ({
  setIsFilterBy,
  handleOk,
  initialFilter,
  page,
}) => {
  const user = useSelector(selectUser);
  const { setValue } = useFilter();

  return (
    <div className="flex flex-row items-center justify-end space-x-16 pt-16">
      <ButtonComponent
        value="Reset Filter"
        type="button"
        size="none"
        className="bordered-button-transition px-16 py-8 text-16"
        onClick={() => {
          setIsFilterBy({
            ...initialFilter,
            pageIndex: page.current,
            fltCompany: user?.companyId,
          });
          setValue("username", null);
          setValue("estate", null);
          setValue("afdeling", null);
          setValue("block", null);
          setValue("anotherFilter", null);
          setValue("dateRange", null);
        }}
      />
      <ButtonComponent
        value="Tutup"
        type="submit"
        size="none"
        className={`button-transition px-16 py-8 text-16 ${
          APP_NAME === "semai-kebun" &&
          "bg-primary-red-500 hover:border-primary-red-500 hover:text-primary-red-500 active:bg-primary-red-500"
        }
`}
        onClick={handleOk}
      />
    </div>
  );
};

export default ModalFilterFooter;
