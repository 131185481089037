export const headersData = [
  {
    value: "NO.",
    accessorKey: "index",
    type: "alphabet",
  },
  {
    value: "Foto Panen",
    accessorKey: "photoFileId",
    type: "alphabet",
  },
  {
    value: "Tanggal panen",
    accessorKey: "date",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Dibuat Oleh",
    accessorKey: "createdBy",
    type: "alphabet",
  },
  {
    value: "Komentar",
    accessorKey: "remark",
    type: "alphabet",
  },
  {
    value: "Tanggal Tindakan",
    accessorKey: "caDueDate",
    type: "alphabet",
  },
  {
    value: "Blok",
    accessorKey: "adhocBlockName",
    type: "number",
  },
  {
    value: "TPH",
    accessorKey: "collectionPointName",
    type: "number",
  },
  {
    value: "Total JJG",
    accessorKey: "fruitCount",
    type: "number",
  },
  {
    value: "Buah Mentah",
    accessorKey: "fruitCount",
    type: "number",
  },
  {
    value: "Abnormal",
    accessorKey: "abnormalCount",
    type: "number",
  },
  {
    value: "Total Panen (kg)",
    accessorKey: "totalWeight",
    type: "number",
    sorted: true,
  },
];
