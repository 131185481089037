import CardWithDoughnutChart from "./CardWithDoughnutChart";
import CardWithTable from "./CardWithTable/CardWithTable";
import Heading from "./Heading";
import SimpleCardHorizontal from "./SimpleCardHorizontal";
import SimpleCardHorizontalDivide from "./SimpleCardHorizontalDivide";
import SimpleCardVertical from "./SimpleCardVertical";
import ComparisonChart from "./ComparisonChart";

const GardenProductionComponent = {
  SimpleCardHorizontal,
  SimpleCardHorizontalDivide,
  SimpleCardVertical,
  CardWithTable,
  CardWithDoughnutChart,
  ComparisonChart,
  Heading,
};

export default GardenProductionComponent;
