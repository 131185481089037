import React from "react";
import PropTypes from "prop-types";
import InputHook from "../../../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../../../components/atoms/SelectHook";
import { isEmptyObject } from "../../../../../../../helpers/Common";
import InputOption from "../../../../../../../components/atoms/SelectHook/partials/InpuOptions";
import CreatableSelects from "../../../../../../../components/atoms/Inputs/CreatableSelect/CreateableSelect";
import ButtonComponent from "../../../../../../../components/atoms/ButtonComponent";

const Content = ({
  control,
  register,
  errors,
  tags,
  watch,
  setTagsSelected,
  isCreateDimensionalTag,
  setIsCreateDimensionalTag,
  clearGroupTagForm,
}) => {
  const watchDimension = watch("dimension");
  const dimensions = [
    ...(watchDimension &&
    !isEmptyObject(watchDimension) &&
    watchDimension?.items?.length > 0
      ? watchDimension?.items?.map((item) => ({
          label: `${watchDimension?.itemPrefix}-${item}`,
          value: `${watchDimension?.itemPrefix}-${item}`,
        }))
      : []),
  ];

  return (
    <div className="grid grid-cols-1 gap-12">
      {!isCreateDimensionalTag && (
        <div className="grid grid-cols-2 gap-12">
          <InputHook
            register={register}
            label="Tanggal Checkpoint"
            name="checkpointDate"
            errors={errors?.checkpointDate}
            inputClassName="input-large-border"
            className="col-span-2"
            disabled={true}
          />
          <InputHook
            register={register}
            label="Lokasi Checkpoint"
            name="checkpointLocation"
            errors={errors?.checkpointLocation}
            inputClassName="input-large-border"
            disabled={true}
          />
          <InputHook
            register={register}
            label="No. Surat Jalan"
            name="deliveryLetterNumber"
            errors={errors?.deliveryLetterNumber}
            inputClassName="input-large-border"
            disabled={true}
          />
          <CreatableSelects
            required
            control={control}
            label="Tag Grup"
            name="dimension"
            data={tags}
            errors={errors?.dimension}
            placeholder="Pilih dimensi"
            onCreateOption={(data) => {
              setIsCreateDimensionalTag(true);
            }}
          />
          <SelectHook
            required
            control={control}
            label="Tags"
            name="tags"
            data={dimensions}
            errors={errors?.tags}
            placeholder="Pilih Tags"
            afterOnChange={(selectedItems) => setTagsSelected(selectedItems)}
            components={{ Option: InputOption }}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
          <ButtonComponent
            value="Tambah Tag Grup Baru"
            type="button"
            className="col-span-2 border border-gray-200 bg-white text-gray-500 hover:border-gray-500"
            onClick={() => {
              clearGroupTagForm();
              setIsCreateDimensionalTag(true);
            }}
          />
        </div>
      )}

      {isCreateDimensionalTag && (
        <div className="grid grid-cols-2 gap-12">
          <InputHook
            required
            register={register}
            label="Nama Tag Grup"
            name="name"
            errors={errors?.name}
            inputClassName="input-large-border"
            className="col-span-2"
            placeholder="Masukkan nama group untuk tags"
          />
          <InputHook
            required
            register={register}
            label="Deskripsi"
            name="description"
            errors={errors?.description}
            inputClassName="input-large-border"
            placeholder="Masukkan deskripsi"
          />
          <InputHook
            required
            register={register}
            label="Tag Prefix"
            name="itemPrefix"
            errors={errors?.itemPrefix}
            inputClassName="input-large-border"
            placeholder="Masukkan prefix dari tag"
          />
          <CreatableSelects
            required
            control={control}
            label="Tags"
            name="newTags"
            errors={errors?.newTags}
            placeholder="Masukkan tag"
            className="col-span-2"
            components={{ Option: InputOption }}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
          <ButtonComponent
            value="Batal Tambah Tag Grup"
            type="button"
            className="col-span-2 border border-gray-200 bg-white text-gray-500 hover:border-gray-500"
            onClick={() => {
              setIsCreateDimensionalTag(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Content;

Content.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};
