import React, { useEffect, useReducer, useState } from "react";

import Filter from "./partials/Filter/Filter";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import CHEMIST from "../../../assets/icons/chemist.svg";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter/ModalFilter";
import { useChemistStore } from "../../../api/chemist/useChemistStore";
import useFilter from "../../../hooks/useFilter";
import { useUserStore } from "../../MasterWorkerPage/hooks/userStore";
import ListData from "./partials/ListData";

const ListView = () => {
  const initialFilter = {
    pageIndex: 0,
    pageSize: 20,
    fltCompany: undefined,
    fltUser: undefined,
    fltAfdeling: undefined,
    fltBlock: undefined,
    fltStartDate: undefined,
    fltEndDate: undefined,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };

  const { onFilter, isFilterBy, setIsFilterBy } = useFilter({ initialFilter });

  const [getChemists] = useChemistStore((state) => [state.getChemists]);
  const [user] = useUserStore((state) => [state.user]);

  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );
  const paginationLenght = Math.ceil(page?.total / 20);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    if (beforeFetch) {
      beforeFetch();
    }
    if (!isSilent) setRequest({ isLoading: true });
    await getChemists({
      params: {
        ...isFilterBy,
        pageIndex,
      },
      onSuccess: (response) => {
        setData(response?.records);
        const totalRecords = response?.totalRecords;
        setPage({
          current: pageIndex,
          total: totalRecords,
        });
        setTimeout(() => {
          setRequest({ isLoading: false });
        }, 800);
      },
    });
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const handleRefreshData = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, [isFilterBy]);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={CHEMIST}
                alt='Pesticide Applicator by Luis Prado from <a href="https://thenounproject.com/browse/icons/term/pesticide-applicator/" target="_blank" title="Pesticide Applicator Icons">Noun Project</a> (CC BY 3.0)'
                className="hidden w-24 grayscale md:inline"
              />
              <p className="line-clamp-1">Riwayat Chemist</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListData data={data} refreshHandler={handleRefreshData} />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
