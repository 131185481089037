import "moment/locale/id";

import { cn, Number, parseToFloat } from "../../../../../helpers/Common";
import { TbPencil, TbTrash } from "react-icons/tb";

import React from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { APP_NAME } from "../../../../../configs/api";
import { DEFAULT_TABLE_THEME } from "../../../../../utils/constant";

const TableRowBlock = ({ data, handleUpdate, handleDelete, handleDetail }) => {
  return data.map((item, index) => {
    const plantedDate = moment(item.plantedDate);
    const today = moment();
    const treeAgeInMonths = today.diff(plantedDate, "month");
    let treeAgeInYears = Math.floor(treeAgeInMonths / 12);
    let remainingMonths = treeAgeInMonths % 12;

    return (
      <tr
        className={cn(
          "table-header-regular cursor-pointer select-none",
          DEFAULT_TABLE_THEME
        )}
        key={index}
        onClick={() =>
          handleDetail({
            blockId: item.id,
            blockName: item.blockName,
            estateId: item.estateId,
            estateName: item.estateName,
            afdelingId: item.afdelingId,
            afdelingName: item.afdelingName,
          })
        }
      >
        <td className="table-row-regular font-semi-bold text-gray-600">
          {index + 1}
        </td>
        <td className="table-row-regular">{item?.plantedYear}</td>
        <td
          className={cn(
            "table-row-regular font-semi-bold",
            APP_NAME === "semai-kebun"
              ? "text-primary-red-500"
              : "text-yellow-500"
          )}
        >
          {item?.blockName}
        </td>
        <td className="table-row-regular">
          {treeAgeInMonths > 30 ? "TM" : "TBM"}
        </td>
        <td className="table-row-regular">
          {treeAgeInYears + " Tahun"} {remainingMonths + " Bulan"}
        </td>
        <td className="table-row-regular">{parseToFloat(item?.areaHa)}</td>
        <td className="table-row-regular">{Number(item?.treeCount)}</td>
        <td className="table-row-regular">{item?.averageFruitWeight}</td>
        <td className="table-row-regular">{Number(item?.tphCount)}</td>
        <td className="table-row-regular">
          {moment(item?.createdTime).format("LL")}
        </td>
        <td className="table-row-regular ">
          <div className="flex flex-row items-center space-x-16">
            <button
              className="rounded-[4px] p-8 outline-none"
              onClick={(event) => {
                event.stopPropagation();
                handleUpdate({ item });
              }}
            >
              <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
                Swal.fire({
                  title: "Hapus Blok",
                  text: `Anda akan menghapus ${item?.blockName}, Apa kamu yakin?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#dc2626",
                  confirmButtonText: "Ya, hapus!",
                  cancelButtonText: "Tidak",
                  reverseButtons: true,
                  heightAuto: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleDelete({
                      id: item?.id,
                      name: item?.blockName,
                    });
                  }
                });
              }}
              className="rounded-[4px] p-8 outline-none"
            >
              <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
          </div>
        </td>
      </tr>
    );
  });
};

export default TableRowBlock;
