import {
	createVehicle,
	updateVehicle,
} from "../../../../../features/vehicleSlice";
import { useDispatch, useSelector } from "react-redux";

import Content from "./partials/Content";
import Modal from "../../../../../components/atoms/Modal";
import React from "react";
import { formSchema } from "./schema/formSchema";
import { omitBy } from "lodash";
import { selectModal } from "../../../../../features/modalSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";

const MultiModal = ({ isOpen, toggleModal, type, onRefreshData }) => {
	const UPDATE_TYPE = type.toLowerCase().includes("ubah");
	const dispatch = useDispatch();
	const alert = useAlert();
	const modalName = "Vehicle";
	const modal = useSelector(selectModal);
	const user = useSelector(selectUser);

	const {
		handleSubmit,
		register,
		control,
		reset,
		setValue,
		formState: { errors },
		clearErrors,
		watch,
	} = useForm({ resolver: yupResolver(formSchema) });

	const handleCreateVehicle = async (data) => {
		try {
			const response = await dispatch(createVehicle(data)).unwrap();
			const success = response.success;
			if (!success) {
				return alert.error(
					"Ups! Ada yang salah, silakan coba lagi nanti."
				);
			}

			alert.success(`Berhasil manambahkan ${data.name}`);
			toggleModal();
			onRefreshData();
		} catch (error) {
			alert.error("Ups! Ada yang salah, silakan coba lagi nanti.");
		}
	};

	const handleUpdateVehicle = async (data) => {
		try {
			const response = await dispatch(updateVehicle(data)).unwrap();
			const success = response.success;
			if (!success) {
				return alert.error(
					"Ups! Ada yang salah, silakan coba lagi nanti."
				);
			}
			alert.success(`Data Truk ${data.name} berhasil diperbaharui`);
			toggleModal();
			onRefreshData();
		} catch (error) {
			alert.error("Ups! Ada yang salah, silakan coba lagi nanti.");
		}
	};

	const onSubmit = (data) => {
		let requestData = {
			...data,
			acquisitionYear: data.acquisitionYear.value,
			estateId: data?.estate?.value || undefined,
			estateName: data?.estate?.label || undefined,
			afdelingId: data?.afdeling?.value || undefined,
			afdelingName: data?.afdeling?.label || undefined,
			companyId: user.companyId,
			companyName: user.companyName,
			ownership: data?.ownership || null,
			maxLoadCapacity: data?.maxLoadCapacity || null,
			frameNo: data?.frameNo || null,
			engineNo: data?.engineNo || null,
		};

		if (UPDATE_TYPE) {
			handleUpdateVehicle(requestData);
			return;
		}

		requestData = omitBy(requestData, (v) => v == null);
		requestData = { ...requestData, id: uuidv4() };
		handleCreateVehicle(requestData);
	};

	const handleDefaultValue = () => {
		let state = modal[modalName]?.params?.item;
		clearErrors();
		reset();
		if (UPDATE_TYPE) {
			const currentData = {
				...state,
				estate: { label: state.estateName, value: state.estateId },
				afdeling: {
					label: state.afdelingName,
					value: state.afdelingId,
				},
				acquisitionYear: {
					label: state.acquisitionYear,
					value: state.acquisitionYear,
				},
			};
			reset(currentData);
		} else {
			const nullValues = Object.keys(state || {}).reduce(
				(accumulator, key) => {
					return { ...accumulator, [key]: null };
				},
				{}
			);
			reset(nullValues);
			setValue("estate", null);
			setValue("afdeling", null);
		}
	};

	React.useEffect(() => handleDefaultValue(), [type, isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={toggleModal} title={type}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Content>
					<Content
						register={register}
						control={control}
						errors={errors}
						watch={watch}
					/>
				</Modal.Content>
				<Modal.Footer toggleModal={toggleModal} type={type} />
			</form>
		</Modal>
	);
};

export default MultiModal;
