import React from "react";
import { Number } from "../../../../../helpers/Common";

const SimpleCardVertical = ({ title, subTitle, data }) => {
  const checkNumber = (num) => {
    if (num === undefined || num === null || num < 0) return 0;
    return num || 0;
  };

  return (
    <div className="grid h-full w-full grid-cols-1 rounded-8 bg-white p-8 shadow md:gap-14 md:p-16 md:px-16">
      <div className="flex flex-col">
        <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
          {title}
        </h3>
        <p className="font-regular text-13 text-gray-400 md:text-14">
          {subTitle}
        </p>
      </div>
      <div
        className={`m-auto grid w-full ${
          data?.length === 1 ? "grid-cols-1" : "grid-cols-2"
        } gap-8`}
      >
        {data?.map((item, index) => (
          <div
            key={index}
            className="mx-auto flex flex-row items-end space-x-4"
          >
            <h1
              className={`text-gray-600 ${
                index === 0
                  ? "text-24 font-bold md:text-32"
                  : "text-24 font-medium md:text-32"
              }`}
            >
              {checkNumber(Number(Math.ceil(item?.value)))}
            </h1>
            <p className="justify-end text-13 font-medium text-gray-600 md:text-16">
              {item?.unit}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleCardVertical;
