import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  defaultTableConfig,
  MIU_TABLE_BODY_CELL_PROPS_SX,
  MIU_TABLE_HEAD_CELL_PROPS_SX,
  MIU_TABLE_HEAD_ROW_PROPS_SX,
  MIU_TABLE_PROPS_SX,
} from "../../../../utils/tableUtils";
import { omitBy } from "lodash";
import { useMeasure } from "@uidotdev/usehooks";
import { v4 as uuidv4 } from "uuid";
import ButtonComponent from "../../../../components/atoms/ButtonComponent";

function convertPropertiesToArray(properties) {
  return Object.keys(properties)
    .sort()
    .map((key) => ({
      id: uuidv4(),
      name: key,
      value: properties[key],
    }));
}

function convertArrayToProperties(array) {
  return array.reduce((obj, item) => {
    obj[item.name] = item.value;
    return obj;
  }, {});
}

const Popup = ({ popupInfo, updateMapShapes }) => {
  const [ref, { height }] = useMeasure();
  const [data, setData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const validateRequired = (value) => !!value.length;

  const updateData = (updatedData) => {
    setData((currentData) => {
      return currentData?.map((item) =>
        item.id === updatedData.id ? { ...item, ...updatedData } : item
      );
    });
  };

  const onCreatingRowSave = ({ values, table }) => {
    const newRow = {
      id: uuidv4(),
      ...values,
    };
    setData((currentData) => [...currentData, newRow]);
    table.setCreatingRow(false);
  };

  const onSave = () => {
    const filteredData = data.filter(
      (item) => item.name !== "" && item.value !== ""
    );

    const dataObject = convertArrayToProperties(filteredData);
    updateMapShapes({
      id: popupInfo?.data?.id,
      properties: dataObject,
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Nama",
        accessorKey: "name",
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          error: !!validationErrors?.[row.id],
          helperText: validationErrors?.[row.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [row.id]: validationError,
            });
            updateData({
              id: row.original.id,
              name: event.currentTarget.value,
            });
          },
        }),
      },
      {
        header: "Nilai",
        accessorKey: "value",
        muiEditTextFieldProps: ({ cell, row }) => ({
          disabled: row.original.name === "propertyId",
          type: "text",
          required: true,
          error: !!validationErrors?.[row.id],
          helperText: validationErrors?.[row.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [row.id]: validationError,
            });
            updateData({
              id: row.original.id,
              value: event.currentTarget.value,
            });
          },
        }),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
    ...omitBy(
      defaultTableConfig,
      (_, key) =>
        key === "enablePinning" &&
        key === "enableColumnResizing" &&
        key === "enableColumnOrdering"
    ),
    muiTableContainerProps: {
      sx: { maxHeight: height - 24 },
    },
    muiTableProps: { sx: MIU_TABLE_PROPS_SX },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          ...MIU_TABLE_BODY_CELL_PROPS_SX,
        },
      };
    },
    muiTableHeadRowProps: {
      sx: { ...MIU_TABLE_HEAD_ROW_PROPS_SX, backgroundColor: "#fafbfd" },
    },
    muiTableHeadCellProps: {
      sx: MIU_TABLE_HEAD_CELL_PROPS_SX,
    },
    muiEditTextFieldProps: {
      sx: {
        padding: "-8px",
      },
      InputProps: {
        sx: {
          fontFamily: "Inter-Regular",
          fontSize: "14px",
          color: "#1d1b1d",
        },
      },
    },
    initialState: { density: "compact" },
    onCreatingRowSave: onCreatingRowSave,
    enablePinning: false,
    enableColumnResizing: false,
    enableColumnOrdering: false,
    enableColumnActions: false,
    createDisplayMode: "row",
    editDisplayMode: "table",
    enableClickToCopy: "context-menu",
    enableEditing: true,
    getRowId: (row) => row.id,
  });

  useEffect(() => {
    setData([]);
    setData(convertPropertiesToArray(popupInfo?.data?.properties));
  }, [popupInfo?.data?.properties?.propertyId]);

  return (
    <div
      className="absolute z-10 max-w-sm space-y-4 rounded-[16px] bg-white shadow-md shadow-yellow-200"
      style={{ left: popupInfo.x, top: popupInfo.y }}
    >
      <div className="flex flex-row items-center justify-between rounded-t-16 bg-gray-30 p-16">
        <p className="font-bold text-14 text-black">{popupInfo?.data?.name}</p>
      </div>
      <div className={"relative max-h-[300px] overflow-hidden p-16"} ref={ref}>
        <MaterialReactTable table={table} />
      </div>
      <div className={"flex flex-row items-center gap-16 p-8"}>
        <ButtonComponent
          type={"button"}
          value={"Tambah Row"}
          className="button-outline-transition w-full"
          onClick={() => table.setCreatingRow(true)}
        />
        <ButtonComponent
          type={"button"}
          value={"Simpan Data"}
          className="button-transition w-full"
          onClick={onSave}
        />
      </div>
    </div>
  );
};

export default Popup;
