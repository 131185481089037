import {
  APP_NAME,
  KEYCLOAK_BASE_URL,
  KEYCLOAK_ID,
  KEYCLOAK_REALM,
  LOGIN_CALLBACK_URL,
} from "../../configs/api";

import Keycloak from "keycloak-js";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LOGO_SAWIT_WEB_HORIZONTAL from "../../assets/icons/horizontal.png";
import LOGO_KJP from "../../assets/icons/logo-kjp.jpeg";
import { handleKeycloackAuth } from "../../features/authSlice";
import { getUser } from "../../features/userSlice";
import { useAuthStore } from "../../hooks/authStore";

import { useAlert } from "react-alert";
import LOGO_SEMAI_KEBUN_HORIZONTAL from "../../assets/icons/logo-semai-kebun-h.png";

const LoginPage = () => {
  const saveKeycloak = useAuthStore((state) => state.saveKeycloak);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const location = window.location;

  const onGetUser = (sub, token) => {
    dispatch(getUser({ id: sub, token: token }))
      .unwrap()
      .then((e) => {
        localStorage.setItem("user:api", JSON.stringify(e.record));
      });
  };

  const initializeKeycloak = async () => {
    const keycloakConfig = {
      url: KEYCLOAK_BASE_URL,
      realm: KEYCLOAK_REALM,
      clientId: KEYCLOAK_ID,
      onLoad: "login-required",
    };
    const keycloak = new Keycloak(keycloakConfig);
    try {
      await keycloak.init({
        onLoad: "login-required",
        redirectUri: LOGIN_CALLBACK_URL,
      });

      if (keycloak.authenticated) {
        const {
          authenticated,
          token,
          tokenParsed,
          refreshToken,
          refreshTokenParsed,
        } = keycloak;

        const tokensParsed = { tokenParsed, refreshTokenParsed };
        const tokens = { token, refreshToken };

        // Save token and keycloak properties to local storage using zustand
        saveKeycloak({
          keycloak,
          authenticated,
          tokensParsed,
          tokens,
        });

        const sub = keycloak.tokenParsed.sub;
        const provide = keycloak.token;
        const provide_fresh = keycloak.refreshToken;
        const keycloakProvides = {
          provide,
          provide_fresh,
          sub,
        };

        dispatch(
          handleKeycloackAuth({
            keycloak: keycloak,
            auth: authenticated,
            tokens: keycloakProvides,
          })
        );

        const remainingToken = {
          idToken: keycloak.idToken,
          refreshToken: keycloak.refreshToken,
          accessToken: keycloak.token,
        };

        localStorage.setItem("token", JSON.stringify(remainingToken));
        localStorage.setItem("user", JSON.stringify(keycloak.tokenParsed));

        onGetUser(keycloak.tokenParsed.sub, keycloak.token);

        setIsLoading(false);
        alert.success("Selamat datang di SawitWeb Dashboard");
        APP_NAME === "sawit-web"
          ? navigate("/produksi-kebun", { replace: true })
          : navigate("/riwayat-panen", { replace: true });
        return;
      }
    } catch (error) {
      localStorage.clear();
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(initializeKeycloak, 1000);
  }, []);

  // useEffect(() => {
  //   let isMounted = true;
  //
  //   if (isMounted) {
  //     setIsLoading(true);
  //     const keycloak = new Keycloak({
  //       url: KEYCLOAK_BASE_URL,
  //       realm: KEYCLOAK_REALM,
  //       clientId: KEYCLOAK_ID,
  //       onLoad: "login-required",
  //     });
  //
  //     keycloak
  //       .init({
  //         onLoad: "login-required",
  //         redirectUri: LOGIN_CALLBACK_URL,
  //       })
  //       .then((auth) => {
  //         alert.success("Selamat datang di Semai Kebun Dashboard");
  //         const {
  //           authenticated,
  //           token,
  //           tokenParsed,
  //           refreshToken,
  //           refreshTokenParsed,
  //         } = keycloak;
  //
  //         const tokensParsed = { tokenParsed, refreshTokenParsed };
  //         const tokens = { token, refreshToken };
  //
  //         // Save token and keycloak properties to local storage using zustand
  //         saveKeycloak({
  //           keycloak,
  //           authenticated,
  //           tokensParsed,
  //           tokens,
  //         });
  //
  //         const sub = keycloak.tokenParsed.sub;
  //         const provide = keycloak.token;
  //         const provide_fresh = keycloak.refreshToken;
  //         const keycloakProvides = {
  //           provide,
  //           provide_fresh,
  //           sub,
  //         };
  //
  //         dispatch(
  //           handleKeycloackAuth({
  //             keycloak: keycloak,
  //             auth: auth,
  //             tokens: keycloakProvides,
  //           })
  //         );
  //
  //         const remainingToken = {
  //           idToken: keycloak.idToken,
  //           refreshToken: keycloak.refreshToken,
  //           accessToken: keycloak.token,
  //         };
  //
  //         localStorage.setItem("token", JSON.stringify(remainingToken));
  //         localStorage.setItem("user", JSON.stringify(keycloak.tokenParsed));
  //
  //         onGetUser(keycloak.tokenParsed.sub, keycloak.token);
  //
  //         setIsLoading(false);
  //         if (auth) {
  //           navigate("/produksi-kebun", { replace: true });
  //         }
  //       })
  //       .catch((err) => console.error(err));
  //   }
  //
  //   return () => (isMounted = false);
  // }, []);

  if (location.origin.includes("kjpcps")) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center space-y-16">
        <img src={LOGO_KJP} className="aspect-square h-[140px] w-auto" />
        <p className="mx-24 mt-16 text-center font-regular text-18 text-gray-500 md:mx-0">
          Membawakan Anda konten yang menarik, harap tunggu...{" "}
        </p>
        <div className="flex flex-row items-center space-x-16">
          <p className="font-regular text-18 text-gray-500">Powered by</p>
          <img
            src={
              APP_NAME === "sawit-web"
                ? LOGO_SAWIT_WEB_HORIZONTAL
                : LOGO_SEMAI_KEBUN_HORIZONTAL
            }
            className="h-[52px] w-auto"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <img
          src={
            APP_NAME === "sawit-web"
              ? LOGO_SAWIT_WEB_HORIZONTAL
              : LOGO_SEMAI_KEBUN_HORIZONTAL
          }
          className="h-120 w-auto"
        />
        <p className="mx-24 mt-16 text-center font-regular text-18 text-gray-500 md:mx-0">
          Membawakan Anda konten yang menarik, harap tunggu...{" "}
        </p>
      </div>
    );
  }

  // return (
  //   <div className="relative grid h-screen max-h-screen w-full grid-cols-1 overflow-hidden md:grid-cols-[40%,60%]">
  //     <RightContent />
  //     <LeftContent />
  //   </div>
  // );
};

export default LoginPage;
