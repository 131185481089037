import React from "react";
import { useUploadImageStore } from "../../hooks/useUplodaImageStore";
import { IMAGE_NOT_FOUND } from "../../../../../../api/fallbackImageNotFound";
import Cropper from "react-easy-crop";
import { Slider } from "antd";
import { LuFileImage, LuRotateCcw, LuRotateCw } from "react-icons/lu";

const EasyCropImage = () => {
  const {
    photo,
    crop,
    rotation,
    zoom,
    setCrop,
    setRotation,
    setZoom,
    setCroppedAreaPixels,
    setRotatePlus,
    setRotateMinus,
  } = useUploadImageStore((state) => state);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div className="grid grid-cols-2 gap-14">
      <div className="relative aspect-square w-full overflow-hidden rounded-16 border-4 border-yellow-500">
        <Cropper
          image={photo || IMAGE_NOT_FOUND}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          maxZoom={10}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="flex flex-col space-y-16">
        <p className="font-regular text-14 text-gray-400">
          Format file berupa PNG atau JPG
        </p>
        <div className="flex flex-col space-y-8">
          <p className="text-16 font-semibold text-gray-500">Ukuran Gambar</p>
          <div className="flex w-full flex-row items-center justify-between space-x-16">
            <LuFileImage className="text-18 text-gray-500" />
            <Slider
              className="w-full"
              defaultValue={1}
              min={1}
              max={10}
              value={zoom}
              onChange={setZoom}
            />
            <LuFileImage className="text-24 text-gray-500" />
          </div>
        </div>{" "}
        <div className="flex flex-col space-y-8">
          <p className="text-16 font-semibold text-gray-500">Putar Gambar</p>
          <div className="flex w-full flex-row items-center justify-between space-x-16">
            <div onClick={setRotatePlus}>
              <LuRotateCcw className="text-18 text-gray-500" />
            </div>
            <Slider
              className="w-full"
              defaultValue={0}
              min={0}
              max={360}
              value={rotation}
              onChange={setRotation}
            />
            <div onClick={setRotateMinus}>
              <LuRotateCw className="text-24 text-gray-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasyCropImage;
