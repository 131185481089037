export const sabuhur2010 = {
  type: "FeatureCollection",
  name: "sabuhur_tt2010_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 3.0,
        Luas: 2.0,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Santoso",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "1 dari siti jahr",
        KET: "no skt 21",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.804362615884187, -4.008817760166139],
              [114.80438630846416, -4.008659529592307],
              [114.804441533511621, -4.008273963206714],
              [114.804507254659555, -4.007991187082689],
              [114.805538267821206, -4.008472557305345],
              [114.805443044499398, -4.008603658344389],
              [114.805128602068962, -4.010108597180982],
              [114.804953488476556, -4.010728576617347],
              [114.804625481338377, -4.010463195548523],
              [114.804701961222065, -4.008923958184738],
              [114.804362615884187, -4.008817760166139],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 1.0,
        Luas: 2.0,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Sofyan Hadi",
        KTP: "4",
        KK: "5",
        SKT: "5",
        SJB: "5 dari abduljaba",
        KET: "no skt 20",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.805184667334871, -4.007424560787762],
              [114.805643987188148, -4.005985872880783],
              [114.806776313923294, -4.00685032873223],
              [114.806580169309726, -4.007111994128919],
              [114.805907583307373, -4.007823638643652],
              [114.805844402531605, -4.007985688866539],
              [114.805184667334871, -4.007424560787762],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 2.0,
        Luas: 2.0,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Budi Siswantono",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "1 dari iskandar",
        KET: "no skt 23",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.804507254659555, -4.007991187082689],
              [114.804660835201403, -4.00733037963849],
              [114.804573834071562, -4.007261521407365],
              [114.804622855992932, -4.006856585314508],
              [114.804623435721197, -4.006639247504612],
              [114.804664704898755, -4.005710540233663],
              [114.804711833502765, -4.005303547742983],
              [114.805242882075547, -4.005731263967226],
              [114.804781374801635, -4.00713176153958],
              [114.805184046858855, -4.007426504244437],
              [114.805184667334871, -4.007424560787762],
              [114.805844402522212, -4.007985688885274],
              [114.805789364866257, -4.008126853095883],
              [114.805538267821206, -4.008472557305345],
              [114.804507254659555, -4.007991187082689],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 4.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Suwignyo",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "ftcpy 1 dr rofik",
        KET: "no skt 25",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.806635501085125, -4.006551246541019],
              [114.807463394627831, -4.006166475848005],
              [114.809149314319797, -4.007273228130626],
              [114.808400554645957, -4.007676987722641],
              [114.807875859937113, -4.007453196697353],
              [114.807587694172511, -4.007271250060286],
              [114.806635501085125, -4.006551246541019],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 5.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Maro'atin",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: "ftcpy1 dri bakri",
        KET: "no skt 22",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807463394627831, -4.006166475848005],
              [114.808358965078071, -4.005750250047502],
              [114.810019474062898, -4.006829189353851],
              [114.809889615660254, -4.006874028266743],
              [114.809149314319797, -4.007273228130626],
              [114.807463394627831, -4.006166475848005],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 6.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Sunarwan",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 24",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808358965078071, -4.005750250047502],
              [114.809359859052648, -4.005285072229527],
              [114.81064055222943, -4.006152133762437],
              [114.810344195009719, -4.006303503888988],
              [114.810500955441725, -4.006662938661538],
              [114.810019474062898, -4.006829189353851],
              [114.808358965078071, -4.005750250047502],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 7.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Heryindra Sembada",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 68",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.809359859052648, -4.005285072229527],
              [114.810551773848744, -4.004731111936195],
              [114.811843533827982, -4.005605896887811],
              [114.811738825878052, -4.005633719383128],
              [114.811746264314181, -4.00556698774577],
              [114.811253147233558, -4.005710955423774],
              [114.811202443765467, -4.005865136343933],
              [114.81064055222943, -4.006152133762437],
              [114.809359859052648, -4.005285072229527],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 8.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Susana SP",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 69",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.810551773848744, -4.004731111936195],
              [114.811327023446609, -4.004370801421691],
              [114.811746216179444, -4.004262240248051],
              [114.812865529830987, -4.005129631651709],
              [114.812855958668621, -4.005186368818093],
              [114.812899633909552, -4.005238249550878],
              [114.812886534678569, -4.005318448238088],
              [114.812781957486408, -4.005356543635528],
              [114.811843533827982, -4.005605896887811],
              [114.810551773848744, -4.004731111936195],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 9.0,
        Luas: 2.059,
        LUAS__HA_: "2.000",
        Nm_Pemilik: "Setyiawadi Andi Waluyo",
        KTP: "5",
        KK: "5",
        SKT: "5",
        SJB: null,
        KET: "no skt 70",
        TANAM: "2010",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.811746219200899, -4.004262238236848],
              [114.812164152188771, -4.004153998311689],
              [114.81372137458898, -4.004046227017967],
              [114.814001552314139, -4.004130571663716],
              [114.814056467384844, -4.004710548200818],
              [114.813745339897977, -4.004946801001154],
              [114.813216734655484, -4.005171625872306],
              [114.813026577653019, -4.004990123695303],
              [114.812882143361648, -4.00503114777941],
              [114.812865529830987, -4.005129631651709],
              [114.811746219200899, -4.004262238236848],
            ],
          ],
        ],
      },
    },
  ],
};
