import { ConfigProvider, DatePicker } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { TbDownload, TbReload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import domToImage from "dom-to-image";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { downloadExcel } from "react-export-table-to-excel";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SiMicrosoftexcel } from "react-icons/si";
import { analytics } from "../../../../../App";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import {
  getProductionRecapsDailyBlock,
  productionRecapsRecalculate,
} from "../../../../../features/harvestingSlice";
import { selectUser } from "../../../../../features/userSlice";
import { formatNumericValue, getYears } from "../../../../../helpers/Common";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import { pagePath } from "../../../GardenProduction/GardenProduction";
import { useData } from "../../hooks/DataContext";

const Filter = forwardRef((props, ref) => {
  const { control } = useForm();
  const { onFilter, handleRefreshData, isFilterBy, handleOnFilter } = useData();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const setIsSnapshot = useSnapshotStore((state) => state.setIsSnapshot);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const captureComponent = () => {
    const component = ref.current;
    if (!component) return;

    const estateName =
      isFilterBy.estateName !== undefined ? `_${isFilterBy.estateName}` : "";

    const afdelingName =
      isFilterBy.afdelingName !== undefined
        ? `_${isFilterBy.afdelingName}`
        : "";

    setIsSnapshot(true); // Set isSnapshot to true before capturing the screenshot

    // Tambahkan penundaan sebelum mengambil tangkapan layar
    setTimeout(() => {
      domToImage.toBlob(component).then((blob) => {
        const link = document.createElement("a");
        link.download = `${moment(isFilterBy.date).format(
          "D-MM-Y"
        )}${estateName}${afdelingName}_produksi kebun.jpg`;
        link.href = URL.createObjectURL(blob);
        link.click();

        // Set isSnapshot to false after the screenshot is taken
        setTimeout(() => {
          setIsSnapshot(false);
        }, 1500);
      });
    }, 1000); // Sesuaikan nilai penundaan jika diperlukan
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  const handleProductionRecapsRecalculate = () => {
    dispatch(productionRecapsRecalculate())
      .unwrap()
      .then((response) => {
        if (response.success) {
          handleRefreshData();
        }
      })
      .catch(() => {
        toast.error("Gagal mereset data penerimaan pks");
      });
  };

  const prepareExcelData = async (records) => {
    const data = await Promise.all(
      records?.map((record, index) => ({
        no: index + 1,
        companyName: record?.companyName,
        estateName: record?.estateName,
        afdelingName: record?.afdelingName,
        adhocBlockName: record?.blockName,
        plantedYear: record?.plantedYear,
        areaHa: formatNumericValue(record?.areaHa, { decimals: 2 }),
        harvestingDate: moment(record?.harvestingDate).format("DD/MM/YYYY"),
        harvestingBunchCount: formatNumericValue(record?.harvestingBunchCount, {
          decimals: 0,
        }),
        harvestingEstWeightKg: formatNumericValue(
          record?.harvestingEstWeightKg,
          { decimals: 2 }
        ),
        deliveryBunchCount: formatNumericValue(record?.deliveryBunchCount, {
          decimals: 0,
        }),
        deliveryEstWeightKg: formatNumericValue(record?.deliveryEstWeightKg, {
          decimals: 2,
        }),
        millDeliveredBunchCount: formatNumericValue(
          record?.millDeliveredBunchCount,
          { decimals: 0 }
        ),
        millAcceptedBunchCount: formatNumericValue(
          record?.millAcceptedBunchCount,
          { decimals: 0 }
        ),
        millRejectedBunchCount: formatNumericValue(
          record?.millRejectedBunchCount,
          { decimals: 0 }
        ),
        millNetWeightKg: formatNumericValue(record?.millNetWeightKg, {
          decimals: 2,
        }),
      }))
    );
    return data;
  };

  const handleExportExcel = async () => {
    try {
      setIsLoadingDownload(true);
      const header = [
        "No",
        "Perusahaan",
        "Estate",
        "Afdeling",
        "Blok",
        "Tahun Tanam",
        "Luas (Ha)",
        "Tanggal Kegiatan",
        "Janjang Panen (JJG)",
        "Est. Berat Panen (Kg)",
        "Janjang Terkirim (JJG)",
        "Est. Berat Terkirim (Kg)",
        "Janjang tiba di PKS (JJG)",
        "Janjang diterima PKS (JJG)",
        "Janjang dikembalikan PKS (JJG)",
        "Berat diterima PKS (JJG)",
      ];

      const response = await dispatch(
        getProductionRecapsDailyBlock({
          fltCompany: isFilterBy.company,
          fltEstate: isFilterBy.estate,
          fltAfdeling: isFilterBy.afdeling,
          fltBlock: isFilterBy.block,
          fltMonth: isFilterBy.month,
          fltStartDate: isFilterBy.startDate,
          fltEndDate: isFilterBy.endDate,
          fltPlantedYear: isFilterBy.plantedYear,
          fltYear: isFilterBy.year,
          sord: isFilterBy.sord,

          pageSize: 999999999,
          type: `/by-block`,
        })
      ).unwrap();

      if (!response?.success) {
        return toast.error("Gagal mengunduh excel produksi kebun!");
      }

      if (response.records.length === 0) {
        return toast.error("Data tidak ditemukan!");
      }

      const body = await prepareExcelData(response.records);

      downloadExcel({
        fileName: `PanenHarianPerBlok(${moment(isFilterBy?.fltStartDate).format(
          "DDMMYYYY"
        )} - ${moment(isFilterBy?.fltEndDate).format("DDMMYYYY")})`,
        sheet: `data`,
        tablePayload: { header, body },
      });

      setTimeout(() => {
        toast.success("Berhasil mengunduh excel");
      }, 500);
    } catch (error) {
      toast.error("Gagal mengunduh excel produksi kebun");
    } finally {
      setIsLoadingDownload(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetEstates({});
    return () => (isMounted = false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <div className="flex h-full w-full flex-row items-center gap-8 px-8 md:gap-0 md:space-x-8 md:px-[2%]">
        <SelectHook
          name="estate"
          data={estates}
          control={control}
          placeholder="Pilih estate"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_estate_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleOnFilter({ type: "estate", value });
            if (value !== null) {
              handleGetAfdelings({ estate: value.value });
            } else {
              setAfdelings([]);
            }
          }}
        />
        <SelectHook
          name="afdeling"
          data={afdelings}
          control={control}
          placeholder="Pilih afdeling"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_afdeling_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleOnFilter({ type: "afdeling", value });
            if (value !== null) {
              handleGetBlocks({ afdelingId: value.value });
            } else {
              setBlocks([]);
            }
          }}
        />
        <SelectHook
          control={control}
          data={blocks}
          name="block"
          placeholder="Pilih blok"
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_block_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleOnFilter({
              value: value,
              type: "block",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        <SelectHook
          name="year"
          data={getYears()}
          control={control}
          placeholder="Pilih tahun tanam"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            logEvent(
              analytics,
              `filter_year_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleOnFilter({ type: "plantedYear", value });
          }}
        />

        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Pilih tanggal"
          className="hidden md:inline"
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 8,
            maxWidth: 250,
            borderWidth: 1,
            borderColor: "#e1e4e8",
          }}
          onChange={(value) => {
            logEvent(
              analytics,
              `filter_date_picker_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            handleOnFilter({ type: "date", value });
          }}
        />
        {/* 
		  <ButtonComponent
			tooltipPlacement="bottom"
			tooltipTitle="Filter"
			value="Filter"
			size="none"
			type="button"
			className="bordered-button-transition -ml-8 w-full bg-white p-10 md:hidden md:w-fit"
			// onClick={captureComponent}
			icon={<TbFilter className="text-18 text-gray-500" />}
		  /> */}
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Snapshot Halaman"
          value="Snapshot"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => {
            logEvent(
              analytics,
              `snapshot_button_${pagePath}_${window.location.host}`,
              {
                page_title: `${pagePath}_${window.location.host}`,
                page_location: window.location.href,
                who_access_this_filter: `${user?.username}_at_${moment().format(
                  "D_MMM_YY"
                )}`,
              }
            );

            captureComponent(ref, isFilterBy);
          }}
          icon={<TbDownload className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle={isLoadingDownload ? "Sedang mengunduh" : "Rincian"}
          value={isLoadingDownload ? "Mengunduh..." : "Rincian"}
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          disabled={isLoadingDownload}
          onClick={() => {
            handleExportExcel();
          }}
          icon={<SiMicrosoftexcel className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Recalculate Data"
          value="Recalculate"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => {
            handleProductionRecapsRecalculate();
          }}
          icon={<TbReload className="text-18 text-gray-500" />}
        />
      </div>
    </ConfigProvider>
  );
});

export default Filter;
