import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "@uidotdev/usehooks";
import { MaterialReactTable } from "material-react-table";
import { MdDragIndicator, MdOutlineWarning } from "react-icons/md";
import {
  defaultTableConfig,
  MIU_TABLE_BODY_CELL_PROPS_SX,
  MIU_TABLE_HEAD_CELL_PROPS_SX,
  MIU_TABLE_HEAD_ROW_PROPS_SX,
  MIU_TABLE_PROPS_SX,
} from "../../../../../../utils/tableUtils";
import {
  formatPhoneNumber,
  formatValueByPattern,
} from "../../../../../../utils/utils";
import { Image, Tooltip } from "antd";
import { TbPencil, TbTrash } from "react-icons/tb";
import { useModalStore } from "../Modal/Modal";
import Swal from "sweetalert2";
import { useEmployeeStore } from "../../../../../../api/employee/useEmployeeStore";
import { IMAGE_NOT_FOUND } from "../../../../../../api/fallbackImageNotFound";
import { useImageSource } from "../../../../../../hooks/useImageSource";

const RowImage = ({ cell }) => {
  const { source, setSource, setDownloadedImage } = useImageSource();

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(cell.getValue(), "main");
  }, [cell.getValue()]);

  return (
    <div className="w-fit">
      <img
        src={source.main}
        alt={cell.getValue()}
        className="aspect-square w-32 cursor-pointer rounded-8"
        onClick={() => handleShowVisible("main")}
      />
      <Image
        wrapperStyle={{ display: "none" }}
        src={source.main}
        preview={{
          visible: source.mainVisible,
          src: source.main || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("main"),
        }}
      />
    </div>
  );
};

const ListData = ({ isLoading, data, handleRefreshData, page }) => {
  const toggleModal = useModalStore((state) => state.toggleModal);
  const deleteEmployee = useEmployeeStore((state) => state.deleteEmployee);

  const columns = useMemo(
    () => [
      {
        header: "NO.",
        accessorKey: "index",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center space-x-8">
              <p>{row.index + 1}</p>
            </div>
          );
        },
      },
      {
        header: "Foto",
        accessorKey: "photoFileId",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ cell }) => {
          return <RowImage cell={cell} />;
        },
      },
      {
        header: "No. Anggota",
        accessorKey: "employeeNo",
      },
      {
        header: "Nama Anggota",
        accessorKey: "name",
      },
      {
        header: "No.Telp",
        accessorKey: "phone",
        Cell: ({ cell }) => (
          <p>
            {formatValueByPattern(formatPhoneNumber(cell.getValue()), {
              pattern: "+62 ###-####-####",
            })}
          </p>
        ),
      },
      {
        header: "Aksi",
        accessorKey: "action",
        Cell: ({ row }) => {
          const deleteHandler = () => {
            Swal.fire({
              title: "Apakah anda yakin?",
              text: `Anda tidak akan dapat mengembalikan pengguna ini! ${row?.original?.name}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Ya, hapus ini!",
              cancelButtonText: "Batal",
            }).then((result) => {
              if (result.isConfirmed) {
                deleteEmployee({
                  id: row?.original?.id,
                  onSuccess: () => {
                    handleRefreshData();
                  },
                });
              }
            });
          };

          return (
            <div className="flex flex-row items-center space-x-16">
              <Tooltip placement="bottom" title="Edit" className="font-regular">
                <button
                  className="rounded-[4px] p-8 outline-none"
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleModal({ data: row?.original, isUpdate: true });
                  }}
                >
                  <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
                </button>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title="Hapus"
                className="font-regular"
              >
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteHandler();
                  }}
                  className="rounded-[4px] p-8 outline-none"
                >
                  <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
                </button>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  // default table state handler
  const columnSizingChangeHandler = (updater) => {
    setColumnSizing((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnPinningChangeHandler = (updater) => {
    setColumnPinning((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnOrderChangeHandler = (updater) => {
    setColumnOrder((prev) => {
      return updater instanceof Function ? updater(prev) : updater;
    });
  };
  const columnVisibilityHandler = (updater) => {
    setColumnVisibility((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };

  return (
    <div
      ref={ref}
      className="relative mx-16 h-full max-h-full overflow-y-scroll rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        // Enable features
        {...defaultTableConfig}
        enableRowNumbers={true}
        // State
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
          isLoading,
        }}
        initialState={{ density: "compact" }}
        onColumnSizingChange={columnSizingChangeHandler}
        onColumnPinningChange={columnPinningChangeHandler}
        onColumnOrderChange={columnOrderChangeHandler}
        onColumnVisibilityChange={columnVisibilityHandler}
        // Styles
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{ sx: MIU_TABLE_PROPS_SX }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          return {
            sx: {
              ...MIU_TABLE_BODY_CELL_PROPS_SX,
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: MIU_TABLE_HEAD_ROW_PROPS_SX,
        }}
        muiTableHeadCellProps={{
          sx: MIU_TABLE_HEAD_CELL_PROPS_SX,
        }}
      />
    </div>
  );
};

export default ListData;
