import { TbPencil, TbTrash } from "react-icons/tb";

import React from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { cn, parseToFloat } from "../../../../../helpers/Common";
import { APP_NAME } from "../../../../../configs/api";
import { DEFAULT_TABLE_THEME } from "../../../../../utils/constant";

const TableRowMasterAfdeling = ({
  data,
  handleUpdate,
  handleDelete,
  handleDetail,
}) => {
  return data.map((item, index) => (
    <tr
      className={cn(
        "table-header-regular cursor-pointer select-none",
        DEFAULT_TABLE_THEME
      )}
      key={index}
      onClick={() => handleDetail(item?.id, item?.name)}
    >
      <td className="table-row-regular font-semi-bold text-gray-600">
        {index + 1}
      </td>
      <td className="table-row-regular">{item?.code}</td>
      <td
        className={cn(
          "table-row-regular font-semi-bold",
          APP_NAME === "semai-kebun"
            ? "text-primary-red-500"
            : "text-yellow-500"
        )}
      >
        {item?.name}
      </td>
      <td className="table-row-regular">{parseToFloat(item?.areaHa)} Ha</td>
      <td className="table-row-regular">
        {moment(item?.createdTime).format("LL")}
      </td>
      <td className="table-row-regular ">
        <div className="flex flex-row items-center space-x-16">
          <button
            className="rounded-[4px] p-8 outline-none"
            onClick={(event) => {
              event.stopPropagation();
              handleUpdate({ item }, "showModalCreate");
            }}
          >
            <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation();
              Swal.fire({
                title: "Hapus Afdeling/Wilayah",
                text: `Anda akan menghapus ${item?.name}, Apa kamu yakin?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#dc2626",
                confirmButtonText: "Ya, hapus!",
                cancelButtonText: "Tidak",
                reverseButtons: true,
                heightAuto: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleDelete({
                    id: item?.id,
                    name: item?.name,
                    estate: item?.estateName,
                  });
                }
              });
            }}
            className="rounded-[4px] p-8 outline-none"
          >
            <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
          </button>
        </div>
      </td>
    </tr>
  ));
};

export default TableRowMasterAfdeling;
