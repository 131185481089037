import { ConfigProvider, DatePicker } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbDownload, TbFilter } from "react-icons/tb";
import domToImage from "dom-to-image";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import locale from "antd/es/date-picker/locale/id_ID";
import moment from "moment";
import { selectUser } from "../../../../../features/userSlice";
import { useData } from "../../hooks/DataContext";
import { useForm } from "react-hook-form";

const Filter = forwardRef(({ showModal }, ref) => {
  const { control } = useForm();
  const { isFilterBy, handleOnFilter } = useData();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const captureComponent = () => {
    const component = ref.current;
    if (!component) return;

    const estateName =
      isFilterBy.estateName !== undefined ? `_${isFilterBy.estateName}` : "";

    const afdelingName =
      isFilterBy.afdelingName !== undefined
        ? `_${isFilterBy.afdelingName}`
        : "";

    domToImage.toBlob(component).then((blob) => {
      const link = document.createElement("a");
      link.download = `${moment(isFilterBy.date).format(
        "D-MM-Y"
      )}${estateName}${afdelingName}_Data Yield.jpg`;
      link.href = URL.createObjectURL(blob);
      link.click();
    });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response.data.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetEstates({});
    return () => (isMounted = false);
  }, []);

  return (
    <div className="mx-8 mt-56 flex flex-row items-center justify-between md:mx-0 md:mt-8">
      <div className="relative flex w-full flex-row items-center space-x-8">
        {/*<ButtonComponent*/}
        {/*  tooltipPlacement="bottom"*/}
        {/*  tooltipTitle="Filter"*/}
        {/*  value="Filter"*/}
        {/*  size="none"*/}
        {/*  type="button"*/}
        {/*  className="bordered-button-transition w-full bg-white p-10 md:hidden"*/}
        {/*  onClick={showModal}*/}
        {/*  icon={<TbFilter className="text-18 text-gray-500" />}*/}
        {/*/>*/}
        <SelectHook
          name="estate"
          data={estates}
          control={control}
          placeholder="Pilih estate"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            handleOnFilter({ type: "estate", value });
            if (value !== null) {
              handleGetAfdelings({ estate: value.value });
            } else {
              setAfdelings([]);
            }
          }}
        />
        <SelectHook
          name="afdeling"
          data={afdelings}
          control={control}
          placeholder="Pilih afdeling"
          menuStyles={{ padding: 5 }}
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(value) => {
            handleOnFilter({ type: "afdeling", value });
            if (value !== null) {
              handleGetBlocks({ afdelingId: value.value });
            } else {
              setBlocks([]);
            }
          }}
        />
        <SelectHook
          control={control}
          data={blocks}
          name="block"
          className="hidden max-w-[200px] md:inline"
          placeholder="Pilih blok"
          afterOnChange={(value) => {
            handleOnFilter({
              value: value,
              type: "block",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: "#d7a137",
              colorTextQuaternary: "#e1e4e8",
              fontFamily: "Inter-Regular",
              fontSize: 14,
              colorTextPlaceholder: "#6B6F77",
            },
          }}
        >
          <div className="hidden md:inline">
            <DatePicker
              picker="month"
              locale={locale}
              format="MMMM YYYY"
              placeholder="Pilih bulan tahun"
              style={{
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                maxWidth: 250,
                borderWidth: 1,
                borderColor: "#e1e4e8",
              }}
              onChange={(value) => {
                handleOnFilter({ type: "date", value });
              }}
            />
          </div>
        </ConfigProvider>
        {/* <ButtonComponent
				tooltipPlacement="bottom"
				tooltipTitle="Muat ulang"
				size="none"
				type="button"
				className="bordered-button-transition border-yellow-500 bg-white p-10"
				onClick={() => {
					handleRefreshData();
					dispatch(getHarvestigsRefreshAggregates()).unwrap();
				}}
				icon={<TbRefresh className="text-18 text-gray-500" />}
			/> */}
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Download halaman"
          size="none"
          type="button"
          value="Download"
          className="bordered-button-transition w-full border-yellow-500 bg-white p-10 md:w-fit"
          onClick={captureComponent}
          icon={<TbDownload className="text-18 text-gray-500" />}
        />
      </div>
    </div>
  );
});

export default Filter;
