import React, { useEffect, useReducer, useState } from "react";
import ListData from "../ListData";
import { usePriceListStore } from "../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import useFilter from "../../../../../hooks/useFilter";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import { useModalStore } from "../ListData/partials/ModalUpdateHarga/ModalUpdateHarga";
import Pagination from "../../../../../components/atoms/Pagination";

const SettingPrice = () => {
  const [getPriceLists] = usePriceListStore((state) => [state.getPriceLists]);
  const [user] = useUserStore((state) => [state.user]);

  const initialFilter = {
    pageIndex: 0,
    pageSize: 7,
    fltCompany: user?.companyId,
    fltEndDateIsNull: 1,
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };

  const { isFilterBy, setIsFilterBy } = useFilter({ initialFilter });
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const paginationLength = Math.ceil(page?.total / 7);

  const [toggleModal] = useModalStore((state) => [state.toggleModal]);

  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );

  const [createPriceList, deletePriceList] = usePriceListStore((state) => [
    state.createPriceList,
    state.deletePriceList,
  ]);

  const resetHandler = () => {
    const data = [
      {
        buyerPartyId: "1ee62b3c-2e94-6ce1-8806-a7ae9f06dafd",
        buyerPartyName: "Indoraya",
        startDate: "2023-10-06",
        buyerPartyPrice: 2200,
        maxPurchasePrice: 2160,
        minPurchasePrice: 2140,
      },
      {
        buyerPartyId: "1ee62b4a-9b8a-6ae6-8806-a7ae9f06dafd",
        buyerPartyName: "GMK",
        startDate: "2023-10-05",
        buyerPartyPrice: 2195,
        maxPurchasePrice: 2165,
        minPurchasePrice: 2135,
      },
      {
        buyerPartyId: "1ee50741-1d62-6995-878c-09ca115bdb06",
        buyerPartyName: "ABS",
        startDate: "2023-10-05",
        buyerPartyPrice: 2200,
        maxPurchasePrice: 2160,
        minPurchasePrice: 2140,
      },
      {
        buyerPartyId: "1ee4d5d3-5cb0-6fc9-b27e-33157b2c0425",
        buyerPartyName: "CPKA",
        startDate: "2023-10-04",
        buyerPartyPrice: 2010,
        maxPurchasePrice: 1990,
        minPurchasePrice: 1950,
      },
      {
        buyerPartyId: "1ee4d5d5-c5da-646f-b27e-33157b2c0425",
        buyerPartyName: "SSP",
        startDate: "2023-10-04",
        buyerPartyPrice: 2035,
        maxPurchasePrice: 2000,
        minPurchasePrice: 1975,
      },
      {
        buyerPartyId: "1ee4d5d6-b107-642e-b27e-33157b2c0425",
        buyerPartyName: "SSK",
        startDate: "2023-10-05",
        buyerPartyPrice: 0,
        maxPurchasePrice: 0,
        minPurchasePrice: 0,
      },
      //   divider
      {
        buyerPartyId: "1ee62b3c-2e94-6ce1-8806-a7ae9f06dafd",
        buyerPartyName: "Indoraya",
        startDate: "2023-09-26",
        endDate: "2023-10-05",
        buyerPartyPrice: 2000,
        maxPurchasePrice: 2100,
        minPurchasePrice: 2090,
      },
      {
        buyerPartyId: "1ee62b4a-9b8a-6ae6-8806-a7ae9f06dafd",
        buyerPartyName: "GMK",
        startDate: "2023-09-26",
        endDate: "2023-10-04",
        buyerPartyPrice: 2195,
        maxPurchasePrice: 2195,
        minPurchasePrice: 2195,
      },
      {
        buyerPartyId: "1ee50741-1d62-6995-878c-09ca115bdb06",
        buyerPartyName: "ABS",
        startDate: "2023-09-26",
        endDate: "2023-10-04",
        buyerPartyPrice: 2200,
        maxPurchasePrice: 2200,
        minPurchasePrice: 2200,
      },
      {
        buyerPartyId: "1ee4d5d3-5cb0-6fc9-b27e-33157b2c0425",
        buyerPartyName: "CPKA",
        startDate: "2023-09-28",
        endDate: "2023-10-03",
        buyerPartyPrice: 2010,
        maxPurchasePrice: 2010,
        minPurchasePrice: 2010,
      },
      {
        buyerPartyId: "1ee4d5d5-c5da-646f-b27e-33157b2c0425",
        buyerPartyName: "SSP",
        startDate: "2023-09-28",
        endDate: "2023-10-03",
        buyerPartyPrice: 2035,
        maxPurchasePrice: 2035,
        minPurchasePrice: 2035,
      },
    ];

    getPriceLists({
      params: {
        pageSize: 9999,
        fltCompany: user?.companyId,
      },
      silent: true,
      onSuccess: async (priceData) => {
        await priceData.records.forEach((item, index) =>
          deletePriceList({
            id: item?.id,
            silent: index === priceData.length - 1 ? false : true,
          })
        );

        await data.forEach((item, index) => {
          createPriceList({
            body: {
              companyId: user?.companyId,
              companyName: user?.companyName,
              buyerPartyId: item?.buyerPartyId,
              buyerPartyName: item?.buyerPartyName,
              buyerPartyPrice: item?.buyerPartyPrice,
              maxPurchasePrice: item?.maxPurchasePrice,
              minPurchasePrice: item?.minPurchasePrice,
              startDate: item?.startDate,
              endDate: item?.endDate,
            },
            silent: index === data.length - 1 ? false : true,
          });
        });

        await refreshHandler(true);
      },
    });
  };

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    if (beforeFetch) {
      beforeFetch();
    }
    if (!isSilent) setRequest({ isLoading: true });
    await getPriceLists({
      params: {
        ...isFilterBy,
        pageIndex,
      },
      onSuccess: (response) => {
        setData(response?.records);
        const totalRecords = response?.totalRecords;
        setPage({
          current: pageIndex,
          total: totalRecords,
        });
        setTimeout(() => {
          setRequest({ isLoading: false });
        }, 800);
      },
    });
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const refreshHandler = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) refreshHandler();
    return () => (isMounted = false);
  }, [isFilterBy]);

  return (
    <div className="mx-16 grid grid-cols-1 grid-rows-[max-content,1fr,max-content] gap-12 overflow-hidden rounded-b-16 pt-[52px] md:h-auto md:pt-0">
      <div className="flex flex-col space-y-8 md:space-y-0">
        <div className="flex w-full flex-col items-center justify-between space-y-12 md:flex-row md:space-y-0">
          <h1
            className="text-16 font-bold text-gray-500 md:text-24"
            onClick={() => {
              if (
                user?.username === "k.emj" ||
                user?.username === "pranotodahlan"
              ) {
                resetHandler();
              }
            }}
          >
            Pemberlakuan Harga
          </h1>
          <ButtonComponent
            value="Tambah Daftar PKS"
            className="button-transition w-full md:w-auto"
            onClick={() => {
              toggleModal({ type: "create", data: {} });
            }}
          />
        </div>

        <div className="hidden grid-cols-1 gap-4 md:grid">
          <h3 className="text-18 font-bold italic text-gray-400">Perhatian</h3>
          <ul className="list-inside list-disc font-regular text-16 italic text-gray-400">
            <li>
              Jika terdapat kesalahan dalam memasukkan harga harap perbaiki
              sebelum pukul 09:00 pada tanggal mulai berlaku.
            </li>
            <li>
              Pengubahan tidak dapat dilakukan pada pukul 09:00 pada tanggal
              mulai berlaku, karena pengaturan tersebut sudah berlaku.
            </li>
          </ul>
        </div>
      </div>
      <ListData data={data} refreshHandler={refreshHandler} />
      <div className="-mt-12">
        <Pagination.Container className="mx-0 mb-0">
          <Pagination.Records
            current={page.current}
            length={paginationLength}
            totalRecords={page?.total}
          />
          {paginationLength !== 1 && (
            <Pagination
              pageCount={paginationLength}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default SettingPrice;
