import { ConfigProvider, DatePicker, Image, Tooltip, notification } from "antd";
import { MdDragIndicator, MdOutlineWarning } from "react-icons/md";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { capitalize, debounce, isUndefined } from "lodash";

import ColumnSettings from "../../../../../components/atoms/Table/partials/ColumnSettings";
import { Controller } from "react-hook-form";
import { IMAGE_NOT_FOUND } from "../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../components/atoms/InputHook";
import { MaterialReactTable } from "material-react-table";
import { TbCalendar } from "react-icons/tb";
import { checkValues } from "../../../../../helpers/validationHelper";
import { create } from "zustand";
import dayjs from "dayjs";
import { formatNumericValue } from "../../../../../helpers/Common";
import moment from "moment/moment";
import { useChemistStore } from "../../../../../api/chemist/useChemistStore";
import { useComment } from "../../../../../hooks/useComment";
import { useImageSource } from "../../../../../hooks/useImageSource";
import { useMeasure } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

const ImagesRow = ({ row }) => {
  const rowData = row.original;

  const { source, setSource, setDownloadedImage } = useImageSource();
  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(rowData?.photo1FileId, "main");
    setDownloadedImage(rowData?.photo2FileId, "second");
  }, []);

  return (
    <div className="flex flex-row items-center space-x-8">
      <Tooltip placement="bottom" title="Foto 1" className="font-regular">
        <img
          src={source.main || IMAGE_NOT_FOUND}
          alt={rowData?.photo1FileId}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("main")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.main}
        preview={{
          visible: source.mainVisible,
          src: source.main || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("main"),
        }}
      />
      {/* <Tooltip placement="bottom" title="Foto Sisa" className="font-regular">
        <img
          src={source.second || IMAGE_NOT_FOUND}
          alt={rowData?.photo2FileId}
          className="aspect-square w-[42px] cursor-pointer rounded"
          onClick={() => handleShowVisible("second")}
        />
      </Tooltip>
      <Image
        width={200}
        wrapperStyle={{ display: "none" }}
        src={source.second}
        preview={{
          visible: source.secondVisible,
          src: source.second || IMAGE_NOT_FOUND,
          onVisibleChange: () => handleShowVisible("second"),
        }}
      /> */}
    </div>
  );
};

export const useTableStore = create((set, get) => ({
  isDrawer: false,
  setIsDrawer: (value) => set({ isDrawer: !get().isDrawer }),
  sortedColumns: [],
  setSortedColumns: (value) => set({ sortedColumns: value }),
}));

const ListData = ({ data, refreshHandler }) => {
  // const data = [{ id: 1, photoFileId: "123-123123-12312312", date: "221321" }];
  const navigate = useNavigate();

  const navigateHandler = (id) => navigate(`/riwayat-multiguna/${id}`);

  const columns = useMemo(
    () => [
      {
        header: "NO.",
        accessorKey: "index",
        enableColumnOrdering: false,
        size: 68,
        Cell: ({ cell, row }) => {
          const message = generateMessage(row.original);

          return (
            <div
              className="flex flex-row items-center space-x-8"
              // onClick={() => navigateHandler(row?.original?.id)} //remove this line because has handled in muiTableBodyRowProps
            >
              <Tooltip
                placement="bottom"
                title={message.message}
                className="font-regular"
              >
                <div>
                  {message.isError ? (
                    <MdOutlineWarning className="text-24 text-yellow-600" />
                  ) : null}
                </div>
              </Tooltip>
              <p>{row.index + 1}</p>
            </div>
          );
        },
      },
      {
        header: "Foto",
        accessorKey: "photoFileId",
        size: 90, // add 90px to the width of the column for the image to fit
        Cell: ({ cell, row }) => {
          return <ImagesRow row={row} />;
        },
      },
      {
        header: "Tanggal Jeprin",
        accessorKey: "date",
        Cell: ({ cell, row }) => (
          <div>
            <p>{moment(row.original?.createdTime).format("LL")}</p>
          </div>
        ),
      },
      {
        header: "Dibuat Oleh",
        accessorKey: "userFullname",
        Cell: ({ cell, row }) => <p>{capitalize(cell.getValue())}</p>,
      },
      {
        header: "Judul",
        accessorKey: "title",
      },
      {
        header: "Deskripsi",
        accessorKey: "notes",
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  const generateMessage = (data) => {
    let message;

    let fieldNames = {
      title: "Judul",
      notes: "Deksripsi",
      photo1FileId: "Foto 1",
    };

    let items = [
      { title: data?.title },
      { notes: data?.notes },
      { photo1FileId: data?.photo1FileId },
    ];

    message = checkValues(items, fieldNames);

    return message;
  };

  return (
    <div
      ref={ref}
      className="vird relative mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <ColumnSettings
        ref={tableInstanceRef}
        columns={columns}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        isDrawer={isDrawer}
        setIsDrawer={setIsDrawer}
        sortedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
      />
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        enableColumnOrdering
        enablePinning
        enableColumnResizing
        enableDensityToggle={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableRowVirtualization
        initialState={{ density: "compact" }}
        enableTableFooter={false}
        enableBottomToolbar={false}
        enableStickyHeader
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{
          sx: {
            borderRadius: "24px",
            boxShadow: "0",
            fontFamily: "Inter-Regular",
          },
        }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          const message = generateMessage(row.original);

          return {
            sx: {
              backgroundColor:
                message.message.toLowerCase() == "sudah lengkap"
                  ? "#FFFFFF"
                  : "#F9F3E4",
              borderBottom: "1px solid #ECEDEF",
              fontFamily: "Inter-Regular",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: "#fafbfd",
            padding: "8px",
            fontFamily: "Inter-Regular",
            color: "#646769",
          },
        }}
        muiTableBodyRowProps={({ row, table }) => ({
          onClick: () => navigateHandler(row?.original?.id),
        })}
        muiTableHeadCellProps={{
          sx: { borderBottom: 0 },
        }}
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        columnResizeMode="onEnd"
        onColumnSizingChange={(updater) => {
          setColumnSizing((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnPinningChange={(updater) => {
          setColumnPinning((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
        onColumnOrderChange={(updater) => {
          setColumnOrder((prev) => {
            const newOrder =
              updater instanceof Function ? updater(prev) : updater;
            // setSortedColumns(
            //   newOrder?.map((item) =>
            //     tableInstanceRef.current
            //       ?.getAllColumns()
            //       .find((column) => column.id === item)
            //   )
            // );
            return updater instanceof Function ? updater(prev) : updater;
          });
        }}
        onColumnVisibilityChange={(updater) => {
          setColumnVisibility((prev) =>
            updater instanceof Function ? updater(prev) : updater
          );
        }}
      />
    </div>
  );
};
export default ListData;
