import ButtonComponent from "../../../atoms/ButtonComponent";
import { FaUser } from "react-icons/fa";
import InputHook from "../../../atoms/InputHook";
import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useUserStore } from "../../../../pages/MasterWorkerPage/hooks/userStore";
import { shallow } from "zustand/shallow";

const CommonProfile = ({ refreshData }) => {
  const [user, updateUser] = useUserStore(
    (state) => [state.user, state.updateUser],
    shallow
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    const requestBody = {
      ...user,
      ...data,
      isEmailVerified: user?.email === data?.email ? user?.isEmailVerified : 0,
      version: user?.version + 1,
    };

    await updateUser(requestBody?.id, requestBody?.companyId, requestBody);
  };

  const handleDefaultValue = () => {
    const state = {
      ...user,
      // position: { label: user.position, value: user.position },
    };
    reset(state);
  };

  useEffect(() => {
    handleDefaultValue();
  }, []);

  return (
    <div className="overflow-hidden rounded-16 border-1 border-gray-10 bg-white pt-24 md:mx-32">
      <div className="flex flex-row items-center space-x-16 px-24">
        <FaUser className="text-24 text-gray-500" />
        <h1 className="text-left font-medium text-16 text-gray-500 md:text-[20px]">
          Tentang anda
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-16 p-24 md:grid-cols-2">
          <InputHook
            register={register}
            label="Nama Lengkap"
            name="fullname"
            errors={errors?.fullname}
            inputClassName="input-large-border"
            placeholder="Masukkan nama lengkap"
          />
          <InputHook
            register={register}
            label="Username"
            name="username"
            disabled={true}
            errors={errors?.username}
            inputClassName="input-large-border"
            placeholder="Masukkan username"
          />
          <InputHook
            register={register}
            label="Email"
            name="email"
            errors={errors?.email}
            inputClassName="input-large-border"
            placeholder="Masukkan email"
          />
          <InputHook
            register={register}
            label="Nomer Telepon"
            name="phone"
            errors={errors?.phone}
            inputClassName="input-large-border"
            placeholder="Masukkan nomer telepon"
          />
          {/* <SelectHook
						label="Jabatan"
						name="position"
						data={dataPositions}
						control={control}
						placeholder="Pilih Jabatan"
						errors={errors?.position}
					/> */}
          <InputHook
            disabled
            register={register}
            label="Jabatan"
            name="position"
            errors={errors?.position}
            inputClassName="input-large-border"
            placeholder="Masukkan Jabatan"
          />
        </div>

        <div className="card-footer-container">
          <ButtonComponent value="Simpan" size="sm" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default CommonProfile;
