import "react-lazy-load-image-component/src/effects/blur.css";

import { Image, Tooltip } from "antd";
import React, { useEffect } from "react";
import { TbPencil, TbTrash } from "react-icons/tb";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import { useImageSource } from "../../../../../../../../../hooks/useImageSource";
import Swal from "sweetalert2";
import { useUserStore } from "../../../../../../../../MasterWorkerPage/hooks/userStore";
import { cn } from "../../../../../../../../../helpers/Common";
import { APP_NAME } from "../../../../../../../../../configs/api";

const Item = ({ item, index, handleRefreshData, onUpdate }) => {
  const { source, setSource, setDownloadedImage } = useImageSource();
  const deleteUser = useUserStore((state) => state.deleteUser);

  const deleteUserHandler = () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda tidak akan dapat mengembalikan ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus ini!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser({
          id: item?.id,
          companyId: item?.companyId,
          fullname: item?.fullname,
          onSuccess: () => {
            handleRefreshData();
            // Swal.fire("Terhapus!", "Data telah dihapus.", "success");
          },
        });
      }
    });
  };

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  useEffect(() => {
    setDownloadedImage(item?.photoFileId, "main");
  }, [item?.photoFileId]);

  return (
    <tr
      className={cn(
        "table-header-regular relative hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out",
        APP_NAME === "semai-kebun" && "hover:bg-[rgba(124,7,17,0.2)]"
      )}
      key={index}
    >
      <td className="table-row-regular">{index + 1}</td>
      <td className="table-row-regular w-fit">
        <div className="w-fit">
          <img
            src={source.main}
            alt={item?.photoFileId}
            className="aspect-square w-32 cursor-pointer rounded-8"
            onClick={() => handleShowVisible("main")}
          />
          <Image
            wrapperStyle={{ display: "none" }}
            src={source.main}
            preview={{
              visible: source.mainVisible,
              src: source.main || IMAGE_NOT_FOUND,
              onVisibleChange: () => handleShowVisible("main"),
            }}
          />
        </div>
      </td>
      <td className="table-row-regular ">{item?.fullname}</td>
      <td className="table-row-regular ">{item?.email}</td>
      <td className="table-row-regular ">{item?.username}</td>
      <td className="table-row-regular ">{item?.defaultEstateName}</td>
      <td className="table-row-regular ">
        {item?.defaultAfdelingName
          ? item.defaultAfdelingName
          : !item?.defaultEstateName
          ? null
          : "Semua Wilayah"}
      </td>
      <td className="table-row-regular ">{item?.position}</td>
      <td className="table-row-regular ">
        {item.phone !== "0" ? item.phone : null}
      </td>
      <td className="table-row-regular ">
        <div className="flex flex-row items-center space-x-16">
          {/*<Tooltip*/}
          {/*  placement="bottom"*/}
          {/*  title="Tautan Login"*/}
          {/*  className="font-regular"*/}
          {/*>*/}
          {/*  <button*/}
          {/*    className="rounded-[4px] p-8 outline-none"*/}
          {/*    onClick={(event) => {*/}
          {/*      event.stopPropagation();*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <TbCopy className="text-24 text-gray-200 hover:text-gray-500" />*/}
          {/*  </button>*/}
          {/*</Tooltip>*/}
          <Tooltip placement="bottom" title="Edit" className="font-regular">
            <button
              className="rounded-[4px] p-8 outline-none"
              onClick={(event) => {
                event.stopPropagation();
                onUpdate({ item });
              }}
            >
              <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
          </Tooltip>

          <Tooltip placement="bottom" title="Hapus" className="font-regular">
            <button
              onClick={(event) => {
                event.stopPropagation();
                deleteUserHandler();
              }}
              className="rounded-[4px] p-8 outline-none"
            >
              <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
            </button>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default Item;
