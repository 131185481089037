import React from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsActive,
  getIsSidebarSize,
  toggleChangeSidebarSize,
} from "../../../features/navbarSlice";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../api/masterRoutes.js.js";
import LOGO_SAWIT_WEB_HORIZONTAL from "../../../assets/icons/horizontal.png";
import LOGO_SAWIT_WEB_VERTICAL from "../../../assets/icons/vertical.png";
import { APP_NAME } from "../../../configs/api.js";
import { cn } from "../../../helpers/Common.js";
import { useUserStore } from "../../../pages/MasterWorkerPage/hooks/userStore";
import SidebarItem from "../../organisms/Navbar/Item";

import LOGO_SEMAI_KEBUN_VERTICAL from "../../../assets/icons/logo-semai-kebun-v.png";
import LOGO_SEMAI_KEBUN_HORIZONTAL from "../../../assets/icons/logo-semai-kebun-h.png";

const MainSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActive = useSelector(getIsActive);
  const isSidebarSize = useSelector(getIsSidebarSize);
  const user = useUserStore((state) => state.user);

  return (
    <div
      className={`${isSidebarSize ? "min-w-[250px]" : "w-[70px]"} ${
        !isActive && "hidden"
      } fixed left-0 top-54 z-20 h-[93dvh] grid-cols-1  gap-16 overflow-scroll bg-white px-8 py-16 pb-16 md:relative md:top-0 md:grid md:h-full md:max-h-screen`}
    >
      <div className="relative grid h-full w-full grid-cols-1 grid-rows-[max-content,1fr] gap-12">
        <div className="right-[-16px] top-48 z-40 hidden md:absolute md:block ">
          <button
            className={cn(
              "w-fit rounded-l-16 border-1 border-dashed border-yellow-600 bg-white p-4 pr-24 no-underline outline-none drop-shadow-md",
              APP_NAME === "semai-kebun" && "border-primary-red-500"
            )}
            onClick={() => dispatch(toggleChangeSidebarSize())}
          >
            {isSidebarSize && (
              <HiChevronDoubleLeft
                className={`text-16 ${
                  APP_NAME === "sawit-web"
                    ? "text-yellow-600"
                    : "text-primary-red-500"
                }`}
              />
            )}
            {!isSidebarSize && (
              <HiChevronDoubleRight
                className={`text-16 ${
                  APP_NAME === "sawit-web"
                    ? "text-yellow-600"
                    : "text-primary-red-500"
                }`}
              />
            )}
          </button>
        </div>

        <div className="relative flex h-fit w-full flex-col items-center justify-center">
          {isSidebarSize ? (
            <img
              src={
                APP_NAME === "sawit-web"
                  ? LOGO_SAWIT_WEB_HORIZONTAL
                  : LOGO_SEMAI_KEBUN_HORIZONTAL
              }
              onClick={() =>
                APP_NAME === "sawit-web"
                  ? navigate("/produksi-kebun")
                  : navigate("/riwayat-panen")
              }
              className="h-auto w-[165px] cursor-pointer"
              alt="PT Semai Raya International"
            />
          ) : (
            <img
              src={
                APP_NAME === "sawit-web"
                  ? LOGO_SAWIT_WEB_VERTICAL
                  : LOGO_SEMAI_KEBUN_VERTICAL
              }
              onClick={() => navigate("/produksi-kebun")}
              className="w-[62px] cursor-pointer"
              alt="PT Semai Raya International"
            />
          )}
        </div>

        <div className="relative  flex max-h-full flex-col space-y-8 pb-16">
          {routes({ user })?.map((item, index) => {
            return (
              <SidebarItem
                key={index}
                item={item}
                icon={item.icon}
                activeIcon={item.activeIcon}
                title={item.title}
                linkTo={item.link}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainSidebar;
