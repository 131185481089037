import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import React from "react";
import { TbFilter, TbRefresh } from "react-icons/tb";
import { cn } from "../../../../../helpers/Common";
import { APP_NAME } from "../../../../../configs/api";

const Filter = ({ onShowModal, handleRefreshData, modalName, showModal }) => {
  return (
    <div className="mx-16 mt-56 grid grid-cols-1 gap-8 md:mt-0 md:grid-cols-2">
      <div className="order-2 grid w-full grid-cols-1 gap-8 md:order-1 md:flex md:w-fit md:flex-row md:items-center md:space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={handleRefreshData}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
      </div>
      <div className="order-1 flex flex-row items-center space-x-8 md:order-2 md:justify-end">
        <ButtonComponent
          value="Buat Satuan"
          className={cn(
            "bordered-button-transition w-full md:w-fit",
            APP_NAME === "sawit-web"
              ? "border-yellow-500 text-yellow-500"
              : "border-primary-red-500 text-primary-red-500"
          )}
          onClick={() => onShowModal(modalName, "showModalCreate")}
        />
        <ButtonComponent
          value="Buat Banyak"
          onClick={() => onShowModal(modalName, "showModalMassCreate")}
        />
      </div>
    </div>
  );
};

export default Filter;
