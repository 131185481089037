import { create } from "zustand";

export const useSpotCheckStore = create((set, get) => ({
  data: [],
  spotChecks: [],
  request: {
    isLoadingSpotChecks: true,
    isLoadingData: true,
    isLoadingRequest: false,
  },
  page: {
    total: 1,
    current: 0,
  },
  paginationLenght: () => Math.ceil(get().page.total / 24), //important to devide with 24 because we use 24 data per page
  setData: (data) => set({ data }),
  setSpotChecks: (spotChecks) => set({ spotChecks }),
  setRequest: (request) =>
    set((state) => ({ request: { ...state.request, ...request } })),
  setPage: (page) => set((state) => ({ page: { ...state.page, ...page } })),
}));
