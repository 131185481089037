// Partial untuk validasi Array
const validateArray = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (Array.isArray(value) && value.length < items[i].min) {
      errorList.push(
        `${fieldNames[key]} ${config.minArrayMessage} ${items[i].min}`
      );
    } else if (Array.isArray(value) && value.length > items[i].max) {
      errorList.push(
        `${fieldNames[key]} ${config.maxArrayMessage} ${items[i].max}`
      );
    }
  }
};

// Partial untuk validasi nilai numerik
const validateNumericValue = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (typeof items[i].min !== "undefined" && value < items[i].min) {
      errorList.push(
        `${fieldNames[key]} ${config.minValueMessage} ${items[i].min}`
      );
    } else if (typeof items[i].max !== "undefined" && value > items[i].max) {
      errorList.push(
        `${fieldNames[key]} ${config.maxValueMessage} ${items[i].max}`
      );
    } else if (
      typeof items[i].minInclusive !== "undefined" &&
      value <= items[i].minInclusive
    ) {
      errorList.push(
        `${fieldNames[key]} harus ${config.minValueMessage} atau sama dengan ${items[i].minInclusive}`
      );
    } else if (
      typeof items[i].maxInclusive !== "undefined" &&
      value >= items[i].maxInclusive
    ) {
      errorList.push(
        `${fieldNames[key]} harus ${config.maxValueMessage} atau sama dengan ${items[i].maxInclusive}`
      );
    }
  }
};

// Partial untuk validasi nilai non-null
const validateNonNull = (items, fieldNames, errorList) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (!value && value !== 0) {
      errorList.push(fieldNames[key]);
    }
  }
};

// Partial untuk validasi tipe data
const validateDataType = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (items[i].dataType && typeof value !== items[i].dataType) {
      errorList.push(
        `${fieldNames[key]} ${config.dataTypeMessage} ${items[i].dataType}`
      );
    }
  }
};

// Partial untuk validasi format
const validateFormat = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (items[i].format && !items[i].format.test(value)) {
      errorList.push(`${fieldNames[key]} ${config.formatMessage}`);
    }
  }
};

// Partial untuk validasi relasi antar nilai
const validateRelation = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i].relatedValue;

    if (
      items[i].relatedField &&
      items[i].relation &&
      items[i].relatedValue !== items[i].relatedField
    ) {
      errorList.push(
        `${fieldNames[key]} ${config.relationMessage} ${items[i].relation} ${value}`
      );
    }
  }
};

// Partial untuk validasi panjang atau jumlah karakter
const validateLength = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (items[i].maxLength && value.length > items[i].maxLength) {
      errorList.push(
        `${fieldNames[key]} ${config.maxLengthMessage} ${items[i].maxLength} ${config.charactersMessage}`
      );
    }
  }
};

// Partial untuk validasi dengan pola atau ekspresi reguler
const validatePattern = (items, fieldNames, errorList, config) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (items[i].pattern && !items[i].pattern.test(value)) {
      errorList.push(
        `${fieldNames[key]} ${config.patternMessage} ${items[i].pattern}`
      );
    }
  }
};

// Partial untuk validasi dengan logika kustom
const validateCustomLogic = (items, fieldNames, errorList) => {
  for (let i = 0; i < items.length; i++) {
    const key = Object.keys(items[i])[0];
    const value = items[i][key];

    if (items[i].customValidation && !items[i].customValidation(value)) {
      errorList.push(
        `${fieldNames[key]} ${
          items[i].customValidationMessage ||
          "tidak memenuhi validasi kustom yang ditentukan"
        }`
      );
    }
  }
};

const defaultConfig = {
  mainErrorMessage: "Belum lengkap:",
  mainTrueMessage: "Sudah lengkap",
  minValueMessage: "harus lebih besar atau sama dengan",
  maxValueMessage: "harus maksimal",
  minArrayMessage: "kurang dari",
  maxArrayMessage: "lebih dari",
  customLogicMessage: "tidak memenuhi validasi kustom yang ditentukan",
  dataTypeMessage: "harus bertipe",
  formatMessage: "tidak sesuai dengan format yang diharapkan",
  relationMessage: "harus",
  maxLengthMessage: "tidak boleh lebih dari",
  charactersMessage: "karakter",
  patternMessage: "tidak sesuai dengan pola",
};

// Main function
export const checkValues = (items = [], fieldNames = {}, customConfig = {}) => {
  const config = { ...defaultConfig, ...customConfig };

  const errorList = [];

  // Memanggil partials untuk validasi
  validateArray(items, fieldNames, errorList, config);
  validateNumericValue(items, fieldNames, errorList, config);
  validateNonNull(items, fieldNames, errorList);
  validateDataType(items, fieldNames, errorList);
  validateFormat(items, fieldNames, errorList);
  validateRelation(items, fieldNames, errorList);
  validateLength(items, fieldNames, errorList);
  validatePattern(items, fieldNames, errorList);
  validateCustomLogic(items, fieldNames, errorList);

  if (errorList.length > 0) {
    return {
      message: `${config.mainErrorMessage} ${errorList.join(", ")}`,
      isError: true,
    };
  } else {
    return { message: config.mainTrueMessage, isError: false };
  }
};
