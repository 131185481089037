export const pantailinuh2008 = {
  type: "FeatureCollection",
  name: "pantailinuhtt08_110ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "28",
        Nm_Pemilik: "Barokah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873367876888452, -3.851391495488703],
              [114.873659161861355, -3.851575186249291],
              [114.873806475524646, -3.851590430162842],
              [114.873854225277825, -3.851574679381736],
              [114.873851689223926, -3.853033606890803],
              [114.873846637535465, -3.85303359864168],
              [114.872108759873214, -3.853030758999366],
              [114.872281529298562, -3.852825610098427],
              [114.872474953673404, -3.852707442848448],
              [114.872705524229289, -3.85259030429958],
              [114.872868886506794, -3.852456250712272],
              [114.873102936504992, -3.852145308919185],
              [114.873231526810059, -3.851878815722922],
              [114.873328458829221, -3.851532136331203],
              [114.873367876888452, -3.851391495488703],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "29",
        Nm_Pemilik: "Eko Sugiarto",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87385422643554, -3.851574678803921],
              [114.873931715882122, -3.851549094207968],
              [114.87413991385462, -3.851632397902928],
              [114.874800459454974, -3.852583842562259],
              [114.874954730691442, -3.852696614403827],
              [114.875144177258591, -3.852746691802997],
              [114.875822963915766, -3.852684387455888],
              [114.876185851741027, -3.852814903673903],
              [114.876172825694084, -3.8529284779661],
              [114.876120323292454, -3.853037308403998],
              [114.873989274632805, -3.853033831548535],
              [114.873851689430126, -3.853033606891135],
              [114.87385422643554, -3.851574678803921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "30",
        Nm_Pemilik: "Rudi Ismanto",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873990362602044, -3.853773158350188],
              [114.87398620118816, -3.853773145798699],
              [114.872077131665193, -3.853767385591622],
              [114.872017103546256, -3.853597128110036],
              [114.871931736491973, -3.853240957326511],
              [114.872108759873214, -3.853030758999366],
              [114.873846637535465, -3.85303359864168],
              [114.873990750186579, -3.853033834639792],
              [114.873990362602044, -3.853773158350188],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "31",
        Nm_Pemilik: "Edi Joharsa",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876120323292454, -3.853037308403998],
              [114.876047926639004, -3.853187381038923],
              [114.875972752726383, -3.853308800211583],
              [114.875924596675077, -3.853455888559875],
              [114.875901833690989, -3.853641442799886],
              [114.875910950778817, -3.853778948970883],
              [114.874171632135628, -3.853773705067996],
              [114.873990364168151, -3.853773158354904],
              [114.873990751146621, -3.853033834642198],
              [114.876120323292454, -3.853037308403998],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "32",
        Nm_Pemilik: "Norsehan",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874169877635794, -3.854588283331136],
              [114.874168217539562, -3.854588277302346],
              [114.873090813587808, -3.854584363925322],
              [114.872765052480901, -3.854431453249867],
              [114.872261015971745, -3.854288936280918],
              [114.872077131665193, -3.853767385591622],
              [114.87398620118816, -3.853773145798699],
              [114.874171632135628, -3.853773705067996],
              [114.874169877635794, -3.854588283331136],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "33",
        Nm_Pemilik: "Tutik Andayani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875910950778817, -3.853778948970883],
              [114.875927938192675, -3.8540351319854],
              [114.876052428995706, -3.854582676870646],
              [114.876056579949264, -3.854595132967402],
              [114.875016374649761, -3.854591357040723],
              [114.874169879409706, -3.854588283337586],
              [114.874171632135628, -3.853773705067996],
              [114.875910950778817, -3.853778948970883],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "34a",
        Nm_Pemilik: "M. Salmani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875015199322064, -3.855294082716932],
              [114.875014629311281, -3.855294080681366],
              [114.874214759917109, -3.855291223887552],
              [114.874202567975161, -3.855114480415077],
              [114.874168826535112, -3.855064592861785],
              [114.874032423625451, -3.854941593741767],
              [114.873549777786835, -3.854763726530581],
              [114.87313213586792, -3.854603760500331],
              [114.873090813587808, -3.854584363925322],
              [114.874168217539562, -3.854588277302346],
              [114.875016374649761, -3.854591357040723],
              [114.875015199322064, -3.855294082716932],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.0,
        No_Kav: "34b",
        Nm_Pemilik: "Samsul Muarif",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876056579949264, -3.854595132967402],
              [114.876291021715119, -3.85529863785201],
              [114.87501519939886, -3.855294082695821],
              [114.875016374649761, -3.854591357040723],
              [114.876056579949264, -3.854595132967402],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "37",
        Nm_Pemilik: "Sarjono",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876291021715119, -3.85529863785201],
              [114.876308592992984, -3.855351364996002],
              [114.876359914107482, -3.855533704600161],
              [114.876373138095957, -3.855821755224294],
              [114.876363497995172, -3.855976639453061],
              [114.874100286404129, -3.855972892991179],
              [114.874145317403787, -3.85592224242016],
              [114.874232428542101, -3.855547361825572],
              [114.874214759917109, -3.855291223887552],
              [114.875014629311281, -3.855294080681366],
              [114.876291021715119, -3.85529863785201],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "38",
        Nm_Pemilik: "Sumardi Solikin",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876363497995172, -3.855976639453061],
              [114.876354471638265, -3.856121726698461],
              [114.876193352998257, -3.856573580205813],
              [114.873639675689347, -3.856566089761256],
              [114.873633781555384, -3.856519042084793],
              [114.873630175937834, -3.856476940333785],
              [114.873922585969069, -3.856172769151838],
              [114.874100286404129, -3.855972892991179],
              [114.876363497995172, -3.855976639453061],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "39b",
        Nm_Pemilik: "Budiman Bernandus",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875992293034685, -3.857183286335003],
              [114.875979353768557, -3.85722685389379],
              [114.875825214520916, -3.857552487282562],
              [114.875703527149085, -3.85764018757548],
              [114.875467729817757, -3.857971616447331],
              [114.875355740321666, -3.858163347198419],
              [114.875226102566003, -3.858245027051617],
              [114.874656722637084, -3.858191595970495],
              [114.87451142806043, -3.858163229854715],
              [114.874398854469646, -3.857788925229202],
              [114.874238221453766, -3.85752250650777],
              [114.874057282770565, -3.857414046237326],
              [114.873770264189744, -3.857267331884147],
              [114.873749404388391, -3.8571755831397],
              [114.875992293034685, -3.857183286335003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.801,
        No_Kav: "39a",
        Nm_Pemilik: "Budiman Bernandus",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876193349174159, -3.856573585936355],
              [114.876073734294451, -3.856909071155953],
              [114.875992293034912, -3.857183286234926],
              [114.873749404388391, -3.8571755831397],
              [114.873654114995119, -3.856756466926783],
              [114.873639686072806, -3.856566110751033],
              [114.876193349174159, -3.856573585936355],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.366,
        No_Kav: "2",
        Nm_Pemilik: "Wawan Istar",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.865613207635505, -3.843459440961726],
              [114.865548820827101, -3.843418795290886],
              [114.865579783850663, -3.843118880324507],
              [114.865874716635375, -3.842368469879645],
              [114.866800917060218, -3.842771744793033],
              [114.866741402690906, -3.843337496371041],
              [114.866618804960595, -3.84346398800289],
              [114.866528053616378, -3.843791204457165],
              [114.866267901118491, -3.843762637438175],
              [114.866127324058482, -3.843367617462912],
              [114.865679887772345, -3.843502319677715],
              [114.865613207635505, -3.843459440961726],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.336,
        No_Kav: "3",
        Nm_Pemilik: null,
        NO_PETA: "91",
        NAMA_SERTI: "Suyono/Sarjo",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.870715770517293, -3.848314371981759],
              [114.870880855013226, -3.848389318326064],
              [114.871614033706095, -3.848558853998244],
              [114.871613789704369, -3.848657100914895],
              [114.871559331812477, -3.848855153430176],
              [114.871427533853307, -3.848958152434037],
              [114.871247170337483, -3.848923821956297],
              [114.87118689661105, -3.848747502958925],
              [114.870863435266315, -3.848612872379741],
              [114.870845052920501, -3.848545069421241],
              [114.870691794428424, -3.848461683089562],
              [114.870715770517293, -3.848314371981759],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.538,
        No_Kav: "66",
        Nm_Pemilik: null,
        NO_PETA: "68",
        NAMA_SERTI: "Jamal/Gumberi",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881157395550261, -3.854317767730849],
              [114.88128879220811, -3.854349972298079],
              [114.881425895324043, -3.854274722300361],
              [114.881490525553403, -3.854172879434826],
              [114.881499207062248, -3.854556481813293],
              [114.881299247529768, -3.855021193870878],
              [114.881464773929807, -3.855227880466387],
              [114.881621007324696, -3.855658372096713],
              [114.881578255396619, -3.856204146320609],
              [114.881575782857269, -3.856308645448386],
              [114.881457459959293, -3.856710806337945],
              [114.881290931293989, -3.856912730142432],
              [114.881078206881568, -3.856996692013354],
              [114.880919057527862, -3.856900682944331],
              [114.880948887922656, -3.856476065919803],
              [114.881027271276551, -3.856382343679611],
              [114.881105438633568, -3.85583386097497],
              [114.880931018988335, -3.855360838915034],
              [114.880981878322117, -3.854892924714062],
              [114.881164105884665, -3.854537275826544],
              [114.881157395550261, -3.854317767730849],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.416,
        No_Kav: "67a",
        Nm_Pemilik: null,
        NO_PETA: "70",
        NAMA_SERTI: "Nasrudin/San Wikarma",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878812322587265, -3.855143449441631],
              [114.879023535203089, -3.855057633915191],
              [114.879324928852014, -3.854846452788112],
              [114.879756228676754, -3.854247048840298],
              [114.879907882931462, -3.853868138430305],
              [114.879970849106414, -3.853698151974436],
              [114.880083242156161, -3.853313260000069],
              [114.880090883461023, -3.853227457562824],
              [114.880311207412404, -3.853449546574697],
              [114.880706616208315, -3.853888349412018],
              [114.881018642980024, -3.854116762920706],
              [114.880631870584111, -3.854092248287952],
              [114.880099834985387, -3.854346025733312],
              [114.880095703977133, -3.854436284015844],
              [114.880001849058161, -3.854467447202762],
              [114.879973836159834, -3.854734258260785],
              [114.879645246548506, -3.854882576198599],
              [114.879609097695479, -3.855278882615439],
              [114.879452384056734, -3.855447253708654],
              [114.878812322587265, -3.855143449441631],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.29,
        No_Kav: "6",
        Nm_Pemilik: "Rusmiah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87199333447451, -3.850262722528012],
              [114.871926109097799, -3.850501329426047],
              [114.871939142594741, -3.85062792570428],
              [114.87207732700557, -3.850957542588113],
              [114.87218493458515, -3.851472079384172],
              [114.872169104040864, -3.851782694236487],
              [114.871726868374978, -3.852144597350008],
              [114.871721487268502, -3.85197936688485],
              [114.871640019089412, -3.851667344653389],
              [114.871531610901741, -3.851475095357979],
              [114.871466957049435, -3.851388834257694],
              [114.871290041180487, -3.851262732656171],
              [114.871243179120356, -3.851008972255651],
              [114.871328427167057, -3.850732273136569],
              [114.871367260033082, -3.850488035596494],
              [114.87199333447451, -3.850262722528012],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.104,
        No_Kav: "46c",
        Nm_Pemilik: null,
        NO_PETA: "51",
        NAMA_SERTI: "Suranto/Ahmad Ramlan",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881110561035442, -3.854163160740675],
              [114.88108410740918, -3.854121871880506],
              [114.880852904040921, -3.853940422398899],
              [114.88080461659986, -3.853909419664781],
              [114.880908099096089, -3.853718386234668],
              [114.880908377467037, -3.85371857519611],
              [114.88120596349593, -3.853920579993056],
              [114.881206704418247, -3.853921082939412],
              [114.881110561035442, -3.854163160740675],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.166,
        No_Kav: "67b",
        Nm_Pemilik: null,
        NO_PETA: "70",
        NAMA_SERTI: "Nasrudin/San Wikarma",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881627971627296, -3.853873730406977],
              [114.881553192585656, -3.853980345557139],
              [114.881408622376711, -3.854259927022567],
              [114.88128721435082, -3.854321429254755],
              [114.881156998239746, -3.854285656711135],
              [114.88115351596727, -3.854230204858517],
              [114.881110561035442, -3.854163160740675],
              [114.881206704418247, -3.853921082939412],
              [114.88120596349593, -3.853920579993056],
              [114.881627971627296, -3.853873730406977],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.482,
        No_Kav: "46b",
        Nm_Pemilik: null,
        NO_PETA: "51",
        NAMA_SERTI: "Suranto/Ahmad Ramlan",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880102818003252, -3.853181431538513],
              [114.880165365445734, -3.853137051540731],
              [114.880514820340494, -3.853069756794945],
              [114.88090531276309, -3.853239788321144],
              [114.880997576144125, -3.853177177205563],
              [114.880908377467037, -3.85371857519611],
              [114.880908099096089, -3.853718386234668],
              [114.88080461659986, -3.853909419664781],
              [114.880726420119004, -3.853859213958597],
              [114.880102818003252, -3.853181431538513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.95,
        No_Kav: "64",
        Nm_Pemilik: null,
        NO_PETA: "71",
        NAMA_SERTI: "Muryono/Tahar",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881150450830347, -3.853043484604874],
              [114.881302875581852, -3.852015578252213],
              [114.881482648453485, -3.851781906143986],
              [114.881847204077317, -3.851761050885286],
              [114.882093465559805, -3.851791391033223],
              [114.882203372751206, -3.851850026849628],
              [114.881688662879469, -3.852827161458303],
              [114.881150450830347, -3.853043484604874],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.259,
        No_Kav: "49b",
        Nm_Pemilik: null,
        NO_PETA: "62",
        NAMA_SERTI: "Kismo Suwito/Mintorejo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879173375014261, -3.851924378437896],
              [114.879061340177046, -3.851706786200917],
              [114.878910455825917, -3.851152775326957],
              [114.878956558403615, -3.851098687980673],
              [114.879008754248957, -3.851120787754306],
              [114.879016600789328, -3.851127529160267],
              [114.879082028518624, -3.851183741812815],
              [114.879156830158891, -3.851260823158452],
              [114.879330027835323, -3.851350704533685],
              [114.879386124237371, -3.851410477122992],
              [114.87943888613438, -3.851554982226242],
              [114.879191236479372, -3.851877634052722],
              [114.879173375014261, -3.851924378437896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.783,
        No_Kav: "60b",
        Nm_Pemilik: null,
        NO_PETA: "52",
        NAMA_SERTI: "Jumirin/Jaman",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879797354394796, -3.852887597324452],
              [114.87970957013269, -3.852801590153087],
              [114.879358713746484, -3.85227011185351],
              [114.879465069175893, -3.852279536232588],
              [114.879517778656194, -3.852297330435645],
              [114.879593977763292, -3.85229752083234],
              [114.879789729450181, -3.852148859929035],
              [114.879809816806372, -3.851927147621972],
              [114.879980192962705, -3.851768610715892],
              [114.879980195759344, -3.851768610048047],
              [114.880230306565949, -3.851759422918578],
              [114.880231321463555, -3.851760498123273],
              [114.880427127756462, -3.851967939538016],
              [114.88036967982292, -3.85228572116506],
              [114.880261592688697, -3.852538613749905],
              [114.87999546432728, -3.852702798931614],
              [114.879797354394796, -3.852887597324452],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.974,
        No_Kav: "49a",
        Nm_Pemilik: null,
        NO_PETA: "62",
        NAMA_SERTI: "Kismo Suwito/Mintorejo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879280630834813, -3.850262912894328],
              [114.878962828033238, -3.850873738518324],
              [114.878943787440789, -3.851064200498571],
              [114.878768211943907, -3.85126492919345],
              [114.878355102817878, -3.850441908300996],
              [114.87829993434066, -3.850224616281273],
              [114.878336283364007, -3.850007552946229],
              [114.878704206146224, -3.849790135518669],
              [114.879280630834813, -3.850262912894328],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.128,
        No_Kav: "50a",
        Nm_Pemilik: null,
        NO_PETA: "61",
        NAMA_SERTI: "Rukianto/Wongso Karso",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87899758299028, -3.847720343066685],
              [114.87899789778551, -3.847593118059374],
              [114.879305621916274, -3.847233101263688],
              [114.879496615476967, -3.84747899914234],
              [114.87899758299028, -3.847720343066685],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.041,
        No_Kav: "50b",
        Nm_Pemilik: null,
        NO_PETA: "61",
        NAMA_SERTI: "Rukianto/Wongso Karso",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879662868476345, -3.849362123980248],
              [114.879525668688345, -3.849318400412845],
              [114.879363279748773, -3.849027385757551],
              [114.879394575935123, -3.848681354972508],
              [114.879264299112378, -3.84818693786722],
              [114.878997350059791, -3.847814479592279],
              [114.87899758299028, -3.847720343066685],
              [114.879496615476967, -3.84747899914234],
              [114.87970251100532, -3.847744082692733],
              [114.879815870253566, -3.848301003602734],
              [114.879800708610006, -3.848901285808756],
              [114.879662868476345, -3.849362123980248],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.758,
        No_Kav: "52",
        Nm_Pemilik: null,
        NO_PETA: "67",
        NAMA_SERTI: "Waliyo/Karto Dikromo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879340332701048, -3.847225064481343],
              [114.87983419711, -3.84694735131289],
              [114.880154797164764, -3.84663103598325],
              [114.880859892617778, -3.846845072347651],
              [114.880099122240921, -3.847352712687703],
              [114.879613322488353, -3.847566907873546],
              [114.879340332701048, -3.847225064481343],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.574,
        No_Kav: "57a",
        Nm_Pemilik: null,
        NO_PETA: "55",
        NAMA_SERTI: "Damai/Narima",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882284435909042, -3.844653341728281],
              [114.882396855462687, -3.84457404180872],
              [114.882684430447455, -3.844761732272946],
              [114.882571173492053, -3.845318853019143],
              [114.882634947100541, -3.845373067745526],
              [114.882578919244821, -3.84558746251714],
              [114.882492857290742, -3.845705495404244],
              [114.882554849045533, -3.845800247627786],
              [114.882524214408988, -3.845947135763818],
              [114.882526887915546, -3.845949135269203],
              [114.88236591398757, -3.846047459677555],
              [114.882258955563557, -3.84576015108227],
              [114.882200171225449, -3.845592431413078],
              [114.88219942977662, -3.845347826720979],
              [114.88227836780159, -3.844981794768509],
              [114.882284435909042, -3.844653341728281],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.896,
        No_Kav: "57b",
        Nm_Pemilik: null,
        NO_PETA: "55",
        NAMA_SERTI: "Damai/Narima",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882526887915546, -3.845949135269203],
              [114.882660187187597, -3.84604882927161],
              [114.882785915092654, -3.846212999387125],
              [114.882955235520598, -3.846431333996367],
              [114.883420419137735, -3.846702265408604],
              [114.88341995876408, -3.846888757180367],
              [114.883208916259278, -3.847357166549133],
              [114.88302020423437, -3.84741078347007],
              [114.882832978756298, -3.847460220411903],
              [114.882761579009454, -3.847479154451239],
              [114.8828052917261, -3.847356160949626],
              [114.882837872038252, -3.847238670550899],
              [114.882722984030195, -3.846907291944743],
              [114.882401966966597, -3.846144304126613],
              [114.88236591398757, -3.846047459677555],
              [114.882526887915546, -3.845949135269203],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.444,
        No_Kav: "51a",
        Nm_Pemilik: null,
        NO_PETA: "64",
        NAMA_SERTI: "Sarmi/Sumarto Wiyono",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880065351629739, -3.849501845894002],
              [114.879986598836084, -3.84947842176945],
              [114.879709726670086, -3.849362600294282],
              [114.879832991444871, -3.848907105400277],
              [114.879857064298193, -3.848290935419485],
              [114.879739081267331, -3.847750849873398],
              [114.879838898897546, -3.847711466134686],
              [114.879912353783752, -3.847795916757955],
              [114.880168907418877, -3.8478880017928],
              [114.880385643618496, -3.848033916480505],
              [114.880544003426266, -3.848186719737858],
              [114.880786394605749, -3.848342077372703],
              [114.880921625412498, -3.848408067485652],
              [114.880968086607538, -3.848499628312447],
              [114.880946816429912, -3.848605088629198],
              [114.880883701601164, -3.848640102301277],
              [114.880743251198368, -3.848796849574024],
              [114.880523530899438, -3.848913538392197],
              [114.880310726657768, -3.849065415491173],
              [114.880333331518926, -3.849109471595429],
              [114.880065351629739, -3.849501845894002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.846,
        No_Kav: "54",
        Nm_Pemilik: "Siti Khushadiah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879838898897546, -3.847711466134686],
              [114.87986980202632, -3.847699273087038],
              [114.880349664771003, -3.847782094106002],
              [114.880458856289493, -3.84770700338992],
              [114.880260739509026, -3.847545179355273],
              [114.880703342373039, -3.847394784795132],
              [114.880937818433779, -3.847304251428218],
              [114.881308191522862, -3.847159581284319],
              [114.881404888672094, -3.847015581603951],
              [114.881824085788324, -3.847258043088567],
              [114.881880326580273, -3.847486412415544],
              [114.881749420130376, -3.847571747871646],
              [114.881709084118484, -3.847821197021593],
              [114.881403719105506, -3.847836482970968],
              [114.881226602136138, -3.84829811787142],
              [114.881187865663335, -3.84852804607919],
              [114.880898124238954, -3.848748500993275],
              [114.880918530290387, -3.848807532615142],
              [114.880653722711756, -3.849036896667965],
              [114.88076398190735, -3.849159915871698],
              [114.880714659714926, -3.849203832213546],
              [114.88066237506014, -3.849195492773382],
              [114.880466320827679, -3.849158884050883],
              [114.880360141385552, -3.849132350360093],
              [114.880335688424225, -3.849106020628079],
              [114.880333331518926, -3.849109471595429],
              [114.880310726657768, -3.849065415491173],
              [114.880523530899438, -3.848913538392197],
              [114.880743251198368, -3.848796849574024],
              [114.880883701601164, -3.848640102301277],
              [114.880946816429912, -3.848605088629198],
              [114.880968086607538, -3.848499628312447],
              [114.880921625412498, -3.848408067485652],
              [114.880786394605749, -3.848342077372703],
              [114.880544003426266, -3.848186719737858],
              [114.880385643618496, -3.848033916480505],
              [114.880168907418877, -3.8478880017928],
              [114.879912353783752, -3.847795916757955],
              [114.879838898897546, -3.847711466134686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.035,
        No_Kav: "18",
        Nm_Pemilik: "Sri Hadi",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877407117936087, -3.848653932355326],
              [114.877401596107958, -3.848300513857351],
              [114.877311097796749, -3.847818372254752],
              [114.877146040935074, -3.847306058577673],
              [114.877159922316167, -3.846867014747179],
              [114.877363585466256, -3.846803871408833],
              [114.877363740992124, -3.846741049322307],
              [114.877533659666142, -3.846678651224707],
              [114.877548714475196, -3.84640778471554],
              [114.877940452925898, -3.846972881744566],
              [114.877837367233994, -3.847130012105522],
              [114.877696455986026, -3.847688704909916],
              [114.877664583822522, -3.847930940598719],
              [114.87763230548704, -3.848134026218993],
              [114.877533664977491, -3.848356531857216],
              [114.877407117936087, -3.848653932355326],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.787,
        No_Kav: "19",
        Nm_Pemilik: null,
        NO_PETA: "67",
        NAMA_SERTI: "Waliyo/Karto Dikromo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877548714475196, -3.84640778471554],
              [114.877552362516056, -3.846342148917704],
              [114.878126178272893, -3.845888461627601],
              [114.878443900289057, -3.845889254674022],
              [114.878607291601341, -3.846097979844625],
              [114.87846175624334, -3.846429044993716],
              [114.878351447799503, -3.846581972107181],
              [114.878044991202472, -3.846813537145663],
              [114.877940452925898, -3.846972881744566],
              [114.877548714475196, -3.84640778471554],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.484,
        No_Kav: "48",
        Nm_Pemilik: null,
        NO_PETA: "59",
        NAMA_SERTI: "Kasidi/Matal",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87850277595912, -3.853475530527966],
              [114.878404008661647, -3.853489906248769],
              [114.87830161270351, -3.853204659232628],
              [114.8780057489098, -3.852509947460797],
              [114.878003629444521, -3.852308769928126],
              [114.878120405114785, -3.851671359036621],
              [114.878149282128291, -3.851639874795492],
              [114.878513409522071, -3.851554004793797],
              [114.878887909926604, -3.851195177409061],
              [114.878635209041349, -3.853455613314693],
              [114.878510139552887, -3.853474411487871],
              [114.87850277595912, -3.853475530527966],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.068,
        No_Kav: "46a",
        Nm_Pemilik: null,
        NO_PETA: "51",
        NAMA_SERTI: "Suranto/Ahmad Ramlan",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879434971728472, -3.852452509604946],
              [114.87967821974911, -3.852825857153288],
              [114.880022736826987, -3.853173627732873],
              [114.879885632714419, -3.85338103898118],
              [114.879727481991779, -3.853419842584063],
              [114.879563817313453, -3.853321436464689],
              [114.878639919813864, -3.853455366447487],
              [114.878709146170593, -3.852804379770312],
              [114.879434971728472, -3.852452509604946],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.322,
        No_Kav: "40b",
        Nm_Pemilik: "Irwan Rifani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875832797512473, -3.850671534003588],
              [114.876100540124668, -3.850835526388212],
              [114.876925827764168, -3.850788898792339],
              [114.876942807323246, -3.85086052539221],
              [114.876361612659167, -3.851059242108394],
              [114.876138525689868, -3.851077242836192],
              [114.876010583731002, -3.851046433204925],
              [114.875459723315146, -3.850731645096541],
              [114.875468126882808, -3.850716692447263],
              [114.875678234589131, -3.850770445565195],
              [114.875832797512473, -3.850671534003588],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.626,
        No_Kav: "27",
        Nm_Pemilik: null,
        NO_PETA: "56",
        NAMA_SERTI: "Mirin/Wakiran",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877011843107809, -3.849361911424099],
              [114.876689794265758, -3.84975515914268],
              [114.876281235508117, -3.850261205412658],
              [114.876112806404194, -3.850515758588647],
              [114.875831324865317, -3.850670632006728],
              [114.875832797512473, -3.850671534003588],
              [114.875678234589131, -3.850770445565195],
              [114.875468126882808, -3.850716692447263],
              [114.875538952359591, -3.850590671110089],
              [114.875942834103228, -3.850235869857655],
              [114.876299434641979, -3.849862223037204],
              [114.876391545885738, -3.849731364802049],
              [114.876672124779731, -3.849361849103204],
              [114.876760539241232, -3.849260365055404],
              [114.877011843107809, -3.849361911424099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.262,
        No_Kav: "27",
        Nm_Pemilik: null,
        NO_PETA: "56",
        NAMA_SERTI: "Mirin/Wakiran",
        KETERANGAN: "Masuk SK-435",
        block: null,
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877343898568128, -3.848724030853869],
              [114.877342166586942, -3.84878030122885],
              [114.877205977263642, -3.849124857563578],
              [114.877024079675635, -3.849346969570156],
              [114.876772218966067, -3.84924695880896],
              [114.8768636806589, -3.849141977026779],
              [114.877049313788802, -3.848704298730219],
              [114.877038385323743, -3.848556136000215],
              [114.877343898568128, -3.848724030853869],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.066,
        No_Kav: "12",
        Nm_Pemilik: null,
        NO_PETA: "69",
        NAMA_SERTI: "Sukmadi/Suriansyah",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 2",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874799368155891, -3.849587989293118],
              [114.87479574926877, -3.849735063103529],
              [114.87471177244727, -3.849795222440123],
              [114.874574467438435, -3.84997687024673],
              [114.874516565149349, -3.850007702581121],
              [114.874306590989718, -3.849956838906212],
              [114.874199111393906, -3.849770706265823],
              [114.874049159288148, -3.849611572413812],
              [114.873860042016929, -3.849700158560673],
              [114.873684297186259, -3.849837180084294],
              [114.873468454186465, -3.849821150960309],
              [114.87327816870409, -3.849603833707857],
              [114.873138104333663, -3.849344048531487],
              [114.873028687954715, -3.849161783228309],
              [114.872994615434465, -3.848968335586235],
              [114.873038380273002, -3.848979935454766],
              [114.873271042150179, -3.84895882273718],
              [114.873513596352765, -3.848968608831875],
              [114.873937261451488, -3.849310119235386],
              [114.874469219714726, -3.849532576315106],
              [114.874799368155891, -3.849587989293118],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.309,
        No_Kav: "13",
        Nm_Pemilik: null,
        NO_PETA: "74",
        NAMA_SERTI: "Ansyar/Said",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874816408528019, -3.849381049509502],
              [114.874766435889512, -3.84951925842155],
              [114.874732888924441, -3.84955366785798],
              [114.874478082496523, -3.849497984981499],
              [114.873950105290206, -3.849296352905526],
              [114.873521986611607, -3.848935252415469],
              [114.873466404427603, -3.848932580072022],
              [114.873459039568431, -3.848169584356433],
              [114.873612145837882, -3.847697200188062],
              [114.873628880818288, -3.847706284009567],
              [114.8738947031122, -3.847850573475015],
              [114.874059277284061, -3.848022533760561],
              [114.874145513451381, -3.848266988048911],
              [114.874078350404332, -3.848502335655229],
              [114.874164766867878, -3.848674100391648],
              [114.87420465842473, -3.84893006913378],
              [114.874426997669829, -3.84915451090187],
              [114.874600350082787, -3.849288694420783],
              [114.874759344261349, -3.849376320367392],
              [114.874816408528019, -3.849381049509502],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.782,
        No_Kav: "16",
        Nm_Pemilik: null,
        NO_PETA: "53",
        NAMA_SERTI: "Jumingin/Juwon",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873997722937816, -3.845924204439752],
              [114.874394556689381, -3.846126182195765],
              [114.87439064815716, -3.846946129724211],
              [114.874180977663471, -3.847309905421621],
              [114.874124662199037, -3.847327202306121],
              [114.874056780627541, -3.847430860363973],
              [114.873780266496127, -3.847519441039996],
              [114.87370862413303, -3.84758136440905],
              [114.873628880818288, -3.847706284009567],
              [114.873612145837882, -3.847697200188062],
              [114.873665000619084, -3.847534125226712],
              [114.873762727537851, -3.847317592853661],
              [114.873853539530216, -3.847007728559583],
              [114.873934300355955, -3.846716501989779],
              [114.874015388530182, -3.846293200162302],
              [114.873997722937816, -3.845924204439752],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.57,
        No_Kav: "5",
        Nm_Pemilik: null,
        NO_PETA: "60",
        NAMA_SERTI: "Ahmad Kusasi/Yapuk",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873438617809938, -3.848944318544129],
              [114.873264420975076, -3.848935294893284],
              [114.873046762263556, -3.848949916773913],
              [114.87292217747644, -3.848917566077716],
              [114.872880506110647, -3.848693367544088],
              [114.872833780097253, -3.848520761643882],
              [114.872608751610755, -3.847891534580534],
              [114.872525830650289, -3.847679788838714],
              [114.872350800502673, -3.847356056503138],
              [114.872881096115677, -3.847135196271461],
              [114.872894472378135, -3.847126152595561],
              [114.87357150113499, -3.847762350896425],
              [114.873443400095312, -3.848166257112837],
              [114.873438617809938, -3.848944318544129],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.893,
        No_Kav: "16",
        Nm_Pemilik: null,
        NO_PETA: "53",
        NAMA_SERTI: "Jumingin/Juwon",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872894472378135, -3.847126152595561],
              [114.873405723284293, -3.846780496049414],
              [114.873472503455673, -3.846650336847279],
              [114.873753584831064, -3.846242578325364],
              [114.874000800475386, -3.846294808725417],
              [114.873913678924467, -3.846709480139148],
              [114.873743048839046, -3.847300857397156],
              [114.873633206700887, -3.84756779122021],
              [114.87357150113499, -3.847762350896425],
              [114.872894472378135, -3.847126152595561],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.009,
        No_Kav: "14",
        Nm_Pemilik: "Jadi/",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874180977663471, -3.847309905421621],
              [114.874179876240035, -3.847311816376116],
              [114.874642635872561, -3.847215807864799],
              [114.87440302095979, -3.84753707121105],
              [114.874260337618637, -3.847779629474157],
              [114.874293289410915, -3.847901169532256],
              [114.874344537342679, -3.847958990123204],
              [114.874413908225662, -3.848022928939236],
              [114.874486339770186, -3.84807169320934],
              [114.874658500259088, -3.848132852744718],
              [114.874439334823549, -3.847887079148303],
              [114.874470048219564, -3.847707934998164],
              [114.874625055428055, -3.84751630025713],
              [114.874926187808896, -3.847502651765252],
              [114.875240842976439, -3.84714249660709],
              [114.87511145649917, -3.84804899810743],
              [114.874930854323182, -3.847941832581316],
              [114.87492930747753, -3.847946618004308],
              [114.874810787505709, -3.848500493667954],
              [114.875019138894032, -3.848921665437969],
              [114.875023445583565, -3.848930371190597],
              [114.874904621374512, -3.849137079760005],
              [114.874816408528019, -3.849381049509502],
              [114.874759344261349, -3.849376320367392],
              [114.874600350082787, -3.849288694420783],
              [114.874426997669829, -3.84915451090187],
              [114.87420465842473, -3.84893006913378],
              [114.874164766867878, -3.848674100391648],
              [114.874078350404332, -3.848502335655229],
              [114.874145513451381, -3.848266988048911],
              [114.874059277284061, -3.848022533760561],
              [114.8738947031122, -3.847850573475015],
              [114.873628880818288, -3.847706284009567],
              [114.87370862413303, -3.84758136440905],
              [114.873780266496127, -3.847519441039996],
              [114.874056780627541, -3.847430860363973],
              [114.874124662199037, -3.847327202306121],
              [114.874180977663471, -3.847309905421621],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.017,
        No_Kav: "63",
        Nm_Pemilik: "Syahrani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881371200108831, -3.85188728298839],
              [114.881279440593133, -3.85200563292848],
              [114.881115623357985, -3.853062511164171],
              [114.880895039021667, -3.853200555321207],
              [114.880523950366538, -3.853037045756857],
              [114.880146241501521, -3.853102526134088],
              [114.880061138049811, -3.853146040882445],
              [114.879797354394796, -3.852887597324452],
              [114.87999546432728, -3.852702798931614],
              [114.880261592688697, -3.852538613749905],
              [114.88036967982292, -3.85228572116506],
              [114.880427127756462, -3.851967939538016],
              [114.880231321463555, -3.851760498123273],
              [114.879980195759344, -3.851768610048047],
              [114.880246341482604, -3.851400031883694],
              [114.880319655138209, -3.85129850356921],
              [114.881371200108831, -3.85188728298839],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.101,
        No_Kav: "62",
        Nm_Pemilik: "Rinawati",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880503907993841, -3.851051392387786],
              [114.881488127481603, -3.850886928294437],
              [114.881659332738082, -3.850890372593271],
              [114.881730312255897, -3.85142705758183],
              [114.881664854254439, -3.851741731720381],
              [114.881474100565256, -3.851754582063628],
              [114.881371208954732, -3.851887280297581],
              [114.880319655235482, -3.851298501505398],
              [114.880497291995255, -3.851052496625285],
              [114.880503907993841, -3.851051392387786],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.074,
        No_Kav: "11",
        Nm_Pemilik: "Supiyem",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87479574926877, -3.849735063103529],
              [114.874793722529887, -3.84981743075551],
              [114.874607710870876, -3.850107608917873],
              [114.874487094699532, -3.85022701581906],
              [114.874140749845978, -3.849873791293273],
              [114.873943418007343, -3.850056666919107],
              [114.873899651682294, -3.850407741601059],
              [114.872440829242009, -3.849348521254987],
              [114.872505150549785, -3.849274439574765],
              [114.872723733815675, -3.848896538375643],
              [114.872994615434465, -3.848968335586235],
              [114.873028687954715, -3.849161783228309],
              [114.873138104333663, -3.849344048531487],
              [114.87327816870409, -3.849603833707857],
              [114.873468454186465, -3.849821150960309],
              [114.873684297186259, -3.849837180084294],
              [114.873860042016929, -3.849700158560673],
              [114.874049159288148, -3.849611572413812],
              [114.874199111393906, -3.849770706265823],
              [114.874306590989718, -3.849956838906212],
              [114.874516565149349, -3.850007702581121],
              [114.874574467438435, -3.84997687024673],
              [114.87471177244727, -3.849795222440123],
              [114.87479574926877, -3.849735063103529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.568,
        No_Kav: "8",
        Nm_Pemilik: "Pathan",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873258471228468, -3.85162165276402],
              [114.873187458589101, -3.851883768897217],
              [114.873068861026397, -3.852127474137599],
              [114.872843234915948, -3.852428077325847],
              [114.872686067284747, -3.852551775982842],
              [114.872256301821423, -3.852791493902853],
              [114.871925506617259, -3.853200431969161],
              [114.871878822559097, -3.852846737483964],
              [114.87181003971736, -3.852595591233288],
              [114.871772716056327, -3.85253275419227],
              [114.871735617691954, -3.852379288317202],
              [114.871731928120511, -3.852188260173774],
              [114.87221318924361, -3.85180045756282],
              [114.872222450901162, -3.851619056213127],
              [114.873258471228468, -3.85162165276402],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.595,
        No_Kav: "9",
        Nm_Pemilik: "Lahmuddin",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872048969968247, -3.850292808855989],
              [114.872226675011007, -3.850283494114448],
              [114.872702381686054, -3.850454790635899],
              [114.872968411403633, -3.850653447696831],
              [114.873397323532359, -3.851222002279785],
              [114.873323354256584, -3.851382161457736],
              [114.873258471228468, -3.85162165276402],
              [114.872222450901162, -3.851619056213127],
              [114.872230699120649, -3.851457503921674],
              [114.87211124451332, -3.850928765207219],
              [114.871988473251449, -3.850617528844188],
              [114.871979036911227, -3.850504566993902],
              [114.872048969968247, -3.850292808855989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.977,
        No_Kav: "25",
        Nm_Pemilik: "Sayuti",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874824675915548, -3.852543785274797],
              [114.874635981282736, -3.852258213957584],
              [114.874460554636812, -3.852003373159495],
              [114.874168148837512, -3.851590014142267],
              [114.873924362001091, -3.851499432317641],
              [114.873803801151979, -3.851539462246093],
              [114.873664804178148, -3.851540665279235],
              [114.873383099421972, -3.851360002343378],
              [114.873571972776162, -3.85098664454331],
              [114.87389692521073, -3.850731505875137],
              [114.874127082426256, -3.851335509906407],
              [114.874366159165831, -3.851457104427344],
              [114.874390595682428, -3.851567302824118],
              [114.874717532191909, -3.851757371385656],
              [114.874933773665518, -3.851644755893192],
              [114.874931330168053, -3.852629645735867],
              [114.874824675915548, -3.852543785274797],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.537,
        No_Kav: "26",
        Nm_Pemilik: "H.Aliansyah/Ilyasa Khalik",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876209973691786, -3.852772509151452],
              [114.87582111897828, -3.852639681272189],
              [114.875141422141766, -3.852707784816418],
              [114.874965483162711, -3.852659256130617],
              [114.874931330795732, -3.852629646287439],
              [114.874933775257318, -3.851644755005721],
              [114.875503091890693, -3.851348262321075],
              [114.875636747829773, -3.851632471818674],
              [114.875900211775701, -3.851884430404547],
              [114.876041805214825, -3.852081790950466],
              [114.876152438158812, -3.852308547393317],
              [114.876230358400633, -3.852648461772454],
              [114.876209973691786, -3.852772509151452],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.004,
        No_Kav: "40",
        Nm_Pemilik: "Irwan Rifani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875440943740529, -3.850781831088923],
              [114.87599312930729, -3.851099230991167],
              [114.876136072192068, -3.851126402305563],
              [114.876370664599648, -3.851104006063715],
              [114.876895503976925, -3.850915133255514],
              [114.87717754217293, -3.850983255833242],
              [114.877167960415562, -3.851156371677955],
              [114.877376603184388, -3.851294792394499],
              [114.877456986013399, -3.851744583205008],
              [114.878052467715634, -3.851750572652234],
              [114.878025620724813, -3.851898660154636],
              [114.877600467544923, -3.851897597992252],
              [114.875956922265587, -3.85189348564916],
              [114.875926431435119, -3.851851761983559],
              [114.875663921180745, -3.851598290456435],
              [114.875502789368284, -3.851216750167821],
              [114.875444134952389, -3.851032738293104],
              [114.875440943740529, -3.850781831088923],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.505,
        No_Kav: "42",
        Nm_Pemilik: "Warniyati",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878025620143035, -3.851898661256026],
              [114.87794994381025, -3.852316091893253],
              [114.877953306080371, -3.852517830601804],
              [114.878270932642181, -3.853249234206428],
              [114.878383791874427, -3.853587644321563],
              [114.878454237741295, -3.853948340968725],
              [114.87855271042325, -3.85469426006512],
              [114.878531929912995, -3.854983072322434],
              [114.878264106281094, -3.855103322181175],
              [114.878251468654483, -3.854805469347597],
              [114.878051686647566, -3.854457884672432],
              [114.878039832484959, -3.854032384618743],
              [114.878034985343419, -3.85347164942261],
              [114.877668370670492, -3.85291000941335],
              [114.877598171665952, -3.85282373369955],
              [114.877600467544923, -3.851897597992252],
              [114.878025620143035, -3.851898661256026],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.018,
        No_Kav: "41",
        Nm_Pemilik: "Siti Hadijah/",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877598171665952, -3.85282373369955],
              [114.877596858411934, -3.852822119688931],
              [114.877554930907309, -3.853006483705708],
              [114.877584550579144, -3.853056641950049],
              [114.876168391519741, -3.853053098263145],
              [114.876217393297779, -3.852934613203638],
              [114.87627286934719, -3.852653285947825],
              [114.876184653175102, -3.852296748989078],
              [114.876070092582822, -3.852055684973525],
              [114.875956922265587, -3.85189348564916],
              [114.877600467544923, -3.851897597992252],
              [114.877598171665952, -3.85282373369955],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.006,
        No_Kav: "43",
        Nm_Pemilik: "Mulyadi A.TD,MT/",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877584552583386, -3.853056645311573],
              [114.877829377294432, -3.853380291734693],
              [114.877874922624514, -3.853878478058935],
              [114.877848001792557, -3.853969575870931],
              [114.875977133794635, -3.853964892715523],
              [114.875952454189985, -3.853659834214107],
              [114.875983452504258, -3.853462639864871],
              [114.876016129451472, -3.853357382275607],
              [114.876073599491278, -3.853250271485851],
              [114.876168391868603, -3.853053101543334],
              [114.877584552583386, -3.853056645311573],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.012,
        No_Kav: "44",
        Nm_Pemilik: "Muhroji/",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877618751947551, -3.854053255652747],
              [114.877593409504328, -3.854212954490004],
              [114.877505669542316, -3.854701392957666],
              [114.877374739229765, -3.854769362685418],
              [114.877421454738311, -3.854913954899367],
              [114.877215196490553, -3.85514325537371],
              [114.876289002311538, -3.855140945794551],
              [114.876208750756291, -3.854880497799365],
              [114.876097033978951, -3.854564199813032],
              [114.875982059994769, -3.854025731605546],
              [114.875977134680156, -3.853964894302467],
              [114.877848001792557, -3.853969575870931],
              [114.877827746084705, -3.854038118991707],
              [114.877618751947551, -3.854053255652747],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.659,
        No_Kav: "36",
        Nm_Pemilik: "Sufian Noor",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874168276753551, -3.855682424604369],
              [114.874112303676981, -3.855904960565694],
              [114.873627047321847, -3.856449022727341],
              [114.873616853082908, -3.856363633984403],
              [114.873362444670676, -3.856141748442171],
              [114.873304784915547, -3.856133008534518],
              [114.873070256820938, -3.855960369876632],
              [114.872985630239071, -3.855818544675613],
              [114.872937416735354, -3.855737736020759],
              [114.872868131658834, -3.85567915769141],
              [114.874168276753551, -3.855682424604369],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.715,
        No_Kav: "22",
        Nm_Pemilik: "Sarno",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875577615530958, -3.849077315427604],
              [114.875595879229195, -3.849063977537345],
              [114.875615228243589, -3.84904984922271],
              [114.875617619247478, -3.849048103353077],
              [114.875691580973921, -3.848830396804687],
              [114.875808637279192, -3.848730124219582],
              [114.876055758565172, -3.848667050793531],
              [114.876139424772376, -3.848573398994506],
              [114.87626692296574, -3.848554023010687],
              [114.876269631016939, -3.848553611466139],
              [114.876403191941463, -3.84878895626151],
              [114.876412513806031, -3.848805382113412],
              [114.876425622284117, -3.848902628080896],
              [114.876482141176851, -3.848989926046436],
              [114.876511712091002, -3.84917772201883],
              [114.876493272645675, -3.849185982361756],
              [114.876347999335124, -3.849251060616843],
              [114.87627449462687, -3.849284398603602],
              [114.876184311491897, -3.849314342694187],
              [114.876023836339755, -3.849427915434588],
              [114.875926529737058, -3.849638859280668],
              [114.875922762519195, -3.849668934314647],
              [114.875917741108694, -3.849709022001639],
              [114.87591160905626, -3.849757976317525],
              [114.875577615530958, -3.849077315427604],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.706,
        No_Kav: "24",
        Nm_Pemilik: "Jumaidah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875187473371909, -3.850388921851252],
              [114.87519107463973, -3.849968689971348],
              [114.875351370723465, -3.849819793648971],
              [114.875120323664277, -3.849668028292844],
              [114.874976087549669, -3.849763335045181],
              [114.874866756150524, -3.849658902032058],
              [114.874942020942754, -3.849633044044196],
              [114.875115712541657, -3.849573139498907],
              [114.875279491851529, -3.849472983985554],
              [114.875279791107218, -3.849352306376291],
              [114.875259949914778, -3.849278508852455],
              [114.875263337133575, -3.849258404267048],
              [114.875443761525318, -3.849175051204351],
              [114.875577613394981, -3.849077314931503],
              [114.875911608816935, -3.849757978228255],
              [114.87589588690939, -3.849883491623335],
              [114.875778514516526, -3.850111146096449],
              [114.875715088267171, -3.850117691774575],
              [114.875598414076293, -3.8500637650355],
              [114.87542151800767, -3.850070026794816],
              [114.875331226712987, -3.850143548758001],
              [114.875187473371909, -3.850388921851252],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.514,
        No_Kav: "5",
        Nm_Pemilik: null,
        NO_PETA: "91",
        NAMA_SERTI: "Suyono/Sarjo",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87212990521958, -3.84816252885682],
              [114.872255740537156, -3.848077887325356],
              [114.871934235298554, -3.847638829118355],
              [114.872362521902843, -3.847413371533882],
              [114.872510047487623, -3.847684551678278],
              [114.872600705354728, -3.847912426460737],
              [114.872637289649631, -3.848016929460881],
              [114.872798255148851, -3.848476740101589],
              [114.872857977536569, -3.848696148275871],
              [114.872892529955053, -3.848907892569182],
              [114.872416784933577, -3.848693404069776],
              [114.87203391151931, -3.848622333684226],
              [114.871183777465546, -3.848420476291106],
              [114.871200625133682, -3.847849555916577],
              [114.871668870344834, -3.847925725251778],
              [114.87212990521958, -3.84816252885682],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.506,
        No_Kav: "4",
        Nm_Pemilik: null,
        NO_PETA: "63",
        NAMA_SERTI: "Parnyono/Sanduriat",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871934235298554, -3.847638829118355],
              [114.87192096544841, -3.84762070736454],
              [114.871878147520533, -3.847346309795876],
              [114.871694907586374, -3.847175936506664],
              [114.871642513662692, -3.846865104778542],
              [114.870664689555838, -3.846500737955872],
              [114.870692545398043, -3.846151541757033],
              [114.871210923621916, -3.845674361568057],
              [114.87140476290989, -3.845842625211995],
              [114.871609448019754, -3.846127739398406],
              [114.871762542995612, -3.846261102568801],
              [114.871832794511889, -3.846371887865719],
              [114.871973615848987, -3.846764344580838],
              [114.87222996745686, -3.847170637182198],
              [114.872328504864882, -3.847350841721969],
              [114.872362521902843, -3.847413371533882],
              [114.871934235298554, -3.847638829118355],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.709,
        No_Kav: "3",
        Nm_Pemilik: null,
        NO_PETA: "91",
        NAMA_SERTI: "Suyono/Sarjo",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87045968745521, -3.848192288581349],
              [114.870485872324338, -3.847776989487496],
              [114.87054873348022, -3.847457064462637],
              [114.870744785607059, -3.847333761121472],
              [114.870923137795231, -3.847525968400426],
              [114.871125873101064, -3.847630852296492],
              [114.871200239256098, -3.84784950491964],
              [114.871200624066759, -3.847849555377412],
              [114.87118377414869, -3.848420476282793],
              [114.870888112795043, -3.848350367463772],
              [114.870570304597621, -3.848210628385797],
              [114.87045968745521, -3.848192288581349],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.201,
        No_Kav: "13",
        Nm_Pemilik: null,
        NO_PETA: "74",
        NAMA_SERTI: "Ansyar/Said",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875450356394595, -3.848817631669397],
              [114.874766435889512, -3.84951925842155],
              [114.874816408528019, -3.849381049509502],
              [114.874904621374512, -3.849137079760005],
              [114.875023445583565, -3.848930371190597],
              [114.874932825367665, -3.848747131973757],
              [114.875450356394595, -3.848817631669397],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.83,
        No_Kav: "13",
        Nm_Pemilik: null,
        NO_PETA: "74",
        NAMA_SERTI: "Ansyar/Said",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875604337957981, -3.848659806697192],
              [114.875450507144663, -3.848817556430539],
              [114.874932814570698, -3.848747104664421],
              [114.874810787505709, -3.848500493667954],
              [114.87492930747753, -3.847946618004308],
              [114.874930844106956, -3.847942075275819],
              [114.875111574533193, -3.84804920455579],
              [114.875265841592366, -3.848141288389367],
              [114.875487244723956, -3.848122674883964],
              [114.875501515557616, -3.848110335661751],
              [114.875827637600906, -3.847828362005888],
              [114.875860578158395, -3.848499160208768],
              [114.875738264692629, -3.848566482604701],
              [114.875604337957981, -3.848659806697192],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.53,
        No_Kav: "17",
        Nm_Pemilik: null,
        NO_PETA: "73",
        NAMA_SERTI: "Nurhalim/Ahmad",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876253655107249, -3.847419268546796],
              [114.876256575384488, -3.847422058323373],
              [114.876319937288983, -3.847521812218982],
              [114.876440913597136, -3.84777580132607],
              [114.876513666993873, -3.847860545417427],
              [114.876637128697723, -3.847866491472255],
              [114.876682289617449, -3.847761371302851],
              [114.876676007116117, -3.847276531966841],
              [114.87685036776368, -3.847126634718393],
              [114.87710247546417, -3.84731142244561],
              [114.877273336634062, -3.847819223066144],
              [114.877356805786306, -3.848304685634735],
              [114.877343898568128, -3.848724030853869],
              [114.877038385323743, -3.848556136000215],
              [114.877017032987382, -3.848266651977758],
              [114.876868824192158, -3.848049134689421],
              [114.876668262868122, -3.848066466494847],
              [114.8762583695123, -3.848268948469326],
              [114.875902331215244, -3.848475634598461],
              [114.87586333833795, -3.848497324300713],
              [114.875860643774772, -3.848499127459791],
              [114.875827642670174, -3.847828350873595],
              [114.875847456493531, -3.847533225204901],
              [114.875973913060065, -3.847326535326141],
              [114.876253655107249, -3.847419268546796],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.219,
        No_Kav: "15",
        Nm_Pemilik: "Abdul Malik Sarpani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875336951364645, -3.846945595139275],
              [114.875387644337465, -3.846965429299259],
              [114.8754746584614, -3.846610922143445],
              [114.875619117017834, -3.846537742757095],
              [114.875953924206229, -3.846990637410413],
              [114.876160311134953, -3.847127419503528],
              [114.876179145009587, -3.847348088133302],
              [114.876253655107249, -3.847419268546796],
              [114.875973913060065, -3.847326535326141],
              [114.875847456493531, -3.847533225204901],
              [114.875827642670174, -3.847828350873595],
              [114.875501515557616, -3.848110335661751],
              [114.875487244723956, -3.848122674883964],
              [114.875265841592366, -3.848141288389367],
              [114.875111574533193, -3.84804920455579],
              [114.875240932111993, -3.847142467010747],
              [114.875060560856454, -3.847045535256235],
              [114.875225669818988, -3.846903188214704],
              [114.875336951364645, -3.846945595139275],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.53,
        No_Kav: "20",
        Nm_Pemilik: null,
        NO_PETA: "69",
        NAMA_SERTI: "Sukmadi/Suriansyah",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874853792284142, -3.849500311639324],
              [114.87562884268533, -3.848710641498716],
              [114.876341138803156, -3.848290592591642],
              [114.876685188368498, -3.848112494358777],
              [114.876850684686133, -3.848090183118174],
              [114.876882862208703, -3.84813527198445],
              [114.876269590261543, -3.848553573111553],
              [114.876139424772376, -3.848573398994506],
              [114.876055758565172, -3.848667050793531],
              [114.875808637279192, -3.848730124219582],
              [114.875691580973921, -3.848830396804687],
              [114.875617619247478, -3.849048103353077],
              [114.875615228243589, -3.84904984922271],
              [114.875595879229195, -3.849063977537345],
              [114.875577613394981, -3.849077314931503],
              [114.875443761525318, -3.849175051204351],
              [114.875263337133575, -3.849258404267048],
              [114.875259949914778, -3.849278508852455],
              [114.875279791107218, -3.849352306376291],
              [114.875279491851529, -3.849472983985554],
              [114.875115712541657, -3.849573139498907],
              [114.874942020942754, -3.849633044044196],
              [114.874866756150524, -3.849658902032058],
              [114.87486007498579, -3.849652520203426],
              [114.874853792284142, -3.849500311639324],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.73,
        No_Kav: "23",
        Nm_Pemilik: null,
        NO_PETA: "56",
        NAMA_SERTI: "Mirin/Wakiran",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876504531094241, -3.849546124493347],
              [114.87646914250692, -3.84959238681463],
              [114.876283576336959, -3.849835738507059],
              [114.876110594110003, -3.850025626466834],
              [114.875808664796352, -3.850324555070318],
              [114.875611707419765, -3.850488817384064],
              [114.875477370986545, -3.850602503023718],
              [114.875381120086274, -3.850721322128484],
              [114.875185778818121, -3.850586654572461],
              [114.875187473371909, -3.850388921851252],
              [114.875331226712987, -3.850143548758001],
              [114.87542151800767, -3.850070026794816],
              [114.875598414076293, -3.8500637650355],
              [114.875715088267171, -3.850117691774575],
              [114.875778514516526, -3.850111146096449],
              [114.87589588690939, -3.849883491623335],
              [114.875911608816935, -3.849757978228255],
              [114.875917741108694, -3.849709022001639],
              [114.875922762519195, -3.849668934314647],
              [114.875926529737058, -3.849638859280668],
              [114.876023836339755, -3.849427915434588],
              [114.876184311491897, -3.849314342694187],
              [114.87627449462687, -3.849284398603602],
              [114.876292134532477, -3.849276419611518],
              [114.876293556012158, -3.84927575608594],
              [114.876504531094241, -3.849546124493347],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.724,
        No_Kav: "21",
        Nm_Pemilik: null,
        NO_PETA: "56",
        NAMA_SERTI: "Mirin/Wakiran",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876988315268306, -3.848281651704449],
              [114.877020127623751, -3.848689491879369],
              [114.876841028345595, -3.849134082178829],
              [114.876504290696388, -3.849545638543416],
              [114.876293558433119, -3.849275754877638],
              [114.876511712091002, -3.84917772201883],
              [114.876482141176851, -3.848989926046436],
              [114.876425622284117, -3.848902628080896],
              [114.876412513806031, -3.848805382113412],
              [114.876269656881576, -3.848553606777885],
              [114.876882863675462, -3.848135277417466],
              [114.876988315268306, -3.848281651704449],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.514,
        No_Kav: "53a",
        Nm_Pemilik: null,
        NO_PETA: "54",
        NAMA_SERTI: "Tukimin/Wiro Sumarto",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880154797858282, -3.846631028057184],
              [114.880446085579635, -3.845524793969835],
              [114.880766588809678, -3.845288538327327],
              [114.880935507476693, -3.845554109457213],
              [114.881011299539026, -3.846010848203076],
              [114.881370458175439, -3.846403322834185],
              [114.881479522271661, -3.846837317149875],
              [114.881379871483617, -3.847002895460273],
              [114.880154797858282, -3.846631028057184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.104,
        No_Kav: "51b",
        Nm_Pemilik: null,
        NO_PETA: "64",
        NAMA_SERTI: "Sarmi/Sumarto Wiyono",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880086797696762, -3.847569795339528],
              [114.880028907698289, -3.847588429045486],
              [114.879727793823747, -3.847710288499644],
              [114.879613311245009, -3.847566920103042],
              [114.880099124532038, -3.847352716021662],
              [114.880086797696762, -3.847569795339528],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.497,
        No_Kav: "53b",
        Nm_Pemilik: null,
        NO_PETA: "54",
        NAMA_SERTI: "Tukimin/Wiro Sumarto",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880086823881456, -3.847569775703376],
              [114.880099133981972, -3.847352730694248],
              [114.880859910810656, -3.846845072393015],
              [114.881379856988261, -3.847002902179253],
              [114.881326332585672, -3.847090573574605],
              [114.881280354847917, -3.847146457415855],
              [114.880668992085745, -3.84738137078637],
              [114.880086823881456, -3.847569775703376],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.584,
        No_Kav: "58a",
        Nm_Pemilik: null,
        NO_PETA: "65",
        NAMA_SERTI: "Kardi/Atmo Karyono",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882086386454418, -3.848762260359635],
              [114.88183600518353, -3.84930562243231],
              [114.881639018628306, -3.849558984216899],
              [114.881468616102254, -3.849700112897643],
              [114.881273972792343, -3.849765685702394],
              [114.880543325479707, -3.849644013633556],
              [114.880065351629739, -3.849501845894002],
              [114.880333331518926, -3.849109471595429],
              [114.880335688424225, -3.849106020628079],
              [114.880360141385552, -3.849132350360093],
              [114.880466320827679, -3.849158884050883],
              [114.88066237506014, -3.849195492773382],
              [114.880714659714926, -3.849203832213546],
              [114.88076398190735, -3.849159915871698],
              [114.880764983855371, -3.849161033772402],
              [114.881006267128214, -3.848946356181183],
              [114.881116974859509, -3.848819381463779],
              [114.881470432583896, -3.848631294321561],
              [114.881669865164326, -3.848789358655467],
              [114.88158469294946, -3.848492770302603],
              [114.882086386454418, -3.848762260359635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.692,
        No_Kav: "56",
        Nm_Pemilik: null,
        NO_PETA: "58",
        NAMA_SERTI: "Sugianto/Slamet",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882494868342974, -3.846471846513692],
              [114.882661257351756, -3.846881446891135],
              [114.88279191079269, -3.847237544509826],
              [114.882644379193238, -3.847628809287623],
              [114.882580245072205, -3.847728680859877],
              [114.882320922496973, -3.848114948379072],
              [114.882090130719803, -3.848758518100293],
              [114.881586569718394, -3.848489023388808],
              [114.881525580348153, -3.848379224762037],
              [114.881602540016033, -3.847835195691916],
              [114.881740484975325, -3.847827263068871],
              [114.881817896840886, -3.847607503508652],
              [114.882181472640269, -3.847195346871231],
              [114.882257231122892, -3.846953714539919],
              [114.882140816234056, -3.846886570150058],
              [114.882141518403998, -3.846602297804477],
              [114.882494868342974, -3.846471846513692],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.047,
        No_Kav: "55",
        Nm_Pemilik: null,
        NO_PETA: "58",
        NAMA_SERTI: "Sugianto/Slamet",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 8",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882239316469594, -3.844696711876727],
              [114.882237657080537, -3.844988308659916],
              [114.88216236671299, -3.845323061483598],
              [114.882161730569948, -3.845580692338661],
              [114.882232048087317, -3.845758545067985],
              [114.882366163057753, -3.846155012202531],
              [114.882494868342974, -3.846471846513692],
              [114.882141518403998, -3.846602297804477],
              [114.882141825622242, -3.846477914328291],
              [114.881957384902535, -3.845953306025085],
              [114.88147360535541, -3.845174081923153],
              [114.881522448688671, -3.844893455306311],
              [114.882239316469594, -3.844696711876727],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.699,
        No_Kav: "57c",
        Nm_Pemilik: null,
        NO_PETA: "55",
        NAMA_SERTI: "Damai/Narima",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882867283863931, -3.847480479614084],
              [114.882776260363457, -3.847695106827431],
              [114.882699498272302, -3.84815189902631],
              [114.882654234184898, -3.848749379980177],
              [114.882514229026214, -3.849316070540898],
              [114.882076048008486, -3.848914784890907],
              [114.882141669323474, -3.848768094115837],
              [114.882260271599151, -3.848428526158396],
              [114.882367773260938, -3.848151072204194],
              [114.882684787009566, -3.847661392301973],
              [114.882747755743793, -3.847512035028911],
              [114.882867283863931, -3.847480479614084],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.559,
        No_Kav: "59",
        Nm_Pemilik: null,
        NO_PETA: "57",
        NAMA_SERTI: "Maryoto/Supa Pawiro",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882733453576122, -3.849634901326589],
              [114.882564934347869, -3.849983870635361],
              [114.882300999019918, -3.850237277185361],
              [114.881691442742579, -3.850239921660806],
              [114.881371403278663, -3.850391930208951],
              [114.880780885377703, -3.849725024524031],
              [114.881271621724821, -3.849811962378396],
              [114.881483256305899, -3.849747932987914],
              [114.881676457877177, -3.849592227672628],
              [114.881859928479273, -3.849347158866145],
              [114.881925858464257, -3.849230471891355],
              [114.882073778626363, -3.848917053056215],
              [114.882513091524984, -3.849318341529495],
              [114.882484496647152, -3.849438782940855],
              [114.882733453576122, -3.849634901326589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.539,
        No_Kav: "50c",
        Nm_Pemilik: null,
        NO_PETA: "61",
        NAMA_SERTI: "Rukianto/Wongso Karso",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87966545108462, -3.849404705955801],
              [114.879390622299482, -3.850051485986413],
              [114.879280648251381, -3.850262864595231],
              [114.878704259239143, -3.849790075469584],
              [114.878980505300461, -3.849626811019788],
              [114.879276955269418, -3.849354443223795],
              [114.879456057397832, -3.849336239150123],
              [114.87966545108462, -3.849404705955801],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.615,
        No_Kav: "49",
        Nm_Pemilik: null,
        NO_PETA: "62",
        NAMA_SERTI: "Kismo Suwito/Mintorejo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880163075694369, -3.851003356594437],
              [114.880125573286875, -3.851080062260557],
              [114.879907693521062, -3.85117719259704],
              [114.879903477946939, -3.851175103494274],
              [114.879431323838645, -3.850941118970049],
              [114.879178783797201, -3.851010255538189],
              [114.879016600789328, -3.851127529160267],
              [114.879008754248957, -3.851120787754306],
              [114.878956558403615, -3.851098687980673],
              [114.878981953599606, -3.851068894440168],
              [114.87900099806123, -3.850888369686423],
              [114.879317141064831, -3.85028357030159],
              [114.880163075694369, -3.851003356594437],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.606,
        No_Kav: "47",
        Nm_Pemilik: null,
        NO_PETA: "62",
        NAMA_SERTI: "Kismo Suwito/Mintorejo",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8788919968366, -3.851191261534669],
              [114.879029231450417, -3.851718828532906],
              [114.879285552155366, -3.852223173902318],
              [114.879434971728472, -3.852452509604946],
              [114.878766826798298, -3.852780595642738],
              [114.8787091476879, -3.852803767452654],
              [114.878887909926604, -3.851195177409061],
              [114.8788919968366, -3.851191261534669],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.504,
        No_Kav: "65",
        Nm_Pemilik: null,
        NO_PETA: "71",
        NAMA_SERTI: "Muryono/Tahar",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 10",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880997576144125, -3.853177177205563],
              [114.881145512738442, -3.853076785480657],
              [114.881150450830347, -3.853043484604874],
              [114.881688662879469, -3.852827161458303],
              [114.882203401849694, -3.851850025383106],
              [114.882222813370404, -3.851861257508261],
              [114.882243407326527, -3.851872828228663],
              [114.882303046574521, -3.85190809703183],
              [114.88233907118277, -3.851977263691083],
              [114.882406729804018, -3.852427340449535],
              [114.882081137980649, -3.852724648659271],
              [114.882012921511162, -3.853231647204631],
              [114.881795654746853, -3.853634658417109],
              [114.881627971627296, -3.853873730406977],
              [114.88120596349593, -3.853920579993056],
              [114.880908377467037, -3.85371857519611],
              [114.880997576144125, -3.853177177205563],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.487,
        No_Kav: "1",
        Nm_Pemilik: null,
        NO_PETA: null,
        NAMA_SERTI: "Wagino Harjo",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.864387197355043, -3.841888951704207],
              [114.864540773223524, -3.841713542208591],
              [114.864757670518671, -3.84153904943301],
              [114.865287594297982, -3.841762925354386],
              [114.865323848252288, -3.841948683614909],
              [114.865307423872522, -3.84219528405876],
              [114.865262608813381, -3.842299321241297],
              [114.864387197355043, -3.841888951704207],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.134,
        No_Kav: "1",
        Nm_Pemilik: null,
        NO_PETA: null,
        NAMA_SERTI: "Wagino Harjo",
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 1",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.865047283529208, -3.843192977845852],
              [114.864191124814781, -3.842804233904469],
              [114.864097724627882, -3.842688275353442],
              [114.864160983099794, -3.842185298542006],
              [114.864342054199341, -3.84194051276091],
              [114.864387197355043, -3.841888951704207],
              [114.865262608813381, -3.842299321241297],
              [114.865209992815352, -3.842421468073194],
              [114.865082947504391, -3.842597914708494],
              [114.865047283529208, -3.843192977845852],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.009,
        No_Kav: "72",
        Nm_Pemilik: "Poppy Risma Dinauli S",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871642628635314, -3.858039437340567],
              [114.871491682044294, -3.857716278209831],
              [114.871766664843875, -3.857691550141569],
              [114.872348646608785, -3.857651882852926],
              [114.872684913706422, -3.857624586370215],
              [114.872612738689355, -3.857182813302191],
              [114.872723085418315, -3.857007752297978],
              [114.872961100324062, -3.856625203634207],
              [114.873333534821143, -3.856176446791351],
              [114.873576159853812, -3.856392560853457],
              [114.873597649959379, -3.856750353660955],
              [114.873690934881239, -3.857239505724157],
              [114.873559948981068, -3.857221167065243],
              [114.873406760400798, -3.857256802775157],
              [114.873322252781406, -3.857406212521608],
              [114.873282533922321, -3.857848051278627],
              [114.873336805765987, -3.85804369094997],
              [114.871642628635314, -3.858039437340567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.779,
        No_Kav: "70",
        Nm_Pemilik: "Suhadak",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873475928997706, -3.859592911863304],
              [114.873322465605028, -3.859586853097355],
              [114.873340596575915, -3.859420637421524],
              [114.873210893864822, -3.859358059575474],
              [114.873012591825258, -3.859574619134279],
              [114.872826819435957, -3.859567284699282],
              [114.872759085308758, -3.859460678731],
              [114.872266047022165, -3.859527621352148],
              [114.871970141940281, -3.859397617940616],
              [114.871869780235542, -3.859103830660541],
              [114.873558139832994, -3.859108070677829],
              [114.873580298259171, -3.859191203278185],
              [114.873561562342616, -3.859344969607364],
              [114.873475928997706, -3.859592911863304],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.019,
        No_Kav: "70",
        Nm_Pemilik: "Suhadak",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873396802982299, -3.859924421425937],
              [114.873494395764112, -3.859690218269948],
              [114.873602288525362, -3.859349691739755],
              [114.87362371883205, -3.859187506770087],
              [114.873575225672994, -3.858986443111863],
              [114.875156892385235, -3.85899041170106],
              [114.875174924689688, -3.85901086323698],
              [114.874509730122583, -3.859630029704367],
              [114.874207303896625, -3.859636770698795],
              [114.874168504047816, -3.859475424492675],
              [114.873838332574209, -3.859529532897159],
              [114.873544594387553, -3.859945792336646],
              [114.873396802982299, -3.859924421425937],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.995,
        No_Kav: "68",
        Nm_Pemilik: "Jailani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874518246947588, -3.858230249233864],
              [114.875225284164173, -3.858315108104867],
              [114.875414577960868, -3.858197816962904],
              [114.875514277346511, -3.858018357472748],
              [114.875742348341262, -3.85768312172369],
              [114.87587320477428, -3.85757850986446],
              [114.87602228289505, -3.857244162243535],
              [114.876093255452943, -3.856983625817847],
              [114.876402251058309, -3.856133962697855],
              [114.876410145021879, -3.855904456421312],
              [114.877141850936212, -3.856156967401736],
              [114.876906109696151, -3.856372209358343],
              [114.876661772889776, -3.856540763219152],
              [114.876446806874029, -3.856575224559335],
              [114.87620647912243, -3.857144080417541],
              [114.876439266880055, -3.857411092059294],
              [114.876758809713692, -3.85803984125629],
              [114.876551312192518, -3.857978980863016],
              [114.876363078252183, -3.858049343577099],
              [114.876192421362205, -3.857878388560608],
              [114.876143091502058, -3.857758895815013],
              [114.876071143262294, -3.857807250184925],
              [114.876120049459431, -3.858097269167678],
              [114.875869410801187, -3.858200269022901],
              [114.875940252582353, -3.858480431993972],
              [114.875824096985269, -3.858566861922271],
              [114.875836209372935, -3.858656091166817],
              [114.875384579801164, -3.858736724349397],
              [114.875333355714986, -3.858503687786358],
              [114.875165637303439, -3.858493356206484],
              [114.874638244683837, -3.858330247757764],
              [114.874488237290024, -3.85841024803585],
              [114.874938239699333, -3.858740250517639],
              [114.875156716296317, -3.858990273718163],
              [114.874894655547777, -3.858989751943209],
              [114.874388286475053, -3.858330284744237],
              [114.874388245488092, -3.858330249401298],
              [114.874518246947588, -3.858230249233864],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.001,
        No_Kav: "69",
        Nm_Pemilik: "Poppy Risma Dinauli S",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873575223487521, -3.858986437762585],
              [114.873544254554247, -3.858858029823152],
              [114.873478514750275, -3.858390348410237],
              [114.873332332457068, -3.857838346903573],
              [114.873348613921948, -3.857652357772428],
              [114.873374642838229, -3.857419189655616],
              [114.873421384726399, -3.85731909216027],
              [114.873549800562529, -3.857296253741049],
              [114.873771703813276, -3.857343901923995],
              [114.874047228622572, -3.857479917369365],
              [114.874191023136362, -3.857573152250326],
              [114.874330506019561, -3.857808174673965],
              [114.874458569440804, -3.858199235422938],
              [114.87434315751247, -3.858293322880741],
              [114.874388289419869, -3.858330289254636],
              [114.874894652877828, -3.858989751840949],
              [114.873575223487521, -3.858986437762585],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.031,
        No_Kav: "45",
        Nm_Pemilik: "Hamidah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877215195858128, -3.855143267396787],
              [114.876978257441792, -3.855406688191974],
              [114.877160677447222, -3.855667200382358],
              [114.87743063174139, -3.855431462101734],
              [114.877484195647909, -3.855977975486565],
              [114.877599089417359, -3.856049187355963],
              [114.877619470183291, -3.85626726475137],
              [114.8764279008127, -3.855872884353822],
              [114.87640524419173, -3.855518206669383],
              [114.87628900183843, -3.855140948376751],
              [114.877215195858128, -3.855143267396787],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.011,
        No_Kav: "71",
        Nm_Pemilik: "Stefan Theophilus",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 11",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871869778443681, -3.859103828900901],
              [114.871755914457182, -3.858770520511766],
              [114.872280500809694, -3.858672991965135],
              [114.872229000987545, -3.858359334918255],
              [114.871830540939513, -3.858441735635649],
              [114.871642628635314, -3.858039437340567],
              [114.873336805765987, -3.85804369094997],
              [114.873432906267567, -3.858390114979665],
              [114.873503408324638, -3.858880718952833],
              [114.873558138686974, -3.85910806862069],
              [114.871869778443681, -3.859103828900901],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.022,
        No_Kav: "35",
        Nm_Pemilik: "Heru Purwanto",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872273931603658, -3.854333791371669],
              [114.872766475386243, -3.854476543338912],
              [114.873130285227788, -3.854644697815796],
              [114.873549639654016, -3.85479557043285],
              [114.87401751017579, -3.854965727717312],
              [114.874176681956882, -3.855124658402145],
              [114.874203393582661, -3.855542830864268],
              [114.874168282121232, -3.855682417245923],
              [114.87286812912545, -3.855679156485707],
              [114.872639418887374, -3.855485770073988],
              [114.872539778399712, -3.855322605243454],
              [114.872476680961441, -3.855094061658024],
              [114.872273931603658, -3.854333791371669],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.711,
        No_Kav: "60a",
        Nm_Pemilik: null,
        NO_PETA: "52",
        NAMA_SERTI: "Jumirin/Jaman",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880164562441237, -3.849837414273553],
              [114.88002432345678, -3.849815535392574],
              [114.880016404555064, -3.84981429995293],
              [114.880003684042421, -3.849812315406655],
              [114.879841268145327, -3.849951444849595],
              [114.879845599451869, -3.850072385942727],
              [114.879916740480354, -3.850328377633301],
              [114.880112982335703, -3.850554449140564],
              [114.880267525915414, -3.850789718876447],
              [114.880163075694369, -3.851003356594437],
              [114.879317141064831, -3.85028357030159],
              [114.879427819029374, -3.850071836750061],
              [114.879661781599594, -3.849512070112163],
              [114.880164562441237, -3.849837414273553],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.093,
        No_Kav: "58b",
        Nm_Pemilik: null,
        NO_PETA: "65",
        NAMA_SERTI: "Kardi/Atmo Karyono",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880164576133382, -3.849837416409677],
              [114.880164563950856, -3.849837414509068],
              [114.879661781602948, -3.849512068744724],
              [114.879703012342532, -3.849413421834201],
              [114.879949604229395, -3.849510866093478],
              [114.880056561162618, -3.84954163711406],
              [114.880164576133382, -3.849837416409677],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.669,
        No_Kav: "58c",
        Nm_Pemilik: null,
        NO_PETA: "65",
        NAMA_SERTI: "Kardi/Atmo Karyono",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880164576133382, -3.849837416409677],
              [114.880056561162618, -3.84954163711406],
              [114.880487309735059, -3.849665561372846],
              [114.880548712628141, -3.849683529416069],
              [114.880779570607729, -3.849724498986809],
              [114.881371150572633, -3.850392148568714],
              [114.881223833078394, -3.850462464404877],
              [114.881115332206093, -3.85038275297047],
              [114.880962403918474, -3.850475145816629],
              [114.88077245597816, -3.850476916259856],
              [114.880586780359806, -3.850257235649805],
              [114.880483487538754, -3.850158217879889],
              [114.880164576133382, -3.849837416409677],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.02,
        No_Kav: "61b",
        Nm_Pemilik: "Indang",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880133986196952, -3.851555627683413],
              [114.879980195759344, -3.851768610048047],
              [114.879980192962705, -3.851768610715892],
              [114.879809816806372, -3.851927147621972],
              [114.879789729450181, -3.852148859929035],
              [114.879593977763292, -3.85229752083234],
              [114.879517778656194, -3.852297330435645],
              [114.879465069175893, -3.852279536232588],
              [114.879358713746484, -3.85227011185351],
              [114.879325421671197, -3.852219680897062],
              [114.879173375014261, -3.851924378437896],
              [114.879191236479372, -3.851877634052722],
              [114.87943888613438, -3.851554982226242],
              [114.879386124237371, -3.851410477122992],
              [114.879330027835323, -3.851350704533685],
              [114.879156830158891, -3.851260823158452],
              [114.879082028518624, -3.851183741812815],
              [114.879016600789328, -3.851127529160267],
              [114.879178783797201, -3.851010255538189],
              [114.879431323838645, -3.850941118970049],
              [114.879903477946939, -3.851175103494274],
              [114.880133986196952, -3.851555627683413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.02,
        No_Kav: "61a",
        Nm_Pemilik: "H. Pahrani",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Masuk SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880962151895773, -3.850476954899362],
              [114.88094444204404, -3.85048795722671],
              [114.880497286117162, -3.851052498905771],
              [114.880497195109896, -3.851052614021232],
              [114.88013398701392, -3.851555626323427],
              [114.879903477946939, -3.851175103494274],
              [114.879907693521062, -3.85117719259704],
              [114.880125573286875, -3.851080062260557],
              [114.880163075694369, -3.851003356594437],
              [114.880267525915414, -3.850789718876447],
              [114.880112982335703, -3.850554449140564],
              [114.879916740480354, -3.850328377633301],
              [114.879845599451869, -3.850072385942727],
              [114.879841268145327, -3.849951444849595],
              [114.880003684042421, -3.849812315406655],
              [114.880016404555064, -3.84981429995293],
              [114.88002432345678, -3.849815535392574],
              [114.880164576133382, -3.849837416409677],
              [114.880170321934102, -3.849838312819645],
              [114.880472854545815, -3.850150696149075],
              [114.880581420287996, -3.850253292813398],
              [114.88077071650568, -3.850479346917622],
              [114.880962151895773, -3.850476954899362],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.908,
        No_Kav: "10",
        Nm_Pemilik: "Sri Astuti/Sahrudin",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872786172526446, -3.850474714775239],
              [114.872713650938522, -3.850421800312143],
              [114.872235977541152, -3.850246468764054],
              [114.87199419387079, -3.850255641356401],
              [114.872229002269563, -3.849820123266086],
              [114.872130785390908, -3.849643192147406],
              [114.872203239018972, -3.849576144155271],
              [114.872262299540026, -3.849511751776188],
              [114.87230522957087, -3.849474210791814],
              [114.872337430430676, -3.849444710468223],
              [114.87236962461067, -3.849417899307215],
              [114.872440826460121, -3.849348526024532],
              [114.873203684651287, -3.849902415242868],
              [114.872786172526446, -3.850474714775239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.908,
        No_Kav: "10",
        Nm_Pemilik: "Sri Astuti/Sahrudin",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.873899651682294, -3.850407741601059],
              [114.873862294121977, -3.850713500571165],
              [114.873550029754625, -3.850961906998036],
              [114.873405102645265, -3.851178753558546],
              [114.873174952154827, -3.850881435266865],
              [114.872998584801167, -3.850629698664095],
              [114.872786172526446, -3.850474714775239],
              [114.873203684811784, -3.849902415404897],
              [114.873899651682294, -3.850407741601059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.791,
        No_Kav: "7",
        Nm_Pemilik: "Rusmiah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.871018683052469, -3.853821443447207],
              [114.87109016342346, -3.853497382338953],
              [114.871799782522885, -3.85272082842114],
              [114.871840959033719, -3.852855948884867],
              [114.871901222036598, -3.853265122708259],
              [114.871981425499243, -3.853604852429616],
              [114.87205688666279, -3.853820218557957],
              [114.871018683052469, -3.853821443447207],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.791,
        No_Kav: "7",
        Nm_Pemilik: "Rusmiah",
        NO_PETA: null,
        NAMA_SERTI: null,
        KETERANGAN: "Bebas Kawasan",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.872508872460415, -3.85533757293914],
              [114.87194107711035, -3.854726585264007],
              [114.871693292518728, -3.854207735802968],
              [114.871231478483935, -3.853904773897301],
              [114.871016188216913, -3.853832753943026],
              [114.871018683052469, -3.853821443447207],
              [114.872056886662349, -3.853820218731759],
              [114.872230708226496, -3.854316303664351],
              [114.872508872460415, -3.85533757293914],
            ],
          ],
        ],
      },
    },
  ],
};
