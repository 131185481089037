import { ConfigProvider, Tooltip } from "antd";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { APP_NAME } from "../../../configs/api";

const ButtonComponent = ({
  value,
  className = `button-transition w-full md:w-fit ${
    APP_NAME === "semai-kebun" &&
    "bg-primary-red-500 hover:border-primary-red-500 hover:text-primary-red-500 active:bg-primary-red-500"
  }`,
  textClass,
  isLoading,
  size = "sm",
  type = "submit",
  onClick,
  icon,
  disabled = false,
  tooltipTitle,
  tooltipPlacement,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    if (!tooltipTitle) return;
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => setTooltipVisible(false);

  const fontSize = {
    sm: "text-14",
    md: "text-18",
    lg: "text-24",
  };

  const buttonPadding = {
    sm: "px-[24px] py-[10px]",
    md: "px-32 py-13",
    lg: "px-24 py-13",
    none: "",
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: "#FFFFFF",
          borderRadius: 8,
          fontSize: 16,
          fontFamily: "Inter-Medium",
        },
      }}
    >
      <Tooltip
        title={tooltipTitle}
        placement={tooltipPlacement || "right"}
        open={tooltipVisible}
      >
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={disabled}
          onClick={onClick}
          type={type}
          target="_blank"
          className={`no-decoration flex cursor-pointer flex-row items-center justify-center space-x-16 rounded-8 no-underline ${
            disabled &&
            "cursor-not-allowed bg-gray-300 text-center text-gray-500 hover:bg-gray-300 hover:text-gray-500"
          } ${className} ${buttonPadding[size]}`}
        >
          {icon}
          {isLoading && (
            <p
              className={`no-decoration text-center font-medium ${fontSize[size]} no-underline ${textClass}`}
            >
              Sedang diproses...
            </p>
          )}
          {!isLoading && value && (
            <p
              className={`no-decoration text-center font-medium ${fontSize[size]} no-underline ${textClass}`}
            >
              {value}
            </p>
          )}
        </button>
      </Tooltip>
    </ConfigProvider>
  );
};

export default ButtonComponent;
