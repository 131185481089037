import InputHook from "../../../../components/atoms/InputHook";
import React from "react";

const Content = ({ register, errors, isSended }) => {
	return (
		<div className="mt-16 mb-24 grid w-full grid-cols-1 gap-13">
			<div className="grid grid-cols-1 gap-13">
				{isSended ? (
					<div className="grid grid-cols-1 gap-13">
						<InputHook
							register={register}
							label="Kode Verifikasi"
							name="code"
							className="mb-0 mt-0"
							inputClassName="input-large-border"
							placeholder="Masukkan kode verifikasi"
							errors={errors?.code}
						/>
					</div>
				) : (
					<InputHook
						disabled
						label="Email Terdaftar"
						register={register}
						name="email"
						className="mb-0 mt-0"
						inputClassName="input-large-border"
						errors={errors?.email}
					/>
				)}
			</div>
		</div>
	);
};

export default Content;
