import React, { useCallback, useEffect, useState } from "react";
import Modals from "../../../components/atoms/Modal";
import { create } from "zustand";
import NameRow from "./NameRow";
import { useMap } from "../context/mapContext";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";

export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data }) =>
    set((state) => ({
      modal: {
        ...state.modal,
        isOpen: !state.modal.isOpen,
        data: data || {},
      },
    })),
}));

const Modal = () => {
  const user = useSelector(selectUser);
  const [modal, isOpen, isLoading, toggleModal] = useModalStore((state) => [
    state.modal,
    state.modal.isOpen,
    state.modal.isLoading,
    state.toggleModal,
  ]);
  const { setMap } = useMap();

  const [data, setData] = useState();

  const updateData = useCallback(
    (updatedData) => {
      setData((currentData) => {
        return currentData?.map((item) =>
          item.id === updatedData.id ? { ...item, ...updatedData } : item
        );
      });
    },
    [data]
  );

  const deleteData = (id) => {
    setData((currentData) => currentData.filter((item) => item.id !== id));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const maps = {
      userInfo: user,
      mapShapes: data,
    };

    setMap(maps);
    toggleModal({ data });
  };

  // set data from modal data if exist
  useEffect(() => {
    setData(modal.data?.length >= 1 ? modal?.data : []);
  }, [modal?.data]);

  return (
    <Modals
      isOpen={isOpen}
      onClose={() => toggleModal({ data: modal?.data })}
      title={"Import GeoJson"}
    >
      <form onSubmit={onSubmit}>
        <Modals.Content>
          <div className={"relative grid w-full grid-cols-1 gap-16"}>
            {data?.map((item, index) => (
              <NameRow
                key={index}
                row={item}
                updateData={updateData}
                deleteData={deleteData}
              />
            ))}
          </div>
        </Modals.Content>
        <Modals.Footer
          toggleModal={() => toggleModal({ data: modal.data })}
          type={"Simpan GeJson"}
        />
      </form>
    </Modals>
  );
};

export default Modal;
