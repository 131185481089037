import { Number, parseToFloat } from "../../../../../helpers/Common";

import InputHook from "../../../../atoms/InputHook";
import React from "react";
import SelectHook from "../../../../atoms/SelectHook";
import moment from "moment";

const BlockCreateContent = ({ register, errors, watchFields, control }) => {
	const getYears = () => {
		let years = [];
		const now = moment().format("Y");
		for (let index = 1990; index < parseInt(now) + 1; index++) {
			years.push({
				label: index,
				value: index,
			});
		}
		return years.reverse();
	};

	return (
		<div className="grid grid-cols-1 gap-16">
			<InputHook
				register={register}
				required
				label="Nama Blok"
				name="blockName"
				placeholder="Contoh: A12"
				errors={errors?.blockName}
				inputClassName="input-large-border"
			/>
			<InputHook
				required
				register={register}
				label="Luas (Ha)"
				name="areaHa"
				errors={errors?.areaHa}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.areaHa?.length >= 1 &&
					`(${parseToFloat(watchFields?.areaHa)} Ha)`
				}
			/>
			<SelectHook
				label="Tahun Tanam"
				name="plantedYear"
				errors={errors?.plantedYear}
				data={getYears()}
				control={control}
				placeholder="Pilih tahun tanam"
			/>
			<InputHook
				register={register}
				required
				label="BJR"
				name="averageFruitWeight"
				errors={errors?.averageFruitWeight}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.averageFruitWeight?.length >= 1 &&
					`(${parseToFloat(watchFields?.averageFruitWeight)} kg)`
				}
			/>
			<InputHook
				register={register}
				required
				label="Jumlah TPH"
				name="tphCount"
				errors={errors?.tphCount}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.total_tph?.length >= 1 &&
					`(${Number(watchFields?.total_tph)} TPH)`
				}
			/>
			<InputHook
				register={register}
				required
				label="Jumlah Pokok Per Ha"
				name="treePerHa"
				errors={errors?.treePerHa}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.treePerHa?.length >= 1 &&
					`(${Number(watchFields?.treePerHa)})`
				}
			/>
			<InputHook
				register={register}
				required
				label="Jumlah Pokok"
				name="treeCount"
				errors={errors?.treeCount}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.treeCount?.length >= 1 &&
					`(${Number(watchFields?.treeCount)})`
				}
			/>
		</div>
	);
};

export default BlockCreateContent;
