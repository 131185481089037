import React from "react";
import PropTypes from "prop-types";
import InputHook from "../../../../../../../../components/atoms/InputHook";
import InputOption from "../../../../../../../../components/atoms/SelectHook/partials/InpuOptions";
import SelectHook from "../../../../../../../../components/atoms/SelectHook";
import { isEmptyObject } from "../../../../../../../../helpers/Common";

const Content = ({ control, register, errors, watch, tags, isUpdate }) => {
  const watchDimension = watch("dimension");
  const dimensions = [
    ...(watchDimension &&
    !isEmptyObject(watchDimension) &&
    watchDimension?.items?.length > 0
      ? watchDimension?.items?.map((item) => ({
          label: `${watchDimension?.itemPrefix}.${item}`,
          value: `${watchDimension?.itemPrefix}.${item}`,
        }))
      : []),
  ];

  return (
    <div className="grid grid-cols-1 gap-12">
      <div className="grid grid-cols-2 gap-12">
        <InputHook
          required
          register={register}
          label="Nama PKS"
          name="name"
          errors={errors?.name}
          inputClassName="input-large-border"
          className="col-span-2"
          placeholder="Masukkan nama group untuk tags"
        />
        <InputHook
          register={register}
          label="Deskripsi PKS"
          name="description"
          errors={errors?.description}
          inputClassName="input-large-border"
          className="col-span-2"
          placeholder="Masukkan deskripsi"
        />
        {!isUpdate && (
          <>
            <SelectHook
              control={control}
              label="Tag Grup"
              name="dimension"
              data={tags}
              errors={errors?.dimension}
              placeholder="Pilih dimensi"
              onCreateOption={(data) => {
                setIsCreateDimensionalTag(true);
              }}
            />
            <SelectHook
              control={control}
              label="Tags"
              name="tags"
              data={dimensions}
              errors={errors?.tags}
              placeholder="Pilih Tags"
              components={{ Option: InputOption }}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Content;

Content.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};
