import React, { useEffect } from "react";
import {
	createAfdeling,
	updateAfdeling,
} from "../../../../features/afdelingSlice";
import { useDispatch, useSelector } from "react-redux";

import AfdelingCreateContent from "../../../molecules/MasterAfdeling/Modal/CreateContent";
import ButtonComponent from "../../../atoms/ButtonComponent";
import Modal from "../../../atoms/Modal";
import { formSchema as formSchemaEstate } from "../../../molecules/MasterAfdeling/Modal/CreateContent/formValidation";
import { omitBy } from "lodash";
import { selectEstates } from "../../../../features/estateSlice";
import { selectModal } from "../../../../features/modalSlice";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";

export const modalName = "masterAfdeling";

const AfdelingModal = ({
	isOpen,
	toggleModal,
	type,
	estate,
	onRefreshData: handleRefreshData,
}) => {
	const [searchParams] = useSearchParams();
	const areaHaFromParams = searchParams.get("areaHa");

	const UPDATE_TYPE = type.toLowerCase().includes("ubah");
	const {
		register,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm({
		resolver: yupResolver(formSchemaEstate),
	});
	const watchFields = watch();

	const alert = useAlert();
	const dispatch = useDispatch();
	const modal = useSelector(selectModal);
	const user = useSelector(selectUser);
	const estates = useSelector(selectEstates);
	const [isLoading, setIsLoading] = React.useState(false);

	const onSubmit = (data) => {
		setIsLoading(true);
		let requestData = {
			...data,
			companyId: user?.companyId,
			companyName: user?.companyName,
			estateId: estate?.id || user?.defaultEstateId,
			estateName: estate?.name || user?.defaultEstateName,
			areaHa: parseFloat(data?.areaHa),
		};

		if (UPDATE_TYPE) {
			requestData = { ...requestData, id: data.id, uuid: data.id };
			dispatch(updateAfdeling(requestData))
				.unwrap()
				.then((resp) => {
					setIsLoading(false);
					const success = resp.success;
					if (!success) {
						return alert.error(resp?.message);
					}
					alert.success(
						`Berhasil memperharui data afdeling ${resp.record.name}`
					);
					toggleModal();
					handleRefreshData();
				})
				.catch((err) => {
					setIsLoading(false);
					alert.error(err?.message);
				});
		} else {
			let id = uuidv4();
			requestData = omitBy(requestData, (v) => v == null);
			requestData = { ...requestData, id: id, uuid: id };
			dispatch(createAfdeling(requestData))
				.unwrap()
				.then((resp) => {
					setIsLoading(false);
					if (!resp.success) {
						return alert.error(resp?.message);
					}
					alert.success(
						`Berhasil menambahkan afdeling ${resp.record.name}`
					);
					toggleModal();
					handleRefreshData();
				})
				.catch((err) => {
					setIsLoading(false);
					alert.error(err?.message);
				});
		}
	};

	const handleDefaultValue = () => {
		let state = modal[modalName]?.params?.item;
		clearErrors();
		if (UPDATE_TYPE) {
			reset();
			const currentData = {
				...state,
				estate: { label: state?.estateName, value: state?.estateId },
			};
			reset(currentData);
		} else {
			const nullValues = Object.keys(state || {}).reduce(
				(accumulator, key) => {
					return { ...accumulator, [key]: null };
				},
				{}
			);
			reset();
			reset(nullValues);
		}
	};

	useEffect(() => {
		handleDefaultValue();
	}, [modal, type]);

	function isFloat(value) {
		// Check if the value is a number and is not NaN or Infinity
		if (typeof value === "number" && !isNaN(value) && isFinite(value)) {
			// Check if the value has a decimal point
			return value % 1 !== 0;
		}
		// If the value is not a number or is NaN or Infinity, return false
		return false;
	}

	useEffect(() => {
		const subscription = watch((data) => {
			if (isFloat(parseFloat(areaHaFromParams))) {
				const tolerance = 0.0001;
				if (parseFloat(data.areaHa) - areaHaFromParams > tolerance) {
					setValue("areaHa", areaHaFromParams);
				}
			} else {
				if (parseFloat(data.areaHa) > Number(areaHaFromParams)) {
					setValue("areaHa", areaHaFromParams);
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={toggleModal}
			title={`${type} ${estate.name} / Afdeling`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="relative flex flex-col px-24 py-16">
					<AfdelingCreateContent
						register={register}
						control={control}
						errors={errors}
						watchFields={watchFields}
						data={{ estates: estates }}
						showEstateOptions={estate.id}
					/>
				</div>
				<div className="modal-footer-container">
					<div className="left flex flex-row items-center space-x-16">
						<ButtonComponent
							value="Batalkan"
							size="sm"
							type="button"
							border
							className="danger-button-transition"
							onClick={() => toggleModal()}
						/>
						<ButtonComponent
							disabled={isLoading}
							isLoading={isLoading}
							value={`${type} afdeling`}
							size="sm"
							type="submit"
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AfdelingModal;
