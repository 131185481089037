import GardenProductionComponent from "../../components";
import React from "react";
import { headersData } from "./api/headerData";
import moment from "moment";
import { useData } from "../../hooks/DataContext";

const Cards = () => {
  const {
    dataPreviousDate,
    data,
    isFilterBy,
    calculateDate,
    averageFruits,
    target,
  } = useData();

  const restanPanen = data?.harvestingEstWeightKg - data?.deliveryEstWeightKg;
  const restanJanjang = data?.harvestingBunchCount - data?.deliveryBunchCount;

  return (
    <div className="mx-auto grid w-full grid-cols-1 gap-8 px-8 md:grid-cols-4 md:grid-rows-[minmax(0px,1fr)] md:gap-13 md:px-[2%]">
      <div className="order-1 col-span-2 grid grid-cols-2 gap-8 md:order-1 md:grid-rows-[minmax(0px,0.2fr)_minmax(0px,0.2fr)_minmax(0px,0.6fr)] md:gap-13">
        <GardenProductionComponent.SimpleCardHorizontal
          title="Panen Hari Ini"
          subTitle={moment(isFilterBy.endDate).format("ll")}
          value={data?.harvestingEstWeightKg || 0}
          unit="kg"
        />
        <GardenProductionComponent.SimpleCardHorizontal
          title="Janjang Hari Ini"
          subTitle={moment(isFilterBy.endDate).format("ll")}
          value={data?.harvestingBunchCount || 0}
          unit="jjg"
        />
        {/*Tanggal, dan value belum di perbaiki */}
        {/*<GardenProductionComponent.SimpleCardHorizontal*/}
        {/*  title="Taksasi Tonase"*/}
        {/*  subTitle={moment(isFilterBy.endDate).format("ll")}*/}
        {/*  value={0}*/}
        {/*  unit="kg"*/}
        {/*/>*/}
        {/*<GardenProductionComponent.SimpleCardHorizontal*/}
        {/*  title="Taksasi Janjang"*/}
        {/*  subTitle={moment(isFilterBy.endDate).format("ll")}*/}
        {/*  value={0}*/}
        {/*  unit="jjg"*/}
        {/*/>*/}

        <GardenProductionComponent.SimpleCardHorizontal
          title="Panen Kemarin"
          subTitle={moment(calculateDate(isFilterBy.endDate)?.yesterday).format(
            "ll"
          )}
          value={dataPreviousDate?.harvestingEstWeightKg || 0}
          unit="kg"
        />
        <GardenProductionComponent.SimpleCardHorizontal
          title="Janjang Kemarin"
          subTitle={moment(calculateDate(isFilterBy.endDate)?.yesterday).format(
            "ll"
          )}
          value={dataPreviousDate?.harvestingBunchCount || 0}
          unit="jjg"
        />

        <div className="col-span-1">
          <GardenProductionComponent.CardWithDoughnutChart
            title="Total Panen Bulan Ini"
            subTitle={`s/d ${moment(isFilterBy.endDate).format("ll")}`}
            data={[
              {
                title: "Realisasi",
                value: data?.mtdHarvestingEstWeightKg || 0,
                unit: "kg",
                style: { backgroundColor: "#5A687C" },
              },
              {
                title: "Target",
                value: target?.targetWeightKg || 0,
                unit: "kg",
                style: { backgroundColor: "#A7BCD7" },
              },
            ]}
            progressStyle={{
              backgroundColor: ["#5A687C", "#A7BCD7"],
            }}
          />
        </div>
        <div className="col-span-1">
          <GardenProductionComponent.CardWithDoughnutChart
            title="Total janjang Bulan Ini"
            subTitle={`s/d ${moment(isFilterBy.endDate).format("ll")}`}
            data={[
              {
                title: "Realisasi",
                value: data?.mtdHarvestingBunchCount || 0,
                unit: "jjg",
                style: { backgroundColor: "#B69A7A" },
              },
              {
                title: "Target",
                value: target?.targetBunchCount || 0,
                unit: "jjg",
                style: { backgroundColor: "#F8E7AC" },
              },
            ]}
            progressStyle={{
              backgroundColor: ["#B69A7A", "#F8E7AC"],
            }}
          />
        </div>
      </div>
      <div className="order-2 col-span-2 grid grid-cols-1 gap-13 md:order-3 md:col-span-1 md:grid-rows-[minmax(0px,0.5fr)_minmax(0px,0.5fr)_minmax(0px,1.5fr)]">
        {/*<GardenProductionComponent.SimpleCardHorizontal*/}
        {/*  title="Panen Kemarin"*/}
        {/*  subTitle={moment(calculateDate(isFilterBy.endDate)?.yesterday).format(*/}
        {/*    "ll"*/}
        {/*  )}*/}
        {/*  value={dataPreviousDate?.harvestingEstWeightKg || 0}*/}
        {/*  unit="kg"*/}
        {/*/>*/}
        {/*<GardenProductionComponent.SimpleCardHorizontal*/}
        {/*  title="Janjang Kemarin"*/}
        {/*  subTitle={moment(calculateDate(isFilterBy.endDate)?.yesterday).format(*/}
        {/*    "ll"*/}
        {/*  )}*/}
        {/*  value={dataPreviousDate?.harvestingBunchCount || 0}*/}
        {/*  unit="jjg"*/}
        {/*/>*/}
        <div className="row-span-2">
          <GardenProductionComponent.SimpleCardVertical
            title="Restan Hari Ini"
            subTitle={moment(isFilterBy.endDate).format("ll")}
            data={[
              {
                value: restanJanjang,
                unit: "jjg",
              },
              {
                value: restanPanen,
                unit: "kg",
              },
            ]}
          />
        </div>
        {/*<div className="row-span-2">*/}
        {/*  /!*Tanggal, dan value belum di perbaiki *!/*/}
        {/*  <GardenProductionComponent.SimpleCardVertical*/}
        {/*    title="Buah Mentah"*/}
        {/*    subTitle={moment(isFilterBy.endDate).format("ll")}*/}
        {/*    data={[*/}
        {/*      {*/}
        {/*        value: restanJanjang,*/}
        {/*        unit: "jjg",*/}
        {/*      },*/}
        {/*      {*/}
        {/*        value: restanPanen,*/}
        {/*        unit: "kg",*/}
        {/*      },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="relative order-3 grid h-full grid-cols-1 gap-13 overflow-y-scroll rounded-8 bg-white scrollbar-none">
        <div className="">
          <GardenProductionComponent.CardWithTable
            headers={headersData}
            data={averageFruits || []}
          />
        </div>
      </div>
    </div>
  );
};

export default Cards;
