import axiosInstance from "../api";
import { CHEMIST_RECAPITULATION_URL } from "../../configs/api";

export const getChemistRecapsDistinctBlocksApi = (params) =>
  axiosInstance.get(`${CHEMIST_RECAPITULATION_URL}/daily/distinct-blocks`, {
    params,
  });

export const getChemistRecapsMaterialSummaryApi = (params) =>
  axiosInstance.get(`${CHEMIST_RECAPITULATION_URL}/daily/material-summary`, {
    params,
  });

export const getChemistRecapsOverallSummaryApi = (params) =>
  axiosInstance.get(`${CHEMIST_RECAPITULATION_URL}/daily/overall-summary`, {
    params,
  });
