import React, { useState } from "react";
import { ConfigProvider, Upload } from "antd";
import { HiOutlineInbox } from "react-icons/hi";
import { create } from "zustand";
import { FILE_ATTACHMENTS_URL } from "../../../../../../configs/api";
import Modal from "../../../../../../components/atoms/Modal";
import { toast } from "react-hot-toast";
import ButtonComponent from "../../../../../../components/atoms/ButtonComponent";

const { Dragger } = Upload;

export const useModalUploadStore = create((set) => ({
  modalUpload: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data, isUpdate, key = "modalUpload" }) =>
    set((state) => ({
      [key]: {
        ...state[key],
        isOpen: state[key] ? !state[key].isOpen : true,
        isUpdate: isUpdate || false,
        data: data || {},
      },
    })),
}));

const ModalUploadFile = () => {
  const [modal, isOpen, isUpdate, isLoading, toggleModal] = useModalUploadStore(
    (state) => [
      state.modalUpload,
      state.modalUpload.isOpen,
      state.modalUpload.isUpdate,
      state.modalUpload.isLoading,
      state.toggleModal,
    ]
  );

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    setUploading(true);
    // You can use any AJAX library you like
    // fetch('https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188', {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then((res) => res.json())
    //   .then(() => {
    //     setFileList([]);
    //     message.success('upload successfully.');
    //   })
    //   .catch(() => {
    //     message.error('upload failed.');
    //   })
    //   .finally(() => {
    //     setUploading(false);
    //   });
  };

  const props = {
    name: "file",
    action: FILE_ATTACHMENTS_URL,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#D7A137" },
      }}
    >
      <Modal
        isOpen={isOpen}
        onClose={() => toggleModal({ data: {}, isUpdate: false })}
        title={`Unggah Excel Anggota`}
      >
        <Modal.Content>
          <Dragger {...props}>
            <div className="flex flex-col items-center justify-center space-y-12 px-24">
              <figure>
                <HiOutlineInbox className="text-42 text-gray-500" />
              </figure>
              <div className="flex flex-col items-center justify-center space-y-8">
                <p className="text-16 font-semibold text-gray-500">
                  Klik atau seret file ke area ini untuk diunggah
                </p>
                <p className="font-regular text-14 text-gray-300">
                  Hanya mendukung file Excel Template yang di download dari {""}
                  "Unduh Template" atau{" "}
                  <span
                    className="font-bold text-gray-500 underline"
                    onClick={(event) => {
                      event.stopPropagation();
                      toast.success(
                        "Berhasil mendownload template Excel Anggota"
                      );
                    }}
                  >
                    "Klik Disini."
                  </span>
                </p>
              </div>
            </div>
          </Dragger>
        </Modal.Content>

        <Modal.Footer
          toggleModal={() => toggleModal({ data: {}, isUpdate: false })}
          submitComponent={
            <ButtonComponent
              value="Unggah Excel"
              onClick={() => toggleModal({ data: {}, isUpdate: false })}
              className="bg-yellow-500 text-white hover:bg-yellow-700"
            />
          }
        />
      </Modal>
    </ConfigProvider>
  );
};

export default ModalUploadFile;
