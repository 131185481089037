import {
  handleModal,
  handleSetParams,
  selectModal,
} from "../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import React, { useState } from "react";

const useModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const [isUpdate, setIsUpdate] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen((prev) => !prev);
  const handleOk = () => setIsModalOpen(false);

  const onShowModal = (modalName, modalType) => {
    setIsUpdate(false);
    dispatch(handleModal({ modalName: modalName, state: modalType }));
  };

  const onModalUpdate = (modalName, modalType, params) => {
    setIsUpdate(true);
    dispatch(handleModal({ modalName: modalName, state: modalType }));
    dispatch(
      handleSetParams({
        modalName: modalName,
        state: "params",
        params: { ...modal[modalName].params, ...params },
      })
    );
  };

  const onModalDelete = (props) => {};

  const onOthersModal = (modalName, params) => {
    dispatch(
      handleSetParams({
        modalName: modalName,
        state: "params",
        params: { ...modal[modalName].params, ...params },
      })
    );
  };

  return {
    isUpdate,
    setIsUpdate,
    onShowModal,
    onModalUpdate,
    onModalDelete,
    onOthersModal,
    isModalOpen,
    setIsModalOpen,
    showModal,
    handleOk,
  };
};

export default useModal;
