import { ConfigProvider, DatePicker, Image } from "antd";
import { Controller, useForm } from "react-hook-form";
import { Number } from "../../../../../../../helpers/Common";
import React, { useCallback, useEffect } from "react";
import { debounce, omit } from "lodash";

import { IMAGE_NOT_FOUND } from "../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../components/atoms/InputHook";
import { TbCalendar } from "react-icons/tb";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useGalleryViewStore } from "../../hooks/useGalleryViewStore";
import { useHarvestingStore } from "../../../../../../../api/useHarvestingStore";
import {
  processTextChange,
  processDateChange,
} from "../../../../../../../hooks/commentUtils";

dayjs.extend(customParseFormat);

const DetailContent = ({ handleRefreshData }) => {
  const [getHarvestingById, updateHarvesting] = useHarvestingStore((state) => [
    state.getHarvestingById,
    state.updateHarvesting,
  ]);
  const jeprinData = useGalleryViewStore((state) => state.jeprinData);
  const { control, setValue, watch, resetField } = useForm();

  const watchRemark = watch(`remark-${jeprinData?.id}`);

  const handleBlurOnRemarkChange = () => {
    const textParsed = processTextChange({
      value: watchRemark,
    });

    getHarvestingById(jeprinData?.id, (harvestingData) => {
      updateHarvesting({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(harvestingData.record, ["remark"]),
          remark: textParsed.value,
          version: harvestingData.record.version + 1,
        },
        onSuccess: () => {
          textParsed.onSuccess();
          handleRefreshData(true);
        },
      });
    });
  };

  const handleOnKeyDownOnRemarkChange = (event) => {
    if (event.key === "Enter") {
      handleBlurOnRemarkChange();
    }
  };

  const handleOnChangeOnDateChange = async (date, onChange) => {
    onChange(date);

    const dateParsed = processDateChange({
      value: date,
    });

    await getHarvestingById(jeprinData?.id, (harvestingData) => {
      updateHarvesting({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(harvestingData.record, ["caDueDate"]),
          caDueDate: dateParsed.value,
          version: harvestingData.record.version + 1,
        },
        onSuccess: () => {
          dateParsed.onSuccess();
          handleRefreshData(true);
        },
      });
    });
  };

  const request = debounce(async (searchTerm) => {
    const textParsed = processTextChange({
      value: searchTerm,
    });

    await getHarvestingById(jeprinData?.id, (harvestingData) => {
      updateHarvesting({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(harvestingData.record, ["remark"]),
          remark: textParsed.value,
          version: harvestingData.record.version + 1,
        },
        onSuccess: () => handleRefreshData(true),
      });
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [jeprinData]
  );

  const clearFormData = () => {
    resetField("fullname");
    resetField("date");
    resetField("adhocBlockName");
    resetField("collectionPointName");
    resetField("fruitCount");
    resetField("unripeCount");
    resetField("totalWeight");
    resetField("estateName");
    resetField("afdelingName");
    resetField(`remark-${jeprinData?.id}`);
    resetField(`caDueDate-${jeprinData?.id}`);
  };

  // this function is used to populate the form data when the modal is opened
  const populateFormData = (formValue) => {
    setValue("fullname", formValue?.fullname);
    setValue(
      "date",
      `${moment(formValue?.date).format("LL")} ${moment(
        formValue?.createdTime
      ).format("H:mm")}`
    );
    setValue("adhocBlockName", formValue?.adhocBlockName);
    setValue("collectionPointName", formValue?.collectionPointName);
    setValue("fruitCount", Number(formValue?.fruitCount));
    setValue("unripeCount", Number(formValue?.unripeCount));
    setValue("totalWeight", Number(Math.ceil(formValue?.totalWeight)));
    setValue("estateName", formValue?.estateName);
    setValue("afdelingName", formValue?.afdelingName);
    setValue(`remark-${jeprinData?.id}`, jeprinData?.remark);
    setValue(
      `caDueDate-${jeprinData?.id}`,
      jeprinData?.caDueDate
        ? dayjs(moment(jeprinData?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  };

  useEffect(() => {});

  useEffect(() => {
    console.log("Called!");
    clearFormData();
    getHarvestingById(jeprinData?.id, (harvestingData) => {
      populateFormData({
        ...harvestingData.record,
        source: jeprinData?.source,
      });
    });
  }, [jeprinData?.id, jeprinData?.remark, jeprinData?.caDueDate]);

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-16">
      <div>
        <Image
          wrapperClassName="aspect-square w-full"
          className="rounded-16"
          src={jeprinData.source || IMAGE_NOT_FOUND}
        />
      </div>
      <div className="relative grid max-h-[350px] w-full grid-cols-1 gap-8 overflow-y-scroll py-16">
        <InputHook
          label="Komentar (Opsional)"
          control={control}
          name={`remark-${jeprinData?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-large-border"
          afterOnChange={debounceTextRequest}
          onBlur={handleBlurOnRemarkChange}
          onKeyDown={handleOnKeyDownOnRemarkChange}
        />
        <div className="w-full">
          <p className="mb-8 flex flex-row items-center text-14 font-medium text-gray-500">
            Tanggal Tindakan (Opsional)
          </p>
          <Controller
            name={`caDueDate-${jeprinData?.id}`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#d7a137",
                      colorTextQuaternary: "#e1e4e8",
                      fontFamily: "Inter-Regular",
                      fontSize: 16,
                      colorTextPlaceholder: "#6B6F77",
                    },
                  }}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(date) =>
                      handleOnChangeOnDateChange(date, onChange)
                    }
                    className="w-full border border-gray-10 py-13 font-regular text-16"
                    placeholder="Tanggal tindakan"
                    suffixIcon={<TbCalendar className="text-16" />}
                  />
                </ConfigProvider>
              );
            }}
          />
        </div>
        <InputHook
          control={control}
          disabled
          label="Tanggal Panen"
          name="date"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Dibuat Oleh"
          name="fullname"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Blok"
          name="adhocBlockName"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="TPH"
          name="collectionPointName"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Total Janjang"
          name="fruitCount"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Buah Mentah"
          name="unripeCount"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Total Panen (Kg)"
          name="totalWeight"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Estate"
          name="estateName"
          inputClassName="input-large-border"
        />
        <InputHook
          control={control}
          disabled
          label="Afdeling"
          name="afdelingName"
          inputClassName="input-large-border"
        />
      </div>
    </div>
  );
};

export default DetailContent;
