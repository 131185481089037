import GardenProductionComponent from "../../../GardenProduction/components";
import PksProductionComponent from "../../components";
import React from "react";
import { headersData } from "../../../GardenProduction/partials/Cards/api/headerData";
import moment from "moment";
import { useData } from "../../hooks/DataContext";

const Cards = () => {
  const { data, isFilterBy, averageFruits, target } = useData();

  return (
    <div className="mx-auto grid w-full grid-cols-1 gap-8 px-8 md:grid-cols-4 md:grid-rows-[minmax(0px,1fr)] md:gap-13 md:px-16 md:px-[2%]">
      <div className="grid grid-cols-2 gap-8 md:col-span-2 md:grid-rows-[minmax(0px,0.2fr)_minmax(0px,0.2fr)_minmax(0px,0.6fr)] md:gap-13">
        <GardenProductionComponent.SimpleCardHorizontal
          title="Tonase Diterima PKS Hari ini"
          subTitle={moment(isFilterBy.endDate).format("LL")}
          value={data?.millNetWeightKg || 0}
          unit="kg"
        />
        <GardenProductionComponent.SimpleCardHorizontal
          title="Jjg Dikirim Hari ini"
          subTitle={moment(isFilterBy.endDate).format("LL")}
          value={data?.millAcceptedBunchCount || 0}
          unit="jjg"
        />
        <div className=" row-span-2 md:col-span-1">
          <PksProductionComponent.CardWithDoughnutChartVertical
            title="Diterima PKS Bulan Ini"
            subTitle={`s/d ${moment(isFilterBy.endDate).format("LL")}`}
            data={[
              {
                title: "Tonase PKS",
                value: data?.mtdMillNetWeightKg || 0,
                unit: "kg",
                style: { backgroundColor: "#5A687C" },
              },
              {
                title: "Target Bulan Ini",
                value: target?.targetWeightKg || 0,
                unit: "kg",
                style: { backgroundColor: "#A7BCD7" },
              },
            ]}
            progressStyle={{
              backgroundColor: ["#5A687C", "#A7BCD7"],
            }}
          />
        </div>
        <div className=" row-span-2 md:col-span-1">
          <PksProductionComponent.CardWithDoughnutChartVertical
            title="Diterima PKS Bulan Ini"
            subTitle={`s/d ${moment(isFilterBy.endDate).format("LL")}`}
            data={[
              {
                title: "Janjang PKS",
                value: data?.mtdMillAcceptedBunchCount || 0,
                unit: "jjg",
                style: { backgroundColor: "#B69A7A" },
              },
              {
                title: "Janjang Panen",
                value: data?.mtdMillDeliveredBunchCount || 0,
                unit: "jjg",
                style: { backgroundColor: "#F8E7AC" },
              },
            ]}
            progressStyle={{
              backgroundColor: ["#B69A7A", "#F8E7AC"],
            }}
          />
        </div>
      </div>
      {/*<div className="grid grid-cols-1 gap-13 md:grid-rows-[minmax(0px,0.5fr)_minmax(0px,0.5fr)_minmax(0px,1.5fr)]">*/}
      {/*  */}
      {/*</div>*/}
      <div className="grid grid-cols-1 gap-8 md:grid-rows-[minmax(0px,0.5fr)_minmax(0px,0.8fr)] md:gap-13">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-2 md:gap-13 md:gap-14">
          <GardenProductionComponent.SimpleCardVertical
            title="Penalti Hari Ini"
            subTitle={moment(isFilterBy.endDate).format("LL")}
            data={[
              {
                value: data?.millRejectedBunchCount || 0,
                unit: "jjg",
              },
            ]}
          />
          <GardenProductionComponent.SimpleCardVertical
            title="Penalti Bulan Ini"
            subTitle={`s/d ${moment(isFilterBy.endDate).format("LL")}`}
            data={[
              {
                value: data?.mtdMillRejectedBunchCount || 0,
                unit: "jjg",
              },
            ]}
          />
        </div>
        <GardenProductionComponent.CardWithDoughnutChart
          onlyText
          title="Janjang Dikirim vs Penalti"
          subTitle={`s/d ${moment(isFilterBy.endDate).format("LL")}`}
          data={[
            {
              title: "Penalti",
              value: data?.mtdMillRejectedBunchCount || 0,
              unit: "jjg",
              style: { backgroundColor: "#dc2626" },
            },
            {
              title: "Janjang Dikirim",
              value: data?.mtdMillDeliveredBunchCount || 0,
              unit: "jjg",
              style: { backgroundColor: "#F8E7AC" },
            },
          ]}
          progressStyle={{
            backgroundColor: ["#dc2626", "#F8E7AC"],
          }}
        />
      </div>
      <div className="relative grid h-full grid-cols-1 gap-13 overflow-y-scroll rounded-8 bg-white scrollbar-none">
        <div>
          <GardenProductionComponent.CardWithTable
            headers={headersData}
            data={averageFruits || []}
          />
        </div>
      </div>
    </div>
  );
};

export default Cards;
