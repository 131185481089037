import React, { useRef } from "react";
import { IoColorPaletteOutline, IoLayersOutline } from "react-icons/io5";
import { readMultipleJsonFiles } from ".././helpers/fileHelpers";
import { toast } from "react-hot-toast";
import { useModalStore } from "./Modal";
import { GrPowerReset } from "react-icons/gr";
import { useMap } from "../context/mapContext";

const Tools = ({ fitToBounds }) => {
  const fileInputRef = useRef(null);
  const { map: data, setMap: setData } = useMap();

  const [modal, toggleModal] = useModalStore((state) => [
    state.modal,
    state.toggleModal,
  ]);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      toggleModal({ data: modal.data, isOpen: false });
      return;
    }

    readMultipleJsonFiles(files)
      .then((data) => {
        toggleModal({ data: data });
      })
      .catch((error) => {
        toast.error("Gagal membaca file GeoJson. Silahkan coba lagi.");
      });
  };

  return (
    <div className="absolute left-16 top-16 z-10 flex flex-wrap gap-16 rounded-8 bg-white px-16 py-8 shadow-md shadow-yellow-500">
      <button
        type={"button"}
        className="flex cursor-pointer flex-row items-center gap-16"
        onClick={triggerFileInput}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple
          onChange={handleFileChange}
          accept=".json"
          className={"hidden"}
        />
        <IoLayersOutline className={"text-24 text-gray-500"} />
        <p className={"font-medium text-gray-500"}>Import GeoJson</p>
      </button>
      {data?.mapShapes?.length > 0 && (
        <>
          <button
            type={"button"}
            className="flex cursor-pointer flex-row items-center gap-16"
            onClick={fitToBounds}
          >
            <IoLayersOutline className={"text-24 text-gray-500"} />
            <p className={"font-medium text-gray-500"}>Fit to Bounds</p>
          </button>
          <button
            type={"button"}
            className="flex cursor-pointer flex-row items-center gap-16"
            onClick={() => toggleModal({ data: modal.data })}
          >
            <IoColorPaletteOutline className={"text-24 text-gray-500"} />
            <p className={"font-medium text-gray-500"}>Edit Warna</p>
          </button>
        </>
      )}
      <button
        type={"button"}
        className="flex cursor-pointer flex-row items-center gap-16"
        onClick={() => setData([])}
      >
        <GrPowerReset className={"text-24 text-gray-500"} />
        <p className={"font-medium text-gray-500"}>Reset</p>
      </button>
    </div>
  );
};

export default Tools;
