import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AVERAGEFRUIT_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	averageFruits: [],
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getAverageFruits = createAsyncThunk(
	"averageFruit/getAverageFruits",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(AVERAGEFRUIT_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					sidx: "syncInfo.updatedTime",
					sord: "desc",
					...data,
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createAverageFruit = createAsyncThunk(
	"averageFruit/createAverageFruit",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(AVERAGEFRUIT_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateAverageFruit = createAsyncThunk(
	"averageFruit/updateAverageFruit",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(
				`${AVERAGEFRUIT_URL}/${data?.id}`,
				data,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteAverageFruit = createAsyncThunk(
	"averageFruit/deleteAverageFruit",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(
				`${AVERAGEFRUIT_URL}/${data?.id}`,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const averageFruitSlice = createSlice({
	name: "averageFruit",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAverageFruits.fulfilled, (state, action) => {
				const records = action.payload?.records;
				state.averageFruits = records;
			})
			.addCase(deleteAverageFruit.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(createAverageFruit.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(updateAverageFruit.fulfilled, (state, action) => {
				state.isRequest = false;
			});
	},
});

export const {} = averageFruitSlice.actions;
export const selectAverageFruits = (state) => state.averageFruit.averageFruits;
export const selectStatuses = (state) => ({
	isLoading: state.averageFruit.isLoading,
	isRequest: state.averageFruit.isRequest,
	isError: state.averageFruit.isError,
	errors: state.averageFruit.errors,
});

export default averageFruitSlice.reducer;
