import * as yup from "yup";

export const formSchema = yup.object().shape({
	afdeling: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Afdeling atau Wilayah wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Afdeling atau Wilayah wajib diisi."),
		})
		.nullable()
		.required("Tahun tanam wajib diisi."),
	plantingYear: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Tahun tanam wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Tahun tanam wajib diisi."),
		})
		.nullable()
		.required("Tahun tanam wajib diisi."),
	block: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Blok wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Blok wajib diisi."),
		})
		.nullable()
		.required("Blok wajib diisi."),
	estate: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Estate wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Estate wajib diisi."),
		})
		.nullable()
		.required("Estate wajib diisi."),
	month: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Bulan panen wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Bulan panen wajib diisi."),
		})
		.nullable()
		.required("Bulan panen wajib diisi."),
	year: yup
		.object()
		.shape({
			label: yup
				.string("Harus berupa string")
				.required("Tahun panen wajib diisi."),
			value: yup
				.string("Harus berupa string")
				.required("Tahun panen wajib diisi."),
		})
		.nullable()
		.required("Tahun panen wajib diisi."),
	bjr: yup.string("Harus berupa string").required("BJR Acuan wajib diisi."),
	tonnase: yup
		.string("Harus berupa string")
		.required("Jumlah tonase wajib diisi."),
	tbs: yup.string("Harus berupa string").required("Tbs wajib diisi."),
});
