import React, { useCallback, useEffect, useState } from "react";
import ModalContainer from "../../../../../../components/atoms/Modal";

import { useForm } from "react-hook-form";
import Content from "./partials/Content/Content.jsx";
import { isEmptyObject } from "../../../../../../helpers/Common";
import { create } from "zustand";
import { useUserStore } from "../../../../../MasterWorkerPage/hooks/userStore";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { formSchema } from "./schema/formSchema";
import { useEmployeeStore } from "../../../../../../api/employee/useEmployeeStore";
import {
  getCities,
  getProvinces,
  getSubdistricts,
  getVillages,
  selectArea,
} from "../../../../../../features/areaSlice";
import { formatPhoneNumber } from "../../../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import ProfilePicture from "../ProfilePicture";
import { isNil } from "lodash";

export const useModalStore = create((set) => ({
  modal: {
    isOpen: false,
    isLoading: false,
    isUpdate: false,
    data: {},
  },
  toggleModal: ({ data, isUpdate, key = "modal" }) =>
    set((state) => ({
      [key]: {
        ...state[key],
        isOpen: state[key] ? !state[key].isOpen : true,
        isUpdate: isUpdate || false,
        data: data || {},
      },
    })),
}));

const Modal = ({ onRefreshData }) => {
  const dispatch = useDispatch();
  const area = useSelector(selectArea);
  const [version, setVersion] = useState(1);

  const [modal, isOpen, isUpdate, isLoading, toggleModal] = useModalStore(
    (state) => [
      state.modal,
      state.modal.isOpen,
      state.modal.isUpdate,
      state.modal.isLoading,
      state.toggleModal,
    ]
  );
  const [createEmployee, updateEmployee] = useEmployeeStore((state) => [
    state.createEmployee,
    state.updateEmployee,
  ]);
  const [user] = useUserStore((state) => [state.user]);

  const [photoFileId, setPhotoFileId] = useState(null);
  const performSetPhotoFileId = useCallback(
    (id) => {
      setPhotoFileId(id);
      setVersion((prev) => {
        return prev + 2;
      });
    },
    [version, photoFileId]
  );
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = (inputData) => {
    if (isUpdate) {
      const body = {
        ...modal?.data,
        ...inputData,
        phone: `62${formatPhoneNumber(inputData?.phone)}`,
        version,
      };

      updateEmployee({
        id: body.id,
        body,
        onSuccess: () => {
          onRefreshData(true);
          toggleModal({ data: {}, isUpdate: false });
          clearForm();
        },
      });
    } else {
      let body = {
        companyId: user?.companyId,
        companyName: user?.adhocEstateName,
        ...inputData,
        phone: `62${formatPhoneNumber(inputData?.phone)}`,
      };
      if (photoFileId) body = { ...body, photoFileId };
      createEmployee({
        body,
        onSuccess: () => {
          onRefreshData(true);
          toggleModal({ data: {} });
          clearForm();
        },
      });
    }
  };

  const clearForm = () => {
    clearErrors();
    setPhotoFileId(null);
    setValue("name", null);
    setValue("phone", "");
    setValue("identificationNumber", undefined);
    setValue("province", null);
    setValue("city", null);
    setValue("subdistrict", null);
    setValue("village", null);
    setValue("bank", null);
    setValue("employeeNo", "");
    setValue("bankAccountNumber", "");
    setVersion(1);
  };

  useEffect(() => {
    clearForm();

    if (!isEmptyObject(modal?.data)) {
      setValue("employeeNo", modal?.data?.employeeNo);
      setPhotoFileId(null);
      setValue("name", modal?.data?.name);
      setValue("phone", formatPhoneNumber(modal?.data?.phone));
      setValue("identificationNumber", modal?.data?.identificationNumber);
      setValue("province", modal?.data?.province);
      setValue("city", modal?.data?.city);
      setValue("subdistrict", modal?.data?.subdistrict);
      setValue("village", modal?.data?.village);
      setValue("bank", modal?.data?.bank);
      setValue("bankAccountNumber", modal?.data?.bankAccountNumber);
      setVersion(parseInt(modal?.data?.version));
    }

    dispatch(getProvinces());
    setLocationValues();
  }, [isOpen, modal?.data, isUpdate]);

  const setLocationValues = async () => {
    const location = modal?.data;
    if (!location) return;

    const selectedProvince = area.provinces?.find(
      (item) => item.value === location.province
    );
    if (!selectedProvince) return;
    setValue("province", selectedProvince);

    const regencies = await dispatch(
      getCities({ id: selectedProvince.id })
    ).unwrap();
    if (!regencies) return;
    const selectedRegency = regencies.find(
      (item) => item.name === location.regency
    );
    if (!selectedRegency) return;
    setValue("city", {
      label: selectedRegency.name,
      value: selectedRegency.name,
      id: selectedRegency.id,
    });

    const subdistricts = await dispatch(
      getSubdistricts({ id: selectedRegency.id })
    ).unwrap();
    if (!subdistricts) return;
    const selectedSubdistrict = subdistricts.find(
      (item) => item.name === location.district
    );
    if (!selectedSubdistrict) return;
    setValue("subdistrict", {
      label: selectedSubdistrict.name,
      value: selectedSubdistrict.name,
      id: selectedSubdistrict.id,
    });

    const villages = await dispatch(
      getVillages({ id: selectedSubdistrict.id })
    ).unwrap();
    if (!villages) return;
    const selectedVillage = villages.find(
      (item) => item.name === location.village
    );
    if (!selectedVillage) return;
    setValue("village", {
      label: selectedVillage.name,
      value: selectedVillage.name,
      id: selectedVillage.id,
    });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (!isNil(value?.province)) {
        dispatch(getCities({ id: value?.province?.id }));
      }
      if (!isNil(value?.city)) {
        dispatch(getSubdistricts({ id: value?.city?.id }));
      }
      if (!isNil(value?.subdistrict)) {
        dispatch(getVillages({ id: value?.subdistrict?.id }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={() => toggleModal({ data: {}, isUpdate: false })}
      title={`${isUpdate ? "Ubah" : "Tambah"} Anggota`}
    >
      <ProfilePicture
        data={modal?.data}
        onRefreshData={onRefreshData}
        isEditable={isUpdate}
        performSetPhotoFileId={performSetPhotoFileId}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContainer.Content>
          <Content
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            isUpdate={isUpdate}
          />
        </ModalContainer.Content>
        <ModalContainer.Footer
          toggleModal={() => toggleModal({ data: {}, isUpdate: false })}
          type={isUpdate ? "Update Anggota" : "Simpan Anggota"}
        />
      </form>
    </ModalContainer>
  );
};

export default Modal;
