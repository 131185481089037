export const pemalongan2009 = {
  type: "FeatureCollection",
  name: "pemalongantt09_60ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.323,
        KET: "Sporadik",
        Nm_Pemilik: "Daryo",
        NO_KAPLING: "19",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.863309600655171, -3.825838703714131],
              [114.863747407335936, -3.825449525447725],
              [114.864018241803009, -3.825650247418277],
              [114.864118208096286, -3.82595023789175],
              [114.863794274237875, -3.826029778097091],
              [114.863309600655171, -3.825838703714131],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.051,
        KET: "Sporadik",
        Nm_Pemilik: "Fitriyani",
        NO_KAPLING: "2",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869304057337104, -3.82340527189743],
              [114.869418789464831, -3.822357684936044],
              [114.869943182563134, -3.822072678085007],
              [114.870113076232514, -3.822057230025188],
              [114.870380988145953, -3.822339610149119],
              [114.870344457880535, -3.82273629805127],
              [114.869304057337104, -3.82340527189743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.928,
        KET: "Sertifikat",
        Nm_Pemilik: "Juremi / Mujiati",
        NO_KAPLING: "34",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861856288190864, -3.817736440374019],
              [114.861859115095655, -3.817738303755015],
              [114.86242950634788, -3.817558019823162],
              [114.863203588489327, -3.817807166995287],
              [114.863874289620597, -3.818016726088004],
              [114.864155946821839, -3.818346112773781],
              [114.86435135000221, -3.818495491313154],
              [114.86488296556297, -3.818401302287038],
              [114.864943986811937, -3.818606530930935],
              [114.864844837801641, -3.819266625175885],
              [114.864492878935224, -3.818875792537351],
              [114.863731159065793, -3.818523908208611],
              [114.863573497198828, -3.818477290507064],
              [114.863133267462288, -3.818383743287696],
              [114.862863327052878, -3.81828471235462],
              [114.862789903555367, -3.818257775840548],
              [114.862154623442763, -3.818024713506145],
              [114.862094040537158, -3.818012385336529],
              [114.861947530721366, -3.81798257166419],
              [114.861895103732508, -3.817971903150826],
              [114.861856288190864, -3.817736440374019],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.266,
        KET: "Sertifikat",
        Nm_Pemilik: "Partomo / Norwanto",
        NO_KAPLING: "35",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.859567764450517, -3.817850186755207],
              [114.859712732586274, -3.817810628132845],
              [114.85976341744626, -3.817643728296732],
              [114.859918501800394, -3.817480973703609],
              [114.860160549808853, -3.817376334445421],
              [114.860436661422682, -3.817490037200579],
              [114.860698905968633, -3.817137517660062],
              [114.860847602825544, -3.817071555082988],
              [114.861856288190864, -3.817736440374019],
              [114.861895103732508, -3.817971903150826],
              [114.861727526439097, -3.817937802361811],
              [114.861725629657656, -3.817937639165646],
              [114.8607342795611, -3.817852654122142],
              [114.860646264423849, -3.817845108869224],
              [114.860616750328489, -3.817842578671609],
              [114.860435604447417, -3.817843892578515],
              [114.860336876633269, -3.817844608665556],
              [114.859567764450517, -3.817850186755207],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.699,
        KET: "Sertifikat",
        Nm_Pemilik: "Ramijo / H Gt Rudiyansyah",
        NO_KAPLING: "39",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.85962330927471, -3.816649579185935],
              [114.859496419367915, -3.816781688419109],
              [114.859332641773278, -3.816903111305507],
              [114.858832258227295, -3.816663493150208],
              [114.858684082803819, -3.81712043472126],
              [114.858318584403662, -3.817530924961349],
              [114.858334605157509, -3.818009075607149],
              [114.8581218133845, -3.818197860931258],
              [114.857405233217435, -3.817952403804445],
              [114.857332800124169, -3.817841019011274],
              [114.857558075915861, -3.817650513152046],
              [114.857742326495526, -3.817451693007306],
              [114.858109584761962, -3.817055393343946],
              [114.858120632195707, -3.817045337681915],
              [114.858382783957381, -3.81680672016768],
              [114.858549269860774, -3.816630720636049],
              [114.858636561384216, -3.816538440889858],
              [114.85879749238913, -3.816416609644925],
              [114.858929085001876, -3.8163484877858],
              [114.859201823568, -3.81628560800747],
              [114.859391705304503, -3.816266525168002],
              [114.859589793551677, -3.816268750435859],
              [114.85962330927471, -3.816649579185935],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.574,
        KET: "Sertifikat",
        Nm_Pemilik: "Suparno / Tri Mulyani",
        NO_KAPLING: "42",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.857880680732961, -3.8168500494004],
              [114.857893145125317, -3.816841244561954],
              [114.857535420174386, -3.816490744682717],
              [114.857592249601026, -3.816280514020525],
              [114.857671682996795, -3.816085417377742],
              [114.85813998021365, -3.816376401821119],
              [114.858549269860774, -3.816630720636049],
              [114.858382783957381, -3.81680672016768],
              [114.858120632195707, -3.817045337681915],
              [114.857880680732961, -3.8168500494004],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.921,
        KET: "Sertifikat",
        Nm_Pemilik: "Suparno / Tri Mulyani",
        NO_KAPLING: "40",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.858456826921937, -3.815517035626354],
              [114.858783277427975, -3.815629436890974],
              [114.859311614881292, -3.816136560067831],
              [114.859582276437592, -3.81618333590425],
              [114.859589793551677, -3.816268750435859],
              [114.859391705304503, -3.816266525168002],
              [114.859201823568, -3.81628560800747],
              [114.858929085001876, -3.8163484877858],
              [114.85879749238913, -3.816416609644925],
              [114.858636561384216, -3.816538440889858],
              [114.858549269860774, -3.816630720636049],
              [114.85813998021365, -3.816376401821119],
              [114.858456826921937, -3.815517035626354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.441,
        KET: "Sertifikat",
        Nm_Pemilik: "Suparno / Tri Mulyani",
        NO_KAPLING: "41",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.857671682996795, -3.816085417377742],
              [114.857737214406527, -3.815924465325969],
              [114.857984449858876, -3.81564102933438],
              [114.858349554594128, -3.815480100304927],
              [114.858456826921937, -3.815517035626354],
              [114.85813998021365, -3.816376401821119],
              [114.857671682996795, -3.816085417377742],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.746,
        KET: "Sertifikat",
        Nm_Pemilik: "Fajar Sodik / Sulanjari",
        NO_KAPLING: "6",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.866025283634215, -3.825603998612328],
              [114.865961487267271, -3.825550659601366],
              [114.865777254822191, -3.825734796908566],
              [114.865560447104315, -3.825753188428966],
              [114.865282568439397, -3.8256956950675],
              [114.865032986440212, -3.825628805805951],
              [114.864801631981308, -3.825812825040829],
              [114.864452718912332, -3.825897151812833],
              [114.864660758667, -3.825613675925602],
              [114.864797963069634, -3.825391555359807],
              [114.864963639741305, -3.825089040362735],
              [114.865127429859925, -3.824939405022552],
              [114.866237520083189, -3.824568207445915],
              [114.86648280586293, -3.82442478057873],
              [114.866757076487772, -3.824156675435005],
              [114.866788144866334, -3.824202429225296],
              [114.866794226994273, -3.824211386255139],
              [114.866801597047626, -3.824471677367432],
              [114.866777376388583, -3.824544063964804],
              [114.86665955495252, -3.824659148521786],
              [114.866626995183523, -3.824862992246523],
              [114.866509153755331, -3.824986126366616],
              [114.865971875798678, -3.825126995755476],
              [114.865926397739102, -3.825153714416082],
              [114.865907426541654, -3.825263679157186],
              [114.866025283634215, -3.825603998612328],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.695,
        KET: "Sporadik",
        Nm_Pemilik: "Ngatno",
        NO_KAPLING: "28",
        block: "Blok 2",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86642092812437, -3.822271473379661],
              [114.866423745623848, -3.822271731970764],
              [114.86637226833885, -3.821989386148146],
              [114.866569194898872, -3.821806516386939],
              [114.86653550553099, -3.821283754702112],
              [114.866928313211275, -3.821375281160249],
              [114.86734984638899, -3.822459709583438],
              [114.867649603905903, -3.823183503953449],
              [114.866883955497443, -3.823186800803008],
              [114.866745651949898, -3.823222080846927],
              [114.86642092812437, -3.822271473379661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.606,
        KET: "Sertifikat",
        Nm_Pemilik: "Partomo / Norwanto",
        NO_KAPLING: "38",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.859652721792358, -3.818767397281881],
              [114.859602059838608, -3.818777551500549],
              [114.859142097443083, -3.818690945892862],
              [114.858898966945262, -3.818492236702832],
              [114.858771894904862, -3.818274396105024],
              [114.858838192430881, -3.818049270456406],
              [114.859406164395054, -3.817894283825372],
              [114.859652721792358, -3.818767397281881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.202,
        KET: "Sertifikat",
        Nm_Pemilik: "Ramijo / H Gt Rudiyansyah",
        NO_KAPLING: "44",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.857332800124169, -3.817841019011274],
              [114.857309822309688, -3.817805684618371],
              [114.857215645097952, -3.817605122503382],
              [114.857514964565055, -3.817185138187825],
              [114.857742326495526, -3.817451693007306],
              [114.857558075915861, -3.817650513152046],
              [114.857332800124169, -3.817841019011274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.224,
        KET: "Sertifikat",
        Nm_Pemilik: "Suparno / Tri Mulyani",
        NO_KAPLING: "43",
        block: "Blok 4",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.857514964565055, -3.817185138187825],
              [114.85762511756343, -3.817030578949428],
              [114.857880680732961, -3.8168500494004],
              [114.858120632195707, -3.817045337681915],
              [114.858109584761962, -3.817055393343946],
              [114.857742326495526, -3.817451693007306],
              [114.857514964565055, -3.817185138187825],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.813,
        KET: "Sertifikat",
        Nm_Pemilik: "Mulyati / Pairin",
        NO_KAPLING: "15",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861664050822029, -3.826237540374971],
              [114.861512656708442, -3.826402943915091],
              [114.861351131938321, -3.826708883356856],
              [114.861188810021858, -3.827014934423608],
              [114.860976940263512, -3.827091948997541],
              [114.860707712386599, -3.82720133874968],
              [114.860548275957811, -3.827223452500573],
              [114.860406671971802, -3.827169595880965],
              [114.86058207573862, -3.826987178967098],
              [114.860720106299894, -3.826621812096803],
              [114.860664356014539, -3.826222713330965],
              [114.860782447388644, -3.826270504164322],
              [114.861135419234927, -3.825225589951521],
              [114.861193427243435, -3.825196637641952],
              [114.861195219353746, -3.825191693300247],
              [114.861921221643669, -3.825304524846331],
              [114.861851191000881, -3.825442965278048],
              [114.861664050822029, -3.826237540374971],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.997,
        KET: "Sertifikat",
        Nm_Pemilik: "Sukiran / Farida T",
        NO_KAPLING: "16",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861195219353746, -3.825191693300247],
              [114.861444998377252, -3.824502564527601],
              [114.861593118119032, -3.824453892826879],
              [114.861660493163512, -3.824284325728228],
              [114.861858458243532, -3.824234446987486],
              [114.861944275239708, -3.82427542790109],
              [114.86225687109507, -3.82421856362507],
              [114.862427660648265, -3.824303365902778],
              [114.862226571666866, -3.824700891150098],
              [114.861921221643669, -3.825304524846331],
              [114.861195219353746, -3.825191693300247],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.963,
        KET: "Sertifikat",
        Nm_Pemilik: "Sukiran / Farida T",
        NO_KAPLING: "18",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862226571666866, -3.824700891150098],
              [114.862427660648265, -3.824303365902778],
              [114.862438381718292, -3.824308689243201],
              [114.862505899467976, -3.824081476232105],
              [114.862423630783141, -3.823828268193862],
              [114.862507034347615, -3.823623512808617],
              [114.862765365536916, -3.823592133017398],
              [114.862816052928338, -3.823723564764307],
              [114.862799743911339, -3.824305907522442],
              [114.862748509244014, -3.824524010818767],
              [114.862835070496658, -3.824662440481984],
              [114.862645201284337, -3.825298473896654],
              [114.862720962653185, -3.825411416262516],
              [114.862722304187699, -3.825529575180652],
              [114.862425474565327, -3.825656935157938],
              [114.862196299527326, -3.825772891431682],
              [114.862443745256982, -3.824748510507943],
              [114.862226571666866, -3.824700891150098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.58,
        KET: "Sertifikat",
        Nm_Pemilik: "Lasrum",
        NO_KAPLING: "17",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861716369090132, -3.826180380746954],
              [114.861664050822029, -3.826237540374971],
              [114.861851191000881, -3.825442965278048],
              [114.861921221643669, -3.825304524846331],
              [114.862226571666866, -3.824700891150098],
              [114.862443745256982, -3.824748510507943],
              [114.862196299527326, -3.825772891431682],
              [114.862192434753069, -3.8257748469003],
              [114.861716369090132, -3.826180380746954],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.999,
        KET: "Sertifikat",
        Nm_Pemilik: "Sarmin",
        NO_KAPLING: "1",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868323508337895, -3.823251403575655],
              [114.868407327726715, -3.822638688988036],
              [114.86856740936976, -3.822174406361876],
              [114.868805964902975, -3.822112283059794],
              [114.868981399283797, -3.822326530812604],
              [114.869120487501561, -3.82231832494544],
              [114.869165573337483, -3.822449574476538],
              [114.86941418299115, -3.822363096369232],
              [114.869297003850605, -3.823419470682931],
              [114.869297496468093, -3.82341915205884],
              [114.869275557982306, -3.823531369239522],
              [114.869251673901871, -3.823715714292267],
              [114.869953355648093, -3.824412359975496],
              [114.869794828117392, -3.824564074708154],
              [114.8691914198576, -3.824173731991551],
              [114.868856708593441, -3.823748344576945],
              [114.868323508337895, -3.823251403575655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.5,
        KET: "Sertifikat",
        Nm_Pemilik: "Wasiman / Juriyanto",
        NO_KAPLING: "23",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.863273455320268, -3.82429734901624],
              [114.863186312876209, -3.823986751807097],
              [114.863272050921793, -3.823625458012505],
              [114.863906360490688, -3.823517237039001],
              [114.864029806743659, -3.823109066955722],
              [114.86428471296739, -3.822897504351065],
              [114.864480198059795, -3.82271633688994],
              [114.864506580084736, -3.822847854671314],
              [114.864687435525482, -3.824001634432303],
              [114.863273455320268, -3.82429734901624],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.5,
        KET: "Sertifikat",
        Nm_Pemilik: "Miskal / Farida T",
        NO_KAPLING: "22",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86369054009576, -3.825339574798643],
              [114.863611826402234, -3.825194738251386],
              [114.863837139715542, -3.824605044539551],
              [114.86362572422756, -3.824307403348677],
              [114.863285316325289, -3.824339994123324],
              [114.863273457472786, -3.824297350583552],
              [114.864687435565884, -3.824001634689983],
              [114.864687450390122, -3.824001729262287],
              [114.8647924703603, -3.82467171254053],
              [114.864840282039609, -3.825099612025184],
              [114.86369054009576, -3.825339574798643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.207,
        KET: "Sertifikat",
        Nm_Pemilik: "Lasrum",
        NO_KAPLING: "20",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.864697632250937, -3.825453586612302],
              [114.864604212406192, -3.825613534619269],
              [114.864434057755091, -3.825821372963209],
              [114.864125687437379, -3.825945272005242],
              [114.864025303919775, -3.82564905114906],
              [114.863983299610794, -3.82561772371669],
              [114.864697632250937, -3.825453586612302],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.4,
        KET: "Sertifikat",
        Nm_Pemilik: "Daryo",
        NO_KAPLING: "21",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.864769842325003, -3.825329952662838],
              [114.864697632250937, -3.825453586612302],
              [114.863983297172965, -3.825617721255733],
              [114.863748548901668, -3.825446313455801],
              [114.86369054009576, -3.825339574798643],
              [114.864840282122103, -3.825099612763128],
              [114.864840984723017, -3.82510590082492],
              [114.864850072823103, -3.82518723653539],
              [114.864769842325003, -3.825329952662838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.61,
        KET: "Sertifikat",
        Nm_Pemilik: "Gangsar",
        NO_KAPLING: "14",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862249501444253, -3.828868369598065],
              [114.862174319454894, -3.828832601752812],
              [114.861972947628161, -3.828758989140721],
              [114.86187249204059, -3.828629392015408],
              [114.861659643739841, -3.828668225022855],
              [114.861288062552177, -3.827244493624313],
              [114.861246288583345, -3.827038712201739],
              [114.861562222146304, -3.826436314597456],
              [114.861751506517763, -3.826224057499318],
              [114.861816250873687, -3.826169525949342],
              [114.861861367999424, -3.826215070934317],
              [114.861897403804463, -3.826306220668586],
              [114.861878745936892, -3.826518646260495],
              [114.861799546468163, -3.826773414767305],
              [114.861768725965632, -3.827016163071586],
              [114.861767852373134, -3.827368257783951],
              [114.861797829464194, -3.82746546301002],
              [114.86204469910713, -3.827836389877396],
              [114.862285721094977, -3.82812838385508],
              [114.862249501444253, -3.828868369598065],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 2.001,
        KET: "Sporadik",
        Nm_Pemilik: "Yanun",
        NO_KAPLING: "13",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862510889673487, -3.825689451965271],
              [114.862744753335633, -3.825703661140039],
              [114.862393050661964, -3.826582380651449],
              [114.862304873789114, -3.82680268762285],
              [114.862121149970406, -3.827405769717752],
              [114.862693284842891, -3.828117725666162],
              [114.862671381432449, -3.828139175181507],
              [114.862852948453195, -3.828356284184598],
              [114.862762769320085, -3.828597878592883],
              [114.862689804800809, -3.828670804356873],
              [114.862728465524995, -3.828884602622924],
              [114.862654182849269, -3.829160516636684],
              [114.862497681342447, -3.829160124809357],
              [114.862269385752782, -3.828877829560244],
              [114.862249501444253, -3.828868369598065],
              [114.862285721094977, -3.82812838385508],
              [114.86204469910713, -3.827836389877396],
              [114.861797829464194, -3.82746546301002],
              [114.861767852373134, -3.827368257783951],
              [114.861768725965632, -3.827016163071586],
              [114.861799546468163, -3.826773414767305],
              [114.861878745936892, -3.826518646260495],
              [114.861897403804463, -3.826306220668586],
              [114.861861367999424, -3.826215070934317],
              [114.861816250873687, -3.826169525949342],
              [114.862233675884511, -3.825817945381555],
              [114.862510889673487, -3.825689451965271],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.79,
        KET: "Sporadik",
        Nm_Pemilik: "Agus Supriyadi / Dewi S.",
        NO_KAPLING: "27",
        block: "Blok 2",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.865663106101323, -3.822200419294237],
              [114.865664720584618, -3.822202068058911],
              [114.86642092812437, -3.822271473379661],
              [114.866745646090962, -3.823222081706366],
              [114.866542953274958, -3.823273799510271],
              [114.866545081318108, -3.823285025739351],
              [114.866402151429725, -3.823388894979939],
              [114.866401469501341, -3.823387894620758],
              [114.865718123121908, -3.822385454543471],
              [114.865663106101323, -3.822200419294237],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.541,
        KET: "Sporadik",
        Nm_Pemilik: "Sudiono",
        NO_KAPLING: "12",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86366717515844, -3.827111159900736],
              [114.86363440806025, -3.827196102832011],
              [114.862693284842891, -3.828117725666162],
              [114.862121149970406, -3.827405769717752],
              [114.862304873789114, -3.82680268762285],
              [114.86238852114657, -3.826593707733611],
              [114.86366717515844, -3.827111159900736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.541,
        KET: "Sporadik",
        Nm_Pemilik: "Anton Triyono",
        NO_KAPLING: "11",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862744753335633, -3.825703661140039],
              [114.862783312113137, -3.825706003902495],
              [114.86370059527674, -3.826059634995904],
              [114.863829741474575, -3.826689734882322],
              [114.86366717515844, -3.827111159900736],
              [114.862388523226983, -3.826593704615901],
              [114.862744753335633, -3.825703661140039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 3.286,
        KET: "Sporadik",
        Nm_Pemilik: "Priyanto",
        NO_KAPLING: "7",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.868467663673641, -3.825456123827344],
              [114.868342513506676, -3.825574956164771],
              [114.868078725610388, -3.825872775924494],
              [114.86783809014257, -3.826000160788182],
              [114.867825139738486, -3.826058018707077],
              [114.867748010019724, -3.826173606692493],
              [114.867471317838209, -3.826261268463274],
              [114.866188243766686, -3.825740246654078],
              [114.866025283634215, -3.825603998612328],
              [114.865907426541654, -3.825263679157186],
              [114.865926397739102, -3.825153714416082],
              [114.865971875798678, -3.825126995755476],
              [114.866509153755331, -3.824986126366616],
              [114.866626995183523, -3.824862992246523],
              [114.86665955495252, -3.824659148521786],
              [114.866777376388583, -3.824544063964804],
              [114.866801597047626, -3.824471677367432],
              [114.866794226994273, -3.824211386255139],
              [114.866788144866334, -3.824202429225296],
              [114.868467663673641, -3.825456123827344],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.014,
        KET: "Sporadik",
        Nm_Pemilik: "Daryo",
        NO_KAPLING: "9",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.867618407383461, -3.826321010159018],
              [114.867940391837848, -3.826599450440954],
              [114.868089218367501, -3.826540084427085],
              [114.868241775754328, -3.826689808709379],
              [114.868194309348439, -3.826988377425993],
              [114.867959203818515, -3.827580897925805],
              [114.867695819159181, -3.827584507348374],
              [114.867254417057339, -3.827244954510368],
              [114.867518230108672, -3.826280354329782],
              [114.867618407383461, -3.826321010159018],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.014,
        KET: "Sporadik",
        Nm_Pemilik: "Rudiyan",
        NO_KAPLING: "8",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.867254417057339, -3.827244954510368],
              [114.867250627297281, -3.827242039199305],
              [114.867247047113665, -3.827251556945445],
              [114.866569664391875, -3.826678005556505],
              [114.866704572505313, -3.826459822720482],
              [114.866188243766686, -3.825740246654078],
              [114.867518201001218, -3.826280354257129],
              [114.867254417057339, -3.827244954510368],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.332,
        KET: "Sporadik",
        Nm_Pemilik: "Salamah",
        NO_KAPLING: "10",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.867247047113665, -3.827251556945445],
              [114.867199313778059, -3.827378453686316],
              [114.866897095567495, -3.827625182408001],
              [114.866544207976759, -3.827748042653654],
              [114.866059802485665, -3.82780230282074],
              [114.865648035249095, -3.827686066124302],
              [114.865589081634653, -3.827476838130729],
              [114.866065804442655, -3.827094003523502],
              [114.866372471113721, -3.826766214098683],
              [114.866525439976712, -3.82674952832965],
              [114.866569664391875, -3.826678005556505],
              [114.867247047113665, -3.827251556945445],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 3.216,
        KET: "Sporadik",
        Nm_Pemilik: "Rudi / Juariyah",
        NO_KAPLING: "4",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.867491416723965, -3.823769029656558],
              [114.867492650914414, -3.823770038333932],
              [114.867742572359248, -3.823699662601663],
              [114.868099283057433, -3.824273278399871],
              [114.86821629226425, -3.824595432901524],
              [114.868664193359805, -3.824890260131622],
              [114.869227533331753, -3.825050828386579],
              [114.869927778142753, -3.824498074347309],
              [114.870212545358143, -3.824744138761187],
              [114.869592086618908, -3.825501680588905],
              [114.869218556761936, -3.826216985481592],
              [114.868492677126397, -3.825474795347376],
              [114.86847116471003, -3.825452799529266],
              [114.868467663673641, -3.825456123827344],
              [114.866788144866334, -3.824202429225296],
              [114.866757076487772, -3.824156675435005],
              [114.866757910550646, -3.824155860120949],
              [114.866757407780369, -3.824153122921475],
              [114.867491416723965, -3.823769029656558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.645,
        KET: "Sertifikat",
        Nm_Pemilik: "Panut /Agus S",
        NO_KAPLING: "5",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86660317157795, -3.823313425236037],
              [114.866904938507105, -3.823238445802623],
              [114.867300750182409, -3.823244166433818],
              [114.867393893925353, -3.823689326358477],
              [114.867491416723965, -3.823769029656558],
              [114.866757407780369, -3.824153122921475],
              [114.86660317157795, -3.823313425236037],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 3.588,
        KET: "Sertifikat",
        Nm_Pemilik: "Warto / Riyanto / Mursidah",
        NO_KAPLING: "26",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8645116169441, -3.82252984125116],
              [114.864516684516303, -3.822499761228196],
              [114.864404810341654, -3.821765977269206],
              [114.864655312889013, -3.821257102380398],
              [114.865073861047264, -3.821754470704364],
              [114.86521818083645, -3.82174604641325],
              [114.865663106101323, -3.822200419294237],
              [114.865718123121908, -3.822385454543471],
              [114.866401469501341, -3.823387894620758],
              [114.86613992973156, -3.823735067092687],
              [114.86618026812917, -3.824129404056732],
              [114.86565933552933, -3.824281648163467],
              [114.865583271190445, -3.824236114608341],
              [114.865547952862414, -3.824214972384757],
              [114.8645116169441, -3.82252984125116],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.559,
        KET: "Sertifikat",
        Nm_Pemilik: "Sahrul / Eko Widodo",
        NO_KAPLING: "24",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.864480198059795, -3.82271633688994],
              [114.8645116169441, -3.82252984125116],
              [114.865547952862414, -3.824214972384757],
              [114.865547955797069, -3.824214974305545],
              [114.865539655381312, -3.824751669611364],
              [114.865110197995847, -3.824895342611839],
              [114.864921300382477, -3.825060535000832],
              [114.864850072823103, -3.82518723653539],
              [114.864840984723017, -3.82510590082492],
              [114.8647924703603, -3.82467171254053],
              [114.864687450390122, -3.824001729262287],
              [114.864506580084736, -3.822847854671314],
              [114.864480198059795, -3.82271633688994],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.885,
        KET: "Sertifikat",
        Nm_Pemilik: "Panut /Agus S",
        NO_KAPLING: "25",
        block: "Blok 2",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.865547955872074, -3.82421497438129],
              [114.86565933552933, -3.824281648163467],
              [114.86618026812917, -3.824129404056732],
              [114.86613992973156, -3.823735067092687],
              [114.866401469501341, -3.823387894620758],
              [114.866402151429725, -3.823388894979939],
              [114.866545081318108, -3.823285025739351],
              [114.866706686912948, -3.824137556532482],
              [114.866419511533664, -3.824409476131245],
              [114.866183964364225, -3.824536118413781],
              [114.865539655381312, -3.824751669611364],
              [114.865547955872074, -3.82421497438129],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.609,
        KET: "Sertifikat",
        Nm_Pemilik: "Rudiyono / Heriyanto",
        NO_KAPLING: "36",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861100770907143, -3.820396583585866],
              [114.860881741649408, -3.820133718753968],
              [114.860831928430557, -3.820073935642743],
              [114.860814295153077, -3.820071006804011],
              [114.860831955386189, -3.818468781466826],
              [114.860438014582627, -3.817843879922788],
              [114.860616759748183, -3.817842581726874],
              [114.861725629657656, -3.817937639165646],
              [114.861156426050741, -3.819387103485602],
              [114.861132423979029, -3.819822457891578],
              [114.861100770907143, -3.820396583585866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.666,
        KET: "Sertifikat",
        Nm_Pemilik: "Sungkono",
        NO_KAPLING: "33",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862863327052878, -3.81828471235462],
              [114.863133267462288, -3.818383743287696],
              [114.863573497198828, -3.818477290507064],
              [114.863731159065793, -3.818523908208611],
              [114.864492878935224, -3.818875792537351],
              [114.864844837801641, -3.819266625175885],
              [114.864803486933113, -3.819541920770352],
              [114.864478747917389, -3.81960824116239],
              [114.863763791266351, -3.818907839494031],
              [114.86372321323654, -3.818896185786751],
              [114.863153451166212, -3.818732548374248],
              [114.862902218423656, -3.818962431358785],
              [114.862737091869036, -3.819002449202789],
              [114.862322871460151, -3.819102833895846],
              [114.861986302092618, -3.819577195307406],
              [114.861876136427924, -3.819600833579269],
              [114.861724905612235, -3.819633281238964],
              [114.862164702942081, -3.818818736617393],
              [114.862736996959399, -3.818674767876934],
              [114.862863327052878, -3.81828471235462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.609,
        KET: "Sertifikat",
        Nm_Pemilik: "Dedi Suratman / Rhonda R",
        NO_KAPLING: "37",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.860814295153077, -3.820071006804011],
              [114.860711717605511, -3.820053970359541],
              [114.860563671805352, -3.819980559522017],
              [114.860386316798142, -3.819319940430467],
              [114.860444818237468, -3.818880651141356],
              [114.859967416209173, -3.818028787659864],
              [114.859750661663981, -3.818109816952775],
              [114.859965741754195, -3.818704658343851],
              [114.859652721792358, -3.818767397281881],
              [114.859406164395054, -3.817894283825372],
              [114.859567764450517, -3.817850186755207],
              [114.860336876633269, -3.817844608665556],
              [114.86043800686285, -3.817843878351843],
              [114.860831954768614, -3.818468781029376],
              [114.860814295153077, -3.820071006804011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.299,
        KET: "Sporadik",
        Nm_Pemilik: "Ilmanto",
        NO_KAPLING: "30",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861876136427924, -3.819600833579269],
              [114.861986302092618, -3.819577195307406],
              [114.862322871460151, -3.819102833895846],
              [114.862737091869036, -3.819002449202789],
              [114.862657319466237, -3.82041865913389],
              [114.862587542538407, -3.820408575859471],
              [114.862556706338481, -3.820404119793676],
              [114.861795989293825, -3.820614428940423],
              [114.861856939953029, -3.819843606293472],
              [114.861876136427924, -3.819600833579269],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 0.699,
        KET: "Sporadik",
        Nm_Pemilik: "Heriyanto",
        NO_KAPLING: "31",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861100770907143, -3.820396583585866],
              [114.861132423979029, -3.819822457891578],
              [114.861856939953029, -3.819843606293472],
              [114.861795989293825, -3.820614428940423],
              [114.861378467184792, -3.820729857443595],
              [114.861100770907143, -3.820396583585866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.999,
        KET: "Sporadik",
        Nm_Pemilik: "H. Tukiman",
        NO_KAPLING: "32",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.861132423979029, -3.819822457891578],
              [114.861156426050741, -3.819387103485602],
              [114.861725629657656, -3.817937639165646],
              [114.861727526439097, -3.817937802361811],
              [114.862154623442763, -3.818024713506145],
              [114.862863327052878, -3.81828471235462],
              [114.862736996959399, -3.818674767876934],
              [114.862164700409394, -3.81881873431428],
              [114.861724904425785, -3.819633283436398],
              [114.861876136427924, -3.819600833579269],
              [114.861856939953029, -3.819843606293472],
              [114.861132423979029, -3.819822457891578],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.869,
        KET: "Sporadik",
        Nm_Pemilik: "J. Silaban",
        NO_KAPLING: "29b",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.862737091869036, -3.819002449202789],
              [114.862902218423656, -3.818962431358785],
              [114.863153455099081, -3.818732555300786],
              [114.86372321323654, -3.818896185786751],
              [114.863529763312044, -3.820529129889528],
              [114.863482874561299, -3.8205379589808],
              [114.86265731962277, -3.820418659056835],
              [114.862737091869036, -3.819002449202789],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.268,
        KET: "Sporadik",
        Nm_Pemilik: "J. Silaban",
        NO_KAPLING: "29a",
        block: "Blok 3",
        KAWASAN: "Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.86372321323654, -3.818896185786751],
              [114.863763791266351, -3.818907839494031],
              [114.864478729141851, -3.819608672848817],
              [114.864803486933113, -3.819541920770352],
              [114.864779060077623, -3.819704541874746],
              [114.864775192580041, -3.819708066766963],
              [114.864284852073069, -3.82007687882356],
              [114.86416066866515, -3.820410330930381],
              [114.863529763312044, -3.820529129889528],
              [114.86372321323654, -3.818896185786751],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        Luas: 1.951,
        KET: "Sertifikat",
        Nm_Pemilik: "H. Tukiman",
        NO_KAPLING: "3",
        block: "Blok 1",
        KAWASAN: "Bebas Kawasan",
        TANAM: "2009",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.869297496468093, -3.82341915205884],
              [114.869839476115587, -3.823068600185008],
              [114.870439358417073, -3.822696856497062],
              [114.871006431293196, -3.823404535146792],
              [114.869953358106542, -3.824412359141935],
              [114.869251678583709, -3.823715713662347],
              [114.869275959274674, -3.823531161359005],
              [114.869275557982306, -3.823531369239522],
              [114.869297496468093, -3.82341915205884],
            ],
          ],
        ],
      },
    },
  ],
};
