export const COOKIES_OPTIONS = {
  secure: true,
  sameSite: "strict",
  path: "/",
};

export const CODE_SECURE = "pL%CU7qAX%y&dDTP*hII8eL!w";
export const GRANT_TYPE_REFRESH_TOKEN = "refresh_token";
export const GRANT_TYPE_AUTHORIZATION_CODE = "authorization_code";
export const GRANT_TYPE_PASSWORD = "password";
export const CODE_VERIFIER =
  "c3cxd2UzNHJmZGUzNHJneWh1NzhpazFxd2U0cmZkZXI1Nnl1N3lnZnJ0NmpraW85NHJkc3dlcg";
