export const headersData = [
	{
		value: "Januari",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Februari",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Maret",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "April",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Mei",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Juni",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Juli",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Agustus",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "September",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Oktober",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "November",
		accessorKey: "month",
		type: "alphabet",
	},
	{
		value: "Desember",
		accessorKey: "month",
		type: "alphabet",
	},
];
