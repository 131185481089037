import ChartSection from "../ChartSection/ChartSection";
import DataSection from "./partials/DataSection/DataSection";
import React from "react";

const RecapSection = () => {
  return (
    <div className="mx-8 grid max-h-full grid-cols-1 grid-rows-[max-content,max-content] gap-8 md:mx-0 md:grid-cols-[minmax(0px,1fr)_minmax(0px,3fr)] md:grid-rows-1 lg:grid-cols-[minmax(0px,1fr)_minmax(0px,3fr)]">
      <DataSection />
      <ChartSection />
    </div>
  );
};

export default RecapSection;
