import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import {
  getGenericRecordApi,
  getGenericRecordAttachmentsApi,
  getGenericRecordsApi,
  updateGenericRecordApi,
} from "./genericRecordsApi";

export const useGenericRecordsStore = create((set, get) => ({
  generics: [],
  generic: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getGenericRecords: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getGenericRecordsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan List Catatan",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ generics: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(error.data);
      return notification.error({
        message: "Gagal Mendapatkan List Catatan",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getGenericRecord: async ({ id, params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getGenericRecordApi(id, params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Catatan",
          description: data.message,
          placement: "bottomRight",
        });
      }
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(error.data);
      return notification.error({
        message: "Gagal Mendapatkan Catatan",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getGenericRecordAttachment: async ({
    id,
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getGenericRecordAttachmentsApi(id, params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Foto-foto pada Catatan",
          description: data.message,
          placement: "bottomRight",
        });
      }
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(error.data);
      return notification.error({
        message: "Gagal Mendapatkan Foto-foto pada Catatan",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateGenericRecord: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateGenericRecordApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Catatan",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Catatan`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(error.data);
      return notification.error({
        message: "Gagal Update Catatan",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
