import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { WORKER_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	workers: [],
	isLoading: false,
	isError: false,
	errors: null,
};

export const getWorkers = createAsyncThunk(
	"worker/workers",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(WORKER_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					fltDeleted: 0,
					pageIndex: data?.page || 0,
					pageSize: 20,
					fltCompany: data.companyId,
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const workerSlice = createSlice({
	name: "worker",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getWorkers.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getWorkers.fulfilled, (state, action) => {
				state.isLoading = false;
				const data = action.payload;
				state.workers = data;
			})
			.addCase(getWorkers.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			});
	},
});

export const {} = workerSlice.actions;
export const selectWorkers = (state) => state.worker.workers;
export const selectStatuses = (state) => ({
	isLoading: state.worker.isLoading,
	isError: state.worker.isError,
	errors: state.worker.errors,
});

export default workerSlice.reducer;
