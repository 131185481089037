import { notification } from "antd";
import { create } from "zustand";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../helpers/Common";
import {
  getVehicleCheckpointsApi,
  updateVehicleCheckpointApi,
} from "./vehicleCheckpointApi";

export const useVehicleCheckpointStore = create((set, get) => ({
  vehicleCheckpoints: [],
  vehicleCheckpoint: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getVehicleCheckpoints: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getVehicleCheckpointsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Data Riwayat Traksi",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ vehicleCheckpoints: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan Data Riwayat Traksi",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateVehicleCheckpoint: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateVehicleCheckpointApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Traksi",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil Update Traksi`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Update Traksi",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
