import { ESTATES_IMPORT_EXCEL_URL, ESTATES_URL } from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import moment from "moment";

const initialState = {
	estates: [],
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getEstates = createAsyncThunk(
	"estate/estates",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(ESTATES_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					...data,
					fltDeleted: 0,
					pageIndex: data?.page || 0,
					pageSize: data?.pageSize || 20,
					fltCompany: data?.companyId,
					sidx: "syncInfo.updatedTime",
					sord: "desc",
				},
			});

			return { data: response.data, type: data.type };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createEstate = createAsyncThunk(
	"estate/createEstate",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(ESTATES_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateEstate = createAsyncThunk(
	"estate/updateEstate",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(ESTATES_URL + data?.id, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const importEstate = createAsyncThunk(
	"estate/importEstate",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const formData = new FormData();
			formData.append("content", data.fileContent);
			formData.append("fileName", data.fileName);
			formData.append("userId", data.userId);
			formData.append("mimeType", null);
			formData.append("id", null);

			const response = await axios.post(
				ESTATES_IMPORT_EXCEL_URL,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: "Bearer " + token?.accessToken,
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteEstate = createAsyncThunk(
	"estate/deleteEstate",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(ESTATES_URL + data?.id, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const estateSlice = createSlice({
	name: "estate",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getEstates.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getEstates.fulfilled, (state, action) => {
				const data = action.payload?.data;
				const type = action.payload?.type;

				if (type == "selects") {
					let newData = [];
					data?.records.forEach((item) => {
						return newData.push({
							value: `${item?.id}`,
							label: `${item?.name}`,
						});
					});

					state.estates = newData;
				} else {
					state.estates = data;
				}
				state.isLoading = false;
			})
			.addCase(getEstates.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(importEstate.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(importEstate.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(importEstate.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(deleteEstate.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(deleteEstate.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(deleteEstate.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(createEstate.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(createEstate.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(createEstate.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(updateEstate.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(updateEstate.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(updateEstate.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			});
	},
});

export const {} = estateSlice.actions;
export const selectEstates = (state) => state.estate.estates;
export const selectStatuses = (state) => ({
	isLoading: state.estate.isLoading,
	isRequest: state.estate.isRequest,
	isError: state.estate.isError,
	errors: state.estate.errors,
});

export default estateSlice.reducer;
