import React, { useEffect, useState } from "react";

import Chart from "../../../../../components/atoms/Chart";
import { options } from "./config/options";
import { useData } from "../../hooks/DataContext";

// import { data } from "../TableSection/api/api/headerData";

const ChartSection = () => {
  const { dataChart } = useData();
  const [labels, setLabels] = useState([]);
  const [yieldProduksi, setYieldProduksi] = useState([]);
  const [potensiYield, setPotensiYield] = useState([]);

  const datasets = [
    {
      label: "Yield Produksi",
      data: yieldProduksi,
      backgroundColor: "#1B99F5",
    },
    {
      label: "Potensi Yield",
      data: potensiYield,
      backgroundColor: "#5A687C",
    },
  ];

  const topLabels = {
    id: "topLabels",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        scales: { x, y },
      } = chart;
      chart.data.datasets[0].data.forEach((datapoint, index) => {
        const datasetArray = [];
        chart.data.datasets.forEach((dataset) => {
          datasetArray.push(dataset.data[index]);
        });

        ctx.font = "bold 12px sans-serif";
        ctx.fillStyle = "rgba(255, 26, 104, 1)";
        ctx.textAlign = "center";
        ctx.fillText(
          "19",
          x.getPixelForValue(0),
          chart.getDatasetMeta(1).data[index].y - 10
        );
      });
    },
  };

  const groupingArray = () => {
    const grouped = groupByYear();

    const yieldProduksi = calculateYieldProduksi(grouped);
    const potensiYield = calculatePotensiYield(grouped);
    const yieldLuas = calculateYieldLuas(grouped);

    const yieldProduksiArray = Object.values(yieldProduksi);
    const yieldLuasArray = Object.values(yieldLuas);
    const potensiYieldArray = Object.values(potensiYield);

    const result = [];
    for (let i = 0; i < yieldProduksiArray.length; i++) {
      const sum = yieldProduksiArray[i] / yieldLuasArray[i];
      result.push(sum);
    }
    setYieldProduksi(result);
    setPotensiYield(potensiYieldArray);
    setLabels(Object.keys(yieldProduksi));
  };

  const groupByYear = () => {
    const grouped = {};

    dataChart.forEach((item) => {
      if (!grouped[item.plantedYear]) {
        grouped[item.plantedYear] = [];
      }
      grouped[item.plantedYear].push(item);
    });

    return grouped;
  };

  const calculateYieldProduksi = (grouped) => {
    const yieldProduksi = {};
    Object.keys(grouped).forEach((year) => {
      const yearData = grouped[year];
      const total = yearData.reduce((acc, cur) => acc + cur?.ytdWeightKg, 0);
      yieldProduksi[year] = total;
    });

    return yieldProduksi;
  };

  const calculatePotensiYield = (grouped) => {
    const potensiYield = {};
    Object.keys(grouped).forEach((year) => {
      const yearData = grouped[year];
      const total = yearData.reduce(
        (acc, cur) => acc + cur?.yearlyTargetYieldKgPerHa,
        0
      );
      potensiYield[year] = total;
    });

    return potensiYield;
  };

  const calculateYieldLuas = (grouped) => {
    const yieldLuas = {};
    Object.keys(grouped).forEach((year) => {
      const yearData = grouped[year];
      const total = yearData.reduce((acc, cur) => acc + cur?.areaHa, 0);
      yieldLuas[year] = total;
    });

    return yieldLuas;
  };

  useEffect(() => {
    groupingArray();
  }, [dataChart]);

  return (
    <div className="h-[250px] rounded-8 bg-white p-2 py-24 shadow-sm md:h-auto md:p-16">
      <Chart.Bar
        // plugins={[topLabels]}
        labels={labels}
        options={options}
        withoutMainDatasets={true}
        moreDatasets={datasets}
      />
    </div>
  );
};

export default ChartSection;
