import { Number, parseToFloat } from "../../../../../helpers/Common";

import InputHook from "../../../../atoms/InputHook";
import React from "react";
import SelectHook from "../../../../atoms/SelectHook";
import { selectArea } from "../../../../../features/areaSlice";
import { useSelector } from "react-redux";

const TphCreateContent = ({ register, control, errors, watchFields }) => {
	const area = useSelector(selectArea);

	return (
		<div className="grid grid-cols-1 gap-16">
			<InputHook
				register={register}
				label="Nama Estate"
				name="name"
				placeholder="Contoh: estate utara"
				errors={errors?.name}
				inputClassName="input-large-border"
			/>
			<SelectHook
				label="Provinsi"
				required
				name="province"
				errors={errors?.province}
				data={area.provinces}
				control={control}
				placeholder="Pilih provinsi"
			/>
			<SelectHook
				label="Kota/Kabupaten"
				required
				name="city"
				errors={errors?.city}
				data={area.cities}
				control={control}
				placeholder="Pilih kota/kabupaten"
			/>
			<SelectHook
				label="Kecamatan"
				required
				name="subdistrict"
				errors={errors?.subdistrict}
				data={area.subdistricts}
				control={control}
				placeholder="Pilih kota/kabupaten"
			/>
			<SelectHook
				label="Kelurahan/Desa"
				required
				name="village"
				errors={errors?.village}
				data={area.villages}
				control={control}
				placeholder="Pilih kelurahan/desa"
			/>
			<InputHook
				register={register}
				label="Deskripsi"
				name="description"
				placeholder=""
				errors={errors?.description}
				inputClassName="input-large-border"
			/>
			<InputHook
				required
				register={register}
				label="Luas (Ha)"
				name="areaHa"
				errors={errors?.areaHa}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.areaHa?.length >= 1 &&
					`(${parseToFloat(watchFields?.areaHa)} Ha)`
				}
			/>
		</div>
	);
};

export default TphCreateContent;
