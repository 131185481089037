import {
  HARVESTINGS_URL,
  HARVESTING_TARGETS_URL,
  PRODUCTION_RECAPS_DAILY,
  PRODUCTION_RECAPS_RECALCULATE,
} from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import moment from "moment";

const initialState = {
  harvestings: [],
  isLoading: false,
  isError: false,
  errors: null,
};

export const getProductionRecapsDailyBlock = createAsyncThunk(
  "harvesting/getProductionRecapsDailyBlock",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${PRODUCTION_RECAPS_DAILY}/daily${data?.type || ""}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const productionRecapsRecalculate = createAsyncThunk(
  "harvesting/productionRecapsRecalculate",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.post(
        PRODUCTION_RECAPS_RECALCULATE,
        {},
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getHarvestigs = createAsyncThunk(
  "harvesting/harvestings",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(HARVESTINGS_URL, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
        params: {
          fltDeleted: 0,
          pageIndex: data?.page || 0,
          sidx: "syncInfo.updatedTime",
          sord: data?.sord || "desc",
          ...data,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateHarvesting = createAsyncThunk(
  "harvesting/updateHarvesting",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.put(`${HARVESTINGS_URL}/${data?.id}`, data, {
        headers: {
          Authorization: "Bearer " + token?.accessToken,
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getHarvestigsRefreshAggregates = createAsyncThunk(
  "harvesting/getHarvestigsRefreshAggregates",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.post(
        `${HARVESTINGS_URL}/cmd/refresh-aggregates`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAggregatesMonthlyInYear = createAsyncThunk(
  "harvesting/getAggregatesMonthlyInYear",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${HARVESTING_TARGETS_URL}/aggregates/${data.type}/monthly-in-year`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: {
            fltDeleted: 0,
            pageIndex: data?.page || 0,
            // sidx: "syncInfo.updatedTime",
            sord: data?.sord || "desc",
            ...data,
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getHarvestingTargetAggregates = createAsyncThunk(
  "harvesting/getHarvestingTargetAggregates",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${HARVESTING_TARGETS_URL}${
          data?.isBlock ? `/${data.type}` : `/aggregates/${data.type}`
        }`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDailyInMonth = createAsyncThunk(
  "harvesting/getDailyInMonth",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${PRODUCTION_RECAPS_DAILY}/daily-in-month/${data.year}/${data.month}/${data.type}/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAggregatesMonthlyInYearTemplate = createAsyncThunk(
  "harvesting/getAggregatesMonthlyInYearTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${HARVESTING_TARGETS_URL}/line-items/templates/${data.budgetYear}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + token?.accessToken,
          },
        }
      );

      const href = URL.createObjectURL(response.data);

      // create "a" HTLM element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", data.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importHarvestingTargets = createAsyncThunk(
  "fileAttachment/importHarvestingTargets",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const formData = new FormData();
      formData.append("content", data.fileContent);
      formData.append("fileName", data.fileName);
      formData.append("userId", data.userId);
      formData.append("mimeType", "application/octet-stream");

      const response = await axios.post(
        `${HARVESTING_TARGETS_URL}/cmd/import`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
          },
        }
      );
      console.log(response.data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductionRecapsDaily = createAsyncThunk(
  "harvesting/getProductionRecapsDaily",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${PRODUCTION_RECAPS_DAILY}/daily${data?.type || ""}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProductionRecapsYield = createAsyncThunk(
  "harvesting/getProductionRecapsYield",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${PRODUCTION_RECAPS_DAILY}/yield/${data?.type}/${data?.id}/${data?.year}/${data?.month}/${data?.dayOfMonth}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProductionRecapsDaily = createAsyncThunk(
  "harvesting/updateProductionRecapsDaily",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.put(
        `${PRODUCTION_RECAPS_DAILY}/daily${data?.type || ""}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: data,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const harvestingSlice = createSlice({
  name: "harvesting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHarvestigs.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getHarvestigs.fulfilled, (state, action) => {
        const data = action.payload;
        state.harvestings = data;
        state.isLoading = false;
      })
      .addCase(getHarvestigs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.info(action.payload);
      })
      .addCase(getHarvestigsRefreshAggregates.fulfilled, () => {})
      .addCase(getDailyInMonth.fulfilled, () => {})
      .addCase(updateHarvesting.fulfilled, () => {})
      .addCase(getAggregatesMonthlyInYear.fulfilled, () => {})
      .addCase(getHarvestingTargetAggregates.fulfilled, () => {})
      .addCase(importHarvestingTargets.fulfilled, () => {})
      .addCase(getProductionRecapsDaily.fulfilled, () => {})
      .addCase(updateProductionRecapsDaily.fulfilled, () => {})
      .addCase(getProductionRecapsYield.fulfilled, () => {})
      .addCase(productionRecapsRecalculate.fulfilled, () => {});
  },
});

export const selectHarvestings = (state) => state.harvesting.harvestings;
export const selectStatuses = (state) => ({
  isLoading: state.harvesting.isLoading,
  isError: state.harvesting.isError,
  errors: state.harvesting.errors,
});

export default harvestingSlice.reducer;
