export const bumiasih2008 = {
  type: "FeatureCollection",
  name: "bumiasihtt08_144ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19645.587,
        Luas: 1.965,
        PERIMETRO: 621.021,
        block: "BLOK 3",
        No_Kav: "60",
        Nm_Pemilik: "Sulaiman",
        KETERANGAN: "laki laki",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748586489791805, -3.99408008375566],
              [114.747232808353658, -3.992587041731732],
              [114.747868024383536, -3.991987246229098],
              [114.748591972534101, -3.992096126068705],
              [114.748586489791805, -3.99408008375566],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19657.132,
        Luas: 1.966,
        PERIMETRO: 698.891,
        block: "BLOK 3",
        No_Kav: "59",
        Nm_Pemilik: "Saminten Istiqomah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749276322810729, -3.994817383735235],
              [114.749234009333875, -3.994794263562428],
              [114.748586489791805, -3.99408008375566],
              [114.748591972534101, -3.992096126068705],
              [114.749283713994529, -3.992200161523185],
              [114.749276322810729, -3.994817383735235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19655.2,
        Luas: 1.966,
        PERIMETRO: 719.104,
        block: "BLOK 3",
        No_Kav: "58",
        Nm_Pemilik: "Suratman",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749879751194115, -3.994937403854292],
              [114.749378544805268, -3.994873238064732],
              [114.749276322810729, -3.994817383735235],
              [114.749283713994529, -3.992200161523185],
              [114.749886963004755, -3.992290887735233],
              [114.749879751194115, -3.994937403854292],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19636.421,
        Luas: 1.964,
        PERIMETRO: 728.727,
        block: "BLOK 3",
        No_Kav: "57",
        Nm_Pemilik: "Wiji Sinta Rirastani",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750517857092277, -3.995019095373263],
              [114.749879751194115, -3.994937403854292],
              [114.749886963004755, -3.992290887735233],
              [114.750424221992645, -3.992371688650703],
              [114.75042069721259, -3.993235222790248],
              [114.750522627991245, -3.993235502994995],
              [114.750517857092277, -3.995019095373263],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19646.865,
        Luas: 1.965,
        PERIMETRO: 735.76,
        block: "BLOK 3",
        No_Kav: "56",
        Nm_Pemilik: "Uswatun Hasanah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751229763192228, -3.995110234188233],
              [114.750517857092277, -3.995019095373263],
              [114.750522627991245, -3.993235502994995],
              [114.750854254937238, -3.993236413812552],
              [114.750852159282275, -3.992434651772775],
              [114.751236849750001, -3.992488211749608],
              [114.751229763192228, -3.995110234188233],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19646.152,
        Luas: 1.965,
        PERIMETRO: 716.139,
        block: "BLOK 3",
        No_Kav: "55",
        Nm_Pemilik: "Wiji Sinta Rirastani",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751840072343413, -3.995188366081218],
              [114.751229763192228, -3.995110234188233],
              [114.751236849750001, -3.992488211749608],
              [114.751847104493123, -3.992573174938489],
              [114.751840072343413, -3.995188366081218],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19620.849,
        Luas: 1.962,
        PERIMETRO: 714.806,
        block: "BLOK 3",
        No_Kav: "54",
        Nm_Pemilik: "Uswatun Hasanah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752451131259591, -3.995266593732669],
              [114.751840072343413, -3.995188366081218],
              [114.751847104493123, -3.992573174938489],
              [114.752458231439249, -3.992658259671149],
              [114.752451131259591, -3.995266593732669],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19665.823,
        Luas: 1.967,
        PERIMETRO: 713.962,
        block: "BLOK 3",
        No_Kav: "53",
        Nm_Pemilik: "Kasdan",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753065249277896, -3.99534521285177],
              [114.752451131259591, -3.995266593732669],
              [114.752458231439249, -3.992658259671149],
              [114.753072328941101, -3.992743757541211],
              [114.753065249277896, -3.99534521285177],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19657.739,
        Luas: 1.966,
        PERIMETRO: 712.746,
        block: "BLOK 3",
        No_Kav: "52",
        Nm_Pemilik: "Moh. Ali",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753680744491732, -3.995424004870479],
              [114.753065249277896, -3.99534521285177],
              [114.753072328941101, -3.992743757541211],
              [114.753687800882489, -3.992829447342206],
              [114.753680744491732, -3.995424004870479],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19659.791,
        Luas: 1.966,
        PERIMETRO: 711.6,
        block: "BLOK 3",
        No_Kav: "51",
        Nm_Pemilik: "Kasdan",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754297928364636, -3.995503017521962],
              [114.753680744491732, -3.995424004870479],
              [114.753687800882489, -3.992829447342206],
              [114.75430499424985, -3.992915374367056],
              [114.754297928364636, -3.995503017521962],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19650.57,
        Luas: 1.965,
        PERIMETRO: 709.323,
        block: "BLOK 3",
        No_Kav: "50",
        Nm_Pemilik: "Moh. Ali",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754620249488696, -3.992959265183966],
              [114.75492426656551, -3.993014681839122],
              [114.754917265399868, -3.995582303216094],
              [114.754297928364636, -3.995503017521962],
              [114.75430499424985, -3.992915374367056],
              [114.754620249488696, -3.992959265183966],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19651.7,
        Luas: 1.965,
        PERIMETRO: 705.281,
        block: "BLOK 3",
        No_Kav: "49",
        Nm_Pemilik: "Subandi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75492426656551, -3.993014681839122],
              [114.755550934347966, -3.99312890988933],
              [114.755543968190992, -3.99566282561599],
              [114.755491598448486, -3.995655827272643],
              [114.754917265399868, -3.995582303216094],
              [114.75492426656551, -3.993014681839122],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19637.905,
        Luas: 1.964,
        PERIMETRO: 699.886,
        block: "BLOK 3",
        No_Kav: "48",
        Nm_Pemilik: "Khoiron",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755550934347966, -3.99312890988933],
              [114.756185084546004, -3.993244501876159],
              [114.75617828125209, -3.995747591504329],
              [114.755543968190992, -3.99566282561599],
              [114.755550934347966, -3.99312890988933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19651.1,
        Luas: 1.965,
        PERIMETRO: 694.898,
        block: "BLOK 3",
        No_Kav: "47",
        Nm_Pemilik: "Subandi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756185084546004, -3.993244501876159],
              [114.756827574754197, -3.99336161392369],
              [114.756820948214369, -3.995833472148626],
              [114.75617828125209, -3.995747591504329],
              [114.756185084546004, -3.993244501876159],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19639.329,
        Luas: 1.964,
        PERIMETRO: 689.701,
        block: "BLOK 3",
        No_Kav: "46",
        Nm_Pemilik: "Ichsanudin",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756827574754197, -3.99336161392369],
              [114.757478144228372, -3.993480197629151],
              [114.757471206563224, -3.995920368377052],
              [114.756820948214369, -3.995833472148626],
              [114.756827574754197, -3.99336161392369],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19636.177,
        Luas: 1.964,
        PERIMETRO: 684.554,
        block: "BLOK 3",
        No_Kav: "45",
        Nm_Pemilik: "Kasmono",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757478144228372, -3.993480197629151],
              [114.758136796358698, -3.993600254181704],
              [114.758130251956217, -3.996008438682658],
              [114.757471206563224, -3.995920368377052],
              [114.757478144228372, -3.993480197629151],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19645.738,
        Luas: 1.965,
        PERIMETRO: 679.495,
        block: "BLOK 3",
        No_Kav: "44",
        Nm_Pemilik: "Muanan",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758136796358698, -3.993600254181704],
              [114.758804809319486, -3.993722016668324],
              [114.758798359366494, -3.996097718646896],
              [114.758130251956217, -3.996008438682658],
              [114.758136796358698, -3.993600254181704],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19521.404,
        Luas: 1.952,
        PERIMETRO: 673.394,
        block: "BLOK 3",
        No_Kav: "43",
        Nm_Pemilik: "Toermiati Agustien",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.758804809319486, -3.993722016668324],
              [114.759477770500368, -3.99384468045887],
              [114.759471410906855, -3.996187658701359],
              [114.758798359366494, -3.996097718646896],
              [114.758804809319486, -3.993722016668324],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19449.834,
        Luas: 1.945,
        PERIMETRO: 667.694,
        block: "BLOK 3",
        No_Kav: "42",
        Nm_Pemilik: "Drs. Sukirman",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759477770500368, -3.99384468045887],
              [114.760157745471432, -3.993968622070715],
              [114.760151477364104, -3.996278535478214],
              [114.759471410906855, -3.996187658701359],
              [114.759477770500368, -3.99384468045887],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19466.297,
        Luas: 1.947,
        PERIMETRO: 662.676,
        block: "BLOK 3",
        No_Kav: "41",
        Nm_Pemilik: "Hari Kusmiran",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760157745471432, -3.993968622070715],
              [114.760848212176768, -3.994094475577064],
              [114.760841983055514, -3.996370806837198],
              [114.760151477364104, -3.996278535478214],
              [114.760157745471432, -3.993968622070715],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19431.892,
        Luas: 1.943,
        PERIMETRO: 657.244,
        block: "BLOK 3",
        No_Kav: "40",
        Nm_Pemilik: "Meri Budianto",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.760848212176768, -3.994094475577064],
              [114.761547714820196, -3.994221975534837],
              [114.761541633564434, -3.996464299678887],
              [114.760841983055514, -3.996370806837198],
              [114.760848212176768, -3.994094475577064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19483.554,
        Luas: 1.948,
        PERIMETRO: 652.504,
        block: "BLOK 3",
        No_Kav: "39",
        Nm_Pemilik: "Ari  Nugrahaeni S",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.761547714820196, -3.994221975534837],
              [114.762259924992435, -3.994351791126213],
              [114.762253939397937, -3.996559483012041],
              [114.761541633564434, -3.996464299678887],
              [114.761547714820196, -3.994221975534837],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19555.731,
        Luas: 1.956,
        PERIMETRO: 644.156,
        block: "BLOK 3",
        No_Kav: "37",
        Nm_Pemilik: "Drs. Sukirman",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.76298611917791, -3.994484154991564],
              [114.763686751590271, -3.99461185910643],
              [114.763756208031666, -3.996760224796916],
              [114.76298023129722, -3.996656534469686],
              [114.76298611917791, -3.994484154991564],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19553.83,
        Luas: 1.955,
        PERIMETRO: 647.912,
        block: "BLOK 3",
        No_Kav: "38",
        Nm_Pemilik: "Toermiati Agustien",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.762259924992435, -3.994351791126213],
              [114.762986119177384, -3.99448415499015],
              [114.762980231066962, -3.996656534617756],
              [114.762253939397937, -3.996559483012041],
              [114.762259924992435, -3.994351791126213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19547.284,
        Luas: 1.955,
        PERIMETRO: 656.683,
        block: "BLOK2",
        No_Kav: "16",
        Nm_Pemilik: "Sumarni",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745685445662886, -3.996705654928991],
              [114.745365935878269, -3.996289230590298],
              [114.744517965751626, -3.995860514382924],
              [114.74447770555588, -3.995557811811521],
              [114.74458332621677, -3.995331159201192],
              [114.745661244798114, -3.994307150759731],
              [114.745691914493719, -3.994337321517414],
              [114.745685445662886, -3.996705654928991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19611.718,
        Luas: 1.961,
        PERIMETRO: 702.015,
        block: "BLOK2",
        No_Kav: "17",
        Nm_Pemilik: "Mustaqim",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74636264433866, -3.997166437703426],
              [114.746155695639729, -3.997137288506517],
              [114.745914100504606, -3.997003665776409],
              [114.745685445662886, -3.996705654928991],
              [114.745691914493719, -3.994337321517414],
              [114.746368552357822, -3.995002951221539],
              [114.746368553618169, -3.995002954439742],
              [114.74636264433866, -3.997166437703426],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19577.807,
        Luas: 1.958,
        PERIMETRO: 633.81,
        block: "BLOK2",
        No_Kav: "18",
        Nm_Pemilik: "Damukri",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747252582148505, -3.997291787398337],
              [114.74636264433866, -3.997166437703426],
              [114.746368553618169, -3.995002954439742],
              [114.747256446456376, -3.995876398700183],
              [114.747252582148505, -3.997291787398337],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19560.158,
        Luas: 1.956,
        PERIMETRO: 708.866,
        block: "BLOK2",
        No_Kav: "19",
        Nm_Pemilik: "Katmidi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748629584250992, -3.997485738530376],
              [114.747252582148505, -3.997291787398337],
              [114.747256446456376, -3.995876398700183],
              [114.747982180855317, -3.9965903235564],
              [114.748635276960187, -3.99587800477938],
              [114.748629584250992, -3.997485738530376],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19541.874,
        Luas: 1.954,
        PERIMETRO: 684.34,
        block: "BLOK2",
        No_Kav: "20",
        Nm_Pemilik: "Heru Utomo",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749392877154165, -3.997593247803056],
              [114.748629584250992, -3.997485738530376],
              [114.748635276960187, -3.99587800477938],
              [114.749260428922497, -3.995196157794228],
              [114.749400013300658, -3.994976297766562],
              [114.749392877154165, -3.997593247803056],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19577.271,
        Luas: 1.958,
        PERIMETRO: 719.469,
        block: "BLOK2",
        No_Kav: "21",
        Nm_Pemilik: "Sulaiman",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74999370225953, -3.997677874313794],
              [114.749392877154165, -3.997593247803056],
              [114.749400013300658, -3.994976297766562],
              [114.749419026530646, -3.994946349965777],
              [114.750000934753587, -3.995025447845073],
              [114.74999370225953, -3.997677874313794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19580.738,
        Luas: 1.958,
        PERIMETRO: 721.754,
        block: "BLOK2",
        No_Kav: "22",
        Nm_Pemilik: "Heru Utomo",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750593650662779, -3.997762375666929],
              [114.74999370225953, -3.997677874313794],
              [114.750000934753587, -3.995025447845073],
              [114.750601288759128, -3.995107053595072],
              [114.750593650662779, -3.997762375666929],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19604.392,
        Luas: 1.96,
        PERIMETRO: 722.422,
        block: "BLOK2",
        No_Kav: "23",
        Nm_Pemilik: "Khoiron",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751194124683906, -3.997846951248793],
              [114.750593650662779, -3.997762375666929],
              [114.750601288759128, -3.995107053595072],
              [114.751201232993296, -3.995188601348465],
              [114.751194124683906, -3.997846951248793],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19597.05,
        Luas: 1.96,
        PERIMETRO: 722.882,
        block: "BLOK2",
        No_Kav: "24",
        Nm_Pemilik: "Saminten Istiqomah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751793413832658, -3.997931353820159],
              [114.751194124683906, -3.997846951248793],
              [114.751201232993296, -3.995188601348465],
              [114.751800583725426, -3.995270068976934],
              [114.751793413832658, -3.997931353820159],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19598.548,
        Luas: 1.96,
        PERIMETRO: 723.389,
        block: "BLOK2",
        No_Kav: "25",
        Nm_Pemilik: "Siti Jumila",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752391963932823, -3.998015660854695],
              [114.751793430075296, -3.997931344942191],
              [114.751800583725426, -3.995270068976934],
              [114.752399458815916, -3.995351471022259],
              [114.752391963932823, -3.998015660854695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19592.919,
        Luas: 1.959,
        PERIMETRO: 723.855,
        block: "BLOK2",
        No_Kav: "26",
        Nm_Pemilik: "Ichsanudin",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75299002055705, -3.998099892880838],
              [114.752391963932823, -3.998015660854695],
              [114.752399458815916, -3.995351471022259],
              [114.752997144072594, -3.995432712313125],
              [114.75299002055705, -3.998099892880838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19604.901,
        Luas: 1.96,
        PERIMETRO: 738.144,
        block: "BLOK2",
        No_Kav: "27",
        Nm_Pemilik: "Siti Jumila",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753590393416687, -3.998184455150967],
              [114.75299002055705, -3.998099892880838],
              [114.752997146846141, -3.995432712760401],
              [114.753607152227303, -3.995515620985602],
              [114.753601139511019, -3.997725352152148],
              [114.753564859182049, -3.997727194234745],
              [114.753469315777849, -3.997779038110444],
              [114.753590393416687, -3.998184455150967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19548.681,
        Luas: 1.955,
        PERIMETRO: 663.189,
        block: "BLOK2",
        No_Kav: "28",
        Nm_Pemilik: "Suratman",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753607156285, -3.995515629515745],
              [114.754315898250852, -3.99561196412576],
              [114.754309569574502, -3.997930760309211],
              [114.753722210863145, -3.99771922215498],
              [114.753601143867655, -3.997725353623851],
              [114.753607156285, -3.995515629515745],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19608.545,
        Luas: 1.961,
        PERIMETRO: 674.011,
        block: "BLOK2",
        No_Kav: "29",
        Nm_Pemilik: "Muhajir",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754315903026551, -3.995611954598859],
              [114.754984220717333, -3.995702802346073],
              [114.754977706882372, -3.998077610234028],
              [114.754877965124493, -3.998135468457406],
              [114.754309569574502, -3.997930760309211],
              [114.754315903026551, -3.995611954598859],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19606.707,
        Luas: 1.961,
        PERIMETRO: 690.002,
        block: "BLOK2",
        No_Kav: "30",
        Nm_Pemilik: "Suyanti",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754984220717333, -3.995702802346073],
              [114.755099513230405, -3.995718472867471],
              [114.755660588259516, -3.995787949349789],
              [114.755653557950964, -3.998237364037905],
              [114.75532252233215, -3.998070004626054],
              [114.75503655947908, -3.998043571011195],
              [114.754977760619369, -3.998077614659138],
              [114.754984220717333, -3.995702802346073],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19620.654,
        Luas: 1.962,
        PERIMETRO: 721.856,
        block: "BLOK2",
        No_Kav: "31",
        Nm_Pemilik: "Muhajir",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755660588259516, -3.995787949349789],
              [114.756474258124996, -3.995888702293858],
              [114.756469705763521, -3.997553086405335],
              [114.756432205811677, -3.997596001202323],
              [114.755762599866372, -3.997773698466928],
              [114.755741666369929, -3.997958304826825],
              [114.755920298710635, -3.998010090493196],
              [114.755920005636227, -3.99811781025168],
              [114.755848147941364, -3.998245851832268],
              [114.755730585481828, -3.998276306515914],
              [114.755653557950964, -3.998237364037905],
              [114.755660588259516, -3.995787949349789],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19651.953,
        Luas: 1.965,
        PERIMETRO: 605.733,
        block: "BLOK2",
        No_Kav: "32",
        Nm_Pemilik: "Suyanti",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756474258124996, -3.995888702293858],
              [114.757776412197586, -3.996049939610114],
              [114.75777353670037, -3.99708855689689],
              [114.757577754629324, -3.997081057412239],
              [114.757184344896828, -3.997121015333868],
              [114.756678145913455, -3.997314550341274],
              [114.756469705763521, -3.997553086405335],
              [114.756474258124996, -3.995888702293858],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19636.877,
        Luas: 1.964,
        PERIMETRO: 577.357,
        block: "BLOK2",
        No_Kav: "33",
        Nm_Pemilik: "Muanan",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.757776412197586, -3.996049939610114],
              [114.759430836564633, -3.996254794218383],
              [114.759428674510019, -3.99705092443781],
              [114.759350599594853, -3.997148965787001],
              [114.75777353670037, -3.99708855689689],
              [114.757776412197586, -3.996049939610114],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19678.809,
        Luas: 1.968,
        PERIMETRO: 899.273,
        block: "BLOK2",
        No_Kav: "34",
        Nm_Pemilik: "Ari  Nugrahaeni S",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.759430836564633, -3.996254794218383],
              [114.760018259412178, -3.996335653676665],
              [114.761435760123092, -3.996531809445706],
              [114.761433325350907, -3.997417161627999],
              [114.761281907604413, -3.997347011220872],
              [114.76095892417699, -3.997309095503327],
              [114.760939805823895, -3.997615102669872],
              [114.76049514092108, -3.99757693324648],
              [114.760355459204831, -3.997332816550156],
              [114.760490043883649, -3.997101298521259],
              [114.760423728256896, -3.996897558191892],
              [114.76005087960047, -3.99722600872665],
              [114.759958983340937, -3.997208269220391],
              [114.759887522789782, -3.996799301719248],
              [114.760012917984625, -3.996345226882337],
              [114.759967628636062, -3.996363151758578],
              [114.759845150592582, -3.996527451928439],
              [114.759839229705491, -3.996535373351194],
              [114.759428675798802, -3.997050921636573],
              [114.759430836564633, -3.996254794218383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19690.832,
        Luas: 1.969,
        PERIMETRO: 723.281,
        block: "BLOK2",
        No_Kav: "36",
        Nm_Pemilik: "Hari Kusmiran",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.763782103643777, -3.997988145721155],
              [114.763703966932511, -3.998337132532273],
              [114.763463924970196, -3.998451599675219],
              [114.763393814858503, -3.998484994569788],
              [114.763339003035583, -3.998518645681238],
              [114.763176920398507, -3.998617890351833],
              [114.762943108580856, -3.998691570108011],
              [114.762716589720398, -3.998752406763137],
              [114.762495325656943, -3.998895937981253],
              [114.762233652402458, -3.999010059537298],
              [114.762119494814357, -3.998938786439256],
              [114.762010926951916, -3.998774343647671],
              [114.761929817020174, -3.998560810933401],
              [114.76190887105507, -3.998281770346848],
              [114.761947635441416, -3.998051915328461],
              [114.761910047081628, -3.997822137208299],
              [114.761750568413504, -3.997617394165952],
              [114.762244806900512, -3.997416583624873],
              [114.762293848035469, -3.997306739468269],
              [114.762397420062015, -3.997319242608282],
              [114.762591724125414, -3.997644492478709],
              [114.762566831946046, -3.997797064887643],
              [114.763016487595394, -3.998318744799629],
              [114.763128951660491, -3.998227368047421],
              [114.763178836825929, -3.997986496869923],
              [114.763782103643777, -3.997988145721155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19683.856,
        Luas: 1.968,
        PERIMETRO: 871.721,
        block: "BLOK2",
        No_Kav: "35",
        Nm_Pemilik: "Meri Budianto",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.761435761327093, -3.996531809448999],
              [114.761561289627167, -3.996549180109422],
              [114.763765597780861, -3.996883865117607],
              [114.763795300533062, -3.997929203745661],
              [114.763782103660205, -3.997988145699648],
              [114.763178837007402, -3.997986495284282],
              [114.763207182521654, -3.997849625112937],
              [114.763201565709537, -3.997480513998125],
              [114.763012953456524, -3.99733914869955],
              [114.762708406585787, -3.997283290877749],
              [114.762727325018517, -3.997050665873926],
              [114.762648387447769, -3.996928108898955],
              [114.762471848418414, -3.996848018611718],
              [114.762123981600936, -3.997049015565466],
              [114.761889982362476, -3.997270690181725],
              [114.761734272403416, -3.997329219349236],
              [114.761595245753654, -3.997492183651508],
              [114.761433327124607, -3.997417162297696],
              [114.761435761327093, -3.996531809448999],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19408.367,
        Luas: 1.941,
        PERIMETRO: 758.799,
        block: "BLOK 1",
        No_Kav: "2",
        Nm_Pemilik: "Suwarno",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.744609968676215, -3.997884618666462],
              [114.74460360312392, -3.997343380021508],
              [114.744672373244995, -3.997210337804281],
              [114.74446589656344, -3.996912078664137],
              [114.744846057480146, -3.996613355075349],
              [114.7443625059515, -3.996060359078268],
              [114.744466446933743, -3.995952394753705],
              [114.7453276482603, -3.996366954376785],
              [114.74547435520148, -3.996559778393143],
              [114.745470109974747, -3.99801958148762],
              [114.744166979766376, -3.998015988620894],
              [114.744609968676215, -3.997884618666462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19840.795,
        Luas: 1.984,
        PERIMETRO: 626.853,
        block: "BLOK 1",
        No_Kav: "1",
        Nm_Pemilik: "Paijah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.745465829727905, -3.999478009545615],
              [114.745370814201621, -3.999415668983328],
              [114.743595244961497, -3.998345155980771],
              [114.743703336475406, -3.998153482048219],
              [114.744166973190417, -3.998015989930288],
              [114.74547010997621, -3.99801958330496],
              [114.745465829727905, -3.999478009545615],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19676.594,
        Luas: 1.968,
        PERIMETRO: 777.088,
        block: "BLOK 1",
        No_Kav: "3",
        Nm_Pemilik: "Rizki Noviani",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746059619652641, -3.999754976497184],
              [114.745938878794036, -3.999703424712437],
              [114.745466487935019, -3.999478674193677],
              [114.74547435520148, -3.996559778393143],
              [114.745863823920445, -3.997071674702052],
              [114.746065986927618, -3.997180317329854],
              [114.746059619652641, -3.999754976497184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 8277.334,
        Luas: 0.828,
        PERIMETRO: 612.01,
        block: "BLOK 1",
        No_Kav: "5a",
        Nm_Pemilik: "Mustaqim",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746678256874148, -3.997293422868069],
              [114.747216122040825, -3.997375267496786],
              [114.746671551248724, -3.999796930192125],
              [114.746678256874148, -3.997293422868069],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19661.315,
        Luas: 1.966,
        PERIMETRO: 716.35,
        block: "BLOK 1",
        No_Kav: "4",
        Nm_Pemilik: "Sumarni",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.746631742030203, -3.999953270290258],
              [114.746418627562264, -3.999887893720459],
              [114.746059842801799, -3.999755051880979],
              [114.746065986927618, -3.997180317329854],
              [114.746117744064989, -3.997208134348083],
              [114.746678256592148, -3.997293422772795],
              [114.746671546798396, -3.999796939069629],
              [114.746631742030203, -3.999953270290258],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 11509.706,
        Luas: 1.151,
        PERIMETRO: 677.226,
        block: "BLOK 1",
        No_Kav: "5b",
        Nm_Pemilik: "Mustaqim",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747335832771796, -4.000153909721845],
              [114.747134298709781, -4.000097455953524],
              [114.74669622362785, -3.999965348072993],
              [114.747305750125079, -3.997385894761595],
              [114.747344282901764, -3.99739154623421],
              [114.747335832771796, -4.000153909721845],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19653.635,
        Luas: 1.965,
        PERIMETRO: 754.333,
        block: "BLOK 1",
        No_Kav: "6",
        Nm_Pemilik: "Damukri",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747903264876356, -4.000352518485925],
              [114.74769712420408, -4.000276520178114],
              [114.747336508000728, -4.000154251101842],
              [114.747344282901764, -3.99739154623421],
              [114.747911643624263, -3.997474757300349],
              [114.747903264876356, -4.000352518485925],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19718.401,
        Luas: 1.972,
        PERIMETRO: 775.098,
        block: "BLOK 1",
        No_Kav: "7",
        Nm_Pemilik: "Katmidi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748448510859205, -4.000548840334408],
              [114.748134933744993, -4.00045013861655],
              [114.747904090263447, -4.000352520760115],
              [114.747911650425465, -3.997474764168019],
              [114.748456854208399, -3.99755472363982],
              [114.748448510859205, -4.000548840334408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19644.632,
        Luas: 1.964,
        PERIMETRO: 801.15,
        block: "BLOK 1",
        No_Kav: "8",
        Nm_Pemilik: "Juwairiah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748968338828846, -4.000780084023275],
              [114.748448964554811, -4.000549298466477],
              [114.748456854208399, -3.99755472363982],
              [114.748977420164664, -3.997631071359705],
              [114.748968338828846, -4.000780084023275],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20098.572,
        Luas: 2.01,
        PERIMETRO: 832.244,
        block: "BLOK 1",
        No_Kav: "9",
        Nm_Pemilik: "Abdul Hapid Amirudin",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749288736780173, -4.001065641482223],
              [114.748968792524963, -4.000780542155286],
              [114.748977420164664, -3.997631071359705],
              [114.749476135593866, -3.997704216612467],
              [114.749468185734159, -4.000892469099231],
              [114.749288736780173, -4.001065641482223],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19662.458,
        Luas: 1.966,
        PERIMETRO: 800.132,
        block: "BLOK 1",
        No_Kav: "10",
        Nm_Pemilik: "Juwairiah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.749476135593866, -3.997704216612467],
              [114.750002155510956, -3.997781369955505],
              [114.749994114052825, -4.000730224546119],
              [114.749792101525671, -4.000740666736257],
              [114.749468185734159, -4.000892469099231],
              [114.749476135593866, -3.997704216612467],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19671.472,
        Luas: 1.967,
        PERIMETRO: 793.865,
        block: "BLOK 1",
        No_Kav: "11",
        Nm_Pemilik: "Abdul Hapid Amirudin",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.750002159446041, -3.997781368587403],
              [114.750531195889323, -3.997858958302057],
              [114.750520874128384, -4.000969143313228],
              [114.749994114052825, -4.000730224546119],
              [114.750002159446041, -3.997781368587403],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 21474.016,
        Luas: 2.147,
        PERIMETRO: 837.768,
        block: "BLOK 1",
        No_Kav: "12",
        Nm_Pemilik: "Anik",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.75053119906687, -3.997858958479767],
              [114.751067453578955, -3.997937606770933],
              [114.751060632772806, -4.000513554238998],
              [114.751061819663192, -4.001166045609982],
              [114.750913440344007, -4.001280953938742],
              [114.750522635080117, -4.00097181179329],
              [114.750522959842257, -4.000879035983578],
              [114.75053119906687, -3.997858958479767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 21359.798,
        Luas: 2.136,
        PERIMETRO: 864.709,
        block: "BLOK 1",
        No_Kav: "13",
        Nm_Pemilik: "Junaidi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751067453578955, -3.997937606770933],
              [114.751679211446287, -3.998027324450223],
              [114.751671903624597, -4.000672880304992],
              [114.751559362634623, -4.000378242276434],
              [114.751245328276269, -4.00113521652288],
              [114.751063000401558, -4.001166642557272],
              [114.751067453578955, -3.997937606770933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19390.225,
        Luas: 1.939,
        PERIMETRO: 764.8,
        block: "BLOK 1",
        No_Kav: "15",
        Nm_Pemilik: "Junaidi",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752256191738113, -3.998111947543918],
              [114.753396678116317, -3.998279209028362],
              [114.752866438271852, -3.999569628083116],
              [114.752773964631913, -3.999557920576313],
              [114.752408953250679, -4.00050881507717],
              [114.752248379917177, -4.000918866014224],
              [114.752256191738113, -3.998111947543918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19378.118,
        Luas: 1.938,
        PERIMETRO: 738.741,
        block: "BLOK 1",
        No_Kav: "14",
        Nm_Pemilik: "Anik",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751679211446287, -3.998027324450223],
              [114.752256190129714, -3.998111946730774],
              [114.752248366272042, -4.000918913482807],
              [114.752244501644569, -4.000928791614626],
              [114.751671903624597, -4.000672880304992],
              [114.751679211446287, -3.998027324450223],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19765.858,
        Luas: 1.977,
        PERIMETRO: 706.313,
        block: "BLOK 4",
        No_Kav: "68",
        Nm_Pemilik: "Irsyad Syaifullah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754753000219125, -3.990239891301774],
              [114.756089797239198, -3.990421389836138],
              [114.755312278719202, -3.991703329854125],
              [114.754746471629616, -3.992658096266608],
              [114.754753000219125, -3.990239891301774],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19784.644,
        Luas: 1.978,
        PERIMETRO: 711.669,
        block: "BLOK 4",
        No_Kav: "67",
        Nm_Pemilik: "Ninit Krisdyawati",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754140122423152, -3.990152771930734],
              [114.754527193446989, -3.990209232906661],
              [114.754753000219125, -3.990239891301774],
              [114.754746471629616, -3.992658096266608],
              [114.754620490320249, -3.992870684165757],
              [114.754132911217511, -3.992804895541431],
              [114.754140122423152, -3.990152771930734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19786.864,
        Luas: 1.979,
        PERIMETRO: 723.475,
        block: "BLOK 4",
        No_Kav: "66",
        Nm_Pemilik: "Cicik Ernawati",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753534014285691, -3.990064360150591],
              [114.754140122423152, -3.990152771930734],
              [114.754132907883516, -3.992804892434494],
              [114.753526785005974, -3.992723109212655],
              [114.753534014285691, -3.990064360150591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19754.634,
        Luas: 1.975,
        PERIMETRO: 720.324,
        block: "BLOK 4",
        No_Kav: "65",
        Nm_Pemilik: "Sunik Asri Sustiani",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.752928818964705, -3.990013982977282],
              [114.753110501984807, -3.990002583004046],
              [114.753534014285691, -3.990064360150591],
              [114.753526785005974, -3.992723109212655],
              [114.752922053793469, -3.992641514906343],
              [114.752928818964705, -3.990013982977282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19770.897,
        Luas: 1.977,
        PERIMETRO: 689.932,
        block: "BLOK 4",
        No_Kav: "64",
        Nm_Pemilik: "Suparji",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.7522654389237, -3.990382657753635],
              [114.75260492204886, -3.990136495649805],
              [114.752916802868171, -3.990014736172185],
              [114.752928818964705, -3.990013982977282],
              [114.752922053793469, -3.992641514906343],
              [114.752259597175666, -3.992552121778394],
              [114.7522654389237, -3.990382657753635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19773.461,
        Luas: 1.977,
        PERIMETRO: 653.644,
        block: "BLOK 4",
        No_Kav: "63",
        Nm_Pemilik: "Sunik Asri Sustiani",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751228149366995, -3.991495214712832],
              [114.75161971052205, -3.991368376007004],
              [114.751744384219393, -3.991133647612799],
              [114.751727553577112, -3.990747162299038],
              [114.751795432828132, -3.990557084405935],
              [114.7520399291828, -3.990443596717255],
              [114.752250578595365, -3.990393437898676],
              [114.7522654389237, -3.990382657753635],
              [114.752259592651285, -3.992552121940522],
              [114.751225653842525, -3.992412614531825],
              [114.751228149366995, -3.991495214712832],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19776.634,
        Luas: 1.978,
        PERIMETRO: 762.551,
        block: "BLOK 4",
        No_Kav: "62",
        Nm_Pemilik: "Suparji",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.748883942404106, -3.991403504331955],
              [114.749262707044352, -3.991505646916048],
              [114.749371365659997, -3.991533870321341],
              [114.749427224353255, -3.991549071454091],
              [114.74957560037528, -3.99156665163557],
              [114.749790013618451, -3.991527573672929],
              [114.750501140425214, -3.991482815766371],
              [114.750562220393221, -3.991483677785493],
              [114.75060505369548, -3.991484133132072],
              [114.750703736664292, -3.991485391177453],
              [114.750946575149101, -3.991488638508396],
              [114.751109876603024, -3.991533526848155],
              [114.751228149366995, -3.991495214712832],
              [114.75122565199365, -3.992412615475665],
              [114.750845793588184, -3.992361362022724],
              [114.750848097411293, -3.992099979466238],
              [114.750707039978494, -3.992069988119436],
              [114.750541828064698, -3.99203458552679],
              [114.750478936604566, -3.992021108794456],
              [114.750428228724218, -3.992010241916519],
              [114.750425173994088, -3.992291891393476],
              [114.749379709744574, -3.992136528865655],
              [114.748882144919449, -3.992062574329077],
              [114.748883942404106, -3.991403504331955],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5433.893,
        Luas: 0.543,
        PERIMETRO: 311.43,
        block: "BLOK 4",
        No_Kav: "61a",
        Nm_Pemilik: "Abdul Basit",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.747931588912067, -3.991917540073089],
              [114.748470849734645, -3.991379016550817],
              [114.748651129942289, -3.991341235979251],
              [114.748830260017058, -3.991389145379584],
              [114.748883939522599, -3.991403501700335],
              [114.748882143601662, -3.992062575524685],
              [114.748288534641233, -3.991974009858215],
              [114.747931588912067, -3.991917540073089],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14335.685,
        Luas: 1.434,
        PERIMETRO: 777.771,
        block: "BLOK 5",
        No_Kav: "61b",
        Nm_Pemilik: "Abdul Basit",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.74867864732478, -3.991230417722938],
              [114.748935184392025, -3.991051400127415],
              [114.749237107710726, -3.99088771436938],
              [114.749283659772942, -3.990980468514658],
              [114.749555132213956, -3.9908775281297],
              [114.749632127643835, -3.990912301662403],
              [114.749543626167821, -3.991058601630296],
              [114.749666607646049, -3.991141252412089],
              [114.749770094660533, -3.990992007422535],
              [114.749951729616754, -3.990960182867584],
              [114.750060608039078, -3.990965949812701],
              [114.750120227646036, -3.991065903295864],
              [114.750411682230393, -3.991074134568915],
              [114.750554391466267, -3.99092603246382],
              [114.750870647858832, -3.990938402978984],
              [114.751135754442956, -3.990945172528471],
              [114.751205863872869, -3.990954210048311],
              [114.751296959848929, -3.991037643324353],
              [114.751397698891338, -3.990999289076642],
              [114.751386374779841, -3.990977389488296],
              [114.751651696629992, -3.990978117788225],
              [114.751656938129742, -3.991111325519342],
              [114.751572642880575, -3.991285229650418],
              [114.751566565569632, -3.991297768108591],
              [114.751109802052198, -3.991440734980555],
              [114.750955714205659, -3.991406689963166],
              [114.750486152102724, -3.991396552780003],
              [114.750162755876062, -3.991417784380907],
              [114.749792663009913, -3.991439772363438],
              [114.749581137680295, -3.991468389312604],
              [114.749473700382666, -3.991460711704723],
              [114.74867864732478, -3.991230417722938],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17725.116,
        Luas: 1.773,
        PERIMETRO: 1093.043,
        block: "BLOK 5",
        No_Kav: "69a",
        Nm_Pemilik: "Sri Utari",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.751282772838749, -3.990777032193906],
              [114.751468575710646, -3.990483000439462],
              [114.751513135749747, -3.990322030559297],
              [114.751625629623618, -3.990200563232216],
              [114.751965050352581, -3.989846190082265],
              [114.752157312015612, -3.989835068590429],
              [114.75223763986827, -3.989778656585154],
              [114.752465394838936, -3.98959559209329],
              [114.752793596142212, -3.989533952545899],
              [114.752898273846512, -3.989422373064426],
              [114.752981736277206, -3.989333641615995],
              [114.753121697290268, -3.989324340002645],
              [114.75322852963798, -3.989314128523124],
              [114.753330287661342, -3.989244747137794],
              [114.753512001359752, -3.98910863293534],
              [114.753651929389548, -3.989014848903058],
              [114.75397511217372, -3.988863349350143],
              [114.754164900797733, -3.988739671012032],
              [114.754255492194005, -3.988647431068285],
              [114.754355062311035, -3.988472786886467],
              [114.754467043989422, -3.988341173074015],
              [114.754712494818861, -3.988372848725422],
              [114.754955500728911, -3.988247337659052],
              [114.754955692046366, -3.988441289600541],
              [114.754592373336735, -3.98861928619169],
              [114.754526361990301, -3.988703665971312],
              [114.754338356274502, -3.988816779240059],
              [114.753954155765783, -3.989014146037662],
              [114.753762204621495, -3.989153944936397],
              [114.753470543370383, -3.98946602542178],
              [114.753419678694641, -3.989593918002128],
              [114.753307035998134, -3.989755732832232],
              [114.753175691279225, -3.989869704489872],
              [114.753053927821966, -3.989935731190956],
              [114.752907662493925, -3.98994064017804],
              [114.752616442783079, -3.990047006612637],
              [114.752560873307118, -3.990068282531445],
              [114.752215746944501, -3.990321731823383],
              [114.7520227836891, -3.990364240332116],
              [114.751750683637624, -3.990485746924678],
              [114.75173703451928, -3.990492192612763],
              [114.751642105189532, -3.990734366116742],
              [114.751647947621706, -3.990882847834142],
              [114.751651696616918, -3.990978117613408],
              [114.751386374746275, -3.990977389445452],
              [114.751282772838749, -3.990777032193906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 2040.607,
        Luas: 0.204,
        PERIMETRO: 225.022,
        block: "BLOK 5",
        No_Kav: "69b",
        Nm_Pemilik: "Sri Utari",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.753204586983969, -3.989937016699462],
              [114.753335342581437, -3.989824834035158],
              [114.753426877221358, -3.989708602013792],
              [114.753469729585035, -3.989643285230553],
              [114.753533004773004, -3.989473803137474],
              [114.753697698778169, -3.989295988662674],
              [114.753695743332159, -3.990008754815157],
              [114.753204586983969, -3.989937016699462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19763.735,
        Luas: 1.976,
        PERIMETRO: 584.467,
        block: "BLOK 5",
        No_Kav: "70",
        Nm_Pemilik: "Milati Ati Mul Imaroh",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754018432115245, -3.989022996771892],
              [114.754411468212737, -3.988831354045652],
              [114.754543792270255, -3.988749942637318],
              [114.754618190778075, -3.988665303611605],
              [114.754733086023222, -3.98860174643534],
              [114.754985869817787, -3.988477217698815],
              [114.754981233367687, -3.990196512919773],
              [114.753695743332159, -3.990008754815157],
              [114.753697698778169, -3.989295988662674],
              [114.753824742810878, -3.989158823723128],
              [114.753997998077438, -3.989032962950922],
              [114.754018432115245, -3.989022996771892],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19764.31,
        Luas: 1.976,
        PERIMETRO: 670.385,
        block: "BLOK 5",
        No_Kav: "74",
        Nm_Pemilik: "Refma Nur Ariaty",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755016195244423, -3.988178800565018],
              [114.75507153390474, -3.987624131854456],
              [114.755539022099114, -3.987544013207081],
              [114.755660334548139, -3.987199079405535],
              [114.756064209660991, -3.987043786628501],
              [114.756628738059987, -3.987503912274364],
              [114.756694397188269, -3.987559078509061],
              [114.757439940051754, -3.988185401823693],
              [114.756610644621958, -3.98818314403006],
              [114.756596927781729, -3.988183106678307],
              [114.755773807186301, -3.988180864853061],
              [114.755764601788911, -3.988180839776863],
              [114.755749727119252, -3.988180799152234],
              [114.755705449555762, -3.988180678531814],
              [114.755626877877887, -3.988180464481879],
              [114.755016195244423, -3.988178800565018],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19760.537,
        Luas: 1.976,
        PERIMETRO: 629.598,
        block: "BLOK 5",
        No_Kav: "71",
        Nm_Pemilik: "Irsyad Syaifullah",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.754990187209984, -3.988475090467798],
              [114.755016195244423, -3.988178800565018],
              [114.755764601788911, -3.988180839776863],
              [114.755758792543929, -3.990310081728869],
              [114.754981233367687, -3.990196512919773],
              [114.754985869817787, -3.988477217698815],
              [114.754990187209984, -3.988475090467798],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19771.149,
        Luas: 1.977,
        PERIMETRO: 615.573,
        block: "BLOK 5",
        No_Kav: "73",
        Nm_Pemilik: "Lynda Milsa Novelila",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.756597240412489, -3.988183107641238],
              [114.757439940051754, -3.988185401823693],
              [114.757500712194712, -3.988236455778963],
              [114.757699525626762, -3.988009953262495],
              [114.758018595096118, -3.988261142237489],
              [114.757808255176229, -3.988800133625782],
              [114.757935556047912, -3.988963667850828],
              [114.757880578288407, -3.989238956917607],
              [114.757524501858697, -3.989408833871694],
              [114.757391605387923, -3.989419967899258],
              [114.757099262914593, -3.98925215430086],
              [114.75683844118457, -3.98952600902894],
              [114.756720117046626, -3.989546415648776],
              [114.75660432704386, -3.989518791143665],
              [114.756593570917019, -3.989537227102846],
              [114.756597240412489, -3.988183107641238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19766.801,
        Luas: 1.977,
        PERIMETRO: 623.254,
        block: "BLOK 5",
        No_Kav: "72",
        Nm_Pemilik: "Milati Ati Mul Imaroh",
        KETERANGAN: null,
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.755764601788911, -3.988180839776863],
              [114.756597240349521, -3.988183107619966],
              [114.756593570892079, -3.989537227164945],
              [114.756112522653567, -3.990361746643154],
              [114.755758792543929, -3.990310081728869],
              [114.755764601788911, -3.988180839776863],
            ],
          ],
        ],
      },
    },
  ],
};
