import { ConfigProvider, Image, Modal } from "antd";
import React, { useState } from "react";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import DatasetImage from "./partials/DatasetImage";
import DetailContent from "./partials/DetailContent";
import moment from "moment";
import { useGalleryViewStore } from "./hooks/useGalleryViewStore";

const GalleryView = ({ isLoading, data, handleRefreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const jeprinData = useGalleryViewStore((state) => state.jeprinData);

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  const handleOk = () => setIsModalOpen(false);

  return (
    <div className="mx-8 overflow-y-scroll rounded-t-16 border border-gray-10 bg-white p-16 md:mx-16">
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title={`Informasi Jeprin ${
            jeprinData?.date
              ? `(${moment(jeprinData?.date).format("LL")} ${moment(
                  jeprinData?.createdTime
                ).format("H:mm")})`
              : null
          }`}
          zIndex={100}
          width={800}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={toggleModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="bordered-button-transition border-gray-100 px-16 py-8 text-16 text-gray-500"
                onClick={() => handleOk()}
              />
            </div>
          }
        >
          <DetailContent handleRefreshData={handleRefreshData} />
        </Modal>
      </ConfigProvider>
      <div className="grid grid-cols-3 gap-8 md:grid-cols-16">
        <Image.PreviewGroup>
          {isLoading ? (
            <div className="col-span-16 flex w-full flex-col items-center justify-center">
              <div className="loader-spinner-gradient h-32 w-32"></div>
              <p className="font-regular text-14 text-gray-500">
                Sedang dalam proses memuat data panen...
              </p>
            </div>
          ) : data?.length < 1 ? (
            <div className="col-span-16 flex w-full flex-col items-center justify-center">
              <p className="font-regular text-14 text-gray-300">
                Tidak ada data gambar bukti spot check tersedia.
              </p>
            </div>
          ) : data?.length >= 1 ? (
            data.map((item) => {
              return <DatasetImage item={item} onClick={() => toggleModal()} />;
            })
          ) : null}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default GalleryView;
