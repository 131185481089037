import React from "react";
import TableRow from "./partials/TableRow/TableRow";
import Tables from "../../../../../components/organisms/Tables";

const CardWithTable = ({ data, headers }) => {
	return (
		<div className="flex h-full max-h-full flex-col overflow-y-scroll rounded-8 border border-gray-10 bg-white shadow-md scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200">
			<Tables
				headers={headers}
				rows={data || []}
				rowContent={(props) => <TableRow data={props} />}
				size="sm"
			/>
		</div>
	);
};

export default CardWithTable;
