import InputHook from "../../../../atoms/InputHook";
import React from "react";
import SelectHook from "../../../../atoms/SelectHook";
import { parseToFloat } from "../../../../../helpers/Common";

const AfdelingCreateContent = ({
	register,
	control,
	errors,
	watchFields,
	data,
	showEstateOptions,
}) => {
	return (
		<div className="grid grid-cols-1 gap-16">
			<InputHook
				required
				register={register}
				label="Kode"
				name="code"
				placeholder="Masukkan kode afdeling"
				errors={errors?.code}
				inputClassName="input-large-border"
			/>
			<InputHook
				required
				register={register}
				label="Nama Afdeling"
				name="name"
				placeholder="Masukkan nama afdeling"
				errors={errors?.name}
				inputClassName="input-large-border"
			/>
			{!showEstateOptions && (
				<SelectHook
					label="Estate"
					required
					name="estate"
					errors={errors?.estate}
					data={data?.estates || [{}]}
					control={control}
					placeholder="Pilih salah satu Estate"
				/>
			)}

			<InputHook
				required
				register={register}
				label="Luas (Ha)"
				name="areaHa"
				errors={errors?.areaHa}
				inputClassName="input-large-border"
				placeholder="0"
				secondaryLabel={
					watchFields?.areaHa?.length >= 1 &&
					`(${parseToFloat(watchFields?.areaHa)} Ha)`
				}
			/>
		</div>
	);
};

export default AfdelingCreateContent;
