import "react-lazy-load-image-component/src/effects/blur.css";

import { Controller } from "react-hook-form";
import { ConfigProvider, DatePicker, Image, notification, Tooltip } from "antd";
import {
  Number,
  parseToFloat,
} from "../../../../../../../../../helpers/Common";
import React, { useCallback, useEffect, useState } from "react";
import { TbCalendar } from "react-icons/tb";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import { MdOutlineWarning } from "react-icons/md";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { checkValues } from "../../../../../../../../../helpers/validationHelper";
import { debounce } from "lodash";
import { useImageSource } from "../../../../../../../../../hooks/useImageSource";
import { useComment } from "../../../../../../../../../hooks/useComment";
import { useModalStore } from "../../../../../Modal/Modal";
import { useFfbStockMovementStore } from "../../../../../../../../../api/ffbStockMovement/useFfbStockMovementStore";
import SelectHook from "../../../../../../../../../components/atoms/SelectHook";
import useFilterDataDefaults from "../../../../../../../../../hooks/useFilterDataDefaults";
import { useUserStore } from "../../../../../../../../MasterWorkerPage/hooks/userStore";

dayjs.extend(customParseFormat);
const Item = ({ item, index, handleRefreshData }) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    onChangeDate,
    onChangeText,
    setData,
  } = useComment({ input: item });
  const watchRemark = watch(`remark-${item?.id}`);
  const [message, setMessage] = useState("");
  const [_, setVersion] = useState(item?.version);
  const { source, setSource, setDownloadedImage } = useImageSource();
  const [toggleModal] = useModalStore((state) => [state.toggleModal]);
  const [updateFfbStockMovement] = useFfbStockMovementStore((state) => [
    state.updateFfbStockMovement,
  ]);
  const [user] = useUserStore((state) => [state.user]);

  const { users, fetchUsers } = useFilterDataDefaults();
  const onSearchUser = async (searchTerm) => {
    await fetchUsers({ name: searchTerm });
  };

  const calculateNettWeight = () => {
    const grossWeight = item?.measuredGrossWeightKg || 0;
    const taraWeight = item?.measuredTaraWeightKg || 0;

    const nettWeight =
      item?.measuredNettWeightKg || grossWeight - taraWeight || 0;
    return nettWeight;
  };

  const calculateBjrRamp = () => {
    const nettWeight = calculateNettWeight();
    const ffbCount = item?.ffbCount || 0;
    const pinaltyCount = item?.pinaltyCount || 0;

    if (ffbCount !== 0 && nettWeight !== 0) {
      return parseToFloat(nettWeight / (ffbCount - pinaltyCount));
    } else {
      return 0;
    }
  };

  const handleShowVisible = (type) =>
    setSource({ [`${type}Visible`]: !source[`${type}Visible`] });

  const updateFunc = async (body) => {
    const response = await updateFfbStockMovement({
      id: body?.id,
      body,
      silent: true,
      onError: () => {
        notification.error({
          message: "Gagal Memberikan Komentar",
          placement: "bottomRight",
        });
      },
    });
    return response?.record;
  };

  const request = debounce(async (searchTerm) => {
    onChangeText({
      key: "remark",
      data: searchTerm,
      refreshFunc: handleRefreshData,
      updateFunc,
      silent: true,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [item]
  );

  useEffect(() => {
    setValue(`remark-${item?.id}`, item?.remark);
    setValue(
      `caDueDate-${item?.id}`,
      item?.caDueDate
        ? dayjs(moment(item?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [item?.id]);

  useEffect(() => {
    setValue(`fullname-${item?.id}`, {
      label: item?.fullname,
      value: item?.userId,
    });
    fetchUsers({ params: { pageSize: 99999 } });
    setData(item);
  }, [item?.caDueDate, item?.remark, item]);

  useEffect(() => {
    const fieldNames = {
      truckPlateNo: "Nopol",
      logisticFlow: "Arus TBS",
      ffbSourceParty: "Diterima Dari",
      measuredGrossWeightKg: "Timbangan Isi (Kg)",
      measuredTaraWeightKg: "Timbangan Kosong (Kg)",
      measuredNettWeightKg: "Berat Bersih (Kg)",
      ffbCount: "TBS Diterima (Jjg)",
      pinaltyCount: "Penalti (Jjg)",
      // photoFileId: "Dokumen 1",
      // photoFileId2: "Dokumen 2",
      // stationName: "Nama Stasiun",
      // ffbDestinationParty: "Tujuan Janjang",
      // deliveryLetterNumber: "No. SPB",
      // declaredNettWeightKg: "Berat Bersih",
      // declaredGrossWeightKg: "Berat Bruto",
      // declaredTaraWeightKg: "Berat Tara",
      // status: "Status",
      // photoFileSource: "Sumber Dokumen",
      // tags: "Tags",
    };

    const items = [
      { truckPlateNo: item?.truckPlateNo },
      { logisticFlow: item?.logisticFlow },
      { ffbSourceParty: item?.ffbSourceParty },
      { measuredGrossWeightKg: item?.measuredGrossWeightKg },
      { measuredNettWeightKg: item?.measuredNettWeightKg },
      { measuredTaraWeightKg: item?.measuredTaraWeightKg },
      { ffbCount: item?.ffbCount },
      { pinaltyCount: item?.pinaltyCount },
      // { photoFileId: item?.photoFileId },
      // { photoFileId2: item?.photoFileId2 },

      // { status: item?.status },
      // { stationName: item?.stationName },
      // { ffbDestinationParty: item?.ffbDestinationParty },
      // { deliveryLetterNumber: item?.deliveryLetterNumber },
      // { declaredNettWeightKg: item?.declaredNettWeightKg },
      // { declaredGrossWeightKg: item?.declaredGrossWeightKg },
      // { declaredTaraWeightKg: item?.declaredTaraWeightKg },
      // { photoFileSource: item?.photoFileSource },
      // { tags: item?.tags },
    ];
    setMessage(checkValues(items, fieldNames));

    setDownloadedImage(item?.photoFileId, "main");
    setDownloadedImage(item?.photoFileId2, "second");
  }, [item?.photoFileId, item?.photoFileId2]);

  return (
    <tr
      className={`table-header-regular relative hover:bg-[rgba(215,161,55,0.3)] hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out`}
      key={index}
    >
      <td className="table-row-regular">
        {index + 1}

        <Tooltip
          placement="bottom"
          title={message.message}
          className="font-regular"
        >
          <div>
            {message.isError ? (
              <MdOutlineWarning className="text-24 text-yellow-600" />
            ) : null}
          </div>
        </Tooltip>
      </td>
      <td className="table-row-regular">
        <p>{moment(item?.operationDate).format("ll")}</p>
      </td>

      <td className="flex w-[170px] flex-row items-center space-x-4">
        <Tooltip placement="bottom" title="Dokumen 1" className="font-regular">
          <img
            src={source.main || IMAGE_NOT_FOUND}
            alt={item?.photoFileId}
            className="aspect-square w-[80px] cursor-pointer rounded-8"
            onClick={() => handleShowVisible("main")}
          />
        </Tooltip>
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.main}
          preview={{
            visible: source.mainVisible,
            src: source.main || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("main"),
          }}
        />
        <Tooltip placement="bottom" title="Dokumen 2" className="font-regular">
          <img
            src={source.second || IMAGE_NOT_FOUND}
            alt={item?.photoFileId2}
            className="aspect-square w-[80px] cursor-pointer rounded-8"
            onClick={() => handleShowVisible("second")}
          />
        </Tooltip>
        <Image
          width={200}
          wrapperStyle={{ display: "none" }}
          src={source.second}
          preview={{
            visible: source.secondVisible,
            src: source.second || IMAGE_NOT_FOUND,
            onVisibleChange: () => handleShowVisible("second"),
          }}
        />
      </td>

      <td className="table-row-regular">
        <p></p>
      </td>

      <td className="table-row-regular">
        {/*{item?.fullname}*/}
        {user?.username === "kjp02" ||
        user?.username === "k.emj" ||
        user?.username === "pranotodahlan" ? (
          <SelectHook
            control={control}
            data={users}
            name={`fullname-${item?.id}`}
            placeholder="komentar"
            className="min-w-[160px]"
            inputClassName="input-mini-border pl-8 pr-24 w-full"
            menuStyles={{
              input: {
                padding: 4,
              },
              option: {
                padding: "4px 12px",
              },
              menuList: {
                padding: "2px",
              },
            }}
            onInputChange={(searchValue) => {
              onSearchUser(searchValue);
            }}
            afterOnChange={(value) => {
              const body = {
                ...item,
                // username: value.username,
                // fullname: value.fullname,
                updatedBy: value?.fullname,
                userId: value?.id,
              };
              updateFunc(body).then(() => handleRefreshData(true));
            }}
          />
        ) : (
          item?.fullname
        )}
      </td>
      <td className="table-row-regular">
        <InputHook
          control={control}
          name={`remark-${item?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          afterOnChange={debounceTextRequest}
          onBlur={() => {
            onChangeText({
              key: "remark",
              data: watchRemark,
              refreshFunc: handleRefreshData,
              updateFunc,
            });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit((fieldValues) =>
                onChangeText({
                  key: "remark",
                  data: fieldValues[`remark-${item?.id}`],
                  refreshFunc: handleRefreshData,
                  updateFunc,
                })
              )(event);
            }
          }}
        />
      </td>
      <td className="table-row-regular">
        <Controller
          name={`caDueDate-${item?.id}`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    onChangeDate({
                      key: "caDueDate",
                      date: date || null,
                      refreshFunc: handleRefreshData,
                      updateFunc,
                    });
                  }}
                  placeholder="Tanggal tindakan"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: 160,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    fontFamily: "Inter-Regular",
                    maxWidth: 250,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </td>

      {/*<td*/}
      {/*  className="table-row-regular w-[300px]"*/}
      {/*  onClick={(event) => {*/}
      {/*    event.stopPropagation();*/}
      {/*    toggleModal({ data: item });*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className="w-[300px]">*/}
      {/*    <ShowMore*/}
      {/*      className="flex flex-row flex-wrap"*/}
      {/*      data={item?.tags}*/}
      {/*      maxItems={2}*/}
      {/*      itemComponent={(item) => (*/}
      {/*        <div className="mr-4 mb-4 flex flex-row items-center space-x-8 rounded-[4px] bg-gray-20 px-12 py-4">*/}
      {/*          <span className="text-14 font-medium text-gray-500">*/}
      {/*            {item}*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</td>*/}
      <td className="table-row-regular">
        {item?.totalPrice || item?.totalPrice === 0
          ? Number(item?.totalPrice)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.unitPrice || item?.unitPrice === 0
          ? Number(item?.unitPrice)
          : null}
      </td>

      <td className="table-row-regular">
        {item?.measuredNettWeightKg || item?.measuredNettWeightKg === 0
          ? parseToFloat(item?.measuredNettWeightKg)
          : parseToFloat(calculateNettWeight())}
      </td>
      <td className="table-row-regular">
        {item?.measuredGrossWeightKg || item?.measuredGrossWeightKg === 0
          ? parseToFloat(item?.measuredGrossWeightKg)
          : null}
      </td>
      <td className="table-row-regular">
        {item?.measuredTaraWeightKg || item?.measuredTaraWeightKg === 0
          ? parseToFloat(item?.measuredTaraWeightKg)
          : null}
      </td>

      <td className="table-row-regular">{item?.logisticFlow}</td>
      <td className="table-row-regular">{item?.ffbSourceParty}</td>
      <td className="table-row-regular">{item?.ffbDestinationParty}</td>
      <td className="table-row-regular">{item?.truckPlateNo}</td>

      <td className="table-row-regular">
        {item?.ffbCount || item?.ffbCount === 0 ? Number(item?.ffbCount) : null}
      </td>
      <td className="table-row-regular">
        {item?.pinaltyCount || item?.pinaltyCount === 0
          ? Number(item?.pinaltyCount)
          : null}
      </td>
      <td className="table-row-regular">{calculateBjrRamp()}</td>

      {/*<td className="table-row-regular">{item?.stationName}</td>*/}
      {/*<td className="table-row-regular">{item?.ffbDestinationParty}</td>*/}
      {/*<td className="table-row-regular">{item?.deliveryLetterNumber}</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.declaredNettWeightKg || item?.declaredNettWeightKg === 0*/}
      {/*    ? parseToFloat(item?.declaredNettWeightKg)*/}
      {/*    : null}*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.declaredGrossWeightKg || item?.declaredGrossWeightKg === 0*/}
      {/*    ? parseToFloat(item?.declaredGrossWeightKg)*/}
      {/*    : null}*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.declaredTaraWeightKg || item?.declaredTaraWeightKg === 0*/}
      {/*    ? parseToFloat(item?.declaredTaraWeightKg)*/}
      {/*    : null}*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">{item?.status}</td>*/}
      {/*<td className="table-row-regular">{item?.photoFileSource}</td>*/}
    </tr>
  );
};

export default Item;
