import { ConfigProvider, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import SelectHook from "../../../../../components/atoms/SelectHook";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";

const { RangePicker } = DatePicker;

const Filter = ({ onFilter }) => {
  const { control } = useForm();
  const { users } = useFilterDataDefaults();

  return (
    <div className="grid grid-cols-1 gap-13">
      <SelectHook
        name="username"
        data={users}
        control={control}
        placeholder="Pilih pengguna"
        menuStyles={{ padding: 5 }}
        className="w-full"
        afterOnChange={(value) => {
          onFilter({ type: "fltUser", value });
        }}
      />
      <Controller
        name="dateRange"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#d7a137",
                  colorTextQuaternary: "#e1e4e8",
                  fontFamily: "Inter-Regular",
                  fontSize: 16,
                  colorTextPlaceholder: "#6B6F77",
                },
              }}
            >
              <RangePicker
                placement="bottomLeft"
                placeholder={["Dari tanggal", "sampai"]}
                format="DD/MM/YYYY"
                value={value}
                onChange={(data) => {
                  onChange(data);
                  onFilter({
                    value: data,
                    type: "dateRange",
                  });
                }}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "#e1e4e8",
                }}
              />
            </ConfigProvider>
          );
        }}
      />
    </div>
  );
};

export default Filter;
