import { TbPencil, TbTrash } from "react-icons/tb";

import React from "react";
import moment from "moment";
import { parseToFloat } from "../../../../../../../helpers/Common";

const TableRow = ({ data, onDelete, onUpdate }) => {
	return data.map((item, index) => {
		return (
			<tr className="table-header-regular cursor-pointer" key={index}>
				<td className="table-row-regular font-semi-bold">
					{item?.estateName}
				</td>
				<td className="table-row-regular font-semi-bold">
					{item?.afdelingName}
				</td>
				<td className="table-row-regular font-semi-bold">
					{item?.blockName}
				</td>
				<td className="table-row-regular font-semi-bold">
					{item?.plantingYear}
				</td>
				<td className="table-row-regular font-semi-bold">
					{parseToFloat(item?.tonnase)}
				</td>
				<td className="table-row-regular font-semi-bold">
					{parseToFloat(item?.tonnase / item?.tbs)}
				</td>
				<td className="table-row-regular ">
					{item?.month
						? moment()
								.month(item?.month - 1)
								.format("MMMM")
						: null}
				</td>
				<td className="table-row-regular ">{item?.year}</td>
				<td className="table-row-regular ">
					<div className="flex flex-row items-center space-x-16">
						<button
							className="rounded-[4px] p-8 outline-none"
							onClick={(event) => {
								event.stopPropagation();
								onUpdate({ item }, "showModalCreate");
							}}
						>
							<TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
						</button>
						<button
							className="rounded-[4px] bg-red-500 p-8 outline-none"
							onClick={(event) => {
								event.stopPropagation();
								onDelete({
									id: item?.id,
								});
							}}
						>
							<TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
						</button>
					</div>
				</td>
			</tr>
		);
	});
};

export default TableRow;
