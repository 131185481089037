import { useLocation, useNavigate } from "react-router-dom";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import Navbar from "../../../../../components/atoms/Navbar";
import React, { useEffect, useState } from "react";
import LOGO_SAWIT_WEB_VERTICAL from "../../../../../assets/icons/vertical.png";
import LOGO_SEMAI_KEBUN_VERTICAL from "../../../../../assets/icons/logo-semai-kebun-v.png";

import LOGO_KJP from "../../../../../assets/icons/logo-kjp.jpeg";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import { useCompanyStore } from "../../../../../api/company/useCompanyStore";
import { useImageSource } from "../../../../../hooks/useImageSource";
import { APP_NAME } from "../../../../../configs/api";
const Heading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSnapshot = useSnapshotStore((state) => state.isSnapshot);
  const user = useUserStore((state) => state.user);
  const getCompany = useCompanyStore((state) => state.getCompany);
  const { source, setSource, setDownloadedImage } = useImageSource();
  const [isLogoReady, setIsLogoReady] = useState(false);

  useEffect(() => {
    if (user?.companyId) {
      getCompany({
        id: user?.companyId,
        silent: true,
        onSuccess: (res) => {
          if (res?.record?.photoFileId) {
            setIsLogoReady(true);
            setDownloadedImage(res?.record?.photoFileId, "main");
          }
        },
      });
    }
  }, [user?.companyId]);

  return (
    <div
      className={`mx-auto flex w-full flex-col-reverse items-center justify-between space-y-16 space-y-reverse md:grid md:space-y-0 md:px-[2%] ${
        isSnapshot
          ? "md:grid-cols-[1fr,1fr,max-content]"
          : "md:grid-cols-[1fr,max-content]"
      }`}
    >
      <div className="flex flex-row items-center space-x-16">
        {/* <HiOutlineHome className="hidden text-42 text-gray-500 md:inline" /> */}
        <div className="flex flex-row items-center">
          <ButtonComponent
            value="Produksi Kebun"
            className={`rounded-none rounded-l-8 text-14 ${
              location.pathname === "/produksi-kebun"
                ? APP_NAME === "sawit-web"
                  ? "bg-yellow-500 text-white"
                  : "bg-primary-red-500 text-white"
                : APP_NAME === "sawit-web"
                ? "bg-white text-yellow-600"
                : "bg-white text-primary-red-500"
            }  shadow`}
            onClick={() => navigate("/produksi-kebun")}
          />
          <ButtonComponent
            value="Penerimaan PKS"
            className={`rounded-none rounded-r-8 text-14 ${
              location.pathname === "/penerimaan-pks"
                ? APP_NAME === "sawit-web"
                  ? "bg-yellow-500 text-white"
                  : "bg-primary-red-500 text-white"
                : APP_NAME === "sawit-web"
                ? "bg-white text-yellow-600"
                : "bg-white text-primary-red-500"
            }  shadow`}
            onClick={() => navigate("/penerimaan-pks")}
          />
        </div>
      </div>
      {isSnapshot && (
        <div className="flex flex-row items-center justify-between space-x-8">
          <h1 className="font-bold text-24 text-gray-500">SNAPSHOT</h1>
          {/*{user?.companyName ===*/}
          {/*  "Koperasi Jasa Profesi Cipta Prima Sejahtera" && (*/}
          {/*  <div className="flex flex-row items-center space-x-8 md:hidden">*/}
          {/*    <img src={LOGO_KEBUN} className="h-[55px] w-auto" />*/}
          {/*    <img src={LOGO_KJP} className="h-[52px] w-auto" />*/}
          {/*  </div>*/}
          {/*)}*/}
          {isLogoReady && (
            <div className="flex flex-row items-center space-x-8 md:hidden">
              <img
                src={
                  APP_NAME === "sawit-web"
                    ? LOGO_SAWIT_WEB_VERTICAL
                    : LOGO_SEMAI_KEBUN_VERTICAL
                }
                className="h-[55px] w-auto"
              />
              <img src={source.main} className="h-[52px] w-auto" />
            </div>
          )}
        </div>
      )}
      <div className="w-full md:hidden">
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <p className="line-clamp-1">Dashboard</p>
            </div>
          }
        />
      </div>
      <div className="flex flex-row items-center space-x-24">
        {isLogoReady && (
          <div className="hidden flex-row items-center space-x-8 md:flex">
            <img
              src={
                APP_NAME === "sawit-web"
                  ? LOGO_SAWIT_WEB_VERTICAL
                  : LOGO_SEMAI_KEBUN_VERTICAL
              }
              className="h-[55px] w-auto"
            />
            <img src={source.main} className="h-[52px] w-auto" />
          </div>
        )}

        {/*{user?.companyName ===*/}
        {/*  "Koperasi Jasa Profesi Cipta Prima Sejahtera" && (*/}
        {/*  <div className="hidden flex-row items-center space-x-8 md:flex">*/}
        {/*    <img src={LOGO_KEBUN} className="h-[55px] w-auto" />*/}
        {/*    <img src={LOGO_KJP} className="h-[52px] w-auto" />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="hidden md:inline">
          <Navbar onlyUserProfile={true} />
        </div>
      </div>
      {/* <Dropdown menu={{ items }}>
				<a href="#" onClick={(e) => e.preventDefault()}>
					<ButtonComponent
						value="Export"
						icon={<TbDownload className="text-24" />}
					/>
				</a>
			</Dropdown> */}
    </div>
  );
};

export default Heading;
