import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const ShowMore = ({ data, text, maxItems, itemComponent, className }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  let visibleData = data;
  let visibleText = text;

  if (!expanded && Array.isArray(data) && data.length > maxItems) {
    visibleData = data.slice(0, maxItems);
  }

  if (!expanded && text && text.length > maxItems) {
    visibleText = `${text.slice(0, maxItems)}...`;
  }

  return (
    <div>
      <div className={className}>
        {visibleData &&
          Array.isArray(visibleData) &&
          visibleData.map((item, index) => (
            <div key={index}>{itemComponent(item, index)}</div>
          ))}
      </div>

      {visibleText && (
        <p
          onClick={(event) => {
            event.stopPropagation();
            toggleExpand();
          }}
          className="break-words"
        >
          {visibleText}
        </p>
      )}
      {((Array.isArray(data) && data.length > maxItems) ||
        (text && text.length > maxItems)) && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            toggleExpand();
          }}
          className="flex flex-row items-center space-x-4"
        >
          <p className="text-14 text-gray-400">
            {expanded ? "Less" : "Read more..."}
          </p>
          {expanded ? (
            <BiChevronUp className="text-16 text-gray-400" />
          ) : (
            <BiChevronDown className="text-16 text-gray-400" />
          )}
        </div>
      )}
    </div>
  );
};

export default ShowMore;
