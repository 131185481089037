import Item from "./partials/Item/Item";
import React from "react";

const TableRow = ({ data, page }) => {
	return data.map((item, index) => {
		return <Item item={item} index={index} key={index} page={page} />;
	});
};

export default TableRow;
