import { ConfigProvider, DatePicker, Modal } from "antd";
import React, { forwardRef, useEffect, useState } from "react";

import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { TbDownload, TbFilter } from "react-icons/tb";
import domToImage from "dom-to-image";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSnapshotStore } from "../../../../../hooks/snapshotStore";
import ModalContent from "./partials/ModalContent";
dayjs.extend(customParseFormat);

const Filter = forwardRef((props, ref) => {
  const { watch, control, setValue } = useForm();
  // const { isFilterBy, onFilter } = useData();
  let isFilterBy = "";
  let onFilter = () => {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const watchAfdeling = watch("afdeling");
  const watchEstate = watch("estate");
  const {
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
  } = useFilterDataDefaults();
  const setIsSnapshot = useSnapshotStore((state) => state.setIsSnapshot);

  const onSearchEstate = async (searchTerm) => {
    await fetchEstates({ name: searchTerm });
  };

  const onSearchAfdeling = async (searchTerm) => {
    await fetchAfdelings({
      estateId: watchEstate.value,
      name: searchTerm,
    });
  };

  const onSearchBlock = async (searchTerm) => {
    await fetchBlocks({
      afdelingId: watchAfdeling.value,
      name: searchTerm,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchEstates({});
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    const date = dayjs(
      moment(isFilterBy.fltFertilizationDate).format("DD/MM/Y"),
      "DD/MM/YYYY"
    );
    const defaultDateIfEmpty = dayjs(moment().format("DD/MM/Y"), "DD/MM/YYYY");

    if (isFilterBy.fltFertilizationDate === null) {
      setValue("date", defaultDateIfEmpty);
      return;
    }
    setValue("date", date);
  }, [isFilterBy]);

  const captureComponent = () => {
    const component = ref.current;
    if (!component) return;

    const estateName =
      isFilterBy.estateName !== undefined ? `_${isFilterBy.estateName}` : "";

    const afdelingName =
      isFilterBy.afdelingName !== undefined
        ? `_${isFilterBy.afdelingName}`
        : "";

    setIsSnapshot(true); // Set isSnapshot to true before capturing the screenshot

    // Tambahkan penundaan sebelum mengambil tangkapan layar
    setTimeout(() => {
      domToImage.toBlob(component).then((blob) => {
        const link = document.createElement("a");
        link.download = `${moment(isFilterBy.date).format(
          "D-MM-Y"
        )}${estateName}${afdelingName}_produksi kebun.jpg`;
        link.href = URL.createObjectURL(blob);
        link.click();

        // Set isSnapshot to false after the screenshot is taken
        setTimeout(() => {
          setIsSnapshot(false);
        }, 1500);
      });
    }, 1000); // Sesuaikan nilai penundaan jika diperlukan
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <ButtonComponent
        tooltipPlacement="bottom"
        tooltipTitle="Filter"
        value="Filter"
        size="none"
        type="button"
        className="bordered-button-transition fixed right-16 bottom-16 z-20 -ml-8 w-fit rounded-16 bg-red-mobile p-10 text-16 text-white md:hidden"
        onClick={showModal}
        icon={<TbFilter className="text-18 text-white" />}
      />
      <Modal
        title="Filter"
        zIndex={10}
        open={isModalOpen}
        onOk={showModal}
        onCancel={showModal}
        wrapClassName="backdrop-blur-sm"
        footer={
          <div className="flex flex-row items-center justify-end space-x-16 pt-16">
            <ButtonComponent
              value="Simpan Filter"
              type="submit"
              size="none"
              className="button-transition px-16 py-8 text-16"
              onClick={showModal}
            />
          </div>
        }
      >
        <ModalContent />
      </Modal>
      <div className="flex h-fit w-full flex-row items-center px-8 md:h-full md:space-x-8 md:px-8">
        <SelectHook
          control={control}
          data={[]}
          name="pks"
          placeholder="Pilih PKS"
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            // onSearchEstate(searchValue);
          }}
          afterOnChange={(data) => {
            // onFilter({
            //   value: data?.value,
            //   type: "fltEstate",
            // });
            // fetchAfdelings({ estateId: data.value });
          }}
          menuStyles={{
            option: {
              padding: "4px 16px",
            },
            input: {
              padding: "2px",
            },
          }}
        />
        <SelectHook
          control={control}
          data={[]}
          name="pks"
          placeholder="Pilih Trader"
          className="hidden max-w-[200px] md:inline"
          onInputChange={(searchValue) => {
            // onSearchEstate(searchValue);
          }}
          afterOnChange={(data) => {
            // onFilter({
            //   value: data?.value,
            //   type: "fltEstate",
            // });
            // fetchAfdelings({ estateId: data.value });
          }}
          menuStyles={{
            option: {
              padding: "4px 16px",
            },
            input: {
              padding: "2px",
            },
          }}
        />

        <Controller
          name="date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 14,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Pilih tanggal"
                  className="hidden md:inline"
                  value={value}
                  style={{
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    maxWidth: 250,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                  onChange={(date) => {
                    onChange(date);
                    if (date !== null) {
                      onFilter({
                        type: "fltFertilizationDate",
                        value: date,
                        filterHandler: ({ type, value, updateFilter }) => {
                          const dateObject = new Date(value);
                          const date = moment(dateObject).format("YYYY-MM-DD");
                          updateFilter({
                            [type]: date,
                          });
                        },
                      });
                    } else {
                      onFilter({
                        type: "fltFertilizationDate",
                        value: moment().format("YYYY-MM-DD"),
                      });
                    }
                  }}
                />
              </ConfigProvider>
            );
          }}
        />

        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Snapshot Halaman"
          value="Snapshot"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={() => {
            captureComponent(ref, isFilterBy);
          }}
          icon={<TbDownload className="text-18 text-gray-500" />}
        />
      </div>
    </ConfigProvider>
  );
});

export default Filter;
