import { notification } from "antd";
import { create } from "zustand";
import { getCompanyByIdApi, updateCompanyApi } from "../api/companyApi";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../helpers/Common";

export const useCompanyStore = create((set, get) => ({
  company: {},
  isLoading: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getCompanyById: async (id, silent) => {
    try {
      set({ isLoading: true });
      const response = await getCompanyByIdApi(id);
      if (!response.data.success) {
        set({ isLoading: false });
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Informasi Perusahaan/Koperasi/Grup",
          description: response.data.message,
        });
      }
      set({ company: response?.data?.record, isLoading: false });

      return response.data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      //
      return notification.error({
        message: "Gagal Mendapatkan Informasi Perusahaan/Koperasi/Grup",
        description: error.data,
      });
    }
  },
  updateCompany: async (id, body) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateCompanyApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        return notification.error({
          message: "Gagal Update Data Perusahaan/Koperasi/Grup",
          description: data.message,
          placement: "bottomRight",
        });
      }
      const toastMessage = `Berhasil update data Perusahaan ${data?.record?.name}`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      if (get().company?.id === id) {
        set({ company: data.record });
      }

      set({ isLoadingRequest: false });
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      return notification.error({
        message: "Gagal Update Data Perusahaan/Koperasi/Grup",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
