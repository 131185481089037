import {
  getCities,
  getProvinces,
  selectArea,
} from "../../../../features/areaSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../../atoms/ButtonComponent";
import { FaBuilding } from "react-icons/fa";
import InputHook from "../../../atoms/InputHook";
import React from "react";
import SelectHook from "../../../atoms/SelectHook";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCompanyStore } from "../../../../hooks/companyStore";

const GroupProfile = ({ refreshData }) => {
  const dispatch = useDispatch();
  // const company = useSelector(selectCompany);
  const [company, updateCompany] = useCompanyStore((state) => [
    state.company,
    state.updateCompany,
  ]);
  const area = useSelector(selectArea);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      state: "Indonesia",
    },
  });

  const watchProvince = watch("province");
  const provinceIsNull = watchProvince == undefined;

  const onSubmit = (data) => {
    const requestBody = {
      ...company,
      ...data,
      province: data.province.value,
      city: data.city.value,
    };

    updateCompany(company.id, requestBody);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.province !== undefined) {
        dispatch(getCities({ id: value.province.id }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    const state = {
      ...company,
      province: { label: company.province, value: company.province },
      city: { label: company.city, value: company.city },
    };
    reset(state);
  }, [company]);

  return (
    <div className="relative overflow-hidden rounded-16 border-1 border-gray-10 bg-white pt-24 md:mx-32">
      <div className="flex flex-row items-center space-x-16 px-24">
        <FaBuilding className="text-24 text-gray-500" />
        <h1 className="text-left font-medium text-16 text-gray-500 md:text-[20px]">
          Tentang Perusahaan/Koperasi/Grup
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-16 p-24 md:grid-cols-2">
          <InputHook
            register={register}
            label="Nama Perusahaan/Koperasi/Grup"
            name="name"
            autocomplete="on"
            className="mb-0 mt-0"
            inputClassName="input-large-border"
            placeholder="Masukkan nama koperasi"
          />
          <InputHook
            register={register}
            label="Website Perusahaan/Koperasi/Grup"
            name="url"
            autocomplete="on"
            className="mb-0 mt-0"
            inputClassName="input-large-border"
            placeholder="Masukkan website koperasi"
          />
          <InputHook
            register={register}
            label="Kontak Email"
            name="contactEmail"
            autocomplete="on"
            className="mb-0 mt-0"
            inputClassName="input-large-border"
            placeholder="Masukkan email koperasi"
          />
          <InputHook
            register={register}
            label="Nomer Telpon Koperasi"
            name="contactPhone"
            autocomplete="on"
            className="mb-0 mt-0"
            inputClassName="input-large-border"
            placeholder="Masukkan nomer telepon koperasi"
          />
          <InputHook
            register={register}
            label="Negara"
            name="country"
            autocomplete="on"
            marginAuto={false}
            className="mb-0 mt-0 md:col-span-2"
            inputClassName="input-large-border"
          />
          <SelectHook
            label="Provinsi"
            name="province"
            errors={errors?.province}
            data={area.provinces}
            control={control}
            placeholder="Pilih provinsi"
          />
          {!provinceIsNull && (
            <SelectHook
              label="Kota/Kabupaten"
              name="city"
              errors={errors?.city}
              data={area.cities}
              control={control}
              placeholder="Pilih kota/kabupaten"
            />
          )}
          <InputHook
            register={register}
            label="Kode Pos"
            name="postalCode"
            autocomplete="on"
            marginAuto={false}
            className="mb-0 mt-0 md:col-span-2"
            inputClassName="input-large-border"
            placeholder="Masukkan kode pos"
          />
          <InputHook
            register={register}
            label="Alamat Koperasi"
            name="address"
            autocomplete="on"
            marginAuto={false}
            className="mb-0 mt-0 w-full md:col-span-2"
            inputClassName="input-large-border"
            placeholder="Masukkan alamat koperasi"
          />
        </div>
        <div className="card-footer-container">
          <ButtonComponent value="Simpan" size="sm" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default GroupProfile;
