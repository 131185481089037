export const batutungku2008 = {
  type: "FeatureCollection",
  name: "batutungkutt08_172ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10568.597,
        Luas: 1.057,
        PERIMETRO: 487.88,
        No_Kav: "61",
        Nm_Pemilik: "Kasiyem",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711498474034684, -4.013577282628592],
              [114.711066342467987, -4.015170214466474],
              [114.710899243152184, -4.014820665818933],
              [114.710538307459544, -4.014806225152152],
              [114.710907154248389, -4.013453785329173],
              [114.711498474034684, -4.013577282628592],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13719.468,
        Luas: 1.372,
        PERIMETRO: 1021.754,
        No_Kav: "94",
        Nm_Pemilik: "Eko Wahyudi",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711088543833441, -4.015196584350588],
              [114.71116437817723, -4.01493698645224],
              [114.711334843785735, -4.015159562081166],
              [114.712344082023009, -4.015812733778438],
              [114.71306782134387, -4.016207866613087],
              [114.713390804072404, -4.016204035325175],
              [114.713810884138979, -4.016101000308462],
              [114.714086802692606, -4.01591739263788],
              [114.714153649251614, -4.015888246743385],
              [114.714291305205052, -4.015905395229519],
              [114.714662148545955, -4.016099199698819],
              [114.714503301874473, -4.016199326057086],
              [114.714149056480679, -4.016039092184826],
              [114.713823040851878, -4.016230940203152],
              [114.713814286844382, -4.016377583412111],
              [114.713534498691629, -4.016452226802596],
              [114.713534708754068, -4.0163767982578],
              [114.71339701780353, -4.016372221030484],
              [114.713132858692973, -4.016828244232943],
              [114.712907877308211, -4.016701896985131],
              [114.712866989765303, -4.016400065711367],
              [114.712242119994968, -4.016021164648925],
              [114.712004317047061, -4.016003734407033],
              [114.711562572515859, -4.015797158331983],
              [114.711088543833441, -4.015196584350588],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 6368.883,
        Luas: 0.637,
        PERIMETRO: 487.039,
        No_Kav: "71",
        Nm_Pemilik: "Kamaludin",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.704267161967834, -4.014161905762968],
              [114.702333292675306, -4.013558796311727],
              [114.7040586329272, -4.013560918970662],
              [114.704267161967834, -4.014161905762968],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12420.464,
        Luas: 1.242,
        PERIMETRO: 700.995,
        No_Kav: "62",
        Nm_Pemilik: "Dany Suyanto",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.710399649212803, -4.0151238561591],
              [114.710546323145508, -4.015435802310547],
              [114.71091349960615, -4.015360850690987],
              [114.710923143961935, -4.01597254795843],
              [114.710627677430139, -4.016108488151296],
              [114.709886181460533, -4.016106402791067],
              [114.710007803568118, -4.01590538775659],
              [114.709986577392002, -4.015377240501361],
              [114.709881242792832, -4.015164189588616],
              [114.709749203795639, -4.015030846595011],
              [114.709530343435318, -4.01482887401232],
              [114.708949171339285, -4.014314348900159],
              [114.710399649212803, -4.0151238561591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.002,
        Luas: 2.0,
        PERIMETRO: 648.351,
        No_Kav: "1",
        Nm_Pemilik: "Muskan",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721650667412575, -4.024288881797556],
              [114.722432193280312, -4.024297719148076],
              [114.722641652413444, -4.024658262161771],
              [114.722660639500972, -4.024925805559044],
              [114.72189979959019, -4.02654182196386],
              [114.721642191394324, -4.026621138948336],
              [114.721650667412575, -4.024288881797556],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.866,
        Luas: 2.0,
        PERIMETRO: 691.311,
        No_Kav: "2",
        Nm_Pemilik: "Baderiyah",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720982176468596, -4.024281322064931],
              [114.721650667412575, -4.024288881797556],
              [114.721642191394324, -4.026621138948336],
              [114.720975315532783, -4.026826467246331],
              [114.720982176468596, -4.024281322064931],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.132,
        Luas: 2.0,
        PERIMETRO: 725.471,
        No_Kav: "3",
        Nm_Pemilik: "Moh. Rukin",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720366947581297, -4.024274364088496],
              [114.720982176468596, -4.024281322064931],
              [114.720975315532783, -4.026826467246331],
              [114.720486193327289, -4.026977066764974],
              [114.720358941112622, -4.027020610974557],
              [114.720366947581297, -4.024274364088496],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.358,
        Luas: 2.0,
        PERIMETRO: 918.214,
        No_Kav: "5",
        Nm_Pemilik: "Tugino",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719210628233895, -4.024261285440653],
              [114.719788391061854, -4.024267820504727],
              [114.719781373702006, -4.027087916417861],
              [114.719529019337685, -4.027060239962603],
              [114.71938645067307, -4.026888117846934],
              [114.719185851601765, -4.026889205586913],
              [114.719056565555945, -4.026670888025055],
              [114.718865370698154, -4.026791898419435],
              [114.718789418865981, -4.026694729001812],
              [114.719617546557643, -4.026102326762248],
              [114.719426297533133, -4.025842911333132],
              [114.719135017649364, -4.026051399328778],
              [114.718995480353385, -4.025346211679055],
              [114.719313783931256, -4.024884194505968],
              [114.719210628233895, -4.024261285440653],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.122,
        Luas: 2.0,
        PERIMETRO: 746.211,
        No_Kav: "4",
        Nm_Pemilik: "Evi",
        block: "Blok 1",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719788391093005, -4.024267820497002],
              [114.720366947581297, -4.024274364088496],
              [114.720358941112622, -4.027020610974557],
              [114.720069806125309, -4.027119549593698],
              [114.719781373713971, -4.027087916417896],
              [114.719788391093005, -4.024267820497002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 736.055,
        No_Kav: "6",
        Nm_Pemilik: "Ali Masrur",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721078653457297, -4.021653259464814],
              [114.722366080708312, -4.024181289520378],
              [114.721071464170379, -4.024167361917283],
              [114.721078653457297, -4.021653259464814],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.997,
        Luas: 2.0,
        PERIMETRO: 713.683,
        No_Kav: "7",
        Nm_Pemilik: "Khoirur Rozak",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720457304970836, -4.021539483088054],
              [114.721022117262351, -4.021542243170279],
              [114.721078653457297, -4.021653259464814],
              [114.721071464170379, -4.024167361917283],
              [114.720449860899393, -4.024160673934548],
              [114.720457304970836, -4.021539483088054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.013,
        Luas: 2.0,
        PERIMETRO: 757.579,
        No_Kav: "8",
        Nm_Pemilik: "Siti Rubayah",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719870563600665, -4.021536615444421],
              [114.720457304970836, -4.021539483088054],
              [114.720449860899393, -4.024160673934548],
              [114.719582505077511, -4.024151341042947],
              [114.719550561133161, -4.023991881241032],
              [114.719870056879444, -4.023713436353961],
              [114.719864450989007, -4.023708278258662],
              [114.719870563600665, -4.021536615444421],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.008,
        Luas: 2.0,
        PERIMETRO: 634.552,
        No_Kav: "9",
        Nm_Pemilik: "Sutrisno",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718925222771404, -4.021531994911673],
              [114.719870563600665, -4.021536615444421],
              [114.719864450989007, -4.023708278258662],
              [114.719414841312371, -4.023294588400681],
              [114.719045816579936, -4.022881074653005],
              [114.718921590616006, -4.022851195195669],
              [114.718925222771404, -4.021531994911673],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10000.272,
        Luas: 1.0,
        PERIMETRO: 515.412,
        No_Kav: "11",
        Nm_Pemilik: "Abdillah",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715669150871406, -4.020865221312558],
              [114.715730383781022, -4.020727900908127],
              [114.716296997951773, -4.021203077382224],
              [114.716762819983032, -4.021453908931588],
              [114.717203926175642, -4.021475516738568],
              [114.717254218465754, -4.021468506230977],
              [114.717252846175825, -4.022064995316105],
              [114.716999348611196, -4.022101297185369],
              [114.716523214308964, -4.021911544329808],
              [114.715875849299181, -4.021298648411119],
              [114.715669150871406, -4.020865221312558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.187,
        Luas: 2.0,
        PERIMETRO: 630.675,
        No_Kav: "10",
        Nm_Pemilik: "Tatik",
        block: "Blok 2",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717254218806389, -4.021468506118025],
              [114.717812667903388, -4.021390656166855],
              [114.718577982481818, -4.021530296523264],
              [114.718925222771404, -4.021531994911673],
              [114.718921590616006, -4.022851195195669],
              [114.71810326176697, -4.022654366997498],
              [114.718083871384806, -4.022333496436093],
              [114.717557368163455, -4.022021386961819],
              [114.717252846416727, -4.022064995236195],
              [114.717254218806389, -4.021468506118025],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 737.636,
        No_Kav: "12",
        Nm_Pemilik: "Siti Maryam",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71972361953776, -4.018926985254995],
              [114.721004548019579, -4.021465090537436],
              [114.719718645351463, -4.021458161350322],
              [114.71972361953776, -4.018926985254995],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 719.9,
        No_Kav: "13",
        Nm_Pemilik: "Endang",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71965504845582, -4.018791114381724],
              [114.71972361953776, -4.018926985254995],
              [114.719718645351463, -4.021458161350322],
              [114.719106072231611, -4.021454859741121],
              [114.71911171828269, -4.018791501684101],
              [114.71965504845582, -4.018791114381724],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.998,
        Luas: 2.0,
        PERIMETRO: 723.116,
        No_Kav: "14",
        Nm_Pemilik: "Khamdiah",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719106072231611, -4.021454859741121],
              [114.718586093910503, -4.021452056832278],
              [114.718495445236428, -4.021435073620235],
              [114.718498861170346, -4.018791937814721],
              [114.71911171828269, -4.018791501684101],
              [114.719106072231611, -4.021454859741121],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.001,
        Luas: 2.0,
        PERIMETRO: 712.798,
        No_Kav: "15",
        Nm_Pemilik: "Nanik",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718495445236428, -4.021435073620235],
              [114.717864980891832, -4.021316954584],
              [114.717869818009774, -4.018792385151763],
              [114.718498861170346, -4.018791937814721],
              [114.718495445236428, -4.021435073620235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.009,
        Luas: 2.0,
        PERIMETRO: 709.345,
        No_Kav: "16",
        Nm_Pemilik: "Rahayu Ningsih",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717864980891832, -4.021316954584],
              [114.717823905135162, -4.021309258924254],
              [114.717227822159714, -4.021395224378115],
              [114.717233934814246, -4.018792837075099],
              [114.717869818009774, -4.018792385151763],
              [114.717864980891832, -4.021316954584],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 705.924,
        No_Kav: "17",
        Nm_Pemilik: "Kasiyem",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717227822159714, -4.021395224378115],
              [114.717207363851045, -4.021398175119584],
              [114.71679654817936, -4.021381392889975],
              [114.716693972660309, -4.021331092811209],
              [114.716599868599332, -4.021279353990256],
              [114.716599776953657, -4.018793286952877],
              [114.717233934814246, -4.018792837075099],
              [114.717227822159714, -4.021395224378115],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.001,
        Luas: 2.0,
        PERIMETRO: 671.671,
        No_Kav: "18",
        Nm_Pemilik: "Dany Suyanto",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716599868599332, -4.021279353990256],
              [114.716339679564683, -4.021136301237332],
              [114.716103690313588, -4.020932464618625],
              [114.715868851460726, -4.0207397534593],
              [114.715878218323681, -4.018793798231602],
              [114.716599776953657, -4.018793286952877],
              [114.716599868599332, -4.021279353990256],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15623.279,
        Luas: 1.562,
        PERIMETRO: 586.804,
        No_Kav: "20",
        Nm_Pemilik: "Luluk Khoiriyah",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714986200658132, -4.020738685787414],
              [114.714965438587498, -4.020749730896356],
              [114.714707185005253, -4.020813796795922],
              [114.714361848163463, -4.020706382981442],
              [114.714122828155041, -4.020483568699331],
              [114.714035706280939, -4.020325972912405],
              [114.713962487139824, -4.020140648403407],
              [114.713962701532225, -4.020063721499064],
              [114.713962886970549, -4.019997182524748],
              [114.714138575138733, -4.019794045670098],
              [114.714484698104428, -4.019619155413911],
              [114.714683608749681, -4.01935129197957],
              [114.71469382078979, -4.018794636077331],
              [114.714999281109471, -4.018794420764149],
              [114.714986200658132, -4.020738685787414],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 637.199,
        No_Kav: "19",
        Nm_Pemilik: "Sri Rahayu",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715868851460726, -4.0207397534593],
              [114.715755875217624, -4.020647044390928],
              [114.715725660602359, -4.020681336414521],
              [114.715680018832558, -4.020654550972864],
              [114.715518890694895, -4.020593934725124],
              [114.715242358028576, -4.020602413838097],
              [114.714986200658132, -4.020738685787414],
              [114.71499928159912, -4.018794420519995],
              [114.715878218323681, -4.018793798231602],
              [114.715868851460726, -4.0207397534593],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10000.943,
        Luas: 1.0,
        PERIMETRO: 671.408,
        No_Kav: "21",
        Nm_Pemilik: "Sana ah",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715725660602359, -4.020681336414521],
              [114.715617775255794, -4.020803780649589],
              [114.715541347089172, -4.020826121499885],
              [114.715346090022152, -4.020794091119506],
              [114.71511569491156, -4.021043338438179],
              [114.714767425375101, -4.021003914472566],
              [114.714576352511827, -4.020880352497721],
              [114.714326687095635, -4.021175679649547],
              [114.713657718386685, -4.020820102345898],
              [114.713654747470514, -4.020512531700474],
              [114.713754905053293, -4.020289830583383],
              [114.713525603094624, -4.020146938440524],
              [114.713548659049991, -4.02006202394336],
              [114.713962701532225, -4.020063721499064],
              [114.713962487139824, -4.020140648403407],
              [114.714035706280939, -4.020325972912405],
              [114.714122828155041, -4.020483568699331],
              [114.714361848163463, -4.020706382981442],
              [114.714707185005253, -4.020813796795922],
              [114.714965438587498, -4.020749730896356],
              [114.714986200658132, -4.020738685787414],
              [114.715242358028576, -4.020602413838097],
              [114.715518890694895, -4.020593934725124],
              [114.715680018832558, -4.020654550972864],
              [114.715725660602359, -4.020681336414521],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5408.052,
        Luas: 0.541,
        PERIMETRO: 465.638,
        No_Kav: "22",
        Nm_Pemilik: "Taufikurrohman",
        block: "Blok 3",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713548659049991, -4.02006202394336],
              [114.713618436168971, -4.019805036356486],
              [114.713779107157265, -4.019847777774937],
              [114.714148252860539, -4.019264446655463],
              [114.714404363719837, -4.019403569337096],
              [114.71453524367017, -4.019146353456287],
              [114.714576248011966, -4.018794719168133],
              [114.71469382078979, -4.018794636077331],
              [114.714683608749681, -4.01935129197957],
              [114.714484698104428, -4.019619155413911],
              [114.714138575138733, -4.019794045670098],
              [114.713962886970549, -4.019997182524748],
              [114.713962701532225, -4.020063721499064],
              [114.713548659049991, -4.02006202394336],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.016,
        Luas: 2.0,
        PERIMETRO: 730.159,
        No_Kav: "51",
        Nm_Pemilik: "Khoirur Rozak",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719594244199968, -4.018713183498347],
              [114.718315729674401, -4.018712541784748],
              [114.718325156782498, -4.016214515047328],
              [114.718349585663617, -4.016214469829237],
              [114.719594244199968, -4.018713183498347],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.058,
        Luas: 2.0,
        PERIMETRO: 697.307,
        No_Kav: "50",
        Nm_Pemilik: "Ali Masrur",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718315729674401, -4.018712541784748],
              [114.7176668330534, -4.018712215612857],
              [114.71767067520075, -4.016215726437552],
              [114.718325156782498, -4.016214515047328],
              [114.718315729674401, -4.018712541784748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.02,
        Luas: 2.0,
        PERIMETRO: 697.055,
        No_Kav: "49",
        Nm_Pemilik: "Tugino",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.7176668330534, -4.018712215612857],
              [114.717014343155782, -4.018711886696037],
              [114.717018989236124, -4.016216931866202],
              [114.71767067520075, -4.016215726437552],
              [114.7176668330534, -4.018712215612857],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.013,
        Luas: 2.0,
        PERIMETRO: 696.805,
        No_Kav: "48",
        Nm_Pemilik: "Evi",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717014340298192, -4.018711885970089],
              [114.716360782395157, -4.018711556701847],
              [114.716367569829401, -4.016218136666394],
              [114.717018989236124, -4.016216931866202],
              [114.717014340298192, -4.018711885970089],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 696.554,
        No_Kav: "47",
        Nm_Pemilik: "Moh. Rukin",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716360782395157, -4.018711556701847],
              [114.715709949031989, -4.018711228284168],
              [114.715712623572045, -4.016219346771503],
              [114.716367569829401, -4.016218136666394],
              [114.716360782395157, -4.018711556701847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 696.304,
        No_Kav: "46",
        Nm_Pemilik: "Baderiyah",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715709949031989, -4.018711228284168],
              [114.715054461388661, -4.01871089729479],
              [114.715061527861593, -4.016220549771834],
              [114.715712623572045, -4.016219346771503],
              [114.715709949031989, -4.018711228284168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10798.494,
        Luas: 1.08,
        PERIMETRO: 752.024,
        No_Kav: "45",
        Nm_Pemilik: "Muskan",
        block: "Blok 7",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715054461287323, -4.018710897243538],
              [114.714483642945751, -4.01871060806095],
              [114.714864484359893, -4.018335227949492],
              [114.714766816137967, -4.017983270324047],
              [114.714757930608712, -4.017631562029157],
              [114.714655164494502, -4.017339029580483],
              [114.714725527680841, -4.016868664928375],
              [114.714864847235944, -4.016433166785826],
              [114.714489891033537, -4.016466787224116],
              [114.714475673060718, -4.016258709308873],
              [114.71469311062495, -4.01611072109548],
              [114.715023275680537, -4.016220620101653],
              [114.715061527378225, -4.016220549691978],
              [114.715054461287323, -4.018710897243538],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 733.221,
        No_Kav: "52",
        Nm_Pemilik: "Siti Rubayah",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717044485383056, -4.013630336101783],
              [114.717059314465345, -4.013635042395992],
              [114.718318802238386, -4.016143035805416],
              [114.717038482527371, -4.016148157060977],
              [114.717044485383056, -4.013630336101783],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.012,
        Luas: 2.0,
        PERIMETRO: 694.436,
        No_Kav: "53",
        Nm_Pemilik: "Sutrisno",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716402903006767, -4.013725739292796],
              [114.71646020364831, -4.013669279207151],
              [114.716706175832627, -4.013590021520089],
              [114.716886190737725, -4.013580098139905],
              [114.717044485383056, -4.013630336101783],
              [114.717038482527371, -4.016148157060977],
              [114.716394379294286, -4.016150732256976],
              [114.716402903006767, -4.013725739292796],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 675.008,
        No_Kav: "54",
        Nm_Pemilik: "Tatik",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715568627544712, -4.014507597695233],
              [114.71581932099312, -4.014455330207689],
              [114.716015673287814, -4.014203330908684],
              [114.716199893589661, -4.013925770588402],
              [114.716402903006767, -4.013725739292796],
              [114.716394379294286, -4.016150732256976],
              [114.715563804157455, -4.016154052680597],
              [114.715568627544712, -4.014507597695233],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.002,
        Luas: 2.0,
        PERIMETRO: 713.293,
        No_Kav: "55",
        Nm_Pemilik: "Abdillah",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715034514641957, -4.016156168840602],
              [114.714716074193618, -4.016031943983243],
              [114.714658883100171, -4.016004262933715],
              [114.714671277372801, -4.013655030011326],
              [114.714891389934976, -4.013974240446706],
              [114.714918412912709, -4.014214157318006],
              [114.714945426197488, -4.014457550126573],
              [114.715166753930291, -4.014527690183678],
              [114.715450219886662, -4.014532284453477],
              [114.715568627544712, -4.014507597695233],
              [114.715563804157455, -4.016154052680597],
              [114.715034514641957, -4.016156168840602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.002,
        Luas: 2.0,
        PERIMETRO: 685.501,
        No_Kav: "56",
        Nm_Pemilik: "Siti Maryam",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714658883100171, -4.016004262933715],
              [114.71429072817881, -4.015826072940038],
              [114.714154875186651, -4.015817819366564],
              [114.714073792491064, -4.015846456441798],
              [114.713945496408911, -4.015927839549512],
              [114.713951111292843, -4.013610068121353],
              [114.71464414212673, -4.013615678072297],
              [114.714671277372801, -4.013655030011326],
              [114.714658883100171, -4.016004262933715],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 688.681,
        No_Kav: "57",
        Nm_Pemilik: "Endang",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713945490783374, -4.015927837648815],
              [114.713764913069582, -4.016042393996091],
              [114.713390986766484, -4.016138434258915],
              [114.713284412152333, -4.016139041774622],
              [114.713289729643435, -4.013604713821479],
              [114.713951111292843, -4.013610068121353],
              [114.713945490783374, -4.015927837648815],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.013,
        Luas: 2.0,
        PERIMETRO: 688.026,
        No_Kav: "58",
        Nm_Pemilik: "Khamdiah",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713284412152333, -4.016139041774622],
              [114.713082648035922, -4.016140192282432],
              [114.712619743137608, -4.015885254257941],
              [114.712626107588235, -4.01359934172185],
              [114.713289729643435, -4.013604713821479],
              [114.713284412152333, -4.016139041774622],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 643.762,
        No_Kav: "59",
        Nm_Pemilik: "Nanik",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712619740634352, -4.015885253412151],
              [114.712383437481776, -4.015755115000622],
              [114.711908716862425, -4.015452014172446],
              [114.711828208517431, -4.015399402137232],
              [114.711834542748392, -4.013592931510185],
              [114.712626107588235, -4.01359934172185],
              [114.712619740634352, -4.015885253412151],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 9673.722,
        Luas: 0.967,
        PERIMETRO: 470.409,
        No_Kav: "60",
        Nm_Pemilik: "Rahayu Ningsih",
        block: "Blok 8",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711828208517431, -4.015399402137232],
              [114.711384459910278, -4.01510941316012],
              [114.711233499886532, -4.014896440101335],
              [114.711210202521812, -4.014817652849754],
              [114.711535019025845, -4.013590505585348],
              [114.711834542211378, -4.013592931508676],
              [114.711828208517431, -4.015399402137232],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 663.499,
        No_Kav: "93",
        Nm_Pemilik: "Abdul Azis",
        block: "Blok 13",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716658801523906, -4.013278099755036],
              [114.716778158815856, -4.013341355920243],
              [114.716888455497951, -4.013467456698049],
              [114.716445997034896, -4.013589800483036],
              [114.716125045229532, -4.013885466591535],
              [114.715942772392353, -4.014168034299867],
              [114.71578386190653, -4.014380867180722],
              [114.715467022109152, -4.014453656587647],
              [114.715188990479405, -4.014456754501966],
              [114.715015378495494, -4.014401978434106],
              [114.714958730307544, -4.01394424047792],
              [114.714649605165135, -4.013497127619347],
              [114.714523534157507, -4.013275514312747],
              [114.716658801523906, -4.013278099755036],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.995,
        Luas: 2.0,
        PERIMETRO: 1001.573,
        No_Kav: "92",
        Nm_Pemilik: "Sutopo",
        block: "Blok 13",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714042148308422, -4.012273680862894],
              [114.713874046938443, -4.011885300454114],
              [114.713636959160809, -4.011586716227341],
              [114.713378290664267, -4.011369856923579],
              [114.713930387451953, -4.011371076768967],
              [114.713925716131484, -4.011475458148674],
              [114.714054683828991, -4.011612561544625],
              [114.714045368505793, -4.011849439809202],
              [114.714314791754504, -4.012065898869279],
              [114.71446878989228, -4.012052790480955],
              [114.714542321143895, -4.01259023115094],
              [114.714780016664818, -4.012744861330637],
              [114.715302724087579, -4.012461330601627],
              [114.715677386180019, -4.012629447876916],
              [114.715700416127987, -4.01277017625487],
              [114.716658801523906, -4.013278099755036],
              [114.714523534157507, -4.013275514312747],
              [114.71441462326591, -4.013084065783355],
              [114.714042148308422, -4.012273680862894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 18726.458,
        Luas: 1.873,
        PERIMETRO: 669.487,
        No_Kav: "91",
        Nm_Pemilik: "Abdul Azis",
        block: "Blok 13",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713378290276054, -4.011369856531569],
              [114.712843359421925, -4.010921388845581],
              [114.712636308791232, -4.010811066677491],
              [114.712367531137318, -4.010819619183009],
              [114.711785439572736, -4.010883520053638],
              [114.71161551752941, -4.010890798648291],
              [114.711656352774753, -4.010605702642915],
              [114.711792779331063, -4.01034402045523],
              [114.712106364296915, -4.010187661367129],
              [114.712444767706444, -4.010115232588074],
              [114.713030528653917, -4.010429257609709],
              [114.713635522176858, -4.010584000615575],
              [114.71395577076791, -4.010804297685511],
              [114.713930385873965, -4.011371073577359],
              [114.713378290276054, -4.011369856531569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.004,
        Luas: 2.0,
        PERIMETRO: 668.168,
        No_Kav: "90",
        Nm_Pemilik: "Sri Purwanti",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713290483785471, -4.011347492473958],
              [114.713629035287099, -4.011633409519896],
              [114.713846535780959, -4.011931073557916],
              [114.714014481043606, -4.012320543903209],
              [114.714182485399121, -4.012688796323235],
              [114.714379590808335, -4.013118207039005],
              [114.714500928488789, -4.013330265788674],
              [114.7145969563704, -4.013512791176789],
              [114.713283785518485, -4.013501952627145],
              [114.713290483785471, -4.011347492473958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.011,
        Luas: 2.0,
        PERIMETRO: 697.098,
        No_Kav: "89",
        Nm_Pemilik: "Iskandar Zulkarnain",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712619946562015, -4.010860915315066],
              [114.712813979945054, -4.010945070096327],
              [114.713290483785471, -4.011347492473958],
              [114.713283785518485, -4.013501952627145],
              [114.712612607612698, -4.013496411719543],
              [114.712619946562015, -4.010860915315066],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.01,
        Luas: 2.0,
        PERIMETRO: 716.225,
        No_Kav: "88",
        Nm_Pemilik: "Sri Sularmi",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711997282831987, -4.010902559518868],
              [114.712419820138052, -4.010859091655981],
              [114.712617018082966, -4.010859644981827],
              [114.712619946562015, -4.010860915315066],
              [114.712612607612698, -4.013496411719543],
              [114.711992865554834, -4.013491294319773],
              [114.711997282831987, -4.010902559518868],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 707.243,
        No_Kav: "87",
        Nm_Pemilik: "Iskandar Zulkarnain",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711364177895888, -4.010916820025566],
              [114.711750559036972, -4.010927940423163],
              [114.711997282831987, -4.010902559518868],
              [114.711992862721544, -4.013491294311815],
              [114.711560317878124, -4.013487724484047],
              [114.711355360662338, -4.013437879807568],
              [114.711364177895888, -4.010916820025566],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.007,
        Luas: 2.0,
        PERIMETRO: 703.427,
        No_Kav: "86",
        Nm_Pemilik: "Purwanto",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71070386147521, -4.010804393890963],
              [114.710933655806272, -4.010904428925508],
              [114.711364177895888, -4.010916820025566],
              [114.711355360662338, -4.013437879807568],
              [114.710697054608673, -4.013277782240299],
              [114.71070386147521, -4.010804393890963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 705.016,
        No_Kav: "85",
        Nm_Pemilik: "Endang P",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.710055529470552, -4.010577015086228],
              [114.710124583787547, -4.010598030830038],
              [114.710468288078289, -4.010701842442666],
              [114.71070386147521, -4.010804393890963],
              [114.710697054608673, -4.013277782240299],
              [114.710053845078775, -4.01312135590603],
              [114.710055529470552, -4.010577015086228],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15111.474,
        Luas: 1.511,
        PERIMETRO: 659.273,
        No_Kav: "84",
        Nm_Pemilik: "Yayah",
        block: "Blok 12",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.709426948145193, -4.012968896460507],
              [114.709695201245538, -4.010627987412053],
              [114.709843109424952, -4.010512367880774],
              [114.710055529456355, -4.010577015090938],
              [114.710053844857583, -4.013121355831288],
              [114.709426948145193, -4.012968896460507],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.011,
        Luas: 2.0,
        PERIMETRO: 581.334,
        No_Kav: "72",
        Nm_Pemilik: "Mashuri Huda",
        block: "Blok 10",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703322400955827, -4.012460972870204],
              [114.703319568905343, -4.013474595447444],
              [114.701691568488869, -4.013466206224565],
              [114.701732889620985, -4.012456840943338],
              [114.703322400955827, -4.012460972870204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 651.376,
        No_Kav: "73",
        Nm_Pemilik: "M. Ilyas",
        block: "Blok 10",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703326149860033, -4.011119389978245],
              [114.703322400955827, -4.012460972870204],
              [114.701732889620985, -4.012456840943338],
              [114.701745153523873, -4.012157257014957],
              [114.702223636038212, -4.012165331515845],
              [114.702210219257665, -4.011116176005356],
              [114.703326149860033, -4.011119389978245],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 7913.668,
        Luas: 0.791,
        PERIMETRO: 388.173,
        No_Kav: "74",
        Nm_Pemilik: "Sunyono",
        block: "Blok 10",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703327062698818, -4.010792856888868],
              [114.703326149861724, -4.011119389375597],
              [114.702210219129995, -4.011116175617075],
              [114.70219968320832, -4.010292373680531],
              [114.703327062698818, -4.010792856888868],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 745.84,
        No_Kav: "79",
        Nm_Pemilik: "Guntur F.",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.705599321012073, -4.010017098668503],
              [114.706102214228139, -4.009884464036165],
              [114.706776118192096, -4.010469746985324],
              [114.706769232724554, -4.010917015358195],
              [114.706831155049755, -4.010951160767929],
              [114.706826041614534, -4.011890785199285],
              [114.706580306623621, -4.01194694409072],
              [114.70658409866121, -4.011374114380114],
              [114.70615141888598, -4.011372896883159],
              [114.705934773980516, -4.010931653164976],
              [114.705923049627998, -4.011367766959234],
              [114.70560048728089, -4.011366895876886],
              [114.705595604809091, -4.011366882690743],
              [114.705599321012073, -4.010017098668503],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.417,
        Luas: 2.0,
        PERIMETRO: 773.55,
        No_Kav: "78",
        Nm_Pemilik: "Alwi Abdilah",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.704431605936435, -4.01136373823407],
              [114.704427008640693, -4.011155718495256],
              [114.704500181574247, -4.011086706490603],
              [114.704476855444668, -4.01072833554479],
              [114.704391848971312, -4.010679236379995],
              [114.704384263052276, -4.010491919098876],
              [114.704194688643042, -4.010469520940542],
              [114.704192886650503, -4.010784539015937],
              [114.704022441706869, -4.010841062097642],
              [114.703820152687882, -4.010685769697968],
              [114.703805037947546, -4.010290777192293],
              [114.704046259047388, -4.010081654997299],
              [114.704235398763686, -4.010275796642421],
              [114.704425514963518, -4.010120195550351],
              [114.705142803839536, -4.010363761665549],
              [114.705504742950936, -4.010042042276051],
              [114.705599320343936, -4.010017098890057],
              [114.705595604680241, -4.011366882690398],
              [114.705292782789797, -4.011366064799398],
              [114.704431605936435, -4.01136373823407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.021,
        Luas: 2.0,
        PERIMETRO: 587.232,
        No_Kav: "83",
        Nm_Pemilik: "Noor Bayah",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708551764765346, -4.011383652796709],
              [114.708896566543046, -4.011215566153864],
              [114.709570096768118, -4.011349530578634],
              [114.709372927803827, -4.012975195317511],
              [114.708546136085744, -4.013134460430365],
              [114.708551764765346, -4.011383652796709],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 638.062,
        No_Kav: "82",
        Nm_Pemilik: "Sutamaji",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707501556960452, -4.011785207865575],
              [114.708041357399424, -4.011906244063562],
              [114.708048404517399, -4.011629031064937],
              [114.708551764765346, -4.011383652796709],
              [114.708546136085744, -4.013134460430365],
              [114.707498450410995, -4.0133362750875],
              [114.707501556960452, -4.011785207865575],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.013,
        Luas: 2.0,
        PERIMETRO: 918.964,
        No_Kav: "81",
        Nm_Pemilik: "Siti Chalimah",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706583176705138, -4.013512582750918],
              [114.706105437459712, -4.013507065558991],
              [114.706111612698649, -4.011620105466013],
              [114.706243128417896, -4.011457342788494],
              [114.706242189317592, -4.011889373882624],
              [114.706320993812525, -4.01193489060921],
              [114.706319172028486, -4.012310298050084],
              [114.706409936549321, -4.012388090398861],
              [114.706408688200142, -4.012835374495181],
              [114.707287481751266, -4.012256965267157],
              [114.707301471126641, -4.011740343737507],
              [114.707501556960452, -4.011785207865575],
              [114.707498450410995, -4.0133362750875],
              [114.706583176705138, -4.013512582750918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.01,
        Luas: 2.0,
        PERIMETRO: 758.917,
        No_Kav: "80",
        Nm_Pemilik: "Syaifudin",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706105437459712, -4.013507065558991],
              [114.705289575951184, -4.013497642327902],
              [114.705292782789797, -4.011366064799398],
              [114.70560048728089, -4.011366895876886],
              [114.705923049627998, -4.011367766959234],
              [114.70590329345174, -4.012102731511014],
              [114.706049315385528, -4.012105858275612],
              [114.706050459856087, -4.011695785356403],
              [114.706111612698649, -4.011620105466013],
              [114.706105437459712, -4.013507065558991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.152,
        Luas: 2.0,
        PERIMETRO: 659.618,
        No_Kav: "77",
        Nm_Pemilik: "M. Ashari",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.705289575951184, -4.013497642327902],
              [114.704551264577745, -4.013489114753284],
              [114.704561261248912, -4.01198640545247],
              [114.704473343311093, -4.011986067706158],
              [114.704474149548332, -4.011697380781103],
              [114.704437807514054, -4.011644346969804],
              [114.704431605936435, -4.01136373823407],
              [114.705292782789797, -4.011366064799398],
              [114.705289575951184, -4.013497642327902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 568.981,
        No_Kav: "76",
        Nm_Pemilik: "Santoso",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.704551264577745, -4.013489114753284],
              [114.703411697672834, -4.013475951528466],
              [114.703414623254432, -4.012056599233719],
              [114.704560767516043, -4.012060628426863],
              [114.704551264577745, -4.013489114753284],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12597.565,
        Luas: 1.26,
        PERIMETRO: 527.036,
        No_Kav: "75",
        Nm_Pemilik: "Edi Lukito",
        block: "Blok 11",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703414623254432, -4.012056599233719],
              [114.703417566996379, -4.010627745006096],
              [114.70383999269076, -4.010840548234911],
              [114.703913086004178, -4.010800037614239],
              [114.704042073592049, -4.010965207232984],
              [114.70411036626848, -4.011129585612804],
              [114.704118313168649, -4.011343415153066],
              [114.704255723327435, -4.011478799753995],
              [114.704344419218344, -4.011692943747554],
              [114.704343589080494, -4.011990171876183],
              [114.704473319456454, -4.011994608936861],
              [114.704473343311093, -4.011986067706158],
              [114.704561259742675, -4.011986407005203],
              [114.704560767513172, -4.01206062841207],
              [114.703414623254432, -4.012056599233719],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 718.618,
        No_Kav: "70",
        Nm_Pemilik: "Mujilan",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.704938445116113, -4.016103429143296],
              [114.704333077154459, -4.014274330537418],
              [114.704092186133153, -4.013570060639348],
              [114.705167474205098, -4.013579284510693],
              [114.705157442420017, -4.016103334270229],
              [114.704938445116113, -4.016103429143296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.002,
        Luas: 2.0,
        PERIMETRO: 701.225,
        No_Kav: "69",
        Nm_Pemilik: "Mulyadi",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.705167474205098, -4.013579284510693],
              [114.70581164690492, -4.01358480987613],
              [114.705804247204938, -4.016103053843356],
              [114.705157442420017, -4.016103334270229],
              [114.705167474205098, -4.013579284510693],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.009,
        Luas: 2.0,
        PERIMETRO: 700.269,
        No_Kav: "68",
        Nm_Pemilik: "Enal",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70581164690492, -4.01358480987613],
              [114.706457510574978, -4.013590349039361],
              [114.706452351312137, -4.016102772337066],
              [114.705804247204938, -4.016103053843356],
              [114.70581164690492, -4.01358480987613],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 708.78,
        No_Kav: "67",
        Nm_Pemilik: "Darsih",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706457510574978, -4.013590349039361],
              [114.706594900395956, -4.013591526854813],
              [114.707098506835322, -4.013499546531945],
              [114.707089337191135, -4.016102495156652],
              [114.706452351312137, -4.016102772337066],
              [114.706457510574978, -4.013590349039361],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.997,
        Luas: 2.0,
        PERIMETRO: 725.365,
        No_Kav: "66",
        Nm_Pemilik: "Taufikkurrohman",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707098506835322, -4.013499546531945],
              [114.707709231463284, -4.013388001366986],
              [114.707703190448015, -4.016102227570101],
              [114.707089337191135, -4.016102495156652],
              [114.707098506835322, -4.013499546531945],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.018,
        Luas: 2.0,
        PERIMETRO: 744.125,
        No_Kav: "65",
        Nm_Pemilik: "Sana ah",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707709231463284, -4.013388001366986],
              [114.70829794607927, -4.013280476201537],
              [114.708290662596355, -4.016101971049439],
              [114.707703190448015, -4.016102227570101],
              [114.707709231463284, -4.013388001366986],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 18044.514,
        Luas: 1.804,
        PERIMETRO: 568.753,
        No_Kav: "63",
        Nm_Pemilik: "Sri Rahayu",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708835666143884, -4.014295045170056],
              [114.709155668597049, -4.014577092703193],
              [114.70946047905359, -4.014837576405446],
              [114.709841377243038, -4.015204484918379],
              [114.709934828526414, -4.015405368209793],
              [114.709951056845796, -4.015906965070849],
              [114.70981537322389, -4.016101303301729],
              [114.708816741022204, -4.016101740916781],
              [114.708820935011431, -4.014299109888237],
              [114.708835666143884, -4.014295045170056],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 794.225,
        No_Kav: "64",
        Nm_Pemilik: "Luluk Khoiriyah",
        block: "Blok 9",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70829794607927, -4.013280476201537],
              [114.70909367359404, -4.013135139006703],
              [114.708834120057986, -4.013997929610173],
              [114.708833297205487, -4.01429295720643],
              [114.708835666143884, -4.014295045170056],
              [114.708820935011431, -4.014299109888237],
              [114.708816740930246, -4.016101740916521],
              [114.708290662596355, -4.016101971049439],
              [114.70829794607927, -4.013280476201537],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 655.943,
        No_Kav: "33",
        Nm_Pemilik: "M. Ashari",
        block: "Blok 5",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.704937464357997, -4.016148455485542],
              [114.706123794118142, -4.016152018485768],
              [114.706118264523539, -4.018175245256721],
              [114.705789817358365, -4.018335197169658],
              [114.704937464357997, -4.016148455485542],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.998,
        Luas: 2.0,
        PERIMETRO: 610.563,
        No_Kav: "34",
        Nm_Pemilik: "Alwi Abdilah",
        block: "Blok 5",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706123794118142, -4.016152018485768],
              [114.707025394913344, -4.01615472503096],
              [114.707024451898022, -4.01773393611939],
              [114.706118264523539, -4.018175245256721],
              [114.706123794118142, -4.016152018485768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 588.082,
        No_Kav: "35",
        Nm_Pemilik: "Guntur F.",
        block: "Blok 5",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.707025394913344, -4.01615472503096],
              [114.708253999415163, -4.016158411372471],
              [114.708253186939984, -4.017209118239657],
              [114.708115457874584, -4.017216678189137],
              [114.708076806100891, -4.017405267486348],
              [114.707561929052858, -4.017472186317944],
              [114.707024451898022, -4.01773393611939],
              [114.707025394913344, -4.01615472503096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12297.283,
        Luas: 1.23,
        PERIMETRO: 573.814,
        No_Kav: "36",
        Nm_Pemilik: "Syaifudin",
        block: "Blok 5",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708253999464574, -4.016158411389162],
              [114.709910281386612, -4.016163378268031],
              [114.710026425836489, -4.016505549869069],
              [114.709236878830609, -4.016437694359198],
              [114.709042500952094, -4.016806339637661],
              [114.708352415062478, -4.017203671592226],
              [114.708253186939984, -4.017209118239657],
              [114.708253999464574, -4.016158411389162],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 677.0,
        No_Kav: "37",
        Nm_Pemilik: "Siti Chalimah",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.706968711189973, -4.018703520504314],
              [114.707309581633538, -4.018235606224176],
              [114.707666620047277, -4.018057587101715],
              [114.708040374508045, -4.017973389831183],
              [114.70809953569146, -4.018067331474025],
              [114.709195784390701, -4.017652692769425],
              [114.709321265385711, -4.01776630781049],
              [114.709315651325269, -4.018707301727039],
              [114.706968711189973, -4.018703520504314],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 702.962,
        No_Kav: "38",
        Nm_Pemilik: "Sutamaji",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.709321265385711, -4.01776630781049],
              [114.70944134718664, -4.017875034194563],
              [114.709637974336573, -4.017381136938833],
              [114.709884155715571, -4.017381829660013],
              [114.710241645551022, -4.017041834854982],
              [114.710251323457967, -4.016615611322139],
              [114.7103535006366, -4.016505073507136],
              [114.710451393960824, -4.016551803036091],
              [114.710445094853398, -4.018709119124114],
              [114.709315651325269, -4.018707301727039],
              [114.709321265385711, -4.01776630781049],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 889.327,
        No_Kav: "39",
        Nm_Pemilik: "Noor Bayah",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711148223533428, -4.018710249447787],
              [114.710445094853398, -4.018709119124114],
              [114.710451393960824, -4.016551803036091],
              [114.711101516041197, -4.016856854490443],
              [114.711107953218814, -4.016563441502688],
              [114.710529928569215, -4.016296599645242],
              [114.71115987009938, -4.015922458691724],
              [114.711148223533428, -4.018710249447787],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 759.094,
        No_Kav: "40",
        Nm_Pemilik: "Yayah",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711347475284072, -4.016235067806753],
              [114.711720611675801, -4.016372517013514],
              [114.71176839271638, -4.016360220108472],
              [114.711796971605963, -4.016352864961597],
              [114.711790381146798, -4.0187112817912],
              [114.711148223533428, -4.018710249447787],
              [114.71115987009938, -4.015922458691724],
              [114.711280632513379, -4.015851253897099],
              [114.711424494283747, -4.016013633576646],
              [114.711347475284072, -4.016235067806753],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 671.577,
        No_Kav: "41",
        Nm_Pemilik: "Endang P",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711796971665251, -4.01635286504558],
              [114.712179351091009, -4.016254455914505],
              [114.712459084857471, -4.016400167429776],
              [114.712482603897143, -4.017099285715977],
              [114.712475815078051, -4.017105886392737],
              [114.712471522704632, -4.01871237596084],
              [114.711790381156518, -4.018711281789665],
              [114.711796971665251, -4.01635286504558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 616.085,
        No_Kav: "42",
        Nm_Pemilik: "Purwanto",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712475815078051, -4.017105886392737],
              [114.712652930945879, -4.0169036888231],
              [114.712923986003162, -4.017117576210056],
              [114.713212969252481, -4.016990512651615],
              [114.713340019737188, -4.017093169971634],
              [114.713442889843819, -4.016974642325708],
              [114.713437564806355, -4.01871392632081],
              [114.712471522704632, -4.01871237596084],
              [114.712475815078051, -4.017105886392737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.004,
        Luas: 2.0,
        PERIMETRO: 679.546,
        No_Kav: "43",
        Nm_Pemilik: "Sri Purwanti",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713442889843819, -4.016974642325708],
              [114.713450733149088, -4.016965606111108],
              [114.713476912764406, -4.016709927987579],
              [114.713681361682518, -4.016454751158467],
              [114.713918935765761, -4.016498043511484],
              [114.714187889036367, -4.016197869137249],
              [114.714183410787101, -4.018300622630393],
              [114.714134546208129, -4.018331532127378],
              [114.714040097985674, -4.0187148934814],
              [114.713437564806355, -4.01871392632081],
              [114.713442889843819, -4.016974642325708],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 9647.424,
        Luas: 0.965,
        PERIMETRO: 552.545,
        No_Kav: "44",
        Nm_Pemilik: "Sri Sularmi",
        block: "Blok 6",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714360104231744, -4.016593057660783],
              [114.714572353814219, -4.016585128545117],
              [114.714690868058284, -4.016704811833435],
              [114.714562725601581, -4.016994303468182],
              [114.714587172112189, -4.017360948859205],
              [114.714713700732375, -4.01765115554069],
              [114.714738337071338, -4.017949601172668],
              [114.714183410850964, -4.018300622566623],
              [114.714187889036253, -4.016197869172593],
              [114.714208464675025, -4.016174905040659],
              [114.714403308818135, -4.016320377728735],
              [114.714360104231744, -4.016593057660783],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.991,
        Luas: 2.0,
        PERIMETRO: 615.603,
        No_Kav: "23",
        Nm_Pemilik: "Darsih",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713387787537982, -4.020387310051937],
              [114.712821614164625, -4.020019138848664],
              [114.712553654793552, -4.019844890080199],
              [114.712556203024135, -4.018821667999032],
              [114.712824951486908, -4.018822055896465],
              [114.714222078034041, -4.018824071005035],
              [114.714238470611974, -4.018964575441737],
              [114.713413352438309, -4.019934228337563],
              [114.713387787537982, -4.020387310051937],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.023,
        Luas: 2.0,
        PERIMETRO: 643.238,
        No_Kav: "25",
        Nm_Pemilik: "Mulyadi",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712369536061928, -4.020265567882899],
              [114.713258578913695, -4.020829943733649],
              [114.713325542479225, -4.021441055666364],
              [114.713209147355641, -4.021700925349867],
              [114.712888175711228, -4.022317990316449],
              [114.712618616650559, -4.022749344620009],
              [114.712362645516407, -4.022639090302435],
              [114.712369536061928, -4.020265567882899],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 708.17,
        No_Kav: "26",
        Nm_Pemilik: "Mujilan",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71170336942761, -4.019842675707852],
              [114.711747774365193, -4.019870864623958],
              [114.712369536061928, -4.020265567882899],
              [114.712362645516407, -4.022639090302435],
              [114.71173431141608, -4.0223684458446],
              [114.711697647375047, -4.022352653395177],
              [114.71170336942761, -4.019842675707852],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.995,
        Luas: 2.0,
        PERIMETRO: 799.317,
        No_Kav: "24",
        Nm_Pemilik: "Enal",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712821614164625, -4.020019138848664],
              [114.711691852706139, -4.019284476043902],
              [114.711513594589704, -4.019673181951107],
              [114.711270603464939, -4.019517647086063],
              [114.710525863981189, -4.019040950255513],
              [114.71051790995044, -4.019035859059554],
              [114.710177912698541, -4.018818231432052],
              [114.712824951486908, -4.018822055896465],
              [114.712821614164625, -4.020019138848664],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.004,
        Luas: 2.0,
        PERIMETRO: 736.784,
        No_Kav: "27",
        Nm_Pemilik: "Kamaludin",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711513594589704, -4.019673181951107],
              [114.711496182485092, -4.019711150307381],
              [114.711747774365193, -4.019870864623958],
              [114.71173431141608, -4.0223684458446],
              [114.711283071975998, -4.022174081261852],
              [114.711263244709258, -4.022163861711373],
              [114.711263218534199, -4.022163848220506],
              [114.711109804821731, -4.022084771599594],
              [114.711119441340983, -4.019420890516425],
              [114.711270603464939, -4.019517647086063],
              [114.711513594589704, -4.019673181951107],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.021,
        Luas: 2.0,
        PERIMETRO: 751.991,
        No_Kav: "28",
        Nm_Pemilik: "Mashuri Huda",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711109804813859, -4.022084771536868],
              [114.710508919835661, -4.021775046497581],
              [114.71051790995044, -4.019035859059554],
              [114.711119441339477, -4.019420890505965],
              [114.711109804813859, -4.022084771536868],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.014,
        Luas: 2.0,
        PERIMETRO: 743.253,
        No_Kav: "29",
        Nm_Pemilik: "M. Ilyas",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.710508919835661, -4.021775046497581],
              [114.709916494698959, -4.021469682431759],
              [114.709924899677588, -4.018817865561374],
              [114.710177912698541, -4.018818231432052],
              [114.71051790995044, -4.019035859059554],
              [114.710508919835661, -4.021775046497581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.008,
        Luas: 2.0,
        PERIMETRO: 701.369,
        No_Kav: "30",
        Nm_Pemilik: "Sonyono",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.709916494698959, -4.021469682431759],
              [114.709860063495526, -4.021440595075457],
              [114.709252971569825, -4.021042231103521],
              [114.709256743868124, -4.018816898550571],
              [114.709924899677588, -4.018817865561374],
              [114.709916494698959, -4.021469682431759],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17968.329,
        Luas: 1.797,
        PERIMETRO: 649.946,
        No_Kav: "32",
        Nm_Pemilik: "Santoso",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708403531726489, -4.020399444854643],
              [114.706560971367736, -4.018812991712262],
              [114.708407299879411, -4.018815669282514],
              [114.708403531726489, -4.020399444854643],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 633.979,
        No_Kav: "31",
        Nm_Pemilik: "Edi Lukito",
        block: "Blok 4",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.709252971569825, -4.021042231103521],
              [114.709056472923905, -4.020913292357152],
              [114.708403531774678, -4.020399444891148],
              [114.708407300756946, -4.018815669505439],
              [114.709256743868124, -4.018816898550571],
              [114.709252971569825, -4.021042231103521],
            ],
          ],
        ],
      },
    },
  ],
};
