import { useSelector } from "react-redux";

import Content from "./partials/Content";
import Modal from "../../../../../components/atoms/Modal";
import React, { useEffect, useState } from "react";
import { formSchema } from "./schema/formSchema";
import { omitBy } from "lodash";
import { selectModal } from "../../../../../features/modalSlice";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import { modalMemberName } from "../../ListView";
import ProfilePicture from "../ProfilePicture";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";
import toast from "react-hot-toast";

const MultiModal = ({ isOpen, toggleModal, type, onRefreshData }) => {
  const UPDATE_TYPE = type.toLowerCase().includes("ubah");
  const modal = useSelector(selectModal);
  const [user, updateUser, createUser] = useUserStore((state) => [
    state.user,
    state.updateUser,
    state.createUser,
  ]);
  const [photoFileId, setPhotoFileId] = useState(null);
  const { fetchAfdelings, fetchBlocks } = useFilterDataDefaults();

  const performSetPhotoFileId = (id) => {
    setPhotoFileId(id);
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(formSchema) });

  const state = modal[modalMemberName]?.params?.item;

  const performCloseModal = () => {
    onRefreshData();
    toggleModal();
  };

  const onSubmit = async (data) => {
    let requestData = {
      ...data,
      companyId: user?.companyId,
      adhocEstateName: user?.adhocEstateName,
      companyName: user?.companyName,
      defaultEstateId: data?.estate?.value,
      defaultEstateName: data?.estate?.label,
      defaultAfdelingId: data?.afdeling?.value,
      defaultAfdelingName: data?.afdeling?.label,
      position: data?.position?.value,
      username: `${data?.username}`,
      password: data.password,
      email: data.email,
      phone: data.phone === "-" ? 0 : data.phone?.replace(/[^0-9+]/g, ""),
    };

    let workingArea;
    if (data.workingArea) {
      workingArea = data?.workingArea.reduce((acc, curr) => {
        acc.push({
          id: curr?.id,
          blockName: curr?.blockName,
          // afdelingId: curr?.afdelingId,
          // afdelingName: curr?.afdelingName,
          // treeCount: curr?.treeCount,
          // areaHa: curr?.areaHa,
          // plantedDate: curr?.plantedDate,
        });
        return acc;
      }, []);
    }
    let accessControl;
    if (data.accessControl) {
      accessControl = data?.accessControl.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
    }

    requestData = {
      ...requestData,
      workingArea: JSON.stringify(workingArea),
      accessControl: JSON.stringify(accessControl),
    };

    if (UPDATE_TYPE) {
      await updateUser(
        requestData?.id,
        requestData?.companyId,
        requestData,
        (response) => response?.success && performCloseModal()
      );
    } else {
      requestData = omitBy(requestData, (v) => v == null);
      requestData = { ...requestData, id: uuidv4() };
      if (photoFileId) requestData = { ...requestData, photoFileId };

      await createUser(
        requestData?.companyId,
        requestData,
        (response) => response?.success && performCloseModal()
      );
    }
  };

  const handleDefaultValue = () => {
    clearErrors();
    if (UPDATE_TYPE) {
      const accessControlMap = {
        Harvesting: "Panen",
        Treatment: "Rawat (Pupuk, Racun, Pruning)",
      };

      if (state.defaultEstateId) {
        fetchAfdelings({ estateId: state.defaultEstateId });
      }
      if (state?.defaultAfdelingId) {
        fetchBlocks({
          afdelingId: state?.defaultAfdelingId || undefined,
          defaultData: [{ label: "Choose all", value: "all" }],
        });
      }

      reset(state);
      setValue("username", state?.username);
      setValue("estate", {
        label: state?.defaultEstateName,
        value: state?.defaultEstateId,
      });
      setValue("afdeling", {
        label: state?.defaultAfdelingName,
        value: state?.defaultAfdelingId,
      });
      setValue("position", {
        label: state?.position,
        value: state?.position,
      });
      setValue(
        "workingArea",
        state?.workingArea &&
          JSON.parse(state?.workingArea)?.reduce((acc, curr) => {
            acc.push({
              ...curr,
              value: curr.id,
              label: curr.blockName,
            });
            return acc;
          }, [])
      );
      setValue(
        "accessControl",
        state?.accessControl &&
          JSON.parse(state?.accessControl)?.reduce((acc, curr) => {
            acc.push({
              value: curr,
              label: accessControlMap[curr],
            });
            return acc;
          }, [])
      );
    } else {
      reset({}); // to clear all default value not only on input
      setPhotoFileId(null);
      setValue("fullname", null);
      setValue("email", null);
      setValue("phone", null);
      setValue("position", null);
      setValue("estate", null);
      setValue("afdeling", null);
      setValue("workingArea", null);
      setValue("accessControl", null);
      setValue("username", null);
      setValue("password", null);
    }
  };

  useEffect(() => {
    handleDefaultValue();
  }, [type, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={toggleModal} title={type}>
      <ProfilePicture
        data={state}
        onRefreshData={onRefreshData}
        isEditable={UPDATE_TYPE}
        performSetPhotoFileId={performSetPhotoFileId}
      />
      <Modal.Content>
        <Content
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          isEditable={UPDATE_TYPE}
          setValue={setValue}
          data={state}
          onRefreshData={onRefreshData}
        />
      </Modal.Content>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Footer toggleModal={toggleModal} type={type} />
      </form>
    </Modal>
  );
};

export default MultiModal;
