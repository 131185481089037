import axiosInstance from "../api";
import { COLLECTION_POINTS_URL, HARVESTINGS_URL } from "../../configs/api";

export const getCollectionPointApi = (params) =>
  axiosInstance.get(COLLECTION_POINTS_URL, {
    params,
  });
export const updateCollectionPointApi = (id, body) =>
  axiosInstance.put(`${COLLECTION_POINTS_URL}/${id}`, body);
export const createCollectionPointApi = (body) =>
  axiosInstance.post(COLLECTION_POINTS_URL, body);
