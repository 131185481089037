import InputHook from "../../../../../../../components/atoms/InputHook";
import React from "react";

const Content = ({ register, errors }) => {
  return (
    <div className="grid grid-cols-1 gap-13">
      <InputHook
        type="number"
        register={register}
        label="Awal TPH"
        name="startTph"
        placeholder="Masukkan jumlah TPH"
        errors={errors?.name}
        inputClassName="input-large-border"
      />
      <InputHook
        type="number"
        register={register}
        label="Akhir TPH"
        name="endTph"
        placeholder="Masukkan jumlah TPH"
        errors={errors?.name}
        inputClassName="input-large-border"
      />
    </div>
  );
};

export default Content;
