export const pudingr1 = {
  type: "FeatureCollection",
  name: "Blokpudingr1_2",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        No_Kav: "G5",
        Luas: 22.3,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 23.8,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.905148025807748, -1.583524446343237],
              [103.905166520122563, -1.58427592519773],
              [103.907262365475546, -1.584220471973208],
              [103.909323196399356, -1.585745030308493],
              [103.909324682668, -1.585746148900805],
              [103.909324284968861, -1.585828251275363],
              [103.902572943320024, -1.585802457224518],
              [103.899580518649785, -1.585805409571164],
              [103.89941790786213, -1.583200296835046],
              [103.899635686436895, -1.583200767780146],
              [103.899635536344746, -1.583485372158336],
              [103.904401463579489, -1.583502272199111],
              [103.905148025807748, -1.583524446343237],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G2",
        Luas: 18.7,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 18.7,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.909250432990731, -1.592612642010981],
              [103.907688136684271, -1.592763030639924],
              [103.905301466478832, -1.592991514647548],
              [103.903942241307419, -1.593123616537939],
              [103.902479556296072, -1.593270021827819],
              [103.901020442928683, -1.593409247909245],
              [103.900232391063511, -1.593474190427508],
              [103.900218648343767, -1.593402067272997],
              [103.900094251876382, -1.592534425106344],
              [103.899959884236353, -1.591397937728129],
              [103.909267599458374, -1.591399485848265],
              [103.909250636473018, -1.592225617046047],
              [103.909250432990731, -1.592612642010981],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H2",
        Luas: 5.5,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.914157397505562, -1.592088336435665],
              [103.912647007537245, -1.592259481894834],
              [103.910431574489095, -1.592473703636044],
              [103.909375442882933, -1.592597937058982],
              [103.909394763724578, -1.591399506706367],
              [103.914003714120241, -1.591400257363744],
              [103.914157397505562, -1.592088336435665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H3",
        Luas: 13.3,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 24.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.909397018410516, -1.591259651367682],
              [103.909423230063354, -1.589633744653482],
              [103.909427242819632, -1.588698064524983],
              [103.913266646937132, -1.58871293663124],
              [103.913431149384536, -1.58883674368524],
              [103.913965921543181, -1.591231050554677],
              [103.911014362624016, -1.591257719369308],
              [103.909397018410516, -1.591259651367682],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H3",
        Luas: 6.1,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.909427854443649, -1.588555442235187],
              [103.909431358815652, -1.587738240430211],
              [103.909449742471963, -1.585964170008887],
              [103.909614587267527, -1.585964336254887],
              [103.913090957269532, -1.588580709885149],
              [103.910522854415944, -1.588558222977431],
              [103.909427854443649, -1.588555442235187],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G4",
        Luas: 30.8,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 30.8,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.909323627197594, -1.585964042812687],
              [103.90931746758487, -1.587235583024624],
              [103.9093068672573, -1.588555134953589],
              [103.905756903280448, -1.588546115635332],
              [103.901409500560973, -1.588582087468368],
              [103.89976181256651, -1.588568326447272],
              [103.899751596838584, -1.588405779512339],
              [103.899590361053953, -1.585955012294741],
              [103.899943605868273, -1.585954560730128],
              [103.909323627197594, -1.585964042812687],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G3",
        Luas: 29.8,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2022",
        master_blo: 29.8,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.909305722811453, -1.588697593694792],
              [103.909294568546926, -1.590086013514084],
              [103.909270467558912, -1.591259802485119],
              [103.90408183259099, -1.591265991625724],
              [103.899944022120295, -1.59126377541628],
              [103.899916681427712, -1.591032526996943],
              [103.899768697836635, -1.588677881069627],
              [103.89983003345543, -1.588678128009157],
              [103.90355430934504, -1.588701659900994],
              [103.909290580814783, -1.588697535026511],
              [103.909305722811453, -1.588697593694792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "A2",
        Luas: 18.2,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 18.7,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.857907991214248, -1.592575486516182],
              [103.857562084669269, -1.594031491732377],
              [103.852148910253405, -1.594281286879193],
              [103.851190817512276, -1.591371910350853],
              [103.853367756620599, -1.591266640876447],
              [103.853479289880454, -1.591266703257945],
              [103.855459125521776, -1.591267809587037],
              [103.855694791206673, -1.591267941149327],
              [103.855634616297735, -1.592574238289244],
              [103.857907991214248, -1.592575486516182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "A1",
        Luas: 16.7,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 48.6,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.856881397352979, -1.59705539131864],
              [103.853107748625334, -1.59716877702242],
              [103.852156865185009, -1.594281291341815],
              [103.857732954946641, -1.594128004440509],
              [103.856881397352979, -1.59705539131864],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F6",
        Luas: 19.2,
        POKOK: "0",
        SPH: "0",
        POKOK0: "2860",
        POKOK1: "2860",
        SPH_2: "148",
        TANAM: "2022",
        master_blo: 19.3,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.894538, -1.580158],
              [103.89524, -1.580594],
              [103.895219, -1.580666],
              [103.895371, -1.580718],
              [103.895529, -1.580829],
              [103.895828, -1.581027],
              [103.896125, -1.581195],
              [103.896271, -1.581305],
              [103.89641, -1.581396],
              [103.896677, -1.58158],
              [103.896878, -1.581684],
              [103.896964, -1.581758],
              [103.897162, -1.581877],
              [103.897307, -1.581984],
              [103.897362, -1.581999],
              [103.897396, -1.582016],
              [103.897416, -1.58204],
              [103.897443, -1.582063],
              [103.897717, -1.582226],
              [103.898129, -1.582503],
              [103.898427, -1.582677],
              [103.898444, -1.582735],
              [103.897808, -1.582742],
              [103.897181, -1.582746],
              [103.896668, -1.582741],
              [103.896334, -1.582742],
              [103.896003, -1.582743],
              [103.895668, -1.582742],
              [103.895337, -1.582742],
              [103.894836, -1.582742],
              [103.894491, -1.582756],
              [103.893933, -1.582756],
              [103.893769, -1.582748],
              [103.893436, -1.582748],
              [103.892931, -1.582746],
              [103.892432, -1.582746],
              [103.891933, -1.582752],
              [103.891104, -1.582752],
              [103.890716, -1.582748],
              [103.890715, -1.582427],
              [103.890716, -1.582064],
              [103.890716, -1.581747],
              [103.890712, -1.581484],
              [103.890715, -1.581242],
              [103.890716, -1.581017],
              [103.890715, -1.580759],
              [103.890714, -1.58051],
              [103.890716, -1.580258],
              [103.89072, -1.579976],
              [103.891104, -1.579972],
              [103.891462, -1.579982],
              [103.891839, -1.579987],
              [103.892207, -1.579985],
              [103.892596, -1.579979],
              [103.892954, -1.57998],
              [103.89347, -1.579981],
              [103.893562, -1.579984],
              [103.893596, -1.58],
              [103.89427, -1.580002],
              [103.89427, -1.580002],
              [103.894538, -1.580158],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F5",
        Luas: 23.4,
        POKOK: "2",
        SPH: "0",
        POKOK0: "3425",
        POKOK1: "3427",
        SPH_2: "146",
        TANAM: "2022",
        master_blo: 8.33,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.896026791891046, -1.585908773870933],
              [103.896038791878212, -1.584381773858654],
              [103.890698811641386, -1.584370735282311],
              [103.890701053092741, -1.582837576735104],
              [103.896057791865189, -1.582816773846025],
              [103.89689979187547, -1.582813773838751],
              [103.897446791882118, -1.582806773834018],
              [103.898213791891422, -1.582798773827356],
              [103.898559791895721, -1.582804773824447],
              [103.898664791897005, -1.582806773823582],
              [103.898830791899059, -1.582806773822153],
              [103.898997791901095, -1.582808773820735],
              [103.899164791903161, -1.582809773819258],
              [103.899348791905439, -1.582813773817736],
              [103.899358791907019, -1.582984773819032],
              [103.899376791907997, -1.583072773819542],
              [103.899381791909164, -1.58320077382052],
              [103.899402791911498, -1.583447773822332],
              [103.899423791914018, -1.583715773824282],
              [103.899443791916596, -1.583989773826287],
              [103.899471791918899, -1.584221773827871],
              [103.899481791920451, -1.584388773829112],
              [103.899485791921904, -1.58455677383042],
              [103.89950279192486, -1.584872773832753],
              [103.899524791926709, -1.58506377383412],
              [103.899548791928936, -1.585289773835698],
              [103.899561791929955, -1.585394773836409],
              [103.899569791932066, -1.585626773838169],
              [103.89959079193433, -1.585863773839884],
              [103.899593791935075, -1.585948773840512],
              [103.89887879192635, -1.585952773846732],
              [103.898425791920744, -1.585944773850541],
              [103.897758791912423, -1.585930773856198],
              [103.897019791903347, -1.585923773862508],
              [103.89618379189298, -1.585910773869581],
              [103.896026791891046, -1.585908773870933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "E7",
        Luas: 15.5,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 27.6,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.881523342328663, -1.578541082562844],
              [103.890563087377572, -1.578464035368507],
              [103.890567054484677, -1.579898460289631],
              [103.887721417719092, -1.579899037944689],
              [103.884820018268485, -1.579902259517008],
              [103.881529305810162, -1.57990038942657],
              [103.881523342328663, -1.578541082562844],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "A0",
        Luas: 9.7,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 48.6,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.856480322093077, -1.599118032867912],
              [103.8553, -1.60385],
              [103.853466952027517, -1.598283698673205],
              [103.855251219747728, -1.598252102904671],
              [103.855236848719613, -1.599130406513878],
              [103.856480322093077, -1.599118032867912],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G12",
        Luas: 4.9,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.903455951702355, -1.565960856837805],
              [103.900613179267921, -1.565959358467782],
              [103.900453690643957, -1.565959795401482],
              [103.90045439282413, -1.564611952586526],
              [103.900444043620496, -1.56331678355815],
              [103.903455951702355, -1.565960856837805],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G11",
        Luas: 14.7,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.90369794798255, -1.566173298603507],
              [103.906603832013644, -1.568724289714702],
              [103.901305933440767, -1.568721497267586],
              [103.900452250235986, -1.568721046040716],
              [103.900453582509158, -1.566167258161103],
              [103.90369794798255, -1.566173298603507],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G7",
        Luas: 22.6,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.900518751986979, -1.579774015948282],
              [103.900513103095491, -1.57967070314836],
              [103.900520588363435, -1.577302781248357],
              [103.903043002891337, -1.577304120693128],
              [103.908263163787922, -1.577312866212057],
              [103.909461393879653, -1.577313498393131],
              [103.909461270138635, -1.577551137966533],
              [103.909450271567934, -1.578946601557217],
              [103.907299694232393, -1.579145728261753],
              [103.903541939378826, -1.579496750992307],
              [103.900518751986979, -1.579774015948282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H7",
        Luas: 9.7,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.915563279352526, -1.577787204120551],
              [103.915641686159674, -1.578363053525739],
              [103.91128341011725, -1.578742197760198],
              [103.90966144707626, -1.578892380702728],
              [103.909666487616121, -1.577278937329976],
              [103.913964943113527, -1.577281198989287],
              [103.915044208054653, -1.577265589289608],
              [103.915563279352526, -1.577787204120551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H8",
        Luas: 12.6,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.912202186827741, -1.574417818855676],
              [103.914847021920195, -1.577067436855504],
              [103.910715223681777, -1.57707286664655],
              [103.909667181158639, -1.577056917250452],
              [103.909668206167652, -1.576728776949865],
              [103.90967354328879, -1.574396239960626],
              [103.912202186827741, -1.574417818855676],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H9",
        Luas: 5.1,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.910683784105061, -1.571654911864468],
              [103.91181698377342, -1.574030066946221],
              [103.911995166675467, -1.574210424097347],
              [103.91187387173855, -1.574210360408291],
              [103.909673992273255, -1.574199979642856],
              [103.909674361247497, -1.574038689234196],
              [103.909670753852041, -1.571653276032179],
              [103.910683784105061, -1.571654911864468],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "H9",
        Luas: 1.0,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.910578046407053, -1.571433288517804],
              [103.909686089349051, -1.571433584160334],
              [103.909670421484563, -1.571433558094464],
              [103.909667370249025, -1.56941696956611],
              [103.91022701076119, -1.570805477588411],
              [103.910578046407053, -1.571433288517804],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G10",
        Luas: 27.9,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.900457743059306, -1.571457402422911],
              [103.900452684078928, -1.568937128448256],
              [103.904016463553859, -1.568939010072664],
              [103.909461890512461, -1.568941873383532],
              [103.909463018911239, -1.571467882258115],
              [103.904430249514093, -1.571459502814375],
              [103.900457743059306, -1.571457402422911],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G9",
        Luas: 27.9,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.900475627307713, -1.574198379749039],
              [103.900459797473104, -1.572481165672555],
              [103.900458202105185, -1.571686140911447],
              [103.903071165694413, -1.571684281144102],
              [103.90601775685019, -1.571682246368167],
              [103.909463116967757, -1.571687612029541],
              [103.90946367200246, -1.572932033189493],
              [103.909462995893591, -1.574233653147614],
              [103.908449243209148, -1.574228868565035],
              [103.907821411948419, -1.574199817935894],
              [103.901985302479858, -1.574196733751473],
              [103.900475627307713, -1.574198379749039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "G8",
        Luas: 29.5,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.900521283034493, -1.5770829958014],
              [103.900524511141612, -1.576061594785026],
              [103.900486801314585, -1.575410593894995],
              [103.900477609323445, -1.574413395316502],
              [103.905899147903256, -1.574398693682603],
              [103.909462894317031, -1.574429111532578],
              [103.909461511043617, -1.577088456708027],
              [103.909411956470876, -1.577087702554607],
              [103.900521283034493, -1.5770829958014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F10",
        Luas: 4.4,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.900258663397025, -1.570864823673449],
              [103.897687762679098, -1.569829406561635],
              [103.897705229953829, -1.568935673662482],
              [103.900255423361131, -1.568937024119018],
              [103.900258663397025, -1.570864823673449],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F10",
        Luas: 0.6,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.899618638869626, -1.571457732470207],
              [103.899602190783725, -1.570600432988238],
              [103.900258663397025, -1.570864823673449],
              [103.900259659148006, -1.571457435983982],
              [103.899618638869626, -1.571457732470207],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F9",
        Luas: 3.8,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.898941432493913, -1.574200051352425],
              [103.899122778928884, -1.571685780610484],
              [103.900178086863392, -1.571686340087093],
              [103.900260043640571, -1.57168628181573],
              [103.900260349564974, -1.571868372109249],
              [103.900259129359981, -1.574198615706337],
              [103.898941432493913, -1.574200051352425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F8",
        Luas: 4.5,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.898726094559876, -1.577087691319386],
              [103.898655350042802, -1.575679648561218],
              [103.899095610482675, -1.575679882724752],
              [103.899096100047487, -1.574417139294452],
              [103.900259016491546, -1.574413987778456],
              [103.900258077262905, -1.576205051943488],
              [103.90027419160792, -1.577082864444602],
              [103.899823030840224, -1.577082624525957],
              [103.898726094559876, -1.577087691319386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F11",
        Luas: 7.6,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.898620946643788, -1.568720076895997],
              [103.898669197493234, -1.567405831973764],
              [103.89698653671752, -1.567428873949607],
              [103.89696817375679, -1.566163747209413],
              [103.899266279113846, -1.56616504634756],
              [103.900257371234119, -1.566166892688071],
              [103.900253092842092, -1.56755083355426],
              [103.90025506011672, -1.568720941763145],
              [103.898620946643788, -1.568720076895997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        No_Kav: "F12",
        Luas: 2.4,
        POKOK: "0",
        SPH: "0",
        POKOK0: "0",
        POKOK1: "0",
        SPH_2: "0",
        TANAM: "2023",
        master_blo: 0.0,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.898717907635671, -1.565964549947088],
              [103.898717941182653, -1.564668069184406],
              [103.900247754761281, -1.564661643965258],
              [103.900243740615707, -1.565960323937348],
              [103.898717907635671, -1.565964549947088],
            ],
          ],
        ],
      },
    },
  ],
};
