import {
  getHarvestingByIdApi,
  getHarvestingsApi,
  updateHarvestingApi,
} from "./harvestingApi";

import { calculateToastDuration } from "../helpers/Common";
import { create } from "zustand";
import { getVehicleCheckpointsApi } from "./vehicleCheckpoint/vehicleCheckpointApi";
import { notification } from "antd";
import { toast } from "react-hot-toast";

export const useHarvestingStore = create((set, get) => ({
  harvestings: [],
  harvesting: {},
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete,
  getHarvestings: async ({ params = {}, silent, onSuccess, onError }) => {
    try {
      set({ isLoading: true });
      const response = await getHarvestingsApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Data Riwayat Panen",
          description: data.message,
          placement: "bottomRight",
        });
      }
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(data.message);
      return notification.error({
        message: "Gagal Mendapatkan Data Riwayat Panen",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getHarvestingById: async (id, onSuccess) => {
    try {
      set({ isLoading: true });
      const response = await getHarvestingByIdApi(id);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        return notification.error({
          message: "Gagal Mendapatkan Data Panen",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ harvesting: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      return notification.error({
        message: "Gagal Mendapatkan Data Panen",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  updateHarvesting: async ({ id, body, silent, onSuccess, onError }) => {
    try {
      set({ isLoadingRequest: true });
      const response = await updateHarvestingApi(id, body);
      const data = response?.data;
      if (!data.success) {
        set({ isLoadingRequest: false });
        if (silent) return;
        onError?.(data.message);
        return notification.error({
          message: "Gagal Update Data Panen",
          description: data.message,
          placement: "bottomRight",
        });
      }
      if (!silent) {
        const toastMessage = `Berhasil update data panen`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
      }

      if (get().harvesting?.id === id) {
        set({ harvesting: data.record });
      }

      set({ isLoadingRequest: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoadingRequest: false });
      if (silent) return;
      onError?.(error?.data);
      return notification.error({
        message: "Gagal Update Data Panen",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
