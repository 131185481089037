import "react-lazy-load-image-component/src/effects/blur.css";

import React, { useEffect, useState } from "react";
import { positions, transitions, useAlert } from "react-alert";

import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { downloadImage } from "../../../../../../../features/fileAttachment/fileAttachmentSlice";
import { useGalleryViewStore } from "../../hooks/useGalleryViewStore";
import { IMAGE_NOT_FOUND } from "../../../../../../../api/fallbackImageNotFound";
import { Tooltip } from "antd";
import { MdOutlineWarning } from "react-icons/md";
import { checkValues } from "../../../../../../../helpers/validationHelper";

const DatasetImage = ({
  item,
  onClick,
  checkImage,
  isMultiSelect,
  selected,
}) => {
  const [source, setSource] = React.useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const setJeprinData = useGalleryViewStore((state) => state.setJeprinData);
  const [message, setMessage] = useState({
    message: "",
    isError: false,
  });

  const handleDownloadImage = async () => {
    try {
      if (!item?.photoFileId) {
        return;
      }
      const response = await dispatch(
        downloadImage({ id: item?.photoFileId })
      ).unwrap();
      response.onloadend = function () {
        setSource(response.result);
      };
    } catch (error) {
      alert.error(`Failed to get image ${item?.photoFileId}`, {
        position: positions.BOTTOM_RIGHT,
        transition: transitions.SCALE,
      });
    }
  };

  const items = [
    { estateName: item?.estateName },
    { afdelingName: item?.afdelingName },
    { adhocBlockName: item?.adhocBlockName },
    { collectionPointName: item?.collectionPointName },
    { totalWeight: item?.totalWeight },
    { fruitCount: item?.fruitCount },
    { unripeCount: item?.unripeCount },
  ];

  const fieldNames = {
    estateName: "Estate",
    afdelingName: "Afdeling",
    adhocBlockName: "Blok",
    collectionPointName: "TPH",
    totalWeight: "Total panen",
    fruitCount: "Total janjang",
    unripeCount: "Buah mentah",
  };

  function checkNullValues(items) {
    const errorMessages = {
      estateName: "Estate",
      afdelingName: "Afdeling",
      adhocBlockName: "Blok",
      collectionPointName: "TPH",
      totalWeight: "Total panen",
      fruitCount: "Total janjang",
      unripeCount: "Buah mentah",
    };

    const errorList = [];

    for (let i = 0; i < items.length; i++) {
      const key = Object.keys(items[i])[0];
      const value = items[i][key];

      if (Array.isArray(value) && value.length < items[i].min) {
        errorList.push(`${errorMessages[key]} kurang dari ${items[i].min}`);
      } else if (typeof items[i].min !== "undefined" && value < items[i].min) {
        errorList.push(
          `${errorMessages[key]} harus lebih besar atau sama dengan ${items[i].min}`
        );
      } else if (typeof items[i].max !== "undefined" && value > items[i].max) {
        errorList.push(`${errorMessages[key]} harus maksimal ${items[i].max}`);
      } else if (!value && value !== 0) {
        errorList.push(errorMessages[key]);
      }

      if (!(key in errorMessages)) {
        console.warn(`Error message for ${key} is missing`);
      }
    }

    if (errorList.length > 0) {
      return {
        message: `Masih belum lengkap: ${errorList.join(", ")}`,
        isError: true,
      };
    } else {
      return { message: "Semua sudah lengkap", isError: false };
    }
  }

  useEffect(() => {
    handleDownloadImage();
    setMessage(checkValues(items, fieldNames));
  }, [item?.photoFileId, item?.id]);

  return (
    <div className="relative flex h-fit w-fit flex-col items-center justify-center">
      {/*<div className="">*/}
      <Tooltip
        placement="bottom"
        title={message?.message}
        className="font-regular"
      >
        {message?.isError ? (
          <div
            onClick={() => {
              onClick();
              setJeprinData({ source, ...item });
            }}
            className="absolute left-0 right-0 top-0 bottom-0 z-10 cursor-pointer rounded-8 bg-[rgba(0,0,0,0.4)] p-4"
          >
            <MdOutlineWarning className="text-24 text-yellow-600" />
          </div>
        ) : null}
      </Tooltip>
      {/*</div>*/}

      <LazyLoadImage
        src={source || IMAGE_NOT_FOUND}
        effect="blur"
        onClick={() => {
          setJeprinData({});
          setJeprinData({ source, ...item });
          onClick();
        }}
        className="aspect-square cursor-pointer rounded-8"
        wrapperClassName={`${
          (isMultiSelect ? checkImage(item?.id) : selected) &&
          "outline outline-offset-2 outline-yellow-500"
        } cursor-pointer transition ease-in-out rounded-8 h-fit`}
      />
    </div>
  );
};

DatasetImage.propTypes = {
  checkImage: PropTypes.func,
  isMultiSelect: PropTypes.any,
  item: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.any,
};

export default DatasetImage;
