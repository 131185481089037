import React from "react";
import GeneralInfo from "./partials/GeneralInfo";
import Navbar from "../../../components/atoms/Navbar";
import SpotChecks from "./partials/SpotChecks";
import Pagination from "../../../components/atoms/Pagination";
import { useDispatch } from "react-redux";
import { useSpotCheckStore } from "./hooks/useSpotCheckStore";
import { getFertilizationSpotChecks } from "../../../features/fertilizationSlice";
import { useParams } from "react-router-dom";

const DetailView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage, paginationLenght, setRequest, setSpotChecks] =
    useSpotCheckStore((state) => [
      state.page,
      state.setPage,
      state.paginationLenght,
      state.setRequest,
      state.setSpotChecks,
    ]);

  const handlePageClick = async (page) => {
    try {
      const response = await dispatch(
        getFertilizationSpotChecks({
          id,
          pageIndex: page.selected,
          pageSize: 24,
        })
      ).unwrap();
      if (!response.success) {
        setRequest({ isLoadingSpotChecks: false });
        return alert.error(response.message);
      }
      const records = response.records;
      const totalRecords = response?.totalRecords;

      setSpotChecks(records);
      setPage({
        current: 0,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoadingSpotChecks: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoadingSpotChecks: false });
      return alert.error(error.data);
    }
  };

  return (
    <div className="grid max-h-screen min-h-screen grid-cols-1 grid-rows-[max-content,1fr,1fr,max-content] gap-8 bg-gray-30 md:grid-rows-[max-content,max-content,1fr,min-content]">
      <div className="mb-54 md:mb-0">
        <Navbar arrowBack leftContentTitle="Detail Riwayat Pemupukan" />
      </div>
      <GeneralInfo />
      <SpotChecks />
      <div className="-mt-8 w-full max-w-[1247px] items-center md:mx-auto">
        <Pagination.Container className="mx-0 mb-0 md:px-16 md:pb-24">
          <Pagination.Records
            current={page.current}
            length={paginationLenght()}
            totalRecords={page?.total}
          />
          {paginationLenght() !== 1 && (
            <Pagination
              pageCount={paginationLenght()}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default DetailView;
