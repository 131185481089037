import React, { useCallback, useEffect } from "react";
import { useFfbStockMovementStore } from "../../../../../../api/ffbStockMovement/useFfbStockMovementStore";
import { useForm } from "react-hook-form";
import { processTextChange } from "../../../../../../hooks/commentUtils";
import { debounce, omit } from "lodash";
import InputNumericFormat from "../../../../../../components/atoms/InputNumericFormat";
import { extractNumericValue } from "../../../../../../helpers/Common";

const SellUnitPriceRow = ({ row, refreshHandler }) => {
  const [getFfbStockMovementById, updateFfbStockMovement] =
    useFfbStockMovementStore((state) => [
      state.getFfbStockMovementById,
      state.updateFfbStockMovement,
    ]);

  const jeprinData = row.original;
  const { control, setValue, watch } = useForm();

  const watchRemark = watch(`sellUnitPrice-${jeprinData?.id}`);

  const handleBlurOnRemarkChange = async () => {
    const textParsed = processTextChange({
      value: extractNumericValue(watchRemark),
      message: {
        remove: "Berhasil menghapus rp/kg klaim",
        success: "Berhasil mengubah rp/kg klaim",
      },
    });

    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["sellUnitPrice"]),
          sellUnitPrice: parseInt(textParsed.value),
          totalSellPrice:
            row?.original?.measuredNettWeightKg * parseInt(textParsed.value),
          version: stockMovementData.record.version + 1,
        },
        onSuccess: () => {
          textParsed.onSuccess();
          refreshHandler(true);
        },
      });
    });
  };

  const handleOnKeyDownOnRemarkChange = async (event) => {
    if (event.key === "Enter") {
      await handleBlurOnRemarkChange();
    }
  };

  const request = debounce(async (searchTerm) => {
    const textParsed = processTextChange({
      value: searchTerm,
      message: {
        remove: "Berhasil menghapus rp/kg klaim",
        success: "Berhasil mengubah rp/kg klaim",
      },
    });

    await getFfbStockMovementById(jeprinData?.id, (stockMovementData) => {
      updateFfbStockMovement({
        id: jeprinData?.id,
        silent: true,
        body: {
          ...omit(stockMovementData.record, ["sellUnitPrice"]),
          sellUnitPrice: parseInt(textParsed.value),
          totalSellPrice:
            row?.original?.measuredNettWeightKg * parseInt(textParsed.value),
          version: stockMovementData.record.version + 1,
        },
        onSuccess: () => refreshHandler(true),
      });
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [jeprinData]
  );

  useEffect(() => {
    setValue(`sellUnitPrice-${jeprinData?.id}`, jeprinData?.sellUnitPrice);
  }, [jeprinData?.id]);

  return (
    <InputNumericFormat
      control={control}
      prefix="Rp "
      name={`sellUnitPrice-${jeprinData.id}`}
      placeholder="Rp/kg klaim"
      className="min-w-[160px] rounded-8 shadow"
      inputClassName="input-mini-border pl-8 pr-24 w-full"
      afterOnChange={debounceTextRequest}
      onBlur={handleBlurOnRemarkChange}
      onKeyDown={handleOnKeyDownOnRemarkChange}
    />
  );
};

export default SellUnitPriceRow;
