import React, { useEffect, useReducer, useRef, useState } from "react";
import { notification } from "antd";
import { useUserStore } from "../../../../../MasterWorkerPage/hooks/userStore";
import { useImageSource } from "../../../../../../hooks/useImageSource";
import { useFileAttachmentStore } from "../../../../../../hooks/fileAttachmentStore";
import { useUploadImageStore } from "../../../../../../components/templates/Profile/ProfilePicture/hooks/useUplodaImageStore";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../../../../../helpers/Common";
import Picture from "../../../../../../components/atoms/Picture";
import useModal from "../../../../../../hooks/useModal";
import { useEmployeeStore } from "../../../../../../api/employee/useEmployeeStore";

const ProfilePicture = ({
  data: currentData,
  onRefreshData,
  isEditable,
  performSetPhotoFileId,
  onCancel,
}) => {
  currentData = isEditable ? currentData : {};

  const [user, updateUser] = useUserStore((state) => [
    state.user,
    state.updateUser,
  ]);
  const updateEmployee = useEmployeeStore((state) => state.updateEmployee);
  const { source, setSource, setDownloadedImage } = useImageSource();

  const fileInputRef = useRef(null);
  const [currentState, setCurrentState] = useState(undefined);
  const [imageSource, setUploadFile, setPhoto] = useFileAttachmentStore(
    (state) => [state.imageSource, state.setUploadFile, state.setPhoto]
  );
  const [setPhotoPreview, readFile] = useUploadImageStore((state) => [
    state.setPhoto,
    state.readFile,
  ]);
  const [visible, setVisible] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      photoRandomUser: false,
    }
  );
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isLoadingPhotoRandomUser: false,
    }
  );
  const { isModalOpen, showModal, handleOk } = useModal();

  const handleShowVisible = (key) => {
    setVisible({ [key]: !visible[key] });
  };

  const updateUserPhotoProfile = async ({
    currentData,
    photoFileId,
    parsedUrl,
    type,
    loadingState,
    setStateLoading,
  }) => {
    // Capitalize first letter
    const firstChar = loadingState.charAt(0).toUpperCase();
    const restOfString = loadingState.slice(1);
    const capitalizedStr = firstChar + restOfString;

    try {
      setStateLoading({ [`isLoading${capitalizedStr}`]: true });
      const requestBody = {
        ...currentData,
        photoFileId,
        version: currentData?.version + 1,
      };

      let response;

      if (type === "user") {
        response = await updateEmployee({
          id: currentData?.id,
          body: requestBody,
        });
      }

      if (!response?.success) {
        setStateLoading({ [`isLoading${capitalizedStr}`]: false });
        return notification.error({
          message: `Gagal Update Foto ${type}`,
          description: response?.message,
          placement: "bottomRight",
        });
      }

      const toastMessage = `Berhasil Update Foto ${
        type === "company" ? "Logo" : "Profil"
      }`;
      toast.success(toastMessage, {
        duration: calculateToastDuration(toastMessage),
        position: "top-right",
        className: "font-regular text-16",
      });

      setPhoto({ [loadingState]: parsedUrl });
      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
      onRefreshData(true);
    } catch (error) {
      setStateLoading({ [`isLoading${capitalizedStr}`]: false });
      return notification.error({
        message: `Gagal Update Foto ${type}`,
        description: error?.data,
        placement: "bottomRight",
      });
    }
  };

  const resetInput = (inputElement) => {
    inputElement.value = ""; // Clear the value of the input element
  };

  const onCropping = async (event) => {
    const file = event?.target?.files[0];
    const imageDataUrl = await readFile(file);
    setPhotoPreview(imageDataUrl);
    showModal();
    resetInput(event.target); // Reset the input element to allow uploading the same image
  };

  const onChange = async ({ file, url, type, loadingState, currentData }) => {
    let data = {
      fileContent: file,
      fileName: file?.name,
      userId: user?.id,
    };

    const uploadedFile = await setUploadFile({
      data,
      loadingState,
      setStateLoading: setRequest,
    });
    if (!uploadedFile) return;

    if (isEditable) {
      await updateUserPhotoProfile({
        currentData,
        photoFileId: uploadedFile?.id,
        parsedUrl: url,
        type,
        loadingState,
        setStateLoading: setRequest,
      });
    } else {
      setPhoto({ photoRandomUser: url });
    }
    performSetPhotoFileId(uploadedFile?.id);
  };

  useEffect(() => {
    setPhoto({ photoRandomUser: null });
    setDownloadedImage(currentData?.photoFileId, "main");
  }, [currentData?.photoFileId]);

  useEffect(() => {
    setPhoto({ photoRandomUser: null });
    setPhoto({ photoRandomUser: source.main });
  }, [source.main]);

  const onSaveImageCropped = async ({ file, url }) => {
    if (currentState === "user")
      await onChange({
        file,
        url,
        type: "user",
        loadingState: "photoRandomUser",
        currentData,
      });
  };

  return (
    <div className="grid grid-cols-1 gap-13">
      <Picture.CroppingTool
        isModalOpen={isModalOpen}
        showModal={showModal}
        handleOk={handleOk}
        onCancel={onCancel}
        onSave={onSaveImageCropped}
      />
      <Picture
        ref={fileInputRef}
        isLoading={request.isLoadingPhotoRandomUser}
        imageSource={imageSource.photoRandomUser}
        imageVisible={visible.photoRandomUser}
        onVisibleChange={() => handleShowVisible("photoRandomUser")}
        onInputChange={async (event) => {
          await onCropping(event);
          setCurrentState("user");
        }}
        title="Foto Profil"
        buttonUploadTitle="Unggah foto profil"
        photoFileId={currentData?.photoFileId}
      />
    </div>
  );
};

export default ProfilePicture;
