import { DataProvider } from "./partials/Forms/hooks/DataContext";
import Forms from "./partials/Forms";
import LOGO from "../../assets/images/thumb.png";
import LOGO_SEMAI_KEBUN_VERTICAL from "../../assets/icons/logo-semai-kebun-v.png";

import React from "react";
import { cn } from "../../helpers/Common";
import { APP_NAME } from "../../configs/api";

const RegisterPage = () => {
  return (
    <div className="relative grid h-full max-h-screen min-h-screen w-full grid-cols-1 md:grid-cols-[0.4fr,1fr]">
      <div
        className={cn(
          "sticky left-0 top-0 z-20 flex flex-col items-start justify-center px-24 py-8 md:items-center",
          APP_NAME === "sawit-web" ? "bg-yellow-600" : "bg-[#ffcccc]"
        )}
      >
        <img
          src={APP_NAME === "sawit-web" ? LOGO : LOGO_SEMAI_KEBUN_VERTICAL}
          alt="semai-kebun"
          className="hidden md:inline"
        />
        <h1 className="inline text-16 font-semibold text-white md:hidden">
          Register
        </h1>
      </div>
      <DataProvider>
        <Forms />
      </DataProvider>
    </div>
  );
};

export default RegisterPage;
