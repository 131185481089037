export const headersData = [
  {
    value: "ID",
    accessorKey: "index",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Foto",
    accessorKey: "photoFileId",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Nama Lengkap",
    accessorKey: "fullname",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Email",
    accessorKey: "email",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Username",
    accessorKey: "username",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Estate/Kebun",
    accessorKey: "defaultEstateName",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Wilayah",
    accessorKey: "defaultAfdelingName",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Jabatan",
    accessorKey: "position",
    type: "alphabet",
    sorted: true,
  },
  {
    value: "Nomor Handphone",
    accessorKey: "phone",
    type: "number",
    sorted: true,
  },
  {
    value: "Aksi",
    accessorKey: "action",
    type: "alphabet",
  },
];
