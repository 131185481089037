export const jilatan = {
  type: "FeatureCollection",
  name: "Kepemilikan_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Agung Kristiono",
        No_SHM: "M 1377",
        Luas_SHM: 1.296,
        TTL: "Rembang, 1 Februari 1970",
        No: 2.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Agung Kristiono",
        Nama_SHM: "Angga Islamanda",
        X: "114.837519326412",
        Y: "-3.92093723920404",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.83678818960999, -3.920431014558655],
              [114.837655243202363, -3.920267953348958],
              [114.838224726200636, -3.921635819000066],
              [114.837372780091101, -3.921490639980681],
              [114.83678818960999, -3.920431014558655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Agung Kristiono",
        No_SHM: "M 1378",
        Luas_SHM: 1.312,
        TTL: "Rembang, 1 Februari 1970",
        No: 1.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Agung Kristiono",
        Nama_SHM: "Hajjah Hupiah",
        X: "114.836583431487",
        Y: "-3.92110609606124",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.83576152629314, -3.921320168502287],
              [114.836425442194653, -3.920499232615938],
              [114.83678818960999, -3.920431014558655],
              [114.837372781436557, -3.921490639352399],
              [114.836479633560231, -3.921602996497489],
              [114.836091887400173, -3.92148533722208],
              [114.83576152629314, -3.921320168502287],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Rusmayanti",
        No_SHM: "M 1376",
        Luas_SHM: 1.728,
        TTL: "Banjarmasin, 25 Januari 1970",
        No: 3.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Rusmayanti",
        Nama_SHM: "Hajjah Hupiah",
        X: "114.838390025632",
        Y: "-3.92089434505368",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837655243202363, -3.920267953348958],
              [114.838681551720342, -3.920074940712798],
              [114.838988231771552, -3.921810709721081],
              [114.838224726200636, -3.921635819000066],
              [114.837655243202363, -3.920267953348958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Hamdan Sitorus",
        No_SHM: "M 1375",
        Luas_SHM: 1.5334,
        TTL: "Medan, 15 Mei 1967",
        No: 4.0,
        Luas_Inkes: 3.0,
        Nama_Baru: "Hamdan Sitorus",
        Nama_SHM: "Arman",
        X: "114.83917977205",
        Y: "-3.92094440622004",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838681551720342, -3.920074940712798],
              [114.839360382434904, -3.919967772723073],
              [114.839663978650947, -3.921965497623123],
              [114.838988231771552, -3.921810709721081],
              [114.838681551720342, -3.920074940712798],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Musa",
        No_SHM: "M 1118",
        Luas_SHM: 2.3972,
        TTL: "Surabaya, 9 Februari 1968",
        No: 13.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Musa",
        Nama_SHM: "Ardianta Ginting",
        X: "114.845698558564",
        Y: "-3.92182218327055",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845200954065021, -3.921242621090304],
              [114.845987204005681, -3.921491731784251],
              [114.846233238068663, -3.92229914916789],
              [114.845425356679769, -3.922238712023693],
              [114.845200954065021, -3.921242621090304],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Johan Suharmoko",
        No_SHM: "M 1111",
        Luas_SHM: 1.8346,
        TTL: "Malang, 14 Oktober 1974",
        No: 12.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "KJP-CPS",
        Nama_SHM: null,
        X: "114.844925890617",
        Y: "-3.92166944550076",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.844455667879089, -3.920996462249522],
              [114.845200954065021, -3.921242621090304],
              [114.845425356679769, -3.922238712023693],
              [114.844662358089025, -3.92218163183771],
              [114.844455667879089, -3.920996462249522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Edy Sucipto",
        No_SHM: "M 1110",
        Luas_SHM: 1.693,
        TTL: "Tegal, 18 April 1968",
        No: 11.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Edy Sucipto",
        Nama_SHM: "Elisabet Girsang,BBA",
        X: "114.844222909021",
        Y: "-3.92153014702384",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843809432329849, -3.920783018063614],
              [114.844455667879089, -3.920996462249522],
              [114.844662358089025, -3.92218163183771],
              [114.843989124440881, -3.922131266434974],
              [114.843809432329849, -3.920783018063614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1112",
        Luas_SHM: 1.7264,
        TTL: "Malang, 14 Oktober 1974",
        No: 10.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "PT. ASA",
        Nama_SHM: "Johan Suharmoko",
        X: "114.843489156948",
        Y: "-3.92142286892704",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843106065482075, -3.920550703486043],
              [114.843809432329849, -3.920783018063614],
              [114.843989124440881, -3.922131266434974],
              [114.843089322990195, -3.922106800627053],
              [114.843106065482075, -3.920550703486043],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Joni Rapianoor",
        No_SHM: "M 1106",
        Luas_SHM: 1.6906,
        TTL: "Marabahan, 14 Juni 1973",
        No: 9.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Joni Rapianoor",
        Nama_SHM: null,
        X: "114.842785664154",
        Y: "-3.9212183954206",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842375252466255, -3.920371610703536],
              [114.842753523571645, -3.920434262421164],
              [114.843106065482075, -3.920550703486043],
              [114.843089322990195, -3.922106800627053],
              [114.842603430450211, -3.922093588698671],
              [114.842375252466255, -3.920371610703536],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Irwansyah",
        No_SHM: "M 1109",
        Luas_SHM: 1.9364,
        TTL: "Binjai, 12 Maret 1972",
        No: 8.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Irwansyah",
        Nama_SHM: "Elisabet Girsang,BBA",
        X: "114.842091266293",
        Y: "-3.92119800190919",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.841811605936087, -3.922072057559765],
              [114.841589254906708, -3.92024142815422],
              [114.842375252466255, -3.920371610703536],
              [114.842603430450211, -3.922093588698671],
              [114.841811605936087, -3.922072057559765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Nur Effendi",
        No_SHM: "M 1105",
        Luas_SHM: 1.805,
        TTL: "Bogor, 14 Juni 1974",
        No: 7.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Nur Effendi",
        Nama_SHM: "Ir. Suryanto Saragih,MS",
        X: "114.841333716384",
        Y: "-3.92112451258656",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840862908042411, -3.920121124746831],
              [114.841589254906708, -3.92024142815422],
              [114.841811605936087, -3.922072057559765],
              [114.841082767863497, -3.922052238479394],
              [114.840862908042411, -3.920121124746831],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Edy Sucipto",
        No_SHM: "M 1099",
        Luas_SHM: 1.7123,
        TTL: "Tegal, 18 April 1968",
        No: 6.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Edy Sucipto",
        Nama_SHM: "Ahmad Jani Ginting",
        X: "114.840596290676",
        Y: "-3.92103478624042",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840079513563765, -3.920003421287823],
              [114.840369189798821, -3.920039350814328],
              [114.840862908042411, -3.920121124746831],
              [114.841082767863497, -3.922052238479125],
              [114.840380924156932, -3.922033152854004],
              [114.840079513563765, -3.920003421287823],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Rusmayanti",
        No_SHM: "M 1374",
        Luas_SHM: 1.7245,
        TTL: "Banjarmasin, 25 Januari 1970",
        No: 5.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Rusmayanti",
        Nama_SHM: "Angga Islamanda",
        X: "114.839869872479",
        Y: "-3.92098086156557",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839360382434904, -3.919967772723073],
              [114.839550332511678, -3.919937784916164],
              [114.840079513563765, -3.920003421287823],
              [114.840380924156932, -3.922033152854004],
              [114.839663978650947, -3.921965497623123],
              [114.839360382434904, -3.919967772723073],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Edy Sucipto",
        No_SHM: "M 1115",
        Luas_SHM: 2.2423,
        TTL: "Tegal, 18 April 1968",
        No: 15.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Edy Sucipto",
        Nama_SHM: null,
        X: "114.837979392528",
        Y: "-3.92201588003465",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836479632181323, -3.921602995798453],
              [114.837372780091101, -3.921490639980681],
              [114.838224726200636, -3.921635819000066],
              [114.838988231771552, -3.921810709721081],
              [114.839663978650947, -3.921965497623123],
              [114.837976191796884, -3.922707730589722],
              [114.837247711808146, -3.922257902787182],
              [114.836661681731115, -3.921778052196924],
              [114.836479632181323, -3.921602995798453],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Neti Hermiati",
        No_SHM: "M 1119",
        Luas_SHM: 2.793,
        TTL: "Pontianak, 8 Oktober 1972",
        No: 16.0,
        Luas_Inkes: 3.0,
        Nama_Baru: "Neti Hermiati",
        Nama_SHM: "Abren Ginting",
        X: "114.839419385875",
        Y: "-3.92271015586141",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837976191796884, -3.922707730589722],
              [114.839663978650947, -3.921965497623123],
              [114.840380924156932, -3.922033152854004],
              [114.840219452242437, -3.923270149382756],
              [114.839765761176949, -3.923510794499191],
              [114.839211507292248, -3.923249944235896],
              [114.838563276328117, -3.923141913132157],
              [114.838063062442941, -3.922774319007385],
              [114.837976191796884, -3.922707730589722],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1096",
        Luas_SHM: 1.9814,
        TTL: "Aceh Tenggara, 22 Agustus 1969",
        No: 17.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Farasian Simbolon",
        Nama_SHM: "M. Zein Ginting",
        X: "114.84092841163",
        Y: "-3.92264787026818",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840380924156932, -3.922033152854004],
              [114.841082767863497, -3.922052238479125],
              [114.841811605936087, -3.922072057559765],
              [114.841150816147149, -3.923478274570213],
              [114.840713865747489, -3.923244516567272],
              [114.840219452242437, -3.923270149382756],
              [114.840380924156932, -3.922033152854004],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1116",
        Luas_SHM: 2.2791,
        TTL: "Tegal, 18 April 1968",
        No: 18.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "PT. ASA",
        Nama_SHM: "Edy Sucipto",
        X: "114.842045303336",
        Y: "-3.92284536624799",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.841150816147149, -3.923478274570213],
              [114.841811605936087, -3.922072057559765],
              [114.843089322990195, -3.922106800627053],
              [114.841979954036475, -3.923830179748609],
              [114.841150816147149, -3.923478274570213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Musa",
        No_SHM: "M 1117",
        Luas_SHM: 2.1328,
        TTL: "Surabaya, 9 Februari 1968",
        No: 19.0,
        Luas_Inkes: 3.0,
        Nama_Baru: "Musa",
        Nama_SHM: "M. Zein Ginting",
        X: "114.842965961201",
        Y: "-3.92308566168727",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843089322990195, -3.922106800627053],
              [114.843989124440881, -3.922131266434974],
              [114.842809092992567, -3.924182084482714],
              [114.841979954036475, -3.923830179748609],
              [114.843089322990195, -3.922106800627053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Subiyantono",
        No_SHM: "M 1108",
        Luas_SHM: 1.9642,
        TTL: "Bondowoso, 21 Juni 1965",
        No: 20.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Subiyantono",
        Nama_SHM: "Elisabet Girsang,BBA",
        X: "114.844919324372",
        Y: "-3.92256783077114",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843989124440881, -3.922131266434974],
              [114.846233238068663, -3.92229914916789],
              [114.84550218883885, -3.923122181401897],
              [114.843661517488215, -3.922700627869468],
              [114.843989124440881, -3.922131266434974],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Wahyu Wibowo",
        No_SHM: "M 1098",
        Luas_SHM: 1.637,
        TTL: "Kediri, 1 Januari 1972",
        No: 21.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Wahyu Wibowo",
        Nama_SHM: "Abren Ginting",
        X: "114.844401779405",
        Y: "-3.9232437395121",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843328117239992, -3.923280056272028],
              [114.843661517488215, -3.922700627869468],
              [114.84550218883885, -3.923122181401897],
              [114.844957472066113, -3.923841903567483],
              [114.843328117239992, -3.923280056272028],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Nurcholis",
        No_SHM: "M 1104",
        Luas_SHM: 1.9246,
        TTL: "Malang, 9 Juni 1942",
        No: 22.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Nurcholis",
        Nama_SHM: "Ardianta Ginting",
        X: "114.84383422636",
        Y: "-3.92396695002563",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842807182802133, -3.924183993112183],
              [114.843328117239992, -3.923280056272028],
              [114.844957472066113, -3.923841903567483],
              [114.844247877397279, -3.92461490222338],
              [114.842807182802133, -3.924183993112183],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Imam",
        No_SHM: "M 1401",
        Luas_SHM: 0.7266,
        TTL: "Kebumen, 20 April 1973",
        No: 14.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Imam",
        Nama_SHM: "Ardianta Ginting",
        X: "114.846548794604",
        Y: "-3.92255651558987",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845987204005681, -3.921491731784251],
              [114.847642639674049, -3.922016002890585],
              [114.846498857387743, -3.923644284840684],
              [114.84550218883885, -3.923122181401897],
              [114.846233238068663, -3.92229914916789],
              [114.845987204005681, -3.921491731784251],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1403",
        Luas_SHM: 1.8857,
        TTL: "Bandung, 15 November 1962",
        No: 23.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "KJP-CPS",
        Nama_SHM: "Ir. Suryanto Saragih,MS",
        X: "114.845405886142",
        Y: "-3.92400289314095",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84550218883885, -3.923122181401897],
              [114.846498857387743, -3.923644284840684],
              [114.845313221078399, -3.924741978315781],
              [114.844249787587771, -3.924612993589322],
              [114.844957472066113, -3.923841903567483],
              [114.84550218883885, -3.923122181401897],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Heriyadi",
        No_SHM: "M 2010",
        Luas_SHM: 0.0,
        TTL: "Kandangan, 14 April 1963",
        No: 24.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Heriyadi",
        Nama_SHM: "Heriyadi",
        X: "114.846042728323",
        Y: "-3.92469113435647",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.846498857387743, -3.923644284840684],
              [114.846918866321687, -3.924401307484899],
              [114.845678638438585, -3.925722273248911],
              [114.845267601811173, -3.925277931937991],
              [114.845313221078399, -3.924741978315781],
              [114.846498857387743, -3.923644284840684],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Ririen Kartika Rini",
        No_SHM: "M 2011",
        Luas_SHM: 0.0,
        TTL: "Banjarbaru, 21 April 1968",
        No: 25.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Ririen Kartika Rini",
        Nama_SHM: "Ririen Kartika Rini",
        X: "114.846577002751",
        Y: "-3.92545534814983",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845678638438585, -3.925722273248911],
              [114.846918866321687, -3.924401307484899],
              [114.847400831331171, -3.925269998671875],
              [114.846356655383062, -3.926446476085726],
              [114.845678638438585, -3.925722273248911],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Tjindar Bumi Kusuma Ningsih",
        No_SHM: "M 2012",
        Luas_SHM: 0.5717,
        TTL: "Jakarta, 30 Januari 1966",
        No: 26.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Tjindar Bumi Kusuma Ningsih",
        Nama_SHM: "Tjindar Bumi Kusuma Ningsih",
        X: "114.846488835647",
        Y: "-3.92642420594876",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847400831330873, -3.925269998671874],
              [114.847493418841154, -3.925436877804722],
              [114.846103402445848, -3.926924953895007],
              [114.846139184967328, -3.927006426327093],
              [114.8457871352133, -3.927394257504403],
              [114.845588827173032, -3.927502230456284],
              [114.845508070154395, -3.927402575855163],
              [114.846356655383062, -3.926446476085726],
              [114.847400831330873, -3.925269998671874],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Rochmanudin",
        No_SHM: "M 2015",
        Luas_SHM: 11675.5,
        TTL: "Purworejo, 29 Maret 1977",
        No: 44.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Rochmanudin",
        Nama_SHM: "Rocmanudin",
        X: "114.844997979378",
        Y: "-3.92696319902112",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845853947205185, -3.925911786469127],
              [114.845329852313526, -3.926696954953493],
              [114.845374673485367, -3.92676939500695],
              [114.845260508277136, -3.926907897677832],
              [114.845031184287393, -3.927329017507824],
              [114.845003739453261, -3.927500716090697],
              [114.844687508854648, -3.927960963162379],
              [114.844193455905582, -3.927561901690414],
              [114.844626680632018, -3.927110998257733],
              [114.845678638438585, -3.925722273248911],
              [114.845853947205185, -3.925911786469127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Wene Herlena",
        No_SHM: "M 2014",
        Luas_SHM: 1.4743,
        TTL: "Jarang Kuantan, 3 Juni 1984",
        No: 43.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Wene Herlena",
        Nama_SHM: "Wene Herlena",
        X: "114.844895344642",
        Y: "-3.92617462817788",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845678638438585, -3.925722273248911],
              [114.844626680632018, -3.927110998257733],
              [114.844115298271007, -3.926449717624243],
              [114.845267601811173, -3.925277931937991],
              [114.845678638438585, -3.925722273248911],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Totok Dewanto",
        No_SHM: "M 2013",
        Luas_SHM: 1.0829,
        TTL: "Banjarbaru, 4 Desember 1965",
        No: 40.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Totok Dewanto",
        Nama_SHM: "Totok Dewanto",
        X: "114.844205489988",
        Y: "-3.92597524735056",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.845267601811173, -3.925277931937991],
              [114.844115298271007, -3.926449717624243],
              [114.843862727256862, -3.926656995177072],
              [114.843471662138825, -3.926590493177473],
              [114.843982621748296, -3.925536283540769],
              [114.844404494858935, -3.925568136359018],
              [114.845267601811173, -3.925277931937991],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Welly Irianto",
        No_SHM: "M 1404",
        Luas_SHM: 1.7855,
        TTL: "Banjarmasin, 5 April 1958",
        No: 38.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Welly Irianto",
        Nama_SHM: "M. Zein Ginting",
        X: "114.843787769696",
        Y: "-3.92494828960551",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842255594208467, -3.925113685867918],
              [114.842807841885246, -3.924184190243322],
              [114.844249663227828, -3.92461295639361],
              [114.845313221078399, -3.924741978315781],
              [114.845267601811173, -3.925277931937991],
              [114.844404494858935, -3.925568136359018],
              [114.843982621748296, -3.925536283540769],
              [114.842255594208467, -3.925113685867918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Nutri Lana",
        No_SHM: "M 1400",
        Luas_SHM: 0.8565,
        TTL: "Banjarmasin, 28 Desember 1974",
        No: 39.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Nutri Lana",
        Nama_SHM: "Ir. Suryanto Saragih,MS",
        X: "114.842239639475",
        Y: "-3.92569018142723",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.842255594208467, -3.925113685867918],
              [114.842893591700062, -3.925269802058158],
              [114.842578764594379, -3.925397040998238],
              [114.842405580399202, -3.926237358106754],
              [114.841679635676982, -3.926063356188627],
              [114.842255594208467, -3.925113685867918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Lusita Wardani",
        No_SHM: "M 2016",
        Luas_SHM: 10475.6,
        TTL: "Blitar, 10 September 1963",
        No: 41.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Lusita Wardani",
        Nama_SHM: "Lusita Wardani",
        X: "114.84296167153",
        Y: "-3.92696683397245",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.84364678834055, -3.926620274026244],
              [114.843032097249846, -3.92763121734197],
              [114.842367159831809, -3.927177469627233],
              [114.84234027198103, -3.927132197490828],
              [114.842529741141234, -3.92696091914791],
              [114.842466917645098, -3.926888432421948],
              [114.842774240270032, -3.926392001744573],
              [114.843448712467662, -3.926637842012275],
              [114.843471662138825, -3.926590493177473],
              [114.84364678834055, -3.926620274026244],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Faisal Wahyudi",
        No_SHM: "M 2017",
        Luas_SHM: 1.1945,
        TTL: "Murung Pudak, 23 Februari 1982",
        No: 42.0,
        Luas_Inkes: 1.0,
        Nama_Baru: "Faisal Wahyudi",
        Nama_SHM: "Faisal Wahyudi",
        X: "114.842420664978",
        Y: "-3.9278726570459",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.843032097249846, -3.92763121734197],
              [114.842905942486482, -3.927685132473836],
              [114.842887611369505, -3.927811652833464],
              [114.842914522486367, -3.927847884545985],
              [114.842806204478734, -3.927965130261847],
              [114.842860119612894, -3.928001431959769],
              [114.842652298995333, -3.928308270103748],
              [114.842930388267675, -3.928679651113866],
              [114.842804137087526, -3.928769728807831],
              [114.842597431358072, -3.928642626092137],
              [114.84254363238469, -3.928561122203973],
              [114.842625271737489, -3.928317240541473],
              [114.842319621098866, -3.92816275989596],
              [114.842202115763598, -3.928352305565303],
              [114.842013320003389, -3.92826141119549],
              [114.841780377428478, -3.927835904306072],
              [114.842367159831809, -3.927177469627233],
              [114.843032097249846, -3.92763121734197],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Hilmi Hasan",
        No_SHM: "M 1402",
        Luas_SHM: 1.1532,
        TTL: "Kotabaru, 10 November 1967",
        No: 37.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Hilmi Hasan",
        Nama_SHM: "M. Zein Ginting",
        X: "114.840899040847",
        Y: "-3.92639724042501",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840426058529417, -3.925933891149207],
              [114.841679635676982, -3.926063356188627],
              [114.841559084986898, -3.926379812257423],
              [114.841720690539802, -3.926542959898089],
              [114.84152164009015, -3.926940223738511],
              [114.841324423490875, -3.926623297066674],
              [114.841077290668665, -3.926465838217046],
              [114.840973302608447, -3.926649507932006],
              [114.840910386844413, -3.926613183457023],
              [114.840774715873437, -3.926865964594428],
              [114.840701802194218, -3.926859348834168],
              [114.840594131811386, -3.927082467540128],
              [114.840127690570739, -3.926448424634654],
              [114.840426058529417, -3.925933891149207],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Hamdan Sitorus",
        No_SHM: "M 1114",
        Luas_SHM: 2.5997,
        TTL: "Medan, 15 Mei 1967",
        No: 35.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Hamdan Sitorus",
        Nama_SHM: "Elisabet Girsang,BBA",
        X: "114.841528497524",
        Y: "-3.92421492946143",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.840713865747489, -3.923244516567272],
              [114.841150816147149, -3.923478274570213],
              [114.841989160631883, -3.923815877567721],
              [114.842807841885246, -3.924184190243322],
              [114.842255594208467, -3.925113685867918],
              [114.840359684721264, -3.924351234746897],
              [114.840713865747489, -3.923244516567272],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1113",
        Luas_SHM: 2.3489,
        TTL: "Malang, 14 Oktober 1974",
        No: 36.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "KJP-CPS",
        Nama_SHM: "Ir. Parmin Purba",
        X: "114.840988724656",
        Y: "-3.92531637034179",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839877211561287, -3.925842062990316],
              [114.840359684721264, -3.924351234746897],
              [114.842255594208467, -3.925113685867918],
              [114.841679635676982, -3.926063356188627],
              [114.840426058529417, -3.925933891149207],
              [114.839877211561287, -3.925842062990316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Neti Hermiati",
        No_SHM: "M 1120",
        Luas_SHM: 2.2626,
        TTL: "Pontianak, 8 Oktober 1972",
        No: 34.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Neti Hermiati",
        Nama_SHM: "Ahmad Jani Ginting",
        X: "114.839819061771",
        Y: "-3.92455337889506",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838877603363841, -3.92551513667203],
              [114.839765761176949, -3.923510794499191],
              [114.840219452242437, -3.923270149382756],
              [114.840713865747489, -3.923244516567272],
              [114.839877211561287, -3.925842062990316],
              [114.838877603363841, -3.92551513667203],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Edy Sucipto",
        No_SHM: "M 1101",
        Luas_SHM: 1.6842,
        TTL: "Tegal, 18 April 1968",
        No: 33.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "KJP-CPS",
        Nama_SHM: "Ir. Parmin Purba",
        X: "114.839003214719",
        Y: "-3.92441050698771",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.839211507292248, -3.923249944235896],
              [114.839765761176949, -3.923510794499191],
              [114.838877603867871, -3.925515135534368],
              [114.838209290249381, -3.925270598945201],
              [114.839211507292248, -3.923249944235896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Wahyu Wibowo",
        No_SHM: "M 1103",
        Luas_SHM: 1.749,
        TTL: "Kediri, 1 Januari 1972",
        No: 32.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Wahyu Wibowo",
        Nama_SHM: "Ardianta Ginting",
        X: "114.838369153895",
        Y: "-3.92418793488207",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.838563276328117, -3.923141913132157],
              [114.839211507292248, -3.923249944235896],
              [114.838209290249381, -3.925270598945201],
              [114.837531295222789, -3.924977568366264],
              [114.838563276328117, -3.923141913132157],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Wiwik Widya Hariyanti",
        No_SHM: "M 1097",
        Luas_SHM: 1.749,
        TTL: "Blora, 4 Mei 1965",
        No: 31.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Wiwik Widya Hariyanti",
        Nama_SHM: "M. Zein Ginting",
        X: "114.837749356985",
        Y: "-3.92385066188287",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.837531295222789, -3.924977568366264],
              [114.836928101648027, -3.924582535867398],
              [114.837976169918221, -3.922707714409255],
              [114.838563276328117, -3.923141913132157],
              [114.837531295222789, -3.924977568366264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Lusita Wardani",
        No_SHM: "M 1095",
        Luas_SHM: 1.9654,
        TTL: "Blitar, 10 September 1963",
        No: 30.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Lusita Wardani",
        Nama_SHM: "M. Zein Ginting",
        X: "114.837156193988",
        Y: "-3.92338990233215",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.836384745901299, -3.924165085003082],
              [114.837247711808146, -3.922257902787182],
              [114.837976191796884, -3.922707730589722],
              [114.836928098929121, -3.924582534014665],
              [114.836694479013758, -3.924387328644233],
              [114.836384745901299, -3.924165085003082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Wahyu Wibowo",
        No_SHM: "M 1102",
        Luas_SHM: 1.8316,
        TTL: "Kediri, 1 Januari 1972",
        No: 29.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Wahyu Wibowo",
        Nama_SHM: "Ardianta Ginting",
        X: "114.836521831072",
        Y: "-3.92298077818458",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835791488376998, -3.923739404363321],
              [114.8366616817314, -3.921778052197193],
              [114.837247711808146, -3.922257902787182],
              [114.836384745901299, -3.924165085003082],
              [114.835791488376998, -3.923739404363321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "Krisna Mahdi",
        No_SHM: "M 1107",
        Luas_SHM: 1.6905,
        TTL: "Pontianak, 28 November 1955",
        No: 28.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Krisna Mahdi",
        Nama_SHM: "Ir. Suryanto Saragih,MS",
        X: "114.835955130054",
        Y: "-3.92258590405041",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.835235009286222, -3.923423033179688],
              [114.836091886118453, -3.921485336629618],
              [114.836479632181323, -3.921602995798453],
              [114.8366616817314, -3.921778052196925],
              [114.835791488376998, -3.923739404363321],
              [114.835670220849281, -3.923652391868881],
              [114.835235009286222, -3.923423033179688],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Jilatan",
        Nm_Pemilik: "PT. ASA",
        No_SHM: "M 1100",
        Luas_SHM: 1.8929,
        TTL: "Malang, 24 Februari 1972",
        No: 27.0,
        Luas_Inkes: 2.0,
        Nama_Baru: "Umar Winarno",
        Nama_SHM: "Ahmad Jani Ginting",
        X: "114.835295787282",
        Y: "-3.92243452138938",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.834411746377938, -3.922989169825694],
              [114.83576152629314, -3.921320168502287],
              [114.836091886118453, -3.921485336629618],
              [114.835235009286222, -3.923423033179688],
              [114.834411746377938, -3.922989169825694],
            ],
          ],
        ],
      },
    },
  ],
};
