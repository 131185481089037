import React, { useState } from "react";
import ButtonComponent from "../../../../../../../components/atoms/ButtonComponent";
import { TbFilter } from "react-icons/tb";
import Modal from "./partials/Modal";

const MobileFilter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div>
      <Modal isModalOpen={isModalOpen} showModal={showModal} />

      <div className="fixed right-16 bottom-8 z-10 flex flex-row items-center space-x-16 md:bottom-16">
        <div className="w-fit rounded-16 bg-green-600 p-10 text-16 text-white shadow-lg md:right-16">
          <p>HI = Hari Ini</p>
        </div>
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition w-fit rounded-16 bg-red-mobile p-10 text-16 text-white md:hidden"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-white" />}
        />
      </div>
    </div>
  );
};

export default MobileFilter;
