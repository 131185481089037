import { Bar as ChartBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { defaultOptions } from "./config/options";

const Bar = ({
	width,
	height,
	labels,
	dataset,
	moreDatasets,
	options,
	backgroundColor,
	hoverBackgroundColor,
	borderColor,
	withoutMainDatasets,
	plugins = [],
}) => {
	let datasets = [
		{
			label: "Akurasi (%)",
			data: dataset,
			backgroundColor: backgroundColor || "#E4DFFE",
			hoverBackgroundColor: hoverBackgroundColor || "#5446CD",
			borderColor: borderColor || "rgba(0, 0, 0, 0)",
			borderWidth: borderColor && 2,
		},
	];

	if (moreDatasets && !withoutMainDatasets) {
		datasets = datasets.concat(moreDatasets);
	} else if (moreDatasets && withoutMainDatasets) {
		datasets = moreDatasets;
	}

	const data = {
		labels: labels,
		datasets: datasets,
	};
	return (
		<ChartBar
			plugins={[ChartDataLabels, ...plugins]}
			options={options || defaultOptions}
			data={data}
			width={width}
			height={height}
		/>
	);
};

export default Bar;
