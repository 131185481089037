import Item from "./partials/Item/Item";
import React from "react";

const TableRow = ({ data, handleRefreshData }) => {
	return data.map((item, index) => {
		return (
			<Item
				item={item}
				index={index}
				key={index}
				handleRefreshData={handleRefreshData}
			/>
		);
	});
};

export default TableRow;
