import React from "react";

const Header = ({ title, subTitle }) => {
  return (
    <div className="flex flex-col">
      <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
        {title}
      </h3>
      <p className="font-regular text-13 text-gray-400 md:text-14">
        {subTitle}
      </p>
    </div>
  );
};

export default Header;
