export const tanjung2012 = {
  type: "FeatureCollection",
  name: "kav_tanjung_tt2012_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 1.0,
        AREA: 20000.015,
        Luas: 2.0,
        PERIMETRO: 679.863,
        KET: "Bebas Kawasan",
        Nm_Pemilik: "Suhadak",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.847148247319552, -3.750250244028264],
              [114.847636115601475, -3.74981669057375],
              [114.849480002354383, -3.75018116963833],
              [114.849028833879999, -3.750906279153301],
              [114.848705445782173, -3.75142601652658],
              [114.848651433572314, -3.751377948378205],
              [114.848656658766984, -3.751008896475474],
              [114.847148247319552, -3.750250244028264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 3.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 574.943,
        KET: "Bebas Kawasan",
        Nm_Pemilik: "Edy Syarif D.",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.850103838612895, -3.751395008509629],
              [114.849335157469511, -3.752768017215519],
              [114.84907451866296, -3.752609104390892],
              [114.848785453188214, -3.752421753987161],
              [114.848554915440999, -3.75196170079389],
              [114.848815322480334, -3.751523807987807],
              [114.84870544752394, -3.751426018284951],
              [114.849028733022166, -3.750906441931816],
              [114.850103838612895, -3.751395008509629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 2.0,
        AREA: 15561.96,
        Luas: 1.556,
        PERIMETRO: 527.883,
        KET: "Bebas Kawasan",
        Nm_Pemilik: "Suhadak",
        TANAM: "2012",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.849480002354383, -3.75018116963833],
              [114.849728248996598, -3.750230239992322],
              [114.850790969372611, -3.750167657401875],
              [114.850103838612895, -3.751395008509629],
              [114.84902873322406, -3.750906441595611],
              [114.849480002354383, -3.75018116963833],
            ],
          ],
        ],
      },
    },
  ],
};
