import React, { useEffect, useReducer, useState } from "react";
import { usePriceListStore } from "../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import { useUserStore } from "../../../../MasterWorkerPage/hooks/userStore";
import useFilter from "../../../../../hooks/useFilter";
import moment from "moment/moment";
import HistoryData from "../HistoryData";
import { ConfigProvider, DatePicker } from "antd";
import { TbCalendar, TbRefresh } from "react-icons/tb";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import Pagination from "../../../../../components/atoms/Pagination";
const { RangePicker } = DatePicker;

const HistoryPrice = () => {
  const [getPriceLists] = usePriceListStore((state) => [state.getPriceLists]);
  const [user] = useUserStore((state) => [state.user]);

  const initialFilter = {
    pageIndex: 0,
    pageSize: 20,
    fltCompany: undefined,
    fltEndDateLe: moment().format("YYYY-MM-DD"),
    sord: "desc",
    sidx: "syncInfo.createdTime",
  };

  const { isFilterBy, setIsFilterBy, onFilter } = useFilter({ initialFilter });
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const paginationLength = Math.ceil(page?.total / 20);

  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );

  const fetchData = async (pageIndex, beforeFetch, isSilent) => {
    if (beforeFetch) {
      beforeFetch();
    }
    if (!isSilent) setRequest({ isLoading: true });
    await getPriceLists({
      params: {
        ...isFilterBy,
        pageIndex,
      },
      onSuccess: (response) => {
        setData(response?.records);
        const totalRecords = response?.totalRecords;
        setPage({
          current: pageIndex,
          total: totalRecords,
        });
        setTimeout(() => {
          setRequest({ isLoading: false });
        }, 800);
      },
    });
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const refreshHandler = async (isSilent) => {
    await fetchData(0, null, isSilent);
  };

  useEffect(() => {
    setIsFilterBy({ pageIndex: page.current, fltCompany: user?.companyId });
  }, [user?.companyId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) refreshHandler();
    return () => (isMounted = false);
  }, [isFilterBy]);

  return (
    <div className="mx-16 grid grid-cols-1 grid-rows-[max-content,1fr,max-content] gap-12 overflow-hidden rounded-b-16">
      <div className="flex w-full flex-col items-center justify-between space-y-12 md:flex-row md:space-y-0">
        <h1 className="text-16 font-bold text-gray-500 md:text-24">
          Riwayat Pemberlakuan Harga
        </h1>
        <div className="flex flex-row items-center space-x-8">
          <ButtonComponent
            tooltipPlacement="bottom"
            tooltipTitle="Muat ulang"
            value="Muat ulang"
            size="none"
            type="button"
            className="bordered-button-transition w-fit bg-white p-10"
            onClick={() => refreshHandler()}
            icon={<TbRefresh className="text-18 text-gray-500" />}
          />

          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#d7a137",
                colorTextQuaternary: "#e1e4e8",
                fontFamily: "Inter-Regular",
                fontSize: 16,
                colorTextPlaceholder: "#6B6F77",
              },
            }}
          >
            <DatePicker
              format="DD MMM YYYY"
              placeholder="Pilih tanggal"
              onChange={(date) => {
                if (date !== null) {
                  onFilter({
                    type: "fltStartDateGe",
                    value: date,
                    filterHandler: ({ type, value, updateFilter }) => {
                      const dateParsed = new Date(value);
                      const date = moment(dateParsed).format("YYYY-MM-DD");
                      updateFilter({
                        fltStartDateGe: date,
                        fltEndDateLe: date,
                        sord: "asc",
                      });
                    },
                  });
                } else {
                  onFilter({
                    type: "fltStartDateGe",
                    value: date,
                    filterHandler: ({ type, value, updateFilter }) => {
                      updateFilter({
                        fltStartDateGe: undefined,
                        fltEndDateLe: undefined,
                        sord: "desc",
                      });
                    },
                  });
                }
              }}
              suffixIcon={<TbCalendar className="text-16" />}
              style={{
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                fontFamily: "Inter-Regular",
                maxWidth: 300,
                borderWidth: 1,
                borderColor: "#e1e4e8",
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      <HistoryData
        request={request}
        data={data}
        refreshHandler={refreshHandler}
      />
      <div className="-mt-12">
        <Pagination.Container className="mx-0 mb-0">
          <Pagination.Records
            current={page.current}
            length={paginationLength}
            totalRecords={page?.total}
          />
          {paginationLength !== 1 && (
            <Pagination
              pageCount={paginationLength}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default HistoryPrice;
