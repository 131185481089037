export const headersData = [
	{
		value: "NO.",
		accessorKey: "no",
		type: "alphabet",
	},
	{
		value: "Wilayah",
		accessorKey: "wilayah",
		type: "alphabet",
	},
	{
		value: "Blok",
		accessorKey: "block",
		type: "alphabet",
	},
	{
		value: "Tahun Tanam",
		accessorKey: "tahuntanam",
		type: "alphabet",
	},
	{
		value: "Produksi (Ton)",
		accessorKey: "produksi",
		type: "alphabet",
	},
	{
		value: "Luas (Ha)",
		accessorKey: "luas",
		type: "alphabet",
	},
	{
		value: "Yield (Ton/Hektar)",
		accessorKey: "yield",
		type: "alphabet",
	},
	{
		value: "Komentar",
		accessorKey: "remark",
		type: "alphabet",
	},
	{
		value: "Tanggal Tindakan",
		accessorKey: "caDueDate",
		type: "alphabet",
	},
];

export const data = [
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "H9",
		luas: 26.62,
		produksi: 80861.1,
		yield: 3.0376070623591285,
		plantedYear: 2004,
	},
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "H10",
		luas: 35.31,
		produksi: 72545,
		yield: 2.054517133956386,
		plantedYear: 2004,
	},
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "H11",
		luas: 11.32,
		produksi: 32541,
		yield: 2.87464664310954,
		plantedYear: 2004,
	},
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "G11",
		luas: 25.9,
		produksi: 66522.62,
		yield: 2.5684409266409265,
		plantedYear: 2009,
	},
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "G9",
		luas: 17.2,
		produksi: 28477,
		yield: 1.655639534883721,
		plantedYear: 2016,
	},
	{
		wilayah: "Jilatan (76, 27 & 29)",
		blok: "G10",
		luas: 12,
		produksi: 37300,
		yield: 3.108333333333333,
		plantedYear: 2015,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "F11",
		luas: 19.76,
		produksi: 16892,
		yield: 0.8548582995951416,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "F12",
		luas: 26.03,
		produksi: 31185,
		yield: 1.198040722243565,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "F13",
		luas: 29.92,
		produksi: 32161,
		yield: 1.074899732620321,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "F14",
		luas: 18.48,
		produksi: 21776,
		yield: 1.1783549783549783,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "F15",
		luas: 10,
		produksi: 10961,
		yield: 1.0961,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "H16",
		luas: 20.94,
		produksi: 23536,
		yield: 1.1239732569245464,
		plantedYear: 2009,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "E13",
		luas: 16.9,
		produksi: 8595,
		yield: 0.5085798816568048,
		plantedYear: 2011,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "E14",
		luas: 31.4,
		produksi: 8076,
		yield: 0.2571974522292994,
		plantedYear: 2011,
	},
	{
		wilayah: "Damar Lima (110, 70 & 20) ",
		blok: "E15",
		luas: 19.7,
		produksi: 7461,
		yield: 0.3787309644670051,
		plantedYear: 2011,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "F19",
		luas: 10,
		produksi: 19140.18,
		yield: 1.914018,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "F20",
		luas: 30,
		produksi: 35152.21,
		yield: 1.1717403333333332,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "F21",
		luas: 28,
		produksi: 23531,
		yield: 0.840392857142857,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "G19",
		luas: 21,
		produksi: 19205,
		yield: 0.9145238095238094,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "G20",
		luas: 22,
		produksi: 23815,
		yield: 1.0825,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "G21",
		luas: 33,
		produksi: 30225.739999999998,
		yield: 0.915931515151515,
		plantedYear: 2009,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "H19",
		luas: 30,
		produksi: 13781,
		yield: 0.4593666666666667,
		plantedYear: 2013,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "H20",
		luas: 40,
		produksi: 11802.15,
		yield: 0.29505375,
		plantedYear: 2014,
	},
	{
		wilayah: "Batalang (150 & 125) ",
		blok: "H21",
		luas: 40,
		produksi: 3911,
		yield: 0.097775,
		plantedYear: 2014,
	},
];
