import { TbPencil, TbTrash } from "react-icons/tb";

import React from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { useAlert } from "react-alert";
import { DEFAULT_TABLE_THEME } from "../../../../../../../utils/constant";
import { cn } from "../../../../../../../helpers/Common";

const TableRow = ({ data, onDelete, onUpdate }) => {
  const alert = useAlert();

  return data.map((item, index) => {
    return (
      <tr
        className={cn(
          "table-header-regular cursor-pointer select-none",
          DEFAULT_TABLE_THEME
        )}
        key={index}
      >
        <td className="table-row-regular font-semi-bold">{item?.name}</td>
        <td className="table-row-regular ">
          {moment(item?.createdTime).format("LL")}
        </td>
        {/* <td className="table-row-regular ">
					{moment(item?.syncedTime).format("LL")}
				</td>
				<td className="table-row-regular ">
					{moment(item?.updatedTime).format("LL")}
				</td> */}
        <td className="table-row-regular flex flex-row items-center space-x-16">
          <button
            className="rounded-[4px] p-8 outline-none"
            onClick={() => onUpdate({ item })}
          >
            <TbPencil className="text-24 text-gray-200 hover:text-gray-500" />
          </button>
          <button
            onClick={() =>
              Swal.fire({
                title: "Hapus TPH",
                text: `Kamu akan menghapus ${item?.name}, apa kamu yakin?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#dc2626",
                confirmButtonText: "Ya, hapus!",
                cancelButtonText: "Tidak",
                reverseButtons: true,
                heightAuto: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  onDelete(item.id);
                  alert.success(`${item.name} telah berhasil dihapus`);
                }
              })
            }
            className="rounded-[4px] p-8 outline-none"
          >
            <TbTrash className="text-24 text-gray-200 hover:text-gray-500" />
          </button>
        </td>
      </tr>
    );
  });
};

export default TableRow;
