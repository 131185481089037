import { notification } from "antd";
import { create } from "zustand";
import {
  getChemistRecapsDistinctBlocksApi,
  getChemistRecapsMaterialSummaryApi,
  getChemistRecapsOverallSummaryApi,
} from "./chemistRecapitulationApi";

export const useChemistRecapsStore = create((set, get) => ({
  distinctBlocks: [],
  materialSummary: [],
  overallSummary: [],
  isLoading: true,
  isLoadingRequest: true,
  loading: {}, //this is loading for delete loading state, with pass params id and remove id after finish delete
  getRecapsByDistinctBlocks: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getChemistRecapsDistinctBlocksApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Chemist Distinct Block",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ distinctBlocks: data.records, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      onError?.(error?.data);
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Rekap Chemist Distinct Block",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getFertilizationRecapsMaterialSummary: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getChemistRecapsMaterialSummaryApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Chemist Material Summary",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ materialSummary: data.records, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      onError?.(error?.data);
      if (silent) return;
      return notification.error({
        message: "Gagal Mendapatkan Rekap Chemist Material Summary",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
  getFertilizationRecapsOverallSummary: async ({
    params = {},
    silent,
    onSuccess,
    onError,
  }) => {
    try {
      set({ isLoading: true });
      const response = await getChemistRecapsOverallSummaryApi(params);
      const data = response?.data;
      if (!data.success) {
        set({ isLoading: false });
        onError?.(data.message);
        if (silent) return;
        return notification.error({
          message: "Gagal Mendapatkan Rekap Chemist Overall Summary",
          description: data.message,
          placement: "bottomRight",
        });
      }
      set({ overallSummary: data.record, isLoading: false });
      onSuccess?.(data);
      return data;
    } catch (error) {
      set({ isLoading: false });
      if (silent) return;
      onError?.(error?.data);
      return notification.error({
        message: "Gagal Mendapatkan Rekap Chemist Overall Summary",
        description: error.data,
        placement: "bottomRight",
      });
    }
  },
}));
