import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FILE_ATTACHMENTS_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	file: null,
	secFile: null,
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getFile = createAsyncThunk(
	"fileAttachment/getFile",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(
				`${FILE_ATTACHMENTS_URL}/${data.id}`,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const downloadFile = createAsyncThunk(
	"fileAttachment/downloadFile",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(
				`${FILE_ATTACHMENTS_URL}/${data.id}/download`,
				{
					responseType: "blob",
					headers: {
						Authorization: "Bearer " + token?.accessToken,
					},
				}
			);

			const href = URL.createObjectURL(response.data);

			// create "a" HTLM element with href to file & click
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", data.fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const downloadImage = createAsyncThunk(
	"fileAttachment/downloadImage",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(
				`${FILE_ATTACHMENTS_URL}/${data.id}/download`,
				{
					responseType: "blob",
					headers: {
						Authorization: "Bearer " + token?.accessToken,
					},
				}
			);
			const reader = new FileReader();
			reader.readAsDataURL(response.data);

			return reader;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const uploadFile = createAsyncThunk(
	"fileAttachment/uploadFile",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const formData = new FormData();
			formData.append("content", data.fileContent);
			formData.append("fileName", data.fileName);
			formData.append("userId", data.userId);
			formData.append("mimeType", "application/octet-stream");

			const response = await axios.post(
				`${FILE_ATTACHMENTS_URL}/upload`,
				formData,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
					},
				}
			);

			return response?.data;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fileAttachmentSlice = createSlice({
	name: "fileAttachment",
	initialState,
	reducers: {
		handleSetFile: (state, action) => {
			state.file = action.payload;
		},
		handleSecSetFile: (state, action) => {
			state.secFile = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getFile.fulfilled, () => {})
			.addCase(downloadFile.fulfilled, () => {})
			.addCase(uploadFile.fulfilled, () => {})
			.addCase(downloadImage.fulfilled, () => {});
	},
});

export const { handleSetFile, handleSecSetFile } = fileAttachmentSlice.actions;
export const selectFile = (state) => state.fileAttachment.file;
export const selectSecFile = (state) => state.fileAttachment.secFile;
export const selectStatuses = (state) => ({
	isLoading: state.fileAttachment.isLoading,
	isRequest: state.fileAttachment.isRequest,
	isError: state.fileAttachment.isError,
	errors: state.fileAttachment.errors,
});

export default fileAttachmentSlice.reducer;
