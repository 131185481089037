import { ConfigProvider, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectHook from "../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../features/blockSlice";
import { getEstates } from "../../../../../features/estateSlice";
import { getYears } from "../../../../../helpers/Common";
import { selectUser } from "../../../../../features/userSlice";
import { useData } from "../../hooks/DataContext";
import { useForm } from "react-hook-form";

const ModalFilter = () => {
  const { control } = useForm();
  const { handleOnFilter } = useData();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [estates, setEstates] = useState([]);
  const [afdelings, setAfdelings] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const handleGetBlocks = ({ afdelingId, name, page }) => {
    dispatch(
      getBlocks({
        companyId: user?.companyId,
        afdelingId,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response?.data?.records;
        let newData = [{ label: "Semua blok", value: undefined }];
        records.forEach((item) => {
          return newData?.push({
            value: `${item?.id}`,
            label: `${item?.blockName}`,
          });
        });
        setBlocks(newData);
      });
  };

  const handleGetAfdelings = ({ estate, name, page }) => {
    dispatch(
      getAfdelings({
        companyId: user?.companyId,
        estate,
        name: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response?.data?.records;
        let newData = [{ label: "Semua afdeling", value: undefined }];
        records.forEach((item) => {
          return newData?.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setAfdelings(newData);
      });
  };

  const handleGetEstates = ({ name, page }) => {
    dispatch(
      getEstates({
        companyId: user?.companyId,
        fltName: name || undefined,
        page: page || 0,
      })
    )
      .unwrap()
      .then((response) => {
        const records = response?.data?.records;
        let newData = [{ label: "Semua estate", value: undefined }];
        records.forEach((item) => {
          return newData?.push({
            value: `${item?.id}`,
            label: `${item?.name}`,
          });
        });
        setEstates(newData);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleGetEstates({});
    return () => (isMounted = false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <div className="grid grid-cols-1 gap-13">
        <SelectHook
          name="estate"
          data={estates}
          control={control}
          placeholder="Pilih estate"
          menuStyles={{ padding: 5 }}
          className="w-full"
          afterOnChange={(value) => {
            handleOnFilter({ type: "estate", value });
            if (value !== null) {
              handleGetAfdelings({ estate: value?.value });
            } else {
              setAfdelings([]);
            }
          }}
        />
        <SelectHook
          name="afdeling"
          data={afdelings}
          control={control}
          placeholder="Pilih afdeling"
          menuStyles={{ padding: 5 }}
          className="w-full"
          afterOnChange={(value) => {
            handleOnFilter({ type: "afdeling", value });
            if (value !== null) {
              handleGetBlocks({ afdelingId: value?.value });
            } else {
              setBlocks([]);
            }
          }}
        />
        <SelectHook
          control={control}
          data={blocks}
          name="block"
          placeholder="Pilih blok"
          className="w-full"
          afterOnChange={(value) => {
            handleOnFilter({
              value: value,
              type: "block",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        <SelectHook
          name="year"
          data={getYears()}
          control={control}
          placeholder="Pilih tahun tanam"
          menuStyles={{ padding: 5 }}
          className="w-full"
          afterOnChange={(value) => {
            handleOnFilter({ type: "plantedYear", value });
          }}
        />

        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Pilih tanggal"
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#e1e4e8",
          }}
          onChange={(value) => {
            handleOnFilter({ type: "date", value });
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default ModalFilter;
