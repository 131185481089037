import ButtonComponent from "../../../atoms/ButtonComponent";
import React from "react";

const BillingAlert = ({ navigateToPlans }) => {
	return (
		<div className="mx-16 flex flex-col items-center justify-between space-y-16 rounded-16 border-1 border-red-500 bg-red-30 p-24 md:mx-32 md:flex-row md:space-y-0">
			<div className="flex w-full flex-col space-y-13 md:w-[60%]">
				<h3 className="font-semi-bold text-[20px] text-red-900">
					Ingin tetap menggunakan SemaiKebun Gratis?
				</h3>
				<p className="font-regular text-14 text-red-900">
					Uji Coba Gratis Premium Anda berakhir pada 22 Juni 2022.
					Anda kehilangan akses ke fitur canggih dan data-data dari
					semua master data anda dan batas penggunaan sekarang akan
					berlaku. Tambahkan info penagihan Anda sekarang, sehingga
					tim Anda dapat melanjutkan dari bagian terakhir yang mereka
					tinggalkan!
				</p>
			</div>
			<ButtonComponent
				value="Tambahkan info penagihan"
				size="sm"
				type="button"
				className="button-transition"
				onClick={navigateToPlans}
			/>
		</div>
	);
};

export default BillingAlert;
