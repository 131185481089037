import {
	BsFillCheckCircleFill,
	BsFillExclamationCircleFill,
	BsFillInfoCircleFill,
	BsFillXCircleFill,
} from "react-icons/bs";

import PropTypes from "prop-types";
import React from "react";

const Alert = ({ options, message }) => {
	return (
		<div className="mt-16 flex flex-row items-center bg-white py-13 px-16 drop-shadow-lg">
			{options.type === "info" && (
				<BsFillInfoCircleFill className="text-gray-400" />
			)}
			{options.type === "success" && (
				<BsFillCheckCircleFill className="text-green-500" />
			)}
			{options.type === "warning" && (
				<BsFillExclamationCircleFill className="text-yellow-500" />
			)}
			{options.type === "error" && (
				<BsFillXCircleFill className="text-red-500" />
			)}
			<p className="ml-8 font-regular text-gray-500">{message}</p>
		</div>
	);
};

Alert.propTypes = {
	message: PropTypes.string.isRequired,
	options: PropTypes.shape({
		type: PropTypes.oneOf(["info", "success", "warning", "error"]),
	}),
};

export default Alert;
