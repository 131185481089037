import {
	CITIES_URL,
	PROVINCE_URL,
	SUBDISTRICTS_URL,
	VILLAGES_URL,
} from "../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
	provinces: [],
	cities: [],
	subdistricts: [],
	villages: [],
	provinceId: null,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getProvinces = createAsyncThunk(
	"area/provinces",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(PROVINCE_URL, {
				headers: {
					"content-type": "application/json",
				},
			});
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const getCities = createAsyncThunk(
	"area/cities",
	async (data, { rejectWithValue }) => {
		try {
			const id = data.id;
			const response = await axios.get(`${CITIES_URL}/${id}.json`, {
				headers: {
					"content-type": "application/json",
				},
			});
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const getSubdistricts = createAsyncThunk(
	"area/getSubdistricts",
	async (data, { rejectWithValue }) => {
		try {
			const id = data.id;
			const response = await axios.get(`${SUBDISTRICTS_URL}/${id}.json`, {
				headers: {
					"content-type": "application/json",
				},
			});
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const getVillages = createAsyncThunk(
	"area/getVillages",
	async (data, { rejectWithValue }) => {
		try {
			const id = data.id;
			const response = await axios.get(`${VILLAGES_URL}/${id}.json`, {
				headers: {
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const areaSlice = createSlice({
	name: "area",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProvinces.fulfilled, (state, action) => {
				state.isLoading = false;
				let remainingData = action.payload;
				let newData = [];
				remainingData?.forEach((item) => {
					return newData.push({
						id: item.id,
						value: item.name,
						label: item.name,
					});
				});
				state.provinces = newData;
			})
			.addCase(getCities.fulfilled, (state, action) => {
				state.isLoading = false;
				let remainingData = action.payload;
				let newData = [];
				remainingData?.forEach((item) => {
					return newData.push({
						id: item.id,
						value: item.name,
						label: item.name,
					});
				});
				state.cities = newData;
			})
			.addCase(getSubdistricts.fulfilled, (state, action) => {
				state.isLoading = false;
				let remainingData = action.payload;
				let newData = [];
				remainingData?.forEach((item) => {
					return newData.push({
						id: item.id,
						value: item.name,
						label: item.name,
					});
				});
				state.subdistricts = newData;
			})
			.addCase(getVillages.fulfilled, (state, action) => {
				state.isLoading = false;
				let remainingData = action.payload;
				let newData = [];
				remainingData?.forEach((item) => {
					return newData.push({
						id: item.id,
						value: item.name,
						label: item.name,
					});
				});
				state.villages = newData;
			});
	},
});

export const {} = areaSlice.actions;
export const selectArea = (state) => state.area;

export default areaSlice.reducer;
