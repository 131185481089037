import { ConfigProvider, DatePicker } from "antd";
import {
	MONTHS,
	getYears,
	parseToFloat,
} from "../../../../../../../helpers/Common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Controller } from "react-hook-form";
import InputHook from "../../../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../../../components/atoms/SelectHook";
import { getAfdelings } from "../../../../../../../features/afdelingSlice";
import { getBlocks } from "../../../../../../../features/blockSlice";
import { getEstates } from "../../../../../../../features/estateSlice";
import { selectUser } from "../../../../../../../features/userSlice";
import { useState } from "react";

const Content = ({
	register,
	control,
	errors,
	bjr,
	watch,
	isOpen,
	setValue,
}) => {
	const dispatch = useDispatch();

	const user = useSelector(selectUser);
	const [estates, setEstates] = useState([]);
	const [afdelings, setAfdelings] = useState([]);
	const [blocks, setBlocks] = useState([]);
	const watchAfdeling = watch("afdeling");
	const watchEstate = watch("estate");
	const watchTbs = watch("tbs");
	const watchTonnase = watch("tonnase");

	const handleGetBlocks = (afdelingId, name) => {
		dispatch(
			getBlocks({
				companyId: user?.companyId,
				afdelingId,
				name: name || undefined,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua blok", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.blockName}`,
						plantedYear: `${item?.plantedYear}`,
					});
				});
				setBlocks(newData);
			});
	};

	const handleGetAfdelings = (estate, name) => {
		dispatch(
			getAfdelings({
				companyId: user?.companyId,
				estate,
				name: name || undefined,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua afdeling", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.name}`,
					});
				});
				setAfdelings(newData);
			});
	};

	const handleGetEstates = (name) => {
		dispatch(
			getEstates({
				companyId: user?.companyId,
				fltName: name || undefined,
			})
		)
			.unwrap()
			.then((response) => {
				const records = response.data.records;
				let newData = [{ label: "Semua estate", value: undefined }];
				records.forEach((item) => {
					return newData.push({
						value: `${item?.id}`,
						label: `${item?.name}`,
					});
				});
				setEstates(newData);
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) handleGetEstates();
		return () => (isMounted = false);
	}, [isOpen]);

	useEffect(() => {
		const subscription = watch((value) => {
			if (value?.estate) {
				handleGetAfdelings(value.estate.value);
			}
			if (value?.afdeling) {
				handleGetBlocks(value.afdeling.value);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		const totalBjr = parseInt(watchTonnase) / parseInt(watchTbs);

		if (!isNaN(totalBjr)) {
			setValue("bjr", totalBjr?.toFixed(2) || 0);
		}
	}, [watchTbs, watchTonnase]);

	return (
		<div className="relative grid grid-cols-1 gap-13">
			<SelectHook
				label="Estate/Kebun"
				required
				name="estate"
				errors={errors?.estate}
				data={estates}
				control={control}
				placeholder="Pilih estate atau kebun"
				onInputChange={(searchValue) => handleGetEstates(searchValue)}
			/>
			{watchEstate && (
				<SelectHook
					label="Afdeling/Wilayah"
					required
					name="afdeling"
					errors={errors?.afdeling}
					data={afdelings}
					control={control}
					placeholder="Pilih afdeling atau wilayah"
					onInputChange={(searchValue) =>
						handleGetAfdelings(watchEstate.value, searchValue)
					}
				/>
			)}
			{watchAfdeling && (
				<SelectHook
					label="blocks"
					required
					name="block"
					errors={errors?.block}
					data={blocks}
					control={control}
					placeholder="Pilih blok"
					afterOnChange={(value) =>
						setValue("plantingYear", value.plantedYear)
					}
					onInputChange={(searchValue) => {
						handleGetBlocks(watchAfdeling.value, searchValue);
					}}
				/>
			)}
			<InputHook
				disabled
				required
				register={register}
				label="Tahun Tanam"
				name="plantingYear"
				placeholder="Masukkan tahun tanam"
				errors={errors?.plantingYear}
				inputClassName="input-large-border"
			/>
			<InputHook
				required
				register={register}
				label="Tonase"
				name="tonnase"
				placeholder="Masukkan jumlah tonase"
				errors={errors?.tonnase}
				inputClassName="input-large-border"
				secondaryLabel={
					watchTonnase?.length >= 1 &&
					` (${parseToFloat(watchTonnase)}) Kg`
				}
			/>
			<InputHook
				required
				register={register}
				label="Tandan Buah Segar"
				name="tbs"
				placeholder="Masukkan jumlah tonase"
				errors={errors?.tbs}
				inputClassName="input-large-border"
				secondaryLabel={
					watchTbs?.length >= 1 &&
					`(${parseToFloat(watchTbs)}) Janjang`
				}
			/>
			<InputHook
				required
				register={register}
				label="Bjr Acuan"
				name="bjr"
				placeholder="Masukkan BJR Acuan"
				errors={errors?.bjr}
				inputClassName="input-large-border"
				secondaryLabel={
					bjr?.length >= 1 &&
					`(${parseToFloat(bjr || 0) || parseToFloat(bjr || 0)}) Kg`
				}
			/>
			<SelectHook
				required
				label="Bulan Panen"
				name="month"
				errors={errors?.month}
				data={MONTHS.reduce((acc, curr, curi) => {
					acc.push({ label: curr, value: curi + 1 });
					return acc;
				}, [])}
				control={control}
				placeholder="Pilih bulan panen"
			/>
			<SelectHook
				required
				label="Tahun Panen"
				name="year"
				errors={errors?.year}
				data={getYears()}
				control={control}
				placeholder="Pilih tahun panen"
			/>
		</div>
	);
};

export default Content;
