import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showModalCreate: false,
	showModalUpdate: false,
	showModalHistory: false,
	params: {},
};

export const contactModalSlice = createSlice({
	name: "contactModal",
	initialState,
	reducers: {
		handleModal: (state, action) => {
			state[action.payload.state] = !state[action.payload.state];
		},
		handleSetParams: (state, action) => {
			state.params = action.payload;
		},
	},
});

export const { handleModal, handleSetParams } = contactModalSlice.actions;
export const selectContactModalStatus = (state) => state.contactModal;
export default contactModalSlice.reducer;
