import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	sortBy: {},
};

export const sortedArraySlice = createSlice({
	name: "sortedArray",
	initialState,
	reducers: {
		toggleSort: (state, action) => {
			state.sortBy = action.payload;
		},
	},
});

export const { toggleSort } = sortedArraySlice.actions;
export const getSortBy = (state) => state.sortedArray.sortBy;
export default sortedArraySlice.reducer;
