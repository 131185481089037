import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./partials/Filter/Filter";
import ListHistoryFertilization from "./partials/ListHistoryHarvest/ListHistoryFertilization";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import FERTILIZER from "../../../assets/icons/fertilizer.svg";
import { handleModalStarter } from "../../../features/modalSlice";
import moment from "moment";
import { selectUser } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter/ModalFilter";
import { getFertilizations } from "../../../features/fertilizationSlice";

const ListView = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isFilterBy, setIsFilterBy] = useState({
    user: undefined,
    estate: undefined,
    afdeling: undefined,
    block: undefined,
    fromDate: undefined,
    untilDate: undefined,
    sord: "desc",
  });
  const [page, setPage] = useState({
    total: 1,
    current: 0,
  });
  const [data, setData] = useState([]);
  const [request, setRequest] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { isLoading: true }
  );
  const paginationLenght = Math.ceil(page?.total / 20);
  const modalName = "Vehicle";
  const user = useSelector(selectUser);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  let params = {
    pageIndex: page.current,
    fltCompany: user?.companyId,
    fltUser: isFilterBy.user,
    fltEstate: isFilterBy.estate,
    fltAfdeling: isFilterBy.afdeling,
    fltBlock: isFilterBy.block,
    fltStartDate: isFilterBy.fromDate,
    fltEndDate: isFilterBy.untilDate,
    sord: isFilterBy.sord,
    sidx: "syncInfo.createdTime",
  };

  const onFilter = async ({ type, value }) => {
    if (type === "dateRange") {
      if (value !== null) {
        const startDateObject = new Date(value[0]);
        const endDateObject = new Date(value[1]);
        const startDate = moment(startDateObject).format("Y-MM-DD");
        const endDate = moment(endDateObject).format("Y-MM-DD");

        setIsFilterBy({
          ...isFilterBy,
          fromDate: startDate,
          untilDate: endDate,
          sord: "asc",
        });
        params = {
          ...params,
          fltStartDate: startDate,
          fltEndDate: endDate,
          sord: "asc",
        };
      } else {
        setPage({ ...page, current: 0 });
        setIsFilterBy({
          ...isFilterBy,
          fromDate: undefined,
          untilDate: undefined,
          sord: "desc",
        });
        params = {
          ...params,
          fltStartDate: undefined,
          fltEndDate: undefined,
          sord: "desc",
        };
      }
    }
    if (type === "username") {
      if (value?.value !== undefined) {
        setIsFilterBy({ ...isFilterBy, user: value.value });
        params = {
          ...params,
          fltUser: value.value,
        };
      } else {
        setIsFilterBy({ ...isFilterBy, user: undefined });
        params = {
          ...params,
          fltUser: undefined,
        };
      }
    }
    if (type === "estate") {
      if (value !== undefined) {
        setIsFilterBy({
          ...isFilterBy,
          estate: value,
        });
        params = {
          ...params,
          fltEstate: value,
        };
      } else {
        setIsFilterBy({
          ...isFilterBy,
          estate: undefined,
        });
        params = {
          ...params,
          fltEstate: undefined,
        };
      }
    }
    if (type === "afdeling") {
      if (value !== undefined) {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: value,
        });
        params = {
          ...params,
          fltAfdeling: value,
        };
      } else {
        setIsFilterBy({
          ...isFilterBy,
          afdeling: undefined,
        });
        params = {
          ...params,
          fltAfdeling: undefined,
        };
      }
    }
    if (type === "block") {
      if (value !== undefined) {
        setIsFilterBy({
          ...isFilterBy,
          block: value,
        });
        params = {
          ...params,
          fltBlock: value,
        };
      } else {
        setIsFilterBy({
          ...isFilterBy,
          block: undefined,
        });
        params = {
          ...params,
          fltBlock: undefined,
        };
      }
    }

    const response = await dispatch(
      getFertilizations({ ...params, page: 0 })
    ).unwrap();
    const success = response.success;
    const message = response.message;
    if (!success) {
      alert.error(message);
    }
    setData(response?.records);
    const totalRecords = response?.totalRecords;
    setPage({
      current: 0,
      total: totalRecords,
    });
  };

  const fetchData = async (pageIndex, beforeFetch) => {
    try {
      if (beforeFetch) {
        beforeFetch();
      }

      setRequest({ isLoading: true });
      const response = await dispatch(
        getFertilizations({
          ...params,
          pageIndex,
        })
      ).unwrap();
      const success = response?.success;
      const message = response?.message;
      if (!success) {
        setRequest({ isLoading: false });
        return alert.error(message);
      }
      setData(response?.records);
      const totalRecords = response?.totalRecords;
      setPage({
        current: pageIndex,
        total: totalRecords,
      });
      setTimeout(() => {
        setRequest({ isLoading: false });
      }, 800);
    } catch (error) {
      setRequest({ isLoading: false });
      return alert.error(error?.data);
    }
  };

  const handlePageClick = async (page) => {
    await fetchData(page.selected);
  };

  const handleRefreshData = async () => {
    await fetchData(0);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) handleRefreshData();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            params: {},
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30 pt-54 md:pt-0">
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#D7A137" },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center space-x-8">
              <img
                src={FERTILIZER}
                alt=""
                className="hidden w-24 grayscale md:inline"
              />
              <p className="line-clamp-1">Riwayat Pupuk</p>
            </div>
          }
        />
      </div>
      <div>
        <Filter
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <ListHistoryFertilization
        isLoading={request.isLoading}
        data={data}
        handleRefreshData={handleRefreshData}
        page={page}
      />
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page?.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default ListView;
