import { Number } from "../../../../../helpers/Common";
import React from "react";

const Records = ({ current, length, totalRecords }) => {
	return (
		<p className="font-regular text-14 text-gray-500">
			Showing{" "}
			<span className="font-medium">{Number((current || 0) + 1)}</span> to{" "}
			<span className="font-medium">{Number(length)}</span> of{" "}
			<span className="font-medium">{Number(totalRecords)}</span> results
		</p>
	);
};

export default Records;
