import accountingReportReducers from "../features/accountingReportSlice";
import afdelingReducers from "../features/afdelingSlice";
import areaReducers from "../features/areaSlice";
import authReducers from "../features/authSlice";
import averageFruitReducers from "../features/averageFruitSlice";
import blockReducers from "../features/blockSlice";
import collectionPointReducers from "../features/collectionPointSlice/collectionPointSlice";
import companyReducers from "../features/companySlice";
import { configureStore } from "@reduxjs/toolkit";
import contactModalReducers from "../features/supplier/contactModalSlice";
import deliveryReducers from "../features/delivery/deliverySlice";
import estateReducers from "../features/estateSlice";
import fileAttachmentReducers from "../features/fileAttachment/fileAttachmentSlice";
import harvestModalReducers from "../features/harvest/harvestModalSlice";
import harvestingReducers from "../features/harvestingSlice";
import loadReducers from "../features/loadSlice";
import materialModalReducers from "../features/manageMaterial/materialModalSlice";
import modalReducers from "../features/modalSlice";
import navbarReducers from "../features/navbarSlice";
import sortedArrayReducers from "../features/sortedArraySlice";
import userReducers from "../features/userSlice";
import vehicleReducers from "../features/vehicleSlice";
import workerReducers from "../features/workerSlice";
import fertilizationReducers from "../features/fertilizationSlice";
import spotCheckReducers from "../features/spotCheckSlice";

export const store = configureStore({
  reducer: {
    fileAttachment: fileAttachmentReducers,
    delivery: deliveryReducers,
    company: companyReducers,
    estate: estateReducers,
    afdeling: afdelingReducers,
    block: blockReducers,
    worker: workerReducers,
    worker: workerReducers,
    user: userReducers,
    harvesting: harvestingReducers,
    authentication: authReducers,
    area: areaReducers,
    modal: modalReducers,
    materialModal: materialModalReducers,
    harvestModal: harvestModalReducers,
    contactModal: contactModalReducers,
    accountingReport: accountingReportReducers,
    navbar: navbarReducers,
    sortedArray: sortedArrayReducers,
    collectionPoint: collectionPointReducers,
    load: loadReducers,
    vehicle: vehicleReducers,
    averageFruit: averageFruitReducers,
    fertilization: fertilizationReducers,
    spotCheck: spotCheckReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,

      // serializableCheck: {
      // 	// Ignore these action types
      // 	ignoredActions: ["your/action/type"],
      // 	// Ignore these field paths in all actions
      // 	ignoredActionPaths: ["meta.arg", "payload.timestamp"],
      // 	// Ignore these paths in the state
      // 	ignoredPaths: ["items.dates"],
      // },
    }),
});
