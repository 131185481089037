import React from "react";
import moment from "moment";
import { useData } from "../../hooks/DataContext";
import MaterialSummaries from "./partials/MaterialSummaries";
import { formatNumericValue, Number } from "../../../../../helpers/Common";
import DistincBlocks from "./partials/DistincBlocks";
import { useFertilizationRecapsStore } from "../../../../../api/fertilization-recaps/useFertilizationRecapsStore";
import ChemistComponent from "../../../Chemist/components";

const Cards = () => {
  const { isFilterBy } = useData();
  const [distinctBlocks, materialSummary, overallSummary] =
    useFertilizationRecapsStore((state) => [
      state.distinctBlocks,
      state.materialSummary,
      state.overallSummary,
    ]);

  return (
    <div className="mx-auto grid w-full grid-cols-1 gap-8 px-8 md:grid-cols-4 md:grid-rows-8 md:gap-13 md:px-[2%]">
      <div className="relative md:row-span-3">
        <DistincBlocks
          data={distinctBlocks}
          title="Blok Chemist"
          subTitle={`${moment(isFilterBy?.fltFertilizationDate).format(
            "ll"
          )} - Hari Ini`}
        />
      </div>
      <div className="h-full max-h-[200px] md:col-span-3 md:row-span-3 md:max-h-full">
        <MaterialSummaries data={materialSummary} />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Jumlah Pupuk"
          subTitle={moment(isFilterBy?.fltFertilizationDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.fertilizerWeightKg, {
                decimals: 2,
              }),
              unit: "kg",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdFertilizerWeightKg, {
                decimals: 2,
              }),
              unit: "kg",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Pokok Dipupuk"
          subTitle={moment(isFilterBy?.fltFertilizationDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.treeCount, {
                decimals: 0,
              }),
              unit: "pokok",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdTreeCount, {
                decimals: 0,
              }),
              unit: "pokok",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Luas Dipupuk"
          subTitle={moment(isFilterBy?.fltFertilizationDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.realizationAreaHa, {
                decimals: 2,
              }),
              unit: "ha",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdRealizationAreaHa, {
                decimals: 2,
              }),
              unit: "ha",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Tenaga Kerja"
          subTitle={moment(isFilterBy?.fltFertilizationDate).format("ll")}
          data={[
            {
              value: Number(overallSummary?.workerPersonCount, {
                decimals: 2,
              }),
              unit: "TK",
              dateType: "HI",
            },
            {
              value: Number(overallSummary?.mtdWorkerPersonCount, {
                decimals: 2,
              }),
              unit: "TK",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>

      <div className="md:row-span-2">
        <ChemistComponent.DataSummaryCard
          title="Output"
          subTitle={moment(isFilterBy?.fltFertilizationDate).format("ll")}
          data={[
            {
              value: formatNumericValue(overallSummary?.outputKgPerPerson, {
                decimals: 2,
              }),
              unit: "Ltr/TK",
              dateType: "HI",
            },
            {
              value: formatNumericValue(overallSummary?.mtdOutputKgPerPerson, {
                decimals: 2,
              }),
              unit: "Ltr/TK",
              dateType: "s/d HI",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Cards;
