import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import React from "react";
import { omit } from "lodash";

const InputNumericFormat = ({
  control,
  name,
  label,
  required,
  optional,
  secondaryLabelClassName,
  secondaryLabel,
  errors,
  inputClassName,
  afterOnChange,
  ...props
}) => {
  return (
    <div>
      {label && (
        <p className="mb-8 flex flex-row items-center text-14 font-medium text-gray-500">
          {label}
          {/* required sign */}
          {required && <span className="ml-8 text-red-500"> *</span>}
          {/* optional sign */}
          {optional && (
            <span className="ml-8 font-regular text-gray-300">(optional)</span>
          )}
          {/* secondary label */}
          <span
            className={`${secondaryLabelClassName || "ml-8 text-blue-500"}`}
          >
            {secondaryLabel}
          </span>
        </p>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, onChange }) => (
          <NumericFormat
            {...field}
            allowNegative={false}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            // onChange={(event) => {
            //   console.log("event", event);
            //   const value = event.target.value;
            //   field.onChange(value);
            //   afterOnChange && afterOnChange(value, event, onChange);
            // }}
            onValueChange={(values) => {
              field.onChange(values.floatValue);
              afterOnChange && afterOnChange(values.floatValue);
            }}
            className={`input-large-border relative w-full rounded-8 font-regular text-14 text-gray-500 placeholder-gray-200 ${inputClassName} ${
              errors && "border-red-400"
            }`}
            style={{
              WebkitBoxShadow: "0 0 0px 0px #FFFFFF",
            }}
            {...omit(props, ["className"])}
          />
        )}
      />
      {errors && (
        <p className="error-message mt-4">
          {errors?.message || errors?.label?.message}
        </p>
      )}
    </div>
  );
};

export default InputNumericFormat;
