import { Controller, useForm } from "react-hook-form";
import { usePriceListStore } from "../../../../../../../api/ffbTrading/ffbTradingPriceList/usePriceListStore";
import moment from "moment";
import { toast } from "react-hot-toast";
import { calculateToastDuration } from "../../../../../../../helpers/Common";
import { ConfigProvider, DatePicker, notification } from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { TbCalendar } from "react-icons/tb";

const DueDateRow = ({ cell, row, refreshHandler }) => {
  const { control, setValue } = useForm();

  const [updatePriceList] = usePriceListStore((state) => [
    state.updatePriceList,
  ]);

  const updateFunc = async ({ value, refreshFunc }) => {
    const dateParsed = new Date(value);
    const startDate = value ? moment(dateParsed).format("Y-MM-DD") : null;

    await updatePriceList({
      id: row?.original?.id,
      body: {
        ...row?.original,
        startDate: startDate,
      },
      silent: true,
      onSuccess: () => {
        const toastMessage = `Berhasil Merubah Tanggal Mulai Berlaku ${row?.original?.buyerPartyName}`;
        toast.success(toastMessage, {
          duration: calculateToastDuration(toastMessage),
          position: "top-right",
          className: "font-regular text-16",
        });
        refreshFunc();
      },
      onError: () => {
        notification.error({
          message: "Gagal Merubah Tanggal Mulai Berlaku",
          placement: "bottomRight",
        });
      },
    });
  };

  useEffect(() => {
    setValue(
      `startDate-${row.original?.id}`,
      row.original?.startDate
        ? dayjs(moment(row.original?.startDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : null
    );
  }, [row.original]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return moment().isAfter(moment(row?.original?.startDate)) ? (
    moment(cell.getValue()).format("ll")
  ) : (
    <Controller
      name={`startDate-${row.original?.id}`}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#d7a137",
                colorTextQuaternary: "#e1e4e8",
                fontFamily: "Inter-Regular",
                fontSize: 16,
                colorTextPlaceholder: "#6B6F77",
              },
            }}
          >
            <DatePicker
              format="DD MMM YYYY"
              value={value}
              disabledDate={disabledDate}
              onChange={(date) => {
                onChange(date);
                updateFunc({
                  value: date,
                  refreshFunc: refreshHandler,
                });
              }}
              placeholder="Tanggal mulai berlaku"
              suffixIcon={<TbCalendar className="text-16" />}
              style={{
                width: "100%",
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                fontFamily: "Inter-Regular",
                borderWidth: 1,
                borderColor: "#e1e4e8",
              }}
            />
          </ConfigProvider>
        );
      }}
    />
  );
};

export default DueDateRow;
