import React from "react";
import { useSearchParams } from "react-router-dom";

const RedirectPage = () => {
	const [searchParams] = useSearchParams();

	React.useEffect(() => {
		const params = searchParams.get("url");
		if (params) {
			window.location.replace(params);
		}
	}, [searchParams]);

	return (
		<div>
			<h3>Redirecting...</h3>
		</div>
	);
};

export default RedirectPage;
