import * as yup from "yup";

export const formSchema = yup.object().shape({
  email: yup
    .string()
    .email("email yang dimasukkan harus email yang valid")
    .required("Email diperlukan"),
});

export const formSchemaVerif = yup.object().shape({
  code: yup.string().required("Code diperlukan"),
  password: yup
    .string()
    .required("Kata sandi baru tidak boleh kosong")
    .min(4, "Panjang kata sandi baru harus minimal 4 karakter")
    .max(12, "Kata sandi baru tidak boleh lebih dari 12 karakter"),
  confirmPassword: yup
    .string()
    .required("Konfirmasi kata sandi baru tidak boleh kosong")
    .min(4, "Panjang kata sandi baru harus minimal 4 karakter")
    .max(12, "Kata sandi baru tidak boleh lebih dari 12 karakter")
    .oneOf([yup.ref("password")], "Sandi baru tidak cocok"),
});
