export const damarlima100 = {
  type: "FeatureCollection",
  name: "Damarlima100Kepemilikan_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 6.0,
        Nm_Pemilik: "Faisal. W",
        Luas_SHM: 1.37,
        Nm_Inkesa: "Faisal. W",
        NO_SKT: "590/150/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882199433831929, -3.883861646355633],
              [114.882199430529752, -3.88386195427546],
              [114.882196818953247, -3.884909025484959],
              [114.881296707880978, -3.884906759885101],
              [114.881299911779735, -3.883622712240125],
              [114.881589153110525, -3.883553242571701],
              [114.882199433831929, -3.883861646355633],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 18.0,
        Nm_Pemilik: "Sugito",
        Luas_SHM: 1.17,
        Nm_Inkesa: "Sugito",
        NO_SKT: "590/131/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.883226250177046, -3.885811058783059],
              [114.882194563481775, -3.885813093073561],
              [114.882196818953247, -3.884909025484959],
              [114.883272328754359, -3.884911731305516],
              [114.883226250177046, -3.885811058783059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 42.0,
        Nm_Pemilik: "Tasirun",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Tasirun",
        NO_SKT: "590/156/TE-DLS/V-08",
        No_SHM: "327",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882183278192215, -3.890333430706109],
              [114.881283161375322, -3.890331161933499],
              [114.881287678993388, -3.888523027999977],
              [114.882187793894516, -3.888525295714997],
              [114.882183278192215, -3.890333430706109],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 31.0,
        Nm_Pemilik: "Satriani",
        Luas_SHM: 2.1,
        Nm_Inkesa: "Satriani",
        NO_SKT: "590/141/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882187793894516, -3.888525295714997],
              [114.882192307481517, -3.886717160641521],
              [114.883179696534768, -3.886719645969557],
              [114.883087061830821, -3.888527560334362],
              [114.882187793894516, -3.888525295714997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 47.0,
        Nm_Pemilik: "Ambliansyah",
        Luas_SHM: 1.87,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/63/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877678191275848, -3.892122510209119],
              [114.876778645092472, -3.891894396760445],
              [114.876778641492606, -3.891894213498958],
              [114.876782585231197, -3.890319803635734],
              [114.877682699400538, -3.890322077219909],
              [114.877678191275848, -3.892122510209119],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 13.0,
        Nm_Pemilik: "Surian",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Sri Rubianik",
        NO_SKT: "590/135/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878591858711673, -3.886708088243255],
              [114.87769174784296, -3.886705817740133],
              [114.877694008628694, -3.885801752818383],
              [114.877696268884506, -3.884897687875968],
              [114.878596377839287, -3.884899957319701],
              [114.878591858711673, -3.886708088243255],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 14.0,
        Nm_Pemilik: "Hamsan",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Garin Indrarinaldy",
        NO_SKT: "590/137/SK/DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879491970110152, -3.886710357784948],
              [114.878591858711673, -3.886708088243255],
              [114.878596377839287, -3.884899957319701],
              [114.879496487323763, -3.884902225802469],
              [114.879491970110152, -3.886710357784948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 15.0,
        Nm_Pemilik: "Yamani",
        Luas_SHM: 2.0,
        Nm_Inkesa: "M. Yamani",
        NO_SKT: "590/138/TE-DLS/V-08",
        No_SHM: "329",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880392082038057, -3.886712626365225],
              [114.879491970110152, -3.886710357784948],
              [114.879496487323763, -3.884902225802469],
              [114.88039659733775, -3.88490449332427],
              [114.880392082038057, -3.886712626365225],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 16.0,
        Nm_Pemilik: "Yamani",
        Luas_SHM: 2.0,
        Nm_Inkesa: "M. Yamani",
        NO_SKT: "590/139/TE-DLS/V-08",
        No_SHM: "326",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881292194495273, -3.886714893984083],
              [114.880392082038057, -3.886712626365225],
              [114.88039659733775, -3.88490449332427],
              [114.881296707880978, -3.884906759885101],
              [114.881292194495273, -3.886714893984083],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 17.0,
        Nm_Pemilik: "Sitriani",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Sitriani",
        NO_SKT: "590/140/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882192307481517, -3.886717160641521],
              [114.881292194495273, -3.886714893984083],
              [114.881296707880978, -3.884906759885101],
              [114.882196818953247, -3.884909025484959],
              [114.882192307481517, -3.886717160641521],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 27.0,
        Nm_Pemilik: "Nuriyah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Nuriyah",
        NO_SKT: "590/142/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879487450778555, -3.888518489684329],
              [114.878587337465234, -3.888516219083706],
              [114.878589602462242, -3.887610510437958],
              [114.878591858711673, -3.886708088243255],
              [114.879491970110152, -3.886710357784948],
              [114.879487450778555, -3.888518489684329],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 28.0,
        Nm_Pemilik: "Nuriyah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Nuriyah",
        NO_SKT: "590/143/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.88038756462133, -3.888520759323087],
              [114.879487450778555, -3.888518489684329],
              [114.879491970110152, -3.886710357784948],
              [114.880392082038057, -3.886712626365225],
              [114.88038756462133, -3.888520759323087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 29.0,
        Nm_Pemilik: "Hj. Jamliah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Hj. Jamliah",
        NO_SKT: "590/144/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881287678993388, -3.888523027999977],
              [114.88038756462133, -3.888520759323087],
              [114.880392082038057, -3.886712626365225],
              [114.881292194495273, -3.886714893984083],
              [114.881287678993388, -3.888523027999977],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 30.0,
        Nm_Pemilik: "Hj. Jamliah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Hj. Jamliah",
        NO_SKT: "590/145/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882187793894516, -3.888525295714997],
              [114.881287678993388, -3.888523027999977],
              [114.881292194495273, -3.886714893984083],
              [114.882192307481517, -3.886717160641521],
              [114.882187793894516, -3.888525295714997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 36.0,
        Nm_Pemilik: "Hj. Fatimah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "H. Suyadi",
        NO_SKT: "590/65/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876782585231197, -3.890319803635734],
              [114.875882471591993, -3.890317529089255],
              [114.875884736413482, -3.889413465310312],
              [114.875887000704552, -3.888509401510681],
              [114.875998647833654, -3.888509683559342],
              [114.876787112428019, -3.88851167499688],
              [114.876785767729601, -3.889048825953803],
              [114.876782585231197, -3.890319803635734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 37.0,
        Nm_Pemilik: "Ambliansyah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/62/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877682699400538, -3.890322077219909],
              [114.876782585231197, -3.890319803635734],
              [114.876785767729601, -3.889048825953803],
              [114.876787112428019, -3.88851167499688],
              [114.877687224681651, -3.888513947521222],
              [114.877686174987389, -3.888933440766484],
              [114.877682699400538, -3.890322077219909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 38.0,
        Nm_Pemilik: "H. Sanusi",
        Luas_SHM: 2.0,
        Nm_Inkesa: "H. Sanusi",
        NO_SKT: "590/160/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878582814099886, -3.890324349841773],
              [114.877682699400538, -3.890322077219909],
              [114.877686174987389, -3.888933440766484],
              [114.877687224681651, -3.888513947521222],
              [114.87800469439938, -3.888514748811106],
              [114.878587337465234, -3.888516219083706],
              [114.878582814099886, -3.890324349841773],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 39.0,
        Nm_Pemilik: "H. Sanusi",
        Luas_SHM: 2.0,
        Nm_Inkesa: "H. Sanusi",
        NO_SKT: "590/159/TE-DLS/V-08",
        No_SHM: "332",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879482929328987, -3.89032662150133],
              [114.878582814099886, -3.890324349841773],
              [114.878587337465234, -3.888516219083706],
              [114.879487450778555, -3.888518489684329],
              [114.879482929328987, -3.89032662150133],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 40.0,
        Nm_Pemilik: "Misirah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Misirah",
        NO_SKT: "590/158/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880383045087456, -3.890328892198573],
              [114.879482929328987, -3.89032662150133],
              [114.879487450778555, -3.888518489684329],
              [114.88038756462133, -3.888520759323087],
              [114.880383045087456, -3.890328892198573],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 41.0,
        Nm_Pemilik: "Misirah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Misirah",
        NO_SKT: "590/157/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881283161375322, -3.890331161933499],
              [114.880383045087513, -3.890328892198573],
              [114.88038756462133, -3.888520759323087],
              [114.881287678993388, -3.888523027999977],
              [114.881283161375322, -3.890331161933499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 43.0,
        Nm_Pemilik: "Tasirun **",
        Luas_SHM: 1.82,
        Nm_Inkesa: "Tasirun",
        NO_SKT: "590/155/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882183278192215, -3.890333430706109],
              [114.882187793894516, -3.888525295714997],
              [114.883087061830821, -3.888527560334362],
              [114.88303116456791, -3.889618468408465],
              [114.882801578539912, -3.890334988593164],
              [114.882183278192215, -3.890333430706109],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 54.0,
        Nm_Pemilik: "Rusli",
        Luas_SHM: 1.69,
        Nm_Inkesa: "Rusli",
        NO_SKT: "590/148/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880376077252478, -3.893114782128615],
              [114.878892077722981, -3.892627631746264],
              [114.877804113235328, -3.89215476680197],
              [114.87770884796555, -3.892130284338279],
              [114.880378523436576, -3.892137024990855],
              [114.880376077828387, -3.893114630269745],
              [114.880376077252478, -3.893114782128615],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 53.0,
        Nm_Pemilik: "H. Suyadi",
        Luas_SHM: 0.74,
        Nm_Inkesa: "H. Suyadi",
        NO_SKT: "590/152/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882178760374615, -3.892141565614074],
              [114.882183278192215, -3.890333430706109],
              [114.882801578539912, -3.890334988593164],
              [114.882222885287788, -3.892141676859066],
              [114.882178760374615, -3.892141565614074],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 52.0,
        Nm_Pemilik: "Suriansyah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Tjindar Bumi. K. N",
        NO_SKT: "590/147/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882178760374615, -3.892141565614074],
              [114.881278641641075, -3.892139295783822],
              [114.881283161375322, -3.890331161933499],
              [114.882183278192215, -3.890333430706109],
              [114.882178760374615, -3.892141565614074],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 51.0,
        Nm_Pemilik: "Jarmiyanti",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Jarmiyanti",
        NO_SKT: "590/153/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.880378523436576, -3.892137024990855],
              [114.880383045087513, -3.890328892198573],
              [114.881283161375322, -3.890331161933499],
              [114.881278641641075, -3.892139295783822],
              [114.880378523436576, -3.892137024990855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 49.0,
        Nm_Pemilik: "Sarman",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Sarman",
        NO_SKT: "590/146/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879478430858015, -3.892134753298492],
              [114.878578288615671, -3.892132480516629],
              [114.878582814099886, -3.890324349841773],
              [114.879482929328987, -3.89032662150133],
              [114.879478425036353, -3.892127050594063],
              [114.879478430858015, -3.892134753298492],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 48.0,
        Nm_Pemilik: "Sarman",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Sarman",
        NO_SKT: "590/154/TE-DLS/V-08",
        No_SHM: "321",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878578288615671, -3.892132480516629],
              [114.87770884796555, -3.892130284338279],
              [114.877678191275848, -3.892122510209119],
              [114.877682699400538, -3.890322077219909],
              [114.878582814099886, -3.890324349841773],
              [114.878578288615671, -3.892132480516629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 50.0,
        Nm_Pemilik: "Jarmiyanti",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Jarmiyanti",
        NO_SKT: "590/151/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879478430858015, -3.892134753298492],
              [114.879478425036353, -3.892127050594063],
              [114.879482929328987, -3.89032662150133],
              [114.880383045087456, -3.890328892198573],
              [114.880378523436448, -3.892137024990872],
              [114.879478430858015, -3.892134753298492],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 44.0,
        Nm_Pemilik: "Rusmida",
        Luas_SHM: 1.34,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/67/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874976911322136, -3.89248212494478],
              [114.874825274819699, -3.891930259302736],
              [114.874511028378819, -3.891613040233014],
              [114.874484297037085, -3.891504484364882],
              [114.874550535429421, -3.891201888693142],
              [114.874080448036452, -3.891029886194634],
              [114.874082245904958, -3.890312977109397],
              [114.87498235848318, -3.890315253580476],
              [114.874976911322136, -3.89248212494478],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 45.0,
        Nm_Pemilik: "Zulkifli",
        Luas_SHM: 2.45,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/66/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875877716548572, -3.892214586165209],
              [114.875633587516674, -3.892646518532436],
              [114.875399670799851, -3.892600723398094],
              [114.875246809865544, -3.892537051880827],
              [114.874976911322136, -3.89248212494478],
              [114.87498235848318, -3.890315253580476],
              [114.875882471591993, -3.890317529089255],
              [114.875877718139265, -3.892214308121947],
              [114.875877716548572, -3.892214586165209],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 46.0,
        Nm_Pemilik: "Hj. Fatimah",
        Luas_SHM: 1.71,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/64/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876778645092472, -3.891894396760445],
              [114.876148942109012, -3.89173471154523],
              [114.875877716548572, -3.892214586165209],
              [114.875877718139265, -3.892214308121947],
              [114.875882471591993, -3.890317529089255],
              [114.876782585231197, -3.890319803635734],
              [114.876778641492606, -3.891894213498958],
              [114.876778645092472, -3.891894396760445],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 1.0,
        Nm_Pemilik: "Gazwini",
        Luas_SHM: 1.86,
        Nm_Inkesa: "Gazwini",
        NO_SKT: "590/175/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877246267771824, -3.884875289192228],
              [114.877246214605847, -3.884896552793741],
              [114.874995945200681, -3.884890873779043],
              [114.875257301002222, -3.884761781656596],
              [114.87548274251462, -3.884590879600865],
              [114.876041389479298, -3.884365970173057],
              [114.87588030492654, -3.883986156426585],
              [114.876862385903806, -3.883608622569936],
              [114.877246267771824, -3.884875289192228],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 3.0,
        Nm_Pemilik: "Mahrini",
        Luas_SHM: 0.82,
        Nm_Inkesa: "Mahrini",
        NO_SKT: "590/129/TE-DLS/V-08",
        No_SHM: "336",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.879496487323763, -3.884902225802469],
              [114.878596377839287, -3.884899957319701],
              [114.87859794766706, -3.884271661020168],
              [114.879498500378432, -3.884055370465656],
              [114.879498599806837, -3.884056358551623],
              [114.879496487323763, -3.884902225802469],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 4.0,
        Nm_Pemilik: "Teguh. S",
        Luas_SHM: 1.06,
        Nm_Inkesa: "Teguh. S",
        NO_SKT: "590/131/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.88039659733775, -3.88490449332427],
              [114.879496487323763, -3.884902225802469],
              [114.879498599806837, -3.884056358551623],
              [114.879498500378432, -3.884055370465656],
              [114.880399125199887, -3.883839061382902],
              [114.880399253248669, -3.883840548575305],
              [114.88039659733775, -3.88490449332427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 5.0,
        Nm_Pemilik: "Mahyuni",
        Luas_SHM: 1.3,
        Nm_Inkesa: "Mahyuni",
        NO_SKT: "590/432/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.881296707880978, -3.884906759885101],
              [114.88039659733775, -3.88490449332427],
              [114.880399253248669, -3.883840548575305],
              [114.880399125199887, -3.883839061382902],
              [114.881299911779735, -3.883622712240125],
              [114.881296707880978, -3.884906759885101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 7.0,
        Nm_Pemilik: "Fahrulrazi",
        Luas_SHM: 1.03,
        Nm_Inkesa: "Fahrulrazi",
        NO_SKT: "590/130/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882196818953247, -3.884909025484959],
              [114.882199430529752, -3.88386195427546],
              [114.882199433831929, -3.883861646355633],
              [114.883269221947046, -3.88440225976574],
              [114.883296097500491, -3.884447833045177],
              [114.883272328754359, -3.884911731305516],
              [114.882196818953247, -3.884909025484959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 2.0,
        Nm_Pemilik: "Khardminie",
        Luas_SHM: 0.69,
        Nm_Inkesa: "Mini",
        NO_SKT: "590/134/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878596377839287, -3.884899957319701],
              [114.877696268884506, -3.884897687875968],
              [114.877246214605847, -3.884896552793741],
              [114.877246267771824, -3.884875289192228],
              [114.87769727867726, -3.88448797829241],
              [114.87859794766706, -3.884271661020168],
              [114.878596377839287, -3.884899957319701],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 19.0,
        Nm_Pemilik: "Sri Rubianik",
        Luas_SHM: 1.12,
        Nm_Inkesa: "Sri Rubianik",
        NO_SKT: "590/127/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.882194563481775, -3.885813093073561],
              [114.883226250177046, -3.885811058783059],
              [114.883179696534768, -3.886719645969557],
              [114.882192307481517, -3.886717160641521],
              [114.882194563481775, -3.885813093073561],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 55.0,
        Nm_Pemilik: "Rusli",
        Luas_SHM: 2.46,
        Nm_Inkesa: "Rusli",
        NO_SKT: "590/149/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.88183305639329, -3.893593059966857],
              [114.880376077252478, -3.893114782128615],
              [114.880378523436534, -3.892137024990855],
              [114.882222885287788, -3.892141676859066],
              [114.881824602919167, -3.893385110931924],
              [114.88183305639329, -3.893593059966857],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 32.0,
        Nm_Pemilik: "Rusmadi",
        Luas_SHM: 1.02,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/72/TE-DLS/III-08",
        No_SHM: "333",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874084512642455, -3.889408914391407],
              [114.873291019994284, -3.889406907238968],
              [114.873104873334782, -3.888815609819977],
              [114.873090445658519, -3.888502331876698],
              [114.874086778849048, -3.888504851652811],
              [114.874084512642455, -3.889408914391407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 33.0,
        Nm_Pemilik: "Zainal .A",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Zainal. A",
        NO_SKT: "590/70/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874084512642455, -3.889408914391407],
              [114.874086778849048, -3.888504851652811],
              [114.874986889511476, -3.888507127062645],
              [114.875887000704552, -3.888509401510681],
              [114.875884736413482, -3.889413465310312],
              [114.874084512642455, -3.889408914391407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 10.0,
        Nm_Pemilik: "Nurul. H",
        Luas_SHM: 0.86,
        Nm_Inkesa: "Nurul. H",
        NO_SKT: "590/71/TK-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874093574283762, -3.885792663312875],
              [114.87408904452478, -3.887600788893546],
              [114.873511885829643, -3.887599540445302],
              [114.873702413329553, -3.88743389684184],
              [114.87385649662869, -3.887009374251957],
              [114.873848469581276, -3.886620604562223],
              [114.87379501803278, -3.886398972716048],
              [114.873741566482423, -3.886177340880773],
              [114.873810195612236, -3.8857300004887],
              [114.87387882454388, -3.885282660046742],
              [114.874095041470227, -3.885206830607003],
              [114.874093574283762, -3.885792663312875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 22.0,
        Nm_Pemilik: "Asliah",
        Luas_SHM: 1.0,
        Nm_Inkesa: "Sugianta",
        NO_SKT: "590/136/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878591858711673, -3.886708088243255],
              [114.878589602462242, -3.887610510437958],
              [114.878156716670105, -3.887609576418656],
              [114.877689489815808, -3.88760856805312],
              [114.877691220966014, -3.886916479950339],
              [114.87769174784296, -3.886705817740133],
              [114.878591858711673, -3.886708088243255],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 26.0,
        Nm_Pemilik: "Agus I",
        Luas_SHM: 1.0,
        Nm_Inkesa: "Agus Irawan",
        NO_SKT: "590/133/TE-DLS/V-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.878587337465234, -3.888516219083706],
              [114.87800469439938, -3.888514748811106],
              [114.877687224681651, -3.888513947521222],
              [114.877689489815808, -3.88760856805312],
              [114.878156716670105, -3.887609576418656],
              [114.878589602462242, -3.887610510437958],
              [114.878587337465234, -3.888516219083706],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 24.0,
        Nm_Pemilik: "H. Masriyati",
        Luas_SHM: 2.0,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/74/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.876787112428019, -3.88851167499688],
              [114.875998647833654, -3.888509683559342],
              [114.875887000704552, -3.888509401510681],
              [114.874986889511476, -3.888507127062629],
              [114.874989147644285, -3.88760569294406],
              [114.876082747044038, -3.887606858366564],
              [114.876789375231183, -3.887607610646612],
              [114.876787112428019, -3.88851167499688],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 23.0,
        Nm_Pemilik: "Rusmadi",
        Luas_SHM: 1.94,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/73/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874989147644285, -3.88760569294406],
              [114.874986889511476, -3.888507127062629],
              [114.874086778849048, -3.888504851652811],
              [114.873090445658519, -3.888502331876698],
              [114.873105984183837, -3.88837261935754],
              [114.87326906924244, -3.887948119908598],
              [114.873377671604189, -3.887713336864871],
              [114.873511885829643, -3.887599540445302],
              [114.87408904452478, -3.887600788893546],
              [114.874989147644285, -3.88760569294406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 8.0,
        Nm_Pemilik: "Nur Zainudin",
        Luas_SHM: 1.82,
        Nm_Inkesa: "Nur Zainudin",
        NO_SKT: "590/61/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875893790395438, -3.88579720998799],
              [114.874093574283762, -3.885792663312875],
              [114.874095041470227, -3.885206830607003],
              [114.874995945200681, -3.884890873779043],
              [114.875896052564968, -3.884893146105654],
              [114.875893790395438, -3.88579720998799],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 9.0,
        Nm_Pemilik: "Nur Zainudin",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Nur Zainudin",
        NO_SKT: "590/60/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875893790395438, -3.88579720998799],
              [114.875896052564968, -3.884893146105654],
              [114.877246214605847, -3.884896552793741],
              [114.877696268884506, -3.884897687875968],
              [114.877694008628694, -3.885801752818383],
              [114.875893790395438, -3.88579720998799],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 21.0,
        Nm_Pemilik: "Hj. Arbainah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Hj. Arbainah",
        NO_SKT: "590/56/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87769174784296, -3.886705817740133],
              [114.877691220966014, -3.886916479950339],
              [114.877689489815808, -3.88760856805312],
              [114.876083185035554, -3.88760509935823],
              [114.875889266110917, -3.887604680397335],
              [114.875891527695543, -3.886701273849607],
              [114.876791637504184, -3.886703546275548],
              [114.87769174784296, -3.886705817740133],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 20.0,
        Nm_Pemilik: "Taufik",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Taufik",
        NO_SKT: "590/58/TE-DLS/III-08",
        No_SHM: "334",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875889266110917, -3.887604680397335],
              [114.874989147644285, -3.88760569294406],
              [114.87408904452478, -3.887600788893546],
              [114.874091309669694, -3.886696726113547],
              [114.875891527695543, -3.886701273849607],
              [114.875889266110917, -3.887604680397335],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 11.0,
        Nm_Pemilik: "Taufik",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Taufik",
        NO_SKT: "590/59/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875891527695543, -3.886701273849607],
              [114.874091309669694, -3.886696726113547],
              [114.874093574283762, -3.885792663312875],
              [114.875893790395438, -3.88579720998799],
              [114.875891527695543, -3.886701273849607],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 12.0,
        Nm_Pemilik: "Hj. Arbainah",
        Luas_SHM: 2.0,
        Nm_Inkesa: "Hj. Arbainah",
        NO_SKT: "590/57/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.87769174784296, -3.886705817740133],
              [114.876791637504184, -3.886703546275548],
              [114.875891527695543, -3.886701273849607],
              [114.875893790395438, -3.88579720998799],
              [114.877694008628694, -3.885801752818383],
              [114.87769174784296, -3.886705817740133],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 34.0,
        Nm_Pemilik: "M. Albach R",
        Luas_SHM: 1.05,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/69TE-DLS/III-08",
        No_SHM: "340",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.874082245904987, -3.890312977109397],
              [114.874080448036452, -3.891029886194634],
              [114.873765695374573, -3.890914719701942],
              [114.873291019994284, -3.889406907238968],
              [114.874084512642455, -3.889408914391407],
              [114.874082245904987, -3.890312977109397],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 35.0,
        Nm_Pemilik: "Suhendra I",
        Luas_SHM: 2.0,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/68/TE-DLS/III-08",
        No_SHM: "341",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.875882471591993, -3.890317529089255],
              [114.87498235848318, -3.890315253580476],
              [114.874082245904958, -3.890312977109397],
              [114.874084512642455, -3.889408914391407],
              [114.875884736413482, -3.889413465310312],
              [114.875882471591993, -3.890317529089255],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 25.0,
        Nm_Pemilik: "H. Masriyati",
        Luas_SHM: 1.0,
        Nm_Inkesa: "KJP-CPS",
        NO_SKT: "590/75/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.877687224681651, -3.888513947521222],
              [114.876787112428019, -3.88851167499688],
              [114.876789375231183, -3.887607610646594],
              [114.877689489815808, -3.88760856805312],
              [114.877687224681651, -3.888513947521222],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 100",
        No_Kavling: 0.0,
        Nm_Pemilik: null,
        Luas_SHM: 0.0,
        Nm_Inkesa: null,
        NO_SKT: "590/75/TE-DLS/III-08",
        No_SHM: null,
      },
      geometry: { type: "MultiPolygon", coordinates: [] },
    },
  ],
};
