import {
  COMPANIES_URL,
  MEMBER_URL,
  USER_IMPORT_EXCEL_URL,
  USER_URL,
} from "../../configs/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  data: {},
  users: {},
  isRequest: false,
  isLoading: false,
  isError: false,
  errors: null,
};

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.get(
        `${COMPANIES_URL + data.companyId + MEMBER_URL}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
          params: {
            fltDeleted: 0,
            pageIndex: data?.page || 0,
            pageSize: 20,
            fltFullname: data.fullname || undefined,
            fltCompany: data.companyId,
            sidx: "syncInfo.createdTime",
            sord: "desc",
            ...data,
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "user/getUser",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.get(USER_URL + data.id, {
        headers: {
          Authorization: "Bearer " + data.token || token,
          "content-type": "application/json",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.post(
        `${COMPANIES_URL}${data.companyId}${MEMBER_URL}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importUser = createAsyncThunk(
  "user/importUser",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const formData = new FormData();
      formData.append("content", data.fileContent);
      formData.append("fileName", data.fileName);
      formData.append("userId", data.userId);
      formData.append("mimeType", null);
      formData.append("id", null);

      const response = await axios.post(USER_IMPORT_EXCEL_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token?.accessToken,
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.put(
        `${COMPANIES_URL}${data.companyId}${MEMBER_URL}/${data.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifEmailAddress = createAsyncThunk(
  "user/verifEmailAddress",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.post(
        USER_URL + data.id + "/email-verification",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  "user/requestResetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(USER_URL + "reset-password", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(USER_URL + "reset-password", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.put(USER_URL + "change-password", data, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.post(
        USER_URL + data.id + "/email-verification",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitCodeVerif = createAsyncThunk(
  "user/submitCodeVerif",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const params = new URLSearchParams();
      params.append("code", data.code);

      const response = await axios.put(
        USER_URL + data.id + "/email-verification",
        params,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken);

      const response = await axios.delete(
        `${COMPANIES_URL}${data.companyId}${MEMBER_URL}/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + token?.accessToken,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLoginHelperUrl = createAsyncThunk(
  "user/getLoginHelperUrl",
  async (data, { rejectWithValue }) => {
    try {
      const remainingToken = localStorage.getItem("token");
      const token = JSON.parse(remainingToken).accessToken;

      const response = await axios.get(
        `${COMPANIES_URL}${data.companyId}${MEMBER_URL}/${data.id}/login-helper-url`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload;
        state.users = data.records;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getUser.pending, (state, action) => {})
      .addCase(getUser.fulfilled, (state, action) => {
        const remainingData = action.payload.record;
        state.data = remainingData;
      })
      .addCase(getUser.rejected, (state, action) => {})
      .addCase(createUser.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(importUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(importUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(importUser.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(verifEmailAddress.pending, (state) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(verifEmailAddress.fulfilled, (state) => {
        state.isRequest = false;
      })
      .addCase(verifEmailAddress.rejected, (state) => {
        state.isRequest = false;
        state.isError = true;
      })
      .addCase(submitCodeVerif.pending, (state) => {
        state.isRequest = true;
        state.isError = false;
      })
      .addCase(submitCodeVerif.fulfilled, (state) => {
        state.isRequest = false;
      })
      .addCase(submitCodeVerif.rejected, (state) => {
        state.isRequest = false;
        state.isError = true;
      })
      .addCase(getLoginHelperUrl.fulfilled, (state) => {})
      .addCase(requestResetPassword.fulfilled, (state) => {})
      .addCase(resetPassword.fulfilled, (state) => {})
      .addCase(changePassword.fulfilled, (state) => {});
  },
});

export const {} = userSlice.actions;
export const selectUsers = (state) => state?.user?.users;
export const selectUser = (state) => state?.user?.data;
export const selectUserCompanyId = (state) => state?.user?.data?.companyId;
export const selectUserAfdelingId = (state) =>
  state?.user?.data?.defaultAfdelingId;
export const selectUserEstateId = (state) => state?.user?.data?.defaultEstateId;
export const selectUserPosition = (state) => state?.user?.data?.position;
export const selectStatuses = (state) => ({
  isLoading: state.user?.isLoading,
  isRequest: state.user?.isRequest,
  isError: state.user?.isError,
  errors: state.user?.errors,
});

export default userSlice.reducer;
