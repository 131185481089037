import "react-lazy-load-image-component/src/effects/blur.css";

import { ConfigProvider, DatePicker, Image } from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  Number,
  parseToFloat,
} from "../../../../../../../../../helpers/Common";
import React, { useEffect, useState } from "react";
import { TbCalendar, TbX } from "react-icons/tb";
import { positions, transitions, useAlert } from "react-alert";
import {
  updateHarvesting,
  updateProductionRecapsDaily,
} from "../../../../../../../../../features/harvestingSlice";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import { MdOutlineWarning } from "react-icons/md";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { downloadImage } from "../../../../../../../../../features/fileAttachment/fileAttachmentSlice";
import moment from "moment";
import { useData } from "../../../../../../hooks/DataContext";
import { useDispatch } from "react-redux";

dayjs.extend(customParseFormat);

const Item = ({ item, index, page }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { register, control, handleSubmit, setValue, watch } = useForm();
  const watchRemark = watch(`remark-${item?.id}`);
  const { handleRefreshData } = useData();

  const handleUpdateHarvesting = async ({ item, caDueDate, remark, func }) => {
    try {
      let version = item?.version;
      let request = {
        ...item,
        version: parseInt(version) + 1,
      };

      if (remark) {
        request = {
          ...request,
          remark: remark.trim() === "" ? undefined : remark || undefined,
        };
      }
      if (caDueDate) {
        request = {
          ...request,
          caDueDate:
            caDueDate.trim() === "" ? undefined : caDueDate || undefined,
        };
      }

      const response = await dispatch(
        updateProductionRecapsDaily({
          ...request,
          type: `/by-block/${request?.id}`,
        })
      ).unwrap();
      if (!response?.success) {
        return alert.error(response?.message);
      }
      func();
      handleRefreshData();
    } catch (error) {
      return alert.error(error?.data);
    }
  };

  const onChangeDate = (date) => {
    const dateParsed = new Date(date);
    const caDueDate = date ? moment(dateParsed).format("Y-MM-DD") : " ";
    const previewDate = date ? moment(dateParsed).format("LL") : " ";

    handleUpdateHarvesting({
      item,
      caDueDate,
      func: () =>
        alert.success(`Berhasil mengubah tanggal tindakan ${previewDate}`),
    });
  };

  const onSubmit = (e) => {
    let remark = e;
    remark = remark?.length < 1 ? " " : remark;
    handleUpdateHarvesting({
      item,
      remark,
      func: () => alert.success(`Berhasil mengubah komentar ${remark || ""}`),
    });
  };

  useEffect(() => {
    setValue(`remark-${item?.id}`, item?.remark);
    setValue(
      `caDueDate-${item?.id}`,
      item?.caDueDate
        ? dayjs(moment(item?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [item?.id]);

  return (
    <tr className="table-header-regular relative" key={index}>
      <td className="table-row-regular">{index + 1}</td>
      <td className="table-row-regular">{item?.afdelingName}</td>
      <td className="table-row-regular">{item?.blockName}</td>
      <td className="table-row-regular">{item?.plantedYear}</td>
      <td className="table-row-regular">
        {parseToFloat(item?.ytdMillNetWeightKg / 1000)}
      </td>
      <td className="table-row-regular">{parseToFloat(item?.areaHa)}</td>
      <td className="table-row-regular">
        {parseToFloat(item?.ytdYieldKgPerHa / 1000, 3)}
      </td>
      <td className="table-row-regular max-w-[160px]">
        <InputHook
          register={register}
          name={`remark-${item?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          onBlur={() => onSubmit(watchRemark)}
          rightIcon={
            <TbX
              onClick={() => {
                setValue(`remark-${item?.id}`, undefined);
                handleUpdateHarvesting({
                  item,
                  remark: " ",
                  func: () => alert.success(`Berhasil mengubah komentar`),
                });
              }}
              className="text-14 hover:cursor-pointer hover:text-red-600"
            />
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit((e) => onSubmit(e[`remark-${item?.id}`]))(event);
            }
          }}
        />
      </td>
      <td className="table-row-regular max-w-[160px]">
        <Controller
          name={`caDueDate-${item?.id}`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={(data) => {
                    onChange(data);
                    onChangeDate(data);
                  }}
                  placeholder="Tanggal tindakan"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: 160,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    fontFamily: "Inter-Regular",
                    maxWidth: 250,
                    // fontSize: 16,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </td>
    </tr>
  );
};

export default Item;
