import React, { useEffect } from "react";
import { Image, Tooltip } from "antd";
import { IMAGE_NOT_FOUND } from "../../../../api/fallbackImageNotFound";
import { useImageSource } from "../../../../hooks/useImageSource";
import { useImageViewer } from "../contexts/imageViewerContext";
import { capitalize } from "lodash";
import { MdInfo } from "react-icons/md";
import { cn } from "../../../../utils/utils";
import { Number } from "../../../../helpers/Common";
import moment from "moment/moment";
import { APP_NAME } from "../../../../configs/api";

const ImageContainer = ({
  image,
  display = "none",
  currentImageViewer,
  ...props
}) => {
  const { source, setDownloadedImage } = useImageSource();

  useEffect(() => {
    setDownloadedImage(image?.photoFileId, "main");
  }, [image?.photoFileId]);

  return (
    <div
      className={cn(
        "aspect-square rounded-8 p-8 shadow-xl",
        image.id === currentImageViewer?.id
          ? APP_NAME === "semai-kebun"
            ? "bg-primary-red-500"
            : "bg-yellow-600"
          : ""
      )}
    >
      <Image
        {...props}
        wrapperStyle={{ display, borderRadius: "8px" }}
        src={source.main}
        preview={{
          src: source.main || IMAGE_NOT_FOUND,
        }}
      />
    </div>
  );
};

const ImageRender = ({ originalNode, currentImageViewer }) => {
  return (
    <div className="-mt-96 flex w-screen flex-col items-center justify-center">
      <div className="absolute top-0 z-10 flex w-full flex-row items-center justify-center bg-white p-16">
        <div className="flex flex-col items-center">
          <h2 className="font-bold text-16 text-gray-500">
            {capitalize(currentImageViewer.fullname)}
          </h2>
          <p className="cursor-pointer font-medium text-14 text-gray-400">
            {/*/!*<Tooltip title="Nama Estate">*!/*/}
            {/*/!*  {currentImageViewer.estateName}*!/*/}
            {/*/!*</Tooltip>{" "}*!/*/}
            {/*∘{" "}*/}
            <Tooltip title="Nama Afdeling">
              {currentImageViewer.afdelingName}
            </Tooltip>{" "}
            ∘{" "}
            <Tooltip title="Nama Blok">{currentImageViewer.blockName}</Tooltip>{" "}
            ∘{" "}
            <Tooltip title="Nama TPH">
              TPH {currentImageViewer.collectionPointName}
            </Tooltip>
          </p>
        </div>
      </div>

      {originalNode}
    </div>
  );
};

const ModalImagePreview = ({ images }) => {
  const {
    isOpenImagePreviewer,
    setIsOpenImagePreviewer,
    currentImageViewer,
    setCurrentImageViewer,
  } = useImageViewer();

  return (
    <Image.PreviewGroup
      closable={false}
      preview={{
        closable: false,
        current: currentImageViewer?.index || 0,
        visible: isOpenImagePreviewer,
        onVisibleChange: () => setIsOpenImagePreviewer(false),
        onChange: (current) =>
          setCurrentImageViewer({ ...images[current], index: current }),
        imageRender: (originalNode) => (
          <ImageRender
            originalNode={originalNode}
            currentImageViewer={currentImageViewer}
          />
        ),
        countRender: () => (
          <div className="flex flex-col items-center gap-4 rounded-8 bg-white p-8 shadow-lg">
            <p className="font-medium text-16 text-gray-500">
              {moment(currentImageViewer?.date).format("LL")}{" "}
              {moment(currentImageViewer?.createdTime).format("H:mm")}
            </p>
            <div className="flex flex-row items-center gap-12 rounded-8">
              <p className="font-regular text-16 text-gray-500">
                TBS (Jjg):{" "}
                {currentImageViewer?.fruitCount ||
                currentImageViewer?.fruitCount === 0
                  ? Number(currentImageViewer?.fruitCount)
                  : null}{" "}
                | Mentah:{" "}
                {currentImageViewer?.unripeCount ||
                currentImageViewer?.unripeCount === 0
                  ? Number(currentImageViewer?.unripeCount)
                  : null}{" "}
                | Abnormal:{" "}
                {currentImageViewer?.abnormalCount ||
                currentImageViewer?.abnormalCount === 0
                  ? Number(currentImageViewer?.abnormalCount)
                  : null}
              </p>
              <Tooltip title="Lihat data lebih lengkap di tabel">
                <MdInfo
                  className={cn(
                    "cursor-pointer text-24 text-yellow-500",
                    APP_NAME === "semai-kebun" && "text-primary-red-500"
                  )}
                  onClick={() => setIsOpenImagePreviewer(false)}
                />
              </Tooltip>
            </div>
          </div>
        ),
        toolbarRender: () => {
          return (
            <div className="bottom-0 -mb-24 flex w-full flex-row items-center gap-12 overflow-x-scroll bg-white p-16">
              {images?.map((image, index) => (
                <ImageContainer
                  key={index}
                  image={image}
                  width={64}
                  display={"block"}
                  currentImageViewer={currentImageViewer}
                  onClick={() => {
                    setCurrentImageViewer({
                      ...image,
                      index,
                    });
                  }}
                />
              ))}
            </div>
          );
        },
      }}
    ></Image.PreviewGroup>
  );
};

export default ModalImagePreview;
