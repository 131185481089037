export const kandanganlama2008 = {
  type: "FeatureCollection",
  name: "kandanganlamatt08__1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12941.446,
        Luas: 1.294,
        PERIMETRO: 566.843,
        OWNER: "Norliani Astuti",
        No_Kav: "73",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711840802826543, -4.031002215838942],
              [114.711391489132339, -4.030975403972437],
              [114.711354619608514, -4.028999871828107],
              [114.711412999962604, -4.028804525789349],
              [114.711934370291146, -4.029127541407436],
              [114.711840802826543, -4.031002215838942],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17682.73,
        Luas: 1.768,
        PERIMETRO: 730.762,
        OWNER: "Faturrahman",
        No_Kav: "74",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.708611615171918, -4.030718749097716],
              [114.708093530007616, -4.030743217700446],
              [114.708316213326285, -4.028057236573748],
              [114.708859925970316, -4.027906515524282],
              [114.708611615171918, -4.030718749097716],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 18672.008,
        Luas: 1.867,
        PERIMETRO: 542.044,
        OWNER: "A Bustani",
        No_Kav: "87",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.689365516612426, -4.048142695179868],
              [114.689347491438724, -4.048029094469512],
              [114.689693237392476, -4.047709334496881],
              [114.689825842365437, -4.047594829253557],
              [114.690076772117067, -4.047358597527968],
              [114.690238150908939, -4.047183142029398],
              [114.690651937922212, -4.047511027728462],
              [114.69081551741111, -4.047670221143917],
              [114.690839379318263, -4.047902449523233],
              [114.691024512270303, -4.048085892811555],
              [114.690680221209746, -4.048510866512668],
              [114.690397292515925, -4.04889079003244],
              [114.689365516612426, -4.048142695179868],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14475.967,
        Luas: 1.448,
        PERIMETRO: 559.61,
        OWNER: "Norliani Astuti",
        No_Kav: "65",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714879743982578, -4.027687198508645],
              [114.715389151679432, -4.028097040384909],
              [114.71422754624453, -4.029319259082317],
              [114.713814092057987, -4.02921191129666],
              [114.713644007457717, -4.029206815477274],
              [114.713533854527881, -4.029141871944555],
              [114.714879743982578, -4.027687198508645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20026.898,
        Luas: 2.003,
        PERIMETRO: 588.85,
        OWNER: "Abdul Halim B",
        No_Kav: "2",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718091218867912, -4.043786248577801],
              [114.717654756409914, -4.04378465219738],
              [114.717445375187907, -4.043802609147738],
              [114.717103390343908, -4.04390365743312],
              [114.716896594658309, -4.043970861561754],
              [114.716900194209572, -4.042684103113455],
              [114.718082458880318, -4.042256146832073],
              [114.718095512843306, -4.04225149827632],
              [114.718091218867912, -4.043786248577801],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20073.619,
        Luas: 2.007,
        PERIMETRO: 580.688,
        OWNER: "Lintong Banjarnahor",
        No_Kav: "3",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716896591905765, -4.04397086338603],
              [114.716792189202295, -4.044004792449315],
              [114.715543796349877, -4.044294111685489],
              [114.715546939752571, -4.04317247235218],
              [114.716151744567554, -4.042955025653564],
              [114.716900194209572, -4.042684103113455],
              [114.716896591905765, -4.04397086338603],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20017.932,
        Luas: 2.002,
        PERIMETRO: 596.67,
        OWNER: "Berlian M tambunan",
        No_Kav: "4",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715543796349877, -4.044294111685489],
              [114.714327873760453, -4.044575906708522],
              [114.713944727714079, -4.044666138037312],
              [114.713947482657133, -4.043748879804671],
              [114.71430901152101, -4.043617544825919],
              [114.715546936804358, -4.043172473576594],
              [114.715543796349877, -4.044294111685489],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13002.127,
        Luas: 1.3,
        PERIMETRO: 581.781,
        OWNER: "Ernawati",
        No_Kav: "6",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711869103412383, -4.045162812965275],
              [114.710905427252285, -4.045383681715815],
              [114.710029058266741, -4.045589230092071],
              [114.709868968858487, -4.045616035705309],
              [114.709854871460578, -4.045548565140589],
              [114.709847182112682, -4.045234345352593],
              [114.709922979008965, -4.045207300903089],
              [114.71091263263564, -4.044852864918549],
              [114.711870943563, -4.044505360186619],
              [114.711869103412383, -4.045162812965275],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20002.139,
        Luas: 2.0,
        PERIMETRO: 656.605,
        OWNER: "Berlian M Tambunan",
        No_Kav: "5",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713944633404836, -4.044666137770638],
              [114.712495022866747, -4.045006598196091],
              [114.711943334589805, -4.045144156678139],
              [114.711869113140352, -4.045162803251409],
              [114.71187094505035, -4.04450535882204],
              [114.712488753664928, -4.044279049137782],
              [114.713947417751683, -4.04374891216465],
              [114.713944633404836, -4.044666137770638],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20032.508,
        Luas: 2.003,
        PERIMETRO: 581.861,
        OWNER: "Sudin Tambunan",
        No_Kav: "10",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716246814406674, -4.042830253506288],
              [114.714937057264436, -4.043313154777843],
              [114.71455281129532, -4.043450947161285],
              [114.714554699609167, -4.042777089384948],
              [114.714556668414744, -4.042074386950699],
              [114.714732163309975, -4.042046057749285],
              [114.715556069135062, -4.042099646982631],
              [114.715832947404081, -4.042184645334775],
              [114.716127257343018, -4.042555296765199],
              [114.716246814406674, -4.042830253506288],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20103.793,
        Luas: 2.01,
        PERIMETRO: 599.674,
        OWNER: "Sariati Lumban",
        No_Kav: "8",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71455281129532, -4.043450947161285],
              [114.71452844816136, -4.043459683884769],
              [114.712882591700279, -4.04405958261323],
              [114.7128862011309, -4.04277237315501],
              [114.714554699609167, -4.042777089384948],
              [114.71455281129532, -4.043450947161285],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20152.581,
        Luas: 2.015,
        PERIMETRO: 619.104,
        OWNER: "Sudin Tambunan",
        No_Kav: "11",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.7128862011309, -4.04277237315501],
              [114.712889796270758, -4.041489849126464],
              [114.713128312167797, -4.041382608469867],
              [114.713348158005147, -4.041490071839703],
              [114.713674470393869, -4.041868188900635],
              [114.714087466337674, -4.042056223198028],
              [114.714531528071404, -4.042078445218163],
              [114.714556668414744, -4.042074386950699],
              [114.714554699609167, -4.042777089384948],
              [114.7128862011309, -4.04277237315501],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20258.248,
        Luas: 2.026,
        PERIMETRO: 661.937,
        OWNER: "Rolan NP Tambunan",
        No_Kav: "12",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711409135395797, -4.043221122114576],
              [114.711410606719525, -4.042694698095212],
              [114.711697636108482, -4.042702929828033],
              [114.711893511845417, -4.04269389013983],
              [114.712035417072769, -4.042494426694451],
              [114.712012967657913, -4.042053819173735],
              [114.712089109459527, -4.041936377522456],
              [114.712437901835173, -4.041670905300852],
              [114.712889792819652, -4.041489849109823],
              [114.712884929647558, -4.043225296577742],
              [114.711409135395797, -4.043221122114576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20051.555,
        Luas: 2.005,
        PERIMETRO: 583.147,
        OWNER: "Sariati Lumban",
        No_Kav: "9",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712882587997427, -4.044059584393617],
              [114.711405265875342, -4.044598048758774],
              [114.711405751540241, -4.044425006604532],
              [114.711405753920033, -4.044423990665404],
              [114.71140724831514, -4.043891543212577],
              [114.711407366977085, -4.043849261365922],
              [114.711407433057389, -4.043825715310724],
              [114.711407549175547, -4.043784339210677],
              [114.711409134996217, -4.043221126215141],
              [114.712884930165785, -4.043225299707724],
              [114.712882587997427, -4.044059584393617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20060.539,
        Luas: 2.006,
        PERIMETRO: 572.038,
        OWNER: "Hadi Suroto",
        No_Kav: "32",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719287213172834, -4.036647029828416],
              [114.720710136292752, -4.036651970424591],
              [114.720348812089952, -4.03796253451248],
              [114.719310299034007, -4.037959610333598],
              [114.719283546215024, -4.037959534987128],
              [114.719287213172834, -4.036647029828416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20039.435,
        Luas: 2.004,
        PERIMETRO: 566.428,
        OWNER: "Deby Febriani",
        No_Kav: "33",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71804517067612, -4.036642797906083],
              [114.719287211261246, -4.036647029112781],
              [114.719283546215024, -4.037959534987128],
              [114.718926489415011, -4.037958529290047],
              [114.718041460395654, -4.037956020472602],
              [114.71804517067612, -4.036642797906083],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15104.243,
        Luas: 1.51,
        PERIMETRO: 595.593,
        OWNER: "Bakri",
        No_Kav: "35",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716488215869916, -4.036637636743553],
              [114.716806287322839, -4.036638633696933],
              [114.716802614367026, -4.037952543867074],
              [114.716470899779452, -4.037951608620822],
              [114.716460448466663, -4.037951579148412],
              [114.716457726109638, -4.037951571471412],
              [114.716448432457312, -4.037951545263434],
              [114.715503855649146, -4.037948881012026],
              [114.715538310964817, -4.03791259813857],
              [114.715548738615581, -4.03789180135622],
              [114.715548141509686, -4.037840477967735],
              [114.715568596339367, -4.037809403012755],
              [114.715568147241484, -4.037721022297448],
              [114.7156782285427, -4.037581313548142],
              [114.715715452675866, -4.037591554125586],
              [114.715767982564088, -4.037551072928736],
              [114.715799914340252, -4.037520705047952],
              [114.71599348271036, -4.037414276091335],
              [114.716108087459773, -4.037367031978182],
              [114.716140424192432, -4.037344147582355],
              [114.716097467625602, -4.037067021026983],
              [114.716061551166959, -4.037069605120434],
              [114.715978591372476, -4.037093154837073],
              [114.715880041906189, -4.03704530963741],
              [114.715888460880421, -4.03683201633434],
              [114.716015048098157, -4.036760286546214],
              [114.716003549780993, -4.036637371936162],
              [114.716148932383419, -4.036638989343352],
              [114.716488215869916, -4.036637636743553],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19998.47,
        Luas: 2.0,
        PERIMETRO: 565.869,
        OWNER: "Darsono",
        No_Kav: "34",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716806287322839, -4.036638633696933],
              [114.716946981771912, -4.036639074645474],
              [114.718045147853772, -4.036642809285017],
              [114.71804144371086, -4.0379560287914],
              [114.717696059355418, -4.037955062417783],
              [114.716802614367026, -4.037952543867074],
              [114.716806287322839, -4.036638633696933],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20015.093,
        Luas: 2.002,
        PERIMETRO: 571.828,
        OWNER: "Alfian Husna",
        No_Kav: "31",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720348812089952, -4.03796253451248],
              [114.719979823925698, -4.039271549095713],
              [114.718922804720947, -4.039277431518449],
              [114.718926489415011, -4.037958529290047],
              [114.719283546215024, -4.037959534987128],
              [114.719310299034007, -4.037959610333598],
              [114.720348812089952, -4.03796253451248],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20016.627,
        Luas: 2.002,
        PERIMETRO: 566.204,
        OWNER: "Rusmiati",
        No_Kav: "29",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718922804720947, -4.039277431518449],
              [114.718345688690675, -4.039280642644714],
              [114.717692346860147, -4.039283198279819],
              [114.717696059355418, -4.037955062417783],
              [114.71804099697242, -4.037956034506016],
              [114.718926489415011, -4.037958529290047],
              [114.718922804720947, -4.039277431518449],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20107.521,
        Luas: 2.011,
        PERIMETRO: 574.328,
        OWNER: "Rusmiati",
        No_Kav: "30",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717692346860147, -4.039283198279819],
              [114.717318441738271, -4.039276392650282],
              [114.717045152840271, -4.039203792540504],
              [114.716832471496119, -4.039275022188686],
              [114.716444696187594, -4.039288075988347],
              [114.716448435874014, -4.037951548210399],
              [114.716802614367026, -4.037952543867074],
              [114.717696059355418, -4.037955062417783],
              [114.717692346860147, -4.039283198279819],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 18127.738,
        Luas: 1.813,
        PERIMETRO: 765.736,
        OWNER: "Suparno",
        No_Kav: "27",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715204559139991, -4.03929490015399],
              [114.712772190826897, -4.039308747024948],
              [114.712824583673623, -4.039108909824015],
              [114.712824706471864, -4.039065077791687],
              [114.712857573288304, -4.039018598741072],
              [114.712951242008231, -4.038993191912918],
              [114.713053633733438, -4.038933096854474],
              [114.71315826268561, -4.038803025736256],
              [114.713769530115144, -4.038814350125023],
              [114.713925887494995, -4.038808753200953],
              [114.714116100181315, -4.038698889126199],
              [114.7142471376646, -4.038666384758042],
              [114.714307415078238, -4.038573411023842],
              [114.714372995162535, -4.038535242779463],
              [114.714419387011091, -4.03852989468323],
              [114.714585620494674, -4.038593373036154],
              [114.71462372857691, -4.038623615400015],
              [114.71469461904141, -4.03863751313102],
              [114.714839304366294, -4.038602307641855],
              [114.714894621479829, -4.03858806574915],
              [114.714955174928235, -4.038437275684157],
              [114.714926738705913, -4.038358106379532],
              [114.714917834039852, -4.038320380655637],
              [114.715045676439573, -4.0382498643006],
              [114.715109034559717, -4.038147497455019],
              [114.715169241591568, -4.038097902545709],
              [114.715188843790742, -4.038069305397732],
              [114.715208012375811, -4.03806087835843],
              [114.715204559139991, -4.03929490015399],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20020.0,
        Luas: 2.002,
        PERIMETRO: 572.07,
        OWNER: "Ruben Pangaribuan",
        No_Kav: "21",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718547663591693, -4.039386706902222],
              [114.719967406578604, -4.039383542584468],
              [114.719592360536026, -4.040706733524001],
              [114.718543982095014, -4.040703778586744],
              [114.718547663591693, -4.039386706902222],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20038.645,
        Luas: 2.004,
        PERIMETRO: 566.315,
        OWNER: "Ruben Pangaribuan",
        No_Kav: "22",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717304798561656, -4.039394236585274],
              [114.717695884872285, -4.039391801240691],
              [114.718224355974485, -4.039387427140126],
              [114.718547663591693, -4.039386706902222],
              [114.718543982095014, -4.040703778586744],
              [114.718249765170071, -4.040702949069221],
              [114.718175161556687, -4.040702738714213],
              [114.718083979367819, -4.040702481604326],
              [114.717317072628646, -4.040700318721838],
              [114.717301145916039, -4.040700273796541],
              [114.717304798561656, -4.039394236585274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20279.793,
        Luas: 2.028,
        PERIMETRO: 570.193,
        OWNER: "Tiamsyah Panjaitan",
        No_Kav: "23",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716037850083936, -4.03940201766136],
              [114.716075445276019, -4.039401890689651],
              [114.716863655762694, -4.039408205879498],
              [114.71705313662784, -4.039358037124394],
              [114.717304798561656, -4.039394236585274],
              [114.717301145916039, -4.040700273796541],
              [114.717089488738566, -4.040699676734525],
              [114.717067076144687, -4.040699613507782],
              [114.717035058154067, -4.040699523182786],
              [114.717003040164172, -4.040699432856529],
              [114.716947008683547, -4.040699274782533],
              [114.71603422973962, -4.040696697040464],
              [114.716037850083936, -4.03940201766136],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20140.458,
        Luas: 2.014,
        PERIMETRO: 567.682,
        OWNER: "Tiamsyah Panjaitan",
        No_Kav: "24",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714768110965551, -4.039406304972789],
              [114.716037850083936, -4.03940201766136],
              [114.716034227183712, -4.040696699130157],
              [114.715313507112398, -4.040694664702452],
              [114.715086347739586, -4.040694023350598],
              [114.714764508088848, -4.040693114573473],
              [114.714768110965551, -4.039406304972789],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20359.433,
        Luas: 2.036,
        PERIMETRO: 570.613,
        OWNER: "Nurul Muhibah",
        No_Kav: "25",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713476472787974, -4.039412281458715],
              [114.713710501457172, -4.039409874497514],
              [114.714768110965551, -4.039406304972789],
              [114.714764508088848, -4.040693114573473],
              [114.713995608477063, -4.040690942917308],
              [114.71398426991955, -4.040690910887577],
              [114.713955196695508, -4.040690828759334],
              [114.713915221013409, -4.040690715831295],
              [114.713842537955529, -4.040690511375488],
              [114.71349739697277, -4.040689535394383],
              [114.713472894835249, -4.040689466164226],
              [114.713476472787974, -4.039412281458715],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13546.01,
        Luas: 1.355,
        PERIMETRO: 455.79,
        OWNER: "Abdullah",
        No_Kav: "86",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.69400775598389, -4.046506324931476],
              [114.694002181333076, -4.04682925224756],
              [114.693603648146222, -4.046842155790808],
              [114.69308397557117, -4.046840672281385],
              [114.692769607595309, -4.046757868873673],
              [114.692666806085782, -4.046375467200656],
              [114.693021229433896, -4.045955910126418],
              [114.693366692166094, -4.045713376438392],
              [114.693388224087997, -4.045700241938431],
              [114.693998225084158, -4.046270241262089],
              [114.69399823883937, -4.046271606785824],
              [114.694001281398386, -4.04632377332775],
              [114.694098872200996, -4.046424679043421],
              [114.69400775598389, -4.046506324931476],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 6471.174,
        Luas: 0.647,
        PERIMETRO: 333.956,
        OWNER: "Abdullah",
        No_Kav: "85",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.693388227283052, -4.045700238748142],
              [114.69393888704947, -4.045474079177118],
              [114.694338224893812, -4.045350241416426],
              [114.694348224118741, -4.046140241414193],
              [114.694244691024025, -4.046175793424146],
              [114.694160238024139, -4.046206817911781],
              [114.694081819621758, -4.046234833917588],
              [114.693998225369725, -4.046270239571141],
              [114.693388227283052, -4.045700238748142],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12724.26,
        Luas: 1.272,
        PERIMETRO: 563.338,
        OWNER: "Rusdina",
        No_Kav: "82",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.695624213332565, -4.030447719566423],
              [114.695976673023523, -4.029852124332806],
              [114.697538226325904, -4.030830240643636],
              [114.697458226636158, -4.031010240329917],
              [114.697028226669502, -4.030780240656661],
              [114.6968682264162, -4.031170239895818],
              [114.695624213332565, -4.030447719566423],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 8328.15,
        Luas: 0.833,
        PERIMETRO: 385.34,
        OWNER: "Ahmad Dairobi",
        No_Kav: "46",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712297541048599, -4.031167311989361],
              [114.712674791253306, -4.031232765134368],
              [114.712958225164115, -4.031290241155547],
              [114.713020991771188, -4.032034380919174],
              [114.713021296862493, -4.032037998197327],
              [114.712730466650697, -4.032221519710045],
              [114.712368928303803, -4.032449659581625],
              [114.712368813942021, -4.032447605258328],
              [114.712297541048599, -4.031167311989361],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5196.972,
        Luas: 0.52,
        PERIMETRO: 367.062,
        OWNER: "Buhari",
        No_Kav: "47",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713020991771188, -4.032034380919174],
              [114.712958228956083, -4.031290242800187],
              [114.713837661600863, -4.031309837335032],
              [114.714140158226442, -4.031271366498559],
              [114.71415154148815, -4.031267922444236],
              [114.714099873146367, -4.031302954560192],
              [114.713846027817596, -4.031475048634839],
              [114.713742665590431, -4.031545122851266],
              [114.713742663645974, -4.0315451241695],
              [114.713721317049391, -4.031559596048137],
              [114.713695342636569, -4.031577205342835],
              [114.713690989705668, -4.031580156402347],
              [114.713684003376272, -4.031584892768183],
              [114.713682089018917, -4.031586190496558],
              [114.713643257286734, -4.031612516511406],
              [114.713074503387418, -4.031998103274809],
              [114.713057432066876, -4.032009676793656],
              [114.713020991771188, -4.032034380919174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17308.879,
        Luas: 1.731,
        PERIMETRO: 527.943,
        OWNER: "M Zakaria",
        No_Kav: "79",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703594516308371, -4.03397614541582],
              [114.702509138705096, -4.034536322042792],
              [114.7020258399928, -4.033551382652678],
              [114.702099136861634, -4.033450581504976],
              [114.702299815819146, -4.033315243276363],
              [114.702787925953245, -4.032995885813152],
              [114.703007162668555, -4.03288254702069],
              [114.703119754495418, -4.03313242851732],
              [114.703210114895867, -4.033378572814594],
              [114.703215107959551, -4.033381432746686],
              [114.703594516308371, -4.03397614541582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 9943.032,
        Luas: 0.994,
        PERIMETRO: 399.564,
        OWNER: "M Zakaria",
        No_Kav: "78",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.705401325258862, -4.034596364311011],
              [114.704656987908251, -4.034987172946427],
              [114.704398236386794, -4.034521343035431],
              [114.704191809693398, -4.034135504387935],
              [114.704927642352843, -4.03375548184718],
              [114.705401325258862, -4.034596364311011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19979.078,
        Luas: 1.998,
        PERIMETRO: 859.282,
        OWNER: "Juhran",
        No_Kav: "77",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.703866981137267, -4.033835522557594],
              [114.703594516308371, -4.03397614541582],
              [114.703215107959551, -4.033381432746686],
              [114.703771201201874, -4.033699952161459],
              [114.704405321814278, -4.033435369965156],
              [114.704414977061063, -4.033339752704876],
              [114.705516839241866, -4.032908963330288],
              [114.705618436777172, -4.033350663018272],
              [114.705616972581666, -4.033350554305241],
              [114.705640639144875, -4.033625100603059],
              [114.705621604898838, -4.034099922737718],
              [114.705699818663817, -4.034163630226783],
              [114.7056738323774, -4.034412422178398],
              [114.705643444030557, -4.034469241416135],
              [114.705401325258862, -4.034596364311011],
              [114.704927642352843, -4.03375548184718],
              [114.704191809693398, -4.034135504387935],
              [114.704177976914409, -4.034109649098017],
              [114.703866981137267, -4.033835522557594],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14681.376,
        Luas: 1.468,
        PERIMETRO: 714.369,
        OWNER: "Darno",
        No_Kav: "76",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.705646016479804, -4.032110422691959],
              [114.705624933774914, -4.032012509246723],
              [114.705419885008638, -4.031659664670338],
              [114.705469863395876, -4.030635793287431],
              [114.70604106191918, -4.030652241658325],
              [114.705914596520088, -4.033380206578662],
              [114.705869903219963, -4.033368723111429],
              [114.705619683433213, -4.033349884449232],
              [114.705516840957102, -4.032908970787377],
              [114.705611917888987, -4.032871778784824],
              [114.705664571756955, -4.032196551360214],
              [114.705646016479804, -4.032110422691959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 16285.986,
        Luas: 1.629,
        PERIMETRO: 564.125,
        OWNER: "Danuri",
        No_Kav: "90",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.693413253366728, -4.049014335347754],
              [114.693504218528403, -4.049023297254947],
              [114.693591417676558, -4.048832100007368],
              [114.693648978191646, -4.048621047820363],
              [114.694264449525306, -4.049043657364305],
              [114.695013645220598, -4.049558087036147],
              [114.694511387324837, -4.049828416923308],
              [114.694411936405572, -4.049796025846655],
              [114.693948012909061, -4.049903477329052],
              [114.693678833668088, -4.050094154776061],
              [114.693401814528272, -4.049993289226206],
              [114.69348065635846, -4.049693291904294],
              [114.693337869475101, -4.049623267198895],
              [114.693260753288982, -4.049314122286564],
              [114.693295884424444, -4.049148882699913],
              [114.693413253366728, -4.049014335347754],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 8078.953,
        Luas: 0.808,
        PERIMETRO: 682.128,
        OWNER: "Firdaus Manaf",
        No_Kav: "63a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718272085397786, -4.03335108325949],
              [114.718237619838604, -4.033273511205219],
              [114.71783139470557, -4.032532736901162],
              [114.717334992695186, -4.03159340222519],
              [114.716953772216968, -4.030914471241902],
              [114.71734164551556, -4.030608464664236],
              [114.718268875781845, -4.033343342082508],
              [114.718272085397786, -4.03335108325949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5116.657,
        Luas: 0.512,
        PERIMETRO: 291.726,
        OWNER: "Habibah",
        No_Kav: "50c",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716953772216968, -4.030914471241902],
              [114.716944652766074, -4.030898230035607],
              [114.716886176532711, -4.03081499102354],
              [114.71658086411729, -4.030251369508385],
              [114.71710110377451, -4.029898983338717],
              [114.71734164551556, -4.030608464664236],
              [114.716953772216968, -4.030914471241902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20158.106,
        Luas: 2.016,
        PERIMETRO: 923.978,
        OWNER: "Suwito Harjo",
        No_Kav: "62",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718268875781845, -4.033343342082508],
              [114.71734164551556, -4.030608464664236],
              [114.71710110377451, -4.029898983338717],
              [114.717483105111612, -4.029640232857297],
              [114.718669142277889, -4.033160487469887],
              [114.718301796098785, -4.033420287066545],
              [114.718268875781845, -4.033343342082508],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20061.048,
        Luas: 2.006,
        PERIMETRO: 926.413,
        OWNER: "Witnu Susanto",
        No_Kav: "61",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717483105111612, -4.029640232857297],
              [114.717883255962406, -4.029369188235838],
              [114.718435759668594, -4.031122170756553],
              [114.718439018712573, -4.031120114296869],
              [114.719010065421685, -4.032820000020619],
              [114.71903739064814, -4.032900049378805],
              [114.718669142277889, -4.033160487469887],
              [114.717483105111612, -4.029640232857297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15965.242,
        Luas: 1.597,
        PERIMETRO: 511.464,
        OWNER: "Herlina",
        No_Kav: "71",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711158221681899, -4.027840239606972],
              [114.710721695585079, -4.027996449451283],
              [114.710353239638138, -4.027386603574247],
              [114.710171574049085, -4.026686548977593],
              [114.710435011727625, -4.02628629886239],
              [114.711591886719404, -4.027258637520571],
              [114.711158221681899, -4.027840239606972],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20145.699,
        Luas: 2.015,
        PERIMETRO: 591.312,
        OWNER: "Djarot Subiantoro",
        No_Kav: "19",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717089488738566, -4.040699676734525],
              [114.717301145916039, -4.040700273796541],
              [114.717317072628646, -4.040700318721838],
              [114.718083979367819, -4.040702481604326],
              [114.718092796031797, -4.040702509898815],
              [114.718088728564283, -4.042156433359343],
              [114.717084413290451, -4.042513575748964],
              [114.717089488738566, -4.040699676734525],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20052.05,
        Luas: 2.005,
        PERIMETRO: 614.278,
        OWNER: "Fitriy Riani",
        No_Kav: "18",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716253499330648, -4.040697336782303],
              [114.717089481151291, -4.040699679972038],
              [114.717084409108836, -4.042513576392355],
              [114.716334599154649, -4.042780213016855],
              [114.716248143285981, -4.0425774664437],
              [114.716253499330648, -4.040697336782303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20012.177,
        Luas: 2.001,
        PERIMETRO: 637.874,
        OWNER: "Choifin Rifai",
        No_Kav: "17",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715086347739586, -4.040694023350598],
              [114.716034227183712, -4.040696699130157],
              [114.716057567731937, -4.040696765004455],
              [114.716253429664548, -4.040697371516382],
              [114.71624812554083, -4.042577466393602],
              [114.716212337036993, -4.042496709102334],
              [114.715913204142979, -4.042081222404684],
              [114.715586450272568, -4.041992341713274],
              [114.71530992283671, -4.041974728152553],
              [114.715082801829595, -4.041960261491537],
              [114.715086347739586, -4.040694023350598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17026.298,
        Luas: 1.703,
        PERIMETRO: 520.054,
        OWNER: "Choifin Rifai",
        No_Kav: "16",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713995608477063, -4.040690942917308],
              [114.714764508088848, -4.040693114573473],
              [114.715086347739586, -4.040694023350598],
              [114.715082801829595, -4.041960261491537],
              [114.714815795938463, -4.041943254250405],
              [114.714488697508614, -4.041977513425835],
              [114.714112063813673, -4.041976449300202],
              [114.713992163340507, -4.041920613981289],
              [114.713992162483237, -4.041920612806321],
              [114.71399560743852, -4.040690944174477],
              [114.713995608477063, -4.040690942917308],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20148.134,
        Luas: 2.015,
        PERIMETRO: 942.78,
        OWNER: "Suyanto",
        No_Kav: "14",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712308491117653, -4.041630600659864],
              [114.711984466124733, -4.041886652356197],
              [114.711914835255257, -4.042042198506127],
              [114.711917889437828, -4.042152195513821],
              [114.711940241233393, -4.042341776290534],
              [114.711926913026161, -4.042492945012146],
              [114.711834450539399, -4.04262900271871],
              [114.711686999697505, -4.042638577203103],
              [114.711285710028761, -4.042613073942443],
              [114.711118231048729, -4.042618929608518],
              [114.710868415814105, -4.042613315300401],
              [114.71094734139335, -4.042453977427241],
              [114.7110144789049, -4.042336761165326],
              [114.711069679659332, -4.042221907157288],
              [114.711144116299025, -4.042054394547555],
              [114.711228070008417, -4.041896493022834],
              [114.711328698791959, -4.041748222827931],
              [114.711386125022713, -4.041722221604941],
              [114.711453200223843, -4.041686196790967],
              [114.711536111362776, -4.041659436930696],
              [114.711597344021484, -4.041620549839755],
              [114.711661370648983, -4.041576090601636],
              [114.711747394983973, -4.041490481040339],
              [114.711804164602867, -4.04143278120906],
              [114.711919422780539, -4.041322463003511],
              [114.711951297983589, -4.041286391202979],
              [114.711991622337351, -4.041258851982949],
              [114.712010776086515, -4.041226998550203],
              [114.712019350497414, -4.041190860855767],
              [114.711998269365225, -4.04115463930622],
              [114.711955940095024, -4.04114175657622],
              [114.711909380286585, -4.04112673470915],
              [114.711850122895285, -4.041107422588986],
              [114.711784415310589, -4.041122126987431],
              [114.71169756099367, -4.041124008541766],
              [114.711606476142578, -4.041123750949822],
              [114.711570805891128, -4.041002401249753],
              [114.711590353347049, -4.040830155572956],
              [114.71163702010017, -4.040684276838431],
              [114.71165299636823, -4.040640338383179],
              [114.711568417454927, -4.040552890399868],
              [114.71166753737532, -4.040496383541116],
              [114.711849347406002, -4.0404806727057],
              [114.711899979711134, -4.040430113048511],
              [114.711968782778285, -4.040381632868455],
              [114.711738598506599, -4.039990666460992],
              [114.711810368471532, -4.040004977876468],
              [114.711928544536207, -4.03992634496023],
              [114.711976425516582, -4.039926026374484],
              [114.712016208249409, -4.039897830387638],
              [114.712118830946565, -4.039800304263752],
              [114.712116353287271, -4.040685632134192],
              [114.712133732600364, -4.040685681268188],
              [114.712286832607759, -4.040686114089366],
              [114.712311138922317, -4.040686182801839],
              [114.712308491117653, -4.041630600659864],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17181.155,
        Luas: 1.718,
        PERIMETRO: 650.038,
        OWNER: "Rolan NP Tambunan",
        No_Kav: "15",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712475069178467, -4.041540588329912],
              [114.712372812972475, -4.041583228010835],
              [114.712308491117653, -4.041630600659864],
              [114.712311138922317, -4.040686182801839],
              [114.713472894835249, -4.040689466164226],
              [114.71349739697277, -4.040689535394383],
              [114.71399560743852, -4.040690944174477],
              [114.713992162483237, -4.041920612806321],
              [114.713744665686036, -4.041805359073422],
              [114.713418742542345, -4.041420355032716],
              [114.713118447246046, -4.041267045950718],
              [114.712475069178467, -4.041540588329912],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15416.126,
        Luas: 1.542,
        PERIMETRO: 568.595,
        OWNER: "Ahyani",
        No_Kav: "45",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713010427940915, -4.03402796974227],
              [114.712438008427227, -4.033461583959531],
              [114.711878566283445, -4.032801877584753],
              [114.712368813942021, -4.032447605258328],
              [114.712368928303803, -4.032449659581625],
              [114.713057432066876, -4.032009676793656],
              [114.71304988660296, -4.033998673597177],
              [114.713010427940915, -4.03402796974227],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15280.481,
        Luas: 1.528,
        PERIMETRO: 608.039,
        OWNER: "Eny Nuryani",
        No_Kav: "48",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713676954279677, -4.033573154233054],
              [114.71304988660296, -4.033998673597177],
              [114.713057432066876, -4.032009676793656],
              [114.713684003376272, -4.031584892768183],
              [114.713676954279677, -4.033573154233054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20328.083,
        Luas: 2.033,
        PERIMETRO: 674.56,
        OWNER: "Ir Haryanto",
        No_Kav: "52",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715666497427705, -4.03193720151918],
              [114.715705563099064, -4.031906214184037],
              [114.715783692683033, -4.031844237276981],
              [114.715854746711528, -4.03178787309044],
              [114.716426309540367, -4.031334475546291],
              [114.716419370706859, -4.033819172231246],
              [114.715661474014553, -4.033816836779496],
              [114.715666497427705, -4.03193720151918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20017.874,
        Luas: 2.002,
        PERIMETRO: 594.41,
        OWNER: "Nanang Dwi Haryono",
        No_Kav: "53",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717138150129628, -4.031412918598334],
              [114.717429719254909, -4.031957867653698],
              [114.717933949400205, -4.03288235773717],
              [114.716422003701041, -4.032878099719896],
              [114.716426098525147, -4.031410911999697],
              [114.717138150129628, -4.031412918598334],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15959.184,
        Luas: 1.596,
        PERIMETRO: 612.824,
        OWNER: "M Noor Ilmi",
        No_Kav: "41a",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718410359729404, -4.03382530722627],
              [114.718645575275602, -4.034379683796868],
              [114.719008053963819, -4.034675630273793],
              [114.719212455839724, -4.034708975123565],
              [114.719568445144589, -4.03499179544229],
              [114.719748748027669, -4.03519386592973],
              [114.719171554506985, -4.035192241548212],
              [114.719163952103045, -4.03519222015021],
              [114.718317547085462, -4.035189837382386],
              [114.718073337223601, -4.035189149727644],
              [114.718000907347289, -4.035188921359475],
              [114.717999244693218, -4.033824088180654],
              [114.718410359729404, -4.03382530722627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20172.175,
        Luas: 2.017,
        PERIMETRO: 569.176,
        OWNER: "M Jalpi HB",
        No_Kav: "42",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718000892922063, -4.035188926143042],
              [114.717914418897706, -4.03518870220031],
              [114.716795502748454, -4.035185411967994],
              [114.716799328759393, -4.033820534623318],
              [114.717999243054038, -4.033824087247826],
              [114.718000892922063, -4.035188926143042],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20348.233,
        Luas: 2.035,
        PERIMETRO: 723.183,
        OWNER: "Linda Matondang",
        No_Kav: "36",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717908450029384, -4.036503393383083],
              [114.717451849497238, -4.036497605184582],
              [114.716500162428602, -4.036491306915002],
              [114.716431269154114, -4.036215657072407],
              [114.717093692870577, -4.035994535989465],
              [114.716261991590841, -4.035184106043459],
              [114.716271278416372, -4.035184129577515],
              [114.716469900338865, -4.035184632886507],
              [114.716795417332094, -4.035185550116178],
              [114.71680172842008, -4.035185567898035],
              [114.717914418897706, -4.03518870220031],
              [114.717908450029384, -4.036503393383083],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20122.06,
        Luas: 2.012,
        PERIMETRO: 596.147,
        OWNER: "M Syafie",
        No_Kav: "38",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719748748027669, -4.03519386592973],
              [114.720279778500199, -4.035789006153855],
              [114.720692562437975, -4.035988969876096],
              [114.720857538363731, -4.036118327856505],
              [114.720732390868832, -4.03652868841508],
              [114.719583774562224, -4.036514532622076],
              [114.719160265469327, -4.036516407769481],
              [114.719163952494696, -4.035192220482867],
              [114.719748748027669, -4.03519386592973],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20407.404,
        Luas: 2.041,
        PERIMETRO: 652.061,
        OWNER: "Sahala P Tobing",
        No_Kav: "39",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720369889994416, -4.033850093210623],
              [114.721539657034072, -4.033872360288409],
              [114.72132495397112, -4.034308568823199],
              [114.720895905768117, -4.036009053068864],
              [114.720792040734068, -4.035933725714949],
              [114.72045488186329, -4.035766122514257],
              [114.720364668874652, -4.035721277202117],
              [114.720369889994416, -4.033850093210623],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 3333.954,
        Luas: 0.333,
        PERIMETRO: 255.342,
        OWNER: "M Noor Ilmi",
        No_Kav: "41b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.7184692917766, -4.033832149430645],
              [114.719039966532861, -4.033837537795938],
              [114.719033609659363, -4.034593845185519],
              [114.719014281992571, -4.034589901051787],
              [114.718913976410221, -4.034508256085282],
              [114.718700871787021, -4.034334785121932],
              [114.7184692917766, -4.033832149430645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20042.735,
        Luas: 2.004,
        PERIMETRO: 636.077,
        OWNER: "Sahala P Tobing",
        No_Kav: "40",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 5",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719039965944518, -4.033837536291442],
              [114.720369889998992, -4.033850091571173],
              [114.720364662232782, -4.035721273916693],
              [114.720318308020055, -4.035698231517679],
              [114.720071138235724, -4.035426292161438],
              [114.719637401467125, -4.034949107902069],
              [114.719256364759616, -4.034639231419769],
              [114.719145787258498, -4.034616698969614],
              [114.71903361175471, -4.034593841405035],
              [114.719039965944518, -4.033837536291442],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20067.272,
        Luas: 2.007,
        PERIMETRO: 574.906,
        OWNER: "Joko Prasetyo",
        No_Kav: "58",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719958433614408, -4.030201357886673],
              [114.72116802156286, -4.030521069140043],
              [114.721123226484266, -4.030780175514538],
              [114.721115930776222, -4.031138970319579],
              [114.721080602009522, -4.031765138421828],
              [114.719954081020262, -4.031761972551773],
              [114.719958433614408, -4.030201357886673],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20508.772,
        Luas: 2.051,
        PERIMETRO: 601.909,
        OWNER: "Sri Sumarni",
        No_Kav: "60",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718653477502627, -4.031758314813481],
              [114.718442628743006, -4.031128334905082],
              [114.719282217653799, -4.030622053617055],
              [114.719677643781296, -4.030126954721646],
              [114.719958430010735, -4.030201355048774],
              [114.719954080265254, -4.031761972225794],
              [114.718653481936911, -4.031758317330491],
              [114.718653477502627, -4.031758314813481],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20132.092,
        Luas: 2.013,
        PERIMETRO: 598.906,
        OWNER: "Iswan Herlambang",
        No_Kav: "56",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721142595147427, -4.032123725345321],
              [114.721325144507261, -4.032768981571802],
              [114.72156732379014, -4.033039760133904],
              [114.721584014336031, -4.033128252176308],
              [114.72007843339135, -4.033124019534526],
              [114.720056899275377, -4.033123958975363],
              [114.720060697530627, -4.031762276748598],
              [114.721080596498794, -4.031765144277841],
              [114.721072335415116, -4.031899298982716],
              [114.721142595147427, -4.032123725345321],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20206.732,
        Luas: 2.021,
        PERIMETRO: 630.406,
        OWNER: "Sri Lestari",
        No_Kav: "57",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720056899275377, -4.033123958975363],
              [114.718917322563414, -4.033120753393549],
              [114.718726224838719, -4.033120215686867],
              [114.719027873811328, -4.032906950207593],
              [114.719037578615485, -4.032900084199186],
              [114.719007763495767, -4.032812621995036],
              [114.718653481936911, -4.031758317330491],
              [114.720060695760836, -4.031762276402403],
              [114.720056899275377, -4.033123958975363],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 3501.735,
        Luas: 0.35,
        PERIMETRO: 235.007,
        OWNER: "Firdaus Manaf",
        No_Kav: "63b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718915644299869, -4.03372224672386],
              [114.718431190681954, -4.0337162268037],
              [114.718301796098785, -4.033420287066545],
              [114.718726224838719, -4.033120215686867],
              [114.718917322563414, -4.033120753393549],
              [114.718915644299869, -4.03372224672386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20394.107,
        Luas: 2.039,
        PERIMETRO: 730.081,
        OWNER: "Fatkhul Qorib",
        No_Kav: "55",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.721584014336031, -4.033128252176308],
              [114.721652822012658, -4.033493060521546],
              [114.721582684489732, -4.033754245851755],
              [114.720593424852737, -4.033743092944687],
              [114.718915644299869, -4.03372224672386],
              [114.718917322563414, -4.033120753393549],
              [114.720056899275377, -4.033123958975363],
              [114.72007843339135, -4.033124019534526],
              [114.721584014336031, -4.033128252176308],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 23034.103,
        Luas: 2.303,
        PERIMETRO: 641.541,
        OWNER: "M Ahmadi",
        No_Kav: "69",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711748917463424, -4.024909896104444],
              [114.713070008393089, -4.02605260065615],
              [114.712438234434799, -4.026850221196664],
              [114.710938258981329, -4.02549023818043],
              [114.711748917463424, -4.024909896104444],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17723.013,
        Luas: 1.772,
        PERIMETRO: 559.498,
        OWNER: "Taufik Hidayat",
        No_Kav: "59",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 6",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.720897620970348, -4.02896945847316],
              [114.721377100865965, -4.029242686579876],
              [114.721167087496198, -4.030508832103076],
              [114.719682082393547, -4.030122891734645],
              [114.720096263103997, -4.029698698507936],
              [114.720897620970348, -4.02896945847316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 22079.234,
        Luas: 2.208,
        PERIMETRO: 645.009,
        OWNER: "Zainul Kadam",
        No_Kav: "70",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.710938223034034, -4.025490274077454],
              [114.712438213191646, -4.02685024241011],
              [114.711909886420472, -4.027518654804745],
              [114.710369496561626, -4.026229757657204],
              [114.710938223034034, -4.025490274077454],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20228.76,
        Luas: 2.023,
        PERIMETRO: 587.67,
        OWNER: "Mahyudi",
        No_Kav: "20",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718249765170071, -4.040702949069221],
              [114.718543982095014, -4.040703778586744],
              [114.719592360536026, -4.040706733524001],
              [114.719303940257817, -4.041724295980888],
              [114.718245856724053, -4.042100559226797],
              [114.718088732364606, -4.042156433772437],
              [114.718092798600381, -4.040702510208734],
              [114.718249765170071, -4.040702949069221],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20180.558,
        Luas: 2.018,
        PERIMETRO: 629.722,
        OWNER: "Rahmattullah ST MT",
        No_Kav: "1",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 1",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.718305623627799, -4.04217667714683],
              [114.718694799091892, -4.042040686323495],
              [114.719279113295897, -4.041828813931372],
              [114.718726825380699, -4.043984330323845],
              [114.718239667832336, -4.043824839157763],
              [114.71813615476205, -4.043786412919425],
              [114.718091218867912, -4.043786248577801],
              [114.718095512843306, -4.04225149827632],
              [114.718305623627799, -4.04217667714683],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20175.528,
        Luas: 2.018,
        PERIMETRO: 563.518,
        OWNER: "Suparno",
        No_Kav: "28",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 4",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.71644469444918, -4.039288076659364],
              [114.716310533890891, -4.039288601685883],
              [114.715204559139991, -4.03929490015399],
              [114.715208012375811, -4.03806087835843],
              [114.715217871602391, -4.038056543969898],
              [114.715251309282166, -4.038041208376316],
              [114.715378733802069, -4.038031154790259],
              [114.715456595802408, -4.037998647576],
              [114.715503855649146, -4.037948881012026],
              [114.716448432457312, -4.037951545263434],
              [114.71644469444918, -4.039288076659364],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20238.684,
        Luas: 2.024,
        PERIMETRO: 569.818,
        OWNER: "Linda Matondang",
        No_Kav: "37",
        KETERANGAN: "Masuk SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.719160259444592, -4.036516405733917],
              [114.719043722776689, -4.036509864773005],
              [114.717908450029384, -4.036503393383083],
              [114.717914418897706, -4.03518870220031],
              [114.718003719676943, -4.035188953682628],
              [114.718073337223601, -4.035189149727644],
              [114.718317547085462, -4.035189837382386],
              [114.719163952103045, -4.03519222015021],
              [114.719160259444592, -4.036516405733917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20200.308,
        Luas: 2.02,
        PERIMETRO: 665.565,
        OWNER: "Eis Komariah",
        No_Kav: "49",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714099873146367, -4.031302954560192],
              [114.714585024208887, -4.031948041798497],
              [114.714581845933253, -4.033499744751973],
              [114.714273063548063, -4.03319188422855],
              [114.714259048045946, -4.033176293297051],
              [114.714239927221797, -4.03319042140903],
              [114.713677045677059, -4.033573017393685],
              [114.713684003376272, -4.031584892768183],
              [114.714099873146367, -4.031302954560192],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20707.98,
        Luas: 2.071,
        PERIMETRO: 683.603,
        OWNER: "Eis Komariah",
        No_Kav: "51",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715658629536449, -4.031943442771878],
              [114.71566495596781, -4.031938424291658],
              [114.715666497427705, -4.03193720151918],
              [114.715661246129471, -4.033816850220557],
              [114.714897556340119, -4.033814512385852],
              [114.71458187592566, -4.033499774654843],
              [114.714585768650636, -4.031948926679093],
              [114.714985297777247, -4.032477563641727],
              [114.714985300862466, -4.032477567723942],
              [114.7156396981146, -4.031958460341396],
              [114.715658629536449, -4.031943442771878],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20349.005,
        Luas: 2.035,
        PERIMETRO: 635.527,
        OWNER: "M Rafi e",
        No_Kav: "43",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.716795417332094, -4.035185550116178],
              [114.716469900338865, -4.035184632886507],
              [114.716271278416372, -4.035184129577515],
              [114.714897580646706, -4.033814536619845],
              [114.716799230544908, -4.033820523363447],
              [114.716795417332094, -4.035185550116178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20370.078,
        Luas: 2.037,
        PERIMETRO: 610.276,
        OWNER: "Ir Haryanto",
        No_Kav: "54",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717933952620228, -4.032882360241525],
              [114.717998954997839, -4.033001541771711],
              [114.718215384741498, -4.033401939649895],
              [114.718286741893792, -4.033557249790661],
              [114.718351609272887, -4.033699433844762],
              [114.718410359729404, -4.03382530722627],
              [114.716419377052347, -4.033819171648786],
              [114.716422004141606, -4.032878103364458],
              [114.717933952620228, -4.032882360241525],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 24677.377,
        Luas: 2.468,
        PERIMETRO: 775.694,
        OWNER: "Arif Sujarwo",
        No_Kav: "75",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70659262010517, -4.03353003188846],
              [114.706442587619733, -4.033510555438636],
              [114.705915327337692, -4.03338020864669],
              [114.706041079599402, -4.030652253128158],
              [114.706791162636051, -4.030708551615515],
              [114.706712659762189, -4.031888613561799],
              [114.70659262010517, -4.03353003188846],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 25999.499,
        Luas: 2.6,
        PERIMETRO: 735.158,
        OWNER: "Burhani",
        No_Kav: "81",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.700641783885388, -4.031568384857828],
              [114.700848650042559, -4.030983567667307],
              [114.702378176994287, -4.030795472387647],
              [114.702410926239807, -4.031278673336277],
              [114.702851518030059, -4.031075162825926],
              [114.702849101034019, -4.031934637319845],
              [114.70265628191386, -4.031738205658204],
              [114.702233680470712, -4.031950267664282],
              [114.701659883454766, -4.032229845112305],
              [114.701011435318819, -4.031875717430617],
              [114.70102454050722, -4.031824652239849],
              [114.700641783885388, -4.031568384857828],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 26001.368,
        Luas: 2.6,
        PERIMETRO: 686.834,
        OWNER: "Mardiah",
        No_Kav: "80",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 9",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.702851520731727, -4.031075159335548],
              [114.703849527686145, -4.030615910238903],
              [114.704927847656705, -4.030601093163405],
              [114.705118611137806, -4.031101948106875],
              [114.703054088499115, -4.032155588467432],
              [114.702849106869039, -4.031934645670563],
              [114.702851520731727, -4.031075159335548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20578.449,
        Luas: 2.058,
        PERIMETRO: 603.705,
        OWNER: "Gazali Rahman",
        No_Kav: "66",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714917212266599, -4.027645353399157],
              [114.715959251262277, -4.026211625049856],
              [114.7167143741693, -4.026784783364873],
              [114.715641879808146, -4.028227105117833],
              [114.714917212266599, -4.027645353399157],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20370.943,
        Luas: 2.037,
        PERIMETRO: 557.937,
        OWNER: "Ayu Reztya W.",
        No_Kav: "26",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 3",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712193908789345, -4.039720126140924],
              [114.712212134828121, -4.039700106690602],
              [114.712237928215302, -4.039680286116898],
              [114.712275433663009, -4.039650329044236],
              [114.712335455012095, -4.039597888295448],
              [114.712428488397322, -4.039511802126994],
              [114.712570776609468, -4.039395490706531],
              [114.713476472787974, -4.039412281458715],
              [114.713472894835249, -4.040689466164226],
              [114.712311138922317, -4.040686182801839],
              [114.712286832607759, -4.040686114089366],
              [114.712133732600364, -4.040685681268188],
              [114.712116353287271, -4.040685632134192],
              [114.712118835094245, -4.039800295497374],
              [114.712166567172346, -4.039751067623961],
              [114.712193908789345, -4.039720126140924],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19623.199,
        Luas: 1.962,
        PERIMETRO: 591.391,
        OWNER: "Sugeng",
        No_Kav: "72",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711223723729276, -4.0285747071765],
              [114.711313599707566, -4.02800054405927],
              [114.711281992694765, -4.027868771040251],
              [114.711158228664829, -4.02784023862529],
              [114.711591921676472, -4.027258690499021],
              [114.711616848815609, -4.027279548821567],
              [114.711866069221941, -4.027483361073027],
              [114.7118219104361, -4.027547253527712],
              [114.711955111136788, -4.027683841226494],
              [114.711954899617936, -4.027759526464384],
              [114.712412449433785, -4.028095095090054],
              [114.712807896189716, -4.028418094416337],
              [114.712424646567456, -4.028949528082563],
              [114.711891768740358, -4.029009835603977],
              [114.711223723729276, -4.0285747071765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10570.863,
        Luas: 1.057,
        PERIMETRO: 467.477,
        OWNER: "A Badri",
        No_Kav: "83",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.698572394037711, -4.042741720661953],
              [114.697204266982524, -4.043392480444637],
              [114.696772627498916, -4.042801279022568],
              [114.697259285022952, -4.042571379826803],
              [114.697855187805345, -4.042552470848775],
              [114.69845974109667, -4.042545395826283],
              [114.698572394037711, -4.042741720661953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 27435.096,
        Luas: 2.744,
        PERIMETRO: 812.678,
        OWNER: "Abdullah HM",
        No_Kav: "88",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.693444628154225, -4.047325404202275],
              [114.693483678769425, -4.047308616342304],
              [114.693671779014863, -4.046989912752231],
              [114.693836004795273, -4.046893593538772],
              [114.694075060837491, -4.046881654008952],
              [114.69427493994705, -4.046922691036504],
              [114.694378401815456, -4.046394035674091],
              [114.694594213001025, -4.046365110285773],
              [114.695080769244839, -4.046684843988631],
              [114.695695690703602, -4.04865400263649],
              [114.695811398586997, -4.048722561274261],
              [114.695248053391154, -4.048390007620693],
              [114.694395667811392, -4.04788682686044],
              [114.694387239059125, -4.047881851188811],
              [114.693444628154225, -4.047325404202275],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15314.415,
        Luas: 1.531,
        PERIMETRO: 629.338,
        OWNER: "Tuparmanto",
        No_Kav: "44a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711635009838517, -4.03285272661894],
              [114.711408227030162, -4.03290024088932],
              [114.71123822691888, -4.032030241432458],
              [114.710488226709444, -4.031930241300195],
              [114.710358228441024, -4.031010241032829],
              [114.711461404918438, -4.031091358630056],
              [114.711635009838517, -4.03285272661894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15314.408,
        Luas: 1.531,
        PERIMETRO: 525.668,
        OWNER: "Sukirman",
        No_Kav: "44b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711878566283445, -4.032801877584753],
              [114.711878436928004, -4.032801725045898],
              [114.711635009838517, -4.03285272661894],
              [114.71146140496819, -4.031091358655216],
              [114.71167582202726, -4.031107124723519],
              [114.712297541048599, -4.031167311989361],
              [114.712368813942021, -4.032447605258328],
              [114.711878566283445, -4.032801877584753],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.995,
        Luas: 2.0,
        PERIMETRO: 639.37,
        OWNER: "M Yusuf",
        No_Kav: "91a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.696108224159403, -4.048960240039576],
              [114.696235854041262, -4.048908519186228],
              [114.696626030572389, -4.049100119015505],
              [114.697000675232147, -4.049284091591786],
              [114.695832107374812, -4.050302950017893],
              [114.694969518975071, -4.04997762983795],
              [114.694511387324837, -4.049828416923308],
              [114.695013645220598, -4.049558087036147],
              [114.696108224159403, -4.048960240039576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 593.434,
        OWNER: "M Yusuf",
        No_Kav: "91b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.697000675255026, -4.049284091603367],
              [114.69703408813632, -4.049300499263263],
              [114.697110708034543, -4.049338124167556],
              [114.698000635009919, -4.049775130370572],
              [114.697801746173184, -4.049984209623936],
              [114.697050274288699, -4.050774182562541],
              [114.696051824882346, -4.050385815039934],
              [114.695832107374812, -4.050302950017893],
              [114.697000675255026, -4.049284091603367],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13189.647,
        Luas: 1.319,
        PERIMETRO: 482.945,
        OWNER: "Zaenah",
        No_Kav: "91d",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.698197947101946, -4.048536969583711],
              [114.698360300357095, -4.048610676315091],
              [114.698782994232801, -4.048960931950861],
              [114.698000635009919, -4.049775130370572],
              [114.697017305472457, -4.049292256657703],
              [114.698197947101946, -4.048536969583711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 13189.03,
        Luas: 1.319,
        PERIMETRO: 537.91,
        OWNER: "Zaenah",
        No_Kav: "91c",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.696235854041262, -4.048908519186228],
              [114.697696161896886, -4.048027031891496],
              [114.69782911220689, -4.048100346828387],
              [114.697771357999116, -4.048343302285565],
              [114.698197947101946, -4.048536969583711],
              [114.697017304767755, -4.049292255232282],
              [114.696626030572389, -4.049100119015505],
              [114.696235854041262, -4.048908519186228],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20155.984,
        Luas: 2.016,
        PERIMETRO: 672.804,
        OWNER: "Baharudin",
        No_Kav: "64b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714088552808064, -4.030080921086167],
              [114.714302351299054, -4.030192100990497],
              [114.714437802754546, -4.030225150251175],
              [114.714676768555776, -4.030078818798391],
              [114.714742437382952, -4.029948587991593],
              [114.714834452619385, -4.029856860676491],
              [114.715277667016508, -4.029912245884626],
              [114.715663565496882, -4.030065263789856],
              [114.716075767675846, -4.030011978004163],
              [114.716286348324999, -4.030322914105505],
              [114.715600062101998, -4.030627105069946],
              [114.715054756971711, -4.030852504549364],
              [114.714126730303519, -4.031181564311013],
              [114.714082987969661, -4.03118715840223],
              [114.714088552808064, -4.030080921086167],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20155.993,
        Luas: 2.016,
        PERIMETRO: 563.032,
        OWNER: "Baharudin",
        No_Kav: "64a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713733893563528, -4.02989649035538],
              [114.714088552808064, -4.030080921086167],
              [114.714082987929089, -4.031187158412288],
              [114.713827787165258, -4.031219795444361],
              [114.713524232138965, -4.031197161385546],
              [114.712825044614448, -4.03111896568814],
              [114.712914912136867, -4.030003074479477],
              [114.713012393931791, -4.029604001825501],
              [114.713733893563528, -4.02989649035538],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20060.283,
        Luas: 2.006,
        PERIMETRO: 599.792,
        OWNER: "Maksum",
        No_Kav: "67b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.717096812729238, -4.025001227352494],
              [114.715621441364277, -4.025877294407295],
              [114.714918050326858, -4.025273957492556],
              [114.714890044810005, -4.025247320076355],
              [114.716265597819103, -4.024326895727199],
              [114.717096812729238, -4.025001227352494],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20050.277,
        Luas: 2.005,
        PERIMETRO: 587.832,
        OWNER: "Maksum",
        No_Kav: "67a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714890044810005, -4.025247320076355],
              [114.714173031779083, -4.024565333339584],
              [114.715405608706774, -4.023629219305695],
              [114.716265597795612, -4.024326895715331],
              [114.714890044810005, -4.025247320076355],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14495.369,
        Luas: 1.45,
        PERIMETRO: 491.735,
        OWNER: "H Amat",
        No_Kav: "68b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.713544324430984, -4.026461447643958],
              [114.712543742195663, -4.025595619382395],
              [114.713071654668497, -4.024871589474575],
              [114.714070937154631, -4.025741595392996],
              [114.713544324430984, -4.026461447643958],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14495.086,
        Luas: 1.45,
        PERIMETRO: 493.719,
        OWNER: "H Amat",
        No_Kav: "68a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 8",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.712543742103804, -4.025595619196569],
              [114.711605106496847, -4.024783393751231],
              [114.711968357395193, -4.023969298702971],
              [114.712648798736467, -4.024503437544893],
              [114.713071654668497, -4.024871589474575],
              [114.712543742103804, -4.025595619196569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12916.399,
        Luas: 1.292,
        PERIMETRO: 481.635,
        OWNER: "Badrani",
        No_Kav: "89a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.693485706845408, -4.048502053906283],
              [114.69300752880406, -4.048189048217536],
              [114.692629594497248, -4.04791185479329],
              [114.69290003878082, -4.047674717692356],
              [114.693130079658971, -4.047659745522063],
              [114.693163541143093, -4.047446243419476],
              [114.693444628154225, -4.047325404202275],
              [114.694387239059125, -4.047881851188811],
              [114.693485706845408, -4.048502053906283],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12916.961,
        Luas: 1.292,
        PERIMETRO: 481.343,
        OWNER: "Badrani",
        No_Kav: "89c",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.695301675078085, -4.048421661597118],
              [114.695811398586997, -4.048722561274261],
              [114.69581736079293, -4.048730591648509],
              [114.696108224159403, -4.048960240039576],
              [114.695013645220598, -4.049558087036147],
              [114.694264449525306, -4.049043657364305],
              [114.695301675078085, -4.048421661597118],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 12916.991,
        Luas: 1.292,
        PERIMETRO: 479.471,
        OWNER: "Badrani",
        No_Kav: "89b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.693648978191646, -4.048621047820363],
              [114.693651782942112, -4.048610763877135],
              [114.693485706845408, -4.048502053906283],
              [114.694387239059125, -4.047881851188811],
              [114.695301675041748, -4.048421661569569],
              [114.694264449525306, -4.049043657364305],
              [114.693648978191646, -4.048621047820363],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17855.227,
        Luas: 1.786,
        PERIMETRO: 597.473,
        OWNER: "Habibah",
        No_Kav: "50b",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.715197660787823, -4.030886084184615],
              [114.715808275824372, -4.030657814199151],
              [114.71653376019195, -4.030279729989093],
              [114.717138150129628, -4.031412918598334],
              [114.71663631702306, -4.031411502987143],
              [114.716426098525147, -4.031410911999697],
              [114.716426309540367, -4.031334475546291],
              [114.715854746711528, -4.03178787309044],
              [114.715197660787823, -4.030886084184615],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 17860.002,
        Luas: 1.786,
        PERIMETRO: 540.265,
        OWNER: "Habibah",
        No_Kav: "50a",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 7",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.714099873146367, -4.031302954560192],
              [114.71415154148815, -4.031267922444236],
              [114.714240314131573, -4.03124106389538],
              [114.715065668186696, -4.030935356009511],
              [114.715197660606051, -4.030886084244887],
              [114.715854746711528, -4.03178787309044],
              [114.715783692683033, -4.031844237276981],
              [114.715705563099064, -4.031906214184037],
              [114.715666497427705, -4.03193720151918],
              [114.71566495596781, -4.031938424291658],
              [114.715658629536449, -4.031943442771878],
              [114.7156396981146, -4.031958460341396],
              [114.714985297777247, -4.032477563641727],
              [114.714783143126439, -4.032209380337549],
              [114.714717300634547, -4.03212203376679],
              [114.71470026981396, -4.032099440698961],
              [114.714585388448143, -4.031947039178842],
              [114.714580103757413, -4.031940028511824],
              [114.714272248035215, -4.031531627176667],
              [114.714099873146367, -4.031302954560192],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 8860.007,
        Luas: 0.886,
        PERIMETRO: 459.381,
        OWNER: "Ananda M Tambunan",
        No_Kav: "7",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.711405265875342, -4.044598048758774],
              [114.71111303141295, -4.044704563873596],
              [114.70986497655727, -4.045159459517369],
              [114.70982216950685, -4.044417869498238],
              [114.711405753920033, -4.044423990665404],
              [114.711405265875342, -4.044598048758774],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5270.557,
        Luas: 0.527,
        PERIMETRO: 322.273,
        OWNER: "Ananda M Tambunan",
        No_Kav: "7",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.710386706755571, -4.043253233839557],
              [114.710781059037814, -4.042878311294712],
              [114.710851392951241, -4.042673954199026],
              [114.711331313371687, -4.042686523192533],
              [114.711410606719525, -4.042694698095212],
              [114.711409031817396, -4.043257599117999],
              [114.710386706755571, -4.043253233839557],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20991.296,
        Luas: 2.099,
        PERIMETRO: 576.097,
        OWNER: "Ananda M Tambunan",
        No_Kav: "7",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 2",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.70982216950685, -4.044417869498238],
              [114.70978780970097, -4.043822621826478],
              [114.710386706755571, -4.043253233839557],
              [114.71140903181508, -4.043257599940161],
              [114.711405753920033, -4.044423990665404],
              [114.70982216950685, -4.044417869498238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 25437.793,
        Luas: 2.544,
        PERIMETRO: 636.401,
        OWNER: "Khairul Azzla",
        No_Kav: "84",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.694482690419676, -4.04532765249097],
              [114.695192255750698, -4.044847548996986],
              [114.695324532237677, -4.044757150088749],
              [114.696277802993009, -4.045630753786881],
              [114.695537624246199, -4.046530522868176],
              [114.695173931816043, -4.046239020189572],
              [114.694880495295564, -4.046312993194157],
              [114.694485299994952, -4.046190421014757],
              [114.694348225945902, -4.046140240320373],
              [114.694338224893812, -4.045350241416426],
              [114.694482690419676, -4.04532765249097],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 2193.638,
        Luas: 0.219,
        PERIMETRO: 314.294,
        OWNER: "Khairul Azzla",
        No_Kav: "84",
        KETERANGAN: "Bebas SK-435",
        block: "Blok 10",
        SKT___SHM: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.695324532237677, -4.044757150088749],
              [114.695558092671149, -4.044597532713099],
              [114.69627822303913, -4.04563024317328],
              [114.696277802993009, -4.045630753786881],
              [114.695324532237677, -4.044757150088749],
            ],
          ],
        ],
      },
    },
  ],
};
