export const sabuhur2008 = {
  type: "FeatureCollection",
  name: "sabuhurtt08_152ha_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        ID: 85.0,
        AREA: 10000.007,
        Luas: 1.0,
        PERIMETRO: 400.038,
        block: "BL 7",
        No_Kav: "85",
        Nm_Pemilik: "Muhdi",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81976762415384, -3.99072886208191],
              [114.818962212652011, -3.991150995021632],
              [114.818536813928489, -3.990371167399592],
              [114.819335639620263, -3.989937137501411],
              [114.819647971498171, -3.990509566595969],
              [114.81976762415384, -3.99072886208191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 96.0,
        AREA: 9999.997,
        Luas: 1.0,
        PERIMETRO: 419.467,
        block: "BL 8",
        No_Kav: "96",
        Nm_Pemilik: "Sukarno",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816412416643146, -3.99396869630812],
              [114.816591163300004, -3.992702952237874],
              [114.816713078503838, -3.992741570239492],
              [114.816862042731685, -3.992772504018167],
              [114.817200299538158, -3.992784987458002],
              [114.817201618942534, -3.992785036246083],
              [114.817199442395165, -3.993881115750566],
              [114.816412416643146, -3.99396869630812],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 93.0,
        AREA: 10117.002,
        Luas: 1.012,
        PERIMETRO: 409.449,
        block: "BL 7",
        No_Kav: "93",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816084470772722, -3.992406287914026],
              [114.815467186049972, -3.992199534394212],
              [114.815371783147896, -3.992163946408931],
              [114.815749292872511, -3.991129063521917],
              [114.816451514794124, -3.991372318762827],
              [114.816084470772722, -3.992406287914026],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 92.0,
        AREA: 10667.008,
        Luas: 1.067,
        PERIMETRO: 445.413,
        block: "BL 7",
        No_Kav: "92",
        Nm_Pemilik: "Hj Mas Amah",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817100165919101, -3.992699193493057],
              [114.816878094120398, -3.992672104625623],
              [114.816084470772722, -3.992406287914026],
              [114.816451514794124, -3.991372318762827],
              [114.816775876177701, -3.991484677198413],
              [114.816822937554448, -3.991808277570299],
              [114.816980820440307, -3.991783961298478],
              [114.817191809454414, -3.992699840952893],
              [114.817100165919101, -3.992699193493057],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 91.0,
        AREA: 9715.999,
        Luas: 0.972,
        PERIMETRO: 396.625,
        block: "BL 7",
        No_Kav: "91",
        Nm_Pemilik: "Norhayati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81797360836083, -3.992672625566161],
              [114.817463791354442, -3.992701746894567],
              [114.817191809454414, -3.992699840952893],
              [114.816980820440307, -3.991783961298478],
              [114.817797575418808, -3.991658167220926],
              [114.81797360836083, -3.992672625566161],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 90.0,
        AREA: 10084.01,
        Luas: 1.008,
        PERIMETRO: 402.85,
        block: "BL 7",
        No_Kav: "90",
        Nm_Pemilik: "Supiani Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818753548707377, -3.992594964091565],
              [114.818247625319799, -3.992656973075374],
              [114.817973611769645, -3.992672623601131],
              [114.817797575418808, -3.991658167220926],
              [114.818290284438348, -3.991582280267214],
              [114.818575618611263, -3.991573103636986],
              [114.818753548707377, -3.992594964091565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 89.0,
        AREA: 10223.007,
        Luas: 1.022,
        PERIMETRO: 406.756,
        block: "BL 7",
        No_Kav: "89",
        Nm_Pemilik: "Sridawati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819558227990214, -3.992496337203267],
              [114.818753548707377, -3.992594964091565],
              [114.818575618611263, -3.991573103636986],
              [114.819438680504348, -3.991545347754306],
              [114.819558227942153, -3.992496335861666],
              [114.819558227990214, -3.992496337203267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 88.0,
        AREA: 10271.999,
        Luas: 1.027,
        PERIMETRO: 410.985,
        block: "BL 7",
        No_Kav: "88",
        Nm_Pemilik: "Parijal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820563419400685, -3.992388970969431],
              [114.820425246034418, -3.992398811902636],
              [114.820357982304785, -3.992398313504057],
              [114.819558227942153, -3.992496335861666],
              [114.819438680504348, -3.991545347754306],
              [114.820251424378412, -3.99151920787926],
              [114.820563419400685, -3.992388970969431],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 84.0,
        AREA: 10203.997,
        Luas: 1.02,
        PERIMETRO: 510.721,
        block: "BL 6",
        No_Kav: "84",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819647971498171, -3.990509566595969],
              [114.819335639620263, -3.989937137501411],
              [114.819767685299951, -3.989702396195344],
              [114.819767688071437, -3.989702394754702],
              [114.819862494647012, -3.989923225666911],
              [114.820959606691247, -3.989455153797021],
              [114.821102571679191, -3.989869592802853],
              [114.82042856031498, -3.990166135043242],
              [114.819647971498171, -3.990509566595969],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 52.0,
        AREA: 10143.999,
        Luas: 1.014,
        PERIMETRO: 426.255,
        block: "BL 6",
        No_Kav: "52",
        Nm_Pemilik: "Faisal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81734080753337, -3.98509277625936],
              [114.817255623984167, -3.984908289824156],
              [114.817259340841829, -3.984467210685124],
              [114.817494232191152, -3.9843650633389],
              [114.817469161680194, -3.984306955547383],
              [114.817395058669206, -3.984129241345445],
              [114.817776788041726, -3.983908355461472],
              [114.817777821248882, -3.98390478356522],
              [114.817978550443101, -3.98380166736857],
              [114.818321338545218, -3.984702081186216],
              [114.818205292693577, -3.984748320058105],
              [114.81734080753337, -3.98509277625936],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 50.0,
        AREA: 13086.999,
        Luas: 1.309,
        PERIMETRO: 446.052,
        block: "BL 4",
        No_Kav: "50",
        Nm_Pemilik: "Sridawati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818873745181747, -3.983340221176443],
              [114.819470364724737, -3.983020753884052],
              [114.819860046068484, -3.98316194397234],
              [114.820156158012878, -3.983970987582055],
              [114.819958457112136, -3.984049763397864],
              [114.819883353290194, -3.984079688560524],
              [114.819218523747793, -3.984344594014876],
              [114.818873745181747, -3.983340221176443],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 51.0,
        AREA: 12217.999,
        Luas: 1.222,
        PERIMETRO: 443.153,
        block: "BL 4",
        No_Kav: "51",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817978550705618, -3.983801667369268],
              [114.818873744385115, -3.983340220452915],
              [114.819218523371291, -3.984344594164884],
              [114.819091080631694, -3.984395374418213],
              [114.819068001003458, -3.984404570324588],
              [114.818321337613696, -3.984702079897632],
              [114.817978550705618, -3.983801667369268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 49.0,
        AREA: 12800.002,
        Luas: 1.28,
        PERIMETRO: 681.29,
        block: "BL 4",
        No_Kav: "49",
        Nm_Pemilik: "Parijal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820156158012878, -3.983970987582055],
              [114.819860047407346, -3.983161945638054],
              [114.81993856687761, -3.983190397789012],
              [114.819938642784493, -3.983190425294481],
              [114.820299343265503, -3.983338163492968],
              [114.820906198946673, -3.983102289349083],
              [114.822247939068987, -3.982997898340629],
              [114.822514752356341, -3.983036899761845],
              [114.820803258327203, -3.983714713741064],
              [114.820156158012878, -3.983970987582055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 58.0,
        AREA: 10142.999,
        Luas: 1.014,
        PERIMETRO: 402.968,
        block: "BL 6",
        No_Kav: "58",
        Nm_Pemilik: "Khairul Muslim",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817709955732496, -3.985917569891182],
              [114.81734080753337, -3.98509277625936],
              [114.818205292693577, -3.984748320058105],
              [114.818547198116562, -3.98557788014053],
              [114.817709955732496, -3.985917569891182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 57.0,
        AREA: 10300.002,
        Luas: 1.03,
        PERIMETRO: 406.121,
        block: "BL 6",
        No_Kav: "57",
        Nm_Pemilik: "Hj Mas Amah",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818205292693577, -3.984748320058105],
              [114.819091080631694, -3.984395374418213],
              [114.819400697786989, -3.985231592268108],
              [114.818615519197138, -3.985550160543094],
              [114.818580642213519, -3.985564310993485],
              [114.818547198116562, -3.98557788014053],
              [114.818205292693577, -3.984748320058105],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 56.0,
        AREA: 10300.008,
        Luas: 1.03,
        PERIMETRO: 406.191,
        block: "BL 6",
        No_Kav: "56",
        Nm_Pemilik: "Supiani Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819091080631694, -3.984395374418213],
              [114.819958455122688, -3.984049763855703],
              [114.820284078334382, -3.984873179926665],
              [114.820194539208941, -3.98490950956762],
              [114.819482818067584, -3.985198273904553],
              [114.81943016160271, -3.985219638031687],
              [114.819401050533571, -3.985231449149644],
              [114.819400697786989, -3.985231592268108],
              [114.819091080631694, -3.984395374418213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 55.0,
        AREA: 10300.006,
        Luas: 1.03,
        PERIMETRO: 406.238,
        block: "BL 6",
        No_Kav: "55",
        Nm_Pemilik: "Khairunnisa",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819958457112136, -3.984049763397864],
              [114.820156158012878, -3.983970987582055],
              [114.820835436782829, -3.983701975337844],
              [114.821171384418136, -3.984513172743304],
              [114.821152244684683, -3.984520939929197],
              [114.821130338658236, -3.98452982832577],
              [114.820344219440486, -3.98484877959685],
              [114.820313066410137, -3.984861418672443],
              [114.820284078334382, -3.984873179926665],
              [114.819958457112136, -3.984049763397864],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 54.0,
        AREA: 10299.997,
        Luas: 1.03,
        PERIMETRO: 406.496,
        block: "BL 4",
        No_Kav: "54",
        Nm_Pemilik: "Khairul Muslim",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820835439511214, -3.98370197534509],
              [114.821718745527392, -3.98335214957092],
              [114.822068522595657, -3.98414917861549],
              [114.822060256533092, -3.984152532407223],
              [114.822025207251883, -3.984166752959802],
              [114.821171384418136, -3.984513172743304],
              [114.820835439511214, -3.98370197534509],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 53.0,
        AREA: 12660.797,
        Luas: 1.266,
        PERIMETRO: 447.069,
        block: "BL 4",
        No_Kav: "53",
        Nm_Pemilik: "Sridawati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821718745666018, -3.983352149148089],
              [114.822514752356341, -3.983036899761845],
              [114.823059131536539, -3.98311647735761],
              [114.823068221852211, -3.983140251554026],
              [114.82313264812376, -3.983717429581748],
              [114.822068522795291, -3.984149178534503],
              [114.821718745666018, -3.983352149148089],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 64.0,
        AREA: 10290.003,
        Luas: 1.029,
        PERIMETRO: 403.579,
        block: "BL 6",
        No_Kav: "64",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8178547042845, -3.986258795736195],
              [114.817709955732496, -3.985917569891182],
              [114.818547198116562, -3.98557788014053],
              [114.818615519197138, -3.985550160543094],
              [114.818938988290427, -3.986381421328963],
              [114.81891404174489, -3.986391210094422],
              [114.818116171645954, -3.986704261864535],
              [114.8178547042845, -3.986258795736195],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 63.0,
        AREA: 10290.012,
        Luas: 1.029,
        PERIMETRO: 405.861,
        block: "BL 6",
        No_Kav: "63",
        Nm_Pemilik: "Ibrahim Nazar",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818615519197138, -3.985550160543094],
              [114.819400697786989, -3.985231592268108],
              [114.819401050533571, -3.985231449149644],
              [114.819482818067584, -3.985198273904553],
              [114.819805190593698, -3.986041556904049],
              [114.81977563391591, -3.986053154450578],
              [114.819116459168583, -3.986311788838762],
              [114.818938988290427, -3.986381421328963],
              [114.818615519197138, -3.985550160543094],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 62.0,
        AREA: 10290.006,
        Luas: 1.029,
        PERIMETRO: 405.825,
        block: "BL 6",
        No_Kav: "62",
        Nm_Pemilik: "Nazarudin Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819482818067584, -3.985198273904553],
              [114.820194539208941, -3.98490950956762],
              [114.820284078334382, -3.984873179926665],
              [114.820344219440486, -3.98484877959685],
              [114.820656852644206, -3.985707396945019],
              [114.820643350040399, -3.985712694875486],
              [114.820193036798187, -3.98588938090553],
              [114.819805190593698, -3.986041556904049],
              [114.819482818067584, -3.985198273904553],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 61.0,
        AREA: 10290.003,
        Luas: 1.029,
        PERIMETRO: 405.811,
        block: "BL 6",
        No_Kav: "61",
        Nm_Pemilik: "Norhayati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820344219440486, -3.98484877959685],
              [114.821130338658236, -3.98452982832577],
              [114.821171385005485, -3.984513174169761],
              [114.821523165977041, -3.985367486390011],
              [114.820656865113804, -3.985707384111778],
              [114.820344219440486, -3.98484877959685],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 60.0,
        AREA: 10290.004,
        Luas: 1.029,
        PERIMETRO: 405.817,
        block: "BL 5",
        No_Kav: "60",
        Nm_Pemilik: "Ibrahim Nazar",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821171384418136, -3.984513172743304],
              [114.822007343163818, -3.984174001481256],
              [114.822361533184448, -3.985038540543823],
              [114.821523165977041, -3.985367486390011],
              [114.821171384418136, -3.984513172743304],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 59.0,
        AREA: 11030.796,
        Luas: 1.103,
        PERIMETRO: 443.983,
        block: "BL 5",
        No_Kav: "59",
        Nm_Pemilik: "Supiani Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.822007342575588, -3.984174002206265],
              [114.82313264812376, -3.983717429581748],
              [114.823157944623972, -3.983947148475402],
              [114.823340010586421, -3.984171127073955],
              [114.823238587293432, -3.984216639910278],
              [114.822609562035197, -3.984938194258755],
              [114.822605550026282, -3.984942796691452],
              [114.822605544624366, -3.984942798810986],
              [114.822411866527233, -3.985018791495284],
              [114.822361534767353, -3.985038539950797],
              [114.822007342575588, -3.984174002206265],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 68.0,
        AREA: 13641.006,
        Luas: 1.364,
        PERIMETRO: 471.251,
        block: "BL 6",
        No_Kav: "68",
        Nm_Pemilik: "Supiani Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818714826320075, -3.987524670131314],
              [114.818658284551503, -3.987416073527962],
              [114.818642030720369, -3.98738485417431],
              [114.818588379348796, -3.98740538740209],
              [114.81844299804267, -3.987261080236967],
              [114.818116171645954, -3.986704261864535],
              [114.81891404174489, -3.986391210094422],
              [114.818938988290427, -3.986381421328963],
              [114.819253842757533, -3.986257884998516],
              [114.819630719874013, -3.987157815463623],
              [114.818714826320075, -3.987524670131314],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 67.0,
        AREA: 13512.019,
        Luas: 1.351,
        PERIMETRO: 466.429,
        block: "BL 6",
        No_Kav: "67",
        Nm_Pemilik: "Sridawati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819253862938609, -3.986257887727054],
              [114.81977563391591, -3.986053154450578],
              [114.819805190593698, -3.986041556904049],
              [114.820308329288224, -3.985844144768581],
              [114.820680686662541, -3.98673725735885],
              [114.820656361440868, -3.986747000738095],
              [114.820621021483035, -3.986761155870703],
              [114.819769379035392, -3.987102277268832],
              [114.81970636280019, -3.987127518071645],
              [114.819630720163929, -3.987157815347515],
              [114.819253862938609, -3.986257887727054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 66.0,
        AREA: 13695.024,
        Luas: 1.37,
        PERIMETRO: 470.164,
        block: "BL 6",
        No_Kav: "66",
        Nm_Pemilik: "Faisal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820643350040399, -3.985712694875486],
              [114.821388953862339, -3.985420145317218],
              [114.821750920691969, -3.986308577877862],
              [114.821556866484144, -3.986386305953765],
              [114.820680686766465, -3.986737257317215],
              [114.820308329971482, -3.985844146135934],
              [114.820643350040399, -3.985712694875486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 65.0,
        AREA: 13148.009,
        Luas: 1.315,
        PERIMETRO: 498.991,
        block: "BL 5",
        No_Kav: "65",
        Nm_Pemilik: "Khairunnisa",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821388955285826, -3.985420146295502],
              [114.821523165977041, -3.985367486390011],
              [114.822411866527233, -3.985018791495284],
              [114.822605544624366, -3.984942798810986],
              [114.822423857839226, -3.985158879711347],
              [114.822810586882639, -3.985832598722477],
              [114.822790243857455, -3.98586150936801],
              [114.822760280751098, -3.985904087915288],
              [114.821750920691969, -3.986308577877862],
              [114.821388955285826, -3.985420146295502],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 72.0,
        AREA: 10920.998,
        Luas: 1.092,
        PERIMETRO: 420.186,
        block: "BL 6",
        No_Kav: "72",
        Nm_Pemilik: "Faisal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819116712555314, -3.988296588802906],
              [114.818714826320075, -3.987524670131314],
              [114.81970636280019, -3.987127518071645],
              [114.820034849105326, -3.987922018435559],
              [114.819116712555314, -3.988296588802906],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 71.0,
        AREA: 10723.005,
        Luas: 1.072,
        PERIMETRO: 415.833,
        block: "BL 6",
        No_Kav: "71",
        Nm_Pemilik: "Hj Mas Amah",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81970636280019, -3.987127518071645],
              [114.820656361440868, -3.986747000738095],
              [114.8209741235639, -3.987538822581955],
              [114.820960544311589, -3.987544362702166],
              [114.820944395619961, -3.987550951059151],
              [114.820915793497207, -3.987562620219543],
              [114.820100095053618, -3.987895400041364],
              [114.820076905098645, -3.987904860849912],
              [114.820034849105326, -3.987922018435559],
              [114.81970636280019, -3.987127518071645],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 70.0,
        AREA: 10778.996,
        Luas: 1.078,
        PERIMETRO: 417.317,
        block: "BL 6",
        No_Kav: "70",
        Nm_Pemilik: "Parijal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820656361440868, -3.986747000738095],
              [114.821618852994021, -3.986361477377808],
              [114.821925394633794, -3.987150731451373],
              [114.82187320271548, -3.987172024287238],
              [114.821860877907369, -3.987177052463163],
              [114.8209741235639, -3.987538822581955],
              [114.820656361440868, -3.986747000738095],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 69.0,
        AREA: 10545.968,
        Luas: 1.055,
        PERIMETRO: 437.751,
        block: "BL 5",
        No_Kav: "69",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821618853101469, -3.986361477385468],
              [114.821750920691969, -3.986308577877862],
              [114.822760272568502, -3.985904098811936],
              [114.822634577795526, -3.986082735257026],
              [114.82274145138463, -3.986475857148232],
              [114.822769443427688, -3.986762446349774],
              [114.82277356912968, -3.986804699252404],
              [114.822773566760318, -3.986804700219054],
              [114.821925394730897, -3.987150731405963],
              [114.821618853101469, -3.986361477385468],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 76.0,
        AREA: 10750.997,
        Luas: 1.075,
        PERIMETRO: 415.212,
        block: "BL 6",
        No_Kav: "76",
        Nm_Pemilik: "Khairul Muslim",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81954784935003, -3.989124689248024],
              [114.819440603099665, -3.988918699291856],
              [114.819116712555314, -3.988296588802906],
              [114.820034849105326, -3.987922018435559],
              [114.820378017677683, -3.988774811186888],
              [114.81954784935003, -3.989124689248024],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 75.0,
        AREA: 10641.005,
        Luas: 1.064,
        PERIMETRO: 412.72,
        block: "BL 6",
        No_Kav: "75",
        Nm_Pemilik: "H Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820034849105326, -3.987922018435559],
              [114.820100095053618, -3.987895400041364],
              [114.820915793497207, -3.987562620219543],
              [114.821247925337786, -3.988408176694962],
              [114.821247927310026, -3.988408180543144],
              [114.82046212332196, -3.988739364594116],
              [114.820447450004139, -3.988745548745406],
              [114.820378017677683, -3.988774811186888],
              [114.820034849105326, -3.987922018435559],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 74.0,
        AREA: 11031.999,
        Luas: 1.103,
        PERIMETRO: 420.697,
        block: "BL 6",
        No_Kav: "74",
        Nm_Pemilik: "Caturiana Listanti",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820915793497207, -3.987562620219543],
              [114.8209741235639, -3.987538822581955],
              [114.821838500680144, -3.98718618180644],
              [114.822162879503907, -3.988022568737519],
              [114.821333430851865, -3.988372141132852],
              [114.821247925337786, -3.988408176694962],
              [114.820915793497207, -3.987562620219543],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 73.0,
        AREA: 11167.947,
        Luas: 1.117,
        PERIMETRO: 425.679,
        block: "BL 5",
        No_Kav: "73",
        Nm_Pemilik: "Parijal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821838501488685, -3.987186182683254],
              [114.82187320271548, -3.987172024287238],
              [114.822773566760318, -3.986804700219054],
              [114.82277826580372, -3.986852787601281],
              [114.823084706487421, -3.98752609143722],
              [114.823125935596948, -3.987616679412886],
              [114.822162879575743, -3.98802256869738],
              [114.822162878654169, -3.988022566246442],
              [114.821838501488685, -3.987186182683254],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 80.0,
        AREA: 10145.006,
        Luas: 1.015,
        PERIMETRO: 414.683,
        block: "BL 6",
        No_Kav: "80",
        Nm_Pemilik: "Sridawati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819862494647012, -3.989923225666911],
              [114.819767685299951, -3.989702396195344],
              [114.819821309188939, -3.989674521976498],
              [114.819758760527847, -3.98952979709791],
              [114.81954784935003, -3.989124689248024],
              [114.820378017677683, -3.988774811186888],
              [114.820447450004139, -3.988745548745406],
              [114.820777913692524, -3.989532666654446],
              [114.820727542976357, -3.989554157172139],
              [114.820564689318786, -3.98962363817077],
              [114.819862494647012, -3.989923225666911],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 79.0,
        AREA: 10038.01,
        Luas: 1.004,
        PERIMETRO: 401.508,
        block: "BL 6",
        No_Kav: "79",
        Nm_Pemilik: "Norhayati Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820447450004139, -3.988745548745406],
              [114.821333430851865, -3.988372141132852],
              [114.821658265116795, -3.989157066956445],
              [114.821618597027026, -3.989173991786495],
              [114.821376218098379, -3.989277402129781],
              [114.820777913692524, -3.989532666654446],
              [114.820447450004139, -3.988745548745406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 78.0,
        AREA: 10168.0,
        Luas: 1.017,
        PERIMETRO: 404.409,
        block: "BL 5",
        No_Kav: "78",
        Nm_Pemilik: "Supiani Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821333430851865, -3.988372141132852],
              [114.822230224653694, -3.987994185609281],
              [114.822559665204338, -3.988772486099945],
              [114.822538275993622, -3.98878161179015],
              [114.822270301169084, -3.988895942964185],
              [114.821658265116795, -3.989157066956445],
              [114.821333430851865, -3.988372141132852],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 77.0,
        AREA: 10185.031,
        Luas: 1.019,
        PERIMETRO: 404.966,
        block: "BL 5",
        No_Kav: "77",
        Nm_Pemilik: "Hj Mas Amah",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.822230224675806, -3.987994185543247],
              [114.823125935596948, -3.987616679412886],
              [114.823474361369236, -3.988382230832238],
              [114.822559665937234, -3.988772485787254],
              [114.822230224675806, -3.987994185543247],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 81.0,
        AREA: 13399.996,
        Luas: 1.34,
        PERIMETRO: 508.13,
        block: "BL 5",
        No_Kav: "81",
        Nm_Pemilik: "Khairul Muslim",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.823286475621899, -3.988931992725968],
              [114.823183104894767, -3.989611468514635],
              [114.822713873052919, -3.989973075817131],
              [114.822629362563461, -3.990038202647964],
              [114.82221335904562, -3.988920237241314],
              [114.822270301169084, -3.988895942964185],
              [114.822538275993622, -3.98878161179015],
              [114.823474361369236, -3.988382230832238],
              [114.823538630802872, -3.988523441846862],
              [114.823286475621899, -3.988931992725968],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 83.0,
        AREA: 10880.001,
        Luas: 1.088,
        PERIMETRO: 436.161,
        block: "BL 6",
        No_Kav: "83",
        Nm_Pemilik: "Parijal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.822007143175597, -3.990342063861773],
              [114.82146488761245, -3.990728488620619],
              [114.821374868340868, -3.990658990698023],
              [114.821374864729052, -3.990658991682286],
              [114.821102571697239, -3.989869592801553],
              [114.820959613682646, -3.989455145882586],
              [114.821563574828446, -3.989197466056167],
              [114.822007143175597, -3.990342063861773],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 82.0,
        AREA: 10234.996,
        Luas: 1.023,
        PERIMETRO: 426.633,
        block: "BL 5",
        No_Kav: "82",
        Nm_Pemilik: "Faisal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.82262936251675, -3.99003820268699],
              [114.822506125900574, -3.990133173162594],
              [114.822310665855184, -3.990125765570273],
              [114.822007143150131, -3.990342063786032],
              [114.821563577940651, -3.98919746558254],
              [114.822213359137351, -3.988920238065742],
              [114.82262936251675, -3.99003820268699],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 87.0,
        AREA: 13956.998,
        Luas: 1.396,
        PERIMETRO: 617.76,
        block: "BL 7",
        No_Kav: "87",
        Nm_Pemilik: "Faisal Ahmad",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821004948300327, -3.992357524741472],
              [114.820563419400685, -3.992388970969431],
              [114.820251424378412, -3.99151920787926],
              [114.82004263805527, -3.991135770370044],
              [114.819808518797544, -3.990707429738918],
              [114.81976762415384, -3.99072886208191],
              [114.819647971498171, -3.990509566595969],
              [114.820324625941339, -3.99021186260805],
              [114.821004948300327, -3.992357524741472],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 86.0,
        AREA: 22205.727,
        Luas: 1.521,
        PERIMETRO: 684.374,
        block: "BL 6",
        No_Kav: "86",
        Nm_Pemilik: "Khairul Muslim",
        CPCL_BANK: null,
        KET: "SHM",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821374864729052, -3.990658991682286],
              [114.821577637102592, -3.992316736315484],
              [114.821004947849701, -3.992357524302916],
              [114.820324625993692, -3.990211862557488],
              [114.821102571697239, -3.989869592801553],
              [114.821374864729052, -3.990658991682286],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10267.255,
        Luas: 1.027,
        PERIMETRO: 417.221,
        block: "BL 7",
        No_Kav: "97",
        Nm_Pemilik: "Guntara S. Ir.",
        CPCL_BANK: "Guntara S. Ir.",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815199433628734, -3.992188999686363],
              [114.814667701393617, -3.991986903133275],
              [114.814670628183563, -3.990880369118246],
              [114.815606488253067, -3.991149986147001],
              [114.815199433628734, -3.992188999686363],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 3797.638,
        Luas: 0.38,
        PERIMETRO: 380.016,
        block: "BL 2",
        No_Kav: "27",
        Nm_Pemilik: "Suparno",
        CPCL_BANK: "Asmuni",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.812403298377063, -3.978038724101927],
              [114.8125317045873, -3.978192493400357],
              [114.812236413948497, -3.978123040587065],
              [114.811999800575123, -3.978026767630239],
              [114.811782132140522, -3.978038603386253],
              [114.811792382612424, -3.978202634077903],
              [114.811620209638122, -3.978093351002514],
              [114.811524217497762, -3.977984331546407],
              [114.811265762022813, -3.977951827684663],
              [114.811494192985691, -3.977652433086674],
              [114.811487341029775, -3.977517047128652],
              [114.811599664762511, -3.977675206522403],
              [114.81172735105109, -3.977608962541204],
              [114.8117372566971, -3.977673698662838],
              [114.812403298377063, -3.978038724101927],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20347.578,
        Luas: 2.035,
        PERIMETRO: 636.032,
        block: "BL 7",
        No_Kav: "98",
        Nm_Pemilik: "Guntara S. Ir.",
        CPCL_BANK: "Jaluh",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.814667701393617, -3.991986903133275],
              [114.814622259659629, -3.991969631984083],
              [114.814191284857031, -3.991785039840026],
              [114.813369880924583, -3.991425325547892],
              [114.813277297441218, -3.991378801421974],
              [114.813280666877461, -3.990105204572869],
              [114.813381135637044, -3.990064560616412],
              [114.81384834095293, -3.990211313466806],
              [114.814008923559911, -3.990758615943775],
              [114.814670628183563, -3.990880369118246],
              [114.814667701393617, -3.991986903133275],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.03,
        Luas: 2.0,
        PERIMETRO: 615.51,
        block: "BL 7",
        No_Kav: "103",
        Nm_Pemilik: "Hartejo",
        CPCL_BANK: "Hartejo",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.807489513269857, -3.991430415359955],
              [114.806769511329847, -3.991764223748723],
              [114.806039407985679, -3.990006602725088],
              [114.806193114351544, -3.989593002560998],
              [114.806916711187426, -3.989903256096039],
              [114.807489513269857, -3.991430415359955],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.043,
        Luas: 2.0,
        PERIMETRO: 750.527,
        block: "BL 7",
        No_Kav: "102",
        Nm_Pemilik: "Halisah",
        CPCL_BANK: "Halisah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808277511594042, -3.991233074722392],
              [114.807865220170754, -3.991294850761215],
              [114.807503506100403, -3.991424063566201],
              [114.807489513269857, -3.991430415359955],
              [114.806916711187426, -3.989903256096039],
              [114.807463796052019, -3.990137740085743],
              [114.80757637453975, -3.989964813366831],
              [114.808002972226149, -3.988883151305088],
              [114.808277511594042, -3.991233074722392],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.062,
        Luas: 2.0,
        PERIMETRO: 685.841,
        block: "BL 7",
        No_Kav: "101",
        Nm_Pemilik: "Munziri Taufik",
        CPCL_BANK: "Munziri Taufik",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808953044878251, -3.991131461929159],
              [114.80827751601511, -3.991233072517188],
              [114.808002972226149, -3.988883151305088],
              [114.808014864933597, -3.988852996609933],
              [114.80804564781883, -3.988627882549281],
              [114.808112997872001, -3.988590847990666],
              [114.808802654770645, -3.989366125600152],
              [114.808953044878251, -3.991131461929159],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 10159.844,
        Luas: 1.016,
        PERIMETRO: 488.614,
        block: "BL 7",
        No_Kav: "100",
        Nm_Pemilik: "Sahidah",
        CPCL_BANK: "Sahidah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.808113002464111, -3.988590848002946],
              [114.808732307601119, -3.988248616548016],
              [114.809015459469705, -3.988799372488628],
              [114.80952907811043, -3.988627516961616],
              [114.809808652671308, -3.988901098334695],
              [114.808802654770645, -3.989366125600152],
              [114.808113002464111, -3.988590848002946],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20159.034,
        Luas: 2.016,
        PERIMETRO: 697.98,
        block: "BL 7",
        No_Kav: "99",
        Nm_Pemilik: "Hikmatullah",
        CPCL_BANK: "Hikmatullah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.813277296437946, -3.991378800601399],
              [114.811832379787546, -3.990697344040769],
              [114.811518503059261, -3.989882532397001],
              [114.811473985667689, -3.989625194323577],
              [114.811666439499788, -3.989489533330025],
              [114.811820597100777, -3.989592076088834],
              [114.812030351621843, -3.990035204557787],
              [114.812240987440873, -3.990145463384811],
              [114.812263776921924, -3.990073654120814],
              [114.812907775254942, -3.990105634229395],
              [114.813065709762625, -3.990204404341106],
              [114.813280666667055, -3.990105204583807],
              [114.813277296437946, -3.991378800601399],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.446,
        Luas: 2.0,
        PERIMETRO: 669.235,
        block: "BL 2",
        No_Kav: "23",
        Nm_Pemilik: "Sri Pamuji Rahayu",
        CPCL_BANK: "Sri Pamuji Rahayu",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.814225422774797, -3.976080817009671],
              [114.814206929059736, -3.977147159582348],
              [114.814255172676226, -3.977793441283612],
              [114.814160952851282, -3.977831028493891],
              [114.813716594712545, -3.978008296160226],
              [114.813303199742336, -3.978375697017964],
              [114.81312867355679, -3.97840608049031],
              [114.813154978511378, -3.978034814675062],
              [114.813171354472104, -3.977587220548941],
              [114.813318992684955, -3.977302752908218],
              [114.813345136287026, -3.976992528095035],
              [114.813626361905463, -3.976695572417662],
              [114.813650341021969, -3.976499920782213],
              [114.813757230285546, -3.976301820016908],
              [114.81372740308791, -3.976088095365669],
              [114.813718860292965, -3.976079470429222],
              [114.814225422774797, -3.976080817009671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.997,
        Luas: 2.0,
        PERIMETRO: 770.259,
        block: "BL 2",
        No_Kav: "24",
        Nm_Pemilik: "Taufik Nurcahyo",
        CPCL_BANK: "Sarwani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.814257853597752, -3.974210753112656],
              [114.814225422774797, -3.976080817009671],
              [114.813718860292965, -3.976079470429222],
              [114.813626370076946, -3.975986090881365],
              [114.813806141283138, -3.975829682108662],
              [114.813814301626863, -3.975630362474961],
              [114.813814182454095, -3.97563031580539],
              [114.813659864653758, -3.975570532723746],
              [114.813658519341217, -3.975577701600549],
              [114.812502924917112, -3.97544425136124],
              [114.812574910005651, -3.975355555675149],
              [114.813293034964673, -3.974852488718198],
              [114.813769917684496, -3.974366164896255],
              [114.81378635802966, -3.974357170350314],
              [114.814022634100326, -3.974229372887609],
              [114.814419697562101, -3.974014448896397],
              [114.814257853597752, -3.974210753112656],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 15891.779,
        Luas: 1.589,
        PERIMETRO: 580.192,
        block: "BL 2",
        No_Kav: "26",
        Nm_Pemilik: "Suparno",
        CPCL_BANK: "Asmuni",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.813287694476003, -3.976751945679843],
              [114.813250088399514, -3.976803826146167],
              [114.813085719386123, -3.976891558604524],
              [114.813065833168451, -3.97726647147545],
              [114.812974514576638, -3.97732218366605],
              [114.812805717526558, -3.977533297181715],
              [114.812799627355417, -3.97754439614441],
              [114.812718356275454, -3.977650902223538],
              [114.812841192092449, -3.97776364780614],
              [114.812832908415331, -3.978074651622216],
              [114.812755327378596, -3.978296830605745],
              [114.812596448507236, -3.978125707445096],
              [114.812543430936884, -3.978058428431099],
              [114.811981168949103, -3.977769105386194],
              [114.81177251926033, -3.977657911159541],
              [114.811759649887875, -3.977584974365969],
              [114.811992707946388, -3.977007718377972],
              [114.812030599126501, -3.976937187961464],
              [114.812072105526482, -3.976751234923394],
              [114.813287694476003, -3.976751945679843],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 580.957,
        block: "BL 2",
        No_Kav: "25",
        Nm_Pemilik: "Suparno",
        CPCL_BANK: "Rafik",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.813658519341217, -3.975577701600549],
              [114.813634698503293, -3.975704637592993],
              [114.813421848694276, -3.976083587247402],
              [114.813623831868483, -3.976206798569343],
              [114.813612033180348, -3.976339020059621],
              [114.813476122312665, -3.976491994848282],
              [114.813287694476003, -3.976751945679843],
              [114.812072105617275, -3.976751234742233],
              [114.812311069577959, -3.975680643317177],
              [114.812499536853807, -3.975448425799313],
              [114.812502924917112, -3.97544425136124],
              [114.813658519341217, -3.975577701600549],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.997,
        Luas: 2.0,
        PERIMETRO: 621.955,
        block: "BL 1",
        No_Kav: "12",
        Nm_Pemilik: "Taufik Hidayat",
        CPCL_BANK: "Asmuni",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815138067471182, -3.97580869326696],
              [114.814279585757347, -3.97580817205545],
              [114.814300936389003, -3.97422614107239],
              [114.814452530320935, -3.974016265934379],
              [114.815145842053838, -3.973668138064098],
              [114.815138067471182, -3.97580869326696],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 670.197,
        block: "BL 1",
        No_Kav: "11",
        Nm_Pemilik: "Yuniar Riani",
        CPCL_BANK: "Jainah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815886260825664, -3.975809146832822],
              [114.815138067471182, -3.97580869326696],
              [114.815145842053838, -3.973668138064098],
              [114.815182330080802, -3.973649816551164],
              [114.815361317852108, -3.973659121101142],
              [114.815454706542738, -3.973701905854927],
              [114.815692282149598, -3.973618263030751],
              [114.815892193115388, -3.973469253933001],
              [114.815886260825664, -3.975809146832822],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.043,
        Luas: 2.0,
        PERIMETRO: 726.672,
        block: "BL 1",
        No_Kav: "10",
        Nm_Pemilik: "Yuniar Riani",
        CPCL_BANK: "Jainah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816517253888918, -3.97580952881322],
              [114.815886260825664, -3.975809146832822],
              [114.815892193115388, -3.973469253933001],
              [114.816404185615355, -3.973087625050433],
              [114.816526295471789, -3.973003095100637],
              [114.816517253888918, -3.97580952881322],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.014,
        Luas: 2.0,
        PERIMETRO: 791.184,
        block: "BL 1",
        No_Kav: "9",
        Nm_Pemilik: "Sumadi",
        CPCL_BANK: "Sumadi",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817063556777413, -3.975809858799851],
              [114.816517253888918, -3.97580952881322],
              [114.816526295471789, -3.973003095100637],
              [114.816712621770819, -3.972874110825338],
              [114.817071764397369, -3.972659430920635],
              [114.817063556777413, -3.975809858799851],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.011,
        Luas: 2.0,
        PERIMETRO: 833.45,
        block: "BL 1",
        No_Kav: "8",
        Nm_Pemilik: "Sumadi",
        CPCL_BANK: "Sumadi",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817558193143739, -3.975810157796164],
              [114.817063556777413, -3.975809858799851],
              [114.817071764397369, -3.972659430920635],
              [114.817080791605065, -3.972654034744352],
              [114.81718322305224, -3.972555201782828],
              [114.81729533964014, -3.972488049466488],
              [114.817567180495672, -3.972480152145659],
              [114.817558193143739, -3.975810157796164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.019,
        Luas: 2.0,
        PERIMETRO: 846.522,
        block: "BL 1",
        No_Kav: "7",
        Nm_Pemilik: "Riyadi ,Drs",
        CPCL_BANK: "Dahlia",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818045506922616, -3.975810451908325],
              [114.817558193143739, -3.975810157796164],
              [114.817567180495672, -3.972480152145659],
              [114.818004844964278, -3.972467437086132],
              [114.818055320398997, -3.972467484870287],
              [114.818045506922616, -3.975810451908325],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 847.728,
        block: "BL 1",
        No_Kav: "6",
        Nm_Pemilik: "Naimah",
        CPCL_BANK: "Halisah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818532911120826, -3.975810745734749],
              [114.818045506922616, -3.975810451908325],
              [114.818055320398997, -3.972467484870287],
              [114.818541664276594, -3.972467943723325],
              [114.818536482620971, -3.974447144947379],
              [114.818532911120826, -3.975810745734749],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 7301.127,
        Luas: 0.73,
        PERIMETRO: 553.206,
        block: "BL 1",
        No_Kav: "1",
        Nm_Pemilik: "Yustika Qasthari H",
        CPCL_BANK: "Yustika Qasthari H",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820246440961014, -3.973061647612723],
              [114.820439153975784, -3.973515676787711],
              [114.8204791459289, -3.973638250016416],
              [114.820660499843754, -3.974677053375235],
              [114.820656233161742, -3.974769965970137],
              [114.820279765930707, -3.975410320591262],
              [114.820240314131496, -3.975473975977029],
              [114.820243202927401, -3.974336754964148],
              [114.820246440961014, -3.973061647612723],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 687.228,
        block: "BL 1",
        No_Kav: "5",
        Nm_Pemilik: "Fajar Gigih Wibowo",
        CPCL_BANK: "Fajar Gigih Wibowo",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819341030034025, -3.976678090758343],
              [114.81922227760424, -3.976721102160696],
              [114.818927130043079, -3.976695146432375],
              [114.818804529647068, -3.976717197075903],
              [114.818759866091469, -3.976756236359051],
              [114.818765051808654, -3.976396483520197],
              [114.818773493500302, -3.975810890696185],
              [114.818532911120826, -3.975810745734749],
              [114.818536492691379, -3.974443388251768],
              [114.819348109927788, -3.97444554541402],
              [114.819348102356301, -3.974449296664975],
              [114.819343599533468, -3.976677160064233],
              [114.819341030034025, -3.976678090758343],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 625.982,
        block: "BL 1",
        No_Kav: "4",
        Nm_Pemilik: "Fajar Gigih Wibowo",
        CPCL_BANK: "Fajar Gigih Wibowo",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818536492692473, -3.974443387838257],
              [114.818541664276594, -3.972467943723325],
              [114.818640025528907, -3.972468036406257],
              [114.819226420224268, -3.97241181964019],
              [114.819352251659524, -3.972394896837135],
              [114.819348321735859, -3.974340712421079],
              [114.819348109927802, -3.974445545403649],
              [114.818536492692473, -3.974443387838257],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 652.496,
        block: "BL 1",
        No_Kav: "3",
        Nm_Pemilik: "Yustika",
        CPCL_BANK: "Jasmiah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819667087802685, -3.976398875849297],
              [114.819347911663684, -3.976674110475018],
              [114.819344937032895, -3.976676675560788],
              [114.819343599533468, -3.976677160064233],
              [114.819348102356301, -3.974449296664975],
              [114.819348337142259, -3.974333424787931],
              [114.820243207502898, -3.97433494099073],
              [114.820240314131496, -3.975473975977029],
              [114.819667087802685, -3.976398875849297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.001,
        Luas: 2.0,
        PERIMETRO: 599.251,
        block: "BL 1",
        No_Kav: "2",
        Nm_Pemilik: "Tri Mulyono",
        CPCL_BANK: "Dahlia",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.81934833751842, -3.974333423664458],
              [114.819352251659524, -3.972394896837135],
              [114.819790776624174, -3.972335919924175],
              [114.820147084420014, -3.972827564068772],
              [114.820246440961014, -3.973061647612723],
              [114.820243207503026, -3.974334940939809],
              [114.81934833751842, -3.974333423664458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 14099.926,
        Luas: 1.41,
        PERIMETRO: 561.244,
        block: "BL 2",
        No_Kav: "13",
        Nm_Pemilik: "Yustika Qasthari H",
        CPCL_BANK: "Yustika Qasthari H",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819690470994914, -3.976466449483372],
              [114.819696069488842, -3.976461583364882],
              [114.820720905014497, -3.974773789562892],
              [114.821030356201675, -3.976384496126494],
              [114.821046769527371, -3.976469924765926],
              [114.819690470994914, -3.976466449483372],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 631.881,
        block: "BL 2",
        No_Kav: "15",
        Nm_Pemilik: "Sri Lestari",
        CPCL_BANK: "Sri Lestari",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820341046432219, -3.977410067770246],
              [114.820237015540926, -3.977532936983152],
              [114.820078231647031, -3.977720246712325],
              [114.820509433244226, -3.978491170581903],
              [114.818762936877903, -3.97848251944554],
              [114.818757579804839, -3.977405877623436],
              [114.820341046432219, -3.977410067770246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.995,
        Luas: 2.0,
        PERIMETRO: 639.58,
        block: "BL 2",
        No_Kav: "14",
        Nm_Pemilik: "Sri Lestari",
        CPCL_BANK: "Sri Lestari",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821063463613712, -3.976556811304635],
              [114.820342425637335, -3.97740843901452],
              [114.820341046556209, -3.977410067522857],
              [114.818757579805791, -3.977405877259347],
              [114.818757571511199, -3.977404234378288],
              [114.818755099788774, -3.976908243383785],
              [114.818833263951433, -3.976778654919225],
              [114.818926432053814, -3.976756571605053],
              [114.819145955709189, -3.976775297410706],
              [114.819241840814044, -3.976776947388528],
              [114.819369776932902, -3.97674518665043],
              [114.819690470994914, -3.976466449483372],
              [114.821046769527371, -3.976469924765926],
              [114.821055877120955, -3.976517323411333],
              [114.821063463613712, -3.976556811304635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.013,
        Luas: 2.0,
        PERIMETRO: 714.563,
        block: "BL 2",
        No_Kav: "16",
        Nm_Pemilik: "Fitri  Agusyani",
        CPCL_BANK: "Pahriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818078507696967, -3.975880237087381],
              [114.818700883077526, -3.975886218095541],
              [114.818700507213975, -3.978485099223679],
              [114.818071704742252, -3.978484652279079],
              [114.818078507696967, -3.975880237087381],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.011,
        Luas: 2.0,
        PERIMETRO: 715.485,
        block: "BL 2",
        No_Kav: "17",
        Nm_Pemilik: "Suriadi Nual Kamis",
        CPCL_BANK: "Jasmiah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817454282772275, -3.975874328431383],
              [114.817644096753696, -3.975876062110947],
              [114.818078507696967, -3.975880237087381],
              [114.818071704742252, -3.978484652279079],
              [114.817447415057899, -3.97848420838988],
              [114.817454282772275, -3.975874328431383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.006,
        Luas: 2.0,
        PERIMETRO: 716.207,
        block: "BL 2",
        No_Kav: "18",
        Nm_Pemilik: "Puspita Iriani",
        CPCL_BANK: "Sarhani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816831232754566, -3.975868637156573],
              [114.817454282772275, -3.975874328431383],
              [114.817447415057899, -3.97848420838988],
              [114.817044297252252, -3.978483922024649],
              [114.816852936167876, -3.978482245122949],
              [114.81682435167383, -3.978481980232838],
              [114.816831232754566, -3.975868637156573],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 716.525,
        block: "BL 2",
        No_Kav: "19",
        Nm_Pemilik: "Puspita Iriani",
        CPCL_BANK: "Sarhani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8162077601947, -3.975862941649444],
              [114.816831232754566, -3.975868637156573],
              [114.81682435167383, -3.978481980232838],
              [114.816202251589175, -3.978476218235968],
              [114.8162077601947, -3.975862941649444],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.02,
        Luas: 2.0,
        PERIMETRO: 716.517,
        block: "BL 2",
        No_Kav: "20",
        Nm_Pemilik: "Jono",
        CPCL_BANK: "Normin",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.8156250942545, -3.978470872087064],
              [114.815580405257734, -3.978470480556162],
              [114.815583996600651, -3.97585724316064],
              [114.8162077601947, -3.975862941649444],
              [114.816202251589175, -3.978476218235968],
              [114.8156250942545, -3.978470872087064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 21608.298,
        Luas: 2.161,
        PERIMETRO: 719.457,
        block: "BL 2",
        No_Kav: "22",
        Nm_Pemilik: "Darno",
        CPCL_BANK: "Darno",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.814956091325925, -3.978465011330345],
              [114.814385137239299, -3.978460009099151],
              [114.814312592508543, -3.977900645701967],
              [114.814261966307228, -3.977222428998316],
              [114.814251905686078, -3.97714325340884],
              [114.814270695304515, -3.97584524296323],
              [114.814962749772704, -3.975851566822196],
              [114.814956091325925, -3.978465011330345],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 716.53,
        block: "BL 2",
        No_Kav: "21",
        Nm_Pemilik: "Jono",
        CPCL_BANK: "Sarwani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815580405257734, -3.978470480556162],
              [114.814956091346104, -3.978465011325345],
              [114.814962749772704, -3.975851566822196],
              [114.815583996600651, -3.97585724316064],
              [114.815580405257734, -3.978470480556162],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.047,
        Luas: 2.0,
        PERIMETRO: 840.576,
        block: "BL 3",
        No_Kav: "33",
        Nm_Pemilik: "Kaprawi",
        CPCL_BANK: "Kaprawi",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818209089063245, -3.978558478416045],
              [114.818700213171439, -3.978563411977311],
              [114.818671051390197, -3.980970694893908],
              [114.81878777827923, -3.981325487662581],
              [114.818794087128467, -3.981344663712017],
              [114.818811248033285, -3.981584172541512],
              [114.818714128930509, -3.981941036643525],
              [114.818547953256655, -3.981854175120405],
              [114.81820243991308, -3.981673571423544],
              [114.818209089063245, -3.978558478416045],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.015,
        Luas: 2.0,
        PERIMETRO: 787.384,
        block: "BL 3",
        No_Kav: "34",
        Nm_Pemilik: "Saut Slamet Tambunan",
        CPCL_BANK: "HM. Yamani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817662006047527, -3.978552981239655],
              [114.818209089063245, -3.978558478416045],
              [114.81820243991308, -3.981673571423544],
              [114.817655980721185, -3.981387930666983],
              [114.817662006047527, -3.978552981239655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.009,
        Luas: 2.0,
        PERIMETRO: 743.789,
        block: "BL 3",
        No_Kav: "35",
        Nm_Pemilik: "Saut Slamet Tambunan",
        CPCL_BANK: "St Arlena",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817398360108839, -3.981253269735873],
              [114.81714125587456, -3.981189649606323],
              [114.817054542409878, -3.981189276399484],
              [114.81706249700332, -3.978546957364554],
              [114.817662006047527, -3.978552981239655],
              [114.817655980721185, -3.981387930666983],
              [114.817398360108839, -3.981253269735873],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.023,
        Luas: 2.0,
        PERIMETRO: 721.722,
        block: "BL 3",
        No_Kav: "36",
        Nm_Pemilik: "Sujiyem",
        CPCL_BANK: "Hj. Budiah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817054542409878, -3.981189276399484],
              [114.816439113845462, -3.981186627385938],
              [114.816446845431784, -3.978540771887856],
              [114.81706249700332, -3.978546957364554],
              [114.817054542409878, -3.981189276399484],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.996,
        Luas: 2.0,
        PERIMETRO: 722.32,
        block: "BL 3",
        No_Kav: "37",
        Nm_Pemilik: "Sujiyem",
        CPCL_BANK: "Hj. Budiah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816439113845462, -3.981186627385938],
              [114.815822709875405, -3.981183973697872],
              [114.815833814767444, -3.978534610920174],
              [114.816446845431784, -3.978540771887856],
              [114.816439113845462, -3.981186627385938],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19901.137,
        Luas: 1.99,
        PERIMETRO: 711.438,
        block: "BL 3",
        No_Kav: "39",
        Nm_Pemilik: "Maulidah",
        CPCL_BANK: "Mielana Siregar",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815210809412534, -3.98118133896767],
              [114.815129264032123, -3.981180987822162],
              [114.814786554929086, -3.981002784432452],
              [114.814606751884483, -3.98002027228498],
              [114.814457413231509, -3.979010694091652],
              [114.814423242282871, -3.978520432915769],
              [114.815217917913756, -3.978528420370047],
              [114.815210809412534, -3.98118133896767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 722.92,
        block: "BL 3",
        No_Kav: "38",
        Nm_Pemilik: "Mielana Siregar",
        CPCL_BANK: "Maulidah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.815822709856477, -3.981183973697822],
              [114.815210809433054, -3.981181338957451],
              [114.815217918109553, -3.978528420370565],
              [114.815833814767444, -3.978534610920174],
              [114.815822709856477, -3.981183973697822],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 716.374,
        block: "BL 3",
        No_Kav: "32",
        Nm_Pemilik: "Sidik Sasra Wijaya",
        CPCL_BANK: "St Arlena",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819361005909016, -3.981240014714792],
              [114.818834453450819, -3.981237248978426],
              [114.818739305457783, -3.980965141563809],
              [114.818756826529011, -3.978577368950794],
              [114.819367361864764, -3.978581990477365],
              [114.819361005909016, -3.981240014714792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.046,
        Luas: 2.0,
        PERIMETRO: 723.965,
        block: "BL 3",
        No_Kav: "31",
        Nm_Pemilik: "Wagiati",
        CPCL_BANK: "Pahriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819972719871146, -3.981243226668202],
              [114.819361005909016, -3.981240014714792],
              [114.819367361864764, -3.978581990477365],
              [114.819980613789099, -3.978586632452185],
              [114.819972719871146, -3.981243226668202],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.003,
        Luas: 2.0,
        PERIMETRO: 713.022,
        block: "BL 3",
        No_Kav: "30",
        Nm_Pemilik: "Suprianto",
        CPCL_BANK: "Hartejo",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820589283103686, -3.98114157877369],
              [114.820472127000585, -3.981220040276386],
              [114.820433887212104, -3.981245649020976],
              [114.819972719871146, -3.981243226668202],
              [114.819980613789099, -3.978586632452185],
              [114.820554968598955, -3.978590978722941],
              [114.820597073794644, -3.978665433395254],
              [114.820589283103686, -3.98114157877369],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.998,
        Luas: 2.0,
        PERIMETRO: 609.226,
        block: "BL 4",
        No_Kav: "48",
        Nm_Pemilik: "Rusni",
        CPCL_BANK: "Rusni",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.816177472543316, -3.982680739819337],
              [114.815694563040907, -3.982421626337215],
              [114.815105076955447, -3.98239757373698],
              [114.814911663522722, -3.982194886643965],
              [114.814769614335702, -3.981025549141313],
              [114.815128935679525, -3.981227017541284],
              [114.816181260351826, -3.981244518641822],
              [114.816177472543316, -3.982680739819337],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.001,
        Luas: 2.0,
        PERIMETRO: 583.359,
        block: "BL 4",
        No_Kav: "47",
        Nm_Pemilik: "Agus Wibowo",
        CPCL_BANK: "Ahlan",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817233312881115, -3.982761558316512],
              [114.817058178791598, -3.982638784959378],
              [114.816707643656756, -3.982965212392536],
              [114.816177472543316, -3.982680739819337],
              [114.81618126227734, -3.981244518646942],
              [114.817133443883748, -3.981260350098004],
              [114.817237262035448, -3.981286072835001],
              [114.817233312881115, -3.982761558316512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.995,
        Luas: 2.0,
        PERIMETRO: 721.509,
        block: "BL 4",
        No_Kav: "46",
        Nm_Pemilik: "Agus Wibowo",
        CPCL_BANK: "Ahlan",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817777821215174, -3.983904783770079],
              [114.817784304040515, -3.983764971032325],
              [114.817788341588184, -3.983648815467784],
              [114.817807106862702, -3.98360764505744],
              [114.817754996750878, -3.983558791617414],
              [114.817964642222478, -3.983297037928918],
              [114.817257731967032, -3.982780934377258],
              [114.817243842188745, -3.982768939703719],
              [114.817233312881115, -3.982761558316512],
              [114.817237262035448, -3.981286072835001],
              [114.81736568313805, -3.981317890833441],
              [114.818153452573497, -3.981727190764364],
              [114.818148974376271, -3.983714118966171],
              [114.817777821215174, -3.983904783770079],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 11634.831,
        Luas: 1.163,
        PERIMETRO: 514.826,
        block: "BL 4",
        No_Kav: "45",
        Nm_Pemilik: "Hj. Fitriah",
        CPCL_BANK: "Hj. Fitriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818153452573497, -3.981727190764364],
              [114.818703210528739, -3.982012826975049],
              [114.818710948457564, -3.98278479846978],
              [114.818709422252439, -3.983426211274022],
              [114.818148974445606, -3.98371411893852],
              [114.818153452573497, -3.981727190764364],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 19999.999,
        Luas: 2.0,
        PERIMETRO: 632.474,
        block: "BL 4",
        No_Kav: "44",
        Nm_Pemilik: "Hj. Fitriah",
        CPCL_BANK: "Hj. Fitriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818784910105634, -3.98338778748849],
              [114.818789257051307, -3.982776155054128],
              [114.818788535722803, -3.982740588012863],
              [114.818773561638565, -3.982002240210081],
              [114.818874362165928, -3.981601131237529],
              [114.818872816656423, -3.98128226173664],
              [114.819673570786733, -3.981285468572584],
              [114.819666082650713, -3.983091665851627],
              [114.819470364904959, -3.98302075208352],
              [114.818784910105634, -3.98338778748849],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.0,
        Luas: 2.0,
        PERIMETRO: 614.575,
        block: "BL 4",
        No_Kav: "43",
        Nm_Pemilik: "Eva Merry",
        CPCL_BANK: "Eva Merry",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.819673570786733, -3.981285468572584],
              [114.820464290373508, -3.981288634141086],
              [114.820508423036927, -3.981258615545899],
              [114.820503281466856, -3.983258891639425],
              [114.820299345673106, -3.983338156880924],
              [114.8199366428654, -3.983189697028303],
              [114.819666082650713, -3.983091665851627],
              [114.819673570786733, -3.981285468572584],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.008,
        Luas: 2.0,
        PERIMETRO: 635.406,
        block: "BL 4",
        No_Kav: "42",
        Nm_Pemilik: "Suhadak",
        CPCL_BANK: "Suhadak",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.820508423036927, -3.981258615545899],
              [114.820733605279159, -3.98110544772323],
              [114.821028565175482, -3.981032220796923],
              [114.821131257943065, -3.981028273658527],
              [114.821302879665865, -3.981011450114676],
              [114.821298468189454, -3.983071767339788],
              [114.82090620126074, -3.983102285576376],
              [114.820503281466856, -3.983258891639425],
              [114.820508423036927, -3.981258615545899],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 4118.257,
        Luas: 0.412,
        PERIMETRO: 300.05,
        block: "BL 4",
        No_Kav: "40",
        Nm_Pemilik: "Hj. Fitriah",
        CPCL_BANK: "Hj. Fitriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.822389121688062, -3.982167089083413],
              [114.822938231630076, -3.982800247831225],
              [114.823059128677272, -3.983116472097388],
              [114.822385932937777, -3.983018068748915],
              [114.822389121688062, -3.982167089083413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.005,
        Luas: 2.0,
        PERIMETRO: 632.329,
        block: "BL 4",
        No_Kav: "41",
        Nm_Pemilik: "Jansenius Saragih",
        CPCL_BANK: "Jansenius Saragih",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821302879665865, -3.981011450114676],
              [114.821501241312518, -3.980992004634891],
              [114.821502285688069, -3.981144500741387],
              [114.822389120206481, -3.982167085334931],
              [114.822385932618673, -3.983018068667898],
              [114.822247938929351, -3.982997898012772],
              [114.821298468189454, -3.983071767339788],
              [114.821302879665865, -3.981011450114676],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 4754.663,
        Luas: 0.475,
        PERIMETRO: 384.846,
        block: "BL 3",
        No_Kav: "28",
        Nm_Pemilik: "Hj. Fitriah",
        CPCL_BANK: "Hj. Fitriah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821397445622821, -3.979122054543683],
              [114.821588232604299, -3.979100247962782],
              [114.82157436848766, -3.979261618744959],
              [114.821900695117819, -3.979746074051294],
              [114.821845052211174, -3.979892589905931],
              [114.821666761809212, -3.980149769033079],
              [114.821510049986614, -3.980453973546684],
              [114.821451794627691, -3.980646551582297],
              [114.821397445622821, -3.979122054543683],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.004,
        Luas: 2.0,
        PERIMETRO: 705.517,
        block: "BL 3",
        No_Kav: "29",
        Nm_Pemilik: "Erlina Saragih",
        CPCL_BANK: "Erlina Saragih",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.821021297164918, -3.980983267830122],
              [114.820709193395061, -3.981061272700637],
              [114.820589283103686, -3.98114157877369],
              [114.820597073794644, -3.978665433395254],
              [114.820888215727493, -3.979180258085622],
              [114.821397445534529, -3.97912205454345],
              [114.821451794627691, -3.980646551582297],
              [114.821440378144743, -3.980684291674417],
              [114.821499473746584, -3.980959680424707],
              [114.821286753316897, -3.980970173419518],
              [114.821021297164918, -3.980983267830122],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 5308.14,
        Luas: 0.531,
        PERIMETRO: 314.306,
        block: "BL 8",
        No_Kav: "94",
        Nm_Pemilik: "Sahidah",
        CPCL_BANK: "Sahidah",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.818774785882866, -3.99270261329564],
              [114.819179031056706, -3.992671555421915],
              [114.8192451781578, -3.993613738680489],
              [114.818899255861197, -3.993691955778564],
              [114.818770610010233, -3.993706272580935],
              [114.818774785882866, -3.99270261329564],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ID: 0.0,
        AREA: 20000.001,
        Luas: 2.0,
        PERIMETRO: 583.017,
        block: "BL 8",
        No_Kav: "95",
        Nm_Pemilik: "Supiani",
        CPCL_BANK: "Supiani",
        KET: "SKT",
        TANAM: "2008",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.817221007563802, -3.99278583238162],
              [114.817438441619331, -3.992795314347183],
              [114.817828084576519, -3.992775347784459],
              [114.817955042090631, -3.992765594574653],
              [114.818774785678499, -3.992702613397562],
              [114.818770609114011, -3.993706272323217],
              [114.817952315970402, -3.993797334891855],
              [114.817199442395165, -3.993881115750566],
              [114.817201618942534, -3.992785036246083],
              [114.817221007563802, -3.99278583238162],
            ],
          ],
        ],
      },
    },
  ],
};
