export const damarlima25 = {
  type: "FeatureCollection",
  name: "Damarlima25_1",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Suradi",
        NoKavl: "11",
        No_SKT: "590/89/TE-DLS/II-08",
        Luas_SHM: "0,89",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.894584602463027, -3.910859919264265],
              [114.895841121278536, -3.91086308268901],
              [114.895100345507444, -3.911925019049633],
              [114.894686525998722, -3.91182452797137],
              [114.894904209013262, -3.911165097742906],
              [114.894584602463027, -3.910859919264265],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Rahibah",
        NoKavl: "5",
        No_SKT: "590/84/TE-DLS/II-08",
        Luas_SHM: "1,17",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.895841121278536, -3.91086308268901],
              [114.895846511294664, -3.90870218469731],
              [114.896351140384567, -3.908483619481351],
              [114.896419951175076, -3.909767587644965],
              [114.895841121278536, -3.91086308268901],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Mahyuni",
        NoKavl: "3",
        No_SKT: "590/07/SK-DL/I-2012",
        Luas_SHM: "1,91",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.895108418257038, -3.908690166729829],
              [114.895103001006945, -3.910861224618538],
              [114.894584602463027, -3.910859919264265],
              [114.89421229270107, -3.910684192815803],
              [114.894141748253418, -3.910096363306162],
              [114.894246382074925, -3.909649107306927],
              [114.894982402409639, -3.908688114817734],
              [114.895108418257038, -3.908690166729829],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Diany K. S.",
        NoKavl: "4",
        No_SKT: "590/08/SK-DL/I-2012",
        Luas_SHM: "1,96",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.895846511294664, -3.90870218469731],
              [114.895841121278536, -3.91086308268901],
              [114.895103001006945, -3.910861224618538],
              [114.895108418257038, -3.908690166729829],
              [114.895846511294664, -3.90870218469731],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "A.Mudhofar",
        NoKavl: "10",
        No_SKT: "590/87/TE-DLS/II-08",
        Luas_SHM: "1,05",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.893665108759592, -3.911722504702772],
              [114.893113709837024, -3.910844158239528],
              [114.89421229380261, -3.910684191714997],
              [114.894584602112886, -3.910859919079441],
              [114.894093197767916, -3.911515645056751],
              [114.893665108759592, -3.911722504702772],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Aspul Anwar",
        NoKavl: "9",
        No_SKT: "590/78/TE-DLS/II-08",
        Luas_SHM: "1,26",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.893665108759592, -3.911722504702772],
              [114.893237019631613, -3.911929364014472],
              [114.892642064307964, -3.912271414079657],
              [114.892311134576929, -3.911420745616909],
              [114.893113709837024, -3.910844158239528],
              [114.893665108759592, -3.911722504702772],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "H..Alfiansyah",
        NoKavl: "8",
        No_SKT: "590/80/TE-DLS/II-08",
        Luas_SHM: "1,09",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892642064307964, -3.912271414079657],
              [114.891426866971258, -3.912268348965634],
              [114.891428992308107, -3.911418520880523],
              [114.892311134576929, -3.911420745616909],
              [114.892642064307964, -3.912271414079657],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Suradi",
        NoKavl: "12",
        No_SKT: "590/81/TE-DLS/II-08",
        Luas_SHM: "1,09",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.890296341996958, -3.913183134460946],
              [114.889580068895015, -3.913181325575083],
              [114.889598168756578, -3.912824260856476],
              [114.888968836940165, -3.912515285108662],
              [114.889045327680449, -3.912262336843848],
              [114.890301375243311, -3.912265508542222],
              [114.890296341996958, -3.913183134460946],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Aspul Anwar",
        NoKavl: "6",
        No_SKT: "590/77/TE-DLS/II-08",
        Luas_SHM: "1,17",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.890301375243311, -3.912265508542222],
              [114.889045327680449, -3.912262336843848],
              [114.889250213163109, -3.911584796684343],
              [114.889435753884626, -3.911413490566176],
              [114.890306034467073, -3.911415687468228],
              [114.890301375243311, -3.912265508542222],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "M. Tatak",
        NoKavl: "7",
        No_SKT: "590/83/TE-DLS/II-08",
        Luas_SHM: "1,17",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891426866971258, -3.912268348965634],
              [114.890301375243311, -3.912265508542222],
              [114.890306034467073, -3.911415687468228],
              [114.891428992308107, -3.911418520880523],
              [114.891426866971258, -3.912268348965634],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "H..Alfiansyah",
        NoKavl: "13",
        No_SKT: "590/86/TE-DLS/II-08",
        Luas_SHM: "1,27",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891424571537215, -3.913185982462423],
              [114.890296341996958, -3.913183134460946],
              [114.890301375243311, -3.912265508542222],
              [114.891426866971258, -3.912268348965634],
              [114.891424571537215, -3.913185982462423],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Prasetyo W.",
        NoKavl: "14",
        No_SKT: "590/79/TE-DLS/II-08",
        Luas_SHM: "1,63",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892324718598573, -3.913188253620792],
              [114.891424571537215, -3.913185982462423],
              [114.891426866971258, -3.912268348965634],
              [114.892642064307964, -3.912271414079657],
              [114.893063257677227, -3.913022861676785],
              [114.892594593278744, -3.913256740260797],
              [114.892324718598573, -3.913188253620792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Rahibah",
        NoKavl: "1",
        No_SKT: "590/85/TE-DLS/II-08",
        Luas_SHM: "1,95",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.891428992308107, -3.911418520880523],
              [114.890306034467073, -3.911415687468228],
              [114.889435753884626, -3.911413490566176],
              [114.89008026846254, -3.910818426577951],
              [114.890864591757349, -3.910341244377612],
              [114.891432859583361, -3.909871691199022],
              [114.891428992308107, -3.911418520880523],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "M. Tatak",
        NoKavl: "2",
        No_SKT: "590/82/TE-DLS/II-08",
        Luas_SHM: "1,99",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.892311134576929, -3.911420745616909],
              [114.891428992308107, -3.911418520880523],
              [114.891432859583361, -3.909871691199022],
              [114.892018153982121, -3.909792665531925],
              [114.892700524053978, -3.91049052609418],
              [114.892311134576929, -3.911420745616909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "Prasetyo W.",
        NoKavl: "15",
        No_SKT: "590/78/TE-DLS/II-08",
        Luas_SHM: "1,3",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.89095239269912, -3.913184791273574],
              [114.890950265389975, -3.914035081923372],
              [114.889928212088776, -3.914027517069996],
              [114.889569760890467, -3.913384716870597],
              [114.889580068895029, -3.91318132557505],
              [114.890296341996958, -3.913183134460912],
              [114.89095239269912, -3.913184791273574],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Id: 0.0,
        Lokasi: "Damar LIma 25",
        Nm_Pemilik: "A.Mudhofar",
        NoKavl: "16",
        No_SKT: "590/88/TE-DLS/II-08",
        Luas_SHM: "1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [114.890950265389975, -3.914035081923372],
              [114.89095239269912, -3.913184791273574],
              [114.891424571537215, -3.91318598246239],
              [114.892324718598601, -3.91318825362076],
              [114.891765553128266, -3.913616280099311],
              [114.891782493047018, -3.914041239649664],
              [114.890950265389975, -3.914035081923372],
            ],
          ],
        ],
      },
    },
  ],
};
