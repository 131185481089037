import "react-circular-progressbar/dist/styles.css";

// import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import { Number, parseToFloat } from "../../../../../helpers/Common";
import ProgressBar from "../../../../../components/atoms/ProgressBar";

const CardWithDoughnutChart = ({
  title,
  subTitle,
  data,
  progressStyle,
  onlyText,
}) => {
  const valuePercentage = (data[0]?.value / data[1]?.value) * 100 || 0;
  // const valuePercentage = !Number.isNaN(
  // 	typeof (data[0]?.value / data[1]?.value)
  // )
  // 	? data[0]?.value / data[1]?.value
  // 	: 0 || 0;

  return (
    <div className="relative grid h-full grid-cols-1 gap-8 rounded-8 bg-white p-8 shadow md:grid-rows-[minmax(0px,1fr)_minmax(0px,2fr)] md:p-16">
      <div className="flex flex-col">
        <h3 className="truncate text-14 font-semibold text-gray-600 md:text-16">
          {title}
        </h3>
        <p className="font-regular text-13 text-gray-400 md:text-14">
          {subTitle}
        </p>
      </div>
      <div
        className={`relative grid ${
          onlyText ? "grid-cols-[1fr,max-content]" : "grid-cols-1"
        } gap-8 md:grid-cols-2 md:gap-0`}
      >
        <div className="flex flex-row space-x-8 space-y-0 md:flex-col md:justify-end md:space-x-0 md:space-y-8">
          {data.map((item, index) => (
            <div className="grid w-full grid-cols-1">
              <div className="flex flex-row items-center justify-start space-x-4">
                <div
                  className="aspect-square w-10 rounded-full"
                  style={item?.style}
                ></div>
                <p className="break-words text-13 font-medium text-gray-500 md:text-13">
                  {item?.title}
                </p>
              </div>
              <div
                key={index}
                className="flex flex-row items-end space-x-4 md:items-center"
              >
                <h1 className="text-14 font-bold text-gray-500 md:text-16">
                  {Number(Math.ceil(item?.value))}
                </h1>
                <p className="text-12 font-regular text-gray-500 md:text-14">
                  {item?.unit}
                </p>
              </div>
            </div>
          ))}
        </div>
        {onlyText ? (
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-32 font-semibold text-gray-500 md:text-32">
              {parseToFloat(valuePercentage)}%
            </h1>
          </div>
        ) : (
          <div className="relative hidden flex-col items-center justify-center md:flex">
            <p className="absolute -top-24 text-18 font-bold text-gray-500 md:-top-32 md:text-24">
              {parseToFloat(valuePercentage)}%
            </p>
            <ProgressBar
              baseBgColor={progressStyle.backgroundColor[1]}
              bgColor={progressStyle.backgroundColor[0]}
              completed={valuePercentage}
              className="h-full -rotate-180"
              containerStyles={{ width: 15 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardWithDoughnutChart;
