import React from "react";
import { MapProvider } from "./context/mapContext";

const MapContainer = ({ children }) => {
  return (
    <MapProvider>
      <>{children}</>
    </MapProvider>
  );
};

export default MapContainer;
