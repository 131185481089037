import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showModalCreate: false,
	showModalUpdate: false,
	showModalHistory: false,
	params: {},
};

export const harvestModalSlice = createSlice({
	name: "harvestModal",
	initialState,
	reducers: {
		handleModal: (state, action) => {
			state[action.payload.state] = !state[action.payload.state];
		},
		handleSetParams: (state, action) => {
			state.params = action.payload;
		},
	},
});

export const { handleModal, handleSetParams } = harvestModalSlice.actions;
export const selectHarvestModalStatus = (state) => state.harvestModal;
export default harvestModalSlice.reducer;
