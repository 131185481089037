import React, { useMemo, useRef, useState } from "react";
import { useMeasure } from "@uidotdev/usehooks";
import { useModalStore } from "../../../../../../PriceSetting/ListView/partials/ListData/partials/ModalUpdateHarga/ModalUpdateHarga";
import { useTableStore } from "../../../../../../PriceSetting/ListView/partials/ListData/ListData";
import { MdDragIndicator } from "react-icons/md";
import { MaterialReactTable } from "material-react-table";
import {
  defaultTableConfig,
  MIU_TABLE_BODY_CELL_PROPS_SX,
  MIU_TABLE_HEAD_CELL_PROPS_SX,
  MIU_TABLE_HEAD_ROW_PROPS_SX,
  MIU_TABLE_PROPS_SX,
} from "../../../../../../../utils/tableUtils";
import { data } from "../../index";
import { formatNumericValue } from "../../../../../../../helpers/Common";
import { omitBy } from "lodash";
import {
  BiSolidChevronDown,
  BiSolidChevronsDown,
  BiSolidChevronUp,
} from "react-icons/bi";

const Table = () => {
  const columns = useMemo(
    () => [
      {
        header: "Collapsed",
        accessorKey: "collapsed",
        size: 40,
        enableColumnDragging: false,
        enableColumnOrdering: false,
        Header: () => (
          <div className="flex items-center">
            <BiSolidChevronsDown />
          </div>
        ),
        Cell: ({ cell, row }) => (
          <div
            className="flex items-center"
            onClick={() => row.toggleExpanded()}
          >
            {row?.getIsExpanded() ? (
              <BiSolidChevronUp />
            ) : (
              <BiSolidChevronDown />
            )}
          </div>
        ),
      },
      {
        header: "Nama Mitra",
        accessorKey: "name",
      },
      {
        header: "Jumlah Nota",
        accessorKey: "totalNota",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Total Netto",
        accessorKey: "totalNetto",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Total Harga",
        accessorKey: "totalRp",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
      },
    ],
    [data]
  );

  const columnsDetail = useMemo(
    () => [
      {
        header: "Netto",
        accessorKey: "totalNetto",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Harga PKS",
        accessorKey: "priceAgreement",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
      },
      {
        header: "Subtotal",
        accessorKey: "totalRp",
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell, row }) => (
          <p>
            {formatNumericValue(cell.getValue(), {
              prefix: "Rp",
              decimals: 0,
            })}
          </p>
        ),
      },
    ],
    [data]
  );

  const [ref, { height }] = useMeasure();

  const [toggleModal] = useModalStore((state) => [state.toggleModal]);

  const [isDrawer, setIsDrawer, sortedColumns, setSortedColumns] =
    useTableStore((state) => [
      state.isDrawer,
      state.setIsDrawer,
      state.sortedColumns,
      state.setSortedColumns,
    ]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    columns.map((c) => c.accessorKey) //array of column ids
  );
  const [columnPinning, setColumnPinning] = useState([]);
  const [columnSizing, setColumnSizing] = useState([]);
  const tableInstanceRef = useRef(null);

  // default table state handler
  const columnSizingChangeHandler = (updater) => {
    setColumnSizing((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnPinningChangeHandler = (updater) => {
    setColumnPinning((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };
  const columnOrderChangeHandler = (updater) => {
    setColumnOrder((prev) => {
      return updater instanceof Function ? updater(prev) : updater;
    });
  };
  const columnVisibilityHandler = (updater) => {
    setColumnVisibility((prev) =>
      updater instanceof Function ? updater(prev) : updater
    );
  };

  return (
    <div
      ref={ref}
      className="relative h-full max-h-full overflow-y-scroll rounded-8 rounded-b-[0px] border border-gray-50 bg-white"
    >
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
        columns={columns}
        data={data}
        renderDetailPanel={({ row }) => (
          <div className="m-auto grid w-full rounded-16">
            <MaterialReactTable
              icon={{ MoreVertIcon: (props) => <MdDragIndicator {...props} /> }}
              columns={columnsDetail}
              data={row?.original?.detail_of_all_notas}
              {...omitBy(
                defaultTableConfig,
                (v, k) => k === "enableTableFooter"
              )}
              enableTableFooter
              enableRowNumbers
              rowNumberMode="original"
              muiTableContainerProps={{
                sx: { maxHeight: "250px" },
              }}
              initialState={{ density: "compact" }}
              muiTableProps={{ sx: MIU_TABLE_PROPS_SX }}
              muiTableBodyCellProps={({ cell, column, row, table }) => {
                return {
                  sx: {
                    ...MIU_TABLE_BODY_CELL_PROPS_SX,
                  },
                };
              }}
              muiTableHeadRowProps={{
                sx: MIU_TABLE_HEAD_ROW_PROPS_SX,
              }}
              muiTableHeadCellProps={{
                sx: MIU_TABLE_HEAD_CELL_PROPS_SX,
              }}
            />
          </div>
        )}
        // Enable
        enableExpanding
        {...defaultTableConfig}
        // State
        state={{
          columnOrder,
          columnVisibility,
          columnPinning,
          columnSizing,
        }}
        initialState={{ density: "compact" }}
        onColumnSizingChange={columnSizingChangeHandler}
        onColumnPinningChange={columnPinningChangeHandler}
        onColumnOrderChange={columnOrderChangeHandler}
        onColumnVisibilityChange={columnVisibilityHandler}
        // Styles
        muiTableContainerProps={{
          sx: { maxHeight: height },
        }}
        muiTableProps={{ sx: MIU_TABLE_PROPS_SX }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          return {
            sx: {
              ...MIU_TABLE_BODY_CELL_PROPS_SX,
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: MIU_TABLE_HEAD_ROW_PROPS_SX,
        }}
        muiTableHeadCellProps={{
          sx: MIU_TABLE_HEAD_CELL_PROPS_SX,
        }}
      />
    </div>
  );
};

export default Table;
