import React, { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  deleteEstate,
  getEstates,
  selectStatuses,
} from "../../../features/estateSlice";
import {
  handleModalStarter,
  handleSetParams,
  selectModal,
} from "../../../features/modalSlice";
import { selectUser, selectUserCompanyId } from "../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import EstateModal from "../../../components/organisms/MasterTph/EstateModal";
import Filter from "./partials/Filter/Filter";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import TableRowMasterEstate from "../../../components/organisms/Tables/columns/TableRowMasterEstate";
import Tables from "../../../components/organisms/Tables";
import { TiChartArea } from "react-icons/ti";
import headers from "../../../api/tables/dataTph/estate_headers.json";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";
import { ConfigProvider, Modal } from "antd";
import ButtonComponent from "../../../components/atoms/ButtonComponent";
import ModalFilter from "./partials/ModalFilter";
import CreateMassData from "./partials/CreateMassData/CreateMassData";
import { APP_NAME } from "../../../configs/api";

const modalName = "masterTph";
const modalUploadName = "modalUpload";

const MoContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onShowModal, onModalUpdate, isUpdate } = useModal();
  const alert = useAlert();

  const user = useSelector(selectUser);
  const modal = useSelector(selectModal);
  const status = useSelector(selectStatuses);
  const currentCompanyId = useSelector(selectUserCompanyId);

  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    total: 1,
    current: 1,
  });
  const [isFilterBy, setIsFilterBy] = useState({
    name: undefined,
  });
  const paginationLenght = Math.ceil(page?.total / 20);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  let params = {
    companyId: user?.companyId,
    page: page.current,
    fltName: isFilterBy.name,
  };

  // const onFileCapture = (data) => {
  //   const fileContent = data;
  //   const fileName = data.name;
  //   const userId = user?.id;
  //
  //   dispatch(importBlocks({ fileContent, fileName, userId }))
  //     .unwrap()
  //     .then((resp) => {
  //       if (!resp.success) {
  //         return alert.error(resp.message);
  //       }
  //       alert.success("Berhasil import data block");
  //       handleRefreshData();
  //     })
  //     .catch((err) => {
  //       alert.error(err.message);
  //     });
  // };

  const handleDelete = async (props) => {
    try {
      const response = await dispatch(deleteEstate({ id: props.id })).unwrap();
      alert.success(`Berhasil menghapus block ${props.name}`);
      handleRefreshData();
    } catch (err) {
      return alert.error(`Gagal menghapus block id: ${props.id}`);
    }
  };

  const onFilter = useCallback(
    ({ type, value }) => {
      if (type === "name") {
        setIsFilterBy({
          ...isFilterBy,
          name: value,
        });
        params = {
          ...params,
          fltName: value,
        };
      }

      dispatch(getEstates(params))
        .unwrap()
        .then((response) => {
          setData(response.data?.records);
          setPage({ current: 0, total: response.data?.totalRecords });
        });
    },
    [data, isFilterBy, page]
  );

  const handlePageClick = async (page) => {
    try {
      const response = await dispatch(
        getEstates({ ...params, page: page.selected })
      ).unwrap();
      const success = response?.data.success;
      const message = response?.data.message;
      if (!success) {
        return alert.error(message);
      }
      setData(response.data?.records);
      setPage({ current: page.selected, total: response.data?.totalRecords });
    } catch (err) {
      return alert.error(err?.data);
    }
  };

  const handleRefreshData = async () => {
    try {
      const response = await dispatch(
        getEstates({
          ...params,
          page: 0,
        })
      ).unwrap();
      const success = response?.data.success;
      const message = response?.data.message;
      if (!success) {
        return alert.error(message);
      }
      setData(response.data?.records);
      setPage({ current: 0, total: response.data?.totalRecords });
    } catch (err) {
      return alert.error(err?.data);
    }
  };

  useEffect(() => {
    handleRefreshData();
  }, []);

  // modal initial
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(
        handleModalStarter({
          [modalName]: {
            showModalCreate: false,
            showModalDetail: false,
            showModalBlock: false,
            params: {},
          },
        })
      );
      dispatch(
        handleModalStarter({
          [modalUploadName]: {
            showModalInput: false,
          },
        })
      );
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative grid max-h-screen min-h-screen w-full grid-cols-1 grid-rows-[max-content,max-content,1fr,min-content] gap-8 overflow-hidden bg-gray-30">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: APP_NAME === "sawit-web" ? "#D7A137" : "#7C0711",
          },
        }}
      >
        <Modal
          title="Filter"
          zIndex={10}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={showModal}
          wrapClassName="backdrop-blur-sm"
          footer={
            <div className="flex flex-row items-center justify-end space-x-16 pt-16">
              <ButtonComponent
                value="Tutup"
                type="submit"
                size="none"
                className="button-transition px-16 py-8 text-16"
                onClick={handleOk}
              />
            </div>
          }
        >
          <ModalFilter onFilter={onFilter} />
        </Modal>
      </ConfigProvider>
      <div>
        <Navbar
          leftContentTitle={
            <div className="flex flex-row items-center">
              <TiChartArea className="mr-16 hidden text-24 text-gray-500 md:inline" />
              <p className="line-clamp-1">Data Estate</p>
            </div>
          }
        />
      </div>
      {/* <FileUploadModal
				isOpen={modal[modalUploadName]?.showModalInput}
				onSubmit={onFileCapture}
				toggleModal={() =>
					onShowModal(modalUploadName, "showModalInput")
				}
			/> */}
      <EstateModal
        isOpen={modal[modalName]?.showModalCreate}
        toggleModal={() => onShowModal(modalName, "showModalCreate")}
        onRefreshData={handleRefreshData}
        params={isUpdate && modal[modalName]?.params}
        type={isUpdate ? "Ubah estate" : "Tambah estate"}
      />
      <div>
        {/*<CreateMassData />*/}
        <Filter
          modalUploadName={modalUploadName}
          modalName={modalName}
          onShowModal={onShowModal}
          onFilter={onFilter}
          handleRefreshData={handleRefreshData}
          showModal={showModal}
        />
      </div>
      <div className="mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
        <Tables
          isLoading={status.isLoading}
          isError={status.isError}
          headers={headers}
          rows={data || []}
          rowContent={(props) => (
            <TableRowMasterEstate
              data={props}
              handleDelete={handleDelete}
              handleUpdate={(params) =>
                onModalUpdate(modalName, "showModalCreate", params)
              }
              handleDetail={(estateId, estateName, areaHa) => {
                navigate({
                  pathname: "/data-afdeling",
                  search: createSearchParams({
                    estateId: estateId,
                    estateName: estateName,
                    areaHa: areaHa,
                  }).toString(),
                });
                dispatch(
                  handleSetParams({
                    modalName: modalName,
                    state: "params",
                    params: {
                      ...modal[modalName].params,
                      estateId: estateId,
                    },
                  })
                );
              }}
            />
          )}
        />
      </div>
      <div className="-mt-8">
        <Pagination.Container>
          <Pagination.Records
            current={page.current}
            length={paginationLenght}
            totalRecords={page.total}
          />
          {paginationLenght !== 1 && (
            <Pagination
              current={page.current}
              pageCount={paginationLenght}
              onPageClick={handlePageClick}
            />
          )}
        </Pagination.Container>
      </div>
    </div>
  );
};

export default MoContent;
