import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ConfigProvider, DatePicker } from "antd";
import SelectHook from "../../../../../../../../../../../components/atoms/SelectHook";
import { getYears } from "../../../../../../../../../../../helpers/Common";
import useFilterDataDefaults from "../../../../../../../../../../../hooks/useFilterDataDefaults";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useData } from "../../../../../../../../hooks/DataContext";
import { DEFAULT_DATE_PICKER_STYLE } from "../../../../../../../../../../../utils/antdUtils";

const Content = () => {
  const { watch, control, setValue } = useForm();
  const { isFilterBy, onFilter } = useData();
  const watchAfdeling = watch("afdeling");
  const watchEstate = watch("estate");
  const {
    estates,
    afdelings,
    blocks,
    fetchEstates,
    fetchAfdelings,
    fetchBlocks,
  } = useFilterDataDefaults();

  const onSearchEstate = async (searchTerm) => {
    await fetchEstates({ name: searchTerm });
  };
  const onSearchAfdeling = async (searchTerm) => {
    await fetchAfdelings({
      estateId: watchEstate.value,
      name: searchTerm,
    });
  };
  const onSearchBlock = async (searchTerm) => {
    await fetchBlocks({
      afdelingId: watchAfdeling.value,
      name: searchTerm,
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchEstates({});
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    const date = dayjs(
      moment(isFilterBy?.fltChemistDate).format("DD/MM/Y"),
      "DD/MM/YYYY"
    );
    const defaultDateIfEmpty = dayjs(moment().format("DD/MM/Y"), "DD/MM/YYYY");

    if (isFilterBy?.fltChemistDate === null) {
      setValue("date", defaultDateIfEmpty);
      return;
    }
    setValue("date", date);
  }, [isFilterBy]);

  const onChangeDateHandler = (date, onChange) => {
    onChange(date);
    if (date !== null) {
      onFilter({
        type: "fltChemistDate",
        value: date,
        filterHandler: ({ type, value, updateFilter }) => {
          const dateObject = new Date(value);
          const date = moment(dateObject).format("YYYY-MM-DD");
          updateFilter({
            [type]: date,
          });
        },
      });
    } else {
      onFilter({
        type: "fltChemistDate",
        value: moment().format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#d7a137",
          colorTextQuaternary: "#e1e4e8",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          colorTextPlaceholder: "#6B6F77",
        },
      }}
    >
      <div className="grid grid-cols-1 gap-13">
        <SelectHook
          name="estate"
          data={estates}
          control={control}
          placeholder="Pilih estate"
          menuStyles={{ padding: 5 }}
          className="w-full"
          onInputChange={(searchValue) => {
            onSearchEstate(searchValue);
          }}
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "fltEstate",
            });
            fetchAfdelings({ estateId: data.value });
          }}
        />
        {watchEstate?.value && (
          <SelectHook
            control={control}
            data={afdelings}
            name="afdeling"
            placeholder="Pilih wilayah"
            className="w-full"
            onInputChange={(searchValue) => {
              onSearchAfdeling(searchValue);
            }}
            afterOnChange={(data) => {
              onFilter({
                type: "fltAfdeling",
                value: data?.value,
              });
              fetchBlocks({ afdelingId: data?.value });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}
        {watchAfdeling?.value && (
          <SelectHook
            control={control}
            data={blocks}
            name="block"
            placeholder="Pilih blok"
            className="w-full"
            onInputChange={(searchValue) => {
              onSearchBlock(searchValue);
            }}
            afterOnChange={(data) => {
              onFilter({
                type: "fltBlock",
                value: data?.value,
              });
            }}
            menuStyles={{ padding: 5 }}
          />
        )}
        <SelectHook
          name="year"
          data={getYears()}
          control={control}
          placeholder="Pilih tahun tanam"
          className="w-full"
          afterOnChange={(value) => {
            onFilter({ type: "fltPlantedYear", value });
          }}
          menuStyles={{ padding: 5 }}
        />

        <Controller
          name="date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Pilih tanggal"
                value={value}
                style={DEFAULT_DATE_PICKER_STYLE}
                onChange={(date) => {
                  onChangeDateHandler(date, onChange);
                }}
              />
            );
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default Content;
