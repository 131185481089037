import {
  Number,
  parseFloatHelper,
  parseToFloat,
} from "../../../../../../helpers/Common";

export const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        display: true,
        text: "Tahun Tanam",
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Yield",
      },
    },
  },
  plugins: {
    // datalabels: {
    // 	display: false,
    // },
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: false,
    },
    datalabels: {
      color: "black",
      align: "end",
      anchor: "end",
      font: { size: "14", weight: "500", family: "Inter-Regular" },
      formatter: function (value) {
        return `${value !== 0 ? parseToFloat(value) : ""}`;
      },
    },
    tooltip: {
      callbacks: {
        label: (items) => {
          // console.log(items);
          return `${items?.dataset?.label}: ${parseFloatHelper(items?.raw)}`;
        },
      },
    },
  },
};
