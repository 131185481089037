import {
  requestResetPassword,
  resetPassword,
  selectStatuses,
} from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../components/atoms/ButtonComponent";
import Content from "./partials/Content/Content";
import LOGO_LONG from "../../assets/icons/horizontal.png";
import React from "react";
import queryString from "query-string";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { formSchema, formSchemaVerif } from "./schema/formSchema";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const statusRequest = useSelector(selectStatuses);
  const [isSended, setIsSended] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(formSchema) });

  const {
    handleSubmit: handleSubmitVerif,
    register: registerVerif,
    formState: { errors: errorsVerif },
    setValue: setValueVerif,
    watch: watchVerif,
  } = useForm({ resolver: yupResolver(formSchemaVerif) });

  const watchEmail = watch("email");

  const handleEmailClick = () =>
    window.open(`https://mail.google.com/mail/u/0`, "_blank");

  const sendCodeVerification = async () => {
    try {
      const response = await dispatch(
        requestResetPassword(queryString.stringify({ email: watchEmail }))
      ).unwrap();
      setIsSended(true);
      if (!response.success) {
        return alert.error(response?.message);
      }
      alert.success(
        "Kami telah mengirimkan instruksi pemulihan kata sandi ke email Anda."
      );
    } catch (error) {
      setIsSended(false);
      return alert.error(error?.data);
    }
  };

  const onSubmit = async (data) => {
    if (isSended) {
      return dispatch(resetPassword(queryString.stringify(data)))
        .unwrap()
        .then((resp) => {
          if (!resp.success) {
            return alert.error(resp.message);
          }
          setIsSended(false);
          alert.success("Berhasil atur ulang kata sandi");
          setValue("email", undefined);
          setValue("code", undefined);
          setValue("password", undefined);
          setValue("confirmPassword", undefined);
          navigate("/");
        })
        .catch((err) => {
          return alert.error(
            `Gagal memverifikasi email address ${err?.message}`
          );
        });
    }

    sendCodeVerification();
  };

  return (
    <div className="relative flex h-screen max-h-screen w-full flex-col items-center justify-center bg-gray-30">
      <form
        onSubmit={(e) =>
          isSended ? handleSubmitVerif(onSubmit)(e) : handleSubmit(onSubmit)(e)
        }
        className="relative flex w-full max-w-[412px] flex-col items-center justify-center rounded-16 border border-gray-10 bg-white p-16"
      >
        <img src={LOGO_LONG} className="mb-16 h-72 w-auto" alt="SawitWeb" />
        <div className="flex flex-col space-y-8">
          <h2 className="text-20 font-semi-bold text-gray-500">
            Lupa Kata Sandi
          </h2>
          <p className="font-regular text-14 text-gray-400">
            Isikan email yang terdaftar untuk melakukan pengaturan ulang kata
            sandi.
          </p>
          <p className="font-regular text-14 text-gray-400">
            Kami akan mengirimkan kode pengaturan ulang kata sandi melalui email
            setelah Anda mengisi alamat email terdaftar.
          </p>
        </div>
        <Content
          register={register}
          registerVerif={registerVerif}
          errorsVerif={errorsVerif}
          errors={errors}
          isSended={isSended}
        />
        <div className="flex w-full flex-col items-center justify-center space-y-16">
          <ButtonComponent
            value={`${isSended ? "Ubah kata sandi" : "Kirim"}`}
            size="sm"
            type="submit"
            className="button-transition w-full"
          />
          {isSended && (
            <div className="flex flex-row items-center space-x-16">
              <ButtonComponent
                disabled={statusRequest.isRequest}
                value="Buka Email di Gmail"
                size="sm"
                type="button"
                className="bordered-button-transition w-full"
                onClick={handleEmailClick}
              />
              <ButtonComponent
                disabled={statusRequest.isRequest}
                value="Kirim Ulang Email Verifikasi"
                size="sm"
                type="button"
                className="bordered-button-transition w-full"
                onClick={sendCodeVerification}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
