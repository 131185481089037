import { useReducer, useCallback, useEffect } from "react";
import moment from "moment";

const useFilter = ({ initialFilter }) => {
  const [isFilterBy, setIsFilterBy] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, initialFilter);

  useEffect(() => {
    setIsFilterBy(initialFilter);
  }, []);

  const updateFilter = useCallback(
    (filterValues) => {
      setIsFilterBy(filterValues);
    },
    [isFilterBy]
  );

  const filterConfig = {
    dateRange: (value, filterKeys) => {
      if (value !== null) {
        const startDateObject = new Date(value[0]);
        const endDateObject = new Date(value[1]);
        const startDate = moment(startDateObject).format("Y-MM-DD");
        const endDate = moment(endDateObject).format("Y-MM-DD");

        const filterValues = {};
        filterKeys.forEach((key, index) => {
          filterValues[key] = index === 0 ? startDate : endDate;
        });

        updateFilter({
          ...filterValues,
          sord: "asc",
        });
      } else {
        const filterValues = {};
        filterKeys.forEach((key) => {
          filterValues[key] = undefined;
        });

        updateFilter({
          ...filterValues,
          sord: "desc",
        });
      }
    },
    // Tambahkan aturan-aturan lainnya sesuai kebutuhan
  };

  const onFilter = async ({ type, value, filterKeys, filterHandler }) => {
    if (typeof filterHandler === "function") {
      filterHandler({ type, value, filterKeys, updateFilter });
    } else if (filterConfig[type]) {
      filterConfig[type](value, filterKeys);
    } else {
      const filterKey = type;
      updateFilter({ [filterKey]: value?.value || value });
    }
  };

  return { isFilterBy, setIsFilterBy, onFilter, updateFilter };
};
export default useFilter;
