import "react-lazy-load-image-component/src/effects/blur.css";

import { Controller } from "react-hook-form";
import { ConfigProvider, DatePicker, Image, notification, Tooltip } from "antd";
import {
  Number,
  parseFloatHelper,
  parseToFloat,
} from "../../../../../../../../../helpers/Common";
import React, { useCallback, useEffect, useState } from "react";
import { TbCalendar } from "react-icons/tb";

import { IMAGE_NOT_FOUND } from "../../../../../../../../../api/fallbackImageNotFound";
import InputHook from "../../../../../../../../../components/atoms/InputHook";
import { MdOutlineWarning } from "react-icons/md";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { updateDelivery } from "../../../../../../../../../features/delivery/deliverySlice";
import { useDispatch } from "react-redux";
import { checkValues } from "../../../../../../../../../helpers/validationHelper";
import { debounce } from "lodash";
import { useImageSource } from "../../../../../../../../../hooks/useImageSource";
import { useComment } from "../../../../../../../../../hooks/useComment";
import ShowMore from "../../../../../../../../../components/atoms/ShowMore";
import { useModalStore } from "../../../../../Modal/Modal";
import { useVehicleCheckpointStore } from "../../../../../../../../../api/vehicleCheckpoint/useVehicleCheckpointStore";

dayjs.extend(customParseFormat);
const Item = ({ item, index, handleRefreshData }) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    onChangeDate,
    onChangeText,
    setData,
  } = useComment({ input: item });
  const dispatch = useDispatch();
  const watchRemark = watch(`remark-${item?.id}`);
  const [message, setMessage] = useState("");
  const { source, setDownloadedImage } = useImageSource();
  const [toggleModal] = useModalStore((state) => [state.toggleModal]);
  const [updateVehicleCheckpoint] = useVehicleCheckpointStore((state) => [
    state.updateVehicleCheckpoint,
  ]);

  const updateFunc = async (body) => {
    const response = await updateVehicleCheckpoint({
      id: body?.id,
      body,
      silent: true,
      onError: () => {
        notification.error({
          message: "Gagal Memberikan Komentar",
          placement: "bottomRight",
        });
      },
    });
    return response?.record;
  };

  const request = debounce(async (searchTerm) => {
    onChangeText({
      key: "remark",
      data: searchTerm,
      refreshFunc: handleRefreshData,
      updateFunc,
      silent: true,
    });
  }, 500);

  const debounceTextRequest = useCallback(
    (searchTerm) => {
      request(searchTerm);
    },
    [item]
  );

  useEffect(() => {
    setValue(`remark-${item?.id}`, item?.remark);
    setValue(
      `caDueDate-${item?.id}`,
      item?.caDueDate
        ? dayjs(moment(item?.caDueDate).format("DD/MM/Y"), "DD/MM/YYYY")
        : undefined
    );
  }, [item?.id]);

  useEffect(() => {
    setData(item);
  }, [item?.caDueDate, item?.remark, item]);

  useEffect(() => {
    const fieldNames = {
      vehiclePlateNo: "Nopol",
      originLocation: "Lokasi Pengambilan",
      cargoType: "Jenis Muatan",
      cargoFlow: "Aktivitas",
      cargoWeightKg: "Estimasi Berat Muatan (Kg)",
      departureTime: "Tanggal Pengambilan",

      // destinationLocation: "Muatan Diantar Ke",
      // arrivalTime: "Waktu Tiba",
      // departureTime: "Waktu Keberangkatan",

      // checkpointLocation: "Lokasi Checkpoint",
      // description: "Deskripsi",
      // deliveryLetterNumber: "No. Surat Jalan",
      // ffbCount: "Jumlah JJG",
      // ffbWeightKg: "Berat JJG",
      // bjrKg: "Berat Total JJG",
      // status: "Status",
      // tags: "Tags",
      // photoFileId: "Dokumen 1",
      // photoFileId2: "Dokumen 2",
      // photoFileId3: "Dokumen 3",
    };

    const items = [
      { vehiclePlateNo: item?.vehiclePlateNo },
      { originLocation: item?.originLocation },
      { cargoType: item?.cargoType },
      { cargoFlow: item?.cargoFlow },
      { cargoWeightKg: item?.cargoWeightKg },
      { departureTime: item?.departureTime },
      // { photoFileId: item?.photoFileId },
      // { photoFileId2: item?.photoFileId2 },
      // { checkpointDate: item?.checkpointDate },
      // { destinationLocation: item?.destinationLocation },
      // { arrivalTime: item?.arrivalTime },
      // { description: item?.description },
      // { deliveryLetterNumber: item?.deliveryLetterNumber },
      // { ffbCount: item?.ffbCount },
      // { ffbWeightKg: item?.ffbWeightKg },
      // { bjrKg: item?.bjrKg },
      // { status: item?.status },
      // { tags: item?.tags },
      // { photoFileI3d: item?.photoFileId3 },
    ];
    setMessage(checkValues(items, fieldNames));
    setDownloadedImage(item?.photoFileId, "main");
    setDownloadedImage(item?.photoFileId2, "second");
    setDownloadedImage(item?.photoFileId3, "third");
  }, [item?.photoFileId, item?.photoFileId2, item?.photoFileId3]);

  return (
    <tr
      className={`table-header-regular relative hover:bg-[rgba(215,161,55,0.3)] hover:text-gray-500 hover:transition-all hover:duration-300 hover:ease-in-out`}
      key={index}
    >
      <td className="table-row-regular">
        <span>{index + 1}</span>
        <Tooltip
          placement="bottom"
          title={message.message}
          className="font-regular"
        >
          <div>
            {message.isError ? (
              <MdOutlineWarning className="text-24 text-yellow-600" />
            ) : null}
          </div>
        </Tooltip>
      </td>
      <td className="flex w-[270px] flex-row items-center space-x-4">
        <Image.PreviewGroup>
          <Tooltip placement="bottom" title="Foto 1" className="font-regular">
            <Image
              width={200}
              src={source.main || IMAGE_NOT_FOUND}
              wrapperClassName="aspect-square rounded-8 object-cover"
              className="aspect-square rounded-8 object-cover"
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Foto 2" className="font-regular">
            <Image
              width={200}
              src={source.second || IMAGE_NOT_FOUND}
              wrapperClassName="aspect-square rounded-8 object-cover"
              className="aspect-square rounded-8 object-cover"
            />
          </Tooltip>{" "}
          <Tooltip placement="bottom" title="Foto 3" className="font-regular">
            <Image
              width={200}
              src={source.third || IMAGE_NOT_FOUND}
              wrapperClassName="aspect-square rounded-8 object-cover"
              className="aspect-square rounded-8 object-cover"
            />
          </Tooltip>
        </Image.PreviewGroup>
      </td>
      <td className="table-row-regular">
        <p>
          {item?.departureTime
            ? moment(item?.departureTime).format("ll")
            : null}
        </p>
      </td>{" "}
      {/*<td className="table-row-regular">*/}
      {/*  <p>*/}
      {/*    {item?.arrivalTime ? moment(item?.arrivalTime).format("LL") : null}*/}
      {/*  </p>*/}
      {/*</td>*/}
      <td className="table-row-regular">{item?.fullname}</td>
      <td className="table-row-regular">
        <InputHook
          control={control}
          name={`remark-${item?.id}`}
          placeholder="komentar"
          className="min-w-[160px]"
          inputClassName="input-mini-border pl-8 pr-24 w-full"
          afterOnChange={debounceTextRequest}
          onBlur={() => {
            onChangeText({
              key: "remark",
              data: watchRemark,
              refreshFunc: handleRefreshData,
              updateFunc,
            });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit((fieldValues) =>
                onChangeText({
                  key: "remark",
                  data: fieldValues[`remark-${item?.id}`],
                  refreshFunc: handleRefreshData,
                  updateFunc,
                })
              )(event);
            }
          }}
        />
      </td>
      <td className="table-row-regular">
        <Controller
          name={`caDueDate-${item?.id}`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#d7a137",
                    colorTextQuaternary: "#e1e4e8",
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    colorTextPlaceholder: "#6B6F77",
                  },
                }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    onChangeDate({
                      key: "caDueDate",
                      date: date || null,
                      refreshFunc: handleRefreshData,
                      updateFunc,
                    });
                  }}
                  placeholder="Tanggal tindakan"
                  suffixIcon={<TbCalendar className="text-16" />}
                  style={{
                    width: 160,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    fontFamily: "Inter-Regular",
                    maxWidth: 250,
                    // fontSize: 16,
                    borderWidth: 1,
                    borderColor: "#e1e4e8",
                  }}
                />
              </ConfigProvider>
            );
          }}
        />
      </td>
      {/**/}
      <td className="table-row-regular">{item?.cargoFlow}</td>
      <td className="table-row-regular">{item?.cargoType}</td>
      <td className="table-row-regular">
        {item?.cargoWeightKg || item?.cargoWeightKg === 0
          ? parseToFloat(item?.cargoWeightKg)
          : null}
      </td>
      <td className="table-row-regular">{item?.originLocation}</td>
      <td className="table-row-regular">{item?.vehiclePlateNo}</td>
      {/*<td className="table-row-regular">{item?.destinationLocation}</td>*/}
      {/*<td*/}
      {/*  className="table-row-regular"*/}
      {/*  onClick={(event) => {*/}
      {/*    event.stopPropagation();*/}
      {/*    toggleModal({ data: item });*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <ShowMore*/}
      {/*    className="flex flex-row flex-wrap"*/}
      {/*    data={item?.tags}*/}
      {/*    maxItems={2}*/}
      {/*    itemComponent={(item) => (*/}
      {/*      <div className="mr-4 mb-4 flex flex-row items-center space-x-8 rounded-[4px] bg-gray-20 px-12 py-4">*/}
      {/*        <span className="text-14 font-medium text-gray-500">{item}</span>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">{item?.vehiclePlateNo}</td>*/}
      {/*<td className="table-row-regular">{item?.checkpointDate}</td>*/}
      {/*<td className="table-row-regular">{item?.checkpointLocation}</td>*/}
      {/*<td className="table-row-regular">{item?.tripCode}</td>*/}
      {/*<td className="table-row-regular">{item?.description}</td>*/}
      {/*<td className="table-row-regular">{item?.deliveryLetterNumber}</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.ffbCount || item?.ffbCount === 0 ? Number(item?.ffbCount) : null}*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.ffbWeightKg || item?.ffbWeightKg === 0*/}
      {/*    ? parseFloatHelper(item?.ffbWeightKg)*/}
      {/*    : null}*/}
      {/*</td>*/}
      {/*<td className="table-row-regular">*/}
      {/*  {item?.bjrKg || item?.bjrKg === 0*/}
      {/*    ? parseFloatHelper(item?.bjrKg)*/}
      {/*    : null}*/}
      {/*</td>*/}
    </tr>
  );
};

export default Item;
