import React, { useEffect } from "react";
import { createBlock, updateBlock } from "../../../../features/blockSlice";
import { useDispatch, useSelector } from "react-redux";

import BlockCreateContent from "../../../molecules/MasterTph/Modal/BlockCreateContent";
import ButtonComponent from "../../../atoms/ButtonComponent";
import Modal from "../../../atoms/Modal";
import { formSchema as formSchemaBlock } from "../../../molecules/MasterTph/Modal/BlockCreateContent/formValidation";
import { parseToFloat } from "../../../../helpers/Common";
import { selectModal } from "../../../../features/modalSlice";
import { selectUser } from "../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";

const modalName = "masterBlock";

const BlockModal = ({
  isOpen,
  toggleModal,
  type,
  onRefreshData: handleRefreshData,
  afdeling,
  estate,
}) => {
  const UPDATE_TYPE = type.toLowerCase().includes("ubah");
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchemaBlock),
  });
  const watchFields = watch();

  const alert = useAlert();
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    let requestBody = {
      ...data,
      userId: user?.id,
      companyId: user?.companyId,
      companyName: user?.companyName,
      estateId: estate?.id || user?.defaultEstateId,
      estateName: estate?.name || user?.defaultEstateName,
      afdelingId: afdeling?.id || user?.defaultAfdelingId,
      afdelingName: afdeling?.name || user?.defaultAfdelingName,
      plantedYear: parseInt(data?.plantedYear?.value),
      areHa: parseToFloat(data.areaHa),
      plantedDate: `${parseInt(data?.plantedYear?.value)}-01-01`,
      treeCount: parseInt(data?.treeCount),
    };
    if (UPDATE_TYPE) {
      requestBody = { ...requestBody, id: data.id };
      dispatch(updateBlock(requestBody))
        .unwrap()
        .then((e) => {
          setIsLoading(false);
          if (!e?.success) {
            return alert.error(e?.message);
          }
          alert.success(`Berhasil memperharui blok ${e?.record?.blockName}`);
          toggleModal();
          handleRefreshData();
        })
        .catch((err) => {
          setIsLoading(false);
          alert.error(err?.message);
        });
      return;
    }

    const id = uuidv4();
    requestBody = { ...requestBody, id: id, uuid: id };
    dispatch(createBlock(requestBody))
      .unwrap()
      .then((e) => {
        setIsLoading(false);
        if (!e?.success) {
          return alert.error(e?.message);
        }
        alert.success(`Berhasil menambahkan blok ${e?.record?.blockName}`);
        toggleModal();
        handleRefreshData();
      })
      .catch((err) => {
        setIsLoading(false);
        alert.error(err?.message);
      });
  };

  const state = modal[modalName]?.params?.item;
  const handleDefaultValue = () => {
    clearErrors();
    reset();
    reset({ plantedYear: null, statusTanaman: null });

    if (UPDATE_TYPE) {
      reset();
      reset({
        ...state,
        plantedYear: {
          label: state?.plantedYear,
          value: state?.plantedYear,
        },
      });
    }
  };

  const areaHa = watch("areaHa");
  const treeOneHa = watch("treePerHa");

  useEffect(() => {
    const totalTree = parseInt(areaHa * treeOneHa);
    if (totalTree > 0) {
      setValue("treeCount", totalTree);
    }
    if (state?.treeCount && UPDATE_TYPE) {
      setValue("treeCount", state?.treeCount);
    }
  }, [areaHa, treeOneHa]);

  useEffect(() => {
    handleDefaultValue();
  }, [modal, type]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      title={`${type} ${estate.name} / ${afdeling.name} / Blok`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex flex-col px-24 py-16">
          <BlockCreateContent
            register={register}
            control={control}
            errors={errors}
            watchFields={watchFields}
          />
        </div>
        <div className="modal-footer-container">
          <div className="left flex flex-row items-center space-x-16">
            <ButtonComponent
              value="Batalkan"
              size="sm"
              type="button"
              border
              className="danger-button-transition"
              onClick={() => toggleModal()}
            />
            <ButtonComponent
              disabled={isLoading}
              isLoading={isLoading}
              value={`${type} blok`}
              size="sm"
              type="submit"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default BlockModal;
