import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DELIVERY_URL } from "../../configs/api";
import axios from "axios";

const initialState = {
	deliveries: [],
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getDeliveries = createAsyncThunk(
	"delivery/getDeliveries",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(DELIVERY_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					sidx: "syncInfo.updatedTime",
					sord: "desc",
					...data,
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createDelivery = createAsyncThunk(
	"delivery/createDelivery",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.post(DELIVERY_URL, data, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateDelivery = createAsyncThunk(
	"delivery/updateDelivery",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.put(
				`${DELIVERY_URL}/${data?.id}`,
				data,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteDelivery = createAsyncThunk(
	"delivery/deleteDelivery",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(`${DELIVERY_URL}/${data?.id}`, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deliverySlice = createSlice({
	name: "delivery",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getDeliveries.fulfilled, (state, action) => {
				const records = action.payload?.records;
				state.deliveries = records;
			})
			.addCase(deleteDelivery.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(createDelivery.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(updateDelivery.fulfilled, (state, action) => {
				state.isRequest = false;
			});
	},
});

export const selectDeliveries = (state) => state.delivery.deliveries;
export const selectStatuses = (state) => ({
	isLoading: state.delivery.isLoading,
	isRequest: state.delivery.isRequest,
	isError: state.delivery.isError,
	errors: state.delivery.errors,
});

export default deliverySlice.reducer;
