import React, { useEffect, useState } from "react";
import {
	deleteAverageFruit,
	getAverageFruits,
} from "../../../features/averageFruitSlice";
import { handleModalStarter, selectModal } from "../../../features/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import Filter from "./partials/Filter/Filter";
import ListAverageFruit from "./partials/ListAverageFruit/ListAverageFruit";
import MultiModal from "./partials/MultiModal/MultiModal";
import Navbar from "../../../components/atoms/Navbar";
import Pagination from "../../../components/atoms/Pagination";
import { TbChartArea } from "react-icons/tb";
import { selectUserCompanyId } from "../../../features/userSlice";
import { useAlert } from "react-alert";
import useModal from "../../../hooks/useModal";

const ListView = () => {
	const dispatch = useDispatch();
	const alert = useAlert();
	const { onShowModal, onModalUpdate, isUpdate } = useModal();
	const [page, setPage] = React.useState({
		total: 1,
		current: 0,
	});
	const [isLoading, setIsLoading] = React.useState(false);
	const paginationLenght = Math.ceil(page?.total / 20);
	const modalName = "AverageFruitMonitoring";
	const [isFilterBy, setIsFilterBy] = useState({
		name: undefined,
		afdeling: undefined,
		estate: undefined,
		block: undefined,
	});

	const modal = useSelector(selectModal);
	const companyId = useSelector(selectUserCompanyId);

	const onDelete = ({ id }) => {
		dispatch(deleteAverageFruit({ id: id }))
			.unwrap()
			.then(() => handleRefreshData());
	};

	let params = {
		pageIndex: page.current,
		fltCompany: companyId,
		fltEstate: isFilterBy.estate,
		fltAfdeling: isFilterBy.afdeling,
		fltBlock: isFilterBy.block,
	};

	const onFilter = ({ type, value }) => {
		switch (type) {
			case "estate":
				setIsFilterBy({
					...isFilterBy,
					estate: value,
				});
				params = {
					...params,
					fltEstate: value,
				};
				break;
			case "afdeling":
				setIsFilterBy({
					...isFilterBy,
					afdeling: value,
				});
				params = {
					...params,
					fltAfdeling: value,
				};
				break;
			case "block":
				setIsFilterBy({
					...isFilterBy,
					block: value,
				});
				params = {
					...params,
					fltBlock: value,
				};
				break;
			default:
				break;
		}
		dispatch(getAverageFruits(params))
			.unwrap()
			.then((data) => {
				setPage({ current: 0, total: data.totalRecords });
			});
	};

	const handlePageClick = async (page) => {
		try {
			setIsLoading(true);
			const data = await dispatch(
				getAverageFruits({
					...params,
					pageIndex: page.selected,
				})
			).unwrap();
			const success = data.success;
			const message = data.message;
			if (!success) {
				alert.error(message);
			}
			const totalRecords = data?.totalRecords;
			setPage({
				current: page.selected,
				total: totalRecords,
			});
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			alert.error("Our serve are down, try again later");
		}
	};

	const handleRefreshData = async () => {
		try {
			setIsLoading(true);
			const data = await dispatch(
				getAverageFruits({
					...params,
					pageIndex: 0,
				})
			).unwrap();
			const success = data.success;
			const message = data.message;
			if (!success) {
				alert.error(message);
			}
			const totalRecords = data?.totalRecords;
			setPage({
				current: 0,
				total: totalRecords,
			});
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			alert.error("Our serve are down, try again later");
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) handleRefreshData();
		return () => (isMounted = false);
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			dispatch(
				handleModalStarter({
					[modalName]: {
						showModalCreate: false,
						params: {},
					},
				})
			);
		}
		return () => (isMounted = false);
	}, []);

	return (
		<div className="relative min-h-full w-full overflow-hidden bg-gray-30">
			<MultiModal
				modalName={modalName}
				isOpen={modal[modalName]?.showModalCreate}
				toggleModal={() => onShowModal(modalName, "showModalCreate")}
				onRefreshData={handleRefreshData}
				type={isUpdate ? "Ubah Data" : "Tambah Data"}
			/>
			<Navbar
				leftContentTitle={
					<div className="flex flex-row items-center space-x-16">
						<TbChartArea className="text-24 text-gray-500" />
						<span>BJR Acuan</span>
					</div>
				}
			/>
			<Filter
				modalName={modalName}
				onShowModal={onShowModal}
				onFilter={onFilter}
				handleRefreshData={handleRefreshData}
			/>
			<ListAverageFruit
				isLoading={isLoading}
				onDelete={onDelete}
				onUpdate={(params) =>
					onModalUpdate(modalName, "showModalCreate", params)
				}
			/>
			<Pagination.Container>
				<Pagination.Records
					current={page.current}
					length={paginationLenght}
					totalRecords={page?.total}
				/>
				{paginationLenght !== 1 && (
					<Pagination
						pageCount={paginationLenght}
						onPageClick={handlePageClick}
					/>
				)}
			</Pagination.Container>
		</div>
	);
};

export default ListView;
