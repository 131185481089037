export const headersData = [
	{
		value: "Nama/Kode TPH",
		accessorKey: "name",
		type: "alphabet",
	},
	// {
	// 	value: "Waktu Sinkronisasi",
	// 	accessorKey: "syncedTime",
	// 	type: "alphabet",
	// },
	// {
	// 	value: "Terakhir Diubah",
	// 	accessorKey: "updatedTime",
	// 	type: "alphabet",
	// },
	{
		value: "Tanggal dibuat",
		accessorKey: "createdTime",
		type: "number",
		sorted: true,
	},
	{
		value: "Aksi",
		accessorKey: "action",
		type: "alphabet",
	},
];
