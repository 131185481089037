import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AFDELINGS_URL } from "../../configs/api";
import axios from "axios";
import moment from "moment/moment";

const initialState = {
	afdelings: [],
	isRequest: false,
	isLoading: false,
	isError: false,
	errors: null,
};

export const getAfdelings = createAsyncThunk(
	"afdeling/afdelings",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.get(AFDELINGS_URL, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
				params: {
					fltCompany: data.companyId,
					fltEstate: data.estate || undefined,
					fltName: data.name || undefined,
					fltDeleted: 0,
					pageSize: data.pageSize || 20,
					pageIndex: data?.page
						? data?.page >= 2
							? data?.page - 1
							: data?.page
						: 0,

					sidx: "syncInfo.updatedTime",
					sord: "desc",
				},
			});
			return { data: response.data, type: data.type };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const createAfdeling = createAsyncThunk(
	"estate/createAfdeling",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const requestBody = {
				...data,
				id: data.uuid,
				companyId: data.companyId,
				companyName: data.companyName,
				estateId: data.estateId,
				estateName: data.estateName,
				name: data.name,
				description: data.description,
				areaHa: data.areaHa,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
			};

			const response = await axios.post(AFDELINGS_URL, requestBody, {
				headers: {
					Authorization: "Bearer " + token?.accessToken,
					"content-type": "application/json",
				},
			});

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateAfdeling = createAsyncThunk(
	"estate/updateAfdeling",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const requestBody = {
				...data,
				id: data.uuid,
				companyId: data.companyId,
				companyName: data.companyName,
				estateId: data.estateId,
				estateName: data.estateName,
				name: data.name,
				description: data.description,
				areaHa: data.areaHa,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				updatedTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
			};

			const response = await axios.put(
				`${AFDELINGS_URL}/${data?.id}`,
				requestBody,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteAfdeling = createAsyncThunk(
	"estate/deleteAfdeling",
	async (data, { rejectWithValue }) => {
		try {
			const remainingToken = localStorage.getItem("token");
			const token = JSON.parse(remainingToken);

			const response = await axios.delete(
				`${AFDELINGS_URL}/${data?.id}`,
				{
					headers: {
						Authorization: "Bearer " + token?.accessToken,
						"content-type": "application/json",
					},
				}
			);

			return response.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const afdelingSlice = createSlice({
	name: "afdeling",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAfdelings.pending, (state, action) => {
				state.isLoading = true;
				state.isError = false;
			})
			.addCase(getAfdelings.fulfilled, (state, action) => {
				state.isLoading = false;

				const data = action.payload?.data;
				const type = action.payload?.type;

				if (type == "selects") {
					let newData = [
						{ label: "Semua Afdeling", value: undefined },
					];
					data?.records.forEach((item) => {
						return newData.push({
							value: `${item?.id}`,
							label: `${item?.name}`,
						});
					});

					state.afdelings = newData;
				} else {
					state.afdelings = data?.records;
				}
			})
			.addCase(getAfdelings.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(deleteAfdeling.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(deleteAfdeling.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(deleteAfdeling.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(createAfdeling.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(createAfdeling.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(createAfdeling.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			})
			.addCase(updateAfdeling.pending, (state, action) => {
				state.isRequest = true;
				state.isError = false;
			})
			.addCase(updateAfdeling.fulfilled, (state, action) => {
				state.isRequest = false;
			})
			.addCase(updateAfdeling.rejected, (state, action) => {
				state.isRequest = false;
				state.isError = true;
				console.info(action.payload);
			});
	},
});

export const {} = afdelingSlice.actions;
export const selectAfdelings = (state) => state.afdeling.afdelings;
export const selectStatuses = (state) => ({
	isLoading: state.afdeling.isLoading,
	isRequest: state.afdeling.isRequest,
	isError: state.afdeling.isError,
	errors: state.afdeling.errors,
});

export default afdelingSlice.reducer;
