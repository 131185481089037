import React, { useEffect } from "react";
import { TbFilter, TbRefresh, TbX } from "react-icons/tb";
import ButtonComponent from "../../../../../components/atoms/ButtonComponent";
import InputHook from "../../../../../components/atoms/InputHook";
import SelectHook from "../../../../../components/atoms/SelectHook";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useFilterDataDefaults from "../../../../../hooks/useFilterDataDefaults";

const Filter = ({
  onShowModal,
  handleRefreshData,
  modalName,
  onFilter,
  showModal,
}) => {
  const { afdelings, fetchAfdelings } = useFilterDataDefaults();
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const [input, setInput] = useState({
    name: undefined,
  });

  const onSubmit = (data) => {
    onFilter({
      value: data.name,
      type: "name",
    });
  };

  useEffect(() => {
    fetchAfdelings({});
  }, []);

  useEffect(() => {
    const subscription = watch((value) =>
      setInput({
        name: value.name,
      })
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="mx-16 mt-56 grid grid-cols-1 gap-8 md:mt-0 md:grid-cols-2">
      <div className="order-2 grid w-full grid-cols-2 gap-8 md:order-1 md:flex md:w-fit md:flex-row md:items-center md:space-x-8">
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Filter"
          value="Filter"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:-ml-8 md:hidden md:w-fit"
          onClick={showModal}
          icon={<TbFilter className="text-18 text-gray-500" />}
        />
        <ButtonComponent
          tooltipPlacement="bottom"
          tooltipTitle="Muat ulang"
          value="Muat ulang"
          size="none"
          type="button"
          className="bordered-button-transition w-full bg-white p-10 md:w-fit"
          onClick={handleRefreshData}
          icon={<TbRefresh className="text-18 text-gray-500" />}
        />
        <SelectHook
          control={control}
          data={afdelings}
          name="afdeling"
          placeholder="Pilih wilayah"
          className="hidden max-w-[200px] md:inline"
          afterOnChange={(data) => {
            onFilter({
              value: data?.value,
              type: "afdeling",
            });
          }}
          menuStyles={{ padding: 5 }}
        />
        <InputHook
          register={register}
          name="name"
          placeholder="nama truck"
          className="hidden max-w-[210px] md:inline"
          inputClassName="input-mini-border pr-24 pl-8"
          rightIcon={
            <TbX
              className="cursor-pointer text-16 text-gray-500 hover:text-red-600"
              onClick={() => {
                setValue("name", undefined);
                onFilter({
                  value: undefined,
                  type: "name",
                });
              }}
            />
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit(onSubmit)(event);
            }
          }}
          onSubmit={() => {
            onFilter({
              value: input.name,
              type: "name",
            });
          }}
        />
      </div>
      <div className="order-1 flex w-full justify-end md:order-2">
        <ButtonComponent
          value="Tambah Kendaraan"
          className="button-transition w-full md:w-fit"
          onClick={() => onShowModal(modalName, "showModalCreate")}
        />
      </div>
    </div>
  );
};

export default Filter;
