import React from "react";
import TableRow from "./partials/TableRow";
import Tables from "../../../../../components/organisms/Tables";
import { headersData } from "./api/headerData";

const ListData = ({ isLoading, data, handleRefreshData, page, onUpdate }) => {
  return (
    <div className="mx-16 h-full max-h-full overflow-hidden rounded-8 rounded-b-[0px] border border-gray-50 bg-white">
      <Tables
        className="overscroll-y max-h-full"
        isLoading={isLoading}
        headers={headersData}
        rows={data || []}
        rowContent={(data) => (
          <TableRow
            data={data}
            handleRefreshData={handleRefreshData}
            page={page}
            onUpdate={onUpdate}
          />
        )}
      />
    </div>
  );
};

export default ListData;
