import { TbEye, TbEyeOff } from "react-icons/tb";

import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { APP_NAME } from "../../../configs/api";

const InputHook = ({
  label,
  className,
  secondaryLabel,
  secondaryLabelClassName,
  inputClassName,
  name,
  control,
  register,
  required,
  marginAuto,
  errors,
  optional,
  disabled,
  allFieldsAreDisabled,
  afterOnChange,
  type,
  password,
  leftIcon,
  onSubmit,
  onKeyDown,
  rightIcon,
  ...attrs
}) => {
  const [isVisible, setIsVisible] = React.useState(true);

  const handleVisible = () => setIsVisible(!isVisible);

  return (
    <div className={`relative ${className}`}>
      {label && (
        <p className="mb-8 flex flex-row items-center font-medium text-14 text-gray-500">
          {label}
          {/* required sign */}
          {required && <span className="ml-8 text-red-500"> *</span>}
          {/* optional sign */}
          {optional && (
            <span className="ml-8 font-regular text-gray-300">(optional)</span>
          )}
          {/* secondary label */}
          <span
            className={`${secondaryLabelClassName || "ml-8 text-blue-500"}`}
          >
            {secondaryLabel}
          </span>
        </p>
      )}

      <div className="relative flex w-full flex-col items-start">
        <div className="relative flex w-full flex-row items-center">
          {leftIcon && <div className="absolute left-8 z-10">{leftIcon}</div>}
          {rightIcon && (
            <div className="absolute right-16 z-10">{rightIcon}</div>
          )}
          {control ? (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <input
                    onChange={(event) => {
                      onChange(event.target.value);
                      afterOnChange &&
                        afterOnChange(event.target.value, event, onChange);
                    }}
                    ref={ref}
                    value={value}
                    onKeyDown={onKeyDown}
                    type={
                      password && isVisible ? "password" : type ? type : "text"
                    }
                    autoComplete="on"
                    disabled={allFieldsAreDisabled || disabled}
                    className={`input-border relative w-full rounded-8 font-regular text-16 text-gray-500 placeholder-gray-200 ${
                      disabled
                        ? "cursor-not-allowed bg-gray-20 text-gray-300"
                        : ""
                    } ${inputClassName} ${errors && "border-red-400"}`}
                    style={{
                      WebkitBoxShadow: "0 0 0px 0px #FFFFFF",
                    }}
                    {...attrs}
                  />
                );
              }}
            />
          ) : null}

          {register ? (
            <input
              onKeyDown={onKeyDown}
              {...register(name)}
              type={password && isVisible ? "password" : type ? type : "text"}
              autoComplete="on"
              disabled={allFieldsAreDisabled || disabled}
              className={`input-border relative w-full rounded-8 font-regular text-16 text-gray-500 placeholder-gray-200 ${
                disabled ? "cursor-not-allowed bg-gray-20 text-gray-300" : ""
              } ${inputClassName} ${
                APP_NAME === "semai-kebun" && "focus:outline-primary-red-500"
              } ${errors && "border-red-400"}`}
              style={{
                WebkitBoxShadow: "0 0 0px 0px #FFFFFF",
              }}
              {...attrs}
            />
          ) : null}

          {password && (
            <button
              type="button"
              onClick={handleVisible}
              className="absolute right-16 z-10"
            >
              {isVisible && <TbEye className="text-24 text-gray-400" />}
              {!isVisible && <TbEyeOff className="text-24 text-gray-400" />}
            </button>
          )}
        </div>
        {/* error message */}
        {errors && <p className="error-message mt-4">{errors?.message}</p>}
      </div>
    </div>
  );
};

InputHook.defaultProps = {
  autoComplete: "off",
  required: false,
  disabled: false,
  marginAuto: true,
  type: "text",
};

export default InputHook;
