import { TbCreditCard, TbLicense, TbMoonStars } from "react-icons/tb";

import BasePageHeader from "../../components/atoms/Headings/BasePageHeader";
import BillingAlert from "../../components/templates/Billing/BillingAlert";
import BillingCurrentPlan from "../../components/templates/Billing/BillingCurrentPlan";
import BillingHistory from "../../components/templates/Billing/BillingHistory";
import Navbar from "../../components/atoms/Navbar";
import React from "react";
import { useNavigate } from "react-router-dom";

const BillingPage = () => {
	const navigate = useNavigate();

	const handleNavigateToPlans = () => {
		navigate("/pengaturan/paket-berlangganan");
	};

	return (
		<div className="relative w-full overflow-hidden bg-gray-30">
			<Navbar />
			<BasePageHeader
				icon={<TbCreditCard />}
				title="Info Tagihan"
				buttonShow={false}
			/>

			<div className="grid grid-cols-1 gap-24 pb-24">
				<BillingAlert navigateToPlans={handleNavigateToPlans} />
				<BillingCurrentPlan navigateToPlans={handleNavigateToPlans} />
				<BillingHistory />
			</div>
		</div>
	);
};

export default BillingPage;
