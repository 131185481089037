import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  createMultipleLayers,
  DEFAULT_CSS_MAP_STYLE,
  fitToBounds,
  MAP_STYLE,
} from "././helpers/mapHelpers";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  Source,
  useControl,
} from "react-map-gl";
import Tools from "./partials/Tools";
import Modal from "./partials/Modal";
import { useMap } from "./context/mapContext";
import { omitBy } from "lodash";
import Popup from "./partials/Popup/Popup";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_SECRET_TOKEN;

function DrawControl(props) {
  useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  return null;
}

const Content = () => {
  const mapRef = useRef(null);

  const { map: data, setMap: setData } = useMap();
  const [popupInfo, setPopupInfo] = useState(null);

  const initialViewState = {
    longitude: 117.952,
    latitude: -1.09,
    zoom: 5.98,
  };

  const fitToBoundsHandler = async () => {
    const map = mapRef?.current?.getMap();
    console.log("fit to bounds", mapRef?.current);
    await fitToBounds(map, data?.mapShapes[0]);
  };

  const onClickHandler = useCallback(
    (event) => {
      // Attempt to extract the first feature from the event
      const firstFeature = event?.features?.[0];

      if (firstFeature) {
        // Filter for the matching shape data based on the feature's layer ID
        const matchingShapeData = data?.mapShapes?.find(
          (shape) => shape?.id === firstFeature?.layer?.id
        );

        if (matchingShapeData) {
          // Prepare the data for the popup, excluding 'features' property from the shape data
          const popupData = omitBy(
            matchingShapeData,
            (_, key) => key === "features"
          );
          setPopupInfo({
            data: {
              ...popupData,
              properties: firstFeature.properties,
            },
            x: event?.point?.x,
            y: event?.point?.y,
          });
        }
      } else {
        // No feature was selected, so we clear the popup info
        setPopupInfo(null);
      }
    },
    [data?.mapShapes] // Depend on mapShapes as it's the external data used within the callback
  );

  const updateMapShapes = (updatedProperties) => {
    setData((currentData) => {
      const newData = {
        ...currentData,
        mapShapes: currentData?.mapShapes.map((item, index) => {
          if (item.id === updatedProperties.id) {
            return {
              ...item,
              features: item?.features?.map((feature, feIndex) => {
                if (
                  feature.properties.propertyId ===
                  updatedProperties.properties.propertyId
                ) {
                  return {
                    ...feature,
                    properties: {
                      ...updatedProperties.properties,
                    },
                  };
                } else {
                  return feature;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      };
      setPopupInfo(null);
      return newData;
    });
  };

  useEffect(() => {
    if (data?.mapShapes?.length >= 1) fitToBoundsHandler();
  }, [data?.mapShapes]);

  // useEffect(() => {
  //   console.log("first initiallization");
  // }, []);

  useEffect(() => {
    // Define the function to be called when a key is pressed
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setPopupInfo(null);
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleEscKey);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []); // Empty dependency array means this runs once on mount and cleanup on unmount

  // console.log("MAP", data);

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden">
      <Tools fitToBounds={fitToBoundsHandler} />
      <Modal />
      <Map
        ref={mapRef}
        mapboxAccessToken={ACCESS_TOKEN}
        mapStyle={MAP_STYLE}
        style={DEFAULT_CSS_MAP_STYLE}
        initialViewState={initialViewState}
        projection={"globe"}
        interactiveLayerIds={data?.mapShapes?.map((item) => item?.id)}
        onClick={onClickHandler}
      >
        <GeolocateControl
          position="bottom-right"
          onGeolocate={(position) => {
            // get latitude and longitude of user current location
            console.log("position: ", position);
          }}
        />
        <FullscreenControl position="bottom-right" />
        <NavigationControl />

        {data?.mapShapes?.length >= 1 &&
          createMultipleLayers(data?.mapShapes)?.map((item) => (
            <Source id={item?.id} key={item?.id} type="geojson" data={item}>
              <Layer {...item.label} />
              <Layer {...item.outlineStyle} />
              <Layer {...item.layer} />
            </Source>
          ))}

        {popupInfo && (
          <Popup popupInfo={popupInfo} updateMapShapes={updateMapShapes} />
        )}
      </Map>
    </div>
  );
};

export default Content;
